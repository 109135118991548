import {
    React,Component,BootstrapTable,
    paginationFactory,Button, ButtonToolbar, Card, 
    Form, Col, Row, axios, Container, Banner, Typeahead, TimePicker,
    cellEditFactory, Type, moment, NavLink, Alert, AppConfiguration,
    NoserLoading, Noser, sizePerPageRenderer, DatePicker
} 
from '../../../noser-hris-component';
import ToolkitProvider, { CSVExport } from 'react-bootstrap-table2-toolkit';

class ClientArea extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fade            :   true,
            color           :   "",
            isshow          :   false,
            message         :   "",
            userinfo        :   [],
            isloading       :   false,
            alerttype       :   "",

            clientDDL       : [],
            clientId        :   "",
            clientName      :   "",
            branchDDL       : [],
            branchId        : "",
            provinceId      :   "",
            regionDDL       : [],
            regionId        :   "",
            regionName      : "",
            provinceDDL     : [],
            provinceId      :   "",
            provinceName    : "",
            cityDDL         : [],
            cityId          : "",
            cityName        : "",
            DG              : [],
        }
        this.state.userinfo = JSON.parse(sessionStorage.getItem("userData"))
    }
    
    componentDidMount(){
        this.GetData();
        this.GetClient();
        this.GetRegion();
        this.GetProvince();
        this.GetCity();
    }

    GetData = async() =>{
        this.setState({isloading:true})
        const params = {
            "IpAddress":"0.0.0.0",
            "UserId":this.state.userinfo.userId,
            "ClientId":this.state.clientId,
            "RegionId": this.state.regionId,
            "ProvinceId": this.state.provinceId,
            "CityId":this.state.cityId
        };
        await axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Report/GetClieantAreaReports",  params)
        .then(res => {
            const data = res.data.clientArea;
            console.log("clientArea")
            console.log(data)
            this.setState({isloading:false, DG : data });
        })
        .catch(error=>{
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade        :   true,
            })
        })
    }

    GetClient = async() =>{
        this.setState({isloading:true})
        const params = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.userinfo.clientId,
            "UserId":this.state.userinfo.userId,
            "EmployeeId":this.state.userinfo.roleId==="1" || 
                        this.state.userinfo.roleId==="8" ? "" : this.state.userinfo.employeeId
        }
        await axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Client/GetClientList", params)
        .then(res => {
            const data = res.data;
            let arr = []
            if(this.state.userinfo.roleId!=="1"){
                let fieldaccess = JSON.parse(sessionStorage.getItem("userControlAccess"))
                if(fieldaccess.filter(x=>x.accessCode==="0001").length>0){
                    let access = fieldaccess.find(x=>x.accessCode==="0001" && x.controlAccessDetails.length>0)
                    if(access.controlAccessDetails.filter(x=>x.isClient==="1").length>0){
                        let brr = access.controlAccessDetails.filter(x=>x.isClient==="1")
                        arr = data.clients.filter(f => brr.some(item => item.referenceId === f.id))
                    }
                }
                this.setState({clientDDL : arr,isloading   :   false})
            }
            else{
                this.setState({clientDDL : data.clients,isloading   :   false})
            }
        })
        .catch(error=>{
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade        :   true
            })
        })
    }
    handleChangedClient = (e) => {
        this.setState({isshow:false})
        if(e.length == 0) {
            this.state.clientId=""
            this.state.clientName=""
            this.state.regionClientId=""
            this.state.provinceClientId=""
            this.GetRegion();
            return
        } 
        this.state.clientId = e[0].id
        this.state.clientName= e[0].name
        this.state.regionClientId = e[0].regionId
        this.state.provinceClientId = e[0].provinceId
        console.log(e)
        this.GetRegionSlected(this.state.regionClientId);
        this.GetClientLocation();
    }

    GetClientLocation = async() =>{
        this.setState({isloading:true})

        const getParams = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.clientId,
            "UserId":this.state.userinfo.userId,
            "ClientName": this.state.clientName,
            "City": "",
            "Province": "",
            "Region": ""
    
        }
        await axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Client/GetClientLocations", getParams)
        .then(res => {
            const data = res.data.locations;
            this.setState({
                branchDDL   :   data,
                isloading   :   false
            })
            /* if(data.locations.length=="0"){
                this.setState({
                    isloading   :   false,
                    alerttype   :   "Error!",
                    isshow      :   true,
                    color       :   "danger",
                    message     :   data.message,
                    fade        :   true
                });
            } */
        })
    }

    onChangeBranch = (e) => {
        if(e.length == 0) {
            this.state.branchId=""
            return
        } 
        this.state.branchId = e[0].id
    }
    GetRegion = async() => {
        this.setState({isloading:true});
        const params = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.userinfo.clientId,
            "UserId":this.state.userinfo.userId,
            "Name":""
        };
        await axios.post(AppConfiguration.Setting().noserapiendpoint + "Maintenance/GetRegions",  params)
        .then(res => {
                const data = res.data.regions
                console.log("GetRegions")
                console.log(data)
                this.setState({isloading:false, regionDDL : data});
            })
            .catch(error=>{
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade        :   true,
            })
        })
    }
   
    GetRegionSlected = async(regionId) => {
        console.log("regionId")
        console.log(regionId)
        this.setState({isloading:true});
        const params = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.userinfo.clientId,
            "UserId":this.state.userinfo.userId,
            "Name":""
        };
        await axios.post(AppConfiguration.Setting().noserapiendpoint + "Maintenance/GetRegions",  params)
        .then(res => {
            const data = res.data.regions.filter(x => x.id === regionId)
            console.log("Get Regions Selected")
            console.log(data)
            this.setState({isloading:false, regionDDL : data});
        })
        .catch(error=>{
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade        :   true,
            })
        })
    }
    handleChangedRegion = (e) => {
        this.setState({isshow:false})
        if(e.length == 0) {
            this.state.regionId=""
            this.state.regionName=""
            this.GetProvince();
            return
        } 
        this.state.regionId = e[0].id
        this.state.regionName = e[0].name
        this.GetProvince();
    }
    GetProvince = async() => {
        this.setState({isloading:true});
        const params = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.userinfo.clientId,
            "UserId":this.state.userinfo.userId,
            "Region": this.state.regionName
        };

        await axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Maintenance/GetProvinces",  params)
        .then(res => {
            const data = res.data.provinces;
            console.log("Get Province Selected")
            console.log(data)
            this.setState({isloading:false, provinceDDL : data});
        })
        .catch(error=>{
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade        :   true,
            })
        })

    }
    handleChangedProvince = (e) => {
        this.setState({isshow:false})
        if(e.length == 0) {
            this.state.provinceId=""
            this.state.provinceName=""
            this.GetCity();
            return
        } 
        this.state.provinceId = e[0].id
        this.state.provinceName = e[0].name
        this.GetCity();
    }
    GetCity = async () => {
        this.setState({isloading:true})
        const params = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.userinfo.clientId,
            "UserId":this.state.userinfo.userId,
            "RegionId": this.state.regionId,
            "ProvinceId": this.state.provinceId,
            "Name":""
        };

        await axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Maintenance/GetCities",  params)
        .then(res => {
            const data = res.data.cities;
            console.log("Get cities Selected")
            console.log(data)
            this.setState({ cityDDL: data,isloading:false });
        })
        .catch(error=>{
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade        :   true,
            })
        })

    }
    handleChangedCity = (e) => {
        this.setState({isshow:false})
        if(e.length == 0) {
            this.state.cityId=""
            this.state.cityName=""
            return
        } 
        this.state.cityId = e[0].id
        this.state.cityName = e[0].name
    }

    handleSearchClick = () => {
        this.setState({isloading:true})
        const params = {
            "IpAddress":"0.0.0.0",
            "UserId":this.state.userinfo.userId,
            "ClientId":this.state.clientId,
            "RegionId": this.state.regionId,
            "ProvinceId": this.state.provinceId,
            "CityId":this.state.cityId,
            "BranchId":this.state.branchId
        };
        console.log("params")
        console.log(params)
        axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Report/GetClieantAreaReports",  params)
        .then(res => {
            const data = res.data.clientArea;
            console.log("clientArea")
            console.log(data)
            this.setState({isloading:false, DG : data });
        })
        .catch(error=>{
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade        :   true,
            })
        })
    }
    render(){
        const { ExportCSVButton } = CSVExport;

        const columns = [
            {
                dataField: 'client',
                text: 'Client',
                editable:false,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'left',width:'20%' }},
                style:{textAlign:'left', width:'20%' }
            },
            {
                dataField: 'region',
                text: 'Region',
                editable:false,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'left',width:'20%' }},
                style:{textAlign:'left', width:'20%' }
            },
            {
                dataField: 'province',
                text: 'Province',
                editable:false,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'left',width:'20%' }},
                style:{textAlign:'left', width:'20%' }
            },
            {
                dataField: 'city',
                text: 'City',
                editable:false,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'left',width:'20%' }},
                style:{textAlign:'left', width:'20%' }
            },
            {
                dataField: 'branch',
                text: 'Branch',
                editable:false,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'left',width:'20%' }},
                style:{textAlign:'left', width:'20%' }
            },
        ]
        const selectRow = {
            mode: 'checkbox',
            hideSelectAll: true,
            clickToSelectAndEditCell: true,
            onSelect: (row, isSelect, rowIndex, e) => {
                
            }
        };
        return(
            <div>
                <Banner />
                <Container className="mt-5" fluid>
                    <Card>
                        <Card.Header>REPORTS >> HR-ADMIN >> CLIENT AREA</Card.Header>
                        <Card.Body>
                            <Form>
                                <Alert isOpen={this.state.isshow} color={this.state.color} fade={this.state.fade} className={this.state.isshow ? 'display-block' : 'display-none'}>
                                    <div className={this.state.color}></div> 
                                    {this.state.message}
                                </Alert>
                                <Form.Group as={Row} controlId="formPlaintextEmail">
                                    <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                        CLIENT
                                    </Form.Label>
                                    <Col sm="11">
                                        <Typeahead
                                            labelKey='name'
                                            id="basic-example"
                                            onChange={this.handleChangedClient}
                                            options={this.state.clientDDL}
                                        />
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} controlId="formPlaintextEmail">
                                    <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                        BRANCH
                                    </Form.Label>
                                    <Col sm="11">
                                        <Typeahead
                                            labelKey='name'
                                            id="basic-example"
                                            onChange={this.onChangeBranch}
                                            options={this.state.branchDDL}
                                        />
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} controlId="formPlaintextEmail">
                                    <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                        REGION
                                    </Form.Label>
                                    <Col sm="11">
                                        <Typeahead
                                            labelKey='name'
                                            id="basic-example"
                                            onChange={this.handleChangedRegion}
                                            options={this.state.regionDDL}
                                        />
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} controlId="formPlaintextEmail">
                                    <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                        PROVINCE
                                    </Form.Label>
                                    <Col sm="11">
                                        <Typeahead
                                            labelKey='name'
                                            id="basic-example"
                                            onChange={this.handleChangedProvince}
                                            options={this.state.provinceDDL}
                                        />
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} controlId="formPlaintextEmail">
                                    <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                        CITY
                                    </Form.Label>
                                    <Col sm="11">
                                        <Typeahead
                                            labelKey='name'
                                            id="basic-example"
                                            onChange={this.handleChangedCity}
                                            options={this.state.cityDDL}
                                        />
                                    </Col>
                                </Form.Group>
                                <ButtonToolbar sm={12}>
                                    <Button variant="success" className="ml-auto noser-button" onClick = { this.handleSearchClick }>
                                        SEARCH
                                    </Button>
                                </ButtonToolbar>
                                <div>
                                    <ToolkitProvider
                                        keyField="id"   
                                        data={ this.state.DG }
                                        columns = { columns }
                                        exportCSV={ {
                                            noAutoBOM: false,blobType: "data:text/csv;charset=utf-8,%EF%BB%BF",fileName: "ClientAreaList.csv",
                                        } }
                                        >
                                        {
                                            props => (
                                            <div>
                                                
                                                <hr />
                                                <ExportCSVButton disabled={this.state.DG.length>0 ? false : true} className="btn btn-success" { ...props.csvProps }>Export CSV!!</ExportCSVButton>
                                                <Card.Header>CLIENT AREA LIST</Card.Header>
                                                <BootstrapTable
                                                    { ...props.baseProps } 
                                                    striped
                                                    hover
                                                    condensed
                                                    pagination={ paginationFactory({sizePerPageRenderer})}
                                                    keyField = "id"
                                                    data = { this.state.DG }
                                                    noDataIndication={ () => <div>No record found.</div> }
                                                    columns = { columns }
                                                    rowClasses="noser-table-row-class no-checkbox"
                                                    selectRow={selectRow}
                                                />
                                            </div>
                                            )
                                        }
                                    </ToolkitProvider>
                                </div>
                            </Form>
                        </Card.Body>
                    </Card>
                </Container>  
                <NoserLoading show={this.state.isloading} />
            </div>
        )
    }

}

export  default ClientArea