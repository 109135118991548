import {
    React,Component,BootstrapTable,
    paginationFactory,Button, ButtonToolbar, Card, 
    Form, Col, Row, axios, Container, Banner, Typeahead, TimePicker,
    cellEditFactory, Type, moment, NavLink, Alert, AppConfiguration,
    NoserLoading, Noser, sizePerPageRenderer, DatePicker
} 
from '../../noser-hris-component';
import ToolkitProvider, { CSVExport } from 'react-bootstrap-table2-toolkit';
import CreatePayrollPosition from './Modal/CreatePositionRates';
import EditPositionRates from './Modal/EditPositionRates';

class PositionRates extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fade            :   true,
            color           :   "",
            isshow          :   false,
            message         :   "",
            userinfo        :   [],
            isloading       :   false,
            alerttype       :   "",

            dataGrid    : [],
            clientDDL   : [],
            clientId    :   "",
            branchDDL   : [],
            branchId    : "",
            areaDDL     : [],
            areaName    : "",
            minRateDDL  : [],
            minRate     : "",
            arrList     : [],
            postionDDL   :   [],
            positionId    :   "",
            clientDGDDL     :   [],
            positonDGDDL: [],
            
            disableSaveBtn : true,
        }
        this.state.userinfo = JSON.parse(sessionStorage.getItem("userData"))
        this.GetClients();
        this.GetPosition();
        this.GetData();
    }
    
    componentDidMount(){
    }
    handleModalShow = (e) =>{
        this.setState({openModal:true})
        let obj = {}
        this.child.initialize(obj)
    }
    handleModalClose = (e) =>{
        this.setState({openModal:false})
        this.GetData();
    }
    GetClients = async() => {
        this.setState({isloading:true})
        const params = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.userinfo.clientId,
            "UserId":this.state.userinfo.userId,
            "EmployeeId":this.state.userinfo.roleId==="1" || 
                         this.state.userinfo.roleId==="8" ? "" : this.state.userinfo.employeeId
        }
        await axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Client/GetClientList", params)
        .then(res => {
            const data = res.data.clients;
            //console.log("GetClientList")
            //console.log(data)
            let arr = []
            
            if(this.state.userinfo.roleId!=="1"){
                let fieldaccess = JSON.parse(sessionStorage.getItem("userControlAccess"))
                if(fieldaccess.filter(x=>x.accessCode==="0001").length>0){
                    let access = fieldaccess.find(x=>x.accessCode==="0001" && x.controlAccessDetails.length>0)
                    if(access.controlAccessDetails.filter(x=>x.isClient==="1").length>0){
                        let brr = access.controlAccessDetails.filter(x=>x.isClient==="1")
                        arr = data.filter(f => brr.some(item => item.referenceId === f.id))
                    }
                }
                this.setState({clientDDL:arr,isloading:false})
                for (let i = 0; i < data.length; i++) {
                    const client = {
                        value : data[i]["id"],
                        label : data[i]["name"],
                    };
                    this.state.clientDGDDL.push(client);
                }
            }
            else{
                this.setState({clientDDL:data,isloading:false})
                for (let i = 0; i < data.length; i++) {
                    const client = {
                        value : data[i]["id"],
                        label : data[i]["name"],
                    };
                    this.state.clientDGDDL.push(client);
                }
            }
        })
        .catch(error=>{
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade        :   true
            })
        })
    }
    handleChangedClient = (e) => {
        this.setState({isloading:false,alerttype:'',isshow:false,color:'',message:'',fade:true})
        if(e.length == 0) {
            this.state.clientId=""
            this.state.clientName=""
            return
        } 
        this.state.clientId = e[0].id
        this.state.clientName= e[0].name
        //this.GetMinimumRate();
        
    }

    GetPosition = () => {
        const params = {
            "IpAddress"     :   "0.0.0.0",
            "ClientId"      :   this.state.userinfo.clientId,
            "UserId"        :   this.state.userinfo.userId,
            "SectionId"     :   "",
            "DepartmentId"  :   "",
            "Name"          :   ""
        };

        axios.post(AppConfiguration.Setting().noserapiendpoint + "Maintenance/GetPositions",  params)
        .then(res => {
            const data = res.data.positions;
            //console.log("Get positions");
            //console.log(data);
            for (let i = 0; i < data.length; i++) {
                const pos = {
                    value : data[i]["id"],
                    label : data[i]["name"],
                };
                this.state.positonDGDDL.push(pos);
            }

            this.setState({ postionDDL : data });
         })
    }
    handleChangedPosition = (e) => {
        this.setState({isloading:false,alerttype:'',isshow:false,color:'',message:'',fade:true})
        if(e.length == 0) {
            this.state.positionId=""
            return
        } 
        this.state.positionId = e[0].id
        this.state.position = e[0].name
    }
    GetData = async() => {
        this.setState({isloading:true,alerttype:'',isshow:false,color:'',message:'',fade:true})
        const param = {
            "IpAddress"     :   "0.0.0.0",
            "UserId"        :   this.state.userinfo.userId,
            "ClientId"      :   "",
            "PositionId"    :   "",
            "Name"          :   "",
            "Rates"         :   "",
        };
        await axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Maintenance/GetPayrollPositionRates",  param)
        .then(res => {
            const data = res.data;
            console.log("GetPayrollPositionRates")
            console.log(data)
            this.setState({dataGrid:data.payrollPosition,isloading:false});
            if(data.status==="0"){
                this.setState(  {
                    isloading       :   false,
                    alerttype       :   "Error!",
                    isshow          :   true,
                    color           :   "danger",
                    message         :   data.message,
                    fade            :   true
                })
            }
        })
        .catch(error=>{
            this.setState(  {
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade            :   true
            })
        })
    }
    GetMinimumRate = async() => {
        this.setState({isloading:true,alerttype:'',isshow:false,color:'',message:'',fade:true})
        const param = {
            "IpAddress"     :   "0.0.0.0",
            "UserId"        :   this.state.userinfo.userId,
            "ClientId"      :   this.state.clientId,
            "PositionId"    :   this.state.positionId,
            "Name"          :   this.state.position,
            "Rates"         :   "",
        };
        await axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Maintenance/GetPayrollPositionRates",  param)
        .then(res => {
            const data = res.data;
            console.log(data)
            this.setState({minRateDDL:data.payrollPosition,isloading:false});
            if(data.status==="0"){
                this.setState(  {
                    isloading       :   false,
                    alerttype       :   "Error!",
                    isshow          :   true,
                    color           :   "danger",
                    message         :   data.message,
                    fade            :   true
                })
            }
        })
        .catch(error=>{
            this.setState(  {
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade            :   true
            })
        })
    }
    handleChangedMinRate = (e) => {
        this.setState({isloading:false,alerttype:'',isshow:false,color:'',message:'',fade:true})
        if(e.length == 0) {
            this.state.minRate=""
            return
        } 
        this.state.minRate = e[0].rates
    }
    handleSearchClick = async() =>{
        this.setState({isloading:true,alerttype:'',isshow:false,color:'',message:'',fade:true})
        const param = {
            "IpAddress"     :   "0.0.0.0",
            "UserId"        :   this.state.userinfo.userId,
            "ClientId"      :   this.state.clientId,
            "PsotionIdId"    :   this.state.positionId,
            "Name"          :   this.state.position,
            "Rates"         :   this.state.minRate,
        };
        console.log(param)
        console.log("param")
        await axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Maintenance/GetPayrollPositionRates",  param)
        .then(res => {
            const data = res.data;
            console.log(data)
            this.setState({dataGrid:data.payrollPosition,isloading:false});
            if(data.status==="0"){
                this.setState(  {
                    isloading       :   false,
                    alerttype       :   "Error!",
                    isshow          :   true,
                    color           :   "danger",
                    message         :   data.message,
                    fade            :   true
                })
            }
        })
        .catch(error=>{
            this.setState(  {
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade            :   true
            })
        })
    }

    handleSaveClick = event => {
        const sleep = (milliseconds) => {return new Promise(resolve => setTimeout(resolve, milliseconds))}
        this.setState({
            arrList     : [],
            isloading   : true,
            disableSaveBtn : true
        })

        for (let i = 0; i < this.state.dataGrid.length; i++) {
            if (this.state.dataGrid[i]["isModified"] == 1) {
                const obj = {
                    "Id"        :   this.state.dataGrid[i]["id"],
                    "ClientId"  :   this.state.dataGrid[i]["clientId"],
                    "PositionId":   this.state.dataGrid[i]["positionId"],
                    "Name"      :   this.state.dataGrid[i]["name"],
                    "Rates"     :   this.state.dataGrid[i]["rates"],
                    "ModifiedBy":   this.state.userinfo.userId,
                    "IsDeleted" :   this.state.dataGrid[i]["isDeleted"].toString()
                };

                this.state.arrList.push(obj);
            }
        }
        console.log(this.state.arrList)
        const editPositionParams = {
            "IpAddress"     :   "0.0.0.0",
            "ClientId"      :   this.state.userinfo.clientId,
            "UserId"        :   this.state.userinfo.userId,
            "payrollPosition":   this.state.arrList
        };

        console.log("Submit Edit Params")
        console.log(editPositionParams)

        axios
            .post(AppConfiguration.Setting().noserapiendpoint + "Maintenance/EditPayrollPositionRates", editPositionParams)
            .then(res => {
                const data = res.data;
                sleep(5000).then(() => {
                    this.setState({
                        isloading   :   false
                    })
                })
                this.GetData();
                if(data.status=="1"){
                    this.setState({
                        isloading   :   false,
                        alerttype   :   "Success!",
                        isshow      :   true,
                        color       :   "success",
                        message     :   data.message,
                        fade        :   true
                    });
                }
                else{
                    this.setState({
                        isloading   :   false,
                        alerttype   :   "Error!",
                        isshow      :   true,
                        color       :   "danger",
                        message     :   data.message,
                        fade        :   true
                    })
                }
             })
             .catch(error=>{
                this.setState(  {
                    isloading   :   false,
                    alerttype   :   "Error!",
                    isshow      :   true,
                    color       :   "danger",
                    message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                    fade        :   true
                })
            })
    }

    GridDataModified(oldValue, newValue, id, column) {
        let disable = true
        this.state.dataGrid.map(function(item,i) {
            if (item.id===id){
                item.isModified = newValue!=oldValue ? "1" : "0"
                disable = newValue!=oldValue ? false : true
            }
        })
        this.setState({disableSaveBtn:disable})
    }
    handleClickedEdit =  (row) => {
        this.setState({openModalEdit:true})
        //let e = {}
        this.childEdit.initialize(row)
    }
    handleModalCloseEdit = (e) =>{
        this.setState({openModalEdit:false})
        this.GetData();
    }
    render(){
        const columns = [
            {
                dataField: 'clientName',
                text: 'Client',
                editable    : false,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',whiteSpace:'nowrap',width:"35%"}},
                style:{textAlign:'left',whiteSpace:'nowrap',width:"35%"},
                sort:true,
            },
            {
                dataField: 'name',
                text: 'Position',
                editable    : false,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',whiteSpace:'nowrap',width:"35%"}},
                style:{textAlign:'left',whiteSpace:'nowrap',width:"35%"},
            },
            {
                dataField: 'rates',
                text: 'Min Rates',
                editable    : false,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'left',whiteSpace:'nowrap',width:"10%"}},
                style:{textAlign:'left',whiteSpace:'nowrap',width:"10%"},
                align: (cell, row, rowIndex, colIndex) => {
                   return 'right';
                }
            },
            {
                dataField: 'mandatoryAmount',
                text: 'Manda Fixed Amount',
                editable    : false,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'left',whiteSpace:'nowrap',width:"10%"}},
                style:{textAlign:'left',whiteSpace:'nowrap',width:"10%"},
                align: (cell, row, rowIndex, colIndex) => {
                   return 'right';
                }
            },
            {
                dataField: 'action',
                text: 'ACTION',
                headerStyle: () => {
                    return { width: "10%" };
                },
                editable:false,
                formatter   :   (cell, row, isSelect) => {
                    return (
                        <Button style={{height:'18px',paddingTop:'0',marginTop:'-4px',fontSize:'11px'}} 
                            variant="link" onClick={e => this.handleClickedEdit(row)}
                        >Edit</Button>
                        );
                },
            } 
        ]
        const selectRow = {
            mode: 'checkbox',
            clickToSelectAndEditCell: true,
            onSelect: (row, isSelect, rowIndex, e) => {
                let isDisable = true
                this.state.dataGrid.map(function(item,i){
                    if(item.id===row.id)
                    {
                        item.isDeleted = isSelect ? "1" : "0"
                        item.isModified = isSelect ? "1" : "0"
                    }
                    if(item.isDeleted=="1")
                        isDisable=false
                })
                this.setState({disableSaveBtn:isDisable})
            }
        };

        const rowEvents = {
            onClick: (e, row, rowIndex) => {
            }
        };
        return(
            <div>
                <Banner />
                <Container className="mt-5" fluid>
                    <Card>
                        <Card.Header>MAINTENANCE >> PAYROLL >> POSITION RATES</Card.Header>
                        <Card.Body>
                            <Form>
                                <Alert isOpen={this.state.isshow} color={this.state.color} fade={this.state.fade} className={this.state.isshow ? 'display-block' : 'display-none'}>
                                    <div className={this.state.color}></div> 
                                    {this.state.message}
                                </Alert>
                                <Form.Group as={Row} controlId="formPlaintextEmail">
                                    <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                        CLIENT
                                    </Form.Label>
                                    <Col>
                                        <Typeahead
                                            labelKey='name'
                                            id="basic-example"
                                            onChange={this.handleChangedClient}
                                            options={this.state.clientDDL}
                                        />
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} controlId="formPlaintextEmail">
                                    <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                    POSITION
                                    </Form.Label>
                                    <Col>
                                        <Typeahead
                                            labelKey='name'
                                            id="basic-example"
                                            onChange={this.handleChangedPosition}
                                            options={this.state.postionDDL}
                                        />
                                    </Col>
                                </Form.Group>
                                {/* <Form.Group as={Row} controlId="formPlaintextEmail">
                                    <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                        RATE
                                    </Form.Label>
                                    <Col>
                                        <Typeahead
                                            labelKey='rates'
                                            id="basic-example"
                                            onChange={this.handleChangedMinRate}
                                            options={this.state.minRateDDL}
                                        />
                                    </Col>
                                </Form.Group> */}
                                <ButtonToolbar sm={12}>
                                    <Button variant="success" className="ml-auto noser-button" onClick = { this.handleSearchClick }>
                                        SEARCH
                                    </Button>
                                </ButtonToolbar>
                                <div className="mt-3">
                                    <Card.Header>LISTS</Card.Header>
                                    <BootstrapTable
                                        keyField = "id"
                                        data = { this.state.dataGrid }
                                        columns = { columns }
                                        pagination={ paginationFactory({sizePerPageRenderer}) }
                                        noDataIndication={ () => <div>No record found.</div> }
                                        rowClasses="noser-table-row-class"
                                        striped
                                        hover
                                        condensed
                                        cellEdit = { cellEditFactory({
                                            mode: 'dbclick',
                                            blurToSave: true,
                                            afterSaveCell: (oldValue, newValue, row, column) => {
                                                this.GridDataModified(oldValue, newValue, row.id, column.dataField)
                                                }
                                            })
                                        }
                                        rowEvents={ rowEvents }
                                        selectRow = { selectRow }
                                    />
                                </div>
                                <div className="mt-1">
                                    <ButtonToolbar>
                                        <Button className="ml-auto noser-button-mr1" variant="danger" onClick={this.handleSaveClick} disabled={this.state.disableSaveBtn}>Delete</Button>
                                        <Button className="noser-button" variant="primary" onClick={this.handleModalShow}>Create</Button>
                                    </ButtonToolbar>
                                </div>
                            </Form>
                        </Card.Body>
                    </Card>
                </Container>  
                <NoserLoading show={this.state.isloading} />
                <CreatePayrollPosition 
                    show={this.state.openModal}
                    onHide={this.handleModalClose}
                    onRefModal={ref => (this.child = ref)}
                />
                <EditPositionRates 
                    show={this.state.openModalEdit}
                    onHide={this.handleModalCloseEdit}
                    onRefModal={ref => (this.childEdit = ref)}
                />
            </div>
        )
    }

}

export  default PositionRates