import {
    React,Component,BootstrapTable, Type,
    paginationFactory,Button, ButtonToolbar, Card,
    Form, Col, Row, Container, axios, Typeahead, Banner, TimePicker,
    cellEditFactory ,moment, NavLink, Alert, AppConfiguration,
    NoserLoading, Noser, sizePerPageRenderer, DatePicker
} 
from '../../../noser-hris-component';
import type from 'react-bootstrap-table2-editor';

import ToolkitProvider, { CSVExport } from 'react-bootstrap-table2-toolkit';
import 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css';

class EmployeeDeductionReport extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userinfo        :   [],
            isloading       :   false,
            isshow          :   false,
            alerttype       :   "",
            message         :   "",
            color           :   "",
            fade            :   true,

            clientDDL : [],
            employeeDDL : [],
            deductionDDL : [],
            dicList:[],
            dynamicledgerPlaceholder : "",
            dataGrid : [],
            selectedEmployeeId : "",
            selectedResultId : "",

        }
    }

    componentDidMount(){
        const sleep = (milliseconds) => {return new Promise(resolve => setTimeout(resolve, milliseconds))}
        this.state.userinfo = JSON.parse(sessionStorage.getItem("userData"))
        this.GetClients();
        this.getLedgerType();
    }

    GetClients = async() => {
        this.setState({isloading:true})
        const param = {
            "IpAddress"     :   "0.0.0.0",
            "ClientId"      :   this.state.userinfo.clientId,
            "UserId"        :   this.state.userinfo.userId,
        };
        await axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Client/GetClientList",  param)
        .then(res => {
            const data = res.data;
            this.setState({clientDDL:data.clients,isloading:false});
        })
        .catch(error=>{
            this.setState(  {
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade            :   true
            })
        })
    }

    onChangeClient = (e) => {
        if(e.length == 0) {
            this.state.selectedClientName=""
            this.state.selectedClientId=""
            return
        } 
        this.state.selectedClientId = e[0].id
        this.state.selectedClientName = e[0].name

        this.getEmployees();
    }

    getEmployees = async() => {
        this.setState({isloading:true})
        const getParams = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.selectedClientId,
            "UserId":this.state.userinfo.userId,
            "EmployeeName":"",
            "EmployeeNo":"",
            "ClientName":this.state.selectedClientName
        }
        await axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Employee/GetEmployees", getParams)
        .then(res => {
            console.log("Employee List ");
            console.log(res.data);
            const data = res.data
            this.setState({employeeDDL : data.employees, isloading:false,}) 
        })
        .catch(error=>{
           this.setState(  {
               isloading       :   false,
               alerttype       :   "Error!",
               isshow          :   true,
               color           :   "danger",
               message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
               fade            :   true
           })
       })
        
    }

    onChangeEmployees = (e) => {
        if(e.length == 0) {
            this.state.selectedEmployeeId=""
            return
        } 
        this.state.selectedEmployeeId = e[0].id
    }

    getLedgerType = async() => {
        const typeParams = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.userinfo.clientId,
            "UserId":this.state.userinfo.userId,
            "TypeId":"", 
	        "Name":""
        }
        await axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Maintenance/GetDeductions", typeParams)
        .then(res => {
            const data = res.data;
            console.log("deductions")
            console.log(data)
            this.setState({deductionDDL : data.deductions})
        })
    }

    onChangeReference = (e) => {
        if(e.length == 0) {
            this.state.selectedResultId = ""
            return
        } 
        this.state.selectedResultId = e[0].id
        this.setState({
            isshow : false,
        });
        this.getDeductions();

    }

    getDeductions = async() => {
        this.setState({isloading:true})
        const getParams = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.userinfo.clientId,
            "UserId":this.state.userinfo.userId,
        };
        await axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Maintenance/GetDeductions",  getParams)
        .then(res => {
            const data = res.data;
            this.setState({isloading   :   false, dicList: data.deductions ,dynamicledgerPlaceholder: "Select Employee Deductions" }); 
        })
        .catch(error=>{
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade        :   true
            })
        })
    }

    handleSearchClick = () => {
        this.setState({isloading:true})
        const typeParams = {
            "IpAddress" : "0.0.0.0",
            "UserId"    : this.state.userinfo.userId,
            "ClientId"  : this.state.selectedClientId,
            "LocationId": "",
            "EmployeeId": this.state.selectedEmployeeId ,
            "LoanTypeId": this.state.selectedResultId,
        };
        console.log("Submit Search Params")
        console.log(typeParams);
        axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Report/GetEmployeeDeductions", typeParams)
        .then(res => {
            const data = res.data;
            console.log(data)
            this.setState({
                dataGrid    : data.employeeDeduction,
                isloading   : false,
            });
            if(data.employeeDeduction.length=="0"){
                this.setState({
                    isloading   :   false,
                    alerttype   :   "Error!",
                    isshow      :   true,
                    color       :   "danger",
                    message     :   data.message,
                    fade        :   true
                });
            }
        })
        .catch(error=>{
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade        :   true
            })
        })
    }

    render() {
        const { ExportCSVButton } = CSVExport;

        const employeeDeductionReportColumn = [
            {
                text	    : 'EMPLOYEE_NUMBER',
                editable    :   false,
                dataField   :   "employeeNo",
                /* headerStyle : () => {
                    return { width  : "15%" };
                } */
            },
            {
                text	    : 'CLIENT',
                editable    :   false,
                dataField   :   "clientName",
                /* headerStyle : () => {
                    return { width  : "15%" };
                } */
            },
            {
                text	    : 'EMPLOYEE_NAME',
                editable    :   false,
                dataField   :   "employeeName",
                /* headerStyle : () => {
                    return { width  : "15%" };
                } */
            },
            {
                text	    : 'BRANCH',
                editable    :   false,
                dataField   :   "location",
                /* headerStyle : () => {
                    return { width  : "15%" };
                } */
            },
            {
                text	    : 'LOAN_TYPE',
                editable    :   false,
                dataField   :   "loanType",
                /* headerStyle : () => {
                    return { width  : "15%" };
                } */
            },
            {
                text	    : 'DATE_LOAN',
                editable    :   false,
                dataField   :   "loanDate",
                /* headerStyle : () => {
                    return { width  : "15%" };
                } */
            },
            {
                text	    : 'BEGINNING_BALANCE',
                editable    :   false,
                dataField   :   "beginningBalance",
                /* headerStyle : () => {
                    return { width  : "15%" };
                } */
            },
            {
                text	    : 'AMORTIZATION',
                editable    :   false,
                dataField   :   "amortization",
                /* headerStyle : () => {
                    return { width  : "15%" };
                } */
            },
            {
                text	    : 'AMOUNT_DEDUCTED',
                editable    :   false,
                dataField   :   "amountDebited",
                /* headerStyle : () => {
                    return { width  : "15%" };
                } */
            },
            {
                text	    : 'BALANCE',
                editable    :   false,
                dataField   :   "balance",
                /* headerStyle : () => {
                    return { width  : "15%" };
                } */
            },
        ] 

            const employeeDeductionReportSelectRow = {
                mode: 'checkbox',
                clickToSelectAndEditCell: true,
                onSelect: (row, isSelect, rowIndex, e) => {
                    this.state.dataGrid.map(function(item,i){
                        if(item.id===row.id)
                        {
                            item.isDeleted = isSelect ? "1" : "0"
                            item.isModified = isSelect ? "1" : "0"
                        }
                    })
                }
            };
    
            const employeeDeductionReportRowEvents = {
                onClick: (e, row, rowIndex) => {
                }
            };

        return(
            <div>
            <Banner />
                <Container className="themed-container" fluid={true}>
                    <Card className="mt-5">
                        <Card.Header>REPORT >> PAYROLL - EMPLOYEE DEDUCTION REPORT</Card.Header>
                        <Card.Body>
                            <Form >
                                <Alert isOpen={this.state.isshow} color={this.state.color} fade={this.state.fade} className={this.state.isshow ? 'display-block' : 'display-none'}>
                                    <div className={this.state.color}></div> 
                                    {this.state.message}
                                </Alert>
                                <Form.Group as={Row} controlId="formPlaintextEmail">
                                    <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                        CLIENT
                                    </Form.Label>
                                    <Col sm="11">
                                        <Typeahead
                                            labelKey='name'
                                            id="basic-example"
                                            onChange={this.onChangeClient}
                                            options={this.state.clientDDL}
                                        />
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} controlId="formPlaintextEmail">
                                    <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                        EMPLOYEE
                                    </Form.Label>
                                    <Col sm="11">
                                        <Typeahead
                                            labelKey='employeeName'
                                            id="basic-example"
                                            onChange={this.onChangeEmployees}
                                            options={this.state.employeeDDL}
                                    />
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} controlId="formPlaintextEmail">
                                    <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                        DEDUCTION
                                    </Form.Label>
                                    <Col>
                                        <Typeahead
                                            labelKey='name'
                                            id="basic-example"
                                            onChange={this.onChangeReference }
                                            options={this.state.deductionDDL}
                                        /> 
                                    </Col>
                                    {/* <Col sm="5">
                                        <Typeahead
                                            labelKey='name'
                                            id="basic-example"
                                            onChange={this.onChangeResults}
                                            options={this.state.dicList}
                                            placeholder={this.state.dynamicledgerPlaceholder}
                                        />
                                    </Col> */}
                                </Form.Group> 

                                <ButtonToolbar sm={12}>
                                    <Button variant="success" className="ml-auto" onClick = { this.handleSearchClick }>
                                        SEARCH
                                    </Button>
                                </ButtonToolbar>
                                <div className="mt-5">   
                                    <ToolkitProvider
                                        keyField="id"   
                                        data={ this.state.dataGrid }
                                        columns={ employeeDeductionReportColumn }
                                        exportCSV={ {
                                            fileName: "Payroll Employee Loan Reports.csv",
                                        } }
                                        >
                                        {
                                            props => (
                                            <div>
                                            <Card.Header>Payroll Employee Deduction Record</Card.Header>
                                            <BootstrapTable
                                                /* caption={Noser.TableHeader({title:"RECORD"})} */
                                                keyField = "id"
                                                data = { this.state.dataGrid }
                                                columns = { employeeDeductionReportColumn }
                                                //pagination={ paginationFactory({sizePerPageRenderer}) }
                                                noDataIndication={ () => <div style={{width:'100%'}}>No record found.</div> }
                                                rowClasses="noser-table-row-class no-checkbox"
                                                striped
                                                hover
                                                condensed
                                                cellEdit = { cellEditFactory({
                                                    mode: 'dbclick',
                                                    blurToSave: true,
                                                    afterSaveCell: (oldValue, newValue, row, column) => {
                                                        //this.GridDataModified(oldValue, newValue, row.id, column.dataField)
                                                        }
                                                    })
                                                }
                                                selectRow = { employeeDeductionReportSelectRow }
                                                rowEvents={ employeeDeductionReportRowEvents }
                        
                                            />
                                            <ButtonToolbar>
                                                <ExportCSVButton className="btn btn-info ml-auto" { ...props.csvProps }>Export</ExportCSVButton>
                                            </ButtonToolbar>
                                            </div>
                                            )
                                        }
                                    </ToolkitProvider>
                                </div>
                            </Form>
                        </Card.Body>
                    </Card>
                </Container>
                <NoserLoading show={this.state.isloading} />
            </div> 
            
        )
    }
}

export default EmployeeDeductionReport;