import {
    React,Component,BootstrapTable,
    paginationFactory,Button, ButtonToolbar, Card, 
    Form, Col, Row, axios, Container, Banner, Typeahead, TimePicker,
    cellEditFactory, Type, moment, NavLink, Alert, AppConfiguration,
    NoserLoading, Noser, sizePerPageRenderer, DatePicker, Redirect, Tabs, Tab
} 
from '../../noser-hris-component';
import { DropdownButton, Dropdown} from 'react-bootstrap';

import PayCardAMT from './PayCardAMT';

// START CLYDE FINANCE MODAL
import  AdminModal  from './Modal/AdminModal';
import  FinanceModal  from './Modal/FinanceModal';

class PayCardMonitoring extends Component {
    constructor(props) {
        super(props);
        this.ref = React.createRef();
        this.state = {
            userinfo        :   [],
            isloading       :   false,
            isshow          :   false,
            alerttype       :   "",
            message         :   "",
            color           :   "",
            fade            :   true,      

            selectedEmployeeId :"",
            clientDDL : [],
            clientId : "",
            employeeDDL : [],
            referenceNo : "",
            applicationFormNo : "",
            payCardApplicationList : [],
            statusDDLIST : [
                {name: 'On Process', value: "1",},
                {name: 'Cancelled',  value: '2',},
                {name: 'Completed',  value: '3',},
            ],
            status    : "",
            statusId  : "",
            dataList :[],
            navigateViewPage:false,

            // FINANCE
            modalShow : false,
            modalShowAdmin : false,
            onProcessedDDG : [],
            cancelledDDG : [],
            completedDDG : [],
            // attentionDDL : [
            //     {"name" :"AMT"},
            //     {"name" :"ADMIN-SERVICES"},
            //     {"name" :"FINANCE"},
            //     {"name" :"ADMIN-RMT"},
            // ],
            attentionName : "",
            arrayList : [],
            attentionDDL : [],
            regectedDDG : [],
            finalArr : []
        }
        this.state.userinfo = JSON.parse(sessionStorage.getItem("userData"))
        this.state.userControlAccess = JSON.parse(sessionStorage.getItem("userControlAccess"))
        for(let x = 0; x < this.state.userControlAccess.length; x++){
            ////////////console.log(this.state.userControlAccess[x])
            this.state.controlAccessDetails = this.state.userControlAccess[x]
        }

    }

    componentDidMount(){
        const sleep = (milliseconds) => {return new Promise(resolve => setTimeout(resolve, milliseconds))}
        //this.getStatus()
        this.getClient()
         
        //this.GetApplicationList()
        
        // this.PayCardAMT()
    }

    handleModalShow = (row) =>{
        if(this.state.userinfo.roleId === "26"){
            if(row.attention === "AMT"){
                this.setState({openModalamt:true})
                this.child.initialize(row)
            }
        }
        if(this.state.userinfo.roleId === "4"){
            if(row.attention === "AMT"){
                this.setState({openModalamt:true})
                this.child.initialize(row)
            }
        }
        if(this.state.userinfo.roleId === "8"){
            if(row.attention === "ADMIN-SERVICES"){
                this.setState({modalShowAdmin:true})
                this.childAdmin.initialize(row)
            }
            if(row.attention === "ADMIN-RMT"){
                this.setState({navigate: true})
            }
        }
        if(this.state.userinfo.roleId === "2"){
            if(row.attention === "ADMIN-SERVICES"){
                this.setState({modalShowAdmin:true})
                this.childAdmin.initialize(row)
            }
        }
        if(this.state.userinfo.roleId === "32"){
            if(row.attention === "FINANCE"){
                this.setState({modalShow:true})
                this.childFinance.initialize(row)
            }
        }
       
    }
    // handleModalShowAdmin = (row) =>{
    //     this.setState({openModaladmin:true})
    //     this.child.initialize(row)
       
    // }
    handleModalCloseAMT = (row) =>{ 
        sessionStorage.setItem("paycardviepage_" + this.state.userinfo.userId + this.state.userinfo.employeeId, JSON.stringify(row))
        this.setState({openModal:false,navigateViewPage:true})
        
    }



    getClient = async() =>{
        this.setState({isloading:true})
        const params = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.userinfo.clientId,
            "UserId":this.state.userinfo.userId,
            "EmployeeId":this.state.userinfo.roleId==="1" || 
                         this.state.userinfo.roleId==="8" ? "" : this.state.userinfo.employeeId
        }
        //////console.log("params")
        //////console.log(params)
        await axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Client/GetClientList", params)
        //.post(AppConfiguration.Setting().noserapiendpoint + "Client/GetClientPerAccess", params)
        .then(res => {
            const data = res.data;
            let arr = []
            if(this.state.userinfo.roleId!=="1"){
                let fieldaccess = JSON.parse(sessionStorage.getItem("userControlAccess"))
                if(fieldaccess.filter(x=>x.accessCode==="0001").length>0){
                    let access = fieldaccess.find(x=>x.accessCode==="0001" && x.controlAccessDetails.length>0)
                    //////////////console.log("access")
                    //////////////console.log(access)
                    if(access.controlAccessDetails.filter(x=>x.isClient==="1").length>0){
                        let brr = access.controlAccessDetails.filter(x=>x.isClient==="1")
                        arr = data.clients.filter(f => brr.some(item => item.referenceId === f.id))
                    }
                }
                //for(let x = 0; x < arr.length; x++){
                    //this.state.clientIdAccess = arr
                    // this.setState({
                    //     clientIdAccess : arr[x]["id"]
                    // })
                //    this.GetApplicationList(arr);
                //}
                // arr.forEach(x=> {
                //     //////////////console.log("x.id")
                //     //////////////console.log(x.id)
                //     this.state.clientIdAccess = x.id
                // })
                this.setState({
                    clientDDL : arr,
                    //isloading   :   false
                })
                //////console.log("client arr")
                //////console.log(arr)
		this.GetApplicationList(arr);
            }
            else{
                this.setState({
                    clientDDL : data.clients,
                    //isloading   :   false
                })
		this.GetApplicationList(data.clients);
        //////console.log("data.clients")
        //////console.log(data.clients)
            }
        })
        .catch(error=>{
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade        :   true
            })
        })
   }

   handleChangeAttention = (e) => {
    if(e.length == 0) {
        this.state.attentionName   =   ""
        return
    }
    this.state.attentionName   =   e[0].name
    ////////////console.log(e[0].id)
    this.setState({
        isshow:false,
    })
}
   
    
   handleChangeClient = (e) => {
       if(e.length == 0) {
           this.state.clientId     =   ""
           this.state.clientName   =   ""
           return
       }
       this.state.clientId     =   e[0].id
       this.state.clientName   =   e[0].name
        this.setState({
            isshow:false,
        })
       this.getEmployees();
   }

   getStatus = async() => {
        const getParams = {
            "IpAddress"     :   "0.0.0.0",
            "ClientId"      :   this.state.userinfo.clientId,
            "UserId"        :   this.state.userinfo.userId,
            "Code"          :   "0031"
        };
        await axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Maintenance/GetDataReferences",  getParams)
        .then(res => {
            const data = res.data;
            ////////console.log("Get status");
            ////////console.log(data);
            this.setState({ statusDDL : data.dataReferences });
            this.getClient();
        })
    }


    getEmployees = () => {
        this.setState({isloading:true})
        const getParams = {
            "IpAddress"     :   "0.0.0.0",
            "ClientId"      :   this.state.clientId,
            "UserId"        :   this.state.userinfo.userId,
            "EmployeeName"  :   "",
            "EmployeeNo"    :   "",
            "ClientName"    :   ""
        }
        //console.log("getParams")
        //console.log(getParams)
        axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Employee/GetEmployees",  getParams)
        .then(res => {
            const data = res.data
            //console.log("GetEmployees")
            //console.log(data)
            this.setState({
                employeeDDL        :   data.employees,
                isloading           :   false,
            }) 
        })
        .catch(error=>{
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade        :   true
            })
        }) 
        
    }

    GetAttentionList = () =>{
        //////////////console.log("2")
        this.setState({isloading:true})

        const getParams = {
            "IpAddress":"0.0.0.0",
            "ClientId":"",
            "UserId":this.state.userinfo.userId,
            "EmployeeId" : "",
            "StatusId" : ""
        };
        //////////////console.log("3")
        axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Paycard/GetApplication",  getParams)
        //.post("http://104.248.57.90:8081/Paycard/GetApplication",  getParams)
            .then(res => {
            const data = res.data;
            //////////////console.log("data")
            //////////////console.log(data)
        })
    }

    handleChangeStatus =  (e) => {
        if(e.length == 0) {
            this.state.statusId     =   ""
            this.state.status   =   ""
            return
        }
        this.state.statusId     =   e[0].id
        this.state.status   =   e[0].name
       
    }

    handleChangeEmployee = (e) => {
        
        if(e.length == 0) {
            this.state.selectedEmployeeId    =   ""
            // this.state.profileId            =   ""
            // this.state.referenceNo          =   ""
            return
        }
        this.state.selectedEmployeeId      =   e[0].id
        // this.state.profileId            =   e[0].profileId
        // this.state.referenceNo          =   e[0].referenceNo
        this.setState({
            isshow:false,
        })
        //this.GetApplicationList();
    }

    GetApplicationList = async(arr) =>{
        //this.setState({isloading:true})
        ////////////console.log("this.state.clientIdAccess")
        ////////////console.log(arr)
        const getParams = {
            "IpAddress":"0.0.0.0",
            "ClientId":"",
            "UserId":this.state.userinfo.userId,
            "EmployeeId" : "",
            "StatusId" : ""
        };
        //////////////console.log("getParams")
        //////////////console.log(getParams)

        ////////console.log("Start GetApplication")
        ////////console.log(new Date())
        await axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Paycard/GetApplication",  getParams)
        //.post("http://104.248.57.90:8081/Paycard/GetApplication",  getParams)
            .then(res => {
                ////////console.log("done api request")
                ////////console.log(new Date())
                const data = res.data.applications
                const inactiveData = res.data.applications.filter(x => x.systemStatusId === "1")
                ////////console.log("End GetApplication")
                ////////console.log(new Date())
                //const data = res.data.applications;
                //console.log("GetApplication")
                //console.log(data)
                //this.setState({isloading   :   false})
            
            let dataList=[]
            data.forEach(itm=>{
                if(itm.statusId==="33"){
                    itm.effectivityDate = moment(itm.effectivityDate).format('MM/DD/YYYY')
                }
                else{
                    itm.effectivityDate = ""
                }

                if(itm.statusId==="12" && itm.reasonId==="3"){
                    itm.statusName = "UPDATE MANNING"
                }

                if(itm.statusId==="40"){
                    itm.statusName = "Receive Submitted Application"
                }
                itm.applicationDate=moment(itm.applicationDate).format('MM/DD/YYYY')
                itm.dateStart=moment(itm.dateStart).format('MM/DD/YYYY')
                itm.dateHired=moment(itm.dateHired).format('MM/DD/YYYY')
            })
            console.log("test")
            console.log(data.filter(f => arr.some(item => item.id === f.clientId && f.statusProcess === "ON-PROCESS" && f.systemStatusId === "1")))
            this.setState({
                onProcessedDDG: data.filter(f => arr.some(item => item.id === f.clientId && f.statusProcess === "ON-PROCESS" && f.systemStatusId === "0")),
                cancelledDDG: data.filter(f => arr.some(item => item.id === f.clientId && f.statusProcess === "CANCELLED" && f.systemStatusId === "0")).concat(inactiveData),
                cancelledDDG: data.filter(f => arr.some(item => item.id === f.clientId && f.statusProcess === "REJECTED" && f.systemStatusId === "0")).concat(inactiveData),
                regectedDDG: data.filter(f => arr.some(item => item.id === f.clientId && f.statusProcess === "PAYCARD REJECTED" && f.systemStatusId === "0")),
                completedDDG: data.filter(f => arr.some(item => item.id === f.clientId && f.statusProcess === "COMPLETED" && f.systemStatusId === "0")),

                
                isloading   :   false
            })
        })
        .catch(error=>{
            this.setState(
            {
                isloading:false,
                alertType:"Error!",
                show:true,
                color:"danger",
                message: "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade:true
            })
        })
        
        this.getEmployees();
    }    

    

    handleSearchClick = async() => {
        this.setState({
            isloading:true,
            finalArr : [],
            onProcessedDDG : [],
            cancelledDDG : [],
            completedDDG : [],
            regectedDDG : [],
            completedDDG : [],
        })
        // if(!this.state.clientId){
        //     this.setState({
        //         isloading   :   false,
        //         alerttype   :   "Error!",
        //         isshow      :   true,
        //         color       :   "danger",
        //         message     :   "Please select client!",
        //         fade        :   true
        //     })
        //     return
        // }
        const getParams ={
            "IpAddress": "0,0,0,0",
            "ClientId":this.state.clientId ,
            "UserId":this.state.userinfo.userId,
            "EmployeeId" : this.state.selectedEmployeeId ,
            "StatusId" : ""
        
        }
        //////////////console.log("3")
        await axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Paycard/GetApplication",  getParams)
        //.post("http://104.248.57.90:8081/Paycard/GetApplication",  getParams)
            .then(res => {
            const data = res.data;
            const dataFilter = res.data.applications.filter(itm => itm.attention === this.state.attentionName);
            //console.log("GetApplication")
            //console.log(data.applications)
            let newArr = []
            let dataList=[]
            if(this.state.attentionName === ""){
                dataList = []
                for(let x = 0; x < data.applications.length; x++){
                let effectivityDate = ""
                let statusName = ""
                if(data.applications[x]["statusId"] === "27"){
                    effectivityDate = moment(data.applications[x]["effectivityDate"]).format('MM/DD/YYYY')
                } else {
                    effectivityDate = ""
                }

                if(data.applications[x]["statusId"] === "12"){
                    if(data.applications[x]["reasonId"] === "3"){
                        statusName = "UPDATE MANNING"
                    } else {
                        statusName = data.applications[x]["statusName"]
                    }
                } else {
                    statusName = data.applications[x]["statusName"]
                }

                if(data.applications[x]["statusId"] === "40"){
                    statusName = "Receive Submitted Application"
                } else {
                    statusName = data.applications[x]["statusName"]
                }
                 
                const obj = {
                    id                      : data.applications[x]["id"],
                    applicationDate         : moment(data.applications[x]["applicationDate"]).format('MM/DD/YYYY'),
                    applicationdocuments    : data.applications[x]["applicationdocuments"],
                    applicationstatus       : data.applications[x]["applicationstatus"],
                    attention               : data.applications[x]["attention"],
                    banK_SLA                : data.applications[x]["banK_SLA"],
                    branchName              : data.applications[x]["branchName"],
                    cardValue               : data.applications[x]["cardValue"],
                    clientName              : data.applications[x]["clientName"],
                    createdBy               : data.applications[x]["createdBy"],
                    createdDate             : data.applications[x]["createdDate"],
                    dateStart               : moment(data.applications[x]["dateStart"]).format('MM/DD/YYYY'),
                    dateHired               : moment(data.applications[x]["dateHired"]).format('MM/DD/YYYY'),
                    effectivityDate         : effectivityDate,
                    description             : data.applications[x]["description"],
                    employeeId              : data.applications[x]["employeeId"],
                    employeeName            : data.applications[x]["employeeName"],
                    firstName               : data.applications[x]["firstName"],
                    lastName                : data.applications[x]["lastName"],
                    middleName              : data.applications[x]["middleName"],
                    suffixName              : data.applications[x]["suffixName"],
                    employeeNo              : data.applications[x]["employeeNo"],
                    referenceNo             : data.applications[x]["referenceNo"],
                    natureId                : data.applications[x]["natureId"],
                    natureName              : data.applications[x]["natureName"],
                    oldOayCardNumber        : data.applications[x]["oldOayCardNumber"],
                    payCardNumber           : data.applications[x]["payCardNumber"],
                    payCardType             : data.applications[x]["payCardType"],
                    payCardTypeId           : data.applications[x]["payCardTypeId"],
                    phR_SLA                 : data.applications[x]["phR_SLA"],
                    positionName            : data.applications[x]["positionName"],
                    profileId               : data.applications[x]["profileId"],
                    rEmarks                 : data.applications[x]["rEmarks"],
                    reasonId                : data.applications[x]["reasonId"],
                    reasonName              : data.applications[x]["reasonName"],
                    statusId                : data.applications[x]["statusId"],
                    isReApplication         : data.applications[x]["isReApplication"],
                    reApplication           : data.applications[x]["reApplication"],
                    statusName              : statusName,
                    statusProcess           : data.applications[x]["statusProcess"],
                    systemStatusId           : data.applications[x]["systemStatusId"],
                    systemStatus           : data.applications[x]["systemStatus"],
                }

              
                dataList.push(obj)
            }
            }else {
                dataList = []
                for(let i = 0; i < dataFilter.length; i++){
                    let effectivityDate = ""
                    let statusName = ""
                    if(dataFilter[i]["statusId"] === "27"){
                        effectivityDate = moment(dataFilter[i]["effectivityDate"]).format('MM/DD/YYYY')
                    } else {
                        effectivityDate = ""
                    }
    
                    if(dataFilter[i]["statusId"] === "12"){
                        if(dataFilter[i]["reasonId"] === "3"){
                            statusName = "UPDATE MANNING"
                        } else {
                            statusName = dataFilter[i]["statusName"]
                        }
                    } else {
                        statusName = dataFilter[i]["statusName"]
                    }
    
                    if(dataFilter[i]["statusId"] === "40"){
                        statusName = "Receive Submitted Application"
                    } else {
                        statusName = dataFilter[i]["statusName"]
                    }
                     
                    const objNew = {
                        id                      : dataFilter[i]["id"],
                        applicationDate         : moment(dataFilter[i]["applicationDate"]).format('MM/DD/YYYY'),
                        applicationdocuments    : dataFilter[i]["applicationdocuments"],
                        applicationstatus       : dataFilter[i]["applicationstatus"],
                        attention               : dataFilter[i]["attention"],
                        banK_SLA                : dataFilter[i]["banK_SLA"],
                        branchName              : dataFilter[i]["branchName"],
                        cardValue               : dataFilter[i]["cardValue"],
                        clientName              : dataFilter[i]["clientName"],
                        createdBy               : dataFilter[i]["createdBy"],
                        createdDate             : dataFilter[i]["createdDate"],
                        dateStart               : moment(dataFilter[i]["dateStart"]).format('MM/DD/YYYY'),
                        dateHired               : moment(dataFilter[i]["dateHired"]).format('MM/DD/YYYY'),
                        effectivityDate         : effectivityDate,
                        description             : dataFilter[i]["description"],
                        employeeId              : dataFilter[i]["employeeId"],
                        employeeName            : dataFilter[i]["employeeName"],
                        firstName               : dataFilter[i]["firstName"],
                        lastName                : dataFilter[i]["lastName"],
                        middleName              : dataFilter[i]["middleName"],
                        suffixName              : dataFilter[i]["suffixName"],
                        employeeNo              : dataFilter[i]["employeeNo"],
                        referenceNo             : dataFilter[i]["referenceNo"],
                        natureId                : dataFilter[i]["natureId"],
                        natureName              : dataFilter[i]["natureName"],
                        oldOayCardNumber        : dataFilter[i]["oldOayCardNumber"],
                        payCardNumber           : dataFilter[i]["payCardNumber"],
                        payCardType             : dataFilter[i]["payCardType"],
                        payCardTypeId           : dataFilter[i]["payCardTypeId"],
                        phR_SLA                 : dataFilter[i]["phR_SLA"],
                        positionName            : dataFilter[i]["positionName"],
                        profileId               : dataFilter[i]["profileId"],
                        rEmarks                 : dataFilter[i]["rEmarks"],
                        reasonId                : dataFilter[i]["reasonId"],
                        reasonName              : dataFilter[i]["reasonName"],
                        statusId                : dataFilter[i]["statusId"],
                        isReApplication         : dataFilter[i]["isReApplication"],
                        reApplication           : dataFilter[i]["reApplication"],
                        statusName              : statusName,
                        statusProcess           : dataFilter[i]["statusProcess"],
                        systemStatusId           : dataFilter[i]["systemStatusId"],
                        systemStatus           : dataFilter[i]["systemStatus"]
                    }
    
                  
                    dataList.push(objNew)
                }
            }
            //////////////console.log("data")
            //////////////console.log(res.data.applications)
                ////console.log("dataFilter" + ", " + this.state.attentionName)
                ////console.log(dataFilter)
            
            
            this.setState({
                onProcessedDDG: dataList.filter(x => x.statusProcess === "ON-PROCESS"),
                cancelledDDG: dataList.filter(x => x.statusProcess === "CANCELLED"),
                cancelledDDG: dataList.filter(x => x.systemStatusId === "1"),
                cancelledDDG: dataList.filter(x => x.statusProcess === "REJECTED"),
                regectedDDG: dataList.filter(x => x.statusProcess === "PAYCARD REJECTED"),
                completedDDG: dataList.filter(x => x.statusProcess === "COMPLETED"),
                isloading   :   false
            })
        })
    }    

    handleModalCloseFinance = (e) =>{
        this.setState({modalShow:false})
        this.getClient()
    }

    handleModalCloseAdmin = (e) =>{
        this.setState({modalShowAdmin:false})
        this.getClient()
    }

    handleClickedRouting = (row) => {
       
    }

    handleReApplication = (row) => {
        this.setState({isloading:true})
        if( this.state.cancelledDDG.some(item =>item.isReApplication === "1") === true ){
            this.setState({
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :    row.employeeName + " " + " already exist!",
                fade            :   true,
            })
            return  
        }
        
        ////////console.log("re application")
        ////////console.log(row)
        let arrLst = []
        for(let x = 0; x < row.applicationdocuments.length; x++){
            const obj = {
                "DocumentId" : row.applicationdocuments[x]["documentId"]
            }
            arrLst.push(obj)
        }

        const addParams = {
            "IpAddress"         :   "0.0.0.0",
            "UserId"            : this.state.userinfo.userId,
            "ClientId"          : row.clientId,
            "EmployeeId"        : row.employeeId,
            "ReferenceNo"       : row.employeeNo,
            "ProfileId"         : row.profileId,
            "ApplicationDate"   : moment(row.applicationDate).format('MM/DD/YYYY'),
            "DateStart"         : moment(row.dateStart).format('MM/DD/YYYY'),
            "PayCardTypeId"     : row.payCardTypeId,
            "PayCardNumber"     : row.payCardNumber,
            "OldOayCardNumber"  : row.oldOayCardNumber,
            "NatureId"          : row.natureId,
            "ReasonId"          : row.reasonId,
            "Description"       : row.description,
            "CardValue"         : row.cardValue,
            "Remarks"           : "",
            "StatusId"          : "40",
            "isReApplication"   : "1",
            "ReApplication"     : "Re-Application",

            "CreatedBy"        : this.state.userinfo.userId,
            "documents"        : arrLst
        };
        ////////console.log("addParams")
        ////////console.log(addParams)
        this.UpdateStatus(row);
        axios
        .post( AppConfiguration.Setting().noserapiendpoint + "Paycard/AddApplication",  addParams)
        //.post("http://104.248.57.90:8081/Paycard/AddApplication",  addParams)
        .then(res => {
            const data = res.data;
            this.getClient();
            if(data.status=="0"){
                this.setState({
                    isloading   :   false,
                    alerttype   :   "Error!",
                    isshow      :   true,
                    color       :   "danger",
                    message     :   data.message,
                    fade        :   true
                });
            }
        })

    }
    
    UpdateStatus = (row) => {
        const params = {
            "IpAddress"     :   "0.0.0.0",
            "ClientId"      :   this.state.userinfo.clientId,
            "UserId"        :   this.state.userinfo.userId,
            "HeaderId"      :   row.id,
            "StatusId"      :   row.statusId,
            "RequestDate"   :   moment(new Date()).format('MM/DD/YYYY'),
            "CreatedBy"     :   this.state.userinfo.userId,
            "Remarks"       :   "",
         };

        ////////console.log("UpdateStatus")
        ////////console.log(params)

        axios
        .post( AppConfiguration.Setting().noserapiendpoint + "Paycard/AddApplicationStatus",  params)
        .then(res => {
            const data = res.data;
            if(data.status=="1"){
            this.setState({
                    isloading   :   false,
                    alerttype   :   "Success!",
                    isshow      :   true,
                    color       :   "success",
                    message     :   data.message,
                    fade        :   true
                });
            
            }
            else {
                this.setState({
                    isloading   :   false,
                    alerttype   :   "Error!",
                    isshow      :   true,
                    color       :   "danger",
                    message     :   data.message,
                    fade        :   true
                });
            }
        })

    }
    
    render() {
        const {navigate} = this.state;
        if (navigate) {
            return <Redirect to="/manageemployeeapprovals" push={true} />;
        } 
        
        const monitoringColumn = [
            {
                dataField: 'applicationDate',
                text: 'APPLICATION DATE',
                editable:false,
                headerStyle: () => {
                    return { width: "10%" };
                },
            },
            {
                dataField: 'employeeName',
                text: 'FULL NAME',
                editable:false,
                headerStyle: () => {
                    return { width: "15%" };
                },
            },
            {
                dataField: 'clientName',
                text: 'CLIENT',
                editable:false,
                headerStyle: () => {
                    return { width: "20%"};
                },
            },
           {
                dataField: 'branchName',
                text: 'BRANCH',
                editable:false,
                headerStyle: () => {
                    return { width: "20%" };
                },
            },
            {
                dataField: 'positionName',
                text: 'POSITION',
                editable:false,
                headerStyle: () => {
                    return { width: "20%" };
                },
            },
            {
                dataField: 'dateHired',
                text: 'DATE HIRED',
                editable:false,
                headerStyle: () => {
                    return { width: "8%" };
                },
            },
            {
                dataField: 'payCardType',
                text: 'PAYCARD_TYPE',
                editable:false,
                headerStyle: () => {
                    return { width: "20%" };
                },
            },
            {
                dataField: 'natureName',
                text: 'NATURE_OF_APPLICATION',
                editable:false,
                headerStyle: () => {
                    return { width: "20%" };
                },
            },
            {
                dataField: 'reasonName',
                text: 'REASON',
                editable:false,
                headerStyle: () => {
                    return { width: "20%" };
                },
            },
            {
                dataField: 'statusProcess',
                text: 'STATUS',
                editable:false,
                headerStyle: () => {
                    return { width: "20%" };
                },
            },
            {
                dataField: 'effectivityDate',
                text: 'EFFECTIVITY DATE',
                editable:false,
                headerStyle: () => {
                    return { width: "20%" };
                },
            },
            {
                dataField: 'phR_SLA',
                text: 'Cycle Day/s PHR',
                editable:false,
                headerStyle: () => {
                    return { width: "20%" };
                },
            },
            {
                dataField: 'banK_SLA',
                text: 'Cycle Day/s BANK',
                editable:false,
                headerStyle: () => {
                    return { width: "20%" };
                },
            },
            {
                dataField: 'systemStatus',
                text: 'SYSTEM STATUS',
                editable:false,
                headerStyle: () => {
                    return { width: "20%" };
                },
            },
            {
                dataField: 'attention',
                text: 'ATTENTION',
                editable:false,
                headerStyle: () => {
                    return { width: "20%" };
                },
                formatter   :   (cell, row, isSelect) => {
                    if(row.statusId==="1"  || row.statusId==="2" || row.statusId==="3" ||  row.statusId==="4" || row.statusId==="5" ||
                    row.statusId==="6" || row.statusId==="7"  || row.statusId==="8" || row.statusId==="9" ||  row.statusId==="10" || 
                    row.statusId==="11"  || row.statusId==="12" || row.statusId==="13" || row.statusId==="15" ||
                    row.statusId==="16" || row.statusId==="17"  || row.statusId==="18" || row.statusId==="19" ||  row.statusId==="20" ||
                    row.statusId==="21"  || row.statusId==="22" || row.statusId==="23" ||  row.statusId==="24" || row.statusId==="25" ||
                    row.statusId==="26"  || row.statusId==="28" || row.statusId==="29" ||  row.statusId==="30" || 
                    row.statusId==="31" ||  row.statusId==="40"
                        )
                    if(row.systemStatusId==="1" ){
                        return (
                            <label style={{height:'18px',paddingTop:'0',marginTop:'-4px',fontSize:'11px'}} 
                        ></label>
                        );
                    }
                    return (
                        
                        <Button style={{height:'18px',paddingTop:'0',marginTop:'-4px',fontSize:'11px'}} 
                            variant="link" onClick={e => this.handleModalShow(row)}
                        >{cell}</Button>
                        
                        );
                        // CLYDE FINANCE
                        if(row.statusId==="27" ){
                            return (
                                <Button style={{height:'18px',paddingTop:'0',marginTop:'-4px',fontSize:'11px'}} 
                                variant="link"  onClick={e => this.handleModalShow(row)}
                            >{cell}</Button>
                            );
                        }
                        // CLYDE FINANCE
                        if(row.statusId==="14" ){
                            return (
                                <label style={{height:'18px',paddingTop:'0',marginTop:'-4px',fontSize:'11px'}} 
                                 onClick={e => this.handleModalShow(row)}
                            >{cell}</label>
                            );
                        }
                },
            },
            {
                dataField: 'statusName',
                text: 'NEXT_ACTION',
                editable:false,
                headerStyle: () => {
                    return { width: "20%" };
                },
            },
            {
                dataField: 'reApplication',
                text: 'APPLICATION',
                editable:false,
                headerStyle: () => {
                    return { width: "20%" };
                },
            },
            {
                dataField: '',
                text: 'ACTION',
                editable:false,
                headerStyle: () => {
                    return { width: "20%" };
                },
                formatter   :   (cell, row, isSelect) => {
                        // CLYDE FINANCE
                        if(row.statusId==="14" ){
                            return (
                                <Button style={{height:'18px',paddingTop:'0',marginTop:'-4px',fontSize:'11px'}} 
                                variant="link"  onClick={e => this.handleReApplication(row)}
                            >Re-Application</Button>
                            );
                        }
                },
            },
        ] 

        const expandRow = {
            renderer: row => (
                <BootstrapTable
                    keyField = "id"
                    data = { row.applicationstatus }
                    columns = { statusColumn }
                    pagination={ paginationFactory({sizePerPageRenderer}) }
                    noDataIndication={ () => <div style={{width:'100%'}}>No record found.</div> }
                    rowClasses="noser-table-row-class no-checkbox"
                    striped
                    hover
                    condensed
                    wrapperClasses="table-responsive"
                    //selectRow = { requestMonitoringSelectRow }
                    //rowEvents={ requestMonitoringRowEvents }

                />
            ),
            showExpandColumn: true
        };
        const statusColumn = [
            {
                dataField: 'statusName',
                text: 'STATUS NAME',
                editable:false,
                headerStyle: () => {
                    return { width: "40%" };
                },
                style:{width:'40%'},
                
            },
            {
                dataField: 'requestDate',
                text: 'REQUEST DATE',
                editable:false,
                headerStyle: () => {
                    return { width: "20%" };
                },
                style:{width:'20%'},
                
            },
            {
                dataField: 'remarks',
                text: 'REMARKS',
                editable:false,
                headerStyle: () => {
                    return { width: "40%" };
                },
                style:{width:'40%'},
                
            },
        ] 
    
        const selectRow = {
            mode: 'checkbox',
            clickToSelectAndEditCell: true,
            onSelect: (row, isSelect, rowIndex, e) => {
                this.state.payCardApplicationList.map(function(item,i){
                   
                    if(item.id===row.id)
                        {
                        item.isDeleted = isSelect ? "1" : "0"
                        item.isModified = isSelect ? "1" : "0"
                    }
                })

            }
        };
            const rowEvents = {
            onClick: (e, row, rowIndex) => {

            }
        };

    return(
            <div>
                <Banner />
                    <Container className="mt-5" fluid>
                        <Card>
                            <Card.Header>PayCard Application Monitoring</Card.Header>
                            <Card.Body>
                                <Form>
                                    <Alert isOpen={this.state.isshow} color={this.state.color} fade={this.state.fade} className={this.state.isshow ? 'display-block' : 'display-none'}>
                                        <div className={this.state.color}></div> 
                                        {this.state.message}
                                    </Alert>
                                    <Form.Row>
                                        <Form.Group as={Col} controlId="formGridAddress1">
                                            <Form.Label style={{fontWeight : "bold"}}>CLIENT</Form.Label>
                                            <Typeahead 
                                                labelKey='name'
                                                id="basic-example"
                                                onChange={this.handleChangeClient}
                                                options={this.state.clientDDL}
                                                placeholder="Select Client" 
                                            />
                                        </Form.Group>
                                    </Form.Row>
                                    <Form.Row>
                                        <Form.Group as={Col} controlId="formGridAddress1">
                                            <Form.Label style={{fontWeight : "bold"}}>EMPLOYEE</Form.Label>
                                            <Typeahead 
                                                labelKey='employeeName'
                                                id="basic-example"
                                                onChange={this.handleChangeEmployee}
                                                options={this.state.employeeDDL}
                                                placeholder="Select Employee" 
                                            />
                                        </Form.Group>
                                    </Form.Row>
                                    <Form.Row>
                                        <Form.Group as={Col} controlId="formGridAddress1">
                                            <Form.Label style={{fontWeight : "bold"}}>ATTENTION</Form.Label>
                                            <Typeahead 
                                                labelKey='name'
                                                id="basic-example"
                                                onChange={this.handleChangeAttention}
                                                options={this.state.attentionDDL}
                                                placeholder="Select Attention" 
                                            />
                                        </Form.Group>
                                    </Form.Row>
                                    <ButtonToolbar className="mt-1">
                                        <Button variant="info" className="ml-auto" onClick={ this.handleSearchClick }>
                                            Search
                                        </Button>
                                    </ButtonToolbar>
                                    <Tabs className="mt-3" defaultActiveKey="default" transition={false} id="noanim-tab-example">
                                        <Tab eventKey="default" title="On Process">
                                            <Card>
                                                <Card.Body>
                                                    <div className="mt-3">  
                                                        <div>
                                                            <Card.Header>MONITORING LIST</Card.Header>
                                                            <BootstrapTable
                                                                keyField = "id"
                                                                data = { this.state.onProcessedDDG }
                                                                columns = { monitoringColumn }
                                                                pagination={ paginationFactory({sizePerPageRenderer}) }
                                                                noDataIndication={ () => <div style={{width:'100%'}}>No record found.</div> }
                                                                rowClasses="noser-table-row-class no-checkbox"
                                                                striped
                                                                hover
                                                                condensed
                                                                cellEdit = { cellEditFactory({
                                                                    mode: 'dbclick',
                                                                    blurToSave: true,
                                                                    afterSaveCell: (oldValue, newValue, row, column) => {
                                                                        //this.GridDataModified(oldValue, newValue, row.id, column.dataField)
                                                                        }
                                                                    })
                                                                }
                                                                wrapperClasses="table-responsive"
                                                                expandRow={ expandRow }
                                                                //selectRow = { requestMonitoringSelectRow }
                                                                //rowEvents={ requestMonitoringRowEvents }
                                        
                                                            />
                                                        </div>
                                                    </div>
                                                </Card.Body>
                                            </Card>
                                        </Tab>
                                        <Tab eventKey="rejected" title="Rejected">
                                            <Card>
                                                <Card.Body>
                                                    <div className="mt-3">  
                                                        <div>
                                                            <Card.Header>MONITORING LIST</Card.Header>
                                                            <BootstrapTable
                                                                keyField = "id"
                                                                data = { this.state.regectedDDG }
                                                                columns = { monitoringColumn }
                                                                pagination={ paginationFactory({sizePerPageRenderer}) }
                                                                noDataIndication={ () => <div style={{width:'100%'}}>No record found.</div> }
                                                                rowClasses="noser-table-row-class no-checkbox"
                                                                striped
                                                                hover
                                                                condensed
                                                                cellEdit = { cellEditFactory({
                                                                    mode: 'dbclick',
                                                                    blurToSave: true,
                                                                    afterSaveCell: (oldValue, newValue, row, column) => {
                                                                        //this.GridDataModified(oldValue, newValue, row.id, column.dataField)
                                                                        }
                                                                    })
                                                                }
                                                                wrapperClasses="table-responsive"
                                                                expandRow={ expandRow }
                                                                //selectRow = { requestMonitoringSelectRow }
                                                                //rowEvents={ requestMonitoringRowEvents }
                                        
                                                            />
                                                        </div>
                                                    </div>
                                                </Card.Body>
                                            </Card>
                                        </Tab>
                                        <Tab eventKey="cancel" title="Cancelled">
                                            <Card>
                                                <Card.Body>
                                                    <div className="mt-3">  
                                                        <div>
                                                            <Card.Header>MONITORING LIST</Card.Header>
                                                            <BootstrapTable
                                                                keyField = "id"
                                                                data = { this.state.cancelledDDG }
                                                                columns = { monitoringColumn }
                                                                pagination={ paginationFactory({sizePerPageRenderer}) }
                                                                noDataIndication={ () => <div style={{width:'100%'}}>No record found.</div> }
                                                                rowClasses="noser-table-row-class no-checkbox"
                                                                striped
                                                                hover
                                                                condensed
                                                                cellEdit = { cellEditFactory({
                                                                    mode: 'dbclick',
                                                                    blurToSave: true,
                                                                    afterSaveCell: (oldValue, newValue, row, column) => {
                                                                        //this.GridDataModified(oldValue, newValue, row.id, column.dataField)
                                                                        }
                                                                    })
                                                                }
                                                                wrapperClasses="table-responsive"
                                                                expandRow={ expandRow }
                                                                //selectRow = { requestMonitoringSelectRow }
                                                                //rowEvents={ requestMonitoringRowEvents }
                                        
                                                            />
                                                        </div>
                                                    </div>
                                                </Card.Body>
                                            </Card>
                                        </Tab>
                                        <Tab eventKey="completed" title="Completed">
                                            <Card>
                                                <Card.Body>
                                                    <div className="mt-3">  
                                                        <div>
                                                            <Card.Header>MONITORING LIST</Card.Header>
                                                            <BootstrapTable
                                                                keyField = "id"
                                                                data = { this.state.completedDDG }
                                                                columns = { monitoringColumn }
                                                                pagination={ paginationFactory({sizePerPageRenderer}) }
                                                                noDataIndication={ () => <div style={{width:'100%'}}>No record found.</div> }
                                                                rowClasses="noser-table-row-class no-checkbox"
                                                                striped
                                                                hover
                                                                condensed
                                                                cellEdit = { cellEditFactory({
                                                                    mode: 'dbclick',
                                                                    blurToSave: true,
                                                                    afterSaveCell: (oldValue, newValue, row, column) => {
                                                                        //this.GridDataModified(oldValue, newValue, row.id, column.dataField)
                                                                        }
                                                                    })
                                                                }
                                                                wrapperClasses="table-responsive"
                                                                expandRow={ expandRow }
                                                                //selectRow = { requestMonitoringSelectRow }
                                                                //rowEvents={ requestMonitoringRowEvents }
                                        
                                                            />
                                                        </div>
                                                    </div>
                                                </Card.Body>
                                            </Card>
                                        </Tab>
                                    </Tabs>
                                    <ButtonToolbar className="mt-3" sm={12}>
                                        <Button  className="ml-auto noser-button-mr1 noser-button"  variant="danger" href="/home">Close </Button>
                                    </ButtonToolbar> 
                                </Form>
                            </Card.Body>
                        </Card>
                       
                    </Container>
                    <NoserLoading show={this.state.isloading} />
                    <PayCardAMT 
                        show={this.state.openModalamt}
                        onHide={this.handleModalCloseAMT}
                        onRefModal={ref => (this.child = ref)}
                    />
                <FinanceModal 
                    show={this.state.modalShow}
                    onHide={this.handleModalCloseFinance}
                    onRefModal={ref => (this.childFinance = ref)}
                />
                <AdminModal 
                    show={this.state.modalShowAdmin}
                    onHide={this.handleModalCloseAdmin}
                    onRefModal={ref => (this.childAdmin = ref)}
                />
            </div> 
        )
    }

}

export  default PayCardMonitoring 
