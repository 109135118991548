import {
    React,Component,BootstrapTable,
    paginationFactory,Button, ButtonToolbar, Card, 
    Form, Col, Row, axios, Container, Banner, Typeahead, TimePicker,
    cellEditFactory, Type, moment, NavLink, Alert, AppConfiguration,
    NoserLoading, Noser, sizePerPageRenderer, DatePicker
} 
from '../../noser-hris-component';
import { DropdownButton, Dropdown} from 'react-bootstrap';
import CasesCreate from '../Maintenance/CasesCreate';
import ToolkitProvider, { CSVExport } from 'react-bootstrap-table2-toolkit';
import 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css';

 
class CriminalCasesMonitoring extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fade                        :true, 
            color                       :"",
            isshow                      :false,
            message                     :"",
            userinfo                    :[],
            isloading                   :false,
            alerttype                   :"",

            batchAutocomplete           :[],
            clientAutocomplete          :[],
            positionAutocomplete        :[],
            trainingAutocomplete        :[],
            batchNoAutocomplete         :[],
            dateFrom                    :"",
            dateTo                      :"",
            modalTrainingRegisterShow   :false,
            selectedClientName          :[],            
            getClientList               :[],
            employeeDetailList          :[],
            clientName                  :[],

            particularList              :[ {"id" : "Pending","name" : "Pending"} , {"id" : "Closed","name" : "Closed"}  ],
            selectedparticular          :'',
            selectedparticularId        :'',

            filterPending               :"Pending",
            filterResolved              :"Closed",
            totalCriminalCases          :"Total Criminal Cases",

            countPending                :[],
            countClosed                 :[],
            countTotal                  :[],

            statusTableList             :[],
            TotalStatus                 :[],

        }
    }

    componentDidMount(){
        const sleep = (milliseconds) => {return new Promise(resolve => setTimeout(resolve, milliseconds))}
        this.state.userinfo = JSON.parse(sessionStorage.getItem("userData"))
        //this.getMaintenanceCase()
        this.getClientList();;
        //this.getCriminalCase()
        this.getCriminalCasesPending();
        this.getCriminalCasesClosed();
        this.getCriminalCasesTotalAll();
    }
    getCriminalCasesPending =()=>{
       
        let filter_data ={'status':this.state.filterPending}
        const getParams ={
            "_collection" : "TestCriminalCases",
            "filter_data" : filter_data
        }
        axios
        .post("http://134.209.99.190:8088/action/get" , getParams)
        .then(res =>{
            let data = res.data
        
            const itemUsersDataLists = this.buildList(data["TestCriminalCases"])
            
            this.state.itemUserDataList  =  itemUsersDataLists
            let countPendingTmp = this.state.itemUserDataList.length
            let List =[]
            for (let i = 0; i < this.state.itemUserDataList.length; i++) {
                let obj = {
                    'id'                :   this.state.itemUserDataList[i]['id'],
                    'client'            :   this.state.itemUserDataList[i]['client'],
                    'employeeName'      :   this.state.itemUserDataList[i]['employeeName'],
                    'position'          :   this.state.itemUserDataList[i]['position'],
                    'employeeNo'        :   this.state.itemUserDataList[i]['employeeNo'],
                    'location'          :   this.state.itemUserDataList[i]['location'],
                    'dateCaseFiled'     :   this.state.itemUserDataList[i]['dateCaseFiled'],
                    'caseFiled'         :   this.state.itemUserDataList[i]['caseFiled'],
                    'venue'             :   this.state.itemUserDataList[i]['venue'],
                    'SchedOfHearing'    :   this.state.itemUserDataList[i]['SchedOfHearing'],
                    'prosecutor'        :   this.state.itemUserDataList[i]['prosecutor'],
                    'legal'             :   this.state.itemUserDataList[i]['legal'],
                    'latestUpdate'      :   this.state.itemUserDataList[i]['latestUpdate'],
                    'updateDate'        :   this.state.itemUserDataList[i]['updateDate'],
                    'remarks'           :   this.state.itemUserDataList[i]['remarks'],
                    'status'            :   this.state.itemUserDataList[i]['status'],
                    'file'              :   this.state.itemUserDataList[i]['file'],   
                    'counsel'           :   this.state.itemUserDataList[i]['counsel'],                      
                    'isDraft'           :   this.state.itemUserDataList[i]['isDraft'],
                    'isDeleted'         :   this.state.itemUserDataList[i]['isDeleted'],
                    'createdby'         :   this.state.itemUserDataList[i]['createdby'],
                    'createddate'       :   this.state.itemUserDataList[i]['createddate'],
                    'modifiedby'        :   this.state.itemUserDataList[i]['modifiedby'],
                    'modifieddate'      :   this.state.itemUserDataList[i]['modifieddate'],
                    'isModified'        :   this.state.itemUserDataList[i]['isModified'],
                    
                }               

                List.push(obj)
                console.log("countPendingTmp")    
                console.log(countPendingTmp)
                console.log("countPendingTmp")                
                this.setState({
                    countPending:countPendingTmp
                })                   
            }                              
            this.getAllStatusTable();
        })
        .catch(error=>{
            //console.log("error: " + error)
        })
    }
    getCriminalCasesClosed =()=>{
       
        let filter_data ={'status':this.state.filterResolved}
        const getParams ={
            "_collection" : "TestCriminalCases",
            "filter_data" : filter_data
        }
        //console.log("axios start")
        axios
        .post("http://134.209.99.190:8088/action/get" , getParams)
        .then(res =>{
            let data = res.data        
            const itemUsersDataLists = this.buildList(data["TestCriminalCases"])
            
            this.state.itemUserDataList  =  itemUsersDataLists
            let countClosedTmp = this.state.itemUserDataList.length
            let List =[]
            for (let i = 0; i < this.state.itemUserDataList.length; i++) {
                let obj = {
                    'id'                :   this.state.itemUserDataList[i]['id'],
                    'client'            :   this.state.itemUserDataList[i]['client'],
                    'employeeName'      :   this.state.itemUserDataList[i]['employeeName'],
                    'position'          :   this.state.itemUserDataList[i]['position'],
                    'employeeNo'        :   this.state.itemUserDataList[i]['employeeNo'],
                    'location'          :   this.state.itemUserDataList[i]['location'],
                    'dateCaseFiled'     :   this.state.itemUserDataList[i]['dateCaseFiled'],
                    'caseFiled'         :   this.state.itemUserDataList[i]['caseFiled'],
                    'venue'             :   this.state.itemUserDataList[i]['venue'],
                    'SchedOfHearing'    :   this.state.itemUserDataList[i]['SchedOfHearing'],
                    'prosecutor'        :   this.state.itemUserDataList[i]['prosecutor'],
                    'legal'             :   this.state.itemUserDataList[i]['legal'],
                    'latestUpdate'      :   this.state.itemUserDataList[i]['latestUpdate'],
                    'updateDate'        :   this.state.itemUserDataList[i]['updateDate'],
                    'remarks'           :   this.state.itemUserDataList[i]['remarks'],
                    'status'            :   this.state.itemUserDataList[i]['status'],
                    'file'              :   this.state.itemUserDataList[i]['file'],
                    'counsel'           :   this.state.itemUserDataList[i]['counsel'],                        
                    'isDraft'           :   this.state.itemUserDataList[i]['isDraft'],
                    'isDeleted'         :   this.state.itemUserDataList[i]['isDeleted'],
                    'createdby'         :   this.state.itemUserDataList[i]['createdby'],
                    'createddate'       :   this.state.itemUserDataList[i]['createddate'],
                    'modifiedby'        :   this.state.itemUserDataList[i]['modifiedby'],
                    'modifieddate'      :   this.state.itemUserDataList[i]['modifieddate'],
                    'isModified'        :   this.state.itemUserDataList[i]['isModified'],
                    
                }               

                List.push(obj)
                console.log("countClosedTmp")    
                console.log(countClosedTmp)
                console.log("countClosedTmp")                
                this.setState({
                    countClosed:countClosedTmp
                })                   
            }                              
            this.getAllStatusTable();
        })
        .catch(error=>{
            //console.log("error: " + error)
        })
    }
    getCriminalCasesTotalAll =()=>{
       
        let filter_data ={}
        const getParams ={
            "_collection" : "TestCriminalCases",
            "filter_data" : filter_data
        }
        //console.log("axios start")
        axios
        .post("http://134.209.99.190:8088/action/get" , getParams)
        .then(res =>{
            let data = res.data        
            const itemUsersDataLists = this.buildList(data["TestCriminalCases"])
            
            this.state.itemUserDataList  =  itemUsersDataLists
            let countTotalTmp = this.state.itemUserDataList.length
            let List =[]
            for (let i = 0; i < this.state.itemUserDataList.length; i++) {
                let obj = {
                    'id'                :   this.state.itemUserDataList[i]['id'],
                    'client'            :   this.state.itemUserDataList[i]['client'],
                    'employeeName'      :   this.state.itemUserDataList[i]['employeeName'],
                    'position'          :   this.state.itemUserDataList[i]['position'],
                    'employeeNo'        :   this.state.itemUserDataList[i]['employeeNo'],
                    'location'          :   this.state.itemUserDataList[i]['location'],
                    'dateCaseFiled'     :   this.state.itemUserDataList[i]['dateCaseFiled'],
                    'caseFiled'         :   this.state.itemUserDataList[i]['caseFiled'],
                    'venue'             :   this.state.itemUserDataList[i]['venue'],
                    'SchedOfHearing'    :   this.state.itemUserDataList[i]['SchedOfHearing'],
                    'prosecutor'        :   this.state.itemUserDataList[i]['prosecutor'],
                    'legal'             :   this.state.itemUserDataList[i]['legal'],
                    'latestUpdate'      :   this.state.itemUserDataList[i]['latestUpdate'],
                    'updateDate'        :   this.state.itemUserDataList[i]['updateDate'],
                    'remarks'           :   this.state.itemUserDataList[i]['remarks'],
                    'status'            :   this.state.itemUserDataList[i]['status'],
                    'file'              :   this.state.itemUserDataList[i]['file'],
                    'counsel'           :   this.state.itemUserDataList[i]['counsel'],                        
                    'isDraft'           :   this.state.itemUserDataList[i]['isDraft'],
                    'isDeleted'         :   this.state.itemUserDataList[i]['isDeleted'],
                    'createdby'         :   this.state.itemUserDataList[i]['createdby'],
                    'createddate'       :   this.state.itemUserDataList[i]['createddate'],
                    'modifiedby'        :   this.state.itemUserDataList[i]['modifiedby'],
                    'modifieddate'      :   this.state.itemUserDataList[i]['modifieddate'],
                    'isModified'        :   this.state.itemUserDataList[i]['isModified'],
                    
                }               

                List.push(obj)
                //console.log(List)
                console.log("countTotalTmp")    
                console.log(countTotalTmp)
                console.log("countTotalTmp")                     
                this.setState({
                    countTotal:countTotalTmp
                })                   
            }                              
            this.getAllStatusTable();
        })
        .catch(error=>{
            //console.log("error: " + error)
        })
    }
    getAllStatusTable=()=>{

        let allStatus           =[]
        
        let id1Tmp              ='1'
        let id2Tmp              ='2'
        let id3Tmp              ='3'
        let id4Tmp              ='4'
        let id5Tmp              ='5'
        let isDraftTmp          ='0'
        let isDeletedTmp        ='0'
        let createdbyTmp        ='0'
        let createddateTmp      ='0'
        let modifiedbyTmp       ='0'
        let modifieddateTmp     ='0'
        let isModifiedTmp       ='0'

        
       

        let filterPendingTmp = this.state.filterPending
        let countPendingTmp = this.state.countPending
        let obj1 = {
            'id'                    :   id1Tmp,
            'status'                :   filterPendingTmp,
            'isDraft'               :   isDraftTmp,
            'isDeleted'             :   isDeletedTmp,
            'createdby'             :   createdbyTmp,
            'createddate'           :   createddateTmp,
            'modifiedby'            :   modifiedbyTmp,
            'modifieddate'          :   modifieddateTmp,
            'isModified'            :   isModifiedTmp,
            'count'                 :   countPendingTmp,
            //'persentage'            :   pendingListTmp['persentage'],
        }
        allStatus.push(obj1)

        let filterResolvedTmp = this.state.filterResolved
        let countResolvedTmp = this.state.countClosed
        let obj2 = {
            'id'                    :   id2Tmp,
            'status'                :   filterResolvedTmp,
            'isDraft'               :   isDraftTmp,
            'isDeleted'             :   isDeletedTmp,
            'createdby'             :   createdbyTmp,
            'createddate'           :   createddateTmp,
            'modifiedby'            :   modifiedbyTmp,
            'modifieddate'          :   modifieddateTmp,
            'isModified'            :   isModifiedTmp,
            'count'                 :   countResolvedTmp,
            //'persentage'            :   pendingListTmp['persentage'],
        }
        allStatus.push(obj2)

        let totalCriminalCasesTmp = this.state.totalCriminalCases
        let countTotalTmp = this.state.countTotal
        let obj3 = {
            'id'                    :   id3Tmp,
            'status'                :   totalCriminalCasesTmp,
            'isDraft'               :   isDraftTmp,
            'isDeleted'             :   isDeletedTmp,
            'createdby'             :   createdbyTmp,
            'createddate'           :   createddateTmp,
            'modifiedby'            :   modifiedbyTmp,
            'modifieddate'          :   modifieddateTmp,
            'isModified'            :   isModifiedTmp,
            'count'                 :   countTotalTmp,
            //'persentage'            :   pendingListTmp['persentage'],
        }
        allStatus.push(obj3)

        this.setState({
            statusTableList:allStatus
        })
       
    }

    getClientList(){
        this.setState({isloading:true})
        const getParams = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.userinfo.clientId,
            "UserId":this.state.userinfo.userId,
        }
        axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Client/GetClientList", getParams)
        .then(res => {
            console.log("Client List start ");
            console.log(res.data);
            console.log("Client List end");
            const data = res.data
            this.setState({
                isloading:false,
                getClientList : res.data.clients ? res.data.clients : []
            });
        })
        .catch(error=>{
           this.setState({
               isloading       :   false,
               alerttype       :   "Error!",
               isshow          :   true,
               color           :   "danger",
               message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
               fade            :   true
            })
       })
    }
    onChangeClientList = (e) => {
        if(e.length == 0) {
            this.state.selectedClientName=""
            this.state.selectedClientId=""
            return
        } 
        this.state.selectedClientId = e[0].id
        this.state.selectedClientName = e[0].name
        this.setState({
           // isloading:true,
            isshow:false,
            color:"",
            message:"",
            fade:true ,
            clientName: e[0].name
        })
       let clientTmp = e[0].name
       this.getCriminalCase();;
        
    }

   /*  showTrainingRegisterModal = (e) => {
        this.setState({modalTrainingRegisterShow: true})
    } */
    handleModalClose = (e) =>{
        //reload or refresh data here
        //alert("Data pass from child : " + e)
        this.setState({modalTrainingRegisterShow: false})
    }


    GridDataModified(oldValue, newValue, id, column) {
        ////console.log(id)
        this.state.employeeDetailList.map(function(item,i) {
            if (item.id===id)
                item.isModified = newValue!=oldValue ? "1" : "0"
        })
    }
    getCriminalCase =()=>{
       
        let filter_data ={"client":this.state.selectedClientName}
        const getParams ={
            "_collection" : "TestCriminalCases",
            "filter_data" : filter_data
        }
            console.log("axios start")
            axios
            .post("http://134.209.99.190:8088/action/get" , getParams)
            .then(res =>{
                let data = res.data
                console.log("res.data start")
                console.log(res.data)
                console.log("res.data end")
            
                const itemUsersDataLists = this.buildList(data["TestCriminalCases"])
                
                this.state.itemUserDataList  =  itemUsersDataLists
                
                let List =[]
                for (let i = 0; i < this.state.itemUserDataList.length; i++) {
                    let obj = {
                        'id'                :   this.state.itemUserDataList[i]['id'],
                        'client'            :   this.state.itemUserDataList[i]['client'],
                        'employeeName'      :   this.state.itemUserDataList[i]['employeeName'],
                        'position'          :   this.state.itemUserDataList[i]['position'],
                        'employeeNo'        :   this.state.itemUserDataList[i]['employeeNo'],
                        'location'          :   this.state.itemUserDataList[i]['location'],
                        'dateCaseFiled'     :   this.state.itemUserDataList[i]['dateCaseFiled'],
                        'caseFiled'         :   this.state.itemUserDataList[i]['caseFiled'],
                        'venue'             :   this.state.itemUserDataList[i]['venue'],
                        'SchedOfHearing'    :   this.state.itemUserDataList[i]['SchedOfHearing'],
                        'prosecutor'        :   this.state.itemUserDataList[i]['prosecutor'],
                        'legal'             :   this.state.itemUserDataList[i]['legal'],
                        'latestUpdate'      :   this.state.itemUserDataList[i]['latestUpdate'],
                        'updateDate'        :   this.state.itemUserDataList[i]['updateDate'],
                        'remarks'           :   this.state.itemUserDataList[i]['remarks'],
                        'status'            :   this.state.itemUserDataList[i]['status'],
                        'file'              :   this.state.itemUserDataList[i]['file'], 
                        'counsel'           :   this.state.itemUserDataList[i]['counsel'],                       
                        'isDraft'           :   this.state.itemUserDataList[i]['isDraft'],
                        'isDeleted'         :   this.state.itemUserDataList[i]['isDeleted'],
                        'createdby'         :   this.state.itemUserDataList[i]['createdby'],
                        'createddate'       :   this.state.itemUserDataList[i]['createddate'],
                        'modifiedby'        :   this.state.itemUserDataList[i]['modifiedby'],
                        'modifieddate'      :   this.state.itemUserDataList[i]['modifieddate'],
                        'isModified'        :   this.state.itemUserDataList[i]['isModified'],
                        
                    }                

                    List.push(obj)
                    console.log(List)
                    
                    this.setState({
                        employeeDetailList:List

                    })
                   
                }
                              

            })
            .catch(error=>{
                console.log("error: " + error)
            })
        } 
        
    buildList(data) {
        //console.log("data start")
        let itemList =[]
    
        let idList                      =[]
        let clientList                  =[]
        let employeeNameList            =[]
        let positionList                =[]
        let employeeNoList              =[]
        let locationList                =[]
        let dateCaseFiledList           =[]
        let caseFiledList               =[]
        let venueList                   =[]
        let SchedOfHearingList          =[]
        let prosecutorList              =[]
        let legalList                   =[]
        let latestUpdateList            =[]
        let updateDateList              =[]
        let remarksList                 =[]
        let statusList                  =[]
        let fileList                    =[]
        let counselList                 =[]     
        let isDraftList                 =[]
        let isDeletedList               =[]
        let createdbyList               =[]
        let createddateList             =[]
        let modifiedbyList              =[]
        let modifieddateList            =[]
        let isModifiedList              =[]
        
    
        for (let i = 0; i < data.length; i++) {
            
    
            let s1 = data[i].split("':")
            
            let idClean                     = s1[1].replace("ObjectId(","").replace(")","").replace("{","")
            let IdClean2                    = idClean.split("',")[0]
            let clientTmp                   = s1[2].split("',")[0]
            let employeeNameTmp             = s1[3].split("',")[0]
            let positionTmp                 = s1[4].split("',")[0]
            let employeeNoTmp               = s1[5].split("',")[0]
            let locationTmp                 = s1[6].split("',")[0]
            let dateCaseFiledTmp            = s1[7].split("',")[0]
            let caseFiledTmp                = s1[8].split("',")[0]
            let venueTmp                    = s1[9].split("',")[0]
            let SchedOfHearingTmp           = s1[10].split("',")[0]
            let prosecutorTmp               = s1[11].split("',")[0]
            let legalTmp                    = s1[12].split("',")[0]
            let latestUpdateTmp             = s1[13].split("',")[0]
            let updateDateTmp               = s1[14].split("',")[0]
            let remarksTmp                  = s1[15].split("',")[0]
            let statusTmp                   = s1[16].split("',")[0]
            let fileTmp                     = s1[17].split("',")[0] 
            let counselTmp                  = s1[18].split("',")[0]            
            let isDraftListTmp              = s1[19].split("',")[0]
            let isDeletedListTmp            = s1[20].split("',")[0]
            let createdbyListTmp            = s1[21].split("',")[0]
            let createddateListTmp          = s1[22].split("',")[0]
            let modifiedbyListTmp           = s1[23].split("',")[0]
            let modifieddateListTmp         = s1[24].split("',")[0]
            let isModifiedListTmp           = s1[25].split("',")[0]

        
            idList.push(IdClean2.replace("'",""))
            clientList.push(clientTmp.replace("'",""))
            employeeNameList.push(employeeNameTmp.replace("'",""))
            positionList.push(positionTmp.replace("'",""))
            employeeNoList.push(employeeNoTmp.replace("'",""))
            locationList.push(locationTmp.replace("'",""))
            dateCaseFiledList.push(dateCaseFiledTmp.replace("'",""))
            caseFiledList.push(caseFiledTmp.replace("'",""))
            venueList.push(venueTmp.replace("'",""))
            SchedOfHearingList.push(SchedOfHearingTmp.replace("'",""))
            prosecutorList.push(prosecutorTmp.replace("'",""))
            legalList.push(legalTmp.replace("'",""))
            latestUpdateList.push(latestUpdateTmp.replace("'",""))
            updateDateList.push(updateDateTmp.replace("'",""))
            remarksList.push(remarksTmp.replace("'",""))
            statusList.push(statusTmp.replace("'",""))
            fileList.push(fileTmp.replace("'",""))  
            counselList.push(counselTmp.replace("'",""))                     
            isDraftList.push(isDraftListTmp.replace("'",""))
            isDeletedList.push(isDeletedListTmp.replace("'",""))
            createdbyList.push(createdbyListTmp.replace("'",""))
            createddateList.push(createddateListTmp.replace("'",""))
            modifiedbyList.push(modifiedbyListTmp.replace("'",""))
            modifieddateList.push(modifieddateListTmp.replace("'",""))
            isModifiedList.push(isModifiedListTmp.replace("'","").replace("'}",""))
        }
        
        for (let i = 0; i < idList.length; i++) {
    
            let obj ={
                'id'                    :   idList[i],
                'client'                :   clientList[i],
                'employeeName'          :   employeeNameList[i],
                'position'              :   positionList[i],
                'employeeNo'            :   employeeNoList[i],
                'location'              :   locationList[i],
                'dateCaseFiled'         :   dateCaseFiledList[i],
                'caseFiled'             :   caseFiledList[i],
                'venue'                 :   venueList[i],
                'SchedOfHearing'        :   SchedOfHearingList[i],
                'prosecutor'            :   prosecutorList[i],
                'legal'                 :   legalList[i],
                'latestUpdate'          :   latestUpdateList[i],
                'updateDate'            :   updateDateList[i],
                'remarks'               :   remarksList[i],
                'status'                :   statusList[i],
                'file'                  :   fileList[i],
                'counsel'               :   counselList[i],
                'isDraft'               :   isDraftList[i],
                'isDeleted'             :   isDeletedList[i],
                'createdby'             :   createdbyList[i],
                'createddate'           :   createddateList[i],
                'modifiedby'            :   modifiedbyList[i],
                'modifieddate'          :   modifieddateList[i],
                'isModified'            :   isModifiedList[i],
            
            }
            itemList.push(obj)
        }
        
       
        return itemList
    }

    onChangeparticular = (e) => {
        if(e.length==0)
        {
            this.setState({
                selectedparticular     :   '',
                selectedparticularId   :   ''
            })
            return
        }
        this.state.selectedparticular = e[0].name
        this.state.selectedparticularId = e[0].id
       
        this.setState({
            isshow   :   false,
           selectedparticular : e[0].id

        })
        this.getEmployeeDetailsByStatus();
    }
    getEmployeeDetailsByStatus =()=>{
       
        let filter_data ={"client":this.state.selectedparticular}
        const getParams ={
            "_collection" : "TestCriminalCases",
            "filter_data" : filter_data
        }
            console.log("axios start")
            axios
            .post("http://134.209.99.190:8088/action/get" , getParams)
            .then(res =>{
                let data = res.data
                           
                const itemUsersDataLists = this.buildList(data["TestCriminalCases"])
                
                this.state.itemUserDataList  =  itemUsersDataLists
                
                let List =[]
                for (let i = 0; i < this.state.itemUserDataList.length; i++) {
                    let obj = {
                        'id'                :   this.state.itemUserDataList[i]['id'],
                        'client'            :   this.state.itemUserDataList[i]['client'],
                        'employeeName'      :   this.state.itemUserDataList[i]['employeeName'],
                        'position'          :   this.state.itemUserDataList[i]['position'],
                        'employeeNo'        :   this.state.itemUserDataList[i]['employeeNo'],
                        'location'          :   this.state.itemUserDataList[i]['location'],
                        'dateCaseFiled'     :   this.state.itemUserDataList[i]['dateCaseFiled'],
                        'caseFiled'         :   this.state.itemUserDataList[i]['caseFiled'],
                        'venue'             :   this.state.itemUserDataList[i]['venue'],
                        'SchedOfHearing'    :   this.state.itemUserDataList[i]['SchedOfHearing'],
                        'prosecutor'        :   this.state.itemUserDataList[i]['prosecutor'],
                        'legal'             :   this.state.itemUserDataList[i]['legal'],
                        'latestUpdate'      :   this.state.itemUserDataList[i]['latestUpdate'],
                        'updateDate'        :   this.state.itemUserDataList[i]['updateDate'],
                        'remarks'           :   this.state.itemUserDataList[i]['remarks'],
                        'status'            :   this.state.itemUserDataList[i]['status'],
                        'file'              :   this.state.itemUserDataList[i]['file'],
                        'counsel'           :   this.state.itemUserDataList[i]['counsel'],                        
                        'isDraft'           :   this.state.itemUserDataList[i]['isDraft'],
                        'isDeleted'         :   this.state.itemUserDataList[i]['isDeleted'],
                        'createdby'         :   this.state.itemUserDataList[i]['createdby'],
                        'createddate'       :   this.state.itemUserDataList[i]['createddate'],
                        'modifiedby'        :   this.state.itemUserDataList[i]['modifiedby'],
                        'modifieddate'      :   this.state.itemUserDataList[i]['modifieddate'],
                        'isModified'        :   this.state.itemUserDataList[i]['isModified'],
                        
                    }                

                    List.push(obj)
                    console.log(List)
                    
                    this.setState({
                        employeeDetailList:List

                    })                   
                }    
            })
            .catch(error=>{
                console.log("error: " + error)
            })
        }

    render() {
        const { ExportCSVButton } = CSVExport;

        const statusColumn = [
            
            {
                dataField   : 'status',
                text:'Status',
                headerStyle : () => {
                    return  { minWidth  : "865px"};
                }
            },
            {
                dataField: 'count',
                text: 'Count',
                headerStyle : () => {
                    return  { minWidth  : "870px"};
                }
            },
            /* {
                dataField   : 'persentage',
                text        : '%',
                headerStyle : () => {
                    return { width  : "33.3333333333%" };
                }
            }, */
            /*
            {
                dataField   : 'action',
                text        : 'Action',
                headerStyle : () => {
                    return { width  : "10%" };
                },
                formatter   :   (cell, row, isSelect) => {
                    if (row)
                    return (
                        <Button style={{height:'16px',paddingTop:'0',marginTop:'-3px',fontSize:'11px'}}
                            variant="link" onClick={this.showTrainingRegisterModal}
                        >View Details</Button>
                    );
                },
            }, */
        ]

        const employeeDetailColumn = [
            {
                dataField   : 'employeeName',
                text:'NAME',
                headerStyle : () => {
                    return { width  : "12%" };
                }
            },
            {
                dataField: 'client',
                text: 'DEPT / CLIENT',
                headerStyle : () => {
                    return { width  : "12%" };
                }
            },
            {
                dataField: 'position',
                text: 'POSITION',
                headerStyle : () => {
                    return { width  : "12%" };
                }
            },
            {
                dataField: 'dateCaseFiled',
                text: 'DATE CASE FILED',
                headerStyle : () => {
                    return { width  : "12%" };
                }
            },
            {
                dataField: 'caseFiled',
                text: 'CASE FILED',
                headerStyle : () => {
                    return { width  : "12%" };
                }
            },
            {
                dataField: 'venue',
                text: 'VENUE',
                headerStyle : () => {
                    return { width  : "12%" };
                }
            },
            {
                dataField: 'SchedOfHearing',
                text: 'DATE OF HEARING',
                headerStyle : () => {
                    return { width  : "12%" };
                }
            },
            {
                dataField: 'prosecutor',
                text: 'PROSECUTOR',
                headerStyle : () => {
                    return { width  : "12%" };
                }
            },
            {
                dataField: 'latestUpdate',
                text: 'STATUS / UPDATE',
                headerStyle : () => {
                    return { width  : "12%" };
                }
            },
            {
                dataField: 'counsel',
                text: 'COUNSEL / COOP. REP.',
                headerStyle : () => {
                    return { width  : "12%" };
                }
            },
            /*
            {
                dataField   : 'action',
                text        : 'Action',
                headerStyle : () => {
                    return { width  : "11%" };
                },
                formatter   :   (cell, row, isSelect) => {
                    if (row)
                    return (
                        <Button style={{height:'16px',paddingTop:'0',marginTop:'-3px',fontSize:'11px'}}
                            variant="link" onClick={this.showTrainingRegisterModal}
                        >View Details</Button>
                    );
                },
            }, */
        ]

        const selectRow = {
            mode: 'checkbox',
            clickToSelectAndEditCell: true,
            onSelect: (row, isSelect, rowIndex, e) => {
                this.state.employeeDetailList.map(function(item,i){
                    if(item.id===row.id)
                    {
                        item.isDeleted = isSelect ? "1" : "0"
                        item.isModified = isSelect ? "1" : "0"
                    }
                })
            }
        };
        const selectstatusRow = {
            mode: 'checkbox',
            clickToSelectAndEditCell: true,
            onSelect: (row, isSelect, rowIndex, e) => {
                this.state.statusTableList.map(function(item,i){
                    if(item.id===row.id)
                    {
                        item.isDeleted = isSelect ? "1" : "0"
                        item.isModified = isSelect ? "1" : "0"
                    }
                })
            }
        };

        const rowEvents = {
            onClick: (e, row, rowIndex) => {
            }
        };
        
    return(
            <div>
                <Banner />
                    <Container className="mt-5" fluid>
                        <Card>
                            <Card.Header>CRIMINAL CASSES MONITORING</Card.Header>
                            <Card.Body>
                                <Form>
                                    <Alert isOpen={this.state.isshow} color={this.state.color} fade={this.state.fade} className={this.state.isshow ? 'display-block' : 'display-none'}>
                                        <div className={this.state.color}></div> 
                                        {this.state.message}
                                    </Alert>
                                    <Card.Header>SUMMARY</Card.Header>
                                  
                                    
                                    <Form.Group as={Row} controlId="formPlaintextEmail">
                                    
                                    </Form.Group>
                                   
                                    <ToolkitProvider
                                        striped
                                        hover
                                        condensed
                                        rowClasses="noser-table-row-class"
                                        rowEvents={ rowEvents }
                                        selectRow = { selectRow }
                                        keyField="id"   
                                        data={ this.state.statusTableList }
                                        columns={ statusColumn }
                                        exportCSV={ {
                                            fileName: "Criminal Cases Monitoring Summary.csv",
                                        } }
                                    >
                                        {
                                            props => (
                                                <div className="mt-1" >
                                                    <hr />
                                                    <BootstrapTable { ...props.baseProps } />
                                                    <ButtonToolbar>
                                                        <ExportCSVButton className="btn btn-info ml-auto" { ...props.csvProps }>Export Summary</ExportCSVButton>
                                                    </ButtonToolbar>
                                                </div>
                                            )
                                        }
                                    </ToolkitProvider>

                                    <Card.Header className="mt-4">REPORT FILTERING</Card.Header>

                                      <Form.Group as={Row} controlId="formPlaintextEmail" className="mt-3">
                                    <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                        Client
                                    </Form.Label>
                                    <Col sm="11">
                                        <Typeahead
                                            labelKey='name'
                                            id="basic-example"
                                            onChange={this.onChangeClientList}
                                            options={this.state.getClientList}
                                            placeholder="Select Client"
                                        />
                                    </Col>
                                </Form.Group>

                                <Form.Group as={Row} controlId="formPlaintextEmail">
                                    <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                        Status
                                    </Form.Label>
                                    <Col sm="11">
                                        <Typeahead
                                            labelKey='id'
                                            id="basic-example"
                                            onChange={this.onChangeparticular}
                                            options={this.state.particularList}
                                            //placeholder="select penalty type"
                                        />
                                    </Col>
                                </Form.Group>
                                
                                <ToolkitProvider
                                    keyField="id"   
                                    data={ this.state.employeeDetailList }
                                    columns={ employeeDetailColumn }
                                    exportCSV={ {
                                        fileName: "Criminal Case Monitoring.csv",
                                    } }
                                >
                                    {
                                        props => (
                                            <div>
                                                <hr />
                                                <BootstrapTable { ...props.baseProps } />
                                                <ButtonToolbar>
                                                    <ExportCSVButton className="btn btn-info ml-auto" { ...props.csvProps }>Export Report</ExportCSVButton>                                                       
                                                   {/*  <Button variant="success" className="" style={{minWidth:'60px'}} onClick={this.handleUpdateClick}>
                                                        Save
                                                    </Button> */}&nbsp;&nbsp;
                                                    <NavLink to="/home">
                                                        <Button variant="danger" href="/home" style={{minWidth:'60px'}}>
                                                            Close
                                                        </Button>
                                                    </NavLink>
                                                </ButtonToolbar>
                                            </div>
                                        )
                                    }
                                </ToolkitProvider>
                                </Form>
                            </Card.Body>
                        </Card>
                       
                    </Container>
                    <NoserLoading show={this.state.isloading} />

                    {/* <TrainingRegisterModal 
                        show={this.state.modalTrainingRegisterShow}
                        onHide={this.handleModalClose}
                    /> */}
            </div> 
        )
    }

}

export  default CriminalCasesMonitoring 
