import {
    React,Component,BootstrapTable,
    paginationFactory,Button, ButtonToolbar, Card, 
    Form, Col, Row, axios, Container, Banner, Typeahead, TimePicker,
    cellEditFactory, Type, moment, NavLink, Alert, AppConfiguration,
    NoserLoading, Noser, sizePerPageRenderer
} 
from '../../noser-hris-component';

import  PayrollDeductionsModal  from './Modal/PayrollDeductionsModal';

/* 
import WorkScheduleEdit from  "./WorkScheduleEdit" */

class PayrollDeductions extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userinfo        :   [],
            isloading       :   false,
            isshow          :   false,
            alerttype       :   "",
            message         :   "",
            color           :   "",
            fade            :   true,

            isModalShow     :   false,
            isDisable       :   true,

            clientId        :   "",
            clientName      :   "",
            employeeNo      :   "",
            employeeId      :   "",
            employeeName    :   "",
            payperiodId     :   "",
            payperiodName   :   "",
            workscheduleId  :   "",
            workschedule    :   "",
            paymodeId       :   "",
            paymode         :   "",
            leadtime        :   "",

            deductionTypeId :   "",
            deductionType   :   "",
            deductionId     :   "",
            deductionName   :   "",
            
            clientDDL       :   [],
            employeeDDL     :   [],
            deductionTypeDDL:   [],
            deductionDDL    :   [],
            deductionGRID   :   [],
        } 
    }
    componentDidMount(){
        const sleep = (milliseconds) => {return new Promise(resolve => setTimeout(resolve, milliseconds))}
        this.state.userinfo = JSON.parse(sessionStorage.getItem("userData"))
        // sleep(1000).then(() => {
        //     this.setState({isloading:false})
        // })
        this.GetClients();
    }
    GetClients = async() =>{
        this.setState({isloading:true})
        const params = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.userinfo.clientId,
            "UserId":this.state.userinfo.userId,
        }
        await axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Client/GetClientList", params)
        .then(res => {
            const data = res.data
            this.setState({clientDDL : data.clients ? data.clients : []});
        })
        .catch(error=>{
            this.setState(
            { 
                isloading:false,
                alertType:"Error! ", 
                isShow:true,
                color:"danger",
                message: "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade:true
            })  
        })
        this.GetDeductionTypes()
    }
    GetEmployees = async() => {
        this.setState({isloading:true})
        const params = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.selectedClientId,
            "UserId":this.state.userinfo.userId,
            "ClientName":this.state.clientName
        }
        await axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Employee/GetConfigurationEmployees", params)
        .then(res => {
            const data = res.data
            this.setState({employeeDDL:data.employees,isloading:false}) 
        })
        .catch(error=>{
            this.setState(
            { 
                isloading:false,
                alertType:"Error! ", 
                isShow:true,
                color:"danger",
                message: "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade:true
            })  
        })
    }
    GetDeductionTypes = async() => {
        const params = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.userinfo.clientId,
            "UserId":this.state.userinfo.userId,
            "Name":"",
        }
        await axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Maintenance/getDeductionTypes", params)
        .then(res => {
            const data = res.data;
            this.setState({deductionTypeDDL:data.deductionTypes}) 
        })
        .catch(error=>{
            this.setState(
            { 
                isloading:false,
                alertType:"Error! ", 
                isShow:true,
                color:"danger",
                message: "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade:true
            })  
        })
        this.GetDeductions()
    }
    GetDeductions = async() => {
        const params = {
            "IpAddress":"0.0.0.0",  
            "ClientId":this.state.userinfo.clientId,
            "UserId":this.state.userinfo.userId,
            "TypeId":this.state.deductionTypeId,
            "Name":""

        }
        await axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Maintenance/GetDeductions", params)
        .then(res => {
            const data = res.data;
            this.setState({deductionDDL:data.deductions,isloading:false})
        })
        .catch(error=>{
            this.setState(
            {
                isloading:false,
                alertType:"Error! ",
                isShow:true,
                color:"danger",
                message: "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade:true
            })
        })
    }
    handleSearchClick = async() => {
        this.setState({isloading:true})

	if(this.state.clientId===""){
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "Please select client.",
                fade        :   true
            });
            return 
        }
        if(this.state.employeeId==="" || this.state.employeeId===null){
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "Please select employee.",
                fade        :   true
            });
            return 
        }
	
        const params = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.clientId,
            "UserId":this.state.userinfo.userId,
            "EmployeeId":this.state.employeeId,
            "DeductionId":this.state.deductionId,
        }
        await axios
        .post(AppConfiguration.Setting().noserapiendpoint + "PayrollConfiguration/GetDeductions", params)
        .then(res => {
            const data = res.data
            data.deductions.forEach(itm => {
                console.log(itm)
                const paramdets = {
                    "IpAddress":"0.0.0.0",  
                    "ClientId":this.state.userinfo.clientId,
                    "UserId":this.state.userinfo.userId,
                    "EmployeeId":itm.employeeId,
                    "HeaderId":itm.id
                
                }
                axios
                .post(AppConfiguration.Setting().noserapiendpoint + "PayrollConfiguration/GetDeductionDetails", paramdets)
                .then(res => {
                    const data = res.data;
                    itm.details = data.deductionDetails
                })
                .catch(error=>{
                    this.setState(
                    {
                        isloading:false,
                        alertType:"Error! ",
                        isShow:true,
                        color:"danger",
                        message: "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                        fade:true
                    })
                })
            })

            this.setState({deductionGRID:data.deductions,isloading:false})
            if(data.status==="0"){
                this.setState({
                    isloading   :   false,
                    alerttype   :   "Error!",
                    isshow      :   true,
                    color       :   "danger",
                    message     :   data.message,
                    fade        :   true
                });
            }
        })
        .catch(error=>{
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade        :   true
            })
        })
    }
    handleChangedClient = (e) => {
        this.setState({isshow:false,color:"",message:"",fade:true})
        if(e.length === 0) {
            this.state.clientId=""
            this.state.clientName=""
            this.state.employeeDDL=[]
            return
        } 
        this.state.clientId = e[0].id
        this.state.clientName = e[0].name
        this.GetEmployees();
    }
    handleChangedEmployee = (e) => {
        this.setState({isshow:false,color:"",message:"",fade:true})
        if(e.length===0)
        {
            this.state.employeeNo=""
            this.state.employeeId=""
            this.state.employeeName=""
            this.state.payperiodId=""
            this.state.payperiodName=""
            this.state.workscheduleId=""
            this.state.workschedule=""
            this.state.paymodeId=""
            this.state.paymode=""
            this.state.leadtime=""
            return
        }
        this.state.employeeNo = e[0].employeeNo
        this.state.employeeId = e[0].id
        this.state.employeeName = e[0].employeeName
        this.state.payperiodId = e[0].payPeriodId
        this.state.payperiodName = e[0].payPeriod
        this.state.workscheduleId= e[0].workScheduleId
        this.state.workschedule = e[0].workSchedule
        this.state.paymodeId = e[0].payModeId
        this.state.paymode = e[0].payMode
        this.state.leadtime = e[0].leadTime

    }
    handleChangedDeductionType = (e) => {
        if(e.length === 0) {
            this.state.deductionTypeId = ""
            this.state.deductionType = ""
            return
        }  
        this.state.deductionTypeId = e[0].id
        this.state.deductionType = e[0].name
        this.GetDeductions();
    }
    handleChangedDeduction = (e) => {
        if(e.length == 0) {
            this.state.deductionId=""
            this.state.deductionName=""
            return
        } 
        this.state.deductionId = e[0].id
        this.state.deductionName = e[0].name
    }
    handleChangeEdit(row){
        this.setState({modalDeductionShow: true})
    }
    handleDeleteClick = async() => {
        this.setState({isloading:true})
        const params = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.userinfo.clientId,
            "UserId":this.state.userinfo.userId,
            "deductions":this.state.deductionGRID.filter(x=>x.isModified==="1")
        };

        await axios
            .post(AppConfiguration.Setting().noserapiendpoint + "PayrollConfiguration/EditDeduction", params)
            .then(res => {
                const data = res.data;
                if(data.status==="1"){
                    this.handleSearchClick();
                }
                this.setState({
                    isloading       :   false,
                    alerttype       :   data.status==="0" ? "Error!" : "!Success",
                    isshow          :   true,
                    color           :   data.status==="0" ? "danger" : "success",
                    message         :   data.message,
                    fade            :   true
                })
             })
             .catch(error=>{
                 this.setState({
                     isloading       :   false,
                     alerttype       :   "Error!",
                     isshow          :   true,
                     color           :   "danger",
                     message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                     fade            :   true
                 })
             })
    }
    GridDataModified(oldValue, newValue, id, column) {
        this.state.deductionGRID.forEach(itm => {
            if(itm.id===id){
                itm.isModified = newValue!=oldValue ? "1" : "0"
                this.setState({isDisable:false})
            }
        })
    }
    handleCreate = () => {
        if(!this.state.clientName){
            this.setState({
                isloading       :   false,
                alerttype       :   "Warning!",
                isshow          :   true,
                color           :   "warning",
                message         :   "Please select client",
                fade            :   true
            })
            return
        }
        if(!this.state.employeeName){
            this.setState({
                isloading       :   false,
                alerttype       :   "Warning!",
                isshow          :   true,
                color           :   "warning",
                message         :   "Please select employee",
                fade            :   true
            })
            return
        }
        this.setState({isModalShow: true})

        let obj = {
            clientNameFromParent        :   this.state.clientName,
            clientIdFromParent          :   this.state.clientId,
            employeeNameFromParent      :   this.state.employeeName,
            employeeIdFromParent        :   this.state.employeeId,
            employeePayModeFromParent   :   this.state.paymode,
            employeePayModeIdFromParent :   this.state.paymodeId,
            employeePayPeriodIdFromParent : this.state.payperiodId,
            employeeLeadTimeFromParent :   this.state.leadtime,
            
        }
        console.log(obj)
        this.child.initialize(obj)
    }

    handleModalClose = (e) =>{
        //reload or refresh data here
        //alert("Data pass from child : " + e)
        this.handleSearchClick()
        this.setState({isModalShow: false})
    }
    
    handleReversalClick =(e) =>{
        this.setState({isloading:true})

        let effectivityDate = ""

        this.state.deductionGRID.find(x=>x.id==e.headerId).details.map(function(itm,idx){
            effectivityDate = itm.effectivityDate
        })
        const params = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.userinfo.clientId,
            "UserId":this.state.userinfo.userId,
            "Id":e.id,
            "EmployeeId":e.employeeId,
            "DeductionId":e.deductionId,
            "Amount":e.amount,
            "HeaderId":e.headerId,
            "EffectivityDate": effectivityDate,
            "FromEffectivityDate": e.effectivityDate
        };

        axios
            .post(AppConfiguration.Setting().noserapiendpoint + "PayrollConfiguration/ReverseDeduction", params)
            .then(res => {
                const data = res.data;
                var alertType = (data.status=="1") ? "success" : "danger"
                if(data.status=="1"){
                    this.setState({
                        isloading       :   false,
                        alerttype       :   "Success!",
                        isshow          :   true,
                        color           :   "success",
                        message         :   data.message,
                        fade            :   true
                    });
                    this.handleSearchClick(e)
                }
                else{
                    this.setState({
                        isloading       :   false,
                        alerttype       :   "Error!",
                        isshow          :   true,
                        color           :   "danger",
                        message         :   data.message,
                        fade            :   true
                    })
                }
             })
             .catch(error=>{
                 this.setState({
                     isloading       :   false,
                     alerttype       :   "Error!",
                     isshow          :   true,
                     color           :   "danger",
                     message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                     fade            :   true
                 })
             })
    }
    handleRemovedClick =(e) =>{
        this.setState({isloading:true})

        let effectivityDate = ""

        //this.state.getDeductionListTable.find(x=>x.id==e.headerId).details.map(function(itm,idx){
        //    effectivityDate = itm.effectivityDate
        //})

        const params = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.clientId,
            "UserId":this.state.userinfo.userId,
            "Id":e.id,
            "EmployeeId":e.employeeId,
            "DeductionId":e.deductionId,
            "Amount":e.amount,
            "HeaderId":e.headerId,
            "EffectivityDate": e.effectivityDate,
            "FromEffectivityDate": e.effectivityDate
        };

        axios
            .post(AppConfiguration.Setting().noserapiendpoint + "PayrollConfiguration/RemoveDeduction", params)
            .then(res => {
                const data = res.data;
                var alertType = (data.status=="1") ? "success" : "danger"
                if(data.status=="1"){
                    this.setState({
                        isloading       :   false,
                        alerttype       :   "Success!",
                        isshow          :   true,
                        color           :   "success",
                        message         :   data.message,
                        fade            :   true
                    });
                    this.handleSearchClick()
                }
                else{
                    this.setState({
                        isloading       :   false,
                        alerttype       :   "Error!",
                        isshow          :   true,
                        color           :   "danger",
                        message         :   data.message,
                        fade            :   true
                    })
                }
             })
             .catch(error=>{
                 this.setState({
                     isloading       :   false,
                     alerttype       :   "Error!",
                     isshow          :   true,
                     color           :   "danger",
                     message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                     fade            :   true
                 })
             })
    }
    render() {
        const selectRow = {
            mode: 'checkbox',
            clickToSelectAndEditCell: true,
            bgColor: (row, rowIndex) => (rowIndex > 1 ? '#AFB2B4' : '#B3B4B4'),
            onSelect: (row, isSelect, rowIndex, e) => {
                let isDisable=true
                this.state.deductionGRID.map(function(item,i){
                    if(item.id===row.id)
                    {
                        item.isDeleted = isSelect ? "1" : "0"
                        item.isModified = isSelect ? "1" : "0"
                    }
                    if (item.isDeleted=="1")
                    isDisable =  false
                    //console.log(item.isDeleted)
                })
                this.setState({isDisable:isDisable})
            }
        };
        const selectRowDetails = {
            mode: 'checkbox',
            clickToSelectAndEditCell: true,
            onSelect: (row, isSelect, rowIndex, e) => {
            }
        };
        const rowEvents = {
            onClick: (e, row, rowIndex) => {
            }
        }
        const columnDeductions= [
            {
                dataField: 'reference',
                text: 'REFERENCE',
                editable: false,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'10%' }},
                style:{textAlign:'right'}
            },
            {
                dataField: 'deductionName',
                text: 'DEDUCTION NAME',
                editable: false,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'15%' }},
                style:{textAlign:'right'}
            },
            {
                dataField: 'effectivityDate',
                text: 'EFFECTIVITY DATE',
                editable: false,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'10%' }},
                style:{textAlign:'right'}
            },
            {
                dataField: 'amount',
                text: 'AMOUNT',
                editable: false,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'8%' }},
                style:{textAlign:'right'}
            },
            {
                dataField: 'amortization',
                text: 'AMOUNT PER CUT-OFF',
                editable: false,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'15%' }},
                style:{textAlign:'right'}
            },
            {
                dataField: 'paymentType',
                text: 'PAYMENT TYPE',
                editable: false,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'12%' }},
                style:{textAlign:'right'}
            },
            {
                dataField: 'remarks',
                text: 'REMARKS',
                editable: false,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'left',width:'30%'}},
                style:{textAlign:'left'}
            },
        ]

        const columnDetails = [
            {
                text        :   "",
                editable    :   false,
                dataField   :   "databasePkey",
                formatter   :   (cell, row, isSelect) => {
                    if (row.isProcessed=="0")
                    return (
                        <Button style={{height:'16px',paddingTop:'0',marginTop:'-3px',fontSize:'11px'}}
                            variant="link" onClick={e => this.handleRemovedClick(row)}
                        >DELETE</Button>
                    );
                },
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'6%'}},
                style:{textAlign:'center'}
            },
            {
                text        :   "",
                editable    :   false,
                dataField   :   "databasePkey",
                formatter   :   (cell, row, isSelect) => {
                    if (row.isProcessed=="0")
                    return (
                        <Button style={{height:'16px',paddingTop:'0',marginTop:'-3px',fontSize:'11px'}}
                            variant="link" onClick={e => this.handleReversalClick(row)}
                        >REVERSE</Button>
                    );
                },
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'6%'}},
                style:{textAlign:'center'}
            },
            {
                dataField: 'effectivityDate',
                text: 'DATE',
                editable: false,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'8%' }},
                style:{textAlign:'center'}
            },
            {
                dataField: 'amount',
                text: 'AMOUNT',
                editable: false,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'8%' }},
                style:{textAlign:'right'}
            },
            {
                dataField: 'isProcessedLabel',
                text: 'PROCESSED',
                editable: false,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'8%'}},
                style:{textAlign:'center'}
            },
            {
                dataField: 'processDate',
                text: 'DATE',
                editable: false,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'12%'}},
                style:{textAlign:'center'}
            },
            {
                dataField: 'remarks',
                text: 'REMARKS',
                editable: false,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'left',width:'48%' }},
                style:{textAlign:'left'}
            }
        ]
        const expandRow = {
            onlyOneExpanding: true,
            renderer: row => 
            (
                <BootstrapTable
                    keyField = "id"
                    data = { row.details }
                    columns = { columnDetails }
                    rowClasses="noser-table-row-class"
                    striped
                    hover
                    condensed
                    expandRow
                    selectRow = { selectRowDetails }
                    wrapperClasses="table-responsive"
                    rowClasses="noser-table-row-class"
                    noDataIndication={ () => <div>No details.</div> }
                /> 
                
            ),
            showExpandColumn: true,
        };
        const sizePerPageRenderer = ({
            options,
            currSizePerPage,
            onSizePerPageChange
            }) => (
            <div className="btn-group" role="group">
                {
                options.map((option) => {
                    const isSelect = currSizePerPage === `${option.page}`;
                    return (
                    <button
                        key={ option.text }
                        type="button"
                        onClick={ () => onSizePerPageChange(option.page) }
                        className={ `btn ${isSelect ? 'btn-primary' : 'btn-success'}` }
                    >
                        { option.text }
                    </button>
                    );
                })
                }
            </div>
            );
        const options = {
            sizePerPageRenderer
        };


        let modalDeductionClose =() => this.setState({modalDeductionShow:false});
        
        return (
            <div>

                <Banner />
                <Container className="themed-container" fluid={true}>
                    <Card className="mt-5">
                        <Card.Header>Payroll >> Deductions / Employee Charges</Card.Header>
                        <Card.Body>
                            <Form >
                                <Alert isOpen={this.state.isshow} color={this.state.color} fade={this.state.fade} className={this.state.isshow ? 'display-block' : 'display-none'}>
                                    <div className={this.state.color}></div> 
                                    {this.state.message}
                                </Alert>
                                <Form.Group as={Row} controlId="formHorizontalEmail">
                                    <Col sm={12}>
                                        <Typeahead
                                            labelKey='name'
                                            id="basic-example"
                                            onChange={this.handleChangedClient}
                                            options={this.state.clientDDL}
                                            placeholder="Select Client"

                                        />
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} controlId="formHorizontalEmail">
                                    <Col sm={12}>
                                        <Typeahead
                                            labelKey='employeeName'
                                            id="basic-example"
                                            onChange={this.handleChangedEmployee}
                                            options={this.state.employeeDDL}
                                            placeholder="Select Employee"
                                        />
                                    </Col>
                                </Form.Group>
                               <Form.Group as={Row} controlId="formHorizontalEmail">
                                    <Col sm={12}>
                                        <Typeahead
                                            labelKey='name'
                                            id="basic-example"
                                            onChange={this.handleChangedDeductionType}
                                            options={this.state.deductionTypeDDL}
                                            placeholder="Select Deduction Type"
                                        />
                                    </Col>
                                </Form.Group>
                               <Form.Group as={Row} controlId="formHorizontalEmail">
                                    <Col sm={12}>
                                        <Typeahead
                                            labelKey='name'
                                            id="basic-example"
                                            onChange={this.handleChangedDeduction}
                                            options={this.state.deductionDDL}
                                            placeholder="Select Deduction"
                                        />
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} controlId="formHorizontalEmail">
                                    <Col sm={12}>
                                        <ButtonToolbar>
                                            <Button variant="primary" className="ml-auto" onClick={ this.handleSearchClick }>
                                                Search
                                            </Button>
                                        </ButtonToolbar>
                                    </Col>
                                </Form.Group>
                                <Card.Header>Record</Card.Header>
                                <div className="mt-1">

                                    <BootstrapTable
                                        caption={Noser.TableHeader({title:"DEDUCTIONS LIST"})}
                                        rowClasses="noser-table-row-class"
                                        striped
                                        hover
                                        condensed
                                        pagination={ paginationFactory(options) }
                                        keyField = "id"
                                        data = { this.state.deductionGRID }
                                        columns = { columnDeductions }
                                        selectRow = { selectRow }
                                        cellEdit = { cellEditFactory({ 
                                                mode: 'dbclick', 
                                                blurToSave: true,
                                                afterSaveCell: (oldValue, newValue, row, column) => { 
                                                    this.GridDataModified(oldValue, newValue, row.id, column.dataField)
                                                }                                     
                                            })
                                        }
                                        rowEvents={ rowEvents }
                                        expandRow={ expandRow }
                                        noDataIndication={ () => <div>No record found.</div> }
                                    />

                                    <ButtonToolbar>
                                        <Button  className="ml-auto" variant="primary" variant="success" onClick={this.handleCreate}>
                                            Create
                                        </Button>&nbsp;&nbsp;
                                        {/* <Button variant="info">
                                            Edit
                                        </Button>&nbsp;&nbsp; */}
                                        <Button variant="danger"  onClick={this.handleDeleteClick} disabled={this.state.isDisable}>
                                            Delete
                                        </Button>&nbsp;&nbsp;
                                        <Button variant="danger" href="/home">
                                            Close
                                        </Button>
                                    </ButtonToolbar>
                                </div>
                            
                            </Form>
                        </Card.Body>
                    </Card>
                </Container>
                <NoserLoading show={this.state.isloading} />
                <PayrollDeductionsModal 
                show={this.state.isModalShow}
                onHide={this.handleModalClose}
                onRefDeductionModal={ref => (this.child = ref)}/>
            </div>
        );
    }
}

export default PayrollDeductions;
