import {
    React,Component,BootstrapTable,
    paginationFactory,Button, ButtonToolbar, Card, 
    Form, Col, Row, axios, Container, Banner, Typeahead, TimePicker,
    cellEditFactory, Type, moment, NavLink, Alert, AppConfiguration,
    NoserLoading, Noser, sizePerPageRenderer, Modal, DatePicker, NumberFormat
} 
from '../../noser-hris-component';

import MergeEmployee from './MergeEmployee';

class MergeEmployees
extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userinfo        :   [],
            isloading       :   false,
            isshow          :   false,
            alerttype       :   "",
            message         :   "",
            color           :   "",
            fade            :   true,      
            disable         :   true,
            
            
            employeeGRID    :   [],
            employeeDDL     :   [],
            clientDDL       :   [],
            employeeList    :   [],

            profileIds       :   [],
            clientId        :   "",
        }
    }

    componentDidMount(){
        this.state.userinfo = JSON.parse(sessionStorage.getItem("userData"))
        this.GetClients()
    }
    componentWillUnmount() {
        
    }
    GetClients = async() =>{
        this.setState({isloading:true})
        const params = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.userinfo.clientId,
            "UserId":this.state.userinfo.userId
        }
        await axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Client/GetClientList", params)
        .then(res => {
            const data = res.data;
            this.setState({clientDDL :data.clients})
        })
        .catch(error=>{
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade        :   true
            })
        })
        this.GetEmployeeList()
    }
    GetEmployeeList = async() =>{
        this.setState({isloading:true})
        
        const params = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.clientId,
            "UserId":this.state.userinfo.userId
        }
        await axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Employee/GetEmployeeProfileFilter", params)
        .then(res => {
            const data = res.data;
            this.setState({employeeDDL:data.profiles,employeeList:data.profiles,isloading:false})
        })
        .catch(error=>{
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade        :   true
            })
        })
    }
    handleChangedClient = (e) =>{
        this.state.alerttype="";this.state.isshow=false;this.state.color="";this.state.message="";this.state.fade=true;
        if(e.length===0){
            this.state.clientId= ''
            this.setState({employeeDDL:this.state.employeeList})
            return
        }
        this.state.clientId=e[0].id
        this.setState({employeeDDL:this.state.employeeList.filter(x=>x.clientId===e[0].id)})
    }
    handleChangedEmployee = (e) =>{
        if(e.length===0){
            this.setState({profileIds:[]})
            return
        }
        let profileIds = []
        e.forEach(itm=>{
            profileIds.push(
            {
                "ProfileId" : itm.profileId
            })
        })
        this.setState({profileIds:profileIds})
    }
    handleSearchClick = async() =>{
        this.setState({isloading:true})
        
        const params = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.clientId,
            "UserId":this.state.userinfo.userId,
            "ProfileIds":this.state.profileIds
        }
        await axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Employee/GetEmployeeForMerged", params)
        .then(res => {
            const data = res.data;
            this.setState({employeeGRID:data.employees,isloading:false})
        })
        .catch(error=>{
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade        :   true
            })
        })
    }
    handleModalShow = (e) =>{
        this.setState({openModal:true})
        this.child.initialize(e)
    }
    handleModalClose = (e) =>{
        this.setState({openModal:false})
        this.handleSearchClick()
    }
    render() { 
        const employeeCol = [
            {
                dataField: 'profileId',
                text: 'PROFILE ID',
                editable: false,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'6%'}},
                style:{textAlign:'center',width:'6%'},
                sort: true
            },
            {
                dataField: 'id',
                text: 'EMPLOYEE ID',
                editable: false,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'7%'}},
                style:{textAlign:'center',width:'7%'},
                sort: true
            },
            {
                dataField: 'employeeNo',
                text: 'EMPLOYEE NO',
                editable: false,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'10%'}},
                style:{textAlign:'center',width:'10%'},
                sort: true
            },
            {
                dataField: 'employeeName',
                text: 'EMPLOYEE NAME',
                editable: false,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'left',width:'20%'}},
                style:{textAlign:'left',width:'20%'},
                sort: true
            },
            {
                dataField: 'clientName',
                text: 'CLIENT',
                editable: false,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'left',width:'20%'}},
                style:{textAlign:'left',width:'20%'}
            },
            {
                dataField: 'status',
                text: 'STATUS',
                editable: false,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'8%'}},
                style:{textAlign:'center',width:'8%'},
                sort: true
            },
            {
                dataField: 'dateHired',
                text: 'DATE HIRED',
                editable: false,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'8%'}},
                style:{textAlign:'center',width:'8%'}
            },
            {
                dataField: 'contractDateStart',
                text: 'DATE START',
                editable: false,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'8%'}},
                style:{textAlign:'center',width:'8%'}
            },
            {
                dataField: 'contractDateEnd',
                text: 'DATE END',
                editable: false,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'8%'}},
                style:{textAlign:'center',width:'8%'}
            },
            {
                dataField: 'action',
                text: 'ACTION',
                editable:false,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'15%'}
                },
                style:{textAlign:'center',width:'15%'},
                formatter   :   (cell, row, isSelect) => {
                    return (
                        <Button 
                            onClick={e => this.handleModalShow(row)}
                            style={{height:'18px',paddingTop:'0',marginTop:'-4px',fontSize:'11px'}} 
                            variant="link"
                    >MERGE</Button>
                    );
                },
                
            }
        ]
        const selectRow = {
            mode: 'checkbox',
            hideSelectAll: false,
            clickToSelectAndEditCell: true,
            onSelect: (row, isSelect, rowIndex, e) => {
                this.state.employeeGRID.forEach(itm=>{
                    if(itm.id===row.id){
                        itm.isSelected = isSelect
                    }
                })
            },
            onSelectAll: (isSelected, row) => {
                this.state.employeeGRID.forEach(item =>{
                    item.isSelected = isSelected
                })
                return this.state.employeeGRID.map(row => row.id);
            }
        };
        return(
            <div>
            <Banner />
            <Container className="themed-container" fluid={true}>
                <Card className="mt-5">
                    <Card.Header>Manning >> Merge Employees</Card.Header>
                    <Card.Body>
                        <Form >
                            <Alert isOpen={this.state.isshow} color={this.state.color} fade={this.state.fade} className={this.state.isshow ? 'display-block' : 'display-none'}>
                                <div className={this.state.color}></div> 
                                {this.state.message}
                            </Alert>
                            <Form.Group as={Row} controlId="formHorizontalEmail">
                                    <Col sm={12}>
                                        <Typeahead
                                            labelKey='name'
                                            id="basic-example"
                                            name="clientId"
                                            onChange={this.handleChangedClient}
                                            options={this.state.clientDDL.filter(x=>x.id!=="111")}
                                            placeholder="Select Client"
                                            //selected={[this.state.client]}
                                        />
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} controlId="formHorizontalEmail">
                                    <Col sm={12}>
                                        <Typeahead
                                            labelKey='employeeName'
                                            id="basic-example"
                                            onChange={this.handleChangedEmployee}
                                            options={this.state.employeeDDL.filter(x=>x.clientId!=="111")}
                                            placeholder="Select Employee"
                                            multiple
                                            clearButton
                                        />
                                    </Col>
                                </Form.Group>
                                <ButtonToolbar className="mt-1">
                                    <Button variant="primary" className="ml-auto noser-button" onClick={ this.handleSearchClick }>Search</Button>
                                </ButtonToolbar>
                                <div className="mt-3">
                                <Card.Header>EMPLOYEE LIST</Card.Header>
                                <BootstrapTable
                                    keyField = "id"
                                    data = { this.state.employeeGRID }
                                    columns = { employeeCol }
                                    selectRow = { selectRow }
                                    cellEdit = { cellEditFactory({ 
                                            mode: 'dbclick', 
                                            blurToSave: true,
                                            afterSaveCell: (oldValue, newValue, row, column) => { 
                                                //this.GridDataModified(oldValue, newValue, row.id, column.dataField)
                                            }
                                        })
                                    }
                                    rowClasses="noser-table-row-class no-checkbox"
                                    striped
                                    hover
                                    condensed
                                    loading={true}
                                    wrapperClasses="table-responsive"
                                    rowClasses="noser-table-row-class"
                                    pagination={ paginationFactory({sizePerPageRenderer})}
                                    noDataIndication={ () => <div style={{width:'100%'}}>No record found.</div> }
                                />
                            </div>
                        </Form>
                        <ButtonToolbar className="mt-1">
                            <Button disabled={this.state.employeeGRID.length>0 ? false:true} variant="success" className="noser-button ml-auto" onClick={this.handleClickedProcess}>RE-PROCESS</Button>
                        </ButtonToolbar>
                    </Card.Body>
                </Card>
            </Container>
            <NoserLoading show={this.state.isloading} />
            <MergeEmployee 
                show={this.state.openModal}
                onHide={this.handleModalClose}
                onRefModal={ref => (this.child = ref)}
            />
        </div>
        );
    }
}
export  default MergeEmployees
