import {
    React,Component,BootstrapTable,
    paginationFactory,Button, ButtonToolbar, Card, 
    Form, Col, Row, axios, Container, Banner, Typeahead, TimePicker,
    cellEditFactory, Type, moment, NavLink, Alert, AppConfiguration,
    NoserLoading, Noser, sizePerPageRenderer
} 
from '../../noser-hris-component';
import TransmittedDocumentApproval from './TransmittedDocumentApproval';

class TransmittedDocuments extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userinfo        :   [],
            isloading       :   false,
            isshow          :   false,
            alerttype       :   "",
            message         :   "",
            color           :   "",
            fade            :   true,      
            openModal       :   false,
            clientId        :   "",
            profileId       :   "",
            clientDDL       :   [],
            employeeDDL     :   [],
            employeeGRID    :   [],
        }
    }

    componentDidMount(){
        const sleep = (milliseconds) => {return new Promise(resolve => setTimeout(resolve, milliseconds))}
        this.state.userinfo = JSON.parse(sessionStorage.getItem("userData"))
        this.GetClients();
    }
    GetClients = async() =>{
        this.setState({isloading:true})
        const params = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.userinfo.clientId,
            "UserId":this.state.userinfo.userId,
        }
        await axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Client/GetClientList", params)
        .then(res => {
            const data = res.data;
            this.setState({clientDDL : data.clients ? data.clients : [], isloading:false})
            this.GetTransmittedDocuments()
        })
        .catch(error=>{
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade        :   true
            })
        })
    }
    GetTransmittedDocuments = async() => {
        const params = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.clientId,
            "UserId":this.state.userinfo.userId,
            "PositionId":"",
            "ProfileId":this.state.profile,
            "StatusId":"4"
        }
        await axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Training/GetDocumentMonitorings", params)
        .then(res => {
            const data = res.data;
            this.setState({employeeGRID : data.monitorings,employeeDDL : data.monitorings, isloading:false})  
        })
        .catch(error=>{
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade        :   true
            })
        })
    }  
     
    handleChangeClient = async(e) => {
        this.setState({alerttype:"",isshow:false,color:"",message:"",fade:true,isloading:false});
        if(e.length===0)
        {
            this.state.clientId= ''
            return
        }
        this.state.clientId= e[0].id
        this.setState({isloading:true})
        const params = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.clientId,
            "UserId":this.state.userinfo.userId,
            "PositionId":"",
            "ProfileId":this.state.profile,
            "StatusId":"4"
        }
        await axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Training/GetDocumentMonitorings", params)
        .then(res => {
            const data = res.data;
            this.setState({employeeDDL : data.monitorings, isloading:false})  
        })
        .catch(error=>{
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade        :   true
            })
        })
    }
    handleChangeEmployee = (e) => {
        this.setState({alerttype:"",isshow:false,color:"",message:"",fade:true});
        if(e.length===0)
        {
            this.state.profileId= ''
            return
        }
        this.state.profileId= e[0].profileId
    }
    handleSearchClick = event => {
        this.setState({isloading:true})
        this.GetTransmittedDocuments()
    }
    handleModalShow = (e) =>{
        this.setState({openModal:true})
        let obj = {}
        this.child.initialize(e)
    }
    handleModalClose = (e) =>{
        this.setState({openModal:false})
        this.handleSearchClick()
    }
    render() { 

        const cols = [
        {
            dataField: 'client',
            text: 'CLIENT',
            editable:false,
            headerStyle: (colum, colIndex) => {
                return { textAlign: 'left',width:'20%'}
            },
            style:{textAlign:'left',width:'20%'}
        },
        {
            dataField: 'employeeName',
            text: 'EMPLOYEE NAME',
            editable:false,
            headerStyle: (colum, colIndex) => {
                return { textAlign: 'left',width:'20%'}
            },
            style:{textAlign:'left',width:'20%'}
        },
        {
            dataField: 'position',
            text: 'POSITION',
            editable:false,
            headerStyle: (colum, colIndex) => {
                return { textAlign: 'left',width:'18%'}
            },
            style:{textAlign:'left',width:'18%'}
        },
        {
            dataField: 'location',
            text: 'LOCATION',
            editable:false,
            headerStyle: (colum, colIndex) => {
                return { textAlign: 'left',width:'20%'}
            },
            style:{textAlign:'left',width:'20%'}
        },
        {
            dataField: 'deploymentDate',
            text: 'DATE DEPLOYED',
            editable:false,
            headerStyle: (colum, colIndex) => {
                return { textAlign: 'center',width:'8%'}
            },
            style:{textAlign:'center',width:'8%'}
        },
        {
            dataField: 'aging',
            text: 'AGING',
            editable:false,
            headerStyle: (colum, colIndex) => {
                return { textAlign: 'center',width:'7%'}
            },
            style:{textAlign:'center',width:'7%'}
        },
        {
            dataField: 'action',
            text: 'ACTION',
            editable:false,
            headerStyle: (colum, colIndex) => {
                return { textAlign: 'center',width:'7%'}
            },
            style:{textAlign:'center',width:'7%'},
            formatter   :   (cell, row, isSelect) => {
                return (
                    <Button onClick={e => this.handleModalShow(row)}
                        style={{height:'18px',paddingTop:'0',marginTop:'-4px',fontSize:'11px'}} 
                        variant="link"
                    >Approve</Button>
                );
            },
            
        }] 
        const selectRow = {
            mode: 'checkbox',
            hideSelectAll: true,
            clickToSelectAndEditCell: false,
            onSelect: (row, isSelect, rowIndex, e) => {
                
            }
        };
        return(
            <div>
                <Banner />
                <Container className="mt-5" fluid>
                    <Card>
                        <Card.Header>DOCUMENT TRANSMITTAL (FOR APPROVAL)</Card.Header>
                        <Card.Body>
                            <Form>
                                <Alert isOpen={this.state.isshow} color={this.state.color} fade={this.state.fade} className={this.state.isshow ? 'display-block' : 'display-none'}>
                                    <div className={this.state.color}></div> 
                                    {this.state.message}
                                </Alert>
                                <Form.Group as={Row} controlId="formPlaintextEmail">
                                    <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                       CLIENT NAME :
                                    </Form.Label>
                                    <Col sm="11">
                                        <Typeahead
                                            labelKey='name'
                                            id="basic-example"
                                            onChange={this.handleChangeClient}
                                            options={this.state.clientDDL}
                                        />
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row}>
                                    <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                        EMPLOYEE NAME :
                                    </Form.Label>
                                    <Col sm="11">
                                        <Typeahead
                                            labelKey='employeeName'
                                            id="basic-example"
                                            onChange={this.handleChangeEmployee}
                                            options={this.state.employeeDDL}
                                        />
                                    </Col>
                                </Form.Group>
                                <ButtonToolbar sm={12} className="mt-1">
                                    <Button variant="primary" className="ml-auto" onClick={this.handleSearchClick}>
                                        Search
                                    </Button>
                                </ButtonToolbar>
                                <Card.Header className="mt-5">EMPLOYEE LIST</Card.Header>
                                <div >
                                    <BootstrapTable
                                        keyField = "id"
                                        data = {this.state.employeeGRID }
                                        columns = { cols }
                                        pagination={ paginationFactory({sizePerPageRenderer}) }
                                        noDataIndication={ () => <div style={{width:'100%'}}>No record found.</div> }
                                        rowClasses="noser-table-row-class no-checkbox"
                                        striped
                                        hover
                                        condensed
                                        selectRow={selectRow}
                                        cellEdit = { cellEditFactory({
                                            mode: 'dbclick',
                                            blurToSave: true,
                                            afterSaveCell: (oldValue, newValue, row, column) => {
                                                //this.GridDataModified(oldValue, newValue, row.id, column.dataField)
                                                }
                                            })
                                        }
                                    />
                                </div>
                            </Form>
                        </Card.Body>
                    </Card>
                    
                </Container>
                <NoserLoading show={this.state.isloading} />
                <TransmittedDocumentApproval 
                    show={this.state.openModal}
                    onHide={this.handleModalClose}
                    onRefModal={ref => (this.child = ref)}
                />
            </div> 
        )
    }

}

export  default TransmittedDocuments
