import {
    React,Component,BootstrapTable,
    paginationFactory,Button, ButtonToolbar, Card, 
    Form, Col, Row, axios, Typeahead, TimePicker,
    cellEditFactory ,moment, NavLink, Alert, AppConfiguration,
    NoserLoading, Noser, Container, Modal, DatePicker,Dropdown, DropdownButton, props,
    Tabs, Tab
} 
from '../../../noser-hris-component';


class StandardBillingModalCreate extends Component {
    constructor(props) {
        super(props)
        this.state ={
            userinfo        :   [],
            isloading       :   false,
            isshow          :   false,
            alerttype       :   "",
            message         :   "",
            color           :   "",
            fade            :   true,

            rateId          :   "0",
            clientName      :   "",
            branchName      :   "",
            rateType        :   "",
            adminfeeType    :   "",

            clientId        :   "",
            locationId      :   "",
            rateTypeId      :   "",
            adminFeeTypeId  :   "",
            locationId      :   "",
            isPercentage    :   true,
            isConvertHrs    :   false,
            feeRate         :   "",
            additionalFieldsDDL:[],
            otherFieldsDDL     :[],
            exclusionFieldsDDL :[],
            additionalFieldsGRID:[],
            otherFieldsGRID     :[],
            exclusionFieldsGRID :[],
            deladditionalFieldsGRID:[],
            delotherFieldsGRID     :[],
            delexclusionFieldsGRID :[],

            fieldId         :   "",
            fieldtypeId     :   "",
            fieldName       :   "",

            otherFeeRate    :   "",
            isBasicBillingRate: false,
            isPremiumBillingRate:false
        }
    }
    componentDidMount(){
        this.state.userinfo = JSON.parse(sessionStorage.getItem("userData"))
        this.props.onStandardCreateRef(this)
    }
    componentWillUnmount() {
        this.props.onStandardCreateRef()
    }
    
    handleModalClose = () => {
        this.props.onHide();
    }
    initialize = (e) => {
        this.state.userinfo = JSON.parse(sessionStorage.getItem("userData"))
        this.setState({
            isloading       :   false,
            isshow          :   false,
            alerttype       :   "",
            message         :   "",
            color           :   "",
            fade            :   true,

            rateId          :   e.rateId,
            clientName      :   e.clientName,
            branchName      :   e.branchName,
            rateType        :   e.rateType,
            adminfeeType    :   e.adminfeeType,

            clientId        :   e.clientId,
            locationId      :   e.locationId,
            rateTypeId      :   e.rateTypeId,
            adminFeeTypeId  :   e.adminFeeTypeId,
            isPercentage    :   e.isPercentage,
            isConvertHrs    :   e.isConvertHrs,            
            feeRate         :   e.feeRate,
            additionalFieldsDDL:[],
            otherFieldsDDL     :[],
            exclusionFieldsDDL :[],

            additionalFieldsGRID:e.additionalFieldsGRID,
            otherFieldsGRID     :e.otherFieldsGRID,
            exclusionFieldsGRID :e.exclusionFieldsGRID,

            otherFeeRate    :   e.otherFeeRate,
            isBasicBillingRate:e.isBasicBillingRate,
            isPremiumBillingRate:e.isPremiumBillingRate
        })
        this.GetPayrollFields();
    }
    GetPayrollFields() {
        this.setState({isloading:true})
        const params = {
            "IpAddress"     :   "0.0.0.0",
            "ClientId"      :   this.state.userinfo.clientId,
            "UserId"        :   this.state.userinfo.userId,
            "TypeId"        :   "",
            "CrDr"          :   "",
        };

        axios.post(AppConfiguration.Setting().noserapiendpoint + "BillingConfiguration/GetPayrollFields",  params)
        .then(res => {
            const data = res.data;
            this.setState({
                additionalFieldsDDL:data.payrollFields,
                otherFieldsDDL:data.payrollFields,
                exclusionFieldsDDL:data.payrollFields,
                isloading:false
            });
        })
        .catch(error=>{
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade        :   true
            })
        })
    }
    handleRemoveAdditional = () =>{
        this.state.alerttype="";this.state.isshow=false;this.state.color="";this.state.message="";this.state.fade=true;
        let additionalFieldsGRID = this.state.additionalFieldsGRID
        let deladditionalFieldsGRID = this.state.deladditionalFieldsGRID

        if(additionalFieldsGRID.filter(x=>x.isDeleted==="1").length===0)
        {
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "warning",
                message     :   "Please select additional field to remove.",
                fade        :   true
            });
            return 
        }
        additionalFieldsGRID.filter(x=>x.isDeleted==="1").map(function(item,idx){
            deladditionalFieldsGRID.push(item)
        })
        this.setState({
            additionalFieldsGRID: additionalFieldsGRID.filter(x=>x.isDeleted==="0"),
            deladditionalFieldsGRID: deladditionalFieldsGRID
        })        
    }
    handleRemoveOthers = () =>{
        this.state.alerttype="";this.state.isshow=false;this.state.color="";this.state.message="";this.state.fade=true;
        let otherFieldsGRID = this.state.otherFieldsGRID
        let delotherFieldsGRID = this.state.delotherFieldsGRID

        if(otherFieldsGRID.filter(x=>x.isDeleted==="1").length===0)
        {
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "warning",
                message     :   "Please select other field to remove.",
                fade        :   true
            });
            return 
        }
        otherFieldsGRID.filter(x=>x.isDeleted==="1").map(function(item,idx){
            delotherFieldsGRID.push(item)
        })

        this.setState({
            otherFieldsGRID: otherFieldsGRID.filter(x=>x.isDeleted==="0"),
            delotherFieldsGRID: delotherFieldsGRID
        })        
    }
    handleRemoveExclusion = () =>{
        this.state.alerttype="";this.state.isshow=false;this.state.color="";this.state.message="";this.state.fade=true;
        let exclusionFieldsGRID = this.state.exclusionFieldsGRID
        let delexclusionFieldsGRID = this.state.delexclusionFieldsGRID

        if(exclusionFieldsGRID.filter(x=>x.isDeleted==="1").length===0)
        {
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "warning",
                message     :   "Please select exclusion field to remove.",
                fade        :   true
            });
            return 
        }
        exclusionFieldsGRID.filter(x=>x.isDeleted==="1").map(function(item,idx){
            delexclusionFieldsGRID.push(item)
        })
        
        this.setState({
            exclusionFieldsGRID: exclusionFieldsGRID.filter(x=>x.isDeleted==="0"),
            delexclusionFieldsGRID: delexclusionFieldsGRID
        })        
    }
    handleAddAdditional = () =>{
        this.state.alerttype="";this.state.isshow=false;this.state.color="";this.state.message="";this.state.fade=true;
        let additionalFieldsGRID = this.state.additionalFieldsGRID
        
        if(this.state.fieldId==="")
        {
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "warning",
                message     :   "Please select additional field.",
                fade        :   true
            });
            return 
        }
        if(additionalFieldsGRID.filter(x=>x.payrollFieldId===this.state.fieldId &&
                                        x.payrollFieldTypeId===this.state.fieldtypeId).length>0)
        {
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "warning",
                message     :   this.state.fieldName + " already exist in To Be Additional List",
                fade        :   true
            });
            return 
        }
        
        let today = new Date()
        const ids = additionalFieldsGRID.map(x => x.id);
        const sorted = ids.sort((a, b) => a - b);
        const uid = moment(today).format('SS');
        let newId = parseInt(sorted[sorted.length-1])+1+uid;

        additionalFieldsGRID.push({
            "payrollFieldId"        :   this.state.fieldId,
            "payrollFieldTypeId"    :   this.state.fieldtypeId,
            "payrollField"          :   this.state.fieldName,
            "isDeleted"             :   "0",
            "id":newId
        })
        
        this.setState({additionalFieldsGRID: additionalFieldsGRID})
        this.refs.addRef.clear()
        this.state.fieldId=""
        this.state.fieldName=""
        this.state.fieldtypeId=""
    }
    handleAddOthers = () =>{
        this.state.alerttype="";this.state.isshow=false;this.state.color="";this.state.message="";this.state.fade=true;
        let otherFieldsGRID = this.state.otherFieldsGRID
        
        if(this.state.fieldId==="")
        {
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "warning",
                message     :   "Please select other field.",
                fade        :   true
            });
            return 
        }
        if(otherFieldsGRID.filter(x=>x.payrollFieldId===this.state.fieldId &&
                                        x.payrollFieldTypeId===this.state.fieldtypeId).length>0)
        {
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "warning",
                message     :   this.state.fieldName + " already exist in To Be Additional List",
                fade        :   true
            });
            return 
        }
        
        let today = new Date()
        const ids = otherFieldsGRID.map(x => x.id);
        const sorted = ids.sort((a, b) => a - b);
        const uid = moment(today).format('SS');
        let newId = parseInt(sorted[sorted.length-1])+1+uid;

        otherFieldsGRID.push({
            "payrollFieldId"        :   this.state.fieldId,
            "payrollFieldTypeId"    :   this.state.fieldtypeId,
            "payrollField"          :   this.state.fieldName,
            "isDeleted"             :   "0",
            "id":newId
        })
        this.setState({otherFieldsGRID: otherFieldsGRID})
        this.refs.othRef.clear()
        this.state.fieldId=""
        this.state.fieldName=""
        this.state.fieldtypeId=""
    }
    handleAddExclusion = () =>{
        this.state.alerttype="";this.state.isshow=false;this.state.color="";this.state.message="";this.state.fade=true;
        let exclusionFieldsGRID = this.state.exclusionFieldsGRID
        
        if(this.state.fieldId==="")
        {
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "warning",
                message     :   "Please select exclusion field.",
                fade        :   true
            });
            return 
        }
        if(exclusionFieldsGRID.filter(x=>x.payrollFieldId===this.state.fieldId &&
                                        x.payrollFieldTypeId===this.state.fieldtypeId).length>0)
        {
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "warning",
                message     :   this.state.fieldName + " already exist in To Be Additional List",
                fade        :   true
            });
            return 
        }
        
        let today = new Date()
        const ids = exclusionFieldsGRID.map(x => x.id);
        const sorted = ids.sort((a, b) => a - b);
        const uid = moment(today).format('SS');
        let newId = parseInt(sorted[sorted.length-1])+1+uid;

        exclusionFieldsGRID.push({
            "payrollFieldId"        :   this.state.fieldId,
            "payrollFieldTypeId"    :   this.state.fieldtypeId,
            "payrollField"          :   this.state.fieldName,
            "isDeleted"             :   "0",
            "id":newId
        })
        this.setState({exclusionFieldsGRID: exclusionFieldsGRID})
        this.refs.excRef.clear()
        this.state.fieldId=""
        this.state.fieldName=""
        this.state.fieldtypeId=""
    }
    handleAdditionalChanged = (e) =>{
        this.state.alerttype="";this.state.isshow=false;this.state.color="";this.state.message="";this.state.fade=true;
        if(e.length==0)
        {
            this.setState({fieldId:'',fieldName:'',fieldtypeId:'',})
            return
        }
        this.state.fieldId= e[0].id
        this.state.fieldName= e[0].name
        this.state.fieldtypeId= e[0].typeId
    }
    handleOtherChanged = (e) =>{
        this.state.alerttype="";this.state.isshow=false;this.state.color="";this.state.message="";this.state.fade=true;
        if(e.length==0)
        {
            this.setState({fieldId:'',fieldName:'',fieldtypeId:'',})
            return
        }
        this.state.fieldId= e[0].id
        this.state.fieldName= e[0].name
        this.state.fieldtypeId= e[0].typeId
    }
    handleExclusionChanged = (e) =>{
        this.state.alerttype="";this.state.isshow=false;this.state.color="";this.state.message="";this.state.fade=true;
        if(e.length==0)
        {
            this.setState({fieldId:'',fieldName:'',fieldtypeId:'',})
            return
        }
        this.state.fieldId= e[0].id
        this.state.fieldName= e[0].name
        if(e[0].typeId==="1"){
            this.state.fieldtypeId= "4"
        }
        else if(e[0].typeId==="2"){
            this.state.fieldtypeId= "5"
        }
        else{
            this.state.fieldtypeId="3"
        }
        //this.state.fieldtypeId= e[0].typeId
    }
    handleCheckboxChanged = (e) =>{
        this.setState({[e.target.name]: e.target.checked})
    }
    handleFeeRateChanged = (e) =>{
        this.setState({[e.target.name]: e.target.value})
    }
    IsNumeric(evt){
        var theEvent = evt || window.event;
        var key = theEvent.keyCode || theEvent.which;
        key = String.fromCharCode( key );
        var regex = /^\d+(.\d+)?$/;
        if( !regex.test(key) ) {
        theEvent.returnValue = false;
        if(theEvent.preventDefault) theEvent.preventDefault();
        }
    }
    handleSubmit = () => {
        this.setState({isloading:true})
        let params = {}
        if(this.state.rateId==="0"){
            params = {
                "IpAddress"             :   "0.0.0.0",
                "ClientId"              :   this.state.clientId,
                "UserId"                :   this.state.userinfo.userId,
                "Id"                    :   this.state.rateId,
                "RateTypeId"            :   this.state.rateTypeId,
                "AdminFeeTypeId"        :   this.state.adminFeeTypeId,
                "AreaId"                :   "",
                "CostCenterId"          :   "",
                "LocationId"            :   this.state.locationId,
                "IsPercentage"          :   (this.state.isPercentage)? "1" : "0",
                "IsConvertHrsToDays"    :   (this.state.isConvertHrs) ? "1" : "0",
                "FeeRate"               :   this.state.feeRate,
                "AdditionalFields"      :   this.state.additionalFieldsGRID,
                "OtherFields"           :   this.state.otherFieldsGRID,
                "ExclusionFields"       :   this.state.exclusionFieldsGRID,

                "OtherFeeRate"          :   this.state.otherFeeRate,
                "IsPremiumBillingRate"  :   this.state.isPremiumBillingRate ? "1" : "0",
                "IsBasicBillingRate"    :   this.state.isBasicBillingRate ? "1" : "0",
            };
        }
        else{
            let additionalFieldsGRID = this.state.additionalFieldsGRID
            let deladditionalFieldsGRID = this.state.deladditionalFieldsGRID
            deladditionalFieldsGRID.map(function(itm,idx){
                additionalFieldsGRID.push(itm)
            })
            
            let otherFieldsGRID = this.state.otherFieldsGRID
            let delotherFieldsGRID = this.state.delotherFieldsGRID
            delotherFieldsGRID.map(function(itm,idx){
                otherFieldsGRID.push(itm)
            })
            

            let exclusionFieldsGRID = this.state.exclusionFieldsGRID
            let delexclusionFieldsGRID = this.state.delexclusionFieldsGRID
            delexclusionFieldsGRID.map(function(itm,idx){
                exclusionFieldsGRID.push(itm)
            })

            params = {
                "IpAddress"             :   "0.0.0.0",
                "ClientId"              :   this.state.clientId,
                "UserId"                :   this.state.userinfo.userId,
                "billingRates"          :   [
                    {
                        "Id"                    :   this.state.rateId,
                        "ClientId"              :   this.state.clientId,
                        "RateTypeId"            :   this.state.rateTypeId,
                        "AdminFeeTypeId"        :   this.state.adminFeeTypeId,
                        "AreaId"                :   "",
                        "CostCenterId"          :   "",
                        "LocationId"            :   this.state.locationId,
                        "IsPercentage"          :   (this.state.isPercentage)? "1" : "0",
                        "IsConvertHrsToDays"    :   (this.state.isConvertHrs) ? "1" : "0",
                        "FeeRate"               :   this.state.feeRate,
                        "AdditionalFields"      :   additionalFieldsGRID,//this.state.additionalFieldsGRID,
                        "OtherFields"           :   otherFieldsGRID,
                        "ExclusionFields"       :   exclusionFieldsGRID,
                        "IsDeleted"             :   "0",

                        "OtherFeeRate"          :   this.state.otherFeeRate,
                        "IsPremiumBillingRate"  :   this.state.isPremiumBillingRate ? "1" : "0",
                        "IsBasicBillingRate"    :   this.state.isBasicBillingRate ? "1" : "0",
                    }
                ]
            };
        }
        let additionalFieldsGRID = this.state.additionalFieldsGRID
        let otherFieldsGRID = this.state.otherFieldsGRID
        let exclusionFieldsGRID = this.state.exclusionFieldsGRID
        this.setState({
            additionalFieldsGRID:additionalFieldsGRID.filter(x=>x.isDeleted==="0"),
            otherFieldsGRID:otherFieldsGRID.filter(x=>x.isDeleted==="0"),
            exclusionFieldsGRID:exclusionFieldsGRID.filter(x=>x.isDeleted==="0")
        })

        let url = this.state.rateId==="0" ? "BillingConfiguration/AddBillingRate" : "BillingConfiguration/EditBillingRate"
        axios
            .post(
                AppConfiguration.Setting().noserapiendpoint + url,  params
            )
            .then(res => {
                const data = res.data;
                if(data.status=="1"){
                    this.setState({
                        isloading   :   false,
                        alerttype   :   "Success!",
                        isshow      :   true,
                        color       :   "success",
                        message     :   data.message,
                        fade        :   true
                    });
                
                }
                else {
                    this.setState({
                        isloading   :   false,
                        alerttype   :   "Error!",
                        isshow      :   true,
                        color       :   "danger",
                        message     :   data.message,
                        fade        :   true
                    });
                }
            })
            .catch(error=>{
            this.setState(  {
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade            :   true
            })
        })
    }

    render() {
        const colAdditional = [
            {
                dataField: 'payrollField',
                text: 'Additional Fields',
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'left',width:'100%'}},
                style:{textAlign:'left'}
            }
        ]
        const colOthers = [
            {
                dataField: 'payrollField',
                text: 'Other Fields',
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'left',width:'100%'}},
                style:{textAlign:'left'}
            }
        ]
        const colExclusion = [
            {
                dataField: 'payrollField',
                text: 'Exclusion Fields',
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'left',width:'100%'}},
                style:{textAlign:'left'}
            }
        ]
        const selectAddRow = {
            mode: 'checkbox',
            clickToSelect: true,
            hideSelectAll: true,
            onSelect: (row, isSelect, rowIndex, e) => {
                this.state.additionalFieldsGRID.map(function(item,i){
                    if(item.id===row.id)
                        {
                        item.isDeleted = isSelect ? "1" : "0"
                    }
                })
            }
        };
        const selectOthRow = {
            mode: 'checkbox',
            clickToSelect: true,
            hideSelectAll: true,
            onSelect: (row, isSelect, rowIndex, e) => {
                this.state.otherFieldsGRID.map(function(item,i){
                    if(item.id===row.id)
                        {
                        item.isDeleted = isSelect ? "1" : "0"
                    }
                })
            }
        };
        const selectExcRow = {
            mode: 'checkbox',
            clickToSelect: true,
            hideSelectAll: true,
            onSelect: (row, isSelect, rowIndex, e) => {
                this.state.exclusionFieldsGRID.map(function(item,i){
                    if(item.id===row.id)
                        {
                        item.isDeleted = isSelect ? "1" : "0"
                    }
                })
            }
        };
        let title = ""
        if(this.state.rateTypeId==="1"){
            title = this.state.rateId==="0" ? "CREATE STANDARD RATE" : "EDIT STANDARD RATE"
        }
        else{
            title = this.state.rateId==="0" ? "CREATE CLIENT RATE" : "EDIT CLIENT RATE"
        }
    return(
        
            <Modal
                {...this.props}
                return
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                backdrop="static"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        {
                            title //this.state.rateId==="0" ? "CREATE STANDARD BILLING" : "EDIT STANDARD BILLING"
                        }
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container>
                        <Alert isOpen={this.state.isshow} color={this.state.color} fade={this.state.fade} className={this.state.isshow ? 'display-block' : 'display-none'}>
                            <div className={this.state.color}></div> 
                            {this.state.message}
                        </Alert>
                        <Form>
                        <Form.Row>
                                <Form.Group as={Col} sm={12} controlId="formGridPassword">
                                <Form.Control 
                                    type="text" 
                                    placeholder="Client Name" 
                                    autoComplete="off" 
                                    name="clientName"
                                    value={this.state.clientName}
                                    readOnly/>
                            </Form.Group>
                        </Form.Row>
                        <Form.Row>
                                <Form.Group as={Col} sm={12} controlId="formGridPassword">
                                <Form.Control 
                                    type="text" 
                                    autoComplete="off" 
                                    name="locationName"
                                    value={this.state.locationName}
                                    readOnly/>
                            </Form.Group>
                        </Form.Row>
                        <Form.Row>
                                <Form.Group as={Col} sm={12} controlId="formGridPassword">
                                <Form.Control 
                                    type="text" 
                                    placeholder="Billing Template" 
                                    autoComplete="off" 
                                    name="rateType"
                                    value={this.state.rateType}
                                    readOnly/>
                            </Form.Group>
                        </Form.Row>
                        <Form.Row>
                                <Form.Group as={Col} sm={12} controlId="formGridPassword">
                                <Form.Control 
                                    type="text" 
                                    placeholder="Default Template" 
                                    autoComplete="off" 
                                    name="adminfeeType"
                                    value={this.state.adminfeeType}
                                    readOnly/>
                            </Form.Group>
                        </Form.Row>
                        <Form.Row>
                            <Form.Group as={Col} sm={2} controlId="formGridPassword">
                                <Form.Check 
                                    type="checkbox" 
                                    label="Is Percentage Fee" 
                                    onChange={this.handleCheckboxChanged}
                                    checked={this.state.isPercentage}
                                    name="isPercentage"
                                />
                                
                            </Form.Group>
                            <Form.Group as={Col} sm={1}>
                            <span>Fee Rate:</span>
                            </Form.Group>
                            <Form.Group as={Col} sm={2} controlId="formGridPassword">
                                <Form.Control 
                                    type="text" 
                                    autoComplete="off" 
                                    name="feeRate"
                                    value={this.state.feeRate}
                                    onChange={this.handleFeeRateChanged}
                                    onKeyPress={this.IsNumeric.bind(this)}
                                />
                            </Form.Group>
                            <Form.Group as={Col} sm={4} controlId="formGridPassword">
                                <Form.Check 
                                    type="checkbox" 
                                    label="Convert Actual Hr(s) to Day(s)" 
                                    onChange={this.handleCheckboxChanged}
                                    checked={this.state.isConvertHrs}
                                    name="isConvertHrs"
                                />
                            </Form.Group>
                        </Form.Row>
                        <Form.Row>
                            <Form.Group as={Col} sm={2} controlId="formGridPassword">
                                <Form.Check 
                                    type="checkbox" 
                                    label="Is Basic Billing Rate" 
                                    onChange={this.handleCheckboxChanged}
                                    checked={this.state.isBasicBillingRate}
                                    name="isBasicBillingRate"
                                />
                            </Form.Group>
                            <Form.Group as={Col} sm={3} controlId="formGridPassword">
                                <Form.Check 
                                    type="checkbox" 
                                    label="Is Premium Billing Rate" 
                                    onChange={this.handleCheckboxChanged}
                                    checked={this.state.isPremiumBillingRate}
                                    name="isPremiumBillingRate"
                                />
                            </Form.Group>
                            <Form.Group as={Col} sm={2}>
                            <span>Other Fee Rate:</span>
                            </Form.Group>
                            <Form.Group as={Col} sm={2} controlId="formGridPassword">
                                <Form.Control 
                                    type="text" 
                                    autoComplete="off" 
                                    name="otherFeeRate"
                                    value={this.state.otherFeeRate}
                                    onChange={this.handleFeeRateChanged}
                                    onKeyPress={this.IsNumeric.bind(this)}
                                />
                            </Form.Group>
                        </Form.Row>
                        </Form>
                        <Card>
                        <Card.Body style={{border:"none !important"}}>
                        <Tabs  onSelect={this.handleSelect} activeKey={this.state.key} defaultActiveKey="default" transition={false} id="noanim-tab-example" >
                            <Tab eventKey="default" title="ADDITIONAL FIELDS">
                                <Card className="card-tab">
                                    <div className="card-header-tab"></div>
                                    <Card.Body>
                                    <Form.Group as={Row} controlId="formPlaintextPassword">
                                        <Col sm="9">
                                            <Typeahead
                                                labelKey='name'
                                                id="basic-example"
                                                onChange={this.handleAdditionalChanged}
                                                options={this.state.additionalFieldsDDL}
                                                placeholder="Select Additional Field"
                                                ref="addRef"
                                            />
                                        </Col>
                                        <Col sm="3">
                                            <ButtonToolbar>
                                                <Button variant="success" className="ml-auto noser-button-mr1" onClick = { this.handleAddAdditional }>ADD</Button>
                                                <Button variant="danger" className="noser-button" onClick={this.handleRemoveAdditional}>REMOVE</Button>
                                            </ButtonToolbar>
                                        </Col>
                                    </Form.Group>

                                    <Card.Header></Card.Header>
                                    <div>
                                        <BootstrapTable
                                            keyField = "id"
                                            data = { this.state.additionalFieldsGRID }
                                            columns = { colAdditional }
                                            selectRow = { selectAddRow }
                                            rowClasses="noser-table-row-class"
                                            striped
                                            hover
                                            condensed
                                            cellEdit = { cellEditFactory({
                                                mode: 'dbclick',
                                                blurToSave: true,
                                                afterSaveCell: (oldValue, newValue, row, column) => {
                                                    this.GridDataModified(oldValue, newValue, row.id, column.dataField)
                                                    }
                                                })
                                            }
                                            noDataIndication={ () => <div style={{width:'100%'}}>No record found.</div> }
                                        />
                                    </div>
                                    </Card.Body>
                                </Card>
                            </Tab>
                            <Tab eventKey="others" title="OTHER FIELDS">
                                <Card className="card-tab">
                                    <div className="card-header-tab"></div>
                                    <Card.Body>
                                    <Form.Group as={Row} controlId="formPlaintextPassword">
                                        <Col sm="9">
                                            <Typeahead
                                                labelKey='name'
                                                id="basic-example"
                                                onChange={this.handleOtherChanged}
                                                options={this.state.otherFieldsDDL}
                                                placeholder="Select Other Field"
                                                ref="othRef"
                                            />
                                        </Col>
                                        <Col sm="3">
                                        <ButtonToolbar>
                                            <Button variant="success" className="ml-auto noser-button-mr1" onClick = { this.handleAddOthers }>ADD</Button>
                                            <Button variant="danger" className="noser-button" onClick={this.handleRemoveOthers}>REMOVE</Button>
                                        </ButtonToolbar>
                                        </Col>
                                    </Form.Group>

                                    <Card.Header></Card.Header>
                                    <div>
                                        <BootstrapTable
                                            keyField = "id"
                                            data = { this.state.otherFieldsGRID }
                                            columns = { colOthers }
                                            selectRow = { selectOthRow }
                                            rowClasses="noser-table-row-class"
                                            striped
                                            hover
                                            condensed
                                            cellEdit = { cellEditFactory({
                                                mode: 'dbclick',
                                                blurToSave: true,
                                                afterSaveCell: (oldValue, newValue, row, column) => {
                                                    this.GridDataModified(oldValue, newValue, row.id, column.dataField)
                                                    }
                                                })
                                            }
                                            noDataIndication={ () => <div style={{width:'100%'}}>No record found.</div> }
                                        />
                                    </div>
                                </Card.Body>
                                </Card>
                            </Tab>
                            <Tab eventKey="exclusion" title="EXCLUSION FIELDS">
                                <Card className="card-tab">
                                    <div className="card-header-tab"></div>
                                    <Card.Body>
                                    <Form.Group as={Row} controlId="formPlaintextPassword">
                                        <Col sm="9">
                                            <Typeahead
                                                labelKey='name'
                                                id="basic-example"
                                                onChange={this.handleExclusionChanged}
                                                options={this.state.exclusionFieldsDDL}
                                                placeholder="Select Exclusion Field"
                                                ref="excRef"
                                            />
                                        </Col>
                                        <Col sm="3">
                                            <ButtonToolbar>
                                                <Button variant="success" className="ml-auto noser-button-mr1" onClick = { this.handleAddExclusion }>ADD</Button>
                                                <Button variant="danger" className="noser-button" onClick={this.handleRemoveExclusion}>REMOVE</Button>
                                            </ButtonToolbar>
                                        </Col>
                                    </Form.Group>

                                    <Card.Header></Card.Header>
                                    <div>
                                        <BootstrapTable
                                            keyField = "id"
                                            data = { this.state.exclusionFieldsGRID }
                                            columns = { colExclusion }
                                            selectRow = { selectExcRow }
                                            rowClasses="noser-table-row-class"
                                            striped
                                            hover
                                            condensed
                                            cellEdit = { cellEditFactory({
                                                mode: 'dbclick',
                                                blurToSave: true,
                                                afterSaveCell: (oldValue, newValue, row, column) => {
                                                    this.GridDataModified(oldValue, newValue, row.id, column.dataField)
                                                    }
                                                })
                                            }
                                            noDataIndication={ () => <div style={{width:'100%'}}>No record found.</div> }
                                        />
                                    </div>
                                </Card.Body>
                                </Card>
                            </Tab>
                        </Tabs>
                        </Card.Body>
                        </Card>
                        
                    </Container>
                </Modal.Body>
                <Modal.Footer>
                    <ButtonToolbar >
                        <Button variant="success" className="ml-auto noser-button-mr1" onClick = { this.handleSubmit }>Save</Button>
                        <Button variant="danger" className="noser-button-mr15" onClick={this.handleModalClose}>Close</Button>
                    </ButtonToolbar>
            </Modal.Footer>
                <NoserLoading show={this.state.isloading} />
        </Modal>
        );
    }

}
export  default StandardBillingModalCreate