import {
    React,Component,BootstrapTable, Type,
    paginationFactory,Button, ButtonToolbar, Card,
    Form, Col, Row, Container, axios, Typeahead, Banner, TimePicker,
    cellEditFactory ,moment, NavLink, Alert, AppConfiguration,
    NoserLoading, Noser, sizePerPageRenderer, DatePicker
} 
from '../../noser-hris-component';

class ManningBatchApprove extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userinfo        :   [],
            isloading       :   false,
            isshow          :   false,
            alerttype       :   "",
            message         :   "",
            color           :   "",
            fade            :   true,
            selected: [],
            
            selectedStatus:'',
            selectedStatusId:"",
            approveId:"",
            statusList:[],
            employeeProfileList:[],
            newEmployeeProfileList: [],
            employeeProfileListGrid:[],
            profileId:"",
            selectedEmployeeName:"",
            selectedEmployeeNameId:"",
            selectedClientName:"",
            selectedClientId:"",
            getClientList:[],
            employeeDDL : [],
            

        }
    }

    componentDidMount(){
        const sleep = (milliseconds) => {return new Promise(resolve => setTimeout(resolve, milliseconds))}
        this.state.userinfo = JSON.parse(sessionStorage.getItem("userData"))
        //this.GetSubmittedEmployeeProfiles();
        this.getStatus();
        this. getClient();
    
    }


    getClient(){
        this.setState({isloading:true,})

        const getParams = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.userinfo.clientId,
            "UserId":this.state.userinfo.userId,
        }

        axios
            .post(AppConfiguration.Setting().noserapiendpoint + "Client/GetClientList", getParams)
            .then(res => {
                //console.log("Client List ");
                //console.log(res.data);
                this.setState({
                    isloading:false,
                    getClientList : res.data.clients
                })
            })
    }

    handleChangeClient= (e) => {
        
        if(e.length == 0) {
            this.state.selectedClientName=""
            this.state.selectedClientId=""
            return
        } 
        this.state.selectedClientId = e[0].id
        this.state.selectedClientName = e[0].name
        // this.setState({isloading:true})
        
        this.GetEmployeeProfiles();
        //this.getStatus();
    }



    GetEmployeeProfiles() {
        this.setState({isloading:true})
        const employeeParams = {
            
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.selectedClientId,
            "UserId":this.state.userinfo.userId,
            "RoleId":this.state.userinfo.roleId,
            "ProfileId":"",
            "StatusId":"",
            
        };
        // //console.log("Test123");
        // //console.log(employeeParams)
        
        axios
            .post(AppConfiguration.Setting().noserapiendpoint + "Administrator/GetEmployeeProfileForApprovals",  employeeParams)
        .then(res => {
            const data = res.data;
            //console.log("Test app2");
            //console.log(data);
            // this.setState({ employeeProfileList: data.employeeProfiles, isloading:false});
            this.setState({ employeeDDL: data.employeeProfiles, isloading:false});
            
        })

    }

        handleChangeEmployee= (e) => {
            //console.log(e)
            //console.log(this.state.profileId)
                if (e.length == 0) {
                    this.setState({selectedEmployeeName: "", selectedEmployeeNameId:"",
                    profileId:""})
                return
            }
            this.setState({
                selectedEmployeeName : e[0].name,
                selectedEmployeeNameId : e[0].id,
                profileId : e[0].profileId,
            })
           
            //this.GetSubmittedEmployeeProfiles();
           
            this.setState({isloading:false,})
            
        }

        

        handleCoverChangeSortBy = (e) => {
            if(e.length == 0) {
                this.state.selectedStatus = e[0].name
                this.state.selectedStatusId = e[0].id
                return
            } 
            this.state.selectedStatus = ""
            this.state.selectedStatusId = ""


            //console.log(this.state.selectedSortBy);
            
        } 

    getStatus(){
        const statusParams = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.userinfo.clientId,
            "UserId":this.state.userinfo.userId,
            "Code":"0021"
        };
        

        axios
            .post(AppConfiguration.Setting().noserapiendpoint + "Maintenance/GetDataReferences", statusParams)
            .then(res => {
                const data = res.data;
                this.setState({
                    statusList : res.data.dataReferences})
                    
                })
                
    }

    handleSearchClick = event => {
        this.GetSubmittedEmployeeProfiles();
    }

    GetSubmittedEmployeeProfiles() {
        this.setState({
            isloading:true
            
        })
        //console.log(this.state.selectedProfileId)
        const employeeParams = {
            
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.selectedClientId,
            "UserId":this.state.userinfo.userId,
            "RoleId":this.state.userinfo.roleId,
            "ProfileId":this.state.profileId,
            "StatusId":this.state.selectedStatusId,
            
        };
        // //console.log("Test123");
        // //console.log(employeeParams)
        
        axios
            .post(AppConfiguration.Setting().noserapiendpoint + "Administrator/GetEmployeeProfileForApprovals",  employeeParams)
        .then(res => {
            const data = res.data;
            //console.log("Test app2");
            //console.log(data);
            // this.setState({ employeeProfileList: data.employeeProfiles, isloading:false});
            this.setState({ employeeProfileListGrid: data.employeeProfiles, isloading:false});
            
        })

    }

    // handleSearchClick = event => {
    //     this.setState({employeeProfileListGrid:[]})
        
    //     const employeeParams = {
           
    //         "IpAddress":"0.0.0.0",
    //         "ClientId": this.state.selectedClientId,
    //         "UserId":this.state.userinfo.userId,
    //         "RoleId":this.state.userinfo.roleId,
    //         "ProfileId":this.state.profileId,
    //         "StatusId":this.state.selectedSortById,
    //     };
    //     // //console.log("Test 1");
    //     // //console.log(employeeParams)
    //     // //console.log(this.state.selectedClientName)
    //     // //console.log(this.state.profileId)
    //     axios
    //         .post(AppConfiguration.Setting().noserapiendpoint + "Administrator/GetEmployeeProfileForApprovals",  employeeParams)
    //     .then(res => {
    //         const data = res.data;
    //         // //console.log("Test app2");
    //         // //console.log(data);
    //         this.setState({ employeeProfileListGrid: data.employeeProfiles, isloading:false});
    //         if(data.status=="1"){
    //             this.setState({
    //                 isloading       :   false,
    //                 alerttype       :   "Success!",
    //                 isshow          :   true,
    //                 color           :   "success",
    //                 message         :   data.message,
    //                 fade            :   true
    //             });
    //         }
    //         else{
    //             this.setState({
    //                 isloading       :   false,
    //                 alerttype       :   "Error!",
    //                 isshow          :   true,
    //                 color           :   "danger",
    //                 message         :   data.message,
    //                 fade            :   true
    //             })
    //         }
    //         })
    //         .catch(error=>{
    //         this.setState(  {
    //             isloading       :   false,
    //             alerttype       :   "Error!",
    //             isshow          :   true,
    //             color           :   "danger",
    //             message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
    //             fade            :   true
    //         })
    //     })

    // }

    handleApproveClick = event => {
        let newEmployeeProfileList = []
        this.setState({newEmployeeProfileList: [],isloading:true})
        // this.props.onButtonClick(this.input.value);
        // this.input.value = '';

        //console.log(this.state.newEmployeeProfileList)
        for (let i = 0; i < this.state.employeeProfileListGrid.length; i++) {
             if (this.state.employeeProfileListGrid[i]["isModified"] == 1) {
            
                if(this.state.userinfo.roleId== 5)
                    {this.state.employeeProfileListGrid[i]["statusId"] ="2"}
                if(this.state.userinfo.roleId== 1)
                    { this.state.employeeProfileListGrid[i]["statusId"] ="4"}
                const obj = {
                    ProfileId:this.state.employeeProfileListGrid[i]["profileId"],
                    ApprovalId:this.state.employeeProfileListGrid[i]["id"],
                    StatusId: this.state.employeeProfileListGrid[i]["statusId"]
                };
            
                this.state.newEmployeeProfileList.push(obj);
            }

        }
       
        
    
    const approveParams = {
        "IpAddress":"0.0.0.0",
        "ClientId":this.state.userinfo.clientId,
        "UserId":this.state.userinfo.userId,
        "RoleId":this.state.userinfo.roleId,
        "approvedEmployees":this.state.newEmployeeProfileList
        };
        //console.log(approveParams)
        // return
        axios
            .post(AppConfiguration.Setting().noserapiendpoint + "Administrator/SubmitApprovedEmployeeProfile", approveParams)
            .then(res => {
                const data = res.data;
                //console.log("test")
                //console.log(data)
                this.setState({isoading:false})
                this.refreshPage();
                if(data.status=="1"){
                    this.setState({
                            isloading:false,
                            alerttype:"Success!",
                            isshow:true,
                            color:"success",
                            message: data.message,
                            fade:true
                        });
                    }
                    else {
                        this.setState({
                            isloading:false,
                            alerttype:"Error!",
                            isshow:true,
                            color:"danger",
                            message: data.message,
                            fade:true
                        });
                    }
                })
    
        }

    handleRejectClick = event => {
        let newEmployeeProfileList = []
        this.setState({newEmployeeProfileList: [],isLoading:true})

        //console.log(this.state.newEmployeeProfileList)
        for (let i = 0; i < this.state.employeeProfileListGrid.length; i++) {
            if(this.state.employeeProfileListGrid[i]["isModified"] == 1){

                if(this.state.userinfo.roleId== 5)
                    {this.state.employeeProfileListGrid[i]["statusId"] ="3"}
                if(this.state.userinfo.roleId== 1)
                    { this.state.employeeProfileListGrid[i]["statusId"] ="5"}

                const obj = {
                    ProfileId:this.state.employeeProfileListGrid[i]["profileId"],
                    ApprovalId:this.state.employeeProfileListGrid[i]["id"],
                    StatusId: this.state.employeeProfileListGrid[i]["statusId"]
                };

                this.state.newEmployeeProfileList.push(obj);
            }
        }

        const approveParams = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.userinfo.clientId,
            "UserId":this.state.userinfo.userId,
            "RoleId":this.state.userinfo.roleId,
            "approvedEmployees":this.state.newEmployeeProfileList
            };
            //console.log(approveParams)
            axios
                .post(AppConfiguration.Setting().noserapiendpoint + "Administrator/SubmitApprovedEmployeeProfile", approveParams)
                .then(res => {
                    const data = res.data;
                    //console.log("test")
                    //console.log(res.data)
                    this.setState({isLoading:false})
                    this.refreshPage();
                    if(data.status=="1"){
                        this.setState({
                                isloading:false,
                                alerttype:"Success!",
                                isshow:true,
                                color:"success",
                                message: data.message,
                                fade:true
                            });
                        }
                        else {
                            this.setState({
                                isloading:false,
                                alerttype:"Error!",
                                isshow:true,
                                color:"danger",
                                message: data.message,
                                fade:true
                            });
                        }
                    })
        
            }

    refreshPage(){
        this.setState({isloading:true})

        const refreshParams = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.userinfo.clientId,
            "UserId":this.state.userinfo.userId,
            "RoleId":this.state.userinfo.roleId,
            "ProfileId":"",
             "StatusId":"",
        };

        axios
            .post(AppConfiguration.Setting().noserapiendpoint + "Administrator/GetEmployeeProfileForApprovals",  refreshParams)
            .then(res => {
                const data = res.data;
                this.setState({ employeeProfileListGrid: data.employeeProfiles});
                
            })
    
        }


    LeavePageWithourSavingChanges() {
        const isChanged=false
        for (let i = 0; i < this.state.employeeProfileListGrid.length; i++) {
            if (this.state.employeeProfileListGrid[i]["isModified"] == 1) {
                this.setState({isGridDataChanged: true})
                isChanged=true
                break;
            }
        }
        return isChanged
    }

    GridDataModified(oldValue, newValue, id, column) {
        //console.log(id)
        this.state.employeeProfileListGrid.map(function(item,i) {
            if (item.id===id)
                item.isModified = newValue!=oldValue ? "1" : "0"
            })
    }
    

    render() {
        const columns1 = [
            {
               
                dataField: 'employeeName',
                text: 'FULL NAME',
                editable: false,
               
                headerStyle : () => {
                    return { width  : "40%" };
                },
              
            },
            {
                dataField: 'status',
                text: 'APPROVAL STATUS',
                editable: false,
                headerStyle: () => {
                    return { width: "25%" };
                },
            },
            {
                dataField: 'createdDate',
                text: 'SUBMITTED DATE',
                editable: false,
                headerStyle: () => {
                    return { width: "25%" };
                },
             },
            {
                dataField: 'action',
                text: 'ACTION',
                editable:false,
                formatter   :   (cell, row, isSelect) => {
                        return( 
                            <NavLink to={{pathname:"/manningbatchapproveview",params:{data:row} }}>
                                <Button style={{height:'16px',paddingTop:'0',marginTop:'-3px',fontSize:'11px'}} variant="link"
                                href="/manningbatchapproveview"
                                >View</Button>
                            </NavLink>
                        );
                    },
                headerStyle : () => {
                    return { width  : "10%" };
                } 
                
            }]  

        const selectRow = {
            mode: 'checkbox',
            clickToSelect: true,
            onSelect: (row, isSelect, rowIndex, e) => {
                    row.isModified = isSelect ? "1" : "0"
                    //console.log(isSelect);
                    //console.log(row);
            },
            onSelectAll: (isSelect, rows) => {
            //console.log(isSelect);
            //console.log(rows);
            rows.map(function(itm){
                    if(itm.id!= null){
                       
                        itm.isModified = isSelect ? "1" : "0"
                    }
                    
                })
            }
        };
          

        const rowEvents = {
            onClick: (e, row, rowIndex) => {
              //alert(e.row);
              
            }
        };

        return(
            <div>
                <Banner />
                    <Container className="mt-5" fluid>
                        <Card>
                            <Card.Header>PROFILE FOR APPROVAL</Card.Header>
                            <Card.Body>
                                <Alert isOpen={this.state.isshow} color={this.state.color} fade={this.state.fade} className={this.state.isshow ? 'display-block' : 'display-none'}>
                                    <div className={this.state.color}></div> 
                                    {this.state.message}
                                </Alert>
                                <Form>
                                    <Form.Group as={Row} controlId="formPlaintextEmail">
                                        <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                        CLIENT
                                        </Form.Label>
                                        <Col sm="11">
                                            <Typeahead
                                                labelKey='name'
                                                id="basic-example"
                                                onChange={this.handleChangeClient}
                                                options={this.state.getClientList}
                                            />  
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} controlId="formPlaintextEmail">
                                        <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                        EMPLOYEE
                                        </Form.Label>
                                        <Col sm="11">
                                            <Typeahead
                                                labelKey='employeeName'
                                                id="basic-example"
                                                onChange={this.handleChangeEmployee}
                                                options={this.state.employeeDDL}
                                            />   
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} controlId="formPlaintextEmail">
                                        <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                        SORT BY
                                        </Form.Label>
                                        <Col sm="11">
                                            <Typeahead
                                                labelKey='name'
                                                id="basic-example"
                                                onChange={this.handleCoverChangeSortBy}
                                                options={this.state.statusList}
                                            /> 
                                        </Col>
                                    </Form.Group>
                                    <ButtonToolbar sm={12} className="mt-3">
                                        <Button className="ml-auto noser-button-mr1" style={{minWidth:"60px",marginRight:"1pt"}} variant="primary" onClick={this.handleSearchClick}>
                                            Search
                                        </Button>&nbsp;&nbsp;
                                    </ButtonToolbar>
                                    <Card.Header className="mt-3">LIST OF APPROVAL </Card.Header>
                                    <div >
                                        <BootstrapTable
                                            ref="tbl"
                                            caption={Noser.TableHeader({title:"Search Result"})}
                                            rowClasses="noser-table-row-class no-checkbox"
                                            striped
                                            hover
                                            condensed
                                            pagination={ paginationFactory({sizePerPageRenderer}) }
                                            keyField = "id"
                                            data = { this.state.employeeProfileListGrid }
                                            columns = { columns1}
                                            selectRow = { selectRow }
                                            noDataIndication={ () => <div style={{width:'100%'}}>No record found.</div> }
                                            cellEdit = { cellEditFactory({
                                                mode: 'dbclick',
                                                blurToSave: true,
                                                afterSaveCell: (oldValue, newValue, row, column) => {
                                                            this.GridDataModified(oldValue, newValue, row.id, column)
                                                }
                                            })
                                        }
                                        rowEvents={ rowEvents }
                                        />
                                    </div>
                                    <ButtonToolbar sm={12} className="mt-3">
                                        <Button className="ml-auto noser-button-mr1" style={{minWidth:"60px",marginRight:"1pt"}} variant="success" onClick={this.handleApproveClick}>
                                            Approve
                                        </Button>
                                        <Button className="noser-button" style={{minWidth:"60px",marginRight:"1pt"}} variant="primary" onClick={this.handleRejectClick}>
                                           Reject
                                        </Button>
                                        <NavLink  to="/home">
                                            <Button className="noser-button" style={{minWidth:"60px",marginRight:"1pt"}}  variant="danger"  href="/banner">
                                                Close
                                            </Button>
                                        </NavLink>
                                    </ButtonToolbar>
                                </Form>
                            </Card.Body>
                        </Card>
                    </Container>
                    <NoserLoading show={this.state.isloading} />
            </div> 
            
        )
    }
}

export default ManningBatchApprove;