import {
    React,Component,BootstrapTable,
    paginationFactory,Button, ButtonToolbar, Card, 
    Form, Col, Row, axios, Container, Banner, Typeahead, TimePicker,
    cellEditFactory, Type, moment, NavLink, Alert, AppConfiguration,
    NoserLoading, Noser, sizePerPageRenderer, DatePicker, Accordion
} 
from '../../../noser-hris-component';
import BillableOtherPayrollModal from './BillableOtherPayrollModal';


class BillableOtherPayroll extends Component {
    state = {
        userinfo        :   [],
        isloading       :   false,
        isshow          :   false,
        alerttype       :   "",
        message         :   "",
        color           :   "",
        fade            :   true,
        disableDeleteBtn:   true,
        openModal       :   false,
        
        clientDDL       :   [],
        periodDDL       :   [],
        payrollGRID     :   [],

        clientId        :   "",
        clientName      :   "",
        periodId        :   "",
        periodName      :   "",
    }

    componentDidMount(){
        this.state.userinfo = JSON.parse(sessionStorage.getItem("userData"))
        this.GetClients()
    }
    handleModalShow = (e) =>{

        if(this.state.clientId===""){
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "Please select client.",
                fade        :   true
            })
            return
        }

        this.setState({openModal:true})
        let obj = {
            typeId:e,
            clientId:this.state.clientId,
            clientName:this.state.clientName,
            periodId:this.state.periodId,
            periodName:this.state.periodName,
        }
        this.child.initialize(obj)
    }
    handleModalClose = (e) =>{
        this.setState({openModal:false})
        this.handleSearchClick()
    }
    GetClients = async() =>{
        this.setState({isloading:true})
        const params = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.userinfo.clientId,
            "UserId":this.state.userinfo.userId,
            "EmployeeId":this.state.userinfo.roleId==="1" || 
                         this.state.userinfo.roleId==="8" ? "" : this.state.userinfo.employeeId
        }
        await axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Client/GetClientList", params)
        .then(res => {
            const data = res.data;
            let arr = []
            
            if(this.state.userinfo.roleId!=="1"){
                let fieldaccess = JSON.parse(sessionStorage.getItem("userControlAccess"))
                if(fieldaccess.filter(x=>x.accessCode==="0001").length>0){
                    let access = fieldaccess.find(x=>x.accessCode==="0001" && x.controlAccessDetails.length>0)
                    if(access.controlAccessDetails.filter(x=>x.isClient==="1").length>0){
                        let brr = access.controlAccessDetails.filter(x=>x.isClient==="1")
                        arr = data.clients.filter(f => brr.some(item => item.referenceId === f.id))
                    }
                }
                this.setState({clientDDL:arr,isloading:false})
            }
            else{
                this.setState({clientDDL:data.clients,isloading:false})
            }
        })
        .catch(error=>{
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade        :   true
            })
        })
    }
    GetPayrollPeriods = async() => {
        this.setState({isloading:true})
        const params = {
            "IpAddress"     :   "0.0.0.0",
            "ClientId"      :   this.state.clientId,
            "UserId"        :   this.state.userinfo.userId,
            "EmployeeId"    :   "",
            "IsProcessed"   :   ""
         };
         let api = ""
        if(this.state.isSpecial==="0"){
            api = "Payroll/ProcessPayroll"
        }
        else{
            api = "Payroll/ProcessSpecialClientPayroll"
        }
        await axios
             .post(
                 AppConfiguration.Setting().noserapiendpoint + "Timekeeping/GetPayrollPeriods",  params
             )
             .then(res => {
                const data = res.data;
                this.setState({periodDDL:data.payrollPeriods,isloading:false})
            })
    }
    
    handleChangedClient = (e) =>{
        this.state.alerttype="";this.state.isshow=false;this.state.color="";this.state.message="";this.state.fade=true;
        if(e.length===0){
            this.state.clientId= ''
            this.state.clientName=''
            return
        }
        this.state.clientId=e[0].id
        this.state.clientName=e[0].name
        this.GetPayrollPeriods()
    }
    handleChangedPeriod = (e) =>{
        this.state.alerttype="";this.state.isshow=false;this.state.color="";this.state.message="";this.state.fade=true;
        if(e.length===0){
            this.state.periodId=''
            this.state.periodName=''
            return
        }
        this.state.periodId=e[0].periodId
        this.state.periodName=e[0].payPeriod
    }
    handleSearchClick = async()=>{
        this.setState({isloading:true})

        if(this.state.clientId===""){
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "Please select client.",
                fade        :   true
            })
            return
        }

        const params = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.clientId,
            "UserId":this.state.userinfo.userId,
            "PayPeriodId":this.state.periodId,
        }
        
        await axios
        .post(AppConfiguration.Setting().noserapiendpoint + "OtherPayroll/GetOtherPayrolls", params)
        .then(res => {
            const data = res.data
            console.log(data)
            if(data.status==="0"){
                this.setState({
                    isloading   :   false,
                    alerttype   :   "Error!",
                    isshow      :   true,
                    color       :   "danger",
                    message     :   data.message,
                    fade        :   true,
                    disableSaveBtn: false
                });
            }
            this.setState({isloading:false,payrollGRID:data.otherPayrolls});
        })
        .catch(error=>{
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade        :   true,
                disableSaveBtn: false
            })
        })
    }
    handleClickDownload = async(row)=>{
        this.setState({isloading:true})
        const params = {
            "IpAddress"     :   "0.0.0.0",
            "ClientId"      :   this.state.clientId,
            "UserId"        :   this.state.userinfo.userId,
            "FileName"      :   row.fileName
        }
        await axios
        .post(AppConfiguration.Setting().noserapiendpoint + "OtherPayroll/DownloadPayrollFile", params,{'responseType': 'blob'})
        .then(res => {
            const url = window.URL.createObjectURL(new Blob([res.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', row.uploadFileName);
            document.body.appendChild(link);
            link.click();
            this.setState({isloading:false})
        })
        .catch(error=>{
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade        :   true
            })
        })
    }
    handleDeleteClick = async() =>{
        this.setState({isloading:true})
        if(this.state.payrollGRID.filter(x=>x.isDeleted==="1").length==="0"){
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "No selected data.",
                fade        :   true
            })
        }

        const params = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.clientId,
            "UserId":this.state.userinfo.userId,
            "otherPayrolls":this.state.payrollGRID.filter(x=>x.isDeleted==="1"),
        }
        
        await axios
        .post(AppConfiguration.Setting().noserapiendpoint + "OtherPayroll/EditOtherPayroll", params)
        .then(res => {
            const data = res.data
            console.log(data)
            if(data.status==="0"){
                this.setState({
                    isloading   :   false,
                    alerttype   :   "Error!",
                    isshow      :   true,
                    color       :   "danger",
                    message     :   data.message,
                    fade        :   true,
                    disableSaveBtn: false
                });
            }
            //this.setState({isloading:false});
            this.handleSearchClick()
        })
        .catch(error=>{
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade        :   true,
                disableSaveBtn: false
            })
        })
    }
    render() {
        const cols = [
            {
                dataField: 'clientName',
                text: 'CLIENT',
                editable: true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'left',width:'50%' }},
                style:{textAlign:'left',width:'50%'},
            },
            {
                dataField: 'payPeriod',
                text: 'Payroll Period',
                editable: false,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'left',width:'10%' }},
                style:{textAlign:'left',width:'10%'},
                sort:true,
            },
            {
                dataField: 'totalTxn',
                text: 'TOTAL TXN',
                editable: true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'10%' }},
                style:{textAlign:'center',width:'10%'},
                sort:true,
            },
            {
                dataField: 'uploadFileName',
                text: 'FILE NAME',
                editable: true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'left',width:'30%' }},
                style:{textAlign:'left',width:'30%'},
                formatter   :   (cell, row, isSelect) => {
                    return (
                        <Button style={{paddingTop:'0',paddingBottom:'0',marginLeft:'-10px',fontSize:'10px'}}
                            variant="link" onClick={e => this.handleClickDownload(row)}
                    >{cell}</Button>
                    );
                } 
            }
        ]
        const colDetails = [
            {
                dataField: 'employeeName',
                text: 'EMPLOYEE NAME',
                editable: true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'left',width:'20%',whiteSpace:'nowrap'}},
                style:{textAlign:'left',width:'20%',whiteSpace:'nowrap'},
            },
            {
                dataField: 'employeeNo',
                text: 'EMPLOYEE NO',
                editable: true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'10%',whiteSpace:'nowrap' }},
                style:{textAlign:'center',width:'10%',whiteSpace:'nowrap'},
            },
            { dataField: 'inC_1', text: 'ADJ (OP / UP)',
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%'}},
                style:{textAlign:'right'}
            },
            { dataField: 'inC_3', text: 'LBC',
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%'}},
                style:{textAlign:'right'}
            },
            { dataField: 'inC_4', text: 'WEEKLY EXPENSE REPORT',
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%'}},
                style:{textAlign:'right'}
            },
            { dataField: 'inC_5', text: 'MRP INCENTIVE',
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%'}},
                style:{textAlign:'right'}
            },
            { dataField: 'inC_6', text: 'BIRTHDAY',
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%'}},
                style:{textAlign:'right'}
            },
            { dataField: 'inC_7', text: 'PERFECT ATTENDANCE',
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%'}},
                style:{textAlign:'right'}
            },
            { dataField: 'inC_8', text: 'SALES GROWTH',
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%'}},
                style:{textAlign:'right'}
            },
            { dataField: 'inC_9', text: 'GROSS MARGIN',
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%'}},
                style:{textAlign:'right'}
            },
            { dataField: 'inC_10', text: 'SHIFT LEADER',
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%'}},
                style:{textAlign:'right'}
            },
            { dataField: 'inC_11', text: 'HAZARD PAY',
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%'}},
                style:{textAlign:'right'}
            },
            { dataField: 'inC_12', text: 'ALLOWANCE (DELIVERY HELPER,DELIVERY DRIVER, PORTER, LOGISTIC PERSONNEL, HEAD BUTLER, CUSTODIAN)',
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%'}},
                style:{textAlign:'right'}
            },
            { dataField: 'inC_13', text: 'TRANSPORTATION ALLOWANCE',
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%'}},
                style:{textAlign:'right'}
            },
            { dataField: 'inC_14', text: 'LOAD ALLOWANCE',
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%'}},
                style:{textAlign:'right'}
            },
            { dataField: 'inC_15', text: 'TEAM LEADERS ALLOWANCE',
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%'}},
                style:{textAlign:'right'}
            },
            { dataField: 'inC_16', text: 'SKILLS PAY ALLOWANCE',
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%'}},
                style:{textAlign:'right'}
            },
            { dataField: 'inC_17', text: 'MEAL ALLOWANCE',
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%'}},
                style:{textAlign:'right'}
            },
            { dataField: 'inC_18', text: 'ALLOWANCE',
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%'}},
                style:{textAlign:'right'}
            },
            { dataField: 'inC_19', text: 'SKILLS PAY FX',
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%'}},
                style:{textAlign:'right'}
            },
            { dataField: 'inC_20', text: 'SERVICE CHARGE',
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%'}},
                style:{textAlign:'right'}
            },
            { dataField: 'inC_21', text: 'ADDBACK COOP LOAN',
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%'}},
                style:{textAlign:'right'}
            },
            { dataField: 'inC_22', text: 'ADDBACK SSS LOAN',
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%'}},
                style:{textAlign:'right'}
            },
            { dataField: 'inC_23', text: 'ADDBACK HDMF LOAN',
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%'}},
                style:{textAlign:'right'}
            },
            { dataField: 'inC_24', text: 'SET UP ALLOWANCE',
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%'}},
                style:{textAlign:'right'}
            },
            { dataField: 'inC_25', text: 'RATE ADJUSMENT',
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%'}},
                style:{textAlign:'right'}
            },
            { dataField: 'inC_26', text: 'UNDER PAYMENT (FOR BILLING)',
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%'}},
                style:{textAlign:'right'}
            },
            { dataField: 'inC_27', text: 'UNDER PAYMENT (NOT FOR BILLING)',
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%'}},
                style:{textAlign:'right'}
            },
            { dataField: 'inC_28', text: 'ADDBACK ON CREDIT BREAD',
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%'}},
                style:{textAlign:'right'}
            },
            { dataField: 'inC_29', text: 'ADDBACK ON CREDIT PORK',
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%'}},
                style:{textAlign:'right'}
            },
            { dataField: 'inC_30', text: 'DIVIDEND (ADD TO PAYROLL)',
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%'}},
                style:{textAlign:'right'}
            },
            { dataField: 'inC_31', text: '13TH MONTH (ADD TO PAYROLL)',
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%'}},
                style:{textAlign:'right'}
            },
            { dataField: 'inC_32', text: 'MC RENTAL ALLOWANCE',
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%'}},
                style:{textAlign:'right'}
            },
            { dataField: 'inC_33', text: 'SA INCENTIVE',
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%'}},
                style:{textAlign:'right'}
            },
            { dataField: 'inC_34', text: 'SENIORITY PAY',
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%'}},
                style:{textAlign:'right'}
            },
            { dataField: 'inC_35', text: 'RICE SUBSIDY',
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%'}},
                style:{textAlign:'right'}
            },
            { dataField: 'inC_38', text: 'MC PLAN',
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%'}},
                style:{textAlign:'right'}
            },
            { dataField: 'inC_39', text: 'INCENTIVES',
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%'}},
                style:{textAlign:'right'}
            },
            { dataField: 'inC_40', text: 'MH ADJ',
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%'}},
                style:{textAlign:'right'}
            },
            { dataField: 'inC_41', text: 'OT ADJ',
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%'}},
                style:{textAlign:'right'}
            },
            { dataField: 'inC_42', text: 'MEAL ALLOW ADJ',
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%'}},
                style:{textAlign:'right'}
            },
            { dataField: 'inC_43', text: 'MC RENTAL ADJ',
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%'}},
                style:{textAlign:'right'}
            },
            { dataField: 'inC_44', text: 'ADDBACK HMO',
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%'}},
                style:{textAlign:'right'}
            },
            { dataField: 'inC_45', text: 'ADDBACK CBU',
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%'}},
                style:{textAlign:'right'}
            },
            { dataField: 'inC_46', text: 'WHSE INCENTIVES',
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%'}},
                style:{textAlign:'right'}
            },
            { dataField: 'inC_47', text: 'HOUSING ALLOWANCE',
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%'}},
                style:{textAlign:'right'}
            },
            { dataField: 'inC_48', text: 'DATA ALLOWANCE',
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%'}},
                style:{textAlign:'right'}
            },
            { dataField: 'inC_49', text: '(SSS) - ADDBACK MANDATORY CONTRIBUTION',
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%'}},
                style:{textAlign:'right'}
            },
            { dataField: 'inC_50', text: '(PHIC) - ADDBACK MANDATORY CONTRIBUTION',
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%'}},
                style:{textAlign:'right'}
            },
            { dataField: 'inC_51', text: '(HDMF) - ADDBACK MANDATORY CONTRIBUTION',
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%'}},
                style:{textAlign:'right'}
            },
            { dataField: 'inC_52', text: 'INCENTIVE ADJUSTMENT',
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%'}},
                style:{textAlign:'right'}
            },
            { dataField: 'inC_53', text: 'SERVICE INCENTIVE LEAVE',
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%'}},
                style:{textAlign:'right'}
            },
            { dataField: 'inC_54', text: '13TH MONTH',
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%'}},
                style:{textAlign:'right'}
            },
            { dataField: 'inC_55', text: 'SPOT & UAT OT',
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%'}},
                style:{textAlign:'right'}
            },
            { dataField: 'inC_56', text: 'RATE ALLOWANCE',
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%'}},
                style:{textAlign:'right'}
            },
            { dataField: 'inC_57', text: 'OT RATE ALLOWANCE',
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%'}},
                style:{textAlign:'right'}
            },
            { dataField: 'inC_58', text: 'DOD RATE ALLOWANCE',
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%'}},
                style:{textAlign:'right'}
            },
            { dataField: 'inC_59', text: 'DOD OT RATE ALLOWANCE',
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%'}},
                style:{textAlign:'right'}
            },
            { dataField: 'inC_60', text: 'SH RATE ALLOWANCE',
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%'}},
                style:{textAlign:'right'}
            },
            { dataField: 'inC_61', text: 'LH RATE ALLOWANCE',
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%'}},
                style:{textAlign:'right'}
            },
            { dataField: 'inC_62', text: 'LHOT RATE ALLOWANCE',
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%'}},
                style:{textAlign:'right'}
            },
            { dataField: 'inC_63', text: 'SHOT RATE ALLOWANCE',
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%'}},
                style:{textAlign:'right'}
            },
            { dataField: 'inC_64', text: 'PRODUCT CONSULTANT LOAD ALLOWANCE',
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%'}},
                style:{textAlign:'right'}
            },
            { dataField: 'inC_65', text: 'SH DOD RATE ALLOWANCE',
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%'}},
                style:{textAlign:'right'}
            },
            { dataField: 'inC_66', text: 'SH DODOT RATE ALLOWANCE',
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%'}},
                style:{textAlign:'right'}
            },
            { dataField: 'inC_67', text: 'LH DOD RATE ALLOWANCE',
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%'}},
                style:{textAlign:'right'}
            },
            { dataField: 'inC_68', text: 'LH DODOT RATE ALLOWANCE',
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%'}},
                style:{textAlign:'right'}
            },
            { dataField: 'inC_69', text: 'NOT FOR BILLING - TL ALLOWANCE',
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%'}},
                style:{textAlign:'right'}
            },
            { dataField: 'inC_70', text: 'NOT FOR BILLING - LOAD ALLOWANCE',
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%'}},
                style:{textAlign:'right'}
            },
            { dataField: 'inC_71', text: 'COMMUNICATION ALLOWANCE',
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%'}},
                style:{textAlign:'right'}
            },
            { dataField: 'inC_72', text: '13TH MONTH RATE ALLOWANCE',
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%'}},
                style:{textAlign:'right'}
            },
            { dataField: 'inC_73', text: 'NOT FOR BILLING - PERFECT ATTENDANCE',
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%'}},
                style:{textAlign:'right'}
            },
            { dataField: 'inC_74', text: 'NOT FOR BILLING - MEAL ALLOWANCE',
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%'}},
                style:{textAlign:'right'}
            },
            { dataField: 'inC_75', text: '14TH MONTH',
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%'}},
                style:{textAlign:'right'}
            },
        ]
        const expandRow = {
            onlyOneExpanding: true,
            renderer: row => 
            (
                <BootstrapTable
                    keyField = "id"
                    data = { row.otherPayrollDetails }
                    columns = { colDetails }
                    rowClasses="noser-table-row-class"
                    striped
                    hover
                    condensed
                    expandRow
                    wrapperClasses="table-responsive"
                    rowClasses="noser-table-row-class"
                    noDataIndication={ () => <div>No details.</div> }
                /> 
                
            ),
            showExpandColumn: true,
          };
        const selectRow = {
            mode: 'checkbox',
            clickToSelectAndEditCell: true,
            onSelect: (row, isSelect, rowIndex, e) => {
                let isDisable = true
                this.state.payrollGRID.map(function(item,i){
                    if(item.id===row.id && item.statusId==="0")
                    {
                        item.isDeleted = isSelect ? "1" : "0"
                    }
                    if(item.isDeleted=="1")
                        isDisable=false
                })
                this.setState({disableDeleteBtn:isDisable})
            }
        };

    return(
            <div>
                <Banner />
                    <Container className="themed-container" fluid={true}>
                        <Card className="mt-5">
                            <Card.Header>PAYROLL >> OTHER PAYROLL</Card.Header>
                            <Card.Body>
                                <Form>
                                    <Alert isOpen={this.state.isshow} color={this.state.color} fade={this.state.fade} className={this.state.isshow ? 'display-block' : 'display-none'}>
                                        <div className={this.state.color}></div> 
                                        {this.state.message}
                                    </Alert>
                                    <Card>
                                    <Accordion defaultActiveKey="1">
                                        <div className="card-header-tab">
                                            <Accordion.Toggle as={Button} variant="link" eventKey="1" style={{color: "#FFFFFF"}}>
                                                SEARCH FILTER
                                            </Accordion.Toggle>
                                        </div>
                                        <Accordion.Collapse eventKey="1">
                                            <Card.Body>
                                                <Form>
                                                    <Form.Group as={Row} controlId="formHorizontalEmail">
                                                        <Col sm={12}>
                                                            <Typeahead
                                                                labelKey='name'
                                                                id="basic-example"
                                                                onChange={this.handleChangedClient}
                                                                options={this.state.clientDDL}
                                                                placeholder="Select CLient"
                                                            />
                                                        </Col>
                                                    </Form.Group>
                                                    <Form.Group as={Row} controlId="formHorizontalEmail">
                                                        <Col sm={12}>
                                                            <Typeahead
                                                                labelKey='payPeriod'
                                                                id="basic-example"
                                                                onChange={this.handleChangedPeriod}
                                                                options={this.state.periodDDL}
                                                                placeholder="Select Period"
                                                            />
                                                        </Col>
                                                    </Form.Group>
                                                    {/* <Form.Group as={Row} controlId="formHorizontalEmail">
                                                        <Col sm={12}>
                                                            <Typeahead
                                                                labelKey='employeeName'
                                                                id="basic-example"
                                                                onChange={this.handleChangedEmployee}
                                                                options={this.state.employeeDDL}
                                                                placeholder="Select EMPLOYEE"
                                                            />
                                                        </Col>
                                                    </Form.Group>
                                                    <Form.Group as={Row} controlId="formHorizontalEmail">
                                                        <Col sm={12}>
                                                            <Typeahead
                                                                labelKey='name'
                                                                id="basic-example"
                                                                onChange={this.handleChangedInclusionType}
                                                                options={this.state.inclusiontypeDDL}
                                                                placeholder="Select INCLUSION TYPE"
                                                            />
                                                        </Col>
                                                    </Form.Group>
                                                    <Form.Group as={Row} controlId="formHorizontalEmail">
                                                        <Col sm={12}>
                                                            <Typeahead
                                                                labelKey='name'
                                                                id="basic-example"
                                                                onChange={this.handleChangedInclusion}
                                                                options={this.state.inclusionDDL}
                                                                placeholder="Select INCLUSION"
                                                            />
                                                        </Col>
                                                    </Form.Group>
                                                    <Form.Group as={Row} controlId="formHorizontalEmail">
                                                        <Col sm={12}>
                                                            <Typeahead
                                                                labelKey='name'
                                                                id="basic-example"
                                                                onChange={this.handleChangedStatus}
                                                                options={this.state.statusDDL}
                                                                placeholder="Select STATUS"
                                                            />
                                                        </Col>
                                                    </Form.Group> */}
                                                    <Form.Group as={Row} controlId="formHorizontalEmail">
                                                        <Col sm={12}>
                                                            <ButtonToolbar>
                                                            <Button variant="warning" className="noser-button-mr1" onClick={() => this.handleModalShow("1") }>Download</Button>
                                                            <Button variant="info" className="noser-button" onClick={() => this.handleModalShow("0") }>Upload</Button>
                                                            <Button variant="primary" className="ml-auto noser-button" onClick={ this.handleSearchClick }>Search</Button>
                                                            </ButtonToolbar>
                                                        </Col>
                                                    </Form.Group>
                                                </Form>
                                            </Card.Body>
                                        </Accordion.Collapse>
                                    </Accordion>
                                    
                                    </Card>
                                    
                                    <div className="mt-2">
                                    <Card.Header>LIST OF OTHER PAYROLL</Card.Header>
                                        <BootstrapTable
                                            keyField = "id"
                                            data = { this.state.payrollGRID }
                                            columns = { cols }
                                            selectRow = { selectRow }
                                            cellEdit = { cellEditFactory({ 
                                                    mode: 'dbclick', 
                                                    blurToSave: true,
                                                    afterSaveCell: (oldValue, newValue, row, column) => { 
                                                        this.GridDataModified(oldValue, newValue, row.id, column.dataField)
                                                    }
                                                })
                                            }
                                            striped
                                            hover
                                            condensed
                                            loading={true}
                                            wrapperClasses="table-responsive"
                                            pagination={ paginationFactory({sizePerPageRenderer})}
                                            expandRow={ expandRow }
                                            noDataIndication={ () => <div style={{width:'100%'}}>No record found.</div> }
                                        />
                                        <ButtonToolbar sm={12}>
                                            <Button variant="danger" className="ml-auto noser-button-mr1" disabled={this.state.disableDeleteBtn} onClick={this.handleDeleteClick}>Delete</Button>
                                        </ButtonToolbar>
                                    </div>
                                </Form>
                            </Card.Body>
                        </Card>
                    </Container>
                <NoserLoading show={this.state.isloading} />
                <BillableOtherPayrollModal 
                    show={this.state.openModal}
                    onHide={this.handleModalClose}
                    onRefModal={ref => (this.child = ref)}
                />
            </div> 
        )
    }

}

export  default BillableOtherPayroll 