import {
    React,Component,BootstrapTable,
    paginationFactory,Button, ButtonToolbar, Card, 
    Form, Col, Row, axios, Typeahead, TimePicker,
    cellEditFactory ,moment, NavLink, Alert, AppConfiguration,
    NoserLoading, Noser, Banner, Container, DatePicker,  Tabs, Tab,
    sizePerPageRenderer,Type
} 
from '../../noser-hris-component';
import { GetRequest, PostRequest } from '../../noser-dataaccess'

class PayCardAdmin extends Component {
    constructor(props) {
        super(props)
        this.state = {
            userinfo        :   [],
            loadingcaption : "ako yung bagong label",
            isloading       :   false,
            isshow          :   false,
            alerttype       :   "",
            message         :   "",
            color           :   "",
            fade            :   true,

            clientId        :   "",
            clientName      :   "",
            employeeId      :   "",
            employeeName    :   "",

           

            amtSubDate      :  "",
            amtTransDate    :  "",
            adminTransDate  :  "",

        }
        this.state.assessedBy = this.state.userinfo.fullName
    }

    componentDidMount(){
        const sleep = (milliseconds) => {return new Promise(resolve => setTimeout(resolve, milliseconds))}
        this.state.userinfo = JSON.parse(sessionStorage.getItem("userData"))
        this.GetClients();
    }

    GetClients = async()=> {
        this.setState({isloading:true,})
        const clientParams = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.userinfo.clientId,
            "UserId":this.state.userinfo.userId,
       }
       console.log(clientParams)
       await axios
           .post(
               AppConfiguration.Setting().noserapiendpoint + "Client/GetClientList", clientParams
           )
           .then(res => {
               const data = res.data;
               this.setState({clientDDL: data.clients, isloading:false})
           })
           .catch(error=>{
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade        :   true
            })
        })
    }

    

    handleChangedClient = (e) =>{
        this.state.alerttype="";this.state.isshow=false;this.state.color="";this.state.message="";this.state.fade=true;this.state.isloading=false
        if(e.length===0) {
            this.state.clientId = ""
            this.state.clientName = ""
            this.state.employeeDDL=[]
            return
        }
        this.state.clientId = e[0].id
        this.state.clientName = e[0].name
        // this.GetPayPeriodList(e[0].isSpecial)
    }

    
    onChangePayrollForm  = (e) =>{
        this.state.payrollForm = e.target.checked
        if(this.state.payrollForm == true) {
            this.setState({
                disablebutton :   false,
            })
        } else {
            this.setState({
                disablebutton :   true,
            })
        }
    }

    onChangeAtd = (e) =>{
        this.state.atd = e.target.checked
        if(this.state.atd == true) {
            this.setState({
                disablebutton :   false,
            })
        } else {
            this.setState({
                disablebutton :   true,
            })
        }
    }

    onChangeAffidavit = (e) =>{
        this.state.affidavit = e.target.checked
        if(this.state.affidavit == true) {
            this.setState({
                disablebutton :   false,
            })
        } else {
            this.setState({
                disablebutton :   true,
            })
        }
    }
    
    onChangeCashCard = (e) =>{
        this.state.cashCard = e.target.checked
        if(this.state.cashCard == true) {
            this.setState({
                disablebutton :   false,
            })
        } else {
            this.setState({
                disablebutton :   true,
            })
        }
    }
    
    onChangeValidId = (e) =>{
        this.state.validId = e.target.checked
        if(this.state.validId == true) {
            this.setState({
                disablebutton :   false,
            })
        } else {
            this.setState({
                disablebutton :   true,
            })
        }
    }
    
    onChangeLOIForm= (e) =>{
        this.state.loiForm = e.target.checked
        if(this.state.loiForm == true) {
            this.setState({
                disablebutton :   false,
            })
        } else {
            this.setState({
                disablebutton :   true,
            })
        }
    }
    
    handleChangeAMTSubDate = date => {
        this.setState({
            amtSubDate: date,
            isshow:false,
        })
    }

    handleChangeAMTTransDate = date => {
        this.setState({amtTransDate: date})
    };

    handleChangeAdminTransDate = date => {
        this.setState({adminTransDate: date})
    };
    
    IsDecimal(val)
    {
        var regex = /^\d*\.?(?:\d{1,2})?$/;
        return !regex.test(val)
    }
    FormatCommas(x) {
        x = x.toString();
        var pattern = /(-?\d+)(\d{3})/;
        while (pattern.test(x))
            x = x.replace(pattern, "$1,$2");
        return x;
    }
    FormatAmount(column) {
        console.log("dsdsdsd")
        console.log(column.payrollAmount)
        return (
          <strong>{ column.payrollAmount }</strong>
        );
      }
    render() {
      
        return(
            <div>
            <Banner />
            <Container className="themed-container" fluid={true}>
                <Card className="mt-5">
                        <Card.Header>RECEIVING / TRANSMITTAL / APPROVAL</Card.Header>
                        <Card.Body>
                            <Form.Row>
                                <Form.Group as={Col} sm={12} controlId="formGridEmail">
                                        <div className="card-header-tab"></div>
                                        <Card.Body>
                                            <Form.Row>
                                                <Form.Group as={Col} sm={12}>
                                                    <fieldset className="border p-2">
                                                        <legend className="w-auto">ADMIN</legend>
                                                        <Form.Row>
                                                        <Form.Group as={Col} sm="6" controlId="formGridEmail">
                                                            <Form.Label style={{fontWeight : "bold"}}>Officer In-Charge</Form.Label>
                                                                <Form.Control 
                                                                    type="text" 
                                                                    autoComplete="off" 
                                                                    /* name="selectedClient" */
                                                                    value={this.state.userinfo.fullName}
                                                                    readOnly
                                                                />
                                                            </Form.Group>
                                                            <Form.Group as={Col} sm="6" controlId="formGridPassword">
                                                            <Form.Label style={{fontWeight : "bold"}}>Position</Form.Label>
                                                                <Form.Control 
                                                                    type="text" 
                                                                    autoComplete="off" 
                                                                    value={this.state.userinfo.roleName}
                                                                    readOnly
                                                                />
                                                            </Form.Group>
                                                        </Form.Row>
                                                    </fieldset>
                                                </Form.Group>
                                            </Form.Row>
                                            <Form.Row className="mt-5">
                                                <Form.Group as={Col} sm={12}>
                                                    <fieldset className="border p-2">
                                                        <legend className="w-auto">Acknowledgement of Transmitted PayCard Application by AMT</legend>
                                                        <Form.Group as={Col} controlId="formGridEmail">
                                                            <Form.Row>
                                                                <Form.Group as={Col} sm="1"  controlId="formGridEmail">
                                                                    <DatePicker
                                                                        ref='amtSubDate'
                                                                        selected={this.state.amtSubDate}
                                                                        onChange={this.handleChangeAMTSubDate}
                                                                        minDate={this.minDate}
                                                                        value={this.props.amtSubDate}
                                                                        dateFormat={"MM/dd/yyyy"}
                                                                        className="form-control"
                                                                        wrapperClassName="datepicker"
                                                                        placeholderText="Select Date" 
                                                                    />
                                                                </Form.Group>
                                                                <Form.Group as={Col}  className="ml=3" controlId="formGridPassword">
                                                                    <ButtonToolbar>
                                                                        <Button variant="success" className="noser-button" /**onClick={ this.handleSearchClick }**/>Receive</Button>
                                                                    </ButtonToolbar> 
                                                                </Form.Group>
                                                                <Col sm="3"></Col>
                                                                <Form.Group as={Col} sm="4" controlId="formGridPassword">
                                                                    <Form.Control 
                                                                        type="text" 
                                                                        placeholder="Remarks" 
                                                                        ref={this.textInput} 
                                                                        onChange={() => this.handleChange()} 
                                                                        autoComplete="off" 
                                                                    />
                                                                </Form.Group>
                                                                <Form.Group as={Col} sm="2" controlId="formGridPassword">
                                                                    <ButtonToolbar>
                                                                        <Button variant="success" className="ml-auto noser-button-mr1" /**onClick={ this.handleSearchClick }**/>Reject</Button>&nbsp;&nbsp;
                                                                        <Button variant="success" className="noser-button" /**onClick={ this.handleSearchClick }**/>Approved</Button>
                                                                    </ButtonToolbar> 
                                                                </Form.Group>
                                                            </Form.Row>
                                                        </Form.Group>
                                                    </fieldset>
                                                </Form.Group>
                                            </Form.Row>
                                        <Form.Row className="mt-5">
                                        <Form.Group as={Col} sm={12}>
                                                <fieldset className="border p-2">
                                                    <legend className="w-auto">Acknowledgement of Approved / Rejected PayCard Application by Finance</legend>
                                                    <Form.Group as={Col} controlId="formGridEmail">
                                                    <Form.Row>
                                                        <Form.Group as={Col} sm="2" controlId="formGridEmail">
                                                            <DatePicker
                                                                ref='amtSubDate'
                                                                selected={this.state.amtSubDate}
                                                                onChange={this.handleChangeAMTSubDate}
                                                                minDate={this.minDate}
                                                                value={this.props.amtSubDate}
                                                                dateFormat={"MM/dd/yyyy"}
                                                                className="form-control"
                                                                wrapperClassName="datepicker"
                                                                placeholderText="Select Date" 
                                                            />
                                                        </Form.Group>
                                                        <Form.Group as={Col} controlId="formGridPassword">
                                                            <ButtonToolbar>
                                                                <Button variant="success" className="noser-button" /**onClick={ this.handleSearchClick }**/>RECEIVED</Button>
                                                            </ButtonToolbar> 
                                                        </Form.Group>
                                                        <Col sm="1">
                                                        </Col>
                                                        <Form.Label style={{fontWeight : "bold"}}>Return To AMT</Form.Label>
                                                        <Form.Group as={Col} controlId="formGridPassword">
                                                            <ButtonToolbar>  
                                                                <Button variant="success" className="noser-button" /**onClick={ this.handleSearchClick }**/>REJECT</Button>
                                                            </ButtonToolbar> 
                                                        </Form.Group>
                                                        <Form.Label style={{fontWeight : "bold"}}>Transmit to AMT</Form.Label>
                                                        <Form.Group as={Col} controlId="formGridPassword">
                                                            <ButtonToolbar>
                                                                <Button variant="success" className="noser-button" /**onClick={ this.handleSearchClick }**/>TRANSMIT</Button>
                                                            </ButtonToolbar> 
                                                        </Form.Group>
                                                        {/* <Form.Group as={Col}  controlId="formGridEmail">
                                                            <DatePicker
                                                                ref='amtSubDate'
                                                                selected={this.state.amtSubDate}
                                                                onChange={this.handleChangeAMTSubDate}
                                                                minDate={this.minDate}
                                                                value={this.props.amtSubDate}
                                                                dateFormat={"MM/dd/yyyy"}
                                                                className="form-control"
                                                                wrapperClassName="datepicker"
                                                                placeholderText="Select Date Applied" 
                                                            />
                                                        </Form.Group> */}
                                                    </Form.Row>
                                                    </Form.Group>
                                                </fieldset>
                                            </Form.Group>
                                            <Form.Group as={Col} sm={4} className="mt-5">
                                                <fieldset className="border p-2">
                                                    <legend className="w-auto">Acknowledgement of Employee Received / Not Received Approved PayCard </legend>
                                                    <Form.Group as={Col} controlId="formGridEmail">
                                                    <Form.Row>
                                                        <Form.Group as={Col} sm="3" controlId="formGridEmail">
                                                            <DatePicker
                                                                ref='amtSubDate'
                                                                selected={this.state.amtSubDate}
                                                                onChange={this.handleChangeAMTSubDate}
                                                                minDate={this.minDate}
                                                                value={this.props.amtSubDate}
                                                                dateFormat={"MM/dd/yyyy"}
                                                                className="form-control"
                                                                wrapperClassName="datepicker"
                                                                placeholderText="Select Date" 
                                                            />
                                                        </Form.Group>
                                                        <Form.Group as={Col} sm="3"  controlId="formGridPassword">
                                                            <ButtonToolbar>
                                                                <Button variant="success" className="noser-button" /**onClick={ this.handleSearchClick }**/>Receive</Button>
                                                            </ButtonToolbar> 
                                                        </Form.Group>
                                                    </Form.Row>
                                                    </Form.Group>
                                                </fieldset>
                                            </Form.Group>
                                            <Form.Group as={Col} sm={4} className="mt-5">
                                                <fieldset className="border p-2">
                                                    <legend className="w-auto">Transmitted of Employee Received / Not Received Approved PayCard </legend>
                                                    <Form.Group as={Col} controlId="formGridEmail">
                                                    <Form.Row>
                                                        <Form.Group as={Col} sm="3" controlId="formGridEmail">
                                                            <DatePicker
                                                                ref='amtSubDate'
                                                                selected={this.state.amtSubDate}
                                                                onChange={this.handleChangeAMTSubDate}
                                                                minDate={this.minDate}
                                                                value={this.props.amtSubDate}
                                                                dateFormat={"MM/dd/yyyy"}
                                                                className="form-control"
                                                                wrapperClassName="datepicker"
                                                                placeholderText="Select Date" 
                                                            />
                                                        </Form.Group>
                                                        <Form.Group as={Col} sm="3"  controlId="formGridPassword">
                                                            <ButtonToolbar>
                                                                <Button variant="success" className="noser-button" /**onClick={ this.handleSearchClick }**/>TRANSMITTED</Button>
                                                            </ButtonToolbar> 
                                                        </Form.Group>
                                                    </Form.Row>
                                                    </Form.Group>
                                                </fieldset>
                                            </Form.Group>
                                        </Form.Row>
                                        {/* <Form.Group> */}
                                            <ButtonToolbar className="mt-5" style={{marginLeft:"-8px"}}>&nbsp;&nbsp;
                                                <Form.Label style={{fontWeight : "bold"}}>VIEW APPLICATION DETAILS</Form.Label>&nbsp;&nbsp;
                                                <Button variant="success" className="noser-button" style={{minWidth:'60px'}}>VIEW</Button>
                                                <NavLink className="ml-auto" to="/PayCardMonitoring">
                                                    <Button className="noser-button-mr1 noser-button"  variant="success">Back</Button>
                                                </NavLink>&nbsp;&nbsp;
                                                <Button variant="danger" className="noser-button"  style={{minWidth:'60px'}} href="/banner" >Close</Button>
                                            </ButtonToolbar>
                                        {/* </Form.Group> */}
                                    </Card.Body>
                                </Form.Group>
                            </Form.Row>
                           
                        </Card.Body>
                </Card>
            </Container>
            <NoserLoading show={this.state.isloading} />
            </div>
        )
    }
}
export  default PayCardAdmin
