import {
    React,Component, Button, ButtonToolbar, Card, 
    Form, Col, Row, axios, Container, Banner, 
     moment, NavLink, Alert,
    NoserLoading, 
} 
from '../../noser-hris-component';
import { Input  } from 'reactstrap';
 
class NatureOfAbsentAndLateCreate extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fade            :   true, 
            color           :   "",
            isshow          :   false,
            message         :   "",
            userinfo        :   [],
            isloading       :   false,
            alerttype       :   "",

          
            dateFrom                            :   "",
            dateTo                              :   "",
          
           
            nature                            :   ""

        }
    }

    componentDidMount(){
        const sleep = (milliseconds) => {return new Promise(resolve => setTimeout(resolve, milliseconds))}
        this.state.userinfo = JSON.parse(sessionStorage.getItem("userData"))
       
    }
  
    onChangenature=(e)=>{
        this.setState({
            nature: e.target.value      
        })
        
    }

    handleSave=(e)=>{
        
        let createddate = moment(this.state.createddate).format('MM/DD/YYYY');

        let data = {
            
          "Nature"          :   this.state.nature,
          "isDraft"         :   "0",
          "isDeleted"       :   "0",
          "createdby"       :   "user007",
          "createddate"     :   createddate,
          "modifiedby"      :   'user007',
          "modifieddate"    :   '0',
          "isModified"      :   '0',    
        }
        console.log(data)
       
        
        const addParams = {
          "_collection" : "TestNatureOfAbsentAndLate",
          "doc_data"    : data
        }
          
        axios
        .post("http://134.209.99.190:8088/action/add", addParams)
        .then(res =>{
            const data = res.data
            console.log("success")
            this.setState({
                nature:"",
                isloading       :   false,
                alerttype       :   "Success!",
                isshow          :   true,
                color           :   "success",
                message         :   "Data had successfully Save" ,
                fade            :   true,
            
            })
        })
        .catch(error =>{
            console.log("error: " + error)
            this.setState({
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade            :   true
            })
        })
    }
   
    render() {        
    return(
            <div>
                <Banner />
                <Container className="mt-5" fluid>
                    <Card>
                        <Card.Header>Maintenance >> Nature Of Absent And Late >> Create</Card.Header>
                        <Card.Body>
                            <Form>
                                <Alert isOpen={this.state.isshow} color={this.state.color} fade={this.state.fade} className={this.state.isshow ? 'display-block' : 'display-none'}>
                                    <div className={this.state.color}></div> 
                                    {this.state.message}
                                </Alert>
                                <Form.Group as={Row} controlId="formPlaintextEmail">
                                    <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                        Nature
                                    </Form.Label>
                                    <Col sm="11">   
                                        <Input
                                            labelKey='name'
                                            id="basic-example"
                                            type="text" 
                                            placeholder=""
                                            onChange={this.onChangenature}
                                            value={this.state.nature}
                                            autoComplete="off"
                                            
                                        />
                                    </Col>
                                </Form.Group>
                                
                                <Form.Group as={Row} controlId="formPlaintextEmail"> </Form.Group>

                                <ButtonToolbar className="mt-1">
                                    <Button variant="success" className="ml-auto" onClick={ this.handleSave } style={{minWidth:'60px'}}>
                                        Save
                                    </Button>&nbsp;&nbsp;
                                    <NavLink to="/home">
                                        <Button variant="danger" href="/home" style={{minWidth:'60px'}}>
                                            Close
                                        </Button>
                                    </NavLink>
                                </ButtonToolbar>
                            </Form>
                        </Card.Body>
                    </Card>
                    
                </Container>
                <NoserLoading show={this.state.isloading} />
            </div> 
        )
    }

}

export  default NatureOfAbsentAndLateCreate
