import {
    React,Component,BootstrapTable,
    paginationFactory,Button, ButtonToolbar, Card, 
    Form, Col, Row, axios, Container, Banner, Typeahead, TimePicker,
    cellEditFactory, Type, moment, NavLink, Alert, AppConfiguration,
    NoserLoading, Noser, sizePerPageRenderer, DatePicker
} 
from '../../noser-hris-component';
import { DropdownButton, Dropdown} from 'react-bootstrap';
import TraningCreateList from './Modal/TraningCreateList';

class CreateLandDTraining extends Component {
    constructor(props) {
        super(props);
            this.state = {
                fade            :   true,
                color           :   "",
                isshow          :   false,
                message         :   "",
                userinfo        :   [],
                isloading       :   false,
                alerttype       :   "",

                date            :   new Date(),
                branch          :   "",
                position        :   "",
                employeeNo      :   "",
                employeeName    :   "",
                isSuspended     :  "",
               
                tblTrainingSuspendedArrLst : [],
                addTrainingSuspendedLists    :   [
                    { "id" : "0", "name" : "NO" },
                    { "id" : "1", "name" : "YES" },
                ],
                yesnoDDL        :   [
                    {
                        "value":"1",
                        "label":"YES"
                    },
                    {
                        "value":"0",
                        "label":"NO"
                    }
                ],
                requiredDDL     :   [{"value":"1","label":"MANDATORY"},{"value":"0","label":"OPTIONAL"}],
              
                
                client:"",
                employeeName:"",
                employeeNo:"",
                branch:"",
                position:"",
                profileid:"",
              
                newRequiredDocsSave : [],
                trainingScheduleTableList : [],

                trainingGrid  :[],
                trainingListGrid : []
                
            }
            this.state.userinfo = JSON.parse(sessionStorage.getItem("userData"))
            this.state.data = JSON.parse(sessionStorage.getItem("CreateLandDTraining_" + this.state.userinfo.userId + this.state.userinfo.employeeId))
            //console.log("this.state.data2222")
            //console.log(this.state.data)

            this.state.applicationId   =   this.state.data.id
            this.state.profileId       =   this.state.data.profileId
            this.state.clientName      =   this.state.data.client
            this.state.clientId        =   this.state.data.clientId
            this.state.employeeName      =   this.state.data.employeeName
            this.state.position        =   this.state.data.position
            this.state.positionId      =   this.state.data.positionId
            this.state.ReferenceNo   =   this.state.data.applicationFormNo
           
    }

    componentDidMount(){
        const sleep = (milliseconds) => {return new Promise(resolve => setTimeout(resolve, milliseconds))}
        this.state.userinfo = JSON.parse(sessionStorage.getItem("userData"))
      
        this.getTraining()
        this.GetTrainingSuspendedLists()

        
    }

    handleModalShow = (row) =>{
        this.setState({openModal:true})
        this.state.trainingSelectedId = row.id
        this.child.initialize(row)
    }

    handleModalClose = (e) =>{
        //console.log("dsds")
        //console.log(e)
        let trainingGrid = this.state.trainingGrid
        trainingGrid.forEach(itm=>{
            if(itm.id===this.state.trainingSelectedId){
                itm.schedule=e[0].fromDate + " " + e[0].fromTime + " " + e[0].venue
                itm.scheduleId = e[0].id
                
            }
        })
        //console.log(this.state.trainingGrid)
        this.state.trainingGrid = trainingGrid
        this.setState({openModal:false})
    } 

    getTraining = async()=> {
        this.setState({isloading:true})

        const param = {
            "IpAddress":"0.0.0.0",
            "UserId":this.state.userinfo.userId,
            "ClientId":this.state.clientId,
            "TrainingId":this.state.trainingId,
        };
        await axios
            .post(
                 AppConfiguration.Setting().noserapiendpoint + "Maintenance/GetTrainings",  param
            )
             .then(res => {
                const data = res.data;
                //console.log("data")
                //console.log(data)

                let trainingGrid=[]
                data.trainings.forEach(itm=>{
                    itm.isSuspended="1"
                    // console.log(itm)
                    // console.log("Clients")
                    // console.log(itm.trainingClients.length)
                    // console.log("Positions")
                    // console.log(itm.trainingPositions.length)
                    //ALL CLIENTS && ALL POSITIONS
                    if(itm.trainingClients.length===0 && itm.trainingPositions.length===0){
                        trainingGrid.push(itm)
                        //console.log("all")
                    }

                    //VARIOUS CLIENTS && VARIOUS POSITIONS
                    if(itm.trainingClients.length>0 && itm.trainingPositions.length>0){
                        if(itm.trainingClients.filter(x=>x.clientId===this.state.clientId).length>0 && 
                            itm.trainingPositions.filter(x=>x.positionId===this.state.positionId).length>0){
                            trainingGrid.push(itm)
                            //console.log("various client and various position")
                        }
                    }

                    //VARIOUS CLIENTS
                    if(itm.trainingClients.length>0 && itm.trainingPositions.length===0){
                        if(itm.trainingClients.filter(x=>x.clientId===this.state.clientId).length>0){
                            trainingGrid.push(itm)
                            //console.log("various client and all position")
                        }
                    }

                    //VARIOUS POSITIONS
                    if(itm.trainingClients.length===0 && itm.trainingPositions.length>0){
                        if(itm.trainingPositions.filter(x=>x.positionId===this.state.positionId).length>0){
                            trainingGrid.push(itm)
                            //console.log("all client and various position")
                        }
                    }
                })
                this.setState({ trainingGrid:trainingGrid, isloading : false })
                if(data.status=="0"){
                    this.setState({
                        isloading   :   false,
                        alerttype   :   "Error!",
                        isshow      :   true,
                        color       :   "danger",
                        message     :   data.message,
                        fade        :   true
                    });
                
                }
                    
            })
            .catch(error=>{
                this.setState({
                    isloading       :   false,
                    alerttype       :   "Error!",
                    isshow          :   true,
                    color           :   "danger",
                    message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                    fade            :   true
                })
            })
        
    }

    handleChangeDateTo = date => {
        ////////console.log(date)
        this.setState({
            date: date,
            isshow:false,
        })
    }

    GetTrainingSuspendedLists() {

        for (let i = 0; i < this.state.addTrainingSuspendedLists.length; i++) {
            const obj = {
                value : this.state.addTrainingSuspendedLists[i]["id"],
                label : this.state.addTrainingSuspendedLists[i]["name"],
            };
            this.state.tblTrainingSuspendedArrLst.push(obj);
        }
    }


    handleModalClose = (e) =>{
        //console.log("dsds")
        //console.log(e)
        let trainingGrid = this.state.trainingGrid
        trainingGrid.forEach(itm=>{
            if(itm.id===this.state.trainingSelectedId){
                itm.schedule=e[0].fromDate + " " + e[0].fromTime + " " + e[0].venue
                itm.scheduleId = e[0].id
                
            }
        })
        //console.log(this.state.trainingGrid)
        this.state.trainingGrid = trainingGrid
        this.setState({openModal:false})
    } 

    
    handleSaveClick = () => {
        let trainingGrid = this.state.trainingGrid
        //console.log("trainingGrid")
        //console.log(trainingGrid)
        let trainingsGridList = []
        let applicationId = this.state.applicationId
        let profileId = this.state.profileId 
        let referenceNo = this.state.ReferenceNo
        let dateSubmitted = this.state.date
        this.state.trainingGrid.map(function(itm,a) {
           
            //let dateSubmitted = itm.createdDate
            trainingsGridList.push({
                "TrainingId"        :   itm.id,
                "TrainingName"      :   itm.name,
                "IsRequired"        :   itm.isRequired,
                "IsSuspended"       :   itm.isSuspended,
                "ScheduleId"        :   itm.scheduleId,
                "ApplicationId"     :   applicationId, 
                "RequestTypeId"     :   "1", 
                "ProfileId"         :   profileId,
                "ReferenceNo"       :   referenceNo,
                "DateSubmitted"     :   dateSubmitted,
                
            })
        })

        const saveParams = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.userinfo.clientId,
            "UserId":this.state.userinfo.userId,
            "employeeTrainings": trainingsGridList
       
        }

        //console.log("Save Params")
        //console.log(saveParams)
   // return
       axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Training/AddEmployeeTraining",  saveParams)
        .then(res => {
            const data = res.data;
            if(data.status=="1"){
                this.setState({
                    isloading   :   false,
                    alerttype   :   "Success!",
                    isshow      :   true,
                    color       :   "success",
                    message     :   data.message,
                    fade        :   true
                });
            
            }
            else {
                this.setState({
                    isloading   :   false,
                    alerttype   :   "Error!",
                    isshow      :   true,
                    color       :   "danger",
                    message     :   data.message,
                    fade        :   true
                });
            }
        })
        .catch(error=>{
            this.setState({
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade            :   true
            })
        })

    }



    LeavePageWithourSavingChanges() {
        const isChanged=false
        for (let i = 0; i < this.state.trainingGrid.length; i++) {
            if (this.state.trainingGrid[i]["isModified"] == 1) {
                this.setState({isGridDataChanged: true})
                isChanged=true
                break;
            }
        }
        return isChanged
    }


    GridDataModified(oldValue, newValue, id, column) {
        //////console.log(id)
        this.state.trainingGrid.map(function(item,i) {
            if (item.id===id)
                item.isModified = newValue!=oldValue ? "1" : "0"
        })
    }

    
    render() {

        // DOCUMENTS TABLE
    
        const documentsSelectRow = {
            mode: 'checkbox',
            clickToSelectAndEditCell: true,
            onSelect: (row, isSelect, rowIndex, e) => {
                this.state.trainingGrid.map(function(item,i){
                    if(item.id===row.id)
                        {
                        item.isDeleted = isSelect ? "1" : "0"
                        item.isModified = isSelect ? "1" : "0"
                    }
                    ////////////console.log(item.id)
                })

            }
        };

        const documentsRowEvents = {
            onClick: (e, row, rowIndex) => {

            }
        };

        // TRAINING TABLE

        const trainingColumns = [
            {
                dataField: 'name',
                text: 'Training',
                headerStyle: () => {
                    return { width: "30%" };
                },
            },
            {
                dataField: 'isRequired',
                text: 'REQUIRED',
                editable: true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'20%'}},
                style:{textAlign:'center'},
                editor: {
                    type: Type.SELECT,
                    options: this.state.requiredDDL
                  },
                formatter: (cell, row) => {
                    if(row.isRequired!=='' && row.isRequired!==null){
                        return this.state.requiredDDL.find(x => x.value == cell).label;
                    }
                }
            },
            {
                dataField: 'isSuspended',
                text: 'Suspended',
                headerStyle: () => {
                    return { width: "20%" };
                },
                editor: {
                    type: Type.SELECT,
                    options: this.state.yesnoDDL
                },
                formatter: (cell, row) => {
                    if(row.isSuspended!='' && row.isSuspended!=null){
                        return this.state.yesnoDDL.find(x => x.value == cell).label;
                    }
                },
                validator: (newValue, row, column) => {
                    if (newValue =="1" && row.isSuspended=="1") {
                      return {
                        valid: false,
                        message: "Please select correctly at Column SUSPENDED"
                      };
                    }
                    return true;
                }
            },
            {
                dataField: 'schedule',
                text: 'Schedule',
                headerStyle: () => {
                    return { width: "20%" };
                },
                style:{textAlign:'center',width:'15%'},
                editable: false,
                
            },
            {
                dataField: 'isSubmitted',
                text: 'Select Schedule',
                headerStyle: () => {
                    return { width: "15%" };
                },
                formatter   :   (cell, row, isSelect) => {
                    return (
                        <Button style={{paddingTop:'0',paddingBottom:'0',marginLeft:'-10px',fontSize:'10px'}}
                            variant="link" onClick={e => this.handleModalShow(row)}
                    >Select Schedule</Button>
                    );
                }
            },
        ]
    
        const trainingSelectRow = {
            mode: 'checkbox',
            clickToSelectAndEditCell: true,
            onSelect: (row, isSelect, rowIndex, e) => {
                this.state.trainingGrid.map(function(item,i){
                    if(item.id===row.id)
                        {
                        item.isDeleted = isSelect ? "1" : "0"
                        item.isModified = isSelect ? "1" : "0"
                    }
                    ////////////console.log(item.id)
                })

            }
        };

        const trainingRowEvents = {
            onClick: (e, row, rowIndex) => {

            }
        };

        return(
            <div>
                <Banner />
                <Container className="mt-5" fluid>
                    <Card>
                        <Card.Header>RECRUITMENT &#62;&#62; L AND D TRAINING - (CREATE)</Card.Header>
                        <Card.Body>
                            <Form>
                            <Alert isOpen={this.state.isshow} color={this.state.color} fade={this.state.fade} className={this.state.isshow ? 'display-block' : 'display-none'}>
                                    <div className={this.state.color}></div> 
                                    {this.state.message}
                                </Alert>
                                <Form.Group as={Row} controlId="formPlaintextEmail">
                                    <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                        CLIENT
                                    </Form.Label>
                                    <Col sm="4">
                                        <Form.Control 
                                            type="text"
                                            name="clientName"
                                            value={this.state.clientName}
                                            //onChange={this.onChangeLastName} 
                                            disabled 
                                        />
                                    </Col>
                                    <Col sm="2">
                                    </Col>
                                    <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                        EMPLOYEE NAME
                                    </Form.Label>
                                    <Col sm="4">
                                    <Form.Control 
                                        type="text"
                                        name="employeeName"
                                        value={this.state.employeeName}
                                        //onChange={this.onChangeLastName} 
                                        disabled 
                                    />
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} controlId="formPlaintextEmail">
                                    <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                        POSITION
                                    </Form.Label>
                                    <Col sm="4">
                                        <Form.Control 
                                            type="text"
                                            name="position"
                                            value={this.state.position}
                                            //onChange={this.onChangeLastName} 
                                            disabled 
                                        />
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} controlId="formPlaintextEmail">
                                    {/* <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                        BRANCH
                                    </Form.Label>
                                    <Col sm="4">
                                        <Form.Control 
                                            type="text"
                                            name="branch"
                                            value={this.state.branch}
                                            onChange={this.onChangeBranch} 
                                            autoComplete="off"
                                        />
                                    </Col>
                                    <Col sm="2">
                                    </Col> */}
                                    <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                        DATE
                                    </Form.Label>
                                    <Col sm="4">
                                        <DatePicker
                                            // ref='date'
                                            // selected={this.state.date}
                                            // onChange={this.handleChangeDateTo}
                                            // minDate={this.minDate}
                                            // value={this.props.date}
                                            // dateFormat={"MM/dd/yyyy"}
                                            // className="form-control"
                                            ref='date'
                                            selected={this.state.date}
                                            onChange={this.handleChangeDateTo}
                                            minDate={this.minDate}
                                            value={this.props.date}
                                            dateFormat={"MM/dd/yyyy"}
                                            className="form-control"
                                        />
                                    </Col>
                                </Form.Group>

                                <div className="mt-5">
                                    <BootstrapTable
                                        keyField = "id"
                                        data = { this.state.trainingGrid }
                                        columns = { trainingColumns }
                                        /* pagination={ paginationFactory({sizePerPageRenderer}) } */
                                        noDataIndication={ () => <div style={{width:'100%'}}>No record found.</div> }
                                        rowClasses="noser-table-row-class"
                                        striped
                                        hover
                                        condensed
                                        cellEdit = { cellEditFactory({
                                            mode: 'dbclick',
                                            blurToSave: true,
                                            afterSaveCell: (oldValue, newValue, row, column) => {
                                                this.GridDataModified(oldValue, newValue, row.id, column.dataField)
                                                }
                                            })
                                        }
                                        selectRow = { trainingSelectRow }
                                        rowEvents={ trainingRowEvents }
                                    />
                                </div>
                                {/* <ButtonToolbar>
                                    <Button className="ml-auto" variant="success" onClick = { this.handleAddTrainingClick }>Add</Button>
                                </ButtonToolbar> */}
                                <ButtonToolbar sm={12} className="mt-3">
                                    <Button variant="success" className="ml-auto" onClick={() => this.handleSaveClick()}>
                                        Submit
                                    </Button>&nbsp;&nbsp;
                                    {/* <Button variant="info" onClick={() => this.handleStatusClick('1')}>
                                        Deploy
                                    </Button>&nbsp;&nbsp; */}
                                    <Button variant="danger" href="/CreateRecruitTraining">
                                        Back
                                    </Button>
                                </ButtonToolbar>
                            </Form>
                        </Card.Body>
                    </Card>
                </Container>  
                <NoserLoading show={this.state.isloading} />     
                <TraningCreateList
                    show={this.state.openModal}
                    onHide={this.handleModalClose}
                    onRefModal={ref => (this.child = ref)}
                    />  
            </div> 
        )
    }

}

export  default CreateLandDTraining
