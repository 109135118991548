import {
    React,Component,BootstrapTable,
    paginationFactory,Button, ButtonToolbar, Card, 
    Form, Col, Row, axios, Container, Banner, Typeahead, TimePicker,
    cellEditFactory, Type, moment, NavLink, Alert, AppConfiguration,
    NoserLoading, Noser, sizePerPageRenderer, Redirect
} 
from '../../noser-hris-component';
import { DropdownButton, Dropdown} from 'react-bootstrap';

import TrainingEvaluation from './TrainingEvaluationAndRecommendationModal'


class TrainingEvaluationAndRecommendation extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fade            :   true, 
            color           :   "",
            isshow          :   false,
            message         :   "",
            userinfo        :   [],
            isloading       :   false,
            alerttype       :   "",
           
            batchNoDDL : [],
            trainingDDL : [],
            clientDDL : [],
            openTrainingEvaluationModal : false,
            recommendationDG : [],
            //selectedEmployeeNo : "",

        }
    }

    componentDidMount(){
        const sleep = (milliseconds) => {return new Promise(resolve => setTimeout(resolve, milliseconds))}
        this.state.userinfo = JSON.parse(sessionStorage.getItem("userData"))
        this.GetClients();
        this.GetTrainingFilters();
        this.OnLoad();
    }

    OnLoad = async() => {
        this.setState({isloading:true})
        const param = {
            "IpAddress"     :   "0.0.0.0",
            "UserId"        :   this.state.userinfo.userId,
            "ClientId"      :   "",
            "TrainingId"    :   "",
            "ProfileId"     :   "",
            "BatchNo"       :   "",
        };
        //console.log("GetEmployeeEvaluations param")
        //console.log(param)
        await axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Training/GetEmployeeEvaluations",  param)
        .then(res => {
            const data = res.data;
            console.log("Get Employee Evaluations Grid")
            console.log(data)
            this.setState({recommendationDG:data.evaluations,isloading:false});
        })
        .catch(error=>{
            this.setState(  {
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade            :   true
            })
        })
    }

    GetClients = async() => {
        this.setState({isloading:true})
        const param = {
            "IpAddress"     :   "0.0.0.0",
            "ClientId"      :   this.state.userinfo.clientId,
            "UserId"        :   this.state.userinfo.userId,
        };
        await axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Client/GetClientList",  param)
        .then(res => {
            const data = res.data;
            this.setState({clientDDL:data.clients,isloading:false});
        })
        .catch(error=>{
            this.setState(  {
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade            :   true
            })
        })
    }

    onChangeClient = (e) => {
        if(e.length === 0) {
            this.state.clientId=""
            return
        }
        this.state.clientId=e[0].id
        this.setState({isshow:false})
        this.GetEmployeeEvaluations();

    }
    GetTrainingFilters = async() => {
        this.setState({isloading:true})
        const param = {
            "IpAddress"     :   "0.0.0.0",
            "ClientId"      :   this.state.userinfo.clientId,
            "UserId"        :   this.state.userinfo.userId,
        };
        await axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Maintenance/GetTrainingFilters",  param)
        .then(res => {
            const data = res.data;
            ////console.log(data)
            this.setState({trainingDDL:data.trainings,isloading:false});
        })
        .catch(error=>{
            this.setState(  {
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade            :   true
            })
        })
    }

    handleTrainingChanged = (e) => {
        if(e.length === 0) {
            this.state.trainingId=""
            return
        }
        this.state.trainingId=e[0].id
        this.setState({isshow:false})
        this.GetEmployeeEvaluations();
    }

    GetEmployeeEvaluations = async() => {
        this.setState({isloading:true})
        const param = {
            "IpAddress"     :   "0.0.0.0",
            "UserId"        :   this.state.userinfo.userId,
            "ClientId"      :   this.state.clientId,
            "TrainingId"    :   this.state.trainingId,
            "ProfileId"     :   "",
            "BatchNo"       :   "",
        };
        //console.log("GetEmployeeEvaluations param")
        //console.log(param)
        await axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Training/GetEmployeeEvaluations",  param)
        .then(res => {
            const data = res.data;
            //console.log("GetEmployeeEvaluations")
            //console.log(data)
            this.setState({batchNoDDL:data.evaluations,isloading:false});
        })
        .catch(error=>{
            this.setState(  {
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade            :   true
            })
        })
    }

    onChangeBatchNo = (e) => {
        if(e.length === 0) {
            this.state.batchNo=""
            return
        }
        this.state.batchNo=e[0].batchNo
        this.setState({isshow:false})
    }

    handleSearchClick = () => {
        this.GetEmployeeEvaluationsGrid();
    }

    GetEmployeeEvaluationsGrid = async() => {
        this.setState({isloading:true})
        const param = {
            "IpAddress"     :   "0.0.0.0",
            "UserId"        :   this.state.userinfo.userId,
            "ClientId"      :   this.state.clientId,
            "TrainingId"    :   this.state.trainingId,
            "ProfileId"     :   "",
            "BatchNo"       :   this.state.batchNo,
        };
        //console.log("GetEmployeeEvaluations param")
        //console.log(param)
        await axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Training/GetEmployeeEvaluations",  param)
        .then(res => {
            const data = res.data;
            //console.log("Get Employee Evaluations for searching")
            //console.log(data)
            this.setState({recommendationDG:data.evaluations,isloading:false});
        })
        .catch(error=>{
            this.setState(  {
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade            :   true
            })
        })
    }

    onClickDataRow = (row) => {
        this.child.initialize(row)
        this.setState({openTrainingEvaluationModal:true})
        sessionStorage.setItem("trainingEvaluationDataRow", JSON.stringify(row))
    }
    handleTrainingEvaluationModalClose = (e) =>{
        this.setState({openTrainingEvaluationModal:false})
        this.OnLoad();
    }

    render() {
        
        const listOfTrainingColumn = [
            {
                dataField: 'hc',
                text: 'No.',
                headerStyle : () => {
                    return { width  : "5%" };
                }
            },
            {
                dataField: 'employeeName',
                text: 'Name',
                headerStyle : () => {
                    return { width  : "15%" };
                }
            },
            {
                dataField   : 'position',
                text        : 'Position',
                headerStyle : () => {
                    return { width  : "15%" };
                }
            },
            {
                dataField   : 'client',
                text        : 'Client',
                headerStyle : () => {
                    return { width  : "15%" };
                }
            },
            {
                dataField   : 'training',
                text        : 'Training',
                headerStyle : () => {
                    return { width  : "15%" };
                }
            },
            {
                dataField   : 'batchNo',
                text        : 'Batch No.',
                headerStyle : () => {
                    return { width  : "10%" };
                }
            },
            {
                dataField   : 'status',
                text        : 'Status',
                headerStyle : () => {
                    return { width  : "10%" };
                }
            },
            {
                dataField   : 'facilitator',
                text        : 'Facilitator',
                headerStyle : () => {
                    return { width  : "10%" };
                },
                hidden: true
            },
            {
                dataField   : 'dateCertificate',
                text        : 'DateCertificate',
                headerStyle : () => {
                    return { width  : "10%" };
                },
                hidden: true
            },
            {
                dataField   : 'action',
                text        : 'Action',
                headerStyle : () => {
                    return { width  : "15%" };
                },
                formatter   :   (cell, row, isSelect) => {
                    this.state.employeeName = row.employeeName
                    if (row)
                    return (
                        <Button style={{height:'16px',paddingTop:'0',marginTop:'-3px',fontSize:'11px'}}
                            variant="link" onClick={e => this.onClickDataRow(row)}
                        >Evaluation & Recommendation</Button>
                        /* <NavLink to={{pathname:"/trainingevaluationandrecommendationform",params:{data:row} }} >
                            <Button href="/trainingevaluationandrecommendationform" style={{height:'16px',paddingTop:'0',marginTop:'-3px',fontSize:'11px'}} variant="link">Evaluation & Recommendation</Button>
                        </NavLink> */
                        
                    );
                },
            },
        ]

        const listOfTrainingSelectRow = {
            mode: 'checkbox',
            clickToSelectAndEditCell: true,
            onSelect: (row, isSelect, rowIndex, e) => {
                this.state.recommendationDG.map(function(item,i){
                    if(item.id===row.id)
                    {
                        item.isDeleted = isSelect ? "1" : "0"
                        item.isModified = isSelect ? "1" : "0"
                    }
                })
            }
        };

        const listOfTrainingRowEvents = {
            onClick: (e, row, rowIndex) => {
            }
        };

    return(
            <div>
                <Banner />
                    <Container className="mt-5" fluid>
                        <Card>
                            <Card.Header>TRAINING >> L and D >> TRAINING EVALUATION AND RECOMMENDATION</Card.Header>
                            <Card.Body>
                                <Form>
                                    <Alert isOpen={this.state.isshow} color={this.state.color} fade={this.state.fade} className={this.state.isshow ? 'display-block' : 'display-none'}>
                                        <div className={this.state.color}></div> 
                                        {this.state.message}
                                    </Alert>
                                    <Form.Group as={Row} controlId="formPlaintextEmail">
                                        <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                            CLIENT
                                        </Form.Label>
                                        <Col sm="11">
                                            <Typeahead
                                                labelKey='name'
                                                id="basic-example"
                                                onChange={this.onChangeClient}
                                                options={this.state.clientDDL}
                                            />
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} controlId="formPlaintextEmail">
                                        <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                            TRAINING
                                        </Form.Label>
                                        <Col sm="11">
                                            <Typeahead
                                                ref="trainingRef"
                                                labelKey='name'
                                                id="basic-example"
                                                onChange={this.handleTrainingChanged}
                                                options={this.state.trainingDDL}
                                            />
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} controlId="formPlaintextEmail">
                                        <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                            BATCH NO.
                                        </Form.Label>
                                        <Col sm="11">
                                            <Typeahead
                                                ref="batchNo"
                                                labelKey='batchNo'
                                                id="basic-example"
                                                onChange={this.onChangeBatchNo}
                                                options={this.state.batchNoDDL}
                                            />
                                        </Col>
                                    </Form.Group>

                                    <ButtonToolbar className="mt-1">
                                        <Button variant="success" className="ml-auto" onClick={ this.handleSearchClick }>
                                            Search
                                        </Button>
                                    </ButtonToolbar>

                                    <Card.Header className="mt-3">List of Attendees</Card.Header>
                                    <div className="mt-1">
                                        <BootstrapTable
                                            /* caption={Noser.TableHeader({title:"RECORD"})} */
                                            keyField = "id"
                                            data = { this.state.recommendationDG }
                                            columns = { listOfTrainingColumn }
                                            pagination={ paginationFactory({sizePerPageRenderer}) }
                                            rowClasses="noser-table-row-class"
                                            striped
                                            hover
                                            condensed
                                            cellEdit = { cellEditFactory({
                                                mode: 'dbclick',
                                                blurToSave: true,
                                                afterSaveCell: (oldValue, newValue, row, column) => {
                                                    this.GridDataModified(oldValue, newValue, row.id, column.dataField)
                                                    }
                                                })
                                            }
                                            rowEvents={ listOfTrainingRowEvents }
                                            selectRow = { listOfTrainingSelectRow }

                                        />
                                    </div>
                                        
                                    <ButtonToolbar className="mt-3">
                                        <Button className="ml-auto" variant="success" onClick = { this.handleSaveClick }>Save</Button>&nbsp;&nbsp;&nbsp;
                                        <Button  href="/employeeposttrainingexamandevaluationresult" variant="danger">Back</Button>
                                    </ButtonToolbar>
                                </Form>
                            </Card.Body>
                        </Card>
                       
                    </Container>
                    <NoserLoading show={this.state.isloading} />
                    <TrainingEvaluation
                        show={this.state.openTrainingEvaluationModal}
                        onHide={this.handleTrainingEvaluationModalClose}
                        onRefModal={ref => (this.child = ref)}
                    />
            </div> 
        )
    }

}

export  default TrainingEvaluationAndRecommendation 
