import {
    React,Component,BootstrapTable, Type,
    paginationFactory,Button, ButtonToolbar, Card,
    Form, Col, Row, Container, axios, Typeahead, Banner, TimePicker,
    cellEditFactory ,moment, NavLink, Alert, AppConfiguration,
    NoserLoading, Noser, sizePerPageRenderer, DatePicker, Redirect
} 
from '../../noser-hris-component';
import type from 'react-bootstrap-table2-editor';

class Profile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userinfo        :   [],
            isloading       :   false,
            isshow          :   false,
            alerttype       :   "",
            message         :   "",
            color           :   "",
            fade            :   true,

            clientAutocomplete	       :   [],
            employeeNameAutocomplete   :   [],
            profileTableList           :   [],
            employeeList : [],
            employeeApplicationRecordsTableList : [],
        }
    }

    componentDidMount(){
        const sleep = (milliseconds) => {return new Promise(resolve => setTimeout(resolve, milliseconds))}
        this.state.userinfo = JSON.parse(sessionStorage.getItem("userData"))
        this.getClientList();
        
    }

    getClientList = async() => {
        this.setState({isloading:true})
        const clientParams = {
            "IpAddress"     :   "0.0.0.0",
            "ClientId"      :   this.state.userinfo.clientId,
            "UserId"        :   this.state.userinfo.userId,
            "Name"          :   "",
        }
        axios
            .post(
                AppConfiguration.Setting().noserapiendpoint + "Client/GetClientList", clientParams
            )
            .then(res => {
                const data = res.data;
                this.setState({
                clientAutocomplete:data.clients})
                this.getEmployees();
            })
            .catch(error=>{
                this.setState({
                    isloading   :   false,
                    alerttype   :   "Error!",
                    isshow      :   true,
                    color       :   "danger",
                    message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                    fade        :   true
                })
            })
    }

    onChangeClientList = (e) => {
        if(e.length == 0) {
            this.state.selectedClientId     =   ""
            this.state.selectedClientName     =   ""
            this.state.employeeNameAutocomplete = []
            return
        }
        this.state.selectedClientId     =   e[0].id
        this.state.selectedClientName   =   e[0].name
        /* this.getEmployeeName(); */
        this.getEmployees();
 
    }

    getEmployees(){
        this.setState({isloading:true})
        const getParams = {
            "IpAddress":"0.0.0.0",
            "ClientId"      :   this.state.selectedClientId,
            "UserId"        :   this.state.userinfo.userId,
            "EmployeeName"  :   "",
            "EmployeeNo"    :   "",
            "ClientName"    :   ""
        }
        axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Employee/GetEmployeeProfileFilter", getParams)
        .then(res => {
            const data = res.data
            
            this.setState({
                employeeNameAutocomplete:data.profiles,isloading:false
            })
            var alerttype = (data.status=="1") ? "success" : "danger"
            if(data.employees.length=="0"){
                this.setState({
                    isloading   :   false,
                    alerttype   :   "Error!",
                    isshow      :   true,
                    color       :   "danger",
                    message     :   data.message,
                    fade        :   true
                });
            }
            
        })
        .catch(error=>{
            this.setState(
            { 
                isloading:false,
                alertType:"Error! ", 
                isShow:true,
                color:"danger",
                message: "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade:true
            })  
        })
    }

    onChangeEmployeeName = (e) => {
        if(e.length == 0) {
            this.state.selectedEmployeeId       =   ""
            this.state.selectedEmployeeName     =   ""
            this.state.selectedEmployeeNo       =   ""
            return
        }
        this.state.selectedProfileId   = e[0].profileId
        //this.state.selectedEmployeeId   =   e[0].id
        //this.state.selectedEmployeeName = e[0].employeeName
        //this.state.selectedProfileId   = e[0].profileId
        //this.state.selectedApplicationFormId   = e[0].applicationFormId
        //this.GetApplicationFormDetailsData();
 
    }
    GetEmployeeProfiles = async() => {
        this.setState({isloading:true,profileTableList:[]}) 

        const getParams = {
            "IpAddress"     :   "0.0.0.0",
            "ClientId"      :   this.state.selectedClientId,
            "UserId"        :   this.state.userinfo.userId,
            "ProfileId"     :   this.state.selectedProfileId,
        }
        await axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Employee/GetEmployeeProfiles", getParams)
        .then(res => {
            const data = res.data
            this.setState({profileTableList:[]}) 
            this.setState({profileTableList:data.employeeProfiles})
            sessionStorage.setItem("employeeProfileData", JSON.stringify(data))
            this.GetApplicationFormDetailsData()
            var alerttype = (data.status=="1") ? "success" : "danger"
            if(data.employees.length=="0"){
                this.setState({
                    isloading   :   false,
                    alerttype   :   "Error!",
                    isshow      :   true,
                    color       :   "danger",
                    message     :   data.message,
                    fade        :   true
                });
            }
            
        })
        .catch(error=>{
            this.setState(
            { 
                isloading:false,
                alertType:"Error! ", 
                isShow:true,
                color:"danger",
                message: "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade:true
            })  
        })
    }
    GetApplicationFormDetailsData = async() =>{
        const getParams = {
            "IpAddress"     :   "0.0.0.0",
            "ClientId"      :   this.state.userinfo.clientId,
            "UserId"        :   this.state.userinfo.userId,
            "ProfileId"     :   this.state.selectedProfileId,
        }
        await axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Employee/GetEmployeeProfileDetails", getParams)
        .then(res => {
            const data = res.data
            this.setState({employeeList:data,isloading:false})
            sessionStorage.setItem("employeeProfileDetails", JSON.stringify(data))
        })
        .catch(error=>{
            this.setState(
            { 
                isloading:false,
                alertType:"Error! ", 
                isShow:true,
                color:"danger",
                message: "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade:true
            })  
        })
    }
    handleSearchClick = () =>{
        this.GetEmployeeProfiles()
    }
    handleRedirect = (row,typeid) =>{
        //sessionStorage.setItem("employeeProfileData" + this.state.userinfo.userId + this.state.userinfo.employeeId, JSON.stringify(row))
        if(typeid==="0"){
            this.setState({editURL: true})
        }
        else{
            this.setState({viewURL: true})
        }
    }
    render() {
        const { editURL,viewURL } = this.state;
        if(editURL){
            return <Redirect to="/profileedit" push={true}/>
        }
        if(viewURL){
            return <Redirect to="/profileview" push={true}/>
        }
        const columnProfile = [
            {
                text	    : 'EMPLOYEE NAME',
                editable    :   false,
                dataField   :   "employeeName",
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'left',width:'30%'}},
                style:{textAlign:'left',width:'30%'}
            },
            {
                text	    : 'CLIENT NAME',
                editable    :   false,
                dataField   :   "clientName",
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'left',width:'40%'}},
                style:{textAlign:'left',width:'40%'}
            },
            {
                text	    : 'STATUS',
                editable    :   false,
                dataField   :   "active",
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'10%'}},
                style:{textAlign:'center',width:'10%'}
            },
            {
                dataField: 'command',
                text: '',
                editable:false,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'10%'}},
                style:{textAlign:'center',width:'10%'},  
                formatter   :   (cell, row, isSelect, ) => {  
                    console.log(this.state.employeeList)
                   if (this.state.employeeList, this.state.employeeList) 
                    return (
                        <Button variant="link" style={{height:'16px',paddingTop:'0',marginTop:'-3px',fontSize:'11px'}}
                        onClick={e => this.handleRedirect(row,'0')}
                        >Edit</Button>
                    )
                }
            },
            {
                dataField: 'command',
                text: '',
                editable:false,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'10%'}},
                style:{textAlign:'center',width:'10%'},  
                formatter   :   (cell, row, isSelect, ) => {  
                    console.log(this.state.employeeList)
                    if (this.state.employeeList, this.state.employeeList) 
                    return (
                        <Button variant="link" style={{height:'16px',paddingTop:'0',marginTop:'-3px',fontSize:'11px'}}
                            onClick={e => this.handleRedirect(row,'1')}
                        >View</Button>
                    )
                }
            }] 
            const selectRow = {
                mode: 'checkbox',
                hideSelectAll: true,
                clickToSelectAndEditCell: true,
                onSelect: (row, isSelect, rowIndex, e) => {
                }
            };
    
        return(
            <div>
                <Banner />
                    <Container className="mt-5" fluid>
                        <Card>
                            <Card.Header>ADMINISTRATOR >> ADMIN >> PROFILE</Card.Header>
                            <Card.Body>
                                <Form>
                                    <Alert isOpen={this.state.isshow} color={this.state.color} fade={this.state.fade} className={this.state.isshow ? 'display-block' : 'display-none'}>
                                        <div className={this.state.color}></div> 
                                        {this.state.message}
                                    </Alert>
                                    <Form.Group as={Row} controlId="formPlaintextEmail">
                                        <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                            CLIENT
                                        </Form.Label>
                                        <Col sm="11">
                                            <Typeahead
                                                labelKey='name'
                                                id="basic-example"
                                                onChange={this.onChangeClientList}
                                                options={this.state.clientAutocomplete}
                                            />
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} controlId="formPlaintextEmail">
                                        <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                            EMPLOYEE NAME
                                        </Form.Label>
                                        <Col sm="11">
                                            <Typeahead
                                                labelKey='employeeName'
                                                id="basic-example"
                                                onChange={this.onChangeEmployeeName}
                                                options={this.state.employeeNameAutocomplete}
                                            />
                                        </Col>
                                    </Form.Group>
                                    <ButtonToolbar className="mt-1">
                                        <Button variant="primary" className="ml-auto noser-button" onClick={ this.handleSearchClick }>Search</Button>
                                    </ButtonToolbar>
                                    <Card.Header className="mt-3">Record</Card.Header>
                                    <div className="mt-1">
                                        <BootstrapTable
                                            /* caption={Noser.TableHeader({title:"RECORD"})} */
                                            keyField = "id"
                                            data = { this.state.profileTableList }
                                            columns = { columnProfile }
                                            pagination={ paginationFactory({sizePerPageRenderer}) }
                                            noDataIndication={ () => <div style={{width:'100%'}}>No record found.</div> }
                                            rowClasses="noser-table-row-class no-checkbox"
                                            striped
                                            hover
                                            condensed
                                            cellEdit = { cellEditFactory({
                                                mode: 'dbclick',
                                                blurToSave: true,
                                                afterSaveCell: (oldValue, newValue, row, column) => {
                                                    this.GridDataModified(oldValue, newValue, row.id, column.dataField)
                                                    }
                                                })
                                            }
                                            selectRow = { selectRow }

                                        />
                                    </div>
                                        
                                </Form>
                            </Card.Body>
                        </Card>
                    </Container>
                <NoserLoading show={this.state.isloading} />
            </div> 
            
        )
    }
}

export default Profile;