import {
    React,Component,BootstrapTable,
    paginationFactory,Button, ButtonToolbar, Card, 
    Form, Col, Row, axios, Container, Banner, Typeahead, TimePicker,
    cellEditFactory, Type, moment, NavLink, Alert, AppConfiguration,
    NoserLoading, Noser, sizePerPageRenderer, DatePicker
} 
from '../../../noser-hris-component';
import ToolkitProvider, { CSVExport } from 'react-bootstrap-table2-toolkit';
import EmployeeReturn from './Modals/EmployeeReturn';
// test commit
class CoopRegConMonitoring extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fade            :   true,
            color           :   "",
            isshow          :   false,
            message         :   "",
            userinfo        :   [],
            isloading       :   false,
            alerttype       :   "",

            clientId        :   "",
            clientName      :   "",
            branchId        :   "",
            employeeId      :   "",
            statusId        :   "",
            dateFrom        :   "",
            dateTo          :   "",
            clientDDL       :   [],
            employeeDDL     :   [],
            branchDDL       :   [],
            statusDDL       :   [
                {id:"0",name:"ACTIVE"},
                {id:"1",name:"INACTIVE"},
            ],
            monitoringGrid  :   [],
        }
    }
    
    componentDidMount(){
        this.state.userinfo = JSON.parse(sessionStorage.getItem("userData"))
        this.GetClients();
    }
    GetClients = async() => {
        this.setState({isloading:true})
        const param = {
            "IpAddress"     :   "0.0.0.0",
            "ClientId"      :   this.state.userinfo.clientId,
            "UserId"        :   this.state.userinfo.userId,
        };
        await axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Client/GetClientList",  param)
        .then(res => {
            const data = res.data;
            this.setState({clientDDL:data.clients,isloading:false});
            if(data.status===0){
                this.setState({
                    isloading   :   false,
                    alerttype   :   "Error!",
                    isshow      :   true,
                    color       :   "danger",
                    message     :   data.message,
                    fade        :   true
                });
            }  
        })
        .catch(error=>{
            this.setState(  {
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade            :   true
            })
        })
        this.GetEmployees()
    }
    GetClientLocations = async() =>{
        this.setState({isloading:true,})
        const params = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.clientId,
            "UserId":this.state.userinfo.userId,
            "ClientName": this.state.clientName,
            "City": "",
            "Province": "",
            "Region": ""
    
        }
        await axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Client/GetClientLocations", params)
        .then(res => {
            const data = res.data;
            this.setState({branchDDL : data.locations ? data.locations : [],isloading:false,})
            if(data.status===0){
                this.setState({
                    isloading   :   false,
                    alerttype   :   "Error!",
                    isshow      :   true,
                    color       :   "danger",
                    message     :   data.message,
                    fade        :   true
                });
            }  
        })
        .catch(error=>{
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade        :   true
            })
        })
        this.GetEmployees()
    }
    GetEmployees = async() =>{
        this.setState({isloading:true})
        const params = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.clientId,
            "UserId":this.state.userinfo.userId,
            "LocationId":this.state.branchId,
            "IsDeleted":this.state.statusId
        }
        await axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Employee/GetEmployeeList", params)
        .then(res => {
            const data = res.data;
            this.setState({employeeDDL : data.employees ? data.employees : [], isloading:false})
            if(data.status===0){
                this.setState({
                    isloading   :   false,
                    alerttype   :   "Error!",
                    isshow      :   true,
                    color       :   "danger",
                    message     :   data.message,
                    fade        :   true
                });
            }  
        })
        .catch(error=>{
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade        :   true
            })
        })
        this.GetEmployeeRegularizations()
    }
    handleChangeClient = (e) => {
        this.setState({isloading:false,alerttype:'',isshow:false,color:'',message:'',fade:true})
        if(e.length == 0) {
            this.state.clientId=""
            this.state.clientName=""
            return
        } 
        this.state.clientId = e[0].id
        this.state.clientName= e[0].name
        this.GetClientLocations()
        
    }
    handleChangedBranch = (e) => {
        this.setState({isloading:false,alerttype:'',isshow:false,color:'',message:'',fade:true})
        if(e.length == 0) {
            this.state.branchId=""
            return
        } 
        this.state.branchId = e[0].id
        this.GetEmployees()
    }
    handleChangedEmployee = (e) => {
        this.setState({isloading:false,alerttype:'',isshow:false,color:'',message:'',fade:true})
        if(e.length == 0) {
            this.state.employeeId=""
            return
        } 
        this.state.employeeId = e[0].id
    }
    handleChangedStatus = (e) => {
        this.setState({isloading:false,alerttype:'',isshow:false,color:'',message:'',fade:true})
        if(e.length == 0) {
            this.state.statusId=""
            return
        } 
        this.state.statusId = e[0].id
        this.GetEmployees()
    }
    handleChangedDateFrom = (date) =>{
        this.setState({isloading:false,alerttype:'',isshow:false,color:'',message:'',fade:true})
        this.setState({dateFrom:date})
    }
    handleChangedDateTo = (date) =>{
        this.setState({isloading:false,alerttype:'',isshow:false,color:'',message:'',fade:true})
        this.setState({dateTo:date})
    }
    handleSearchClick = async() =>{
        this.setState({isloading:true,alerttype:'',isshow:false,color:'',message:'',fade:true})
        let dateFrom = (this.state.dateFrom) ? moment(this.state.dateFrom).format('MM/DD/YYYY') : ""
        let dateTo = (this.state.dateTo) ? moment(this.state.dateTo).format('MM/DD/YYYY') : ""
        const param = {
            "IpAddress"     :   "0.0.0.0",
            "UserId"        :   this.state.userinfo.userId,
            "ClientId"      :   this.state.clientId,
            "BranchId"      :   this.state.branchId,
            "EmployeeId"    :   this.state.employeeId,
            "StatusId"      :   this.state.statusId,
            "DateFrom"      :   dateFrom,
            "DateTo"        :   dateTo,
            "ContractTypeId":   "2",
        };
        console.log("param")
        console.log(param)
        await axios
        .post(AppConfiguration.Setting().noserapiendpoint + "ContractReport/GetContractRegularizationMonitorings",  param)
        .then(res => {
            const data = res.data;
            console.log(data)
            this.setState({monitoringGrid:data.regularizationMonitorings,isloading:false});
            if(data.status==="0"){
                this.setState(  {
                    isloading       :   false,
                    alerttype       :   "Error!",
                    isshow          :   true,
                    color           :   "danger",
                    message         :   data.message,
                    fade            :   true
                })
            }
        })
        .catch(error=>{
            this.setState(  {
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade            :   true
            })
        })
    }

    GetEmployeeRegularizations = async() =>{
        this.setState({isloading:true})
        const param = {
            "IpAddress"     :   "0.0.0.0",
            "UserId"        :   this.state.userinfo.userId,
            "ClientId"      :   "",
            "BranchId"      :   "",
            "EmployeeId"    :   "",
            "StatusId"      :   "",
            "DateFrom"      :   "",
            "DateTo"        :   "",
            "ContractTypeId":   "2",
        };
        await axios
        .post(AppConfiguration.Setting().noserapiendpoint + "ContractReport/GetContractRegularizationMonitorings",  param)
        .then(res => {
            const data = res.data;
            console.log("GetContractRegularizationMonitorings")
            console.log(data)
            this.setState({monitoringGrid:data.regularizationMonitorings,isloading:false});
            if(data.status==="0"){
                this.setState(  {
                    isloading       :   false,
                    alerttype       :   "Error!",
                    isshow          :   true,
                    color           :   "danger",
                    message         :   data.message,
                    fade            :   true
                })
            }
        })
        .catch(error=>{
            this.setState(  {
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade            :   true
            })
        })
        
    }

    handleModalShow = (e) =>{
        this.setState({openModal:true})
        // //console.log(e)
        // let obj = {
        //     "probitionaryTypeId" : e.probitionaryTypeId,
        //     "probitionaryType" : e.probitionaryType
        // }
        this.child.initialize(e)
    }
    handleModalClose = (e) =>{
        this.setState({openModal:false})
        this.GetEmployeeRegularizations()
    }

    render(){
        const columns = [
            {
                dataField: 'client',
                text: 'CLIENT',
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'left',whiteSpace:'nowrap',width:"20%"}},
                style:{textAlign:'left',whiteSpace:'nowrap',width:"20%"}
            },
            {
                dataField: 'branch',
                text: 'BRANCH/AREA OF ASSIGNMENT',
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',whiteSpace:'nowrap',width:"20%"}},
                style:{textAlign:'left',whiteSpace:'nowrap',width:"20%"}
            },
            {
                dataField: 'employeeName',
                text: 'EMPLOYEE NAME',
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',whiteSpace:'nowrap',width:"20%"}},
                style:{textAlign:'left',whiteSpace:'nowrap',width:"20%"}
            },
            {
                dataField: 'position',
                text: 'POSITION',
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',whiteSpace:'nowrap',width:"20%"}},
                style:{textAlign:'left',whiteSpace:'nowrap',width:"20%"}
            },
            {
                dataField: 'salaryRate',
                text: 'RATE',
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',whiteSpace:'nowrap',width:"10%"}},
                style:{textAlign:'right',whiteSpace:'nowrap',width:"10%"}
            },
            // {
            //     dataField: 'regularizationDate',
            //     text: 'REGULARIZATION DATE',
            //     headerStyle: (colum, colIndex) => {
            //         return { textAlign: 'center',whiteSpace:'nowrap',width:"10%"}},
            //     style:{textAlign:'center',whiteSpace:'nowrap',width:"10%"}
            // },
            {
                dataField: 'issuedBy',
                text: 'ISSUED BY',
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',whiteSpace:'nowrap',width:"10%"}},
                style:{textAlign:'center',whiteSpace:'nowrap',width:"10%"}
            },
            // {
            //     dataField: 'dateGeneration',
            //     text: 'Generated Date',
            //     headerStyle: (colum, colIndex) => {
            //         return { textAlign: 'center',whiteSpace:'nowrap',width:"10%"}},
            //     style:{textAlign:'center',whiteSpace:'nowrap',width:"10%"}
            // },
            {
                dataField: 'issuedDate',
                text: 'DATE ISSUED/GENERATED',
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',whiteSpace:'nowrap',width:"10%"}},
                style:{textAlign:'center',whiteSpace:'nowrap',width:"10%"}
            },
            // {
            //     dataField: 'aging',
            //     text: 'AGING',
            //     headerStyle: (colum, colIndex) => {
            //         return { textAlign: 'center',whiteSpace:'nowrap',width:"10%"}},
            //     style:{textAlign:'center',whiteSpace:'nowrap',width:"10%"}
            // },
            {
                dataField: 'returnedBy',
                text: 'RETURNED BY',
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',whiteSpace:'nowrap',width:"10%"}},
                style:{textAlign:'center',whiteSpace:'nowrap',width:"10%"}
            },
            {
                dataField: 'returnedDate',
                text: 'DATE RETURN',
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',whiteSpace:'nowrap',width:"10%"}},
                style:{textAlign:'center',whiteSpace:'nowrap',width:"10%"}
            },
            {
                dataField: 'aging',
                text: 'AGING',
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',whiteSpace:'nowrap',width:"10%"}},
                style:{textAlign:'center',whiteSpace:'nowrap',width:"10%"}
            },
            {
                dataField: 'remarks',
                text: 'REMARKS',
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',whiteSpace:'nowrap',width:"10%"}},
                style:{textAlign:'center',whiteSpace:'nowrap',width:"10%"}
            },
            {
                dataField: 'action',
                text: 'ACTION',
                headerStyle: () => {
                    return { width: "10%" };
                },
                editable:false,
                formatter   :   (cell, row, isSelect) => {
                        if(row.returnedById===""){
			    if(row.contractTypeId==="1" || row.contractTypeId==="2")
                            return (
                                <Button style={{height:'18px',paddingTop:'0',marginTop:'-4px',fontSize:'11px'}} 
                                variant="link" onClick={e => this.handleModalShow(row)}
                            >RETURN</Button>
                            )    
                        }
                },
            },
            {
                dataField: 'status',
                text: 'STATUS',
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',whiteSpace:'nowrap',width:"10%"}},
                style:{textAlign:'center',whiteSpace:'nowrap',width:"10%"}
            },
        ]
        const selectRow = {
            mode: 'checkbox',
            hideSelectAll: true,
            clickToSelectAndEditCell: true,
            onSelect: (row, isSelect, rowIndex, e) => {
                
            }
        };
        const { ExportCSVButton } = CSVExport;
        return(
            <div>
                <Banner />
                <Container className="mt-5" fluid>
                    <Card>
                        <Card.Header>REPORTS >> CONTRACTS >> COOP REGULAR MONITORING</Card.Header>
                        <Card.Body>
                            <Form>
                                <Alert isOpen={this.state.isshow} color={this.state.color} fade={this.state.fade} className={this.state.isshow ? 'display-block' : 'display-none'}>
                                    <div className={this.state.color}></div> 
                                    {this.state.message}
                                </Alert>
                                <Form.Group as={Row} controlId="formPlaintextEmail">
                                    <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                        CLIENT
                                    </Form.Label>
                                    <Col>
                                        <Typeahead
                                            labelKey='name'
                                            id="basic-example"
                                            onChange={this.handleChangeClient}
                                            options={this.state.clientDDL}
                                        />
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} controlId="formPlaintextEmail">
                                    <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                        BRANCH
                                    </Form.Label>
                                    <Col>
                                        <Typeahead
                                            labelKey='name'
                                            id="basic-example"
                                            onChange={this.handleChangedBranch}
                                            options={this.state.branchDDL}
                                        />
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} controlId="formPlaintextEmail">
                                    <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                        STATUS
                                    </Form.Label>
                                    <Col>
                                        <Typeahead
                                            labelKey='name'
                                            id="basic-example"
                                            onChange={this.handleChangedStatus}
                                            options={this.state.statusDDL}
                                        />
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} controlId="formPlaintextEmail">
                                    <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                        EMPLOYEE
                                    </Form.Label>
                                    <Col>
                                        <Typeahead
                                            labelKey='employeeName'
                                            id="basic-example"
                                            onChange={this.handleChangedEmployee}
                                            options={this.state.employeeDDL}
                                        />
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} controlId="formPlaintextEmail">
                                    <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                        FROM DATE
                                    </Form.Label>
                                    <Col sm="2">
                                        <DatePicker
                                            ref='date'
                                            selected={this.state.dateFrom}
                                            onChange={this.handleChangedDateFrom}
                                            minDate={this.minDate}
                                            value={this.props.dateFrom}
                                            dateFormat={"MM/dd/yyyy"}
                                            className="form-control"
                                            placeholderText="SELECT FROM DATE"
                                        />
                                    </Col>
                                    <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                        TO DATE
                                    </Form.Label>
                                    <Col>
                                        <DatePicker
                                            ref='date'
                                            selected={this.state.dateTo}
                                            onChange={this.handleChangedDateTo}
                                            minDate={this.minDate}
                                            value={this.props.dateTo}
                                            dateFormat={"MM/dd/yyyy"}
                                            className="form-control"
                                            placeholderText="SELECT TO DATE"
                                        />
                                    </Col>
                                </Form.Group>
                                <ButtonToolbar sm={12}>
                                    <Button variant="success" className="ml-auto noser-button" onClick = { this.handleSearchClick }>
                                        SEARCH
                                    </Button>
                                </ButtonToolbar>
                                <div>
                                <ToolkitProvider
                                    keyField="id"   
                                    data={ this.state.monitoringGrid }
                                    columns = { columns }
                                    exportCSV={ {
                                        noAutoBOM: false,blobType: "data:text/csv;charset=utf-8,%EF%BB%BF",fileName: "CoopRegularMonitoring.csv",
                                    } }
                                    >
                                    {
                                        props => (
                                        <div>
                                            
                                            <hr />
                                            <ExportCSVButton disabled={this.state.monitoringGrid.length>0 ? false : true} className="btn btn-success" { ...props.csvProps }>Export CSV!!</ExportCSVButton>
                                            <Card.Header>COOP REGULAR MONITORING LIST</Card.Header>
                                            <BootstrapTable
                                                striped
                                                hover
                                                condensed
                                                //pagination={ paginationFactory({sizePerPage:pageSize,hideSizePerPage:true,hidePageListOnlyOnePage:true})}
                                                pagination={ paginationFactory({sizePerPageRenderer})}
                                                keyField = "id"
                                                data = { this.state.monitoringGrid }
                                                noDataIndication={ () => <div>No record found.</div> }
                                                columns = { columns }
                                                rowClasses="noser-table-row-class no-checkbox"
                                                selectRow={selectRow}
                                            />
                                        </div>
                                        )
                                    }
                                </ToolkitProvider>
                                </div>
                            </Form>
                        </Card.Body>
                    </Card>
                </Container>  
                <NoserLoading show={this.state.isloading} />
                <EmployeeReturn 
                show={this.state.openModal}
                onHide={this.handleModalClose}
                onRefModal={ref => (this.child = ref)}
                />
            </div>
        )
    }

}

export  default CoopRegConMonitoring