import {
    React,Component,BootstrapTable,
    paginationFactory,Button, ButtonToolbar, Card, 
    Form, Col, Row, axios, Container, Banner, Typeahead, TimePicker,
    cellEditFactory, Type, moment, NavLink, Alert, AppConfiguration,
    NoserLoading, Noser, sizePerPageRenderer, DatePicker
} 
from '../../noser-hris-component';
import { DropdownButton, Dropdown, Image,FormControl} from 'react-bootstrap';
import ReactToPrint from "react-to-print";
import paramountLogo from "../../icon/paramountLogo.jpg"
import paraFooter from "../../icon/paraFooter.jpg"

const ref = React.createRef();


class ComponentToPrint extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            regexp: /^[0-9\b]+$/,

            fade            :   true, 
            color           :   "",
            isshow          :   false,
            message         :   "",
            userinfo        :   [],
            isloading       :   false,
            alerttype       :   "",

            isLoading           :"",

            id                  :"",
            client              :"",
            employeeName        :"",
            logo                    :"https://assets.bossjob.com/companies/13161/logo/3Mwts6BLTZy6I6WhqQm9B79bIhq8dILG7uqq7SHl.png",
            lastName                :"",
            amount : "",
            netPay : "",
            newDate : "",
            addMonth : "",
            inchargeName : "",
            inchargeNumber : "",
            label: "",
        }
    }

    componentDidMount(){

        const sleep = (milliseconds) => {return new Promise(resolve => setTimeout(resolve, milliseconds))}
        this.state.userinfo = JSON.parse(sessionStorage.getItem("userData"))
        this.state.DataClearance = JSON.parse(sessionStorage.getItem("clearanceData"))
        console.log("this.state.DataClearance")
        console.log(this.state.DataClearance)
        // console.log("this.state.DataClearance")
        // console.log(this.state.DataClearance.fullName)
        // console.log("this.state.DataClearance")
        // console.log(this.state.DataClearance.amount) moment().format('LL');
        let today = new Date()
        let _addmonth = moment(today).add(1, 'M').format('LL');
        // console.log("_addmonth")
        // console.log(_addmonth);
        const nf = new Intl.NumberFormat();
        this.setState({
            employeeName : this.state.DataClearance.fullName,
            lastName : this.state.DataClearance.name,
            amount : String(this.state.DataClearance.amount).replace('-',''),
            netPay : nf.format(this.state.DataClearance.netPay) + ".00",
            newDate : moment(today).format('LL'),
            addMonth : _addmonth,
            inchargeName : this.state.DataClearance.member,
            authorizedPersonel : this.state.DataClearance.authorizedPersonel,
            inchargeNumber : this.state.DataClearance.phoneNo,
            label : this.state.DataClearance.label,
            position : this.state.DataClearance.position,
        })
    }
    
    onChangePosition=(e)=>{
        this.setState({
            position:e.target.value
        })
    }

    setBack=()=>{
        let whenBack = 0
        sessionStorage.setItem("nextProcessCheck", JSON.stringify(whenBack)) 

    }

    // onChangeInchargeName=(e)=>{
    //     this.setState({ inchargeName : e.target.value })
    // }

    render() {

        return( <>

            <Container className="mt-0" fluid style={{padding: 5}}>
                <div style={{ marginLeft: '4%', marginRight: '4%'}}>
                <Form style={{backgroundColor:"#FFFFFF"}}>
                    <Col>
                        <Image src={paramountLogo} style={{width:"82%", height:"120%", marginLeft:"25px" , marginTop:"15px"}}/>
                    </Col>
                    <Form.Group as={Col} sm="10" className="mt-3">                                    
                        <Form.Label row sm="1" className="no-text-transform-li" style={{fontWeight : "bold" ,fontSize: 30, marginLeft:"25px"}}>
                        {this.state.newDate}
                        </Form.Label>                
                    </Form.Group>

                    <Form.Group as={Col} sm="10">                                    
                        <Form.Label row sm="1" className="no-text-transform-li" style={{fontWeight : "bold" ,fontSize: 30,marginTop:"2%", marginLeft:"25px",marginBottom:"-10px"}}>
                            Mr/Ms. {this.state.employeeName}
                        </Form.Label>                                         
                    </Form.Group>
                    <Form.Group as={Col} sm="10">                                    
                        <Form.Label sm="1"  style={{fontWeight : "bold" ,fontSize: 18,marginTop:"-30px", marginLeft:"25px"}}>
                            {this.state.address}
                        </Form.Label>   
                    </Form.Group>

                    

                    <Form.Group as={Col} sm="10">                                    
                        <Form.Label row sm="1" style={{fontWeight : "bold" ,fontSize: 30,marginTop:"-6px", marginLeft:"30%"}}>
                            SUBJECT
                        </Form.Label> &nbsp;&nbsp;
                        <Form.Label row sm="1" style={{fontWeight : "bold" ,fontSize: 30,marginTop:"-1px", marginLeft:"6px"}}>:</Form.Label> &nbsp;&nbsp;
                        <Form.Label row sm="3" style={{fontWeight : "bold" ,fontSize: 30, marginLeft:"1%"}}>
                            NEGATIVE CLAIMS                           
                        </Form.Label>                                
                    </Form.Group>
                    <div style={{marginTop: "2%"}}></div>
                    <Form.Group as={Col} sm="10" >                                    
                        <Form.Label row sm="1" className="no-text-transform-li" style={{fontWeight : "bold" ,fontSize: 30, marginLeft:"25px",textTransform:"capitalize"}}>
                            Dear Mr/Ms. {this.state.lastName},
                        </Form.Label> 
                    </Form.Group>

                    {/* START BODY OF LETTER */}
                    <div style={{ marginLeft: "2.8%",marginRight: "3%"}}>
                        <p style={{textAlign:"justify",fontSize: 30, justifyContent: "center", marginTop: "1%" }} className="no-text-transform-li">
                            We write for immediate legal action regarding <b className="no-text-transform-li" style={{fontSize: 30,}}>your unpaid obligation with Paramount Human</b>
                        
                            &nbsp;<b className="no-text-transform-li" style={{fontSize: 30,}}>Resource Multi-Purpose Cooperative (PHRMPC)</b> that discloses records you have an outstanding 
                        
                            account, inclusive of the interests and penalties up to this date indicated above, in the amount of&nbsp;
                        
                            <b  style={{fontSize: 30,}}>{this.state.amount}/100 (PHP {this.state.netPay})</b>.

                            <div style={{marginTop: "3%"}}></div>
                            <b className="no-text-transform-li" style={{fontSize: 30,}}>Therefore,</b> you are hereby made upon to pay and settle in full the abovementioned obligation.
                        
                            Your failure to comply with the foregoing final demand within <b className="no-text-transform-li" style={{fontSize: 30,}}> one (1) month on/or before </b> 
                            
                            <b className="no-text-transform-li" style={{fontSize: 30,}}>{this.state.addMonth}.</b>  By then, we shall be demanding from you the appropriate amount of damages, 
                            
                            attorney’s fees, and litigations expenses, due to PHRMPC.
                        
                            <div style={{marginTop: "3%"}}></div>
                            <b className="no-text-transform-li" style={{fontSize: 30,}}>You may settle your outstanding balance at any of the PHRMPC’S offices or through its </b>
                        
                            <b className="no-text-transform-li" style={{fontSize: 30,}}>authorized officer {this.state.label}.&nbsp;{this.state.inchargeName}&nbsp;{this.state.inchargeNumber}</b>  
                        
                            &nbsp;The total amount payable is  subject to change the interests and penalties from the abovementioned 
                        
                            from the above date until full payment.
                            <div style={{marginTop: "3%"}}></div>
                            We trust that you will give this matter your preferential attention.
                        </p>  
                    </div>
                    {/* END BODY OF LETTER */}

                    <Form.Group as={Row} controlId="formPlaintextEmail" className="no-text-transform-li">
                        <Form.Label className="no-text-transform-li"  column style={{fontWeight : "bold",fontSize: 30,marginLeft:"40px", marginTop: '2%'}}>
                            Sincerely Yours,
                        </Form.Label>
                    </Form.Group>
                    <Form.Group as={Row} controlId="formPlaintextEmail">
                        <Form.Label column style={{fontWeight : "bold",fontSize: 30,marginLeft:"40px", textTransform : "capitalize", marginTop: '2%'}}>
                            {this.state.authorizedPersonel}
                        </Form.Label>
                    </Form.Group>
                    <Form.Group as={Row} controlId="formPlaintextEmail">
                        <Form.Label sm="5" className="no-text-transform-li" style={{/* fontWeight : "bold" , */marginTop : "-1%",fontSize: 30, marginLeft:"53px", borderTopColor:"#000000",}}>
                            {this.state.position}
                        </Form.Label> 
                    </Form.Group>
                    <Form.Group as={Row} controlId="formPlaintextEmail">
                        <Form.Label column style={{fontWeight : "bold",fontSize: 30, marginTop:"-20px",marginLeft:"40px"}}>
                            {this.state.preparedBy}
                        </Form.Label>
                    </Form.Group>

                    <Form.Group as={Row} controlId="formPlaintextEmail">
                        <Form.Label column sm="2" style={{fontWeight : "bold",fontSize: 18, marginTop:"-19px", marginLeft:"40px"}}>
                            {this.state.prepareByPosition}
                        </Form.Label>
                    </Form.Group>

                    <Form.Group as={Row} controlId="formPlaintextEmail">
                        <Form.Label column sm="2" style={{fontWeight : "bold",fontSize: 30, marginLeft:"40px",}} className="no-text-transform-li">
                            Received by :
                        </Form.Label>
                    </Form.Group>

                    <Form.Group as={Row} controlId="formPlaintextEmail">
                        <Form.Label column sm="12" style={{fontWeight : "bold",fontSize: 30, marginLeft:"40px"}}>
                        {this.state.employeeName}
                        </Form.Label>
                    </Form.Group>

                    <Form.Group as={Row} controlId="formPlaintextEmail" style={{marginTop:"-40px"}}>
                        <h1 className="mt-5" style={{fontWeight : "bold" ,backgroundColor:'#000000', width:"30%", height:"4px",marginLeft:"49px", }} > </h1>
                        <h1 className="mt-5" style={{fontWeight : "bold" ,backgroundColor:'#000000', width:"14%", height:"4px",marginLeft:"43%", }} ></h1>
                    </Form.Group>
                    
                    <Form.Group as={Row} controlId="formPlaintextEmail">
                        
                        <Form.Label sm="5" className="no-text-transform-li" style={{/* fontWeight : "bold" , */fontSize: 30, marginLeft:"50px", borderTopColor:"#000000"}}>
                            {"(Sign Over Printed Name)"}
                        </Form.Label> 
                        
                        <Form.Label sm="1" style={{/* fontWeight : "bold" , */fontSize: 30, marginLeft:"55%", borderTopColor:"#000000",}} className="no-text-transform-li">
                            Date
                        </Form.Label> 
                        
                    </Form.Group>

                    <Form.Group as={Row} controlId="formPlaintextEmail">
                        <Form.Label column sm="2" className="no-text-transform-li" style={{fontWeight : "bold",fontSize: 30,marginTop:"-20px" , marginLeft:"38px"}}>
                            cc:  201 File
                        </Form.Label>
                    </Form.Group>

                    <div style={{ marginTop: "10%", textAlign: "center"}}>
                        <Form.Group as={Row} controlId="formPlaintextEmail" >
                            <Form.Label column sm="12" className="no-text-transform-li" style={{fontWeight : "bold",fontStyle: "normal",fontSize: 19, color: "#07801B", marginLeft: "30%" }}>
                                Unit 503 Hollywood Square Building, West Avenue
                            </Form.Label>
                        </Form.Group>
                        <Form.Group as={Row} controlId="formPlaintextEmail">
                            <Form.Label column sm="12" className="no-text-transform-li" style={{fontWeight : "bold",fontStyle: "normal",fontSize: 19, color: "#07801B", marginLeft: "35%" }}>
                                Brgy. Nayong Kanluran, Quezon City
                            </Form.Label>
                        </Form.Group>
                        <Form.Group as={Row} controlId="formPlaintextEmail">
                            <Form.Label column sm="12" className="no-text-transform-li" style={{fontWeight : "bold",fontStyle: "normal",fontSize: 19, color: "#07801B", marginLeft: "35%"}}>
                                Website: www.paramountmpc.org
                            </Form.Label>
                        </Form.Group>
                    </div>

                </Form>
                </div>
            </Container>
            <NoserLoading show={this.state.isloading} />
            
            </>
            
        )
    }

}


class ExportDemandLetter extends React.Component {
    render() {
    return (
        <div style={{marginRight: "10%"}}>
        
            <ComponentToPrint ref={(el) => (this.componentRef = el)} />
            <Col sm="3" className="mt-3" as={Row}>
                <ReactToPrint
                    trigger={() => <button>Print this out!</button>}
                    content={() => this.componentRef}
                />
                <ButtonToolbar sm={12}>
                    <NavLink to="/clearancefinanceare">
                        <Button variant="danger" href="/clearancefinanceare" style={{minWidth:'60px',marginLeft:"10px"}} onClick={this.setBack}>
                            Close
                        </Button>
                    </NavLink>
                </ButtonToolbar>
            </Col>
        </div>
    );
    }
}

export default ExportDemandLetter
          