import {
    React,Component,BootstrapTable,
    paginationFactory,Button, ButtonToolbar, Card, 
    Form, Col, Row, axios, Container, Banner, Typeahead, TimePicker,
    cellEditFactory, Type, moment, NavLink, Alert, AppConfiguration,
    NoserLoading, Noser, sizePerPageRenderer, DatePicker
} 
from '../../noser-hris-component';
import { DropdownButton, Dropdown, Image} from 'react-bootstrap';

import  TrainingRegisterModal from '../Training/TrainingModal/TrainingRegisterModal'
import { Input } from 'reactstrap';
import { isDoWhileStatement } from '@babel/types';
import Pdf from "react-to-pdf";
import paraLogoIr from "../../icon/paraLogoIr.jpg"


const ref = React.createRef();
class ExportIncidentReport extends Component {
   

    constructor(props) {
        super(props);
        this.state = {
            regexp: /^[0-9\b]+$/,

            fade            :   true, 
            color           :   "",
            isshow          :   false,
            message         :   "",
            userinfo        :   [],
            isloading       :   false,
            alerttype       :   "",

            batchAutocomplete           :   [],
            clientAutocomplete          :   [],
            positionAutocomplete        :   [],
            trainingAutocomplete        :   [],
            scheduleAutocomplete        :   [],
            trainingRegisterTableList   :   [],
            batchNoAutocomplete         :   [],
            dateFrom                    :   new Date(),
            dateTo                      :   new Date(),
            modalTrainingRegisterShow   :   false,

            isLoading               :"",

            selectedEmployeeName    :[],
            selectedEmployeeId      :[],
            selectedEmployeeNo      :[],
            selectedEmployeeBranch  :[],

            getClientList           :[],
            getEmployeeList         :[],
            getEmployeeNoList       :[],

            irID                    :"",
            dateReported            :"",
            venueOfIncident         :"",
            dateOfIncident          :"",
            timeOfIncident          :"",
            employeeName            :"",
            reportedByEmployee      :"",
            position                :"",
            reportedByPosition      :"",
            reportedDept            :"",
            client                  :"",
            reportedByDept          :"",
            question1               :"",
            question2               :"",
            question3               :"",
            question4               :"",
           
            
            nonEmployee             :"",
            employeeNo              :"",
            irReferenceNo           :"",
         
            reportedByClient        :"",
            
            
            departmentCodeAutocomplete:"",
            irIdCheck:"",
            
            logo :"https://assets.bossjob.com/companies/13161/logo/3Mwts6BLTZy6I6WhqQm9B79bIhq8dILG7uqq7SHl.png"

        }
    }

    componentDidMount(){
        const sleep = (milliseconds) => {return new Promise(resolve => setTimeout(resolve, milliseconds))}
        this.state.userinfo = JSON.parse(sessionStorage.getItem("userData"))

        this.setState({isLoading:true,loadingText:"Loading client list..."})

       /*  this.state.userinfo = JSON.parse(sessionStorage.getItem("userData")) */
        this.state.irIdCheck = JSON.parse(sessionStorage.getItem("irId"))
      /*   console.log("filter start1")
        console.log(this.state.irIdCheck)
        console.log("filter start1") */

       
        //this.getIncidentReport()
        this.getViewDraftMemo()
       
    }
   
    getIncidentReport =()=>{
        let irIdCheckTmp = JSON.parse(sessionStorage.getItem("irId"))
        //console.log("filter start")
        let filter_data ={/*   "id" : irIdCheckTmp */}
        /* console.log("filter_data")
        console.log(filter_data)
        console.log("filter_data") */

        const getParams ={
            "_collection" : "TestIncidentReport",
            "filter_data" : filter_data
        }
            console.log("axios start")
            axios
            .post("http://134.209.99.190:8088/action/get" , getParams)
            .then(res =>{
                let data = res.data
                //console.log("res.data start")
                //console.log(res.data)
                //console.log("res.data end")
            
                const itemUsersDataLists = this.buildList(data["TestIncidentReport"])
                
                this.state.itemUserDataList  =  itemUsersDataLists
                
                let List =[]
                for (let i = 0; i < this.state.itemUserDataList.length; i++) {
                    let obj = {
                        'id'                    :   this.state.itemUserDataList[i]['id'],
                        'client'                :   this.state.itemUserDataList[i]['client'],
                        'employeeName'          :   this.state.itemUserDataList[i]['employeeName'],
                        'position'              :   this.state.itemUserDataList[i]['position'],
                        'employeeNo'            :   this.state.itemUserDataList[i]['employeeNo'],
                        'nonEmployee'           :   this.state.itemUserDataList[i]['nonEmployee'],
                        'dateReported'          :   this.state.itemUserDataList[i]['dateReported'],
                        'dateOfIncident'        :   this.state.itemUserDataList[i]['dateOfIncident'],
                        'venueOfIncident'       :   this.state.itemUserDataList[i]['venueOfIncident'],
                        'timeOfIncident'        :   this.state.itemUserDataList[i]['timeOfIncident'],
                        'irReferenceNo'         :   this.state.itemUserDataList[i]['irReferenceNo'],
                        'question1'             :   this.state.itemUserDataList[i]['question1'],
                        'question2'             :   this.state.itemUserDataList[i]['question2'],
                        'question3'             :   this.state.itemUserDataList[i]['question3'],
                        'question4'             :   this.state.itemUserDataList[i]['question4'],
                        'reportedByClient'      :   this.state.itemUserDataList[i]['reportedByClient'],
                        'reportedByEmployee'    :   this.state.itemUserDataList[i]['reportedByEmployee'],
                        'reportedByPosition'    :   this.state.itemUserDataList[i]['reportedByPosition'],
                        'reportedByDept'        :   this.state.itemUserDataList[i]['reportedByDept'],
                        'isDraft'               :   this.state.itemUserDataList[i]['isDraft'],
                        'isDeleted'             :   this.state.itemUserDataList[i]['isDeleted'],
                        'createdby'             :   this.state.itemUserDataList[i]['createdby'],
                        'createddate'           :   this.state.itemUserDataList[i]['createddate'],
                        'modifiedby'            :   this.state.itemUserDataList[i]['modifiedby'],
                        'modifieddate'          :   this.state.itemUserDataList[i]['modifieddate'],
                        'isModified'            :   this.state.itemUserDataList[i]['isModified'],
                        
                    }
                    List.push(obj)
                    
                    this.setState({
                        irID:obj.id,
                        dateReported:obj.dateReported,
                        venueOfIncident:obj.venueOfIncident,
                        dateOfIncident:obj.dateOfIncident,
                        timeOfIncident:obj.timeOfIncident,
                        employeeName:obj.employeeName,
                        reportedByEmployee:obj.reportedByEmployee,
                        position:obj.position,
                        reportedByPosition:obj.reportedByPosition,
                        reportedDept:obj.reportedDept,
                        client:obj.client,
                        reportedByDept:obj.reportedByDept,
                        question1:obj.question1,
                        question2:obj.question2,
                        question3:obj.question3,
                        question4:obj.question4,
                    })
                   
                }
                
               

            })
            .catch(error=>{
                //console.log("error: " + error)
            })
        } 
        
    buildList(data) {
        //console.log("data start")
        let itemList =[]
    
        let idList                      =[]
        let clientList                  =[]
        let employeeNameList            =[]
        let positionList                =[]
        let employeeNoList              =[]
        let nonEmployeeList             =[]
        let dateReportedList            =[]
        let dateOfIncidentList          =[]
        let venueOfIncidentList         =[]
        let timeOfIncidentList          =[]
        let irReferenceNoList           =[]
        let question1List               =[]
        let question2List               =[]
        let question3List               =[]
        let question4List               =[]
        let reportedByClientList        =[]
        let reportedByEmployeeList      =[]
        let reportedByPositionList      =[]
        let reportedByDeptList          =[]
        let isDraftList                 =[]
        let isDeletedList               =[]
        let createdbyList               =[]
        let createddateList             =[]
        let modifiedbyList              =[]
        let modifieddateList            =[]
        let isModifiedList              =[]
        
    
        for (let i = 0; i < data.length; i++) {
            
    
            let s1 = data[i].split("':")
            
            let idClean                     = s1[1].replace("ObjectId(","").replace(")","").replace("{","")
            let IdClean2                    = idClean.split("',")[0]
            let clientTmp                   = s1[2].split("',")[0]
            let employeeNameListTmp         = s1[3].split("',")[0]
            let positionListTmp             = s1[4].split("',")[0]
            let employeeNoListTmp           = s1[5].split(",")[0]
            let nonEmployeeListListTmp      = s1[6].split("',")[0]
            let dateReportedListTmp         = s1[7].split("',")[0]
            let dateOfIncidentListTmp       = s1[8].split("',")[0]
            let venueOfIncidentListTmp      = s1[9].split("',")[0]
            let timeOfIncidentListTmp       = s1[10].split("',")[0]
            let irReferenceNoListTmp        = s1[11].split("',")[0]
            let question1ListTmp            = s1[12].split("',")[0]
            let question2ListTmp            = s1[13].split("',")[0]
            let question3ListTmp            = s1[14].split("',")[0]
            let question4ListTmp            = s1[15].split("',")[0]
            let reportedByClientListTmp     = s1[16].split("',")[0]
            let reportedByEmployeeListTmp   = s1[17].split("',")[0]
            let reportedByPositionListTmp   = s1[18].split("',")[0]
            let reportedByDeptListTmp       = s1[19].split("',")[0]
            let isDraftListTmp              = s1[20].split("',")[0]
            let isDeletedListTmp            = s1[21].split("',")[0]
            let createdbyListTmp            = s1[22].split("',")[0]
            let createddateListTmp          = s1[23].split("',")[0]
            let modifiedbyListTmp           = s1[24].split("',")[0]
            let modifieddateListTmp         = s1[25].split("',")[0]
            let isModifiedListTmp           = s1[26].split("',")[0]

        
            idList.push(IdClean2.replace("'",""))
            clientList.push(clientTmp.replace("'",""))
            employeeNameList.push(employeeNameListTmp.replace("'",""))
            positionList.push(positionListTmp.replace("'",""))
            employeeNoList.push(employeeNoListTmp.replace("'","").replace(" '",""))
            nonEmployeeList.push(nonEmployeeListListTmp.replace("'",""))
            dateReportedList.push(dateReportedListTmp.replace("'",""))
            dateOfIncidentList.push(dateOfIncidentListTmp.replace("'",""))
            venueOfIncidentList.push(venueOfIncidentListTmp.replace("'",""))
            timeOfIncidentList.push(timeOfIncidentListTmp.replace("'",""))
            irReferenceNoList.push(irReferenceNoListTmp.replace("'",""))
            question1List.push(question1ListTmp.replace("'",""))
            question2List.push(question2ListTmp.replace("'",""))
            question3List.push(question3ListTmp.replace("'",""))
            question4List.push(question4ListTmp.replace("'",""))
            reportedByClientList.push(reportedByClientListTmp.replace("'",""))
            reportedByEmployeeList.push(reportedByEmployeeListTmp.replace("'",""))
            reportedByPositionList.push(reportedByPositionListTmp.replace("'",""))
            reportedByDeptList.push(reportedByDeptListTmp.replace("'",""))
            isDraftList.push(isDraftListTmp.replace("'",""))
            isDeletedList.push(isDeletedListTmp.replace("'",""))
            createdbyList.push(createdbyListTmp.replace("'",""))
            createddateList.push(createddateListTmp.replace("'",""))
            modifiedbyList.push(modifiedbyListTmp.replace("'",""))
            modifieddateList.push(modifieddateListTmp.replace("'",""))
            isModifiedList.push(isModifiedListTmp.replace("'","").replace("'}",""))
        }
        
        for (let i = 0; i < idList.length; i++) {
    
            let obj ={
                'id'                    :   idList[i],
                'client'                :   clientList[i],
                'employeeName'          :   employeeNameList[i],
                'position'              :   positionList[i],
                'employeeNo'            :   employeeNoList[i],
                'nonEmployee'           :   nonEmployeeList[i],
                'dateReported'          :   dateReportedList[i],
                'dateOfIncident'        :   dateOfIncidentList[i],
                'venueOfIncident'       :   venueOfIncidentList[i],
                'timeOfIncident'        :   timeOfIncidentList[i],
                'irReferenceNo'         :   irReferenceNoList[i],
                'question1'             :   question1List[i],
                'question2'             :   question2List[i],
                'question3'             :   question3List[i],
                'question4'             :   question4List[i],
                'reportedByClient'      :   reportedByClientList[i],
                'reportedByEmployee'    :   reportedByEmployeeList[i],
                'reportedByPosition'    :   reportedByPositionList[i],
                'reportedByDept'        :   reportedByDeptList[i],
                'isDraft'               :   isDraftList[i],
                'isDeleted'             :   isDeletedList[i],
                'createdby'             :   createdbyList[i],
                'createddate'           :   createddateList[i],
                'modifiedby'            :   modifiedbyList[i],
                'modifieddate'          :   modifieddateList[i],
                'isModified'            :   isModifiedList[i],
            
            }
            itemList.push(obj)
        }
        
       /*  console.log("itemList start")
        console.log(itemList)
        console.log("itemList End") */
        return itemList
    } 
    getViewDraftMemo=()=>{
        let dataIR = JSON.parse(sessionStorage.getItem("data"))
        console.log("dataIR")
        console.log(dataIR)
        console.log("dataIR")

        this.setState({
          
            irID                : dataIR.id,
            dateReported        : dataIR.dateReported,
            venueOfIncident     : dataIR.venueOfIncident,
            dateOfIncident      : dataIR.dateOfIncident,
            timeOfIncident      : dataIR.timeOfIncident,
            employeeName        : dataIR.employeeName,
            reportedByEmployee  : dataIR.reportedByEmployee,
            position            : dataIR.position,
            reportedByPosition  : dataIR.reportedByPosition,
            reportedDept        : dataIR.reportedDept,
            client              : dataIR.client,
            reportedByDept      : dataIR.reportedByDept,
            question1           : dataIR.question1,
            question2           : dataIR.question2,
            question3           : dataIR.question3,
            question4           : dataIR.question4,
        })
      
    }

    render() {
    return( <>
            <div  ref={ref} style={{height: "-1000px" , width:"-1600"}}>
                
                <Container className="mt-0" fluid>
                    <Card className="mr-5" style={{marginLeft:"-15px" ,width: "1800px", height:"1200px"}}>
                        <Card.Body>
                            {  
                                <Col sm="2">
                                    <Image src={paraLogoIr} style={{width:"500px", height:"200px", marginLeft:"100px"}}/>
                                </Col>
                            }
                            <Form>
                                <Alert isOpen={this.state.isshow} color={this.state.color} fade={this.state.fade} className={this.state.isshow ? 'display-block' : 'display-none'}>
                                    <div className={this.state.color}></div> 
                                    {this.state.message}
                                </Alert>
                                <Form.Group as={Row} className="mt-3" controlId="formPlaintextEmail">
                                    <Form.Label column sm="1" style={{fontWeight : "bold" , fontSize: 14}}>
                                        TIME / DATE :
                                    </Form.Label>
                                </Form.Group>
                                <Form.Group as={Row} className="mt-4" controlId="formPlaintextEmail">
                                    
                                    <Form.Label column sm="1" style={{fontWeight : "bold" ,fontSize: 12}}>
                                        Date Reported :
                                    </Form.Label>
                                    <Col sm="1">
                                        <Input
                                            onChange={this.onChangeDateReported}
                                            value={this.state.dateReported}
                                            className="form-control"
                                            style={{borderTop:"#ffffff", borderLeft:"#ffffff", borderRight:"#ffffff" ,borderRadius: "0",marginLeft:"-30px",fontSize: 12}}
                                        />
                                    </Col>
                                    
                                    <Form.Label column sm="1" style={{fontWeight : "bold" ,fontSize: 12,marginLeft:"50px"}}>
                                        Venue Of Incident :
                                    </Form.Label>
                                    <Col sm="1">
                                        <Form.Control
                                            type="text"
                                            name="selectedEmployeeName"
                                            value={this.state.venueOfIncident}
                                            onChange={this.onChangeVenueOfIncident} 
                                            autoComplete="off" 
                                            style={{borderTop:"#ffffff", borderLeft:"#ffffff", borderRight:"#ffffff" ,borderRadius: "0",fontSize: 12}}
                                        />
                                    </Col>
                                </Form.Group>

                                <Form.Group as={Row} controlId="formPlaintextEmail">
                                    <Form.Label column sm="1" style={{fontWeight : "bold",fontSize: 12}}>
                                        Date Of Incident :
                                    </Form.Label>
                                    <Col sm="1">
                                        <Form.Control 
                                            controlId="formBasicEmail"
                                            type="text"
                                            name="selectedEmployeeName"
                                            value={this.state.dateOfIncident}
                                            onChange={this.onChangeDateOfIncident} 
                                            autoComplete="off"
                                            style={{borderTop:"#ffffff", borderLeft:"#ffffff", borderRight:"#ffffff" ,borderRadius: "0",fontSize: 12,marginLeft:"-30px"}}
                                        />
                                    </Col>
                                    
                                    <Form.Label column sm="1" style={{fontWeight : "bold",fontSize: 12,marginLeft:"50px"}}>
                                        Time Of Incident :     
                                    </Form.Label>
                                    <Col sm="1">
                                        <Form.Control 
                                            type="text"
                                            name="selectedEmployeeName"
                                            value={this.state.timeOfIncident}
                                            onChange={this.onChangeTimeOfIncident} 
                                            autoComplete="off"
                                            style={{borderTop:"#ffffff", borderLeft:"#ffffff", borderRight:"#ffffff" ,borderRadius: "0",fontSize: 12}}
                                        />
                                    </Col>
                                </Form.Group>

                                <Form.Group as={Row} className="mt-5" controlId="formPlaintextEmail">
                                    <Form.Label column sm="2" style={{fontWeight : "bold",fontSize: 14}}>
                                        PERSON TO BE REPORTED :
                                    </Form.Label>

                                    {/* <Col sm="1"></Col> */}

                                    <Form.Label column sm="1" style={{fontWeight : "bold",fontSize: 14,marginLeft:"50px"}}>
                                        REPORTED BY :
                                    </Form.Label>
                                </Form.Group>

                                <Form.Group as={Row} controlId="formPlaintextEmail" className="mt-3">
                                    <Form.Label column sm="1" style={{fontWeight : "bold",fontSize: 12,}}>
                                        NAME :
                                    </Form.Label>
                                    <Col sm="1">
                                        <Form.Control 
                                            type="text"
                                            name="employeeName"
                                            value={this.state.employeeName}
                                            //onChange={this.onChangePosition} 
                                            autoComplete="off"
                                            placeholder="Enter Name"
                                            style={{width:"190px",borderTop:"#ffffff", borderLeft:"#ffffff", borderRight:"#ffffff" ,borderRadius: "0",fontSize: 12,marginLeft:"-60px"}}
                                        />
                                    </Col>
                                    
                                  
                                    <Form.Label column sm="1" style={{fontWeight : "bold",fontSize: 12 ,marginLeft:"50px"}}>
                                        NAME :
                                    </Form.Label>
                                    <Col sm="1">
                                        <Form.Control 
                                            type="text"
                                            name="reportedByEmployee"
                                            value={this.state.reportedByEmployee}
                                            //onChange={this.onChangePosition} 
                                            autoComplete="off"
                                            placeholder="Enter Name"
                                            style={{width:"200px",borderTop:"#ffffff", borderLeft:"#ffffff", borderRight:"#ffffff" ,borderRadius: "0",marginLeft:"-100px"}}
                                        />
                                    </Col>
                                  
                                </Form.Group>

                                <Form.Group as={Row} controlId="formPlaintextEmail">
                                    <Form.Label column sm="1" style={{fontWeight : "bold",fontSize: 12}}>
                                        Position :
                                    </Form.Label>
                                    <Col sm="1">
                                        
                                        <Form.Control 
                                            type="text"
                                            name="selectedEmployeeName"
                                            value={this.state.position}
                                            //onChange={this.onChangePosition} 
                                            autoComplete="off"
                                            placeholder="Autofill"
                                            style={{width:"190px", borderTop:"#ffffff", borderLeft:"#ffffff", borderRight:"#ffffff" ,borderRadius: "0",marginLeft:"-60px"}}
                                        />
                                    </Col>

                                    <Form.Label column sm="1" style={{fontWeight : "bold",fontSize: 12,marginLeft:"50px"}}>
                                        Position :
                                    </Form.Label>
                                    <Col sm="1">
                                        <Form.Control 
                                            type="text"
                                            name="selectedEmployeeName"
                                            value={this.state.reportedByPosition}
                                            //onChange={this.onChangePosition} 
                                            autoComplete="off"
                                            placeholder="Autofill"
                                            style={{width:"160px",borderTop:"#ffffff", borderLeft:"#ffffff", borderRight:"#ffffff" ,borderRadius: "0",marginLeft:"-60px"}}
                                        />
                                    </Col>
                                </Form.Group>

                                <Form.Group as={Row} controlId="formPlaintextEmail">
                                    <Form.Label column sm="1" style={{fontWeight : "bold",fontSize: 12}}>
                                        Dept / Client :
                                    </Form.Label>
                                    <Col sm="1">
                                        <Form.Control 
                                            type="text"
                                            name="selectedEmployeeName"
                                            value={this.state.reportedDept}
                                            //onChange={this.onChangePosition} 
                                            autoComplete="off"
                                           // placeholder="Enter Name"
                                            style={{borderTop:"#ffffff", borderLeft:"#ffffff", borderRight:"#ffffff" ,borderRadius: "0",fontSize: 12,marginLeft:"-55px"}}
                                        />
                                    </Col>
                                    <Col sm="1">
                                        <Form.Control 
                                            type="text"
                                            name="selectedEmployeeName"
                                            value={this.state.client}
                                            //onChange={this.onChangePosition} 
                                            autoComplete="off"
                                           // placeholder="Enter Name"
                                            style={{width:"200px",borderTop:"#ffffff", borderLeft:"#ffffff", borderRight:"#ffffff" ,borderRadius: "0",fontSize: 12,marginLeft:"-60px"}}
                                        />
                                    </Col>

                                   

                                    <Form.Label column sm="1" style={{fontWeight : "bold",fontSize: 12, marginLeft:"10px"}}>
                                        Dept :
                                    </Form.Label>
                                    <Col sm="1">
                                        <Form.Control 
                                            type="text"
                                            name="selectedEmployeeName"
                                            value={this.state.reportedByDept}
                                            //onChange={this.onChangePosition} 
                                            autoComplete="off"
                                           // placeholder="Enter Name"
                                            style={{borderTop:"#ffffff", borderLeft:"#ffffff", borderRight:"#ffffff" ,borderRadius: "0",fontSize: 12,marginLeft:"-110px"}}
                                        />
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="mt-1" controlId="formPlaintextEmail">
                                    <Form.Label column sm="2" style={{fontWeight : "bold",fontSize: 12}}>
                                        Signiture:___________________________
                                    </Form.Label>
                                </Form.Group>

                                <Form.Group as={Row} className="mt-3" controlId="formPlaintextEmail">
                                    <Form.Label column sm="2" style={{fontWeight : "bold",fontSize: 12}}>
                                        BRIEF DESCRIPTION OF INCIDENT
                                    </Form.Label>
                                </Form.Group>
                                <Form.Group as={Row} className="mt-2" controlId="formPlaintextEmail">
                                    <Form.Label style={{fontWeight : "bold",fontSize: 12 , marginLeft:"15px"}} >
                                        1. What happened?
                                    </Form.Label>
                                </Form.Group>

                                <Form.Group as={Row} className="mt-2" controlId="formPlaintextEmail">
                                    <Form.Label style={{fontSize: 11 ,width:"750px" , marginLeft:"15px"}}>
                                        {this.state.question1}
                                    </Form.Label>
                                </Form.Group>
                              
                                <Form.Label className="mt-3" style={{fontWeight : "bold",fontSize: 12}}>
                                    2. What physical evidence or document exist to support your report?
                                </Form.Label>
                                <Form.Group as={Row} className="mt-2" controlId="formPlaintextEmail">
                                    <Form.Label style={{fontSize: 11 ,width:"750px" , marginLeft:"15px"}}>
                                        {this.state.question2}
                                    </Form.Label>
                                </Form.Group>

                                <Form.Label className="mt-3" style={{fontWeight : "bold",fontSize: 12}}>
                                    3. How did you know the incident and who are the possible witness/es?
                                </Form.Label>
                                <Form.Group as={Row} className="mt-2" controlId="formPlaintextEmail">
                                    <Form.Label style={{fontSize: 11 ,width:"750px" , marginLeft:"15px"}}>
                                        {this.state.question3}
                                    </Form.Label>
                                </Form.Group>

                                <Form.Label className="mt-3" style={{fontWeight : "bold",fontSize: 12}}>
                                    4. Is there money involved ? If yes please specify the estimated amount?
                                </Form.Label>
                                <Form.Group as={Row} className="mt-2" controlId="formPlaintextEmail">
                                    <Form.Label style={{fontSize: 11 ,width:"750px" , marginLeft:"15px"}}>
                                        {this.state.question4}
                                    </Form.Label>
                                </Form.Group>
                            </Form>
                        </Card.Body>
                    </Card>
                    
                </Container>
                <NoserLoading show={this.state.isloading} />

                <TrainingRegisterModal 
                    show={this.state.modalTrainingRegisterShow}
                    onHide={this.handleModalClose}
                    onRefModal={ref => (this.child = ref)}
                />
            </div>
            <Col sm="3" className="mt-3" as={Row}>
                <Pdf targetRef={ref} filename="Dio.pdf">
                    {({toPdf})=><button onClick={toPdf} variant="danger" style={{marginLeft:"10px"}}>Export to PDF</button>}
                </Pdf>
                <ButtonToolbar sm={12}>
                    <NavLink to="/home">
                        <Button variant="danger" href="/home" style={{minWidth:'60px',marginLeft:"10px"}}>
                            Close
                        </Button>
                    </NavLink>
                </ButtonToolbar>
            </Col>
            </>
            
        )
    }

}

export  default ExportIncidentReport
