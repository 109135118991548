import Login from "./components/Login/Login";
import ChangePassword from "./components/Login/ChangePassword";
import Home from "./components/HomePage/Home";
import Banner from "./components/Nav/Banner";
import WorkSchedule from "./components/Timekeeping/WorkSchedule/WorkSchedule";
import WorkScheduleCreate from "./components/Timekeeping/WorkSchedule/WorkScheduleCreate";
import EmployeeConfig from "./components/Maintenance/EmployeeConfig";
import ListOfEmployeeConfig from "./components/Maintenance/EmployeeConfigExport";                                                                                 
import City from "./components/Maintenance/City";
import CityCreate from "./components/Maintenance/CityCreate";
import Holiday from './components/Holiday/Holiday';
import HolidayCreate from "./components/Holiday/HolidayCreate";
import Province from "./components/Maintenance/Province";
import ProvinceCreate from './components/Maintenance/ProvinceCreate';
import Region from './components/Maintenance/Region';
import RegionCreate from './components/Maintenance/RegionCreate';
import Breaktime from './components/Maintenance/Breaktime';
import BreaktimeCreate from './components/Maintenance/BreaktimeCreate';
import Modal from './components/Maintenance/Modal';
import ChangeWorkSched from './components/Maintenance/ChangeWorkSched';
import RateCard from './components/Maintenance/RateCard';
import RateCardCreate from './components/Maintenance/RateCardCreate';
import RateCardConfig from './components/Timekeeping/RateCard/RateCardConfig';
import DailyTimeRecord from './components/DailyTimeRecord/DailyTimeRecord';
import Location from "./components/Maintenance/Location";
import LocationCreate from './components/Maintenance/LocationCreate';
import ClientConfig from './components/Maintenance/ClientConfig';
import BootStrapGrid1 from './components/ProofOfConcept/BootStrapGrid1';
import LeaveModal from './components/Maintenance/LeaveModal';
import BillableHours from './components/Timekeeping/BillableHours/BillableHours';
import ComputedDTR from './components/Maintenance/ComputedDTR';
//import DraftEmployee from './components/Maintenance/DraftEmployee'
import TransferEmployee from './components/Maintenance/TransferEmployee'
import GenerateDTR from './components/Timekeeping/GeneratedDTR/GenerateDTR';
import GenerateWorkSchedule from './components/Timekeeping/EmployeeWorkSchedule/GenerateWorkSchedule';
import DTRStatusMonitoring from './components/Timekeeping/DTRMonitoring/DTRStatusMonitoring';
import uploadDTR from './components/Timekeeping/uploadDTR';

import Unathorized from './unauthorized'
import React, {Component} from 'react';
import {ProtectedRoute} from './protected.route'
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { Container,Card, Button,ButtonToolbar } from "react-bootstrap";

import PayrollConfiguration from './components/Payroll/PayrollConfiguration';
import InclusionLedger from './components/Payroll/InclusionLedger';
import DeductionLedger from './components/Payroll/DeductionLedger';
import DeductionType from './components/Maintenance/DeductionType';
import DeductionTypeCreate from './components/Maintenance/DeductionTypeCreate';
import Deduction from './components/Maintenance/Deduction';
import DeductionCreate from './components/Maintenance/DeductionCreate';
import InclusionType from './components/Maintenance/InclusionType';
import InclusionTypeCreate from './components/Maintenance/InclusionTypeCreate';
import Inclusion from './components/Maintenance/Inclusion';
import InclusionCreate from './components/Maintenance/InclusionCreate';
import SSSContributionModal from './components/Payroll/SSSContributionModal';
import PHICModal from './components/Payroll/PHICModal';
import SSSLoanModal from './components/Payroll/SSSLoanModal';
import PagibigLoanModal from './components/Payroll/PagibigLoanModal';
import HDMFContributionModal from './components/Payroll/HDMFContributionModal';
import GeneratePayroll from './components/Payroll/GeneratePayroll';
import PayrollSSSLoanDeduction from './components/Payroll/PayrollSSSLoanDeduction';
import SSSTable from './components/Maintenance/SSSTable';
import PhilHealthTable from './components/Maintenance/PhilHealthTable';
import PagibigTable from './components/Maintenance/PagibigTable';
import PayrollInclusions from './components/Payroll/PayrollInclusions';
import PayrollDeductions from './components/Payroll/PayrollDeductions';
import PayrollLoans from './components/Payroll/PayrollLoans';
import PayrollInclusionMenu from './components/Payroll/PayrollInclusionMenu';
import PayrollLoanTypes from './components/Payroll/PayrollLoanTypes';
import PayrollLoanTypesCreate from './components/Payroll/PayrollLoanTypesCreate';
import LoanType from './components/Maintenance/LoanType';
import LoanTypeCreate from './components/Maintenance/LoanTypeCreate';
import PayrollLoanAdjustments from './components/Payroll/PayrollLoanAdjustments';
import PayrollDeductionAdjustments from './components/Payroll/PayrollDeductionAdjustments';
import PayrollContributionAdjustments from './components/Payroll/PayrollContributionAdjustments';
import GeneratePayrollFile from './components/Payroll/GeneratePayrollFile';
/* import PayrollGenerate from './components/Test/PayrollGenerate'; */

import ReportPayrollSummary from './components/Payroll/ReportPayrollSummary';
import ReportGovernmentMandatory from './components/Payroll/ReportGovernmentMandatory';
import ReportLoanType from './components/Payroll/ReportLoanType';
import ReportDeductionType from './components/Payroll/ReportDeductionType';


import BillingConfiguration from './components/Billing/BillingConfiguration';
import BillingScreen from './components/Billing/BillingScreen';

import BillingSummary from './components/Billing/BillingSummary'

/* import BillingTemplate from './components/Maintenance/BillingTemplate';
import BillingTemplateCreate from './components/Maintenance/BillingTemplateCreate'; */
/* import PayrollFields from './components/Maintenance/PayrollFields';
import PayrollFieldsCreate from './components/Maintenance/PayrollFieldsCreate'; */
/* import BranchGroup from './components/Maintenance/BranchGroup';
import BranchGroupCreate from './components/Maintenance/BranchGroupCreate'; */
import Area from './components/Maintenance/Area';
import AreaCreate from './components/Maintenance/AreaCreate';
import Department from './components/Maintenance/Department';
import DepartmentCreate from './components/Maintenance/DepartmentCreate';
import DepartmentSection from './components/Maintenance/DepartmentSection';
import DepartmentSectionCreate from './components/Maintenance/DepartmentSectionCreate';
import CostCenter from './components/Maintenance/CostCenter';
import CostCenterCreate from './components/Maintenance/CostCenterCreate';
import Position from './components/Maintenance/Position';
import PositionCreate from './components/Maintenance/PositionCreate';

import ApplicationForm from './components/RECRUITMENT/ApplicationForm';
import ApplicationFormCreate from './components/RECRUITMENT/ApplicationFormCreate';
import OnlineApplication from "./components/RECRUITMENT/ApplicationFormPublic";
import ApplicationFormEdit from './components/RECRUITMENT/ApplicationFormEdit';
import PreliminaryInterview from './components/RECRUITMENT/PreliminaryInterview';
import PreliminaryInterviewCreate from './components/RECRUITMENT/PreliminaryInterviewCreate';
import ExamResult from './components/RECRUITMENT/ExamResult';
import ExamResultCreate from './components/RECRUITMENT/ExamResultCreate';
import BackgroundInvestigation from './components/RECRUITMENT/BackgroundInvestigation';
import BackgroundInvestigationCreate from './components/RECRUITMENT/BackgroundInvestigationCreate';
import ClientEndorsement from './components/RECRUITMENT/ClientEndorsement';
import ClientEndorsementCreate from './components/RECRUITMENT/ClientEndorsementCreate';
import FinalInterview from './components/RECRUITMENT/FinalInterview';
import FinalInterviewCreate from './components/RECRUITMENT/FinalInterviewCreate';
import JobOffer from './components/RECRUITMENT/JobOffer';
import JobOfferCreate from './components/RECRUITMENT/JobOfferCreate';
import PreliminaryInterviewsCreate from './components/RECRUITMENT/PreliminaryInterviewsCreate';
import ExamResultsCreate from './components/RECRUITMENT/ExamResultsCreate';
import BackgroundInvestigationsCreate from './components/RECRUITMENT/BackgroundInvestigationsCreate';
import ClientEndorsementsCreate from './components/RECRUITMENT/ClientEndorsementsCreate';
import FinalInterviewsCreate from './components/RECRUITMENT/FinalInterviewsCreate';
import JobOffersCreate from './components/RECRUITMENT/JobOffersCreate';

//import AllegedInfraction from './components/Administrator/AllegedInfraction';
import RoleAccess from './components/Administrator/AccessRights/RoleAccess'
import UserAccess from './components/Administrator/AccessRights/UserAccess';
import Features from './components/Administrator/AccessRights/Features';
import CreateFeature from './components/Administrator/AccessRights/CreateFeature'
import Users from './components/Administrator/AccessRights/Users'
import Roles from './components/Administrator/AccessRights/Roles'

// ADMINISTRATOR START
import Diagnoseddisease from './components/Administrator/Diagnoseddisease';
import DiagnoseddiseaseCreate from './components/Administrator/DiagnoseddiseaseCreate';
import ExamCategories from './components/Administrator/ExamCategories';
import ExamCategoriesCreate from './components/Administrator/ExamCategoriesCreate';
import Exam from './components/Administrator/Exam';
import ExamCreate from './components/Administrator/ExamCreate';
import JobOpeningSource from './components/Administrator/JobOpeningSource';
import JobOpeningSourceCreate from './components/Administrator/JobOpeningSourceCreate';
import JobPosition from './components/Administrator/JobPosition';
import JobPositionCreate from './components/Administrator/JobPositionCreate';
import Nationality from './components/Administrator/Nationality';
import NationalityCreate from './components/Administrator/NationalityCreate';
import StoreOperation from './components/Administrator/StoreOperation';
import StoreOperationCreate from './components/Administrator/StoreOperationCreate';
import SupportGroup from './components/Administrator/SupportGroup';
import SupportGroupCreate from './components/Administrator/SupportGroupCreate';
import Religions from './components/Administrator/Religions';
import ReligionCreate from './components/Administrator/ReligionCreate';

import LeaveType from './components/Administrator/LeaveType';
import LeaveTypeCreate from './components/Administrator/LeaveTypeCreate';

import EmployeeStatusType from './components/Administrator/EmployeeStatusType';
import EmployeeStatusTypeCreate from './components/Administrator/EmployeeStatusTypeCreate';
import AllegedInfraction from './components/Administrator/AllegedInfraction';
import AllegedInfractionCreate from './components/Administrator/AllegedInfractionCreate';

import BloodType from './components/Administrator/BloodType';
import BloodTypeCreate from './components/Administrator/BloodTypeCreate';
import Reason from './components/Administrator/Reason';
import ReasonCreate from './components/Administrator/ReasonCreate';
import Client from './components/Administrator/Client';
import ClientCreate from './components/Administrator/ClientCreate';
import LivingArrangement from './components/Administrator/LivingArrangement';
import LivingArrangementCreate from './components/Administrator/LivingArrangementCreate';

import Tax from './components/Administrator/Tax';
import TaxCreate from './components/Administrator/TaxCreate';
import AnnualTaxTable from './components/Administrator/AnnualTaxTable';
import AnnualTaxTableCreate from './components/Administrator/AnnualTaxTableCreate';
import TaxCode from './components/Administrator/TaxCode';
import TaxCodeCreate from './components/Administrator/TaxCodeCreate';


import ManageProfiles from './components/Administrator/ManageProfiles';
import ManageProfilesCreate from './components/Administrator/ManageProfilesCreate';

import EmployeeRecord from './components/Administrator/EmployeeRecord';
import EmployeeRecordCreate from './components/Administrator/EmployeeRecordCreate';

import ManagerProfile from './components/Administrator/ManagerProfile';
import ManagerProfileCreate from './components/Administrator/ManagerProfileCreate';
import BatchUpload from './components/Administrator/BatchUpload';

import ManageAdmin from './components/Administrator/ManageAdmin';
import AdminApproved from './components/Administrator/AdminApproved';

import Profile from './components/Administrator/Profile';
import ProfileEdit from './components/Administrator/ProfileEdit';
import ProfileView from './components/Administrator/ProfileView';

import ManningManageProfiles from './components/Administrator/ManningManageProfiles';
import ManningBatchApprove from './components/Administrator/ManningBatchApprove';

import ManningEmployeeRecord from './components/Administrator/ManningEmployeeRecord';
import ManningRecordCreate from './components/Administrator/ManningRecordCreate';
import ManningRecordApprove from './components/Administrator/ManningRecordApprove';
import ViewRecord from './components/Administrator/ViewRecord';
import View from './components/Administrator/View';

import CustomerService from './components/CustomerService/CustomerService'
import ViewConcern from './components/CustomerService/ViewConcern'
import CaseScreen from './components/CustomerService/CaseScreen'

import OffCyclePayroll from './components/Payroll/OffCyclePayroll';                    
                       

/* START TRAINING 05162020 23:00 - CLYDE */

import Training from './components/Training/Trainings'
import TrainingCreate from './components/Training/TrainingCreate'

//import RequestMonitoring from './components/Training/RequestMonitoring';
import TrainingScheduleRegistration from './components/Training/TrainingScheduleRegistration';

import TrainingRegister from './components/Training/TrainingRegister';
import TrainingRegisterDetails from './components/Training/TrainingRegisterDetails';

import TrainingEvaluationAndRecommendation from './components/Training/TrainingEvaluationAndRecommendation'

import EmployeeDeploymentClearance from './components/Training/Admin/EmployeeDeploymentClearance'
import EmployeeDeploymentClearanceCreate from './components/Training/Admin/EmployeeDeploymentClearanceCreate'

import Documents from './components/Training/Documents'
import DocumentsCreate from './components/Training/DocumentsCreate'

/* ivan */
import CreateRecruitTraining  from './components/RECRUITMENT/CreateRecruitTraining';
import CreateLandDTraining  from './components/RECRUITMENT/CreateLandDTraining';
import LDTrainingSchedule from './components/Training/LDTrainingSchedule';
import EmployeeTrainingMonitoring from "./components/Training/TrainingMonitoring";
import RequestMonitoring from './components/Training/RequestMonitoring';
import EmployeeInfo from './components/Training/EmployeeInfo';
import EmployeeTrainingList from "./components/Training/TrainingList"
import EmployeeInformationTrainee from "./components/Training/EmployeeInformation";
/* END TRAINING 05162020 23:00 */


/* CASE MAINTENANCE START*/

import LateAbsentSearch from './components/Maintenance/LateAbsentSearch';
import LateAbsentCreate from './components/Maintenance/LateAbsentCreate';
import IncidentReportForm from './components/Maintenance/IncidentReportForm';
import ExportIncidentReport from './components/Maintenance/ExportIncidentReport';
import NoticeToExplain from './components/Maintenance/NoticeToExplain'; 
import ExportNTE from './components/Maintenance/ExportNTE';
import PenaltyTypeSearch from './components/Maintenance/PenaltyTypeSearch';
import PenaltyTypeCreate from './components/Maintenance/PenaltyTypeCreate';
import NatureOfAbsentAndLateSearch from './components/Maintenance/NatureOfAbsentAndLateSearch';
import NatureOfAbsentAndLateCreate from './components/Maintenance/NatureOfAbsentAndLateCreate';
import NodaFurtherActionSearch from './components/Maintenance/NodaFurtherActionSearch';
import NodaFurtherActionCreate from './components/Maintenance/NodaFurtherActionCreate';
import MonitoringStatusSearch from './components/Maintenance/MonitoringStatusSearch';
import MonitoringStatusCreate from './components/Maintenance/MonitoringStatusCreate';
import DoleCases from './components/Maintenance/DoleCases'; 
import DoleCompliance from './components/Maintenance/DoleCompliance'; 
import CriminalCases from './components/Maintenance/CriminalCases';
import CasesMonitoringStatus from './components/Maintenance/CasesMonitoringStatus';
import DoleCasesMonitoring from './components/Maintenance/DoleCasesMonitoring';
import DoleComplianceMonitoring from './components/Maintenance/DoleComplianceMonitoring';
import CriminalCasesMonitoring from './components/Maintenance/CriminalCasesMonitoring';
import RecordNodaPenalty from './components/Maintenance/RecordNodaPenalty';
import CodeOfConduct from './components/Maintenance/CodeOfConduct';
import CodeOfConductCreateArticle from './components/Maintenance/CodeOfConductCreateArticle';
import CodeOfConductCreateSection from './components/Maintenance/CodeOfConductCreateSection';
import AdminCasesSearch from './components/Maintenance/AdminCasesSearch';
import AdminCasesCreate from './components/Maintenance/AdminCasesCreate';
import CasesTypes from './components/Maintenance/CasesTypes';
import CasesCreate from './components/Maintenance/CasesCreate';

/* CASE MAINTENANCE END*/

import PostTrainingExamination from './components/Training/PostTrainingExamination'
import PostTrainingExaminationCreate from './components/Training/PostTrainingExaminationCreate'
import PostTrainingExaminationViewEdit from './components/Training/PostTrainingExaminationViewEdit'
import EmployeePostTrainingExam from './components/Training/EmployeePostTrainingExam'
import EmployeePreTrainingExam from './components/Training/EmployeePreTrainingExam'

import PendingJobOffer from './components/HRAdministrator/PendingJobOffer'
import PendingJobOfferEdit from './components/HRAdministrator/PendingJobOfferEdit'

import TransmittedDocuments from './components/RECRUITMENT/TransmittedDocuments'

import AdminTransmittedDocuments from './components/HRAdministrator/TransmittedDocuments'
import DeploymentMonitorings from './components/HRAdministrator/DeploymentMonitoring'

import TrainingMonitoringSuspended from './components/Manning/TrainingMonitoringSuspended'
import TrainingMonitorings from './components/Manning/TrainingMonitorings'

import EmployeeInformation from './components/Manning/EmployeeInformation'
import EmployeeApproval from './components/Manning/EmployeeApproval'
import ManageEmployee from './components/Manning/ManageEmployeeInfo'
import ManageEmployees from './components/Manning/ManageEmployees'
import ManageEmployeeApprovals from './components/Manning/ManageEmployeeApprovals'

import ManageDraftEmployees from './components/Manning/ManageDraftEmployees'
import ManageDraftEmployeeApprovals from './components/Manning/ManageDraftEmployeeApprovals'
import DraftEmployee from './components/Manning/DraftEmployee'

import Employee201 from './components/HRAdministrator/ManageEmployee201'



/* START FINANCE */
import FinanceManageEmployees from './components/Finance/ManageEmployees'
/* END FINANCE */


/* PENDING JOB OFFER VIEW APPLICATION 06182021 */
import ViewApplication from './components/HRAdministrator/ViewApplication'


import RehireApplicant from './components/RECRUITMENT/ApplicationFormRehire'
import SpecialConfiguration from './components/Timekeeping/SpecialClient/SpecialClientConfig'
import BatchScheduler from './components/Manning/BatchScheduler'

import RoleDepartment from "./components/Maintenance/RoleDepartment";
import Cabinet from "./components/Maintenance/Cabinet";
import Drawer from "./components/Maintenance/Drawer";

import Regularization from "./components/HRAdministrator/Regularization";

import DeductionSummary from './components/Payroll/DeductionSummary'

import RegularContract from "./components/HRAdministrator/PDF/RegularContract";
import RegularCoopContract from "./components/HRAdministrator/PDF/RegularCoopContract";

import Contract from "./components/HRAdministrator/PDF/Contract";
import TrainingContract from "./components/HRAdministrator/PDF/TrainingContracts";
import ContractSigning from "./components/HRAdministrator/ContractSigning";
import EmployeeContract from "./components/HRAdministrator/EmployeeContract";

/* START PAYCARD */
import PayCardApplication from './components/Paycard/PayCardApplication';
import PayCardAdmin from './components/Paycard/PayCardAdmin';
import PayCardFinance from './components/Paycard/PayCardFinance';
import PayCardAMT from './components/Paycard/PayCardAMT';
import PayCardMonitoring from './components/Paycard/PayCardMonitoring';
import ViewDetails from './components/Paycard/ViewDetails';
import EditPaycardApplication from './components/Paycard/EditPaycardApplication';
import PayCardView from './components/Paycard/PayCardView';
/* END PAYCARD */

import ManageRequests from './components/Librarian/ManageEmployees';
import CreateRequest from './components/Librarian/CreateRequest';
import ManageRequestApprovals from './components/Librarian/ManageEmployeeApprovals';
import offices from './components/Maintenance/Branches';
import PayrollManageEmployee from './components/Payroll/ManageEmployee';
import MergeEmployees from './components/Manning/MergeEmployees';
import UserControl from './components/Administrator/AccessRights/UserControl';

import GenerateBilling from './components/Billing/GenerateBilling';
import BillingSummaryReport from './components/Billing/BIllingSummaryReport';
import BillingTransaction from './components/Billing/BillingTransaction';

import AttendanceSummary from './components/Reports/Timekeeping/AttendanceSummaryReport';
import  TardinessReport from './components/Reports/Timekeeping/TardinessReport'
import  DTRReport from './components/Reports/Timekeeping/DTRReport'
import  RecruitmentDeployment from './components/Reports/Recruitment/RecruitmentDeployment'
import  RecruitmentJobOffer from './components/Reports/Recruitment/RecruitmentJobOffer'

import  EEShareReport from './components/Reports/Payroll/EEShareReport'
import  EmployeeLoanReport from './components/Reports/Payroll/EmployeeLoanReport'
import  EmployeeDeductionReport from './components/Reports/Payroll/EmployeeDeductionReport'

import  Demographic from './components/Reports/Admin/Demographic'
import  MembersReport from './components/Reports/Admin/MembersReport'
import  EOCReport from './components/Reports/Admin/EOC'
import  AdminJobOffer from './components/Reports/Admin/AdminJobOffer'

import ProbiSummary from './components/Reports/Contracts/ProbiSummary'
import ProbiContractMonitoring from './components/Reports/Contracts/ProbiContractMonitoring'
import TraineeContractMonitoring from './components/Reports/Contracts/TraineeContractMonitoring'
import RegularizationMonitoring from './components/Reports/Contracts/RegularizationMonitoring'
import RegularizationSummary from './components/Reports/Contracts/RegularizationSummary'
import CoopRegConMonitoring from './components/Reports/Contracts/CoopRegConMonitoring'
import CoopRegConSummary from './components/Reports/Contracts/CoopRegConSummary'
import HMO from './components/Reports/Contracts/HMO'

import BIRPayrollReport from './components/Reports/Payroll/BIRPayrollReport';

/* START PAYLIP CLYDE 09/28/2022 */
import Payslip from './components/Payroll/PaySlip';
import DownloadPayslip from './components/Payroll/DownloadPayslip';
/* END PAYLIP CLYDE 09/28/2022 */

import EventBillableHours from './components/Timekeeping/BillableHours/EventBillableHours';
import GeneratePayrollEvent from './components/Payroll/GeneratePayrollEvent';
import PieceRate from './components/Timekeeping/BillableHours/PieceRate';
import GeneratePayrollPieceRate from './components/Payroll/GeneratePayrollPieceRate';

import PayrollPositionRates from './components/Maintenance/PositionRates';


import  ClientArea from './components/Reports/Admin/ClientArea'

import ManningEmployee201 from './components/Reports/Manning/ManningEmployee201'

import GeneratePayrollAdjustment from './components/Payroll/GeneratePayrollAdjustment';
import UploadDTRAdjustment from './components/Timekeeping/UploadDTRAdjustment/UploadDTRAdjustment';

import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-bootstrap-typeahead/css/Typeahead.css";

import ProbiContract from "./components/HRAdministrator/PDF/ProbiContracts";
import OtherPayroll from './components/Timekeeping/OtherPayroll/BillableOtherPayroll';
import GenerateOtherPayroll from './components/Payroll/GenerateOtherPayroll';

import PayrollLocation from './components/Maintenance/PayrollLocation';
import PayrollProduct from './components/Maintenance/PayrollProduct';
import PayrollPerTrip from './components/Maintenance/PayrollPerTrip';
import BillablePerTrip from './components/Timekeeping/PerTrip/BillablePerTrip';
import GeneratePayrollPerTrip from './components/Payroll/GeneratePayrollPerTrip'
import GenerateCustomBilling from './components/Billing/GenerateCustomBilling';
import CustomBillingSummaryReport from './components/Billing/CustomBillingSummaryReport';
import GenerateOtherBilling from './components/Billing/GenerateOtherBilling';
import OtherBillingSummaryReport from './components/Billing/OtherBillingSummaryReport';
import EventInvoice from './components/Maintenance/EventInvoice'
import CustomBillingTransaction from './components/Billing/CustomBillingTransaction'


//START CLEARANCE
import Clearance from './components/Clearance/Clearance';
import SearchScreen from './components/Clearance/SearchScreen';
import ClearanceEdit from './components/Clearance/ClearanceEdit';
import BatchClearance from './components/Clearance/BatchClearance';
import EmployeeClearance from './components/Clearance/EmployeeClearance';
import DisengagementMode from './components/Clearance/DisengagementMode';
import BatchClearanceAre from './components/Clearance/BatchClearanceAre';
import BatchClearancePayroll from './components/Clearance/BatchClearancePayroll';
import ClearanceAreUpload from './components/Clearance/ClearanceAreUpload';
import ClearanceFinance from './components/Clearance/ClearanceFinance';
import ClearanceFinanceAre from './components/Clearance/ClearanceFinanceAre';
import ClearancePayrollSummary from './components/Clearance/ClearancePayrollSummary';
import DemandLetterClearance from './components/Clearance/DemandLetterClearance';
import CreateChildClearance from './components/Clearance/CreateChildClearance';
import ClearanceLastPayroll from './components/Clearance/ClearanceLastPayroll'
//END CLEARANCE
class App extends Component {
    constructor(props){
        super(props);
        this.state = {
            isNavbarHidden: false
                                                                       
                                                                                   
                      
            
            
        };
                                                     
                                                
                                       
                                       
         
    }
    render(){
                   
             
                                     
                                   
             
         
        return (
	    <ErrorHandler>
            <div className="App">
                <BrowserRouter>
                    <Switch>
                        <Route path="/" exact component={Login}/>
			<Route path="/OnlineApplication" exact component={OnlineApplication}/>
                        <ProtectedRoute path="/home" exact component={Home}/>
                        <ProtectedRoute path="/ChangePassword" exact component={ChangePassword}/>
                        <ProtectedRoute path="/banner" exact component={Banner}/>
                             
                            
                                                                                                                                
                             
                                                                                                 
                        <ProtectedRoute path="/WorkSchedule/" exact component={WorkSchedule}/>
                        <ProtectedRoute path="/WorkScheduleCreate/" exact component={WorkScheduleCreate }/>
                        <ProtectedRoute path="/EmployeeConfig/" exact component={EmployeeConfig}/>
                        <ProtectedRoute path="/ListOfEmployeeConfig/" exact component={ListOfEmployeeConfig}/>                                                                                    
                        <ProtectedRoute path="/City/" exact component={City}/>
                        <ProtectedRoute path="/CityCreate/" exact component={CityCreate}/>
                        <ProtectedRoute path="/Holiday/" exact component={Holiday}/>
                        <ProtectedRoute path="/HolidayCreate/" exact component={HolidayCreate}/>
                        <ProtectedRoute path="/Province/" exact component={Province}/>
                        <ProtectedRoute path="/ProvinceCreate/" exact component={ProvinceCreate}/>
                        <ProtectedRoute path="/Region/" exact component={Region}/>
                        <ProtectedRoute path="/RegionCreate/" exact component={RegionCreate}/>
                        <ProtectedRoute path="/Breaktime/" exact component={Breaktime}/>
                        <ProtectedRoute path="/BreaktimeCreate/" exact component={BreaktimeCreate}/>
                        <ProtectedRoute path="/Modal/" exact component={Modal}/>
                        <ProtectedRoute path="/ChangeWorkSched/" exact component={ChangeWorkSched}/>
                        <ProtectedRoute path="/RateCard/" exact component={RateCard}/>
                        <ProtectedRoute path="/RateCardCreate/" exact component={RateCardCreate}/>
                        <ProtectedRoute path="/RateCardConfig/" exact component={RateCardConfig}/>
                        <ProtectedRoute path="/DailyTimeRecord/" exact component={DailyTimeRecord}/>
                        <ProtectedRoute path="/Location/" exact component={Location}/>
                        <ProtectedRoute path="/LocationCreate/" exact component={LocationCreate}/>
                        <ProtectedRoute path="/ClientConfig/" exact component={ClientConfig}/>
                        <ProtectedRoute path="/LeaveModal/" exact component={LeaveModal}/>
                        <ProtectedRoute path="/BillableHours" exact component={BillableHours}/>
                        <ProtectedRoute path="/BootStrapGrid1/" exact component={BootStrapGrid1}/>
                        <ProtectedRoute path="/ComputedDTR" exact component={ComputedDTR}/>
                        <ProtectedRoute path="/GenerateDTR" exact component={GenerateDTR}/>
                        <ProtectedRoute path="/GenerateWorkSchedule" exact component={GenerateWorkSchedule}/>
                        <ProtectedRoute path="/DTRStatusMonitoring" exact component={DTRStatusMonitoring}/>
                        <ProtectedRoute path="/uploadDTR" exact component={uploadDTR}/>                                                
                        
                        <ProtectedRoute path="/TransferEmployee" exact component={TransferEmployee}/>


                        <ProtectedRoute path="/PayrollConfiguration" exact component={PayrollConfiguration}/>
                        <ProtectedRoute path="/DeductionLedger" exact component={DeductionLedger}/>
                        <ProtectedRoute path="/InclusionLedger" exact component={InclusionLedger}/>
                        <ProtectedRoute path="/DeductionType/" exact component={DeductionType}/>
                        <ProtectedRoute path="/DeductionTypeCreate/" exact component={DeductionTypeCreate}/>
                        <ProtectedRoute path="/Deduction/" exact component={Deduction}/>
                        <ProtectedRoute path="/DeductionCreate/" exact component={DeductionCreate}/>
                        <ProtectedRoute path="/InclusionType/" exact component={InclusionType}/>
                        <ProtectedRoute path="/InclusionTypeCreate/" exact component={InclusionTypeCreate}/>
                        <ProtectedRoute path="/Inclusion/" exact component={Inclusion}/>
                        <ProtectedRoute path="/InclusionCreate/" exact component={InclusionCreate}/>
                        <ProtectedRoute path="/SSSContributionModal" exact component={SSSContributionModal}/>
                        <ProtectedRoute path="/PHICModal" exact component={PHICModal}/>
                        <ProtectedRoute path="/SSSLoanModal" exact component={SSSLoanModal}/>
                        <ProtectedRoute path="/PagibigLoanModal" exact component={PagibigLoanModal}/>
                        <ProtectedRoute path="/HDMFContributionModal" exact component={HDMFContributionModal}/>
                        <ProtectedRoute path="/GeneratePayroll" exact component={GeneratePayroll}/>
                        <ProtectedRoute path="/PayrollSSSLoanDeduction" exact component={PayrollSSSLoanDeduction}/>
                        <ProtectedRoute path="/SSSTable" exact component={SSSTable}/>
                        <ProtectedRoute path="/PhilHealthTable" exact component={PhilHealthTable}/>
                        <ProtectedRoute path="/PagibigTable" exact component={PagibigTable}/>
                        <ProtectedRoute path="/PayrollInclusions" exact component={PayrollInclusions}/>
                        <ProtectedRoute path="/PayrollInclusionMenu" exact component={PayrollInclusionMenu}/>
                        <ProtectedRoute path="/PayrollLoans" exact component={PayrollLoans}/>
                        <ProtectedRoute path="/PayrollLoanTypes" exact component={PayrollLoanTypes}/>
                        <ProtectedRoute path="/PayrollLoanTypesCreate" exact component={PayrollLoanTypesCreate}/>
                        <ProtectedRoute path="/PayrollDeductions" exact component={PayrollDeductions}/>
                        <ProtectedRoute path="/LoanType" exact component={LoanType}/>
                        <ProtectedRoute path="/LoanTypeCreate" exact component={LoanTypeCreate}/>  
                        <ProtectedRoute path="/PayrollLoanAdjustments" exact component={PayrollLoanAdjustments}/>
                        <ProtectedRoute path="/PayrollDeductionAdjustments" exact component={PayrollDeductionAdjustments}/>
                        <ProtectedRoute path="/PayrollContributionAdjustments" exact component={PayrollContributionAdjustments}/>
                        <ProtectedRoute path="/GeneratePayrollFile" exact component={GeneratePayrollFile}/>
                        

                        <ProtectedRoute path="/ReportPayrollSummary" exact component={ReportPayrollSummary}/>
                        <ProtectedRoute path="/ReportGovernmentMandatory" exact component={ReportGovernmentMandatory}/>
                        <ProtectedRoute path="/ReportLoanType" exact component={ReportLoanType}/>
                        <ProtectedRoute path="/ReportDeductionType" exact component={ReportDeductionType}/>

                        <ProtectedRoute path="/BillingConfiguration" exact component={BillingConfiguration}/>
                        <ProtectedRoute path="/BillingScreen" exact component={BillingScreen}/>
                        <ProtectedRoute path="/BillingSummary" exact component={BillingSummary}/>
                        
                        <ProtectedRoute path="/Area" exact component={Area}/>
                        <ProtectedRoute path="/AreaCreate" exact component={AreaCreate}/>
                        <ProtectedRoute path="/Department" exact component={Department}/>
                        <ProtectedRoute path="/DepartmentCreate" exact component={DepartmentCreate}/>
                        <ProtectedRoute path="/DepartmentSection" exact component={DepartmentSection}/>
                        <ProtectedRoute path="/DepartmentSectionCreate" exact component={DepartmentSectionCreate}/>
                        <ProtectedRoute path="/CostCenter" exact component={CostCenter}/>
                        <ProtectedRoute path="/CostCenterCreate" exact component={CostCenterCreate}/>
                        <ProtectedRoute path="/Position" exact component={Position}/>
                        <ProtectedRoute path="/PositionCreate" exact component={PositionCreate}/>
                        <ProtectedRoute path="/AllegedInfraction" exact component={AllegedInfraction}/>
                        <ProtectedRoute path="/RoleAccess" exact component={RoleAccess}/>
                        <ProtectedRoute path="/UserAccess" exact component={UserAccess}/>
                        <ProtectedRoute path="/Features" exact component={Features}/>
                        <ProtectedRoute path="/CreateFeature" exact component={CreateFeature}/>
                        <ProtectedRoute path="/Users" exact component={Users}/>
                        <ProtectedRoute path="/Roles" exact component={Roles}/>

                        <ProtectedRoute path="/ApplicationForm" exact component={ApplicationForm}/>
                        <ProtectedRoute path="/ApplicationFormCreate" exact component={ApplicationFormCreate}/>
                        <ProtectedRoute path="/ApplicationFormEdit" exact component={ApplicationFormEdit}/>
                        <ProtectedRoute path="/PreliminaryInterview" exact component={PreliminaryInterview}/>
                        <ProtectedRoute path="/PreliminaryInterviewCreate" exact component={PreliminaryInterviewCreate}/>
                        <ProtectedRoute path="/ExamResult" exact component={ExamResult}/>
                        <ProtectedRoute path="/ExamResultCreate" exact component={ExamResultCreate}/>
                        <ProtectedRoute path="/BackgroundInvestigation" exact component={BackgroundInvestigation}/>
                        <ProtectedRoute path="/BackgroundInvestigationCreate" exact component={BackgroundInvestigationCreate}/>
                        <ProtectedRoute path="/ClientEndorsement" exact component={ClientEndorsement}/>
                        <ProtectedRoute path="/ClientEndorsementCreate" exact component={ClientEndorsementCreate}/>
                        <ProtectedRoute path="/FinalInterview" exact component={FinalInterview}/>
                        <ProtectedRoute path="/FinalInterviewCreate" exact component={FinalInterviewCreate}/>
                        <ProtectedRoute path="/JobOffer" exact component={JobOffer}/>
                        <ProtectedRoute path="/JobOfferCreate" exact component={JobOfferCreate}/>
                        <ProtectedRoute path="/PreliminaryInterviewsCreate" exact component={PreliminaryInterviewsCreate}/>
                        <ProtectedRoute path="/ExamResultsCreate" exact component={ExamResultsCreate}/>
                        <ProtectedRoute path="/BackgroundInvestigationsCreate" exact component={BackgroundInvestigationsCreate}/>
                        <ProtectedRoute path="/ClientEndorsementsCreate" exact component={ClientEndorsementsCreate}/>
                        <ProtectedRoute path="/FinalInterviewsCreate" exact component={FinalInterviewsCreate}/>
                        <ProtectedRoute path="/JobOffersCreate" exact component={JobOffersCreate}/>

            <ProtectedRoute path="/Diagnoseddisease" exact component={Diagnoseddisease}/>
                        <ProtectedRoute path="/DiagnoseddiseaseCreate" exact component={DiagnoseddiseaseCreate}/>
                        <ProtectedRoute path="/ExamCategories" exact component={ExamCategories}/>
                        <ProtectedRoute path="/ExamCategoriesCreate" exact component={ExamCategoriesCreate}/>
                        <ProtectedRoute path="/Exam" exact component={Exam}/>
                        <ProtectedRoute path="/ExamCreate" exact component={ExamCreate}/>
                        <ProtectedRoute path="/JobOpeningSource" exact component={JobOpeningSource}/>
                        <ProtectedRoute path="/JobOpeningSourceCreate" exact component={JobOpeningSourceCreate}/>
                        <ProtectedRoute path="/JobPosition" exact component={JobPosition}/>
                        <ProtectedRoute path="/JobPositionCreate" exact component={JobPositionCreate}/>
                        <ProtectedRoute path="/Nationality" exact component={Nationality}/>
                        <ProtectedRoute path="/NationalityCreate" exact component={NationalityCreate}/>
                        
                        <ProtectedRoute path="/Religions" exact component={Religions}/>
                        <ProtectedRoute path="/ReligionCreate" exact component={ReligionCreate}/>
                        <ProtectedRoute path="/StoreOperation" exact component={StoreOperation}/>
                        <ProtectedRoute path="/StoreOperationCreate" exact component={StoreOperationCreate}/>
                        <ProtectedRoute path="/SupportGroup" exact component={SupportGroup}/>
                        <ProtectedRoute path="/SupportGroupCreate" exact component={SupportGroupCreate}/>
                       
                       
                        <ProtectedRoute path="/EmployeeStatusType" exact component={EmployeeStatusType}/>
                        <ProtectedRoute path="/EmployeeStatusTypeCreate" exact component={EmployeeStatusTypeCreate}/>
                        <ProtectedRoute path="/AllegedInfraction" exact component={AllegedInfraction}/>
                        <ProtectedRoute path="/AllegedInfractionCreate" exact component={AllegedInfractionCreate}/> 
                        
                        <ProtectedRoute path="/Client" exact component={Client}/>
                        <ProtectedRoute path="/ClientCreate" exact component={ClientCreate}/>
                       
                        <ProtectedRoute path="/Reason" exact component={Reason}/>
                        <ProtectedRoute path="/ReasonCreate" exact component={ReasonCreate}/>
                        <ProtectedRoute path="/LeaveType" exact component={LeaveType}/>
                        <ProtectedRoute path="/LeaveTypeCreate" exact component={LeaveTypeCreate}/>
                        
                        <ProtectedRoute path="/Tax" exact component={Tax}/>
                        <ProtectedRoute path="/TaxCreate" exact component={TaxCreate}/>
                        <ProtectedRoute path="/AnnualTaxTable" exact component={AnnualTaxTable}/>
                        <ProtectedRoute path="/AnnualTaxTableCreate" exact component={AnnualTaxTableCreate}/> 
                        <ProtectedRoute path="/TaxCode" exact component={TaxCode}/>
                        <ProtectedRoute path="/TaxCodeCreate" exact component={TaxCodeCreate}/>
                        
                       
                        
                        <ProtectedRoute path="/BloodType" exact component={BloodType}/>
                        <ProtectedRoute path="/BloodTypeCreate" exact component={BloodTypeCreate}/>
                       
                        <ProtectedRoute path="/LivingArrangementCreate" exact component={LivingArrangementCreate}/>
                        <ProtectedRoute path="/LivingArrangement" exact component={LivingArrangement}/>
                       

                       <ProtectedRoute path="/ManageProfiles" exact component={ManageProfiles}/>
                        <ProtectedRoute path="/ManageProfilesCreate" exact component={ManageProfilesCreate}/>
                        <ProtectedRoute path="/EmployeeRecord" exact component={EmployeeRecord}/>
                        <ProtectedRoute path="/EmployeeRecordCreate" exact component={EmployeeRecordCreate}/>

                        <ProtectedRoute path="/ManagerProfile" exact component={ManagerProfile}/>
                        <ProtectedRoute path="/ManagerProfileCreate" exact component={ManagerProfileCreate}/>

                        <ProtectedRoute path="/ManningManageProfiles" exact component={ManningManageProfiles}/>
                        <ProtectedRoute path="/ManningBatchApprove" exact component={ManningBatchApprove}/>

                        <ProtectedRoute path="/ManningEmployeeRecord" exact component={ManningEmployeeRecord}/>
                        <ProtectedRoute path="/ManningRecordCreate" exact component={ManningRecordCreate}/>
                        <ProtectedRoute path="/ManningRecordApprove" exact component={ManningRecordApprove}/>
                        <ProtectedRoute path="/ViewRecord" exact component={ViewRecord}/>
                        <ProtectedRoute path="/View" exact component={View}/>


                        <ProtectedRoute path="/BatchUpload" exact component={BatchUpload}/>
                        <ProtectedRoute path="/ManageAdmin" exact component={ManageAdmin}/>
                        <ProtectedRoute path="/AdminApproved" exact component={AdminApproved}/>
                        <ProtectedRoute path="/Profile" exact component={Profile}/>
                        <ProtectedRoute path="/ProfileEdit" exact component={ProfileEdit}/>
  			<ProtectedRoute path="/ProfileView" exact component={ProfileView}/>                                                                    
                        <ProtectedRoute path="/CustomerService" exact component={CustomerService}/>
                        <ProtectedRoute path="/ViewConcern" exact component={ViewConcern}/>
                        <ProtectedRoute path="/CaseScreen" exact component={CaseScreen}/>            
                                                                              
            		<ProtectedRoute path="/OffCyclePayroll" exact component={OffCyclePayroll}/>



                       {/* START TRAINING 05162020 23:00 - CLYDE  */}

                        <ProtectedRoute path="/Trainings" exact component={Training}/>
                        <ProtectedRoute path="/TrainingCreate" exact component={TrainingCreate}/>
                        
                        <ProtectedRoute path="/Documents" exact component={Documents}/>
                        <ProtectedRoute path="/DocumentsCreate" exact component={DocumentsCreate}/>

                        <ProtectedRoute path="/RequestMonitoring" exact component={RequestMonitoring}/>
                        <ProtectedRoute path="/TrainingScheduleRegistration" exact component={TrainingScheduleRegistration}/>
                        
                        <ProtectedRoute path="/TrainingRegister" exact component={TrainingRegister}/>
                        <ProtectedRoute path="/TrainingRegisterDetails" exact component={TrainingRegisterDetails}/>

                        <ProtectedRoute path="/TrainingEvaluationAndRecommendation" exact component={TrainingEvaluationAndRecommendation}/>

                        <ProtectedRoute path="/EmployeeDeploymentClearance" exact component={EmployeeDeploymentClearance}/>
                        <ProtectedRoute path="/EmployeeDeploymentClearanceCreate" exact component={EmployeeDeploymentClearanceCreate}/>


			{/* ivan */}

			<ProtectedRoute path="/LDTrainingSchedule" exact component={LDTrainingSchedule}/>
 			<ProtectedRoute path="/CreateRecruitTraining" exact component={CreateRecruitTraining}/>
 		       <ProtectedRoute path="/CreateLandDTraining" exact component={CreateLandDTraining}/>
		       <ProtectedRoute path="/TrainingMonitoring" exact component={TrainingMonitorings}/>
		       <ProtectedRoute path="/RequestMonitoring" exact component={RequestMonitoring}/>
                       <ProtectedRoute path="/EmployeeInfo" exact component={EmployeeInfo}/>

                        {/* END TRAINING  05162020 23:00*/} 


				
			
			{/* CASE MAINTENANCE START*/}
			
			<ProtectedRoute path="/LateAbsentSearch" exact component={LateAbsentSearch}/>
                        <ProtectedRoute path="/LateAbsentCreate" exact component={LateAbsentCreate}/>
                        <ProtectedRoute path="/IncidentReportForm" exact component={IncidentReportForm}/>
                        <ProtectedRoute path="/ExportIncidentReport" exact component={ExportIncidentReport}/>
                        <ProtectedRoute path="/NoticeToExplain" exact component={NoticeToExplain}/>
                        <ProtectedRoute path="/ExportNTE" exact component={ExportNTE}/>
                        <ProtectedRoute path="/PenaltyTypeSearch" exact component={PenaltyTypeSearch}/>
                        <ProtectedRoute path="/PenaltyTypeCreate" exact component={PenaltyTypeCreate}/>
                        <ProtectedRoute path="/NatureOfAbsentAndLateSearch" exact component={NatureOfAbsentAndLateSearch}/>
                        <ProtectedRoute path="/NatureOfAbsentAndLateCreate" exact component={NatureOfAbsentAndLateCreate}/>
                        <ProtectedRoute path="/NodaFurtherActionSearch" exact component={NodaFurtherActionSearch}/>
                        <ProtectedRoute path="/NodaFurtherActionCreate" exact component={NodaFurtherActionCreate}/>
                        <ProtectedRoute path="/MonitoringStatusSearch" exact component={MonitoringStatusSearch}/>
                        <ProtectedRoute path="/MonitoringStatusCreate" exact component={MonitoringStatusCreate}/>
                        <ProtectedRoute path="/DoleCases" exact component={DoleCases}/>
                        <ProtectedRoute path="/DoleCompliance" exact component={DoleCompliance}/>
                        <ProtectedRoute path="/CriminalCases" exact component={CriminalCases}/>
                        <ProtectedRoute path="/CasesMonitoringStatus" exact component={CasesMonitoringStatus}/>
                        <ProtectedRoute path="/DoleCasesMonitoring" exact component={DoleCasesMonitoring}/>
                        <ProtectedRoute path="/DoleComplianceMonitoring" exact component={DoleComplianceMonitoring}/>
                        <ProtectedRoute path="/CriminalCasesMonitoring" exact component={CriminalCasesMonitoring}/>
                        <ProtectedRoute path="/RecordNodaPenalty" exact component={RecordNodaPenalty}/>
                        <ProtectedRoute path="/CodeOfConduct" exact component={CodeOfConduct}/>
                        <ProtectedRoute path="/CodeOfConductCreateArticle" exact component={CodeOfConductCreateArticle}/>
                        <ProtectedRoute path="/CodeOfConductCreateSection" exact component={CodeOfConductCreateSection}/>
                        <ProtectedRoute path="/CasesTypes" exact component={CasesTypes}/>
                        <ProtectedRoute path="/CasesCreate" exact component={CasesCreate}/>
                        <ProtectedRoute path="/AdminCasesSearch" exact component={AdminCasesSearch}/>
                        <ProtectedRoute path="/AdminCasesCreate" exact component={AdminCasesCreate}/>
			
			{/* CASE MAINTENANCE END*/}


			<ProtectedRoute path="/PostTrainingExamination" exact component={PostTrainingExamination}/>
			<ProtectedRoute path="/PostTrainingExaminationCreate" exact component={PostTrainingExaminationCreate}/>
			<ProtectedRoute path="/EmployeePostTrainingExam" exact component={EmployeePostTrainingExam}/>
			<ProtectedRoute path="/EmployeePreTrainingExam" exact component={EmployeePreTrainingExam}/>

			<ProtectedRoute path="/PendingJobOffer" exact component={PendingJobOffer}/>
			<ProtectedRoute path="/PendingJobOfferEdit" exact component={PendingJobOfferEdit}/>

			<ProtectedRoute path="/TransmittedDocuments" exact component={TransmittedDocuments}/>
			<ProtectedRoute path="/AdminTransmittedDocuments" exact component={AdminTransmittedDocuments}/>
			<ProtectedRoute path="/TrainingMonitorings" exact component={TrainingMonitoringSuspended}/>
			<ProtectedRoute path="/TrainingMonitoring" exact component={TrainingMonitorings}/>
			<ProtectedRoute path="/DeploymentMonitorings" exact component={DeploymentMonitorings}/>

			<ProtectedRoute path="/EmployeeInformation" exact component={EmployeeInformation}/>
			<ProtectedRoute path="/EmployeeApproval" exact component={EmployeeApproval}/>
			<ProtectedRoute path="/ManageEmployee" exact component={ManageEmployee}/>
			<ProtectedRoute path="/ManageEmployees" exact component={ManageEmployees}/>
			<ProtectedRoute path="/ManageEmployeeApprovals" exact component={ManageEmployeeApprovals}/>

			<ProtectedRoute path="/ManageDraftEmployees" exact component={ManageDraftEmployees}/>
			<ProtectedRoute path="/ManageDraftEmployeeApprovals" exact component={ManageDraftEmployeeApprovals}/>
			<ProtectedRoute path="/DraftEmployee" exact component={DraftEmployee}/>

			<ProtectedRoute path="/Employee201" exact component={Employee201}/>
			


                        {/* START FINANCE */}
			            <ProtectedRoute path="/FinanceManageEmployees" exact component={FinanceManageEmployees}/>
                        {/* END FINANCE */}

                        {/* START PENDING JOB OFFER VIEW APPLICATION 06162021 */}
			<ProtectedRoute path="/viewapplication" exact component={ViewApplication}/>
                        {/* END PENDING JOB OFFER VIEW APPLICATION 06162021 */}



			<ProtectedRoute path="/RehireApplicant" exact component={RehireApplicant}/>
			<ProtectedRoute path="/SpecialConfiguration" exact component={SpecialConfiguration}/>
			<ProtectedRoute path="/BatchScheduler" exact component={BatchScheduler}/>

			<ProtectedRoute path="/RoleDepartment" exact component={RoleDepartment}/>
                        <ProtectedRoute path="/Cabinet" exact component={Cabinet}/>
                        <ProtectedRoute path="/Drawer" exact component={Drawer}/>
                        <ProtectedRoute path="/Regularization" exact component={Regularization}/>

			<ProtectedRoute path="/DeductionSummary" exact component={DeductionSummary}/>

			<ProtectedRoute path="/RegularContract" exact component={RegularContract}/>
                            <ProtectedRoute path="/RegularCoopContract" exact component={RegularCoopContract}/>
                            <ProtectedRoute path="/Contract" exact component={Contract}/>
                            <ProtectedRoute path="/TrainingContract" exact component={TrainingContract}/>
                            <ProtectedRoute path="/ContractSigning" exact component={ContractSigning}/>
                            <ProtectedRoute path="/EmployeeContract" exact component={EmployeeContract}/>

                        {/* START PAYCARD */}
			<ProtectedRoute path="/PayCardApplication" exact component={PayCardApplication}/>
                        <ProtectedRoute path="/PayCardAdmin" exact component={PayCardAdmin}/>
                        <ProtectedRoute path="/PayCardFinance" exact component={PayCardFinance}/>
                        <ProtectedRoute path="/PayCardAMT" exact component={PayCardAMT}/>
			<ProtectedRoute path="/PayCardMonitoring" exact component={PayCardMonitoring}/>
			<ProtectedRoute path="/paycardviewdetails" exact component={ViewDetails}/>
			<ProtectedRoute path="/editpaycardapplication" exact component={EditPaycardApplication}/>
			<ProtectedRoute path="/PayCardView" exact component={PayCardView}/>
                        {/* END PAYCARD */}

                            <ProtectedRoute path="/ManageRequests" exact component={ManageRequests}/>
                            <ProtectedRoute path="/CreateRequest" exact component={CreateRequest}/>
                            <ProtectedRoute path="/ManageRequestApprovals" exact component={ManageRequestApprovals}/>

                            <ProtectedRoute path="/offices" exact component={offices}/>
			    <ProtectedRoute path="/PayrollManageEmployee" exact component={PayrollManageEmployee}/>
			    <ProtectedRoute path="/MergeEmployees" exact component={MergeEmployees}/> 
				<ProtectedRoute path="/UserControl" exact component={UserControl}/>
			<ProtectedRoute path="/GenerateBilling" exact component={GenerateBilling}/>
			<ProtectedRoute path="/BillingSummaryReport" exact component={BillingSummaryReport}/>
			<ProtectedRoute path="/BillingTransaction" exact component={BillingTransaction}/>
			<ProtectedRoute path="/AttendanceSummary" exact component={AttendanceSummary}/>
                        <ProtectedRoute path="/TardinessReport" exact component={TardinessReport}/>
                        <ProtectedRoute path="/DTRReport" exact component={DTRReport}/>
                        <ProtectedRoute path="/RecruitmentDeployment" exact component={RecruitmentDeployment}/>
                        <ProtectedRoute path="/RecruitmentJobOffer" exact component={RecruitmentJobOffer}/>
                        <ProtectedRoute path="/AdminJobOffer" exact component={AdminJobOffer}/>
                        <ProtectedRoute path="/EEShareReport" exact component={EEShareReport}/>
                        <ProtectedRoute path="/EmployeeLoanReport" exact component={EmployeeLoanReport}/>
                        <ProtectedRoute path="/EmployeeDeductionReport" exact component={EmployeeDeductionReport}/>
                        <ProtectedRoute path="/Demographic" exact component={Demographic}/>
                        <ProtectedRoute path="/MembersReport" exact component={MembersReport}/>
                        <ProtectedRoute path="/EOCReport" exact component={EOCReport}/>
			<ProtectedRoute path="/probisummary" exact component={ProbiSummary}/>
			<ProtectedRoute path="/probicontractmonitoring" exact component={ProbiContractMonitoring}/>
			<ProtectedRoute path="/traineecontractmonitoring" exact component={TraineeContractMonitoring}/>
			<ProtectedRoute path="/regularizationmonitoring" exact component={RegularizationMonitoring}/>
			<ProtectedRoute path="/regularizationsummary" exact component={RegularizationSummary}/>
			<ProtectedRoute path="/coopregconmonitoring" exact component={CoopRegConMonitoring}/>
			<ProtectedRoute path="/coopregconsummary" exact component={CoopRegConSummary}/>
			<ProtectedRoute path="/hmo" exact component={HMO}/>

			<ProtectedRoute path="/LDTrainingSchedule" exact component={LDTrainingSchedule}/>
                        <ProtectedRoute path="/CreateRecruitTraining" exact component={CreateRecruitTraining}/>
                        <ProtectedRoute path="/CreateLandDTraining" exact component={CreateLandDTraining}/>
			<ProtectedRoute path="/EmployeeInformation" exact component={EmployeeInformationTrainee}/>
                        <ProtectedRoute path="/EmployeeTrainingMonitoring" exact component={EmployeeTrainingMonitoring}/>
                        <ProtectedRoute path="/EmployeeTrainingList" exact component={EmployeeTrainingList}/>

			<ProtectedRoute path="/BIRPayrollReport" exact component={BIRPayrollReport}/>

                        {/* START PAYSLIP CLYDE 09/28/2022 */}
			<ProtectedRoute path="/payslip" exact component={Payslip}/>
			<ProtectedRoute path="/downloadpayslip" exact component={DownloadPayslip}/>
                        {/* END PAYSLIP CLYDE 09/28/2022 */}
			<ProtectedRoute path="/EventBillableHours" exact component={EventBillableHours}/>
                        <ProtectedRoute path="/GeneratePayrollEvent" exact component={GeneratePayrollEvent}/>
			<ProtectedRoute path="/PieceRate" exact component={PieceRate}/>
                        <ProtectedRoute path="/GeneratePayrollPieceRate" exact component={GeneratePayrollPieceRate}/>
			<ProtectedRoute path="/positionrates" exact component={PayrollPositionRates}/>
			<ProtectedRoute path="/clientareareports" exact component={ClientArea}/>
			<ProtectedRoute path="/manningreports" exact component={ManningEmployee201}/>

                        <ProtectedRoute path="/PayrollAdjustment" exact component={GeneratePayrollAdjustment}/>
                        <ProtectedRoute path="/uploaddtradjustment" exact component={UploadDTRAdjustment}/>
                        <ProtectedRoute path="/OtherPayroll" exact component={OtherPayroll}/>
						<ProtectedRoute path="/ProbiContract" exact component={ProbiContract}/>
                        <ProtectedRoute path="/GenerateOtherPayroll" exact component={GenerateOtherPayroll}/>
                            <ProtectedRoute path="/payrollpertriplocation" exact component={PayrollLocation}/>
                            <ProtectedRoute path="/payrollpertripproduct" exact component={PayrollProduct}/>
                            <ProtectedRoute path="/payrollpertrip" exact component={PayrollPerTrip}/>
                            <ProtectedRoute path="/BillablePerTrip" exact component={BillablePerTrip}/>
                            <ProtectedRoute path="/GeneratePayrollPerTrip" exact component={GeneratePayrollPerTrip}/>
                            <ProtectedRoute path="/GenerateCustomBilling" exact component={GenerateCustomBilling}/>
                            <ProtectedRoute path="/CustomBillingSummaryReport" exact component={CustomBillingSummaryReport}/>

                            <ProtectedRoute path="/GenerateOtherBilling" exact component={GenerateOtherBilling}/>
                            <ProtectedRoute path="/OtherBillingSummaryReport" exact component={OtherBillingSummaryReport}/>
                            <ProtectedRoute path="/EventInvoice" exact component={EventInvoice}/>
                            <ProtectedRoute path="/CustomBillingTransaction" exact component={CustomBillingTransaction}/>
                            
			<ProtectedRoute path="/clearance" exact component={Clearance}/>
			<ProtectedRoute path="/searchscreenclearance" exact component={SearchScreen}/>
			<ProtectedRoute path="/editclearance" exact component={ClearanceEdit}/>
                        <ProtectedRoute path="/batchclearance" exact component={BatchClearance}/>
			<ProtectedRoute path="/employeeclearance" exact component={EmployeeClearance}/>
			<ProtectedRoute path="/disengagementmode" exact component={DisengagementMode}/>
                        <ProtectedRoute path="/batchclearanceare" exact component={BatchClearanceAre}/>
                        <ProtectedRoute path="/batchclearancepayroll" exact component={BatchClearancePayroll}/>                        
			<ProtectedRoute path="/clearanceareupload" exact component={ClearanceAreUpload}/>
                        <ProtectedRoute path="/clearancepayrollsummary" exact component={ClearancePayrollSummary}/>
			<ProtectedRoute path="/clearancefinance" exact component={ClearanceFinance}/>
			<ProtectedRoute path="/clearancefinanceare" exact component={ClearanceFinanceAre}/>
                        <ProtectedRoute path="/clearancepayrollsummary" exact component={ClearancePayrollSummary}/>                    
			<ProtectedRoute path="/demandletterclearance" exact component={DemandLetterClearance}/>
                        <ProtectedRoute path="/createchildclearance" exact component={CreateChildClearance}/>
						<ProtectedRoute path="/ClearanceLastPayroll" exact component={ClearanceLastPayroll}/>
                        <Route path="*" component={Unathorized}/>
                    </Switch>
                </BrowserRouter>
	
            </div>
	</ErrorHandler>
        );
    }
}

class ErrorHandler extends React.Component {
    constructor(props) {
      super(props);
      this.state = { error: null, errorInfo: null };
    }
    
    componentDidCatch(error,errorInfo) {
      // Catch errors in any components below and re-render with error message
      console.log("dsdsd")
      console.log(error.stack)
      this.setState({
        error: error,
        errorInfo: errorInfo
      })
      // You can also log error messages to an error reporting service here
    }
    
    render() {
      if (this.state.errorInfo) {
        // Error path
        return (
        <div className="noser-error-handler">
        <h1>Oops! An error occured while processing your request</h1>
        {/* <p>An error occured while processing your request.</p> */}
        <hr/>
        <p style={{color:'red'}}>Error Message : {this.state.error.message}</p>
        <details style={{ whiteSpace: 'pre-wrap',paddingTop:'5px'}}>
            <summary>View Details</summary>
            {this.state.error.stack}
        </details>
        <div className="mt-5">
        <ButtonToolbar>
            <Button href="/home" className="noser-button" variant="success">Home Page</Button>
        </ButtonToolbar>
        </div>
        </div>
        );
      }
      // Normally, just render children
      return this.props.children;
    }  
  }

export default App;
