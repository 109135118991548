import {
    React,Component,BootstrapTable,
    paginationFactory,Button, ButtonToolbar, Card, 
    Form, Col, Row, axios, Container, Banner, Typeahead, TimePicker,
    cellEditFactory, Type, moment, NavLink, Alert, AppConfiguration,
    NoserLoading, Noser, sizePerPageRenderer, DatePicker
} 
from '../../noser-hris-component';

import { Input  } from 'reactstrap';
import { DropdownButton, Dropdown} from 'react-bootstrap';

 
class CodeOfConductCreateSection extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fade            :   true, 
            color           :   "",
            isshow          :   false,
            message         :   "",
            userinfo        :   [],
            isloading       :   false,
            alerttype       :   "",

            batchAutocomplete                   :   [],
            clientAutocomplete                  :   [],
            positionAutocomplete                :   [],
            trainingAutocomplete                :   [],
            trainingRegisterTableList           :   [],
            batchNoAutocomplete                 :   [],
            dateFrom                            :   "",
            dateTo                              :   "",
            modalTrainingRegisterShow           :   false,

            selectedClientName                  :"",
            getClientList                       :[],
            clientName                          :"",
            articleList                         :[],
            selectedArticleNo                   :'',
            selectedArticleName                 :'',
            selectedArticleId                   :'',
            sectionNo                           :"",
            infraction                          :"",
            category                            :"",
            cocID                               :"",
            isModified                          :"",

        }
    }

    componentDidMount(){
        const sleep = (milliseconds) => {return new Promise(resolve => setTimeout(resolve, milliseconds))}
        this.state.userinfo = JSON.parse(sessionStorage.getItem("userData"))
        this.getClientList();;
        let cocEditTmp = JSON.parse(sessionStorage.getItem("cocEdit"))

        if (cocEditTmp == "1" || cocEditTmp === "1"){
            let itemCOCTmp = JSON.parse(sessionStorage.getItem("itemCOC"))

            this.setState({
                selectedArticleName:itemCOCTmp.articleName,
                selectedArticleNo:itemCOCTmp.articleNo,
                category:itemCOCTmp.category,
                selectedClientName:itemCOCTmp.clientName,
                infraction:itemCOCTmp.infraction,
                isModified:itemCOCTmp.isModified,
                sectionNo:itemCOCTmp.sectionNo,
               
            })
            console.log(cocEditTmp)
        }else{
            
        }
        
        
    }
    GetDepartment() {
        const departmentCodeParams = {
            "IpAddress"     :   "0.0.0.0",
            "ClientId"      :   this.state.userinfo.clientId,
            "UserId"        :   this.state.userinfo.userId,
            "Code"          :   "",
            "Name"          :   ""
        };

        axios.post(AppConfiguration.Setting().noserapiendpoint + "Maintenance/GetDepartments",  departmentCodeParams)
        .then(res => {
            const data = res.data;
            console.log("Get Departments Code");
            console.log(data);
            console.log("Get Departments Code");
            this.setState({
                departmentCodeAutocomplete  :   data.departments
            });
        })

        console.log(this.state.departmentCodeAutocomplete)
    }

    getClientList(){
        this.setState({isloading:true})
        const getParams = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.userinfo.clientId,
            "UserId":this.state.userinfo.userId,
        }
        axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Client/GetClientList", getParams)
        .then(res => {
            console.log("Client List start ");
            console.log(res.data);
            console.log("Client List end");
            const data = res.data
            this.setState({
                isloading:false,
                getClientList : res.data.clients ? res.data.clients : []
            });
        })
        .catch(error=>{
           this.setState({
               isloading       :   false,
               alerttype       :   "Error!",
               isshow          :   true,
               color           :   "danger",
               message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
               fade            :   true
            })
        })
    }
    onChangeClientList = (e) => {
        if(e.length == 0) {
            this.state.selectedClientName=""
            this.state.selectedClientId=""
            return
        } 
        this.state.selectedClientId = e[0].id
        this.state.clientName = e[0].name
        this.setState({
            isloading:false,
            isshow:false,
            color:"",
            message:"",
            fade:true ,
            clientName: e[0].name
        })
       
        this.getCOCArticle();;
    }
    getCOCArticle =()=>{
       
        let filter_data ={ 'clientName' : this.state.clientName }
        const getParams ={
            "_collection" : "TestCOCArticle",
            "filter_data" : filter_data
        }
            console.log("axios start")
            axios
            .post("http://134.209.99.190:8088/action/get" , getParams)
            .then(res =>{
                let data = res.data
              
                const itemUsersDataLists = this.buildList(data["TestCOCArticle"])
                
                this.state.itemUserDataList  =  itemUsersDataLists
                
                let List =[]
                for (let i = 0; i < this.state.itemUserDataList.length; i++) {
                    let obj = {
                        'id'                    :   this.state.itemUserDataList[i]['id'],
                        'clientName'            :   this.state.itemUserDataList[i]['clientName'],
                        'articleNo'             :   this.state.itemUserDataList[i]['articleNo'],
                        'articleName'           :   this.state.itemUserDataList[i]['articleName'],
                        'isDraft'               :   this.state.itemUserDataList[i]['isDraft'],
                        'isDeleted'             :   this.state.itemUserDataList[i]['isDeleted'],
                        'createdby'             :   this.state.itemUserDataList[i]['createdby'],
                        'createddate'           :   this.state.itemUserDataList[i]['createddate'],
                        'modifiedby'            :   this.state.itemUserDataList[i]['modifiedby'],
                        'modifieddate'          :   this.state.itemUserDataList[i]['modifieddate'],
                        'isModified'            :   this.state.itemUserDataList[i]['isModified'],
                        
                    }
                    List.push(obj)
                    console.log("List articleName")
                    console.log(List)
                    console.log("List articleName")
                    
                    this.setState({
                        articleList:List

                    })
                   
                }
            })
            .catch(error=>{
                console.log("error: " + error)
            })
        } 
        
    buildList(data) {
        //console.log("data start")
        let itemList =[]
    
        let idList                      =[]
        let clientNameList              =[]
        let articleNoList               =[]
        let articleNameList             =[]
        let isDraftList                 =[]
        let isDeletedList               =[]
        let createdbyList               =[]
        let createddateList             =[]
        let modifiedbyList              =[]
        let modifieddateList            =[]
        let isModifiedList              =[]
    
        for (let i = 0; i < data.length; i++) {
    
            let s1 = data[i].split("':")

            let idClean                     = s1[1].replace("ObjectId(" ,"").replace(")","").replace("{","")
            let IdClean2                    = idClean.split("',")[0]
            let clientNameTmp               = s1[2].split("',")[0]
            let articleNoTmp                = s1[3].split("',")[0]
            let articleNameTmp              = s1[4].split("',")[0]
            let isDraftListTmp              = s1[5].split("',")[0]
            let isDeletedListTmp            = s1[6].split("',")[0]
            let createdbyListTmp            = s1[7].split("',")[0]
            let createddateListTmp          = s1[8].split("',")[0]
            let modifiedbyListTmp           = s1[9].split("',")[0]
            let modifieddateListTmp         = s1[10].split("',")[0]
            let isModifiedListTmp           = s1[11].split("',")[0]
        
            idList.push(IdClean2.replace(" '",""))
            clientNameList.push(clientNameTmp.replace(" '",""))
            articleNoList.push(articleNoTmp.replace(" '",""))
            articleNameList.push(articleNameTmp.replace(" '",""))
            isDraftList.push(isDraftListTmp.replace(" '",""))
            isDeletedList.push(isDeletedListTmp.replace(" '",""))
            createdbyList.push(createdbyListTmp.replace(" '",""))
            createddateList.push(createddateListTmp.replace(" '",""))
            modifiedbyList.push(modifiedbyListTmp.replace(" '",""))
            modifieddateList.push(modifieddateListTmp.replace(" '",""))
            isModifiedList.push(isModifiedListTmp.replace(" '","").replace("'}",""))
        }
        
        for (let i = 0; i < idList.length; i++) {
    
            let obj ={
                'id'                    :   idList[i],
                'clientName'            :   clientNameList[i],
                'articleNo'             :   articleNoList[i],
                'articleName'           :   articleNameList[i],
                'isDraft'               :   isDraftList[i],
                'isDeleted'             :   isDeletedList[i],
                'createdby'             :   createdbyList[i],
                'createddate'           :   createddateList[i],
                'modifiedby'            :   modifiedbyList[i],
                'modifieddate'          :   modifieddateList[i],
                'isModified'            :   isModifiedList[i],
            
            }
            itemList.push(obj)
        }          
        return itemList
      
    }
    
    onChangeArticle = (e) => {
        if(e.length==0)
        {
            this.setState({
                selectedArticleName     :   '',
                selectedArticleId       :   '',
                selectedArticleNo       :   '',
            })
            return
        }
        this.state.selectedArticleNo = e[0].articleNo
        this.state.selectedArticleName = e[0].articleName
        this.state.selectedArticleId = e[0].id        
               
        this.setState({
            isshow              : false,
            selectedArticleName : e[0].articleName,
            selectedArticleNo   : e[0].articleNo,
            selectedArticleId   : e[0].id,
            isloading           : true,

        })
        console.log(this.state.selectedArticleName)
        console.log(this.state.selectedArticleId)
        this.loadScreenFalse();
       
    }
    loadScreenFalse=()=>{
        this.setState({
            isloading:false,
        })
    }
    onChangeSectionNo=(e)=>{
        this.setState({
            sectionNo:e.target.value
        })
        console.log(this.state.sectionNo)
    }
    onChangeInfraction=(e)=>{
        this.setState({
            infraction:e.target.value
        })
        console.log(this.state.infraction)
    }
    onChangeCategory=(e)=>{
        this.setState({
            category:e.target.value
        })
        console.log(this.state.category)
    }

    handleSave=(e)=>{
        
        let createddate = moment(this.state.createddate).format('MM/DD/YYYY');
        let data = {
          "clientName"          :   this.state.clientName,
          "articleId"           :   this.state.selectedArticleId,
          "articleNo"           :   this.state.selectedArticleNo,
          "articleName"         :   this.state.selectedArticleName,
          "sectionNo"           :   this.state.sectionNo,
          "infraction"          :   this.state.infraction,
          "category"            :   this.state.category,
          "isDraft"             :   "0",
          "isDeleted"           :   "0",
          "createdby"           :   "user007",
          "createddate"         :   createddate,
          "modifiedby"          :   'user007',
          "modifieddate"        :   '0',
          "isModified"          :   '0',    
        }
        console.log(data)
        
        const addParams = {
          "_collection" : "TestCOCSection",
          "doc_data"    : data
        }
          
        axios
        .post("http://134.209.99.190:8088/action/add", addParams)
        .then(res =>{
            const data = res.data
            this.setState({
                sectionNo       :"",
                infraction      :"",
                category        :"",
                isshow          : true,
                alerttype       :   "Successfully Save!",
                color           :   "success",
                message         :   "Successfully Save!",
            
            })
        })
        .catch(error =>{
            console.log("error: " + error)
            this.setState({
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade            :   true
            })
        })
    }

    render() {     
     
    return(
            <div>
                <Banner />
                    <Container className="mt-5" fluid>
                        <Card>
                            <Card.Header>Maintenance Administrative Cases (COC)</Card.Header>
                            <Card.Body>
                                <Form>
                                    <Alert isOpen={this.state.isshow} color={this.state.color} fade={this.state.fade} className={this.state.isshow ? 'display-block' : 'display-none'}>
                                        <div className={this.state.color}></div> 
                                        {this.state.message}
                                    </Alert>
                                    <Form.Group as={Row} controlId="formPlaintextEmail">
                                        <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                            Client
                                        </Form.Label>
                                        <Col sm="11">
                                            <Typeahead
                                                labelKey='name'
                                                id="basic-example"
                                                onChange={this.onChangeClientList}
                                                options={this.state.getClientList}
                                                placeholder="Select Client"
                                            />
                                            
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} controlId="formPlaintextEmail">
                                        <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                            Article
                                        </Form.Label>
                                        <Col sm="2">
                                            <Typeahead
                                                labelKey='articleNo'
                                                id="basic-example"
                                                onChange={this.onChangeArticle}
                                                options={this.state.articleList}
                                                placeholder="Select Article"
                                            />
                                        </Col>
                                        <Col sm="9">
                                            <Form.Control 
                                                type="text"
                                                name="selectedArticleName" 
                                                value={this.state.selectedArticleName}
                                                onChange={this.onChangeArticleName} 
                                                autoComplete="off"
                                                placeholder="Autofill"
                                            />
                                        </Col>
                                    </Form.Group>

                                    <Form.Group as={Row} controlId="formPlaintextEmail">
                                        <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                            Section
                                        </Form.Label>
                                        <Col sm="11">
                                            <Input
                                                onChange={this.onChangeSectionNo}
                                                value={this.state.sectionNo}
                                                className="form-control"
                                                placeholder="(Enter Section No.)"
                                            />
                                        </Col>
                                    </Form.Group> 

                                    <Form.Group as={Row} controlId="formPlaintextEmail">
                                        <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                            Infraction
                                        </Form.Label>
                                        <Col sm="11">
                                            <Input
                                                onChange={this.onChangeInfraction}
                                                value={this.state.infraction}
                                                className="form-control"
                                                placeholder="(Enter Infraction)"
                                            />
                                        </Col>
                                       
                                    </Form.Group> 
                                    <Form.Group as={Row} controlId="formPlaintextEmail">
                                        <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                            Category
                                        </Form.Label>                                       
                                        <Col sm="2">
                                            <Input
                                                onChange={this.onChangeCategory}
                                                value={this.state.category}
                                                className="form-control"
                                                placeholder="(Enter Category)"
                                            />
                                        </Col>
                                    </Form.Group> 
                                    
                                    <ButtonToolbar sm={12}>
                                        <Button variant="success" className="ml-auto" style={{minWidth:'60px'}} onClick={this.handleSave}>
                                           Save
                                        </Button>&nbsp;&nbsp;
                                        <NavLink to="/codeofconduct">
                                            <Button variant="danger" href="/codeofconduct"  style={{minWidth:'60px'}}>
                                                Close
                                            </Button>
                                        </NavLink>
                                    </ButtonToolbar>
                                </Form>
                            </Card.Body>
                        </Card>
                       
                    </Container>
                    <NoserLoading show={this.state.isloading} />

                    
            </div> 
        )
    }

}

export  default CodeOfConductCreateSection
