import {
    React,Component,BootstrapTable, Type,
    paginationFactory,Button, ButtonToolbar, Card,
    Form, Col, Row, Container, axios, Typeahead, Banner, TimePicker,
    cellEditFactory ,moment, NavLink, Alert, AppConfiguration,
    NoserLoading, Noser, sizePerPageRenderer, DatePicker
} 
from '../../noser-hris-component';
import type from 'react-bootstrap-table2-editor';

class ManagerProfileCreate extends Component {
    constructor(props) {
        super(props)
        this.state = {
            userinfo  :   [],
            isloading :   false,
            isshow    :   false,
            alerttype :   "",
            message   :   "",
            color     :   "",
            fade      :   true,

            employeeRecordsListGrid:[],
            employeeRecordsList:[],
            employeeAddressesList:[
                {
                    "id":"1",
                    "typeId":"",
                    "houseNumber":"",
                    "streetName":"",
                    "barangay":"",
                    "postalCode":"",
                    "regionId":"",
                    "region":"",
                    "provinceId":"",
                    "province":"",
                    "cityId":"",
                    "city":""
            }],
            familyList:[
                {
                    "id":"1",
                    "roleId":"",
                    "name":"",
                    "age":"",
                    "occupation":"",
                    "company":"",
                }
            ],
            educationList:[
                {
                    "id":"1",
                    "levelId":"",
                    "schoolName":"",
                    "course":"",
                    "startYear":"",
                    "endYear":"",
                    "honorRecieved":"",
                }
            ],
            typeId:"",
            houseNumber:"",
            streetName:"",
            barangay:"",
            postalCode:"",
            region:"",
            province:"",
            city:"",
            roleId:"",
            name:"",
            age:"",
            occupation:"",
            company:"",
            levelId:"",
            schoolName:"",
            course:"",
            startYear:"",
            endYear:"",
            honorRecieved:"",

            firstName:"",
            nickName:"",
            middleName:"",
            homePhoneNumber:"",
            lastName:"",
            mobileNumber:"",
            selectedReligion:"",
            selectedReligionId:"",
            religionList:[
                {"name":"Adventist","id":"1"},
                {"name":"Aglipay","id":"2"},
                {"name":"Alliance","id":"3"},
                {"name":"Apostolic Holiness","id":"4"},
                {"name":"Assembly Of God","id":"5"},
                {"name":"Baptist","id":"6"},
                {"name":"Bible Baptist","id":"7"},
                {"name":"Born Again","id":"8"},
                {"name":"Born Again Christian","id":"9"},
                {"name":"Catholic","id":"10"},
                {"name":"Christian","id":"11"},
                {"name":"Christian(Born Again)","id":"12"},
                {"name":"Church of God","id":"13"},
                {"name":"Evangilical","id":"14"},
                {"name":"Iemelif","id":"15"},
                {"name":"Iglesia Ni Christo","id":"16"},
                {"name":"Islam","id":"17"},
                {"name":"Jehova Witness","id":"18"},
                {"name":"Jehovah's Witness","id":"19"},
                {"name":"Jehova's Witness","id":"20"},
                {"name":"MCGI","id":"21"},
                {"name":"Methodist","id":"22"},
                {"name":"Mormon","id":"23"},
                {"name":"Mormons","id":"24"},
                {"name":"N/A","id":"25"},
                {"name":"Others","id":"26"},
                {"name":"Pentecostal","id":"27"},
                {"name":"PIC","id":"28"},
                {"name":"Pinicocs","id":"29"},
                {"name":"Protestant","id":"30"},
                {"name":"Roman Catholic","id":"31"},
                {"name":"SDA","id":"32"},
                {"name":"Seventh Day Adventist","id":"33"},
                {"name":"The Church Of Jesus Christ","id":"34"},
                {"name":"UCCP","id":"35"},
                {"name":"Union Espiritista","id":"36"},
                {"name":"UPC","id":"37"},
                {"name":"V.C.C.P","id":"38"}
            ],
            regionLst:[],
            provinceDropdownList:[],
            cityDropdownList:[],
            emailAddress:"",
            selectedCivilStatusId:"",
            selectedCivilStatus:"",
            emergencyContactName:"",
            emergencyContactRelationship:"",
            emergencyContactNumber:"",
            changes:"",
            civilStatusList:[
                {"name":"Single","id":"1"},
                {"name":"Married","id":"2"},
                {"name":"Divorced","id":"3"},
                {"name":"Seperated","id":"4"}

            ],
            type:"",
            typeId:"",
            typeList:[
                {"value":"0","label":"Current Address"},
                {"value":"1","label":"Registered Address"},
                {"value":"2","label":"Provincial Address"},
                {"value":"3","label":"Correspondence Address"},
                {"value":"4","label":"Foreign Address"}
            ],
            regionList:[],
            selectedRegion:"",
            selectedRegionId:"",
            provinceList:[],
            selectedProvince:"",
            selectedProvinceId:"",
            citylist:[],
            selectedCity:"",
            selectedCityId:"",
            house:"",
            street:"",
            barangay:"",
            postalcode:"",
            levelList:[
                {"value":"0","label":"College"},
                {"value":"1","label":"High School"},
                {"value":"2","label":"Elementary"},
                {"value":"3","label":"others"}
            ],
            level:"",
            levelId:"",
            schoolName:"",
            course:"",
            startYear:"",
            endYear:"",
            honorsAward:"",
            roleId:"",
            roleList:[
                {"value":"0","label":"Father"},
                {"value":"1","label":"Mother"},
                {"value":"2","label":"Spouse"},
                {"value":"3","label":"Brother"},
                {"value":"4","label":"Sister"},
                {"value":"5","label":"Child"}
            ],
            occupations:"",
            Name:"",
            Age:"",
            Company:"",
            employeeList:[],
            selectedEmployeeId : "",
            selectedProfileId :"",
            employeeName:"",
            employeeId:"",
            selectedReligionId:"",
            getClientList:[],
            selectedClientName:"",
            selectedClientId:"",
            selectedOptions:"",
            civilStatus:"",
            religion:"",
            religionId:""
        }
         
    }

    componentDidMount() {
        const sleep = (milliseconds) => {return new Promise(resolve => setTimeout(resolve, milliseconds))}
        this.state.userinfo = JSON.parse(sessionStorage.getItem("userData"))
        this.getClient();
        this.GetRegion();
        this.GetProvince();
        this.GetCity(); 
       
        sleep(1000).then(() => {
            this.setState({isLoading:false})}) 
    }

    onChangeFirstName(e){
        console.log(e)
        this.setState({firstName:e.target.value})
    }
   
    handleChangeClient= (e) => {
        if(e.length == 0) {
            this.state.selectedClientName=""
            this.state.selectedClientId=""
            return
        } 
        this.state.selectedClientId = e[0].id
        this.state.selectedClientName = e[0].name
        this.setState({isloading:true})
        
        this.GetEmployees();
    
    }
 
    GetEmployees() {
        this.setState({
            employeeList:[]
            
        })

        const getParams = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.selectedClientId,
            "UserId":"1",
            "ProfileId":"",
         };
        axios
        .post(
            AppConfiguration.Setting().noserapiendpoint + "Employee/GetEmployeeProfiles", getParams
        )
        .then(res => {
            const data = res.data;
            
            console.log(data);
            this.setState({employeeList : data.employeeProfiles, isloading:false}) 
            if(data.status=="1"){
                this.setState({
                    isloading       :   false,
                    alerttype       :   "Success!",
                    isshow          :   true,
                    color           :   "success",
                    message         :   data.message,
                    fade            :   true
                });
            }
            else{
                this.setState({
                    isloading       :   false,
                    alerttype       :   "Error!",
                    isshow          :   true,
                    color           :   "danger",
                    message         :   data.message,
                    fade            :   true
                })
            }
            })
            .catch(error=>{
            this.setState(  {
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade            :   true
            })
        })

    }
    
    handleChangeEmployee = (e) => {
        console.log(e)
            if (e.length == 0) {
                this.setState({employeeName:"", firstName: "", nickName:"", middleName:"",selectedProfileId:"",
                lastName:"", homePhoneNumber:"", mobileNumber:"",  selectedReligionId:"",civilStatus:"",
                emailAddress:"", selectedCivilStatus:"", emergencyContactName:"", emergencyContactRelationship:"",
                emergencyContactNumber:"", changes:"" ,selectedReligion:"", selectedOptions:"",religion:"",religionId:""})
            return
        }
        
        this.setState({
            employeeName:e[0].name,
            selectedProfileId:e[0].employeeId,
            nickName:e[0].nickName,
            firstName:e[0].firstName,
            middleName: e[0].middleName,
            lastName : e[0].lastName,
            homePhoneNumber : e[0].homePhoneNumber,
            mobileNumber : e[0].mobileNumber,
            emailAddress :e[0].emailAddress,
            emergencyContactName : e[0].emergencyContactName,
            emergencyContactRelationship: e[0].emergencyContactRelationship,
            emergencyContactNumber: e[0].emergencyContactNumber,
            //selectedOptions:[e[0].civilStatus],
            // selectedOptions:[e[0].religion],
            selectedReligion:[e[0].religion],
            selectedCivilStatus:[e[0].civilStatus],
            civilStatus:e[0].civilStatus,
            religion :e[0].name,
            religionId :e[0].id
           
            })
           
            

         this.setState({isloading:false,})
    } 

    onChangeNickName(e){
        console.log(e)
        this.setState({nickName:e.target.value})
    }

    onChangeMiddleName(e){
        console.log(e)
        this.setState({middleName:e.target.value})
    }

    onChangeLastName(e){
        console.log(e)
        this.setState({lastName:e.target.value})
    }

    onChangeHomePhoneNumber(e){
        console.log(e)
        this.setState({homePhoneNumber:e.target.value})
    }

    onChangeMobileNumber(e){
        console.log(e)
        this.setState({mobileNumber:e.target.value})
    }

    handleChangeReligion= (e) => {
        console.log(e)
        if (e.length > 0) {
            this.state.religion = e[0].name
            this.state.religionId = e[0].id
        } else {
            this.state.religion = ""
            this.state.religionId = ""
            console.log(this.state.religionId)
        }
        
    }

    onChangeEmailAddress(e){
        console.log(e)
        this.setState({emailAddress:e.target.value})
    }

    handleChangeCivilStatus= (e) => {
        console.log(e)
        console.log(this.state.civilStatus)
        if (e.length > 0) {
                this.state.civilStatus = e[0].civilStatus
                this.state.civilStatusId = e[0].id
            }else{
                this.state.civilStatus =  ""
                this.state.civilStatusId = ""
            }
        }
    
    onChangeEmergencyContactName(e){
        console.log(e)
        this.setState({emergencyContactName:e.target.value})
    }

    onChangeEmergencyContactRelationship(e){
        console.log(e)
        this.setState({emergencyContactRelationship:e.target.value})
    }

    onChangeEmergencyContactNumber(e){
        console.log(e)
        this.setState({emergencyContactNumber:e.target.value})
    }

    onChangeChanges(e){
        console.log(e)
        this.setState({changes:e.target.value})
    }

    handleChangeType= (e) => {
        //console.log(e)
        if(e.length > 0) {
            this.state.type = e[0].name
            this.state.typeId = e[0].id
        }else{
            this.state.type = ""
            this.state.typeId = ""
        }
    }

    GetRegion() {
        const regionParams = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.userinfo.clientId,
            "UserId":this.state.userinfo.userId,
            "Name":this.state.selectedRegion
        };

        axios.post(AppConfiguration.Setting().noserapiendpoint + "Maintenance/GetRegions",  regionParams)
        .then(res => {
             const data = res.data;
             this.setState({ regionList: data.regions });
             for (let i = 0; i < data.regions.length; i++) {
                const obj = {
                    value : data.regions[i]["id"],
                    label : data.regions[i]["name"],
                };
                this.state.regionLst.push(obj);
            }
        })
        
    }

    GetProvince() {
        const provinceParams = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.userinfo.clientId,
            "UserId":this.state.userinfo.userId,
            "Region": this.state.selectedRegion
        };

         axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Maintenance/GetProvinces",  provinceParams)
        .then(res => {
            const data = res.data;
            this.setState({ provinceList: data.provinces });
            for (let i = 0; i < data.provinces.length; i++) {
                const obj = {
                    value : data.provinces[i]["id"],
                    label : data.provinces[i]["name"],
                };
                this.state.provinceDropdownList.push(obj);
            }
        })
        
    }

    handleCoverChangeProvince = (e) => {
        if (e.length > 0) {
            this.state.selectedProvince = e[0].name
            this.state.selectedProvinceId = e[0].id
        } else {
            this.state.selectedProvince = ""
            this.state.selectedProvinceId = ""
        }
    }

    GetCity() {
        const cityParams = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.userinfo.clientId,
            "UserId":this.state.userinfo.userId,
            "Name":this.state.selectedCity,
            "ProvinceName": this.state.selectedProvince
         };
 
         axios
             .post(
                 AppConfiguration.Setting().noserapiendpoint + "Maintenance/GetCities",  cityParams
             )
             .then(res => {
                 const data = res.data;
                 this.setState({ citylist: data.cities });
                 for (let i = 0; i < data.cities.length; i++) {
                    const obj = {
                        value : data.cities[i]["id"],
                        label : data.cities[i]["name"],
                    };
                    this.state.cityDropdownList.push(obj);
                }
            })
            
        }

    handleCoverChangeCity = (e) => {
        if (e.length > 0) {
            this.state.selectedCity = e[0].name
            this.state.selectedCityId=e[0].id
        } else {
            this.state.selectedCity = ""
            this.state.selectedCityId = ""
        }
        console.log(this.state.selectedCity);
        
    }

    onChangeHouse(e){
        console.log(e)
        this.setState({house:e.target.value})
    }

    onChangeStreet(e){
        console.log(e)
        this.setState({street:e.target.value})
    }

    onChangeBarangay(e){
        console.log(e)
        this.setState({barangay:e.target.value})
    }

    onChangePostalCode(e){
        console.log(e)
        this.setState({postalcode:e.target.value})
    }

    handleChangeLevel= (e) => {
        //console.log(e)
        if(e.length > 0) {
            this.state.level = e[0].name
            this.state.levelId = e[0].id
        }else{
            this.state.level = ""
            this.state.levelId = ""
        }
    }

    onChangeSchoolName(e){
        console.log(e)
        this.setState({schoolName:e.target.value})
    }

    onChangeCourse(e){
        console.log(e)
        this.setState({course:e.target.value})
    }

    onChangeStartYear(e){
        console.log(e)
        this.setState({startYear:e.target.value})
    }

    onChangeEndYear(e){
        console.log(e)
        this.setState({endYear:e.target.value})
    }

    onChangeHonorsAward(e){
        console.log(e)
        this.setState({honorsAward:e.target.value})
    }

    handleChangeRole= (e) => {
        //console.log(e)
        if(e.length > 0) {
            this.state.role = e[0].name
            this.state.roleId = e[0].id
        }else{
            this.state.role = ""
            this.state.roleId = ""
        }
    }

    onChangeCompany(e){
        console.log(e)
        this.setState({Company:e.target.value})
    }

    onChangeOccupations(e){
        console.log(e)
        this.setState({occupations:e.target.value})
    }

    onChangeAges(e){
        console.log(e)
        this.setState({Age:e.target.value})
    }

    onChangeName(e){
        console.log(e)
        this.setState({Name:e.target.value})
    }

    getClient(){
        this.setState({isloading:true,})

        const getParams = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.userinfo.clientId,
            "UserId":this.state.userinfo.userId,
        }

        axios
            .post(AppConfiguration.Setting().noserapiendpoint + "Client/GetClientList", getParams)
            .then(res => {
                console.log("Client List ");
                console.log(res.data);
                this.setState({
                    isloading:false,
                    getClientList : res.data.clients
                })
            })
    }

    
    handleSaveClick = event => {

        console.log(this.state.employeeAddressesList)
        this.state.employeeAddressesList.map(function(item,idx){
           
            let row = parseInt(idx) + 1
            if(item.typeId!=""){
                if(item.houseNumber=="" || item.streetName=="" 
                || item.barangay=="" || item.cityId=="" || item.regionId=="" || item.provinceId==""){
                    alert("Please complete all fileds in Employee Address in row number :" + row)
                    return
                }
            }

        })
        console.log(this.state.educationList)
        this.state.educationList.map(function(item,idx){
            let row = parseInt(idx) + 1
            if(item.levelId!=""){
                if(item.schoolName=="" || item.course=="" || item.startYear=="" 
                || item.endYear=="" || item.honorRecieved==""){
                   alert("Please complete all fileds in Employee Educations in row number :" + row)
                    return
                }
            }
        })
        console.log(this.state.familyList)
        this.state.familyList.map(function(item,idx){
            let row = parseInt(idx) + 1
            if(item.roleId!=""){
                if(item.name=="" || item.age=="" || item.occupation=="" || item.company==""){
                    alert("Please complete all fileds in Employee Family Backgrounds in row number :" + row)
                    return

                }
            }
        })
       
        const addParams = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.userinfo.clientId,
            "UserId":this.state.userinfo.userId,
            "RoleId":this.state.userinfo.roleId,
            "ProfileId":this.state.selectedProfileId,
            "LastName":this.state.lastName,
            "FirstName":this.state.firstName,
            "MiddleName":this.state.middleName,
            "NickName":this.state.nickName,
            "HomePhoneNumber":this.state.homePhoneNumber,
            "MobileNumber":this.state.mobileNumber,
            "ReligionId": this.state.religionId,
            "CivilStatus":this.state.civilStatus,
            "EmailAddress":this.state.emailAddress,
            "EmergencyContactName":this.state.emergencyContactName,
            "EmergencyContactRelationship":this.state.emergencyContactRelationship,
            "EmergencyContactNumber":this.state.emergencyContactNumber,
            "Changes":this.state.changes,
            "employeeAddresses":this.state.employeeAddressesList.filter(f=>f.typeId!=""),
            "employeeEducations":this.state.educationList.filter(f=>f.levelId!=""),
            "employeeFamilyBackgrounds":this.state.familyList.filter(f=>f.roleId!=""),
        }
        console.log(addParams)
        axios
            .post(
                AppConfiguration.Setting().noserapiendpoint + "Administrator/SubmitEmployeeProfileForApproval",  addParams
            )
            .then(res => {
                const data = res.data;
                console.log("Get data");
                console.log(data)
                if(data.status=="1")
                {
                    this.setState(
                        { 
                            show:true,
                            Color:"success",
                            Message:data.message ,
                            Fade:true
                        });
                    
                    }
                else
                {
                    this.setState(
                        { 
                            show:true,
                            Color:"danger",
                            Message:data.message,
                            Fade:true
                        });
                }
            })
        
    }
    onSubmitAddAddresses = () => {
        this.setState({isloading:true,isDisable:true})

        let employeeAddresses = this.state.employeeAddressesList
        const ids = employeeAddresses.map(user => user.id);
        const sorted = ids.sort((a, b) => a - b);
        let maxId = parseInt(sorted[sorted.length-1]) + 1;
        console.log(maxId)
        employeeAddresses.push({
            "id":maxId.toString(),
            "typeId":"",
			"houseNumber":"",
			"streetName":"",
			"barangay":"",
			"postalCode":"",
			"regionId":"",
			"region":"",
			"provinceId":"",
			"province":"",
			"cityId":"",
			"city":""
        })
        this.setState({employeeAddressesList:employeeAddresses})
        console.log(this.state.employeeAddressesList)
    } 

    onSubmitAddFamilyBackground = () => {
        this.setState({isloading:true,isDeleted:true})

        let employeeFamilyBackgrounds = this.state.familyList
        const ids = employeeFamilyBackgrounds.map(user => user.id);
        const sorted = ids.sort((a, b) => a - b);
        let maxId = parseInt(sorted[sorted.length-1]) + 1;
        console.log(maxId)
        employeeFamilyBackgrounds.push({
            "id":maxId.toString(),
            "roleId":"",
            "name":"",
            "age":"",
            "occupation":"",
            "company":"",
        })
          this.setState({familyList: employeeFamilyBackgrounds})
        console.log(this.state.familyList)
    }
    
    onSubmitAddEmployeeEducation = () => {
        this.setState({isloading:true,isDeleted:true})

        let employeeEducations = this.state.educationList
        const ids = employeeEducations.map(user => user.id);
        const sorted =ids.sort((a, b) => a - b);
        let maxId = parseInt(sorted[sorted.length - 1]) + 1;
        console.log(maxId)
        employeeEducations.push({
            "id":maxId.toString(),
            "levelId":"",
            "schoolName":"",
            "course":"",
            "startYear":"",
            "endYear":"",
            "honorRecieved":"",
        })
        this.setState({educationList: employeeEducations})
        console.log(this.state.educationList)
    }

    render() {
        const addresses = [
            {
                dataField: 'typeId',
                text: 'TYPE',
                editor: {
                    type: Type.SELECT,
                    options:this.state.typeList
                },
                formatter: (cell, row) => {
                    if(row.typeId!='' && row.typeId!=null){
                        return this.state.typeList.find(x => x.value == cell).label;
                    }
                    //alert(cell + " " + row.typeId)
                },
                validator:(newValue, row, column)=>{
                    if(newValue!=""){
                        let exist = this.state.employeeAddressesList.filter(x=>x.typeId==newValue)
                        if(exist.length>0){
                                row.typeId=""
                                return {valid : false, message : "Invalid Address Type",newValue:""}
                        }
                    }
                    return true
                },
                headerStyle: (colum, colIndex) => {
                    return { width: '15%'};
                }
            },
            {
                dataField: 'houseNumber',
                text: 'HOUSE #',
                headerStyle: (colum, colIndex) => {
                    return { width: '10%'};
                }
            },
            {
                dataField: 'streetName',
                text: 'STREET NAME',
                headerStyle: (colum, colIndex) => {
                    return { width: '15%'};
                }
            },
            {
                dataField: 'barangay',
                text: 'BARANGAY',
                headerStyle: (colum, colIndex) => {
                    return { width: '15%'};
                }
            },
            {
                dataField: 'cityId',
                text: 'CITY',
                editor: {
                    type: Type.SELECT,
                    options:this.state.cityDropdownList
                }, 
                formatter: (cell, row) => {
                    if(row.cityId!='' && row.cityId!=null){
                        return this.state.cityDropdownList.find(x => x.value == cell).label;
                    }
                },
                headerStyle: (colum, colIndex) => {
                    return { width: '20%'};
                }
            },
            {
                dataField: 'regionId',
                text: 'REGION',
                editor: {
                    type: Type.SELECT,
                    options:this.state.regionLst
                }, 
                formatter: (cell, row) => {
                    if(row.regionId!='' && row.regionId!=null){
                        return this.state.regionLst.find(x => x.value == cell).label;
                    }
                },
                
                headerStyle: (colum, colIndex) => {
                    return { width: '15%'};
                }
            },
            {
                dataField: 'provinceId',
                text: 'PROVINCE',
                editor: {
                    type: Type.SELECT,
                    options:this.state.provinceDropdownList
                }, 
                formatter: (cell, row) => {
                    if(row.provinceId!='' && row.provinceId!=null){
                        return this.state.provinceDropdownList.find(x => x.value == cell).label;
                    }
                },
                headerStyle: (colum, colIndex) => {
                    return { width: '15%'};
                }
            },
            {
                dataField: 'postalCode',
                text: 'POSTAL CODE',
                headerStyle: (colum, colIndex) => {
                    return { width: '15%'};
            }
        }]

        const selectRowEmployeeAddresses = {
            mode: 'checkbox',
            clickToSelectAndEditCell: true,
            onSelect: (row, isSelect, rowIndex, e) => {
                let restLst = this.state.employeeAddressesList
                this.state.employeeAddressesList.map(function(item,idx){
                    
                    if(idx==rowIndex)
                    {
                        item.isDeleted = isSelect ? "1" : "0"
                        item.isModified = isSelect ? "1" : "0"
                    }
                })
            }
        };

        const FamilyBackground = [
            {
                dataField: 'roleId',
                text: 'ROLE ID',
                editor: {
                    type: Type.SELECT,
                    options:this.state.roleList
                }, 
                formatter: (cell, row) => {
                    if(row.roleId!='' && row.roleId!=null){
                        return this.state.roleList.find(x => x.value == cell).label;
                    }
                    //alert(cell + " " + row.typeId)
                },
                validator:(newValue, row, column)=>{
                    if(newValue!=""){
                        let exist = this.state.familyList.filter(x=>x.roleId==newValue)
                        if(exist.length>0){
                                row.roleId=""
                                return {valid : false, message : "Invalid Role Type",newValue:""}
                        }
                    }
                    return true
                },
                headerStyle: (colum, colIndex) => {
                    return { width: '15%'};
                }
            },
            {
                dataField: 'name',
                text: 'NAME',
                headerStyle: (colum, colIndex) => {
                    return { width: '40%'};
                }
            },
            {
                dataField: 'age',
                text: 'AGE',
                headerStyle: (colum, colIndex) => {
                    return { width: '10%'};
                }
            },
            {
                dataField: 'occupation',
                text: 'OCCUPATION',
                headerStyle: (colum, colIndex) => {
                    return { width: '40%'};
                }
            },
            {
            dataField: 'company',
            text: 'COMPANY',
            headerStyle: (colum, colIndex) => {
                return { width: '40%'};
            }
        }] 
        
        const selectRowFamilyBackground = {
            mode: 'checkbox',
            clickToSelectAndEditCell: true,
            onSelect: (row, isSelect, rowIndex, e) => {

                let BackgroundLst = this.state.familyList
                this.state.familyList.map(function(item,idx){
                    
                    if(idx==rowIndex)
                    {
                        item.isDeleted = isSelect ? "1" : "0"
                        item.isModified = isSelect ? "1" : "0"
                    }
                })
              }
        };

        const education = [
            {
                dataField: 'levelId',
                text: 'LEVEL ID',
                editor: {
                    type: Type.SELECT,
                    options:this.state.levelList
                  }, 
                  formatter: (cell, row) => {
                    if(row.levelId!='' && row.levelId!=null){
                        return this.state.levelList.find(x => x.value == cell).label;
                    }
                },
                validator:(newValue, row, column)=>{
                    if(newValue!=""){
                        let exist = this.state.educationList.filter(x=>x.levelId==newValue)
                        if(exist.length>0){
                                row.levelId=""
                                return {valid : false, message : "Invalid Level Type",newValue:""}
                        }
                    }
                    return true
                },
                headerStyle: (colum, colIndex) => {
                    return { width: '15%'};
                }
            },
            {
                dataField: 'schoolName',
                text: 'SCHOOL NAME',
                headerStyle: (colum, colIndex) => {
                    return { width: '40%'};
                }
            },
            {
                dataField: 'course',
                text: 'COURSE',
                headerStyle: (colum, colIndex) => {
                    return { width: '35%'};
                }
            },
            {
                dataField: 'startYear',
                text: 'START YEAR',
                headerStyle: (colum, colIndex) => {
                    return { width: '35%'};
                }
            },
            {
                dataField: 'endYear',
                text: 'END YEAR',
                headerStyle: (colum, colIndex) => {
                    return { width: '35%'};
                }
            },
            {
                dataField: 'honorRecieved',
                text: 'HONOR RECIEVED',
                headerStyle: (colum, colIndex) => {
                    return { width: '35%'};
                }
            }] 
        
        const selectRow = {
            mode: 'checkbox',
            clickToSelectAndEditCell: true,
            onSelect: (row, isSelect, rowIndex, e) => {

                let EducationLst = this.state.educationList
                this.state.educationList.map(function(item,idx){
                    
                    if(idx==rowIndex)
                    {
                        item.isDeleted = isSelect ? "1" : "0"
                        item.isModified = isSelect ? "1" : "0"
                    }
                })
              }
        };
        
        return(
            <div>
                <Banner />
                    <Container className="mt-5">
                        <Card>
                            <Card.Header>CREATE EMPLOYEE</Card.Header>
                            <Card.Body>
                                <Form>
                                <Alert color="success" isOpen={this.state.show} color={this.state.Color} fade={this.state.Fade}>
                                        {this.state.Message}
                                    </Alert>
                                <Card.Header>I. GENERAL INFORMATION</Card.Header>
                                    <Form.Row>
                                        <Col sm={6} className="mt-3">
                                            <Typeahead
                                                labelKey='name'
                                                id="basic-example"
                                                onChange={this.handleChangeClient}
                                                options={this.state.getClientList}
                                                placeholder="CLIENT"
                                            />
                                        </Col> 
                                        <Col sm={6} className="mt-3">
                                            <Typeahead
                                                labelKey='employeeName'
                                                id="basic-example"
                                                onChange={this.handleChangeEmployee}
                                                options={this.state.employeeList}
                                                placeholder="EMPLOYEE"
                                            />
                                        </Col> 
                                    </Form.Row>
                                   <Form.Row>
                                        <Col sm={6} className="mt-3">
                                            <Form.Control 
                                                type="text"
                                                name="name"
                                                value={this.state.firstName}
                                                placeholder="FIRST NAME"
                                                onChange={this.onChangeFirstName.bind(this)} 
                                                autoComplete="off"
                                            /> 
                                        </Col>
                                        <Col sm={6} className="mt-3">
                                            <Form.Control 
                                                type="text"
                                                name="middleName"
                                                value={this.state.middleName}
                                                placeholder="MIDDLE NAME"
                                                onChange={this.onChangeMiddleName.bind(this)} 
                                                autoComplete="off"
                                            />
                                        </Col>
                                        <Col sm={6} className="mt-3">
                                            <Form.Control 
                                                type="text"
                                                name="name"
                                                value={this.state.nickName}
                                                placeholder="NICK NAME"
                                                onChange={this.onChangeNickName.bind(this)} 
                                                autoComplete="off"
                                            />
                                        </Col>
                                        <Col sm={6} className="mt-3">
                                            <Form.Control 
                                                type="text"
                                                name="name"
                                                value={this.state.lastName}
                                                placeholder="LAST NAME"
                                                onChange={this.onChangeLastName.bind(this)} 
                                                autoComplete="off"
                                            />
                                        </Col>
                                    </Form.Row> 
                                    <Form.Row>
                                        <Col sm={6} className="mt-3">
                                            <Form.Control 
                                                type="text"
                                                name="name"
                                                value={this.state.homePhoneNumber}
                                                placeholder="HOME PHONE NUMBER"
                                                onChange={this.onChangeHomePhoneNumber.bind(this)} 
                                                autoComplete="off"
                                            />
                                        </Col>
                                        <Col sm={6} className="mt-3">
                                            <Form.Control 
                                                type="text"
                                                name="name"
                                                value={this.state.mobileNumber}
                                                placeholder="MOBILE NUMBER"
                                                onChange={this.onChangeMobileNumber.bind(this)} 
                                                autoComplete="off"
                                            />
                                        </Col>
                                        <Col sm={6} className="mt-3">
                                            <Typeahead
                                                labelKey='name'
                                                id='basic-example'
                                                onChange={this.handleChangeReligion}
                                                options={this.state.religionList}
                                                placeholder="RELIGION"
                                                selected={this.state.selectedReligion || ""}
                                            />
                                        </Col>
                                        <Col sm={6} className="mt-3">
                                            <Form.Control 
                                                type="text"
                                                name="name"
                                                value={this.state.emailAddress}
                                                placeholder="EMAIL ADDRESS"
                                                onChange={this.onChangeEmailAddress.bind(this)} 
                                                autoComplete="off"
                                            />
                                        </Col>
                                        <Col sm={6} className="mt-3">
                                            <Typeahead
                                                labelKey='name'
                                                id='basic-example'
                                                onChange={this.handleChangeCivilStatus}
                                                options={this.state.civilStatusList}
                                                placeholder="CIVIL STATUS"
                                                selected={this.state.selectedCivilStatus || ""}
                                            />
                                        </Col>
                                    </Form.Row>
                                    <Form.Row>
                                        <Col sm={6} className="mt-3">
                                            <Form.Control 
                                                type="text"
                                                name="name"
                                                value={this.state.emergencyContactName}
                                                placeholder="EMERGENCY CONTACT NAME"
                                                onChange={this.onChangeEmergencyContactName.bind(this)} 
                                                autoComplete="off"
                                            />
                                        </Col>
                                        <Col sm={6} className="mt-3">
                                            <Form.Control 
                                                type="text"
                                                name="name"
                                                value={this.state.emergencyContactRelationship}
                                                placeholder="EMERGENCY CONTACT RELATIONSHIP"
                                                onChange={this.onChangeEmergencyContactRelationship.bind(this)} 
                                                autoComplete="off"
                                            />
                                        </Col>
                                        <Col sm={6} className="mt-3">
                                            <Form.Control 
                                                type="text"
                                                name="name"
                                                value={this.state.emergencyContactNumber}
                                                placeholder="EMERGENCY CONTACT NUMBER"
                                                onChange={this.onChangeEmergencyContactNumber.bind(this)} 
                                                autoComplete="off"
                                            />
                                        </Col>
                                        <Col sm={6} className="mt-3">
                                            <Form.Control 
                                                type="text"
                                                name="name"
                                                value={this.state.changes}
                                                placeholder="CHANGES"
                                                onChange={this.onChangeChanges.bind(this)} 
                                                autoComplete="off"
                                            />
                                        </Col>
                                    </Form.Row> &nbsp;&nbsp;&nbsp;&nbsp;
                                    <Card.Header >ADDRESS</Card.Header>
                                    <Form.Group as={Col} className="mt-3" controlId="formGridPassword">
                                        <Button style={{minWidth:'60px'}}  variant="success" onClick={this.onSubmitAddAddresses}>Add Row</Button>
                                        </Form.Group>
                                            <Form.Group as={Row} controlId="formHorizontalEmail">
                                        <Col sm={12}>
                                            <BootstrapTable
                                                keyField = "id"
                                                data = { this.state.employeeAddressesList}
                                                columns = { addresses}
                                                selectRow = { selectRowEmployeeAddresses }
                                                cellEdit = { cellEditFactory({ mode: 'dbclick', blurToSave: true }) }
                                                rowClasses="noser-table-row-class"
                                                striped
                                                hover
                                                condensed
                                            />
                                            
                                        </Col>
                                    </Form.Group>
                                    <Card.Header>II. FAMILY BACKGROUND</Card.Header>
                                    <Form.Group as={Col} className="mt-3" controlId="formGridPassword">
                                        <Button style={{minWidth:'60px'}}  variant="success" onClick={this.onSubmitAddFamilyBackground}>Add Row</Button>
                                    </Form.Group>
                                    <Form.Group as={Row} controlId="formHorizontalEmail">
                                        <Col sm={12}>
                                            <BootstrapTable
                                                caption={Noser.TableHeader({title:"FAMILY BACKGROUND"})}
                                                keyField = "id"
                                                data = { this.state.familyList}
                                                columns = { FamilyBackground}
                                                selectRow = { selectRowFamilyBackground }
                                                cellEdit = { cellEditFactory({ mode: 'dbclick', blurToSave: true }) }
                                                rowClasses="noser-table-row-class"                          
                                                striped
                                                hover
                                                condensed
                                            />
                                            
                                        </Col>
                                    </Form.Group>
                                    <Card.Header>III. EDUCATION</Card.Header>
                                    <Form.Group as={Col} className="mt-3" controlId="formGridPassword">
                                        <Button style={{minWidth:'60px'}}  variant="success" onClick={this.onSubmitAddEmployeeEducation}>Add Row</Button>
                                    </Form.Group>
                                    <Form.Group as={Row} controlId="formHorizontalEmail">
                                        <Col sm={12}>
                                            <BootstrapTable
                                                keyField = "id"
                                                data = { this.state.educationList}
                                                columns = { education}
                                                selectRow = { selectRow }
                                                cellEdit = { cellEditFactory({ mode: 'dbclick', blurToSave: true }) }
                                                rowClasses="noser-table-row-class"
                                                striped
                                                hover
                                                condensed
                                            />
                                            
                                        </Col>
                                    </Form.Group>
                                    <ButtonToolbar sm={12} className="mt-3">
                                        <Button variant="primary"  onClick={this.handleSaveClick}>
                                            Submit
                                        </Button>&nbsp;&nbsp;
                                        <Button variant="danger" href="/ManagerProfile">
                                            Back
                                        </Button>
                                    </ButtonToolbar>
                                </Form>
                            </Card.Body>
                        </Card>
                    </Container>
                    <NoserLoading show={this.state.isLoading} />
            </div> 
            
        )
    }
}

export default ManagerProfileCreate;