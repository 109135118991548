import {
    React,Component,BootstrapTable,
    paginationFactory,Button, ButtonToolbar, Card, 
    Form, Col, Row, axios, Container, Banner, Typeahead, TimePicker,
    cellEditFactory, Type, moment, NavLink, Alert, AppConfiguration,
    NoserLoading, Noser, sizePerPageRenderer,DatePicker, Redirect
} 
from '../../noser-hris-component';
import { DropdownButton, Dropdown} from 'react-bootstrap';
// import { DropDownList } from '@progress/kendo-react-dropdowns';

class ExamResultCreate extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isloading:false,
            isshow:false,
            alerttype:"",
            message:"",
            color:"",
            fade:true,
            navigate    : false,
            clientDDL : [],
            clientId : "",
            employeeDDL : [],
            applicationId : "",
            date : "",
            positionDDL : [],
            positionId : "",
            typeOfTestDDL : [
                {"name":"Select Type Of Test","value":""},
                {   "name"  :   "SRA",              "value" :   "1" },
                {   "name"  :   "ENGLISH GRAMMAR",  "value" :   "2" },
                {   "name"  :   "RAVEN",            "value" :   "3" },
                {   "name"  :   "MATH EXAM",        "value" :   "4" },
                {   "name"  :   "ACCOUNTING EXAM",  "value" :   "5" },
                {   "name"  :   "SSCT",             "value" :   "6" }
            ],
            typeOfTestId : "",
            score : "",
            categoryDDL : [],
            categoryId : "",
            remarks : "",
            //data : JSON.parse(sessionStorage.getItem("applicationformexam_"))

        }
        this.state.userinfo = JSON.parse(sessionStorage.getItem("userData"))
        this.state.data = JSON.parse(sessionStorage.getItem("applicationformexam_" + this.state.userinfo.userId + this.state.userinfo.employeeId))
        
        this.state.applicationId   =   this.state.data.id
        this.state.profileId       =   this.state.data.profileId
        this.state.clientName      =   this.state.data.client
        this.state.clientId        =   this.state.data.clientId
        this.state.memberName      =   this.state.data.employeeName
        this.state.position        =   this.state.data.position
        this.state.positionId      =   this.state.data.positionId
       // this.state.address          = this.state.data.applicantAddresses[0].houseNumber + ", " + this.state.data.applicantAddresses[0].streetName+ ", " + this.state.data.applicantAddresses[0].barangay + ", " + this.state.data.applicantAddresses[0].city + ", " + this.state.data.applicantAddresses[0].province
        
    }
    componentDidMount() {
        const sleep = (milliseconds) => {return new Promise(resolve => setTimeout(resolve, milliseconds))}
        
        this.GetClient();
        this.GetPosition();
        this.GetEmployee();
        this.GetCategory();
        this.GetApplicationFormDetails();
    }
    
    GetApplicationFormDetails = async()=> {
        //this.setState({isloading:true})

        const params = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.clientId,
            "UserId":this.state.userinfo.userId,
            "ApplicationId" : this.state.applicationId,
        };
        console.log("applicationParams")
        console.log(params)
        axios
            .post(AppConfiguration.Setting().noserapiendpoint + "Recruitment/GetApplicationFormDetails",  params)
            .then(res => {
            const data = res.data;
            console.log("GetApplicationFormDetails")
            console.log(data)
            if(data.status==="1"){
                this.setState({
                    address :    data.applicantAddresses[0]["houseNumber"] + ", " +
                                        data.applicantAddresses[0]["streetName"] + ", " + 
                                        data.applicantAddresses[0]["barangay"] + ", " + 
                                        data.applicantAddresses[0]["city"] + ", " + 
                                        data.applicantAddresses[0]["province"] + ", " +
                                        data.applicantAddresses[0]["region"]
                })
            }
            if(data.status=="0"){
               this.setState({
                   isloading   :   false,
                   alerttype   :   "Error!",
                   isshow      :   true,
                   color       :   "danger",
                   message     :   data.message,
                   fade        :   true
               });
            }
         })
         .catch(error=>{
            this.setState(  {
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade            :   true
            })
        })
    }

    GetClient = async()=> {
        this.setState({isloading:true})
        const clientParams = {
            "IpAddress"     :   "0.0.0.0",
            "ClientId"      :   this.state.userinfo.clientId,
            "UserId"        :   this.state.userinfo.userId,
        };
        await
        axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Client/GetClientList",  clientParams)
        .then(res => {
            const data = res.data;
            this.setState({
                clientDDL   :   data.clients,
                isloading   :   false
            });
        })
        .catch(error=>{
            this.setState(  {
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade            :   true
            })
        })
    }

    handleChangeClient = (e) => {
        if(e.length == 0) {
            this.state.clientId     =   ""
            this.state.client   =   ""
            return
        }
        this.state.clientId     =   e[0].id
        this.state.client   =   e[0].name
        this.setState({
            isshow:false,
        })
        this.GetEmployee();
    }

    GetPosition = async()=> {
        this.setState({isloading:true})
        const positionParams = {
            "IpAddress"     : "0.0.0.0",
            "ClientId"      : this.state.userinfo.clientId,
            "UserId"        : this.state.userinfo.userId,
            "SectionId"     : "",
            "DepartmentId"  : "",
            "Name"          : ""
        };
        await
        axios.post(AppConfiguration.Setting().noserapiendpoint + "Maintenance/GetPositions",  positionParams)
        .then(res => {
            const data = res.data;
            this.setState({
                positionDDL : data.positions,
                isloading   : false
            });
        })
        
    }
    
    handleChangePosition = (e) => {
        if(e.length == 0) {
            this.state.positionId     =   ""
            this.state.position   =   ""
            return
        }
        this.state.positionId     =   e[0].id
        this.state.position   =   e[0].name
        this.setState({
            isshow:false,
        })
    }

    GetEmployee = async()=> {

        this.setState({
            isloading:true
        })
        const applicationParams = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.clientId,
            "UserId":this.state.userinfo.userId,
            "ProfileId": "",
            "PositionId":"",
            "StatusId":"4",
            "TINNumber":"",
            "StatusId":""
        };
        await
        axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Recruitment/GetApplicationForms",  applicationParams)
        .then(res => {
            const data = res.data;
            console.log(data)
            this.setState({ employeeDDL: data.applicationForms});
        })
        .catch(error=>{
            this.setState(  {
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade            :   true
            })
        })
    }
    
    handleChangeEmployee = (e) => {
        if(e.length == 0) {
            this.state.applicationId     =   ""
            return
        }
        this.state.applicationId     =   e[0].id
        this.setState({
            isshow:false,
        })
    }

    handleChangeDate = date => {
        this.setState({
            date: date,
            isshow:false,
        })
    }

    handleChangeTypeOfTest = (e) => {
        this.setState({ typeOfTestId: e.target.value });
    };

    onChangeScore = (e) => {
        this.setState({ score: e.target.value });
    };

    GetCategory = async()=> {
        const categParams = {
            "IpAddress":"0.0.0.0",
            "ClientId"      :   this.state.userinfo.clientId,
            "UserId"        :   this.state.userinfo.userId,
        };
        await
        axios.post(AppConfiguration.Setting().noserapiendpoint + "Maintenance/GetExamCategories",  categParams)
        .then(res => {
            const data = res.data;
            ////console.log("Test cat");
            ////console.log(data);
            this.setState({ categoryDDL: data.examCategories });
        })
    }
    
    handleChangeCategory = (e) => {
        if(e.length == 0) {
            this.state.categoryId     =   ""
            return
        }
        this.state.categoryId     =   e[0].id
        this.setState({
            isshow:false,
        })
    }

    onChangeRemarks = (e) => {
        this.setState({ remarks: e.target.value });
    };
    FormatDate(date) {
        let m = moment(date, 'MM-DD-YYYY');
        return (m.isValid() ? (date.getMonth()+1 + "/" + date.getDate() + "/" + date.getFullYear()) : "")
    }


    handleClickStatus = (statusId) => {
        this.setState({isloading:true });
        const params = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.clientId,
            "UserId":this.state.userinfo.userId,
            "ApplicationFormId":this.state.applicationId,
            "ExamCategoryId":this.state.categoryId,
            "ExamId":this.state.typeOfTestId,
            "Score":this.state.score,
            "DateTaken":this.FormatDate(this.state.date),
            "Remarks":this.state.remarks,
            "StatusId":statusId
        }
        console.log(params)
        axios
        .post( AppConfiguration.Setting().noserapiendpoint + "Recruitment/AddApplicantExam",  params)
        .then(res => {
            const data = res.data;
            if(data.status=="1"){
            this.setState({
                    isloading   :   false,
                    alerttype   :   "Success!",
                    isshow      :   true,
                    color       :   "success",
                    message     :   data.message,
                    fade        :   true,
                    navigate    :   true,

                });
            }
            else {
                this.setState({
                    isloading:false,
                    alerttype:"Error!",
                    isshow:true,
                    color:"danger",
                    message: data.message,
                    fade:true
                });
            }
        })
        .catch(error=>{
            this.setState(  {
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade            :   true
            })
        })
    }

    render() {
        const { navigate } = this.state;
        if (navigate) {
            return <Redirect to="/applicationform" push={true} />;
        } 
        return(
            <div>
                <Banner />
                <Container className="mt-5" fluid>
                    <Card>
                        <Card.Header>RECRUITMENT >> EXAM RESULTS (CREATE)</Card.Header>
                        <Card.Body>
                            <Form>
                                <Alert isOpen={this.state.isshow} color={this.state.color} fade={this.state.fade} className={this.state.isshow ? 'display-block' : 'display-none'}>
                                    <div className={this.state.color}></div> 
                                    {this.state.message}
                                </Alert>
                                <Form.Row>
                                    <Form.Group as={Col} controlId="formGridAddress1">
                                        <Form.Label style={{fontWeight : "bold"}}>CLIENT</Form.Label>
                                        <Typeahead 
                                            labelKey='name'
                                            id="basic-example"
                                            onChange={this.handleChangeClient}
                                            options={this.state.clientDDL}
                                            placeholder="Select Client" 
                                            defaultSelected={[this.state.clientName]}
                                            disabled
                                        />
                                    </Form.Group>
                                    <Form.Group as={Col} sm="1" controlId="formGridCity">
                                    </Form.Group>
                                    <Form.Group as={Col} controlId="formGridAddress1">
                                        <Form.Label style={{fontWeight : "bold"}}>EMPLOYEE</Form.Label>
                                        <Typeahead 
                                            labelKey='memberName'
                                            id="basic-example"
                                            onChange={this.handleChangeEmployee}
                                            options={this.state.employeeDDL}
                                            placeholder="Select Employee" 
                                            defaultSelected={[this.state.memberName]}
                                        />
                                    </Form.Group>
                                </Form.Row>
                                <Form.Row className="mt-2">
                                    <Form.Group as={Col} controlId="formGridAddress1">
                                        <Form.Label style={{fontWeight : "bold", marginLeft : 15}}>DATE</Form.Label>
                                        <Form.Group as={Col} controlId="formGridCity">
                                        <DatePicker
                                            ref='date'
                                            selected={this.state.date}
                                            onChange={this.handleChangeDate}
                                            minDate={this.minDate}
                                            value={this.state.date}
                                            dateFormat={"MM/dd/yyyy"}
                                            className="form-control"
                                            wrapperClassName="datepicker"
                                            placeholderText="Select Date" 
                                        />
                                        </Form.Group>
                                    </Form.Group>
                                    <Form.Group as={Col} sm="1" controlId="formGridCity">
                                    </Form.Group>
                                    <Form.Group as={Col} controlId="formGridCity">
                                        <Form.Label style={{fontWeight : "bold"}}>POSITION APPLIED</Form.Label>
                                        <Typeahead
                                            ref='clearPosition'
                                            labelKey='name'
                                            id="basic-example"
                                            onChange={this.handleChangePosition}
                                            options={this.state.positionDDL}
                                            placeholder="Select Position" 
                                            defaultSelected={[this.state.position]}
                                            disabled
                                        />
                                    </Form.Group>
                                </Form.Row>
                                <Form.Row className="mt-2">
                                    <Form.Group as={Col} controlId="formGridCity">
                                        <Form.Label style={{fontWeight : "bold"}}>TYPE OF TEST</Form.Label>
                                        <Form.Control
                                            as="select"
                                            onChange={this.handleChangeTypeOfTest}
                                            value={this.state.typeOfTestId}
                                        >
                                        {this.state.typeOfTestDDL.map(item => (
                                            <option key={item.value} value={item.value}>
                                            {item.name}
                                            </option>
                                        ))}
                                        </Form.Control>
                                    </Form.Group>
                                    <Form.Group as={Col} sm="1" controlId="formGridCity">
                                    </Form.Group>
                                        <Form.Group as={Col} controlId="formGridCity">
                                            <Form.Label style={{fontWeight : "bold"}}>SCORE</Form.Label>
                                            <Form.Control 
                                                type="text"
                                                name="score"
                                                value={this.state.score}
                                                onChange={this.onChangeScore} 
                                                autoComplete="off"
                                                placeholder="Enter Score" 
                                            />
                                        </Form.Group>
                                </Form.Row>
                                <Form.Row className="mt-2">
                                    <Form.Group as={Col} controlId="formGridAddress1">
                                        <Form.Label style={{fontWeight : "bold",}}>CATEGORY</Form.Label>
                                        <Typeahead
                                            ref='clearPosition'
                                            labelKey='name'
                                            id="basic-example"
                                            onChange={this.handleChangeCategory}
                                            options={this.state.categoryDDL}
                                            placeholder="Select Category" 
                                        />
                                    </Form.Group>
                                    <Form.Group as={Col} sm="1" controlId="formGridCity">
                                    </Form.Group>
                                    <Form.Group as={Col} controlId="formGridCity">
                                        <Form.Label style={{fontWeight : "bold"}}>REMARKS</Form.Label>
                                            <Form.Control 
                                                type="text"
                                                name="remarks"
                                                value={this.state.remarks}
                                                onChange={this.onChangeRemarks} 
                                                autoComplete="off"
                                                placeholder="Enter Remarks" 
                                            />
                                    </Form.Group>
                                </Form.Row>
                                <ButtonToolbar className="mt-3">
                                    <Button disabled={this.state.disablebutton}  className="ml-auto noser-button-mr1" variant="success" onClick = {() => this.handleClickStatus('1')}>Save</Button>
                                    <Button disabled={this.state.disablebutton} className="noser-button-mr1"   variant="primary" onClick = {() => this.handleClickStatus('11')}>Submit</Button>
                                    <NavLink to="/applicationform">
                                        <Button  className="noser-button noser-button-mr1" variant="danger">Back</Button>
                                    </NavLink>
                                </ButtonToolbar>
                            </Form>
                        </Card.Body>
                    </Card>
                <NoserLoading show={this.state.isloading} />
                </Container>
            </div> 
        )
    }

}

export  default ExamResultCreate 
