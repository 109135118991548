import {
    React,Component,BootstrapTable,
    paginationFactory,Button, ButtonToolbar, Card, 
    Form, Col, Row, axios, Container, Banner, Typeahead, TimePicker,
    cellEditFactory, Type, moment, NavLink, Alert, AppConfiguration,
    NoserLoading, Noser, sizePerPageRenderer, DatePicker, Tabs, Tab
} 
from '../../noser-hris-component';
import { DropdownButton, Dropdown} from 'react-bootstrap';
import NumberFormat from 'react-number-format';

class ProfileEdit extends Component {
    constructor(props) {
        super(props)
        this.state = {
            userinfo        :   [],
            isloading       :   false,
            isshow          :   false,
            alerttype       :   "",
            message         :   "",
            color           :   "",
            fade            :   true,

            profileDDL : [],
            firstName : "",
            titleDDL : [
                { "name" : "MR." },
                { "name" : "MS." },
                { "name" : "MRS." }
            ],
            genderDDL : [
                {"name":"MALE","id":"1"},
                {"name":"FEMALE","id":"2"}
            ],
            civilStatusDDL:[
                {"name":"SINGLE","id":"1"},
                {"name":"MARRIED","id":"2"},
                {"name":"DIVORCED","id":"3"},
                {"name":"SEPARATED","id":"4"}

            ],
            nationalityDDL:[
                {"name":"FILIPINO","id":"1"},
                {"name":"OTHERS","id":"2"}
            ],
            religionDDL:[
                {"name":"ADVENTIST","id":"1"},
                {"name":"AGLIPAY","id":"2"},
                {"name":"ALLIANCE","id":"3"},
                {"name":"APOSTLIC HOLINESS","id":"4"},
                {"name":"ASSEMBLY OF GOD","id":"5"},
                {"name":"BAPTIST","id":"6"},
                {"name":"BIBLE BAPTIST","id":"7"},
                {"name":"BORN AGAIN","id":"8"},
                {"name":"BORN AGAIN CHRISTIAN","id":"9"},
                {"name":"CATHOLIC","id":"10"},
                {"name":"CHRISTIAN","id":"11"},
                {"name":"CHRISTIAN(BORN AGAIN)","id":"12"},
                {"name":"CHURCH OF GOD","id":"13"},
                {"name":"EVANGILICAL","id":"14"},
                {"name":"IEMELIF","id":"15"},
                {"name":"IGLESIA NI CHRISTO","id":"16"},
                {"name":"ISLAM","id":"17"},
                {"name":"JEHOVA WITNESS","id":"18"},
                {"name":"JEHOVAH'S WITNESS","id":"19"},
                {"name":"JEHOVA'S WITNESS","id":"20"},
                {"name":"MCGI","id":"21"},
                {"name":"METHODIST","id":"22"},
                {"name":"MORMON","id":"23"},
                {"name":"MORMONS","id":"24"},
                {"name":"N/A","id":"25"},
                {"name":"OTHERS","id":"26"},
                {"name":"PENTECOSTAL","id":"27"},
                {"name":"PIC","id":"28"},
                {"name":"PINICOCS","id":"29"},
                {"name":"PROTESTANT","id":"30"},
                {"name":"ROMAN CATHOLIC","id":"31"},
                {"name":"SDA","id":"32"},
                {"name":"SEVENTH DAYA ADVENTIST","id":"33"},
                {"name":"THE CHURCH OF JESUS CHRIST","id":"34"},
                {"name":"UCCP","id":"35"},
                {"name":"UNION ESPIRITISTA" ,"id":"36"},
                {"name":"UPC","id":"37"},
                {"name":"V.C.C.P","id":"38"}
            ],
            livingArrangeDDL : [],
            bloodTypeDDL : [],
            mobileNumber : "",


            typeList:[
                {"name":"CURRENT ADDRESS","id":"0"},
                {"name":"REGISTERED ADDRESS","id":"1"},
                {"name":"PROVINCIAL ADDRESS","id":"2"},
                {"name":"CORRESPONDENCE ADDRESS","id":"3"},
                {"name":"FOREIGN ADDRESS","id":"4"},
            ],
            tblTypeArrLst   :   [],
            tblRegionArrLst :   [],
            tblProvincesArrLst : [],
            tblCitiesArrLst  :   [],
            addressListGrid : [],
            relativeListGrid  :   [],
            backgroundListGrid : [],
            roleList:[
                {"name":"FATHER","id":"0"},
                {"name":"MOTHER","id":"1"},
                {"name":"SPOUSE","id":"2"},
                {"name":"BROTHER","id":"3"},
                {"name":"SISTER","id":"4"},
                {"name":"CHILD","id":"5"},
            ],
            tblRoleArrLst   :   [],

            educationListGrid : [],
            levelList:[
                {"name":"COLLEGE","id":"0"},
                {"name":"HIGH SCHOOL","id":"1"},
                {"name":"ELEMENTARY","id":"2"},
                {"name":"OTHERS","id":"3"}
            ],
            tblLevelArrLst  :   [],
            applicationListGrid :   [],
            employmentListGrid   :   [],
            trainingsListGrid   :   [],

            profiledata : {},
        }
        let profiledata = JSON.parse(sessionStorage.getItem("employeeProfileData")).employeeProfiles[0]
        this.state.profiledata = JSON.parse(sessionStorage.getItem("employeeProfileData")).employeeProfiles[0]
    }

    componentDidMount(){
        this.state.userinfo = JSON.parse(sessionStorage.getItem("userData"))
        this.GetProfileStatus();
        this.GetLivingArrange();
        this.GetBloodType();

        this.getTypeList();
        this.GetRegion();
        this.getRoleList();
        this.getLevelList();    
    }
    LoadProfileDetails()
    {
        let profileDetails = JSON.parse(sessionStorage.getItem("employeeProfileDetails"))
        this.state.addressListGrid = profileDetails.employeeAddresses
        this.state.relativeListGrid = profileDetails.employeeCompanyRelatives
        this.state.backgroundListGrid = profileDetails.employeeFamilyBackgrounds
        this.state.educationListGrid = profileDetails.employeeEducations
        this.state.applicationListGrid = profileDetails.employeeApplicationRecords
        this.state.employmentListGrid = profileDetails.employmentHistories
        this.state.trainingsListGrid = profileDetails.employeeTrainings

        let profiledata = this.state.profiledata
        /* Start General Inforamtion */
        this.setState({
            id                           :   profiledata.id,
            profileStatusId              :   profiledata.profileStatusId,
            title                        :   profiledata.title,
            firstName                    :   profiledata.firstName,
            nickName                     :   profiledata.nickName,
            middleName                   :   profiledata.middleName,
            lastName                     :   profiledata.lastName,
            gender                       :   profiledata.gender,
            civilStatus                  :   profiledata.civilStatus,
            nationalityId                :   profiledata.nationalityId,
            placeOfBirth                 :   profiledata.placeOfBirth,
            dateOfBirth                  :   new Date(profiledata.dateOfBirth),
            age                          :   profiledata.age,
            homePhoneNumber              :   profiledata.homePhoneNumber,
            mobileNumber                 :   profiledata.mobileNumber,
            religionId                   :   profiledata.religionId,
            emailAddress                 :   profiledata.emailAddress,
            facebook                     :   profiledata.facebook,
            twitter                      :   profiledata.twitter,
            sss                          :   profiledata.sssNumber,
            tin                          :   profiledata.tinNumber,
            philhealth                   :   profiledata.philHealthNumber,
            pagibig                      :   profiledata.pagibigNumber,
            livingArrangementId          :   profiledata.livingArrangementId,
            bloodTypeId                  :   profiledata.bloodTypeId,
            height                       :   profiledata.height,
            weight                       :   profiledata.weight,
            hobbies                      :   profiledata.hobbies,
            languageSpoken               :   profiledata.languageSpoken,
            emergencyContactName         :   profiledata.emergencyContactName,
            emergencyContactRelationship :   profiledata.emergencyContactRelationship,
            emergencyContactNumber       :   profiledata.emergencyContactNumber
        })
    }
    GetProfileStatus = async () => {
        const dataParams = {
            "IpAddress"     :   "0.0.0.0",
            "ClientId"      :   this.state.userinfo.clientId,
            "UserId"        :   this.state.userinfo.userId,
            "Code"          :   "0018"
        };

        await axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Maintenance/GetDataReferences",  dataParams)
        .then(res => {
            const data = res.data;
            //console.log("GetProfileStatus")
            //console.log(data)
            this.setState({ profileDDL  : data.dataReferences}); 
        })
        
    } 

    handleCoverProfileStatus= (e) => {
        if(e.length == 0) {
            this.state.profileStatusId     =   ""
            return
        }
        this.state.profileStatusId     =   e[0].id

        this.setState({
            isshow:false,
        })
    }

    handleChangeTitle = (e) => {
        if(e.length == 0) {
            this.state.title     =   ""
            return
        }
        this.state.title     =   e[0].name

        this.setState({
            isshow:false,
        })
    }

    onChangeFirstName = (e) => {
        this.setState({firstName : e.target.value.toUpperCase()})
    }

    onChangeNickName = (e) => {
        this.setState({nickName : e.target.value.toUpperCase()})
    }

    onChangeMiddleName = (e) => {
        this.setState({middleName : e.target.value.toUpperCase()})
    }

    onChangeLastName = (e) => {
        this.setState({lastName : e.target.value.toUpperCase()})
    }

    handleChangeGender= (e) => {
        if(e.length == 0) {
            this.state.gender     =   ""
            return
        }
        this.state.gender     =   e[0].name
        console.log("gender")
        console.log(this.state.gender)
    }

    handleChangeCivilStatus= (e) => {
        if(e.length == 0) {
            this.state.civilStatus     =   ""
            return
        }
        this.state.civilStatus     =   e[0].name
    }

    onChangePlaceOfBirth = (e) => {
        this.setState({placeOfBirth : e.target.value.toUpperCase()})
    }

    onChangeDateOfBirth = date => {
        this.setState({
            dateOfBirth: date
        });
    };

    onChangeAge = (e) => {
        this.setState({age : e.target.value})
    }

    handleChangeNationality = (e) => {
        if(e.length == 0) {
            this.state.nationalityId     =   ""
            return
        }
        this.state.nationalityId     =   e[0].id
    }

    onChangeHomePhoneNumber = (e) => {
        /* const re = /^[0-9\b]+$/;
        if (e.target.value === '' || re.test(e.target.value)) {
            this.setState({homePhoneNumber: e.target.value})
        } */
        this.setState({homePhoneNumber: e.target.value})
    }

    onChangeMobileNumber = (e) => {
        /* const re = /^[0-9\b]+$/;
        if (e.target.value === '' || re.test(e.target.value)) {
            this.setState({mobileNumber: e.target.value})
        } */
        this.setState({mobileNumber: e.target.value})
    }

    handleChangeReligion = (e) => {
        if(e.length == 0) {
            this.state.religionId     =   ""
            return
        }
        this.state.religionId     =   e[0].id
    }

    onChangeEmailAddress = (e) => {
        this.setState({emailAddress : e.target.value.toUpperCase()})
    }

    onChangeFacebook = (e) => {
        this.setState({facebook : e.target.value.toUpperCase()})
    }

    onChangeTwitter = (e) => {
        this.setState({twitter : e.target.value.toUpperCase()})
    }

    onChangeSSS = (e) => {
        this.setState({sss : e.target.value})
    }

    onChangeTin = (e) => {
        this.setState({tin : e.target.value})
    }

    onChangePhilHealth = (e) => {
        this.setState({philhealth : e.target.value})
    }

    onChangePagibig = (e) => {
        this.setState({pagibig : e.target.value})
    }

    GetLivingArrange = async () => {
        const dataParams = {
            "IpAddress"     :   "0.0.0.0",
            "UserId"        :   this.state.userinfo.userId,
            "ClientId"      :   this.state.userinfo.clientId,
            "Name"          :   ""
        };

        await axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Maintenance/GetLivingArrangements",  dataParams)
        .then(res => {
            const data = res.data;
            this.setState({ livingArrangeDDL  : data.livingArrangements}); 
            
        })
        
    } 

    handleCoverLivingArrangement = (e) => {
        if(e.length == 0) {
            this.state.livingArrangementId     =   ""
            return
        }
        this.state.livingArrangementId     =   e[0].id
    }

    GetBloodType = async () => {
        const dataParams = {
            "IpAddress"     :   "0.0.0.0",
            "UserId"        :   this.state.userinfo.userId,
            "ClientId"      :   this.state.userinfo.clientId,
            "Name"          :   ""
        };

        await axios 
        .post(AppConfiguration.Setting().noserapiendpoint + "Maintenance/GetBloodTypes",  dataParams)
        .then(res => {
            const data = res.data;
            //////////console.log("GetBloodTypes")
            //////////console.log(data)
            this.setState({ bloodTypeDDL  : data.bloodTypes});
        })
        
    }  

    handleCoverBloodType = (e) => {
        if(e.length == 0) {
            this.state.bloodTypeId     =   ""
            return
        }
        this.state.bloodTypeId     =   e[0].id
    }

    onChangeHeight = (e) => {
        this.setState({height : e.target.value})
    }

    onChangeWeight = (e) => {
        this.setState({weight : e.target.value})
    }
    
    onChangeHobbies = (e) => {
        this.setState({hobbies : e.target.value.toUpperCase()})
    }

    onChangeLanguageSpoken = (e) => {
        this.setState({languageSpoken : e.target.value.toUpperCase()})
    }

    onChangeEmergencyContactName = (e) => {
        this.setState({emergencyContactName : e.target.value.toUpperCase()})
    }

    onChangeRelationship = (e) => {
        this.setState({emergencyContactRelationship : e.target.value.toUpperCase()})
    }

    onChangeContactNumber = (e) => {
        const re = /^[0-9\b]+$/;
        if (e.target.value === '' || re.test(e.target.value)) {
            this.setState({emergencyContactNumber: e.target.value})
        }
    }
         
    getTypeList(){

        for (let i = 0; i < this.state.typeList.length; i++) {
            const objType = {
                value : this.state.typeList[i]["id"],
                label : this.state.typeList[i]["name"],
            };
            this.state.tblTypeArrLst.push(objType);
        }
    }


    GetRegion = async () => {

        const regionParams = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.userinfo.clientId,
            "UserId":this.state.userinfo.userId,
            "Name":""
        };
        await
        axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Maintenance/GetRegions",  regionParams)
        .then(res => {

            for (let i = 0; i < res.data.regions.length; i++) {
                const regionObj = {
                    value : res.data.regions[i]["id"],
                    label : res.data.regions[i]["name"],
                };
                this.state.tblRegionArrLst.push(regionObj);
            }
         })
         this.GetProvince()
    }

    GetProvince = async () => {
        const provinceParams = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.userinfo.clientId,
            "UserId":this.state.userinfo.userId,
            "Region": ""
        };
        await
        axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Maintenance/GetProvinces",  provinceParams)
        .then(res => {

            for (let i = 0; i < res.data.provinces.length; i++) {
                const provinceObj = {
                    value : res.data.provinces[i]["id"],
                    label : res.data.provinces[i]["name"],
                };
                this.state.tblProvincesArrLst.push(provinceObj);
            }
        })
        this.GetCity()
    }

    GetCity = async () => {

        const cityParams = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.userinfo.clientId,
            "UserId":this.state.userinfo.userId,
            "Name":"",
            "ProvinceName": ""
        };
        await
         axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Maintenance/GetCities",  cityParams)
        .then(res => {
            for (let i = 0; i < res.data.cities.length; i++) {
                const cityObj = {
                    value : res.data.cities[i]["id"],
                    label : res.data.cities[i]["name"],
                };
                this.state.tblCitiesArrLst.push(cityObj);
            }
        })
        this.LoadProfileDetails()
    }

    AddressGridDataModified(oldValue, newValue, id, column) {
        this.state.addressListGrid.map(function(item,i) {
            if (item.id===id)
                item.isModified = newValue!=oldValue ? "1" : "0"
        })
    }

    CompanyRelativeGridDataModified(oldValue, newValue, id, column) {
        //////////console.log(id)
        this.state.relativeListGrid.map(function(item,i) {
            if (item.id===id)
                item.isModified = newValue!=oldValue ? "1" : "0"
            })
    }
         
    getRoleList(){
        for (let i = 0; i < this.state.roleList.length; i++) {
            const objType = {
                value : this.state.roleList[i]["id"],
                label : this.state.roleList[i]["name"],
            };
            this.state.tblRoleArrLst.push(objType);
        }
    }
         
    getLevelList(){
        //////////console.log("getLevelList")
        //////////console.log(this.state.levelList)
        for (let i = 0; i < this.state.levelList.length; i++) {
            const objType = {
                value : this.state.levelList[i]["id"],
                label : this.state.levelList[i]["name"],
            };
            this.state.tblLevelArrLst.push(objType);
        }
        
    }

    handleSaveClick = () => {
        this.setState({
            isloading :   true
        });

        let addressArrList = []
        let relativeArrList = []
        let backgroundArrList = []
        let educationArrList = []
        let recordArrList = []
        let employmentArrList = []
        let trainingArrList = []
        let documentsArrLis = []

        for (let i = 0; i < this.state.addressListGrid.length; i++) {
            const obj = {
            "Id"            :   this.state.addressListGrid[i]["id"],
            "TypeId"            :   this.state.addressListGrid[i]["typeId"],
            "HouseNumber"       :   this.state.addressListGrid[i]["houseNumber"],
            "StreetName"        :   this.state.addressListGrid[i]["streetName"],
            "Barangay"          :   this.state.addressListGrid[i]["barangay"],
            "PostalCode"        :   this.state.addressListGrid[i]["postalCode"],
            "RegionId"          :   this.state.addressListGrid[i]["regionId"],
            "Region"            :   this.state.addressListGrid[i]["region"],
            "Province"          :   this.state.addressListGrid[i]["province"],
            "ProvinceId"        :   this.state.addressListGrid[i]["provinceId"],
            "City"              :   this.state.addressListGrid[i]["city"],
            "CityId"            :   this.state.addressListGrid[i]["cityId"],
                
            };

            addressArrList.push(obj);
        } 

        for (let i = 0; i < this.state.relativeListGrid.length; i++) {
            const obj = {
            "Id"            :   this.state.relativeListGrid[i]["id"],
            "Name"          :   this.state.relativeListGrid[i]["name"],
            "RelationShip"  :   this.state.relativeListGrid[i]["relationShip"],
            "JobTitle"      :   this.state.relativeListGrid[i]["jobTitle"],
                
            };

            relativeArrList.push(obj);
        } 

        for (let i = 0; i < this.state.backgroundListGrid.length; i++) {
            const obj = {
            "Id"            :   this.state.backgroundListGrid[i]["id"],
            "RoleId"        :   this.state.backgroundListGrid[i]["roleId"],
            "Name"          :   this.state.backgroundListGrid[i]["name"],
            "Age"           :   this.state.backgroundListGrid[i]["age"],
            "Occupation"    :   this.state.backgroundListGrid[i]["occupation"],
            "Company"       :   this.state.backgroundListGrid[i]["company"],
                
            };

            backgroundArrList.push(obj);
        } 

        for (let i = 0; i < this.state.educationListGrid.length; i++) {
            const obj = {
            "Id"            :   this.state.educationListGrid[i]["id"],
            "LevelId"        :   this.state.educationListGrid[i]["levelId"],
            "SchoolName"          :   this.state.educationListGrid[i]["schoolName"],
            "Course"           :   this.state.educationListGrid[i]["course"],
            "StartYear"    :   this.state.educationListGrid[i]["startYear"],
            "EndYear"       :   this.state.educationListGrid[i]["endYear"],
            "HonorRecieved"       :   this.state.educationListGrid[i]["honorRecieved"],
                
            };

            educationArrList.push(obj);
        }

        for (let i = 0; i < this.state.employmentListGrid.length; i++) {
            const obj = {
                "Id"               :   this.state.employmentListGrid[i]["id"],
                "CompanyName"               :   this.state.employmentListGrid[i]["companyName"],
                "CompanyAddress"            :   this.state.employmentListGrid[i]["companyAddress"],
                "Position"                  :   this.state.employmentListGrid[i]["position"],
                "NatureOfWorkId"            :   this.state.employmentListGrid[i]["natureOfWorkId"],
                "Salary"                    :   this.state.employmentListGrid[i]["salary"],
                "Supervisor"                :   this.state.employmentListGrid[i]["supervisor"],
                "ContactNumber"             :   this.state.employmentListGrid[i]["contactNumber"],
                "ReasonForLeaving"          :   this.state.employmentListGrid[i]["reasonForLeaving"],
                "PeriodCovered"             :   this.state.employmentListGrid[i]["periodCovered"],
                "TaxableCompensationIncome" :   this.state.employmentListGrid[i]["taxableCompensationIncome"],
                "WithHoldingTax"            :   this.state.employmentListGrid[i]["withHoldingTax"],
                "YearofCompensation"        :   this.state.employmentListGrid[i]["yearofCompensation"],
                "NTThirteenMonthPay"        :   this.state.employmentListGrid[i]["ntThirteenMonthPay"],
                "Deminimis"                 :   this.state.employmentListGrid[i]["deminimis"],
                "NTMandatoryDeduction"      :   this.state.employmentListGrid[i]["ntMandatoryDeduction"],
                "NTSalaries"                :   this.state.employmentListGrid[i]["ntSalaries"],
                "BasicSalary"               :   this.state.employmentListGrid[i]["basicSalary"],
                "TaxableThirteenMonthPay"   :   this.state.employmentListGrid[i]["taxableThirteenMonthPay"],
                "TaxableSalaries"           :   this.state.employmentListGrid[i]["taxableSalaries"],
                "BasicPayMWE"               :   this.state.employmentListGrid[i]["basicPayMWE"],
                "HolidayPayMWE"             :   this.state.employmentListGrid[i]["holidayPayMWE"],
                "OvertimePayMWE"            :   this.state.employmentListGrid[i]["overtimePayMWE"],
                "NightDiffPayMWE"           :   this.state.employmentListGrid[i]["nightDiffPayMWE"],
                "Remarks"                   :   this.state.employmentListGrid[i]["remarks"],
            };

            employmentArrList.push(obj);
            //console.log(this.state.employmentListGrid)
        } 

        for (let i = 0; i < this.state.trainingsListGrid.length; i++) {
            const obj = {
                "Id"       :   this.state.trainingsListGrid[i]["id"],
                "TrainingId"       :   this.state.trainingsListGrid[i]["trainingId"],
                "Presenter"        :   this.state.trainingsListGrid[i]["presenter"],
                "DateCompleted"    :   this.state.trainingsListGrid[i]["dateCompleted"],
        
            };

            trainingArrList.push(obj);
        }

        const params = {
            "IpAddress"                     :   "0.0.0.0",
            "ClientId"                      :   this.state.userinfo.clientId,
            "UserId"                        :   this.state.userinfo.userId,
            "Id"                            :   this.state.id,
            "LastName"                      :   this.state.lastName,
            "FirstName"                     :   this.state.firstName,
            "MiddleName"                    :   this.state.middleName,
            "NickName"                      :   this.state.nickName,
            "Title"                         :   this.state.title,
            "DateOfBirth"                   :   moment(this.state.dateOfBirth).format("MM/DD/YYYY" ),
            "PlaceOfBirth"                  :   this.state.placeOfBirth,
            "Gender"                        :   this.state.gender,
            "CivilStatus"                   :   this.state.civilStatus,
            "LivingArrangementId"           :   this.state.livingArrangementId,
            "BloodTypeId"                   :   this.state.bloodTypeId,
            "Hobbies"                       :   this.state.hobbies,
            "Height"                        :   this.state.height,
            "Weight"                        :   this.state.weight,
            "TINNumber"                     :   this.state.tin,
            "SSSNumber"                     :   this.state.sss,
            "PhilHealthNumber"              :   this.state.philhealth,
            "PAGIBIGNumber"                 :   this.state.pagibig,
            "EmailAddress"                  :   this.state.emailAddress,
            "ProfileStatusId"               :   this.state.profileStatusId,
            "NationalityId"                 :   this.state.nationalityId,
            "ReligionId"                    :   this.state.religionId,
            "Facebook"                      :   this.state.facebook,
            "Twitter"                       :   this.state.twitter,
            "HomePhoneNumber"               :   this.state.homePhoneNumber,
            "MobileNumber"                  :   this.state.mobileNumber,
            "LanguageSpoken"                :   this.state.languageSpoken,
            "EmergencyContactName"          :   this.state.emergencyContactName,
            "EmergencyContactRelationship"  :   this.state.emergencyContactRelationship,
            "EmergencyContactNumber"        :   this.state.emergencyContactNumber,
            "employeeAddresses"             :   addressArrList,
            "employeeCompanyRelatives"      :   relativeArrList,
            "employeeFamilyBackgrounds"     :   backgroundArrList,
            "employeeEducations"            :   educationArrList,
             
            "employmentHistories"           :   employmentArrList,
            "employeeDocuments"             :   documentsArrLis,
            "employeeTrainings"             :   trainingArrList
        }
        
        console.log("Save Profile Parameter");
        console.log(params)
        axios
          .post(AppConfiguration.Setting().noserapiendpoint + "Employee/EditEmployeeProfile", params)
          .then(res => {
              const data = res.data;
              if(data.status=="1"){
                  this.setState({
                      isloading       :   false,
                      alerttype       :   "Success!",
                      isshow          :   true,
                      color           :   "success",
                      message         :   data.message,
                      fade            :   true
                  });
              }
              else{
                  this.setState({
                      isloading       :   false,
                      alerttype       :   "Error!",
                      isshow          :   true,
                      color           :   "danger",
                      message         :   data.message,
                      fade            :   true
                  })
              }
           })
           .catch(error=>{
              this.setState(  {
                  isloading       :   false,
                  alerttype       :   "Error!",
                  isshow          :   true,
                  color           :   "danger",
                  message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                  fade            :   true
              })
          })
    }

    render() {

        const addressColumn = [
            {
                dataField: 'typeId',
                text: 'TYPE',
                headerStyle: () => {
                    return { width: "10%" };
                },
                formatter: (cell, row) => {
                    if(row.typeId!='' && row.typeId!=null)
                    if(this.state.tblTypeArrLst.filter(x => x.value == cell).length>0)
                        return this.state.tblTypeArrLst.find(x => x.value == cell).label;
                    /* if(row.TypeId!='' && row.TypeId!=null){
                        if(this.state.tblTypeArrLst.filter(x => x.value == cell).length==0)
                        return ""
                        else
                            return this.state.tblTypeArrLst.find(x => x.value == cell).label;
                    } */
                },
                editor: {
                    type: Type.SELECT,
                    options: this.state.tblTypeArrLst.sort((a, b) => (a.label > b.label) ? 1 : -1)
                },
            },
            {
                dataField: 'regionId',
                text: 'REGION',
                headerStyle: () => {
                    return { width: "15%" };
                },
                 formatter: (cell, row) => {

                    if(row.regionId!='' && row.regionId!=null){
                        if(this.state.tblRegionArrLst.filter(x => x.value == cell).length>0)
                            return this.state.tblRegionArrLst.find(x => x.value == cell).label;
                    }
                 },
                 editor: {
                     type: Type.SELECT,
                     options:this.state.tblRegionArrLst.sort((a, b) => (a.label > b.label) ? 1 : -1)
                 }, 
            },
            {
                dataField: 'provinceId',
                text: 'PROVINCE',
                headerStyle: () => {
                    return { width: "15%" };
                },
                formatter: (cell, row) => {
                    /* if(row.provinceId!='' && row.provinceId!=null)
                    return this.state.tblProvincesArrLst.find(x => x.value == cell).label; */
                    if(row.provinceId!='' && row.provinceId!=null){
                        if(this.state.tblProvincesArrLst.filter(x => x.value == cell).length>0)
                           return this.state.tblProvincesArrLst.find(x => x.value == cell).label;
                    }
                },
                editor: {
                    type: Type.SELECT,
                    options: this.state.tblProvincesArrLst.sort((a, b) => (a.label > b.label) ? 1 : -1)
                },
            },
            {
                dataField: 'cityId',
                text: 'CITY/MUNICIPALITY',
                headerStyle: () => {
                    return { width: "15%" };
                },
                formatter: (cell, row) => {
                    if(row.cityId!='' && row.cityId!=null){
                        if(this.state.tblCitiesArrLst.filter(x => x.value == cell).length>0)
                            return this.state.tblCitiesArrLst.find(x => x.value == cell).label;
                    }
                },
                editor: {
                     type: Type.SELECT,
                     options: this.state.tblCitiesArrLst.sort((a, b) => (a.label > b.label) ? 1 : -1)
                 },
            },
            {
                dataField: 'houseNumber',
                text: 'HOUSE#',
                headerStyle: () => {
                    return { width: "10%" };
                },
            },
            {
                dataField: 'streetName',
                text: 'STREET',
                headerStyle: () => {
                    return { width: "10%" };
                },
            },
            {
                dataField: 'barangay',
                text: 'BARANGAY',
                headerStyle: () => {
                    return { width: "15%" };
                },
            },
            {
                dataField: 'postalCode',
                text: 'POSTAL CODE',
                headerStyle: () => {
                    return { width: "10%" };
                },
            }
        ] 
        
        const addressSelectRow = {
            mode: 'checkbox',
            clickToSelectAndEditCell: true,
            onSelect: (row, isSelect, rowIndex, e) => {
                //console.log("address")
                //console.log(this.state.addressListGrid)
                let restLst = this.state.addressListGrid
                this.state.addressListGrid.map(function(item,idx){
                    
                    if(idx==rowIndex)
                    {
                        item.isDeleted = isSelect ? "1" : "0"
                        item.isModified = isSelect ? "1" : "0"
                    }
                })
            }
        };
        
        const addressRowEvents = {
            onClick: (e, row, rowIndex) => {
            }
        };

        const relativeColumn = [
            {
                dataField: 'name',
                text: 'NAME',
                headerStyle: () => {
                    return { width: "40%" };
                },
            },
            {
                dataField: 'relationShip',
                text: 'RELATIONSHIP',
                headerStyle: () => {
                    return { width: "20%" };
                },
            },
            {
                dataField: 'jobTitle',
                text: 'JOB TITLE',
                headerStyle: () => {
                    return { width: "40%" };
                },
            }
        ] 

        const relativeSelectRow = {
            mode: 'checkbox',
            clickToSelectAndEditCell: true,
            onSelect: (row, isSelect, rowIndex, e) => {
                this.state.relativeListGrid.map(function(item,i){
                    if(item.id===row.id)
                    {
                        item.isDeleted = isSelect ? "1" : "0"
                        item.isModified = isSelect ? "1" : "0"
                    }
                })
            }
        };
        
        const relativeRowEvents = {
            onClick: (e, row, rowIndex) => {

            }
        };



        const backgroundColumn = [
            {
                dataField: 'roleId',
                text: 'ROLE',
                headerStyle: () => {
                    return { width: "7%" };
                },
                editable    : true,
                formatter: (cell, row) => {
                    if(row.roleId!='' && row.roleId!=null){
                        if(this.state.tblRoleArrLst.filter(x => x.value == cell).length==0)
                        return ""
                        else
                            return this.state.tblRoleArrLst.find(x => x.value == cell).label;
                    }
                },
                editor: {
                    type: Type.SELECT,
                    options: this.state.tblRoleArrLst
                },
            },
            {
                dataField: 'name',
                text: 'NAME',
                headerStyle: () => {
                    return { width: "30%" };
                },
            },
            {
                dataField: 'age',
                text: 'AGE',
                headerStyle: () => {
                    return { width: "15%" };
                },
            },
            {
                dataField: 'occupation',
                text: 'OCCUPATION',
                headerStyle: () => {
                    return { width: "30%" };
                },
            },
            {
                dataField: 'company',
                text: 'CMPNY/SCHL',
                headerStyle: () => {
                    return { width: "30%" };
                },
            
            },
        ] 

        const backgroundSelectRow = {
            mode: 'checkbox',
            clickToSelectAndEditCell: true,
            onSelect: (row, isSelect, rowIndex, e) => {
                this.state.backgroundListGrid.map(function(item,i){
                    if(item.id===row.id)
                    {
                        item.isDeleted = isSelect ? "1" : "0"
                        item.isModified = isSelect ? "1" : "0"
                    }
                })
            }
        };
        
        const backgroundRowEvents = {
                onClick: (e, row, rowIndex) => {
            }
        };

        const educationColumn = [
            {
                dataField: 'levelId',
                text: 'EDUCATION LEVEL',
                headerStyle: () => {
                    return { width: "25%" };
                },
                editable    : true,
                formatter: (cell, row) => {
                    if(row.levelId!='' && row.levelId!=null){
                        if(this.state.tblLevelArrLst.filter(x => x.value == cell).length==0)
                        return ""
                        else
                            return this.state.tblLevelArrLst.find(x => x.value == cell).label;
                    }
                },
                editor: {
                    type: Type.SELECT,
                    options: this.state.tblLevelArrLst
                },
            },
            {
                dataField: 'schoolName',
                text: 'SCHOOL NAME',
                headerStyle: () => {
                    return { width: "25%" };
                },
            },
            {
                dataField: 'course',
                text: 'COURSE',
                headerStyle: () => {
                    return { width: "15%" };
                },
            },
            {
                dataField: 'startYear',
                text: 'START YEAR',
                headerStyle: () => {
                    return { width: "10%" };
                },
            },
            {
                dataField: 'endYear',
                text: 'END YEAR',
                headerStyle: () => {
                    return { width: "10%" };
                },
            },
            /* {
                dataField: 'generalAverage',
                text: 'GENERAL AVERAGE'
            }, */
            {
                dataField: 'honorRecieved',
                text: 'HONOR(S)/AWARD(S)',
                headerStyle: () => {
                    return { width: "15%" };
                },
            
            }
        ] 

        const educationSelectRow = {
            mode: 'checkbox',
            clickToSelectAndEditCell: true,
            onSelect: (row, isSelect, rowIndex, e) => {
                this.state.educationListGrid.map(function(item,i){
                    if(item.id===row.id)
                    {
                        item.isDeleted = isSelect ? "1" : "0"
                        item.isModified = isSelect ? "1" : "0"
                    }
                })
            }
        };
        
        const educationRowEvents = {
                onClick: (e, row, rowIndex) => {
            }
        };


        const applicationColumn = [
            {
                dataField: 'memberName',
                text: 'FULL NAME',
                headerStyle: () => {
                    return { width: "15%" };
                },
            },
            {
                dataField: 'dateApplied',
                text: 'APPLY DATE',
                headerStyle: () => {
                    return { width: "10%" };
                },
            },
            {
                dataField: 'applicationFormNo',
                text: 'APPLICATION FORM NO.',
                headerStyle: () => {
                    return { width: "10%" };
                },
            },
            {
                dataField: 'client',
                text: 'CLIENT/S',
                headerStyle: () => {
                    return { width: "28%" };
                },
            },
            {
                dataField: 'position',
                text: 'POSITION/S APPLIED',
                headerStyle: () => {
                    return { width: "15%" };
                },
            },
            {
                dataField: 'status',
                text: 'STATUS',
                headerStyle: () => {
                    return { width: "15%" };
                },
            },
            {
                dataField: 'command',
                text: 'COMMAND',
                headerStyle: () => {
                    return { width: "7%" };
                },
            
            }
	    ] 

        const applicationSelectRow = {
            mode: 'checkbox',
            clickToSelectAndEditCell: true,
            onSelect: (row, isSelect, rowIndex, e) => {
                this.state.applicationListGrid.map(function(item,i){
                    if(item.id===row.id)
                    {
                        item.isDeleted = isSelect ? "1" : "0"
                        item.isModified = isSelect ? "1" : "0"
                    }
                })
            }
        };
        
        const applicationRowEvents = {
                onClick: (e, row, rowIndex) => {
            }
        };

        const employmentColumn = [
            {
                dataField: 'companyName',
                text: 'COMPANY'
            },
            {
                dataField: 'companyAddress',
                text: 'ADDRESS'
            },
            {
                dataField: 'position',
                text: 'POSITION'
            },
            {
                dataField: 'natureOfWorkId',
                text: 'WORK_NATURE',
            },
            {
                dataField: 'salary',
                text: 'SALARY',
                align: (cell, row, rowIndex, colIndex) => {
                    return 'right';
                  },
            },
            {
                dataField: 'supervisor',
                text: 'SUPERVISOR'
            
            },
            {
                dataField: 'contactNumber',
                text: 'CONTACT #'
            },
            {
                dataField: 'reasonForLeaving',
                text: 'REASON_FOR_LEAVING'
            },
            {
                dataField: 'periodCovered',
                text: 'PERIOD_COVERED',
            },
            {
                dataField: 'taxableCompensationIncome',
                text: 'TAXABLE_COMPENSATION_INCOME',
                align: (cell, row, rowIndex, colIndex) => {
                    return 'right';
                  },
            
            },
            {
                dataField: 'withHoldingTax',
                text: 'WITHHELD_TAX ',
                align: (cell, row, rowIndex, colIndex) => {
                    return 'right';
                  },
            },
            {
                dataField: 'yearofCompensation',
                text: 'YEAR_OF_COMPENSATION',
                align: (cell, row, rowIndex, colIndex) => {
                    return 'right';
                  },
            },
            {
                dataField: 'ntThirteenMonthPay',
                text: '13th_MONTH_PAY_AND_OTHER_BENEFITS',
                align: (cell, row, rowIndex, colIndex) => {
                    return 'right';
                  },
            
            },
            {
                dataField: 'deminimis',
                text: 'DEMINIMIS_BENEFITS',
                align: (cell, row, rowIndex, colIndex) => {
                    return 'right';
                  },
            },
            {
                dataField: 'ntMandatoryDeduction',
                text: 'MANDATORY_DEDUCTIONS',
                align: (cell, row, rowIndex, colIndex) => {
                    return 'right';
                  },
            },
            {
                dataField: 'ntSalaries',
                text: 'NON-TAXABLE_SALARIES_&_OTHER_FORMS_OF_COMPENSATION',
                align: (cell, row, rowIndex, colIndex) => {
                    return 'right';
                  },
            },
            {
                dataField: 'basicSalary',
                text: 'BASIC_SALARY',
                align: (cell, row, rowIndex, colIndex) => {
                    return 'right';
                  },
            },
            {
                dataField: 'taxableThirteenMonthPay',
                text: 'TAXABLE_13th_MONTH_PAY_&_OTHER_BENEFITS',
                align: (cell, row, rowIndex, colIndex) => {
                    return 'right';
                  },
            },
            {
                dataField: 'taxableSalaries',
                text: 'TAXABLE_SALARIES_&_OTHER_FORMS_OF_COMPENSATION',
                align: (cell, row, rowIndex, colIndex) => {
                    return 'right';
                  },
            
            },
            {
                dataField: 'basicPayMWE',
                text: 'BASIC_PAY_FOR_MWE',
                align: (cell, row, rowIndex, colIndex) => {
                    return 'right';
                  },
            },
            {
                dataField: 'holidayPayMWE',
                text: 'HOLIDAY_PAY_FOR_MWE',
                align: (cell, row, rowIndex, colIndex) => {
                    return 'right';
                  },
            },
            {
                dataField: 'overtimePayMWE',
                text: 'OVERTIME_PAY_FOR_MWE',
                align: (cell, row, rowIndex, colIndex) => {
                    return 'right';
                  },
            
            },
            {
                dataField: 'nightDiffPayMWE',
                text: 'NIGHT_DIFFERENTIAL_PAY_FOR_MWE',
                align: (cell, row, rowIndex, colIndex) => {
                    return 'right';
                  },
            },
            {
                dataField: 'remarks',
                text: 'REMARKS'
            }
	    ] 

        const employmentSelectRow = {
            mode: 'checkbox',
            clickToSelectAndEditCell: true,
            onSelect: (row, isSelect, rowIndex, e) => {
                this.state.employmentListGrid.map(function(item,i){
                    if(item.id===row.id)
                    {
                        item.isDeleted = isSelect ? "1" : "0"
                        item.isModified = isSelect ? "1" : "0"
                    }
                })
            }
        };
        
        const employmentRowEvents = {
                onClick: (e, row, rowIndex) => {
            }
        };

        const trainingsColumn = [
            {
                dataField: 'trainingId',
                text: 'TRAINING ID',
                headerStyle: () => {
                    return { width: "50%" };
                },
            },
            {
                dataField: 'dateCompleted',
                text: 'DATE COMPLETED',
                headerStyle: () => {
                    return { width: "10%" };
                },
            },
            {
                dataField: 'presenter',
                text: 'PRESENTER',
                headerStyle: () => {
                    return { width: "40%" };
                },
            
            },
            
	    ] 

        const trainingsSelectRow = {
            mode: 'checkbox',
            clickToSelectAndEditCell: true,
            onSelect: (row, isSelect, rowIndex, e) => {
                this.state.trainingsListGrid.map(function(item,i){
                    if(item.id===row.id)
                    {
                        item.isDeleted = isSelect ? "1" : "0"
                        item.isModified = isSelect ? "1" : "0"
                    }
                })
            }
        };
        
        const trainingsRowEvents = {
                onClick: (e, row, rowIndex) => {
            }
        };



        return(
            <div>
                <Banner />
                    <Container  className="mt-3" fluid>
                        <Card>
                            <Card.Header>ADMINISTRATOR >> ADMIN >> PROFILE - (EDIT)</Card.Header>
                            <Card.Body>
                                <Form>
                                    <Alert isOpen={this.state.isshow} color={this.state.color} fade={this.state.fade} className={this.state.isshow ? 'display-block' : 'display-none'}>
                                        <div className={this.state.color}></div> 
                                        {this.state.message}
                                    </Alert>
                                    <Tabs className="mt-2" defaultActiveKey="default" transition={false} id="noanim-tab-example">
                                        <Tab eventKey="default" title="I. GENERAL INFORMATION">
                                            <Card className="card-tab-no-border">
                                                <Card.Body>
                                                    <Form.Group as={Row} controlId="formPlaintextEmail">
                                                        <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                        PROFILE STATUS
                                                        </Form.Label>
                                                        <Col sm="10">
                                                            <Typeahead
                                                                labelKey='name'
                                                                id="basic-example"
                                                                onChange={this.handleCoverProfileStatus}
                                                                options={this.state.profileDDL}
                                                                type="text" 
                                                                //defaultSelected={[this.props.location.paramsEmployeeProfile.dataEmployeeProfile[0]["profileStatus"]]}
                                                                defaultSelected={[this.state.profiledata.profileStatus]}
                                                            />
                                                        </Col>
                                                    </Form.Group>
                                                    <Form.Group as={Row} controlId="formPlaintextEmail">
                                                        <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                        TITTLE
                                                        </Form.Label>
                                                        <Col sm="3">
                                                            <Typeahead
                                                                labelKey='name'
                                                                id='basic-example'
                                                                onChange={this.handleChangeTitle}
                                                                options={this.state.titleDDL}
                                                                defaultSelected={[this.state.profiledata.title]}
                                                                //defaultSelected={[this.props.location.paramsEmployeeProfile.dataEmployeeProfile[0]["title"]]}
                                                            />
                                                        </Col>
                                                        <Col sm="2">
                                                        </Col>
                                                        <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                        FIRST NAME
                                                        </Form.Label>
                                                        <Col sm="3">
                                                            <Form.Control 
                                                                type="text" 
                                                                autoComplete="off" 
                                                                name="firstName"
                                                                value={this.state.firstName}
                                                                //onChange={this.onChangeFirstName}
                                                                onChange={this.onChangeFirstName}
                                                            />
                                                        </Col>
                                                    </Form.Group>
                                                    <Form.Group as={Row} controlId="formPlaintextEmail">
                                                        <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                        NICK NAME
                                                        </Form.Label>
                                                        <Col sm="3">
                                                            <Form.Control 
                                                                type="text" 
                                                                autoComplete="off" 
                                                                name="nickName"
                                                                value={this.state.nickName}
                                                                onChange={this.onChangeNickName}
                                                            />
                                                        </Col>
                                                        <Col sm="2">
                                                        </Col>
                                                        <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                        MIDDLE NAME
                                                        </Form.Label>
                                                        <Col sm="3">
                                                            <Form.Control 
                                                                type="text" 
                                                                autoComplete="off" 
                                                                name="middleName"
                                                                value={this.state.middleName}
                                                                onChange={this.onChangeMiddleName}
                                                            />
                                                        </Col>
                                                    </Form.Group>
                                                    <Form.Group as={Row} controlId="formPlaintextEmail">
                                                        <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                        LAST NAME
                                                        </Form.Label>
                                                        <Col sm="3">
                                                            <Form.Control 
                                                                type="text" 
                                                                autoComplete="off" 
                                                                name="lastName"
                                                                value={this.state.lastName}
                                                                onChange={this.onChangeLastName}
                                                            />
                                                        </Col>
                                                        <Col sm="2">
                                                        </Col>
                                                        <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                        GENDER
                                                        </Form.Label>
                                                        <Col sm="3">
                                                            <Typeahead
                                                                labelKey='name'
                                                                id='basic-example'
                                                                onChange={this.handleChangeGender}
                                                                options={this.state.genderDDL}
                                                                //defaultSelected={[this.props.location.paramsEmployeeProfile.dataEmployeeProfile[0]["gender"]]}
                                                                defaultSelected={[this.state.profiledata.gender]}
                                                            />
                                                        </Col>
                                                    </Form.Group>
                                                    <Form.Group as={Row} controlId="formPlaintextEmail">
                                                        <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                        CIVIL STATUS
                                                        </Form.Label>
                                                        <Col sm="3">
                                                            <Typeahead
                                                                labelKey='name'
                                                                id='basic-example'
                                                                onChange={this.handleChangeCivilStatus}
                                                                options={this.state.civilStatusDDL}
                                                                defaultSelected={[this.state.profiledata.civilStatus]}
                                                            />
                                                        </Col>
                                                        <Col sm="2">
                                                        </Col>
                                                        <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                        PLACE OF BIRTH
                                                        </Form.Label>
                                                        <Col sm="3">
                                                            <Form.Control 
                                                                type="text" 
                                                                autoComplete="off" 
                                                                name="placeOfBirth"
                                                                value={this.state.placeOfBirth}
                                                                onChange={this.onChangePlaceOfBirth}
                                                            />
                                                        </Col>
                                                    </Form.Group>
                                                    <Form.Group as={Row} controlId="formPlaintextEmail">
                                                        <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                        DATE OF BIRTH
                                                        </Form.Label>
                                                        <Col sm="1">
                                                            <DatePicker
                                                                ref='dateOfBirth'
                                                                selected={this.state.dateOfBirth}
                                                                onChange={this.onChangeDateOfBirth}
                                                                minDate={this.minDate}
                                                                value={this.props.dateOfBirth}
                                                                dateFormat={"MM/dd/yyyy"}
                                                                className="form-control"
                                                            />
                                                        </Col>
                                                        <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                                        AGE
                                                        </Form.Label>
                                                        <Col sm="1">
                                                            <Form.Control 
                                                                type="text" 
                                                                autoComplete="off" 
                                                                name="age"
                                                                value={this.state.age}
                                                                readOnly
                                                                onChange={this.onChangeAge}
                                                            />
                                                        </Col>
                                                        <Col sm="2">
                                                        </Col>
                                                        <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                        NATIONALITY
                                                        </Form.Label>
                                                        <Col sm="3">
                                                            <Typeahead
                                                                labelKey='name'
                                                                id="basic-example"
                                                                onChange={this.handleChangeNationality}
                                                                options={this.state.nationalityDDL}
                                                                type="text" 
                                                                defaultSelected={[this.state.profiledata.nationality]}
                                                            /> 
                                                        </Col>
                                                    </Form.Group>
                                                    <Form.Group as={Row} controlId="formPlaintextEmail">
                                                        <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                        MOBILE NUMBER
                                                        </Form.Label>
                                                        <Col sm="3">
                                                            {/* <Form.Control 
                                                                type="text" 
                                                                autoComplete="off" 
                                                                name="mobileNumber"
                                                                value={this.state.mobileNumber}
                                                                onChange={this.onChangeMobileNumber}
                                                            /> */}

                                                            <NumberFormat
                                                                name="mobileNumber"
                                                                value={this.state.mobileNumber}
                                                                onChange={this.onChangeMobileNumber} 
                                                                autoComplete="off"
                                                                format="####-###-####"
                                                                className="form-control"
                                                            />
                                                        </Col>
                                                        <Col sm="2">
                                                        </Col>
                                                        <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                         HOME PHONE NUMBER
                                                        </Form.Label>
                                                        <Col sm="3">
                                                            {/* <Form.Control 
                                                                type="text" 
                                                                autoComplete="off" 
                                                                name="homePhoneNumber"
                                                                value={this.state.homePhoneNumber}
                                                                onChange={this.onChangeHomePhoneNumber}
                                                            /> */}

                                                            <NumberFormat
                                                                name="homePhoneNumber"
                                                                value={this.state.homePhoneNumber}
                                                                onChange={this.onChangeHomePhoneNumber} 
                                                                autoComplete="off"
                                                                format="####-####"
                                                                className="form-control"
                                                            />
                                                        </Col>
                                                    </Form.Group>
                                                    <Form.Group as={Row} controlId="formPlaintextEmail">
                                                        <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                        RELIGION
                                                        </Form.Label>
                                                        <Col sm="3">
                                                            <Typeahead
                                                                labelKey='name'
                                                                id='basic-example'
                                                                onChange={this.handleChangeReligion}
                                                                options={this.state.religionDDL}
                                                                defaultSelected={[this.state.profiledata.religion]}
                                                            />
                                                        </Col>
                                                        <Col sm="2">
                                                        </Col>
                                                        <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                        EMAIL ADDRESS
                                                        </Form.Label>
                                                        <Col sm="3">
                                                            <Form.Control 
                                                                type="text" 
                                                                autoComplete="off" 
                                                                name="emailAddress"
                                                                value={this.state.emailAddress}
                                                                onChange={this.onChangeEmailAddress}
                                                            />
                                                        </Col>
                                                    </Form.Group>
                                                    <Form.Group as={Row} controlId="formPlaintextEmail">
                                                        <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                        FACEBOOK
                                                        </Form.Label>
                                                        <Col sm="3">
                                                            <Form.Control 
                                                                type="text" 
                                                                autoComplete="off" 
                                                                name="facebook"
                                                                value={this.state.facebook}
                                                                onChange={this.onChangeFacebook}
                                                            />
                                                        </Col>
                                                        <Col sm="2">
                                                        </Col>
                                                        <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                        TWITTER
                                                        </Form.Label>
                                                        <Col sm="3">
                                                            <Form.Control 
                                                                type="text" 
                                                                autoComplete="off" 
                                                                name="twitter"
                                                                value={this.state.twitter}
                                                                onChange={this.onChangeTwitter}
                                                            />
                                                        </Col>
                                                    </Form.Group>
                                                    <Form.Group as={Row} controlId="formPlaintextEmail">
                                                        <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                        SSS #
                                                        </Form.Label>
                                                        <Col sm="3">
                                                            <NumberFormat
                                                                name="sss"
                                                                value={this.state.sss}
                                                                onChange={this.onChangeSSS} 
                                                                autoComplete="off"
                                                                format="##-#######-#"
                                                                className="form-control"
                                                            />
                                                        </Col>
                                                        <Col sm="2">
                                                        </Col>
                                                        <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                        TIN #
                                                        </Form.Label>
                                                        <Col sm="3">
                                                            <NumberFormat
                                                                name="tin"
                                                                value={this.state.tin}
                                                                onChange={this.onChangeTin} 
                                                                autoComplete="off"
                                                                format="###-###-###-###"
                                                                className="form-control"
                                                            />
                                                        </Col>
                                                    </Form.Group>
                                                    <Form.Group as={Row} controlId="formPlaintextEmail">
                                                        <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                        PHILHEALTH #
                                                        </Form.Label>
                                                        <Col sm="3">
                                                            <NumberFormat
                                                                name="philhealth"
                                                                value={this.state.philhealth}
                                                                onChange={this.onChangePhilHealth} 
                                                                autoComplete="off"
                                                                format="##-#########-#"
                                                                className="form-control"
                                                            />
                                                        </Col>
                                                        <Col sm="2">
                                                        </Col>
                                                        <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                        PAG-IBIG #
                                                        </Form.Label>
                                                        <Col sm="3">
                                                            <NumberFormat
                                                                name="pagibig"
                                                                value={this.state.pagibig}
                                                                onChange={this.onChangePagibig} 
                                                                autoComplete="off"
                                                                format="####-####-####"
                                                                className="form-control"
                                                            />
                                                        </Col>
                                                    </Form.Group>
                                                    <Form.Group as={Row} controlId="formPlaintextEmail">
                                                        <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                        LIVING ARRANGEMENT
                                                        </Form.Label>
                                                        <Col sm="3">
                                                            <Typeahead
                                                                labelKey='name'
                                                                id="basic-example"
                                                                onChange={this.handleCoverLivingArrangement}
                                                                options={this.state.livingArrangeDDL}
                                                                type="text" 
                                                                defaultSelected={[this.state.profiledata.livingArrangement]}
                                                            /> 
                                                        </Col>
                                                        <Col sm="2">
                                                        </Col>
                                                        <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                        BLOOD TYPE
                                                        </Form.Label>
                                                        <Col sm="3">
                                                            <Typeahead
                                                                labelKey='name'
                                                                id="basic-example"
                                                                onChange={this.handleCoverBloodType}
                                                                options={this.state.bloodTypeDDL}
                                                                type="text" 
                                                                defaultSelected={[this.state.profiledata.bloodType]}
                                                            /> 
                                                        </Col>
                                                    </Form.Group>
                                                    <Form.Group as={Row} controlId="formPlaintextEmail">
                                                        <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                        HEIGHT
                                                        </Form.Label>
                                                        <Col sm="3">
                                                            <Form.Control 
                                                                type="text" 
                                                                autoComplete="off" 
                                                                name="height"
                                                                value={this.state.height}
                                                                onChange={this.onChangeHeight}
                                                            />
                                                        </Col>
                                                        <Col sm="2">
                                                        </Col>
                                                        <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                        WEIGHT
                                                        </Form.Label>
                                                        <Col sm="3">
                                                            <Form.Control 
                                                                type="text" 
                                                                autoComplete="off" 
                                                                name="weight"
                                                                value={this.state.weight}
                                                                onChange={this.onChangeWeight}
                                                            />
                                                        </Col>
                                                    </Form.Group>
                                                    <Form.Group as={Row} controlId="formPlaintextEmail">
                                                        <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                        HOBBIES
                                                        </Form.Label>
                                                        <Col sm="3">
                                                            <Form.Control 
                                                                type="text" 
                                                                autoComplete="off" 
                                                                name="hobbies"
                                                                value={this.state.hobbies}
                                                                onChange={this.onChangeHobbies}
                                                            />
                                                        </Col>
                                                        <Col sm="2">
                                                        </Col>
                                                        <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                        LANGUAGE SPOKEN
                                                        </Form.Label>
                                                        <Col sm="3">
                                                            <Form.Control 
                                                                type="text" 
                                                                autoComplete="off" 
                                                                name="languageSpoken"
                                                                value={this.state.languageSpoken}
                                                                onChange={this.onChangeLanguageSpoken}
                                                            />
                                                        </Col>
                                                    </Form.Group>
                                                    <Form.Group as={Row} controlId="formPlaintextEmail">
                                                        <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                        EMERGENCY CONTACT NUMBER
                                                        </Form.Label>
                                                        <Col sm="3">
                                                            <Form.Control 
                                                                type="text" 
                                                                autoComplete="off" 
                                                                name="emergencyContactName"
                                                                value={this.state.emergencyContactName}
                                                                onChange={this.onChangeEmergencyContactName}
                                                            />
                                                        </Col>
                                                        <Col sm="2">
                                                        </Col>
                                                        <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                        RELATIONSHIP
                                                        </Form.Label>
                                                        <Col sm="3">
                                                            <Form.Control 
                                                                type="text" 
                                                                autoComplete="off" 
                                                                name="emergencyContactRelationship"
                                                                value={this.state.emergencyContactRelationship}
                                                                onChange={this.onChangeRelationship}
                                                            />
                                                        </Col>
                                                    </Form.Group>
                                                    <Form.Group as={Row} controlId="formPlaintextEmail">
                                                        <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                        CONTACT NUMBER
                                                        </Form.Label>
                                                        <Col sm="3">
                                                            <Form.Control 
                                                                type="text" 
                                                                autoComplete="off" 
                                                                name="emergencyContactNumber"
                                                                value={this.state.emergencyContactNumber}
                                                                onChange={this.onChangeContactNumber}
                                                            />
                                                        </Col>
                                                        <Col sm="2">
                                                        </Col>
                                                        <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                        USER FULL NAME
                                                        </Form.Label>
                                                        <Col sm="3">
                                                            <Form.Control 
                                                                type="text" 
                                                                autoComplete="off" 
                                                                name="userFullName"
                                                                value={this.state.userFullName}
                                                                //onChange={this.onChangeUserFullName}
                                                            />
                                                        </Col>
                                                    </Form.Group>
                                                    <Form.Group as={Row} controlId="formPlaintextEmail">
                                                        <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                        MANAGER NAME
                                                        </Form.Label>
                                                        <Col sm="10">
                                                            <Form.Control 
                                                                type="text" 
                                                                autoComplete="off" 
                                                                name="managerName"
                                                                value={this.state.managerName}
                                                                //onChange={this.onChangeManagerName}
                                                            />
                                                        </Col>
                                                    </Form.Group>

                                                    <div>
                                                        <Form.Row className="mt-5">
                                                            <Form.Group as={Col} sm={12} controlId="formGridPassword">
                                                                <Button style={{minWidth:'60px'}}  variant="success" onClick={this.addAddress}>Add Row</Button>
                                                            </Form.Group>
                                                        </Form.Row>
                                                        <Card.Header>Address</Card.Header>
                                                            <BootstrapTable
                                                            rowClasses="noser-table-row-class"
                                                            striped
                                                            hover
                                                            condensed
                                                            //pagination={ paginationFactory({sizePerPageRenderer}) }
                                                            keyField = "id"
                                                            data = { this.state.addressListGrid }
                                                            columns = { addressColumn}
                                                            selectRow = { addressSelectRow }
                                                            cellEdit = { cellEditFactory({
                                                            mode: 'dbclick',
                                                            blurToSave: true,
                                                            afterSaveCell: (oldValue, newValue, row, column) => {
                                                                this.AddressGridDataModified(oldValue, newValue, row.id, column.dataField)
                                                                }
                                                            })
                                                                }
                                                            rowEvents={ addressRowEvents }

                                                        /> 
                                                    </div>

                                                    <div>
                                                        <Card.Header  className="mt-5">Company Relative</Card.Header>
                                                        <BootstrapTable
                                                            rowClasses="noser-table-row-class"
                                                            striped
                                                            hover
                                                            condensed
                                                            pagination={ paginationFactory({sizePerPageRenderer}) }
                                                            keyField = "id"
                                                            data = { this.state.relativeListGrid }
                                                            columns = { relativeColumn}
                                                            selectRow = { relativeSelectRow }
                                                            cellEdit = { 
                                                                cellEditFactory({
                                                                    mode: 'dbclick',
                                                                    blurToSave: true,
                                                                    afterSaveCell: (oldValue, newValue, row, column) => {
                                                                        this.CompanyRelativeGridDataModified(oldValue, newValue, row.id, column.dataField)
                                                                    }
                                                                })
                                                            }
                                                            rowEvents={ relativeRowEvents }

                                                        /> 
                                                    </div>
                                                </Card.Body>
                                            </Card>
                                        </Tab>
                                   
                                        <Tab eventKey="family" title="II. FAMILY BACKGROUND">
                                            <Form.Row>
                                                <Form.Group controlId="formGridPassword" as={Col}>
                                                    <Card className="card-tab-no-border">
                                                        <Card.Body>
                                                            <BootstrapTable
                                                                rowClasses="noser-table-row-class"
                                                                striped
                                                                hover
                                                                condensed
                                                                pagination={ paginationFactory({sizePerPageRenderer}) }
                                                                noDataIndication={ () => <div style={{width:'100%'}}>No record found.</div> }
                                                                keyField = "id"
                                                                data = { this.state.backgroundListGrid }
                                                                columns = { backgroundColumn}
                                                                selectRow = { backgroundSelectRow }
                                                                cellEdit = { cellEditFactory({
                                                                mode: 'dbclick',
                                                                blurToSave: true,
                                                                afterSaveCell: (oldValue, newValue, row, column) => {
                                                                    this.FamilyGridDataModified(oldValue, newValue, row.id, column.dataField)
                                                                    }
                                                                })
                                                                    }
                                                                rowEvents={ backgroundRowEvents }

                                                            />
                                                        </Card.Body>
                                                    </Card>
                                                </Form.Group>
                                            </Form.Row>
                                        </Tab>
                                    

                                        <Tab eventKey="education" title="III. EDUCATION">
                                            <Form.Row>
                                                <Form.Group controlId="formGridPassword" as={Col}>
                                                    <Card className="card-tab-no-border">
                                                        <Card.Body>
                                                            <Card.Header  className="mt-5">Education</Card.Header>
                                                            <BootstrapTable
                                                                rowClasses="noser-table-row-class"
                                                                striped
                                                                hover
                                                                condensed
                                                                pagination={ paginationFactory({sizePerPageRenderer}) }
                                                                noDataIndication={ () => <div style={{width:'100%'}}>No record found.</div> }
                                                                keyField = "id"
                                                                data = { this.state.educationListGrid }
                                                                columns = { educationColumn}
                                                                selectRow = { educationSelectRow }
                                                                cellEdit = { cellEditFactory({
                                                                mode: 'dbclick',
                                                                blurToSave: true,
                                                                afterSaveCell: (oldValue, newValue, row, column) => {
                                                                    this.EducationGridDataModified(oldValue, newValue, row.id, column.dataField)
                                                                    }
                                                                })
                                                                    }
                                                                rowEvents={ educationRowEvents }

                                                            /> 
                                                        </Card.Body>
                                                    </Card>
                                                </Form.Group>
                                            </Form.Row>
                                        </Tab>

                                        <Tab eventKey="application" title="IV. APPLICATION RECORD">
                                            <Form.Row>
                                                <Form.Group controlId="formGridPassword" as={Col}>
                                                    <Card className="card-tab-no-border">
                                                        <Card.Body>
                                                            <Card.Header  className="mt-5">Application Record</Card.Header>
                                                            <BootstrapTable
                                                                rowClasses="noser-table-row-class"
                                                                striped
                                                                hover
                                                                condensed
                                                                pagination={ paginationFactory({sizePerPageRenderer}) }
                                                                noDataIndication={ () => <div style={{width:'100%'}}>No record found.</div> }
                                                                keyField = "id"
                                                                data = { this.state.applicationListGrid }
                                                                columns = { applicationColumn}
                                                                selectRow = { applicationSelectRow }
                                                                cellEdit = { cellEditFactory({
                                                                mode: 'dbclick',
                                                                blurToSave: true,
                                                                afterSaveCell: (oldValue, newValue, row, column) => {
                                                                    this.ApplicationGridDataModified(oldValue, newValue, row.id, column.dataField)
                                                                    }
                                                                })
                                                                    }
                                                                rowEvents={ applicationRowEvents }

                                                            /> 
                                                        </Card.Body>
                                                    </Card>
                                                </Form.Group>
                                            </Form.Row>
                                        </Tab>
                                        
                                        <Tab eventKey="employment" title="V. EMPLOYMENT RECORD">
                                            <Form.Row>
                                                <Form.Group controlId="formGridPassword" as={Col}>
                                                    <Card className="card-tab-no-border">
                                                        <Card.Body>
                                                            <Card.Header  className="mt-5">Employment History</Card.Header>
                                                            <BootstrapTable
                                                                rowClasses="noser-table-row-class"
                                                                striped
                                                                hover
                                                                condensed
                                                                pagination={ paginationFactory({sizePerPageRenderer}) }
                                                                noDataIndication={ () => <div style={{width:'100%'}}>No record found.</div> }
                                                                keyField = "id"
                                                                data = { this.state.employmentListGrid }
                                                                columns = { employmentColumn}
                                                                selectRow = { employmentSelectRow }
                                                                cellEdit = { cellEditFactory({
                                                                mode: 'dbclick',
                                                                blurToSave: true,
                                                                afterSaveCell: (oldValue, newValue, row, column) => {
                                                                    this.EmploymentGridDataModified(oldValue, newValue, row.id, column.dataField)
                                                                    }
                                                                })
                                                                    }
                                                                rowEvents={ employmentRowEvents }

                                                            /> 
                                                        </Card.Body>
                                                    </Card>
                                                </Form.Group>
                                            </Form.Row>
                                        </Tab>

                                        <Tab eventKey="documents" title="VI. DOCUMENTS">
                                            <Form.Row>
                                                <Form.Group controlId="formGridPassword" as={Col}>
                                                    <Card className="card-tab-no-border">
                                                        <Card.Body>
                                                            {/* <Card.Header  className="mt-5">Education</Card.Header>
                                                            <BootstrapTable
                                                                rowClasses="noser-table-row-class"
                                                                striped
                                                                hover
                                                                condensed
                                                                pagination={ paginationFactory({sizePerPageRenderer}) }
                                            noDataIndication={ () => <div style={{width:'100%'}}>No record found.</div> }
                                                                keyField = "id"
                                                                data = { this.state.documentsListGrid }
                                                                columns = { documentsColumn}
                                                                selectRow = { documentsSelectRow }
                                                                cellEdit = { cellEditFactory({
                                                                mode: 'dbclick',
                                                                blurToSave: true,
                                                                afterSaveCell: (oldValue, newValue, row, column) => {
                                                                    this.DocumentsGridDataModified(oldValue, newValue, row.id, column.dataField)
                                                                    }
                                                                })
                                                                    }
                                                                rowEvents={ documentsRowEvents }

                                                            />  */}
                                                        </Card.Body>
                                                    </Card>
                                                </Form.Group>
                                            </Form.Row>
                                        </Tab>

                                        <Tab eventKey="trainings" title="VII. TRAININGS ATTENDED">
                                            <Form.Row>
                                                <Form.Group controlId="formGridPassword" as={Col}>
                                                    <Card className="card-tab-no-border">
                                                        <Card.Body>
                                                            <Card.Header  className="mt-5">Training</Card.Header>
                                                            <BootstrapTable
                                                                rowClasses="noser-table-row-class"
                                                                striped
                                                                hover
                                                                condensed
                                                                pagination={ paginationFactory({sizePerPageRenderer}) }
                                                                noDataIndication={ () => <div style={{width:'100%'}}>No record found.</div> }
                                                                keyField = "id"
                                                                data = { this.state.trainingsListGrid }
                                                                columns = { trainingsColumn}
                                                                selectRow = { trainingsSelectRow }
                                                                cellEdit = { cellEditFactory({
                                                                mode: 'dbclick',
                                                                blurToSave: true,
                                                                afterSaveCell: (oldValue, newValue, row, column) => {
                                                                    this.TrainingsGridDataModified(oldValue, newValue, row.id, column.dataField)
                                                                    }
                                                                })
                                                                    }
                                                                rowEvents={ trainingsRowEvents }

                                                            /> 
                                                        </Card.Body>
                                                    </Card>
                                                </Form.Group>
                                            </Form.Row>
                                        </Tab>
                                    
                                    
                                    </Tabs>
                                    
                                    <ButtonToolbar className="mt-3">
                                        <Button variant="success" className="ml-auto" onClick = { this.handleSaveClick }>
                                            Save
                                        </Button>&nbsp;&nbsp;
                                        {/* <Button variant="success" onClick={this.handleSubmitClick}>
                                            Submit
                                        </Button>&nbsp;&nbsp; */}
                                        <Button variant="danger" href="/profile">
                                            Back
                                        </Button>
                                    </ButtonToolbar>
                                </Form>
                            </Card.Body>
                        </Card>
                    </Container>
                <NoserLoading show={this.state.isloading} />
            </div> 
        )
    }

}

export  default ProfileEdit;