import {
    React,Component,BootstrapTable,
    paginationFactory,Button, ButtonToolbar, Card, 
    Form, Col, Row, axios, Container, Banner, Typeahead, TimePicker,
    cellEditFactory, Type, moment, NavLink, Alert, AppConfiguration,
    NoserLoading, Noser, sizePerPageRenderer, Modal, DatePicker, NumberFormat
} 
from '../../noser-hris-component';
import DataGrid, {Column,Editing,Paging,Selection,Sorting,Export,Summary,TotalItem, MasterDetail} from 'devextreme-react/data-grid';
class SettlementModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userinfo        :   [],
            isloading       :   false,
            isshow          :   false,
            alerttype       :   "",
            message         :   "",
            color           :   "",
            fade            :   true,
            disable         :   false,    
            
            clientName      :   "",
            employeeName    :   "",
            employeeNo      :   "",
            dateofBirth     :   "",
            sssNumber       :   "",
            tinNumber       :   "",
            phicNumber      :   "",
            hdmfNumber      :   "",
            salaryRate      :   "",
            dateHired       :   "",
            dateStart       :   "",
            dateEnd         :   "",
            mobileNumber    :   "",

            employeeId      :   "",
            profileId       :   "",
            toEmployeeId    :   "",
            toProfileId     :   "",
            isHistory       :   true,
            isAddress       :   false,
            isBackground    :   false,
            isEducation     :   false,
            clearanceData    :   []
        }
    }

    componentDidMount(){
        this.props.onRefModal(this)
    };
    componentWillUnmount() {
        this.props.onRefModal(undefined)
    };
    initialize=(e)=>{
        // console.log(e.filter(x=>x.reasonForLeaving==="DOLE CASES"))
        // console.log("e")
        // console.log(e)
        this.setState({
            userinfo        :   JSON.parse(sessionStorage.getItem("userData")),
            isloading       :   false,
            isshow          :   false,
            alerttype       :   "",
            message         :   "",
            color           :   "",
            fade            :   true, 
            disable         :   false,
            clearanceData    :   e.filter(x=>x.reasonForLeaving==="DOLE CASES"),
            selectedRowKeys : [],

        })
    };

    GridDataModified(oldValue, newValue, id, column,) {
        let disable = true
        this.state.clearanceData.forEach(item => {
            if (item.id===id){
                item.isModified = newValue!=oldValue ? "1" : "0"
            }
        })
    };
    handleSubmitClick = async() => {
        this.setState({isloading:true})
        
        let _userId = this.state.userinfo.userId
        let date = (this.state.datenow) ? moment(this.state.datenow).format('MM/DD/YYYY') : ""
        let arrLst = []
        let _fltrAttchment = this.state.clearanceData.filter(f => this.state.selectedRowKeys.some(item => item === f.employeeNo))
        // console.log("_fltrAttchment")
        // console.log(_fltrAttchment)

        if (_fltrAttchment.length === 0) {
            this.setState({
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :    "Please select atleast 1 to process.",
                fade            :   true
            })
            return
        }
        _fltrAttchment.map(function(itm,i){

            arrLst.push({
                "Id" : itm.id,
                "ClientId":itm.clientId,
                "ProfileId":itm.profileId,
                "EmployeeId":itm.employeeId,
                "PayPeriodId":itm.payPeriodId,
                "DateCreated":(itm.dateCreated) ? moment(itm.dateCreated).format('MM/DD/YYYY') : "",
                "ClearanceDateMember":(itm.clearanceDateMember) ? moment(itm.clearanceDateMember).format('MM/DD/YYYY') : "",
                "ResignationDateMember":(itm.resignationDateMember) ? moment(itm.resignationDateMember).format('MM/DD/YYYY') : "",
                "SubmissionDateMember":(itm.submissionDateMember) ? moment(itm.submissionDateMember).format('MM/DD/YYYY') : "",
                "LastWorkingDateMember":(itm.lastWorkingDateMember) ? moment(itm.lastWorkingDateMember).format('MM/DD/YYYY') : "",
                "DateSubmitted":(itm.dateSubmitted) ? moment(itm.dateSubmitted).format('MM/DD/YYYY') : "",
                "IsSSSNumberMember":itm.isSSSNumberMember,
                "IsTINNumberMember":itm.isTINNumberMember,
                "IsPHICNumberMember":itm.isPHICNumberMember,
                "IsHDMFNumberMember":itm.isHDMFNumberMember,
                "IsCashCardMember":itm.isCashCardMember,
                "IsValidIdMember":itm.isValidIdMember,
                "IsResignationLetterMember":itm.isResignationLetterMember,
                "IsTurnoverChecklistMember":itm.isTurnoverChecklistMember,
                "IsCOERequestFormMember":itm.isCOERequestFormMember,
                "IsTypeOfCashCardMember":itm.isTypeOfCashCardMember, // cash card mode of payment
                "PaycardTypeIdMember":itm.paycardTypeIdMember,
                "TypeOfCashCardMember":itm.typeOfCashCardMember,
                "CashCardNumberMember":itm.cashCardNumberMember,
                "IsCheckMember":itm.isCheckMember,
                "IsMlhuillierMember":itm.isMlhuillierMember,
                "IsPalawanMember":itm.isPalawanMember,
                "RemarksMember":itm.remarksMember,
                "NotesMember":itm.notesMember,
                "ReasonForLeaving":itm.reasonForLeaving,
                "DateForwardedToAmt": itm.dateForwardedToAmt,
                "DateReceivedFromAmt": itm.dateReceivedFromAmt,
                "IsEligible":itm.isEligible,
                "IsNotEligible":itm.isNotEligible,
                "SssNumber": itm.sssNumber,
                "TinNumber": itm.tinNumber,
                "PhicNumber": itm.phicNumber,
                "HdmfNumber": itm.hdmfNumber,
                "IsClearedClient":itm.isClearedClient,
                "IsEligibleClient":itm.isEligibleClient,
                "IsNonEligibleClient":itm.isNonEligibleClient,
                "RemarksClient":itm.remarksClient,

                "IsClearedRmt":itm.isNonEligibleClient,
                "IsReturnedRmt":itm.isNonEligibleClient,
                "ValidateByRmt":itm.isNonEligibleClient,
                "IsForwardToARERmt":itm.isNonEligibleClient ,
                "IsRetrunedServicesRmt":itm.isNonEligibleClient,
                "IsCheckedByRmt":itm.isCheckedByRmt,
                "RemarksRmt":itm.remarksRmt,

                "RemarksLrd":itm.remarksLrd,
                "ServicesRemarks":itm.servicesRemarks ,

                "IsClearanceStatus": itm.isClearanceStatus,
                "clearanceDetails" :itm.clearanceDetails,
                    
                /* ADDITIONAL */
                "DateUpdatedByClient": date,
                "UpdatedByClient":_userId,

                "DateUpdatedByLRD":date,
                "UpdatedByLRD":_userId,

                "DateUpdatedByRMT":date,
                "UpdatedByRMT":_userId,

                "DateUpdatedByServices": _userId === "89657" ? date : "",
                "UpdatedByServices":_userId,

                "DateUpdatedByARE":date,
                "UpdatedByARE":_userId,

                "DateUpdatedByFinance":date,
                "UpdatedByFinance":_userId,
                "DateSubmittedByStaff":date,
                "SubmittedByStaff": _userId,
                "DateSubmittedByLead":date,
                "SubmittedByLead":_userId,

                "DateReturnedByClient":itm.dateReturnedByClient,
                "ReturnedByClient":_userId,
                "DateReturnedByLRD":itm.dateReturnedByLRD,
                "ReturnedByLRD":_userId,
                "DateReturnedByRMT":itm.dateReturnedByRMT,
                "ReturnedByRMT":_userId,
                "DateReturnedByServices":itm.dateReturnedByServices,
                "ReturnedByServices":_userId,
                "DateReturnedByARE":itm.dateReturnedByARE,
                "ReturnedByARE":_userId,
                "DateReturnedByFinance":itm.dateReturnedByFinance,
                "ReturnedByFinance":_userId,
                
                "DateSettle"        : itm.dateSettle,
                "IsSettleWithDole"  :  itm.isSettleWithDole,

                "DateReceivedByAdmin" : itm.dateReceivedByAdmin,
                "IsCash" : itm.isCash,
                "BatchNumber" : itm.batchNumber,
                "servicesAttachments" : itm.servicesAttachments,
                "clearanceDeductions":itm.clearanceDeductions,

                
                // 21/07/2023
                "ParamountId" : itm.paramountId === "" ? "0" : itm.paramountId,
                "LastSalary" : itm.lastSalary,
                "Incentives" : itm.incentives,
                "Sil" : itm.sil,
                "ThirteenthMonth" : itm.thirteenthMonth,
                "Inclusions" : itm.inclusions,
                "GrossPay" : itm.grossPay,
                "NetPay" : itm.netPay,
                "totalBalance" : itm.totalBalance,
                "PeriodCovered" : itm.periodCovered,
                "IsModifiedByARE" : "0",
                "SeparationPay" : itm.separationPay,
                "ReviewStatus" : "0",
                "DateReturnedByPayroll" : "",
                "AreRemarks" : itm.areRemarks,
                "PayrollRemarks" : itm.payrollRemarks,
                "FinanceRemarks" : itm.financeRemarks,
                "IsClearedSubmit" : "0",
                "IsGenerated" : itm.isGenerated,
                "IsFinalized" : itm.isFinalized,
                "DateResigned" : itm.dateResigned,
                "DateHired" : itm.dateHired,
                'BatchPayroll' : itm.batchPayroll,
                "Adjustment" : itm.adjustment,
                "OtherPayroll" :  itm.otherPayroll,
                "TotalInclusion" : itm.totalInclusion,
                "IsParent" : itm.isParent,
                "IsChild" : itm.isChild,
                "ParentId" : itm.parentId,
                "SettlementFee" : itm.settlementFee,
                /* END */
            
            })
        })
        let param = {
            "IpAddress"             :"0.0.0.0",
            "UserId"                :this.state.userinfo.userId,
            "ClientId"              :this.state.userinfo.clientId,
            "clearance"             :arrLst
        }
        console.log("param")
        console.log(param)

        axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Clearance/EditClearance",  param)
        .then(res => {
            let data = res.data;
            if(res.data.status=="1"){
                this.setState({
                    isloading   :   false,
                    alerttype   :   "Success!",
                    isshow      :   true,
                    color       :   "success",
                    message     :   "Financial Assistance successfully uploaded.",
                    fade        :   true
                });
            }
            else{
                this.setState({
                    isloading   :   false,
                    alerttype   :   "!Error",
                    isshow      :   true,
                    color       :   "danger",
                    message     :   data.message,
                    fade        :   true
                });
            }
            //   this.GetDataGrid();
        })
        .catch(error=>{
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade        :   true
            })
        })
    };
    IsNumeric(evt)
    {
        var theEvent = evt || window.event;
        var key = theEvent.keyCode || theEvent.which;
        key = String.fromCharCode( key );
        var regex = /^\d+(.\d+)?$/;
        if( !regex.test(key) ) {
        theEvent.returnValue = false;
        if(theEvent.preventDefault) theEvent.preventDefault();
        }
        
    };
    IsDecimal(val)
    {
        var regex = /^\d*\.?(?:\d{1,2})?$/;
        return !regex.test(val)
    };
    
    handleSelectedItems =(e)=>{ // select items
        if(e.fullName === 'selectedRowKeys') {
            this.setState({
                selectedRowKeys: e.value
            })
        }
    };
    renderAreaCellHeaderName() {
        return <div style={{ }}>Financial Assistance </div>;
    };
    render() { 
        const breakdownColumn = [
            {
                dataField   : 'batchNumber',
                text        : 'BATCH NUMBER',
                editable : false,
                headerStyle : () => {
                    return { width  : "20%" };
                },
                // footer: '',
            },
            {
                dataField   : 'clientName',
                text        : 'CLIENT',
                editable : false,
                headerStyle : () => {
                    return { width  : "10%" };
                },
                // footer: '',
            },
            {
                dataField   : 'employeeNo',
                text        : 'EMPLOYEE NO.',
                editable : false,
                headerStyle : () => {
                    return { width  : "10%" };
                },
                style:{textAlign:'right'},
                // footer: '',
            },
            {
                dataField   : 'employeeName',
                text        : 'EMPLOYEE NAME',
                editable : false,
                headerStyle : () => {
                    return { width  : "20%" };
                },
                style:{textAlign:'left'},
                // footer: '',
            },
            {
                dataField   : 'reasonForLeaving',
                text        : 'REASON',
                editable : false,
                headerStyle : () => {
                    return { width  : "10%" };
                },
                style:{textAlign:'left'},
                // footer: 'FOR DEDUCTION',
                // footerStyle: {
                //     // textAlign : "right"
                // }
            },
            {
                dataField   : 'settlementFee',
                text        : 'FINANCIAL ASSISTANCE',
                editable : true,
                headerStyle : () => {
                    return { width  : "10%" };
                },
                style:{textAlign:'right'},
                validator: (newValue, row, column)=>{
                    if (this.IsDecimal(newValue)){
                        return {
                            valid: false,
                            message : "Invalid value."
                        }
                    }
                    if(newValue.split(".").length>2){
                        return {
                            valid: false
                        }
                    }
                    return true;
                }
            },
            
        ]
        const selectRow = {
            mode: 'checkbox',
            clickToSelectAndEditCell: true,
            onSelect: (row, isSelect, rowIndex, e) => {
                this.state.clearanceData.map(function(item,i){
                    if(item.id===row.id)
                    {
                        item.isDeleted = isSelect ? "1" : "0"
                        // item.isSelected = isSelect ? "1" : "0"
                    }
                })
                // let SelectdTmp = this.state.clearanceData.filter(x => x.isDeleted === "1")
                // let _forDeduct = SelectdTmp.reduce((total, currentValue) => total + parseFloat(String(currentValue.amountPaid).replace(',','').replace(',','').replace(',','')),0)
                // this.setState({forDeductions : _forDeduct})
            }
        };
        return(
                <>
                <Modal
                    {...this.props}
                    return
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    backdrop="static"
                >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        CLEARANCE &#62;&#62; FINANCIAL ASSISTANCE
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <Container>       
                    <Form>
                        <Alert isOpen={this.state.isshow} color={this.state.color} fade={this.state.fade} className={this.state.isshow ? 'display-block' : 'display-none'}>
                            <div className={this.state.color}></div> 
                            {this.state.message}
                        </Alert>
                        <div className="mt-1" style={{ overflow:"auto"}}>
                            {/* <BootstrapTable
                                keyField = "id"
                                style={{width : '100%'}}
                                data = { this.state.clearanceData }
                                columns = { breakdownColumn }
                                pagination={ paginationFactory({sizePerPageRenderer}) }
                                noDataIndication={ () => <div style={{width:'100%'}}>No record found.</div> }
                                rowClasses="noser-table-row-class"
                                striped
                                hover
                                condensed
                                cellEdit = { cellEditFactory({
                                    mode: 'dbclick',
                                    blurToSave: true,
                                    afterSaveCell: (oldValue, newValue, row, column) => {
                                    this.GridDataModified(oldValue, newValue, row.id, column.dataField)}})
                                }
                                // rowEvents={ rowEvents }
                                selectRow = { selectRow }
                            /> */}
                            <DataGrid
                                // ref={this.dataGridRef}
                                // customizeColumns={this.customizeColumns}
                                // onCellPrepared={this.onCellPrepared}
                                // onFocusedCellChanging={this.onFocusedCellChanging}
                                // onFocusedCellChanged={this.onFocusedCellChanged}
                                dataSource={this.state.clearanceData}
                                columnMinWidth={80}
                                keyExpr="employeeNo"
                                showBorders={true}
                                columnAutoWidth={true}
                                allowColumnResizing={true}
                                showRowLines={true}
                                focusedRowEnabled={true}
                                onOptionChanged={this.handleSelectedItems}
                                onExporting={this.onExporting}
                                noDataText = "No record found."
                                // format = 'currency'

                                // rowCollapsed = {this.rowEventHandler}
                                // allowColumnReordering={true}
                                // onRowClick={this.onRowClick}
                            >
                            <Selection mode='multiple' selectAllMode='allPages' showCheckBoxesMode="always" /> 
                            <Sorting mode="none"/>
                            <Paging defaultPageSize="10" />  
                            <Editing  mode="cell" allowUpdating={true} />                      
                            <Column 
                                dataField = "batchNumber"             
                                // headerCellRender = {this.renderAreaCellHeaderName} 
                                alignment = "left"
                                allowEditing={false}
                            />
                                                                    
                            <Column 
                                dataField = "clientName"             
                                // headerCellRender = {this.renderAreaCellHeaderName} 
                                alignment = "left"
                                allowEditing={false}
                            />
                                                                    
                            <Column 
                                dataField = "employeeNo"             
                                // headerCellRender = {this.renderAreaCellHeaderName} 
                                alignment = "left"
                                allowEditing={false}
                            />
                                                                    
                            <Column 
                                dataField = "employeeName"             
                                // headerCellRender = {this.renderAreaCellHeaderName} 
                                alignment = "left"
                                allowEditing={false}
                            />
                                                                    
                            <Column 
                                dataField = "reasonForLeaving"             
                                // headerCellRender = {this.renderAreaCellHeaderName} 
                                alignment = "left"
                                allowEditing={false}
                            />
                                                                    
                            <Column 
                                dataField = "settlementFee"             
                                headerCellRender = {this.renderAreaCellHeaderName} 
                                alignment = "right"
                                allowEditing={true}
                            />
                            
                            </DataGrid>
                        </div>
                    </Form>
                </Container>
                </Modal.Body>
                <Modal.Footer>
                    <ButtonToolbar >
                        <Button disabled={this.state.disable} variant="success" className="ml-auto  noser-button-mr1" onClick = { this.handleSubmitClick }>SUBMIT</Button>
                        <Button className="noser-button" variant="danger" onClick={this.props.onHide}>Close</Button>
                    </ButtonToolbar>
                </Modal.Footer>
                <NoserLoading show={this.state.isloading} />
            </Modal>
            </>
        );
    }
}
export  default SettlementModal
          