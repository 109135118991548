import {
    React,Component,BootstrapTable, Type,
    paginationFactory,Button, ButtonToolbar, Card,
    Form, Col, Row, Container, axios, Typeahead, Banner, TimePicker,
    cellEditFactory ,moment, NavLink, Alert, AppConfiguration,
    NoserLoading, Noser, sizePerPageRenderer
}
from '../../../noser-hris-component';
import ToolkitProvider, { CSVExport } from 'react-bootstrap-table2-toolkit';
import 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css';

const PayrollSummaryColumn = (data)=>{
    
    if(data.length===0)
    {
        
        return [
            { dataField: 'clientName', text: 'CLIENT',
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'left',whiteSpace:'nowrap',fontSize:'10px' }},
                style:{textAlign:'left',fontSize:'10px',whiteSpace:'nowrap'}
            },
            { dataField: 'branchName', text: 'BRANCH',
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'left',whiteSpace:'nowrap',fontSize:'10px' }},
                style:{textAlign:'left',fontSize:'10px',whiteSpace:'nowrap'}
            },
            { dataField: 'employeeName', text: 'EMPLOYEE',
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'left',whiteSpace:'nowrap',fontSize:'10px' }},
                style:{textAlign:'left',fontSize:'10px',whiteSpace:'nowrap'}
            },
            { dataField: 'workSchedule', text: 'SCHEDULE',
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'left',whiteSpace:'nowrap',fontSize:'10px' }},
                style:{textAlign:'left',fontSize:'10px',whiteSpace:'nowrap'}
            },
            { dataField: 'scheduleDate', text: 'DATE IN',
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',whiteSpace:'nowrap',fontSize:'10px' }},
                style:{textAlign:'center',fontSize:'10px',whiteSpace:'nowrap'}
            },
            { dataField: 'tagType', text: 'TAG TYPE',
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',whiteSpace:'nowrap',fontSize:'10px' }},
                style:{textAlign:'center',fontSize:'10px',whiteSpace:'nowrap'}
            },
            { dataField: 'timeIn', text: 'TIME-IN',
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',whiteSpace:'nowrap',fontSize:'10px' }},
                style:{textAlign:'center',fontSize:'10px',whiteSpace:'nowrap'}
            },

            { dataField: 'firstBreakOut', text: '1st BREAK-OUT',
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',whiteSpace:'nowrap',fontSize:'10px' }},
                style:{textAlign:'center',fontSize:'10px',whiteSpace:'nowrap'}
            },

            { dataField: 'firstBreakIn', text: '1st BREAK-IN',
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',whiteSpace:'nowrap',fontSize:'10px' }},
                style:{textAlign:'center',fontSize:'10px',whiteSpace:'nowrap'}
            },

            { dataField: 'secondBreakOut', text: '2nd BREAK-OUT',
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',whiteSpace:'nowrap',fontSize:'10px' }},
                style:{textAlign:'center',fontSize:'10px',whiteSpace:'nowrap'}
            },

            { dataField: 'secondBreakIn', text: '2nd BREAK-IN',
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',whiteSpace:'nowrap',fontSize:'10px' }},
                style:{textAlign:'center',fontSize:'10px',whiteSpace:'nowrap'}
            },

            { dataField: 'thirdBreakOut', text: '3rd BREAK-OUT',
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',whiteSpace:'nowrap',fontSize:'10px' }},
                style:{textAlign:'center',fontSize:'10px',whiteSpace:'nowrap'}
            },

            { dataField: 'thirdBreakIn', text: '3rd BREAK-IN',
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',whiteSpace:'nowrap',fontSize:'10px' }},
                style:{textAlign:'center',fontSize:'10px',whiteSpace:'nowrap'}
            },

            { dataField: 'timeOut', text: 'TIME-OUT',
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',whiteSpace:'nowrap',fontSize:'10px' }},
                style:{textAlign:'center',fontSize:'10px',whiteSpace:'nowrap'}
            },
            
            { dataField: 'basicPayDays', text: 'MAN-DAYS',
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'basicPayHrs', text: 'MAN-HOURS',
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'}
            },

            // { dataField: 'lateMins', text: 'LATE HRS',
            //     headerStyle: (colum, colIndex) => {
            //         return { textAlign: 'center',width:'9%',fontSize:'10px' }},
            //     style:{textAlign:'right',fontSize:'10px'}
            // },

            // { dataField: 'undertimeMins', text: 'UNDERTIME / HALF DAY MINS',
            //     headerStyle: (colum, colIndex) => {
            //         return { textAlign: 'center',width:'9%',fontSize:'10px' }},
            //     style:{textAlign:'right',fontSize:'10px'}
            // },

            // { dataField: 'absentDays', text: 'ABSENT DAYS',
            //     headerStyle: (colum, colIndex) => {
            //         return { textAlign: 'center',width:'9%',fontSize:'10px' }},
            //     style:{textAlign:'right',fontSize:'10px'}
            // }
            ]
    }
    else
    {
        //console.log(parseFloat(data[data.length-1].seaRate)===0 ? true : false)
        //const sleep = (milliseconds) => {return new Promise(resolve => setTimeout(resolve, milliseconds))}
        //console.log("NDOT")
        //console.log(parseFloat(data[data.length-1].premiuM_NDOT_HOURS)===0)

        console.log("gtotal")
        console.log(parseFloat(data[data.length-1].absentDays))
        return  [
            
            { dataField: 'clientName', text: 'CLIENT',hidden: false,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'left',whiteSpace:'nowrap',fontSize:'10px' }},
                style:{textAlign:'left',fontSize:'10px',whiteSpace:'nowrap'}
            },
            { dataField: 'branchName', text: 'BRANCH',hidden: false,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'left',whiteSpace:'nowrap',fontSize:'10px' }},
                style:{textAlign:'left',fontSize:'10px',whiteSpace:'nowrap'}
            },
            { dataField: 'employeeName', text: 'EMPLOYEE',hidden: false,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'left',whiteSpace:'nowrap',fontSize:'10px' }},
                style:{textAlign:'left',fontSize:'10px',whiteSpace:'nowrap'}
            },
            { dataField: 'workSchedule', text: 'SCHEDULE',hidden: false,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'left',whiteSpace:'nowrap',fontSize:'10px' }},
                style:{textAlign:'left',fontSize:'10px',whiteSpace:'nowrap'}
            },
            { dataField: 'scheduleDate', text: 'DATE IN',hidden: false,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',whiteSpace:'nowrap',fontSize:'10px' }},
                style:{textAlign:'center',fontSize:'10px',whiteSpace:'nowrap'}
            },
            { dataField: 'tagType', text: 'TAG TYPE',hidden: false,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',whiteSpace:'nowrap',fontSize:'10px' }},
                style:{textAlign:'center',fontSize:'10px',whiteSpace:'nowrap'}
            },
            { dataField: 'timeIn', text: 'TIME-IN',hidden: false,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',whiteSpace:'nowrap',fontSize:'10px' }},
                style:{textAlign:'center',fontSize:'10px',whiteSpace:'nowrap'}
            },

            { dataField: 'firstBreakOut', text: '1st BREAK-OUT',hidden: false,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',whiteSpace:'nowrap',fontSize:'10px' }},
                style:{textAlign:'center',fontSize:'10px',whiteSpace:'nowrap'}
            },

            { dataField: 'firstBreakIn', text: '1st BREAK-IN',hidden: false,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',whiteSpace:'nowrap',fontSize:'10px' }},
                style:{textAlign:'center',fontSize:'10px',whiteSpace:'nowrap'}
            },

            { dataField: 'secondBreakOut', text: '2nd BREAK-OUT',hidden: false,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',whiteSpace:'nowrap',fontSize:'10px' }},
                style:{textAlign:'center',fontSize:'10px',whiteSpace:'nowrap'}
            },

            { dataField: 'secondBreakIn', text: '2nd BREAK-IN',hidden: false,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',whiteSpace:'nowrap',fontSize:'10px' }},
                style:{textAlign:'center',fontSize:'10px',whiteSpace:'nowrap'}
            },

            { dataField: 'thirdBreakOut', text: '3rd BREAK-OUT',hidden: false,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',whiteSpace:'nowrap',fontSize:'10px' }},
                style:{textAlign:'center',fontSize:'10px',whiteSpace:'nowrap'}
            },

            { dataField: 'thirdBreakIn', text: '3rd BREAK-IN',hidden: false,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',whiteSpace:'nowrap',fontSize:'10px' }},
                style:{textAlign:'center',fontSize:'10px',whiteSpace:'nowrap'}
            },

            { dataField: 'timeOut', text: 'TIME-OUT',hidden: false,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',whiteSpace:'nowrap',fontSize:'10px' }},
                style:{textAlign:'center',fontSize:'10px',whiteSpace:'nowrap'}
            },
            
            { dataField: 'basicPayDays', text: 'MAN-DAYS',hidden: false,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'basicPayHrs', text: 'MAN-HOURS',hidden: false,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'}
            },

            { dataField: 'lateMins', text: 'LATE HRS',hidden: parseFloat(data[data.length-1].lateMins)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].lateMins)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'center',fontSize:'10px'}
            },

            { dataField: 'undertimeMins', text: 'UNDERTIME / HALF DAY MINS',hidden: parseFloat(data[data.length-1].undertimeMins)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].undertimeMins)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'}
            },

            { dataField: 'absentDays', text: 'ABSENT DAYS',hidden: parseFloat(data[data.length-1].absentDays)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].absentDays)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'}
            },
            

            { dataField: 'premiuM_OT_HOURS', text: 'OT HRS',hidden: true,
                csvExport: parseFloat(data[data.length-1].premiuM_OT_HOURS)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'premiuM_ND_HOURS', text: 'ND HRS',hidden: parseFloat(data[data.length-1].premiuM_ND_HOURS)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].premiuM_ND_HOURS)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'premiuM_NDOT_HOURS', text: 'ND OT HRS',hidden: parseFloat(data[data.length-1].premiuM_NDOT_HOURS)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].premiuM_NDOT_HOURS)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'}
            },
            
            { dataField: 'premiuM_DOD_HOURS', text: 'RESTDAY HRS',hidden: parseFloat(data[data.length-1].premiuM_DOD_HOURS)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].premiuM_DOD_HOURS)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'premiuM_DODOT_HOURS', text: 'REST DAY OT HRS',hidden: parseFloat(data[data.length-1].premiuM_DODOT_HOURS)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].premiuM_DODOT_HOURS)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'premiuM_DODND_HOURS', text: 'REST DAY ND HRS',hidden: parseFloat(data[data.length-1].premiuM_DODND_HOURS)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].premiuM_DODND_HOURS)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'premiuM_DODNDOT_HOURS', text: 'REST DAY NDOT HRS',hidden: parseFloat(data[data.length-1].premiuM_DODNDOT_HOURS)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].premiuM_DODNDOT_HOURS)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'}
            },

            { dataField: 'premiuM_SHOL_HOURS', text: 'SHOL HRS',hidden: parseFloat(data[data.length-1].premiuM_SHOL_HOURS)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].premiuM_SHOL_HOURS)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'premiuM_SHOLOT_HOURS', text: 'SHOL OT HRS',hidden: parseFloat(data[data.length-1].premiuM_SHOLOT_HOURS)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].premiuM_SHOLOT_HOURS)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'premiuM_SHOLND_HOURS', text: 'SHOL ND HRS',hidden: parseFloat(data[data.length-1].premiuM_SHOLND_HOURS)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].premiuM_SHOLND_HOURS)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'premiuM_SHOLNDOT_HOURS', text: 'SHOL NDOT HRS',hidden: parseFloat(data[data.length-1].premiuM_SHOLNDOT_HOURS)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].premiuM_SHOLNDOT_HOURS)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'}
            },

            { dataField: 'premiuM_SHOLDOD_HOURS', text: 'SHOL DOD HRS',hidden: parseFloat(data[data.length-1].premiuM_SHOLDOD_HOURS)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].premiuM_SHOLDOD_HOURS)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'premiuM_SHOLDODOT_HOURS', text: 'SHOL DOD OT HRS',hidden: parseFloat(data[data.length-1].premiuM_SHOLDODOT_HOURS)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].premiuM_SHOLDODOT_HOURS)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'premiuM_SHOLDODND_HOURS', text: 'SHOL DOD ND HRS',hidden: parseFloat(data[data.length-1].premiuM_SHOLDODND_HOURS)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].premiuM_SHOLDODND_HOURS)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'premiuM_SHOLDODNDOT_HOURS', text: 'SHOL DOD NDOT HRS',hidden: parseFloat(data[data.length-1].premiuM_SHOLDODNDOT_HOURS)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].premiuM_SHOLDODNDOT_HOURS)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'}
            },

            { dataField: 'premiuM_LHOL_HOURS', text: 'LHOL HRS',hidden: parseFloat(data[data.length-1].premiuM_LHOL_HOURS)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].premiuM_LHOL_HOURS)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'premiuM_LHOLOT_HOURS', text: 'LHOL OT HRS',hidden: parseFloat(data[data.length-1].premiuM_LHOLOT_HOURS)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].premiuM_LHOLOT_HOURS)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'premiuM_LHOLND_HOURS', text: 'LHOL ND HRS',hidden: parseFloat(data[data.length-1].premiuM_LHOLND_HOURS)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].premiuM_LHOLND_HOURS)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'premiuM_LHOLNDOT_HOURS', text: 'LHOL NDOT HRS',hidden: parseFloat(data[data.length-1].premiuM_LHOLNDOT_HOURS)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].premiuM_LHOLNDOT_HOURS)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'}
            },

            { dataField: 'premiuM_LHOLDOD_HOURS', text: 'LHOL DOD HRS',hidden: parseFloat(data[data.length-1].premiuM_LHOLDOD_HOURS)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].premiuM_LHOLDOD_HOURS)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'premiuM_LHOLDODOT_HOURS', text: 'LHOL DOD OT HRS',hidden: parseFloat(data[data.length-1].premiuM_LHOLDODOT_HOURS)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].premiuM_LHOLDODOT_HOURS)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'premiuM_LHOLDODND_HOURS', text: 'LHOL DOD ND HRS',hidden: parseFloat(data[data.length-1].premiuM_LHOLDODND_HOURS)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].premiuM_LHOLDODND_HOURS)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'premiuM_LHOLDODNDOT_HOURS', text: 'LHOL DOD NDOT HRS',hidden: parseFloat(data[data.length-1].premiuM_LHOLDODNDOT_HOURS)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].premiuM_LHOLDODNDOT_HOURS)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'}
            },
            //START OF DOUBLE LEGAL
            { dataField: 'premiuM_LHOLLHOL_HOURS', text: 'DOUBLE LEGAL HRS',hidden: parseFloat(data[data.length-1].premiuM_LHOLLHOL_HOURS)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].premiuM_LHOLLHOL_HOURS)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'premiuM_LHOLLHOLOT_HOURS', text: 'DOUBLE LEGAL OT HRS',hidden: parseFloat(data[data.length-1].premiuM_LHOLLHOLOT_HOURS)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].premiuM_LHOLLHOLOT_HOURS)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'premiuM_LHOLLHOLND_HOURS', text: 'DOUBLE LEGAL ND HRS',hidden: parseFloat(data[data.length-1].premiuM_LHOLLHOLND_HOURS)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].premiuM_LHOLLHOLND_HOURS)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'premiuM_LHOLLHOLNDOT_HOURS', text: 'DOUBLE LEGAL NDOT HRS',hidden: parseFloat(data[data.length-1].premiuM_LHOLLHOLNDOT_HOURS)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].premiuM_LHOLLHOLNDOT_HOURS)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'}
            },

            { dataField: 'premiuM_LHOLLHOLDOD_HOURS', text: 'DOUBLE LEGAL DOD HRS',hidden: parseFloat(data[data.length-1].premiuM_LHOLLHOLDOD_HOURS)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].premiuM_LHOLLHOLDOD_HOURS)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'premiuM_LHOLLHOLDODOT_HOURS', text: 'DOUBLE LEGAL DOD OT HRS',hidden: parseFloat(data[data.length-1].premiuM_LHOLLHOLDODOT_HOURS)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].premiuM_LHOLLHOLDODOT_HOURS)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'premiuM_LHOLLHOLDODND_HOURS', text: 'DOUBLE LEGAL DOD ND HRS',hidden: parseFloat(data[data.length-1].premiuM_LHOLLHOLDODND_HOURS)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].premiuM_LHOLLHOLDODND_HOURS)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'premiuM_LHOLLHOLDODNDOT_HOURS', text: 'DOUBLE LEGAL DOD NDOT HRS',hidden: parseFloat(data[data.length-1].premiuM_LHOLLHOLDODNDOT_HOURS)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].premiuM_LHOLLHOLDODNDOT_HOURS)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'}
            },
            //END OF DOUBLE LEGAL

            //START OF DOUBLE HOL
            { dataField: 'premiuM_LHOLSHOL_HOURS', text: 'DOUBLE HOL HRS',hidden: parseFloat(data[data.length-1].premiuM_LHOLSHOL_HOURS)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].premiuM_LHOLSHOL_HOURS)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'premiuM_LHOLSHOLOT_HOURS', text: 'DOUBLE HOL OT HRS',hidden: parseFloat(data[data.length-1].premiuM_LHOLSHOLOT_HOURS)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].premiuM_LHOLSHOLOT_HOURS)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'premiuM_LHOLSHOLND_HOURS', text: 'DOUBLE HOL ND HRS',hidden: parseFloat(data[data.length-1].premiuM_LHOLSHOLND_HOURS)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].premiuM_LHOLSHOLND_HOURS)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'premiuM_LHOLSHOLNDOT_HOURS', text: 'DOUBLE HOL NDOT HRS',hidden: parseFloat(data[data.length-1].premiuM_LHOLSHOLNDOT_HOURS)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].premiuM_LHOLSHOLNDOT_HOURS)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'}
            },

            { dataField: 'premiuM_LHOLSHOLDOD_HOURS', text: 'DOUBLE HOL DOD HRS',hidden: parseFloat(data[data.length-1].premiuM_LHOLSHOLDOD_HOURS)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].premiuM_LHOLSHOLDOD_HOURS)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'premiuM_LHOLSHOLDODOT_HOURS', text: 'DOUBLE HOL DOD OT HRS',hidden: parseFloat(data[data.length-1].premiuM_LHOLSHOLDODOT_HOURS)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].premiuM_LHOLSHOLDODOT_HOURS)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'premiuM_LHOLSHOLDODND_HOURS', text: 'DOUBLE HOL DOD ND HRS',hidden: parseFloat(data[data.length-1].premiuM_LHOLSHOLDODND_HOURS)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].premiuM_LHOLSHOLDODND_HOURS)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'premiuM_LHOLSHOLDODNDOT_HOURS', text: 'DOUBLE HOL DOD NDOT HRS',hidden: parseFloat(data[data.length-1].premiuM_LHOLSHOLDODNDOT_HOURS)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].premiuM_LHOLSHOLDODNDOT_HOURS)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'}
            },
            //END OF DOUBLE HOL
            { dataField: 'premiuM_VACATIONLEAVE_HOURS', text: 'VACATION LEAVE DAYS',hidden: parseFloat(data[data.length-1].premiuM_VACATIONLEAVE_HOURS)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].premiuM_VACATIONLEAVE_HOURS)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'premiuM_SICKLEAVE_HOURS', text: 'SICK LEAVE DAYS',hidden: parseFloat(data[data.length-1].premiuM_SICKLEAVE_HOURS)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].premiuM_SICKLEAVE_HOURS)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'premiuM_PATERNITYLEAVE_HOURS', text: 'PATERNITY LEAVE DAYS',hidden: parseFloat(data[data.length-1].premiuM_PATERNITYLEAVE_HOURS)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].premiuM_PATERNITYLEAVE_HOURS)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'}
            },
            { dataField: 'premiuM_MATERNITYLEAVE_HOURS', text: 'MATERNITY LEAVE DAYS',hidden: parseFloat(data[data.length-1].premiuM_MATERNITYLEAVE_HOURS)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].premiuM_MATERNITYLEAVE_HOURS)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'}
            },

            { dataField: 'premiuM_SPLLEAVE_HOURS', text: 'SOLO PARENTAL LEAVE DAYS',hidden: parseFloat(data[data.length-1].premiuM_SPLLEAVE_HOURS)===0 ? true : false,
                csvExport: parseFloat(data[data.length-1].premiuM_SPLLEAVE_HOURS)===0 ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'9%',fontSize:'10px' }},
                style:{textAlign:'right',fontSize:'10px'}
            },
        ]
    }
    
}

const NoserTable = ({ data, exportCSV }) =>
{
    const selectRow = {
        mode: 'checkbox',
        style:{backgroundColor:'yellow', color:'#000',cursor:'pointer'},
        clickToSelect: true,
        hideSelectAll: true
    };
    const rowStyle = (row, rowIndex) => {
        if(row.tagType==="TOTAL")
            return { backgroundColor : 'yellow', color:'#000',textAlign:'right'};
    };
    if(exportCSV==true){
        const col = PayrollSummaryColumn(data)
        const { ExportCSVButton } = CSVExport;
        return(
            <ToolkitProvider
                keyField="id"   
                data={ data }
                columns = { col }
                exportCSV={ {
                    noAutoBOM: false,blobType: "data:text/csv;charset=utf-8,%EF%BB%BF",fileName: "DTRReport.csv",
                } }
                >
                {
                    props => (
                    <div>
                        
                        <hr />
                        <ExportCSVButton disabled={data.length>0 ? false : true} className="btn btn-success" { ...props.csvProps }>Export CSV!!</ExportCSVButton>
                        <Card.Header>DTR Report</Card.Header>
                        <BootstrapTable
                            striped
                            hover
                            condensed
                            pagination={ paginationFactory({sizePerPage:16,hideSizePerPage:true,hidePageListOnlyOnePage:true})}
                            //pagination={ paginationFactory({sizePerPageRenderer})}
                            keyField = "id"
                            data = { data }
                            noDataIndication={ () => <div>No record found.</div> }
                            columns = { col }
                            rowClasses="noser-table-row-class no-checkbox"
                            selectRow = { selectRow }
                            rowStyle={ rowStyle }
                        />
                    </div>
                    )
                }
            </ToolkitProvider>
            
        )
    }
    else
    {
        const col = PayrollSummaryColumn(data)
        return(
            
            <div style={{fontSize:'8px'}}>  
                <Card.Header>DTR Report</Card.Header>  
                <BootstrapTable
                    striped
                    hover
                    condensed
                    //pagination={ paginationFactory({sizePerPageRenderer})}
                    pagination={ paginationFactory({sizePerPage:16,hideSizePerPage:true,hidePageListOnlyOnePage:true})}
                    keyField = "id"
                    data = { data }
                    noDataIndication={ () => <div>No record found.</div> }
                    columns = { col }
                    rowClasses="noser-table-row-class no-checkbox"
                    selectRow = { selectRow }
                    rowStyle={ rowStyle }
                />
            </div>
        )
    }
}

export default class NoserGrid extends React.Component {
    constructor(props) {
        super(props);
    }
    render(){
        return (
            <NoserTable 
                data = {this.props.data}
                exportCSV = {true}
                //pageSize = {this.props.pageSize}
            />
        )
    }
}
