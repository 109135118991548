import {
    React,Component,BootstrapTable,
    paginationFactory,Button, ButtonToolbar, Card, 
    Form, Col, Row, axios, Container, Banner, Typeahead, TimePicker,
    cellEditFactory, Type, moment, NavLink, Alert, AppConfiguration,
    NoserLoading, Noser, sizePerPageRenderer, DatePicker
} 
from '../../../noser-hris-component';
import AddUserControl from './AddUserControl';

class UserControl extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userinfo        :   [],
            isloading       :   false,
            isshow          :   false,
            alerttype       :   "",
            message         :   "",
            color           :   "",
            fade            :   true,
            openModal       :   false,
            
            savebutton      :   true,
            searchbutton    :   true,

            usercontrolGRID :   [],
            employeeDDL     :   [],
            accesscontrolDDL:   [],
            yesnoDDL        :   [
                {value:"1",label:"YES"},
                {value:"0",label:"NO"}
            ],

            employeeId      :   "",
            employeeName    :   "",
            accessCode      :   "",
        }
    } 
    componentDidMount(){
        this.state.userinfo = JSON.parse(sessionStorage.getItem("userData"))
        this.GetEmployees()
    }
    GetEmployees = () =>{
        this.setState({isloading:true})
        const params = {
            "IpAddress":"0.0.0.0",
            "ClientId":"111",
            "UserId":this.state.userinfo.userId
        }
        
        axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Employee/GetConfigurationEmployees", params)
        .then(res => {
            const data = res.data
            console.log("employees")
            console.log(data)
            this.setState({
                isloading   :   false,
                alerttype   :   data.status=="1" ? "Success!" : "Error!",
                isshow      :   data.status=="1" ? false : true,
                color       :   data.status=="1" ? "success" : "danger",
                message     :   data.message,
                fade        :   true,
                employeeDDL :   data.employees.filter(x=>x.isDeleted==="0"),
            });

        })
        .catch(error=>{
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade        :   true,
            })
        })
        this.GetAccessControl()
    }
    GetAccessControl = async() =>{
        const params = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.userinfo.clientId,
            "UserId":this.state.userinfo.userId
        }
        await axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Administrator/GetAccessControls", params)
        .then(res => {
            const data = res.data
            this.setState({accesscontrolDDL:data.accessControls,isloading:false});
            
        })
        .catch(error=>{
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade        :   true,
            })
        })
    }
    
    handleChangeEmployee = (e) =>{
        this.setState({alerttype:"",isshow:false,color:"",message:"",fade:false});
        if(e.length==0)
        {
            this.state.employeeId = ''
            this.state.employeeName = ''
            return
        }
        this.state.employeeId = e[0].id
        this.state.employeeName = e[0].employeeName
    }
    handleChangeAccessCode = (e) =>{
        this.setState({alerttype:"",isshow:false,color:"",message:"",fade:false});
        if(e.length==0)
        {
            this.state.accessCode= ''
            return
        }
        this.state.accessCode = e[0].accessCode
    }
    handleSearch = (e) =>{
        this.setState({isloading:true,isDisable:true})
        
        if(this.state.employeeId===""){
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "Please select employee.",
                fade        :   true
            });
            return
        }
        if(this.state.accessCode===""){
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "Please select feature.",
                fade        :   true
            });
            return
        }
        
        const params = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.userinfo.clientId,
            "UserId":this.state.userinfo.userId,
            "EmployeeId":this.state.employeeId,
            "AccessCode":this.state.accessCode
        }
        
        axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Administrator/GetUserControlAccess", params)
        .then(res => {
            const data = res.data
            console.log(data)
            if(data.status=="1"){
                this.setState({
                    isloading:false,
                    usercontrolGRID:data.userControls
                });
            }
            else{
                this.setState({
                    isloading   :   false,
                    alerttype   :   "Error!",
                    isshow      :   true,
                    color       :   "danger",
                    message     :   data.message,
                    fade        :   true,
                    userList    :   data.users
                });
            }
        })
        .catch(error=>{
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade        :   true,
            })
        })
    }
    handleSubmit = (e) => {
        this.setState({isloading:true,savebutton:true})
        
        if(this.state.usercontrolGRID.length===0){
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "No data to save.",
                fade        :   true
            });
            return
        }

        let data = []
        this.state.usercontrolGRID.forEach(itm=>{
            data.push({
                "EmployeeId":this.state.employeeId,
                "AccessId":itm.accessId,
                "HasView":itm.hasView,
                "HasEdit":itm.hasEdit,
                "controlAccessDetails":itm.controlAccessDetails
            })
        })
        const params = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.userinfo.clientId,
            "UserId":this.state.userinfo.userId,
            "controlAccess":data 
        }
        
        axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Administrator/SaveUserControlAccess", params)
        .then(res => {
            const data = res.data
            if(data.status=="1")
                this.handleSearch(e)
            this.setState({
                isloading   :   false,
                alerttype   :   data.status=="1" ? "Success!" : "Error!",
                isshow      :   true,
                color       :   data.status=="1" ? "success" : "danger",
                message     :   data.message,
                fade        :   true,
                savebutton  :   false
            });

        })
        .catch(error=>{
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade        :   true,
                disableSaveBtn: false
            })
        })
    }
    GridDataModified(oldValue, newValue, rowid, column) {
        
    }
    handleModalShow = (row,isclient) =>{
        this.setState({openModal:true})
        let obj = {
            isClient:isclient,
            accessId: row.id,
            employeeId:this.state.employeeId,
            employeeName:this.state.employeeName,
            controlAccessDetails:row.controlAccessDetails
        }
        this.child.initialize(obj)
    }
    handleModalClose = (e) =>{
        this.handleSearch()
        this.setState({openModal:false})
        
    }
    render(){
        const col = [
            {
                dataField: 'featureName',
                text: 'FEATURE NAME',
                editable: false,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'left',width:'45%' }},
                style:{textAlign:'left',width:'45%'}
            },
            {
                dataField: 'fieldName',
                text: 'FIELD',
                editable: true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'left',width:'15%' }},
                style:{textAlign:'left',width:'15%'},
            },
            {
                dataField: 'hasView',
                text: 'HAS VIEW',
                editable: true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'10%' }},
                style:{textAlign:'center',width:'10%'},
                formatter: (cell, row) => {
                    if(row.hasView!='' && row.hasView!=null){
                        if(this.state.yesnoDDL.filter(x => x.value == cell).length>0){
                            return this.state.yesnoDDL.find(x => x.value == cell).label;
                        }
                    }
                },
                editor: {
                    type: Type.SELECT,
                    options: this.state.yesnoDDL
                }
            },
            {
                dataField: 'hasEdit',
                text: 'HAS EDIT',
                editable: true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'10%' }},
                style:{textAlign:'center',width:'10%'},
                formatter: (cell, row) => {
                    if(row.hasEdit!='' && row.hasEdit!=null){
                        if(this.state.yesnoDDL.filter(x => x.value == cell).length>0){
                            return this.state.yesnoDDL.find(x => x.value == cell).label;
                        }
                    }
                },
                editor: {
                    type: Type.SELECT,
                    options: this.state.yesnoDDL
                }
            },
            {
                text        :   "ACTION",
                editable    :   false,
                dataField   :   "databasePkey",
                formatter   :   (cell, row, isSelect) => {
                    if(row.employeeId!=="0"){
                        return (
                            <Button style={{height:'16px',paddingTop:'0',marginTop:'-3px',fontSize:'11px'}}
                                variant="link" onClick={e => this.handleModalShow(row,'1')}
                            >CLIENT</Button>
                        );
                    }
                },
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'10%'}},
                style:{textAlign:'center',width:'10%'}
            },
            {
                text        :   "ACTION",
                editable    :   false,
                dataField   :   "databasePkey",
                formatter   :   (cell, row, isSelect) => {
                    if(row.employeeId!=="0"){
                        return (
                            <Button style={{height:'16px',paddingTop:'0',marginTop:'-3px',fontSize:'11px'}}
                                variant="link" onClick={e => this.handleModalShow(row,'2')}
                            >POSITION</Button>
                        );
                    }
                },
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'10%'}},
                style:{textAlign:'center',width:'10%'}
            },
        ]
        const colreference = [
            {
                dataField: 'referenceName',
                text: 'NAME',
                editable: false,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'left',width:'100%' }},
                style:{textAlign:'left',width:'100%'}
            },
        ]
        const selectRow = {
            mode: 'checkbox',
            hideSelectAll: true,
            clickToSelectAndEditCell: true,
            onSelect: (row, isSelect, rowIndex, e) => {
                
            }
        };
        const selectRowRef = {
            mode: 'checkbox',
            hideSelectAll: false,
            clickToSelectAndEditCell: true,
            onSelect: (row, isSelect, rowIndex, e) => {
                row.isDeleted = isSelect ? "1" : "0"
                console.log(row)
            },
            onSelectAll: (isSelected, rows) => {
                rows.forEach(x=>{
                    x.isDeleted = isSelected ? "1" : "0"
                })
                console.log(rows)
            }
        };
        const expandRow = {
            onlyOneExpanding: true,
            renderer: row => 
            (
                <BootstrapTable
                    keyField = "id"
                    data = { row.controlAccessDetails }
                    columns = { colreference }
                    rowClasses="noser-table-row-class"
                    striped
                    expandRow
                    selectRow = { selectRowRef }
                    wrapperClasses="table-responsive"
                    noDataIndication={ () => <div>No record found.</div> }
                /> 
            ),
            showExpandColumn: true,
          };
        return(
            <div>
                <Banner />
                <Container className="themed-container" fluid={true}>
                    <Card className="mt-5">
                        <Card.Header> ADMINISTRATOR >> ACCESS CONTROL</Card.Header>
                        <Card.Body>
                            <Form >
                                <Alert isOpen={this.state.isshow} color={this.state.color} fade={this.state.fade} className={this.state.isshow ? 'display-block' : 'display-none'}>
                                    <div className={this.state.color}></div> 
                                    {this.state.message}
                                </Alert>
                                <Form.Row>
                                    <Form.Group as={Col} sm={12} controlId="formHorizontalEmail">
                                        <Typeahead
                                                labelKey='employeeName'
                                                id="basic-example"
                                                onChange={this.handleChangeEmployee}
                                                options={this.state.employeeDDL}
                                                placeholder="Select Employee"
                                            />
                                    </Form.Group>
                                </Form.Row>
                                <Form.Row>
                                    <Form.Group as={Col} sm={12} controlId="formHorizontalEmail">
                                        <Typeahead
                                                labelKey='featureName'
                                                id="basic-example"
                                                onChange={this.handleChangeAccessCode}
                                                options={this.state.accesscontrolDDL}
                                                placeholder="Select Feature"
                                            />
                                    </Form.Group>
                                </Form.Row>
                                <Form.Row>
                                    <Form.Group as={Col} sm={12} controlId="formHorizontalEmail">
                                        <div className="mt-1">
                                            <ButtonToolbar>
                                                <Button style={{minWidth:'60px',marginRight:'1pt'}} className="ml-auto" variant="success" variant="primary" onClick={this.handleSearch}>Search</Button>
                                            </ButtonToolbar>
                                        </div>
                                    </Form.Group>
                                </Form.Row>
                                <Form.Row>
                                    <Form.Group as={Col} sm={12} controlId="formGridEmail">
                                        <Card.Header>LIST OF ACCESS</Card.Header>
                                        <div className="mt-1">
                                            <BootstrapTable
                                                keyField = "id"
                                                data = { this.state.usercontrolGRID }
                                                columns = { col }
                                                selectRow = { selectRow }
                                                cellEdit = { cellEditFactory({ 
                                                        mode: 'dbclick', 
                                                        blurToSave: true,
                                                        afterSaveCell: (oldValue, newValue, row, column) => { 
                                                            this.GridDataModified(oldValue, newValue, row.id, column.dataField)
                                                        }
                                                    })
                                                }
                                                rowClasses="noser-table-row-class no-checkbox"
                                                striped
                                                hover
                                                condensed
                                                loading={true}
                                                wrapperClasses="table-responsive"
                                                pagination={ paginationFactory({sizePerPageRenderer})}
                                                noDataIndication={ () => <div style={{width:'100%'}}>No record found.</div> }
                                                expandRow={ expandRow }
                                            />
                                        </div>
                                        
                                    </Form.Group>
                                </Form.Row>
                                <div className="mt-1">
                                    <ButtonToolbar>
                                        <Button className="ml-auto noser-button-mr1" variant="success" 
                                            variant="success" onClick={this.handleSubmit} 
                                            disabled={this.state.usercontrolGRID.length===0 ? true : false}>Save</Button>
                                    </ButtonToolbar>
                                </div>
                            </Form>
                        </Card.Body>
                    </Card>
                </Container>
                <NoserLoading show={this.state.isloading} />
                <AddUserControl 
                    show={this.state.openModal}
                    onHide={this.handleModalClose}
                    onRefModal={ref => (this.child = ref)}
                />
            </div>
        )
    }
}

export default UserControl;