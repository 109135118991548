import {
    React,Component,BootstrapTable,
    paginationFactory,Button, ButtonToolbar, Card, 
    Form, Col, Row, axios, Container, Banner, Typeahead, TimePicker,
    cellEditFactory, Type, moment, NavLink, Alert, AppConfiguration,
    NoserLoading, Noser, sizePerPageRenderer, DatePicker, Modal
} 
from '../../../noser-hris-component';

class CreateRole extends Component {
    constructor(props) {
        super(props)
        this.module = React.createRef();
        this.submodule = React.createRef();
        this.state = {
            userinfo    :   JSON.parse(sessionStorage.getItem("userData")),
            isloading   :   false,
            isshow      :   false,
            alerttype   :   "",
            message     :   "",
            color       :   "",
            fade        :   true,
            name        :   '',
            description :   ''
        }
    }
    componentDidMount(){
        this.state.userinfo = JSON.parse(sessionStorage.getItem("userData"))
        this.props.onRefModal(this)
    }
    componentWillUnmount() {
        this.props.onRefModal(undefined)
    }
    initialize=(e)=>{
        this.setState({
            userinfo    :   JSON.parse(sessionStorage.getItem("userData")),
            isloading   :   false,
            isshow      :   false,
            alerttype   :   "",
            message     :   "",
            color       :   "",
            fade        :   true,
            name        :   '',
            description :   ''
        })
    }
    handleSubmit = (e) =>{
        this.setState({isloading:true})

        if(this.state.name==""){
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "Please enter role name.",
                fade        :   true
            })
            return 
        }

        if(this.state.description==""){
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "Please enter role description.",
                fade        :   true
            })
            return 
        }
        
        const param = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.userinfo.clientId,
            "UserId":this.state.userinfo.userId,
            "Name":this.state.name,
            "Description":this.state.description,
            "DepartmentId":"0"
        }
        
        axios
            .post(
                AppConfiguration.Setting().noserapiendpoint + "Administrator/AddRole",  param
            )
            .then(res => {
                const data = res.data;
                this.setState({
                    isloading   :   false,
                    alerttype   :   res.data.status=="1" ? "Success!" : "!Error",
                    isshow      :   true,
                    color       :   res.data.status=="1" ? "success" : "danger",
                    message     :   data.message,
                    fade        :   true
                });
            })
            .catch(error=>{
                this.setState({
                    isloading   :   false,
                    alerttype   :   "Error!",
                    isshow      :   true,
                    color       :   "danger",
                    message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                    fade        :   true
                })
            })
    }
    handleModalClose = () => {
        this.props.onHide();            
    }
    handleChange = (e) =>{
        this.setState({[e.target.name]: e.target.value.toUpperCase()})
    }
    handleKeyUp = (e) =>{

    }
    render() {
    return(
        
            <Modal
                {...this.props}
                return
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                backdrop="static"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        CREATE ROLE
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container>
                        <Alert isOpen={this.state.isshow} color={this.state.color} fade={this.state.fade} className={this.state.isshow ? 'display-block' : 'display-none'}>
                            <div className={this.state.color}></div> 
                            {this.state.message}
                        </Alert>
                        <Form.Row>
                            <Form.Group as={Col} sm={12} >
                                <Form.Control 
                                    name='name'
                                    type="text" 
                                    placeholder="Enter Name" 
                                    onChange={this.handleChange} 
                                    autoComplete="off" 
                                    value={this.state.name}
                                    
                                />
                            </Form.Group>
                        </Form.Row>
                        <Form.Row>
                            <Form.Group as={Col} sm={12} >
                                <Form.Control 
                                    name='description'
                                    type="text" 
                                    placeholder="Enter Description" 
                                    onChange={this.handleChange} 
                                    autoComplete="off"
                                    value={this.state.description}
                                />
                            </Form.Group>
                        </Form.Row>
                    </Container>
                </Modal.Body>
                <Modal.Footer>
                    <ButtonToolbar >
                        <Button variant="success" style={{minWidth:'60px',marginRight:'1pt'}} className="ml-auto" onClick = { this.handleSubmit }>Save</Button>
                        <Button variant="danger" style={{minWidth:'60px',marginRight:'15px'}} onClick={this.props.onHide}>Close</Button>
                    </ButtonToolbar>
                </Modal.Footer>
                <NoserLoading show={this.state.isloading} />
        </Modal>
        );
    }
}
export default CreateRole