import {
    React,Component,BootstrapTable,
    paginationFactory,Button, ButtonToolbar, Card, 
    Form, Col, Row, axios, Typeahead, TimePicker,
    cellEditFactory ,moment, NavLink, Alert, AppConfiguration,
    NoserLoading, Noser, Banner, Container, DatePicker,  Tabs, Tab,
    sizePerPageRenderer,Type
} 
from '../../noser-hris-component';
import { GetRequest, PostRequest } from '../../noser-dataaccess'

class PayCardView extends Component {
    constructor(props) {
        super(props)
        // this.minDate = new moment(props.minDate)
        this.state = {
            userinfo        :   [],
            isloading       :   false,
            isshow          :   false,
            alerttype       :   "",
            message         :   "",
            color           :   "",
            fade            :   true,

            startDate        : new Date(),
            selectedClientId :   "",
            selectedClient   :   "",
            selectedEmployeeId   :   '',
            selectedEmployeeName :   '',
            selectedProfileId    : "",
            selectedPosition     : "",
            selectedStatus       : "", 
            newpayCardNumber     : "",  
            oldpayCardNumber     : "",
            value                : "",
            description          : "",
            selectedReason       :"",
            selectedReasonId     :"",
            otherReason          :"",
            

            clientList      : [],
            employeeList    : [],
            natureList      : [],
            reasonList      : [],
            documents       : [],
            DocumentId      : [],
            docArr          : [],
           
            payrollForm     : false,
            loiForm         : false,
            validId         : false,
            affidavit       : false,
            cashCard        : false,
            atd             : false,
            dateTransmit    : "",
            today           : "",
            date            : "",
            
            payCardList     : [],
            reasonDDl       : [],
            docArrayList    : [],
            //data : {},
            documentName : false
        }
        // this.state.assessedBy = this.state.userinfo.fullName
        this.state.userinfo = JSON.parse(sessionStorage.getItem("userData"))
        this.state.data = JSON.parse(sessionStorage.getItem("paycardviepage_" + this.state.userinfo.userId + this.state.userinfo.employeeId))
       
        console.log("this.state.data")
        console.log(this.state.data)
        // this.state.clientName        =   this.state.data.clientName
        // this.state.clientId          =   this.state.data.clientId
        // this.state.employeeName      =   this.state.data.employeeName
        // this.state.locationName      = this.state.data.branchName
        // this.state.employeeNo        = this.state.data.employeeNo
        // this.state.position          = this.state.data.positionName
        // this.state.dateStart         = this.state.data.dateStart
        // this.state.status            = this.state.data.statusProcess
        // this.state.applicationDate   = this.state.data.applicationDate
        // this.state.payCardType       = this.state.data.payCardType
        // this.state.oldpayCardNumber  = this.state.data.oldOayCardNumber
        // this.state.newpayCardNumber  = this.state.data.payCardNumber
        // this.state.nature            = this.state.data.natureName
        // this.state.cardvalue         =  this.state.data.cardValue
        // this.state.reason            = this.state.data.reasonName
        // this.state.otherReason        = this.state.data.description
        // this.state.attachment        = this.state.data.applicationdocuments
        // this.state.remarks           = this.state.data.rEmarks 
        // this.state.statusId          = this.state.data.statusId
        // this.state.createdBy          =this.state.data.createdBy
        // this.state.headerId         = this.state.data.id
       
    }

    componentDidMount(){
        const sleep = (milliseconds) => {return new Promise(resolve => setTimeout(resolve, milliseconds))}
   //this.refreshPage()
    }

  

    getClient() {
        this.setState({isloading:true})
        const clientParams = {
            "IpAddress"     :   "0.0.0.0",
            "ClientId"      :   this.state.userinfo.clientId,
            "UserId"        :   this.state.userinfo.userId,
	        "EmployeeId"    :   ""
       }
       axios
           .post(
               AppConfiguration.Setting().noserapiendpoint + "Client/GetClientList", clientParams
           )
           .then(res => {
               const data = res.data;
               console.log(data)
               this.setState({
                   clientList   :   data.clients, 
                   isloading    :false
                })
           })
   }

    onChangeClientList = (e) => {
        if(e.length == 0) {
            this.setState({isshow:false,selectedClientId:"",selectedClientName:""})
            return
        }
        this.state.selectedClientId=e[0].id
        this.state.selectedClientName=e[0].name
        this.getEmployees();
        this.getClientLocation();
    }

    GetLocation(name) {
        let GetLocationName = ''
        for (let i = 0; i <= this.state.clientLocationList.length; i++) {
            if (this.state.clientLocationList[i]["name"] === name) {
                GetLocationName = this.state.clientLocationList[i]['id']; 
                break;
            }
        }
        return GetLocationName
    }
    onChangeLocation = (e) => {
        if(e.length == 0) {
                this.state.selectedLocationName = ""
                this.state.selectedLocationId = ""
                return
        }  
        this.state.selectedLocationName = e[0].name
        this.state.selectedLocationId = e[0].id
    }

    getClientLocation(){

        const getParams = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.selectedClientId,
            "UserId":this.state.userinfo.userId,
            "ClientName": this.state.selectedClientName,
            "City": "",
            "Province": "",
            "Region": ""
    
        }
        axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Client/GetClientLocations", getParams)
        .then(res => {
            let branches = []
            res.data.locations.map(function(itm){
                branches.push({"value" : itm.id,"label" : itm.name})
            })
            console.log("location")
            console.log(res.data.locations)
            this.setState({clientLocationList : res.data.locations ? res.data.locations : [], locationDDL : branches})
        })
        .catch(error=>{
           this.setState(  {
               isloading       :   false,
               alerttype       :   "Error!",
               isshow          :   true,
               color           :   "danger",
               message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
               fade            :   true
           })
       })
    }

    getEmployees(){
        this.setState({isloading:true})
        const getParams = {
            "IpAddress"     :   "0.0.0.0",
            "ClientId"      :   this.state.selectedClientId,
            "UserId"        :   this.state.userinfo.userId,
            "EmployeeName"  :   "",
            "EmployeeNo"    :   "",
            "ClientName"    :   ""
        }
        axios
        .post( AppConfiguration.Setting().noserapiendpoint + "Employee/GetEmployees", getParams)
        .then(res => {
            const data = res.data
            console.log(data)
            if(data.status=="1")
                this.setState({
                    employeeList        :   data.employees,
                    isloading           :   false,
                }) 
            else
                this.setState({
                    employeeList        :   [],
                    isloading           :   false
                }) 
        })
        .catch(error=>{
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade        :   true
            })
        })
        
    }

    getNature(){
      
        const getParams = {
            "IpAddress"     :   "0.0.0.0",
            "ClientId"      :   this.state.userinfo.clientId,
            "UserId"        :   this.state.userinfo.userId,
            "Code"          :   "0034"
        };

        axios
        .post( AppConfiguration.Setting().noserapiendpoint + "Maintenance/GetDataReferences", getParams)
        // .post(AppConfiguration.Setting().noserapiendpoint + "Maintenance/GetDataReferences",  getParams)
        .then(res => {
            const data = res.data;
            console.log("Get Nature");
            console.log(data);
            this.setState({ natureList : data.dataReferences });
         })
    }

    getReason(){
        this.setState({isloading:true})
        const getParams = {
            "IpAddress"     :   "0.0.0.0",
            "ClientId"      :   this.state.userinfo.clientId,
            "UserId"        :   this.state.userinfo.userId,
            "Code"          :   "0033"
        };

        axios
        .post( AppConfiguration.Setting().noserapiendpoint + "Maintenance/GetDataReferences", getParams)
        // .post(AppConfiguration.Setting().noserapiendpoint + "Maintenance/GetDataReferences",  getParams)
        .then(res => {
            const data = res.data;
            console.log("Get Reason");
            console.log(data);
            this.setState({ reasonList : data.dataReferences });
         })
    }

    getPayCard(){
        this.setState({isloading:true})
        const getParams = {
            "IpAddress"     :   "0.0.0.0",
            "ClientId"      :   this.state.userinfo.clientId,
            "UserId"        :   this.state.userinfo.userId,
        }

        axios
        .post( AppConfiguration.Setting().noserapiendpoint + "Maintenance/GetPayCardTypes", getParams)
        // .post(AppConfiguration.Setting().noserapiendpoint + "Maintenance/GetDataReferences",  getParams)
        .then(res => {
            const data = res.data;
            console.log("Get Reason");
            console.log(data);
            this.setState({ payCardList : data.payCardTypes });
         })
    }
   

    onChangeEmployeesNo(e){
        this.setState({selectedEmployeeNo:e.target.value})
    }

    onChangeEmployeePosition(e){
        this.setState({selectedPosition:e.target.value})
    }

    onChangeDateStart(e){
        this.setState({selectedDateStart:e.target.value})
    }

    onChangeStatus(e){
        this.setState({selectedStatus:e.target.value})
    }


    handleTransmitClick = () => {
        this.setState({isloading:true })
        console.log(this.state.createdBy)
        const transParams = {
            "IpAddress"             : "0,0,0,0",
            "ClientId"              :   this.state.userinfo.clientId,
            "UserId"                :   this.state.userinfo.userId,
            "HeaderId"              :   this.state.headerId,
            "StatusId"              :   "4",
            "RequestDate"           :   this.state.applicationDate,
            "CreatedBy"             :   this.state.createdBy,
            "Remarks"               :   this.state.data.rEmarks

        };
        //console.log(params)
         axios
         .post( AppConfiguration.Setting().noserapiendpoint + "Paycard/AddApplicationStatus",  transParams)
        .then(res => {
            const data = res.data;
            if(data.status=="1"){
            this.setState({
                    isloading   :   false,
                    alerttype   :   "Success!",
                    isshow      :   true,
                    color       :   "success",
                    message     :   data.message,
                    fade        :   true
                });
            
            }
            else {
                this.setState({
                    isloading   :   false,
                    alerttype   :   "Error!",
                    isshow      :   true,
                    color       :   "danger",
                    message     :   data.message,
                    fade        :   true
                });
            }
        })

    }

    handleClickCancel = () => {
        this.setState({isloading:true })
            console.log(this.state.createdBy)
            const cancelParams = {
                "IpAddress"             : "0,0,0,0",
                "ClientId"              :   this.state.userinfo.clientId,
                "UserId"                :   this.state.userinfo.userId,
                "HeaderId"              :   this.state.headerId,
                "StatusId"              :   "3",
                "RequestDate"           :   this.state.applicationDate,
                "CreatedBy"             :   this.state.createdBy,
                "Remarks"               :  this.state.remarks

            };
        //console.log(params)
        //  return
        axios
        .post( AppConfiguration.Setting().noserapiendpoint + "Paycard/AddApplicationStatus",  cancelParams)
        .then(res => {
            const data = res.data;
            if(data.status=="1"){
            this.setState({
                    isloading   :   false,
                    alerttype   :   "Success!",
                    isshow      :   true,
                    color       :   "success",
                    message     :   data.message,
                    fade        :   true
                });
            
            }
            else {
                this.setState({
                    isloading   :   false,
                    alerttype   :   "Error!",
                    isshow      :   true,
                    color       :   "danger",
                    message     :   data.message,
                    fade        :   true
                });
            }
        })

    }

    onChangeNewPayCardNumber = (e) => {
        this.setState({ newpayCardNumber: e.target.value} );
    }

    onChangeOldPayCardNumber = (e) => {
        this.setState({ oldpayCardNumber: e.target.value} );
    }

    onChangeDescription = (e) => {
        this.setState({ value: e.target.value });
    };

    onChangeValue = (e) => {
        this.setState({ value: e.target.value });
    };

    onChangeOtherReason  = (e) => {
        this.setState({ otherReason: e.target.value });
    };

    handleClickTransmit = (e) => {

    }

    handleChangeCheckbox(e) {
       
       
        this.setState({[e.target.name]: e.target.checked})
        console.log(e.target.value)
        const obj = {
			"DocumentId"  : e.target.value
		}
		this.state.docArrayList.push(obj)
    }
   

    handleChangeAMTSubDate = date => {
        this.setState({
            amtSubDate: date,
            isshow:false,
        })
    }

   
    handleChangeDateTransmit = date => {
        this.setState({dateTransmit: date})
    };

    handleChangeApplicationDate = date => {
        this.setState({date: date})
    };

    handleClickUpload = e => {
        alert("In Progress...!!")
    }
    
    IsDecimal(val)
    {
        var regex = /^\d*\.?(?:\d{1,2})?$/;
        return !regex.test(val)
    }
    IsNumeric(evt){
        var theEvent = evt || window.event;
        var key = theEvent.keyCode || theEvent.which;
        key = String.fromCharCode( key );
        var regex = /^\d+(.\d+)?$/;
        if( !regex.test(key) ) {
        theEvent.returnValue = false;
        if(theEvent.preventDefault) theEvent.preventDefault();
        }
    }

    
    render() {
      
        return(
            <div>
            <Banner />
            <Container className="themed-container" fluid={true}>
                <Card className="mt-5">
                    <Card.Header >PAYCARD VIEW APPLICATION</Card.Header>
                    
                        <Card.Body >
                            <Card.Header>EMPLOYEE DETAILS</Card.Header>
                            <Form className="mt-5">
                            <Alert isOpen={this.state.isshow} color={this.state.color} fade={this.state.fade} className={this.state.isshow ? 'display-block' : 'display-none'}>
                                    <div className={this.state.color}></div> 
                                    {this.state.message}
                                </Alert>
                                <Form.Group as={Row} controlId="formHorizontalEmail">
                                    <Col sm={6}>
                                    <Form.Label style={{fontWeight : "bold"}}>Client</Form.Label>
                                        <Typeahead 
                                            labelKey='name'
                                            id="basic-example"
                                            onChange={this.handleChangeClient}
                                            options={this.state.clientList}
                                            placeholder="Select Client" 
                                            defaultSelected={[this.state.clientName]}
                                            disabled
                                        />
                                    </Col>
                                    <Col sm={6}>
                                    <Form.Label style={{fontWeight : "bold"}}>Location/Branch</Form.Label>
                                        <Form.Control 
                                            
                                            placeholder="Branch"
                                            disabled
                                            type="text" 
                                            autoComplete="off" 
                                            name="locationName"
                                            value={this.state.locationName}
                                        />
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} controlId="formHorizontalEmail">
                                    <Col sm={6}>
                                    <Form.Label style={{fontWeight : "bold"}}>Employee</Form.Label>
                                        <Typeahead
                                            labelKey='name'
                                            id="basic-example"
                                            onChange={this.onChangeEmployeesList}
                                            options={this.state.employeeList}
                                            defaultSelected={[this.state.employeeName]}
                                            placeholder="Select Employee"
                                            disabled
                                        />
                                    </Col>
                                    <Col sm={6}>
                                    <Form.Label style={{fontWeight : "bold"}}>Emp No.</Form.Label>
                                        <Form.Control 
                                            placeholder="Employee No" 
                                            disabled
                                            type="text" 
                                            autoComplete="off" 
                                            name="employeeNo"
                                            value={this.state.employeeNo}
                                        />
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} controlId="formHorizontalEmail">
                                    <Col sm={6}>
                                    <Form.Label style={{fontWeight : "bold"}}>Position</Form.Label>
                                        <Form.Control 
                                            type="text" 
                                            placeholder="Position" 
                                            name="position"
                                            value={this.state.position}
                                            // onChange={this.onChangeEmployeePosition.bind(this)}
                                            disabled
                                        />
                                    </Col>
                                    <Col sm={6}>
                                    <Form.Label style={{fontWeight : "bold"}}>Date Start</Form.Label>
                                        <Form.Control 
                                            type="text" 
                                            placeholder="Date Start" 
                                            name="dateStart"
                                            value={this.state.dateStart}
                                            disabled
                                        />
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} controlId="formHorizontalEmail">
                                    <Col sm={6}>
                                    <Form.Label style={{fontWeight : "bold"}}>Status</Form.Label>
                                        <Form.Control 
                                            type="text"
                                            placeholder="Status" 
                                            name="status"
                                            value={this.state.status}
                                            disabled
                                        />
                                    </Col>
                                </Form.Group>
                            <Card.Header className="mt-5">PayCard DETAILS</Card.Header>
                                <Form.Group as={Row} className="mt-5" controlId="formHorizontalEmail">
                                    <Col sm={4} className="mt-4">
                                    <Form.Label style={{fontWeight : "bold"}}>Application Date</Form.Label>
                                        {/* <DatePicker
                                            ref='date'
                                            selected={this.state.date}
                                            onChange={this.handleChangeApplicationDate}
                                            minDate={this.minDate}
                                            value={this.props.date}
                                            dateFormat={"MM/dd/yyyy"}
                                            className="form-control"
                                            wrapperClassName="datepicker"
                                            // disabled
                                        /> */}
                                         <Form.Control 
                                            type="text" 
                                            placeholder="Date Start" 
                                            name="applicationDate"
                                            value={this.state.applicationDate}
                                            disabled
                                        />
                                    </Col>
                                    <Col sm={4}>
                                    <Form.Label style={{fontWeight : "bold"}}>Application No.</Form.Label>
                                       
                                        <Form.Control 
                                            type="text"
                                            placeholder="Application No" 
                                            name="applicationNo"
                                            value={this.state.applicationNo}
                                            disabled
                                        />
                                        
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="mt-5" controlId="formHorizontalEmail">
                                    <Col sm={4}>
                                    <Form.Label style={{fontWeight : "bold"}}>PayCard Type</Form.Label>
                                        <Typeahead
                                            labelKey='name'
                                            id="basic-example"
                                            onChange={this.onChangePayCardList}
                                            options={this.state.payCardList}
                                            placeholder="Select PayCard"
                                            defaultSelected={[this.state.payCardType]}
                                            disabled
                                        />
                                        {/* <Typeahead 
                                            labelKey='name'
                                            id="basic-example"
                                            onChange={this.handleChangeClient}
                                            options={this.state.clientList}
                                            placeholder="Select Client" 
                                            defaultSelected={[this.state.clientName]}
                                            disabled
                                        /> */}
                                    </Col>
                                    <Col sm={3}>
                                    <Form.Label style={{fontWeight : "bold"}}>PayCard No. Old</Form.Label>
                                        {/* <Form.Control 
                                           type="text"
                                           placeholder="Enter Old No." 
                                           ref="oldpayCardNumber"
                                           autoComplete="off"
                                           maxLength="12"
                                           name="oldpayCardNumber"
                                           value={this.state.oldpayCardNumber}
                                           onChange={this.onChangeOldPayCardNumber}
                                           onKeyPress={this.IsNumeric.bind(this)}
                                        /> */}
                                         <Form.Control 
                                            type="text"
                                            placeholder="Enter Old No." 
                                            name="oldpayCardNumber"
                                            value={this.state.oldpayCardNumber}
                                            disabled
                                        />
                                    </Col>
                                    <Col sm={3}>
                                    <Form.Label style={{fontWeight : "bold"}}>New</Form.Label>
                                        {/* <Form.Control 
                                            type="text"
                                            placeholder="Enter New No." 
                                            ref="newpayCardNumber"
                                            autoComplete="off"
                                            maxLength="12"
                                            name="newpayCardNumber"
                                            value={this.state.newpayCardNumber}
                                            onChange={this.onChangeNewPayCardNumber}
                                            onKeyPress={this.IsNumeric.bind(this)}

                                        /> */}
                                         <Form.Control 
                                            type="text"
                                            placeholder="Enter New No." 
                                            name="newpayCardNumber"
                                            value={this.state.newpayCardNumber}
                                            disabled
                                        />
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="mt-5" controlId="formHorizontalEmail">
                                    <Col sm={4}>
                                    <Form.Label style={{fontWeight : "bold"}}>Nature of Application</Form.Label>
                                        {/* <Typeahead
                                            labelKey='name'
                                            id="basic-example"
                                            onChange={this.onChangeNature}
                                            options={this.state.natureList}
                                            placeholder="Select Nature"
                                        /> */}
                                        <Typeahead
                                            labelKey='name'
                                            id="basic-example"
                                            onChange={this.onChangeNature}
                                            options={this.state.natureList}
                                            placeholder="Select Nature"
                                            defaultSelected={[this.state.nature]}
                                            disabled
                                        />
                                    </Col>
                                    <Col sm={3}>
                                    <Form.Label style={{fontWeight : "bold"}}>Value Transfer</Form.Label>
                                        <Form.Control 
                                            type="text"
                                            name="cardvalue"
                                            value={this.state.cardvalue}
                                            onChange={this.onChangeValue} 
                                            autoComplete="off"
                                            placeholder="Enter Value" 
                                            disabled
                                        />
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="mt-5" controlId="formHorizontalEmail">
                                    <Col sm={4}>
                                    <Form.Label style={{fontWeight : "bold"}}>Reason of Application</Form.Label>
                                        <Typeahead
                                            labelKey='name'
                                            id="basic-example"
                                            onChange={this.onChangeReasonList}
                                            options={this.state.reasonList}
                                            placeholder="Select Reason"
                                            defaultSelected={[this.state.reason]}
                                            disabled
                                        />
                                       
                                    </Col>
                                    <Col sm={2}>
                                    <Form.Label style={{fontWeight : "bold"}}>Others Specify</Form.Label>
                                        <Form.Control 
                                            type="text"
                                            name="otherReason"
                                            value={this.state.otherReason}
                                            onChange={this.onChangeOtherReason} 
                                            autoComplete="off"
                                            placeholder="Enter Reason" 
                                            disabled
                                        />
                                    </Col>
                                </Form.Group>
                                <fieldset className="border p-2 mt-5">
                                <legend className="w-auto">Attachments </legend>
                                    <Form.Group as={Row} className="mt-3" controlId="formHorizontalEmail">
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                        <Col sm={2}>
                                            <Form.Check 
                                                type="checkbox" 
                                                label="Payroll Form"
                                                onChange={e => this.handleChangeCheckbox(e)}
                                                checked={this.state.IsBasic}
                                                // disabled={true}
                                                value= "1"
                                                name="payrollForm"
                                            />
                                        </Col>
                                        <Col sm={2}>
                                            <Form.Check 
                                                type="checkbox" 
                                                label="LOI Form"
                                                onChange={e => this.handleChangeCheckbox(e)}
                                                checked={this.state.loiForm}
                                                // disabled={true}
                                                value= "4"
                                                name="loiForm"
                                            />
                                        </Col>
                                        <Col sm={2}>
                                            <Form.Check 
                                                type="checkbox" 
                                                label="Valid Id"
                                                onChange={e => this.handleChangeCheckbox(e)}
                                                checked={this.state.validId}
                                                // disabled={true}
                                                value= "2"
                                                name="validId"
                                            />
                                        </Col>
                                        <Col sm={2}></Col>
                                        <Col sm={1}>
                                            <Form.Label style={{fontWeight : "bold"}}>Upload DOCUMENTS</Form.Label>
                                        </Col>
                                        <Col sm={2}>
                                        <ButtonToolbar className="mt-1">
                                            <Button variant="success" className="noser-button" onClick={ this.handleClickUpload }>Upload</Button>
                                        </ButtonToolbar>
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} className="mt-1" controlId="formHorizontalEmail">
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                        <Col sm={2}>
                                            <Form.Check 
                                                type="checkbox" 
                                                label="Affidavit Loss"
                                                onChange={e => this.handleChangeCheckbox(e)}
                                                checked={this.state.affidavit}
                                                // disabled={true}
                                                value= "5"
                                                name="affidavit"
                                            />
                                        </Col>
                                        <Col sm={2}>
                                            <Form.Check 
                                                type="checkbox" 
                                                label="Cash Card"
                                                onChange={e => this.handleChangeCheckbox(e)}
                                                checked={this.state.cashCard}
                                                // disabled={true}
                                                value= "3"
                                                name="cashCard"
                                            />
                                        </Col>
                                        <Col sm={2}>
                                            <Form.Check 
                                                type="checkbox" 
                                                label="ATD"
                                                onChange={e => this.handleChangeCheckbox(e)}
                                                checked={this.state.atd}
                                                // disabled={true}
                                                value= "6"
                                                name="atd"
                                            />
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} className="mt-1" controlId="formHorizontalEmail">
                                        
                                        
                                        
                                    </Form.Group>
                                </fieldset>
                                <Form.Group as={Row} className="mt-5"  controlId="formHorizontalEmail">
                                    <Col sm={1}>
                                    <Form.Label style={{fontWeight : "bold"}}>Prepared By</Form.Label>
                                    </Col>
                                    <Col sm={3}>
                                        <Form.Control 
                                           type="text" 
                                           autoComplete="off" 
                                           /* name="selectedClient" */
                                           value={this.state.userinfo.fullName}
                                           readOnly
                                        />
                                    </Col>
                                    <Col sm={1}>
                                    <Form.Label style={{fontWeight : "bold"}}>Position</Form.Label>
                                    </Col>
                                    <Col sm={3}>
                                        <Form.Control 
                                            type="text" 
                                            autoComplete="off" 
                                            value={this.state.userinfo.roleName}
                                            readOnly
                                        />
                                    </Col>
                                    {/* &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    <Col sm={2} className="ml-auto">
                                        <DatePicker
                                            ref='dateTransmit'
                                            selected={this.state.dateTransmit}
                                            onChange={this.handleChangeDateTransmit}
                                            minDate={this.minDate}
                                            value={this.props.dateTransmit}
                                            dateFormat={"MM/dd/yyyy"}
                                            className="form-control"
                                            wrapperClassName="datepicker"
                                            // disabled
                                        />
                                    </Col> */}
                                    
                                </Form.Group>
                                <Form.Group as={Row} controlId="formHorizontalEmail">
                                    <Col sm={1}>
                                        <Form.Label style={{fontWeight : "bold"}}>Department</Form.Label>
                                    </Col>
                                
                                    <Col sm={3}>
                                        <Form.Control 
                                            type="text"
                                            name="position"
                                            value={this.state.department}
                                            disabled
                                        />
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} controlId="formHorizontalEmail">
                                <Col >
                                    <ButtonToolbar className="mt-1">
                                    <Button variant="success" className="noser-button-mr1 noser-button ml-auto" disable={this.state.statusid==="1" ? false : true }  onClick={ this.handleClickEdit }>Save</Button>&nbsp;&nbsp;
                                    <Button variant="success" className="noser-button" onClick={ this.handleClickCancel}>Cancel</Button>&nbsp;&nbsp;
                                    <Button variant="success" className="noser-button" onClick={ this.handleTransmitClick }>Transmit</Button>&nbsp;&nbsp;
                                    <Button variant="danger"  style={{minWidth:'60px'}} href="/paycardmonitoring">Close</Button>&nbsp;&nbsp;
                                       {/* <Button variant="success" className="noser-button" onClick={ this.handleClickTransmit }>Back</Button>&nbsp;&nbsp; */}
                                    </ButtonToolbar>
                                    </Col>
                                </Form.Group>
                               
                            </Form>
                        </Card.Body>
                       
                </Card>
            </Container>
            <NoserLoading show={this.state.isloading} />
            </div>
        )
    }
}
export  default PayCardView
