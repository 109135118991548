import {
    React,Component,BootstrapTable,
    paginationFactory,Button, ButtonToolbar, Card, 
    Form, Col, Row, axios, Container, Banner, Typeahead, TimePicker,
    cellEditFactory, Type, moment, NavLink, Alert, AppConfiguration,
    NoserLoading, Noser, sizePerPageRenderer, DatePicker
} 
from '../../../noser-hris-component';
import ToolkitProvider, { CSVExport } from 'react-bootstrap-table2-toolkit';

class CoopRegConSummary extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fade            :   true,
            color           :   "",
            isshow          :   false,
            message         :   "",
            userinfo        :   [],
            isloading       :   false,
            alerttype       :   "",

            clientId        :   "",
            dateMonth       :   "",
            clientDDL       :   [],
            summaryGrid     :   [],
        }
    }
    
    componentDidMount(){
        this.state.userinfo = JSON.parse(sessionStorage.getItem("userData"))
        this.GetClients();
    }
    GetClients = async() => {
        this.setState({isloading:true})
        const param = {
            "IpAddress"     :   "0.0.0.0",
            "ClientId"      :   this.state.userinfo.clientId,
            "UserId"        :   this.state.userinfo.userId,
        };
        await axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Client/GetClientList",  param)
        .then(res => {
            const data = res.data;
            this.setState({clientDDL:data.clients,isloading:false});
        })
        .catch(error=>{
            this.setState(  {
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade            :   true
            })
        })
    }

    handleChangedClient = (e) => {
        this.setState({isloading:false,alerttype:'',isshow:false,color:'',message:'',fade:true})
        if(e.length == 0) {
            this.state.clientId=""
            return
        } 
        this.state.clientId = e[0].id
    }
    handleChangedMonth = (date) =>{
        this.setState({isloading:false,alerttype:'',isshow:false,color:'',message:'',fade:true})
        this.setState({dateMonth:date})
    }
    handleSearchClick = async() =>{
        this.setState({isloading:true,alerttype:'',isshow:false,color:'',message:'',fade:true})
        let dateMonth = (this.state.dateTo) ? moment(this.state.dateMonth).format('MM/DD/YYYY') : ""
        const param = {
            "IpAddress"     :   "0.0.0.0",
            "UserId"        :   this.state.userinfo.userId,
            "ClientId"      :   this.state.clientId,
            "ContractTypeID" :   "2",
            "DateMonth"      :   dateMonth,
        };
        await axios
        .post(AppConfiguration.Setting().noserapiendpoint + "ContractReport/GetContractRegularizationSummary",  param)
        .then(res => {
            const data = res.data;
            this.setState({summaryGrid:data.regularizationSummaries,isloading:false});
            if(data.status==="0"){
                this.setState(  {
                    isloading       :   false,
                    alerttype       :   "Error!",
                    isshow          :   true,
                    color           :   "danger",
                    message         :   data.message,
                    fade            :   true
                })
            }
        })
        .catch(error=>{
            this.setState(  {
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade            :   true
            })
        })
    }
    render(){

        const columns = [
            {
                dataField: 'client',
                text: 'Client',
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'left',width:'20%' }},
                style:{textAlign:'left', width:'20%' }
            },
            {
                dataField: 'returned',
                text: 'RETURNED',
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'10%' }},
                style:{textAlign:'center', width:'10%' }
            },
            {
                dataField: 'unReturn',
                text: 'UNRETURN',
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'10%' }},
                style:{textAlign:'center', width:'10%' }
            },
            {
                dataField: 'inActive',
                text: 'INACTIVE',
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'left',width:'60%' }},
                style:{textAlign:'left', width:'60%' }
            },
        ]
        const selectRow = {
            mode: 'checkbox',
            hideSelectAll: true,
            clickToSelectAndEditCell: true,
            onSelect: (row, isSelect, rowIndex, e) => {
                
            }
        };
        const { ExportCSVButton } = CSVExport;
        return(
            <div>
                <Banner />
                <Container className="mt-5" fluid>
                    <Card>
                        <Card.Header>REPORTS >> CONTRACTS >> COOP REGCON SUMMARY</Card.Header>
                        <Card.Body>
                            <Form>
                                <Alert isOpen={this.state.isshow} color={this.state.color} fade={this.state.fade} className={this.state.isshow ? 'display-block' : 'display-none'}>
                                    <div className={this.state.color}></div> 
                                    {this.state.message}
                                </Alert>
                                <Form.Group as={Row} controlId="formPlaintextEmail">
                                    <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                        CLIENT
                                    </Form.Label>
                                    <Col>
                                        <Typeahead
                                            labelKey='name'
                                            id="basic-example"
                                            onChange={this.handleChangeClient}
                                            options={this.state.clientDDL}
                                        />
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} controlId="formPlaintextEmail">
                                    <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                        MONTH
                                    </Form.Label>
                                    <Col>
                                        <DatePicker
                                            ref='date'
                                            selected={this.state.dateMonth}
                                            onChange={this.handleChangedMonth}
                                            minDate={this.minDate}
                                            value={this.props.dateMonth}
                                            dateFormat="MMMM"
                                            showMonthYearPicker
                                            showFullMonthYearPicker
                                            className="form-control"
                                            placeholderText="SELECT MONTH"
                                        />
                                    </Col>
                                </Form.Group>
                                <ButtonToolbar sm={12}>
                                    <Button variant="success" className="ml-auto noser-button" onClick = { this.handleSearchClick }>
                                        SEARCH
                                    </Button>
                                </ButtonToolbar>
                                <div>
                                <ToolkitProvider
                                    keyField="id"   
                                    data={ this.state.summaryGrid }
                                    columns = { columns }
                                    exportCSV={ {
                                        noAutoBOM: false,blobType: "data:text/csv;charset=utf-8,%EF%BB%BF",fileName: "CoopRegularSummary.csv",
                                    } }
                                    >
                                    {
                                        props => (
                                        <div>
                                            
                                            <hr />
                                            <ExportCSVButton disabled={this.state.summaryGrid.length>0 ? false : true} className="btn btn-success" { ...props.csvProps }>Export CSV!!</ExportCSVButton>
                                            <Card.Header>COOP REGULAR Summary</Card.Header>
                                            <BootstrapTable
                                                striped
                                                hover
                                                condensed
                                                //pagination={ paginationFactory({sizePerPage:pageSize,hideSizePerPage:true,hidePageListOnlyOnePage:true})}
                                                pagination={ paginationFactory({sizePerPageRenderer})}
                                                keyField = "id"
                                                data = { this.state.summaryGrid }
                                                noDataIndication={ () => <div>No record found.</div> }
                                                columns = { columns }
                                                rowClasses="noser-table-row-class no-checkbox"
                                                selectRow={selectRow}
                                            />
                                        </div>
                                        )
                                    }
                                </ToolkitProvider>
                                </div>
                            </Form>
                        </Card.Body>
                    </Card>
                </Container>  
                <NoserLoading show={this.state.isloading} />
            </div>
        )
    }

}

export  default CoopRegConSummary