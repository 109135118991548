import {
    React,Component,BootstrapTable, Type,
    paginationFactory,Button, ButtonToolbar, Card,
    Form, Col, Row, Container, axios, Typeahead, Banner, TimePicker,
    cellEditFactory ,moment, NavLink, Alert, AppConfiguration,
    NoserLoading, Noser, sizePerPageRenderer, DatePicker
} 
from '../../noser-hris-component';
import type from 'react-bootstrap-table2-editor';

class AdminApproved extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userinfo        :   [],
            isloading       :   false,
            isshow          :   false,
            alerttype       :   "",
            message         :   "",
            color           :   "",
            fade            :   true,
            selected: [],
            employeeProfileList:[],
            newEmployeeProfileList: []
            

        }
    }

    componentDidMount(){
        const sleep = (milliseconds) => {return new Promise(resolve => setTimeout(resolve, milliseconds))}
        this.state.userinfo = JSON.parse(sessionStorage.getItem("userData"))
        this.GetSubmittedEmployeeProfiles();
        
        sleep(1000).then(() => {
        this.setState({isLoading:false})})
        }

    GetSubmittedEmployeeProfiles() {
        this.setState({
            employeeProfileList:[]
            
        })
        const employeeParams = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.userinfo.clientId,
            "UserId":this.state.userinfo.userId,
            "RoleId":this.state.userinfo.roleId,
            "ProfileId":"",
            "StatusId":"2"
        };
        console.log("Test 1");
        console.log(employeeParams)
        axios
            .post(AppConfiguration.Setting().noserapiendpoint + "Administrator/GetEmployeeProfileForApprovals",  employeeParams)
        .then(res => {
            const data = res.data;
            console.log("Test app2");
            console.log(data);
            this.setState({ employeeProfileList: data.employeeProfiles});
            if(data.status=="1"){
                this.setState({
                    isloading       :   false,
                    alerttype       :   "Success!",
                    isshow          :   true,
                    color           :   "success",
                    message         :   data.message,
                    fade            :   true
                });
            }
            else{
                this.setState({
                    isloading       :   false,
                    alerttype       :   "Error!",
                    isshow          :   true,
                    color           :   "danger",
                    message         :   data.message,
                    fade            :   true
                })
            }
            })
            .catch(error=>{
            this.setState(  {
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade            :   true
            })
        })

    }

    handleApproveClick = event => {
        let newEmployeeProfileList = []
        this.setState({newEmployeeProfileList: [],isLoading:true})

        console.log(this.state.newEmployeeProfileList)
        for (let i = 0; i < this.state.employeeProfileList.length; i++) {
             if (this.state.employeeProfileList[i]["isModified"] == 1) {
                const obj = {
                    ApprovalId:this.state.employeeProfileList[i]["id"],
                    StatusId: "4",
                };

                this.state.newEmployeeProfileList.push(obj);
            }
        }

    const approveParams = {
        "IpAddress":"0.0.0.0",
        "ClientId":this.state.userinfo.clientId,
        "UserId":this.state.userinfo.userId,
        "RoleId":this.state.userinfo.roleId,
        "approvedEmployees":this.state.newEmployeeProfileList
        };
        console.log(approveParams)
        axios
            .post(AppConfiguration.Setting().noserapiendpoint + "Administrator/SubmitApprovedEmployeeProfile", approveParams)
            .then(res => {
                const data = res.data;
                console.log("test")
                console.log(res.data)
                this.setState({isLoading:false})
                this.refreshPage();
                var alertType = (data.status=="1") ? "success" : "danger"
                this.setState(
                    {
                        isLoading:false,
                        AlertType:"Success!",
                        show:true,
                        Color:alertType,
                        Message:data.message ,
                        Fade:true
                    });
            })
            .catch(error=>{
                this.setState(
                {
                    isLoading:false,
                    AlertType:"Error! ",
                    Show:true,
                    Color:"danger",
                    Message: "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                    Fade:true
                })
            })
    }  

    handleRejectClick = event => {
        let newEmployeeProfileList = []
        this.setState({newEmployeeProfileList: [],isLoading:true})

        console.log(this.state.newEmployeeProfileList)
        for (let i = 0; i < this.state.employeeProfileList.length; i++) {
            if(this.state.employeeProfileList[i]["isSelect"] == 1){
                const obj = {
                    ApprovalId: this.state.employeeProfileList[i]["id"],
                    StatusId: "5",
                };

                this.state.newEmployeeProfileList.push(obj);
            }
        }

        const approveParams = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.userinfo.clientId,
            "UserId":this.state.userinfo.userId,
            "RoleId":this.state.userinfo.roleId,
            "approvedEmployees":this.state.newEmployeeProfileList
            };
            console.log(approveParams)
            axios
                .post(AppConfiguration.Setting().noserapiendpoint + "Administrator/SubmitApprovedEmployeeProfile", approveParams)
                .then(res => {
                    const data = res.data;
                    console.log("test")
                    console.log(res.data)
                    this.setState({isLoading:false})
                    this.refreshPage();
                    var alertType = (data.status=="1") ? "success" : "danger"
                    this.setState(
                        {
                            isLoading:false,
                            AlertType:"Success!",
                            show:true,
                            Color:alertType,
                            Message:data.message ,
                            Fade:true
                        });
                })
            .catch(error=>{
                this.setState(
                {
                    isLoading:false,
                    AlertType:"Error! ",
                    Show:true,
                    Color:"danger",
                    Message: "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                    Fade:true
                })
            })
    } 

    LeavePageWithourSavingChanges() {
        const isChanged=false
        for (let i = 0; i < this.state.employeeProfileList.length; i++) {
            if (this.state.employeeProfileList[i]["isModified"] == 1) {
                this.setState({isGridDataChanged: true})
                isChanged=true
                break;
            }
        }
        return isChanged
    }

    GridDataModified(oldValue, newValue, profid, column) {
        console.log(profid)
        this.state.employeeProfileList.map(function(item,i) {
            if (item.id===profid)
                item.isModified = newValue!=oldValue ? "1" : "0"
            })
    }


    // onSelectAll = (isSelected) => {
    //     console.log(isSelected)
    //     if (isSelected) {
    //        return this.state.employeeProfileList.map(row => row.id);
    //      } else {
    //        return [];
    //      }
    //   }
   
    
    render() {
        const columns1 = [
            {
                dataField: 'employeeName',
                text: 'FULL NAME',
                headerStyle: (colum, colIndex) => {
                    return { width: '100%'};
                }
            }
            
            
        ] 
        const selectRow = {
            mode: 'checkbox',
            clickToSelect: true,
            onSelect: (row, isSelect, rowIndex, e) => {
                        row.isModified = isSelect ? "1" : "0"
                    },
            onSelectAll: (isSelect, rows) => {
            console.log(isSelect);
            console.log(rows);
            rows.map(function(itm){
                    if(itm.id!= null){
                       
                        itm.isModified = isSelect ? "1" : "0"
                    }
                    
                })
            }
          };
          

        const rowEvents = {
            onClick: (e, row, rowIndex) => {
              //alert(e.row);
              
            }
        };

        return(
            <div>
                <Banner />
                    <Container className="mt-5">
                        <Card>
                            <Card.Header>BATCH UPLOAD RECORDS</Card.Header>
                            <Card.Body>
                                <Form>
                                    <div className="mt-5">
                                    {/* <BootstrapTable
                                        keyField='id'
                                        data={ products }
                                        columns={ columns }
                                        selectRow={ selectRow }
                                    /> */}
                                        <BootstrapTable
                                            ref="tbl"
                                            caption={Noser.TableHeader({title:"Search Result"})}
                                            rowClasses="noser-table-row-class"
                                            striped
                                            hover
                                            condensed
                                            pagination={ paginationFactory({sizePerPageRenderer}) }
                                            keyField = "id"
                                            data = { this.state.employeeProfileList }
                                            columns = { columns1}
                                            selectRow = { selectRow }
                                            cellEdit = { cellEditFactory({
                                                mode: 'dbclick',
                                                blurToSave: true,
                                                afterSaveCell: (oldValue, newValue, row, column) => {
                                                            this.GridDataModified(oldValue, newValue, row.id, column)
                                                }
                                            })
                                        }
                                        rowEvents={ rowEvents }
                                        />
                                    </div>
                                    <ButtonToolbar sm={12} className="mt-3">
                                        <Button variant="success"  onClick={this.handleApproveClick}>
                                            Approve
                                        </Button>&nbsp;&nbsp;
                                        <Button variant="primary" onClick={this.handleRejectClick}>
                                           Reject
                                        </Button>&nbsp;&nbsp;
                                        <Button variant="danger" href="/ManageAdmin">
                                            Back
                                        </Button>
                                    </ButtonToolbar>
                                </Form>
                            </Card.Body>
                        </Card>
                    </Container>
            </div> 
            
        )
    }
}


export default AdminApproved;