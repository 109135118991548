import {
    React,Component,BootstrapTable,
    paginationFactory,Button, ButtonToolbar, Card, 
    Form, Col, Row, axios, Typeahead, TimePicker,
    cellEditFactory ,moment, NavLink, Alert, AppConfiguration,
    NoserLoading, Noser, Container, Modal, DatePicker,Dropdown, DropdownButton, props, Redirect
} 
from '../../../noser-hris-component';


class PayslipViewCharges extends Component {
    constructor(props) {
        super(props)
        this.state ={
            userinfo        :   [],
            isloading       :   false,
            isshow          :   false,
            alerttype       :   "",
            message         :   "",
            color           :   "",
            fade            :   true,

            deductionId         : "",
            clientId  : "",
            employeeId  : "",
            payroll : [],
        }
        this.state.userinfo = JSON.parse(sessionStorage.getItem("userData"))
        this.state.chargesinfo = JSON.parse(sessionStorage.getItem("chargesData"))
    }
    componentDidMount() {
        this.setState({isloading:true})
        this.props.onRefModal(this)
    }

    componentWillUnmount() {
        this.props.onRefModal(undefined)
    }

    initialize = (e) => {
         this.setState({
            isloading       :   false,
            isshow          :   false,
            alerttype       :   "",
            message         :   "",
            color           :   "",
            fade            :   true,
            clientId : e.clientId,
            employeeId : e.employeeId,
            deductionId : e.deductionId,
            payroll : [],
        })
        this.GetPayrollLedgers(e);
        console.log(e)
    }

    onModalClose = () => {
        this.props.onHide();
    }
  
    GetPayrollLedgers = (e) => {
            this.setState({isloading:true})
            const params = {
                "IpAddress"     : "0.0.0.0",
                "ClientId"      : e.clientId,
                "UserId"        : this.state.userinfo.userId,
                "EmployeeId"    : e.employeeId,
                "PayPeriodId"   : e.payPeriodId,
            };
            console.log("GetPayrollLedgers params")
            console.log(params)
            axios
            .post(AppConfiguration.Setting().noserapiendpoint + "Payroll/GetPayrolls",  params)
            .then(res => {
                const data = res.data.payrolls;
                let arr = []
                for(let x = 0; x < data.length; x++){
                    for(let i = 0; i < data[x]["deductions"].length; i++){
                        const obj = {
                            "deductionName"     : data[x]["deductions"][i]["deductionName"],
                            "deductionType"     : data[x]["deductions"][i]["deductionType"],
                            "amount"            : data[x]["deductions"][i]["amount"]
                        }
                        arr.push(obj)
                    }
                }
                console.log("res.data")
                console.log(arr.filter(x => x.deductionType === e.deductionType))
                /* data.forEach(itm => {
                    this.setState({payroll :itm.deductions.filter(x => x.deductionTypeId === e.deductionTypeId),isloading:false})
                }); */
                this.setState({
                    payroll : arr.filter(x => x.deductionType === e.deductionType),
                    isloading:false
                })
            })
    }

    render() {
        return(
        
            <Modal
                {...this.props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                backdrop="static"
                 >
                <Modal.Header style={{background : "#fff", color : "#000", fontSize : "16px"}} closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        PAYSLIP - VIEW CHARGES
                    </Modal.Title>
                </Modal.Header>
                    <hr style={{borderBottom : "2px solid gray"}}/>
                <Modal.Body>
                    <Container>
                        <Alert isOpen={this.state.isshow} color={this.state.color} fade={this.state.fade} className={this.state.isshow ? 'display-block' : 'display-none'}>
                            <div className={this.state.color}></div> 
                            {this.state.message}
                        </Alert>
                            {
                                this.state.payroll.map((item) => {
                                    console.log(item.deductionName)
                                    return (
                                        <Form.Group as={Row} controlId="formPlaintextEmail">
                                            <Form.Label column sm="3" style={{fontWeight : "bold", fontSize : "14px"}}>
                                                {item.deductionName}
                                            </Form.Label>
                                            <Form.Label column sm="1" style={{fontWeight : "bold", fontSize : "14px", color : "#ff0707", fontStyle : "italic", textAlign : "right" }}>
                                                {item.amount}
                                            </Form.Label>
                                        </Form.Group>
                                    )
                                })
                            }
                    </Container>
                </Modal.Body>
                <Modal.Footer>
                    <ButtonToolbar >
                        <Button variant="danger" onClick={this.onModalClose}>
                            Close
                        </Button>
                    </ButtonToolbar>
            </Modal.Footer>
                <NoserLoading show={this.state.isloading} />
        </Modal>
        );
    }

}
export  default PayslipViewCharges