import {
    React,Component,BootstrapTable,
    paginationFactory,Button, ButtonToolbar, Card, 
    Form, Col, Row, axios, Container, Banner, Typeahead, TimePicker,
    cellEditFactory, Type, moment, NavLink, Alert, AppConfiguration,
    NoserLoading, Noser, sizePerPageRenderer
} 
from '../../noser-hris-component';
import { DropdownButton, Dropdown} from 'react-bootstrap';

class ExamResult extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userinfo        :   [],
            isloading       :   false,
            isshow          :   false,
            alerttype       :   "",
            message         :   "",
            color           :   "",
            fade            :   true,
            selected: [],
            applicantExamGridList:[],
            examdropdownlist:[],
            examList:[],
            selectedfullName:"",
            selectedfullNameId:"",
            applicationexamList:[],
            selectedExamResultNo:"",
            selectedStatus:"",
            selectedStatusId:"",
            selectedReferenceNo:"",
            EditApplicantExam:[],
            statusList:[],
            positionList:[],
            newApplicantExamGridList: [],
            selectedPositionId:"",
            selectedPosition:"",
            selectedApplicationFormId:"",
            applicationFormList:[],
            applicantExamList:[],
            clientList: [],
            selectedClientName:"",
            selectedClientId:""
        }
    }

    componentDidMount(){
        const sleep = (milliseconds) => {return new Promise(resolve => setTimeout(resolve, milliseconds))}
        this.state.userinfo = JSON.parse(sessionStorage.getItem("userData"))
        this.GetExam();
        //this.GetPosition();
        this.getStatus();
        this.GetApplicantExams();
         //this.GetApplicationForms();
        this.getClient();
        sleep(1000).then(() => {
        this.setState({isLoading:false})})
    }

    GetApplicationForms() {

        this.setState({
            isloading:true
        })
        const applicationParams = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.selectedClientId,
            "UserId":this.state.userinfo.userId,
            "ProfileId": "",
            "PositionId":"",
            "StatusId":"4",
            "TINNumber":""
        };
        //console.log("Test app");
        //console.log(applicationParams)
        axios
            .post(AppConfiguration.Setting().noserapiendpoint + "Recruitment/GetApplicationForms",  applicationParams)
        .then(res => {
            const data = res.data;
            //console.log("Test app");
            //console.log(data);
            this.setState({ applicationFormList: data.applicationForms, isloading:false});
            if(data.status=="0"){
                this.setState({
                    isloading       :   false,
                    alerttype       :   "Error!",
                    isshow          :   true,
                    color           :   "danger",
                    message         :   data.message,
                    fade            :   true
                })
            }
         })
         .catch(error=>{
            this.setState(  {
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade            :   true
            })
        })

    }
    GetPosition() {
        const positionParams = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.selectedClientId,
            "UserId":this.state.userinfo.userId,
            "SectionId":"",
            "DepartmentId":"",
            "Name":""
        };

        axios.post(AppConfiguration.Setting().noserapiendpoint + "Maintenance/GetPositions",  positionParams)
        .then(res => {
            const data = res.data;
            //console.log("Get Position Name");
            //console.log(data);
            this.setState({ positionList  : data.positions });
        })
        
    }

    handleChangePosition = (e) => {
        //console.log(e)
            if (e.length > 0) {
            this.state.selectedPositionName = e[0].name
            this.state.selectedPositionId = e[0].id
        } else {
            this.state.selectedPositionName = ""
            this.state.selectedPositionId = ""
        }
    }

    /* handleChangePosition  = (e) => {
        //console.log(e)
            if (e.length > 0) {
            this.state.selectedPosition = e[0].name
            this.state.selectedPositionId = e[0].positionId
        } else {
            this.state.selectedPosition = ""
            this.state.selectedPositionId = ""
        }
    } */

    handleCoverChangeFullName= (e) => {
        //console.log(e)
            if (e.length == 0) {
                this.setState({selectedFullName: null,selectedApplicationFormId:'',selectedExamResultNo:''})
            return
        }
         this.state.selectedFullName = e[0].memberName
        this.state.selectedApplicationFormId =e[0].id
        // this.state.selectedPositionId = e[0].positionId
        this.state.selectedExamResultNo = e[0].referenceNo

        
        this. GetApplicationForms();
        //this.GetApplicantExams();
        this.setState({isloading:false,})
    }

    
     /* handleCoverChangeExamResultNo= (e) => {
        //console.log(e)
        if(e.length == 0) {
            this.state.selectedExamResultNo = e[0].referenceNo
            return
        }  
            this.state.selectedExamResultNo=""
            // this.GetApplicantExams();
    }  */

    handleCoverChangeClient = (e) => {
        if (e.length > 0) {
            this.state.selectedClient = e[0].name
            this.state.selectedClientId = e[0].id
        } else {
            this.state.selectedClient = ""
            this.state.selectedClientId = ""
        } 
        this.setState({
            isshow  :   false
        })
           //this.GetApplicantExams();
            this.GetApplicationForms();
        //console.log(this.state.selectedClient);
       
    }

    getClient(){
        ////console.log("getClient");
        const clientParams = {
            "IpAddress":"0.0.0.0",
           "ClientId":this.state.userinfo.clientId,
            "UserId":this.state.userinfo.userId
        }

        axios
            .post(
                AppConfiguration.Setting().noserapiendpoint + "Client/GetClientList", clientParams)
                .then(res => {
                    const data = res.data;
                     //console.log("Get 5");
                    //console.log(res.data.clients); 
                    this.setState({clientList : data.clients})
                })
    } 

    getStatus(){
        const statusParams = {
            "IpAddress":"0.0.0.0",
            "UserId":this.state.userinfo.userId,
            "ClientId":this.state.userinfo.clientId,
            "Code":"0001"
        };
       

        axios
            .post(AppConfiguration.Setting().noserapiendpoint + "Maintenance/GetDataReferences", statusParams)
            .then(res => {
                //console.log("status List ");
                //console.log(res.data);
                this.setState({
                    statusList : res.data.dataReferences
                })
            })
    }

    handleChangeStatus =  (e) => {
        // //console.log(e)
        if(e.length == 0) {
            this.state.selectedStatusId=""
            return
        } 
        this.state.selectedStatusId = e[0].statusId
        // //console.log( this.state.selectedStatusId)
    }


    GetExam() {
        const examParams = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.userinfo.clientId,
            "UserId":this.state.userinfo.userId,
        }
        //console.log(examParams)
        axios.post(AppConfiguration.Setting().noserapiendpoint + "Maintenance/GetExams",  examParams)
        .then(res => {
            const data = res.data;
            //console.log(data)
            this.setState({ examList  : res.data.exams })

            for (let i = 0; i < data.exams.length; i++) {
                const obj = {
                    value : data.exams[i]["name"],
                    label : data.exams[i]["id"],
                };
                this.state.examdropdownlist.push(obj);
            }
        })
        
    }

    GetApplicantExams() {
        this.setState({isloading:   true,});
        
        const applicantExamParams = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.userinfo.clientId,
            "UserId":this.state.userinfo.userId,
            "ReferenceNo":"",
            "ApplicationFormId":"",
            "PositionId":"",
            "MemberName":"",
            "StatusId":""
        }
        //console.log("1")
        //console.log(applicantExamParams)
        axios.post(AppConfiguration.Setting().noserapiendpoint + "Recruitment/GetApplicantExams",  applicantExamParams)
        .then(res => {
            const data = res.data;
            //console.log(data)
            this.setState({ applicantExamList  : data.applicantExams, isloading:false })
            /* if(data.status=="1"){
                this.setState({
                    isloading       :   false,
                    alerttype       :   "Success!",
                    isshow          :   true,
                    color           :   "success",
                    message         :   data.message,
                    fade            :   true
                });
            }
            else{
                this.setState({
                    isloading       :   false,
                    alerttype       :   "Error!",
                    isshow          :   true,
                    color           :   "danger",
                    message         :   data.message,
                    fade            :   true
                })
            } */
         })
         .catch(error=>{
            this.setState(  {
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade            :   true
            })
        })

    }

    handleSearchClick = event => {

        if(!this.state.selectedClientId){
            this.setState({
                isloading:false,
                alerttype:"Error!",
                isshow:true,
                color:"danger",
                message: "Please select Client",
                fade:true
            });
            return
        }
        
        this.setState({applicantExamGridList:[],newApplicantExamGridList:[], isloading:true})

        const applicantParams = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.selectedClientId,
            "UserId":this.state.userinfo.userId,
            "ReferenceNo":this.state.selectedExamResultNo,
            "ApplicationFormId":this.state.selectedApplicationFormId,
            "PositionId":this.state.selectedPositionId,
            "MemberName":this.state.selectedFullName,
            "StatusId":this.state.selectedStatusId
        };
        // //console.log(applicantParams)
        axios
            .post(AppConfiguration.Setting().noserapiendpoint + "Recruitment/GetApplicantExams",  applicantParams)
            .then(res => {
            const data = res.data;
            //  //console.log(res.data)
            this.setState({applicantExamGridList: data.applicantExams,  isloading:false});
            if(data.status=="1"){
                this.setState({
                    isloading       :   false,
                    alerttype       :   "Success!",
                    isshow          :   true,
                    color           :   "success",
                    message         :   data.message,
                    fade            :   true
                });
            }
            else{
                this.setState({
                    isloading       :   false,
                    alerttype       :   "Error!",
                    isshow          :   true,
                    color           :   "danger",
                    message         :   data.message,
                    fade            :   true
                })
            }
         })
         .catch(error=>{
            this.setState(  {
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade            :   true
            })
        })

    }

    handleSaveClick = event => {
        const sleep = (milliseconds) => {return new Promise(resolve => setTimeout(resolve, milliseconds))}
        this.setState({isLoading:true})

        for (let i = 0; i < this.state.applicantExamGridList.length; i++) {
            if (this.state.applicantExamGridList[i]["isModified"] == 1) {
                const obj = {
                    Id:this.state.applicantExamGridList[i]["id"],
                    ApplicationFormId:this.state.applicantExamGridList[i]["applicationFormId"],
                    ExamCategoryId:this.state.applicantExamGridList[i]["examCategoryId"],
                    ExamId:this.state.applicantExamGridList[i]["examId"],
                    Score:this.state.applicantExamGridList[i]["score"],
                    DateTaken:this.state.applicantExamGridList[i]["dateTaken"],
                    IsDeleted: this.state.applicantExamGridList[i]["isDeleted"].toString()
                    
                };

                this.state.newApplicantExamGridList.push(obj);
                //console.log(this.state.newApplicantExamGridList)
            }
        }
        
       const saveParams = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.userinfo.clientId,
            "UserId":this.state.userinfo.userId,
            "applicantExams":this.state.newApplicantExamGridList
            
        };
        //console.log(saveParams)
        axios
            .post(AppConfiguration.Setting().noserapiendpoint + "Recruitment/EditApplicantExam", saveParams)
            .then(res => {
                const data = res.data;
                //console.log(data)
                this.setState({isLoading:false})
                this.refreshPage();
                var alertType = (data.status=="1") ? "success" : "danger"
                this.setState(
                    {
                        isloading:false,
                        alerttype:"Success!", 
                        show:true,
                        color:alertType,
                        message:data.message ,
                        fade:true
                    });      
             })
             .catch(error=>{
                this.setState(
                { 
                    isloading:false,
                    alerttype:"Error! ", 
                    show:true,
                    color:"danger",
                    message: "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                    fade:true
                }) 
            })    
    } 

    LeavePageWithourSavingChanges() {
        const isChanged=false
        for (let i = 0; i < this.state.applicantExamGridList.length; i++) {
            if (this.state.applicantExamGridList[i]["isModified"] == 1) {
                this.setState({isGridDataChanged: true})
                isChanged=true
                break;
            }
        }
        return isChanged
    }

    GridDataModified(oldValue, newValue, id, column) {
        //console.log(id)
        this.state.applicantExamGridList.map(function(item,i) {
            if (item.id===id)
                item.isModified = newValue!=oldValue ? "1" : "0"
            })
    }

    refreshPage(){
        this.setState({isloading:true})

        const applicantParams = {
            "IpAddress":"0.0.0.0",
            "UserId":this.state.userinfo.userId,
            "ClientId":"",
            "ReferenceNo":"",
            "ApplicationFormId":"",
            "PositionId":"",
            "StatusId":""
            
        };
        //console.log(applicantParams)
        axios
            .post(AppConfiguration.Setting().noserapiendpoint + "Recruitment/GetApplicantExams",  applicantParams)
            .then(res => {
            const data = res.data;
             //console.log(res.data)
            this.setState({applicantExamGridList: res.data.applicantExams})
            // this.processGrid();
            var alertType = (data.status=="1") ? "success" : "danger"
            this.setState(
                {
                    isLoading:false,
                    AlertType:"Success!",
                    show:true,
                    Color:alertType,
                    Message:data.message ,
                    Fade:true
                });
         })
         .catch(error=>{
            this.setState(
            {
                isLoading:false,
                AlertType:"Error! ",
                Show:true,
                Color:"danger",
                Message: "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                Fade:true
            })
        })

    }
    render() {
        
        const columns3 = [
            {
                dataField: 'memberName',
                text: 'FULL NAME',
                headerStyle: () => {
                    return { width: "20%" };
                },
            },
            {
                dataField: 'referenceNo',
                text: 'REFERENCE NO.',
                editable: false,
                headerStyle: () => {
                    return { width: "10%" };
                },
            },
            {
                dataField: 'position',
                text: 'POSITION/S APPLIED',
                editable: false,
                headerStyle: () => {
                    return { width: "22%" };
                },
            },
            {
                dataField: 'exam',
                text: 'TYPE OF TEST',
                editable    : true,
                headerStyle: () => {
                    return { width: "15%" };
                },
                /* formatter: (cell, row) => {
                    if(row.examId!='' && row.examId!=null){
                        return this.state.examdropdownlist.find(x => x.value == cell).label;
                    }
                },
                editor: {
                    type: Type.SELECT,
                    options: this.state.examdropdownlist
                }  */
            },
            {
                dataField: 'score',
                text: 'SCORE',
                editable: false,
                headerStyle: () => {
                    return { width: "8%" };
                },
            },
            {
                dataField: 'status',
                text: 'STATUS',
                editable: false,
                headerStyle: () => {
                    return { width: "10%" };
                },
            },
            {
                dataField: 'command',
                text: 'COMMAND',
                editable: false,
                headerStyle: () => {
                    return { width: "15%" };
                },
            }
        ] 
         /* const dtr2 = [
            {"name" : "BEDANIA, BABY BOY, SUPERADA", 
            "examResult" : "ER-0000006", 
            "position" : "UTILITY MAN",
            "typeOfTest" : "ENGLISH GRAMMAR",
            "score" : "35", 
            "status" : "CLOSE", 
            "command" : ""},
        ]   */
        const selectRow = {
            mode: 'checkbox',
            clickToSelectAndEditCell: true,
            onSelect: (row, isSelect, rowIndex, e) => {
                this.state.applicantExamGridList.map(function(item,i){
                    if(item.id===row.id)
                    {
                        item.isDeleted = isSelect ? "1" : "0"
                        item.isModified = isSelect ? "1" : "0"
                    }
                })
            }
        };
        
            const rowEvents = {
                onClick: (e, row, rowIndex) => {
            }
        };

    return(
            <div>
                <Banner />
                    <Container className="mt-5" fluid>
                        <Card>
                            <Card.Header>RECRUITMENT >> EXAM RESULTS</Card.Header>
                            <Card.Body>
                                <Form>
                                <Alert isOpen={this.state.isshow} color={this.state.color} fade={this.state.fade} className={this.state.isshow ? 'display-block' : 'display-none'}>
                                        <div className={this.state.color}></div> 
                                        {this.state.message}
                                    </Alert>
                                    <Form.Group as={Row} controlId="formPlaintextEmail">
                                        <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                        CLIENT
                                        </Form.Label>
                                        <Col sm="4">
                                            <Typeahead
                                                labelKey='name'
                                                id="basic-example"
                                                onChange={this.handleCoverChangeClient}
                                                options={this.state.clientList}
                                            />
                                        </Col>
                                        <Col sm="2">
                                        </Col>
                                        <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                        FULL NAME
                                        </Form.Label>
                                        <Col sm="4">
                                            <Typeahead
                                                labelKey='memberName'
                                                id="basic-example"
                                                onChange={this.handleCoverChangeFullName}
                                                options={this.state.applicantExamList}
                                                type="text" 
                                            /> 
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} controlId="formPlaintextEmail">
                                        <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                        EXAM RESULT NO
                                        </Form.Label>
                                        <Col sm="4">
                                            <Typeahead
                                                labelKey='referenceNo'
                                                id="basic-example"
                                                onChange={this.handleCoverChangeExamResultNo}
                                                options={this.state.applicantExamList}
                                                type="text" 
                                            /> 
                                        </Col>
                                        <Col sm="2">
                                        </Col>
                                        <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                        POSITION APPLIED
                                        </Form.Label>
                                        <Col sm="4">
                                            <Typeahead
                                                labelKey='positionId'
                                                id="basic-example"
                                                onChange={this.handleChangePosition}
                                                options={this.state.applicantExamList}
                                            />
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} controlId="formPlaintextEmail">
                                        <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                        STATUS
                                        </Form.Label>
                                        <Col sm="4">
                                            <Typeahead
                                                labelKey='statusId'
                                                id="basic-example"
                                                onChange={this.handleChangeStatus}
                                                options={this.state.applicantExamList}
                                            />  
                                        </Col>
                                    </Form.Group>
                                        <Form.Group as={Row } className="mt-3" controlId="formHorizontalEmail">
                                            <Col sm={12}>
                                                <ButtonToolbar>
                                                    <Button variant="success" className="ml-auto" onClick={ this.handleSearchClick }>
                                                        Search
                                                    </Button>&nbsp;&nbsp;
                                                   <NavLink to="/examresultscreate">
                                                        <Button  variant="success">
                                                            Create
                                                        </Button>
                                                    </NavLink>
                                                </ButtonToolbar>
                                            </Col>
                                        </Form.Group>
                                        <Card.Header>Exam List</Card.Header>
                                        <div className="mt-1">
                                        <BootstrapTable
                                                rowClasses="noser-table-row-class"
                                                striped
                                                hover
                                                condensed
                                                pagination={ paginationFactory({sizePerPageRenderer}) }
                                                keyField = "id"
                                                // data = {dtr2}
                                                data = { this.state.applicantExamGridList }
                                                columns = { columns3}
                                                selectRow = { selectRow }
                                                cellEdit = { cellEditFactory({
                                                mode: 'dbclick',
                                                blurToSave: true,
                                                afterSaveCell: (oldValue, newValue, row, column) => {
                                                    this.GridDataModified(oldValue, newValue, row.id, column.dataField)
                                                    }
                                                })
                                                    }
                                                rowEvents={ rowEvents }

                                            />
                                        </div>
                                </Form>
                                <Form.Group as={Row} controlId="formHorizontalEmail">
                                    <Col >
                                        <ButtonToolbar >
                                            <Button variant="success" className="ml-auto" onClick={this.handleSaveClick}>
                                                Save
                                            </Button>&nbsp;&nbsp;
                                            <NavLink to="/home">
                                                <Button variant="danger" href="/banner">
                                                    Close
                                                </Button>
                                            </NavLink>
                                        </ButtonToolbar>
                                    </Col>
                                </Form.Group>
                            </Card.Body>
                        </Card>
                        
                    </Container>
                    <NoserLoading show={this.state.isloading} />
            </div> 
        )
    }

}

export  default ExamResult 
