import {
    React,Component,BootstrapTable,
    paginationFactory,Button, ButtonToolbar, Card, 
    Form, Col, Row, axios, Typeahead, TimePicker,
    cellEditFactory ,moment, NavLink, Alert, AppConfiguration,
    NoserLoading, Noser, Banner, Container, DatePicker, LeaveModal, Tabs, Tab,
    sizePerPageRenderer
} 
from '../../noser-hris-component';

class ManningRecordCreate extends Component {
    constructor(props) {
        super(props)
        this.minDate = new moment(props.Date)
        this.state = {
            selected: [],
            userinfo  :   [],
            isloading :   false,
            isshow    :   false,
            alerttype :   "",
            message   :   "",
            color     :   "",
            fade      :   true,
            disablebutton   :  false,

            clientDDL : [],
            employeeDDL: [],
            branchDDL: [],
            positionDDL:[],
            payStatusDDL:[],
            payCardTypeDDL :[],

            selectedClientName:"",
            selectedClientId:"",
            selectedLocationId:"",
            selectedLocation:"",
            payCardType:"",
            payCardTypeId:"",
            selectedPosition:"",
            selectedPositionId:"",
            selectedEmployeeStatusId:"",
            selectedEmployeeStatus:"",
            dateOfDeactivation:new Date(),
            contractDateStart:new Date(),
            contractDateEnd:new Date(),
            dateHired:new Date(),
            isDefaultLocation:false,
            payCardNumber:"",
            TMNProfiledId:"",
            rate:"",
            seaValue:"",
            ecolaValue:"",
            colaValue:"",
            remarks:"",
            employeeId:"",
            selectedProfileId:"",
            selectedemployeeName:""
        }
         
    }

    componentDidMount() {
        const sleep = (milliseconds) => {return new Promise(resolve => setTimeout(resolve, milliseconds))}
        this.state.userinfo = JSON.parse(sessionStorage.getItem("userData"))
        this.getClient();
        this.getLocation();
        this.GetPayCardTypes();
        this.GetPosition();
        this.GetEmployeeStatusList();
        console.log(this.state.userinfo.employeeId)
    }

    getClient = async () =>{
        this.setState({isloading:true})
        const getParams = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.userinfo.clientId,
            "UserId":this.state.userinfo.userId,
        }

        await axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Client/GetClientList", getParams)
        .then(res => {
            //payCardTypeId:"",("Client List ");
            //payCardTypeId:"",(res.data);
            this.setState({
                isloading:false,
                clientDDL : res.data.clients
            })
        })
    }
    handleChangeClient= (e) => {
        if(e.length == 0) {
            this.state.selectedClientName=""
            this.state.selectedClientId=""
            return
        } 
        this.state.selectedClientId = e[0].id
        this.state.selectedClientName = e[0].name
        
        this.GetEmployees();
    
    }

    GetEmployees = async () => {
        this.setState({isloading:true})
        const getParams = {
            "IpAddress"     :   "0.0.0.0",
            "ClientId"      :   this.state.selectedClientId,
            "UserId"        :   this.state.userinfo.userId,
            "EmployeeName"  :   "",
            "EmployeeNo"    :   "",
            "EmployeeId"    :   this.state.userinfo.employeeId,
            "ClientName"    :   this.state.selectedClientName
         };
         console.log("getParams");
         console.log(getParams);

        let url = ""
        if (this.state.userinfo.roleId!=4){
            url = AppConfiguration.Setting().noserapiendpoint + "Employee/GetEmployees"
        }
        else{
            url = AppConfiguration.Setting().noserapiendpoint + "Employee/GetEmployeesPerApprover"	
        }

        await  axios 
        .post(url, getParams)
        .then(res => {
            const data = res.data;
            console.log("data");
            console.log(data);
            this.setState({employeeDDL : data.employees, isloading:false}) 
            /* if(data.status=="0"){
                this.setState({
                    isloading   :   false,
                    alerttype   :   "Error!",
                    isshow      :   true,
                    color       :   "danger",
                    message     :   data.message,
                    fade        :   true
                });
                
            } */
           
        })
        .catch(error=>{
            this.setState({
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade            :   true
            })
        })

    }
    handleChangeEmployee = (e) => {
        //payCardTypeId:"",(e)
        if (e.length == 0) {
            this.setState({ selectedemployeeName:"", employeeId:"", selectedProfileId:"",
                selectedLocation:"",selectedLocationId:"", selectedEmployeeStatus:"", 
                /* dateOfDeactivation:"", */payCardTypeId:"", payCardType:""/* ,contractDateStart:"",contractDateEnd:"" */,
                payCardNumber:"",TMNProfiledId:"",rate:"", seaValue:"",selectedPositionId:"",
                colaValue:"",ecolaValue:"",remarks:"", selectedPosition:"",
            })
            return
        }

        /* this.state.employeeId = e[0].id
        this.state.selectedemployeeName = e[0].name
        this.state.selectedProfileId = e[0].profileId
        this.state.selectedLocation = e[0].locationName
        this.state.selectedLocationId = e[0].locationId
        this.state.selectedEmployeeStatus = e[0].employeeStatusType
        this.state.selectedEmployeeStatusId = e[0].employeeStatusTypeId
        this.state.selectedPosition = e[0].position
        this.state.selectedPositionId = e[0].jobPositionId
        this.state.payCardType = e[0].payCardType
        this.state.payCardTypeId = e[0].payCardTypeId
        this.state.payCardNumber = e[0].payCardNumber
        this.state.TMNProfiledId = e[0].tmnProfileId
        this.state.dateOfDeactivation = new Date(e[0].dateOfDeactivation)
        this.state.contractDateStart = new Date(e[0].contractDateStart)
        this.state.contractDateEnd = new Date(e[0].contractDateEnd)
        this.state.dateHired = new Date(e[0].dateHired)
        this.state.rate = e[0].salaryOffered
        this.state.seaValue = e[0].seaValue
        this.state.colaValue = e[0].colaValue
        this.state.ecolaValue = e[0].ecolaValue
        this.state.remarks = e[0].remarks */
        
        this.setState({
            employeeId:e[0].id,
            selectedemployeeName:e[0].name,
            selectedProfileId:e[0].profileId,
            selectedLocation:e[0].locationName,
            selectedLocationId:e[0].locationId,
            selectedEmployeeStatus:e[0].employeeStatusType,
            selectedEmployeeStatusId :e[0].employeeStatusTypeId,
            selectedPosition:e[0].position,
            selectedPositionId:e[0].jobPositionId,
            payCardType:e[0].payCardType,
            payCardTypeId:e[0].payCardTypeId,
            payCardNumber:e[0].payCardNumber,
            TMNProfiledId:e[0].tmnProfileId,
            /* dateOfDeactivation:new Date(e[0].dateOfDeactivation), */
            contractDateStart:new Date(e[0].contractDateStart),
            dateHired:new Date(e[0].dateHired),
            contractDateEnd:new Date(e[0].contractDateEnd),
            rate:e[0].salaryOffered, 
            seaValue:e[0].seaValue,
            colaValue:e[0].colaValue,
            ecolaValue:e[0].ecolaValue,
            remarks:e[0].remarks,
        })

        if(e[0].isDefaultLocation == "1"){
            this.setState({
                isDefaultLocation : true
            })
        } else {
            this.setState({
                isDefaultLocation : false
            })
        }
    } 



    handleChangeBranch  = (e) =>{
        this.setState({isDefaultLocation:e.target.checked})
    }

    GetPosition = async () => {
        const positionParams = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.userinfo.clientId,
            "UserId":this.state.userinfo.userId,
            "SectionId":"",
            "DepartmentId":"",
            "Name":""
        };

        await axios.post(AppConfiguration.Setting().noserapiendpoint + "Maintenance/GetPositions",  positionParams)
        .then(res => {
            const data = res.data;
            //payCardTypeId:"",("Get Position Name");
            //payCardTypeId:"",(data);
            this.setState({ positionDDL  : data.positions });
        })
        
    }

    handleChangePosition = (e) => {
        //payCardTypeId:"",(e)
            if (e.length > 0) {
            this.state.selectedPosition= e[0].name
            this.state.selectedPositionId = e[0].id
        } else {
            this.state.selectedPosition = ""
            this.state.selectedPositionId = ""
        }
    }

    GetPayCardTypes = async () =>{
        const getParams = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.userinfo.clientId,
            "UserId":this.state.userinfo.userId,
            
        }
        await axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Maintenance/GetPayCardTypes", getParams)
        .then(res => {
            ////payCardTypeId:"",("Get Pay Card Types List Data")
            ////payCardTypeId:"",(res.data)
            const data = res.data
            this.setState({
                isloading:false,
                payCardTypeDDL : data.payCardTypes 
            });
        })
        
    }

    handleCoverChangePayCardType= (e) => {
        //payCardTypeId:"",(e)
        let paycardtype = ""
        if(e.length > 0) {
            this.state.payCardType = e[0].name
            this.state.payCardTypeId = e[0].id
        }else{
            this.state.payCardType = ""
            this.state.payCardTypeId = ""
        }
        //payCardTypeId:"",( this.state.payCardTypeId );
    }

    getLocation = async () =>{
        const locationParams = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.userinfo.clientId,
            "UserId":this.state.userinfo.userId,
            "ClientName": this.state.selectedClient,
            "City":"",
            "Province": "",
            "Region":""
        }

        await axios
            .post(
                 AppConfiguration.Setting().noserapiendpoint + "Client/GetClientLocations", locationParams)
            .then(res => {
                const data = res.data;
                //payCardTypeId:"",("Test 2");
                //payCardTypeId:"",(data);
                this.setState({branchDDL : data.locations})  
            })
    }

    handleCoverChangeBranch= (e) => {
        //payCardTypeId:"",(e)
        if(e.length == 0) {
            this.state.selectedLocation    =   ""
            this.state.selectedLocationId     =   ""
            return
        }
        this.state.selectedLocation     =   e[0].locationName
        this.state.selectedLocationId     =   e[0].id

    }

    GetEmployeeStatusList = async () =>{
        const getParams = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.userinfo.clientId,
            "UserId":this.state.userinfo.userId,
        }
    
        await axios
            .post(AppConfiguration.Setting().noserapiendpoint + "Maintenance/GetEmployeeStatusTypes", getParams)
            .then(res => {
                ////payCardTypeId:"",("Get Employee Status List ");
                ////payCardTypeId:"",(res.data);
                this.setState({
                    payStatusDDL : res.data.statusTypes
                })
            })
    
    }

    handleChangeEmployeeStatus = (e) => {
        //payCardTypeId:"",(e)
            if (e.length > 0) {
            this.state.selectedEmployeeStatus = e[0].name
            this.state.selectedEmployeeStatusId = e[0].id
        } else {
            this.state.selectedEmployeeStatus = ""
            this.state.selectedEmployeeStatusId = ""
        }
    }

    handleChangeDateHired = date => {
        //////console.log(date)
        this.setState({
            dateHired: date,
            isshow:false,
        })
    }

    handleChangeDateOfDeactivation = date => {
        //////console.log(date)
        this.setState({
            dateOfDeactivation: date,
            isshow:false,
        })
    }

    handleChangeContractDateStart = date => {
        //payCardTypeId:"",(date)
        this.setState({contractDateStart: date});
    }

    handleChangeContractDateEnd = date => {
        //payCardTypeId:"",(date)
        this.setState({contractDateEnd: date});
    }

    onChangePayCardNumber(e){
        //payCardTypeId:"",(e.target.value)
        this.setState({payCardNumber : e.target.value})
       
    }

    onChangeTMNProfiledId(e){
        //payCardTypeId:"",(e.target.value)
        this.setState({TMNProfiledId :e.target.value})
    }

    onChangeRate(e){
        //payCardTypeId:"",(e.target.value)
        this.setState({rate:e.target.value})
    }

    onChangeSeaValue(e){
        //payCardTypeId:"",(e.target.value)
        this.setState({seaValue:e.target.value})
    }

    onChangeECOLAValue(e){
        //payCardTypeId:"",(e.target.value)
        this.setState({ecolaValue:e.target.value})
    }

    onChangeCOLAValue(e){
        //payCardTypeId:"",(e.target.value)
        this.setState({colaValue:e.target.value})
    }

    onChangeRemarks(e){
        //payCardTypeId:"",(e.target.value)
        this.setState({remarks:e.target.value})
    }

    handleSaveClick = event => {
        this.setState({isloading:true})
        const addParams = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.selectedClientId,
            "UserId":this.state.userinfo.userId,
            "RoleId":this.state.userinfo.roleId,
            "EmployeeId":this.state.employeeId,
            "LocationId":this.state.selectedLocationId,
            "IsDefaultLocation":(this.state.isDefaultLocation)? "1" : "0",
            "EmployeeStatusTypeId":this.state.selectedEmployeeStatusId,
            "DateOfDeactivation":moment(this.state.dateOfDeactivation).format("MM/DD/YYYY" ),
            "PayCardTypeId":this.state.payCardTypeId,
            "PayCardNumber":this.state.payCardNumber,
            "TMNProfileId":this.state.TMNProfiledId,
            "ContractDateStart":moment(this.state.contractDateStart).format("MM/DD/YYYY" ),
            "ContractDateEnd":moment(this.state.contractDateEnd).format("MM/DD/YYYY" ),
            "SalaryOffered":this.state.rate,
            "SeaValue":this.state.seaValue,
            "ColaValue":this.state.colaValue,
            "EcolaValue":this.state.ecolaValue,
            "PositionId":this.state.selectedPositionId,
            "Remarks":this.state.remarks,
            "Changes":""
        }

        console.log("addParams")
        console.log(addParams)

        axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Administrator/SubmitEmployeeRecordForApproval",  addParams)
        .then(res => {
            const data = res.data;
            console.log("SubmitEmployeeRecordForApproval")
            console.log(data)
            if(data.status=="1"){
            this.setState({
                    isloading   :   false,
                    alerttype   :   "Success!",
                    isshow      :   true,
                    color       :   "success",
                    message     :   data.message,
                    fade        :   true
                });
            
            }
            else {
                this.setState({
                    isloading   :   false,
                    alerttype   :   "Error!",
                    isshow      :   true,
                    color       :   "danger",
                    message     :   data.message,
                    fade        :   true
                });
            }
        })
        .catch(error=>{
            this.setState({
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade            :   true
            })
        })
        
    }


    render() {
        return(
            <div>
                <Banner />
                    <Container className="mt-5" fluid>
                        <Card>
                            <Card.Header>MANNING >> EMPLOYEE RECORDS - (CREATE)</Card.Header>
                            <Card.Body>
                                <Form>
                                    <Alert isOpen={this.state.isshow} color={this.state.color} fade={this.state.fade} className={this.state.isshow ? 'display-block' : 'display-none'}>
                                        <div className={this.state.color}></div> 
                                        {this.state.message}
                                    </Alert>
                                    <Form.Group as={Row} controlId="formPlaintextEmail">
                                        <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                        CLIENT
                                        </Form.Label>
                                        <Col sm="4">
                                            <Typeahead 
                                                labelKey='name'
                                                id="basic-example"
                                                onChange={this.handleChangeClient}
                                                options={this.state.clientDDL}
                                                //placeholder="CLIENT"
                                            /> 
                                        </Col>
                                        <Col sm="2">
                                        </Col>
                                        <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                        EMPLOYEE
                                        </Form.Label>
                                        <Col sm="4">
                                           <Typeahead 
                                               labelKey='employeeName'
                                               id="basic-example"
                                               onChange={this.handleChangeEmployee}
                                               options={this.state.employeeDDL}
                                               //placeholder="EMPLOYEE"
                                            /> 
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} controlId="formPlaintextEmail">
                                        <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                        BRANCH
                                        </Form.Label>
                                        <Col sm="4">
                                            <Typeahead 
                                                labelKey='name'
                                                id="basic-example"
                                                onChange={this.handleCoverChangeBranch}
                                                options={this.state.branchDDL}
                                                selected={[this.state.selectedLocation]}
                                            />
                                        </Col>
                                        <Col sm="2">
                                        </Col>
                                        <Col sm="4">
                                            <Form.Check type="checkbox" 
                                                label="IS DEFAULT BRANCH" 
                                                name="isDefaultLocation"
                                                onChange={this.handleChangeBranch}
                                                style={{fontWeight : "bold",marginLeft:"2px"}}
                                                checked={this.state.isDefaultLocation}
                                            />
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} controlId="formPlaintextEmail">
                                        <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                        DATE HIRED
                                        </Form.Label>
                                        <Col sm="4">
                                            <DatePicker
                                                ref='dateHired'
                                                selected={this.state.dateHired}
                                                onChange={this.handleChangeDateHired}
                                                minDate={this.minDate}
                                                value={this.props.dateHired}
                                                dateFormat={"MM/dd/yyyy"}
                                                className="form-control"
                                                disabled
                                            />
                                        </Col>
                                        <Col sm="2">
                                        </Col>
                                        <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                        JOB POSITION
                                        </Form.Label>
                                        <Col sm="4">
                                            <Typeahead
                                                labelKey='name'
                                                id="basic-example"
                                                onChange={this.handleChangePosition}
                                                options={this.state.positionDDL}
                                                //placeholder="JOB POSITION"
                                                selected={[this.state.selectedPosition]}
                                            />
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} controlId="formPlaintextEmail">
                                        <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                        EMPLOYEE STATUS
                                        </Form.Label>
                                        <Col sm="4">
                                            <Typeahead
                                                labelKey='name'
                                                id="basic-example"
                                                onChange={this.handleChangeEmployeeStatus}
                                                options={this.state.payStatusDDL}
                                                //placeholder="EMPLOYEE STATUS"
                                                selected={[this.state.selectedEmployeeStatus]}
                                            />
                                        </Col>
                                        <Col sm="2">
                                        </Col>
                                        <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                        DATE OF DEACTIVATION
                                        </Form.Label>
                                        <Col sm="4">
                                            <DatePicker
                                                ref='dateOfDeactivation'
                                                selected={this.state.dateOfDeactivation}
                                                onChange={this.handleChangeDateOfDeactivation}
                                                minDate={this.minDate}
                                                value={this.props.dateOfDeactivation}
                                                dateFormat={"MM/dd/yyyy"}
                                                className="form-control"
                                            /> 
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} controlId="formPlaintextEmail">
                                        <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                        PAY CARD TYPE
                                        </Form.Label>
                                        <Col sm="4">
                                            <Typeahead
                                                labelKey='name'
                                                id="basic-example"
                                                onChange={this.handleCoverChangePayCardType}
                                                options={this.state.payCardTypeDDL}
                                                selected={[this.state.payCardType]}
                                                //placeholder="PAY CARD TYPE"
                                                
                                            /> 
                                        </Col>
                                        <Col sm="2">
                                        </Col>
                                        <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                        PAY CARD NUMBER
                                        </Form.Label>
                                        <Col sm="4">
                                            <Form.Control 
                                                type="text" 
                                                //placeholder="PAY CARD NUMBER" 
                                                value={this.state.payCardNumber}
                                                onChange={this.onChangePayCardNumber.bind(this)}
                                                autoComplete="off" 
                                                name="PayCardNumber"
                                                disabled
                                                //disabled = { this.state.selectedEducationAttainment}
                                            />
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} controlId="formPlaintextEmail">
                                        <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                        CONTRACT DATE START
                                        </Form.Label>
                                        <Col sm="4">
                                            <DatePicker
                                                ref='contractDateStart'
                                                selected={this.state.contractDateStart}
                                                onChange={this.handleChangeContractDateStart}
                                                minDate={this.minDate}
                                                value={this.props.contractDateStart}
                                                dateFormat={"MM/dd/yyyy"}
                                                className="form-control"
                                                disabled
                                            /> 
                                        </Col>
                                        <Col sm="2">
                                        </Col>
                                        <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                        CONTRACT DATE END
                                        </Form.Label>
                                        <Col sm="4">
                                            <DatePicker
                                                ref='contractDateEnd'
                                                selected={this.state.contractDateEnd}
                                                onChange={this.handleChangeContractDateEnd}
                                                minDate={this.minDate}
                                                value={this.props.contractDateEnd}
                                                dateFormat={"MM/dd/yyyy"}
                                                // placeholderText="CONTRACT DATE END"
                                                className="form-control"
                                            />  
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} controlId="formPlaintextEmail">
                                        <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                        RATE
                                        </Form.Label>
                                        <Col sm="4">
                                            <Form.Control 
                                                type="text" 
                                                // placeholder="RATE"  
                                                autoComplete="off" 
                                                name="Rate"
                                                value={this.state.rate}
                                                onChange={this.onChangeRate.bind(this)}
                                            />
                                        </Col>
                                        <Col sm="2">
                                        </Col>
                                        <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                        SEA VALUE
                                        </Form.Label>
                                        <Col sm="4">
                                            <Form.Control 
                                                type="text" 
                                                //placeholder="SEA VALUE"  
                                                autoComplete="off" 
                                                name="seaValue"
                                                value={this.state.seaValue}
                                                onChange={this.onChangeSeaValue.bind(this)}
                                            /> 
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} controlId="formPlaintextEmail">
                                        <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                        ECOLA VALUE
                                        </Form.Label>
                                        <Col sm="4">
                                            <Form.Control 
                                                type="text" 
                                                //placeholder="ECOLA VALUE"  
                                                autoComplete="off" 
                                                name="ecolaValue"
                                                value={this.state.ecolaValue}
                                                onChange={this.onChangeECOLAValue.bind(this)}
                                            />
                                        </Col>
                                        <Col sm="2">
                                        </Col>
                                        <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                        COLA VALUE
                                        </Form.Label>
                                        <Col sm="4">
                                            <Form.Control 
                                                type="text" 
                                                //placeholder="COLA VALUE"  
                                                autoComplete="off" 
                                                name="colaValue"
                                                value={this.state.colaValue}
                                                onChange={this.onChangeCOLAValue.bind(this)}
                                            />
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} controlId="formPlaintextEmail">
                                        <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                        REMARKS
                                        </Form.Label>
                                        <Col sm="11">
                                            <Form.Control 
                                                type="text" 
                                                // placeholder="REMARKS"  
                                                autoComplete="off" 
                                                name="remarks"
                                                value={this.state.remarks}
                                                onChange={this.onChangeRemarks.bind(this)}
                                            />
                                        </Col>
                                    </Form.Group>
                                        <ButtonToolbar sm={12} className="mt-3">
                                        <Button style={{minWidth:"60px",marginRight:"1pt"}} variant="success" className="ml-auto noser-button-mr1" onClick={this.handleSaveClick}>
                                            Submit
                                        </Button>
                                        <Button className="noser-button" style={{minWidth:"60px",marginRight:"1pt"}} variant="danger" href="/manningemployeerecord">
                                            Back
                                        </Button>
                                    </ButtonToolbar>
                                </Form>
                            </Card.Body>
                        </Card>
                        
                    </Container>
                    <NoserLoading show={this.state.isloading} /> 
            </div> 
            
        )
    }
}


export default ManningRecordCreate;