import {
    React,Component,BootstrapTable,
    paginationFactory,Button, ButtonToolbar, Card, 
    Form, Col, Row, axios, Container, Banner, Typeahead, TimePicker,
    cellEditFactory, Type, moment, NavLink, Alert, AppConfiguration,
    NoserLoading, Noser, sizePerPageRenderer, DatePicker
} 
from '../../noser-hris-component';
import { DropdownButton, Dropdown} from 'react-bootstrap';

import  TrainingRegisterModal from '../Training/TrainingModal/TrainingRegisterModal'
import { Input } from 'reactstrap';
 
class IncidentReportForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            regexp: /^[0-9\b]+$/,

            fade            :   true, 
            color           :   "",
            isshow          :   false,
            message         :   "",
            userinfo        :   [],
            isloading       :   false,
            alerttype       :   "",

            batchAutocomplete           :   [],
            clientAutocomplete          :   [],
            positionAutocomplete        :   [],
            trainingAutocomplete        :   [],
            scheduleAutocomplete        :   [],
            trainingRegisterTableList   :   [],
            batchNoAutocomplete         :   [],
            dateFrom                    :   new Date(),
            dateTo                      :   new Date(),
            modalTrainingRegisterShow   :   false,

            isLoading               :"",

            selectedEmployeeName    :[],
            selectedEmployeeId      :[],
            selectedEmployeeNo      :[],
            selectedEmployeeBranch  :[],

            getClientList           :[],
            getEmployeeList         :[],
            getEmployeeNoList       :[],

            client                  :"",
            position                :"",
            employeeName            :"",
            employeeNo              :"",

            nonEmpName              :"",
            nonEmpCompany           :"",
            nonEmpPosition          :"",

            dateReported            :"",
            dateOfIncident          :"",
            venueOfIncident         :"",
            timeOfIncident          :"",
            irReferenceNo           :"",
            question1               :"",
            question2               :"",
            question3               :"",
            question4               :"",

            reportedByClient        :"",
            reportedByEmployee        :"",
            reportedByPosition        :"",
            reportedByDept            :"",
            
            departmentCodeAutocomplete:"",
            

        }
    }

    componentDidMount(){
        const sleep = (milliseconds) => {return new Promise(resolve => setTimeout(resolve, milliseconds))}
        this.state.userinfo = JSON.parse(sessionStorage.getItem("userData"))

        this.setState({isLoading:true,loadingText:"Loading client list..."})

        this.state.userinfo = JSON.parse(sessionStorage.getItem("userData"))
        this.getClientList();;
        //this.GetDepartment();
    }
    GetDepartment() {
        const departmentCodeParams = {
            "IpAddress"     :   "0.0.0.0",
            "ClientId"      :   this.state.userinfo.clientId,
            "UserId"        :   this.state.userinfo.userId,
            "Code"          :   "",
            "Name"          :   ""
        };

        axios.post(AppConfiguration.Setting().noserapiendpoint + "Maintenance/GetDepartments",  departmentCodeParams)
        .then(res => {
            const data = res.data;
            console.log("Get Departments Code");
            console.log(data);
            console.log("Get Departments Code");
            this.setState({
                departmentCodeAutocomplete  :   data.departments
            });
        })

        console.log(this.state.departmentCodeAutocomplete)
    }

    getClientList(){
        this.setState({isloading:true})
        const getParams = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.userinfo.clientId,
            "UserId":this.state.userinfo.userId,
        }
        axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Client/GetClientList", getParams)
        .then(res => {
            console.log("Client List start ");
            console.log(res.data);
            console.log("Client List end");
            const data = res.data
            this.setState({
                isloading:false,
                getClientList : res.data.clients ? res.data.clients : []
            });
        })
        .catch(error=>{
           this.setState({
               isloading       :   false,
               alerttype       :   "Error!",
               isshow          :   true,
               color           :   "danger",
               message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
               fade            :   true
            })
       })
    }
    onChangeClientList = (e) => {
        if(e.length == 0) {
            this.state.selectedClientName=""
            this.state.selectedClientId=""
            return
        } 
        this.state.selectedClientId = e[0].id
        this.state.client = e[0].name
        this.setState({
            isloading:true,
            isshow:false,
            color:"",
            message:"",
            fade:true ,
        })
        this.getEmployees();
    }
    getEmployees(){
        this.setState({isloading:true})
        const getParams = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.selectedClientId,
            "UserId":this.state.userinfo.userId,
            "EmployeeName":"",
            "EmployeeNo":"",
            "ClientName":""
        }
        axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Employee/GetEmployees", getParams)
        .then(res => {
            console.log("Employee List start ");
            console.log(res.data);
            console.log("Employee List end");
            const data = res.data
            if(data.status=="1")
                this.setState({getEmployeeList : data.employees, getEmployeeNoList : data.employees,isloading:false,}) 
            else
                this.setState({getEmployeeList : [], getEmployeeNoList : [],isloading:false}) 
        })
        .catch(error=>{
            this.setState(  {
               isloading       :   false,
               alerttype       :   "Error!",
               isshow          :   true,
               color           :   "danger",
               message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
               fade            :   true
            })
        })
        
    }

    onChangeEmployeesList = (e) => {
       

        if(e.length==0)
        {
            this.setState({getEmployeeListData: null, selectedPayrollpayPeriod: '', selectedWorkScheduleIdParam: '', selectedPayrollPeriodsId : '',selectedEmployeeId : '',selectedEmployeeName : '',selectedPayrollPeriodsName : '', selectedWorkScheduleId: ''})
          
            return
        }
        this.setState({isloading:true,isshow:false,color:"",message:"",fade:true})

        this.state.selectedEmployeeId = e[0].id
        this.state.selectedEmployeeBranch = e[0].locationName
      
        this.state.employeeName = e[0].employeeName
        this.state.employeeNo = e[0].employeeNo
        this.state.position = e[0].position
       
        this.setState({isloading:false})
    }

    onChangeEmployeesNo=(e)=>{
        this.setState({selectedEmployeeNo : e.target.value})
        //console.log(e.target.value)
    }
    onChangePosition=(e)=>{
        this.setState({position : e.target.value})
        //console.log(e.target.value)
    }
    onChangeNonEmpName=(e)=>{
        this.setState({nonEmpName : e.target.value})
        console.log(e.target.value)
    }
    onChangeNonEmpCompany=(e)=>{
        this.setState({nonEmpCompany : e.target.value})
        console.log(e.target.value)
    }
    onChangeNonEmpPosition=(e)=>{
        this.setState({nonEmpPosition : e.target.value})
        console.log(e.target.value)
    }
    onChangeDateReported=(e, date)=>{
        this.setState({dateReported : e.target.value})
        //console.log(this.state.dateReported)
    }
    onChangeDateOfIncident=(e)=>{
        this.setState({dateOfIncident : e.target.value})
        //console.log(e.target.value)
    }
    onChangeVenueOfIncident=(e)=>{
        this.setState({venueOfIncident : e.target.value})
        //console.log(e.target.value)
    }
    onChangeTimeOfIncident=(e)=>{
        this.setState({timeOfIncident : e.target.value})
        //console.log(e.target.value)
    }
    onChangeIrReferenceNo=(e)=>{
        this.setState({irReferenceNo : e.target.value})
        //console.log(e.target.value)
    }
    onChangeQuestion1=(e)=>{
        this.setState({question1 : e.target.value})
        //console.log(e.target.value)
    }
    onChangeQuestion2=(e)=>{
        this.setState({question2 : e.target.value})
        //console.log(e.target.value)
    }
    onChangeQuestion3=(e)=>{
        this.setState({question3 : e.target.value})
        //console.log(e.target.value)
    }
    onChangeQuestion4=(e)=>{
        this.setState({question4 : e.target.value})
        //console.log(e.target.value)
    }
    onChangeReportedByClient=(e)=>{

        if(e.length == 0) {
            this.state.selectedClientName=""
            this.state.selectedClientId=""
            return
        } 
        this.state.reportedByClient = e[0].name
        this.setState({
            isloading:true,
            isshow:false,
            color:"",
            message:"",
            fade:true ,
        })
        
        this.getEmployees();

    }
    onChangeReportedEmployee=(e)=>{

        if(e.length==0)
        {
            this.setState({getEmployeeListData: null, selectedPayrollpayPeriod: '', selectedWorkScheduleIdParam: '', selectedPayrollPeriodsId : '',selectedEmployeeId : '',selectedEmployeeName : '',selectedPayrollPeriodsName : '', selectedWorkScheduleId: ''})
          
            return
        }
        this.setState({isloading:true,isshow:false,color:"",message:"",fade:true})

        this.state.reportedByEmployee = e[0].employeeName
        this.state.reportedByPosition = e[0].position

        this.setState({isloading:false})
    }
    onChangeReportedPosition=(e)=>{
        this.setState({reportedByPosition : e.target.value})
        //console.log(e.target.value)
    }
    onChangeReportedDept=(e)=>{
        this.setState({reportedByDept : e.target.value})
        //console.log(e.target.value)
    }
   
    handleGenerateIR=(e)=>{
        
        let createddate = moment(this.state.createddate).format('MM/DD/YYYY');

        let data = {
            "client"                :   this.state.client,
            "location"              :   this.state.selectedEmployeeBranch,
            "employeeName"          :   this.state.employeeName,
            "position"              :   this.state.position,
            "employeeNo"            :   this.state.employeeNo,
            "nonEmpName"            :   this.state.nonEmpName,
            "nonEmpCompany"         :   this.state.nonEmpCompany,
            "nonEmpPosition"        :   this.state.nonEmpPosition,
            "dateReported"          :   this.state.dateReported,
            "dateOfIncident"        :   this.state.dateOfIncident,
            "venueOfIncident"       :   this.state.venueOfIncident,
            "timeOfIncident"        :   this.state.timeOfIncident,
            "irReferenceNo"         :   this.state.irReferenceNo,
            "question1"             :   this.state.question1,
            "question2"             :   this.state.question2,
            "question3"             :   this.state.question3,
            "question4"             :   this.state.question4,
            "reportedByClient"      :   this.state.reportedByClient,
            "reportedByEmployee"    :   this.state.reportedByEmployee,
            "reportedByPosition"    :   this.state.reportedByPosition,
            "reportedByDept"        :   this.state.reportedByDept,
            "isDraft"               :   "0",
            "isDeleted"             :   "0",
            "createdby"             :   "user007",
            "createddate"           :   createddate,
            "modifiedby"            :   'user007',
            "modifieddate"          :   '0',
            "isModified"            :   '0',    
        }
        console.log(data)

        sessionStorage.setItem("data", JSON.stringify(data))
        let viewIRTmp = "1"
        sessionStorage.setItem("viewDraftMemoTmp", JSON.stringify(viewIRTmp))
        
     
    }
    handleSave=(e)=>{
        
        let createddate = moment(this.state.createddate).format('MM/DD/YYYY');

        let data = {
            "client"                :   this.state.client,
            "location"              :   this.state.selectedEmployeeBranch,
            "employeeName"          :   this.state.employeeName,
            "position"              :   this.state.position,
            "employeeNo"            :   this.state.employeeNo,
            "nonEmployee"           :   this.state.nonEmployee,
            "dateReported"          :   this.state.dateReported,
            "dateOfIncident"        :   this.state.dateOfIncident,
            "venueOfIncident"       :   this.state.venueOfIncident,
            "timeOfIncident"        :   this.state.timeOfIncident,
            "irReferenceNo"         :   this.state.irReferenceNo,
            "question1"             :   this.state.question1,
            "question2"             :   this.state.question2,
            "question3"             :   this.state.question3,
            "question4"             :   this.state.question4,
            "reportedByClient"      :   this.state.reportedByClient,
            "reportedByEmployee"    :   this.state.reportedByEmployee,
            "reportedByPosition"    :   this.state.reportedByPosition,
            "reportedByDept"        :   this.state.reportedByDept,
            "isDraft"               :   "0",
            "isDeleted"             :   "0",
            "createdby"             :   "user007",
            "createddate"           :   createddate,
            "modifiedby"            :   'user007',
            "modifieddate"          :   '0',
            "isModified"            :   '0',    
        }
        console.log(data)
        
        const addParams = {
          "_collection" : "TestIncidentReport",
          "doc_data"    : data
        }
          
        axios
        .post("http://134.209.99.190:8088/action/add", addParams)
        .then(res =>{
            const data = res.data
            console.log("success")
            console.log(data)
            this.setState({
                isloading       :   false,
                alerttype       :   "Success!",
                isshow          :   true,
                color           :   "success",
                message         :   "Data had successfully Save" ,
                fade            :   true,
                /* client          :"",
                selectedEmployeeBranch:"",
                employeeName:"",
                position:"",
                employeeNo:"",
                nonEmployee:"",
                dateReported:"",
                dateOfIncident:"",
                venueOfIncident:"",
                timeOfIncident:"",
                irReferenceNo:"",
                question1:"",
                question2:"",
                question3:"",
                question4:"",
                reportedByClient:"",
                reportedByEmployee:"",
                reportedByPosition:"",
                reportedByDept:"", */

            })
        })
        .catch(error =>{
            console.log("error: " + error)
            this.setState({
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade            :   true
            })
        })
    }

    render() {

    return(
            <div>
                <Banner />
                    <Container className="mt-5" fluid>
                        <Card>
                            <Card.Header>INCIDENT REPORT FORM</Card.Header>
                            <Card.Body>
                                <Form>
                                    <Alert isOpen={this.state.isshow} color={this.state.color} fade={this.state.fade} className={this.state.isshow ? 'display-block' : 'display-none'}>
                                        <div className={this.state.color}></div> 
                                        {this.state.message}
                                    </Alert>

                                    <Card.Header className="mt-5">PERSON/S TO BE REPORTED</Card.Header>

                                    <Form.Group as={Row} className="mt-3" controlId="formPlaintextEmail">
                                        <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                            PHRMPC Employee
                                        </Form.Label>
                                        <Col sm="3">
                                        </Col>
                                        <Col sm="3">`
                                        </Col>
                                    </Form.Group>

                                    <Form.Group as={Row} controlId="formPlaintextEmail">
                                        <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                            Client
                                        </Form.Label>
                                        <Col sm="6">
                                            <Typeahead
                                                labelKey='name'
                                                id="basic-example"
                                                onChange={this.onChangeClientList}
                                                options={this.state.getClientList}
                                                placeholder="Select Client"
                                            />
                                        </Col>

                                        <Col sm="1"></Col>

                                        <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                            Location
                                        </Form.Label>
                                        <Col sm="2">
                                            <Form.Control
                                                labelKey='name'
                                                id="basic-example"
                                                onChange={this.onChangeLocation}
                                                options={this.state.selectedEmployeeBranch}
                                                value={this.state.selectedEmployeeBranch}
                                                //placeholder="Select Client"
                                                autocomplete="off"
                                            />
                                        </Col>
                                    </Form.Group>

                                    <Form.Group as={Row} controlId="formPlaintextEmail">
                                        <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                            Employee
                                        </Form.Label>
                                        <Col sm="3">
                                            <Typeahead
                                                labelKey='employeeName'
                                                id="basic-example"
                                                onChange={this.onChangeEmployeesList}
                                                options={this.state.getEmployeeList}
                                                placeholder="Select Employee"
                                                autocomplete="false"
                                            />
                                        </Col>
                                        <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                            Emp No.
                                        </Form.Label>
                                        <Col sm="2">
                                            <Form.Control 
                                                type="text"
                                                name="selectedEmployeeName"
                                                //value={this.state.selectedEmployeeName}
                                                onChange={this.onChangePosition} 
                                                autoComplete="off"
                                                placeholder="Auto Fill"
                                            />
                                        </Col>                                       
                                    </Form.Group>

                                    <Form.Group as={Row} controlId="formPlaintextEmail">
                                        <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                            Position
                                        </Form.Label>
                                        <Col sm="3">
                                            <Form.Control 
                                                type="text"
                                                name="selectedEmployeeName"
                                                value={this.state.position}
                                                onChange={this.onChangePosition} 
                                                autoComplete="off"
                                                placeholder="Autofill"
                                            />
                                        </Col>
                                        <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                        </Form.Label>
                                        <Col sm="2">
                                        </Col>
                                        <Col sm="1">
                                        </Col>
                                        <Col sm="4">
                                        </Col>
                                    </Form.Group>

                                    <Form.Group as={Row} controlId="formPlaintextEmail">
                                        <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                           
                                        </Form.Label>
                                       {/*  <Col sm="3">
                                        <Button variant="success" style={{minWidth:'60px'}} >Add</Button>
                                        </Col> */}
                                        <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                        </Form.Label>
                                        <Col sm="2">
                                        </Col>
                                        <Col sm="1">
                                        </Col>
                                        <Col sm="4">
                                        </Col>
                                    </Form.Group>

                                    <Form.Group as={Row} className="mt-3" controlId="formPlaintextEmail">
                                        <Form.Label column sm="4" style={{fontWeight : "bold"}}>
                                            NON-Employee
                                        </Form.Label>                                       
                                    </Form.Group>
                                    <Form.Group as={Row} className="mt-3" controlId="formPlaintextEmail">
                                        <Col sm="3">
                                            <Form.Control 
                                                type="text"
                                                name="nonEmpName"
                                                //value={this.state.selectedEmployeeName}
                                                onChange={this.onChangeNonEmpName} 
                                                autoComplete="off"
                                                placeholder="Enter Name"
                                            />
                                        </Col>
                                        <Col sm="3">
                                            <Form.Control 
                                                type="text"
                                                name="nonEmpCompany"
                                                //value={this.state.selectedEmployeeName}
                                                onChange={this.onChangeNonEmpCompany} 
                                                autoComplete="off"
                                                placeholder="Enter Company"
                                            />
                                        </Col>
                                        <Col sm="3">
                                            <Form.Control 
                                                type="text"
                                                name="nonEmpPosition"
                                                //value={this.state.selectedEmployeeName}
                                                onChange={this.onChangeNonEmpPosition} 
                                                autoComplete="off"
                                                placeholder="Enter Position"
                                            />
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} className="mt-3" controlId="formPlaintextEmail">
                                    {/* <Col sm="1">
                                        <Button variant="success" style={{minWidth:'60px', }}>Add</Button>
                                    </Col> */}
                                    </Form.Group>

                                    <Card.Header className="mt-5">DATE / TIME / VENUE</Card.Header>

                                     <Form.Group as={Row} className="mt-4" controlId="formPlaintextEmail">
                                        <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                            Date Reported
                                        </Form.Label>
                                        <Col sm="3">
                                            <Input
                                                //ref='dateReported'
                                                //selected={this.state.dateReported}
                                                //onSelect={this.selectDateReported}
                                                onChange={this.onChangeDateReported}    
                                                //minDate={this.minDate}
                                                value={this.state.dateReported}
                                                //dateFormat={"MM/dd/yyyy"}
                                                className="form-control"
                                            />
                                          
                                        </Col>
                                        <Col sm="2">
                                        </Col>
                                        <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                            Time Of Incident
                                        </Form.Label>
                                        <Col sm="3">
                                            <Form.Control 
                                                type="text"
                                                name="selectedEmployeeName"
                                                value={this.state.timeOfIncident}
                                                onChange={this.onChangeTimeOfIncident} 
                                                autoComplete="off"
                                            />
                                        </Col>
                                    </Form.Group>

                                    <Form.Group as={Row} controlId="formPlaintextEmail">
                                        <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                            Date Of Incident
                                        </Form.Label>
                                        <Col sm="3">
                                            <Form.Control 
                                                type="text"
                                                name="selectedEmployeeName"
                                                value={this.state.dateOfIncident}
                                                onChange={this.onChangeDateOfIncident} 
                                                autoComplete="off"
                                            />
                                        </Col>
                                        <Col sm="2">
                                        </Col>
                                        <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                            IR Reference No.
                                        </Form.Label>
                                        <Col sm="3">
                                            <Form.Control 
                                                type="text"
                                                name="selectedEmployeeName"
                                                value={this.state.irReferenceNo}
                                                onChange={this.onChangeIrReferenceNo} 
                                                autoComplete="off"
                                                placeholder="System Generated"
                                            />
                                        </Col>
                                    </Form.Group>

                                    <Form.Group as={Row} controlId="formPlaintextEmail">
                                        <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                            Venue Of Incident
                                        </Form.Label>
                                        <Col sm="10">
                                            <Form.Control 
                                                controlId="formBasicEmail"
                                                type="text"
                                                name="selectedEmployeeName"
                                                value={this.state.venueOfIncident}
                                                onChange={this.onChangeVenueOfIncident} 
                                                autoComplete="off"
                                            />
                                        </Col>
                                    </Form.Group>

                                    <Card.Header className="mt-5">BRIEF DESCRIPTION OF INCIDENT</Card.Header>


                                    <Form.Label style={{fontWeight : "bold"}} className="mt-4">
                                        1. What happened?
                                    </Form.Label>
                                    <Form.Control 
                                        type="text"
                                        name="selectedEmployeeName"
                                        value={this.state.question1}
                                        onChange={this.onChangeQuestion1} 
                                        autoComplete="off"
                                    />

                                    <Form.Label className="mt-3" style={{fontWeight : "bold"}}>
                                        2. What physical evidence or document exist to support your report?
                                    </Form.Label>
                                    <Form.Control 
                                        type="text"
                                        name="selectedEmployeeName"
                                        value={this.state.question2}
                                        onChange={this.onChangeQuestion2} 
                                        autoComplete="off"
                                    />

                                    <Form.Label className="mt-3" style={{fontWeight : "bold"}}>
                                        3. How did you know the incident and who are the possible witness/es?
                                    </Form.Label>
                                    <Form.Control 
                                        type="text"
                                        name="selectedEmployeeName"
                                        value={this.state.question3}
                                        onChange={this.onChangeQuestion3} 
                                        autoComplete="off"
                                    />

                                    <Form.Label className="mt-3" style={{fontWeight : "bold"}}>
                                        4. Is there money involved ? If yes please specify the estimated amount?
                                    </Form.Label>
                                    <Form.Control 
                                        type="text"
                                        name="selectedEmployeeName"
                                        value={this.state.question4}
                                        onChange={this.onChangeQuestion4} 
                                        autoComplete="off"
                                    />

                                    <Card.Header className="mt-5">REPORTED BY (option to autofill based on log in details)</Card.Header>

                                    <Form.Group as={Row} className="mt-5" controlId="formPlaintextEmail">
                                        <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                            Client
                                        </Form.Label>
                                        <Col sm="11">
                                            <Typeahead
                                                labelKey='name'
                                                id="basic-example"
                                                onChange={this.onChangeReportedByClient}
                                                options={this.state.getClientList}
                                                placeholder="Select Client"
                                            />
                                        </Col>
                                    </Form.Group>

                                    <Form.Group as={Row} className="mt-3" controlId="formPlaintextEmail">
                                        <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                            Employee
                                        </Form.Label>
                                        <Col sm="4">
                                            <Typeahead
                                                labelKey='employeeName'
                                                id="basic-example"
                                                onChange={this.onChangeReportedEmployee}
                                                options={this.state.getEmployeeList}
                                                placeholder="Select Employee"
                                                autocomplete="false"
                                            />
                                        </Col>
                                        <Col sm="1">
                                        </Col>
                                        <Col sm="1">
                                        </Col>
                                        <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                            Position 
                                        </Form.Label>
                                        <Col sm="2">
                                            <Form.Control 
                                                type="text"
                                                name="reportedByPosition"
                                                value={this.state.reportedByPosition}
                                                onChange={this.onChangeReportedPosition} 
                                                autoComplete="off"
                                                placeholder="Auto Fill"
                                            />
                                        </Col>
                                      
                                    </Form.Group>
                                   
                                    <Form.Group as={Row} controlId="formPlaintextEmail" className="mt-4">
                                        <ButtonToolbar sm={1}>
                                            <Col >
                                                <ButtonToolbar className="mt-5">
                                                    <Button className="ml-auto" variant="success" onClick = {this.handleSave} style={{minWidth:'60px'}}>Save</Button>&nbsp;&nbsp;&nbsp;
                                                    <Button  href="/trainingschedule" variant="danger" style={{minWidth:'60px'}}>Back</Button>
                                                </ButtonToolbar>                                          
                                            </Col>                                                                      
                                        </ButtonToolbar>
                                        <ButtonToolbar sm={2}>
                                            <Col className="ml-auto">
                                                <ButtonToolbar className="mt-5">
                                                    <NavLink to="/ExportIncidentReport">
                                                        <Button className="mr-auto" variant="success" onClick={this.handleGenerateIR} href="/ExportIncidentReport" style={{minHeight:"18px",minWidth:'60px', marginLeft:"1200px", marginTop:"-1px"}}>Generate IR</Button>&nbsp;&nbsp;
                                                    </NavLink>
                                                </ButtonToolbar>                                          
                                            </Col>                                                                      
                                        </ButtonToolbar>                                        
                                    </Form.Group>
                                  

                                </Form>
                            </Card.Body>
                        </Card>
                       
                    </Container>
                    <NoserLoading show={this.state.isloading} />

                    <TrainingRegisterModal 
                        show={this.state.modalTrainingRegisterShow}
                        onHide={this.handleModalClose}
                        onRefModal={ref => (this.child = ref)}
                    />
            </div> 
        )
    }

}

export  default IncidentReportForm
