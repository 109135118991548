import React from 'react';
import { DataGrid, Column } from 'devextreme-react/data-grid';
// import ArrayStore from 'devextreme/data/array_store';
// import DataSource from 'devextreme/data/data_source';
// import service from './data.js';

// const tasks = service.getTasks();

class DetailTemplate extends React.Component {
  constructor(props) {
    super(props);
    this.state ={
      DataSource : [],
      ArrayStore : []
    }
    this.dataSource = /* getTasks( */props.data.data/* ) */;
    console.log("this.dataSource")
    console.log(this.dataSource)
  }

  customizeColumns = (cols) => {
    let colHide = ["id","isDeleted","dateResigned","dateHired","contractDateEnd","contractDateStart","reasonForLeaving","periodCovered","salaryRate","profileId","clearingId","headerId","userId"]
    cols.forEach((fld,idx) => {
      // // // // console.log('fld')
      // // // // console.log(fld)
      if(colHide.includes(fld.dataField)){
          fld.visible = false
      } else {
          fld.visible = true
      }
    })
  }
  render() {
   //const { FirstName, LastName } = this.props.data.data;
    let subDetails = this.dataSource
    return (
      <React.Fragment>
        <DataGrid
          keyExpr="id"
          dataSource={subDetails.clearanceDetails}
          showBorders={true}
          columnAutoWidth={true}
          customizeColumns={this.customizeColumns}
        >
        </DataGrid>
      </React.Fragment>
    );
  }
}

export default DetailTemplate;