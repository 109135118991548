import { ThreeSixtyTwoTone } from '@material-ui/icons';
import {
    React,Component, BootstrapTable, Redirect,
    paginationFactory,Button, ButtonToolbar, Card, 
    Form, Col, Row, axios, Container, Banner, Typeahead, TimePicker,
    cellEditFactory, Type, moment, NavLink, Alert, AppConfiguration,
    NoserLoading, Noser, sizePerPageRenderer, useState, Tabs, Tab, DatePicker
} 
from '../../noser-hris-component';
import NoserGrid from './BillingSummaryTable'

class CustomBillingSummaryReport extends Component {
    constructor(props) {
        super(props)
        this.state = {
            fade            :   true,
            color           :   "",
            isshow          :   false,
            message         :   "",
            userinfo        :   [],
            isloading       :   true,
            alerttype       :   "",
            loadingText     :   undefined,

            clientId        :   "",
            clientName      :   "",
            payperiodId     :   "",
            payPeriod       :  "",
            isSpecial       :   "0",

            typeId          :   "",
            disableType     :   true,
            referenceId     :   "",

            clientDDL       :   [],
            periodDDL       :   [],
            typeDDL         :   [
                {"id":"1","name":"PER BRANCH"},
                {"id":"2","name":"PER POSITION"}
            ],
            bpDDL           :   [],
            billingGridData :   [],
            salaryDDL       :   [],
            fromSalary      :   "",
            toSalary        :   "",
            invoiceNo       :   "",
            disablefinalized:   true,

            costcenterId    :   "",
            costCenterDDL   :   [],
            startDate       :   "",
            endDate         :   ""
        }
    }

    componentDidMount() {
        this.state.userinfo = JSON.parse(sessionStorage.getItem("userData"))
        this.GetClients()
    }
    handleStartDateChanged = date => {
        this.setState({startDate:date})
    };
    handleEndDateChanged = date => {
        this.setState({endDate:date})
    };
    GetClients = async() =>{
        this.setState({isloading:true})
        const params = {
            "IpAddress"     :   "0.0.0.0",
            "ClientId"      :   this.state.userinfo.clientId,
            "UserId"        :   this.state.userinfo.userId,
        }
        
        await axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Client/GetClientList", params)
        .then(res => {
            const data = res.data
            let arr = []
            if(this.state.userinfo.roleId!=="1"){
                let fieldaccess = JSON.parse(sessionStorage.getItem("userControlAccess"))
                if(fieldaccess.filter(x=>x.accessCode==="0001").length>0){
                    let access = fieldaccess.find(x=>x.accessCode==="0001" && x.controlAccessDetails.length>0)
                    console.log("access")
                    console.log(access)
                    if(access.controlAccessDetails.filter(x=>x.isClient==="1").length>0){
                        let brr = access.controlAccessDetails.filter(x=>x.isClient==="1")
                        console.log("brr")
                        console.log(brr)
                        arr = data.clients.filter(f => brr.some(item => item.referenceId === f.id))
                    }
                }
                this.setState({clientDDL : arr, isloading:false})
            }
            else{
                this.setState({clientDDL : data.clients, isloading:false})
            }
            //this.setState({clientDDL:res.data.clients ? res.data.clients:[],isloading:false})
        })
        .catch(error=>{
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade        :   true
            })
        })
    }
    GetPayPeriodList = async() =>{
        const params = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.clientId,
            "UserId":this.state.userinfo.userId,
            "IsProcessed":"1",
            "IsSpecial" : this.state.isSpecial
         };
        await axios
             .post(AppConfiguration.Setting().noserapiendpoint + "Billing/GetBillingPeriods", params)
             .then(res => {
                const data = res.data;
                this.setState({periodDDL: data.billingPeriods,isloading:false })
             })
    }
    handleChangedClient = (e) => {
        this.setState({isshow:false,message:"",color:"",alerttype:""})
        if(e.length == 0) {
            this.state.clientId=""
            this.state.clientName=""
            this.state.isSpecial="0"
            this.state.disableType=true
            return
        } 
        this.state.clientId=e[0].id
        this.state.clientName=e[0].name
        this.state.isSpecial=e[0].isSpecial
        //this.GetPayPeriodList()
        this.GetSalaryList()
        this.state.disableType=true
    }
    handleChangedPeriod = (e) =>{
        this.setState({alerttype:'',isshow:false,color:'',message:'',fade:true})
        if(e.length == 0) {
            this.state.payperiodId=""
            this.state.payPeriod=""
        return
        } 
        this.state.payperiodId = e[0].periodId
        this.state.payPeriod = e[0].payPeriod
        this.state.disableType=false
        this.GetSalaryList()
    }
    GetSalaryList = async() =>{
        this.setState({isloading:true})
        const params = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.clientId,
            "UserId":this.state.userinfo.userId,
            "PayPeriodId": this.state.payperiodId
         };
         
        await axios
             .post(AppConfiguration.Setting().noserapiendpoint + "Billing/GetSalaries", params)
             .then(res => {
                const data = res.data;
                this.setState({salaryDDL: data.salaries })
             })
        this.GetCostCenters()
    }
    GetCostCenters = async() =>{
        const params = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.clientId,
            "UserId":this.state.userinfo.userId
         };
         
        await axios
             .post(AppConfiguration.Setting().noserapiendpoint + "Billing/GetCostCenters", params)
             .then(res => {
                const data = res.data;
                this.setState({costCenterDDL: data.costCenters,isloading:false })
             })
    }
    handleChangedType = (e) =>{
        var url = "";
        var typeId = "";
        if(e.length===0) {
            this.setState({ bpDDL: [] });
            return;
        }
        typeId = e[0].id
        
        let params = {}
        if(typeId=="1"){
            url = AppConfiguration.Setting().noserapiendpoint + "Client/GetClientLocations";
            params = {
                "IpAddress":"0.0.0.0",
                "ClientId":this.state.userinfo.clientId,
                "UserId":this.state.userinfo.userId,
                "ClientName": this.state.clientName,
                };
        }
            
        if(typeId=="2"){
            url = AppConfiguration.Setting().noserapiendpoint + "Billing/GetPositions";
            params = {
                "IpAddress":"0.0.0.0",
                "ClientId":this.state.clientId,
                "UserId":this.state.userinfo.userId,
                "PayPeriodId": this.state.payperiodId,
                };
        }
            
        
        axios
            .post(url,  params)
            .then(res => {
                const data = res.data;
                if(typeId=="1")
                    this.setState({ bpDDL: data.locations,typeId:typeId })
                if(typeId=="2")
                    this.setState({ bpDDL: data.positions,typeId:typeId })
            })
            .catch(error=>{
            this.setState(  {
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade            :   true
            })
        })
    }
    handleChangedReference = (e) =>{
        this.setState({alerttype:'',isshow:false,color:'',message:'',fade:true})
        if(e.length == 0) {
            this.state.referenceId=""
        return
        } 
        this.state.referenceId = e[0].id
    }
    handleChangedCostCenter = (e) =>{
        this.setState({alerttype:'',isshow:false,color:'',message:'',fade:true})
        if(e.length == 0) {
            this.state.costcenterId=""
        return
        } 
        this.state.costcenterId = e[0].id
    }
    handleSearchBilling = async()=>{
        this.setState({isloading:true})

        const params = {
            "IpAddress"     : "0.0.0.0",
            "ClientId"      : this.state.clientId,
            "UserId"        : this.state.userinfo.userId,
            "PayPeriodId"   : this.state.payperiodId,
            "TypeId"        : this.state.typeId,
            "ReferenceId"   : this.state.referenceId,
            "CostCenterId"   : this.state.costcenterId,
            "FromSalary"    : this.state.fromSalary,
            "ToSalary"      : this.state.toSalary,
            "IsProcessed"   : "1",
            "InvoiceNo"     : this.state.invoiceNo,
            "IsCustom"      : "1",
            "StartDate"     : this.FormatDate(this.state.startDate),
            "EndDate"       : this.FormatDate(this.state.endDate)
        }
        await axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Billing/GetClientCustomBillings", params)
        .then(res => {
            const data = res.data
            if(data.status==="0"){
                this.setState({
                    isloading   :   false,
                    alerttype   :   "Error! ",
                    isshow      :   true,
                    color       :   "danger",
                    message     :   data.message,
                    fade        :   true,
                })
            }
            this.setState({
                isloading:false,
                isshow:false,
                message:"",
                billingGridData:data.clientBillings,
                loadingText:undefined,
                disablefinalized:data.clientBillings.length>0 ? false:true
            })

        })
        .catch(error=>{
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade        :   true
            })
        })
    }
    handleExportBilling = async()=>{
        this.setState({isloading:true})

        const params = {
            "IpAddress"     : "0.0.0.0",
            "ClientId"      : this.state.clientId,
            "UserId"        : this.state.userinfo.userId,
            "PayPeriodId"   : this.state.payperiodId,
            "TypeId"        : this.state.typeId,
            "ReferenceId"   : this.state.referenceId,
            "IsProcessed"   : "1",
            "StartDate"     : this.state.startDate,
            "EndDate"       : this.state.endDate,
        }
        await axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Billing/GetClientCustomBillings", params)
        .then(res => {
            const data = res.data
            if(data.status==="0"){
                this.setState({
                    isloading   :   false,
                    alerttype   :   "Error! ",
                    isshow      :   true,
                    color       :   "danger",
                    message     :   data.message,
                    fade        :   true,
                })
            }
            this.setState({
                isloading:false,
                isshow:false,
                message:"",
                billingGridData:data.clientBillings,
                loadingText:undefined,
                disablefinalized:data.clientBillings.length>0 ? false:true
            })

        })
        .catch(error=>{
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade        :   true
            })
        })
    }
    handleChangedFromSalary = (e) =>{
        this.setState({alerttype:'',isshow:false,color:'',message:'',fade:true})
        if(e.length == 0) {
            this.state.fromSalary=""
        return
        } 
        this.state.fromSalary = e[0].salary
    }
    handleChangedToSalary = (e) =>{
        this.setState({alerttype:'',isshow:false,color:'',message:'',fade:true})
        if(e.length == 0) {
            this.state.toSalary=""
        return
        } 
        this.state.toSalary = e[0].salary
    }
    handleChangedInvoiceNo = (e) =>{
        this.setState({alerttype:'',isshow:false,color:'',message:'',fade:true})
        this.setState({ invoiceNo: e.target.value} );
    }
    handleUnFinalizedBilling = async()=>{
	
	if (!window.confirm('Do you want to unfinalize the selected billing period?'))
            return
        if (!window.confirm('Are you really sure?'))
            return
        
        this.setState({isloading:true,alerttype:'',isshow:false,color:'',message:'',fade:true,disablefinalized:true})
        let iscontinue = true
        let cnt = 1;
        let total = this.state.billingGridData.length-1;
        for (const itm of this.state.billingGridData){
            const billparams = {
                "IpAddress"     : "0.0.0.0",
                "ClientId"      : this.state.clientId,
                "UserId"        : this.state.userinfo.userId,
                "PayPeriodId"   : this.state.payperiodId,
                "TypeId"        : this.state.typeId,
                "ReferenceId"   : this.state.referenceId,
                "EmployeeId"    : itm.employeeId,
                "BillingId"     : itm.id,
                "InvoiceNo"     : this.state.invoiceNo,
                "StartDate"     : this.state.startDate,
                "EndDate"       : this.state.endDate,
            }
            if(!iscontinue){return false}
            await axios
            .post(AppConfiguration.Setting().noserapiendpoint + "Billing/UnFinalizedCustomBilling",  billparams)
            .then(res => {
                const data = res.data;
                if(data.status==="0"){
                    iscontinue = false
                    this.setState({
                            isloading   :   false,
                            alerttype   :   "Error!",
                            isshow      :   true,
                            color       :   "danger",
                            message     :   data.message,
                            fade        :   true,
                            loadingText :   undefined
                        })
                }
                this.setState({loadingText:"Removing finalized billing " + cnt.toString() + " of " + total.toString() + " employees billing. . ."})
                cnt++;
            })
            .catch(error=>{
                this.setState({
                    isloading   :   false,
                    alerttype   :   "Error!",
                    isshow      :   true,
                    color       :   "danger",
                    message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                    fade        :   true
                })
                return
            })
        }
        this.setState({
            isloading   :   false,
            alerttype   :   "Success! ",
            isshow      :   true,
            color       :   "success",
            message     :   "Employees billing successfully unfinalized.",
            fade        :   true,
            loadingText :   undefined
        })
    }
    FormatDate(date) {
        let m = moment(date, 'MM-DD-YYYY');
        return (m.isValid() ? (date.getMonth()+1 + "/" + date.getDate() + "/" + date.getFullYear()) : "")
    }
    render() {
        
        return(
            <div>
                <Banner />
                <Container className="mt-5" fluid>
                    <Card>
                        <Card.Header>Billing >> Billing Summary (Custom)</Card.Header>
                        <Card.Body>
                            <Alert isOpen={this.state.isshow} color={this.state.color} fade={this.state.fade} className={this.state.isshow ? 'display-block' : 'display-none'}>
                                <div className={this.state.color}></div> 
                                {this.state.message}
                            </Alert>
                            <Form.Row>
                                <Form.Group as={Col} sm={12} controlId="formGridEmail">
                                    <Typeahead
                                        labelKey='name'
                                        id="basic-example"
                                        onChange={this.handleChangedClient}
                                        options={this.state.clientDDL}
                                        placeholder="Select Client"
                                    />
                                </Form.Group>
                            </Form.Row>
                            <Form.Row>
                                {/* <Form.Group as={Col} sm={12} controlId="formGridEmail">
                                    <Typeahead
                                        labelKey='payPeriod'
                                        id="basic-example"
                                        onChange={this.handleChangedPeriod}
                                        options={this.state.periodDDL}
                                        placeholder="Select Period"
                                    />
                                </Form.Group> */}
                                <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                        START DATE
                                    </Form.Label>
                                    <Form.Group as={Col} sm={2} controlId="formGridEmail">
                                        <DatePicker
                                            ref='startDate'
                                            selected={this.state.startDate}
                                            onChange={this.handleStartDateChanged}
                                            minDate={this.minDate}
                                            value={this.props.startDate}
                                            dateFormat={"MM/dd/yyyy"}
                                            className="form-control"
                                        />
                                    </Form.Group>
                                    <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                        END DATE
                                    </Form.Label>
                                    <Form.Group as={Col} sm={2} controlId="formGridPassword">
                                        <DatePicker
                                                ref='startDate'
                                                selected={this.state.endDate}
                                                onChange={this.handleEndDateChanged}
                                                minDate={this.minDate}
                                                value={this.props.endDate}
                                                dateFormat={"MM/dd/yyyy"}
                                                className="form-control"
                                            />
                                    </Form.Group>
                            </Form.Row>
                            <Form.Row>
                                <Form.Group as={Col} sm={12} controlId="formGridEmail">
                                    <Typeahead
                                        labelKey='name'
                                        id="basic-example"
                                        onChange={this.handleChangedCostCenter}
                                        options={this.state.costCenterDDL}
                                        placeholder="Select Cost Center"
                                        disabled={this.state.disableType}
                                    />
                                </Form.Group>
                            </Form.Row>
                            <Form.Row>
                                <Form.Group as={Col} sm={12} controlId="formGridEmail">
                                    <Typeahead
                                        labelKey='name'
                                        id="basic-example"
                                        onChange={this.handleChangedType}
                                        options={this.state.typeDDL}
                                        placeholder="Select Type"
                                        disabled={this.state.disableType}
                                    />
                                </Form.Group>
                            </Form.Row>
                            <Form.Row>
                                <Form.Group as={Col} sm={12} controlId="formGridEmail">
                                    <Typeahead
                                        labelKey='name'
                                        id="basic-example"
                                        onChange={this.handleChangedReference}
                                        options={this.state.bpDDL}
                                        placeholder="Select Branch / Position"
                                        disabled={this.state.disableType}
                                    />
                                </Form.Group>
                            </Form.Row>
                            <Form.Row>
                                <Form.Group as={Col} sm={6} controlId="formGridEmail">
                                    <Typeahead
                                        labelKey='salary'
                                        id="basic-example"
                                        onChange={this.handleChangedFromSalary}
                                        options={this.state.salaryDDL}
                                        placeholder="Select From Salary"
                                        disabled={this.state.disableType}
                                    />
                                </Form.Group>
                                <Form.Group as={Col} sm={6} controlId="formGridEmail">
                                    <Typeahead
                                        labelKey='salary'
                                        id="basic-example"
                                        onChange={this.handleChangedToSalary}
                                        options={this.state.salaryDDL}
                                        placeholder="Select To Salary"
                                        disabled={this.state.disableType}
                                    />
                                </Form.Group>
                            </Form.Row>
                            <Form.Row>
                                <Form.Group as={Col} sm={12} controlId="formGridEmail">
                                    <Form.Control 
                                        type="text" 
                                        autoComplete="off" 
                                        name="invoiceNo"
                                        value={this.state.invoiceNo}
                                        ref="invoiceNo"
                                        onChange={this.handleChangedInvoiceNo}
                                        disabled={this.state.disableType}
                                        placeholder="Enter Invoice No"
                                    />
                                </Form.Group>
                            </Form.Row>
                            <ButtonToolbar>
                            {/* <Button  className="noser-button" variant="success" onClick={this.handleExportBilling}>EXPORT</Button> */}
                            <Button  className="ml-auto noser-button" variant="success" onClick={this.handleSearchBilling}>SEARCH</Button>
                            </ButtonToolbar>
                            <div className="mt-2">
                                <NoserGrid data={this.state.billingGridData} 
                                    exportCSV={true}
                                    pageSize={200}
                                />
                            </div>
                            <ButtonToolbar>
                                <Button disabled={this.state.disablefinalized}  className="ml-auto noser-button" variant="primary" onClick={this.handleUnFinalizedBilling}>UNFINALIZE BILLING</Button>
                            </ButtonToolbar>
                        </Card.Body>
                    </Card>
                </Container>
                <NoserLoading show={this.state.isloading} text={this.state.loadingText}/>
            </div>
        )

    }

}

export  default CustomBillingSummaryReport