import {
    React,Component,BootstrapTable,
    paginationFactory,Button, ButtonToolbar, Card, 
    Form, Col, Row, axios, Container, Banner, Typeahead, TimePicker,
    cellEditFactory, Type, moment, NavLink, Alert, AppConfiguration,
    NoserLoading, Noser, sizePerPageRenderer, DatePicker
} 
from '../../../noser-hris-component';
import { DropdownButton, Dropdown} from 'react-bootstrap';
import ToolkitProvider, { CSVExport } from 'react-bootstrap-table2-toolkit';
import 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css';

class HMO extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fade            :   true,
            color           :   "",
            isshow          :   false,
            message         :   "",
            userinfo        :   [],
            isloading       :   false,
            alerttype       :   "",

            clientDDL: [],
            clientId : "",
            employeeDDL : [],
            employeeId : "",
            clientLocationDDL : [],
            branchId : "",
            employeeStatusDDL : [
                {
                    "id":"0",
                    "name":"ACTIVE"
                },
                {
                    "id":"1",
                    "name":"INACTIVE"
                },
            ],
            statusId : "",
            hmoDDL:[
                {
                    "id":"0",
                    "name":"W/O"
                },
                {
                    "id":"1",
                    "name":"WITH"
                },
                {
                    "id":"2",
                    "name":"WAIVER"
                },
                {
                    "id":"3",
                    "name":"DEACTIVATE"
                }
            ],
            hmoId : "",
            monitoringDG: [],
            
        }
    }
    
    componentDidMount(){
        this.state.userinfo = JSON.parse(sessionStorage.getItem("userData"))
        //this.GetMonitoring();
        this.GetClients();
        //this.GetEmploymentStatus();
    }

    GetMonitoring = async() => {
        this.setState({isloading:true})
        const param = {
            "IpAddress"         : "0.0.0.0",
            "UserId"            : this.state.userinfo.userId,
            "ClientId"          : "",
            "EmployeeId"        : "",
            "LocationId"        : "",
            "StatusId"          : "",
            "RequestTypeId"     : "",
        };

        console.log("Start Get Params")
        console.log(param)
        await axios
        .post(AppConfiguration.Setting().noserapiendpoint + "ContractReport/GetContractHMO",  param)
        .then(res => {
            const data = res.data;
            console.log("contractHMO")
            console.log(data)
            this.setState({monitoringDG:data.contractHMO,isloading:false});
        })
        .catch(error=>{
            this.setState(  {
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade            :   true
            })
        })
    }

    GetClients = async() => {
        this.setState({isloading:true})
        const param = {
            "IpAddress"     :   "0.0.0.0",
            "ClientId"      :   this.state.userinfo.clientId,
            "UserId"        :   this.state.userinfo.userId,
        };

        await axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Client/GetClientList",  param)
        .then(res => {
            const data = res.data;
            this.setState({clientDDL:data.clients,isloading:false});
        })
        .catch(error=>{
            this.setState(  {
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade            :   true
            })
        })
    }

    onChangeClient = (e) => {
        if(e.length == 0) {
            this.state.clientId=""
            this.state.clientName=""
            return
        } 
        this.state.clientId = e[0].id
        this.state.clientName = e[0].name

        this.GetEmployees();
        this.GetClientLocation();
    }

    GetClientLocation(){

        const getParams = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.clientId,
            "UserId":this.state.userinfo.userId,
            "ClientName": this.state.clientName,
            "City": "",
            "Province": "",
            "Region": ""
    
        }
        axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Client/GetClientLocations", getParams)
        .then(res => {
            const data = res.data;
            console.log("GetClientLocations")
            console.log(data)
            this.setState({clientLocationDDL : data.locations})
        })
        .catch(error=>{
           this.setState(  {
               isloading       :   false,
               alerttype       :   "Error!",
               isshow          :   true,
               color           :   "danger",
               message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
               fade            :   true
           })
       })
    }

    onChangeClientLocation = (e) => {
        if(e.length == 0) {
            this.state.branchId=""
            return
        } 
        this.state.branchId = e[0].id
    }

    GetEmployees =() =>{
        this.setState({isloading:true})
        const getParams = {
            "IpAddress"     :   "0.0.0.0",
            "ClientId"      :   this.state.clientId,
            "UserId"        :   this.state.userinfo.userId,
        }
        axios
        .post( AppConfiguration.Setting().noserapiendpoint + "Employee/GetEmployeeProfileFilter",  getParams)
        .then(res => {
            const data = res.data
            console.log("GetEmployees")
            console.log(data)
            this.setState({
                employeeDDL        :   data.profiles,
                isloading           :   false,
            })  
        })
        .catch(error=>{
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade        :   true
            })
        })
        
    }

    onChangeEmployeesList = (e) => {
        console.log("e")
        console.log(e)
        if(e.length==0)
        {
            this.setState({
                employeeId  :   '',
            })
            return
        }
        this.state.employeeId   =   e[0].id
        this.setState({
            isshow      :   false
        })

    }
    GetEmploymentStatus = async()=> {
        const params = {
            "IpAddress"     :   "0.0.0.0",
            "ClientId"      :   this.state.userinfo.clientId,
            "UserId"        :   this.state.userinfo.userId
        };
        await axios.post(AppConfiguration.Setting().noserapiendpoint + "Maintenance/GetEmployeeStatusTypes",  params)
        .then(res => {
            const data = res.data;
            console.log("GetEmployeeStatusTypes")
            console.log(data)
            this.setState({employeeStatusDDL:data.statusTypes});
        })
    }

    onChangeStatus = (e) => {
        if(e.length == 0) {
            this.state.statusId=""
            return
        } 
        this.state.statusId = e[0].id
    }

    onChangeHMO = (e) => {
        if(e.length == 0) {
            this.state.hmoId=""
            return
        } 
        this.state.hmoId = e[0].id
    }
    
    handleChangeDateFrom = date => {
        //////console.log(date)
        this.setState({
            dateFrom: date,
            isshow:false,
        })
    }

    handleChangeDateTo = date => {
        //////console.log(date)
        this.setState({
            dateTo: date,
            isshow:false,
        })
    }

    handleSearchClick = async() => {
        this.setState({isloading:true})
        if(!this.state.clientId){
            this.setState(  {
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "Please select client",
                fade            :   true
            })
            return
        }
        if(!this.state.employeeId){
            this.setState(  {
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "Please select employee",
                fade            :   true
            })
            return
        }
        const param = {
            "IpAddress"         : "0.0.0.0",
            "UserId"            : this.state.userinfo.userId,
            "ClientId"          : this.state.clientId,
            "EmployeeId"        : this.state.employeeId,
            "LocationId"        : this.state.branchId,
            "StatusId"          : this.state.statusId,
            "RequestTypeId"     : this.state.hmoId,
        };

        console.log("Start Get Params")
        console.log(param)
        await axios
        .post(AppConfiguration.Setting().noserapiendpoint + "ContractReport/GetContractHMO",  param)
        .then(res => {
            const data = res.data;
            console.log("contractHMO")
            console.log(data)
            this.setState({monitoringDG:data.contractHMO,isloading:false});
        })
        .catch(error=>{
            this.setState(  {
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade            :   true
            })
        })
    }

    render(){
        const { ExportCSVButton } = CSVExport;

        const columns = [
            {
                dataField: 'employeeId',
                text: 'ID NO.',
                headerStyle: () => {
                    return { width: "15%" };
                },
            },
            {
                dataField: 'client',
                text: 'Client',
                headerStyle: () => {
                    return { width: "15%" };
                },
            },
            {
                dataField: 'employeeName',
                text: 'Name',
                headerStyle: () => {
                    return { width: "15%" };
                },
            },
            {
                dataField: 'branch',
                text: 'Area of Assignment',
                headerStyle: () => {
                    return { width: "8%" };
                },
            },
            {
                dataField: 'contractDateStart',
                text: 'Date Start',
                headerStyle: () => {
                    return { width: "15%" };
                },
            },
            {
                dataField: 'hmoType',
                text: 'HMO Request Type',
                headerStyle: () => {
                    return { width: "6%" };
                },
            },
            {
                dataField: 'hMODate',
                text: 'DEACTIVATION DATE/ ENROLLED DATE',
                headerStyle: () => {
                    return { width: "8%" };
                },
            },
            {
                dataField: 'approver2',
                text: 'Approver 2',
                headerStyle: () => {
                    return { width: "8%" };
                },
            },
            {
                dataField: 'hmoRemarks',
                text: 'REMARKS WITH HMO/NO HMO',
                headerStyle: () => {
                    return { width: "8%" };
                },
            },
            {
                dataField: 'policyNumber',
                text: 'Policy Number',
                headerStyle: () => {
                    return { width: "8%" };
                },
            },
            {
                dataField: 'hmoCardNumber',
                text: 'Card Number',
                headerStyle: () => {
                    return { width: "8%" };
                },
            },
            // {
            //     dataField: 'statusName',
            //     text: 'Status',
            //     headerStyle: () => {
            //         return { width: "8%" };
            //     },
            // },
        ]
        return(
            <div>
                <Banner />
                <Container className="mt-5" fluid>
                    <Card>
                        <Card.Header>REPORTS >> CONTRACTS >> HMO</Card.Header>
                        <Card.Body>
                            <Form>
                                <Alert isOpen={this.state.isshow} color={this.state.color} fade={this.state.fade} className={this.state.isshow ? 'display-block' : 'display-none'}>
                                    <div className={this.state.color}></div> 
                                    {this.state.message}
                                </Alert>
                                <Form.Group as={Row} controlId="formPlaintextEmail">
                                    <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                        CLIENT
                                    </Form.Label>
                                    <Col>
                                        <Typeahead
                                            labelKey='name'
                                            id="basic-example"
                                            onChange={this.onChangeClient}
                                            options={this.state.clientDDL}
                                        />
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} controlId="formPlaintextEmail">
                                    <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                        BRANCH
                                    </Form.Label>
                                    <Col>
                                        <Typeahead
                                            labelKey='name'
                                            id="basic-example"
                                            onChange={this.onChangeClientLocation}
                                            options={this.state.clientLocationDDL}
                                        />
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} controlId="formPlaintextEmail">
                                    <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                        EMPLOYEE
                                    </Form.Label>
                                    <Col>
                                        <Typeahead
                                            labelKey='employeeName'
                                            id="basic-example"
                                            onChange={this.onChangeEmployeesList}
                                            options={this.state.employeeDDL}
                                        />
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} controlId="formPlaintextEmail">
                                    <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                        REQUEST TYPE
                                    </Form.Label>
                                    <Col>
                                        <Typeahead
                                            labelKey='name'
                                            id="basic-example"
                                            onChange={this.onChangeHMO}
                                            options={this.state.hmoDDL}
                                        />
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} controlId="formPlaintextEmail">
                                    <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                        STATUS
                                    </Form.Label>
                                    <Col>
                                        <Typeahead
                                            labelKey='name'
                                            id="basic-example"
                                            onChange={this.onChangeStatus}
                                            options={this.state.employeeStatusDDL}
                                        />
                                    </Col>
                                </Form.Group>
                                <ButtonToolbar sm={12}>
                                    <Button variant="success" className="ml-auto" onClick = { this.handleSearchClick }>
                                        SEARCH
                                    </Button>
                                </ButtonToolbar>
                                <ToolkitProvider
                                    keyField="id"   
                                    data={ this.state.monitoringDG }
                                    columns={ columns }
                                    exportCSV={ {
                                        fileName: "HMO Contract.csv",
                                    } }
                                    >
                                    {
                                        props => (
                                        <div className="mt-3">
                                        <ExportCSVButton disabled={this.state.monitoringDG.length>0 ? false : true} className="btn btn-success" { ...props.csvProps }>Export CSV!!</ExportCSVButton>
                                        <Card.Header>HMO Contract List</Card.Header>
                                            <BootstrapTable
                                                    { ...props.baseProps } 
                                                    striped
                                                    hover
                                                    condensed
                                                    pagination={ paginationFactory({sizePerPageRenderer}) }
                                                    keyField = "id"
                                                    data = { this.state.monitoringDG }
                                                    noDataIndication={ () => <div>No record found.</div> }
                                                    columns = { columns }
                                                    //expandRow={ expandItems }
                                            />
                                        </div>
                                        )
                                    }
                                </ToolkitProvider>
                            </Form>
                        </Card.Body>
                    </Card>
                </Container>  
                <NoserLoading show={this.state.isloading} />
            </div>
        )
    }

}

export  default HMO