import {
    React,Component,BootstrapTable,
    paginationFactory,Button, ButtonToolbar, Card, 
    Form, Col, Row, axios, Container, Banner, Typeahead, TimePicker,
    cellEditFactory, Type, moment, NavLink, Alert, AppConfiguration,
    NoserLoading, Noser, sizePerPageRenderer, DatePicker
} 
from '../../noser-hris-component';
import { DropdownButton, Dropdown} from 'react-bootstrap';
import TrainingScheduleCreate from './TrainingModal/TrainingScheduleCreate';
import TrainingScheduleEdit from './TrainingModal/TrainingScheduleEdit';


class LDTrainingSchedule extends Component {
    constructor(props) {
        super(props);
        this.state = {
            disablesave     :   true,
            fade            :   true, 
            isshow          :   false,
            isloading       :   false,
            color           :   "",
            message         :   "",
            alerttype       :   "",
            userinfo        :   [],
            
            trainingDDL     :   [],
            trainingGRID    :   [],
            training        :   "",
            trainingId      :   "",
            posttestDDL        :   [{ "value" : 1, "label" : "POST-TEST" },{ "value" : 0, "label" : "PRE-TEST" }],
        }
    }

    componentDidMount(){
        this.state.userinfo = JSON.parse(sessionStorage.getItem("userData"))
        this.GetTrainingSchedules()
    }
    handleModalShow = (e) =>{
        this.setState({openModal:true})
        let obj = {}
        this.child.initialize(obj)
        this.GetTrainingSchedules()
    }
    handleEdit = (e) =>{
        this.setState({openModalEdit:true})
        let obj = {}
        this.childEdit.initialize(e)
    }
    handleModalClose = (e) =>{
        this.setState({openModal:false})
        this.GetTrainingSchedules()
    }
    handleModalCloseEdit = (e) =>{
        this.setState({openModalEdit:false})
        this.GetTrainingSchedules()
    }
    GetTrainingSchedules = async()=> {
        this.setState({isloading:true})
        const params = {
            "IpAddress"     :   "0.0.0.0",
            "UserId"        :   this.state.userinfo.userId,
            "ClientId"      :   this.state.userinfo.clientId,
            "PositionId"    :   "",
            "TrainingId"    :   "",
            "FromDate"      :   "",
            "ToDate"        :   "",
            "BatchNo"        :   "",
        };
        await axios
            .post(AppConfiguration.Setting().noserapiendpoint + "Training/GetTrainingSchedules",  params)
            .then(res => {
                const data = res.data;
                console.log("GetTrainingSchedules xxx")
                console.log(data)
                this.setState({ trainingGRID:data.trainingSchedules,
                                trainingDDL: [...new Map(data.trainingSchedules.map(item =>
                                    [item['training'], item])).values()], 
                                isloading : false })
                //console.log([...new Map(data.trainingSchedules.map(item =>[item['training'], item])).values()])
                if(data.status=="0"){
                this.setState({
                    isloading   :   false,
                    alerttype   :   "Error!",
                    isshow      :   true,
                    color       :   "danger",
                    message     :   data.message,
                    fade        :   true
                });
            }
        })
        .catch(error=>{
            this.setState({
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade            :   true
            })
        })
    }
    handleSaveClick = (e) => {
        this.setState({isloading:true})
        const params = {
            "IpAddress"     :   "0.0.0.0",
            "UserId"        :   this.state.userinfo.userId,
            "ClientId"      :   this.state.userinfo.clientId,
            "trainingSchedules":this.state.trainingGRID.filter(x=>x.isDeleted==="1")
        };

        axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Training/EditTrainingSchedule", params)
        .then(res => {
            const data = res.data;
            this.setState({
                isloading   :   false,
                alerttype   :   data.status=="1" ? "Success!" : "Error!",
                isshow      :   true,
                color       :   data.status=="1" ? "success" : "danger",
                message     :   "Successfully Deleted.",
                fade        :   true
            });
            this.GetTrainingSchedules()

        })
        .catch(error=>{
            this.setState(  {
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade        :   true
            })
        })
    }
    handleSearchClick = async(e) => {
        this.setState({isloading:true})
        const params = {
            "IpAddress"     :   "0.0.0.0",
            "UserId"        :   this.state.userinfo.userId,
            "ClientId"      :   this.state.userinfo.clientId,
            "PositionId"    :   "",
            "TrainingId"    :   this.state.trainingId,
            "FromDate"      :   "",
            "ToDate"        :   "",
            "BatchNo"        :   this.state.batchNo,
        };
        await axios
             .post(
                 AppConfiguration.Setting().noserapiendpoint + "Training/GetTrainingSchedules",  params
             )
             .then(res => {
                 const data = res.data;
                 this.setState({ trainingGRID:data.trainingSchedules,isloading:false}) 
                 if(data.status==="0"){
                    this.setState({
                        isloading       :   false,
                        alerttype       :   "Error!",
                        isshow          :   true,
                        color           :   "danger",
                        message         :   data.message,
                        fade            :   true
                    })
                 }   
            })
            .catch(error=>{
                this.setState(  {
                    isloading   :   false,
                    alerttype   :   "Error!",
                    isshow      :   true,
                    color       :   "danger",
                    message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                    fade        :   true
                })
            })   
    }
    handleChangedTraining = (e) => {
        if(e.length == 0) {
            this.state.trainingId     =   ""
            this.state.training      = ""
            return
        }
        this.state.trainingId=e[0].trainingId
        this.state.training=e[0].training
        
    }
    handleChangedBatch = (e) => {
        if(e.length == 0) {
            this.state.batchNo=""
            return
        }
        this.state.batchNo=e[0].batchNo
    }
    GridDataModified(oldValue, newValue, id, column) {
        let isdisable = true
        this.state.trainingGRID.map(function(item,i) {
            if (item.id===id)
            item.isModified = newValue!=oldValue ? "1" : "0"
            if(item.isModified=="1") isdisable = false
        
        })
        this.setState({disablesave:isdisable})
    }
    render() {
        
        const col = [
            {
                dataField: 'training',
                text: 'Training',
                editable : false,
                headerStyle : () => {
                    return { width  : "20%" };
                }
            },
            {
                dataField: 'examSet',
                text: 'Set Name',
                editable : false,
                headerStyle : () => {
                    return { width  : "10%" };
                }
            },
            {
                dataField: 'isPostTest',
                text: 'EXAM TYPE',
                editable: false,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'10%'}},
                style:{textAlign:'center'},
                editor: {
                    type: Type.SELECT,
                    options: this.state.posttestDDL
                  },
                formatter: (cell, row) => {
                    if(row.isPostTest!=='' && row.isPostTest!==null){
                        return this.state.posttestDDL.find(x => x.value == cell).label;
                    }
                }
            },
            {
                dataField   : 'fromDate',
                text        : 'Date From',
                editable : false,
                headerStyle : () => {
                    return { width  : "10%" };
                },
                formatter: (cell) => { 
                    let dateObj = cell;
                    if (typeof cell !== 'object') dateObj = new Date(cell);
                    if (cell != null){
                        let m = moment(dateObj);
                        return (m.isValid() ? (dateObj.getMonth()+1 + "/" + dateObj.getDate() + "/" + dateObj.getFullYear()) : "")
                    }
                },
                editor: {
                    type: Type.DATE,
                    defaultValue: new Date(),
                    minDateValue: new Date(),
                },
                validator: (newValue, row, column) => {
                    if(moment(new Date()) > moment(new Date(newValue)))
                    return {
                        valid: false,
                        message:"Date From must be greater than or equal to date today."
                      };
                    return true
                }
            },
            {
                dataField   : 'toDate',
                text        : 'Date To',
                editable : false,
                headerStyle : () => {
                    return { width  : "10%" };
                },
                formatter: (cell) => { 
                    let dateObj = cell;
                    if (typeof cell !== 'object') dateObj = new Date(cell);
                    if (cell != null){
                        let m = moment(dateObj);
                        return (m.isValid() ? (dateObj.getMonth()+1 + "/" + dateObj.getDate() + "/" + dateObj.getFullYear()) : "")
                    }
                },
                editor: {
                    type: Type.DATE,
                    defaultValue: Date.now(),
                    minDateValue: Date.now(),
                },
                validator: (newValue, row, column) => {
                    if(moment(new Date()) > moment(new Date(newValue)))
                    return {
                        valid: false,
                        message:"Date To must be greater than or equal to date today."
                      };
                    return true
                }
            },
            {
                dataField   : 'fromTime',
                text        : 'Time From',
                editable : false,
                headerStyle : () => {
                    return { width  : "10%" };
                },
                validator: (newValue, row, column) => {
                    var isValid = /^([0-1]?[0-9]|2[0-4]):([0-5][0-9])(:[0-5][0-9])?$/.test(newValue);
                    if(!isValid)
                    return {
                        valid: false,
                        message:"Invalid time format."
                      };
                    return true
                }
            },
            {
                dataField   : 'toTime',
                text        : 'Time To',
                editable : false,
                headerStyle : () => {
                    return { width  : "10%" };
                },
                validator: (newValue, row, column) => {
                    var isValid = /^([0-1]?[0-9]|2[0-4]):([0-5][0-9])(:[0-5][0-9])?$/.test(newValue);
                    if(!isValid)
                    return {
                        valid: false,
                        message:"Invalid time format."
                      };
                    return true
                }
            },
            {
                dataField   : 'venue',
                text        : 'Venue',
                editable : false,
                headerStyle : () => {
                    return { width  : "10%" };
                }
            },
            {
                dataField   : 'facilitator',
                text        : 'Facilitator',
                editable : false,
                headerStyle : () => {
                    return { width  : "10%" };
                },
                
            },
            {
                dataField   : 'batchNo',
                text        : 'Batch No.',
                editable : false,
                headerStyle : () => {
                    return { width  : "10%" };
                }
            },
            {
                dataField   : 'participantLimit',
                text        : 'Limit No. of Participants',
                editable : false,
                headerStyle : () => {
                    return { width  : "10%" };
                },
                validator: (newValue, row, column) => {
                    var isValid = /^\d+(.\d+)?$/.test(newValue);
                    if(!isValid)
                    return {
                        valid: false,
                        message:"Value must be numberic."
                      };
                    if(newValue.length>3)
                    return {
                        valid: false,
                        message:"Invalid participant value."
                      };
                    return true
                }
            },
            {
                dataField: 'action',
                text: 'ACTION',
                headerStyle: () => {
                    return { width: "10%" };
                },
                editable:false,
                formatter   :   (cell, row, isSelect) => {
                    return (
                        <Button style={{height:'18px',paddingTop:'0',marginTop:'-4px',fontSize:'11px'}} 
                            variant="link" onClick={e => this.handleEdit(row)}
                        >EDIT</Button>
                    );
                },
            }
            
        ]

        const selectRow = {
            mode: 'checkbox',
            clickToSelectAndEditCell: true,
            onSelect: (row, isSelect, rowIndex, e) => {
                let isdisable = true
                this.state.trainingGRID.map(function(item,i){
                    if(item.id===row.id)
                    {
                        item.isDeleted = isSelect ? "1" : "0"
                        //item.isModified = isSelect ? "1" : "0"
                    }
                    if(item.isDeleted=="1") isdisable=false
                })
                this.setState({disablesave:isdisable})
            }
        };

        const rowEvents = {
            onClick: (e, row, rowIndex) => {
            }
        };
    return(
            <div>
                <Banner />
                    <Container className="mt-5" fluid>
                        <Card>
                            <Card.Header>TRAINING SCHEDULE</Card.Header>
                            <Card.Body>
                                <Form>
                                    <Alert isOpen={this.state.isshow} color={this.state.color} fade={this.state.fade} className={this.state.isshow ? 'display-block' : 'display-none'}>
                                        <div className={this.state.color}></div> 
                                        {this.state.message}
                                    </Alert>
                                    <Form.Group as={Row} controlId="formPlaintextEmail">
                                        <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                            TRAINING
                                        </Form.Label>
                                        <Col sm="11">
                                            <Typeahead
                                                labelKey='training'
                                                id="basic-example"
                                                onChange={this.handleChangedTraining}
                                                options={this.state.trainingDDL}
                                            />
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} controlId="formPlaintextEmail">
                                        <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                            BATCH NO.
                                        </Form.Label>
                                        <Col sm="11">
                                            <Typeahead
                                                labelKey='batchNo'
                                                id="basic-example"
                                                onChange={this.handleChangedBatch}
                                                options={this.state.trainingDDL}
                                            />
                                        </Col>
                                    </Form.Group>
                                    <ButtonToolbar className="mt-1">
                                        <Button variant="success" className='noser-button-mr1 ml-auto' onClick={ this.handleSearchClick }>Search</Button>
                                        <Button className="noser-button" variant="primary" onClick={this.handleModalShow}>Create</Button>
                                    </ButtonToolbar>

                                    <Card.Header className="mt-3">List Of Training Schedule</Card.Header>
                                    <div className="mt-1">
                                        <BootstrapTable
                                            keyField = "id"
                                            data = { this.state.trainingGRID }
                                            columns = { col }
                                            pagination={ paginationFactory({sizePerPageRenderer}) }
                                            noDataIndication={ () => <div style={{width:'100%'}}>No record found.</div> }
                                            rowClasses="noser-table-row-class"
                                            striped
                                            hover
                                            condensed
                                            cellEdit = { cellEditFactory({
                                                mode: 'dbclick',
                                                blurToSave: true,
                                                afterSaveCell: (oldValue, newValue, row, column) => {
                                                    this.GridDataModified(oldValue, newValue, row.id, column.dataField)
                                                    }
                                                })
                                            }
                                            rowEvents={ rowEvents }
                                            selectRow = { selectRow }

                                        />
                                    </div>
                                    <ButtonToolbar sm={12}>
                                        <Button disabled={this.state.disablesave} variant="danger" className='noser-button-mr1 ml-auto' onClick={ this.handleSaveClick }>Delete</Button>
                                        <Button variant="danger" href="/home" className='noser-button'>Close</Button>
                                        
                                    </ButtonToolbar>
                                </Form>
                            </Card.Body>
                        </Card>
                       
                    </Container>
                    <NoserLoading show={this.state.isloading} />
                <TrainingScheduleCreate 
                    show={this.state.openModal}
                    onHide={this.handleModalClose}
                    onRefModal={ref => (this.child = ref)}
                />
                <TrainingScheduleEdit 
                    show={this.state.openModalEdit}
                    onHide={this.handleModalCloseEdit}
                    onRefModal={ref => (this.childEdit = ref)}
                />
            </div> 
        )
    }

}

export  default LDTrainingSchedule 
