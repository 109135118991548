import {
    React,Component,BootstrapTable, Type,
    paginationFactory,Button, ButtonToolbar, Card,
    Form, Col, Row, Container, axios, Typeahead, Banner, TimePicker,
    cellEditFactory ,moment, NavLink, Alert, AppConfiguration,
    NoserLoading, Noser, sizePerPageRenderer, DatePicker
} 
from '../../noser-hris-component';
import type from 'react-bootstrap-table2-editor';

class ManningEmployeeRecord extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userinfo        :   [],
            isloading       :   false,
            isshow          :   false,
            alerttype       :   "",
            message         :   "",
            color           :   "",
            fade            :   true,
            selected: [],
            employeeDDL : [],
            statusDDL : [],
            employeeRecordListGrid : [],

            
           
        }
         
    }

    componentDidMount(){
        const sleep = (milliseconds) => {return new Promise(resolve => setTimeout(resolve, milliseconds))}
        this.state.userinfo = JSON.parse(sessionStorage.getItem("userData"))
        this.GetEmployee();
        this.getStatus();
    }

    GetEmployee = async () => {
        this.setState({isloading:true})
        const submittedParams = {
            "IpAddress":"0.0.0.0",
            "UserId":this.state.userinfo.userId,
            "ClientId":this.state.userinfo.clientId,
            "RoleId":this.state.userinfo.roleId,
            "EmployeeId":"",
            "StatusId":""
        };
        console.log("Test app");
        console.log(submittedParams)
        await axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Administrator/GetSubmittedEmployeeRecords",  submittedParams)
        .then(res => {
            const data = res.data;
            console.log("Test app123");
            console.log(data);
            this.setState({ employeeDDL: data.employeeRecords,isloading:false});
           
        })
    }
    handleChangeEmployee= (e) => {
        console.log(e)
        if (e.length == 0) {
            this.setState({selectedEmployeeName: null, selectedEmployeeNameId:"", selectedProfileId:''})
            return
        }
         this.state.selectedEmployeeName = e[0].employeeName
        this.state.selectedEmployeeNameId = e[0].id
        this.state.selectedEmployeeId =e[0].employeeId
    }

    getStatus = async () => {
        const statusParams = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.userinfo.clientId,
            "UserId":this.state.userinfo.userId,
            "Code":"0021"
        };
        await axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Maintenance/GetDataReferences", statusParams)
        .then(res => {
            const data = res.data;
            this.setState({
                statusDDL : res.data.dataReferences
            })
                
        })
                
    }
    handleCoverChangeSortBy = (e) => {
        if (e.length > 0) {
            this.state.selectedSortBy = e[0].name
            this.state.selectedSortById = e[0].id

        } else {
            this.state.selectedSortBy = ""
            this.state.selectedSortById = ""
        }
        
    }

    handleSearchClick = event => {
        this.setState({isloading:true})

        const searchParams = {
            "IpAddress":"0.0.0.0",
            "UserId":this.state.userinfo.userId,
            "ClientId":this.state.userinfo.clientId,
            "RoleId":this.state.userinfo.roleId,
            "EmployeeId":this.state.selectedEmployeeId,
            "StatusId":this.state.selectedSortById
        };
        console.log(searchParams)
        axios
            .post(AppConfiguration.Setting().noserapiendpoint + "Administrator/GetSubmittedEmployeeRecords",  searchParams)
            .then(res => {
                const data = res.data;
                console.log(res.data)
                this.setState({employeeRecordListGrid: data.employeeRecords,isloading:false});
                // this.processGrid();
                if(data.status=="0"){
                    this.setState({
                        isloading       :   false,
                        alerttype       :   "Error!",
                        isshow          :   true,
                        color           :   "danger",
                        message         :   data.message,
                        fade            :   true
                    })
                }
            })
            .catch(error=>{
            this.setState(  {
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade            :   true
            })
        })

    }


    render() {
        const columns1 = [
            {
                dataField: 'employeeName',
                text: 'EMPLOYEE',
                headerStyle: (colum, colIndex) => {
                    return { width: '20%'};
                }
            },
            {
                dataField: 'clientName',
                text: 'CLIENT',
                headerStyle: (colum, colIndex) => {
                    return { width: '20%'};
                }
            },
            {
                dataField: 'locationName',
                text: 'BRANCH',
                headerStyle: (colum, colIndex) => {
                    return { width: '20%'};
                }
            },
            {
                dataField: 'createdDate',
                text: 'SUBMITTED DATE',
                headerStyle: (colum, colIndex) => {
                    return { width: '20%'};
                }
            },
            {
                dataField: 'status',
                text: 'APPROVAL STATUS',
                headerStyle: (colum, colIndex) => {
                    return { width: '20%'};
                }
            
            }] 
       
       
        const selectRow = {
            mode: 'checkbox',
            //clickToSelect: true,
            clickToSelectAndEditCell: true
        };
        const rowEvents = {
            onClick: (e, row, rowIndex) => {
              //alert(e.row);
              
            }
        };

        return(
            <div>
                <Banner />
                    <Container className="mt-5" fluid>
                        <Card>
                            <Card.Header>MANNING >> EMPLOYEE RECORDS</Card.Header>
                            <Card.Body>
                                <Form>
                                    <Alert isOpen={this.state.isshow} color={this.state.color} fade={this.state.fade} className={this.state.isshow ? 'display-block' : 'display-none'}>
                                        <div className={this.state.color}></div> 
                                        {this.state.message}
                                    </Alert>
                                    <Form.Group as={Row} controlId="formPlaintextEmail">
                                        <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                        EMPLOYEE
                                        </Form.Label>
                                        <Col sm="11">
                                            <Typeahead
                                               labelKey='employeeName'
                                               id="basic-example"
                                               onChange={this.handleChangeEmployee}
                                               options={this.state.employeeDDL}
                                            />  
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} controlId="formPlaintextEmail">
                                        <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                        APPROVAL STATUS
                                        </Form.Label>
                                        <Col sm="11">
                                            <Typeahead
                                                labelKey='name'
                                                id="basic-example"
                                                onChange={this.handleCoverChangeSortBy}
                                                options={this.state.statusDDL}
                                            />  
                                        </Col>
                                    </Form.Group>
                                    
                                    <ButtonToolbar sm={12} className="mt-3">
                                        <Button style={{minWidth:"60px",marginRight:"1pt"}} variant="success" className="ml-auto" onClick={this.handleSearchClick}>
                                            Search
                                        </Button>&nbsp;&nbsp;
                                        <NavLink to="/manningrecordcreate">
                                            <Button style={{minWidth:"60px",marginRight:"1pt"}} variant="primary" >
                                                Create
                                            </Button>
                                        </NavLink>
                                    </ButtonToolbar>
                                    <Card.Header className="mt-3">record list</Card.Header>
                                    <div className="mt-1">
                                        <BootstrapTable
                                            /* caption={Noser.TableHeader({title:"RECORD"})} */
                                            keyField = "id"
                                            data = { this.state.employeeRecordListGrid }
                                            columns = { columns1 }
                                            pagination={ paginationFactory({sizePerPageRenderer}) }
                                            noDataIndication={ () => <div style={{width:'100%'}}>No record found.</div> }
                                            rowClasses="noser-table-row-class no-checkbox"
                                            striped
                                            hover
                                            condensed
                                            cellEdit = { cellEditFactory({
                                                mode: 'dbclick',
                                                blurToSave: true,
                                                afterSaveCell: (oldValue, newValue, row, column) => {
                                                    this.GridDataModified(oldValue, newValue, row.id, column.dataField)
                                                    }
                                                })
                                            }
                                            rowEvents={ rowEvents }
                                            selectRow = { selectRow }

                                        />
                                    </div>
                                    <ButtonToolbar sm={12} className="mt-3">
                                        <NavLink className="ml-auto" to="/home">
                                            <Button style={{minWidth:"60px",marginRight:"1pt"}}  variant="danger"  href="/banner">
                                                Close
                                            </Button>
                                        </NavLink>
                                    </ButtonToolbar>
                                </Form>
                            </Card.Body>
                        </Card>
                    </Container>
                    <NoserLoading show={this.state.isloading} />
            </div> 
            
        )
    }
}

export default ManningEmployeeRecord;