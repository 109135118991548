import {
    React,Component,BootstrapTable,
    paginationFactory,Button, ButtonToolbar, Card, 
    Form, Col, Row, axios, Container, Banner, Typeahead, TimePicker,
    cellEditFactory, Type, moment, NavLink, Alert, AppConfiguration,
    NoserLoading, Noser, sizePerPageRenderer, DatePicker
} 
from '../../noser-hris-component';
import { DropdownButton, Dropdown} from 'react-bootstrap';
import ToolkitProvider, { CSVExport } from 'react-bootstrap-table2-toolkit';
import 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css';

const showSecond = false;
const str = showSecond ? 'HH:mm:ss' : 'HH:mm';
 
class TrainingRegisterDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fade            :   true, 
            color           :   "",
            isshow          :   false,
            message         :   "",
            userinfo        :   [],
            isloading       :   false,
            alerttype       :   "",

            typeDDL        :   [],
            trainingDDL : [],
            disableRemove : true,

            //dateFrom:new Date(),
            //dateTo:new Date(),
            //valueFrom: moment(),
            //valueTo: moment(),

            trainingRegisterDG : [],
            tblAttendanceArrLst : [],
            attendanceDDL    :   [
                { "id" : "1", "name" : "PRESENT" },
                { "id" : "0", "name" : "ABSENT" },
            ],

            trainingRegisterDR : JSON.parse(sessionStorage.getItem("trainingRegisterDataRow")),

            registerGrid : [],
        }
    }

    componentDidMount(){
        const sleep = (milliseconds) => {return new Promise(resolve => setTimeout(resolve, milliseconds))}
        this.state.userinfo = JSON.parse(sessionStorage.getItem("userData"))
        //console.log("trainingRegisterDataRow")
        //console.log(JSON.parse(sessionStorage.getItem("trainingRegisterDataRow")))
        this.GetTrainings();

        /* let today = new Date();
        let valueFrom = new moment();
        let valueTo = new Date();
        let dd = String(today.getDate()).padStart(2, '0');
        let mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
        let yyyy = today.getFullYear();

        valueFrom = moment(mm + '/' + dd + '/' + yyyy + ' ' + JSON.parse(sessionStorage.getItem("trainingRegisterDataRow")).fromTime);
        valueTo = moment(mm + '/' + dd + '/' + yyyy + ' ' + JSON.parse(sessionStorage.getItem("trainingRegisterDataRow")).toTime); */

        this.setState({
            trainingType        :  this.state.trainingRegisterDR.trainingType,
            trainingName        :  this.state.trainingRegisterDR.training,
            description         : this.state.trainingRegisterDR.description,
            session             : this.state.trainingRegisterDR.sessionName,
            batchNo             : this.state.trainingRegisterDR.batchNo,
            participantLimit    : this.state.trainingRegisterDR.participantLimit,
            facilitator         : this.state.trainingRegisterDR.facilitator,
            venue               : this.state.trainingRegisterDR.venue,
            //dateFrom            : new Date(this.state.trainingRegisterDR.fromDate),
            //dateTo              : new Date(this.state.trainingRegisterDR.toDate),
            dateFrom            : this.state.trainingRegisterDR.fromDate,
            dateTo              : this.state.trainingRegisterDR.toDate,
            //valueFrom           : valueFrom,
            //valueTo             : valueTo,
            valueFrom           : this.state.trainingRegisterDR.fromTime,
            valueTo             : this.state.trainingRegisterDR.toTime,
        })
       
        this.GetTrainingRegistrationDetails();
        this.GetAttendanceLists();
    }

    GetAttendanceLists() {

        for (let i = 0; i < this.state.attendanceDDL.length; i++) {
            const obj = {
                value : this.state.attendanceDDL[i]["id"],
                label : this.state.attendanceDDL[i]["name"],
            };
            this.state.tblAttendanceArrLst.push(obj);
        }
    }

    GetTrainings = async()=> {
        this.setState({isloading:true})

        const param = {
            "IpAddress":"0.0.0.0",
            "UserId":this.state.userinfo.userId,
            "ClientId":this.state.userinfo.clientId,
            "TrainingId":"",
        };
        await axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Maintenance/GetTrainings",  param)
        .then(res => {
            const data = res.data;
            this.setState({ trainingDDL:data.trainings, isloading : false })
            if(data.status=="0"){
                this.setState({
                    isloading   :   false,
                    alerttype   :   "Error!",
                    isshow      :   true,
                    color       :   "danger",
                    message     :   data.message,
                    fade        :   true
                });
            
            }
                
        })
        .catch(error=>{
            this.setState({
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade            :   true
            })
        })
        
    }

    onChangeTraining = (e) => {
        if(e.length == 0) {
            this.state.trainingId     =   ""
            return
        }
        this.state.trainingId    =   e[0].id
        this.setState({
            isshow:false,
        })
    }


    GetTrainingRegistrationDetails = async() =>{
        this.setState({isloading:true})
        const searchParams = {
            "IpAddress"     :   "0.0.0.0",
            "UserId"        :   this.state.userinfo.userId,
            "ClientId"      :   this.state.userinfo.clientId,
            "TypeId"        :   "",
            "TrainingId"    :   this.state.trainingRegisterDR.trainingId,
            "BatchNo"       :   this.state.trainingRegisterDR.batchNo,
        };
        //console.log("searchParams")
        //console.log(searchParams)
        await
        axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Training/GetTrainingRegistrationDetails",  searchParams)
        .then(res => {
            const data = res.data;
            console.log("Diaplay Data Grid")
            console.log(data)
            this.setState({ trainingRegisterDG     :   data.registeredEmployees, isloading : false })
                
        })
        .catch(error=>{
            this.setState({
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade            :   true
            })
        })
    }

    handleUpdateClick = () => {
        this.setState({isloading:true})
        let registerGrid = []
        this.state.trainingRegisterDG.map(function(itm,a) {
            registerGrid.push({
                "Id"            :   itm.id,
                "IsPresent"     :   itm.isPresent,
                "DateAttended"  :   itm.dateRegistered,
            })
        })

        const params = {
            "IpAddress"     :   "0.0.0.0",
            "UserId"        :   this.state.userinfo.userId,
            "ClientId"      :   this.state.userinfo.clientId,
            "employees"     :   registerGrid
        };

        //console.log("submit params")
        //console.log(params)
        
        axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Training/UpdateEmployeeAttendance",  params)
        .then(res => {
            const data = res.data;
            //console.log("UpdateEmployeeAttendance")
            //console.log(data)
            if(data.status=="1"){
                this.setState({
                    isloading   :   false,
                    alerttype   :   "Success!",
                    isshow      :   true,
                    color       :   "success",
                    message     :   data.message,
                    fade        :   true
                });
            
            }
            else {
                this.setState({
                    isloading   :   false,
                    alerttype   :   "Error!",
                    isshow      :   true,
                    color       :   "danger",
                    message     :   data.message,
                    fade        :   true
                });
            }
                
        })
        .catch(error=>{
            this.setState({
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade            :   true
            })
        }) 
    }

    GridDataModified(oldValue, newValue, id, column) {
        ////////console.log(id)
        this.state.trainingRegisterDG.map(function(item,i) {
            if (item.id===id)
                item.isModified = newValue!=oldValue ? "1" : "0"
        })
    }

    handleRemoveClick = () => {
        this.setState({isloading:true})

        const params = {
            "IpAddress"     :   "0.0.0.0",
            "UserId"        :   this.state.userinfo.userId,
            "ClientId"      :   this.state.userinfo.clientId,
            "employees"     :   this.state.registerGrid
        };

        console.log("submit params")
        console.log(params)
        
        axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Training/RemoveEmployeeTrainingSchedule",  params)
        .then(res => {
            const data = res.data;
            //console.log("AddRegistration")
            //console.log(data)
            if(data.status=="1"){
                this.GetTrainingRegistrationDetails();
                this.setState({
                    isloading   :   false,
                    alerttype   :   "Success!",
                    isshow      :   true,
                    color       :   "success",
                    message     :   data.message,
                    fade        :   true
                });
            
            }
            else {
                this.setState({
                    isloading   :   false,
                    alerttype   :   "Error!",
                    isshow      :   true,
                    color       :   "danger",
                    message     :   data.message,
                    fade        :   true
                });
            }
                
        })
        .catch(error=>{
            this.setState({
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade            :   true
            })
        }) 
    }

    render() {
        const { ExportCSVButton } = CSVExport;
        
        const trainingRegisterColumn = [
            {
                dataField: 'hc',
                text: 'No.',
                editable : false,
                headerStyle : () => {
                    return { width  : "5%" };
                }
            },
            {
                dataField: 'batchNo',
                text: 'Batch #.',
                editable : false,
                headerStyle : () => {
                    return { width  : "10%" };
                }
            },
            /* {
                dataField: 'sureName.',
                text: 'SureName',
                headerStyle : () => {
                    return { width  : "10%" };
                }
            },
            {
                dataField: 'firstName.',
                text: 'First Name',
                headerStyle : () => {
                    return { width  : "10%" };
                }
            }, */
            {
                dataField: 'employeeName',
                text: 'Employee Name',
                editable : false,
                headerStyle : () => {
                    return { width  : "15%" };
                }
            },
            {
                dataField   : 'position',
                text        : 'Position',
                editable : false,
                headerStyle : () => {
                    return { width  : "10%" };
                }
            },
            {
                dataField   : 'team',
                text        : 'Team',
                editable : false,
                headerStyle : () => {
                    return { width  : "5%" };
                }
            },
            {
                dataField: 'client',
                text: 'Name Of Client',
                editable : false,
                headerStyle : () => {
                    return { width  : "10%" };
                }
            },
            {
                dataField: 'location',
                text: 'Branch',
                editable : false,
                headerStyle : () => {
                    return { width  : "5%" };
                }
            },
            {
                dataField: 'area.',
                text: 'Area',
                editable : false,
                headerStyle : () => {
                    return { width  : "5%" };
                }
            },
            {
                dataField   : 'fromDate',
                text        : 'Date Orientation',
                editable : false,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'10%'}},
                style:{textAlign:'center',width:'10%'},
            },
            {
                dataField   : 'timeArrived',
                text        : 'Time Arrived',
                editable : false,
                headerStyle : () => {
                    return { width  : "5%" };
                },
            },
            {
                dataField   : 'isPresent',
                text        : 'Attendance',
                headerStyle : () => {
                    return { width  : "10%" };
                },
                formatter: (cell, row) => {
                    if(row.isPresent!='' && row.isPresent!=null){
                        if(this.state.tblAttendanceArrLst.filter(x => x.value == cell).length==0)
                        return ""
                        else
                            return this.state.tblAttendanceArrLst.find(x => x.value == cell).label;
                    }
                },
                editor: {
                    type: Type.SELECT,
                    options: this.state.tblAttendanceArrLst
                }
            },
            {
                dataField   : 'signatureofAttendees',
                editable : false,
                text        : 'Signature of Attendees',
                headerStyle : () => {
                    return { width  : "10%" };
                },
            },
        ]

        const selectRow = {
            mode: 'checkbox',
            clickToSelectAndEditCell: true,
            onSelect: (row, isSelect, rowIndex, e) => {
                let disable = true
                let registerGrid = this.state.registerGrid
                this.state.trainingRegisterDG.map(function(item,i){
                    if(item.id===row.id)
                    {
                        item.isDeleted = isSelect ? "1" : "0"
                        item.isModified = isSelect ? "1" : "0"
                        disable = isSelect ? false : true

                        registerGrid.push({
                            "Id"    :   item.id,
                        })
                    }
                })

                this.setState({disableRemove:disable})
            }
        };

        const rowEvents = {
            onClick: (e, row, rowIndex) => {
            }
        };
        
    return(
            <div>
                <Banner />
                    <Container className="mt-5" fluid>
                        <Card>
                            <Card.Header>TRAINING >> L and D >> TRAINING REGISTER DETAILS</Card.Header>
                            <Card.Body>
                                <Form>
                                    <Alert isOpen={this.state.isshow} color={this.state.color} fade={this.state.fade} className={this.state.isshow ? 'display-block' : 'display-none'}>
                                        <div className={this.state.color}></div> 
                                        {this.state.message}
                                    </Alert>
                                    <Form.Group as={Row} controlId="formPlaintextEmail">
                                        <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                            TYPE
                                        </Form.Label>
                                        <Col sm="11">
                                            {/* <Typeahead
                                                ref="trainingRef"
                                                labelKey='name'
                                                id="basic-example"
                                                onChange={this.onChangeType}
                                                options={this.state.typeDDL}
                                            /> */}
                                            <Form.Control 
                                                ref="trainingType"
                                                name="trainingType"
                                                value={this.state.trainingType}
                                                onChange={this.onChangeTrainingType}
                                                autoComplete="off"
                                                disabled
                                            />
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} controlId="formPlaintextEmail">
                                        <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                            TRAINING
                                        </Form.Label>
                                        <Col sm="11">
                                            <Form.Control 
                                                ref="trainingName"
                                                name="trainingName"
                                                value={this.state.trainingName}
                                                onChange={this.onChangeTraining}
                                                autoComplete="off"
                                                disabled
                                            />
                                            {/* <Typeahead
                                                ref="trainingRef"
                                                labelKey='name'
                                                id="basic-example"
                                                onChange={this.onChangeTraining}
                                                options={this.state.trainingDDL}
                                                selected={[JSON.parse(sessionStorage.getItem("trainingRegisterDataRow")).training]}
                                            /> */}
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} controlId="formPlaintextEmail">
                                        <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                            DESCRIPTION
                                        </Form.Label>
                                        <Col sm="6">
                                            <Form.Control 
                                                ref="description"
                                                name="description"
                                                value={this.state.description}
                                                onChange={this.onChangeDescription}
                                                autoComplete="off"
                                                disabled
                                            />
                                        </Col>
                                        <Col sm="1">
                                        </Col>
                                        <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                            SESSION
                                        </Form.Label>
                                        <Col sm="3">
                                            <Form.Control 
                                                ref="session"
                                                name="session"
                                                value={this.state.session}
                                                onChange={this.onChangePart}
                                                autoComplete="off"
                                                disabled
                                            />
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} controlId="formPlaintextEmail">
                                        <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                            BATCH NO.
                                        </Form.Label>
                                        <Col sm="6">
                                            <Form.Control 
                                                ref="batchNo"
                                                name="batchNo"
                                                value={this.state.batchNo}
                                                onChange={this.onChangeBatchNo}
                                                autoComplete="off"
                                                disabled
                                            />
                                        </Col>
                                        <Col sm="1">
                                        </Col>
                                        <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                            LIMIT NO. OF PARTICIPANTS
                                        </Form.Label>
                                        <Col sm="3">
                                            <Form.Control 
                                                ref="participantLimit"
                                                name="participantLimit"
                                                value={this.state.participantLimit}
                                                onChange={this.onChangeParticipantLimit}
                                                autoComplete="off"
                                                disabled
                                            />
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} controlId="formPlaintextEmail">
                                        <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                            FACILITATOR
                                        </Form.Label>
                                        <Col sm="6">
                                            <Form.Control 
                                                ref="facilitator"
                                                name="facilitator"
                                                value={this.state.facilitator}
                                                onChange={this.onChangeFacilitator}
                                                autoComplete="off"
                                                disabled
                                            />
                                        </Col>
                                        <Col sm="1">
                                        </Col>
                                        <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                            VENUE
                                        </Form.Label>
                                        <Col sm="3">
                                            <Form.Control 
                                                ref="venue"
                                                name="venue"
                                                value={this.state.venue}
                                                onChange={this.onChangeVenue}
                                                autoComplete="off"
                                                disabled
                                            />
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} controlId="formPlaintextEmail">
                                        <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                            DATE FROM
                                        </Form.Label>
                                        <Col sm="2">
                                            <Form.Control 
                                                ref="dateFrom"
                                                name="dateFrom"
                                                value={this.state.dateFrom}
                                                onChange={this.onChangeDateFrom}
                                                autoComplete="off"
                                                disabled
                                            />
                                            {/* <DatePicker
                                                ref='dateFrom'
                                                selected={this.state.dateFrom}
                                                onChange={this.handleChangeDateFrom}
                                                minDate={this.minDate}
                                                value={this.state.dateFrom}
                                                dateFormat={"MM/dd/yyyy"}
                                                className="form-control"
                                                disabled
                                            /> */}
                                        </Col>
                                        <Col sm="1">
                                        </Col>
                                        <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                            DATE TO
                                        </Form.Label>
                                        <Col sm="2">
                                            {/* <DatePicker
                                                ref='dateTo'
                                                selected={this.state.dateTo}
                                                onChange={this.handleChangeDateTo}
                                                minDate={this.minDate}
                                                value={this.state.dateTo}
                                                dateFormat={"MM/dd/yyyy"}
                                                className="form-control"
                                                disabled
                                            /> */}
                                            <Form.Control 
                                                ref="dateTo"
                                                name="dateTo"
                                                value={this.state.dateTo}
                                                onChange={this.onChangeDateTo}
                                                autoComplete="off"
                                                disabled
                                            />
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} controlId="formPlaintextEmail">
                                        <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                            TIME FROM
                                        </Form.Label>
                                        <Col sm="2">
                                            <Form.Control 
                                                ref="valueFrom"
                                                name="valueFrom"
                                                value={this.state.valueFrom}
                                                onChange={this.onChangeValueFrom}
                                                autoComplete="off"
                                                disabled
                                            />
                                            {/* <TimePicker 
                                                showSecond={showSecond}
                                                defaultValue={this.state.valueFrom}
                                                value={this.state.valueFrom}
                                                className="xxx"
                                                onChange={this.onChangeFrom}
                                                readOnly
                                            /> */}
                                        </Col>
                                        <Col sm="1">
                                        </Col>
                                        <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                            TIME TO
                                        </Form.Label>
                                        <Col sm="2">
                                            <Form.Control 
                                                ref="valueTo"
                                                name="valueTo"
                                                value={this.state.valueTo}
                                                onChange={this.onChangeValueTo}
                                                autoComplete="off"
                                                disabled
                                            />
                                            {/* <TimePicker 
                                                showSecond={showSecond}
                                                defaultValue={this.state.valueTo}
                                                value={this.state.valueTo}
                                                className="xxx"
                                                onChange={this.onChangeTo}
                                                readOnly
                                            /> */}
                                        </Col>
                                    </Form.Group>
                                    <ToolkitProvider
                                    keyField="id"   
                                    data={ this.state.trainingRegisterDG }
                                    columns={ trainingRegisterColumn }
                                    exportCSV={ {
                                        fileName: "Training - Attendance Reports.csv",
                                    } }
                                    >
                                    {
                                        props => (
                                        <div className="mt-3">
                                        <Card.Header className="mt-3">List Of Attendees</Card.Header>
                                        <BootstrapTable
                                            { ...props.baseProps } 
                                            /* keyField = "id"
                                            data = { this.state.employeeListTable }
                                            columns = { columnEmployee }
                                            pagination={ paginationFactory({sizePerPageRenderer}) }
                                            rowClasses="noser-table-row-class"
                                            striped
                                            hover
                                            condensed
                                            selectRow = { employeeRow } */


                                            striped
                                            hover
                                            condensed
                                            pagination={ paginationFactory({sizePerPageRenderer}) }
                                            keyField = "id"
                                            data = { this.state.trainingRegisterDG }
                                            noDataIndication={ () => <div>No record found.</div> }
                                            columns = { trainingRegisterColumn }
                                            cellEdit = { cellEditFactory({
                                                mode: 'dbclick',
                                                blurToSave: true,
                                                afterSaveCell: (oldValue, newValue, row, column) => {
                                                    this.GridDataModified(oldValue, newValue, row.id, column.dataField)
                                                    }
                                                })
                                            }
                                            rowEvents={ rowEvents }
                                            selectRow = { selectRow }
                                        />

                                            {/* <BootstrapTable
                                                keyField = "id"
                                                data = { this.state.trainingRegisterDG }
                                                columns = { trainingRegisterColumn }
                                                pagination={ paginationFactory({sizePerPageRenderer}) }
                                                rowClasses="noser-table-row-class"
                                                striped
                                                hover
                                                condensed
                                                cellEdit = { cellEditFactory({
                                                    mode: 'dbclick',
                                                    blurToSave: true,
                                                    afterSaveCell: (oldValue, newValue, row, column) => {
                                                        this.GridDataModified(oldValue, newValue, row.id, column.dataField)
                                                        }
                                                    })
                                                }
                                                rowEvents={ rowEvents }
                                                selectRow = { selectRow }

                                            /> */}
                                
                                            <ButtonToolbar className="mt-5" sm={12}>
                                                <ExportCSVButton className="btn btn-info" style={{minWidth:'60px'}} { ...props.csvProps }>
                                                    Generate ATTENDANCE SHEET
                                                </ExportCSVButton>&nbsp;&nbsp;
                                                <Button onClick={this.handleRemoveClick} disabled={this.state.disableRemove} variant="danger" className="ml-auto" style={{minWidth:'60px'}}>
                                                Remove
                                                </Button>&nbsp;&nbsp;
                                                <Button onClick={this.handleUpdateClick} disabled={this.state.disableSubmit} variant="success" style={{minWidth:'60px'}}>
                                                Submit
                                                </Button>&nbsp;&nbsp;
                                                <NavLink to="/trainingregister">
                                                    <Button variant="danger" href="/trainingregister" style={{minWidth:'60px'}}>
                                                        Back
                                                    </Button>
                                                </NavLink>
                                            </ButtonToolbar>
                                        </div>
                                        )
                                    }
                                </ToolkitProvider>

                                    {/* <ButtonToolbar className="mt-5" sm={12}>
                                        <Button variant="success" style={{minWidth:'60px'}} onClick={this.handleUpdateClick}>
                                            Generate Report
                                        </Button>&nbsp;&nbsp;
                                        <Button disabled={this.state.disableRemove} variant="danger" className="ml-auto" style={{minWidth:'60px'}} onClick={this.handleRemoveClick}>
                                           Remove
                                        </Button>&nbsp;&nbsp;
                                        <Button disabled={this.state.disableSubmit} variant="success" style={{minWidth:'60px'}} onClick={this.handleUpdateClick}>
                                           Submit
                                        </Button>&nbsp;&nbsp;
                                        <NavLink to="/trainingregister">
                                            <Button variant="danger" href="/trainingregister" style={{minWidth:'60px'}}>
                                                Back
                                            </Button>
                                        </NavLink>
                                    </ButtonToolbar> */}
                                </Form>
                            </Card.Body>
                        </Card>
                       
                    </Container>
                    <NoserLoading show={this.state.isloading} />
            </div> 
        )
    }

}

export  default TrainingRegisterDetails
