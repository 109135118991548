import {
    React,Component,BootstrapTable,
    paginationFactory,Button, ButtonToolbar, Card, 
    Form, Col, Row, axios, Container, Banner, Typeahead, TimePicker,
    cellEditFactory, Type, moment, NavLink, Alert, AppConfiguration,
    NoserLoading, Noser, sizePerPageRenderer
} 
from '../../noser-hris-component';
import DocumentsClient from './DocumentsModal/DocumentsClient';
import DocumentsPosition from './DocumentsModal/DocumentsPosition';

class Document extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fade            :   true, 
            color           :   "",
            isshow          :   false,
            message         :   "",
            userinfo        :   [],
            isloading       :   false,
            alerttype       :   "",
            disablebtn      :   true,
            openClientModal :   false,
            openPositionModal:  false,
            clientId        :   "",
            trainingId      :   "",
            documentDDL     :   [],
            documentsGrid    :   [],
            clientDDL       :   [],
            requiredDDL     :   [{"value":"1","label":"MANDATORY"},{"value":"0","label":"OPTIONAL"}]
        }
    }

    componentDidMount(){
        const sleep = (milliseconds) => {return new Promise(resolve => setTimeout(resolve, milliseconds))}
        this.state.userinfo = JSON.parse(sessionStorage.getItem("userData"))
        this.GetDocuments();
        this.GetClients();
        this.GetDocumentsGrid();
    }

    GetDocuments = async()=> {
        this.setState({isloading:true})

        const param = {
            "IpAddress":"0.0.0.0",
            "UserId":this.state.userinfo.userId,
            "ClientId":"",
            "Code":"",
            "Name":""
        };
        await
        axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Maintenance/GetDocuments",  param)
        .then(res => {
            const data = res.data;
            this.setState({ documentDDL:data.documents, isloading : false })
            if(data.status=="0"){
                this.setState({
                    isloading   :   false,
                    alerttype   :   "Error!",
                    isshow      :   true,
                    color       :   "danger",
                    message     :   data.message,
                    fade        :   true
                });
            
            }
                    
        })
        .catch(error=>{
            this.setState({
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade            :   true
            })
        })
        
    }

    handleDocumentsChanged = (e) => {
        if(e.length === 0) {
            this.state.documentsName=""
            return
        }
        this.state.documentsName=e[0].name
        this.setState({isshow:false})

    }

    GetClients = async() => {
        this.setState({isloading:true})
        const param = {
            "IpAddress"     :   "0.0.0.0",
            "ClientId"      :   this.state.userinfo.clientId,
            "UserId"        :   this.state.userinfo.userId,
        };
        await axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Client/GetClientList",  param)
        .then(res => {
            const data = res.data;
            this.setState({clientDDL:data.clients,isloading:false});
        })
        .catch(error=>{
            this.setState(  {
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade            :   true
            })
        })
    }

    handleClientChanged = (e) => {
        if(e.length === 0) {
            this.state.clientId=""
            return
        }
        this.state.clientId=e[0].id
        this.setState({isshow:false})

    }

    handleSearchClick = () => {
        this.GetDocumentsGrid();
    }

    GetDocumentsGrid = async()=> {
        this.setState({isloading:true})

        const param = {
            "IpAddress":"0.0.0.0",
            "UserId":this.state.userinfo.userId,
            "ClientId":this.state.clientId,
            "Code":"",
            "Name":this.state.documentsName
        };
        await 
        axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Maintenance/GetDocuments",  param)
        .then(res => {
            const data = res.data;
            console.log("GetDocumentsGrid")
            console.log(data)
            this.setState({ documentsGrid:data.documents, isloading : false })
            if(data.status=="0"){
                this.setState({
                    isloading   :   false,
                    alerttype   :   "Error!",
                    isshow      :   true,
                    color       :   "danger",
                    message     :   data.message,
                    fade        :   true
                });
            
            }
                
        })
        .catch(error=>{
            this.setState({
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade            :   true
            })
        })
        
    }
    handleSaveClick = async() =>{
        this.setState({isloading:true})

        const param = {
            "IpAddress" : "0.0.0.0",
            "UserId" : this.state.userinfo.userId,
            "ClientId" : this.state.userinfo.clientId,
            "documents" : this.state.documentsGrid.filter(x=>x.isModified==="1")
        };
        console.log("param")
        console.log(param)
        axios.post(AppConfiguration.Setting().noserapiendpoint + "Maintenance/EditDocument",  param)
            .then(res => {
                const data = res.data;
                this.GetDocuments()
                this.GetDocumentsGrid();
                this.setState({
                    isloading   :   false,
                    alerttype   :   data.status==="1" ? "Success!" : "Error!",
                    isshow      :   true,
                    color       :   data.status==="1" ? "success" : "error",
                    message     :   data.message,
                    fade        :   true,
                    disablebtn  :   true
                });
        })
        .catch(error=>{
            this.setState({
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade            :   true
            })
        })
    }
    
    handleEditClientClick = (row) =>{
        this.setState({openClientModal:true})
        this.child.initialize(row)
    }
    handleClientModalClose = (e) =>{
        this.setState({openClientModal:false})
        this.GetDocumentsGrid()
    }
    handleEditPositionClick = (row) =>{
        this.child1.initialize(row)
        this.setState({openPositionModal:true})
    }
    handlePositionModalClose = (e) =>{
        this.setState({openPositionModal:false})
        this.GetDocumentsGrid()
    }

    GridDataModified(oldValue, newValue, id, column) {
        let disable = true
        this.state.documentsGrid.map(function(item,i) {
            if (item.id===id){
                item.isModified = newValue!=oldValue ? "1" : "0"
                disable = newValue!=oldValue ? false : true
            }
        })
        this.setState({disablebtn:disable})
    }

    render() {
        const clientCol = [
            {
                dataField: 'client',
                text: 'CLIENT',
                headerStyle : () => {
                    return { width  : "100%" };
                }
            }
        ]
        const positionCol = [
            {
                dataField: 'position',
                text: 'POSITION',
                headerStyle : () => {
                    return { width  : "100%" };
                }
            }
        ]
        const documentsColumn = [
            {
                dataField: 'name',
                text: 'DOCUMENTS NAME',
                editable: true,
                headerStyle : () => {
                    return { width  : "20%" };
                }
            },
            {
                dataField: 'isRequired',
                text: 'REQUIRED',
                editable: true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'10%'}},
                style:{textAlign:'center'},
                editor: {
                    type: Type.SELECT,
                    options: this.state.requiredDDL
                  },
                formatter: (cell, row) => {
                    if(row.isRequired!=='' && row.isRequired!==null){
                        return this.state.requiredDDL.find(x => x.value == cell).label;
                    }
                }
            },
            {
                dataField   : 'client',
                text        : 'CLIENT',
                editable: false,
                headerStyle : () => {
                    return { width  : "35%" };
                },
                formatter   :   (cell, row, isSelect) => {
                    return (
                        <Button style={{paddingTop:'0',paddingBottom:'0',marginLeft:'-10px',fontSize:'10px'}}
                            variant="link" onClick={e => this.handleEditClientClick(row)}
                    >{cell}</Button>
                    );
                }
            },
            {
                dataField   : 'position',
                text        : 'POSITION',
                editable: false,
                headerStyle : () => {
                    return { width  : "35%" };
                },
                formatter   :   (cell, row, isSelect) => {
                    return (
                        <Button style={{paddingTop:'0',paddingBottom:'0',marginLeft:'-10px',fontSize:'10px'}}
                            variant="link" onClick={e => this.handleEditPositionClick(row)}
                    >{cell}</Button>
                    );
                }
            }
            
        ]

        const selectRow = {
            mode: 'checkbox',
            clickToSelectAndEditCell: true,
            onSelect: (row, isSelect, rowIndex, e) => {
                let disable = true
                this.state.documentsGrid.map(function(item,i){
                    if(item.id===row.id)
                    {
                        item.isDeleted = isSelect ? "1" : "0"
                        item.isModified = isSelect ? "1" : "0"
                        disable = isSelect ? false : true
                    }
                })
                this.setState({disablebtn:disable})
            }
        };

        const selectClientRow = {
            mode: 'checkbox',
            hideSelectAll: true
        };
        const selectPositionRow = {
            mode: 'checkbox',
            hideSelectAll: true
        };
        const expandItems = {
            onlyOneExpanding: true,
            renderer: row => 
            (
                <div>
                <div as={Col}>
                    <BootstrapTable
                        keyField = "id"
                        data = { row.documentClients }
                        columns = { clientCol }
                        selectRow = { selectClientRow }
                        striped
                        cellEdit = { cellEditFactory({ 
                            mode: 'dbclick', 
                            blurToSave: true,
                        afterSaveCell: (oldValue, newValue, row, column) => { 
                        this.ItemGridDataModified(oldValue, newValue, row.id, column)
                                }
                            })
                        }
                        wrapperClasses="table-responsive"
                        rowClasses="noser-table-row-class no-checkbox"
                        noDataIndication={ () => <div style={{width:'100%'}}>ALL CLIENTS</div> }
                    />
                </div>
                <div as={Col}>
                    <BootstrapTable
                        keyField = "id"
                        data = { row.documentPositions }
                        columns = { positionCol }
                        selectRow = { selectPositionRow }
                        striped
                        cellEdit = { cellEditFactory({ 
                            mode: 'dbclick', 
                            blurToSave: true,
                        afterSaveCell: (oldValue, newValue, row, column) => { 
                        this.ItemGridDataModified(oldValue, newValue, row.id, column)
                                }
                            })
                        }
                        wrapperClasses="table-responsive"
                        rowClasses="noser-table-row-class no-checkbox"
                        noDataIndication={ () => <div style={{width:'100%'}}>ALL POSITIONS</div> }
                    />
                </div> 
                </div>
            ),
            //nonExpandable: this.state.nonExpandableSubMenu,
            showExpandColumn: true
          };
        

    return(
            <div>
                <Banner />
                    <Container className="mt-5" fluid>
                        <Card>
                            <Card.Header>MAINTENANCE >> DOCUMENT MAINTENANCE >> DOCUMENT</Card.Header>
                            <Card.Body>
                                <Form>
                                    <Alert isOpen={this.state.isshow} color={this.state.color} fade={this.state.fade} className={this.state.isshow ? 'display-block' : 'display-none'}>
                                        <div className={this.state.color}></div> 
                                        {this.state.message}
                                    </Alert>
                                    <Form.Group as={Row} controlId="formPlaintextEmail">
                                        <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                            DOCUMENT NAME
                                        </Form.Label>
                                        <Col sm="11">
                                            <Typeahead
                                                labelKey='name'
                                                id="basic-example"
                                                onChange={this.handleDocumentsChanged}
                                                options={this.state.documentDDL}
                                            /> 
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} controlId="formPlaintextEmail">
                                        <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                            CLIENT
                                        </Form.Label>
                                        <Col sm="11">
                                            <Typeahead
                                                labelKey='name'
                                                id="basic-example"
                                                onChange={this.handleClientChanged}
                                                options={this.state.clientDDL}
                                            />
                                        </Col>
                                    </Form.Group>
                                    <ButtonToolbar className="mt-1">
                                        <Button variant="primary" className="ml-auto" onClick={ this.handleSearchClick }>
                                            Search
                                        </Button>
                                    </ButtonToolbar>
                                    <div className="mt-2">
                                        <Card.Header>Documents List</Card.Header>
                                        <BootstrapTable
                                            /* caption={Noser.TableHeader({title:"RECORD"})} */
                                            keyField = "id"
                                            data = { this.state.documentsGrid }
                                            columns = { documentsColumn }
                                            pagination={ paginationFactory({sizePerPageRenderer}) }
                                            noDataIndication={ () => <div style={{width:'100%'}}>No record found.</div> }
                                            rowClasses="noser-table-row-class"
                                            striped
                                            hover
                                            condensed
                                            cellEdit = { cellEditFactory({
                                                mode: 'dbclick',
                                                blurToSave: true,
                                                afterSaveCell: (oldValue, newValue, row, column) => {
                                                    this.GridDataModified(oldValue, newValue, row.id, column.dataField)
                                                    }
                                                })
                                            }
                                            selectRow = { selectRow }
                                            expandRow={ expandItems }
                                        />
                                    </div>
                                    <ButtonToolbar sm={12}>
                                        <Button variant="success" className="ml-auto noser-button-mr1" disabled={this.state.disablebtn} onClick={this.handleSaveClick}>Save</Button>
                                        <NavLink to="/documentscreate" >
                                            <Button className="noser-button" variant="primary" >
                                                Create
                                            </Button>
                                        </NavLink>
                                    </ButtonToolbar>
                                </Form>
                            </Card.Body>
                        </Card>
                    </Container>
                    <NoserLoading show={this.state.isloading} />
                    <DocumentsClient 
                        show={this.state.openClientModal}
                        onHide={this.handleClientModalClose}
                        onRefModal={ref => (this.child = ref)}
                    />
                    <DocumentsPosition
                        show={this.state.openPositionModal}
                        onHide={this.handlePositionModalClose}
                        onRefModal={ref => (this.child1 = ref)}
                    />
            </div> 
        )
    }

}

export  default Document 
