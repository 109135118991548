import {
    React,Component,BootstrapTable, Type,
    paginationFactory,Button, ButtonToolbar, Card,
    Form, Col, Row, Container, axios, Typeahead, Banner, TimePicker,
    cellEditFactory ,moment, NavLink, Alert, AppConfiguration,
    NoserLoading, Noser, sizePerPageRenderer, DatePicker
} 
from '../../noser-hris-component';
import type from 'react-bootstrap-table2-editor';

class ManagerProfile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userinfo        :   [],
            isloading       :   false,
            isshow          :   false,
            alerttype       :   "",
            message         :   "",
            color           :   "",
            fade            :   true,
            selected: [],
            statusList:[],
            employeeProfileList:[],
            employeeProfileListGrid:[],
            selectedSortBy:"",
            selectedSortById:"",
            selectedProfile:"",
            selectedEmployeeId:"",
            selectedProfileId:"",
            selectedClientName:"",
            selectedClientId:"",
            getClientList:[],

            open: false,

        }
        this.togglebutton = this.togglebutton.bind(this);
    }

    componentDidMount(){
        const sleep = (milliseconds) => {return new Promise(resolve => setTimeout(resolve, milliseconds))}
        this.state.userinfo = JSON.parse(sessionStorage.getItem("userData"))
        this.GetSubmittedEmployeeProfiles();
        this. getStatus();
        // this. getClient();
        
        sleep(1000).then(() => {
        this.setState({isLoading:false})})
        }

    togglebutton() {  
        const { open } = this.state;  
        this.setState({  
            open: !open,  
        });  
    }  


    handleCoverChangeSortBy = (e) => {
        if (e.length > 0) {
            this.state.selectedSortBy = e[0].name
            this.state.selectedSortById = e[0].id

        } else {
            this.state.selectedSortBy = ""
            this.state.selectedSortById = ""
        }
        
        console.log(this.state.selectedSortBy);
        
    } 
    
    handleChangeEmployee= (e) => {
        console.log(e)
            if (e.length == 0) {
                this.setState({selectedProfile: null, selectedEmployeeNameId:"", selectedProfileId:''})
            return
        }
         this.state.selectedProfile = e[0].employeeName
        // this.state.selectedEmployeeNameId = e[0].id
        this.state.selectedProfileId =e[0].employeeName

        // this.GetSubmittedEmployeeProfiles();
        this.setState({isloading:false,})
    }

    GetSubmittedEmployeeProfiles() {
        this.setState({
            employeeProfileList:[]
            
        })
        const employeeParams = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.userinfo.clientId,
            "UserId":this.state.userinfo.userId,
            "RoleId":this.state.userinfo.roleId,
            "ProfileId":this.state.selectedProfileId,
            "StatusId":""
        };
        console.log("Test 1");
        console.log(employeeParams)
        axios
            .post(AppConfiguration.Setting().noserapiendpoint + "Administrator/GetSubmittedEmployeeProfiles",  employeeParams)
        .then(res => {
            const data = res.data;
            console.log("Test app2");
            console.log(data);
            this.setState({ employeeProfileList: data.employeeProfiles});
            if(data.status=="1"){
                this.setState({
                    isloading       :   false,
                    alerttype       :   "Success!",
                    isshow          :   true,
                    color           :   "success",
                    message         :   data.message,
                    fade            :   true
                });
            }
            else{
                this.setState({
                    isloading       :   false,
                    alerttype       :   "Error!",
                    isshow          :   true,
                    color           :   "danger",
                    message         :   data.message,
                    fade            :   true
                })
            }
            })
            .catch(error=>{
            this.setState(  {
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade            :   true
            })
        })

    }

    getStatus(){
        const statusParams = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.userinfo.clientId,
            "UserId":this.state.userinfo.userId,
            "Code":"0021"
        };
        

        axios
            .post(AppConfiguration.Setting().noserapiendpoint + "Maintenance/GetDataReferences", statusParams)
            .then(res => {
                const data = res.data;
                this.setState({
                    statusList : res.data.dataReferences})
                    
                })
                
            }

    handleSearchClick = event => {
        this.setState({employeeProfileListGrid:[], isloading:true})

        const searchParams = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.userinfo.clientId,
            "UserId":this.state.userinfo.userId,
            "RoleId":this.state.userinfo.roleId,
            "ProfileId":this.state.selectedProfileId,
            "StatusId":this.state.selectedSortById
        };
        console.log(searchParams)
        axios
            .post(AppConfiguration.Setting().noserapiendpoint + "Administrator/GetSubmittedEmployeeProfiles",  searchParams)
            .then(res => {
            const data = res.data;
            console.log(res.data)
            this.setState({ employeeProfileListGrid: data.employeeProfiles});
            // this.processGrid();
            if(data.status=="1"){
                this.setState({
                    isloading       :   false,
                    alerttype       :   "Success!",
                    isshow          :   true,
                    color           :   "success",
                    message         :   data.message,
                    fade            :   true
                });
            }
            else{
                this.setState({
                    isloading       :   false,
                    alerttype       :   "Error!",
                    isshow          :   true,
                    color           :   "danger",
                    message         :   data.message,
                    fade            :   true
                })
            }
            })
            .catch(error=>{
            this.setState(  {
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade            :   true
            })
        })

    }

    render() {
        const columns1 = [
            {
                dataField: 'employeeName',
                text: 'FULL NAME'
            },
            {
                dataField: 'status',
                text: 'APPROVAL STATUS',
            },
            {
                dataField: 'createdDate',
                text: 'SUBMITTED DATE',
            },
            ] 
       
       
       const selectRow = {
        //     mode: 'checkbox',
        //     clickToSelect: true
        //   };
            mode: 'checkbox',
            clickToSelectAndEditCell: true,
            onSelect: (row, isSelect, rowIndex, e) => {
                this.state.employeeProfileListGrid.map(function(item,i){
                    if(item.id===row.id)
                    {
                        item.isDeleted = isSelect ? "1" : "0"
                        item.isModified = isSelect ? "1" : "0"
                    }
                })
             }
        };
        const rowEvents = {
            onClick: (e, row, rowIndex) => {
              //alert(e.row);
            }
        };

        var { title, children } = this.props;  
        const { open } = this.state;  
        if (open) {  
            title = 'Hide Component';  
        } else {  
            title = 'Show Component';  
        }  

        return(
            <div onClick={this.togglebutton}>
                <Banner />
                    <Container className="mt-5">
                        <Card>
                            <Card.Header> Profile Search</Card.Header>
                            <Card.Body>
                                <Form>
                                <Alert isOpen={this.state.isshow} color={this.state.color} fade={this.state.fade} className={this.state.isshow ? 'display-block' : 'display-none'}>
                                        <div className={this.state.color}></div> 
                                        {this.state.message}
                                    </Alert>
                                    <Form.Row>
                                        <Col sm={6} className="mt-3">
                                        <Typeahead
                                                labelKey='employeeName'
                                                id="basic-example"
                                                onChange={this.handleChangeEmployee}
                                                options={this.state.employeeProfileList}
                                                placeholder="EMPLOYEE"
                                            />                           
                                        </Col>
                                        <Col sm={6} className="mt-3">
                                             <Typeahead
                                                    labelKey='name'
                                                    id="basic-example"
                                                    onChange={this.handleCoverChangeSortBy}
                                                    options={this.state.statusList}
                                                    placeholder="SORT BY"
                                                /> 
                                        </Col>
                                    </Form.Row>
                                    
                                    <ButtonToolbar sm={12} className="mt-3">
                                        <Button variant="primary" className="ml-auto" onClick={this.handleSearchClick}>
                                            Search
                                        </Button>&nbsp;&nbsp;
                                        <NavLink to="/ManagerProfileCreate">
                                            <Button  variant="primary" variant="success">
                                                Create
                                            </Button>
                                        </NavLink>&nbsp;&nbsp;
                                        {open && (
                                        <NavLink to="/BatchApprove">
                                            <Button  variant="primary" variant="success">
                                                 Batch Approve
                                            </Button>
                                        </NavLink>
                                         )}  
                                    </ButtonToolbar>
                                    <div className="mt-5">
                                        <BootstrapTable
                                            ref="tbl"
                                            caption={Noser.TableHeader({title:"Search Result"})}
                                            rowClasses="noser-table-row-class"
                                            striped
                                            hover
                                            condensed
                                            pagination={ paginationFactory({sizePerPageRenderer}) }
                                            keyField = "id"
                                            data = { this.state.employeeProfileListGrid }
                                            columns = { columns1}
                                            selectRow = { selectRow }
                                            cellEdit = { cellEditFactory({
                                                    mode: 'dbclick',
                                                    blurToSave: true,
                                                    afterSaveCell: (oldValue, newValue, row, column) => {
                                                                this.GridDataModified(oldValue, newValue, row.id, column)
                                                    }
                                                })
                                            }
                                            rowEvents={ rowEvents }
                                        />
                                    </div>
                                
                                </Form>
                            </Card.Body>
                        </Card>
                    </Container>
                    <NoserLoading show={this.state.isLoading} />
            </div> 
                        
        )
    }
}

export default ManagerProfile;