import {
    React,Component,BootstrapTable,
    paginationFactory,Button, ButtonToolbar, Card, 
    Form, Col, Row, axios, Container, Banner, Typeahead, TimePicker,
    cellEditFactory, Type, moment, NavLink, Alert, AppConfiguration,
    NoserLoading, Noser, sizePerPageRenderer, DatePicker, Modal
} 
from '../../../noser-hris-component';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css';

class CreateUserClient extends Component {
    constructor(props) {
        super(props)
        this.state = {
            userinfo    :   JSON.parse(sessionStorage.getItem("userData")),
            isloading   :   false,
            isshow      :   false,
            alerttype   :   "",
            message     :   "",
            color       :   "",
            fade        :   true,
            disableAdd  :   true,
            employeeId  :   '',
            employeeName:   '',
            clientId    :   '',
            clientName  :   '',
            userclients :   [],
            clientDDL   :   [],
        }
    }
    componentDidMount(){
        this.state.userinfo = JSON.parse(sessionStorage.getItem("userData"))
        this.props.onRefModal(this)
    }
    componentWillUnmount() {
        this.props.onRefModal(undefined)
    }
    initialize=(e)=>{
        this.setState({
            userinfo    :   JSON.parse(sessionStorage.getItem("userData")),
            isloading   :   false,
            isshow      :   false,
            alerttype   :   "",
            message     :   "",
            color       :   "",
            fade        :   true,
            disableAdd  :   true,
            
            employeeId  :   e.employeeId,
            employeeName:   e.employeeName,
            clientId    :   '',
            clientName  :   '',
            userclients :   [],
            clientDDL   :   [],
        })
        this.GetClients()
    }
    GetClients = async() =>{
        this.setState({isloading:true,isDisable:true})
        const params = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.userinfo.clientId,
            "UserId":this.state.userinfo.userId,
        }
        
        await axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Client/GetClientList", params)
        .then(res => {
            const data = res.data
            this.setState({
                isloading   :   false,
                alerttype   :   data.status=="1" ? "Success!" : "Error!",
                isshow      :   data.status=="1" ? false : true,
                color       :   data.status=="1" ? "success" : "danger",
                message     :   data.message,
                fade        :   true,
                clientDDL   :   data.clients,
            });
        })
        .catch(error=>{
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade        :   true,
            })
        })
    }
    handleSubmit = (e) =>{
        this.setState({isloading:true})

        if(this.state.userclients.length===0){
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "Please add client.",
                fade        :   true
            })
            return 
        }
        
        const param = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.userinfo.clientId,
            "UserId":this.state.userinfo.userId,
            "EmployeeId":this.state.employeeId,
            "clients":this.state.userclients.filter(x=>x.isSelected==="1")
        }
        axios
            .post(
                AppConfiguration.Setting().noserapiendpoint + "Administrator/AddUserClient",  param
            )
            .then(res => {
                const data = res.data;
                this.setState({
                    isloading   :   false,
                    alerttype   :   res.data.status=="1" ? "Success!" : "!Error",
                    isshow      :   true,
                    color       :   res.data.status=="1" ? "success" : "danger",
                    message     :   data.message,
                    fade        :   true
                });
            })
            .catch(error=>{
                this.setState({
                    isloading   :   false,
                    alerttype   :   "Error!",
                    isshow      :   true,
                    color       :   "danger",
                    message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                    fade        :   true
                })
            })
    }
    handleModalClose = () => {
        this.props.onHide();            
    }
    handleChange = (e) =>{
        this.setState({[e.target.name]: e.target.value.toUpperCase()})
    }
    handleChangeClient = (e) =>{
        this.setState({alerttype:"",isshow:false,color:"",message:"",fade:false});
        if(e.length==0)
        {
            this.state.clientId=''
            this.state.clientName=''
            this.state.disableAdd=true
            return
        }
        this.state.disableAdd=false
        this.state.clientId= e[0].id
        this.state.clientName=e[0].name
    }
    handleAddClient = (e) => {
        this.setState({isloading:true})
        if(!this.state.clientId){
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "Please select client.",
                fade        :   true
            });
            return
        }
        
        if(this.state.userclients.filter(x=>x.clientId===this.state.clientId).length>0){
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "Selected client already exist.",
                fade        :   true
            });
            return
        }

        const ids = this.state.userclients.map(x => x.id);
        const sorted = ids.sort((a, b) => a - b);
        let maxId = parseInt(sorted[sorted.length-1]) + 1;
        const obj = {
            "id":maxId.toString(),
            "clientId" : this.state.clientId,
            "name" : this.state.clientName,
            "isDeleted" : "0",
        }
        
        let isNew = true;
        const lstRange = this.state.userclients
        lstRange.forEach(itm => {
            if(itm.clientId==obj.clientId)
            {
                itm.id=obj.id
                itm.clientId=obj.clientId
                itm.name=obj.name
                itm.isDeleted="0"
                isNew=false
            }
        });
        if(isNew){
            lstRange.push(obj)
        }
        this.state.userclients = []
        this.setState({userclients: lstRange,isloading:false,clientId:'',clientName:''})
        this.refs.clientRef.clear()
    }
    render() {
        const { SearchBar, ClearSearchButton } = Search;
        const col = [
            {
                dataField: 'name',
                text: 'CLIENT NAME',
                editable: false,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'left',width:'100%' }},
                style:{textAlign:'left',width:'100%'},
                //filter: textFilter()
            },
        ]
        const selectRow = {
            mode: 'checkbox',
            hideSelectAll: false,
            clickToSelectAndEditCell: true,
            onSelect: (row, isSelect, rowIndex, e) => {
                if(isSelect)
                {
                    this.state.userclients.push({
                        "clientId":row.id,
                        "clientName":row.name,
                        "isSelected":"1"
                    })
                }
                else{
                    let userclients = this.state.userclients
                    this.state.userclients.map(function(itm,idx){
                        if(itm.id===row.clientId){
                            userclients.splice(idx,1)
                        }
                    })
                    this.setState({userclients:userclients})
                }
            },
            onSelectAll: (isSelected, rows) => {
                this.state.userclients = []
                if (isSelected) {
                    this.state.clientDDL.forEach(itm =>{
                        this.state.userclients.push({
                            "clientId":itm.id,
                            "clientName":itm.name,
                            "isSelected":isSelected ? "1" : "0"
                        })
                    })
                    return this.state.clientDDL.map(itm => itm.id);
                }
                else{
                    this.state.userclients = []
                }
            }
        };
        
    return(
        
            <Modal
                {...this.props}
                return
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                backdrop="static"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        ADD CLIENT
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container>
                        <Alert isOpen={this.state.isshow} color={this.state.color} fade={this.state.fade} className={this.state.isshow ? 'display-block' : 'display-none'}>
                            <div className={this.state.color}></div> 
                            {this.state.message}
                        </Alert>
                        <Form.Row>
                            <Form.Group as={Col} sm={12} >
                                <Form.Control 
                                    name='name'
                                    type="text" 
                                    onChange={this.handleChange} 
                                    autoComplete="off" 
                                    value={this.state.employeeName}
                                    disabled
                                />
                            </Form.Group>
                        </Form.Row>
                        {/* <Form.Row>
                            <Form.Group as={Col} sm={10} >
                                <Typeahead
                                    ref="clientRef"
                                    labelKey='name'
                                    id="basic-example"
                                    onChange={this.handleChangeClient}
                                    options={this.state.clientDDL}
                                    placeholder="Select Client"
                                />
                            </Form.Group>
                            <Form.Group as={Col} sm={2} >
                            <Button variant="success" 
                                    style={{minWidth:'60px',marginRight:'1pt'}} 
                                    className="ml-auto" 
                                    onClick = { this.handleAddClient }
                                    disabled={this.state.disableAdd}
                            >ADD</Button>
                            </Form.Group>
                        </Form.Row> */}
                        <Form.Row>
                            <Form.Group as={Col} sm={12} controlId="formGridEmail">
                                {/* <Card.Header>USER CLIENTS</Card.Header> */}
                                <div className="mt-1">
                                <ToolkitProvider
                                    keyField="id"
                                    data={ this.state.clientDDL }
                                    columns={ col }
                                    search = {{defaultSearch:'DALIRETAIL INC.'}}
                                    >
                                    {
                                        props => (
                                        <div>
                                            <SearchBar { ...props.searchProps } />
                                            <ClearSearchButton { ...props.searchProps } />
                                            <BootstrapTable
                                            { ...props.baseProps }
                                            selectRow = { selectRow }
                                            cellEdit = { cellEditFactory({ 
                                                    mode: 'dbclick', 
                                                    blurToSave: true,
                                                    afterSaveCell: (oldValue, newValue, row, column) => { 
                                                        this.GridDataModified(oldValue, newValue, row.id, column.dataField)
                                                    }
                                                })
                                            }
                                            rowClasses="noser-table-row-class"
                                            striped
                                            hover
                                            condensed
                                            loading={true}
                                            wrapperClasses="table-responsive"
                                            pagination={ paginationFactory({sizePerPageRenderer})}
                                            noDataIndication={ () => <div style={{width:'100%'}}>No record found.</div> }
                                            />
                                            {/* <hr /> */}
                                            {/* <BootstrapTable
                                                keyField = "id"
                                                data = { this.state.userclients }
                                                columns = { col }
                                                selectRow = { selectRow }
                                                cellEdit = { cellEditFactory({ 
                                                        mode: 'dbclick', 
                                                        blurToSave: true,
                                                        afterSaveCell: (oldValue, newValue, row, column) => { 
                                                            this.GridDataModified(oldValue, newValue, row.id, column.dataField)
                                                        }
                                                    })
                                                }
                                                rowClasses="noser-table-row-class"
                                                striped
                                                hover
                                                condensed
                                                loading={true}
                                                wrapperClasses="table-responsive"
                                                pagination={ paginationFactory({sizePerPageRenderer})}
                                                noDataIndication={ () => <div style={{width:'100%'}}>No record found.</div> }
                                            /> */}
                                        </div>
                                        )
                                    }
                                    </ToolkitProvider>
                                    
                                </div>
                                
                            </Form.Group>
                        </Form.Row>
                    </Container>
                </Modal.Body>
                <Modal.Footer>
                    <ButtonToolbar >
                        <Button variant="success" style={{minWidth:'60px',marginRight:'1pt'}} className="ml-auto" onClick = { this.handleSubmit }>Save</Button>
                        <Button variant="danger" style={{minWidth:'60px',marginRight:'15px'}} onClick={this.props.onHide}>Close</Button>
                    </ButtonToolbar>
                </Modal.Footer>
                <NoserLoading show={this.state.isloading} />
        </Modal>
        );
    }
}
export default CreateUserClient