import {
    React,Component,BootstrapTable,
    paginationFactory,Button, ButtonToolbar, Card, 
    Form, Col, Row, axios, Container, Banner, Typeahead, TimePicker,
    cellEditFactory, Type, moment, NavLink, Alert, AppConfiguration,
    NoserLoading, Noser, sizePerPageRenderer, DatePicker, Tabs, Tab, Accordion, 
}
from '../../noser-hris-component';
import {DropdownButton, Dropdown} from 'react-bootstrap';

class ProfileView extends Component {
    constructor(props) {
        super(props)
        this.state = {
            userinfo        :   [],
            isloading       :   false,
            isshow          :   false,
            alerttype       :   "",
            message         :   "",
            color           :   "",
            fade            :   true,
            remarks	                :   "",
            /* selectedClient	        :   "",
            selectedFullName	    :   "",
            selectedPositionApplied	:   "",
            currentAddress		    :   "", */
            /* selectedFirstName     :   "", */
            CitizenshipList:[
                {"name":"FILIPINO","id":"1"},
                {"name":"OTHERS","id":"2"}
            ],
            religionAutocompleteList:[
                {"name":"ADVENTIST","id":"1"},
                {"name":"AGLIPAY","id":"2"},
                {"name":"ALLIANCE","id":"3"},
                {"name":"APOSTLIC HOLINESS","id":"4"},
                {"name":"ASSEMBLY OF GOD","id":"5"},
                {"name":"BAPTIST","id":"6"},
                {"name":"BIBLE BAPTIST","id":"7"},
                {"name":"BORN AGAIN","id":"8"},
                {"name":"BORN AGAIN CHRISTIAN","id":"9"},
                {"name":"CATHOLIC","id":"10"},
                {"name":"CHRISTIAN","id":"11"},
                {"name":"CHRISTIAN(BORN AGAIN)","id":"12"},
                {"name":"CHURCH OF GOD","id":"13"},
                {"name":"EVANGILICAL","id":"14"},
                {"name":"IEMELIF","id":"15"},
                {"name":"IGLESIA NI CHRISTO","id":"16"},
                {"name":"ISLAM","id":"17"},
                {"name":"JEHOVA WITNESS","id":"18"},
                {"name":"JEHOVAH'S WITNESS","id":"19"},
                {"name":"JEHOVA'S WITNESS","id":"20"},
                {"name":"MCGI","id":"21"},
                {"name":"METHODIST","id":"22"},
                {"name":"MORMON","id":"23"},
                {"name":"MORMONS","id":"24"},
                {"name":"N/A","id":"25"},
                {"name":"OTHERS","id":"26"},
                {"name":"PENTECOSTAL","id":"27"},
                {"name":"PIC","id":"28"},
                {"name":"PINICOCS","id":"29"},
                {"name":"PROTESTANT","id":"30"},
                {"name":"ROMAN CATHOLIC","id":"31"},
                {"name":"SDA","id":"32"},
                {"name":"SEVENTH DAYA ADVENTIST","id":"33"},
                {"name":"THE CHURCH OF JESUS CHRIST","id":"34"},
                {"name":"UCCP","id":"35"},
                {"name":"UNION ESPIRITISTA" ,"id":"36"},
                {"name":"UPC","id":"37"},
                {"name":"V.C.C.P","id":"38"}

            ],
            genderAutocompleteList:[
                {"name":"MALE","id":"1"},
                {"name":"FEMALE","id":"2"}
            ],
            civilStatusAutocompleteList:[
                {"name":"SINGLE","id":"1"},
                {"name":"MARRIED","id":"2"},
                {"name":"DIVORCED","id":"3"},
                {"name":"SEPARATED","id":"4"}

            ],
            typeList:[
                {"name":"CURRENT ADDRESS","id":"0"},
                {"name":"REGISTERED ADDRESS","id":"1"},
                {"name":"PROVINCIAL ADDRESS","id":"2"},
                {"name":"CORRESPONDENCE ADDRESS","id":"3"},
                {"name":"FOREIGN ADDRESS","id":"4"},
            ],
            levelList:[
                {"name":"COLLEGE","id":"0"},
                {"name":"HIGH SCHOOL","id":"1"},
                {"name":"ELEMENTARY","id":"2"},
                {"name":"OTHERS","id":"3"}
            ],
            roleList:[
                {"name":"FATHER","id":"0"},
                {"name":"MOTHER","id":"1"},
                {"name":"SPOUSE","id":"2"},
                {"name":"BROTHER","id":"3"},
                {"name":"SISTER","id":"4"},
                {"name":"CHILD","id":"5"},
            ],
            tblRoleArrLst               :   [],
            tblLevelArrLst              :   [],
            applicationListGrid         :   [],
            employmentListGrid          :   [],
            documentsListGrid           :   [],
            tblTypeArrLst               :   [],
            educationListGrid           :   [],
            profileStatusAutocomplete   :   [],
            tblProfileArrLst            :   [],
            bloodTypeAutocomplete       :   [],
            livingArrangeAutocomplete   :   [],
            remarksAutocomplete         :   [],
            selectedUserFullName        :   "",
            selectedManagerName         :   "",
            relativeListGrid            :   [],
            addressListGrid             :   [],
            applicationFormListGrid     :   [],
            trainingsListGrid           :   [
                // {
                //     "trainingId"        :   "",
                //     "dateCompleted"     :   "",
                //     "presenter"         :   "",
                // }
            ],
            regionList                  :   [],
            provinceList                :   [],
            tblRegionArrLst             :   [],
            tblProvincesArrLst          :   [],
            tblCitiesArrLst             :   [],
            citylist                    :   [],

            employmentListGrid : [],
            backgroundListGrid : [],
            employeeAddressesListGrid:[]
        }

    }

    
    componentDidMount(){
        const sleep = (milliseconds) => {return new Promise(resolve => setTimeout(resolve, milliseconds))}
        this.state.userinfo = JSON.parse(sessionStorage.getItem("userData"))
        this.state.employeeProfileData = JSON.parse(sessionStorage.getItem("employeeProfileData")).employeeProfiles[0]["profileStatus"]
        // this.GetProvince();
        // this.GetRegion();
        // this.GetCity();
        this.getTypeList();
        this.getLevelList();
        this.getRoleList();
        this.GetProfileStatus();
        this.LoadProfileDetails();
    }
    LoadProfileDetails(){
        for (let i = 0; i < JSON.parse(sessionStorage.getItem("employeeProfileDetails")).employeeAddresses.length; i++) {
            const obj = {
            "typeId"           :   JSON.parse(sessionStorage.getItem("employeeProfileDetails")).employeeAddresses[i]["typeId"],
            "region"            :   JSON.parse(sessionStorage.getItem("employeeProfileDetails")).employeeAddresses[i]["region"],
            "province"          :   JSON.parse(sessionStorage.getItem("employeeProfileDetails")).employeeAddresses[i]["province"],
            "city"              :   JSON.parse(sessionStorage.getItem("employeeProfileDetails")).employeeAddresses[i]["city"],
            "houseNumber"       :   JSON.parse(sessionStorage.getItem("employeeProfileDetails")).employeeAddresses[i]["houseNumber"],
            "streetName"        :   JSON.parse(sessionStorage.getItem("employeeProfileDetails")).employeeAddresses[i]["streetName"],
            "barangay"          :   JSON.parse(sessionStorage.getItem("employeeProfileDetails")).employeeAddresses[i]["barangay"],
            "postalCode"        :   JSON.parse(sessionStorage.getItem("employeeProfileDetails")).employeeAddresses[i]["postalCode"],
                
            };

            this.state.employeeAddressesListGrid.push(obj);
        } 



        // FAMILY DATA

        for (let i = 0; i < JSON.parse(sessionStorage.getItem("employeeProfileDetails")).employeeFamilyBackgrounds.length; i++) {
            const obj = {
                "roleId"        :   JSON.parse(sessionStorage.getItem("employeeProfileDetails")).employeeFamilyBackgrounds[i]["roleId"],
                "name"          :   JSON.parse(sessionStorage.getItem("employeeProfileDetails")).employeeFamilyBackgrounds[i]["name"],
                "age"           :   JSON.parse(sessionStorage.getItem("employeeProfileDetails")).employeeFamilyBackgrounds[i]["age"],
                "occupation"    :   JSON.parse(sessionStorage.getItem("employeeProfileDetails")).employeeFamilyBackgrounds[i]["occupation"],
                "company"       :   JSON.parse(sessionStorage.getItem("employeeProfileDetails")).employeeFamilyBackgrounds[i]["company"],
            };

            this.state.backgroundListGrid.push(obj);
        } 

        // ApplicationRecords DATA
        for (let i = 0; i < JSON.parse(sessionStorage.getItem("employeeProfileDetails")).employeeApplicationRecords.length; i++) {
                const obj = {
                    "memberName"        :   JSON.parse(sessionStorage.getItem("employeeProfileDetails")).employeeApplicationRecords[i]["memberName"],
                    "dateApplied"       :   JSON.parse(sessionStorage.getItem("employeeProfileDetails")).employeeApplicationRecords[i]["dateApplied"],
                    "applicationFormNo" :   JSON.parse(sessionStorage.getItem("employeeProfileDetails")).employeeApplicationRecords[i]["referenceNo"],
                    "client"            :   JSON.parse(sessionStorage.getItem("employeeProfileDetails")).employeeApplicationRecords[i]["client"],
                    "position"          :   JSON.parse(sessionStorage.getItem("employeeProfileDetails")).employeeApplicationRecords[i]["position"],
                    "status"            :   JSON.parse(sessionStorage.getItem("employeeProfileDetails")).employeeApplicationRecords[i]["status"],
                };

                this.state.applicationListGrid.push(obj);
        } 

        // EDUCATION DATA

        for (let i = 0; i < JSON.parse(sessionStorage.getItem("employeeProfileDetails")).employeeEducations.length; i++) {
            const obj = {
                "levelId"       :   JSON.parse(sessionStorage.getItem("employeeProfileDetails")).employeeEducations[i]["levelId"],
                "schoolName"    :   JSON.parse(sessionStorage.getItem("employeeProfileDetails")).employeeEducations[i]["schoolName"],
                "course"        :   JSON.parse(sessionStorage.getItem("employeeProfileDetails")).employeeEducations[i]["course"],
                "startYear"     :   JSON.parse(sessionStorage.getItem("employeeProfileDetails")).employeeEducations[i]["startYear"],
                "endYear"       :   JSON.parse(sessionStorage.getItem("employeeProfileDetails")).employeeEducations[i]["endYear"],
                "honorRecieved" :   JSON.parse(sessionStorage.getItem("employeeProfileDetails")).employeeEducations[i]["honorRecieved"],
            };

            this.state.educationListGrid.push(obj);
        } 



        // employmentHistories DATA

         for (let i = 0; i < JSON.parse(sessionStorage.getItem("employeeProfileDetails")).employmentHistories.length; i++) {
            const obj = {
                "companyName"               :   JSON.parse(sessionStorage.getItem("employeeProfileDetails")).employmentHistories[i]["companyName"],
                "companyAddress"            :   JSON.parse(sessionStorage.getItem("employeeProfileDetails")).employmentHistories[i]["companyAddress"],
                "position"                  :   JSON.parse(sessionStorage.getItem("employeeProfileDetails")).employmentHistories[i]["position"],
                "workNature"                :   JSON.parse(sessionStorage.getItem("employeeProfileDetails")).employmentHistories[i]["natureOfWorkId"],
                "periodCovered"             :   JSON.parse(sessionStorage.getItem("employeeProfileDetails")).employmentHistories[i]["periodCovered"],
                "salary"                    :   JSON.parse(sessionStorage.getItem("employeeProfileDetails")).employmentHistories[i]["salary"],
                "supervisor"                :   JSON.parse(sessionStorage.getItem("employeeProfileDetails")).employmentHistories[i]["supervisor"],
                "contactNumber"             :   JSON.parse(sessionStorage.getItem("employeeProfileDetails")).employmentHistories[i]["contactNumber"],
                "reasonForLeaving"          :   JSON.parse(sessionStorage.getItem("employeeProfileDetails")).employmentHistories[i]["reasonForLeaving"],
                "taxableCompensationIncome" :   JSON.parse(sessionStorage.getItem("employeeProfileDetails")).employmentHistories[i]["taxableCompensationIncome"],
                "withholdingTax"            :   JSON.parse(sessionStorage.getItem("employeeProfileDetails")).employmentHistories[i]["withHoldingTax"],
                "yearOfCompensation"        :   JSON.parse(sessionStorage.getItem("employeeProfileDetails")).employmentHistories[i]["yearofCompensation"],
                "ntThirteenMonthPay"        :   JSON.parse(sessionStorage.getItem("employeeProfileDetails")).employmentHistories[i]["ntThirteenMonthPay"],
                "deminimis"                 :   JSON.parse(sessionStorage.getItem("employeeProfileDetails")).employmentHistories[i]["deminimis"],
                "ntMandatoryDeduction"      :   JSON.parse(sessionStorage.getItem("employeeProfileDetails")).employmentHistories[i]["ntMandatoryDeduction"],
                "ntSalaries"                :   JSON.parse(sessionStorage.getItem("employeeProfileDetails")).employmentHistories[i]["ntSalaries"],
                "basicSalary"               :   JSON.parse(sessionStorage.getItem("employeeProfileDetails")).employmentHistories[i]["basicSalary"],
                "taxableThirteenMonthPay"   :   JSON.parse(sessionStorage.getItem("employeeProfileDetails")).employmentHistories[i]["taxableThirteenMonthPay"],
                "taxableSalaries"           :   JSON.parse(sessionStorage.getItem("employeeProfileDetails")).employmentHistories[i]["taxableSalaries"],
                "basicPayMWE"               :   JSON.parse(sessionStorage.getItem("employeeProfileDetails")).employmentHistories[i]["basicPayMWE"],
                "holidayPayMWE"             :   JSON.parse(sessionStorage.getItem("employeeProfileDetails")).employmentHistories[i]["holidayPayMWE"],
                "overtimePayMWE"            :   JSON.parse(sessionStorage.getItem("employeeProfileDetails")).employmentHistories[i]["overtimePayMWE"],
                "nightDiffPayMWE"           :   JSON.parse(sessionStorage.getItem("employeeProfileDetails")).employmentHistories[i]["nightDiffPayMWE"],
                "remarks"                   :   JSON.parse(sessionStorage.getItem("employeeProfileDetails")).employmentHistories[i]["remarks"],
            };

            this.state.employmentListGrid.push(obj);
             //console.log(JSON.parse(sessionStorage.getItem("employeeProfileDetails")).employmentHistories)
        } 

        // Trainings DATA

        for (let i = 0; i < JSON.parse(sessionStorage.getItem("employeeProfileDetails")).employeeTrainings.length; i++) {
            const obj = {
                "trainingId"       :   JSON.parse(sessionStorage.getItem("employeeProfileDetails")).employeeTrainings[i]["trainingId"],
                "dateCompleted"    :   JSON.parse(sessionStorage.getItem("employeeProfileDetails")).employeeTrainings[i]["dateCompleted"],
                "presenter"        :   JSON.parse(sessionStorage.getItem("employeeProfileDetails")).employeeTrainings[i]["presenter"],
        
            };

            this.state.trainingsListGrid.push(obj);
             //console.log("training")
             //console.log(JSON.parse(sessionStorage.getItem("employeeProfileDetails")).employeeTrainings)
        } 


        for (let i = 0; i < JSON.parse(sessionStorage.getItem("employeeProfileData")).employeeProfiles.length; i++) {
            this.state.selectedPlaceOfBirth	            =   JSON.parse(sessionStorage.getItem("employeeProfileData")).employeeProfiles[i]["placeOfBirth"]
            this.state.employeeId                       =   JSON.parse(sessionStorage.getItem("employeeProfileData")).employeeProfiles[i]["id"]
            this.state.livingArrangement                =   JSON.parse(sessionStorage.getItem("employeeProfileData")).employeeProfiles[i]["livingArrangement"]
            this.state.bloodTypeId                      =   JSON.parse(sessionStorage.getItem("employeeProfileData")).employeeProfiles[i]["bloodTypeId"]
            this.state.dateOfBirth                      =   new Date(JSON.parse(sessionStorage.getItem("employeeProfileData")).employeeProfiles[0]["dateOfBirth"])
            this.state.profileStatus                    =   JSON.parse(sessionStorage.getItem("employeeProfileData")).employeeProfiles[i]["profileStatus"]
            this.state.selectedFirstName                =   JSON.parse(sessionStorage.getItem("employeeProfileData")).employeeProfiles[i]["firstName"]
            this.state.bloodType                         =   JSON.parse(sessionStorage.getItem("employeeProfileData")).employeeProfiles[i]["bloodType"]
            this.state.selectedMiddleName               =   JSON.parse(sessionStorage.getItem("employeeProfileData")).employeeProfiles[i]["middleName"]
            this.state.selectedNickName                 =   JSON.parse(sessionStorage.getItem("employeeProfileData")).employeeProfiles[i]["nickName"]
            this.state.selectedLastName                 =   JSON.parse(sessionStorage.getItem("employeeProfileData")).employeeProfiles[i]["lastName"]
            this.state.selectedTitle                    =   JSON.parse(sessionStorage.getItem("employeeProfileData")).employeeProfiles[i]["title"]
            this.state.selectedUserFullName              =   JSON.parse(sessionStorage.getItem("employeeProfileData")).employeeProfiles[i]["employeeName"]
            this.state.selectedAge                      =   JSON.parse(sessionStorage.getItem("employeeProfileData")).employeeProfiles[i]["age"]
            this.state.selectedNationality              =   JSON.parse(sessionStorage.getItem("employeeProfileData")).employeeProfiles[i]["nationality"]
            this.state.nationalityId                    =   JSON.parse(sessionStorage.getItem("employeeProfileData")).employeeProfiles[i]["nationalityId"]
            this.state.selectedHomePhoneNumber          =   JSON.parse(sessionStorage.getItem("employeeProfileData")).employeeProfiles[i]["homePhoneNumber"]
            this.state.selectedMobileNumber             =   JSON.parse(sessionStorage.getItem("employeeProfileData")).employeeProfiles[i]["mobileNumber"]
            this.state.religion                         =   JSON.parse(sessionStorage.getItem("employeeProfileData")).employeeProfiles[i]["religion"]
            this.state.religionId                       =   JSON.parse(sessionStorage.getItem("employeeProfileData")).employeeProfiles[i]["religionId"]
            this.state.selectedEmailAddress             =   JSON.parse(sessionStorage.getItem("employeeProfileData")).employeeProfiles[i]["emailAddress"]
            this.state.selectedFacebook                 =   JSON.parse(sessionStorage.getItem("employeeProfileData")).employeeProfiles[i]["facebook"]
            this.state.selectedTwitter                  =   JSON.parse(sessionStorage.getItem("employeeProfileData")).employeeProfiles[i]["twitter"]
            this.state.gender                           =   JSON.parse(sessionStorage.getItem("employeeProfileData")).employeeProfiles[i]["gender"]
            this.state.civilStatus                      =   JSON.parse(sessionStorage.getItem("employeeProfileData")).employeeProfiles[i]["civilStatus"]
            this.state.selectedSSSNumber                =   JSON.parse(sessionStorage.getItem("employeeProfileData")).employeeProfiles[i]["sssNumber"]
            this.state.selectedTinNumber                =   JSON.parse(sessionStorage.getItem("employeeProfileData")).employeeProfiles[i]["tinNumber"]
            this.state.selectedPhicNumber               =   JSON.parse(sessionStorage.getItem("employeeProfileData")).employeeProfiles[i]["philHealthNumber"]
            this.state.selectedHdmfNumber               =   JSON.parse(sessionStorage.getItem("employeeProfileData")).employeeProfiles[i]["pagibigNumber"]
            this.state.selectedHeight                   =   JSON.parse(sessionStorage.getItem("employeeProfileData")).employeeProfiles[i]["height"]
            this.state.selectedWeight                   =   JSON.parse(sessionStorage.getItem("employeeProfileData")).employeeProfiles[i]["weight"]
            this.state.selectedHobbies                  =   JSON.parse(sessionStorage.getItem("employeeProfileData")).employeeProfiles[i]["hobbies"]
            this.state.selectedLanguageSpoken           =   JSON.parse(sessionStorage.getItem("employeeProfileData")).employeeProfiles[i]["languageSpoken"]
            this.state.selectedEmergencyContactName     =   JSON.parse(sessionStorage.getItem("employeeProfileData")).employeeProfiles[i]["emergencyContactName"]
            this.state.selectedRelationship             =   JSON.parse(sessionStorage.getItem("employeeProfileData")).employeeProfiles[i]["emergencyContactRelationship"]
            this.state.selectedEmergencyContactNumber   =   JSON.parse(sessionStorage.getItem("employeeProfileData")).employeeProfiles[i]["emergencyContactNumber"]
            this.state.selectedManagerName           =   JSON.parse(sessionStorage.getItem("employeeProfileData")).employeeProfiles[i]["managerName"]
        }
    }
    getApplicationForm() {
        this.setState({
            isloading   :   true,
        })

        const applicationParams = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.selectedClientId,
            "UserId":this.state.userinfo.userId,
            "ProfileId":this.state.selectedProfileId,
            "PositionId":this.state.selectedPositionId,
            "StatusId":this.state.selectedStatusId,
            "TINNumber":this.state.selectedTinNo
        };
        // //console.log(applicationParams)
        axios
            .post(AppConfiguration.Setting().noserapiendpoint + "Recruitment/GetApplicationForms",  applicationParams)
            /* .post(AppConfiguration.Setting().noserapiendpoint + "Recruitment/GetApplicationFormDetails",  applicationParams) */
            .then(res => {
            const data = res.data;
            // //console.log("GetApplicationForms")
            // //console.log(data)
            this.setState({
                relativeListGrid    :   data.applicationForms,
                isloading	        :   false,
                applicationListGrid :   data.applicationForms,
            })

           
            if(data.status=="0"){
                this.setState({
                    isloading       :   false,
                    alerttype       :   "Error!",
                    isshow          :   true,
                    color           :   "danger",
                    message         :   data.message,
                    fade            :   true
                })
            }
         })
         .catch(error=>{
            this.setState(  {
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade            :   true
            })
        })
    }

    

    formatDate(date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();
    
        if (month.length < 2) 
            month = '0' + month;
        if (day.length < 2) 
            day = '0' + day;
    
        return [month, day, year].join('/');
    }

    onChangeProfileStatus(e){
         //console.log(e)
        this.setState({profileStatus:e.target.value})
    }

    onChangeNationality(e){
         //console.log(e)
        this.setState({nationality:e.target.value})
    }

    onChangeReligion(e){
         //console.log(e)
        this.setState({religion:e.target.value})
    }

    onChangeGender(e) {
        this.setState({gender : e.target.value})
        //////// //console.log(e.target.value)
    }

    onChangeUserFullName(e) {
        this.setState({selectedUserFullName : e.target.value})
        //////// //console.log(e.target.value)
    }  

    onChangeManagerName(e) {
        this.setState({selectedManagerName : e.target.value})
        //////// //console.log(e.target.value)
    }

    onChangeEmergencyContactName(e) {
        this.setState({selectedEmergencyContactName : e.target.value})
        //////// //console.log(e.target.value)
    }   

    onChangeRelationship(e) {
        this.setState({selectedRelationship : e.target.value})
        //////// //console.log(e.target.value)
    }  

    onChangeContactNumber(e) {
        this.setState({selectedEmergencyContactNumber : e.target.value})
        //////// //console.log(e.target.value)
    }

    onChangeLanguageSpoken(e) {
        this.setState({selectedLanguageSpoken : e.target.value})
        //////// //console.log(e.target.value)
    }

    onChangeHeight(e) {
        this.setState({selectedHeight : e.target.value})
        //////// //console.log(e.target.value)
    }

    onChangeWeight(e) {
        this.setState({selectedWeight : e.target.value})
        //////// //console.log(e.target.value)
    }

    onChangeHobbies(e) {
        this.setState({selectedHobbies : e.target.value})
        //////// //console.log(e.target.value)
    }

    onChangeFirstName(e) {
        this.setState({selectedFirstName : e.target.value})
        //////// //console.log(e.target.value)
    }

    onChangeMiddleName(e) {
        this.setState({selectedMiddleName : e.target.value})
        //////// //console.log(e.target.value)
    }

    onChangeNickName(e) {
        this.setState({selectedNickName : e.target.value})
        //////// //console.log(e.target.value)
    }

    onChangeLastName(e) {
        this.setState({selectedLastName : e.target.value})
        //////// //console.log(e.target.value)
    }

    onChangeTitle(e) {
        this.setState({selectedTitle : e.target.value})
        //////// //console.log(e.target.value)
    }

    onChangePlaceOfBirth(e) {
        this.setState({selectedPlaceOfBirth : e.target.value})
        //////// //console.log(e.target.value)
    }

    onChangeHomePhoneNumber(e) {
        this.setState({selectedHomePhoneNumber : e.target.value})
        //////// //console.log(e.target.value)
    }

    onChangeMobileNumber(e) {
        this.setState({selectedMobileNumber : e.target.value})
        //////// //console.log(e.target.value)
    }

    
    onChangeEmailAddress(e) {
        this.setState({selectedEmailAddress : e.target.value})
        //////// //console.log(e.target.value)
    }

    onChangeFacebook(e) {
        this.setState({selectedFacebook : e.target.value})
        //////// //console.log(e.target.value)
    }

    onChangeTwitter(e) {
        this.setState({selectedTwitter : e.target.value})
        //////// //console.log(e.target.value)
    }

    onChangeSSSNumber(e) {
        this.setState({selectedSSSNumber : e.target.value})
        //////// //console.log(e.target.value)
    }

    onChangeTinNumber(e) {
        this.setState({selectedTinNumber : e.target.value})
        //////// //console.log(e.target.value)
    }

    onChangePhicNumber(e) {
        this.setState({selectedPhicNumber : e.target.value})
        //////// //console.log(e.target.value)
    }

    onChangeHdmfNumber(e) {
        this.setState({selectedHdmfNumber : e.target.value})
        //////// //console.log(e.target.value)
    }

   
    onChangeCivilStatus(e) {
        this.setState({civilStatus : e.target.value})
        //////// //console.log(e.target.value)
    }

    onChangeLivingArrangement(e) {
        this.setState({livingArrangement : e.target.value})
        //////// //console.log(e.target.value)
    }

    GetProfileStatus = async () => {
        const dataParams = {
            "IpAddress"     :   "0.0.0.0",
            "ClientId"      :   this.state.userinfo.clientId,
            "UserId"        :   this.state.userinfo.userId,
            "Code"          :   "0018"
        };

        await axios.post(AppConfiguration.Setting().noserapiendpoint + "Maintenance/GetDataReferences",  dataParams)
        .then(res => {
            const data = res.data;
            //////// //console.log("GetProfileStatus")
            //////// //console.log(data)
            this.setState({ profileStatusAutocomplete  : data.dataReferences}); 
        })
        
    } 


    handleCoverSSSRemarks= (e) => {
        //////////// //console.log(e)
        if(e.length > 0) {
            this.state.sssRemarksId = e[0].id
        }else{
            this.state.sssRemarksId = ""
        }
    }

    handleCoverTINRemarks= (e) => {
        //////////// //console.log(e)
        if(e.length > 0) {
            this.state.tinRemarksId = e[0].id
        }else{
            this.state.tinRemarksId = ""
        }
    }

    handleCoverPHILHEALTHRemarks= (e) => {
        //////////// //console.log(e)
        if(e.length > 0) {
            this.state.phicRemarksId = e[0].id
        }else{
            this.state.phicRemarksId = ""
        }
    }

    handleCoverPAGIBIGRemarks= (e) => {
        //////////// //console.log(e)
        if(e.length > 0) {
            this.state.pagibigRemarksId = e[0].id
        }else{
            this.state.pagibigRemarksId = ""
        }
    }

    onChangeBloodType(e) {
        this.setState({bloodType : e.target.value})
        //////// //console.log(e.target.value)
    }


    handleSubmitClick = () => {
        //////// //console.log(this.state.selectedFirstName)
    }
         
    getTypeList(){
        //////// //console.log("getTypeList")
        //////// //console.log(this.state.typeList)
        for (let i = 0; i < this.state.typeList.length; i++) {
            const objType = {
                value : this.state.typeList[i]["id"],
                label : this.state.typeList[i]["name"],
            };
            this.state.tblTypeArrLst.push(objType);
        }
    }
         
    getLevelList(){
        //////// //console.log("getLevelList")
        //////// //console.log(this.state.levelList)
        for (let i = 0; i < this.state.levelList.length; i++) {
            const objType = {
                value : this.state.levelList[i]["id"],
                label : this.state.levelList[i]["name"],
            };
            this.state.tblLevelArrLst.push(objType);
        }
    }
         
    getRoleList(){
        for (let i = 0; i < this.state.roleList.length; i++) {
            const objType = {
                value : this.state.roleList[i]["id"],
                label : this.state.roleList[i]["name"],
            };
            this.state.tblRoleArrLst.push(objType);
        }
    }

    
  
    addAddress = () => {
        let address = this.state.addressListGrid
        let obj = {
            "typeId"        :   "",
            "region"        :   "",
            "province"      :   "",
            "city"          :   "",
            "houseNumber"   :   "",
            "streetName"    :   "",
            "barangay"      :   "",
            "postalCode"    :   "",
        }
    
        address.push(obj)
        this.setState({addressListGrid: address})
    }

    GetRegion() {
        const regionParams = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.userinfo.clientId,
            "UserId":this.state.userinfo.userId,
            "Name":this.state.selectedRegion
        };

        axios.post(AppConfiguration.Setting().noserapiendpoint + "Maintenance/GetRegions",  regionParams)
        .then(res => {
            const data = res.data;
            ////// //console.log("Test region");
            ////// //console.log(data);
            this.setState({ regionList: data.regions });
            for (let i = 0; i < data.regions.length; i++) {
                const regionObj = {
                    value : data.regions[i]["id"],
                    label : data.regions[i]["name"],
                };
                this.state.tblRegionArrLst.push(regionObj);
            }
         })
    }

    
    
    onChangeDateOfBirth = date => {
        this.setState({
            dateOfBirth: date
        });
    };

    AddressGridDataModified(oldValue, newValue, id, column) {
        //////// //console.log(id)
        this.state.addressListGrid.map(function(item,i) {
            if (item.id===id)
                item.isModified = newValue!=oldValue ? "1" : "0"
            })
    }

    CompanyRelativeGridDataModified(oldValue, newValue, id, column) {
        //////// //console.log(id)
        this.state.relativeListGrid.map(function(item,i) {
            if (item.id===id)
                item.isModified = newValue!=oldValue ? "1" : "0"
            })
    }

    FamilyGridDataModified(oldValue, newValue, id, column) {
        //////// //console.log(id)
        this.state.backgroundListGrid.map(function(item,i) {
            if (item.id===id)
                item.isModified = newValue!=oldValue ? "1" : "0"
            })
    }

    EducationGridDataModified(oldValue, newValue, id, column) {
        //////// //console.log(id)
        this.state.educationListGrid.map(function(item,i) {
            if (item.id===id)
                item.isModified = newValue!=oldValue ? "1" : "0"
            })
    }

    ApplicationGridDataModified(oldValue, newValue, id, column) {
        //////// //console.log(id)
        this.state.applicationListGrid.map(function(item,i) {
            if (item.id===id)
                item.isModified = newValue!=oldValue ? "1" : "0"
            })
    }

    EmploymentGridDataModified(oldValue, newValue, id, column) {
        //////// //console.log(id)
        this.state.employmentListGrid.map(function(item,i) {
            if (item.id===id)
                item.isModified = newValue!=oldValue ? "1" : "0"
            })
    }

    TrainingsGridDataModified(oldValue, newValue, id, column) {
        //////// //console.log(id)
        this.state.trainingsListGrid.map(function(item,i) {
            if (item.id===id)
                item.isModified = newValue!=oldValue ? "1" : "0"
            })
    }

    render() {

        const relativeColumn = [
            {
                dataField: 'relativeName',
                text: 'NAME',
                headerStyle: () => {
                    return { width: "40%" };
                },
            },
            {
                dataField: 'relativeRelation',
                text: 'RELATIONSHIP',
                headerStyle: () => {
                    return { width: "20%" };
                },
            },
            {
                dataField: 'relativePosition',
                text: 'JOB TITLE',
                headerStyle: () => {
                    return { width: "40%" };
                },
            }
        ] 

        const relativeSelectRow = {
            mode: 'checkbox',
            clickToSelectAndEditCell: true,
            onSelect: (row, isSelect, rowIndex, e) => {
                this.state.relativeListGrid.map(function(item,i){
                    if(item.id===row.id)
                    {
                        item.isDeleted = isSelect ? "1" : "0"
                        item.isModified = isSelect ? "1" : "0"
                    }
                })
            }
        };
        
        const relativeRowEvents = {
            onClick: (e, row, rowIndex) => {

            }
        };

        const addressColumn = [
            {
                dataField: 'typeId',
                text: 'TYPE',
                editable    : true,
                formatter: (cell, row) => {
                    if(row.typeId!='' && row.typeId!=null){
                        if(this.state.tblTypeArrLst.filter(x => x.value == cell).length>0)
                            return this.state.tblTypeArrLst.find(x => x.value == cell).label;
                    }
                },
                editor: {
                    type: Type.SELECT,
                    options: this.state.tblTypeArrLst
                },
                headerStyle: () => {
                    return { width: "10%" };
                },
            },
            {
                dataField: 'region',
                text: 'REGION',
                headerStyle: () => {
                    return { width: "15%" };
                },
            },
            {
                dataField: 'province',
                text: 'PROVINCE',
                headerStyle: () => {
                    return { width: "15%" };
                },
            },
            {
                dataField: 'city',
                text: 'CITY/MUNICIPALITY',
                headerStyle: () => {
                    return { width: "15%" };
                },
            },
            {
                dataField: 'houseNumber',
                text: 'HOUSE#',
                headerStyle: () => {
                    return { width: "10%" };
                },
            },
            {
                dataField: 'streetName',
                text: 'STREET',
                headerStyle: () => {
                    return { width: "10%" };
                },
            },
            {
                dataField: 'barangay',
                text: 'BARANGAY',
                headerStyle: () => {
                    return { width: "15%" };
                },
            },
            {
                dataField: 'postalCode',
                text: 'POSTAL CODE',
                headerStyle: () => {
                    return { width: "10%" };
                },
            }
        ] 
        const addressSelectRow = {
            mode: 'checkbox',
            clickToSelectAndEditCell: true,
            onSelect: (row, isSelect, rowIndex, e) => {
                this.state.addressListGrid.map(function(item,i){
                    if(item.id===row.id)
                    {
                        item.isDeleted = isSelect ? "1" : "0"
                        item.isModified = isSelect ? "1" : "0"
                    }
                })
            }
        };
        
            const addressRowEvents = {
                onClick: (e, row, rowIndex) => {
            }
        };

        const educationColumn = [
            {
                dataField: 'levelId',
                text: 'EDUCATION LEVEL',
                headerStyle: () => {
                    return { width: "25%" };
                },
                editable    : true,
                formatter: (cell, row) => {
                    if(row.levelId!='' && row.levelId!=null){
                        if(this.state.tblLevelArrLst.filter(x => x.value == cell).length>0)
                            return this.state.tblLevelArrLst.find(x => x.value == cell).label;
                    }
                },
                editor: {
                    type: Type.SELECT,
                    options: this.state.tblLevelArrLst
                },
            },
            {
                dataField: 'schoolName',
                text: 'SCHOOL NAME',
                headerStyle: () => {
                    return { width: "25%" };
                },
            },
            {
                dataField: 'course',
                text: 'COURSE',
                headerStyle: () => {
                    return { width: "15%" };
                },
            },
            {
                dataField: 'startYear',
                text: 'START YEAR',
                headerStyle: () => {
                    return { width: "20%" };
                },
            },
            {
                dataField: 'endYear',
                text: 'END YEAR',
                headerStyle: () => {
                    return { width: "20%" };
                },
            },
            /* {
                dataField: 'generalAverage',
                text: 'GENERAL AVERAGE'
            }, */
            {
                dataField: 'honorRecieved',
                text: 'HONOR(S)/AWARD(S)',
                headerStyle: () => {
                    return { width: "15%" };
                },
            
            }
        ] 

        const educationSelectRow = {
            mode: 'checkbox',
            clickToSelectAndEditCell: true,
            onSelect: (row, isSelect, rowIndex, e) => {
                this.state.educationListGrid.map(function(item,i){
                    if(item.id===row.id)
                    {
                        item.isDeleted = isSelect ? "1" : "0"
                        item.isModified = isSelect ? "1" : "0"
                    }
                })
            }
        };
        
        const educationRowEvents = {
                onClick: (e, row, rowIndex) => {
            }
        };

        const backgroundColumn = [
            {
                dataField: 'roleId',
                text: 'ROLE',
                headerStyle: () => {
                    return { width: "20%" };
                },
                editable    : true,
                formatter: (cell, row) => {
                    if(row.roleId!='' && row.roleId!=null){
                        if(this.state.tblRoleArrLst.filter(x => x.value == cell).length>0)
                            return this.state.tblRoleArrLst.find(x => x.value == cell).label;
                    }
                },
                editor: {
                    type: Type.SELECT,
                    options: this.state.tblRoleArrLst
                },
            },
            {
                dataField: 'name',
                text: 'NAME',
                headerStyle: () => {
                    return { width: "30%" };
                },
            },
            {
                dataField: 'age',
                text: 'AGE',
                headerStyle: () => {
                    return { width: "20%" };
                },
            },
            {
                dataField: 'occupation',
                text: 'OCCUPATION',
                headerStyle: () => {
                    return { width: "30%" };
                },
            },
            {
                dataField: 'company',
                text: 'CMPNY/SCHL',
                headerStyle: () => {
                    return { width: "30%" };
                },
            
            },
        ] 

        const backgroundSelectRow = {
            mode: 'checkbox',
            clickToSelectAndEditCell: true,
            onSelect: (row, isSelect, rowIndex, e) => {
                this.state.backgroundListGrid.map(function(item,i){
                    if(item.id===row.id)
                    {
                        item.isDeleted = isSelect ? "1" : "0"
                        item.isModified = isSelect ? "1" : "0"
                    }
                })
            }
        };
        
        const backgroundRowEvents = {
                onClick: (e, row, rowIndex) => {
            }
        };


        const applicationColumn = [
            {
                dataField: 'memberName',
                text: 'FULL NAME',
                headerStyle: () => {
                    return { width: "15%" };
                },
            },
            {
                dataField: 'dateApplied',
                text: 'APPLY DATE',
                headerStyle: () => {
                    return { width: "10%" };
                },
            },
            {
                dataField: 'applicationFormNo',
                text: 'APPLICATION FORM NO.',
                headerStyle: () => {
                    return { width: "10%" };
                },
            },
            {
                dataField: 'client',
                text: 'CLIENT/S',
                headerStyle: () => {
                    return { width: "28%" };
                },
            },
            {
                dataField: 'position',
                text: 'POSITION/S APPLIED',
                headerStyle: () => {
                    return { width: "15%" };
                },
            },
            {
                dataField: 'status',
                text: 'STATUS',
                headerStyle: () => {
                    return { width: "15%" };
                },
            }
	    ] 

        const applicationSelectRow = {
            mode: 'checkbox',
            clickToSelectAndEditCell: true,
            onSelect: (row, isSelect, rowIndex, e) => {
                this.state.educationListGrid.map(function(item,i){
                    if(item.id===row.id)
                    {
                        item.isDeleted = isSelect ? "1" : "0"
                        item.isModified = isSelect ? "1" : "0"
                    }
                })
            }
        };
        
        const applicationRowEvents = {
                onClick: (e, row, rowIndex) => {
            }
        };

        const employmentColumn = [
            {
                dataField: 'companyName',
                text: 'COMPANY'
            },
            {
                dataField: 'companyAddress',
                text: 'ADDRESS'
            },
            {
                dataField: 'position',
                text: 'POSITION'
            },
            {
                dataField: 'workNature',
                text: 'WORK_NATURE',
            },
            {
                dataField: 'periodCovered',
                text: 'PERIOD_COVERED',
            },
            {
                dataField: 'salary',
                text: 'SALARY'
            },
            {
                dataField: 'supervisor',
                text: 'SUPERVISOR'
            
            },
            {
                dataField: 'contactNumber',
                text: 'CONTACT #'
            },
            {
                dataField: 'reasonForLeaving',
                text: 'REASON_FOR_LEAVING'
            },
            {
                dataField: 'taxableCompensationIncome',
                text: 'TAXABLE_COMPENSATION_INCOME'
            
            },
            {
                dataField: 'withholdingTax',
                text: 'WITHHELD_TAX '
            },
            {
                dataField: 'yearOfCompensation',
                text: 'YEAR_OF_COMPENSATION'
            },
            {
                dataField: 'ntThirteenMonthPay',
                text: '13th_MONTH_PAY_AND_OTHER_BENEFITS',
            
            },
            {
                dataField: 'deminimis',
                text: 'DEMINIMIS_BENEFITS'
            },
            {
                dataField: 'ntMandatoryDeduction',
                text: 'MANDATORY_DEDUCTIONS'
            },
            {
                dataField: 'ntSalaries',
                text: 'NON-TAXABLE_SALARIES_&_OTHER_FORMS_OF_COMPENSATION',
            
            },
            {
                dataField: 'basicSalary',
                text: 'BASIC_SALARY'
            },
            {
                dataField: 'taxableThirteenMonthPay',
                text: 'TAXABLE_13th_MONTH_PAY_&_OTHER_BENEFITS',
            },
            {
                dataField: 'taxableSalaries',
                text: 'TAXABLE_SALARIES_&_OTHER_FORMS_OF_COMPENSATION',
                /* headerStyle: () => {
                    return { width: "45%" };
                }, */
            
            },
            {
                dataField: 'basicPayMWE',
                text: 'BASIC_PAY_FOR_MWE'
            },
            {
                dataField: 'holidayPayMWE',
                text: 'HOLIDAY_PAY_FOR_MWE'
            },
            {
                dataField: 'overtimePayMWE',
                text: 'OVERTIME_PAY_FOR_MWE'
            
            },
            {
                dataField: 'nightDiffPayMWE',
                text: 'NIGHT_DIFFERENTIAL_PAY_FOR_MWE'
            },
            {
                dataField: 'remarks',
                text: 'REMARKS'
            }
	    ] 

        const employmentSelectRow = {
            mode: 'checkbox',
            clickToSelectAndEditCell: true,
            onSelect: (row, isSelect, rowIndex, e) => {
                this.state.employmentListGrid.map(function(item,i){
                    if(item.id===row.id)
                    {
                        item.isDeleted = isSelect ? "1" : "0"
                        item.isModified = isSelect ? "1" : "0"
                    }
                })
            }
        };
        
        const employmentRowEvents = {
                onClick: (e, row, rowIndex) => {
            }
        };

        const documentsColumn = [
            
	    ] 

        /* const documentsSelectRow = {
            mode: 'checkbox',
            clickToSelectAndEditCell: true,
            onSelect: (row, isSelect, rowIndex, e) => {
                this.state.documentsListGrid.map(function(item,i){
                    if(item.id===row.id)
                    {
                        item.isDeleted = isSelect ? "1" : "0"
                        item.isModified = isSelect ? "1" : "0"
                    }
                })
            }
        };
        
        const documentsRowEvents = {
                onClick: (e, row, rowIndex) => {
            }
        }; */

        const trainingsColumn = [
            {
                dataField: 'trainingId',
                text: 'TRAINING ID',
                headerStyle: () => {
                    return { width: "50%" };
                },
            },
            {
                dataField: 'dateCompleted',
                text: 'DATE COMPLETED',
                headerStyle: () => {
                    return { width: "10%" };
                },
            },
            {
                dataField: 'presenter',
                text: 'PRESENTER',
                headerStyle: () => {
                    return { width: "40%" };
                },
            
            },
            
	    ] 

        const trainingsSelectRow = {
            mode: 'checkbox',
            clickToSelectAndEditCell: true,
            onSelect: (row, isSelect, rowIndex, e) => {
                this.state.trainingsListGrid.map(function(item,i){
                    if(item.id===row.id)
                    {
                        item.isDeleted = isSelect ? "1" : "0"
                        item.isModified = isSelect ? "1" : "0"
                    }
                })
            }
        };
        
        const trainingsRowEvents = {
                onClick: (e, row, rowIndex) => {
            }
        };

        return(
            <div>
                <Banner />
                    <Container  className="mt-3" fluid>
                        <Card>
                            <Card.Header>VIEW PROFILE</Card.Header>
                            <Card.Body>
                                <Form>
                                    <Alert isOpen={this.state.isshow} color={this.state.color} fade={this.state.fade} className={this.state.isshow ? 'display-block' : 'display-none'}>
                                        <div className={this.state.color}></div> 
                                        {this.state.message}
                                    </Alert>
                                        <Form.Row>
                                            <Form.Group controlId="formGridPassword" as={Col}>
                                                <Card className="card-tab-no-border">
                                                    <Card.Body>
                                                        <Form.Group as={Row} controlId="formPlaintextEmail">
                                                            <Col sm={12} className="mt-3">
                                                                <label style={{fontSize:"10px", fontWeight : "bold"}}>   
                                                                    PROFILE STATUS
                                                                </label>
                                                                <Form.Control 
                                                                    type="text" 
                                                                    autoComplete="off" 
                                                                    name="name"
                                                                    readOnly
                                                                    value={this.state.profileStatus}
                                                                    onChange={this.onChangeProfileStatus.bind(this)}
                                                                />
                                                            </Col>
                                                        </Form.Group>
                                                        <Form.Group as={Row} controlId="formPlaintextEmail">
                                                            <Col sm={4} className="mt-3">
                                                                <label style={{fontSize:"10px", fontWeight : "bold"}}>   
                                                                    TITLE
                                                                </label>
                                                                <Form.Control 
                                                                    readOnly
                                                                    type="text" 
                                                                    autoComplete="off" 
                                                                    name="selectedTitle"
                                                                    value={this.state.selectedTitle}
                                                                    onChange={this.onChangeTitle.bind(this)}
                                                                />
                                                            </Col>
                                                            <Col sm={4} className="mt-3">
                                                                <label style={{fontSize:"10px", fontWeight : "bold"}}>   
                                                                    FIRST NAME
                                                                </label>
                                                                <Form.Control 
                                                                    type="text" 
                                                                    autoComplete="off" 
                                                                    name="selectedFirstName"
                                                                    value={this.state.selectedFirstName}
                                                                    onChange={this.onChangeFirstName.bind(this)}
                                                                    readOnly
                                                                />
                                                            </Col>
                                                            <Col sm={4} className="mt-3">
                                                                <label style={{fontSize:"10px", fontWeight : "bold"}}>   
                                                                    NICK NAME
                                                                </label>
                                                                <Form.Control 
                                                                    type="text" 
                                                                    autoComplete="off" 
                                                                    name="selectedNickName"
                                                                    value={this.state.selectedNickName}
                                                                    onChange={this.onChangeNickName.bind(this)}
                                                                    readOnly
                                                                />
                                                            </Col>
                                                            <Col sm={6} className="mt-3">
                                                                <label style={{fontSize:"10px", fontWeight : "bold"}}>   
                                                                    MIDDLE NAME
                                                                </label>
                                                                <Form.Control 
                                                                    readOnly
                                                                    type="text" 
                                                                    autoComplete="off" 
                                                                    name="selectedMiddleName"
                                                                    value={this.state.selectedMiddleName}
                                                                    onChange={this.onChangeMiddleName.bind(this)}
                                                                    
                                                                />
                                                            </Col>
                                                            <Col sm={6} className="mt-3">
                                                                <label style={{fontSize:"10px", fontWeight : "bold"}}>   
                                                                    LAST NAME
                                                                </label>
                                                                <Form.Control 
                                                                    readOnly
                                                                    type="text" 
                                                                    autoComplete="off" 
                                                                    name="selectedLastName"
                                                                    value={this.state.selectedLastName}
                                                                    onChange={this.onChangeLastName.bind(this)}
                                                                />
                                                            </Col>
                                                        </Form.Group>
                                                        <Form.Group as={Row} controlId="formPlaintextEmail">
                                                            <Col sm={6} className="mt-3">
                                                                <label style={{fontSize:"10px", fontWeight : "bold"}}>   
                                                                    GENDER
                                                                </label>
                                                                <Form.Control 
                                                                    type="text" 
                                                                    autoComplete="off" 
                                                                    name="name"
                                                                    readOnly
                                                                    value={this.state.gender}
                                                                    onChange={this.onChangeGender.bind(this)}
                                                                />
                                                            </Col>
                                                            <Col sm={6} className="mt-3">
                                                                <label style={{fontSize:"10px", fontWeight : "bold"}}>   
                                                                    CIVIL STATUS
                                                                </label>
                                                                <Form.Control 
                                                                    type="text" 
                                                                    autoComplete="off" 
                                                                    name="name"
                                                                    readOnly
                                                                    value={this.state.civilStatus}
                                                                    onChange={this.onChangeCivilStatus.bind(this)}
                                                                />
                                                            </Col>
                                                        </Form.Group>
                                                        <Form.Group as={Row} controlId="formPlaintextEmail">
                                                            <Col sm={6} className="mt-5">
                                                                <label style={{fontSize:"10px", fontWeight : "bold"}}>   
                                                                    DATE OF BIRTH
                                                                </label>
                                                                <DatePicker
                                                                    readOnly
                                                                    ref='dateOfBirth'
                                                                    selected={this.state.dateOfBirth}
                                                                    onChange={this.onChangeDateOfBirth}
                                                                    minDate={this.minDate}
                                                                    //value={this.props.dateOfBirth}
                                                                    dateFormat={"MM/dd/yyyy"}
                                                                    className="form-control"
                                                                    defaultValue={JSON.parse(sessionStorage.getItem("employeeProfileData")).employeeProfiles[0]["dateOfBirth"] }
                                                                />
                                                            </Col>
                                                            <Col sm={6} className="mt-2">
                                                                <label style={{fontSize:"10px", fontWeight : "bold"}}>   
                                                                    AGE
                                                                </label>
                                                                <Form.Control 
                                                                    readOnly
                                                                    type="text" 
                                                                    autoComplete="off" 
                                                                    name="selectedAge"
                                                                    value={this.state.selectedAge}
                                                                    //onChange={this.onChangeAge.bind(this)}
                                                                />
                                                            </Col>
                                                        </Form.Group>
                                                        <Form.Group as={Row} controlId="formPlaintextEmail">
                                                            <Col sm={6} className="mt-3">
                                                                <label style={{fontSize:"10px", fontWeight : "bold"}}>   
                                                                    PLACE OF BIRTH
                                                                </label>
                                                                <Form.Control 
                                                                    readOnly
                                                                    type="text" 
                                                                    autoComplete="off" 
                                                                    name="selectedPlaceOfBirth"
                                                                    value={this.state.selectedPlaceOfBirth}
                                                                    onChange={this.onChangePlaceOfBirth.bind(this)}
                                                                />
                                                            </Col>
                                                            <Col sm={6} className="mt-3">
                                                                <label style={{fontSize:"10px", fontWeight : "bold"}}>   
                                                                    NATIONALITY
                                                                </label>
                                                                <Form.Control 
                                                                    type="text" 
                                                                    autoComplete="off" 
                                                                    name="name"
                                                                    readOnly
                                                                    value={this.state.selectedNationality}
                                                                    onChange={this.onChangeNationality.bind(this)}
                                                                />
                                                            </Col>
                                                        </Form.Group>

                                                        <Form.Group as={Row} controlId="formPlaintextEmail">
                                                            <Col sm={6} className="mt-3">
                                                                <label style={{fontSize:"10px", fontWeight : "bold"}}>   
                                                                    HOME PHONE NUMBER
                                                                </label>
                                                                <Form.Control 
                                                                    readOnly
                                                                    type="text" 
                                                                    autoComplete="off" 
                                                                    name="selectedHomePhoneNumber"
                                                                    value={this.state.selectedHomePhoneNumber}
                                                                    onChange={this.onChangeHomePhoneNumber.bind(this)}
                                                                />
                                                            </Col>
                                                            <Col sm={6} className="mt-3">
                                                                <label style={{fontSize:"10px", fontWeight : "bold"}}>   
                                                                    MOBILE NUMBER
                                                                </label>
                                                                <Form.Control 
                                                                    readOnly
                                                                    type="text" 
                                                                    autoComplete="off" 
                                                                    name="selectedMobileNumber"
                                                                    value={this.state.selectedMobileNumber}
                                                                    onChange={this.onChangeMobileNumber.bind(this)}
                                                                />
                                                            </Col>
                                                        </Form.Group>
                                                        <Form.Group as={Row} controlId="formPlaintextEmail">
                                                            <Col sm={6} className="mt-3">
                                                                <label style={{fontSize:"10px", fontWeight : "bold"}}>   
                                                                    RELIGION
                                                                </label>
                                                            <Form.Control 
                                                                type="text" 
                                                                autoComplete="off" 
                                                                name="name"
                                                                readOnly
                                                                value={this.state.religion}
                                                                onChange={this.onChangeReligion.bind(this)}
                                                            />
                                                            </Col>
                                                            <Col sm={6} className="mt-3">
                                                                <label style={{fontSize:"10px", fontWeight : "bold"}}>   
                                                                    EMAIL ADDRESS
                                                                </label>
                                                                <Form.Control 
                                                                    readOnly
                                                                    type="text" 
                                                                    autoComplete="off" 
                                                                    name="selectedEmailAddress"
                                                                    value={this.state.selectedEmailAddress}
                                                                    onChange={this.onChangeEmailAddress.bind(this)}
                                                                />
                                                            </Col>
                                                        </Form.Group>
                                                        <Form.Group as={Row} controlId="formPlaintextEmail">
                                                            <Col sm={6} className="mt-3">
                                                                <label style={{fontSize:"10px", fontWeight : "bold"}}>   
                                                                    FACEBOOK
                                                                </label>
                                                                <Form.Control 
                                                                    readOnly
                                                                    type="text" 
                                                                    autoComplete="off" 
                                                                    name="selectedFacebook"
                                                                    value={this.state.selectedFacebook}
                                                                    onChange={this.onChangeFacebook.bind(this)}
                                                                />
                                                            </Col>
                                                            <Col sm={6} className="mt-3">
                                                                <label style={{fontSize:"10px", fontWeight : "bold"}}>   
                                                                    TWITTER
                                                                </label>
                                                                <Form.Control 
                                                                    readOnly
                                                                    type="text" 
                                                                    autoComplete="off" 
                                                                    name="selectedTwitter"
                                                                    value={this.state.selectedTwitter}
                                                                    onChange={this.onChangeTwitter.bind(this)}
                                                                />
                                                            </Col>
                                                        </Form.Group>
                                                        <Form.Group as={Row} controlId="formPlaintextEmail">
                                                            <Col sm={6} className="mt-3">
                                                                <label style={{fontSize:"10px", fontWeight : "bold"}}>   
                                                                    SSS
                                                                </label>
                                                                <Form.Control 
                                                                    readOnly
                                                                    type="text" 
                                                                    autoComplete="off" 
                                                                    name="selectedSSSNumber"
                                                                    value={this.state.selectedSSSNumber}
                                                                    onChange={this.onChangeSSSNumber.bind(this)}
                                                                />
                                                            </Col>
                                                            <Col sm={6} className="mt-3">
                                                                <label style={{fontSize:"10px", fontWeight : "bold"}}>   
                                                                    TIN
                                                                </label>
                                                                <Form.Control 
                                                                    readOnly
                                                                    type="text" 
                                                                    autoComplete="off" 
                                                                    name="selectedTinNumber"
                                                                    value={this.state.selectedTinNumber}
                                                                    onChange={this.onChangeTinNumber.bind(this)}
                                                                />
                                                            </Col>
                                                        </Form.Group>
                                                        <Form.Group as={Row} controlId="formPlaintextEmail">
                                                            <Col sm={6} className="mt-3">
                                                                <label style={{fontSize:"10px", fontWeight : "bold"}}>   
                                                                    PHIC
                                                                </label>
                                                                <Form.Control 
                                                                    readOnly
                                                                    type="text" 
                                                                    autoComplete="off" 
                                                                    name="selectedPhicNumber"
                                                                    value={this.state.selectedPhicNumber}
                                                                    onChange={this.onChangePhicNumber.bind(this)}
                                                                />
                                                            </Col>
                                                            <Col sm={6} className="mt-3">
                                                                <label style={{fontSize:"10px", fontWeight : "bold"}}>   
                                                                    PAG-IBIG
                                                                </label>
                                                                <Form.Control 
                                                                    readOnly
                                                                    type="text" 
                                                                    autoComplete="off" 
                                                                    name="selectedHdmfNumber"
                                                                    value={this.state.selectedHdmfNumber}
                                                                    onChange={this.onChangeHdmfNumber.bind(this)}
                                                                />
                                                            </Col>
                                                        </Form.Group>

                                                        <Form.Group as={Row} controlId="formPlaintextEmail">
                                                            <Col sm={6} className="mt-3">
                                                                <label style={{fontSize:"10px", fontWeight : "bold"}}>   
                                                                    LIVING ARRANGEMENT
                                                                </label>
                                                                <Form.Control 
                                                                    type="text" 
                                                                    autoComplete="off" 
                                                                    name="name"
                                                                    readOnly
                                                                    value={this.state.livingArrangement}
                                                                    onChange={this.onChangeLivingArrangement.bind(this)}
                                                                />
                                                            </Col>
                                                            <Col sm="6" className="mt-3">
                                                                <label style={{fontSize:"10px"}}>
                                                                    BLOOD TYPE
                                                                </label>
                                                                <Form.Control 
                                                                    type="text" 
                                                                    autoComplete="off" 
                                                                    name="name"
                                                                    readOnly
                                                                    value={this.state.bloodType}
                                                                    onChange={this.onChangeBloodType.bind(this)}
                                                                />
                                                            </Col>
                                                        </Form.Group>

                                                        <Form.Group as={Row} controlId="formPlaintextEmail">
                                                            <Col sm={6} className="mt-3">
                                                                <label style={{fontSize:"10px", fontWeight : "bold"}}>   
                                                                    HEIGHT
                                                                </label>
                                                                <Form.Control 
                                                                    readOnly
                                                                    type="text" 
                                                                    autoComplete="off" 
                                                                    name="selectedHeight"
                                                                    value={this.state.selectedHeight}
                                                                    onChange={this.onChangeHeight.bind(this)}
                                                                />
                                                            </Col>
                                                            <Col sm={6} className="mt-3">
                                                                <label style={{fontSize:"10px", fontWeight : "bold"}}>   
                                                                    WEIGHT
                                                                </label>
                                                                <Form.Control 
                                                                    readOnly
                                                                    type="text" 
                                                                    autoComplete="off" 
                                                                    name="selectedWeight"
                                                                    value={this.state.selectedWeight}
                                                                    onChange={this.onChangeWeight.bind(this)}
                                                                />
                                                            </Col>
                                                        </Form.Group>

                                                        <Form.Group as={Row} controlId="formPlaintextEmail">
                                                            <Col sm={6} className="mt-3">
                                                                <label style={{fontSize:"10px", fontWeight : "bold"}}>   
                                                                    HOBBIES
                                                                </label>
                                                                <Form.Control 
                                                                    readOnly
                                                                    type="text" 
                                                                    autoComplete="off" 
                                                                    name="selectedHobbies"
                                                                    value={this.state.selectedHobbies}
                                                                    onChange={this.onChangeHobbies.bind(this)}
                                                                />
                                                            </Col>
                                                            <Col sm={6} className="mt-3">
                                                                <label style={{fontSize:"10px", fontWeight : "bold"}}>   
                                                                    LANGUAGE SPOKEN
                                                                </label>
                                                                <Form.Control 
                                                                    readOnly
                                                                    type="text" 
                                                                    autoComplete="off" 
                                                                    name="selectedLanguageSpoken"
                                                                    value={this.state.selectedLanguageSpoken}
                                                                    onChange={this.onChangeLanguageSpoken.bind(this)}
                                                                />
                                                            </Col>
                                                        </Form.Group>

                                                        <Form.Group as={Row} controlId="formPlaintextEmail">
                                                            <Col sm={6} className="mt-3">
                                                                <label style={{fontSize:"10px", fontWeight : "bold"}}>   
                                                                    EMERGENCY CONTACT NAME
                                                                </label>
                                                                <Form.Control 
                                                                    readOnly
                                                                    type="text" 
                                                                    autoComplete="off" 
                                                                    name="selectedEmergencyContactName"
                                                                    value={this.state.selectedEmergencyContactName}
                                                                    onChange={this.onChangeEmergencyContactName.bind(this)}
                                                                />
                                                            </Col>
                                                            <Col sm={6} className="mt-3">
                                                                <label style={{fontSize:"10px", fontWeight : "bold"}}>   
                                                                    RELATIONSHIP
                                                                </label>
                                                                <Form.Control 
                                                                    readOnly
                                                                    type="text" 
                                                                    autoComplete="off" 
                                                                    name="selectedRelationship"
                                                                    value={this.state.selectedRelationship}
                                                                    onChange={this.onChangeRelationship.bind(this)}
                                                                />
                                                            </Col>
                                                        </Form.Group>

                                                        <Form.Group as={Row} controlId="formPlaintextEmail">
                                                            <Col sm={6} className="mt-3">
                                                                <label style={{fontSize:"10px", fontWeight : "bold"}}>   
                                                                    CONTACT NUMBER
                                                                </label>
                                                                <Form.Control 
                                                                    readOnly
                                                                    type="text" 
                                                                    autoComplete="off" 
                                                                    name="selectedEmergencyContactNumber"
                                                                    value={this.state.selectedEmergencyContactNumber}
                                                                    onChange={this.onChangeContactNumber.bind(this)}
                                                                />
                                                            </Col>
                                                            <Col sm={6} className="mt-3">
                                                                <label style={{fontSize:"10px", fontWeight : "bold"}}>   
                                                                    USER FULL NAME
                                                                </label>
                                                                <Form.Control 
                                                                    readOnly
                                                                    type="text" 
                                                                    autoComplete="off" 
                                                                    name="selectedUserFullName"
                                                                    value={this.state.selectedUserFullName}
                                                                    onChange={this.onChangeUserFullName.bind(this)}
                                                                />
                                                            </Col>
                                                        </Form.Group>
                                                        <Form.Group as={Row} controlId="formPlaintextEmail">
                                                            <Col sm={6} className="mt-3">
                                                                <label style={{fontSize:"10px", fontWeight : "bold"}}>   
                                                                    MANAGER NAME
                                                                </label>
                                                                <Form.Control 
                                                                    readOnly
                                                                    type="text" 
                                                                    autoComplete="off" 
                                                                    name="selectedManagerName"
                                                                    value={this.state.selectedManagerName}
                                                                    onChange={this.onChangeManagerName.bind(this)}
                                                                />
                                                            </Col>
                                                        </Form.Group>
                                                        <Accordion className="mt-2">
                                                            <Card>
                                                                <Card.Header>
                                                                    <Accordion.Toggle as={Button} variant="link" eventKey="0" style={{color: "#FFFFFF"}}>
                                                                            I. EMPLOYEE ADDRESSES
                                                                    </Accordion.Toggle>
                                                                </Card.Header>
                                                                <Accordion.Collapse eventKey="0">
                                                                    <Card.Body>
                                                                        <div>
                                                                            <BootstrapTable
                                                                                rowClasses="noser-table-row-class"
                                                                                striped
                                                                                hover
                                                                                condensed
                                                                                pagination={ paginationFactory({sizePerPageRenderer}) }
                                                                                keyField = "id"
                                                                                data = { this.state.employeeAddressesListGrid }
                                                                                columns = { addressColumn}
                                                                            
                                                                            /> 
                                                                        </div>
                                                                    </Card.Body>
                                                                </Accordion.Collapse>
                                                            </Card>
                                                            <Card>
                                                                <Card.Header>
                                                                    <Accordion.Toggle as={Button} variant="link" eventKey="1" style={{color: "#FFFFFF"}}>
                                                                        II. EMPLOYEE FAMILY BACKGROUNDS
                                                                    </Accordion.Toggle>
                                                                </Card.Header>
                                                                <Accordion.Collapse eventKey="1">
                                                                    <Card.Body>
                                                                        <div>
                                                                            <BootstrapTable
                                                                                rowClasses="noser-table-row-class"
                                                                                striped
                                                                                hover
                                                                                condensed
                                                                                pagination={ paginationFactory({sizePerPageRenderer}) }
                                                                                noDataIndication={ () => <div style={{width:'100%'}}>No record found.</div> }
                                                                                keyField = "id"
                                                                                data = { this.state.backgroundListGrid }
                                                                                columns = { backgroundColumn}
                                                                            
                                                                            />
                                                                        </div>
                                                                    </Card.Body>
                                                                </Accordion.Collapse>
                                                            </Card>
                                                            <Card>
                                                                <Card.Header>
                                                                    <Accordion.Toggle as={Button} variant="link" eventKey="2" style={{color: "#FFFFFF"}}>
                                                                        III. EMPLOYEE EDUCATIONS
                                                                    </Accordion.Toggle>
                                                                </Card.Header>
                                                                <Accordion.Collapse eventKey="2">
                                                                    <Card.Body>
                                                                        <div>
                                                                            <BootstrapTable
                                                                                rowClasses="noser-table-row-class"
                                                                                striped
                                                                                hover
                                                                                condensed
                                                                                pagination={ paginationFactory({sizePerPageRenderer}) }
                                                                                noDataIndication={ () => <div style={{width:'100%'}}>No record found.</div> }
                                                                                keyField = "id"
                                                                                data = { this.state.educationListGrid }
                                                                                columns = { educationColumn}
                                                                                

                                                                            /> 
                                                                        </div>
                                                                    </Card.Body>
                                                                </Accordion.Collapse>
                                                            </Card>
                                                            <Card>
                                                                <Card.Header>
                                                                    <Accordion.Toggle as={Button} variant="link" eventKey="3" style={{color: "#FFFFFF"}}>
                                                                        IV. EMPLOYEE APPLICATION RECORDS
                                                                    </Accordion.Toggle>
                                                                </Card.Header>
                                                                <Accordion.Collapse eventKey="3">
                                                                    <Card.Body>
                                                                        <div>
                                                                            <BootstrapTable
                                                                                rowClasses="noser-table-row-class"
                                                                                striped
                                                                                hover
                                                                                condensed
                                                                                pagination={ paginationFactory({sizePerPageRenderer}) }
                                                                                noDataIndication={ () => <div style={{width:'100%'}}>No record found.</div> }
                                                                                keyField = "id"
                                                                                data = { this.state.applicationListGrid }
                                                                                columns = { applicationColumn}
                                                                            
                                                                            /> 
                                                                        </div>
                                                                    </Card.Body>
                                                                </Accordion.Collapse>
                                                            </Card>
                                                            <Card>
                                                                <Card.Header>
                                                                    <Accordion.Toggle as={Button} variant="link" eventKey="4" style={{color: "#FFFFFF"}}>
                                                                        V. EMPLOYEE HISTORIES
                                                                    </Accordion.Toggle>
                                                                </Card.Header>
                                                                <Accordion.Collapse eventKey="4">
                                                                    <Card.Body>
                                                                        <div>
                                                                            <BootstrapTable
                                                                                rowClasses="noser-table-row-class"
                                                                                striped
                                                                                hover
                                                                                condensed
                                                                                pagination={ paginationFactory({sizePerPageRenderer}) }
                                                                                noDataIndication={ () => <div style={{width:'100%'}}>No record found.</div> }
                                                                                keyField = "id"
                                                                                data = { this.state.employmentListGrid }
                                                                                columns = { employmentColumn}
                                                                            
                                                                            /> 
                                                                        </div>
                                                                    </Card.Body>
                                                                </Accordion.Collapse>
                                                            </Card>
                                                            <Card>
                                                                <Card.Header>
                                                                    <Accordion.Toggle as={Button} variant="link" eventKey="5" style={{color: "#FFFFFF"}}>
                                                                        VI. EMPLOYEE TRAINING
                                                                    </Accordion.Toggle>
                                                                </Card.Header>
                                                                <Accordion.Collapse eventKey="5">
                                                                    <Card.Body>
                                                                        <div>
                                                                            <BootstrapTable
                                                                                rowClasses="noser-table-row-class"
                                                                                striped
                                                                                hover
                                                                                condensed
                                                                                pagination={ paginationFactory({sizePerPageRenderer}) }
                                                                                noDataIndication={ () => <div style={{width:'100%'}}>No record found.</div> }
                                                                                keyField = "id"
                                                                                data = { this.state.trainingsListGrid }
                                                                                columns = { trainingsColumn}
                                                                            
                                                                            /> 
                                                                        </div>
                                                                    </Card.Body>
                                                                </Accordion.Collapse>
                                                            </Card>
                                                            </Accordion>
                                                            <ButtonToolbar className="mt-3">
                                                                <Button style={{minWidth:"60px",marginRight:"1pt"}}  variant="danger" className="ml-auto" href="/profile">
                                                                    Back
                                                                </Button>
                                                            </ButtonToolbar>
                                                        </Card.Body>
                                                    </Card>
                                                </Form.Group>
                                            </Form.Row> 
                                           
                                </Form>
                            </Card.Body>
                        </Card>
                    </Container>
                <NoserLoading show={this.state.isloading} />
            </div> 
        )
    }

}
export default ProfileView



