import {
    React,Component,BootstrapTable,
    paginationFactory,Button, ButtonToolbar, Card, 
    Form, Col, Row, axios, Typeahead, TimePicker,
    cellEditFactory ,moment, NavLink, Alert, AppConfiguration,
    NoserLoading, Noser, Banner, Container, DatePicker,  Tabs, Tab,
    sizePerPageRenderer,Type
} 
from '../../noser-hris-component';
import { GetRequest, PostRequest } from '../../noser-dataaccess'

class EditPaycardApplication extends Component {
    constructor(props) {
        super(props)
        // this.minDate = new moment(props.minDate)
        this.state = {
            userinfo        :   [],
            isloading       :   false,
            isshow          :   false,
            alerttype       :   "",
            message         :   "",
            color           :   "",
            fade            :   true,

        }
        this.state.userinfo         = JSON.parse(sessionStorage.getItem("userData"))
        this.state.details          = JSON.parse(sessionStorage.getItem("_paycardviewdetails"))
        this.state.client           = this.state.details.clientName
        this.state.branch           = this.state.details.branchName
        this.state.employee         = this.state.details.employeeName
        this.state.employeeNo       = this.state.details.employeeNo
        this.state.position         = this.state.details.positionName
        this.state.dateStart        = this.state.details.dateStart
        this.state.status           = this.state.details.statusName
        this.state.applicationDate  = this.state.details.applicationDate
        //this.state.status   = this.state.details.statusName
        this.state.payCardType      = this.state.details.payCardType
        this.state.oldOayCardNumber = this.state.details.oldOayCardNumber
        this.state.payCardNumber    = this.state.details.payCardNumber
        this.state.natureName       = this.state.details.natureName
        this.state.cardValue        = this.state.details.cardValue
        this.state.reasonName       = this.state.details.reasonName
        this.state.description      = this.state.details.description
        this.state.documentDG       = this.state.details.applicationdocuments
        this.state.statusDG         = this.state.details.applicationstatus
    }

    componentDidMount(){
        
    }
    render() {
       
        const documentColumn = [
            {
                dataField: 'documentName',
                text: 'DOCUMENT NAME',
                editable:false,
                headerStyle: {
                    width: '100%'
                },
                style:{width:'100%'}
               
            },
        ] 
        const selectRowDoc = {
            mode: 'checkbox',
            clickToSelectAndEditCell: true,
            onSelect: (row, isSelect, rowIndex, e) => {
                this.state.documentDG.map(function(item,i){
                   
                    if(item.id===row.id)
                        {
                        item.isDeleted = isSelect ? "1" : "0"
                        item.isModified = isSelect ? "1" : "0"
                    }
                })

            }
        };
    
       
        const statusColumn = [
            {
                dataField: 'requestDate',
                text: 'REQUEST DATE',
                editable:false,
                headerStyle: {
                    width: '20%'
                },
                style:{width:'20%'}
               
            },
            {
                dataField: 'statusName',
                text: 'STATUS NAME',
                editable:false,
                headerStyle: {
                    width: '40%'
                },
                style:{width:'40%'}
               
            },
            {
                dataField: 'remarks',
                text: 'REMARKS',
                editable:false,
                headerStyle: {
                    width: '40%'
                },
                style:{width:'40%'}
               
            },
        ] 
        const selectRowStatus = {
            mode: 'checkbox',
            clickToSelectAndEditCell: true,
            onSelect: (row, isSelect, rowIndex, e) => {
                this.state.statusDG.map(function(item,i){
                   
                    if(item.id===row.id)
                        {
                        item.isDeleted = isSelect ? "1" : "0"
                        item.isModified = isSelect ? "1" : "0"
                    }
                })

            }
        };
      
        return(
            <div>
            <Banner />
            <Container className="themed-container" fluid={true}>
                <Card className="mt-5">
                    <Card.Header >PAYCARD APPLICATION (DETAILS)</Card.Header>
                    <Card.Body>

                        <Card className="mt-5">
                            <Card.Header>EMPLOYEE DETAILS</Card.Header>
                            <Card.Body>
                                <Form.Row className="mt-2">
                                    <Form.Group as={Col} controlId="formGridAddress1">
                                        <Form.Label style={{fontWeight : "bold"}}>CLIENT</Form.Label>
                                        <Form.Control 
                                            type="text" 
                                            autoComplete="off" 
                                            name="name"
                                            value={this.state.client}
                                            disabled 
                                        /> 
                                    </Form.Group>
                                    <Form.Group as={Col} controlId="formGridAddress1">
                                        <Form.Label style={{fontWeight : "bold"}}>LOCATION / BRANCH</Form.Label>
                                        <Form.Control 
                                            type="text" 
                                            autoComplete="off" 
                                            name="name"
                                            value={this.state.branch}
                                            disabled 
                                        /> 
                                    </Form.Group>
                                </Form.Row>
                                <Form.Row className="mt-2">
                                    <Form.Group as={Col} controlId="formGridAddress1">
                                        <Form.Label style={{fontWeight : "bold"}}>EMPLOYEE</Form.Label>
                                        <Form.Control 
                                            type="text" 
                                            autoComplete="off" 
                                            name="name"
                                            value={this.state.employee}
                                            disabled 
                                        /> 
                                    </Form.Group>
                                    <Form.Group as={Col} controlId="formGridAddress1">
                                        <Form.Label style={{fontWeight : "bold"}}>EMPLOYEE NO.</Form.Label>
                                        <Form.Control 
                                            type="text" 
                                            autoComplete="off" 
                                            name="name"
                                            value={this.state.employeeNo}
                                            disabled 
                                        /> 
                                    </Form.Group>
                                </Form.Row>
                                <Form.Row className="mt-2">
                                    <Form.Group as={Col} controlId="formGridAddress1">
                                        <Form.Label style={{fontWeight : "bold"}}>Position</Form.Label>
                                        <Form.Control 
                                            type="text" 
                                            autoComplete="off" 
                                            name="name"
                                            value={this.state.position}
                                            disabled 
                                        /> 
                                    </Form.Group>
                                    <Form.Group as={Col} controlId="formGridAddress1">
                                        <Form.Label style={{fontWeight : "bold"}}>Date Start</Form.Label>
                                        <Form.Control 
                                            type="text" 
                                            autoComplete="off" 
                                            name="name"
                                            value={this.state.dateStart}
                                            disabled 
                                        /> 
                                    </Form.Group>
                                </Form.Row>
                                <Form.Row className="mt-2">
                                    <Form.Group as={Col} controlId="formGridAddress1">
                                        <Form.Label style={{fontWeight : "bold"}}>Status</Form.Label>
                                        <Form.Control 
                                            type="text" 
                                            autoComplete="off" 
                                            name="name"
                                            value={this.state.status}
                                            disabled 
                                        /> 
                                    </Form.Group>
                                </Form.Row>
                            </Card.Body>
                        </Card>
                        
                        <Card className="mt-5">
                            <Card.Header>PAYCARD DETAILS</Card.Header>
                            <Card.Body>
                                <Form.Row className="mt-2">
                                    <Form.Group as={Col} controlId="formGridAddress1">
                                        <Form.Label style={{fontWeight : "bold"}}>Application Date</Form.Label>
                                        <Form.Control 
                                            type="text" 
                                            autoComplete="off" 
                                            name="name"
                                            value={this.state.applicationDate}
                                            disabled 
                                        /> 
                                    </Form.Group>
                                    <Form.Group as={Col} controlId="formGridAddress1">
                                        <Form.Label style={{fontWeight : "bold"}}>Application No.</Form.Label>
                                        <Form.Control 
                                            type="text" 
                                            autoComplete="off" 
                                            name="name"
                                            value={this.state.applicationNo}
                                            disabled 
                                        /> 
                                    </Form.Group>
                                </Form.Row>
                                <Form.Row className="mt-2">
                                    <Form.Group as={Col} controlId="formGridAddress1">
                                        <Form.Label style={{fontWeight : "bold"}}>PAYCARD TYPE</Form.Label>
                                        <Form.Control 
                                            type="text" 
                                            autoComplete="off" 
                                            name="name"
                                            value={this.state.payCardType}
                                            disabled 
                                        /> 
                                    </Form.Group>
                                    <Form.Group as={Col} controlId="formGridAddress1">
                                        <Form.Label style={{fontWeight : "bold"}}>OLD PAYCARD NO.</Form.Label>
                                        <Form.Control 
                                            type="text" 
                                            autoComplete="off" 
                                            name="name"
                                            value={this.state.oldOayCardNumber}
                                            disabled 
                                        /> 
                                    </Form.Group>
                                    <Form.Group as={Col} controlId="formGridAddress1">
                                        <Form.Label style={{fontWeight : "bold"}}>NEW PAYCARD NO.</Form.Label>
                                        <Form.Control 
                                            type="text" 
                                            autoComplete="off" 
                                            name="name"
                                            value={this.state.payCardNumber}
                                            disabled 
                                        /> 
                                    </Form.Group>
                                </Form.Row>
                                <Form.Row className="mt-2">
                                    <Form.Group as={Col} controlId="formGridAddress1">
                                        <Form.Label style={{fontWeight : "bold"}}>NATURE OF APPLICATION</Form.Label>
                                        <Form.Control 
                                            type="text" 
                                            autoComplete="off" 
                                            name="name"
                                            value={this.state.natureName}
                                            disabled 
                                        /> 
                                    </Form.Group>
                                    <Form.Group as={Col} controlId="formGridAddress1">
                                        <Form.Label style={{fontWeight : "bold"}}>VALUE TRANSFER</Form.Label>
                                        <Form.Control 
                                            type="text" 
                                            autoComplete="off" 
                                            name="name"
                                            value={this.state.cardValue}
                                            disabled 
                                        /> 
                                    </Form.Group>
                                </Form.Row>
                                <Form.Row className="mt-2">
                                    <Form.Group as={Col} controlId="formGridAddress1">
                                        <Form.Label style={{fontWeight : "bold"}}>REASON OF APPLICATION</Form.Label>
                                        <Form.Control 
                                            type="text" 
                                            autoComplete="off" 
                                            name="name"
                                            value={this.state.reasonName}
                                            disabled 
                                        /> 
                                    </Form.Group>
                                    <Form.Group as={Col} controlId="formGridAddress1">
                                        <Form.Label style={{fontWeight : "bold"}}>OTHER SPECIFY</Form.Label>
                                        <Form.Control 
                                            type="text" 
                                            autoComplete="off" 
                                            name="name"
                                            value={this.state.description}
                                            disabled 
                                        /> 
                                    </Form.Group>
                                </Form.Row>
                            </Card.Body>
                        </Card>
                        <Card className="mt-5">
                            <Card.Header>PAYCARD DOCUMENT DETAILS</Card.Header>
                            <Card.Body>
                                <div className="mt-3">  
                                    <BootstrapTable
                                        keyField = "id"
                                        data = { this.state.documentDG }
                                        columns = { documentColumn }
                                        //pagination={ paginationFactory({sizePerPageRenderer}) }
                                        noDataIndication={ () => <div style={{width:'100%'}}>No record found.</div> }
                                        rowClasses="noser-table-row-class no-checkbox"
                                        striped
                                        hover
                                        condensed
                                        cellEdit = { cellEditFactory({
                                            mode: 'dbclick',
                                            blurToSave: true,
                                            afterSaveCell: (oldValue, newValue, row, column) => {
                                                //this.GridDataModified(oldValue, newValue, row.id, column.dataField)
                                                }
                                            })
                                        }
                                        selectRow = { selectRowDoc }
                                        //rowEvents={ requestMonitoringRowEvents }
                
                                    />
                                </div>
                            </Card.Body>
                        </Card>
                        <Card className="mt-5">
                            <Card.Header>PAYCARD STATUS DETAILS</Card.Header>
                            <Card.Body>
                                <div className="mt-3">  
                                    <BootstrapTable
                                        keyField = "id"
                                        data = { this.state.statusDG }
                                        columns = { statusColumn }
                                        //pagination={ paginationFactory({sizePerPageRenderer}) }
                                        noDataIndication={ () => <div style={{width:'100%'}}>No record found.</div> }
                                        rowClasses="noser-table-row-class no-checkbox"
                                        striped
                                        hover
                                        condensed
                                        cellEdit = { cellEditFactory({
                                            mode: 'dbclick',
                                            blurToSave: true,
                                            afterSaveCell: (oldValue, newValue, row, column) => {
                                                //this.GridDataModified(oldValue, newValue, row.id, column.dataField)
                                                }
                                            })
                                        }
                                        selectRow = { selectRowStatus }
                                        //rowEvents={ requestMonitoringRowEvents }
                
                                    />
                                </div>
                            </Card.Body>
                        </Card>
                        <ButtonToolbar className="mt-5">
                            <Button disabled={this.state.disabledButton} variant="success" className="ml-auto noser-button-mr1" onClick={ this.handleSaveClick }>Save</Button>&nbsp;&nbsp;
                            <Button disabled={this.state.disabledButton} variant="success" className="noser-button" onClick={ () => this.handleClickCancel('3')} >Cancel</Button>&nbsp;&nbsp;
                            <Button disabled={this.state.disabledButton} variant="success" className="noser-button"  onClick={ () => this.handleClickCancel('4')}>Transmit</Button>&nbsp;&nbsp;
                            <Button href='/home' variant="danger" className="noser-button">Close</Button>
                        </ButtonToolbar>

                    </Card.Body>
                </Card>
                <div className="mt-5"></div>
            </Container>
            <NoserLoading show={this.state.isloading} />
            </div>
        )
    }
}
export  default EditPaycardApplication
