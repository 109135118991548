import {
    React,Component,BootstrapTable,
    paginationFactory,Button, ButtonToolbar, Card, 
    Form, Col, Row, axios, Container, Banner, Typeahead, TimePicker,
    cellEditFactory, Type, moment, NavLink, Alert, AppConfiguration,
    NoserLoading, Noser, sizePerPageRenderer, Modal, DatePicker, NumberFormat, Image
} 
from '../../../noser-hris-component';

import ReactToPrint from "react-to-print";
import paramountHeader from '../../../icon/contract-header.PNG'
import paramountFooter from '../../../icon/contract-footer.PNG'

class TrainingContract extends React.Component {
    
    render() {
      return (
        <div style={{backgroundColor:"#FFF",height:"100%"}}>
            <div style={{width:"100%"}}>
                <Col sm="3" className="noser-button" as={Row} style={{width:"150px",
                                                height:"25px",
                                                marginLeft:"auto",
                                                marginRight:"auto"}}>
                    <ReactToPrint
                        trigger={() => <button style={{width:"150px",
                                                height:"25px",
                                                marginLeft:"auto",
                                                marginRight:"auto",
                                                color:"#ffffff",
                                                fontWeight:"bold",
                                                backgroundColor:"rgb(8, 139, 30)",
                                                border:".5pt solid rgba(17, 99, 31, 0.644)"}}>Print Preview</button>}
                        content={() => this.componentRef}
                    />
                </Col>
            </div>
            <ComponentToPrint ref={(el) => (this.componentRef = el)} />  
        </div>
      );
    }
  }
export  default TrainingContract
class ComponentToPrint extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userinfo        :   [],
            isloading       :   false,
            isshow          :   false,
            alerttype       :   "",
            message         :   "",
            color           :   "",
            fade            :   true,      
            disable         :   true,
            
            clientName      :   "",
            employeeName    :   "",
            lastName        :   "",
            issueDate       :   "",
            contractStart   :   "",
            contractEnd     :   "",
            positionName    :   "",
            branchName      :   "",
            salaryRate      :   ""
        }
    }
    componentDidMount(){
        this.state.userinfo = JSON.parse(sessionStorage.getItem("userData"))
        document.title = "Training Contract";
        let data = JSON.parse(sessionStorage.getItem("contract_" + this.state.userinfo.userId + this.state.userinfo.employeeId))
        console.log(data)
        this.setState({
            employeeName : data.employeeName,
            lastName:data.lastName,
            positionName : data.positionName,
            address : data.address,
            contractStart:moment(new Date(data.contractDateStart)).format('MMMM DD, YYYY'),
            contractEnd:moment(new Date(data.contractDateEnd)).format('MMMM DD, YYYY'),
            clientName:data.clientName,
            branchName:data.locationName,
            salaryRate:data.salaryRate,
            issueDate : moment(new Date(data.issueDate)).format('MMMM DD, YYYY')
        })
    }
    render() {
        return( <>
            <Container className="mt-0 noser-a4-body-page" fluid>
                <div className='noser-a4-page' style={{fontFamily:'Times New Roman',wordBreak:'break-word',whiteSpace:'-moz-pre-wrap'}}>
                <Form>
                <Col style={{marginLeft:'-30px', marginTop:"50px"}} >
                    <Image src={paramountHeader} style={{width:"900px", height:"200px"}} />
                </Col>

                <Form.Group as={Col} sm="12">                                    
                    <Form.Label row sm="1" style={{fontFamily:'Times New Roman',fontWeight : "bold" ,fontSize: 20,marginTop:"20px", marginLeft:"95px"}}>
                    LETTER ACCEPTANCE / APPOINTMENT (TRAINING PERIOD)
                    </Form.Label>                            
                </Form.Group>
                <Form.Group as={Row} controlId="formPlaintextEmail" className='mt-5'>
                    <Col>
                        <Form.Label className="no-text-transform" row style={{fontFamily:'Times New Roman',fontWeight:"bold",fontSize: 20}}>
                        To: Mr./Ms. {this.state.employeeName}
                        </Form.Label>  
                    </Col>                                    
                </Form.Group>
                <Form.Group as={Row} controlId="formPlaintextEmail">
                    <Col>
                        <Form.Label className="no-text-transform" row style={{fontFamily:'Times New Roman',fontWeight:"bold",fontSize: 20}}>
                        ADDRESS: _______________________________________________
                        </Form.Label>  
                    </Col>                                    
                </Form.Group>
                <Form.Group as={Row} controlId="formPlaintextEmail">
                    <Col>
                        <Form.Label className="no-text-transform" row style={{fontFamily:'Times New Roman',fontWeight:"bold",fontSize: 20}}>
                        ___________________________________________________________
                        </Form.Label>  
                    </Col>                                    
                </Form.Group>
                <Form.Group as={Row} controlId="formPlaintextEmail" className="mt-5">
                    <Col>
                        <Form.Label className="no-text-transform" row style={{fontFamily:'Times New Roman',fontSize: 20}}>
                        Date : &nbsp;
                        <span className="no-text-transform" style={{fontFamily:'Times New Roman',fontWeight:'bold',textAlign:'justify',fontSize: 20,whiteSpace: "pre-wrap"}}>
                        {/* {this.state.issueDate} */}
                        {this.state.contractStart}
                        </span>
                        </Form.Label>  
                    </Col>                                    
                </Form.Group>

                <Form.Group as={Row} controlId="formPlaintextEmail" className="mt-3">
                    <Col>
                        <Form.Label className="no-text-transform" row style={{fontFamily:'Times New Roman',fontSize: 20}}>
                        Dear, &nbsp;
                        <span className="no-text-transform" style={{fontFamily:'Times New Roman',fontWeight:'bold',textAlign:'justify',fontSize: 20,whiteSpace: "pre-wrap"}}>
                        Mr. / Ms. {this.state.lastName},
                        </span>
                        </Form.Label>  
                    </Col>                                    
                </Form.Group>
                <Form.Group as={Row} controlId="formPlaintextEmail" className="mt-3">
                    <Col>
                        <p style={{ fontFamily:'Times New Roman',textAlign:'justify',fontSize: 20,whiteSpace: "pre-wrap" }}>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;This will inform you that your application for membership in the cooperative has been accepted and approved by the Board of Directors.
                        </p>
                    </Col>                                    
                </Form.Group>
                <Form.Group as={Row} controlId="formPlaintextEmail" className="mt-3">
                    <Col>
                        <p style={{ fontFamily:'Times New Roman',textAlign:'justify',fontSize: 20,whiteSpace: "pre-wrap" }}>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;As member of this service cooperative you have committed yourself to render your service to our various clients as the need arises. 
                        Initially, you will be on-the-job training for a period of &nbsp;
                        <span className="no-text-transform" style={{fontFamily:'Times New Roman',fontWeight:'bold',textAlign:'justify',fontSize: 20,whiteSpace: "pre-wrap"}}>
                        ONE MONTH (1) Month 
                        </span>&nbsp;
                        effective on &nbsp;
                        <span className="no-text-transform" style={{textDecoration:"underline",fontFamily:'Times New Roman',fontWeight:'bold',textAlign:'justify',fontSize: 20,whiteSpace: "pre-wrap"}}>
                        {this.state.contractStart} TO {this.state.contractEnd}
                        </span>&nbsp;
                        and with specific assignment as &nbsp;
                        <span className="no-text-transform" style={{textDecoration:"underline",fontFamily:'Times New Roman',fontWeight:'bold',textAlign:'justify',fontSize: 20,whiteSpace: "pre-wrap"}}>
                        {this.state.positionName} 
                        </span>&nbsp;
                        at &nbsp;
                        <span className="no-text-transform" style={{textDecoration:"underline",fontFamily:'Times New Roman',fontWeight:'bold',textAlign:'justify',fontSize: 20,whiteSpace: "pre-wrap"}}>
                        {this.state.clientName} - {this.state.branchName}
                        </span>&nbsp;
                        and you will receive an allowance of &nbsp;
                        <span className="no-text-transform" style={{textDecoration:"underline",fontFamily:'Times New Roman',fontWeight:'bold',textAlign:'justify',fontSize: 20,whiteSpace: "pre-wrap"}}>
                        Php {this.state.salaryRate} per day 
                        </span>&nbsp;
                        of work. 
                        </p>
                    </Col>                                    
                </Form.Group>
                <Form.Group as={Row} controlId="formPlaintextEmail" className="mt-3">
                    <Col>
                        <p style={{ fontFamily:'Times New Roman',textAlign:'justify',fontSize: 20,whiteSpace: "pre-wrap" }}>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;We congratulate you for joining us as cooperative member, as we hereby expect your utmost loyalty, honesty, diligence, and competence at all times.
                        </p>
                    </Col>                                    
                </Form.Group>

                <Form.Group as={Row} controlId="formPlaintextEmail" >
                    <Col>
                        <Form.Label className="no-text-transform" row style={{ fontFamily:'Times New Roman',fontSize: 20, whiteSpace: "pre-wrap",marginTop:"30px",marginLeft:"65%" }}>
                        Very truly yours,
                        </Form.Label>  
                    </Col>                                    
                </Form.Group>
                <h1 style={{backgroundColor:'#000000', width:"300px", height:"5px",marginTop:"50px",marginLeft:"65%" }} > </h1>
                <Form.Group as={Row} controlId="formPlaintextEmail" className="mt-3">
                    <Col>
                        <Form.Label className="no-text-transform" row style={{fontFamily:'Times New Roman',fontStyle:'italic', fontSize: 20 ,marginLeft:"70%",whiteSpace: "pre-wrap" }}>
                        HR Admin Manager
                        </Form.Label>  
                    </Col>                                    
                </Form.Group>
                <Form.Group as={Row} controlId="formPlaintextEmail">
                    <Col>
                        <Form.Label className="no-text-transform" row style={{ fontFamily:'Times New Roman',width:"95%", fontSize: 26, whiteSpace: "pre-wrap",marginTop:"20px" }}>
                        Conforme:
                        </Form.Label>  
                    </Col>                                    
                </Form.Group>
                <h1  style={{backgroundColor:'#000000', width:"325px", height:"5px",marginTop:"40px" }} > </h1>
                <Form.Group as={Row} controlId="formPlaintextEmail" >
                    <Col>
                        <Form.Label className="no-text-transform" row style={{ fontFamily:'Times New Roman',width:"95%", fontSize: 20, whiteSpace: "pre-wrap" }}>
                        Signature Over Printed Name
                        </Form.Label>  
                    </Col>                                    
                </Form.Group>
                <Col style={{width:'100%'}}>
                    <Image src={paramountFooter} 
                        style={{width:"400px", 
                                height:"100px", 
                                marginLeft:'210px',
                                marginTop:"150px"}}/>
                </Col>
            </Form>
                </div>
            </Container>
            <NoserLoading show={this.state.isloading} />
            </>
        )
    }
}

