import {
    React,Component,BootstrapTable,
    paginationFactory,Button, ButtonToolbar, Card, 
    Form, Col, Row, axios, Container, Banner, Typeahead, TimePicker,
    cellEditFactory, Type, moment, NavLink, Alert, AppConfiguration,
    NoserLoading, Noser, sizePerPageRenderer
} 
from '../../noser-hris-component';
import { DropdownButton, Dropdown} from 'react-bootstrap';

class PostTrainingExaminationCreate extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fade            :   true, 
            color           :   "",
            isshow          :   false,
            message         :   "",
            userinfo        :   [],
            isloading       :   false,
            alerttype       :   "",

            examName                :   "",
            clientAutocomplete          :   [],
            positionAutocomplete        :   [],
            trainingNameAutocomplete    :   [],

            clientTableList     :   [],
            positionTableList   :   [],
            instruction         :   "",
            noOfItems           :   "",
            timeLimit           :   "",
            passingScore        :   "",
            questionName        :   "",
            questionAnswer      :   "",
            selectedTrainingId  :   "",
            choicesName         :   "",
            choicesAnswer       :   "",

            /* choicesTableList    :   [
                { "id" : 1, "Name" : "", "Answer" : "" },
                { "id" : 2, "Name" : "", "Answer" : "" },
                { "id" : 3, "Name" : "", "Answer" : "" },
                { "id" : 4, "Name" : "", "Answer" : "" },
                { "id" : 5, "Name" : "", "Answer" : "" },
                { "id" : 6, "Name" : "", "Answer" : "" },
                { "id" : 7, "Name" : "", "Answer" : "" },
                { "id" : 8, "Name" : "", "Answer" : "" },
                { "id" : 9, "Name" : "", "Answer" : "" },
            ], */

            /* postExamTableList    :   [
                { "id" : 1, "name" : "", "answer" : "" },
            ], */
            choicesTableList : [],
            
            addQuestionList     :   [],
            choicesAnswerAutocomplete    :   [
                { "id" : 1, "answer" : "YES" },
                { "id" : 0, "answer" : "NO" },
            ],
            addQuestionsLists    :   [
                { "id" : 1, "answer" : "CORRECT" },
                { "id" : 0, "answer" : "INCORRECT" },
            ],
            tblAnswerArrLst     :   [],  
            addQuestionLists : [],
            addChoicesLists : [],
            addChoicesList : [],
            questArr : [],


            postExamTableListQuestAns : [],
            postExamTableList   :   [],
            tblLstArr : [],
            tblChoicesArr : [],
            questionId : 0,
            arr : [],
            disableremovePosition:true,
        }
    }

    componentDidMount(){
        const sleep = (milliseconds) => {return new Promise(resolve => setTimeout(resolve, milliseconds))}
        this.state.userinfo = JSON.parse(sessionStorage.getItem("userData"))
        this.GetTrainingName()
        this.GetQuestionAnswer()

        console.log("this.props.location.params.data")
        console.log(this.props.location.params)


        let clientData = []
        let positionData = []
        this.state.selectedTrainingId     =   JSON.parse(sessionStorage.getItem("selectedCreateTrainingData"))[0]["id"]
        for(let x = 0; x < JSON.parse(sessionStorage.getItem("selectedCreateTrainingData")).length; x++) {
            console.log("trainingClients")
            console.log(JSON.parse(sessionStorage.getItem("selectedCreateTrainingData"))[x].trainingClients)
            for(let j = 0; j < JSON.parse(sessionStorage.getItem("selectedCreateTrainingData"))[x].trainingClients.length; j++) {
                let clientObj = {
                    "client" : JSON.parse(sessionStorage.getItem("selectedCreateTrainingData"))[x].trainingClients[j]["client"],
                }
                clientData.push(clientObj);
            }
            this.setState({
                clientTableList : clientData
            })

            for(let j = 0; j < JSON.parse(sessionStorage.getItem("selectedCreateTrainingData"))[x].trainingPositions.length; j++) {
                let positionObj = {
                    "position" : JSON.parse(sessionStorage.getItem("selectedCreateTrainingData"))[x].trainingPositions[j]["position"],
                }
                positionData.push(positionObj);
            }
            this.setState({
                positionTableList : positionData
            })
        }

        let questionData = []
        for (let i = 0; i < JSON.parse(sessionStorage.getItem("postExaminationData")).postExaminations.length; i++) {
            //this.state.trainingName     =   JSON.parse(sessionStorage.getItem("selectedTrainingData"))[i]["name"]
            this.state.id	    =   JSON.parse(sessionStorage.getItem("postExaminationData")).postExaminations[i]["id"]
            this.state.examName         =   JSON.parse(sessionStorage.getItem("postExaminationData")).postExaminations[i]["name"]
            this.state.instruction	    =   JSON.parse(sessionStorage.getItem("postExaminationData")).postExaminations[i]["instructions"]
            this.state.noOfItems        =   JSON.parse(sessionStorage.getItem("postExaminationData")).postExaminations[i]["noOfItems"]
            this.state.timeLimit        =   JSON.parse(sessionStorage.getItem("postExaminationData")).postExaminations[i]["timeLimit"]
            this.state.passingScore	    =   JSON.parse(sessionStorage.getItem("postExaminationData")).postExaminations[i]["passingScore"] 
            this.state.isDeletedData	=   JSON.parse(sessionStorage.getItem("postExaminationData")).postExaminations[i]["isDeleted"]

            for(let j = 0; j < JSON.parse(sessionStorage.getItem("postExaminationData")).postExaminations[i].questions.length; j++) {
                console.log("questions")
                console.log(JSON.parse(sessionStorage.getItem("postExaminationData")).postExaminations[i].questions)
                let questionObj = {
                    "id" : JSON.parse(sessionStorage.getItem("postExaminationData")).postExaminations[i].questions[j]["id"],
                    "examId" : JSON.parse(sessionStorage.getItem("postExaminationData")).postExaminations[i].questions[j]["examId"],
                    "name" : JSON.parse(sessionStorage.getItem("postExaminationData")).postExaminations[i].questions[j]["name"],
                    "answer" : JSON.parse(sessionStorage.getItem("postExaminationData")).postExaminations[i].questions[j]["answer"],
                    "isDeleted" : JSON.parse(sessionStorage.getItem("postExaminationData")).postExaminations[i].questions[j]["isDeleted"].toString(),
                    "choices" : JSON.parse(sessionStorage.getItem("postExaminationData")).postExaminations[i].questions[j].choices
                };
                questionData.push(questionObj);
            }
            this.setState({
                postExamTableList : questionData
            })

        }

        console.log("postExamTableList")
        console.log(questionData)

    }

    GetQuestionAnswer() {

        for (let i = 0; i < this.state.addQuestionsLists.length; i++) {
            const obj = {
                value : this.state.addQuestionsLists[i]["id"],
                label : this.state.addQuestionsLists[i]["answer"],
            };
            this.state.tblAnswerArrLst.push(obj);
        }
    }

    GetTrainingName() {
        this.setState({isloading:true})

        const searchParams = {
            "IpAddress"         :   "0.0.0.0",
            "UserId"            :   this.state.userinfo.userId,
            "ClientId"          :   this.state.userinfo.clientId,
            "TrainingId"    :   "",
            "Name"              :   "",
        };

        console.log("Search Params")

         axios
             .post(
                 AppConfiguration.Setting().noserapiendpoint + "Maintenance/GetTrainings",  searchParams
             )
             .then(res => {
                 const data = res.data;
                 console.log("GetTrainings")
                 console.log(data)
                 this.setState({ trainingNameAutocomplete     :   data.trainings, isloading : false })
                 if(data.status=="0"){
                    this.setState({
                        isloading   :   false,
                        alerttype   :   "Error!",
                        isshow      :   true,
                        color       :   "danger",
                        message     :   data.message,
                        fade        :   true
                    });
                
                }
                    
            })
            .catch(error=>{
                this.setState({
                    isloading       :   false,
                    alerttype       :   "Error!",
                    isshow          :   true,
                    color           :   "danger",
                    message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                    fade            :   true
                })
            })
        
    }
    
    onChangeTrainingName = (e) => {
        if(e.length == 0) {
            this.state.selectedTrainingId  =   ""
            /* this.setState({
                clientTableList     :   [],
                //positionTableList   :   [],
            }) */
            return
        }

        if(!this.state.selectedTrainingId) {
            this.setState({
                clientTableList     :   [],
                //positionTableList   :   [],
            })
        }

        this.state.selectedTrainingId    =   e[0].id
        /* for (let clientLoop = 0; clientLoop < e[0].trainingClients.length; clientLoop++) {
            const clientObj = {
                "client"   :   e[0].trainingClients[clientLoop]["client"],
            };
            this.state.clientTableList.push(clientObj);
        }

        for (let positionLoop = 0; positionLoop < e[0].trainingPositions.length; positionLoop++) {
            const positionObj = {
                "position"   :   e[0].trainingPositions[positionLoop]["position"],
            };
            this.state.positionTableList.push(positionObj);
        }
        this.setState({
            isshow:false,
        }) */
        /* console.log("this.state.clientTableList")
        console.log(this.state.clientTableList)
        console.log("this.state.positionTableList")
        console.log(this.state.positionTableList) */
    }

    onChangeExamName = (e) => {
        this.setState({
            examName : e.target.value.toUpperCase()
        })
    }
    
    onChangeInstruction = (e) => {
        this.setState({
            instruction : e.target.value.toUpperCase()
        })
    }

    onChangeNoOfItems = (e) => {
        const re = /^[0-9\b]+$/;
        if (e.target.value === '' || re.test(e.target.value)) {
            this.setState({noOfItems: e.target.value})
        }

        /* const ids = this.state.postExamTableList.map(x => x.id);
        const sorted = ids.sort((a,b) => a - b);
        let maxId = parseInt(sorted[sorted.length-1]) + 1;

        let today = new Date()
        let newId = moment(today).format('HHMMSS')
        for (let x = 0; x < e.target.value; x++) {
            if(x === 0) {
                const obj = {
                    "id":newId.toString() + 1,
                    "name" : "",
                    "answer" : "",
                    "choices" : [
                        {"name" : ""}
                    ]
                }
                console.log(obj)
                this.state.arr.push(obj);
            }
        }

        //set multi rows
        this.setState({
            postExamTableList : this.state.arr
        })

        console.log(this.state.arr) */

    }

    handleAddQuestionClick = (e) => {

        let today = new Date()
        let newId = moment(today).format('HHMMSS')
        /* for (let x = 0; x < e.target.value; x++) { */
            /* if(i === 0) {
                newId + 1
            } */

            for(let x = 0; x < this.state.postExamTableList.length; x++) {
                if(this.state.postExamTableList[x]["name"] === this.state.questionName){
                    this.setState({
                        isloading   :   false,
                        alerttype   :   "Error!",
                        isshow      :   true,
                        color       :   "danger",
                        message     :   "Question already exist",
                        fade        :   true
                    });
                    return
                }
                if(this.state.postExamTableList[x]["answer"] === this.state.questionAnswer){
                    this.setState({
                        isloading   :   false,
                        alerttype   :   "Error!",
                        isshow      :   true,
                        color       :   "danger",
                        message     :   "Question answer already exist",
                        fade        :   true
                    });
                    return
                }
            }
            const obj = {
                "id":newId.toString(),
                "name" : this.state.questionName,
                "answer" : this.state.questionAnswer,
                "choices" : [
                    {"id": 1,"name" : "","answer" : ""},
                    {"id": 2,"name" : "","answer" : ""},
                    {"id": 3,"name" : "","answer" : ""},
                    {"id": 4,"name" : "","answer" : ""},
                    {"id": 5,"name" : "","answer" : ""},
                ]
            }
            console.log(obj)
            this.state.arr.push(obj);
       /*  } */

        //set multi rows
        this.setState({
            postExamTableList : this.state.arr
        })

        console.log(this.state.postExamTableList)
    }

    onChangeLimeLimit = (e) => {
        //this.state.rowId = this.state.questionId + 1
        const re = /^[0-9\b]+$/;
        if (e.target.value === '' || re.test(e.target.value)) {
            this.setState({timeLimit: e.target.value})
            //this.state.timeLimit = e.target.value
        }
        
    }

    onChangePassingScore = (e) => {
        const re = /^[0-9\b]+$/;
        if (e.target.value === '' || re.test(e.target.value)) {
            this.setState({passingScore: e.target.value})
        }
    }

    onChangeQuestion = (e) => {
        this.setState({
            questionName : e.target.value.toUpperCase()
        })
    }

    onChangeQuestionAnswer = (e) => {
        this.setState({
            questionAnswer : e.target.value.toUpperCase()
        })
    }

    onChangeChoicesName = (e) => {
        this.setState({
            choicesName : e.target.value.toUpperCase()
        })
    }

    onChangeChoicesAnswer = (e) => {
        if(e.length == 0) {
            this.state.choicesAnswerId  =   ""
            this.state.choicesAnswerName  =   ""
            this.setState({
                clientTableList     :   [],
                positionTableList   :   [],
            })
            return
        }
        this.state.choicesAnswerId    =   e[0].id
        this.state.choicesAnswerName    =   e[0].name

        console.log(this.state.choicesAnswerName)
    }


    clear() {
        //this.state.examName = ""
        this.state.instruction = ""
        this.state.noOfItems = ""
        this.state.timeLimit = ""
        this.state.passingScore = ""
        this.state.questionName = ""
        this.state.questionAnswer = ""
        //this.state.checkIsRequired = false,
        //this.state.postExamTableList = []
        //this.state.addQuestionLists = []

        /* for (let i = 0; i < this.state.postExamTableList.length; i++) {
            this.state.postExamTableList[i]["Name"] = ""
        } */
        
    }

    handleSaveClick = (e) => {

        let choicesArr = []
        for (let i = 0; i < this.state.postExamTableList.length; i++) {
            console.log(this.state.postExamTableList[i]["name"])
            let obj = {
                "Id"      :   this.state.postExamTableList[i]["id"],
                "ExamId"    :   this.state.postExamTableList[i]["examId"],
                "Name"      :   this.state.postExamTableList[i]["name"],
                "Answer"    :   this.state.postExamTableList[i]["answer"],
                "IsDeleted"    :   this.state.postExamTableList[i]["isDeleted"].toString(),
                "choices"   :   choicesArr
            };
            this.state.questArr.push(obj);
        }

        for (let i = 0; i < this.state.postExamTableList.length; i++) {
            console.log("this.state.postExamTableList[i].choices")
            console.log(this.state.postExamTableList[i].choices)

            for (let x = 0; x < this.state.postExamTableList[i].choices.length; x++) {
                if (this.state.postExamTableList[i].choices[x]["isModified"] == 1) {
                    let obj = {
                        "Id"      :   this.state.postExamTableList[i]["id"],
                        "QuestionId"    :   this.state.postExamTableList[i]["questionId"],
                        "Name"      :   this.state.postExamTableList[i]["name"],
                        "Answer"    :   this.state.postExamTableList[i]["answer"],
                        "IsDeleted"    :   this.state.postExamTableList[i]["isDeleted"].toString(),
                    };
    
                    choicesArr.push(obj);
                }
            }

        }

        this.setState({
            tblLstArr   :   this.state.questArr,
        })

        console.log("questArr")
        console.log(this.state.tblLstArr)
        console.log("choicesArr")
        console.log(choicesArr)

        const saveParams = {
            "IpAddress"     :   "0.0.0.0",
            "UserId"        :   this.state.userinfo.userId,
            "ClientId"      :   this.state.userinfo.clientId,
            "TrainingId"    :   this.state.selectedTrainingId,
            "Name"          :   this.state.examName,
            "Instructions"  :   this.state.instruction,
            "NoOfItems"     :   this.state.noOfItems,
            "TimeLimit"     :   this.state.timeLimit,
            "PassingScore"  :   this.state.passingScore,
            "IsRequired"    :   (this.state.checkIsRequired) ? "1" : "0",
            "questions"     :   this.state.questArr
            /* "questions"     :   this.state.questArr */
        };

        console.log("Save Params")
        console.log(saveParams)

        axios
            .post(
                AppConfiguration.Setting().noserapiendpoint + "Training/EditPostExamination",  saveParams
            )
            .then(res => {
            const data = res.data;
            console.log("Save Results")
            console.log(data)
            this.clear()
            if(data.status=="1"){
            this.setState({
                    isloading   :   false,
                    alerttype   :   "Success!",
                    isshow      :   true,
                    color       :   "success",
                    message     :   data.message,
                    fade        :   true
                });
            
            }
            else {
                this.setState({
                    isloading   :   false,
                    alerttype   :   "Error!",
                    isshow      :   true,
                    color       :   "danger",
                    message     :   data.message,
                    fade        :   true
                });
            }
        })
        .catch(error=>{
            this.setState({
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade            :   true
            })
        })

    }

    LeavePageWithourSavingChanges() {
        const isChanged=false
        for (let i = 0; i < this.state.choicesTableList.length; i++) {
            if (this.state.choicesTableList[i]["isModified"] == 1) {
                this.setState({isGridDataChanged: true})
                isChanged=true
                break;
            }
        }
        return isChanged
    }

    ChoicesGridDataModified(oldValue, newValue, applicationid, column) {
        //console.log(applicationid)
        this.state.choicesTableList.map(function(item,i) {
            if (item.id===applicationid)
                item.isModified = newValue!=oldValue ? "1" : "0"
            })
    }

    LeavePageWithourSavingChanges() {
        const isChanged=false
        for (let i = 0; i < this.state.postExamTableList.length; i++) {
            if (this.state.postExamTableList[i]["isModified"] == 1) {
                this.setState({isGridDataChanged: true})
                isChanged=true
                break;
            }
        }
        return isChanged
    }

    QuestionsGridDataModified(oldValue, newValue, applicationid, column) {
        //console.log(applicationid)
        this.state.postExamTableList.map(function(item,i) {
            if (item.id===applicationid)
                item.isModified = newValue!=oldValue ? "1" : "0"
            })

    }

    handleChoicesClick = () => {
        for (let i = 0; i < this.state.choicesTableList.length; i++) {
            if(this.state.choicesTableList[i]["Name"] === this.state.choicesName) {
                alert("Choices already exist")
                return
            }

            if(this.state.choicesTableList[i]["Answer"] === this.state.choicesAnswerName) {
                alert("Choices Answer already exist")
                return
            }
         }
        
        let obj = {
            "Name"      :   this.state.choicesName,
            "Answer"    :   this.state.choicesAnswerName,
        };

        this.state.choicesTableList.push(obj);
        this.setState({
            addChoicesList   :   this.state.choicesTableList,
        })
        console.log(this.state.choicesTableList)
    }

    handleAddClick = () => {
       // this.setState({ questionId: this.state.questionId + 1 });
       for (let i = 0; i < this.state.postExamTableList.length; i++) {
           if(this.state.postExamTableList[i]["Name"] === this.state.questionName) {
               alert("Question already exist")
               return
           }
           if(this.state.postExamTableList[i]["Answer"] === this.state.questionAnswer) {
               alert("Answer already exist")
               return
           }
        }
        let obj = {
            "Name" :   this.state.questionName,
            "Answer"   :   this.state.questionAnswer,
            //"choice"  : ["testing","testing"]
        };

        this.state.postExamTableList.push(obj);
        this.setState({
            postExamTableListQuestAns   :   this.state.postExamTableList,
        })
    }

    /* { "id" : "", "choices" : "", "answers" : "" },
    { "id" : "", "choices" : "", "answers" : "" },
    { "id" : "", "choices" : "", "answers" : "" },
    { "id" : "", "choices" : "", "answers" : "" },
    { "id" : "", "choices" : "", "answers" : "" }, */
  
    
    onChangeIsRequired  = (e) =>{
        this.setState({
            checkIsRequired  :   e.target.checked,
        })
    }
    
    handleRemoveChoicesClick =() => {
  
        let lstQuestion = this.state.addChoicesList
        for( var i = 0; i < lstQuestion.length; i++){ 
            console.log(lstQuestion[i].IsSelected)
            if ( lstQuestion[i].IsSelected === 1) {
                lstQuestion.splice(i, 1); 
              i--;
            }
         }
        
        this.setState({addChoicesList:lstQuestion})
    }


    SubGridDataModified(oldValue, newValue, id, column) {
        this.state.postExamTableList.map(function(menu,i){
            menu.choices.map(function(submenu,j){
                /* if(submenu.id==id)
                    submenu.isModified="1" */
                if (submenu.id===id)
                submenu.isModified = newValue!=oldValue ? "1" : "0"
            })
        })
    }

    ChoicesGridDataModified(oldValue, newValue, applicationid, column) {
        //console.log(applicationid)
        this.state.choicesTableList.map(function(item,i) {
            if (item.id===applicationid)
                item.isModified = newValue!=oldValue ? "1" : "0"
            })
    }

    handleRemovePosition = (e) =>{
        let positionGrid = this.state.postExamTableList.filter(x=>x.isDeleted=="0")
        this.setState({postExamTableList:positionGrid,disableremovePosition: positionGrid.length===0 ? true : false})
    }

    render() {

        const clientColumn = [
            {
                dataField: 'client',
                text: 'Client',
                editable: false,
                headerStyle : () => {
                    return { width  : "100%" };
                }
            },
        ]

        const clientSelectRow = {
            mode: 'checkbox',
            clickToSelectAndEditCell: true,
            onSelect: (row, isSelect, rowIndex, e) => {
                this.state.clientTableList.map(function(item,i){
                    if(item.id===row.id)
                    {
                        item.isDeleted = isSelect ? "1" : "0"
                        item.isModified = isSelect ? "1" : "0"
                    }
                })
            }
        };

        const clientRowEvents = {
            onClick: (e, row, rowIndex) => {
            }
        };


        const positionColumn = [
            {
                dataField: 'position',
                text: 'Position',
                editable: false,
                headerStyle : () => {
                    return { width  : "100%" };
                }
            },
        ]

        const positionSelectRow = {
            mode: 'checkbox',
            clickToSelectAndEditCell: true,
            onSelect: (row, isSelect, rowIndex, e) => {
                this.state.positionTableList.map(function(item,i){
                    if(item.id===row.id)
                    {
                        item.isDeleted = isSelect ? "1" : "0"
                        item.isModified = isSelect ? "1" : "0"
                    }
                })
            }
        };

        const positionRowEvents = {
            onClick: (e, row, rowIndex) => {
            }
        };


        const postExamColumn = [
            {
                dataField: 'name',
                text: 'Questions',
                //editable: false,
                headerStyle : () => {
                    return { width  : "50%" };
                }
            },
            {
                dataField: 'answer',
                text: 'Answers',
                //editable: false,
                headerStyle : () => {
                    return { width  : "50%" };
                }
            },
        ]

        const postExamSelectRow = {
            mode: 'checkbox',
            clickToSelectAndEditCell: true,
            onSelect: (row, isSelect, rowIndex, e) => {
                let isDisable = true
                this.state.postExamTableList.map(function(item,i){
                    if(item.id===row.id)
                        item.isDeleted = isSelect ? "1" : "0"
                    if(item.isDeleted=="1")
                        isDisable=false
                    /* if(item.id===row.id)
                    {
                        item.isDeleted = isSelect ? "1" : "0"
                        item.isModified = isSelect ? "1" : "0"
                    }
                    if(item.isDeleted=="1")
                        isDisable=false */
                })
                this.setState({disableremovePosition:isDisable})
            }
        };

        const postExamRowEvents = {
            onClick: (e, row, rowIndex) => {
            }
            
        };


        const choicesColumn = [
            {
                dataField: 'name',
                text: 'Choices',
                //editable: false,
                headerStyle : () => {
                    return { width  : "50%" };
                }
            },
            {
                dataField: 'answer',
                text: 'Answers',
                //: true,
                headerStyle : () => {
                    return { width  : "50%" };
                },
                formatter: (cell, row) => {
                    if(row.answer!='' && row.answer!=null){
                        if(this.state.tblAnswerArrLst.filter(x => x.value == cell).length==0)
                        return ""
                        else
                            return this.state.tblAnswerArrLst.find(x => x.value == cell).label;
                    }
                },
                editor: {
                    type: Type.SELECT,
                    options: this.state.tblAnswerArrLst
                }
            },
        ]

        const choicesSelectRow = {
            /* mode: 'checkbox',
            clickToSelectAndEditCell: true,
            onSelect: (row, isSelect, rowIndex, e) => {
                console.log(row.Name)
                this.state.addChoicesList.map(function(item,i){
                    if(row.Name===row.Name)
                    {
                        row.isDeleted = isSelect ? "1" : "0"
                        //item.isModified = isSelect ? "1" : "0"
                    }
                })
            } */



            mode: 'checkbox',
            clickToSelectAndEditCell: false,
            onSelect: (row, isSelect, rowIndex, e) => {
                this.state.choicesTableList.map(function(item,idx){
                    if(idx==rowIndex)
                    {
                        item.isDeleted = isSelect ? "1" : "0"
                    }
                }) 
                //console.log(this.state.LeavedayDataList)
            }
        };

        const choicesRowEvents = {
            onClick: (e, row, rowIndex) => {
            }
        };

        const expandSubModules = {
            //onlyOneExpanding: true,
            renderer: row => 
            (
                <div className="expandable-table" style={{marginLeft:'50px !important'}}>
                    <BootstrapTable
                            keyField = "id"
                            data = { row.choices }
                            columns = { choicesColumn }
                            selectRow = { choicesSelectRow }
                            rowClasses="noser-table-row-class"
                            striped
                            cellEdit = { cellEditFactory({ 
                                mode: 'dbclick', 
                                blurToSave: true,
                            afterSaveCell: (oldValue, newValue, row, column) => { 
                            this.SubGridDataModified(oldValue, newValue, row.id, column)
                                    }
                                })
                            }
                            //expandRow={ expandItems }
                            wrapperClasses="table-responsive"
                            //headerClasses="role-access-header-class"
                            className="expandable-table-sub"
                        /> 
                </div>
            ),
            showExpandColumn: true,
          };

    return(
            <div>
                <Banner />
                    <Container className="mt-5" fluid>
                        <Card>
                            <Card.Header>MAINTENANCE >> TRAINING MAINTENANCE  >> POST TRAINING EXAMINATION (VIEW / EDIT)</Card.Header>
                            <Card.Body>
                                <Form>
                                    <Alert isOpen={this.state.isshow} color={this.state.color} fade={this.state.fade} className={this.state.isshow ? 'display-block' : 'display-none'}>
                                        <div className={this.state.color}></div> 
                                        {this.state.message}
                                    </Alert>

                                    <Form.Group as={Row} controlId="formPlaintextEmail">
                                        <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                            TRAINING NAME
                                        </Form.Label>
                                        <Col sm="11">
                                            <Typeahead
                                                labelKey='name'
                                                id="basic-example"
                                                onChange={this.onChangeTrainingName}
                                                options={this.state.trainingNameAutocomplete}
                                                defaultSelected={[JSON.parse(sessionStorage.getItem("selectedCreateTrainingName"))]}
                                            />
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} controlId="formPlaintextEmail">
                                        <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                            EXAM NAME
                                        </Form.Label>
                                        <Col sm="11">
                                            <Form.Control 
                                                ref="examName"
                                                name="examName"
                                                value={this.state.examName}
                                                onChange={this.onChangeExamName}
                                                autoComplete="off"
                                            />
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} controlId="formPlaintextEmail">
                                        <Col sm="6">
                                            <Form.Check 
                                                inline
                                                type="checkbox" 
                                                label="IS REQUIRED" 
                                                name="checkIsRequired"
                                                checked={this.state.checkIsRequired}
                                                onChange={this.onChangeIsRequired}
                                                style={{fontWeight : "bold", marginTop : "3px"}}
                                            />
                                        </Col>
                                    </Form.Group>
                                    
                                    <Form.Row>
                                        <Form.Group as={Col} sm={6} controlId="formGridPassword">

                                            <Card.Header className="mt-3">List of Client</Card.Header>
                                            <div className="mt-1">
                                                <BootstrapTable
                                                    /* caption={Noser.TableHeader({title:"RECORD"})} */
                                                    keyField = "id"
                                                    data = { this.state.clientTableList }
                                                    columns = { clientColumn }
                                                    /* pagination={ paginationFactory({sizePerPageRenderer}) } */
                                                    noDataIndication={ () => <div style={{width:'100%'}}>No record found.</div> }
                                                    rowClasses="noser-table-row-class"
                                                    striped
                                                    hover
                                                    condensed
                                                    cellEdit = { cellEditFactory({
                                                        mode: 'dbclick',
                                                        blurToSave: true,
                                                        afterSaveCell: (oldValue, newValue, row, column) => {
                                                            this.ClientGridDataModified(oldValue, newValue, row.id, column.dataField)
                                                            }
                                                        })
                                                    }
                                                    rowEvents={ clientRowEvents }
                                                    selectRow = { clientSelectRow }

                                                />
                                            </div>
                                        </Form.Group>
                                        {/* <Form.Group as={Col} sm={2} controlId="formGridPassword">
                                        </Form.Group> */}
                                        <Form.Group as={Col} sm={6} controlId="formGridPassword">

                                            <Card.Header className="mt-3">List of Position</Card.Header>
                                            <div className="mt-1">
                                                <BootstrapTable
                                                    /* caption={Noser.TableHeader({title:"RECORD"})} */
                                                    keyField = "id"
                                                    data = { this.state.positionTableList }
                                                    columns = { positionColumn }
                                                    /* pagination={ paginationFactory({sizePerPageRenderer}) } */
                                                    noDataIndication={ () => <div style={{width:'100%'}}>No record found.</div> }
                                                    rowClasses="noser-table-row-class"
                                                    striped
                                                    hover
                                                    condensed
                                                    cellEdit = { cellEditFactory({
                                                        mode: 'dbclick',
                                                        blurToSave: true,
                                                        afterSaveCell: (oldValue, newValue, row, column) => {
                                                            this.PositionGridDataModified(oldValue, newValue, row.id, column.dataField)
                                                            }
                                                        })
                                                    }
                                                    rowEvents={ positionRowEvents }
                                                    selectRow = { positionSelectRow }

                                                />
                                            </div>
                                        </Form.Group>
                                    </Form.Row>

                                    <Form.Group as={Row} controlId="formPlaintextEmail">
                                        <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                            INSTRUCTION
                                        </Form.Label>
                                        <Col sm="11">
                                            <Form.Control
                                                as="textarea"
                                                rows={3} 
                                                ref="instruction"
                                                name="instruction"
                                                value={this.state.instruction}
                                                onChange={this.onChangeInstruction} 
                                                autoComplete="off" 
                                            />
                                        </Col>
                                    </Form.Group>

                                    <Form.Group as={Row} controlId="formPlaintextEmail">
                                        <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                            NO. OF ITEMS
                                        </Form.Label>
                                        <Col sm="2">
                                            <Form.Control 
                                                type="text" 
                                                ref="noOfItems"
                                                name="noOfItems"
                                                value={this.state.noOfItems}
                                                onChange={this.onChangeNoOfItems} 
                                                autoComplete="off" 
                                            />
                                        </Col>
                                        <Col sm="1">
                                        </Col>
                                        <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                            TIME LIMIT
                                        </Form.Label>
                                        <Col sm="2">
                                            <Form.Control 
                                                type="text" 
                                                ref="timeLimit"
                                                name="timeLimit"
                                                value={this.state.timeLimit}
                                                onChange={this.onChangeLimeLimit} 
                                                autoComplete="off" 
                                            />
                                        </Col>
                                        <Col sm="1">
                                        </Col>
                                        <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                            PASSING SCORE
                                        </Form.Label>
                                        <Col sm="2">
                                            <Form.Control 
                                                type="text" 
                                                ref="passingScore"
                                                name="passingScore"
                                                value={this.state.passingScore}	
                                                onChange={this.onChangePassingScore} 
                                                autoComplete="off" 
                                            />
                                        </Col>
                                    </Form.Group>



                                    <Card className="mt-5">
                                        <Card.Header>QUESTION AND ANSWERS</Card.Header>
                                        <Card.Body>
                                            {/* <Form.Group as={Row} controlId="formPlaintextEmail">
                                                <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                                    QUESTION
                                                </Form.Label>
                                                <Col sm="11">
                                                    <Form.Control 
                                                        ref="questionName"
                                                        name="questionName"
                                                        value={this.state.questionName}
                                                        onChange={this.onChangeQuestion}
                                                        autoComplete="off"
                                                    />
                                                </Col>
                                            </Form.Group>
                                            <Form.Group as={Row} controlId="formPlaintextEmail">
                                                <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                                    ANSWER
                                                </Form.Label>
                                                <Col sm="11">
                                                    <Form.Control 
                                                        ref="questionAnswer"
                                                        name="questionAnswer"
                                                        value={this.state.questionAnswer}
                                                        onChange={this.onChangeQuestionAnswer}
                                                        autoComplete="off"
                                                    />
                                                </Col>
                                            </Form.Group>
                                            <ButtonToolbar>
                                                <Button className="ml-auto" variant="success" onClick = { this.handleAddQuestionClick }>Add</Button>
                                            </ButtonToolbar>  */}

                                            <div className="mt-5">
                                                <BootstrapTable

                                                    keyField = "id"
                                                    data = { this.state.postExamTableList }
                                                    columns = { postExamColumn }
                                                    selectRow = { postExamSelectRow }
                                                    cellEdit = { cellEditFactory({ 
                                                            mode: 'dbclick', 
                                                            blurToSave: true,
                                                            afterSaveCell: (oldValue, newValue, row, column) => { 
                                                                //this.GridDataModified(oldValue, newValue, row.rowIndex, column.dataField)
                                                            }
                                                        })
                                                    }
                                                    //rowEvents={ rowEvents }
                                                    rowClasses="noser-table-row-class"
                                                    //headerClasses="role-access-header-class"
                                                    striped
                                                    //hover
                                                    //condensed
                                                    loading={true}
                                                    //expanded={this.state.expanded}
                                                    expandRow={ expandSubModules }
                                                    wrapperClasses="table-responsive"
                                                    rowClasses="noser-table-row-class"

                                                />
                                            </div>
                                            {/* <ButtonToolbar>
                                                <Button disabled={this.state.disableremovePosition} style={{width:'60px'}} variant="danger" onClick = { this.handleRemovePosition }>REMOVE</Button>
                                            </ButtonToolbar> */}
                                        </Card.Body>
                                    </Card>
                                        
                                        <ButtonToolbar className="mt-5">
                                            <Button className="ml-auto" variant="success" onClick = { this.handleSaveClick }>Save</Button>&nbsp;&nbsp;&nbsp;
                                            <Button  href="/posttrainingexamination" variant="danger">Back</Button>
                                        </ButtonToolbar>
                                </Form>
                            </Card.Body>
                        </Card>
                       
                    </Container>
                    <NoserLoading show={this.state.isloading} />
            </div> 
        )
    }

}

export  default PostTrainingExaminationCreate 
