import {
    React,Component,BootstrapTable,
    paginationFactory,Button, ButtonToolbar, Card, 
    Form, Col, Row, axios, Typeahead, TimePicker,
    cellEditFactory ,moment, NavLink, Alert, AppConfiguration,
    NoserLoading, Noser, Banner, Container, DatePicker, LeaveModal, Tabs, Tab,
    sizePerPageRenderer
} 
from '../../noser-hris-component';

class EmployeeRecordCreate extends Component {
    constructor(props) {
        super(props)
        this.minDate = new moment(props.Date)
        this.state = {
            selected: [],
            userinfo  :   [],
            isloading :   false,
            isshow    :   false,
            alerttype :   "",
            message   :   "",
            color     :   "",
            fade      :   true,
            getClientList:[],
            selectedClientName:"",
            selectedClientId:"",
            IsDefaultLocation:"false",
            selectedLocationId:"",
            selectedLocation:"",
            locationList:[],
            employeeList:[],
            // selectedEmployee:"",
            // selectedEmployeeId:"",
            getPayCardTypesList:[],
            payCardType:"",
            payCardTypeId:"",
            payCardNumber:"",
            TMNProfiledId:"",
            dateOfDeactivation:"",
            contractDateStart:new Date(),
            contractDateEnd:new Date(),
            dateHired:new Date(),
            selectedPosition:"",
            selectedPositionId:"",
            positionList:[],
            rate:"",
            seaValue:"",
            ecolaValue:"",
            colaValue:"",
            remarks:"",
            selectedEmployeeStatusId:"",
            selectedEmployeeStatus:"",
            getPayStatusList:[],
            selectedProfileId:"",
            selectedemployeeName:""

        }
         
    }

    componentDidMount() {
        const sleep = (milliseconds) => {return new Promise(resolve => setTimeout(resolve, milliseconds))}
        this.state.userinfo = JSON.parse(sessionStorage.getItem("userData"))
        sleep(1000).then(() => {
            this.setState({isLoading:false})}) 
        this.getClient();
        //this.GetEmployees();
        this.getLocation();
        this.GetPayCardTypes();
        this.GetPosition();
        this.GetEmployeeStatusList();
       
        
    }

    onChangePayCardNumber(e){
        console.log(e.target.value)
        this.setState({payCardNumber : e.target.value})
       
    }

    onChangeTMNProfiledId(e){
        console.log(e.target.value)
        this.setState({TMNProfiledId :e.target.value})
    }

    onChangeRate(e){
        console.log(e.target.value)
        this.setState({rate:e.target.value})
    }

    onChangeSeaValue(e){
        console.log(e.target.value)
        this.setState({seaValue:e.target.value})
    }

    onChangeECOLAValue(e){
        console.log(e.target.value)
        this.setState({ecolaValue:e.target.value})
    }

    onChangeCOLAValue(e){
        console.log(e.target.value)
        this.setState({colaValue:e.target.value})
    }

    onChangeRemarks(e){
        console.log(e.target.value)
        this.setState({remarks:e.target.value})
    }


    // handleChangeDateOfDeactivation = date => {
    //     console.log(date)
    //     this.setState({
    //         dateOfDeactivation:date
    //     });
    // }
    onChangeDeactivation(e){
        console.log(e.target.value)
        this.setState({dateOfDeactivation:e.target.value})
    }

    handleChangeContractDateStart = date => {
        console.log(date)
        this.setState({contractDateStart: date});
    }

    handleChangeContractDateEnd = date => {
        console.log(date)
        this.setState({contractDateEnd: date});
    }

    handleChangeDateHired = date => {
        console.log(date)
        this.setState({
            dateHired: date
        });
    }

    handleChangePosition = (e) => {
        console.log( this.state.selectedPosition)
            if (e.length > 0) {
            this.state.selectedPosition= e[0].position
            this.state.selectedPositionId = e[0].id
        } else {
            this.state.selectedPosition = ""
            this.state.selectedPositionId = ""
        }
    }

    GetPosition() {
        const positionParams = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.userinfo.clientId,
            "UserId":this.state.userinfo.userId,
            "SectionId":"",
            "DepartmentId":"",
            "Name":""
        };

        axios.post(AppConfiguration.Setting().noserapiendpoint + "Maintenance/GetPositions",  positionParams)
        .then(res => {
            const data = res.data;
            console.log("Get Position Name");
            console.log(data);
            this.setState({ positionList  : data.positions });
        })
        
    }

    GetEmployeeStatusList(){
        const getParams = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.userinfo.clientId,
            "UserId":this.state.userinfo.userId,
        }
    
        axios
            .post(AppConfiguration.Setting().noserapiendpoint + "Maintenance/GetEmployeeStatusTypes", getParams)
            .then(res => {
                //console.log("Get Employee Status List ");
                //console.log(res.data);
                this.setState({
                    getPayStatusList : res.data.statusTypes
                })
            })
    
    }

    handleChangeEmployeeStatus = (e) => {
        console.log(e)
            if (e.length > 0) {
            this.state.selectedEmployeeStatus = e[0].name
            this.state.selectedEmployeeStatusId = e[0].id
        } else {
            this.state.selectedEmployeeStatus = ""
            this.state.selectedEmployeeStatusId = ""
        }
    }


    
    handleChangeEmployee = (e) => {
        if (e.length == 0) {
            this.setState({ selectedemployeeName:"", employeeId:"", selectedProfileId:"",
                selectedLocation:"",selectedLocationId:"", selectedEmployeeStatus:"", 
                dateOfDeactivation:"",payCardTypeId:"",contractDateStart:"",contractDateEnd:"",
                payCardNumber:"",TMNProfiledId:"",rate:"", seaValue:"",selectedPositionId:"",
                colaValue:"",ecolaValue:"",remarks:"", selectedPosition:"", contractDateEnd:"",
            })
            return
        }
        
        this.setState({
            employeeId:e[0].employeeId,
            selectedemployeeName:e[0].name,
            selectedProfileId:e[0].id,
            selectedLocation:[e[0].locationName],
            selectedLocationId:e[0].locationId,
            selectedEmployeeStatus:[e[0].employeeStatusType],
            selectedEmployeeStatusId:e[0].employeeStatusTypeId,
            selectedPosition:[e[0].position],
            selectedPositionId:e[0].jobPositionId,
            payCardType:[e[0].payType],
            payCardTypeId:e[0].payTypeId,
            payCardNumber:e[0].payCardNumber,
            TMNProfiledId:e[0].tmnProfileId,
            dateOfDeactivation:e[0].dateOfDeactivation,
            contractDateStart:new Date(e[0].contractDateStart),
            dateHired:new Date(e[0].dateHired),
            contractDateEnd:new Date(e[0].contractDateEnd),
            rate:e[0].salaryOffered, 
            seaValue:e[0].seaValue,
            colaValue:e[0].colaValue,
            ecolaValue:e[0].ecolaValue,
            remarks:e[0].remarks,
            })

           console.log("test")
           
             console.log( this.state.selectedPosition)
            // console.log( this.state.contractDateEnd)
            // console.log( this.state.selectedPosition)

         this.setState({isloading:false,});
    } 

    handleChangeBranch  = (e) =>{
        console.log(this.state.IsDefaultLocation)
        if(e.target.unchecked) 
            this.setState({IsDefaultLocation: "false"})
        else
        {
            this.setState({IsDefaultLocation: ""})
            
        }
    }

    getClient(){
        this.setState({isloading:true,})

        const getParams = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.userinfo.clientId,
            "UserId":this.state.userinfo.userId,
        }

        axios
            .post(AppConfiguration.Setting().noserapiendpoint + "Client/GetClientList", getParams)
            .then(res => {
                console.log("Client List ");
                console.log(res.data);
                this.setState({
                    isloading:false,
                    getClientList : res.data.clients
                })
            })
    }

    handleChangeClient= (e) => {
        console.log(this.state.selectedClientName)
        if(e.length == 0) {
            this.state.selectedClientName=""
            this.state.selectedClientId=""
            return
        } 
        this.state.selectedClientId = e[0].id
        this.state.selectedClientName = e[0].name
        this.setState({isloading:true})
        
        this.GetEmployees();
        // this.getLocation();
    
    }

    handleCoverChangeBranch= (e) => {
        console.log(e)
        if(e.length == 0) {
            this.state.selectedLocation    =   ""
            this.state.selectedLocationId     =   ""
            return
        }
        this.state.selectedLocation     =   e[0].locationName
        this.state.selectedLocationId     =   e[0].id

    }

    handleCoverChangePayCardType= (e) => {
        console.log(e)
        let paycardtype = ""
        if(e.length > 0) {
            this.state.payCardType = e[0].name
            this.state.payCardTypeId = e[0].id
        }else{
            this.state.payCardType = ""
            this.state.payCardTypeId = ""
        }
        console.log( this.state.payCardTypeId );
    }

    GetPayCardTypes(){
        
        const getParams = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.userinfo.clientId,
            "UserId":this.state.userinfo.userId,
            
        }
        axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Maintenance/GetPayCardTypes", getParams)
        .then(res => {
            console.log("Get Pay Card Types List Data")
            console.log(res.data)
            const data = res.data
            this.setState({ getPayCardTypesList : data.payCardTypes,isloading:false });
                
        })
    }

    // GetPayCardType(name) {
    //     console.log("payCardTypeId ");
    //     let payCardTypeId = ""
    //     for (let i = 0; i <= this.state.getPayCardTypesList.length; i++) {
    //         if (this.state.getPayCardTypesList[i]["name"] === name) {
    //             payCardTypeId = this.state.getPayCardTypesList[i]['name'];
                
    //             break;
                      
    //         }
       
    //     }
    //     return payCardTypeId
    //     // console.log(payCardTypeId);
    // }

    GetEmployees() {
        this.setState({
            employeeList:[]
            
        })

        const getParams = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.selectedClientId,
            "UserId":this.state.userinfo.userId,
            "ProfileId":"",
         };
        axios
        .post(
            AppConfiguration.Setting().noserapiendpoint + "Employee/GetEmployees", getParams
        )
        .then(res => {
            const data = res.data;
            console.log("data");
            console.log(data);
            this.setState({employeeList : data.employees, isloading:false}) 
           
        })

    }

    getLocation(){
        const locationParams = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.userinfo.clientId,
            "UserId":this.state.userinfo.userId,
            "ClientName": this.state.selectedClient,
            "City":"",
            "Province": "",
            "Region":""
        }

        axios
            .post(
                 AppConfiguration.Setting().noserapiendpoint + "Client/GetClientLocations", locationParams)
            .then(res => {
                const data = res.data;
                console.log("Test 2");
                console.log(data);
                this.setState({locationList : data.locations})  
            })
    }


    handleSaveClick = event => {
        console.log(this.state.selectedPositionId)
        const addParams = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.selectedClientId,
            "UserId":this.state.userinfo.userId,
            "RoleId":this.state.userinfo.roleId,
            "EmployeeId":this.state.selectedProfileId,
            "LocationId":this.state.selectedLocationId,
            "IsDefaultLocation":(this.state.IsDefaultLocation)? "1" : "0",
            "EmployeeStatusTypeId":this.state.selectedEmployeeStatusId,
            "DateOfDeactivation":this.state.dateOfDeactivation,
            "PayCardTypeId":this.state.payCardTypeId,
            "PayCardNumber":this.state.payCardNumber,
            "TMNProfileId":this.state.TMNProfiledId,
            "ContractDateStart":moment(this.state.contractDateStart).format("MM-DD-YYYY" ),
            "ContractDateEnd":moment(this.state.contractDateEnd).format("MM-DD-YYYY" ),
            "SalaryOffered":this.state.rate,
            "SeaValue":this.state.seaValue,
            "ColaValue":this.state.colaValue,
            "EcolaValue":this.state.ecolaValue,
            "PositionId":this.state.selectedPositionId,
            "Remarks":this.state.remarks,
            "Changes":""
           
        }
        console.log(addParams)
    //   return
        axios
            .post(
                AppConfiguration.Setting().noserapiendpoint + "Administrator/SubmitEmployeeRecordForApproval",  addParams
            )
            .then(res => {
                const data = res.data;
                console.log("Get data");
                console.log(data)
                this.setState({isLoading:false})

                    var alertType = (data.status=="1") ? "success" : "danger"
                this.setState(
                    {
                        isLoading:false,
                        AlertType:"Success!",
                        show:true,
                        Color:alertType,
                        Message:data.message ,
                        Fade:true
                    });
            })
            .catch(error=>{
                this.setState(
                {
                    isLoading:false,
                    AlertType:"Error! ",
                    Show:true,
                    Color:"danger",
                    Message: "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                    Fade:true
            })
        })

    }

    render() {
        return(
            <div>
                <Banner />
                    <Container className="mt-5">
                        <Card>
                            <Card.Header>Create Administration Account Manager</Card.Header>
                            <Card.Body>
                                <Form>
                                <Alert color="success" isOpen={this.state.show} color={this.state.Color} fade={this.state.Fade}>
                                        {this.state.Message}
                                    </Alert>
                                    <Form.Row>
                                        <Col sm={6} className="mt-3">
                                            <Typeahead 
                                                labelKey='name'
                                                id="basic-example"
                                                onChange={this.handleChangeClient}
                                                options={this.state.getClientList}
                                                placeholder="CLIENT"
                                            />
                                        </Col>
                                        <Col sm={6} className="mt-3">
                                           <Typeahead 
                                               labelKey='employeeName'
                                               id="basic-example"
                                               onChange={this.handleChangeEmployee}
                                               options={this.state.employeeList}
                                               placeholder="EMPLOYEE"
                                            /> 
                                        </Col>
                                            <Col sm={6} className="mt-3">
                                                <Typeahead 
                                                    labelKey='name'
                                                    id="basic-example"
                                                    onChange={this.handleCoverChangeBranch}
                                                    options={this.state.locationList}
                                                    placeholder="BRANCH"
                                                    selected={this.state.selectedLocation || ""}
                                                />
                                            </Col>
                                        </Form.Row>
                                        <Form.Row>
                                            <Col sm={6} className="mt-3">
                                                <Form.Check type="checkbox" 
                                                    label="IS DEFAULT BRANCH" 
                                                    name="IsDefaultLocation"
                                                    onChange={this.handleChangeBranch}
                                                />
                                            </Col>
                                        </Form.Row>
                                        <Form.Row>
                                            <Col sm={6} className="mt-3">
                                                <DatePicker
                                                    ref='dateHired'
                                                    selected={this.state.dateHired}
                                                    onChange={this.handleChangeDateHired}
                                                    minDate={this.minDate}
                                                    value={this.props.dateHired}
                                                    dateFormat={"MM/dd/yyyy"}
                                                    placeholderText="DATE HIRED"
                                                    className="form-control"
                                                    disabled = { this.state.dateHired}
                                                    
                                                />
                                            </Col>
                                            <Col sm={6} className="mt-3">
                                                <Typeahead
                                                    labelKey='name'
                                                    id="basic-example"
                                                    onChange={this.handleChangePosition}
                                                    options={this.state.positionList}
                                                    placeholder="JOB POSITION"
                                                    selected={this.state.selectedPosition || ""}
                                                />
                                            </Col>
                                            <Col sm={6} className="mt-3">
                                                <Typeahead
                                                    labelKey='name'
                                                    id="basic-example"
                                                    onChange={this.handleChangeEmployeeStatus}
                                                    options={this.state.getPayStatusList}
                                                    placeholder="EMPLOYEE STATUS"
                                                    selected={this.state.selectedEmployeeStatus || ""}
                                                />
                                            </Col>
                                            <Col sm={6} className="mt-3">
                                                {/* <DatePicker
                                                    ref='dateOfDeactivation'
                                                    selected={this.state.dateOfDeactivation}
                                                    onChange={this.handleChangeDateOfDeactivation}
                                                    minDate={this.minDate}
                                                    value={this.props.dateOfDeactivation}
                                                    dateFormat={"MM/dd/yyyy"}
                                                    placeholderText="DATE OF DEACTIVATION"
                                                    className="form-control"
                                                    disabled = { this.state.dateOfDeactivation}
                                                /> */}
                                                <Form.Control 
                                                    type="text" 
                                                    placeholder="DATE OF DEACTIVATION" 
                                                    value={this.state.dateOfDeactivation}
                                                    onChange={this.onChangeDeactivation.bind(this)}
                                                    autoComplete="off" 
                                                    name="DateOfDeactivation"
                                                    disabled={ this.state.dateOfDeactivation}
                                                   
                                                />
                                                
                                            </Col>
                                            <Col sm={6} className="mt-3">
                                                <Typeahead
                                                    labelKey='name'
                                                    id="basic-example"
                                                    onChange={this.handleCoverChangePayCardType}
                                                    options={this.state.getPayCardTypesList}
                                                    placeholder="PAY CARD TYPE"
                                                    selected={this.state.payCardType || ""}
                                                    
                                                /> 
                                            </Col>
                                            <Col sm={6} className="mt-3">
                                                <Form.Control 
                                                    type="text" 
                                                    placeholder="PAY CARD NUMBER" 
                                                    value={this.state.payCardNumber}
                                                    onChange={this.onChangePayCardNumber.bind(this)}
                                                    autoComplete="off" 
                                                    name="PayCardNumber"
                                                    //disabled = { this.state.selectedEducationAttainment}
                                                />
                                            </Col>
                                            <Col sm={6} className="mt-3">
                                                <Form.Control 
                                                    type="text" 
                                                    placeholder="TMN PROFILEID"  
                                                    autoComplete="off" 
                                                    name="TMNProfiledId"
                                                    value={this.state.TMNProfiledId}
                                                    onChange={this.onChangeTMNProfiledId.bind(this)}
                                                />
                                            </Col>
                                        </Form.Row>
                                        <Form.Row>
                                            <Col sm={6} className="mt-3">
                                                <DatePicker
                                                    ref='contractDateStart'
                                                    selected={this.state.contractDateStart}
                                                    onChange={this.handleChangeContractDateStart}
                                                    minDate={this.minDate}
                                                    value={this.props.contractDateStart}
                                                    dateFormat={"MM/dd/yyyy"}
                                                    placeholderText="CONTRACT DATE START"
                                                    className="form-control"
                                                    
                                                />                                                
                                            </Col>
                                            <Col sm={6} className="mt-3">
                                                <DatePicker
                                                    ref='contractDateEnd'
                                                    selected={this.state.contractDateEnd}
                                                    onChange={this.handleChangeContractDateEnd}
                                                    minDate={this.minDate}
                                                    value={this.props.contractDateEnd}
                                                    dateFormat={"MM/dd/yyyy"}
                                                    placeholderText="CONTRACT DATE END"
                                                    className="form-control"
                                                />   
                                            </Col>
                                            <Col sm={6} className="mt-3">
                                                <Form.Control 
                                                    type="text" 
                                                    placeholder="RATE"  
                                                    autoComplete="off" 
                                                    name="Rate"
                                                    value={this.state.rate}
                                                    onChange={this.onChangeRate.bind(this)}
                                                />
                                            </Col>
                                        </Form.Row>
                                        <Form.Row>
                                        <Col sm={6} className="mt-3">
                                            <Form.Control 
                                                type="text" 
                                                placeholder="SEA VALUE"  
                                                autoComplete="off" 
                                                name="seaValue"
                                                value={this.state.seaValue}
                                                onChange={this.onChangeSeaValue.bind(this)}
                                            />
                                        </Col>
                                        </Form.Row>
                                        <Form.Row>
                                            <Col sm={6} className="mt-3">
                                                <Form.Control 
                                                    type="text" 
                                                    placeholder="ECOLA VALUE"  
                                                    autoComplete="off" 
                                                    name="ecolaValue"
                                                    value={this.state.ecolaValue}
                                                    onChange={this.onChangeECOLAValue.bind(this)}
                                                />
                                            </Col>
                                            <Col sm={6} className="mt-3">
                                                <Form.Control 
                                                    type="text" 
                                                    placeholder="COLA VALUE"  
                                                    autoComplete="off" 
                                                    name="colaValue"
                                                    value={this.state.colaValue}
                                                    onChange={this.onChangeCOLAValue.bind(this)}
                                                />
                                            </Col>
                                            <Col sm={12} className="mt-3">
                                                <Form.Control 
                                                    type="text" 
                                                    placeholder="REMARKS"  
                                                    autoComplete="off" 
                                                    name="remarks"
                                                    value={this.state.remarks}
                                                    onChange={this.onChangeRemarks.bind(this)}
                                                />
                                            </Col>
                                        </Form.Row>
                                        <ButtonToolbar sm={12} className="mt-3">
                                        {/* <Button variant="success"  onClick={this.handleSaveClick}>
                                            Save
                                        </Button>&nbsp;&nbsp; */}
                                        <Button variant="success"  onClick={this.handleSaveClick}>
                                            Submit
                                        </Button>&nbsp;&nbsp;
                                        <Button variant="danger" href="/EmployeeRecord">
                                            Back
                                        </Button>
                                    </ButtonToolbar>
                                </Form>
                            </Card.Body>
                        </Card>
                        
                    </Container>
                    <NoserLoading show={this.state.isLoading} /> 
            </div> 
            
        )
    }
}

export default EmployeeRecordCreate;