import {
    React,Component,BootstrapTable,
    paginationFactory,Button, ButtonToolbar, Card, 
    Form, Col, Row, axios, Typeahead, TimePicker,
    cellEditFactory ,moment, NavLink, Alert, AppConfiguration,
    NoserLoading, Noser, Banner, Container, DatePicker, LeaveModal, Tabs, Tab,
    sizePerPageRenderer
} 
from '../../noser-hris-component';

class TaxCreate extends Component {
    constructor(props) {
        super(props)
        this.state = {
            userinfo  :   [],
            isloading :   false,
            isshow    :   false,
            alerttype :   "",
            message   :   "",
            color     :   "",
            fade      :   true,
            selectedTaxRateId:"",
            selectedTaxRate:"",
            salaryFrom:"",
            salaryTo:"",
            percentage:"",
            taxDue:"",
            dataReferenceList:[],
            
        }
    }

    componentDidMount() {
        const sleep = (milliseconds) => {return new Promise(resolve => setTimeout(resolve, milliseconds))}
        this.state.userinfo = JSON.parse(sessionStorage.getItem("userData"))
        this.GetDataReferences();
        sleep(1000).then(() => {
            this.setState({isLoading:false})}) 
    }

    handleCoverChangeTaxRate= (e) => {
        if (e.length > 0) {
            this.state.selectedTaxRate = e[0].name
            this.state.selectedTaxRateId = e[0].id

        } else {
            this.state.selectedTaxRate = ""
            this.state.selectedTaxRateId = ""
        }
        
        console.log(this.state.selectedTaxRate);
       
    } 

    GetDataReferences() {
        this.setState({
            dataReferenceList:[],
            isloading:true
        })
        const referenceParams = {
            "IpAddress":"0.0.0.0",
            "UserId":"1",
            "ClientId":"1",
            "Code":"0019"
            
            
        };
        console.log("referenceParams");
        console.log(referenceParams)
        axios
            .post(AppConfiguration.Setting().noserapiendpoint + "Maintenance/GetDataReferences",  referenceParams)
        .then(res => {
            const data = res.data;
            console.log("Test app");
            console.log(data);
            this.setState({ dataReferenceList: data. dataReferences, isloading:false});
            if(data.status=="1"){
                this.setState({
                    isloading       :   false,
                    alerttype       :   "Success!",
                    isshow          :   true,
                    color           :   "success",
                    message         :   data.message,
                    fade            :   true
                });
            }
            else{
                this.setState({
                    isloading       :   false,
                    alerttype       :   "Error!",
                    isshow          :   true,
                    color           :   "danger",
                    message         :   data.message,
                    fade            :   true
                })
            }
            })
            .catch(error=>{
            this.setState(  {
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade            :   true
            })
        })

    }

    onChangeSalaryFrom(e){
        console.log(e)
        this.setState({salaryFrom:e.target.value})
    }

    onChangeSalaryTo(e){
        console.log(e)
        this.setState({salaryTo:e.target.value})
    }
    onChangePercentage(e){
        console.log(e)
        this.setState({percentage:e.target.value})
    }
    onChangeTaxdue(e){
        console.log(e)
        this.setState({taxDue:e.target.value})
    }

    handleSaveClick = event => {
        const taxParams = {
            "IpAddress":"0.0.0.0",
            "UserId":this.state.userinfo.userId,
            "ClientId":this.state.userinfo.clientId,
            "TaxRateId":this.state.selectedTaxRateId,
            "SalaryFrom":this.state.salaryFrom, 
            "SalaryTo":this.state.salaryTo,	 
            "Percentage":this.state.percentage,
            "TaxDue":this.state.taxDue
        }
        console.log(taxParams)
        axios
            .post(
                AppConfiguration.Setting().noserapiendpoint + "Maintenance/AddTaxTable",  taxParams
            )
            .then(res => {
                const data = res.data;
                console.log("Get data");
                console.log(data)
                if(data.status=="1")
                {
                    this.setState(
                        { 
                            show:true,
                            Color:"success",
                            Message:data.message ,
                            Fade:true
                        });
                    
                    }
                else
                {
                    this.setState(
                        { 
                            show:true,
                            Color:"danger",
                            Message:data.message,
                            Fade:true
                        });
                }
            })
        
    }

    render() {
        

        return(
            <div>
                <Banner />
                    <Container className="mt-5">
                        <Card>
                            <Card.Header>Create Tax</Card.Header>
                            <Card.Body>
                                <Form>
                                <Alert color="success" isOpen={this.state.show} color={this.state.Color} fade={this.state.Fade}>
                                        {this.state.Message}
                                    </Alert>                             
                                    <Form.Row>
                                        <Col sm={6} className="mt-3">
                                            <Typeahead
                                                labelKey='name'
                                                id="basic-example"
                                                onChange={this.handleCoverChangeTaxRate}
                                                options={this.state.dataReferenceList}
                                                placeholder="Tax Rate"
                                            /> 
                                        </Col>

                                    </Form.Row>
                                    <Form.Row>
                                        <Col sm={6} className="mt-3">
                                            <Form.Control 
                                                type="text"
                                                name="salaryFrom"
                                                value={this.state.salaryFrom}
                                                placeholder="Salary From"
                                                onChange={this.onChangeSalaryFrom.bind(this)} 
                                                autoComplete="off"
                                            />
                                        </Col>
                                        <Col sm={6} className="mt-3">
                                            <Form.Control 
                                                type="text"
                                                name="salaryTo"
                                                value={this.state.salaryTo}
                                                placeholder="Salary To"
                                                onChange={this.onChangeSalaryTo.bind(this)} 
                                                autoComplete="off"
                                            />
                                        </Col>
                                        <Col sm={6} className="mt-3">
                                            <Form.Control 
                                                type="text"
                                                name="percentage"
                                                value={this.state.percentage}
                                                placeholder="Percentage"
                                                onChange={this.onChangePercentage.bind(this)} 
                                                autoComplete="off"
                                            />
                                        </Col>
                                        <Col sm={6} className="mt-3">
                                            <Form.Control 
                                                type="text"
                                                name="taxdue"
                                                value={this.state.taxdue}
                                                placeholder="Tax Due"
                                                onChange={this.onChangeTaxdue.bind(this)} 
                                                autoComplete="off"
                                            />
                                        </Col>
                                    </Form.Row>
                                    <ButtonToolbar sm={12} className="mt-3">
                                        <Button variant="primary"  onClick={this.handleSaveClick}>
                                            Submit
                                        </Button>&nbsp;&nbsp;
                                        <Button variant="danger" href="/Tax">
                                            Back
                                        </Button>
                                    </ButtonToolbar>
                                </Form>
                            </Card.Body>
                        </Card>
                    </Container>
            </div> 
            
        )
    }
}

export default TaxCreate;