import {
    React,Component,BootstrapTable,
    paginationFactory,Button, ButtonToolbar, Card, 
    Form, Col, Row, axios, Container, Banner, Typeahead, TimePicker,
    cellEditFactory, Type, moment, NavLink, Alert, AppConfiguration,
    NoserLoading, Noser, sizePerPageRenderer, DatePicker, Modal, Tabs, Tab
} 
from '../../../noser-hris-component';

class PayrollFileModal extends Component {
    constructor(props) {
        super(props)
        this.module = React.createRef();
        this.submodule = React.createRef();
        this.state = {
            userinfo    :   JSON.parse(sessionStorage.getItem("userData")),
            isloading   :   false,
            isshow      :   false,
            alerttype   :   "",
            message     :   "",
            color       :   "",
            fade        :   true,
            
            clientId    :   '',
            clientName  :   '',
            periodId    :   '',
            periodName  :   '',
            isHold      :   '1',

            bdoBatchNo	:   "",
            mlBatchNo	:   "",
            mbBatchNo	:   "",
            palawanBatchNo: "",
            ubBatchNo	:   "",

            employeeDDL :   [],
            employeeGRID:   [],

            bdoGRID     :   [],
            mlGRID      :   [],
            mbGRID      :   [],
            palawanGRID :   [],
            ubGRID      :   [],

            disablesubmit:   false
        }
    }
    componentDidMount(){
        this.state.userinfo = JSON.parse(sessionStorage.getItem("userData"))
        this.props.onRefModal(this)
    }
    componentWillUnmount() {
        this.props.onRefModal(undefined)
    }
    initialize=(e)=>{
        this.setState({
            userinfo    :   JSON.parse(sessionStorage.getItem("userData")),
            isloading   :   true,
            isshow      :   false,
            alerttype   :   "",
            message     :   "",
            color       :   "",
            fade        :   true,
            
            clientId    :   e.clientId,
            clientName  :   e.clientName,
            periodId    :   e.periodId,
            periodName  :   e.periodName,
            isHold      :   '1',

            bdoBatchNo	:   e.bdoBatchNo,
            mlBatchNo	:   e.mlBatchNo,
            mbBatchNo	:   e.mbBatchNo,
            palawanBatchNo: e.palawanBatchNo,
            ubBatchNo	:   e.ubBatchNo,

            employeeDDL :   [],
            employeeGRID:   [],

            bdoGRID     :   [],
            mlGRID      :   [],
            mbGRID      :   [],
            palawanGRID :   [],
            ubGRID      :   [],

            disablesubmit:   false
        })
        this.GetPayrollFiles(e)
    }
    GetPayrollFiles = async(e) =>{
        this.setState({isloading:true})

        const params = {
            "IpAddress"     :   "0.0.0.0",
            "ClientId"      :   e.clientId,
            "UserId"        :   this.state.userinfo.userId,
            "PayPeriodId"    :  e.periodId,
        }
        await axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Accounting/GetEmployeeForPayrollFiles", params)
        .then(res => {
            const data = res.data
            this.setState({
                employeeDDL :   data.employeePayrolls,
                employeeGRID:   data.employeePayrolls,
                bdoGRID     :   data.employeePayrolls.filter(x=>x.payCardTypeId==="2"),
                mlGRID      :   data.employeePayrolls.filter(x=>x.payCardTypeId==="4"),
                mbGRID      :   data.employeePayrolls.filter(x=>x.payCardTypeId==="6"),
                palawanGRID :   data.employeePayrolls.filter(x=>x.payCardTypeId==="15"),
                ubGRID      :   data.employeePayrolls.filter(x=>x.payCardTypeId==="14"),
                isloading   :   false,
            })
            if(data.status=="0"){
                this.setState({
                    isloading       :   false,
                    alerttype       :   "Error!",
                    isshow          :   true,
                    color           :   "danger",
                    message         :   data.message,
                    fade            :   true
                })
            }
        })
        .catch(error=>{
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade        :   true
            })
        })
    }
    handleEmployeeChanged = (e) =>{
        if(e.length == 0) {
            this.setState({
                bdoGRID     :   this.state.employeeGRID.filter(x=>x.payCardTypeId==="2"),
                mlGRID      :   this.state.employeeGRID.filter(x=>x.payCardTypeId==="4"),
                mbGRID      :   this.state.employeeGRID.filter(x=>x.payCardTypeId==="6"),
                palawanGRID :   this.state.employeeGRID.filter(x=>x.payCardTypeId==="15"),
                ubGRID      :   this.state.employeeGRID.filter(x=>x.payCardTypeId==="14"),
            })
            return
        } 
        if(e[0].payCardTypeId==="2"){
            this.setState({
                bdoGRID     :   this.state.bdoGRID.filter(x=>x.id===e[0].id),
            })
        }
        if(e[0].payCardTypeId==="4"){
            this.setState({
                mlGRID     :   this.state.mlGRID.filter(x=>x.id===e[0].id),
            })
        }
        if(e[0].payCardTypeId==="6"){
            this.setState({
                mbGRID     :   this.state.mbGRID.filter(x=>x.id===e[0].id),
            })
        }
        if(e[0].payCardTypeId==="14"){
            this.setState({
                ubGRID     :   this.state.ubGRID.filter(x=>x.id===e[0].id),
            })
        }
        if(e[0].payCardTypeId==="15"){
            this.setState({
                palawanGRID     :   this.state.palawanGRID.filter(x=>x.id===e[0].id),
            })
        }
    } 
    handleSubmit = async(e) =>{
        this.setState({isloading:true,disablesubmit:true})

        if(this.state.bdoGRID.filter(x=>x.isModified==="1").length>0 && this.state.bdoBatchNo===""){
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "Please enter BDO batch no.",
                fade        :   true,
                disablesubmit:  false
            })
            return 
        }

        if(this.state.mlGRID.filter(x=>x.isModified==="1").length>0 && this.state.mlBatchNo===""){
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "Please enter ML batch no.",
                fade        :   true,
                disablesubmit:  false
            })
            return 
        }

        if(this.state.mbGRID.filter(x=>x.isModified==="1").length>0 && this.state.mbBatchNo===""){
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "Please enter Metro Bank batch no.",
                fade        :   true,
                disablesubmit:  false
            })
            return 
        }

        if(this.state.ubGRID.filter(x=>x.isModified==="1").length>0 && this.state.ubBatchNo===""){
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "Please enter Union Bank batch no.",
                fade        :   true,
                disablesubmit:  false
            })
            return 
        }

        if(this.state.palawanGRID.filter(x=>x.isModified==="1").length>0 && this.state.palawanBatchNo===""){
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "Please enter Palawan batch no.",
                fade        :   true,
                disablesubmit:  false
            })
            return 
        }
        
        let employeePayrolls = []

        this.state.bdoGRID.filter(x=>x.isModified==="1").map(function(itm,idx){
            employeePayrolls.push(itm)
        })
        this.state.mlGRID.filter(x=>x.isModified==="1").map(function(itm,idx){
            employeePayrolls.push(itm)
        })
        this.state.mbGRID.filter(x=>x.isModified==="1").map(function(itm,idx){
            employeePayrolls.push(itm)
        })
        this.state.ubGRID.filter(x=>x.isModified==="1").map(function(itm,idx){
            employeePayrolls.push(itm)
        })
        this.state.palawanGRID.filter(x=>x.isModified==="1").map(function(itm,idx){
            employeePayrolls.push(itm)
        })
        const param = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.clientId,
            "UserId":this.state.userinfo.userId,
            "PayPeriodId":this.state.periodId,
            "BDOBatchNumber"        :   this.state.bdoBatchNo,
            "MLBatchNumber"         :   this.state.mlBatchNo,
            "MetroBankBatchNumber"  :   this.state.mbBatchNo,
            "PalawanBatchNumber"    :   this.state.palawanBatchNo,
            "UBBatchNumber"         :   this.state.ubBatchNo,
            "employeePayrolls": employeePayrolls,
            "IsHold":"1"
        }
        
        await axios
            .post(
                AppConfiguration.Setting().noserapiendpoint + "Accounting/ProcessPayrollFiles",  param
            )
            .then(res => {
                const data = res.data;
                this.setState({
                    isloading   :   false,
                    alerttype   :   res.data.status=="1" ? "Success!" : "!Error",
                    isshow      :   true,
                    color       :   res.data.status=="1" ? "success" : "danger",
                    message     :   data.message,
                    fade        :   true
                });
            })
            .catch(error=>{
                this.setState({
                    isloading   :   false,
                    alerttype   :   "Error!",
                    isshow      :   true,
                    color       :   "danger",
                    message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                    fade        :   true
                })
            })
    }
    handleChangedBDO = (e) => {
        this.setState({
            bdoBatchNo	:   e.target.value
        })
    }

    handleChangedML = (e) => {
        this.setState({
            mlBatchNo	:   e.target.value
        })
    }

    handleChangedMB = (e) => {
        this.setState({
            mbBatchNo	:   e.target.value
        })
    }

    handleChangedPalawan = (e) => {
        this.setState({
            palawanBatchNo	:   e.target.value
        })
    }

    handleChangedUB = (e) => {
        this.setState({
            ubBatchNo	:   e.target.value
        })
    }
    handleModalClose = () => {
        this.props.onHide();            
    }
    render() {
        const colGrid = [
            {
                text        :   "Member Name",
                editable    :   false,
                dataField   :   "memberName",
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'left',width:'85%' }},
                style:{textAlign:'left',width:'85%'},
            },
            {
                text        :   "Net Pay",
                editable    :   false,
                dataField   :   "actualNetPay",
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'left',width:'15%' }},
                style:{textAlign:'left',width:'15%'},
            },
        ]
        const selectRowBDO = {
            mode: 'checkbox',
            hideSelectAll: false,
            clickToSelectAndEditCell: true,
            onSelect: (row, isSelect, rowIndex, e) => {
                this.state.bdoGRID.map(function(item,i){
                    if(item.id===row.id)
                    {
                        item.isModified = isSelect ? "1" : "0"
                    }
                })
            }
        };
        const selectRowML = {
            mode: 'checkbox',
            hideSelectAll: false,
            clickToSelectAndEditCell: true,
            onSelect: (row, isSelect, rowIndex, e) => {
                this.state.mlGRID.map(function(item,i){
                    if(item.id===row.id)
                    {
                        item.isModified = isSelect ? "1" : "0"
                    }
                })
            }
        };
        const selectRowMB = {
            mode: 'checkbox',
            hideSelectAll: false,
            clickToSelectAndEditCell: true,
            onSelect: (row, isSelect, rowIndex, e) => {
                this.state.mbGRID.map(function(item,i){
                    if(item.id===row.id)
                    {
                        item.isModified = isSelect ? "1" : "0"
                    }
                })
            }
        };
        const selectRowPalawan = {
            mode: 'checkbox',
            hideSelectAll: false,
            clickToSelectAndEditCell: true,
            onSelect: (row, isSelect, rowIndex, e) => {
                this.state.palawanGRID.map(function(item,i){
                    if(item.id===row.id)
                    {
                        item.isModified = isSelect ? "1" : "0"
                    }
                })
            }
        };
        const selectRowUB = {
            mode: 'checkbox',
            hideSelectAll: false,
            clickToSelectAndEditCell: true,
            onSelect: (row, isSelect, rowIndex, e) => {
                this.state.ubGRID.map(function(item,i){
                    if(item.id===row.id)
                    {
                        item.isModified = isSelect ? "1" : "0"
                    }
                })
            }
        };
    return(
        
            <Modal
                {...this.props}
                return
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                backdrop="static"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        GENERATE HOLD PAYROLL FILE
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container>
                        <Alert isOpen={this.state.isshow} color={this.state.color} fade={this.state.fade} className={this.state.isshow ? 'display-block' : 'display-none'}>
                            <div className={this.state.color}></div> 
                            {this.state.message}
                        </Alert>
                        <Form>
                        <Form.Row>
                            <Form.Group as={Col} sm={6} >
                                <Form.Control 
                                    name='clientName'
                                    type="text" 
                                    value={this.state.clientName}
                                    disabled
                                />
                            </Form.Group>
                            <Form.Group as={Col} sm={6} >
                                <Form.Control 
                                    name='periodName'
                                    type="text" 
                                    value={this.state.periodName}
                                    disabled
                                />
                            </Form.Group>
                        </Form.Row>
                        <Form.Row>
                            <Form.Group as={Col} sm={6} >
                                <Typeahead
                                    labelKey='memberName'
                                    id="basic-example"
                                    onChange={this.handleEmployeeChanged}
                                    options={this.state.employeeDDL}
                                    placeholder="Select Employee"
                                />
                            </Form.Group>
                            <Form.Group as={Col} sm={6} >
                                <Form.Control 
                                    name='bdoBatchNo'
                                    type="text" 
                                    value={this.state.bdoBatchNo}
                                    placeholder="BDO Batch No"
                                    onChange={this.handleChangedBDO}
                                    autoComplete="off"
                                />
                            </Form.Group>
                        </Form.Row>
                        <Form.Row>
                            <Form.Group as={Col} sm={6} >
                                <Form.Control 
                                    name='mlBatchNo'
                                    type="text" 
                                    value={this.state.mlBatchNo}
                                    placeholder="ML Batch No"
                                    onChange={this.handleChangedML}
                                    autoComplete="off"
                                />
                            </Form.Group>
                            <Form.Group as={Col} sm={6} >
                                <Form.Control 
                                    name='mbBatchNo'
                                    type="text" 
                                    value={this.state.mbBatchNo}
                                    placeholder="METRO BANK Batch No"
                                    onChange={this.handleChangedMB}
                                    autoComplete="off"
                                />
                            </Form.Group>
                        </Form.Row>
                        <Form.Row>
                            <Form.Group as={Col} sm={6} >
                                <Form.Control 
                                    name='palawanBatchNo'
                                    type="text" 
                                    value={this.state.palawanBatchNo}
                                    placeholder="PALAWAN Batch No"
                                    onChange={this.handleChangedPalawan}
                                    autoComplete="off"
                                />
                            </Form.Group>
                            <Form.Group as={Col} sm={6} >
                                <Form.Control 
                                    name='ubBatchNo'
                                    type="text" 
                                    value={this.state.ubBatchNo}
                                    placeholder="UB Batch No"
                                    onChange={this.handleChangedUB}
                                    autoComplete="off"
                                />
                            </Form.Group>
                        </Form.Row>
                        </Form>
                        <Card>
                        <Card.Body style={{border:"none !important"}}>
                        <Tabs defaultActiveKey="default" transition={false} id="noanim-tab-example">
                            <Tab eventKey="default" title="BDO">
                                <Form.Row>
                                    <Form.Group controlId="formGridPassword" as={Col}>
                                        <Card className="card-tab-no-border">
                                            <div className="card-header-tab"></div>
                                            <BootstrapTable
                                                keyField = "id"
                                                data = { this.state.bdoGRID }
                                                columns = { colGrid }
                                                striped
                                                hover
                                                condensed
                                                selectRow={selectRowBDO}
                                                noDataIndication={ () => <div>No record found.</div> }
                                            />
                                        </Card>
                                    </Form.Group>
                                </Form.Row>
                            </Tab>

                            <Tab eventKey="metrobank" title="METROBANK">
                                <Form.Row>
                                    <Form.Group controlId="formGridPassword" as={Col}>
                                        <Card className="card-tab-no-border">
                                            <div className="card-header-tab"></div>
                                            <BootstrapTable
                                                keyField = "id"
                                                data = { this.state.mbGRID }
                                                columns = { colGrid }
                                                striped
                                                hover
                                                condensed
                                                selectRow={selectRowMB}
                                                noDataIndication={ () => <div>No record found.</div> }
                                            />
                                        </Card>
                                    </Form.Group>
                                </Form.Row>
                            </Tab>

                            <Tab eventKey="ml" title="ML">
                                <Form.Row>
                                    <Form.Group controlId="formGridPassword" as={Col}>
                                        <Card className="card-tab-no-border">
                                            <div className="card-header-tab"></div>
                                            <BootstrapTable
                                                keyField = "id"
                                                data = { this.state.mlGRID }
                                                columns = { colGrid }
                                                striped
                                                hover
                                                condensed
                                                selectRow={selectRowML}
                                                noDataIndication={ () => <div>No record found.</div> }
                                            />
                                        </Card>
                                    </Form.Group>
                                </Form.Row>
                            </Tab>

                            <Tab eventKey="palawan" title="PALAWAN">
                                <Form.Row>
                                    <Form.Group controlId="formGridPassword" as={Col}>
                                        <Card className="card-tab-no-border">
                                            <div className="card-header-tab"></div>
                                            <BootstrapTable
                                                keyField = "id"
                                                data = { this.state.palawanGRID }
                                                /* data = { this.state.mlListData } */
                                                columns = { colGrid }
                                                striped
                                                hover
                                                condensed
                                                selectRow={selectRowPalawan}
                                                noDataIndication={ () => <div>No record found.</div> }
                                            />
                                        </Card>
                                    </Form.Group>
                                </Form.Row>
                            </Tab>

                            <Tab eventKey="ub" title="UB">
                                <Form.Row>
                                    <Form.Group controlId="formGridPassword" as={Col}>
                                        <Card className="card-tab-no-border">
                                            <div className="card-header-tab"></div>
                                            <BootstrapTable
                                                keyField = "id"
                                                data = { this.state.ubGRID }
                                                columns = { colGrid }
                                                striped
                                                hover
                                                condensed
                                                selectRow={selectRowUB}
                                                noDataIndication={ () => <div>No record found.</div> }
                                            />
                                        </Card>
                                    </Form.Group>
                                </Form.Row>
                            </Tab>
                        </Tabs>
                        </Card.Body>
                        </Card>
                    </Container>
                </Modal.Body>
                <Modal.Footer>
                    <ButtonToolbar >
                        <Button variant="success" className="ml-auto noser-button-mr1" onClick = { this.handleSubmit } disabled={this.state.disablesubmit}>GENERATE</Button>
                        <Button variant="danger" className='noser-button-mr15' onClick={this.props.onHide}>Close</Button>
                    </ButtonToolbar>
                </Modal.Footer>
                <NoserLoading show={this.state.isloading} />
        </Modal>
        );
    }
}
export default PayrollFileModal