import {
    React,Component,BootstrapTable,
    paginationFactory,Button, ButtonToolbar, Card, 
    Form, Col, Row, axios, Container, Banner, Typeahead, TimePicker,
    cellEditFactory, Type, moment, NavLink, Alert, AppConfiguration,
    NoserLoading, Noser, sizePerPageRenderer, DatePicker, Modal
} 
from '../../../noser-hris-component';

const showSecond = false;
const str = showSecond ? 'HH:mm:ss' : 'HH:mm';

class TraningCreateList extends Component {
    constructor(props) {
        super(props)
        this.ref = React.createRef();
        this.state = {
            userinfo    :   JSON.parse(sessionStorage.getItem("userData")),
            isloading   :   false,
            isshow      :   false,
            alerttype   :   "",
            message     :   "",
            color       :   "",
            fade        :   true,
            id                          :   "",          
            dateFrom                    :   "",
            dateTo                      :   "",
            venue                       :   "",
            facilitated                 :   "",
            selectedTrainingId          :   "",
            trainingAutocomplete        :   [],
            description                 :   "",
            facilitator                 :   "",
            batchNo                     :   "",
            participantLimit            :   "",
            checkIsRequired             :   "",
            partNo                      :   "",   
            platform                    :   "", 
            TrainingGrid                : [],
            trainingScheduleTableList   :  [], 
            
            trainingId      :   "",
            scheduleId      :   "",

            platformDDL  :    [
                {"name": 'FACE TO FACE ',  "value": "1",},
                {"name":"ONLINE VIA ZOOM	",       "value":"2"},
            ],
            datenow: new Date(),
        }
    }
    componentDidMount(){
        this.state.userinfo = JSON.parse(sessionStorage.getItem("userData"))
        // console.log(this.state.userinfo)
        this.props.onRefModal(this)
        this.getTraining()
    }
    componentWillUnmount() {
        this.props.onRefModal(undefined)
        
        
        
    }
    initialize=(e)=>{
        // console.log("modal")
        // console.log(e)
        this.setState({
            userinfo    :   JSON.parse(sessionStorage.getItem("userData")),
            isloading   :   false,
            isshow      :   false,
            alerttype   :   "",
            message     :   "",
            color       :   "",
            fade        :   true,
            name        :   '',
            id          :  e.id,

            
            profileId       :   e.profileId,
            employeeName    :   e.employeeName,
            employeeNo      :   e.employeeNo,
            clientName      :   e.client,
            locationName    :   e.locationName,
            positionName    :   e.position,
            trainingId      :   e.trainingId,
            scheduleId      :   e.scheduleId,
            trainId  : e.id
            
        })
        // console.log("e.trainingId")
        // console.log(e)
        // console.log("e.scheduleId")
        // console.log(e.trainingId)
        
        this.getTraining(e.id)
    }
    
    handleModalClose = (e) => {
        // 
        if ( this.state.trainingScheduleTableList.length === "0" ){
            this.props.onHide(e);    
        } else {
            this.props.onHide(this.state.trainingScheduleTableList.filter(x=>x.isSelect==="1"))
        }     
        // console.log(e)   
    }

    handleSelectClick = () =>{

    }

    getTraining(id, scheduleId, trainingId) {
        this.setState({isloading:true})
        let dateFrom    =   moment(this.state.dateFrom).format('MM/DD/YYYY');
        let dateTo      =   moment(this.state.dateTo).format('MM/DD/YYYY');
        // this.setState({isloading:true})

        const params = {
            "IpAddress"     :   "0.0.0.0",
            "UserId"        :   this.state.userinfo.userId,
            "ClientId"      :   this.state.userinfo.clientId,
            "PositionId"    :   "",
            "TrainingId"    :   trainingId,
            // "ScheduleId"    :   scheduleId,
        };

        // console.log("params123")
        // console.log(params)

        axios
            // .post(AppConfiguration.Setting().noserapiendpoint + "Training/ReScheduleEmployeeTraining",  params)
            .post(AppConfiguration.Setting().noserapiendpoint + "Training/GetTrainingSchedules",  params)
            .then(res => {
                const data = res.data;
                // // console.log("Training/ReScheduleEmployeeTraining")
                // // console.log(data)
                let filterTraining = data.trainingSchedules.filter( x => x.trainingId === this.state.trainingId )
                // console.log("filterTraining")
                // console.log(filterTraining)
                let dateTmp = moment(this.state.datenow).format('MM/DD/YYYY')
                let formatDate = filterTraining.filter( x => x.fromDate >= dateTmp) 
                console.log("formatDate")
                console.log(formatDate)
                this.setState({ trainingScheduleTableList     :  formatDate, isloading : false })
                if(data.status=="0"){
                    this.setState({
                        isloading   :   false,
                        alerttype   :   "Error!",
                        isshow      :   true,
                        color       :   "danger",
                        message     :   data.message,
                        fade        :   true
                    });
                }
                if(formatDate.length === 0){
                    this.setState({
                        isloading   :   false,
                        alerttype   :   "Error!",
                        isshow      :   true,
                        color       :   "danger",
                        message     :   "Please create schedule!.",
                        fade        :   true
                    });
                }
                    
            })
            .catch(error=>{
                this.setState({
                    isloading       :   false,
                    alerttype       :   "Error!",
                    isshow          :   true,
                    color           :   "danger",
                    message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                    fade            :   true
                })
            })
    }

    handleSubmit = () =>{
        this.setState({isloading:true})
        this.state.trainingScheduleTableList.forEach(itm => {
            if(itm.isSelected === "1"){
                // console.log("trainingId")
                // console.log(itm.id)
                const params = {
                    "IpAddress"     :   "0.0.0.0",
                    "UserId"        :   this.state.userinfo.userId,
                    "ClientId"      :   this.state.userinfo.clientId,
                    "PositionId"    :   "",
                    // "TrainingId"    :  "3",
                    // "ScheduleId"    :  "3"
                    "TrainingId"    :   this.state.trainId,
                    "ScheduleId"    :   itm.id,
                };
        
                // console.log("params123654")
                // console.log(params)
                axios
                .post(AppConfiguration.Setting().noserapiendpoint + "Training/ReScheduleEmployeeTraining",  params)
                .then(res => {
                    const data = res.data;
                    // console.log("Training/ReScheduleEmployeeTraining")
                    // console.log(data)
            
                    if (data.status=="0") {
                        this.setState({
                            isloading   :   false,
                            alerttype   :   "Error!",
                            isshow      :   true,
                            color       :   "danger",
                            message     :   data.message,
                            fade        :   true
                        });
                    
                    } else {
                        this.setState({
                            isloading   :   false,
                            alerttype   :   "Success",
                            isshow      :   true,
                            color       :   "success",
                            message     :   data.message,
                            fade        :   true
                        });
                    }
                        
                })
                .catch(error=>{
                    this.setState({
                        isloading       :   false,
                        alerttype       :   "Error!",
                        isshow          :   true,
                        color           :   "danger",
                        message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                        fade            :   true
                    })
                })
                    
            }
        })
    }

    
    LeavePageWithourSavingChanges() {
        const isChanged=false
        for (let i = 0; i < this.state.trainingScheduleTableList.length; i++) {
            if (this.state.trainingScheduleTableList[i]["isSelect"] == 1) {
                this.setState({isGridDataChanged: true})
                isChanged=true
                break;
            }
        }
        return isChanged
    }


    GridDataModified(oldValue, newValue, id, column) {
        ////// console.log(id)
        this.state.trainingScheduleTableList.map(function(item,i) {
            if (item.id===id)
                item.isModified = newValue!=oldValue ? "1" : "0"
        })
    }


    render() {
        const trainingColumn = [
            {
                dataField: 'training',
                text: 'Training',
                editable : false,
                headerStyle : () => {
                    return { width  : "20%" };
                }
            },
            {
                dataField   : 'fromDate',
                text        : 'Date From',
                editable : false,
                headerStyle : () => {
                    return { width  : "10%" };
                }
            },
            {
                dataField   : 'toDate',
                text        : 'Date To',
                editable : false,
                headerStyle : () => {
                    return { width  : "10%" };
                }
            },
            {
                dataField   : 'fromTime',
                text        : 'Time From',
                editable : false,
                headerStyle : () => {
                    return { width  : "10%" };
                }
            },
            {
                dataField   : 'toTime',
                text        : 'Time To',
                editable : false,
                headerStyle : () => {
                    return { width  : "10%" };
                }
            },
            {
                dataField   : 'venue',
                text        : 'Venue',
                editable : false,
                headerStyle : () => {
                    return { width  : "10%" };
                }
            },
            {
                dataField   : 'facilitator',
                text        : 'Facilitator',
                editable : false,
                headerStyle : () => {
                    return { width  : "10%" };
                },
                
            },
            {
                dataField   : 'batchNo',
                text        : 'Batch No.',
                editable : false,
                headerStyle : () => {
                    return { width  : "10%" };
                }
            },
            {
                dataField   : 'participantLimit',
                text        : 'Limit No. of Participants',
                editable : false,
                headerStyle : () => {
                    return { width  : "10%" };
                }
            },
            
        ]

        const selectRow = {
            mode: 'radio',
            clickToSelectAndEditCell: true,
            onSelect: (row, isSelect, rowIndex, e) => {
                this.state.trainingScheduleTableList.map(function(item,i){
                    if(item.id===row.id)
                        {
                       // item.isDeleted = isSelect ? "1" : "0"
                        item.isSelected = isSelect ? "1" : "0"
                        // console.log(isSelect + " " + item.id)
                        }
                    // console.log(item.id)
                })

            }
        };

        const rowEvents = {
            onClick: (e, row, rowIndex) => {

            }
        };
    return(
        
            <Modal
                {...this.props}
                return
                size="xl"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                backdrop="static"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        TRAINING List
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container>
                        <Alert isOpen={this.state.isshow} color={this.state.color} fade={this.state.fade} className={this.state.isshow ? 'display-block' : 'display-none'}>
                            <div className={this.state.color}></div> 
                            {this.state.message}
                        </Alert>
                        <Form.Row>
                            <Form.Group as={Col}  >
                                <div className="mt-1">
                                    <BootstrapTable
                                        /* caption={Noser.TableHeader({title:"RECORD"})} */
                                        keyField = "id"
                                        data = { this.state.trainingScheduleTableList }
                                        columns = { trainingColumn }
                                        pagination={ paginationFactory({sizePerPageRenderer}) }
                                        noDataIndication={ () => <div style={{width:'100%'}}>No record found.</div> }
                                        rowClasses="noser-table-row-class"
                                        striped
                                        hover
                                        condensed
                                        cellEdit = { cellEditFactory({
                                            mode: 'dbclick',
                                            blurToSave: true,
                                            afterSaveCell: (oldValue, newValue, row, column) => {
                                                this.GridDataModified(oldValue, newValue, row.id, column.dataField)
                                                }
                                            })
                                        }
                                        rowEvents={ rowEvents }
                                        selectRow = { selectRow }

                                    />
                                </div>
    

                                <ButtonToolbar sm={12} className="mt-3">
                                    <Button variant="success" className="ml-auto" onClick={ this.handleSubmit }>
                                        Submit
                                    </Button>&nbsp;&nbsp;
                                    <Button variant="danger" onClick={() => this.handleModalClose('0')}>
                                        Close
                                    </Button>
                                </ButtonToolbar>
                            </Form.Group>
                            
                        </Form.Row>
                    </Container>
                </Modal.Body>
                <Modal.Footer>
                    
                </Modal.Footer>
                <NoserLoading show={this.state.isloading} />
        </Modal>
        );
    }
}
export default TraningCreateList