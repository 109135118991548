import {
    React,Component,BootstrapTable,
    paginationFactory,Button, ButtonToolbar, Card, 
    Form, Col, Row, axios, Typeahead, TimePicker,
    cellEditFactory ,moment, NavLink, Alert, AppConfiguration,
    NoserLoading, Noser, Container, Modal, DatePicker,Dropdown, DropdownButton, props, sizePerPageRenderer
} 
from '../../noser-hris-component';

import ToolkitProvider, { CSVExport } from 'react-bootstrap-table2-toolkit';
import 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css';
import { BsFileEarmarkPlus } from "react-icons/bs";
import { resolve } from 'q';



class CreateCaseModal extends Component {
    constructor(props) {
        super(props)
        this.state ={
            userinfo        :   [],
            
            isloading       :   false,
            isshow          :   false,
            alerttype       :   "",
            message         :   "",
            color           :   "",
            fade            :   true,

            type            :   "",
            title           :   "",
            status          :   "",
            description     :   "",
            reply           :   "",  
            image           :   [],
            attachment      :   [],
            caseId          :   "",
            users           :   "",
            priorityLevel   :   "",
            deptAccess      :   "",
            employeeId      :   "",
            totalDays       :   "",
            createddate	    :   new Date(),
            valueKey        :   "",
            userId          :   "",
            roleId          :   "",
            tmpImage        :   "",
            fullName        :   "",
            concernId       :   '',

            valueType       :   "",
            valueTitle      :   "",
            valueDescript   :   "",
            valueStatus     :   "",
            valuePriolvl    :   "",
            valueDeptAccess :   "",
            disabled        : true,
			UpdateDate		: "",
            //idList          :   "",
			userUpdate		: "",
			// employeeId		: "",
			createdUser		: "",
			
            


            trainingRegisterTableList : [],
            unKnownData : [],
            createValue: "",
            updateValue: "",
            forUpdate:""
        }
        this.onImageChange = this.onImageChange.bind(this);
    }

    componentDidMount(){
        this.state.userinfo = JSON.parse(sessionStorage.getItem("userData")) 
       console.log(this.state.userinfo)
        this.setState({
            employeeId: this.state.userinfo.employeeId,
            roleId: this.state.userinfo.roleId,
            userId: this.state.userinfo.userId,
            userFirstName: this.state.userinfo.firstName,
            fullName: this.state.userinfo.firstName,
        })
        this.itemcheck();
        this.props.onRefCaseModal(this)
    };

    componentWillUnmount() {
        this.props.onRefCaseModal(undefined)
    };

    initialize=(e)=> {

        let ValueOfZero = e.createKey

        this.state.createValue = ValueOfZero
        //this.setState({createValue: this.state.createValue})

        let ValueOfOne = e.updateKey

        this.state.updateValue = ValueOfOne
        //this.setState({updateValue: this.state.updateValue})

        if(this.state.createValue === "0"){
            this.setState({ header: "Create Case"})
        };

        if(this.state.updateValue === "1"){
            this.setState({ header: "Update Case"})
        };

        this.itemcheck(ValueOfOne, ValueOfZero);
    };

    onModalClose = () => {
        this.props.onHide("Hello Parent! It's your turn parent"); 
        this.setState({isshow: false,})
    };

    itemcheck (ValueOfOne){
        if(ValueOfOne === "1"){
            this.state.viewConcernDataInfo = JSON.parse(sessionStorage.getItem("viewConcernData"))
			console.log(this.state.viewConcernDataInfo)
            this.setState({
                priorityLevel: this.state.viewConcernDataInfo.priorityLevel,
                deptAccess: this.state.viewConcernDataInfo.deptAccess,
                status: this.state.viewConcernDataInfo.status,
                type: this.state.viewConcernDataInfo.type,
                title: this.state.viewConcernDataInfo.title,
                description: this.state.viewConcernDataInfo.description,
                UpdateDate: this.state.viewConcernDataInfo.createddate,
                createdUser: this.state.viewConcernDataInfo.createdby,
                employeeId: this.state.viewConcernDataInfo.employeeId,
                caseId: this.state.viewConcernDataInfo.id,
                userUpdate: this.state.viewConcernDataInfo.user,
            })
        }
    }
    /************ onchange items ****************************/
    

    onChangeType = (e) =>{
        /* if(e.length == 0){
            this.state.type = ""
            return
        } */
        //this.state.type = e[0].type
        //console.log(e[0].type)
        if(this.state.roleId == '1' || this.state.roleId == '27'){
            this.setState({ type: this.state.type })
        } else {
            this.setState({ type: e[0].type})
        }
    };

    onChangeStatus= (e) =>{
        if(e.length == 0){
            this.state.status = ""
            return
        }
        this.state.status = e[0].Status
      // console.log(this.state.status + " :selectedStatus")
       
    };

    onChangePrioLevel= (e) =>{
        if(e.length == 0){
            this.priorityLevel = ""
            return
        }
        this.state.priorityLevel = e[0].prioLevel
       //console.log(this.state.priorityLevel + " :selectedStatus")
    };

    onChangeDeptAccess= (e) =>{
        if(e.length == 0){
            this.deptAccess = ""
            return
        }
        this.state.deptAccess = e[0].DeptAccess
      // console.log(this.state.deptAccess + " :selectedStatus")
    };

    onChangeTitle = (e) => {
       //this.setState({title: e.target.value})
       if(this.state.roleId === "1" || this.state.roleId === "27"){
           this.setState({title: this.state.title})
           //console.log(this.state.title)
       } else {
           this.setState({title: e.target.value})
       }
      
    };

    onChangeDescription = (e) => {
       if(this.state.roleId === "1" || this.state.roleId === "27"){
        this.setState({description: this.state.description})
        //console.log(this.state.description)
        } else {
        this.setState({description: e.target.value})
        }
    };
    
    /************ end onchange items *************************/

    /************ update items *******************************/

    handleKeyDown = (e) => {
        
        if (e.key === 'Enter') {
            this.submitDataInfo(e);
        }

    }
    submitDataInfo = async(e)=>{
        
        this.setState({isloading: true})
        
       let userFullName = this.state.userinfo.fullName
       let userId = this.state.userinfo.userId
       
        /* if(this.state.type=="")
        {
            this.setState({
                isloading       :   false,
                alerttype       :   "Warning!",
                isshow          :   true,
                color           :   "warning",
                message         :   "Please enter Type.",
                fade            :   true
            })
            return
        }
        
        if(this.state.title=="")
        {
            this.setState({
                isloading       :   false,
                alerttype       :   "Warning!",
                isshow          :   true,
                color           :   "warning",
                message         :   "Please enter Title.",
                fade            :   true
            })
            return
        }
        if(this.state.description=="")
        {
            this.setState({
                isloading       :   false,
                alerttype       :   "Warning!",
                isshow          :   true,
                color           :   "warning",
                message         :   "Please enter Description.",
                fade            :   true
            })
            return
        } */

        if (this.state.createValue === "0") {
            let data1 ={
            
                "type"          : this.state.type,
                "title"         : this.state.title,
                "description"   : this.state.description,
                "reply"         : "",  
                "user"          : userFullName,
                "status"        : this.state.status,
                "priorityLevel" : this.state.priorityLevel,
                "employeeId"    : this.state.employeeId,
                "deptAccess"    : this.state.deptAccess,
                "isDeleted"     : "0",
                "isDraft"       : "0",
                "createdby" 	: this.state.employeeId,
                "createddate"   : this.formatDate(new Date()),
                "modifiedby"    : userId,
                "modifieddate" 	: this.formatDate(new Date()),
                "isModified"    : "0",
                "clientid"      : "",
    
            }

            
            const addParams ={
                "_collection" : "CreateCase",
                "doc_data"    : data1,
            }

            console.log(data1)

             axios
            .post("http://43.255.218.198:8086/action/add", addParams )
            .then(res =>{
                let dataCase=res.data
                //console.log(dataCase)

                this.getNewlyAddedCase();
                //this.onModalClose();
                this.setState({
                    fade:   true,
                    isshow: true,
                    alerttype:   "Success!",
                    color: "success",
                    isloading: false,
                    message: "Successfully added!",

                    title: "",
                    description: "",
                })
                    
            })
            .catch(error =>{
                console.log("error: " + error)
                this.setState({message: "Error: Please contact system admin."})
            })
            //this.getCaseById();
            this.setState({isloading: false})
        }
        
        else if(this.state.updateValue === "1") {

            let data1 ={
                
                "type"          : this.state.type,
                "title"         : this.state.title,
                "description"   : this.state.description,
                "reply"         : "",  
                "user"          : this.state.userUpdate,
                "status"        : this.state.status,
                "priorityLevel" : this.state.priorityLevel,
                "employeeId"    : this.state.employeeId,
                "deptAccess"    : this.state.deptAccess,
                "isDeleted"     : "0",
                "isDraft"       : "0",
                "createdby" 	: this.state.createdUser,
                "createddate"   : this.state.UpdateDate,
                "modifiedby"    : userId,
                "modifieddate" 	: this.formatDate(new Date()),
                "isModified"    : "1",
                "clientid"      : "",
                

            }

            const updateParams = {

                "_collection" : "CreateCase",
                "doc_data"    : data1,
                "_id"         : this.state.caseId,

            }
            //console.log(data1)
            axios
            .post("http://43.255.218.198:8086/action/update_one", updateParams)
            .then(res =>{
                //this.getNewlyAddedCase();
                this.setState({
                    fade:   true,
                    isshow: true,
                    alerttype:   "Success!",
                    color: "success",
                    isloading: false,
                    message: "Successfully Updated!",

                    //title: "",
                    //description: "",
                })
                
            })
            .catch(error =>{

                console.log("error: " + error)
            })
        }
        this.setState({isloading: false})
    }; 
    /**************** end update items ******************************/

    /**************** get case data **********************************/
    getNewlyAddedCase = async () => {

        let filter_data = {}
    
        const getParams = {
          "_collection" : "CreateCase",
          "filter_data" : filter_data
        }
        axios
        .post("http://43.255.218.198:8086/action/get" , getParams)
        .then(res => {
            const data = res.data
            
            const itemUsersDataLists = this.buildList(data["CreateCase"])
            this.state.itemUserDataList   =   itemUsersDataLists
            let List =[]
            for (let i = 0; i < this.state.itemUserDataList.length; i++) {
                this.setState({
                    concernId: this.state.itemUserDataList[i]['id'].replace(" '","").replace("'",""),
                })
            }

            this.saveImage()
            
        })
        .catch(error=>{
          //console.log("error: " + error)
        })


    };

    buildList = (data) => {
    
        let itemList =[]
     
        let idList              =[]
        let typeList            =[]
        let titleList           =[]
        let descriptionList     =[]
        let replyList           =[]
        let userList            =[]
        let statusList          =[]
        let priorityLevelList   =[]
        let employeeIdList      =[]
        let deptAccessList      =[]
        let isDeletedList       =[]
        let isDraftList         =[]
        let createdbyList       =[]
        let createddateList     =[]
        let modifiedbydList     =[]
        let modifieddateList    =[]
        let isModifiedList      =[]
        let clientidlist      =[]
        
    
        for (let i = 0; i < data.length; i++) {
            
            let s1 = data[i].split(":")
        
            let idClean = s1[1].replace("ObjectId(","").replace(")","").replace("{","").split(",")[0]
            let typTmp = s1[2].split(",")[0]/* .replace("'" , "").replace("'" , "") */
            let titleTmp = s1[3].split(",")[0]
            let descrTmp = s1[4].split("', ")[0]
            let replyTmp = s1[5].split(",")[0]
            let userTmp = s1[6].split("',")[0]
            let statusTmp = s1[7].split(",")[0]
            let priorityLevelTmp = s1[8].split(",")[0]
            let employeeIdTmp = s1[9].split(",")[0]
            let deptAccessTmp = s1[10].split(",")[0]
            let isDeletedTmp = s1[16].split(",")[0]
            let isDraftTmp = s1[11].split(",")[0]
            let createdbyTmp = s1[12].split(",")[0]
            let createddateTmp = s1[13].split(",")[0]
            let modifiedbydTmp = s1[14].split(",")[0]
            let modifieddateTmp = s1[15].split(",")[0]
            let isModifiedTmp = s1[16].split(",")[0]
            let clientid = s1[17].split(",")[0].replace("}","")
    
        
            idList.push(idClean)
            typeList.push(typTmp)
            titleList.push(titleTmp)
            descriptionList.push(descrTmp)
            replyList.push(replyTmp)
            userList.push(userTmp)
            statusList.push(statusTmp)
            clientidlist.push(clientid)
            priorityLevelList.push(priorityLevelTmp)
            employeeIdList.push(employeeIdTmp)
            deptAccessList.push(deptAccessTmp)
            isDeletedList.push(isDeletedTmp)
            isDraftList.push(isDraftTmp)
            createdbyList.push(createdbyTmp)
            createddateList.push(createddateTmp)
            modifiedbydList.push(modifiedbydTmp)
            modifieddateList.push(modifieddateTmp)
            isModifiedList.push(isModifiedTmp)
        }
        for (let i = 0; i < idList.length; i++) {
    
            let obj = {
                "id"            :   idList[i].replace(" ","").replace("'","").replace("'",""),
                "type"          :   typeList[i].replace(" ","").replace("'","").replace("'",""),
                "title"         :   titleList[i].replace(" ","").replace("'","").replace("'",""),
                "description"   :   descriptionList[i].replace(" ","").replace("'","").replace("'",""),
                "reply"         :   replyList[i].replace(" ","").replace("'","").replace("'",""),
                "user"          :   userList[i].replace(" ","").replace("'","").replace("'",""),
                "status"        :   statusList[i].replace(" ","").replace("'","").replace("'",""),
                "priorityLevel" :   priorityLevelList[i].replace(" ","").replace("'","").replace("'",""),
                "employeeId"    :   employeeIdList[i].replace(" ","").replace("'","").replace("'",""),
                "deptAccess"    :   deptAccessList[i].replace(" ","").replace("'","").replace("'",""),
                "clientid"    	:   clientidlist[i].replace(" ","").replace("'","").replace("'",""),
                "isDeleted"     :   isDeletedList[i].replace(" ","").replace("'","").replace("'",""),
                "isDraft"       :   isDraftList[i].replace(" ","").replace("'","").replace("'",""),
                "createdby"     :   createdbyList[i].replace(" ","").replace("'","").replace("'",""),
                "createddate"   :   createddateList[i].replace(" ","").replace("'","").replace("'",""),
                "modifiedby"    :   modifiedbydList[i].replace(" ","").replace("'","").replace("'",""),
                "modifieddate"  :   modifieddateList[i].replace(" ","").replace("'","").replace("'",""),
                "isModified"    :   isModifiedList[i].replace(" ","").replace("'","").replace("'",""),
            
            }
    
            itemList.push(obj)
    
        }
        //console.log("itemList Start")
        //console.log(itemList)
        //console.log("itemList END")
       
    
        return itemList
    };

    
    /**************** end get case data *******************************/

    formatDate(date) {
        var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();
    
        if (month.length < 2) 
            month = '0' + month;
        if (day.length < 2) 
            day = '0' + day;
        return [month, day, year].join('/');
    }

    /******************** Image  data**********************************/


    // image set up //
    
    onImageChange(e) {
        let reader = new FileReader();

        reader.onload = () =>   {            
            this.testImageStr(reader.result)
        };
        reader.readAsDataURL(e.target.files[0]);
    };

    testImageStr(result) {  
           
        let newResult = result.replace("data:image/jpeg;base64,", "").replace("data:image/png;base64,", "")

        let encoded = btoa(newResult)

        let getImage = this.state.image

        let imageTmp = {uri:encoded}

        let pushImage = imageTmp

        getImage.push(pushImage)

        /* console.log(getImage)
        console.log(" to dataabse ") */

        this.setState({image : getImage})

        //console.log(this.state.image)
        //console.log(" to dataabse ")
    };

    // end image set up //

    formatDate(date) {
        var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();
    
        if (month.length < 2) 
            month = '0' + month;
        if (day.length < 2) 
            day = '0' + day;
        return [month, day, year].join('/');
    };

    saveImage = async(e) => {
       
        //const createddate = moment(createddate).format('MM/DD/YYYY');

        if (this.state.createValue === "0") {

            let data2 ={
                "concernId"     : this.state.concernId,
                "image"         : this.state.image,
                "user"          : this.state.fullName,
                "isDeleted"     : "0",
                "isDraft"       : "0",
                "createdby" 	: this.state.userId,
                "createddate"   : this.formatDate(new Date()),
                "modifiedby"    : this.state.userId,
                "modifieddate" 	: this.formatDate(new Date()),
                "isModified"    : "0",

            }
            
            const addParams ={
                "_collection" : "ImageCase",
                "doc_data"    : data2
            }
            axios
            .post("http://43.255.218.198:8086/action/add", addParams)
            .then(res =>{
            })
            .catch(error =>{

              console.log("error: " + error)

              this.setState({message: "Error: Please contact system admin."})

            })
        } 
        //else{ console.log("no case ID") }
    };
    /******************** End Image  data******************************/
 
    render() {
        const selectType = [
            {type : "SSS"               },
            {type : "Phil.Health"       },
            {type : "Pag-Ibig"          },
            {type : "DTR"               },
            {type : "Loans"             },
            {type : "Leave"             },
            {type : "Records"           },
            {type : "Members-Concerns"  },
            {type : "Hr-Concerns"       },
            {type : "Payslip"           },
            {type : "COE"               },
            {type : "Absent-Letter"     },
            {type : "Others"            },
            {type : "Suggestions"       },
        ];
        const selectStatus = [
            {Status : "New"        },
            {Status : "In Progress"},
            {Status : "Closed"     },
        ];
        const prioLevel = [
            { prioLevel : "1"  },
            { prioLevel : "2"  },
            { prioLevel : "3"  },
            { prioLevel : "4"  },
            { prioLevel : "5"  },
        ];
        const DeptAccess = [
            {DeptAccess : "Legal"  },
            {DeptAccess : "AMS"  },
            {DeptAccess : "AMT"  },
            {DeptAccess : "CMW"  },
            {DeptAccess : "Payroll"  },
            {DeptAccess : "Hr"  },
            {DeptAccess : "TimeKeeping"  },
            {DeptAccess : "Recruitment"  },
            {DeptAccess : "Training"  },
        ];
        return(
        
            <Modal
                {...this.props}
                return
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                backdrop="static"
                className="modal-90w"
                 >
                <Modal.Header closeButton className="card-header">
                    <Modal.Title id="contained-modal-title-vcenter">
                      {this.state.header}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container>
                        <Form>
                            <Alert isOpen={this.state.isshow} color={this.state.color} fade={this.state.fade} className={this.state.isshow ? 'display-block' : 'display-none'}>
                                <div className={this.state.color}></div> 
                                {this.state.message}
                            </Alert>
                            <Form.Group as={Row} controlId="formPlaintextEmail">
                                <Form.Label column sm="4" style={{fontWeight : "bold"}}>
                                    TYPE
                                </Form.Label>
                                <Col sm="8">
                                    <Typeahead
                                        labelKey='type'
                                        id="basic-example"
                                        onChange={this.onChangeType}
                                        options={selectType} 
                                        disabled={(this.state.roleId === "1" || this.state.roleId === "27") ? true : false}
                                        //defaultValue={this.state.type}  
                                        selected={[this.state.type]}                                            
                                        //autocomplete="true"
                                    />
                                </Col>
                            </Form.Group> 

                            {this.state.roleId === "1" || this.state.roleId === "27" ?
                                <Form.Group as={Row} controlId="formPlaintextEmail">
                                    <Form.Label column sm="4" style={{fontWeight : "bold"}}>
                                        STATUS
                                    </Form.Label>
                                    <Col sm="8">
                                        <Typeahead
                                            labelKey='Status'
                                            id="basic-example"
                                            ref="Status"
                                            //defaultValue={this.props.Status}
                                            selected={[this.state.status]}
                                            onChange={this.onChangeStatus}
                                            options={selectStatus}
                                            //autocomplete="true"
                                        />
                                    </Col>
                                </Form.Group> : null
                            }

                            {this.state.roleId === "1" || this.state.roleId === "27" ?
                                <Form.Group as={Row} controlId="formPlaintextEmail">
                                    <Form.Label column sm="4" style={{fontWeight : "bold"}}>
                                        PRIORITY LEVEL
                                    </Form.Label>
                                    <Col sm="8">
                                        <Typeahead
                                            labelKey='prioLevel'
                                            id="basic-example"
                                            onChange={this.onChangePrioLevel}
                                            options={prioLevel}
                                            selected={[this.state.priorityLevel]}
                                            //defaultValue={this.state.priorityLevel}  
                                            //autocomplete="true"
                                        />
                                    </Col>
                                </Form.Group> : null
                            }

                            {this.state.roleId === "1" || this.state.roleId === "27" ?
                                <Form.Group as={Row} controlId="formPlaintextEmail">
                                    <Form.Label column sm="4" style={{fontWeight : "bold"}}>
                                        DEPARTMENT ACCESS
                                    </Form.Label>
                                    <Col sm="8">
                                        <Typeahead
                                            labelKey='DeptAccess'
                                            id="basic-example"
                                            onChange={this.onChangeDeptAccess}
                                            options={DeptAccess}
                                            //defaultValue={this.state.DeptAccess}  
                                            selected={[this.state.deptAccess]}
                                            //autocomplete="true"
                                        />
                                    </Col>
                                </Form.Group>: null
                            }

                            <Form.Group as={Row} controlId="formPlaintextEmail">
                                <Form.Label column sm="4" style={{fontWeight : "bold"}}>
                                    TITLE
                                </Form.Label>
                                <Col sm="8">
                                    <Form.Control 
                                        type="title"
                                        ref="title"
                                        defaultValue={this.state.title}
                                        onChange={this.onChangeTitle} 
                                        autoComplete="off"
                                        disabled={(this.state.roleId === "1" || this.state.roleId === "27") ? true : false}
                                    />
                                </Col>
                            </Form.Group>
                            
                            <Form.Group as={Row} controlId="formPlaintextEmail">
                                <Form.Label column sm="4" style={{fontWeight : "bold"}}>
                                    DESCRIPTION
                                </Form.Label>
                                <Col sm="8">
                                    <Form.Control 
                                        as="textarea"
                                        rows={4}
                                        name="description"
                                        onChange={this.onChangeDescription}
                                        defaultValue={this.state.description}
                                        onKeyDown={this.handleKeyDown}
                                        disabled={(this.state.roleId === "1" || this.state.roleId === "27") ? true : false}
                                    />
                                </Col>
                            </Form.Group>
                           
                            <Form.Group as={Row} className="mt-2" controlId="formPlaintextEmail">
                                <Form.Label column sm="4" style={{fontWeight : "bold"}} >
                                    ATTACHMENT FILE
                                </Form.Label>
                                <Col sm="8">
                                </Col>
                            </Form.Group>
                            
                            <Form.Group as={Row} className="mt-2" controlId="formPlaintextEmail">
                                <Col sm='12'>
                                    <input name="file" type="file" multiple /* disabled={(this.state.roleId === "1" || this.state.roleId === "27") ? true : false} */ onChange={this.onImageChange.bind(this)}/>
                                </Col>
                            </Form.Group> 
                            
                            
                            <div style={{height: '50px'}}></div>
                            <Form.Group as={Row} className="mt-2" controlId="formPlaintextEmail">
                                <ButtonToolbar sm={12} className="ml-auto">
                                    <Button variant="primary" style={{minWidth:'60px'}} onClick={this.submitDataInfo}>
                                        Submit
                                    </Button>&nbsp;&nbsp;
                                    <Button variant="danger" onClick={this.onModalClose} style={{minWidth:'60px'}}>
                                        Close
                                    </Button>
                                </ButtonToolbar>
                            </Form.Group>
                        </Form>
                    </Container>
                </Modal.Body>
                <NoserLoading show={this.state.isloading} />
            </Modal>
        );
    }

}
export  default CreateCaseModal