import {
    React,Component,BootstrapTable,
    paginationFactory,Button, ButtonToolbar, Card, 
    Form, Col, Row, axios, Container, Banner, Typeahead, TimePicker,
    cellEditFactory, Type, moment, NavLink, Alert, AppConfiguration,
    NoserLoading, Noser, sizePerPageRenderer, DatePicker
} 
from '../../../noser-hris-component';
import { DropdownButton, Dropdown} from 'react-bootstrap';

import ToolkitProvider, { CSVExport } from 'react-bootstrap-table2-toolkit';
import 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css';
import { tsNullKeyword } from '@babel/types';

 
class ManningEmployee201 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fade            :   true, 
            color           :   "",
            isshow          :   false,
            message         :   "",
            userinfo        :   [],
            isloading       :   false,
            alerttype       :   "",

            batchAutocomplete                   :   [],
            clientAutocomplete                  :   [],
            positionAutocomplete                :   [],
            trainingAutocomplete                :   [],
            statusTableList                     :   [],
            batchNoAutocomplete                 :   [],
            dateFrom                            :   "",
            dateTo                              :   "",
            modalTrainingRegisterShow           :   false,          
            
            detailsOfED                  :   [],
            particularList: [
                {
                    "id" : "Closed",
                    "name" : "Closed"
                },
                {
                    "id" : "Pending",
                    "name" : "Pending"
                },
                
               
            ],
            selectedparticular      :   '',
            selectedparticularId    :   '',
            countStatus             :'Pending',
            pendingsList            :[],
            closedList              :[],
            fliterClose             :'Closed',
            statusPendingList       :[],
            statusClosedList        :[],
            totalStatusList         :[],
            totalDoleCasesList      :[], 
            totalClosedCount        :[],  

            clientDDL           :   [],
            selectedClientId        :   '',
            getEmployeeList         :   [],
            selectedClientName      :   '',
            selectedEmployeeName    :   '',
            selectedEmployeeNo      :   '',
            selectedEmployeeBranch  :   '',
            selectedPosition        :   '',
            ViewList:[
                {
                    "id":"1",
                    "label":"IR",
                    "value":"IR"
                },
                {
                    "id":"1",
                    "label":"NTE",
                    "value":"NTE"
                },
                {
                    "id":"1",
                    "label":"NOH",
                    "value":"NOH"
                },
                {
                    "id":"1",
                    "label":"NOD",
                    "value":"NOD"
                },
                {
                    "id":"1",
                    "label":"RecordNODPenalty",
                    "value":"RecordNODPenalty"
                },
            ],
            recordNODObjId:"",
            systemStatusList:[
                {
                    "id":1,
                    "systemStatus":"active"
                },
                {
                    "id":2,
                    "systemStatus":"Inactive"
                }
            ],
            selectedsystemStatusid:"",
            selectedsystemStatus:"",
            branchDDL:[],
            selectedBranchName:"",
            selectedBranchId:"",
            withHMOList:[
                {
                    "id":1,
                    "name":"Yes"
                },
                {
                    "id":2,
                    "name":"No"
                }
            ],
            selectedWithHMOId:"",
            selectedWithHMOName:"",
            statusDDL :[
                {
                    "id":"0",
                    "name":"ACTIVE"
                },
                {
                    "id":"1",
                    "name":"INACTIVE"
                }
            ],
            statusId:"",
            dataGRID:[],

        }
    }

    componentDidMount(){
        const sleep = (milliseconds) => {return new Promise(resolve => setTimeout(resolve, milliseconds))}        
        this.state.userinfo = JSON.parse(sessionStorage.getItem("userData"))
        //this.getEDMonitoring();
        this.clientDDL();      
        
    }

    clientDDL(){

        this.setState({isloading:true})
        const getParams = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.userinfo.clientId,
            "UserId":this.state.userinfo.userId,
            
        }

        axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Client/GetClientList", getParams)
        .then(res => {
            //console.log("Client List start ");
            //console.log(res.data);
            //console.log("Client List end");
            const data = res.data
            this.setState({
                isloading:false,
                clientDDL : res.data.clients ? res.data.clients : []
            });
            //console.log("this.state.clientDDL")
            //console.log(this.state.clientDDL)

        })
        .catch(error=>{
           this.setState({
               isloading       :   false,
               alerttype       :   "Error!",
               isshow          :   true,
               color           :   "danger",
               message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
               fade            :   true
            })
       })
    }

    onChangeClientLocations = (e) => {
        //console.log(e)
        if(e.length == 0) {
            this.state.selectedBranchName=""
            return
        } 

        this.state.selectedBranchId = e[0].id
        this.state.selectedBranchName = e[0].name
        this.setState({
           // isloading:true,
            isshow:false,
            color:"",
            message:"",
            fade:true ,
            //clientName: e[0].name
        })

       //let clientTmp = e[0].name
       ////console.log(clientTmp)
        
    }

    onChangeClientList = (e) => {
        //console.log(e)
        if(e.length == 0) {
            this.state.selectedClientName=""
            return
        } 

        this.state.selectedClientId = e[0].id
        this.state.selectedClientName = e[0].name
        this.setState({
           // isloading:true,
            isshow:false,
            color:"",
            message:"",
            fade:true ,
            //clientName: e[0].name
        })

       //let clientTmp = e[0].name
       ////console.log(clientTmp)
       //this.getEmployees();;
       this.getbranchDDL();;
        
    }

    getbranchDDL(){

        this.setState({isloading:true})
        const getParams = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.selectedClientId,
            "UserId":this.state.userinfo.userId,
            "ClientName": this.state.selectedClientName,
            "City": "",
            "Province": "",
            "Region": ""
            
        }

        axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Client/GetClientLocations", getParams)
        .then(res => {
            //console.log("getbranchDDL List start ");
            //console.log(res.data);
            //console.log("getbranchDDL List end");
            const data = res.data
            this.setState({
                isloading:false,
                branchDDL : res.data.locations ? res.data.locations : []
            });
            //console.log("this.state.branchDDL")
            //console.log(this.state.branchDDL)

        })
        .catch(error=>{
           this.setState({
               isloading       :   false,
               alerttype       :   "Error!",
               isshow          :   true,
               color           :   "danger",
               message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
               fade            :   true
            })
       })
    }

    getEmployees(){
        this.setState({isloading:true})
        const getParams = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.selectedClientId,
            "UserId":this.state.userinfo.userId,
            "EmployeeName":"",
            "EmployeeNo":"",
            "ClientName":""
        }
        axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Employee/GetEmployees", getParams)
        .then(res => {
            //console.log("Employee List start ");
            //console.log(res.data);
            //console.log("Employee List end");
            const data = res.data

            if(data.status=="1")
                this.setState({getEmployeeList : data.employees, getEmployeeNoList : data.employees,isloading:false,}) 
            else
                this.setState({getEmployeeList : [], getEmployeeNoList : [],isloading:false}) 
                
        })
        .catch(error=>{

           this.setState(  {
               isloading       :   false,
               alerttype       :   "Error!",
               isshow          :   true,
               color           :   "danger",
               message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
               fade            :   true
           })

        })
        
    }

    onChangeEmployeesList = (e) => {       

        if(e.length==0)
        {
            this.setState({getEmployeeListData: null, selectedPayrollpayPeriod: '', selectedWorkScheduleIdParam: '', selectedPayrollPeriodsId : '',selectedEmployeeId : '',selectedEmployeeName : '',selectedPayrollPeriodsName : '', selectedWorkScheduleId: ''})
          
            return
        }

        this.setState({isloading:true,isshow:false,color:"",message:"",fade:true})

        this.state.selectedEmployeeName = e[0].employeeName
        this.state.selectedEmployeeId = e[0].id
        this.state.selectedEmployeeNo = e[0].employeeNo
        this.state.selectedEmployeeBranch = e[0].locationName
        this.state.selectedPosition = e[0].position
        
        //console.log("e[0]")
        //console.log(e[0])
        //console.log("e[0]")
        //console.log("Work selectedEmployeeName   " + this.state.selectedEmployeeNo );
        //console.log("Employee List start ");
        //console.log(this.state.selectedEmployeeBranch);
        //console.log("Employee List end");

        this.setState({isloading:false})

    }

    onChangeSystemStatusList = (e) => {

        if(e.length == 0) {
            this.state.selectedsystemStatus=""
            return
        } 

        this.state.selectedsystemStatusId = e[0].id
        this.state.selectedsystemStatus = e[0].systemStatus
        
    }

    onChangeWithMHO = (e) => {

        if(e.length == 0) {
            this.state.selectedWithHMO=""
            return
        } 

        this.state.selectedWithHMOId = e[0].id
        this.state.selectedWithHMOName = e[0].systemStatus
        
    }


    GridDataModified(oldValue, newValue, id, column) {
        ////////console.log(id)
        this.state.detailsOfED.map(function(item,i) {
            if (item.id===id)
                item.isModified = newValue!=oldValue ? "1" : "0"
        })
    }

    onChangeStatus = (e) => {
        //console.log(e)
        if(e.length == 0) {
            this.state.statusId=""
            return
        } 

        this.state.statusId = e[0].id
        this.setState({
            isshow:false
        })
        
    }

    handleClickSearch=()=>{
        if(this.state.selectedClientId === ""){
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "Please select client!",
                fade        :   true
            });
            return
        }
     
        this.setState({  isloading:true })
        const params = {
            "IpAddress":"0.0.0.0",
            "UserId":this.state.userinfo.userId,
            "ClientId":this.state.selectedClientId,
            "Locationid":this.state.selectedBranchId ,
            "Isdeleted":this.state.statusId
        };
        console.log("params")
        console.log(params)
        axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Report/GetManningReports",  params)
        .then(res => {
            const data = res.data.manningReports;
            //const dataArrs = data[data.length-1];
            const dataArrs = data.filter((thing, index, self) =>
                index === self.findIndex((t) => (
                    t.profileId === thing.profileId
                ))
            )
            console.log("GetManningReports");
            console.log(dataArrs);
            this.setState({
                dataGRID: dataArrs,
                isloading:false
            });
            if(data.status=="0"){
                this.setState({
                    isloading   :   false,
                    alerttype   :   "Error!",
                    isshow      :   true,
                    color       :   "danger",
                    message     :   data.message,
                    fade        :   true
                });
            }
            
        })
        .catch(error=>{
            this.setState({
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade            :   true
            })
        })
    }

    handleClickRefresh=()=>{
        this.setState({  isloading:true })

        let filter_data ={}

        const getParams ={
            "_collection" : "UATEDMonitoring",
            "filter_data" : filter_data
        }

        //console.log("axios start")
        //console.log(getParams)

        axios
        .post(AppConfiguration.Setting().noserMongoDB + "action/get", getParams)
        //.post("http://134.209.99.190:8088/action/get" , getParams)
        .then(res =>{
            
            const data = res.data

            if(data.UATEDMonitoring.length > 0){

                const itemUsersDataLists = this.buildListEDMonitoring(data["UATEDMonitoring"])
            
                this.state.itemUserDataList  =  itemUsersDataLists
                
                let List =[]
                for (let i = 0; i < this.state.itemUserDataList.length; i++) {
                                        
                    let obj = {

                        'id'                        :   this.state.itemUserDataList[i]['id'],
                        'quotedStatement'           :   this.state.itemUserDataList[i]['quotedStatement'],
                        'dateOfNTE'                 :   this.state.itemUserDataList[i]['dateOfNTE'],
                        'client'                    :   this.state.itemUserDataList[i]['client'],              
                        'cycleOfDays'               :   this.state.itemUserDataList[i]['cycleOfDays'],
                        'dateOfIncident'            :   this.state.itemUserDataList[i]['dateOfIncident'],
                        'dateOfNOD'                 :   this.state.itemUserDataList[i]['dateOfNOD'],
                        'dateOfNOH'                 :   this.state.itemUserDataList[i]['dateOfNOH'],
                        'dateRecordNodaPenalty'     :   this.state.itemUserDataList[i]['dateRecordNodaPenalty'],
                        'employeeName'              :   this.state.itemUserDataList[i]['employeeName'],
                        'employeeNo'                :   this.state.itemUserDataList[i]['employeeNo'],
                        'irReferenceNo'             :   this.state.itemUserDataList[i]['irReferenceNo'],              
                        'location'                  :   this.state.itemUserDataList[i]['location'],
                        'position'                  :   this.state.itemUserDataList[i]['position'],
                        'retailgroup'               :   this.state.itemUserDataList[i]['retailgroup'],
                        'status'                    :   this.state.itemUserDataList[i]['status'],
                        'article'                   :   this.state.itemUserDataList[i]['article'],
                        'category'                  :   this.state.itemUserDataList[i]['category'],
                        'section'                   :   this.state.itemUserDataList[i]['section'],
                        'isDraft'                   :   this.state.itemUserDataList[i]['isDraft'],
                        'isDeleted'                 :   this.state.itemUserDataList[i]['isDeleted'],
                        'createdby'                 :   this.state.itemUserDataList[i]['createdby'],
                        'createddate'               :   this.state.itemUserDataList[i]['createddate'],
                        'modifiedby'                :   this.state.itemUserDataList[i]['modifiedby'],
                        'modifieddate'              :   this.state.itemUserDataList[i]['modifieddate'],
                        'isModified'                :   this.state.itemUserDataList[i]['isModified'],
                        'NTEReferenceNo'            :   this.state.itemUserDataList[i]['NTEReferenceNo'],
                        'NODReferenceNo'            :   this.state.itemUserDataList[i]['NODReferenceNo'],
                        'NOHReferenceNo'            :   this.state.itemUserDataList[i]['NOHReferenceNo'],
                        'ClientId'                  :   this.state.itemUserDataList[i]['ClientId'],  
                        "NTHoffense"                :   this.state.itemUserDataList[i]['NTHoffense'],  
                        "penalty"                   :   this.state.itemUserDataList[i]['penalty'],  
                        "monetaryCharge"            :   this.state.itemUserDataList[i]['monetaryCharge'],  
                        "statusOfIssue"             :   this.state.itemUserDataList[i]['statusOfIssue'],  
                        
                        'action'                    :   "1",
                        'selectView'                    :   [],
                        'buttonView'                    :   "1",

                    }

                    List.push(obj)

                }

                this.setState({
                    detailsOfED: List,
                    isloading:false
                })
                
            }else{
                this.setState(  {
                    isloading       :   false,
                    alerttype       :   "Warning!",
                    isshow          :   true,
                    color           :   "warning",
                    message         :   "No data",
                    fade            :   true
                })
            }
            
        })
        .catch(error=>{
            //console.log("error: " + error)
            this.setState(  {
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade            :   true
            })
        })
    }

    GridDataModified(oldValue, newValue, id, column) {
        //////console.log(id)
        //let employeeData = this.state.getEmployeeList
        this.state.detailsOfED.map(function(item,i) {
            
            if (item.id===id){
               // checktmp = "1"
                item.isModified = newValue!=oldValue ? "1" : "0"
               
                
            }
               
        })

    }


    render() {
        const { ExportCSVButton } = CSVExport;
        
        const statusColumn = [
            
            {
                dataField   : 'status',
                text:'Status',
                headerStyle : () => {
                    return { minWidth  : "865px"};
                }
            },
            {
                dataField: 'count',
                text: 'Count',
                headerStyle : () => {
                    return { minWidth  : "870px" };
                }
            },
           /*  {
                dataField   : 'persentage',
                text        : '%',
                headerStyle : () => {
                    return { width  : "33.3333333333%" };
                }
            }, */
            /*{
                dataField   : 'action',
                text        : 'Action',
                headerStyle : () => {
                    return { width  : "10%" };
                },
                formatter   :   (cell, row, isSelect) => {
                    if (row)
                    return (
                        <Button style={{height:'16px',paddingTop:'0',marginTop:'-3px',fontSize:'11px'}}
                            variant="link" onClick={this.showTrainingRegisterModal}
                        >View Details</Button>
                    );
                },
            }, */
        ]

        const selectRow = {
            mode: 'checkbox',
            clickToSelectAndEditCell: true,
            onSelect: (row, isSelect, rowIndex, e) => {
                this.state.statusTableList.map(function(item,i){
                    if(item.id===row.id)
                    {
                        item.isDeleted = isSelect ? "1" : "0"
                        item.isModified = isSelect ? "1" : "0"
                    }
                })
            }
        };

        const rowEvents = {
            onClick: (e, row, rowIndex) => {
            }
        };

        const column = [

            {
                dataField   : 'submittedBy',
                text        : 'SUBMITTED BY:',
                editable    : false,
                headerStyle : () => {
                    return { width  : "10%",  whiteSpace: 'nowrap' };
                }
            },
            {
                dataField   : 'submittedDate',
                text        : 'DATE SUBMITTTED',
                editable    : false,
                headerStyle : () => {
                    return { width  : "10%",  whiteSpace: 'nowrap' };
                }
            },
            {
                dataField   : 'approverBy',
                text        : 'APPROVED / REJECTED By',
                editable    : false,
                headerStyle : () => {
                    return { width  : "10%",  whiteSpace: 'nowrap' };
                }
            },
            {
                dataField   : 'approvedDate',
                text        : 'APPROVED / REJECTED DATE',
                editable    : false,
                headerStyle : () => {
                    return { width  : "10%",  whiteSpace: 'nowrap' };
                }
            },
            {
                dataField   : 'employeeName',
                text        : "MEMBER'S NAME",
                editable    : false,
                headerStyle : () => {
                    return { width  : "10%",  whiteSpace: 'nowrap' };
                }
            }, 
            {
                dataField   : 'employeeNo',
                text        : 'USER ID',
                editable    : false,
                headerStyle : () => {
                    return { width  : "10%",  whiteSpace: 'nowrap' };
                }
            },
            {
                dataField   : 'dateOfBirth',
                text        : 'birth date',
                editable    : false,
                headerStyle : () => {
                    return { width  : "10%",  whiteSpace: 'nowrap' };
                }
            },  
            {
                dataField   : 'position',
                text        : 'Position',
                editable    : false,
                headerStyle : () => {
                    return { width  : "10%",  whiteSpace: 'nowrap' };
                }
            }, 
            {
                dataField   : 'location',
                text        : 'AREA ASSIGNMENT/STORE ASSIGNMENT',
                editable    : false,
                headerStyle : () => {
                    return { width  : "10%",  whiteSpace: 'nowrap' };
                }
            }, 
            {
                dataField   : 'payMode',
                text        : 'pay mode',
                editable    : false,
                headerStyle : () => {
                    return { width  : "10%",  whiteSpace: 'nowrap' };
                }
            }, 
            {
                dataField   : 'payType',
                text        : 'pay Type',
                editable    : false,
                headerStyle : () => {
                    return { width  : "10%",  whiteSpace: 'nowrap' };
                }
            }, 
            {
                dataField   : 'tlApproval',
                text        : 'TL APPROVAL (1)',
                editable    : false,
                headerStyle : () => {
                    return { width  : "10%",  whiteSpace: 'nowrap' };
                }
            }, 
            {
                dataField   : 'coorApproval',
                text        : 'COOR APPROVAL (2)',
                editable    : false,
                headerStyle : () => {
                    return { width  : "10%",  whiteSpace: 'nowrap' };
                }
            }, 
            {
                dataField   : 'salaryOffered',
                text        : 'rate',
                editable    : false,
                headerStyle : () => {
                    return { width  : "10%",  whiteSpace: 'nowrap' };
                }
            }, 
            {
                dataField   : 'systemStatus',
                text        : 'system Status',
                editable    : false,
                headerStyle : () => {
                    return { width  : "10%",  whiteSpace: 'nowrap' };
                }
            }, 
            {
                dataField   : 'payCardType',
                text        : 'paycard Type',
                editable    : false,
                headerStyle : () => {
                    return { width  : "10%",  whiteSpace: 'nowrap' };
                }
            }, 
            {
                dataField   : 'payCardNumber',
                text        : 'paycard Number',
                editable    : false,
                headerStyle : () => {
                    return { width  : "10%",  whiteSpace: 'nowrap' };
                }
            }, 
            {
                dataField   : 'workSchedule',
                text        : 'working Sched',
                editable    : false,
                headerStyle : () => {
                    return { width  : "10%",  whiteSpace: 'nowrap' };
                }
            }, 
            {
                dataField   : 'periodType',
                text        : 'period Type',
                editable    : false,
                headerStyle : () => {
                    return { width  : "10%",  whiteSpace: 'nowrap' };
                }
            }, 
            {
                dataField   : 'contractDateStart',
                text        : 'date Started',
                editable    : false,
                headerStyle : () => {
                    return { width  : "10%",  whiteSpace: 'nowrap' };
                }
            }, 
            {
                dataField   : 'contractDateEnd',
                text        : 'duration',
                editable    : false,
                headerStyle : () => {
                    return { width  : "10%",  whiteSpace: 'nowrap' };
                }
            }, 
            {
                dataField   : 'regConStatus',
                text        : 'regcon Status',
                editable    : false,
                headerStyle : () => {
                    return { width  : "10%",  whiteSpace: 'nowrap' };
                }
            }, 
            {
                dataField   : 'remarks',
                text        : 'remarks',
                editable    : false,
                headerStyle : () => {
                    return { width  : "10%",  whiteSpace: 'nowrap' };
                }
            }, 
        ]

        const selectemployeeDetailRow = {
            mode: 'checkbox',
            clickToSelectAndEditCell: true,
            onSelect: (row, isSelect, rowIndex, e) => {
                this.state.detailsOfED.map(function(item,i){
                    if(item.id===row.id)
                    {
                        item.isDeleted = isSelect ? "1" : "0"
                        item.isModified = isSelect ? "1" : "0"
                    }
                })
            }
        };

        const rowEmployeeDetailEvents = {
            onClick: (e, row, rowIndex) => {
            }
        };
       
        
    return(
            <div>
                <Banner />
                <Container className="mt-5" fluid>
                    <Card>
                        <Card.Header>Manning Reports</Card.Header>
                        <Card.Body>
                            <Form>
                                <Alert isOpen={this.state.isshow} color={this.state.color} fade={this.state.fade} className={this.state.isshow ? 'display-block' : 'display-none'}>
                                    <div className={this.state.color}></div> 
                                    {this.state.message}
                                </Alert>
                                <Form.Group as={Row} controlId="formPlaintextEmail" className="mt-3">
                                    <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                        Client
                                    </Form.Label>
                                    <Col sm="11">
                                        <Typeahead
                                            labelKey='name'
                                            id="basic-example"
                                            onChange={this.onChangeClientList}
                                            options={this.state.clientDDL}
                                            placeholder="Select Client"
                                        />
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} controlId="formPlaintextEmail" className="mt-3">
                                    <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                        Branch
                                    </Form.Label>
                                    <Col sm="11">
                                        <Typeahead
                                            labelKey='name'
                                            id="basic-example"
                                            onChange={this.onChangeClientLocations}
                                            options={this.state.branchDDL}
                                            placeholder="Select Branch"
                                        />
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} controlId="formPlaintextEmail" className="mt-3">
                                    <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                        STATUS
                                    </Form.Label>
                                    <Col sm="11">
                                        <Typeahead
                                            labelKey='name'
                                            id="basic-example"
                                            onChange={this.onChangeStatus}
                                            options={this.state.statusDDL}
                                            placeholder="Select Status"
                                        />
                                    </Col>
                                </Form.Group>
                                <ButtonToolbar>
                                    <Button variant="success" className="ml-auto" style={{minWidth:'60px'}} onClick={this.handleClickSearch}>
                                        Search
                                    </Button> 
                                </ButtonToolbar>                              
                                <ToolkitProvider
                                    keyField="id"   
                                    data={ this.state.dataGRID }
                                    columns={ column }
                                    exportCSV={ {
                                        fileName: "Admin - Job Offer Reports.csv",
                                    } }
                                    >
                                    {
                                        props => (
                                        <div className="mt-3">
                                            <BootstrapTable
                                                { ...props.baseProps } 
                                                /* keyField = "id"
                                                data = { this.state.employeeListTable }
                                                columns = { columnEmployee }
                                                pagination={ paginationFactory({sizePerPageRenderer}) }
                                                rowClasses="noser-table-row-class"
                                                striped
                                                hover
                                                condensed
                                                selectRow = { employeeRow } */


                                                striped
                                                hover
                                                condensed
                                                pagination={ paginationFactory({sizePerPageRenderer}) }
                                                keyField = "id"
                                                data = { this.state.dataGRID }
                                                noDataIndication={ () => <div>No record found.</div> }
                                                columns = { column }
                                            />
                                            <ButtonToolbar>
                                                <ExportCSVButton className="btn btn-info ml-auto" { ...props.csvProps }>Export CSV!!</ExportCSVButton>
                                                &nbsp;&nbsp;&nbsp;
                                                <NavLink to="/home">
                                                    <Button className="ml-auto" variant="danger" href="/banner">
                                                        Close
                                                    </Button>
                                                </NavLink>
                                            </ButtonToolbar>
                                        </div>
                                        )
                                    }
                                </ToolkitProvider>
                            </Form>
                        </Card.Body>
                    </Card>                    
                </Container>
                <NoserLoading show={this.state.isloading} />
                  
            </div> 
        )
    }

}

export  default ManningEmployee201 
