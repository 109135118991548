import {
    React,Component,BootstrapTable,
    paginationFactory,Button, ButtonToolbar, Card, 
    Form, Col, Row, axios, Container, Banner, Typeahead, TimePicker,
    cellEditFactory, Type, moment, NavLink, Alert, AppConfiguration,
    NoserLoading, Noser, sizePerPageRenderer, DatePicker, Modal
} 
from '../../../noser-hris-component';

const showSecond = false;
const str = showSecond ? 'HH:mm:ss' : 'HH:mm';

class TrainingScheduleEdit extends Component {
    constructor(props) {
        super(props)
        this.ref = React.createRef();
        this.state = {
            userinfo    :   JSON.parse(sessionStorage.getItem("userData")),
            isloading   :   false,
            isshow      :   false,
            alerttype   :   "",
            message     :   "",
            color       :   "",
            fade        :   true,
        }
    }
    componentDidMount(){
        this.state.userinfo = JSON.parse(sessionStorage.getItem("userData"))
        this.props.onRefModal(this)
    }
    componentWillUnmount() {
        this.props.onRefModal(undefined)
    }
    initialize=(data)=>{
        this.setState({
            userinfo    :   JSON.parse(sessionStorage.getItem("userData")),
            isloading   :   false,
            isshow      :   false,
            alerttype   :   "",
            message     :   "",
            color       :   "",
            fade        :   true,
            trainingDDL :   [],
            platformDDL :    [
                {"name": 'FACE TO FACE (F2F)',"value": "1",},
                {"name":"Online Distance Learning (ODL)","value":"2"},
            ],
            facilitator : "",
            venue       : "",

            // BINDING DATA

            id    : data.id,
            training    : data.training,
            trainingId  : data.trainingId,
            batchNo     : data.batchNo,
            dateFrom    : new Date(data.fromDate),
            dateTo      : new Date(data.toDate),
            description : data.description,
            platform    : data.sessionName,
            participant : data.participantLimit,
            facilitator : data.facilitator,
            venue       : data.venue,
            valueFrom: moment(data.fromTime,'HHmm'),
            valueTo: moment(data.toTime,'HHmm'),
            examSet       : data.examSet,
        })
        if(data.examSet === "SET A"){
            this.setState({
                isSetACheck : true,
                isSetBCheck : false,
                isSetCCheck : false,
            })
        }
        if(data.examSet === "SET B"){
            this.setState({
                isSetACheck : false,
                isSetBCheck : true,
                isSetCCheck : false,
            })
        }
        if(data.examSet === "SET C"){
            this.setState({
                isSetACheck : false,
                isSetBCheck : false,
                isSetCCheck : true,
            })
        }
        this.GetTrainingName();
        console.log(data)
        console.log("time")
        console.log(moment(data.valueFrom,'HHmmss').format("HH:mm"))
    }
    handleModalClose = () => {
        this.props.onHide();            
    }
    GetTrainingName = async() =>{
        this.setState({isloading:true})
        const param = {
            "IpAddress"     :   "0.0.0.0",
            "ClientId"      :   this.state.userinfo.clientId,
            "UserId"        :   this.state.userinfo.userId,
        };
        await axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Maintenance/GetTrainingFilters",  param)
        .then(res => {
            const data = res.data;
            //console.log("GetTrainingFilters")
            //console.log(data)
            this.setState({trainingDDL:data.trainings,isloading:false});
        })
        .catch(error=>{
            this.setState(  {
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade            :   true
            })
        })
    }
    onChangeTraining = (e) => {
        if(e.length == 0) {
            this.state.trainingId     =   ""
            this.state.training       =  ""
            return
        }
        this.state.trainingId    =   e[0].id
        this.state.training      =   e[0].name
        this.setState({
            isshow:false,
        })
    }
    onChangeBatchNo = (e) => {
        this.setState({
            batchNo   :   e.target.value,
            isshow      :   false,
        })
    }
    handleChangeDateFrom = date => {
        this.setState({
            dateFrom: date,
            isshow:false,
        })
    }
    handleChangeDateTo = date => {
        this.setState({
            dateTo: date,
            isshow:false,
        })
    }
    onChangeTimeFrom = (Fromvalue) => {
        this.state.Fromvalue = Fromvalue && Fromvalue.format(str) 
        this.setState({
            isshow      :   false,
        })
    } 
    onChangeTimeTo = (Tovalue) => {
        this.state.Tovalue = Tovalue && Tovalue.format(str)
        this.setState({
            isshow      :   false,
        })
        console.log(this.state.Tovalue)
    }
    onChangeDescription = (e) => {
        this.setState({
            description : e.target.value.toUpperCase(),
            isshow      :   false,
        })
    }
    onChangePlatform = (e) => {
        if(e.length == 0) {
            this.state.platform       =  ""
            return
        }
        this.state.platform      =   e[0].name
        this.setState({
            isshow:false,
        })
    }
    onChangeParticipant = (e) => {
        const re = /^[0-9\b]+$/;
        if (e.target.value === '' || re.test(e.target.value)) {
            this.setState({
                participant   :   e.target.value,
                isshow      :   false,
            })
        }
    }
    onChangeFacilitator = (e) => {
        this.setState({
            facilitator : e.target.value.toUpperCase(),
            isshow      :   false,
        })
    }
    onChangeVenue = (e) => {
        this.setState({
            venue : e.target.value.toUpperCase(),
            isshow      :   false,
        })

    }

    handleChangeCheckboxA = (e) =>{
        this.setState({
            isSetACheck : true,
            isSetBCheck : false,
            isSetCCheck : false,
            setExam     : e.target.name
        })
    }

    handleChangeCheckboxB = (e) =>{
        this.setState({
            isSetBCheck : true,
            isSetACheck : false,
            isSetCCheck : false,
            setExam     : e.target.name
        })
    }

    handleChangeCheckboxC = (e) =>{
        this.setState({
            isSetCCheck : true,
            isSetACheck : false,
            isSetBCheck : false,
            setExam     : e.target.name
        })
    }

    handleSaveClick = event => {
        let dateFrom    =   moment(this.state.dateFrom).format('MM/DD/YYYY');
        let dateTo      =   moment(this.state.dateTo).format('MM/DD/YYYY');
        this.setState({
            isloading   :   true
        })

        const params = {
            "IpAddress"     :   "0.0.0.0",
            "UserId"        :   this.state.userinfo.userId,
            "ClientId"      :   this.state.userinfo.clientId,
            "trainingSchedules":[{
                "Id"                :   this.state.id,
                "TrainingId"        :   this.state.trainingId,
                "ScheduleName"      :   this.state.description,
                "Description"       :   this.state.description,
                "BatchNo"           :   this.state.batchNo,
                "Facilitator"       :   this.state.facilitator,
                "Venue"             :   this.state.venue,
                "ParticipantLimit"  :   this.state.participant,
                "FromDate"          :   dateFrom,
                "FromTime"          :   this.state.Fromvalue,
                "ToDate"            :   dateTo,
                "ToTime"            :   this.state.Tovalue,
                "ExamSet"            :   this.state.setExam,
                "PartNo"            :   "",
                "SessionName"       :  this.state.platform,
                "IsDeleted"        :   "0",
                "ModifiedBy"        : this.state.userinfo.userId,
            }]
        };

        console.log("Save Params")
        console.log(params)
        axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Training/EditTrainingSchedule", params)
        .then(res => {
            const data = res.data;
            this.setState({
                isloading   :   false,
                alerttype   :   data.status=="1" ? "Success!" : "Error!",
                isshow      :   true,
                color       :   data.status=="1" ? "success" : "danger",
                message     :   "Successfully Updated.",
                fade        :   true
            });

        })
        .catch(error=>{
            this.setState(  {
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade        :   true
            })
        })

    }

    render() {
        return(
        
            <Modal
                {...this.props}
                return
                size="xl"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                backdrop="static"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        EDIT TRAINING SCHEDULE
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container>
                        <Alert isOpen={this.state.isshow} color={this.state.color} fade={this.state.fade} className={this.state.isshow ? 'display-block' : 'display-none'}>
                            <div className={this.state.color}></div> 
                            {this.state.message}
                        </Alert>
                        <Form.Group as={Row} controlId="formPlaintextEmail">
                            <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                            TRAINING TITLE/PROGRAM
                            </Form.Label>
                            <Col sm="10">
                                <Typeahead
                                    labelKey='name'
                                    id="basic-example"
                                    onChange={this.onChangeTraining}
                                    options={this.state.trainingDDL}
                                    defaultSelected={[this.state.training]}
                                />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} controlId="formPlaintextEmail">
                            <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                            BATCH NUMBER
                            </Form.Label>
                            <Col sm="10">
                                <Form.Control 
                                    ref="batchNo"
                                    name="batchNo"
                                    value={this.state.batchNo}
                                    onChange={this.onChangeBatchNo}
                                    autoComplete="off"
                                    
                                />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} controlId="formPlaintextEmail">
                            <Col sm="1">
                            </Col>
                            <Col sm="0.5">
                                <Form.Check 
                                    type="checkbox" 
                                    label="SET A"
                                    onChange={e => this.handleChangeCheckboxA(e)}
                                    checked={this.state.isSetACheck}
                                    name="SET A"
                                />
                            </Col>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            <Col sm="0.5">
                                <Form.Check 
                                    type="checkbox" 
                                    label="SET B"
                                    onChange={e => this.handleChangeCheckboxB(e)}
                                    checked={this.state.isSetBCheck}
                                    name="SET B"
                                />
                            </Col>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            <Col sm="0.5">
                                <Form.Check 
                                    type="checkbox" 
                                    label="SET C"
                                    onChange={e => this.handleChangeCheckboxC(e)}
                                    checked={this.state.isSetCCheck}
                                    name="SET C"
                                />
                            </Col>
                        </Form.Group>
                        <Card className="mt-5">
                            <Card.Header>DATE OF TRAINING</Card.Header>
                            <Card.Body className="mt-3">
                                <Form.Group as={Row} controlId="formPlaintextEmail">
                                    <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                    DATE FROM
                                    </Form.Label>
                                    <Col sm="2">
                                        <DatePicker
                                            ref='dateFrom'
                                            selected={this.state.dateFrom}
                                            onChange={this.handleChangeDateFrom}
                                            minDate={this.minDate}
                                            value={this.props.dateFrom}
                                            dateFormat={"MM/dd/yyyy"}
                                            className="form-control"
                                            wrapperClassName="datepicker"
                                        />
                                    </Col>
                                    <Col sm="1">
                                    </Col>
                                    <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                    DATE TO
                                    </Form.Label>
                                    <Col sm="2">
                                        <DatePicker
                                            ref='dateTo'
                                            selected={this.state.dateTo}
                                            onChange={this.handleChangeDateTo}
                                            minDate={this.minDate}
                                            value={this.props.dateTo}
                                            dateFormat={"MM/dd/yyyy"}
                                            className="form-control"
                                            wrapperClassName="datepicker"
                                        />
                                    </Col>
                                </Form.Group>
                            </Card.Body>
                        </Card>
                        <Card className="mt-3">
                            <Card.Header>TIME OF TRAINING</Card.Header>
                            <Card.Body className="mt-3">
                                <Form.Group as={Row} controlId="formPlaintextEmail">
                                    <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                    TIME FROM
                                    </Form.Label>
                                    <Col sm="3">
                                        <TimePicker 
                                            showSecond={false}
                                            defaultValue={this.state.valueFrom}
                                            className="xxx"
                                            onChange={this.onChangeTimeFrom}
                                            
                                        />
                                    </Col>
                                    <Col sm="1">
                                    </Col>
                                    <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                    TIME TO
                                    </Form.Label>
                                    <Col sm="3">
                                        <TimePicker 
                                            showSecond={false}
                                            defaultValue={this.state.valueTo}
                                            className="xxx"
                                            onChange={this.onChangeTimeTo}
                                            
                                        />
                                    </Col>
                                </Form.Group>
                            </Card.Body>
                        </Card>
                        <Form.Group className="mt-5" as={Row} controlId="formPlaintextEmail">
                            <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                            DESCRIPTION
                            </Form.Label>
                            <Col sm="10">
                                <Form.Control 
                                    ref="description"
                                    name="description"
                                    value={this.state.description}
                                    onChange={this.onChangeDescription}
                                    autoComplete="off"
                                    
                                />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} controlId="formPlaintextEmail">
                            <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                            TRAINING PLATFORM
                            </Form.Label>
                            <Col sm="10">
                                <Typeahead
                                    labelKey='name'
                                    id="basic-example"
                                    onChange={this.onChangePlatform}
                                    options={this.state.platformDDL}
                                    defaultSelected={[this.state.platform]}
                                />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} controlId="formPlaintextEmail">
                            <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                            HEADCOUNT LIMIT
                            </Form.Label>
                            <Col sm="10">
                                <Form.Control 
                                    ref="participant"
                                    name="participant"
                                    value={this.state.participant}
                                    onChange={this.onChangeParticipant}
                                    autoComplete="off"
                                    
                                />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} controlId="formPlaintextEmail">
                            <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                            FACILITATOR
                            </Form.Label>
                            <Col sm="10">
                                <Form.Control 
                                    ref="facilitator"
                                    name="facilitator"
                                    value={this.state.facilitator}
                                    onChange={this.onChangeFacilitator}
                                    autoComplete="off"
                                    
                                />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} controlId="formPlaintextEmail">
                            <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                            VENUE
                            </Form.Label>
                            <Col sm="10">
                                <Form.Control 
                                    ref="venue"
                                    name="venue"
                                    value={this.state.venue}
                                    onChange={this.onChangeVenue}
                                    autoComplete="off"
                                    
                                />
                            </Col>
                        </Form.Group>
                    </Container>
                </Modal.Body>
                <Modal.Footer>
                    <ButtonToolbar >
                        <Button variant="success" className="ml-auto noser-button-mr1" onClick = { this.handleSaveClick }>Save</Button>
                        <Button variant="danger" className="noser-button-mr15" onClick={this.props.onHide}>Close</Button>
                    </ButtonToolbar>
                </Modal.Footer>
                <NoserLoading show={this.state.isloading} />
        </Modal>
        );
    }
}
export default TrainingScheduleEdit