import {
    React,Component,BootstrapTable,
    paginationFactory,Button, ButtonToolbar, Card, 
    Form, Col, Row, axios, Container, Banner, Typeahead, TimePicker,
    cellEditFactory, Type, moment, NavLink, Alert, AppConfiguration,
    NoserLoading, Noser, sizePerPageRenderer, DatePicker, Modal
} 
from '../../../noser-hris-component';

class UploadDTRAdjustmentModal extends Component {
    constructor(props) {
        super(props)
        this.state = {
            clientId : '',
            clientName : '',
            locationId : '',
            locationName:'',
            periodId:'',
            periodName:'',
            disable : true,
            uploadfile : '',
            clientList:[],
            locationList : [],
            periodList : [],
            isSpecial:"0",
        }
    }
    componentDidMount(){
        this.state.userinfo = JSON.parse(sessionStorage.getItem("userData"))
        this.GetClients()
    }
    setFile = (e) => {
        if(e.target.value.length>0)
            this.setState({ uploadfile: e.target.files[0],disable:false});
        else
            this.setState({ uploadfile: "",disable:true });
    }
    handleSubmit = (e) =>{
        this.setState({isloading:true})

        if(this.state.clientId==""){
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "Please select client.",
                fade        :   true
            })
            return 
        }

        // if(this.state.locationId==""){
        //     this.setState({
        //         isloading   :   false,
        //         alerttype   :   "Error!",
        //         isshow      :   true,
        //         color       :   "danger",
        //         message     :   "Please select location.",
        //         fade        :   true
        //     })
        //     return 
        // }

        if(this.state.periodId==""){
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "Please select Payroll Period.",
                fade        :   true
            })
            return 
        }

        const formData = new FormData();
        formData.append('IpAddress', "0.0.0.0");
        formData.append('ClientId', this.state.clientId);
        formData.append('ClientName', this.state.clientName);
        formData.append('LocationId', this.state.locationId);
        formData.append('LocationName', this.state.locationName);
        formData.append('PeriodId', this.state.periodId);
        formData.append('PeriodName', this.state.periodName);
        formData.append('UserId', this.state.userinfo.userId);
        formData.append('IsSpecial', this.state.isSpecial);
        formData.append('File', this.state.uploadfile);

        console.log(formData)
        
        axios
            .post(
                AppConfiguration.Setting().noserapiendpoint + "Timekeeping/UploadAdjustmentBillableHours",  formData, {headers: {'Content-Type': 'multipart/form-data'}}
            )
            .then(res => {
                const data = res.data;
                if(data.status=="1")
                {
                    this.setState({
                        isloading   :   false,
                        alerttype   :   "Success!",
                        isshow      :   true,
                        color       :   "success",
                        message     :   data.message,
                        fade        :   true
                    });
                }
                else{
                    this.setState({
                        isloading   :   false,
                        alerttype   :   "Error!",
                        isshow      :   true,
                        color       :   "danger",
                        message     :   data.message,
                        fade        :   true
                    });
                }
            })
            .catch(error=>{
                this.setState({
                    isloading   :   false,
                    alerttype   :   "Error!",
                    isshow      :   true,
                    color       :   "danger",
                    message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                    fade        :   true
                })
            })
    }
    handleModalClose = () => {
        this.props.onHide();            
    }
    handleChangeClient = (e) =>{
        this.setState({alerttype:"",isshow:false,color:"",message:"",fade:false});
        if(e.length==0)
        {
            this.setState({clientId:"",clientName:""})
            return
        }
        this.state.clientId= e[0].id
        this.state.clientName= e[0].name

        this.setState({periodId:"",periodName:""})
        this.setState({locationId:"",locationName:""})
        this.reflocaltion.getInstance().clear()
        this.refperiod.getInstance().clear()
        this.GetClientLocation()
        this.GetPeriodList()
    }
    handleChangePeriod = (e) => {
        this.setState({alerttype:"",isshow:false,color:"",message:"",fade:false});
        if(e.length == 0) {
            this.setState({periodId:"",periodName:""})
            return
        }
        this.state.periodId = e[0].periodId
        this.state.periodName =e[0].payPeriod
    }
    handleChangeLocation = (e) => {
        this.setState({alerttype:"",isshow:false,color:"",message:"",fade:false});
        if(e.length==0)
        {
            this.setState({locationId:"",locationName:""})
            return
        }
        this.state.locationId = e[0].id
        this.state.locationName = e[0].name
    }
    GetClients(){
        this.setState({isloading:true})
        const getParams = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.userinfo.clientId,
            "UserId":this.state.userinfo.userId,
        }
        axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Client/GetClientList", getParams)
        .then(res => {
            this.setState(
                {
                    isloading:false,
                    clientList : res.data.clients ? res.data.clients : []
                });
                console.log(this.state.clientList.findIndex(x=>x.id=="101"))
        })
        .catch(error=>{
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade        :   true
            })
        })
    }
    GetClientLocation(){
        this.setState({isloading:true})

        const getParams = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.clientId,
            "UserId":this.state.userinfo.userId,
            "ClientName": this.state.clientName,
            "City": "",
            "Province": "",
            "Region": ""
    
        }
        console.log(getParams)
        axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Client/GetClientLocations", getParams)
        .then(res => {
            const data = res.data;
            this.setState({locationList : res.data.locations ? res.data.locations : [], isloading:false})
            if(data.locations.length=="0"){
                this.setState({
                    isloading   :   false,
                    alerttype   :   "Error!",
                    isshow      :   true,
                    color       :   "danger",
                    message     :   data.message,
                    fade        :   true
                });
            }
        })
        
        .catch(error=>{
           this.setState(  {
               isloading       :   false,
               alerttype       :   "Error!",
               isshow          :   true,
               color           :   "danger",
               message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
               fade            :   true
           })
       })
    }
    GetPeriodList() {
        this.setState({isloading:true})
        const param = {
            "IpAddress"     :   "0.0.0.0",
            "ClientId"      :   this.state.clientId,
            "UserId"        :   this.state.userinfo.userId,
            "EmployeeId"    :   "",
            "IsProcessed"   :   "1"
         };
         axios
             .post(
                 AppConfiguration.Setting().noserapiendpoint + "Timekeeping/GetPayrollPeriods",  param
             )
             .then(res => {
                const data = res.data;
                console.log(data)
                this.setState({
                    periodList   :   data.payrollPeriods,
                    isloading       :   false
                })
             })
    }
    render() {

        const columnOtherPayrollFields = [
            {
                dataField: 'otherAdditionalField',
                text: 'Other Additional Fields',
                headerStyle : () => {
                    return { width  : "100%" };
                }
            }
        ]

    return(
        
            <Modal
                {...this.props}
                return
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                backdrop="static"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                    Upload DTR Adjustment
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container>
                        <Alert isOpen={this.state.isshow} color={this.state.color} fade={this.state.fade} className={this.state.isshow ? 'display-block' : 'display-none'}>
                            <div className={this.state.color}></div> 
                            {this.state.message}
                        </Alert>
                        <Form.Row>
                            <Form.Group as={Col} sm={12} controlId="formGridPassword">
                                <Typeahead
                                    labelKey='name'
                                    id="basic-example"
                                    onChange={this.handleChangeClient}
                                    options={this.state.clientList}
                                    placeholder="Select Client"
                                />
                            </Form.Group>
                        </Form.Row>
                        <Form.Row>
                            <Form.Group as={Col} sm={12} controlId="formGridPassword">
                                <Typeahead
                                    labelKey='name'
                                    id="basic-example"
                                    onChange={this.handleChangeLocation}
                                    
                                    options={this.state.locationList}
                                    placeholder="Select Location"
                                    ref={ref => this.reflocaltion = ref}
                                />
                            </Form.Group>
                        </Form.Row>
                        <Form.Row>
                            <Form.Group as={Col} sm={12} controlId="formGridPassword">
                                <Typeahead
                                        labelKey='payPeriod'
                                        id="basic-example"
                                        onChange={this.handleChangePeriod}
                                        options={this.state.periodList}
                                        placeholder="Select Payroll Period"
                                        ref={ref => this.refperiod = ref}
                                    />
                            </Form.Group>
                        </Form.Row>
                        <Form.Row>
                            <Form.Group as={Col} sm={6} controlId="formGridPassword">
                                <Form.Control type="file" accept='.csv' onChange={this.setFile} />
                            </Form.Group>
                        </Form.Row>
                    </Container>
                </Modal.Body>
                <Modal.Footer>
                    <ButtonToolbar >
                        <Button variant="success" style={{minWidth:'60px',marginRight:'1pt'}} className="ml-auto" onClick = { this.handleSubmit } disabled={this.state.disable}>Upload</Button>
                        <Button variant="danger" onClick={this.props.onHide}>Close</Button>
                    </ButtonToolbar>
                </Modal.Footer>
                <NoserLoading show={this.state.isloading} />
        </Modal>
        );
    }
}
export default UploadDTRAdjustmentModal