import {
    React,Component,BootstrapTable,
    paginationFactory,Button, ButtonToolbar, Card, 
    Form, Col, Row, axios, Container, Banner, Typeahead, TimePicker,
    cellEditFactory, Type, moment, NavLink, Alert, AppConfiguration,
    NoserLoading, Noser, sizePerPageRenderer, DatePicker, Modal
} 
from '../../noser-hris-component';

class TrainingEvaluationAndRecommendationModal extends Component {
    constructor(props) {
        super(props)
        this.state = {
            userinfo    :   JSON.parse(sessionStorage.getItem("userData")),
            isloading   :   false,
            isshow      :   false,
            alerttype   :   "",
            message     :   "",
            color       :   "",
            fade        :   true,
            isDisableComplete : true,
            isDisableNot : true,
            isPreTestPassed : true,
            isPreTestFailed : false,
            isPostTestPassed : true,
            isPostTestFailed : false,
            postTestResult : "",
            preTestResult : "",
            recommendationRemarks : "",
            
            //trainingEvaluationRD : JSON.parse(sessionStorage.getItem("trainingEvaluationDataRow")),
        }
    }
    componentDidMount(){
        this.state.userinfo = JSON.parse(sessionStorage.getItem("userData"))
        this.state.trainingEvaluationRD = JSON.parse(sessionStorage.getItem("trainingEvaluationDataRow"))
        this.props.onRefModal(this)
        this.setState({
            //clientId : this.state.trainingEvaluationRD.clientId
        })
       
    }
    componentWillUnmount() {
        this.props.onRefModal(undefined)
    }
    
    initialize=(e)=>{
        this.setState({
            userinfo    :   JSON.parse(sessionStorage.getItem("userData")),
            isloading   :   false,
            isshow      :   false,
            alerttype   :   "",
            message     :   "",
            color       :   "",
            fade        :   true,
            removeBtn   :   true,

            trainingEvaluationRD    :   e,
            clientId                :   e.clientId,
            trainingId              :   e.trainingId,
            referenceId             :   e.id,
            profileId               :   e.profileId,
            employeeName            :   e.employeeName,
            employeeNo              :   e.employeeNo,
            position                :   e.position,
            location                :   e.location,
            client                  :   e.client,
            training                :   e.training,
            dateAttended            :   e.dateAttended,
            batchNo                 :   e.batchNo,
            validity                :   e.validity,
            facilitator                :   e.facilitator,
            recommendationDDL : [
                {"id" : "2", "name" : "Re-Orientation / Re-Training"},
                {"id" : "7", "name" : "Exam Retake"},
                {"id" : "9", "name" : "Completed"},
            ],
            postTestResult:"",
            preTestResult:"",
            isPreTestPassed:false,
            isPreTestFailed:false,
            isPreTestExempt:false,
            isPostTestPassed:false,
            isPostTestFailed:false,
            isPostTestExempt:false,
            hasExam:true,
            disableGenerate : true,
            employeeTrainingList : []
        })
        console.log("init")
        console.log(e)       
        this.GetEmployeeTrainingList(e.profileId);
        this.GetEmployeeExamResults(e.id)
    }

    GetEmployeeTrainingList = async(employeeNo)=> {
        // // //console.log("GetEmployeeTrainingList")
        const params = {
            "IpAddress"     :   "0.0.0.0",
            "ClientId"      :   this.state.userinfo.clientId,
            "UserId"        :   this.state.userinfo.userId,
            "ProfileId"     :   employeeNo ,
            "TrainingId"    :   ""            
        };
        // // //console.log("params 1")
        // // //console.log(params)
        await axios
            .post(AppConfiguration.Setting().noserapiendpoint + "Training/GetEmployeeTrainings",  params) 
            .then(res => {
            const data = res.data;
            // //console.log(" GetEmployeeTrainingList  data 1")
            // //console.log(data.employeeTrainings)
            this.setState({employeeTrainingList:data.employeeTrainings/* .filter( x => x.isPresent === "1") */,isloading:false });
            let checkCompleted = data.employeeTrainings.filter( x => x.isCompleted === "1") 
            //console.log("checkCompleted")
            //console.log(checkCompleted)
            if (checkCompleted.length === 0){
                this.setState({ disableGenerate : false })
            }else {
                this.setState({ disableGenerate : false })
            }
            if(data.status=="0"){
                this.setState({
                    isloading   :   false,
                    alerttype   :   "Error!",
                    isshow      :   true,
                    color       :   "danger",
                    message     :   data.message,
                    fade        :   true,
                    disableGenerate : true
                });
            }
        })
        .catch(error=>{
            this.setState(  {
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade            :   true
            })
        })
    }

    GetEmployeeExamResults = async(refid) => { 
        this.setState({isloading:true})
        const param = {
            "IpAddress"     :   "0.0.0.0",
            "UserId"        :   this.state.userinfo.userId,
            "ClientId"      :   this.state.userinfo.clientId,
            "ReferenceId"   :   refid,
        };
        //console.log("exam param")
        //console.log(param)
        await axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Training/GetEmployeeTrainingExamResults",  param)
        .then(res => {
            const data = res.data;
            //console.log("exam data")
            //console.log(data)
            if(data.exams.length===0)
                this.setState({
                    isPreTestPassed:false,
                    isPreTestFailed:false,
                    isPreTestExempt:true,
                    isPostTestPassed:false,
                    isPostTestFailed:false,
                    isPostTestExempt:true,
                    hasExam:false
                })
            else{
                data.exams.forEach(itm => {
                    if(itm.requestTypeId==="5"){
                        this.setState({isPreTestPassed:itm.isPassed==="1" ? true : false,
                                      isPreTestFailed:itm.isPassed==="0" ? true : false,
                                      preTestResult:itm.score,
                                      hasExam:true})
                    }
                    if(itm.requestTypeId==="6"){
                        this.setState({isPostTestPassed:itm.isPassed==="1" ? true : false,
                                      isPostTestFailed:itm.isPassed==="0" ? true : false,
                                      postTestResult:itm.score,
                                      hasExam:true})
                    }
                });
            }
            this.setState({isloading:false})
        })
        .catch(error=>{
            this.setState(  {
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade            :   true
            })
        })
    }

    GetEmployeeEvaluations = async() => { 
        //this.setState({isloading:true})
        const param = {
            "IpAddress"     :   "0.0.0.0",
            "UserId"        :   this.state.userinfo.userId,
            "ClientId"      :   this.state.trainingEvaluationRD.clientId,
            "TrainingId"    :   this.state.trainingEvaluationRD.trainingId,
            "ProfileId"     :   this.state.trainingEvaluationRD.profileId,
            "ReferenceId"   :  this.state.trainingEvaluationRD.id,
        };
        //console.log("param")
        //console.log(param)
        await axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Training/GetEmployeeEvaluationDetails",  param)
        .then(res => {
            const data = res.data;
            //console.log("GetEmployeeEvaluationDetails for submitting")
            //console.log(data)
            //this.setState({recommendationDG:data.evaluations,isloading:false});
        })
        .catch(error=>{
            this.setState(  {
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade            :   true
            })
        })
    }

    onChangeRecommendation= (e) => {
        //////////////console.log(e)
        if(e.length == 0) {
            this.state.recommendationId     =   ""
            this.state.recommendation   =   ""
            return
        }
        this.state.recommendationId     =   e[0].id
        this.state.recommendation   =   e[0].name
        this.setState({
            isshow:false,
        })

        /* if(this.state.recommendationId === "7" || this.state.recommendationId === "8") {
            this.setState({
                isDisableComplete : false,
                isDisableNot : false,
            })
        } else {
            this.setState({
                isDisableComplete : true,
                isDisableNot : true,
            })
        } */

        if(this.state.recommendationId === "8" || this.state.recommendationId === "9") {
            this.setState({
                isDisableComplete : false,
                isDisableNot : true,
            })
        }

        if(this.state.recommendationId === "2" || this.state.recommendationId === "4" || this.state.recommendationId === "7") {
            this.setState({
                isDisableNot : false,
                isDisableComplete : true,
            })
        }

        if( this.state.isPostTestFailed === true && this.state.recommendationId === "9") {
            this.setState({
                isDisableNot : true,
                isDisableComplete : true,
            })
        }

        if( this.state.isPostTestFailed === true && this.state.recommendationId === "8") {
            this.setState({
                isDisableNot : false,
                isDisableComplete : true,
            })
        }
             
        //////////////console.log(this.state.selectedClient);
       
    }

    onChangeRecommendationRemarks = (e) => {
        this.setState({
            recommendationRemarks : e.target.value
        })
    }

    onChangePreTestResult = (e) => {
        /* const re = /^[0-9\b]+$/;
        if (e.target.value === '' || re.test(e.target.value)) {
            this.setState({preTestResult: e.target.value})
        } */
        this.setState({preTestResult: e.target.value})
    }

    onChangePostTestResult = (e) => {
        /* const re = /^[0-9\b]+$/;
        if (e.target.value === '' || re.test(e.target.value)) {
            this.setState({postTestResult: e.target.value})
        } */
        this.setState({postTestResult: e.target.value})
    }

    handleTrainingEvaluationModalClose = () => {
        this.props.onHide();            
    }

    handleChangePreTestPassed = (e) =>{
        this.state.isPreTestPassed=e.target.checked
        if(this.state.isPreTestPassed===true){
            this.setState({
                isPreTestFailed:false,
                disableGenerate : false
            })
        }
        
    }

    handleChangePreTestFailed = (e) =>{
        if(e.target.name==="isPreTestFailed"){
            this.setState({
                isPreTestFailed: true,
                isPreTestPassed:false,
                isPreTestExempt:false,
                disableGenerate : true
            })
        }
        if(e.target.name==="isPreTestPassed"){
            this.setState({
                isPreTestFailed: false,
                isPreTestPassed:true,
                isPreTestExempt:false
            })
        }
        if(e.target.name==="isPreTestExempt"){
            this.setState({
                isPreTestFailed: false,
                isPreTestPassed:false,
                isPreTestExempt:true
            })
        }
    }

    handleChangePostTestPassed = (e) =>{
        if(e.target.name==="isPostTestFailed"){
            this.setState({
                isPostTestFailed: true,
                isPostTestPassed:false,
                isPostTestExempt:false
            })
        }
        if(e.target.name==="isPostTestPassed"){
            this.setState({
                isPostTestFailed: false,
                isPostTestPassed:true,
                isPostTestExempt:false
            })
        }
        if(e.target.name==="isPostTestExempt"){
            this.setState({
                isPostTestFailed: false,
                isPostTestPassed:false,
                isPostTestExempt:true
            })
        }
    }

    handleChangePostTestFailed = (e) =>{
        this.state.isPostTestFailed=e.target.checked
        if(this.state.isPostTestFailed===true){
            this.setState({
                isPostTestPassed:false,
                disableGenerate : false
            })
            //this.state.recommendationDDL.filter(x=>x.id!=="10")
        }

        if( this.state.isPostTestFailed === true && this.state.recommendationId === "9") {
            this.setState({
                isDisableNot : true,
                isDisableComplete : true,
                disableGenerate : true
            })
        }
        
    }

    onClickIsPassed = async(isPassed) =>{
        
        const params = {
            "IpAddress"         :   "0.0.0.0",
            "UserId"            :   this.state.userinfo.userId,
            "ClientId"          :   this.state.clientId,
            "ReferenceId"       :   this.state.referenceId,
            "ProfileId"         :   this.state.profileId, 
            "TrainingId"        :   this.state.trainingId,
            "RequestTypeId"     :   this.state.recommendationId,
            "PreTestResult"     :   this.state.preTestResult,
            "PreTestPassed"     :   (this.state.isPreTestPassed) ? "1" : "0",
            "PostTestResult"    :   this.state.postTestResult,
            "PostTestPassed"    :   (this.state.isPostTestPassed) ? "1" : "0",
            "Remarks"           :   this.state.recommendationRemarks,
            "IsPassed"          :   isPassed
        };
        //console.log("params")
        //console.log(params)        
        let _checkData = await this.checkingCompleted(this.state.employeeId)
        // // //console.log('_checkData')
        // // //console.log(_checkData)
        if (_checkData === true){
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "Training already completed",
                fade        :   true,
                isDisableComplete : true,
                isDisableNot : true,
                disableGenerate : false
            })
            return
        }else {
            this.setState({
                disableGenerate : false
            })
        }
        await axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Training/AddTrainingEvaluation",  params)
        .then(res => {
            const data = res.data;
            if(data.status=="1"){
                this.refs.recommendationRef.clear()
                this.setState({
                    isloading   :   false,
                    alerttype   :   "Success!",
                    isshow      :   true,
                    color       :   "success",
                    message     :   data.message,
                    fade        :   true,
                    
                    postTestResult:"",
                    preTestResult:"",
                    isPreTestPassed:false,
                    isPreTestFailed:false,
                    isPreTestExempt:false,
                    isPostTestPassed:false,
                    isPostTestFailed:false,
                    isPostTestExempt:false,
                    hasExam:true,
                    isDisableNot            : true,
                    isDisableComplete       : true,
                    recommendationRemarks   : "",
                });
                this.GetEmployeeTrainingList(this.state.profileId)
            }
            else {
                this.setState({
                    isloading   :   false,
                    alerttype   :   "Error!",
                    isshow      :   true,
                    color       :   "danger",
                    message     :   data.message,
                    fade        :   true
                });
            }
        })
        .catch(error=>{
            this.setState({
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade            :   true
            })
        })
    }
    checkingCompleted (checkData){
        let isSubmit = typeof this.state.employeeTrainingList === "undefined" ? [] : this.state.employeeTrainingList
        // // //console.log('isSubmit')
        // // //console.log(isSubmit)
        let _fltrExistingData = isSubmit.filter( x => x.trainingId === this.state.trainingId)
        let isExists = _fltrExistingData.filter( x => x.isCompleted === "1")
        let isCompleted = isExists.length === 0 ? false : true
        // // //console.log('_fltrExistingData')
        // // //console.log(_fltrExistingData)
        // // //console.log('isCompleted')
        // // //console.log(isCompleted)
        return isCompleted
    }


    onClickNavigateCertificateIssuance = () =>{
        // // //console.log(this.state.employeeName)
        // // //console.log(this.state.employeeNo)
        // // //console.log(this.state.position)
        // // //console.log(this.state.client)
        // // //console.log(this.state.location)
        // // //console.log(this.state.training)
        // // //console.log(this.state.batchNo)
        // // //console.log(this.state.dateAttended)
        // // //console.log(this.state.validity)

        let TrainingData = {
            'employeeName' : this.state.employeeName,
            'employeeNo' : this.state.employeeNo,
            'position' : this.state.position,
            'client' : this.state.client,
            'location' : this.state.location,
            'training' : this.state.training,
            'batchNo' : this.state.batchNo,
            'dateAttended' : this.state.dateAttended,
            'validity' : this.state.validity,
            'trainingId' : this.state.trainingId,
            'profileId' : this.state.profileId,
            'employeeId' : this.state.employeeId,
            'present' : this.state.present,
            'status' : this.state.status,
            'statusId' : this.state.statusId,
            'facilitator' : this.state.facilitator,
            'dateCertificate' : this.state.dateCertificate,
        }
        sessionStorage.setItem('TrainingData', JSON.stringify(TrainingData))
    }

    render() {
        
        return(
            <>
            <Modal
                {...this.props}
                return
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                backdrop="static"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        TRAINING &#62;&#62; L and D &#62;&#62; EMPLOYEE TRAINING EVALUATION
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container>
                        <Alert isOpen={this.state.isshow} color={this.state.color} fade={this.state.fade} className={this.state.isshow ? 'display-block' : 'display-none'}>
                            <div className={this.state.color}></div> 
                            {this.state.message}
                        </Alert>
                        <Form.Group as={Row} controlId="formPlaintextEmail">
                            <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                EMPLOYEE NAME
                            </Form.Label>
                            <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                :
                            </Form.Label>
                            <Form.Label column sm="9" style={{fontFamily : "italic", color: "red"}}>
                                {this.state.employeeName}
                            </Form.Label>
                        </Form.Group>
                        <Form.Group as={Row} controlId="formPlaintextEmail">
                            <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                            REFERENCE No.
                            </Form.Label>
                            <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                :
                            </Form.Label>
                            <Form.Label column sm="9" style={{fontFamily : "italic", color: "red"}}>
                                {this.state.employeeNo}
                            </Form.Label>
                        </Form.Group>
                        <Form.Group as={Row} controlId="formPlaintextEmail">
                            <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                            POSITION 
                            </Form.Label>
                            <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                :
                            </Form.Label>
                            <Form.Label column sm="9" style={{fontFamily : "italic", color: "red"}}>
                                {this.state.position}
                            </Form.Label>
                        </Form.Group>
                        <Form.Group as={Row} controlId="formPlaintextEmail">
                            <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                            CLIENT 
                            </Form.Label>
                            <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                :
                            </Form.Label>
                            <Form.Label column sm="9" style={{fontFamily : "italic", color: "red"}}>
                                {this.state.client}
                            </Form.Label>
                        </Form.Group>
                        <Form.Group as={Row} controlId="formPlaintextEmail">
                            <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                            BRANCH 
                            </Form.Label>
                            <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                :
                            </Form.Label>
                            <Form.Label column sm="9" style={{fontFamily : "italic", color: "red"}}>
                                {this.state.location}
                            </Form.Label>
                        </Form.Group>
                        <hr/>
                        <Form.Group as={Row} controlId="formPlaintextEmail">
                            <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                            TRAINING NAME  
                            </Form.Label>
                            <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                :
                            </Form.Label>
                            <Form.Label column sm="9" style={{fontFamily : "italic", color: "red"}}>
                                {this.state.training}
                            </Form.Label>
                        </Form.Group>
                        <Form.Group as={Row} controlId="formPlaintextEmail">
                            <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                            BATCH NUMBER 
                            </Form.Label>
                            <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                :
                            </Form.Label>
                            <Form.Label column sm="9" style={{fontFamily : "italic", color: "red"}}>
                                {this.state.batchNo}
                            </Form.Label>
                        </Form.Group>
                        <Form.Group as={Row} controlId="formPlaintextEmail">
                            <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                            DATE ATTENDED 
                            </Form.Label>
                            <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                :
                            </Form.Label>
                            <Form.Label column sm="9" style={{fontFamily : "italic", color: "red"}}>
                                {this.state.dateAttended}
                            </Form.Label>
                        </Form.Group>
                        <Form.Group as={Row} controlId="formPlaintextEmail">
                            <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                            VALID UNTIL 
                            </Form.Label>
                            <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                :
                            </Form.Label>
                            <Form.Label column sm="9" style={{fontFamily : "italic", color: "red"}}>
                                {this.state.validity}
                            </Form.Label>
                        </Form.Group>
                        <hr/>
                        <Form.Group as={Row} controlId="formPlaintextEmail">
                            <Form.Label column sm="2" style={{fontWeight : "bold", fontSize : "13px"}}>
                            PRE - TEST 
                            </Form.Label>
                            <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                
                            </Form.Label>
                            <Col sm="4"></Col>
                            <Form.Label column sm="2" style={{fontWeight : "bold", fontSize : "13px"}}>
                            POST - TEST 
                            </Form.Label>
                            {/* <Form.Label column sm="9" style={{fontFamily : "italic", color: "red"}}>
                                {this.state.location}
                            </Form.Label> */}
                        </Form.Group>
                        <Form.Group as={Row} controlId="formPlaintextEmail">
                            <Form.Label className="ml-auto" column sm="2" style={{fontWeight : "bold"}}>
                            SCORE 
                            </Form.Label>
                            <Col sm="3">
                                <Form.Control 
                                    ref="preTestResult"
                                    name="preTestResult"
                                    value={this.state.preTestResult}
                                    onChange={this.onChangePreTestResult}
                                    autoComplete="off"
                                    maxLength="3"
                                    disabled={this.state.hasExam}
                                />
                            </Col>
                            <Col sm="2"></Col>
                            <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                            SCORE 
                            </Form.Label>
                            <Col sm="3">
                                <Form.Control 
                                    ref="postTestResult"
                                    name="postTestResult"
                                    value={this.state.postTestResult}
                                    onChange={this.onChangePostTestResult}
                                    autoComplete="off"
                                    maxLength="3"
                                    disabled={this.state.hasExam}
                                />
                            </Col>
                            {/* <Form.Label column sm="9" style={{fontFamily : "italic", color: "red"}}>
                                {this.state.location}
                            </Form.Label> */}
                        </Form.Group>
                        <Form.Group as={Row} controlId="formPlaintextEmail">
                            <Col sm="2">
                            </Col>
                            <Col sm="3">
                                <Form.Check 
                                    inline
                                    type="radio"  
                                    label="PASSED" 
                                    name="isPreTestPassed"
                                    value={this.state.isPreTestPassed}
                                    checked={this.state.isPreTestPassed}
                                    onChange={this.handleChangePreTestFailed}
                                    style={{fontWeight : "bold",marginLeft:"12px"}}
                                    disabled={this.state.hasExam}
                                />
                                <Form.Check 
                                    inline
                                    type="radio" 
                                    label="FAILED" 
                                    name="isPreTestFailed"
                                    value={this.state.isPreTestFailed}
                                    checked={this.state.isPreTestFailed}
                                    onChange={this.handleChangePreTestFailed}
                                    style={{fontWeight : "bold",marginLeft:"12px"}}
                                    disabled={this.state.hasExam}
                                />
                                <Form.Check 
                                    inline
                                    type="radio" 
                                    label="EXEMPT" 
                                    name="isPreTestExempt"
                                    value={this.state.isPreTestExempt}
                                    checked={this.state.isPreTestExempt}
                                    onChange={this.handleChangePreTestFailed}
                                    style={{fontWeight : "bold",marginLeft:"12px"}}
                                    disabled={this.state.hasExam}
                                />
                            </Col>
                            <Col sm="4">
                            </Col>
                            <Col sm="3">
                                <Form.Check 
                                    inline
                                    type="radio"
                                    label="PASSED" 
                                    name="isPostTestPassed"
                                    value={this.state.isPostTestPassed}
                                    checked={this.state.isPostTestPassed}
                                    onChange={this.handleChangePostTestPassed}
                                    style={{fontWeight : "bold",marginLeft:"12px"}}
                                    disabled={this.state.hasExam}
                                />
                                <Form.Check 
                                    inline
                                    type="radio"
                                    label="FAILED" 
                                    name="isPostTestFailed"
                                    value={this.state.isPostTestFailed}
                                    checked={this.state.isPostTestFailed}
                                    onChange={this.handleChangePostTestPassed}
                                    style={{fontWeight : "bold",marginLeft:"12px"}}
                                    disabled={this.state.hasExam}
                                    
                                />
                                <Form.Check 
                                    inline
                                    type="radio" 
                                    label="EXEMPT" 
                                    name="isPostTestExempt"
                                    value={this.state.isPostTestExempt}
                                    checked={this.state.isPostTestExempt}
                                    onChange={this.handleChangePostTestPassed}
                                    style={{fontWeight : "bold",marginLeft:"12px"}}
                                    disabled={this.state.hasExam}
                                />
                            </Col>
                        </Form.Group>
                        
                        <Form.Group as={Row} controlId="formPlaintextEmail">
                            <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                RECOMMENDATION
                            </Form.Label>
                            <Col sm="10">
                                <Typeahead
                                    ref="recommendationRef"
                                    labelKey='name'
                                    id="basic-example"
                                    onChange={this.onChangeRecommendation}
                                    options={this.state.recommendationDDL}
                                />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} controlId="formPlaintextEmail">
                            <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                REMARKS
                            </Form.Label>
                            <Col sm="10">
                                <Form.Control 
                                    type="text"
                                    name="recommendationRemarks"
                                    value={this.state.recommendationRemarks}
                                    onChange={this.onChangeRecommendationRemarks} 
                                    autoComplete="off"
                                />
                            </Col>
                        </Form.Group>
                        <hr/>
                        <Form.Group className="mt-5" as={Row} controlId="formPlaintextEmail">
                        <Col sm="2">
                            <Button disabled={this.state.disableGenerate} href="/TrainingCertificateIssuance" onClick={ this.onClickNavigateCertificateIssuance} variant="success" >Generate Certificate</Button>
                        </Col>
                        <Col sm="2">
                        </Col>
                        <Col sm="8">
                        <ButtonToolbar>
                            <Button disabled={this.state.isDisableComplete} className="ml-auto noser-button-mr1" variant="info" onClick={() => this.onClickIsPassed('1')}>COMPLETED</Button>&nbsp;&nbsp;&nbsp;
                            <Button disabled={this.state.isDisableNot} variant="danger noser-button-mr1" onClick={() => this.onClickIsPassed('0')}>NOT</Button>&nbsp;&nbsp;&nbsp;
                            <Button onClick={this.props.onHide} variant="danger noser-button-mr15">CLOSE</Button>
                        </ButtonToolbar>
                        </Col>
                    </Form.Group>
                    </Container>
                </Modal.Body>
                {/* <Modal.Footer>
                        <ButtonToolbar>
                            <Button variant="info" onClick = { this.handleClickCompleted }>COMPLETED</Button>&nbsp;&nbsp;&nbsp;
                            <Button variant="danger" onClick = { this.handleClickNot }>NOT</Button>&nbsp;&nbsp;&nbsp;
                            <Button  onClick={this.props.onHide} variant="danger">CLOSE</Button>
                        </ButtonToolbar>
                </Modal.Footer> */}
                <NoserLoading show={this.state.isloading} />
                
        </Modal>
        </>
        );
    }
}
export default TrainingEvaluationAndRecommendationModal