import {
    React,Component,BootstrapTable, Type,
    paginationFactory,Button, ButtonToolbar, Card,
    Form, Col, Row, Container, axios, Typeahead, Banner, TimePicker,
    cellEditFactory ,moment, NavLink, Alert, AppConfiguration,
    NoserLoading, Noser, sizePerPageRenderer, DatePicker
} 
from '../../../noser-hris-component';
import type from 'react-bootstrap-table2-editor';

import ToolkitProvider, { CSVExport } from 'react-bootstrap-table2-toolkit';
import 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css';

class AdminJobOffer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userinfo        :   [],
            isloading       :   false,
            isshow          :   false,
            alerttype       :   "",
            message         :   "",
            color           :   "",
            fade            :   true,

            clientDDL : [],
            employeeDDL : [],
            positionDDL : [],
            branchDDL : [],
            dateFrom : "",
            dateTo : "",
            adminJobOfferGrid : [],
            statusDDL : [],
            statusId : "",
            selectedPositionId : "",
            referenceNo : "",
            selectedApplicationFormId : "",
        }
    }

    componentDidMount(){
        const sleep = (milliseconds) => {return new Promise(resolve => setTimeout(resolve, milliseconds))}
        this.state.userinfo = JSON.parse(sessionStorage.getItem("userData"))
        this.GetClients();
    }

    GetClients = async() => {
        this.setState({isloading:true})
        const param = {
            "IpAddress"     :   "0.0.0.0",
            "ClientId"      :   this.state.userinfo.clientId,
            "UserId"        :   this.state.userinfo.userId,
        };

        //console.log("Start Get Params")
        //console.log(param)
        await axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Client/GetClientList",  param)
        .then(res => {
            const data = res.data;

            //console.log("GetClientList")
            //console.log(data)
            this.setState({clientDDL:data.clients,isloading:false});
        })
        .catch(error=>{
            this.setState(  {
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade            :   true
            })
        })
        this.GetPosition();
    }

    GetJobStatus = () =>{
        this.setState({isloading:true})
        const getParams = {
            "IpAddress"     :   "0.0.0.0",
            "ClientId"      :   this.state.userinfo.clientId,
            "UserId"        :   this.state.userinfo.userId,
            "Code"          :   "0027"
        };

        axios
        .post( AppConfiguration.Setting().noserapiendpoint + "Maintenance/GetDataReferences",  getParams)
        .then(res => {
            const data = res.data;
            //console.log("Get Reason");
            //console.log(data);
            this.setState({ statusDDL : data.dataReferences,isloading:false });
         })
    }
    onChangeStatus = (e) => {
        this.setState({isshow:false})
        if(e.length == 0) {
            this.state.statusId=""
            return
        } 
        this.state.statusId = e[0].id
        
    }

    onChangeClient = (e) => {
        if(e.length == 0) {
            this.state.selectedClientName=""
            this.state.selectedClientId=""
            return
        } 
        this.state.selectedClientId = e[0].id
        this.state.selectedClientName = e[0].name

        this.getEmployees();
        this.getClientLocation();
    }

    getEmployees = async() => {

        this.setState({isloading:true,})
        const jobParams = {
            "IpAddress":"0.0.0.0",
            "UserId":this.state.userinfo.userId,
            "ClientId":this.state.selectedClientId,
            "ReferenceNo":"" ,
            "ApplicationFormId":"",
            "PositionId":"",
            "StatusId":"",
            "RoleId" : this.state.userinfo.roleId
        };
        //console.log("jobParams")
        //console.log(jobParams)
        await axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Administrator/GetJobOfferForApprovals",  jobParams)
        .then(res => {
            const data = res.data;
            //console.log("GetJobOfferForApprovals");
            //console.log(data);
            this.setState({ employeeDDL: data.jobOffers, isloading:false});
            
        })
        
    }

    onChangeBranch = (e) => {
        if(e.length == 0) {
            this.state.selectedBranchId=""
            return
        } 
        this.state.selectedBranchId = e[0].id
    }

    GetPosition = async() => {
        this.setState({isloading:true})
        const positionParams = {
            "IpAddress":"0.0.0.0",
            "ClientId"      :   this.state.userinfo.clientId,
            "UserId"        :   this.state.userinfo.userId,
            "SectionId":"",
            "DepartmentId":"",
            "Name":""
        };
        await axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Maintenance/GetPositions",  positionParams)
        .then(res => {
            const data = res.data;
            ////////console.log("Get Position Name");
            ////////console.log(data);
            this.setState({ positionDDL  : data.positions, isloading:false });
        })
        this.GetJobStatus();
        
    }

    getClientLocation(){
        this.setState({isloading:true})

        const getParams = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.selectedClientId,
            "UserId":this.state.userinfo.userId,
            "ClientName": this.state.selectedClientName,
            "City": "",
            "Province": "",
            "Region": ""
    
        }
        axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Client/GetClientLocations", getParams)
        .then(res => {
            const data = res.data;
            //////////////console.log("Client Location");
            //////////////console.log(data);
            this.setState({
                branchDDL	:   res.data.locations ? res.data.locations : [],
                isloading	            :   false
            })
            /* if(data.locations.length=="0"){
                this.setState({
                    isloading   :   false,
                    alerttype   :   "Error!",
                    isshow      :   true,
                    color       :   "danger",
                    message     :   data.message,
                    fade        :   true
                });
            } */
        })
    }

    onChangeEmployees = (e) => {
        if(e.length == 0) {
            this.setState   ({
                selectedFullName:"",
                positionId : "", 
                referenceNo: "",
                selectedApplicationFormId:""
            })
            return
        }
        this.setState({
            selectedFullName : e[0].memberName,
            referenceNo : e[0].referenceNo,
            selectedPositionId : e[0].positionId,
            selectedApplicationFormId : e[0].applicationFormId
        })
        this.setState({isloading:false,})
    }

    onChangePosition = (e) => {
        if(e.length == 0) {
            this.state.selectedPositionId=""
            return
        } 
        this.state.selectedPositionId = e[0].id
    }
    
    handleChangeDateFrom = date => {
        ////////console.log(date)
        this.setState({
            dateFrom: date,
            isshow:false,
        })
    }

    handleChangeDateTo = date => {
        ////////console.log(date)
        this.setState({
            dateTo: date,
            isshow:false,
        })
    }
    handleSearchClick = async() => {
        this.setState({isloading:true,})
        let dateFrom = (this.state.dateFrom) ? moment(this.state.dateFrom).format('MM/DD/YYYY') : ""
        let dateTo = (this.state.dateTo) ? moment(this.state.dateTo).format('MM/DD/YYYY') : ""
        const jobParams = {
            "IpAddress":"0.0.0.0",
            "UserId":this.state.userinfo.userId,
            "ClientId":this.state.selectedClientId,
            "ReferenceNo":this.state.referenceNo ,
            "ApplicationFormId":this.state.selectedApplicationFormId,
            "PositionId":this.state.selectedPositionId,
            "StatusId":this.state.statusId,
            "RoleId" : this.state.userinfo.roleId,
            "StartDate":dateFrom,
            "EndDate":dateTo
        };
        console.log("jobParams")
        console.log(jobParams)
        await axios
        //.post(AppConfiguration.Setting().noserapiendpoint + "Recruitment/GetJobOfferForApprovals",  jobParams)
        .post(AppConfiguration.Setting().noserapiendpoint + "Administrator/GetJobOfferForApprovals",  jobParams)
        .then(res => {
            const data = res.data;
            console.log("GetJobOfferForApprovals");
            console.log(data.jobOffers);
            let arr = []
            let statusAdmin = ""
            let approvedByAdmin = ""
            let approvedDateByAdmin = ""
            let TLstatus = ""
            let approvedByTL = ""
            let approvedDateTL = ""
            // for(let x = 0; x < data.jobOffers.length; x++){
            //     if(data[x]["statusId"] === "6" && data[x]["statusId"] === "6" ){

            //     }
            // }

            // data.jobOffers.forEach(x => {
            //     if(x.statusId === "6" || x.statusId === "5"){
            //         statusAdmin = x.status
            //         approvedByAdmin = x.approvedBy
            //         approvedDateByAdmin = x.approvedDate
            //     }
            //     if(x.statusId === "4" || x.statusId === "3"){
            //         TLstatus = x.status
            //         approvedByTL = x.approvedBy
            //         approvedDateTL = x.approvedDate
            //     }
            //     const obj = {
            //         "dateHired" : x.dateHired,
            //         "lastName" : x.lastName,
            //         "firstName" : x.firstName,
            //         "suffixName" : x.suffixName,
            //         "middleName" : x.middleName,
            //         "client" : x.client,
            //         "position" : x.position,
            //         "employeeStatusType" : x.employeeStatusType,
            //         "contractDateStart" : x.contractDateStart,
            //         "contractDateEnd" : x.contractDateEnd,
            //         "salaryOffered" : x.salaryOffered,
            //         "ecolaValue" : x.ecolaValue,
            //         "colaValue" : x.colaValue,
            //         "seaValue" : x.seaValue,
            //         "location" : x.location,
            //         "mobileNumber" : x.mobileNumber,
            //         "status" : x.status,
            //         "preparedBy" : x.preparedBy,
            //         "approvedDateTL" : approvedDateTL,
            //         "approvedByTL" : approvedByTL,
            //         "approvedDateByAdmin" : approvedDateByAdmin,
            //         "approvedByAdmin" : approvedByAdmin,
            //     }
            //     arr.push(obj)
            // });
            //console.log("arr")
            //console.log(arr)
            this.setState({ adminJobOfferGrid: data.jobOffers, isloading:false});
            if(data.status=="0"){
                this.setState({
                    isloading   :   false,
                    alerttype   :   "Error!",
                    isshow      :   true,
                    color       :   "danger",
                    message     :   data.message,
                    fade        :   true
                });
            }
            
        })
        .catch(error=>{
            this.setState({
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade            :   true
            })
        })

    }

    onLoadData(){
        this.setState({isloading:true,})
        const jobParams = {
            "IpAddress":"0.0.0.0",
            "UserId":this.state.userinfo.userId,
            "ClientId":"",
            "ReferenceNo":"" ,
            "ApplicationFormId":"",
            "PositionId":"",
            "StatusId":""
        };
        //console.log("jobParams")
        //console.log(jobParams)
        axios
        //.post(AppConfiguration.Setting().noserapiendpoint + "Recruitment/GetJobOfferForApprovals",  jobParams)
        .post(AppConfiguration.Setting().noserapiendpoint + "Administrator/GetJobOfferForApprovals",  jobParams)
        .then(res => {
            const data = res.data;
            //console.log("GetJobOfferForApprovals");
            //console.log(data);
            this.setState({ adminJobOfferGrid: data.jobOffers, isloading:false});
            if(data.status=="0"){
                this.setState({
                    isloading   :   false,
                    alerttype   :   "Error!",
                    isshow      :   true,
                    color       :   "danger",
                    message     :   data.message,
                    fade        :   true
                });
            }
            
        })
        .catch(error=>{
            this.setState({
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade            :   true
            })
        })
    }

    render() {
        const { ExportCSVButton } = CSVExport;

        const adminJobOfferColumn = [
            {
                text	    : 'JO_DATE',
                editable    :   false,
                dataField   :   "dateHired",
            },
            {
                text	    : 'ACCOUNT',
                editable    :   false,
                dataField   :   "",
            },
            {
                text	    : 'CLIENT',
                editable    :   false,
                dataField   :   "client",
            },
            {
                text	    : 'LAST_NAME',
                editable    :   false,
                dataField   :   "lastName",
            },
            {
                text	    : 'FIRST_NAME',
                editable    :   false,
                dataField   :   "firstName",
            },
            {
                text	    : 'SUFFIX_NAME',
                editable    :   false,
                dataField   :   "suffixName",
            },
            {
                text	    : 'MIDDLE_NAME',
                editable    :   false,
                dataField   :   "middleName",
            },
            {
                text	    : 'CONTACT_NUMBER',
                editable    :   false,
                dataField   :   "mobileNumber",
            },
            {
                text	    : 'POSITION',
                editable    :   false,
                dataField   :   "position",
            },
            {
                text	    : 'EMPLOYMENT_STATUS',
                editable    :   false,
                dataField   :   "employeeStatusType",
            },
            {
                text	    : 'CONTRACT_START_DATE',
                editable    :   false,
                dataField   :   "contractDateStart",
            },
            {
                text	    : 'CONTRACT_END_DATE',
                editable    :   false,
                dataField   :   "contractDateEnd",
            },
            {
                text	    : 'RATE',
                editable    :   false,
                dataField   :   "salaryOffered",
            },
            {
                text	    : 'ECOLA',
                editable    :   false,
                dataField   :   "ecolaValue",
            },
            {
                text	    : 'COLA',
                editable    :   false,
                dataField   :   "colaValue",
            },
            {
                text	    : 'SEA',
                editable    :   false,
                dataField   :   "seaValue",
            },
            {
                text	    : 'BRANCH',
                editable    :   false,
                dataField   :   "location",
            },/* 
            {
                text	    : 'SOURCE',
                editable    :   false,
                dataField   :   "employeeName",
            },
            {
                text	    : 'NAME_OF_REFERRER',
                editable    :   false,
                dataField   :   "employeeName",
            },  */
            /* {
                text	    : 'RECRUITER',
                editable    :   false,
                dataField   :   "employeeName",
            }, */
            {
                text	    : 'JO_STATUS',
                editable    :   false,
                dataField   :   "status",
            },
            {
                text	    : 'DATE_SUBMITTED_BY_RECRUITMENT',
                editable    :   false,
                dataField   :   "preparedDate",
            },
            {
                text	    : 'PREPARED_BY',
                editable    :   false,
                dataField   :   "preparedBy",
            }, 
            {
                text	    : 'DATE_APPROVED_BY_TL_RECRUITMENT',
                editable    :   false,
                dataField   :   "approvedDate",
            },
            {
                text	    : 'TL_APPROVER',
                editable    :   false,
                dataField   :   "approvedBy",
            },
            {
                text	    : 'DATE_APPROVED_BY_ADMIN',
                editable    :   false,
                dataField   :   "notedDate",
            },
            {
                text	    : 'ADMIN_APPROVER',
                editable    :   false,
                dataField   :   "notedBy",
            },
        ] 

            const adminJobOfferSelectRow = {
                mode: 'checkbox',
                clickToSelectAndEditCell: true,
                onSelect: (row, isSelect, rowIndex, e) => {
                    this.state.adminJobOfferGrid.map(function(item,i){
                        if(item.id===row.id)
                        {
                            item.isDeleted = isSelect ? "1" : "0"
                            item.isModified = isSelect ? "1" : "0"
                        }
                    })
                }
            };
    
            const adminJobOfferRowEvents = {
                onClick: (e, row, rowIndex) => {
                }
            };

        return(
            <div>

                <Banner />
                <Container className="themed-container" fluid={true}>
                    <Card className="mt-5">
                        <Card.Header>REPORT >> ADMIN - JOB OFFER</Card.Header>
                        <Card.Body>
                            <Form >
                                <Alert isOpen={this.state.isshow} color={this.state.color} fade={this.state.fade} className={this.state.isshow ? 'display-block' : 'display-none'}>
                                    <div className={this.state.color}></div> 
                                    {this.state.message}
                                </Alert>
                                <Form.Group as={Row} controlId="formPlaintextEmail">
                                    <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                        CLIENT
                                    </Form.Label>
                                    <Col sm="11">
                                        <Typeahead
                                            labelKey='name'
                                            id="basic-example"
                                            onChange={this.onChangeClient}
                                            options={this.state.clientDDL}
                                        />
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} controlId="formPlaintextEmail">
                                    <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                        EMPLOYEE
                                    </Form.Label>
                                    <Col sm="11">
                                        <Typeahead
                                            labelKey='memberName'
                                            id="basic-example"
                                            onChange={this.onChangeEmployees}
                                            options={this.state.employeeDDL}
                                    />
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} controlId="formPlaintextEmail">
                                    <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                        POSITION
                                    </Form.Label>
                                    <Col sm="11">
                                        <Typeahead
                                            labelKey='name'
                                            id="basic-example"
                                            onChange={this.onChangePosition}
                                            options={this.state.positionDDL}
                                    />
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} controlId="formPlaintextEmail">
                                    <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                        BRANCH
                                    </Form.Label>
                                    <Col sm="11">
                                        <Typeahead
                                            labelKey='name'
                                            id="basic-example"
                                            onChange={this.onChangeBranch}
                                            options={this.state.branchDDL}
                                        />
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} controlId="formPlaintextEmail">
                                    <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                        STATUS
                                    </Form.Label>
                                    <Col sm="11">
                                        <Typeahead
                                            labelKey='name'
                                            id="basic-example"
                                            onChange={this.onChangeStatus}
                                            options={this.state.statusDDL}

                                        />
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} controlId="formPlaintextEmail">
                                    <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                        DATE FROM
                                    </Form.Label>
                                    <Col sm="2">
                                        <DatePicker
                                            ref='dateFrom'
                                            selected={this.state.dateFrom}
                                            onChange={this.handleChangeDateFrom}
                                            minDate={this.minDate}
                                            value={this.props.dateFrom}
                                            dateFormat={"MM/dd/yyyy"}
                                            className="form-control"
                                        />  
                                    </Col>
                                    <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                        DATE TO
                                    </Form.Label>
                                    <Col sm="2">
                                        <DatePicker
                                            ref='dateTo'
                                            selected={this.state.dateTo}
                                            onChange={this.handleChangeDateTo}
                                            minDate={this.minDate}
                                            value={this.props.dateTo}
                                            dateFormat={"MM/dd/yyyy"}
                                            className="form-control"
                                        />
                                    </Col>
                                </Form.Group>

                                <ButtonToolbar sm={12}>
                                    <Button variant="success" className="ml-auto" onClick = { this.handleSearchClick }>
                                        SEARCH
                                    </Button>
                                </ButtonToolbar>                              
                                <ToolkitProvider
                                    keyField="id"   
                                    data={ this.state.adminJobOfferGrid }
                                    columns={ adminJobOfferColumn }
                                    exportCSV={ {
                                        fileName: "Admin - Job Offer Reports.csv",
                                    } }
                                    >
                                    {
                                        props => (
                                        <div className="mt-3">
                                            <BootstrapTable
                                                { ...props.baseProps } 
                                                /* keyField = "id"
                                                data = { this.state.employeeListTable }
                                                columns = { columnEmployee }
                                                pagination={ paginationFactory({sizePerPageRenderer}) }
                                                rowClasses="noser-table-row-class"
                                                striped
                                                hover
                                                condensed
                                                selectRow = { employeeRow } */


                                                striped
                                                hover
                                                condensed
                                                pagination={ paginationFactory({sizePerPageRenderer}) }
                                                keyField = "id"
                                                data = { this.state.adminJobOfferGrid }
                                                noDataIndication={ () => <div>No record found.</div> }
                                                columns = { adminJobOfferColumn }
                                            />
                                            <ButtonToolbar>
                                                <ExportCSVButton className="btn btn-info ml-auto" { ...props.csvProps }>Export CSV!!</ExportCSVButton>
                                                &nbsp;&nbsp;&nbsp;
                                                <NavLink to="/home">
                                                    <Button className="ml-auto" variant="danger" href="/banner">
                                                        Close
                                                    </Button>
                                                </NavLink>
                                            </ButtonToolbar>
                                        </div>
                                        )
                                    }
                                </ToolkitProvider>
                            </Form>
                        </Card.Body>
                    </Card>
                </Container>
                <NoserLoading show={this.state.isloading} />
            </div> 
            
        )
    }
}

export default AdminJobOffer;