import {
    React,Component,BootstrapTable,
    paginationFactory,Button, ButtonToolbar, Card, 
    Form, Col, Row, axios, Container, Banner, Typeahead, TimePicker,
    cellEditFactory, Type, moment, NavLink, Alert, AppConfiguration,
    NoserLoading, Noser, sizePerPageRenderer, DatePicker, Modal
} 
from '../../../noser-hris-component';

const showSecond = false;
const str = showSecond ? 'HH:mm:ss' : 'HH:mm';

class TrainingScheduleCreate extends Component {
    constructor(props) {
        super(props)
        this.ref = React.createRef();
        this.state = {
            userinfo    :   JSON.parse(sessionStorage.getItem("userData")),
            isloading   :   false,
            isshow      :   false,
            alerttype   :   "",
            message     :   "",
            color       :   "",
            fade        :   true,
            
            dateFrom                    :   "",
            dateTo                      :   "",
            venue                       :   "",
            facilitated                 :   "",
            selectedTrainingId          :   "",
            trainingAutocomplete        :   [],
            description                 :   "",
            facilitator                 :   "",
            batchNo                     :   "",
            participantLimit            :   "",
            checkIsRequired             :   "",
            partNo                      :   "",   
            platform                    :   "", 

            platformDDL  :    [
                {"name": 'FACE TO FACE (F2F)',"value": "1",},
                {"name":"Online Distance Learning (ODL)","value":"2"},
            ],
            preTestSetNameDDL : [
                {"id" : "0", "name" : "SET A", "isPostTest" : "0"},
                {"id" : "1", "name" : "SET B", "isPostTest" : "0"},
                {"id" : "2", "name" : "SET C", "isPostTest" : "0"},
            ],
            postTestSetNameDDL : [
                {"id" : "0", "name" : "SET A", "isPostTest" : "1"},
                {"id" : "1", "name" : "SET B", "isPostTest" : "1"},
                {"id" : "2", "name" : "SET C", "isPostTest" : "1"},
            ],
            setName : "",
            isPostTest : "",
        }
    }
    componentDidMount(){
        this.state.userinfo = JSON.parse(sessionStorage.getItem("userData"))
        this.props.onRefModal(this)
        this.GetTrainingName()
    }
    componentWillUnmount() {
        this.props.onRefModal(undefined)
    }
    initialize=(e)=>{
        this.setState({
            userinfo    :   JSON.parse(sessionStorage.getItem("userData")),
            isloading   :   false,
            isshow      :   false,
            alerttype   :   "",
            message     :   "",
            color       :   "",
            fade        :   true,
            name        :   ''
        })
    }
    handleSubmit = (e) =>{
        
    }
    handleModalClose = () => {
        this.props.onHide();            
    }

    GetTrainingName = async() =>{
        this.setState({isloading:true})
        const param = {
            "IpAddress"     :   "0.0.0.0",
            "ClientId"      :   this.state.userinfo.clientId,
            "UserId"        :   this.state.userinfo.userId,
        };
        await axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Maintenance/GetTrainingFilters",  param)
        .then(res => {
            const data = res.data;
            console.log("create123")
            console.log(data)
            this.setState({trainingAutocomplete:data.trainings,isloading:false});
        })
        .catch(error=>{
            this.setState(  {
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade            :   true
            })
        })
    }

    onChangeTraining = (e) => {
        if(e.length == 0) {
            this.state.selectedTrainingId     =   ""
            this.state.selectedTraining       =  ""
            return
        }
        this.state.selectedTrainingId    =   e[0].id
        this.state.selectedTraining      =   e[0].name
        this.setState({
            isshow:false,
        })
    }

    onChangePlatform = (e) => {
        if(e.length == 0) {
            this.state.platform       =  ""
            return
        }
        this.state.platform      =   e[0].name
        this.setState({
            isshow:false,
        })
        console.log("this.state.selectedTrainingId ")
        console.log(this.state.platform )
    }

    onChangeDescription = (e) => {
        this.setState({
            description : e.target.value.toUpperCase(),
            isshow      :   false,
        })
    }

    // onChangePlatform = (e) => {
    //     this.setState({
    //         platform : e.target.value.toUpperCase(),
    //         isshow      :   false,
    //     })
    // }

    onChangeParticipantLimit = (e) => {
        const re = /^[0-9\b]+$/;
        if (e.target.value === '' || re.test(e.target.value)) {
            this.setState({
                participantLimit   :   e.target.value,
                isshow      :   false,
            })
            //this.state.timeLimit = e.target.value
        }
    }


    onChangeVenue = (e) => {
        this.setState({
            venue : e.target.value.toUpperCase(),
            isshow      :   false,
        })
    }

    onChangeFacilitator = (e) => {
        this.setState({
            facilitator : e.target.value.toUpperCase(),
            isshow      :   false,
        })
    }

    onChangeBatchNo = (e) => {
        this.setState({
            batchNo   :   e.target.value,
            isshow      :   false,
        })
    }

    handleChangeDateFrom = date => {
        //////console.log(date)
        this.setState({
            dateFrom: date,
            isshow:false,
        })
    }

    handleChangeDateTo = date => {
        //////console.log(date)
        this.setState({
            dateTo: date,
            isshow:false,
        })
    }

    onChangeFrom = (Fromvalue) => {
        this.state.Fromvalue = Fromvalue && Fromvalue.format(str) 
        this.setState({
            isshow      :   false,
        })
    } 

    onChangeTo = (Tovalue) => {
        this.state.Tovalue = Tovalue && Tovalue.format(str)
        this.setState({
            isshow      :   false,
        })
    }
    handleKeyUp = (e) =>{

    }

    onChangePreTestSetName = (e) => {
        if(e.length == 0) {
            this.state.setName       =  ""
            return
        }
        this.state.setName      =   e[0].name
        this.state.isPostTest      =   e[0].isPostTest
        this.setState({
            isshow:false,
        })
    }

    onChangePostTestSetName = (e) => {
        if(e.length == 0) {
            this.state.setName       =  ""
            return
        }
        this.state.setName      =   e[0].name
        this.state.isPostTest      =   e[0].isPostTest
        this.setState({
            isshow:false,
        })
    }
    handleSaveClick = event => {
        let dateFrom    =   moment(this.state.dateFrom).format('MM/DD/YYYY');
        let dateTo      =   moment(this.state.dateTo).format('MM/DD/YYYY');
        this.setState({
            isloading   :   true
        })

        const saveParams = {
            "IpAddress"         :   "0.0.0.0",
            "UserId"            :   this.state.userinfo.userId,
            "ClientId"          :   this.state.userinfo.clientId,
            "TrainingId"        :   this.state.selectedTrainingId,
            "ScheduleName"      :   this.state.description,
            "Description"       :   this.state.description,
            "BatchNo"           :   this.state.batchNo,
            "Facilitator"       :   this.state.facilitator,
            "Venue"             :   this.state.venue,
            "ParticipantLimit"  :   this.state.participantLimit,
            "FromDate"          :   dateFrom,
            "FromTime"          :   this.state.Fromvalue,
            "ToDate"            :   dateTo,
            "ToTime"            :   this.state.Tovalue,
            "ExamSet"           :   this.state.setName,
            "IsPostTest"        :   this.state.isPostTest,
            "PartNo"            :   "",
            "SessionName"       :  this.state.platform,
            "IsRequired"        :   "1",
            "CreatedBy"         :   this.state.userinfo.userId,
        };

        console.log("Save Params")
        console.log(saveParams)
        // return
         axios
             .post(
                 AppConfiguration.Setting().noserapiendpoint + "Training/AddTrainingSchedule",  saveParams
             )
             .then(res => {
                const data = res.data;
                console.log("Save Results")
                console.log(data)
                if(data.status=="1"){
                this.setState({
                        isloading   :   false,
                        alerttype   :   "Success!",
                        isshow      :   true,
                        color       :   "success",
                        message     :   data.message,
                        fade        :   true
                    });
                
                }
                else {
                    this.setState({
                        isloading   :   false,
                        alerttype   :   "Error!",
                        isshow      :   true,
                        color       :   "danger",
                        message     :   data.message,
                        fade        :   true
                    });
                }
            })
            .catch(error=>{
                this.setState({
                    isloading       :   false,
                    alerttype       :   "Error!",
                    isshow          :   true,
                    color           :   "danger",
                    message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                    fade            :   true
                })
            })

    }

    onModalClose = () => {
        this.props.onHide();
    }

    render() {
    return(
        
            <Modal
                {...this.props}
                return
                size="xl"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                backdrop="static"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        CREATE TRAINING SCHEDULE
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container>
                        <Alert isOpen={this.state.isshow} color={this.state.color} fade={this.state.fade} className={this.state.isshow ? 'display-block' : 'display-none'}>
                            <div className={this.state.color}></div> 
                            {this.state.message}
                        </Alert>
                        <Form.Group as={Row} controlId="formPlaintextEmail">
                            <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                            TRAINING TITLE/PROGRAM
                            </Form.Label>
                            <Col sm="10">
                                <Typeahead
                                    labelKey='name'
                                    id="basic-example"
                                    onChange={this.onChangeTraining}
                                    options={this.state.trainingAutocomplete}
                                />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} controlId="formPlaintextEmail">
                            <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                            BATCH NUMBER
                            </Form.Label>
                            <Col sm="10">
                                <Form.Control 
                                    ref="batchNo"
                                    name="batchNo"
                                    value={this.state.batchNo}
                                    onChange={this.onChangeBatchNo}
                                    autoComplete="off"
                                    
                                />
                            </Col>
                        </Form.Group>
                        <Card className="mt-5">
                            <Card.Header>DATE OF TRAINING</Card.Header>
                            <Card.Body className="mt-3">
                                <Form.Group as={Row} controlId="formPlaintextEmail">
                                    <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                    DATE FROM
                                    </Form.Label>
                                    <Col sm="2">
                                        <DatePicker
                                            ref='dateFrom'
                                            selected={this.state.dateFrom}
                                            onChange={this.handleChangeDateFrom}
                                            minDate={this.minDate}
                                            value={this.props.dateFrom}
                                            dateFormat={"MM/dd/yyyy"}
                                            className="form-control"
                                        />
                                    </Col>
                                    <Col sm="1">
                                    </Col>
                                    <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                    DATE TO
                                    </Form.Label>
                                    <Col sm="2">
                                        <DatePicker
                                            ref='dateTo'
                                            selected={this.state.dateTo}
                                            onChange={this.handleChangeDateTo}
                                            minDate={this.minDate}
                                            value={this.props.dateTo}
                                            dateFormat={"MM/dd/yyyy"}
                                            className="form-control"
                                        />
                                    </Col>
                                </Form.Group>
                            </Card.Body>
                        </Card>
                        <Card className="mt-3">
                            <Card.Header>TIME OF TRAINING</Card.Header>
                            <Card.Body className="mt-3">
                                <Form.Group as={Row} controlId="formPlaintextEmail">
                                    <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                    TIME FROM
                                    </Form.Label>
                                    <Col sm="3">
                                        <TimePicker 
                                            showSecond={showSecond}
                                            defaultValue={this.state.valueFrom}
                                            className="xxx"
                                            onChange={this.onChangeFrom}
                                        />
                                    </Col>
                                    <Col sm="1">
                                    </Col>
                                    <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                    TIME TO
                                    </Form.Label>
                                    <Col sm="3">
                                        <TimePicker 
                                            showSecond={showSecond}
                                            defaultValue={this.state.valueTo}
                                            className="xxx"
                                            onChange={this.onChangeTo}
                                        />
                                    </Col>
                                </Form.Group>
                            </Card.Body>
                        </Card>
                        <Form.Group className="mt-5" as={Row} controlId="formPlaintextEmail">
                            <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                            DESCRIPTION
                            </Form.Label>
                            <Col sm="10">
                                <Form.Control 
                                    ref="description"
                                    name="description"
                                    value={this.state.description}
                                    onChange={this.onChangeDescription}
                                    autoComplete="off"
                                />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} controlId="formPlaintextEmail">
                            <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                            TRAINING PLATFORM
                            </Form.Label>
                            <Col sm="10">
                                <Typeahead
                                    labelKey='name'
                                    id="basic-example"
                                    onChange={this.onChangePlatform}
                                    options={this.state.platformDDL}
                                />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} controlId="formPlaintextEmail">
                            <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                            HEADCOUNT LIMIT
                            </Form.Label>
                            <Col sm="10">
                                <Form.Control 
                                    ref="participantLimit"
                                    name="participantLimit"
                                    value={this.state.participantLimit}
                                    onChange={this.onChangeParticipantLimit}
                                    autoComplete="off"
                                />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} controlId="formPlaintextEmail">
                            <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                            FACILITATOR
                            </Form.Label>
                            <Col sm="10">
                                <Form.Control 
                                    ref="facilitator"
                                    name="facilitator"
                                    value={this.state.facilitator}
                                    onChange={this.onChangeFacilitator}
                                    autoComplete="off"
                                />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} controlId="formPlaintextEmail">
                            <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                            VENUE
                            </Form.Label>
                            <Col sm="10">
                                <Form.Control 
                                    ref="venue"
                                    name="venue"
                                    value={this.state.venue}
                                    onChange={this.onChangeVenue}
                                    autoComplete="off"
                                />
                            </Col>
                        </Form.Group>
                        <hr className='mt-4'/>
                        <Form.Group as={Row} controlId="formPlaintextEmail">
                            <Form.Label column sm="12" style={{fontWeight : "bold", fontSize : "13px"}}>
                            SELECT EXAM SET
                            </Form.Label>
                        </Form.Group>
                        <Form.Group as={Row} controlId="formPlaintextEmail">
                            <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                            PRE-TEST
                            </Form.Label>
                            <Col sm="4">
                                <Typeahead
                                    labelKey='name'
                                    id="basic-example"
                                    onChange={this.onChangePreTestSetName}
                                    options={this.state.preTestSetNameDDL}
                                />
                            </Col>
                            <Col sm="2">
                            </Col>
                            <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                            POST-TEST
                            </Form.Label>
                            <Col sm="4">
                                <Typeahead
                                    labelKey='name'
                                    id="basic-example"
                                    onChange={this.onChangePostTestSetName}
                                    options={this.state.postTestSetNameDDL}
                                />
                            </Col>
                        </Form.Group>
                    </Container>
                </Modal.Body>
                <Modal.Footer>
                    <ButtonToolbar >
                        <Button variant="success" className="ml-auto noser-button-mr1" onClick = { this.handleSaveClick }>Save</Button>
                        <Button variant="danger" className="noser-button-mr15" onClick={this.props.onHide}>Close</Button>
                    </ButtonToolbar>
                </Modal.Footer>
                <NoserLoading show={this.state.isloading} />
        </Modal>
        );
    }
}
export default TrainingScheduleCreate