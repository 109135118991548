import {
    React,Component,BootstrapTable,
    paginationFactory,Button, ButtonToolbar, Card, 
    Form, Col, Row, axios, Typeahead, TimePicker,
    cellEditFactory ,moment, NavLink, Alert, AppConfiguration,
    NoserLoading, Noser, Container, Modal, DatePicker,Dropdown, DropdownButton, props, sizePerPageRenderer
} 
from '../../../noser-hris-component';

import ToolkitProvider, { CSVExport } from 'react-bootstrap-table2-toolkit';
import 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css';



class InsertCOCReference extends Component {
    constructor(props) {
        super(props)
        this.state ={
            userinfo        :   [],
            
            isloading       :   false,
            isshow          :   false,
            alerttype       :   "",
            message         :   "",
            color           :   "",
            fade            :   true,

            trainingRegisterTableList : [],

            selectedClientName                  :"",
            getClientList                       :[],
            clientName                          :"",
            articleList                         :[],
            selectedArticleNo                   :'',
            selectedArticleName                 :'',
            selectedArticleId                   :'',
            selectedArticleNo2                   :'',
            selectedArticleName2                 :'',
            selectedArticleId2                   :'',
            sectionList                         :[],
            selectedSectionNo                   :'',
            selectedInfraction                  :'',
            selectedCategory                    :'',
            selectedSectionNo2                   :'',
            selectedInfraction2                  :'',
            selectedCategory2                   :'',
            selectedSectionNo3                   :'',
            selectedInfraction3                  :'',
            selectedCategory3                    :'',
            selectedArticleId                   :'',
            sectionNo                           :"",
            infraction                          :"",
            category                            :"",
            addedArticle:false,
            addedSection2:false,
            addedSection3:false,
            
        }
    }
    componentDidMount(){
        this.state.userinfo = JSON.parse(sessionStorage.getItem("userData"))
        this.getClientList();;
    }
    GetDepartment() {
        const departmentCodeParams = {
            "IpAddress"     :   "0.0.0.0",
            "ClientId"      :   this.state.userinfo.clientId,
            "UserId"        :   this.state.userinfo.userId,
            "Code"          :   "",
            "Name"          :   ""
        };

        axios.post(AppConfiguration.Setting().noserapiendpoint + "Maintenance/GetDepartments",  departmentCodeParams)
        .then(res => {
            const data = res.data;
            console.log("Get Departments Code");
            console.log(data);
            console.log("Get Departments Code");
            this.setState({
                departmentCodeAutocomplete  :   data.departments
            });
        })

        console.log(this.state.departmentCodeAutocomplete)
    }

    getClientList(){
        this.setState({isloading:true})
        const getParams = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.userinfo.clientId,
            "UserId":this.state.userinfo.userId,
        }
        axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Client/GetClientList", getParams)
        .then(res => {
            console.log("Client List start ");
            console.log(res.data);
            console.log("Client List end");
            const data = res.data
            this.setState({
                isloading:false,
                getClientList : res.data.clients ? res.data.clients : []
            });
        })
        .catch(error=>{
           this.setState({
               isloading       :   false,
               alerttype       :   "Error!",
               isshow          :   true,
               color           :   "danger",
               message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
               fade            :   true
            })
        })
    }
    onChangeClientList = (e) => {
        if(e.length == 0) {
            this.state.selectedClientName=""
            this.state.selectedClientId=""
            return
        } 
        this.state.selectedClientId = e[0].id
        this.state.clientName = e[0].name
        this.setState({
            isloading:false,
            isshow:false,
            color:"",
            message:"",
            fade:true ,
            clientName: e[0].name
        })
       
        this.getCOCArticle();;
    }
    getCOCArticle =()=>{
       
        let filter_data ={ 'clientName' : this.state.clientName }
        const getParams ={
            "_collection" : "TestCOCArticle",
            "filter_data" : filter_data
        }
            console.log("axios start")
            axios
            .post("http://134.209.99.190:8088/action/get" , getParams)
            .then(res =>{
                let data = res.data
              
                const itemUsersDataLists = this.buildList(data["TestCOCArticle"])
                
                this.state.itemUserDataList  =  itemUsersDataLists
                
                let List =[]
                for (let i = 0; i < this.state.itemUserDataList.length; i++) {
                    let obj = {
                        'id'                    :   this.state.itemUserDataList[i]['id'],
                        'clientName'            :   this.state.itemUserDataList[i]['clientName'],
                        'articleNo'             :   this.state.itemUserDataList[i]['articleNo'],
                        'articleName'           :   this.state.itemUserDataList[i]['articleName'],
                        'isDraft'               :   this.state.itemUserDataList[i]['isDraft'],
                        'isDeleted'             :   this.state.itemUserDataList[i]['isDeleted'],
                        'createdby'             :   this.state.itemUserDataList[i]['createdby'],
                        'createddate'           :   this.state.itemUserDataList[i]['createddate'],
                        'modifiedby'            :   this.state.itemUserDataList[i]['modifiedby'],
                        'modifieddate'          :   this.state.itemUserDataList[i]['modifieddate'],
                        'isModified'            :   this.state.itemUserDataList[i]['isModified'],
                        
                    }
                    List.push(obj)
                    console.log("List articleName")
                    console.log(List)
                    console.log("List articleName")
                    
                    this.setState({
                        articleList:List

                    })
                   
                }
            })
            .catch(error=>{
                console.log("error: " + error)
            })
        } 
        
    buildList(data) {
        //console.log("data start")
        let itemList =[]
    
        let idList                      =[]
        let clientNameList              =[]
        let articleNoList               =[]
        let articleNameList             =[]
        let isDraftList                 =[]
        let isDeletedList               =[]
        let createdbyList               =[]
        let createddateList             =[]
        let modifiedbyList              =[]
        let modifieddateList            =[]
        let isModifiedList              =[]
    
        for (let i = 0; i < data.length; i++) {
    
            let s1 = data[i].split("':")

            let idClean                     = s1[1].replace("ObjectId(" ,"").replace(")","").replace("{","")
            let IdClean2                    = idClean.split("',")[0]
            let clientNameTmp               = s1[2].split("',")[0]
            let articleNoTmp                = s1[3].split("',")[0]
            let articleNameTmp              = s1[4].split("',")[0]
            let isDraftListTmp              = s1[5].split("',")[0]
            let isDeletedListTmp            = s1[6].split("',")[0]
            let createdbyListTmp            = s1[7].split("',")[0]
            let createddateListTmp          = s1[8].split("',")[0]
            let modifiedbyListTmp           = s1[9].split("',")[0]
            let modifieddateListTmp         = s1[10].split("',")[0]
            let isModifiedListTmp           = s1[11].split("',")[0]
        
            idList.push(IdClean2.replace(" '",""))
            clientNameList.push(clientNameTmp.replace(" '",""))
            articleNoList.push(articleNoTmp.replace(" '",""))
            articleNameList.push(articleNameTmp.replace(" '",""))
            isDraftList.push(isDraftListTmp.replace(" '",""))
            isDeletedList.push(isDeletedListTmp.replace(" '",""))
            createdbyList.push(createdbyListTmp.replace(" '",""))
            createddateList.push(createddateListTmp.replace(" '",""))
            modifiedbyList.push(modifiedbyListTmp.replace(" '",""))
            modifieddateList.push(modifieddateListTmp.replace(" '",""))
            isModifiedList.push(isModifiedListTmp.replace(" '","").replace("'}",""))
        }
        
        for (let i = 0; i < idList.length; i++) {
    
            let obj ={
                'id'                    :   idList[i],
                'clientName'            :   clientNameList[i],
                'articleNo'             :   articleNoList[i],
                'articleName'           :   articleNameList[i],
                'isDraft'               :   isDraftList[i],
                'isDeleted'             :   isDeletedList[i],
                'createdby'             :   createdbyList[i],
                'createddate'           :   createddateList[i],
                'modifiedby'            :   modifiedbyList[i],
                'modifieddate'          :   modifieddateList[i],
                'isModified'            :   isModifiedList[i],
            
            }
            itemList.push(obj)
        }          
        return itemList
      
    }
    
    onChangeArticle = (e) => {
        if(e.length==0)
        {
            this.setState({
                selectedArticleName     :   '',
                selectedArticleId       :   '',
                selectedArticleNo       :   '',
            })
            return
        }
        this.state.selectedArticleNo = e[0].articleNo
        this.state.selectedArticleName = e[0].articleName
        this.state.selectedArticleId = e[0].id        
               
        this.setState({
            isshow              : false,
            selectedArticleName : e[0].articleName,
            selectedArticleNo   : e[0].articleNo,
            selectedArticleId   : e[0].id,
            isloading           : true,

        })
        console.log(this.state.selectedArticleName)
        console.log(this.state.selectedArticleId)
        this.loadScreenFalse();
        this.getCOCSection();
       
    }
    loadScreenFalse=()=>{
        this.setState({
            isloading:false,
        })
    }
    onChangeArticle2 = (e) => {
        if(e.length==0)
        {
            this.setState({
                selectedArticleName2     :   '',
                selectedArticleId2       :   '',
                selectedArticleNo2       :   '',
            })
            return
        }
        this.state.selectedArticleNo2 = e[0].articleNo
        this.state.selectedArticleName2 = e[0].articleName
        this.state.selectedArticleId2 = e[0].id        
               
        this.setState({
            isshow                  : false,
            selectedArticleName2    : e[0].articleName,
            selectedArticleNo2      : e[0].articleNo,
            selectedArticleId2      : e[0].id,
            isloading               : true,

        })
        console.log(this.state.selectedArticleName2)
        console.log(this.state.selectedArticleId2)
        this.loadScreenFalse();
        this.getCOCSection2();
       
    }
    loadScreenFalse=()=>{
        this.setState({
            isloading:false,
        })
    }

    getCOCSection=()=>{

        let filter_data ={ 'articleId' : this.state.selectedArticleId }
        const getParams ={
            "_collection" : "TestCOCSection",
            "filter_data" : filter_data
        }
        console.log("axios start")
        axios
        .post("http://134.209.99.190:8088/action/get" , getParams)
        .then(res =>{
            let data = res.data
            
            const itemUsersDataLists = this.buildListCOCSection(data["TestCOCSection"])
            
            this.state.itemUserDataList  =  itemUsersDataLists
            
            let List =[]
            for (let i = 0; i < this.state.itemUserDataList.length; i++) {
                let obj = {
                    'id'                    :   this.state.itemUserDataList[i]['id'],
                    'clientName'            :   this.state.itemUserDataList[i]['clientName'],
                    'articleId'             :   this.state.itemUserDataList[i]['articleId'],
                    "articleNo"             :   this.state.itemUserDataList[i]['articleNo'],
                    "articleName"           :   this.state.itemUserDataList[i]['articleName'],
                    'sectionNo'             :   this.state.itemUserDataList[i]['sectionNo'],
                    'infraction'            :   this.state.itemUserDataList[i]['infraction'],
                    'category'              :   this.state.itemUserDataList[i]['category'],
                    'isDraft'               :   this.state.itemUserDataList[i]['isDraft'],
                    'isDeleted'             :   this.state.itemUserDataList[i]['isDeleted'],
                    'createdby'             :   this.state.itemUserDataList[i]['createdby'],
                    'createddate'           :   this.state.itemUserDataList[i]['createddate'],
                    'modifiedby'            :   this.state.itemUserDataList[i]['modifiedby'],
                    'modifieddate'          :   this.state.itemUserDataList[i]['modifieddate'],
                    'isModified'            :   this.state.itemUserDataList[i]['isModified'],
                    
                }
                List.push(obj)
                
                this.setState({
                    sectionList:List
                })
                         
            }
            
        })
        .catch(error=>{
            console.log("error: " + error)
        })

    }
    getCOCSection2=()=>{

        let filter_data ={ 'articleId' : this.state.selectedArticleId2 }
        const getParams ={
            "_collection" : "TestCOCSection",
            "filter_data" : filter_data
        }
        console.log("axios start")
        axios
        .post("http://134.209.99.190:8088/action/get" , getParams)
        .then(res =>{
            let data = res.data
            
            const itemUsersDataLists = this.buildListCOCSection(data["TestCOCSection"])
            
            this.state.itemUserDataList  =  itemUsersDataLists
            
            let List =[]
            for (let i = 0; i < this.state.itemUserDataList.length; i++) {
                let obj = {
                    'id'                    :   this.state.itemUserDataList[i]['id'],
                    'clientName'            :   this.state.itemUserDataList[i]['clientName'],
                    'articleId'             :   this.state.itemUserDataList[i]['articleId'],
                    "articleNo"             :   this.state.itemUserDataList[i]['articleNo'],
                    "articleName"           :   this.state.itemUserDataList[i]['articleName'],
                    'sectionNo'             :   this.state.itemUserDataList[i]['sectionNo'],
                    'infraction'            :   this.state.itemUserDataList[i]['infraction'],
                    'category'              :   this.state.itemUserDataList[i]['category'],
                    'isDraft'               :   this.state.itemUserDataList[i]['isDraft'],
                    'isDeleted'             :   this.state.itemUserDataList[i]['isDeleted'],
                    'createdby'             :   this.state.itemUserDataList[i]['createdby'],
                    'createddate'           :   this.state.itemUserDataList[i]['createddate'],
                    'modifiedby'            :   this.state.itemUserDataList[i]['modifiedby'],
                    'modifieddate'          :   this.state.itemUserDataList[i]['modifieddate'],
                    'isModified'            :   this.state.itemUserDataList[i]['isModified'],
                    
                }
                List.push(obj)
                
                this.setState({
                    sectionList:List
                })
                         
            }
            
        })
        .catch(error=>{
            console.log("error: " + error)
        })

    }
    buildListCOCSection(data) {
        //console.log("data start")
        let itemList =[]
    
        let idList                      =[]
        let clientNameList              =[]
        let articleIdList               =[]
        let articleNoList               =[]
        let articleNameList             =[]
        let sectionNoList               =[]
        let infractionList              =[]
        let categoryList                =[]        
        let isDraftList                 =[]
        let isDeletedList               =[]
        let createdbyList               =[]
        let createddateList             =[]
        let modifiedbyList              =[]
        let modifieddateList            =[]
        let isModifiedList              =[]
    
        for (let i = 0; i < data.length; i++) {
    
            let s1 = data[i].split("':")

            let idClean                     = s1[1].replace("ObjectId(" ,"").replace(")","").replace("{","")
            let IdClean2                    = idClean.split("',")[0]
            let clientNameTmp               = s1[2].split("',")[0]
            let articleIdTmp                = s1[3].split("',")[0]
            let articleNoTmp                = s1[4].split("',")[0]
            let articleNameTmp              = s1[5].split("',")[0]
            let sectionNoTmp                = s1[6].split("',")[0]
            let infractionTmp               = s1[7].split("',")[0]
            let categoryTmp                 = s1[8].split("',")[0]
            let isDraftListTmp              = s1[9].split("',")[0]
            let isDeletedListTmp            = s1[10].split("',")[0]
            let createdbyListTmp            = s1[11].split("',")[0]
            let createddateListTmp          = s1[12].split("',")[0]
            let modifiedbyListTmp           = s1[13].split("',")[0]
            let modifieddateListTmp         = s1[14].split("',")[0]
            let isModifiedListTmp           = s1[15].split("',")[0]
        
            idList.push(IdClean2.replace(" '",""))
            clientNameList.push(clientNameTmp.replace(" '",""))
            articleIdList.push(articleIdTmp.replace(" '",""))
            articleNoList.push(articleNoTmp.replace(" '",""))
            articleNameList.push(articleNameTmp.replace(" '",""))
            sectionNoList.push(sectionNoTmp.replace(" '",""))
            infractionList.push(infractionTmp.replace(" '",""))
            categoryList.push(categoryTmp.replace(" '",""))
            isDraftList.push(isDraftListTmp.replace(" '",""))
            isDeletedList.push(isDeletedListTmp.replace(" '",""))
            createdbyList.push(createdbyListTmp.replace(" '",""))
            createddateList.push(createddateListTmp.replace(" '",""))
            modifiedbyList.push(modifiedbyListTmp.replace(" '",""))
            modifieddateList.push(modifieddateListTmp.replace(" '",""))
            isModifiedList.push(isModifiedListTmp.replace(" '","").replace("'}",""))
        }
        
        for (let i = 0; i < idList.length; i++) {
    
            let obj ={
                'id'                    :   idList[i],
                'clientName'            :   clientNameList[i],
                'articleId'             :   articleIdList[i],
                'articleNo'             :   articleNoList[i],
                'articleName'           :   articleNameList[i],
                'sectionNo'             :   sectionNoList[i],
                'infraction'            :   infractionList[i],
                'category'              :   categoryList[i],
                'isDraft'               :   isDraftList[i],
                'isDeleted'             :   isDeletedList[i],
                'createdby'             :   createdbyList[i],
                'createddate'           :   createddateList[i],
                'modifiedby'            :   modifiedbyList[i],
                'modifieddate'          :   modifieddateList[i],
                'isModified'            :   isModifiedList[i],
            
            }
            itemList.push(obj)
        }          
        return itemList
      
    }
    onChangeSection = (e) => {
        if(e.length==0)
        {
            this.setState({
                selectedSectionNo     :   '',
                selectedInfraction    :   '',
                selectedCategory      :   '',
            })
            return
        }
        this.state.selectedSectionNo = e[0].sectionNo
        this.state.selectedInfraction = e[0].infraction
        this.state.selectedCategory = e[0].category        
               
        this.setState({
            isshow              : false,
            selectedSectionNo   : e[0].sectionNo,
            selectedInfraction  : e[0].infraction,
            selectedCategory    : e[0].category,
            isloading           : true,

        })
        console.log(this.state.selectedSectionNo)
        console.log(this.state.selectedInfraction)
        console.log(this.state.selectedCategory)
        this.loadScreenFalse();
    }
    onChangeSection2 = (e) => {
        if(e.length==0)
        {
            this.setState({
                selectedSectionNo2     :   '',
                selectedInfraction2    :   '',
                selectedCategory2      :   '',
            })
            return
        }
        this.state.selectedSectionNo2 = e[0].sectionNo
        this.state.selectedInfraction2 = e[0].infraction
        this.state.selectedCategory2 = e[0].category        
               
        this.setState({
            isshow              : false,
            selectedSectionNo2   : e[0].sectionNo,
            selectedInfraction2  : e[0].infraction,
            selectedCategory2   : e[0].category,
            isloading           : true,

        })
        console.log(this.state.selectedSectionNo2)
        console.log(this.state.selectedInfraction2)
        console.log(this.state.selectedCategory2)
        this.loadScreenFalse();
    }
    onChangeSection3 = (e) => {
        if(e.length==0)
        {
            this.setState({
                selectedSectionNo3     :   '',
                selectedInfraction3    :   '',
                selectedCategory3      :   '',
            })
            return
        }
        this.state.selectedSectionNo3 = e[0].sectionNo
        this.state.selectedInfraction3 = e[0].infraction
        this.state.selectedCategory3 = e[0].category        
               
        this.setState({
            isshow              : false,
            selectedSectionNo3   : e[0].sectionNo,
            selectedInfraction3  : e[0].infraction,
            selectedCategory3   : e[0].category,
            isloading           : true,
        })
        console.log(this.state.selectedSectionNo3)
        console.log(this.state.selectedInfraction3)
        console.log(this.state.selectedCategory3)
        this.loadScreenFalse();
    }
    handleSave=()=>{
        let dataCOCRef = {
            "articleNo"           :   this.state.selectedArticleNo,
            "articleName"         :   this.state.selectedArticleName,
            "sectionNo"           :   this.state.selectedSectionNo,
            "infraction"          :   this.state.selectedInfraction,
            "category"            :   this.state.selectedCategory,

            "articleNo2"           :   this.state.selectedArticleNo2,
            "articleName2"         :   this.state.selectedArticleName2,
            "sectionNo2"           :   this.state.selectedSectionNo2,
            "infraction2"          :   this.state.selectedInfraction2,
            "category2"            :   this.state.selectedCategory2,

            "sectionNo3"           :   this.state.selectedSectionNo3,
            "infraction3"          :   this.state.selectedInfraction3,
            "category3"            :   this.state.selectedCategory3,


        }

        sessionStorage.setItem("dataCOCRef", JSON.stringify(dataCOCRef))
        let viewdataCOCRefTmp = "1"
        sessionStorage.setItem("viewdataCOCRefTmp", JSON.stringify(viewdataCOCRefTmp))
        this.props.onHide("Hello Parent! It's your turn parent"); 
    }

    onModalClose = () => {
        this.props.onHide("Hello Parent! It's your turn parent"); 
        let viewdataCOCRefTmp = "0"
        sessionStorage.setItem("viewdataCOCRefTmp", JSON.stringify(viewdataCOCRefTmp))           
    }
    handleAddArticle=()=>{
        this.setState({
            addedArticle:true,
        })
    }
    handleAddSection2=()=>{
        this.setState({
            addedSection2:true,
        })
    }
    handleAddSection3=()=>{
        this.setState({
            addedSection3:true,
        })
    }
    


    

    render() {
        return(
        
            <Modal
                {...this.props}
                return
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                backdrop="static"
                className="modal-90w"
                 >
                <Modal.Header closeButton className="card-header">
                    <Modal.Title id="contained-modal-title-vcenter">
                        COC References
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container>
                        <Form>
                            <Alert isOpen={this.state.isshow} color={this.state.color} fade={this.state.fade} className={this.state.isshow ? 'display-block' : 'display-none'}>
                                <div className={this.state.color}></div> 
                                {this.state.message}
                            </Alert>
                            <Form.Group as={Row} controlId="formPlaintextEmail">
                                <Form.Label column sm="3" style={{fontWeight : "bold"}}>
                                    COC References
                                </Form.Label>
                                <Col sm="5" style={{marginLeft : "-65px"}}>
                                    <Typeahead
                                        labelKey='name'
                                        id="basic-example"
                                        onChange={this.onChangeClientList}
                                        options={this.state.getClientList}
                                        //placeholder="Select Client"
                                    />
                                </Col>
                              
                            </Form.Group>
                            <Form.Group as={Row} controlId="formPlaintextEmail">
                            <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                    Article
                                </Form.Label>
                                <Col sm="2">
                                    <Typeahead
                                        labelKey='articleNo'
                                        id="basic-example"
                                        onChange={this.onChangeArticle}
                                        options={this.state.articleList}
                                        placeholder="Select Article"
                                    />
                                </Col>

                                <Button variant="success" onClick={this.handleAddArticle}>
                                    Add
                                </Button>&nbsp;

                                <Col sm="7">
                                    <Form.Control 
                                        type="text"
                                        name="selectedArticleName" 
                                        value={this.state.selectedArticleName}
                                        onChange={this.onChangeArticleName} 
                                        autoComplete="off"
                                        placeholder="Autofill"
                                    />
                                </Col>

                            </Form.Group>

                            {   this.state.addedArticle ?
                                 <Form.Group as={Row} controlId="formPlaintextEmail">
                                <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                    Article
                                </Form.Label>
                                <Col sm="2">
                                    <Typeahead
                                        labelKey='articleNo'
                                        id="basic-example"
                                        onChange={this.onChangeArticle2}
                                        options={this.state.articleList}
                                        placeholder="Select Article"
                                    />
                                </Col>&nbsp;
                                <Col sm="7">
                                    <Form.Control 
                                        type="text"
                                        name="selectedArticleName" 
                                        value={this.state.selectedArticleName2}
                                        onChange={this.onChangeArticleName2} 
                                        autoComplete="off"
                                        placeholder="Autofill"
                                    />
                                </Col>

                            </Form.Group>:null}

                            <Form.Group as={Row} controlId="formPlaintextEmail">
                                <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                    Section
                                </Form.Label>
                                <Col sm="2">
                                    <Typeahead
                                        labelKey='sectionNo'
                                        id="basic-example"
                                        onChange={this.onChangeSection}
                                        options={this.state.sectionList}
                                        placeholder="Select Section"
                                    />
                                </Col>
                                <Button variant="success" onClick={this.handleAddSection2}>
                                    Add
                                </Button>&nbsp;
                               
                                <Col sm="5">
                                    <Form.Control 
                                        type="text"
                                        name="selectedInfraction" 
                                        value={this.state.selectedInfraction}
                                        onChange={this.onChangeArticleName} 
                                        autoComplete="off"
                                        placeholder="Autofill"
                                    />
                                </Col>
                                <Col sm="2">
                                    <Form.Control 
                                        type="text"
                                        name="selectedCategory" 
                                        value={this.state.selectedCategory}
                                        onChange={this.onChangeArticleName} 
                                        autoComplete="off"
                                        placeholder="Autofill"
                                    />
                                </Col>
                            </Form.Group>

                            {   this.state.addedSection2 ?
                                <Form.Group as={Row} controlId="formPlaintextEmail">
                                    <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                        Section
                                    </Form.Label>
                                    <Col sm="2">
                                        <Typeahead
                                            labelKey='sectionNo'
                                            id="basic-example"
                                            onChange={this.onChangeSection2}
                                            options={this.state.sectionList}
                                            placeholder="Select Section"
                                        />
                                    </Col>
                                    <Button variant="success" onClick={this.handleAddSection3}>
                                        Add
                                    </Button>&nbsp;
                                
                                    <Col sm="5">
                                        <Form.Control 
                                            type="text"
                                            name="selectedInfraction" 
                                            value={this.state.selectedInfraction2}
                                            onChange={this.onChangeArticleName} 
                                            autoComplete="off"
                                            placeholder="Autofill"
                                        />
                                    </Col>
                                    <Col sm="2">
                                        <Form.Control 
                                            type="text"
                                            name="selectedCategory" 
                                            value={this.state.selectedCategory2}
                                            onChange={this.onChangeArticleName} 
                                            autoComplete="off"
                                            placeholder="Autofill"
                                        />
                                    </Col>
                                </Form.Group>:null
                            }

                            {this.state.addedSection3 ?
                                <Form.Group as={Row} controlId="formPlaintextEmail">
                                    <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                        Section
                                    </Form.Label>
                                    <Col sm="2">
                                        <Typeahead
                                            labelKey='sectionNo'
                                            id="basic-example"
                                            onChange={this.onChangeSection3}
                                            options={this.state.sectionList}
                                            placeholder="Select Section"
                                        />
                                    </Col>&nbsp;
                                    <Col sm="5">
                                        <Form.Control 
                                            type="text"
                                            name="selectedInfraction" 
                                            value={this.state.selectedInfraction3}
                                            onChange={this.onChangeArticleName} 
                                            autoComplete="off"
                                            placeholder="Autofill"
                                        />
                                    </Col>
                                    <Col sm="2">
                                        <Form.Control 
                                            type="text"
                                            name="selectedCategory" 
                                            value={this.state.selectedCategory3}
                                            onChange={this.onChangeArticleName} 
                                            autoComplete="off"
                                            placeholder="Autofill"
                                        />
                                    </Col>
                                </Form.Group>:null
                            }

                            <ButtonToolbar>
                                <Button variant="success" className="btn btn-info ml-auto" onClick={this.handleSave}>
                                    Save
                                </Button>&nbsp;&nbsp;
                                <Button variant="danger" onClick={this.onModalClose} >
                                    Close
                                </Button>
                            </ButtonToolbar>

                        </Form>
                    </Container>
                </Modal.Body>
               
                <NoserLoading show={this.state.isloading} />
            </Modal>
        );
    }

}
export  default InsertCOCReference