import {
    React,Component,BootstrapTable,
    paginationFactory,Button, ButtonToolbar, Card, 
    Form, Col, Row, axios, Container, Banner, Typeahead, TimePicker,
    cellEditFactory, Type, moment, NavLink, Alert, AppConfiguration,
    NoserLoading, Noser, sizePerPageRenderer, DatePicker, Accordion
} 
from '../../noser-hris-component';
import ClearanceLastPayrollModal from './ClearanceLastPayrollModal';

class ClearanceLastPayroll extends Component {
    state = {
        userinfo        :   [],
        isloading       :   false,
        isshow          :   false,
        alerttype       :   "",
        message         :   "",
        color           :   "",
        fade            :   true,
        disableDeleteBtn:   true,
        openModal       :   false,
        
        clientDDL       :   [],
        employeeDDL     :   [],
        employeeList    :   [],
        payrollGRID     :   [],

        clientId        :   "",
        employeeId      :   "",
        profileId       :   "",
    }

    componentDidMount(){
        this.state.userinfo = JSON.parse(sessionStorage.getItem("userData"))
        this.GetClients()
    }
    GetClients = async() =>{
        this.setState({isloading:true})
        const params = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.userinfo.clientId,
            "UserId":this.state.userinfo.userId,
            "EmployeeId":this.state.userinfo.roleId==="1" || 
                         this.state.userinfo.roleId==="8" ? "" : this.state.userinfo.employeeId
        }
        await axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Client/GetClientList", params)
        .then(res => {
            const data = res.data;
            let arr = []
            
            if(this.state.userinfo.roleId!=="1"){
                let fieldaccess = JSON.parse(sessionStorage.getItem("userControlAccess"))
                if(fieldaccess.filter(x=>x.accessCode==="0001").length>0){
                    let access = fieldaccess.find(x=>x.accessCode==="0001" && x.controlAccessDetails.length>0)
                    if(access.controlAccessDetails.filter(x=>x.isClient==="1").length>0){
                        let brr = access.controlAccessDetails.filter(x=>x.isClient==="1")
                        arr = data.clients.filter(f => brr.some(item => item.referenceId === f.id))
                    }
                }
                this.setState({clientDDL:arr,isloading:false})
            }
            else{
                this.setState({clientDDL:data.clients,isloading:false})
            }
            this.GetEmployeeList()
        })
        .catch(error=>{
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade        :   true
            })
        })
    }
    GetEmployeeList = async() =>{
        this.setState({isloading:true})
        
        const params = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.clientId,
            "UserId":this.state.userinfo.userId
        }
        await axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Employee/GetEmployeeInActiveProfiles", params)
        .then(res => {
            const data = res.data;
            let arr = []
            if(this.state.userinfo.roleId!=="1"){
                let fieldaccess = JSON.parse(sessionStorage.getItem("userControlAccess"))
                if(fieldaccess.filter(x=>x.accessCode==="0001").length>0){
                    let access = fieldaccess.find(x=>x.accessCode==="0001" && x.controlAccessDetails.length>0)
                    if(access.controlAccessDetails.filter(x=>x.isClient==="1").length>0){
                        let brr = access.controlAccessDetails.filter(x=>x.isClient==="1")
                        arr = data.profiles.filter(f => brr.some(item => item.referenceId === f.clientId))
                    }
                }
                this.setState({employeeDDL : arr,employeeList : arr,isloading:false})
            }
            else{
                this.setState({employeeDDL : data.profiles,employeeList : data.profiles,isloading:false})
            }
        })
        .catch(error=>{
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade        :   true
            })
        })
    }
    handleChangedClient = (e) =>{
        this.state.alerttype="";this.state.isshow=false;this.state.color="";this.state.message="";this.state.fade=true;
        if(e.length===0){
            this.state.clientId= ''
            this.state.clientName=''
            this.state.employeeDDL=this.state.employeeList
            return
        }
        this.state.clientId=e[0].id
        this.state.clientName=e[0].name
        this.setState({employeeDDL:this.state.employeeList.filter(x=>x.clientId===e[0].id)})
    }
    handleChangedEmployee = (e) =>{
        this.state.alerttype="";this.state.isshow=false;this.state.color="";this.state.message="";this.state.fade=true;
        if(e.length===0){
            this.state.employeeId= ''
            return
        }
        this.state.employeeId=e[0].id
        this.state.profileId=e[0].profileId
    }
    handleModalShow = (e) =>{

        this.setState({openModal:true})
        let obj = {
            typeId:e,
            clientId:this.state.clientId,
            clientName:this.state.clientName,
        }
        this.child.initialize(obj)
    }
    handleModalClose = (e) =>{
        this.setState({openModal:false})
        this.handleSearchClick()
    }

    handleSearchClick = async()=>{
        this.setState({isloading:true})
        //this.state.alerttype="";this.state.isshow=false;this.state.color="";this.state.message="";this.state.fade=true;
        // if(this.state.clientId===""){
        //     this.setState({
        //         isloading   :   false,
        //         alerttype   :   "Error!",
        //         isshow      :   true,
        //         color       :   "danger",
        //         message     :   "Please select client.",
        //         fade        :   true
        //     })
        //     return
        // }

        const params = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.clientId,
            "UserId":this.state.userinfo.userId,
            "ProfileId":this.state.profileId,
        }
        
        await axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Payroll/GetClearanceEmployeeLastPayrolls", params)
        .then(res => {
            const data = res.data
            console.log(data)
            if(data.status==="0"){
                this.setState({
                    isloading   :   false,
                    alerttype   :   "Error!",
                    isshow      :   true,
                    color       :   "danger",
                    message     :   data.message,
                    fade        :   true,
                    disableSaveBtn: false
                });
            }
            this.setState({isloading:false,payrollGRID:data.lastPayrolls});
        })
        .catch(error=>{
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade        :   true,
                disableSaveBtn: false
            })
        })
    }
    
    handleSaveClick = async() =>{
        this.setState({isloading:true,disableDeleteBtn:true})
        if(this.state.payrollGRID.filter(x=>x.isModified==="1").length==="0"){
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "No selected data.",
                fade        :   true
            })
        }

        const params = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.clientId,
            "UserId":this.state.userinfo.userId,
            "lastPayrolls":this.state.payrollGRID.filter(x=>x.isModified==="1"),
        }
        
        await axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Payroll/EditLastPayroll", params)
        .then(res => {
            const data = res.data
            console.log(data)
            if(data.status==="0"){
                this.setState({
                    isloading   :   false,
                    alerttype   :   "Error!",
                    isshow      :   true,
                    color       :   "danger",
                    message     :   data.message,
                    fade        :   true,
                    disableSaveBtn: false
                });
            }
            this.setState({isloading:false});
            this.handleSearchClick()
        })
        .catch(error=>{
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade        :   true,
                disableSaveBtn: false
            })
        })
    }
    handleClickDownload = async(row)=>{
        this.setState({isloading:true})
        const params = {
            "IpAddress"     :   "0.0.0.0",
            "ClientId"      :   this.state.clientId,
            "UserId"        :   this.state.userinfo.userId,
            "FileName"      :   row.fileName
        }
        await axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Timekeeping/DownloadPerTripFile", params,{'responseType': 'blob'})
        .then(res => {
            const url = window.URL.createObjectURL(new Blob([res.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', row.uploadFileName);
            document.body.appendChild(link);
            link.click();
            this.setState({isloading:false})
        })
        .catch(error=>{
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade        :   true
            })
        })
    }
    GridDataModified(oldValue, newValue, id, column) {
        let isDisable = true
        this.state.payrollGRID.forEach(item => {
            if (item.id === id){
                item.isModified = newValue!=oldValue ? "1" : "0"
                isDisable=false
            }
        })
        this.setState({disableDeleteBtn:isDisable})
    }
    render() {
        const cols = [
            {
                dataField: 'status',
                text: 'STATUS',
                editable: false,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'left',width:'10%' }},
                style:{textAlign:'left',width:'10%'},
                footer: ''
            },
            {
                dataField: 'clientName',
                text: 'CLIENT',
                editable: false,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'left',width:'17%' }},
                style:{textAlign:'left',width:'17%'},
                footer: ''
            },
            {
                dataField: 'employeeName',
                text: 'EMPLOYEE NAME',
                editable: false,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'left',width:'15%' }},
                style:{textAlign:'left',width:'15%'},
                sort:true,
                footer: ''
            },
            {
                dataField: 'employeeNo',
                text: 'EMPLOYEE NO',
                editable: false,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'10%' }},
                style:{textAlign:'center',width:'10%'},
                sort:true,
                footer: ''
            },
            {
                dataField: 'lastPayroll',
                text: 'LAST PAYROLL',
                editable: true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'8%' }},
                style:{textAlign:'right',width:'8%'},
                sort:true,
                footerAlign: 'right',
                footer: columnData => columnData.reduce((acc, item) => parseFloat(parseFloat(acc) + parseFloat(item)).toFixed(2), 0)
            },
            {
                dataField: 'adjustmentPayroll',
                text: 'ADJ PAYROLL',
                editable: true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'8%' }},
                style:{textAlign:'right',width:'8%'},
                sort:true,
                footerAlign: 'right',
                footer: columnData => columnData.reduce((acc, item) => parseFloat(parseFloat(acc) + parseFloat(item)).toFixed(2), 0)
            },
            {
                dataField: 'otherPayroll',
                text: 'OTHER PAYROLL',
                editable: true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'8%' }},
                style:{textAlign:'right',width:'8%'},
                sort:true,
                footerAlign: 'right',
                footer: columnData => columnData.reduce((acc, item) => parseFloat(parseFloat(acc) + parseFloat(item)).toFixed(2), 0)
            },
            {
                dataField: 'thirteenthMonth',
                text: '13TH MONTH',
                editable: true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'8%' }},
                style:{textAlign:'right',width:'8%'},
                sort:true,
                footerAlign: 'right',
                footer: columnData => columnData.reduce((acc, item) => parseFloat(parseFloat(acc) + parseFloat(item)).toFixed(2), 0)
            },
            {
                dataField: 'sil',
                text: 'SIL',
                editable: true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'8%' }},
                style:{textAlign:'right',width:'8%'},
                sort:true,
                footerAlign: 'right',
                footer: columnData => columnData.reduce((acc, item) => parseFloat(parseFloat(acc) + parseFloat(item)).toFixed(2), 0)
            },
            {
                dataField: 'separationPay',
                text: 'SP',
                editable: true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'8%' }},
                style:{textAlign:'right',width:'8%'},
                sort:true,
                footerAlign: 'right',
                footer: columnData => columnData.reduce((acc, item) => parseFloat(parseFloat(acc) + parseFloat(item)).toFixed(2), 0)
            },
            {
                dataField: 'remarks',
                text: 'REMARKS',
                editable: true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'left',width:'20%' }},
                style:{textAlign:'left',width:'20%'},
                sort:true,
                footer: ''
            },
        ]
        
        const selectRow = {
            mode: 'checkbox',
            clickToSelectAndEditCell: true,
            style:{backgroundColor:'yellow', color:'#000',cursor:'pointer'},
            onSelect: (row, isSelect, rowIndex, e) => {
                let isDisable = true
                this.state.payrollGRID.map(function(item,i){
                    if(item.id===row.id && item.statusId==="0")
                    {
                        item.isDeleted = isSelect ? "1" : "0"
                        item.isModified = isSelect ? "1" : "0"
                    }
                    if(item.isDeleted=="1")
                        isDisable=false
                })
                this.setState({disableDeleteBtn:isDisable})
            },
            onSelectAll: (isSelect, rows) => {
                let isDisable = true
                this.state.payrollGRID.map(function(item,i){
                    item.isDeleted = isSelect ? "1" : "0"
                    item.isModified = isSelect ? "1" : "0"
                    if(item.isDeleted=="1")
                        isDisable=false
                })
                this.setState({disableDeleteBtn:isDisable})
            }
        };
    return(
            <div>
                <Banner />
                    <Container className="themed-container" fluid={true}>
                        <Card className="mt-5">
                            <Card.Header>CLEARANCE >> LAST PAYROLL</Card.Header>
                            <Card.Body>
                                <Form>
                                    <Alert isOpen={this.state.isshow} color={this.state.color} fade={this.state.fade} className={this.state.isshow ? 'display-block' : 'display-none'}>
                                        <div className={this.state.color}></div> 
                                        {this.state.message}
                                    </Alert>
                                    <Card>
                                    <Accordion defaultActiveKey="1">
                                        <div className="card-header-tab">
                                            <Accordion.Toggle as={Button} variant="link" eventKey="1" style={{color: "#FFFFFF"}}>
                                                SEARCH FILTER
                                            </Accordion.Toggle>
                                        </div>
                                        <Accordion.Collapse eventKey="1">
                                            <Card.Body>
                                                <Form>
                                                    <Form.Group as={Row} controlId="formHorizontalEmail">
                                                        <Col sm={12}>
                                                            <Typeahead
                                                                labelKey='name'
                                                                id="basic-example"
                                                                onChange={this.handleChangedClient}
                                                                options={this.state.clientDDL}
                                                                placeholder="Select CLient"
                                                            />
                                                        </Col>
                                                    </Form.Group>
                                                    <Form.Group as={Row} controlId="formHorizontalEmail">
                                                        <Col sm={12}>
                                                            <Typeahead
                                                                labelKey='employeeName'
                                                                id="basic-example"
                                                                onChange={this.handleChangedEmployee}
                                                                options={this.state.employeeDDL}
                                                                placeholder="Select Employee"
                                                            />
                                                        </Col>
                                                    </Form.Group>
                                                    <Form.Group as={Row} controlId="formHorizontalEmail">
                                                        <Col sm={12}>
                                                            <ButtonToolbar>
                                                            <Button variant="primary" className="ml-auto noser-button" onClick={ this.handleSearchClick }>Search</Button>
                                                            </ButtonToolbar>
                                                        </Col>
                                                    </Form.Group>
                                                </Form>
                                            </Card.Body>
                                        </Accordion.Collapse>
                                    </Accordion>
                                    
                                    </Card>
                                    
                                    <div className="mt-2">
                                    <Card.Header>LIST OF LAST PAYROLL</Card.Header>
                                        <BootstrapTable
                                            keyField = "id"
                                            data = { this.state.payrollGRID }
                                            columns = { cols }
                                            selectRow = { selectRow }
                                            cellEdit = { cellEditFactory({ 
                                                    mode: 'dbclick', 
                                                    blurToSave: true,
                                                    afterSaveCell: (oldValue, newValue, row, column) => { 
                                                        this.GridDataModified(oldValue, newValue, row.id, column.dataField)
                                                    }
                                                })
                                            }
                                            striped
                                            hover
                                            condensed
                                            loading={true}
                                            wrapperClasses="table-responsive"
                                            pagination={ paginationFactory({sizePerPageRenderer})}
                                            noDataIndication={ () => <div style={{width:'100%'}}>No record found.</div> }
                                        />
                                        <ButtonToolbar sm={12}>
                                            <Button variant="warning" className="ml-auto noser-button-mr1" onClick={() => this.handleModalShow("1") }>Download</Button>
                                            <Button variant="info" className="noser-button-mr1" onClick={() => this.handleModalShow("0") }>Upload</Button>
                                            <Button variant="primary" className="noser-button" disabled={this.state.disableDeleteBtn} onClick={this.handleSaveClick}>Save</Button>
                                        </ButtonToolbar>
                                    </div>
                                </Form>
                            </Card.Body>
                        </Card>
                    </Container>
                <NoserLoading show={this.state.isloading} />
                <ClearanceLastPayrollModal 
                    show={this.state.openModal}
                    onHide={this.handleModalClose}
                    onRefModal={ref => (this.child = ref)}
                />
            </div> 
        )
    }

}

export  default ClearanceLastPayroll 