import {
    React,Component,BootstrapTable, Type,
    paginationFactory,Button, ButtonToolbar, Card,
    Form, Col, Row, Container, axios, Typeahead, Banner, TimePicker,
    cellEditFactory ,moment, NavLink, Alert, AppConfiguration,
    NoserLoading, Noser, sizePerPageRenderer, DatePicker
} 
from '../../../noser-hris-component';
import type from 'react-bootstrap-table2-editor';

import ToolkitProvider, { CSVExport } from 'react-bootstrap-table2-toolkit';
import 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css';

class RecruitmentDeployment extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userinfo        :   [],
            isloading       :   false,
            isshow          :   false,
            alerttype       :   "",
            message         :   "",
            color           :   "",
            fade            :   true,


            clientDDL : [],
            employeeDDL : [],
            positionDDL : [],
            branchDDL : [],
            dateFrom : "",
            dateTo : "",
            recruitmentDeploymentGrid : [],
            accountDDL: [],
            statusDDL : [
                {"name": "ACTIVE", "id": "0"},
                {"name": "INACTIVE", "id": "1"},
            ],
            cityDDL: [],
            cityId: "",
            selectedClientId: "",
            selectedBranchId: "",
            profileId: "",
            selectedPositionId: "",
        }
    }


    componentDidMount(){
        const sleep = (milliseconds) => {return new Promise(resolve => setTimeout(resolve, milliseconds))}
        this.state.userinfo = JSON.parse(sessionStorage.getItem("userData"))
        this.GetClients();
        this.GetPosition();
        this.GetCity();
    }

    GetClients = async() => {
        this.setState({isloading:true})
        const param = {
            "IpAddress"     :   "0.0.0.0",
            "ClientId"      :   this.state.userinfo.clientId,
            "UserId"        :   this.state.userinfo.userId,
        };
        await axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Client/GetClientList",  param)
        .then(res => {
            const data = res.data;
            this.setState({clientDDL:data.clients,isloading:false});
        })
        .catch(error=>{
            this.setState(  {
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade            :   true
            })
        })
    }

    onChangeClient = (e) => {
        if(e.length == 0) {
            this.state.selectedClientName=""
            this.state.selectedClientId=""
            return
        } 
        this.state.selectedClientId = e[0].id
        this.state.selectedClientName = e[0].name

        this.GetEmployee();
        this.getClientLocation();
    }

    GetEmployee = async () =>{
        this.setState({isloading:true,})
        const jobParams = {
            "IpAddress":"0.0.0.0",
            "UserId":this.state.userinfo.userId,
            "ClientId":this.state.selectedClientId,
            "StatusId": "1"
        };
        await axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Report/GetRecruitmentEmployeeFilter",  jobParams)
        .then(res => {
            const data = res.data;
            this.setState({ employeeDDL: data.employees, isloading:false});
            
        })
    }

    onChangeEmployees = (e) => {
        if(e.length == 0) {
            this.state.profileId=""
            return
        } 
        this.state.profileId = e[0].profileId
    }
    GetPosition = async() => {
        this.setState({isloading:true})
        const positionParams = {
            "IpAddress":"0.0.0.0",
            "ClientId"      :   this.state.userinfo.clientId,
            "UserId"        :   this.state.userinfo.userId,
            "SectionId":"",
            "DepartmentId":"",
            "Name":""
        };
        await axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Maintenance/GetPositions",  positionParams)
        .then(res => {
            const data = res.data;
            ////////////console.log("Get Position Name");
            ////////////console.log(data);
            this.setState({ positionDDL  : data.positions, isloading:false });
        })
        
    }

    onChangePosition = (e) => {
        if(e.length == 0) {
            this.state.selectedPositionId=""
            return
        } 
        this.state.selectedPositionId = e[0].id
    }

    getClientLocation(){
        this.setState({isloading:true})

        const getParams = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.selectedClientId,
            "UserId":this.state.userinfo.userId,
            "ClientName": this.state.selectedClientName,
            "City": "",
            "Province": "",
            "Region": ""
    
        }
        axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Client/GetClientLocations", getParams)
        .then(res => {
            const data = res.data;
            //////////////////console.log("Client Location");
            //////////////////console.log(data);
            this.setState({
                branchDDL	:   res.data.locations ? res.data.locations : [],
                isloading	            :   false
            })
            /* if(data.locations.length=="0"){
                this.setState({
                    isloading   :   false,
                    alerttype   :   "Error!",
                    isshow      :   true,
                    color       :   "danger",
                    message     :   data.message,
                    fade        :   true
                });
            } */
        })
    }

    onChangeBranch = (e) => {
        if(e.length == 0) {
            this.state.selectedBranchId=""
            return
        } 
        this.state.selectedBranchId = e[0].id
    }
    
    handleChangeDateFrom = date => {
        ////////////console.log(date)
        this.setState({
            dateFrom: date,
            isshow:false,
        })
    }

    handleChangeDateTo = date => {
        ////////////console.log(date)
        this.setState({
            dateTo: date,
            isshow:false,
        })
    }

    onChangeStatus = (e) => {
        if(e.length == 0) {
            this.state.statusId=""
            return
        } 
        this.state.statusId = e[0].id
    }
    GetCity() {
        this.setState({isloading:true})
        const params = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.userinfo.clientId,
            "UserId":this.state.userinfo.userId,
            "RegionId": "",
            "ProvinceId": "",
            "Name":""
        };

         axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Maintenance/GetCities",  params)
        .then(res => {
            const data = res.data;
            //////console.log("GetCities")
            //////console.log(data)
            this.setState({ cityDDL: data.cities,isloading:false });
        })
        .catch(error=>{
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade        :   true,
            })
        })

    }

    onChangeStatus = (e) => {
        if(e.length == 0) {
            this.state.cityId=""
            return
        } 
        this.state.cityId = e[0].id
    }

    FormatDate(date) {
        let m = moment(date, 'MM-DD-YYYY');
        return (m.isValid() ? (date.getMonth()+1 + "/" + date.getDate() + "/" + date.getFullYear()) : "")
    }
    
    handleSearchClick = event => {
        this.setState({isloading:true,})
        let dateFrom = (this.state.dateFrom) ? moment(this.state.dateFrom).format('MM/DD/YYYY') : ""
        let dateTo = (this.state.dateTo) ? moment(this.state.dateTo).format('MM/DD/YYYY') : ""
        const params = {
            "IpAddress":"0.0.0.0",
            "UserId":this.state.userinfo.userId,
            "ClientId":this.state.selectedClientId,
            "BranchId":this.state.selectedBranchId ,
            "ProfileId":this.state.profileId,
            "PositionId":this.state.selectedPositionId,
            "StartDate":dateFrom, 
            "EndDate":dateTo
        };
        console.log("params")
        console.log(params)
        axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Report/GetRecruitmentDeploymentReports",  params)
        .then(res => {
            const data = res.data;
            console.log("GetRecruitmentDeploymentReports");
            console.log(data);
            this.setState({ recruitmentDeploymentGrid: data.deployments, isloading:false});
            if(data.status=="0"){
                this.setState({
                    isloading   :   false,
                    alerttype   :   "Error!",
                    isshow      :   true,
                    color       :   "danger",
                    message     :   data.message,
                    fade        :   true
                });
            }
            
        })
        .catch(error=>{
            this.setState({
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade            :   true
            })
        })

    }


    render() {
        const { ExportCSVButton } = CSVExport;
        const recruitmentDeploymentColumn = [
            {
                text	    : 'ACCOUNT',
                editable    :   false,
                dataField   :   "account",
            },
            {
                text	    : 'CLIENT',
                editable    :   false,
                dataField   :   "clientName",
            },
            {
                text	    : 'LAST_NAME',
                editable    :   false,
                dataField   :   "lastName",
            },
            {
                text	    : 'FIRST_NAME',
                editable    :   false,
                dataField   :   "firstName",
            },
            {
                text	    : 'SUFFIX_NAME',
                editable    :   false,
                dataField   :   "suffixName",
            },
            {
                text	    : 'MIDDLE_NAME',
                editable    :   false,
                dataField   :   "middleName",
            },
            {
                text	    : 'DATE_OF_BIRTH',
                editable    :   false,
                dataField   :   "dateOfBirth",
            },
            {
                text	    : 'CONTACT_NUMBER',
                editable    :   false,
                dataField   :   "mobileNumber",
            },
            {
                text	    : 'CIVIL_STATUS',
                editable    :   false,
                dataField   :   "civilStatus",
            },
            {
                text	    : 'GENDER',
                editable    :   false,
                dataField   :   "gender",
            },
            {
                text	    : 'REGION',
                editable    :   false,
                dataField   :   "region",
            },
            {
                text	    : 'PROVINCE',
                editable    :   false,
                dataField   :   "province",
            },
            {
                text	    : 'CITY',
                editable    :   false,
                dataField   :   "city",
            },
            {
                text	    : 'COMPLETE_ADDRESS',
                editable    :   false,
                dataField   :   "address",
            },
            {
                text	    : 'SSS',
                editable    :   false,
                dataField   :   "sssNumber",
            },
            {
                text	    : 'TIN',
                editable    :   false,
                dataField   :   "tinNumber",
            },
            {
                text	    : 'PHIC',
                editable    :   false,
                dataField   :   "phicNumber",
            },
            {
                text	    : 'HDMF',
                editable    :   false,
                dataField   :   "hdmfNumber",
            },
            {
                text	    : 'BRANCH',
                editable    :   false,
                dataField   :   "branchName",
            },
            {
                text	    : 'POSITION',
                editable    :   false,
                dataField   :   "positionName",
            },
            {
                text	    : 'EMPLOYMENT_STATUS',
                editable    :   false,
                dataField   :   "employmentStatus",
            },
            {
                text	    : 'CONTRACT_START_DATE',
                editable    :   false,
                dataField   :   "contractDateStart",
            },
            {
                text	    : 'CONTRACT_END_DATE',
                editable    :   false,
                dataField   :   "contractDateEnd",
            },
            {
                text	    : 'RATE',
                editable    :   false,
                dataField   :   "salaryOffered",
            },
            {
                text	    : 'ECOLA',
                editable    :   false,
                dataField   :   "ecolaRate",
            },
            {
                text	    : 'COLA',
                editable    :   false,
                dataField   :   "colaRate",
            },
            {
                text	    : 'SEA',
                editable    :   false,
                dataField   :   "seaRate",
            },
            {
                text	    : 'JO_STATUS',
                editable    :   false,
                dataField   :   "status",
            },
            {
                text	    : 'DATE_SUBMITTED_BY_RECRUITMENT',
                editable    :   false,
                dataField   :   "preparedDate",
            },
            {
                text	    : 'PREPARED_BY',
                editable    :   false,
                dataField   :   "preparedBy",
            },
            {
                text	    : 'DATE_APPROVED_BY_TL',
                editable    :   false,
                dataField   :   "approvedDate",
            },
            {
                text	    : 'TL_APPROVER',
                editable    :   false,
                dataField   :   "approvedBy",
            },
            {
                text	    : 'DATE_APPROVED_BY_ADMIN',
                editable    :   false,
                dataField   :   "notedDate",
            },
            {
                text	    : 'ADMIN_APPROVER',
                editable    :   false,
                dataField   :   "notedBy",
            },
        ] 

            const recruitmentDeploymentSelectRow = {
                mode: 'checkbox',
                clickToSelectAndEditCell: true,
                onSelect: (row, isSelect, rowIndex, e) => {
                    this.state.recruitmentDeploymentGrid.map(function(item,i){
                        if(item.id===row.id)
                        {
                            item.isDeleted = isSelect ? "1" : "0"
                            item.isModified = isSelect ? "1" : "0"
                        }
                    })
                }
            };
    
            const recruitmentDeploymentRowEvents = {
                onClick: (e, row, rowIndex) => {
                }
            };

        return(
            <div>

            <Banner />
                <Container className="themed-container" fluid={true}>
                    <Card className="mt-5">
                        <Card.Header>REPORT >> RECRUITMENT - DEPLOYMENT</Card.Header>
                        <Card.Body>
                            <Form >
                                <Alert isOpen={this.state.isshow} color={this.state.color} fade={this.state.fade} className={this.state.isshow ? 'display-block' : 'display-none'}>
                                    <div className={this.state.color}></div> 
                                    {this.state.message}
                                </Alert>
                                <Form.Group as={Row} controlId="formPlaintextEmail">
                                    <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                        CLIENT
                                    </Form.Label>
                                    <Col sm="11">
                                        <Typeahead
                                            labelKey='name'
                                            id="basic-example"
                                            onChange={this.onChangeClient}
                                            options={this.state.clientDDL}
                                        />
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} controlId="formPlaintextEmail">
                                    <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                        EMPLOYEE
                                    </Form.Label>
                                    <Col sm="11">
                                        <Typeahead
                                            labelKey='employeeName'
                                            id="basic-example"
                                            onChange={this.onChangeEmployees}
                                            options={this.state.employeeDDL}
                                    />
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} controlId="formPlaintextEmail">
                                    <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                        POSITION
                                    </Form.Label>
                                    <Col sm="11">
                                        <Typeahead
                                            labelKey='name'
                                            id="basic-example"
                                            onChange={this.onChangePosition}
                                            options={this.state.positionDDL}
                                    />
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} controlId="formPlaintextEmail">
                                    <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                        BRANCH
                                    </Form.Label>
                                    <Col sm="11">
                                        <Typeahead
                                            labelKey='name'
                                            id="basic-example"
                                            onChange={this.onChangeBranch}
                                            options={this.state.branchDDL}
                                        />
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} controlId="formPlaintextEmail">
                                    <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                        ACCOUNT
                                    </Form.Label>
                                    <Col sm="11">
                                        <Typeahead
                                            labelKey='name'
                                            id="basic-example"
                                            onChange={this.onChangeAccount}
                                            options={this.state.accountDDL}
                                        />
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} controlId="formPlaintextEmail">
                                    <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                        STATUS
                                    </Form.Label>
                                    <Col sm="11">
                                        <Typeahead
                                            labelKey='name'
                                            id="basic-example"
                                            onChange={this.onChangeStatus}
                                            options={this.state.statusDDL}
                                    />
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} controlId="formPlaintextEmail">
                                    <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                        CITY
                                    </Form.Label>
                                    <Col sm="11">
                                        <Typeahead
                                            labelKey='name'
                                            id="basic-example"
                                            onChange={this.onChangeCity}
                                            options={this.state.cityDDL}
                                    />
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} controlId="formPlaintextEmail">
                                    <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                        DATE FROM
                                    </Form.Label>
                                    <Col sm="2">
                                        <DatePicker
                                            ref='dateFrom'
                                            selected={this.state.dateFrom}
                                            onChange={this.handleChangeDateFrom}
                                            minDate={this.minDate}
                                            value={this.props.dateFrom}
                                            dateFormat={"MM/dd/yyyy"}
                                            className="form-control"
                                        />  
                                    </Col>
                                    <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                        DATE TO
                                    </Form.Label>
                                    <Col sm="2">
                                        <DatePicker
                                            ref='dateTo'
                                            selected={this.state.dateTo}
                                            onChange={this.handleChangeDateTo}
                                            minDate={this.minDate}
                                            value={this.props.dateTo}
                                            dateFormat={"MM/dd/yyyy"}
                                            className="form-control"
                                        />
                                    </Col>
                                </Form.Group>

                                <ButtonToolbar sm={12}>
                                    <Button variant="success" className="ml-auto" onClick = { this.handleSearchClick }>
                                        SEARCH
                                    </Button>
                                </ButtonToolbar>                             
                                <ToolkitProvider
                                    keyField="id"   
                                    data={ this.state.recruitmentDeploymentGrid }
                                    columns={ recruitmentDeploymentColumn }
                                    exportCSV={ {
                                        fileName: "Recruitment - Deployment Reports.csv",
                                    } }
                                    >
                                    {
                                        props => (
                                        <div className="mt-3">
                                            <BootstrapTable
                                                { ...props.baseProps } 
                                                /* keyField = "id"
                                                data = { this.state.employeeListTable }
                                                columns = { columnEmployee }
                                                pagination={ paginationFactory({sizePerPageRenderer}) }
                                                rowClasses="noser-table-row-class"
                                                striped
                                                hover
                                                condensed
                                                selectRow = { employeeRow } */


                                                striped
                                                hover
                                                condensed
                                                pagination={ paginationFactory({sizePerPageRenderer}) }
                                                keyField = "id"
                                                data = { this.state.recruitmentDeploymentGrid }
                                                noDataIndication={ () => <div>No record found.</div> }
                                                columns = { recruitmentDeploymentColumn }
                                            />
                                            <ButtonToolbar>
                                                <ExportCSVButton className="btn btn-info ml-auto" { ...props.csvProps }>Export CSV!!</ExportCSVButton>
                                                &nbsp;&nbsp;&nbsp;
                                                <NavLink to="/home">
                                                    <Button className="ml-auto" variant="danger" href="/banner">
                                                        Close
                                                    </Button>
                                                </NavLink>
                                            </ButtonToolbar>
                                        </div>
                                        )
                                    }
                                </ToolkitProvider>
                            </Form>
                        </Card.Body>
                    </Card>
                </Container>
                <NoserLoading show={this.state.isloading} />
            </div> 
            
        )
    }
}

export default RecruitmentDeployment;