import {
    React,Component,BootstrapTable,
    paginationFactory,Button, ButtonToolbar, Card, 
    Form, Col, Row, axios, Container, Banner, Typeahead, TimePicker,
    cellEditFactory, Type, moment, NavLink, Alert, AppConfiguration,
    NoserLoading, Noser, sizePerPageRenderer, DatePicker
} 
from '../../noser-hris-component';
import { DropdownButton, Dropdown, Image,FormControl} from 'react-bootstrap';

import  TrainingRegisterModal from '../Training/TrainingModal/TrainingRegisterModal'
import { Input } from 'reactstrap';
import { isDoWhileStatement } from '@babel/types';
import Pdf from "react-to-pdf";
import ReactToPrint from "react-to-print";
import paramountLogo from "../../icon/paramountLogo.jpg"
import paraFooter from "../../icon/paraFooter.jpg"

const ref = React.createRef();


class ComponentToPrint extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            regexp: /^[0-9\b]+$/,

            fade            :   true, 
            color           :   "",
            isshow          :   false,
            message         :   "",
            userinfo        :   [],
            isloading       :   false,
            alerttype       :   "",

           

            isLoading           :"",

            id                  :"",
            client              :"",
            employeeName        :"",
            position            :"",
            employeeNo          :"",
            location            :"",
            date                :"",
            irReferenceNo       :"",
            paragraph           :"",
            addedparagraph1     :"",
            addedparagraph2     :"",
            quotedStatement     :"",
            preparedBy          :"",
            prepareByPosition   :"",
            notedBy             :"",
            receivedBy          :"",
            isDraft             :"",
            isDeleted           :"",
            createdby           : "",
            createddate         :"",
            modifiedby          :"",
            modifieddate        :"",
            isModified          :"",

            articleNo               :"",
            articleName             :"",
            sectionNo               :"",
            infraction              :"",
            category                :"",

            articleNo2           :   "" , 
            articleName2         :   "",
            sectionNo2           :   "",
            infraction2          :   "",
            category2            :   "",

            sectionNo3              :   "",
            infraction3             :   "",
            category3               :   "",

            irDateIssued            :"",
            dateRecieveByER         :"",
            referenceNoNTE          :"",
            cocReferences           :"",
            
            
            departmentCodeAutocomplete:"",
            irIdCheck:"",
            dateNow:"2/31/2021",
            paragraph1:"",
            
            logo :"https://assets.bossjob.com/companies/13161/logo/3Mwts6BLTZy6I6WhqQm9B79bIhq8dILG7uqq7SHl.png"

        }
    }

    componentDidMount(){
        const sleep = (milliseconds) => {return new Promise(resolve => setTimeout(resolve, milliseconds))}
        this.state.userinfo = JSON.parse(sessionStorage.getItem("userData"))

        this.setState({isLoading:true,loadingText:"Loading client list..."})

       /*  this.state.userinfo = JSON.parse(sessionStorage.getItem("userData")) */
        this.state.irIdCheck = JSON.parse(sessionStorage.getItem("irId"))
      /*   console.log("filter start1")
        console.log(this.state.irIdCheck)
        console.log("filter start1") */

       
        //this.getNTE()
        this.getViewDraftMemo()
       
    }
   
    getNTE=()=>{
        //let irIdCheckTmp = JSON.parse(sessionStorage.getItem("irId"))
        
        let filter_data ={}
       
        const getParams ={
            "_collection" : "TestNTE",
            "filter_data" : filter_data
        }
           
            axios
            .post("http://134.209.99.190:8088/action/get" , getParams)
            .then(res =>{
                let data = res.data

                const itemUsersDataLists = this.buildList(data["TestNTE"])
                
                this.state.itemUserDataList  =  itemUsersDataLists
                
                let List =[]
                for (let i = 0; i < this.state.itemUserDataList.length; i++) {
                    let obj = {
                        'id'                    :   this.state.itemUserDataList[i]['id'],
                        'client'                :   this.state.itemUserDataList[i]['client'],
                        'employeeName'          :   this.state.itemUserDataList[i]['employeeName'],
                        'position'              :   this.state.itemUserDataList[i]['position'],
                        'employeeNo'            :   this.state.itemUserDataList[i]['employeeNo'],
                        'location'              :   this.state.itemUserDataList[i]['location'],
                        'date'                  :   this.state.itemUserDataList[i]['date'],
                        'referenceNoNTE'        :   this.state.itemUserDataList[i]['referenceNoNTE'],
                        'irReferenceNo'         :   this.state.itemUserDataList[i]['irReferenceNo'],
                        'irDateIssued'          :   this.state.itemUserDataList[i]['irDateIssued'],
                        'dateRecieveByER'       :   this.state.itemUserDataList[i]['dateRecieveByER'],
                        'paragraph'             :   this.state.itemUserDataList[i]['paragraph'],
                        'addedparagraph1'       :   this.state.itemUserDataList[i]['addedparagraph1'],
                        'addedparagraph2'       :   this.state.itemUserDataList[i]['addedparagraph2'],
                        'addedparagraph3'       :   this.state.itemUserDataList[i]['addedparagraph3'],
                        'quotedStatement'       :   this.state.itemUserDataList[i]['quotedStatement'],
                        "cocArticle"            :   this.state.itemUserDataList[i]['cocArticle'],
                        "articleDiscription"    :   this.state.itemUserDataList[i]['articleDiscription'],
                        "cocSection"            :   this.state.itemUserDataList[i]['cocSection'],
                        "sectionDiscription"    :   this.state.itemUserDataList[i]['sectionDiscription'],
                        'preparedBy'            :   this.state.itemUserDataList[i]['preparedBy'],
                        'prepareByPosition'     :   this.state.itemUserDataList[i]['prepareByPosition'],
                        'notedBy'               :   this.state.itemUserDataList[i]['notedBy'],
                        'receivedBy'            :   this.state.itemUserDataList[i]['receivedBy'],
                        'isDraft'               :   this.state.itemUserDataList[i]['isDraft'],
                        'isDeleted'             :   this.state.itemUserDataList[i]['isDeleted'],
                        'createdby'             :   this.state.itemUserDataList[i]['createdby'],
                        'createddate'           :   this.state.itemUserDataList[i]['createddate'],
                        'modifiedby'            :   this.state.itemUserDataList[i]['modifiedby'],
                        'modifieddate'          :   this.state.itemUserDataList[i]['modifieddate'],
                        'isModified'            :   this.state.itemUserDataList[i]['isModified'],
                        
                    }
                    List.push(obj)
                    console.log(List)
                    

                    this.setState({
                        paragraph:obj.paragraph,
                        addedparagraph1:obj.addedparagraph1,
                        quotedStatement:obj.quotedStatement,
                        employeeName:obj.employeeName,
                        location:obj.location,
                    })
                    
                    
                }
            })
            .catch(error=>{
                //console.log("error: " + error)
            })
        } 
        
    buildList(data) {
        //console.log("data start")
        let itemList =[]
    
        let idList                  =[]
        let clientList              =[]
        let employeeNameList        =[]
        let positionList            =[]
        let employeeNoList          =[]
        let locationList            =[]
        let dateList                =[]
        let referenceNoNTEList      =[]
        let irReferenceNoList       =[]
        let irDateIssuedList        =[]
        let dateRecieveByERList     =[]
        let paragraphList           =[]
        let addedparagraph1List     =[]
        let addedparagraph2List     =[]
        let addedparagraph3List     =[]
        let quotedStatementList     =[]
        let cocArticleList          =[]
        let articleDiscriptionList  =[]
        let cocSectionList          =[]
        let sectionDiscriptionList  =[]
        let preparedByList          =[]
        let prepareByPositionList   =[]
        let notedByList             =[]
        let receivedByList          =[]
        let isDraftList             =[]
        let isDeletedList           =[]
        let createdbyList           =[]
        let createddateList         =[]
        let modifiedbyList          =[]
        let modifieddateList        =[]
        let isModifiedList          =[]        
    
        for (let i = 0; i < data.length; i++) {            
    
            let s1 = data[i].split("':")
            
            let idClean                     = s1[1].replace("ObjectId(","").replace(")","").replace("{","")
            let IdClean2                    = idClean.split("',")[0]
            let clientTmp                   = s1[2].split("',")[0]
            let employeeNameListTmp         = s1[3].split("',")[0]
            let positionListTmp             = s1[4].split("',")[0]
            let employeeNoListTmp           = s1[5].split(",")[0]
            let locationListTmp             = s1[6].split("',")[0]
            let dateListTmp                 = s1[7].split("',")[0]
            let referenceNoNTETmp           = s1[8].split("',")[0]
            let irReferenceNoListTmp        = s1[9].split("',")[0]
            let irDateIssuedListTmp         = s1[10].split("',")[0]
            let dateRecieveByERListTmp      = s1[11].split("',")[0]
            let paragraphListTmp            = s1[12].split("',")[0]
            let addedparagraph1ListTmp      = s1[13].split("',")[0]
            let addedparagraph2ListTmp      = s1[14].split("',")[0]
            let addedparagraph3ListTmp      = s1[15].split("',")[0]
            let quotedStatementListTmp      = s1[16].split("',")[0]
            let cocArticleListTmp           = s1[18].split("',")[0]
            let articleDiscriptionListTmp   = s1[19].split("',")[0]
            let cocSectionListTmp           = s1[20].split("',")[0]
            let sectionDiscriptionListTmp   = s1[21].split("',")[0]
            let preparedByListTmp           = s1[22].split("',")[0]
            let prepareByPositionListTmp    = s1[23].split("',")[0]
            let notedByListTmp              = s1[24].split("',")[0]
            let receivedByListTmp           = s1[25].split("',")[0]
            let isDraftListTmp              = s1[26].split("',")[0]
            let isDeletedListTmp            = s1[27].split("',")[0]
            let createdbyListTmp            = s1[28].split("',")[0]
            let createddateListTmp          = s1[29].split("',")[0]
            let modifiedbyListTmp           = s1[30].split("',")[0]
            let modifieddateListTmp         = s1[31].split("',")[0]
            let isModifiedListTmp           = s1[32].split("',")[0]

        
            idList.push(IdClean2.replace("'",""))
            clientList.push(clientTmp.replace("'",""))
            employeeNameList.push(employeeNameListTmp.replace("'",""))
            positionList.push(positionListTmp.replace("'",""))
            employeeNoList.push(employeeNoListTmp.replace("'","").replace(" '",""))
            locationList.push(locationListTmp.replace("'",""))
            dateList.push(dateListTmp.replace("'",""))
            referenceNoNTEList.push(referenceNoNTETmp.replace("'",""))
            irReferenceNoList.push(irReferenceNoListTmp.replace("'",""))
            irDateIssuedList.push(irDateIssuedListTmp.replace("'",""))
            dateRecieveByERList.push(dateRecieveByERListTmp.replace("'",""))
            paragraphList.push(paragraphListTmp.replace("'",""))
            addedparagraph1List.push(addedparagraph1ListTmp.replace("'",""))
            addedparagraph2List.push(addedparagraph2ListTmp.replace("'",""))
            addedparagraph3List.push(addedparagraph3ListTmp.replace("'",""))
            quotedStatementList.push(quotedStatementListTmp.replace("'",""))
            cocArticleList.push(cocArticleListTmp.replace("'",""))
            articleDiscriptionList.push(articleDiscriptionListTmp.replace("'",""))
            cocSectionList.push(cocSectionListTmp.replace("'",""))
            sectionDiscriptionList.push(sectionDiscriptionListTmp.replace("'",""))
            preparedByList.push(preparedByListTmp.replace("'",""))
            prepareByPositionList.push(prepareByPositionListTmp.replace("'",""))
            notedByList.push(notedByListTmp.replace("'",""))
            receivedByList.push(receivedByListTmp.replace("'",""))
            isDraftList.push(isDraftListTmp.replace("'",""))
            isDeletedList.push(isDeletedListTmp.replace("'",""))
            createdbyList.push(createdbyListTmp.replace("'",""))
            createddateList.push(createddateListTmp.replace("'",""))
            modifiedbyList.push(modifiedbyListTmp.replace("'",""))
            modifieddateList.push(modifieddateListTmp.replace("'",""))
            isModifiedList.push(isModifiedListTmp.replace("'","").replace("'}",""))
            
        }
        
        for (let i = 0; i < idList.length; i++) {
    
            let obj ={
                'id'                    :   idList[i],
                'client'                :   clientList[i],
                'employeeName'          :   employeeNameList[i],
                'position'              :   positionList[i],
                'employeeNo'            :   employeeNoList[i],
                'location'              :   locationList[i],
                'date'                  :   dateList[i],
                'referenceNoNTE'        :   referenceNoNTEList[i],
                'irReferenceNo'         :   irReferenceNoList[i],
                'irDateIssued'          :   irDateIssuedList[i],
                'dateRecieveByER'       :   dateRecieveByERList[i],
                'paragraph'             :   paragraphList[i],
                'addedparagraph1'       :   addedparagraph1List[i],
                'addedparagraph2'       :   addedparagraph2List[i],
                'addedparagraph3'       :   addedparagraph3List[i],
                'quotedStatement'       :   quotedStatementList[i],
                'cocArticle'            :   cocArticleList[i],
                'articleDiscription'    :   articleDiscriptionList[i],
                'cocSection'            :   cocSectionList[i],
                'sectionDiscription'    :   sectionDiscriptionList[i],
                'preparedBy'            :   preparedByList[i],
                'prepareByPosition'     :   prepareByPositionList[i],
                'notedBy'               :   notedByList[i],
                'receivedBy'            :   receivedByList[i],
                'isDraft'               :   isDraftList[i],
                'isDeleted'             :   isDeletedList[i],
                'createdby'             :   createdbyList[i],
                'createddate'           :   createddateList[i],
                'modifiedby'            :   modifiedbyList[i],
                'modifieddate'          :   modifieddateList[i],
                'isModified'            :   isModifiedList[i],
            
            }
            itemList.push(obj)
           
        }        
        return itemList
    } 
    onChangePosition=(e)=>{
        this.setState({
            position:e.target.value
        })
    }
    getViewDraftMemo=()=>{
        let dataNTE = JSON.parse(sessionStorage.getItem("data"))
       /*  console.log("dataNTE")
        console.log(dataNTE.location)
        console.log("dataNTE") */

        this.setState({
            paragraph           :   dataNTE.paragraph,
            addedparagraph1     :   dataNTE.addedparagraph1,
            quotedStatement     :   dataNTE.quotedStatement,
            employeeName        :   dataNTE.employeeName,
            location            :   dataNTE.location,
            dateNow             :   dataNTE.createddate,
            preparedBy          :   dataNTE.preparedBy ,
            prepareByPosition   :   dataNTE.prepareByPosition ,
            notedBy             :   dataNTE.notedBy ,
            addedparagraph2     :   dataNTE.addedparagraph2,
            articleNo           :   dataNTE.articleNo , 
            articleName         :   dataNTE.articleName,
            sectionNo           :   dataNTE.sectionNo,
            infraction          :   dataNTE.infraction,
            category            :   dataNTE.category,

            articleNo2           :   dataNTE.articleNo2 , 
            articleName2         :   dataNTE.articleName2,
            sectionNo2           :   dataNTE.sectionNo2,
            infraction2          :   dataNTE.infraction2,
            category2            :   dataNTE.category2,

            sectionNo3           :   dataNTE.sectionNo3,
            infraction3          :   dataNTE.infraction3,
            category3            :   dataNTE.category3,

        })
      
    }
    render() {
        return( <>
            <Container className="mt-0" fluid>
              
                        <Form style={{backgroundColor:"#FFFFFF"}}>
                            <Col>
                                <Image src={paramountLogo} style={{width:"82%", height:"120%", marginLeft:"20px" , marginTop:"15px"}}/>
                            </Col>

                            <h1 as={Row} className="mt-5" style={{backgroundColor:'#D5D5D5', width:"100%",height:"60px" }}>
                                <Col>
                                <label style={{fontWeight : "bold" , fontSize: 25, marginTop:"15px", marginLeft:"-8px"}}>    
                                    Memorandum Details
                                </label>
                               
                                <label style={{fontWeight : "bold" , fontSize: 25, marginLeft:"1200px"  }}>
                                    {this.state.dateNow}
                                </label>
                                </Col>
                                
                            </h1>

                            <Form.Group as={Col} sm="10" className="mt-3">                                    
                                <Form.Label row sm="1" style={{fontWeight : "bold" ,fontSize: 23,marginTop:"-1px"}}>
                                    TO 
                                </Form.Label> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                <Form.Label row sm="1" style={{fontWeight : "bold" ,fontSize: 23,marginTop:"-1px"}}>:</Form.Label> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                <Form.Label row sm="3" style={{fontWeight : "bold" ,fontSize: 23,}}>
                                    {this.state.employeeName}                                       
                                </Form.Label>                                
                            </Form.Group>
                            <Form.Group as={Row} controlId="formPlaintextEmail"> 
                                <Col sm="2" style={{marginLeft:"153px", marginTop:"-20px"}}>
                                    <Form.Label row style={{fontWeight : "bold" ,fontSize: 23,}}>
                                        {this.state.location}
                                    </Form.Label>  
                                </Col>
                            </Form.Group>
                            <Form.Group as={Col} sm="10">                                    
                                <Form.Label row sm="1" style={{fontWeight : "bold" ,fontSize: 23,marginTop:"-6px"}}>
                                    FROM
                                </Form.Label> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                <Form.Label row sm="1" style={{fontWeight : "bold" ,fontSize: 23,marginTop:"-1px"}}>:</Form.Label> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                <Form.Label row sm="3" style={{fontWeight : "bold" ,fontSize: 23,}}>
                                    LABOR RELATIONS AND DISCIPLINE TEAM                                    
                                </Form.Label>                                
                            </Form.Group>

                            <Form.Group as={Col} sm="10">                                    
                                <Form.Label row sm="1" style={{fontWeight : "bold" ,fontSize: 23,marginTop:"-6px"}}>
                                    SUBJECT
                                </Form.Label> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                <Form.Label row sm="1" style={{fontWeight : "bold" ,fontSize: 23,marginTop:"-1px"}}>:</Form.Label> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                <Form.Label row sm="3" style={{fontWeight : "bold" ,fontSize: 23,}}>
                                    NOTICE TO EXPLAIN                                 
                                </Form.Label>                                
                            </Form.Group>

                            <h1 className="mt-3" style={{fontWeight : "bold" ,backgroundColor:'#D5D5D5', width:"100%", height:"35px" }} > </h1>

                            <Form.Group as={Row} controlId="formPlaintextEmail" className="mt-4" >
                                <Col sm="5" style={{width:"95%"}}>
                                    <Form.Label row style={{fontSize: 23}}>
                                        {this.state.paragraph}  
                                    </Form.Label>                                                          
                                </Col>                                    
                            </Form.Group>

                            <Form.Group as={Row} controlId="formPlaintextEmail" className="mt-4" style={{width:"85%"}}>
                                <Col sm="5" >
                                    <Form.Label row style={{fontSize: 23, fontWeight:"bold" ,  marginLeft:"25px" , marginRight:"25px"}}>
                                        {this.state.quotedStatement}
                                    </Form.Label> 
                                </Col>                                    
                            </Form.Group>

                            <Form.Group as={Row} controlId="formPlaintextEmail" className="mt-4" >
                                <Col sm="5" style={{width:"95%"}}>
                                    <Form.Label row style={{fontSize: 23}}>
                                        {this.state.addedparagraph1}
                                    </Form.Label> 
                                                            
                                </Col>                                    
                            </Form.Group>

                            <Form.Group as={Row} controlId="formPlaintextEmail" className="mt-3">
                                <Col sm="8" style={{  justifyContent:"center" ,textAlign:"center", marginLeft:"-90px"}}>
                                    <Form.Label row style={{fontWeight:"bold" ,fontSize: 23}}>
                                        {this.state.articleNo} &nbsp;&nbsp;&nbsp; {this.state.articleName}
                                    </Form.Label> 
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} controlId="formPlaintextEmail" className="mt-3">
                                <Col sm="8" style={{ justifyContent:"center" ,textAlign:"center", marginLeft:"-90px"}}>
                                    <Form.Label row style={{fontWeight:"bold" ,fontSize: 23}}>
                                        {this.state.articleNo2} &nbsp;&nbsp;&nbsp; {this.state.articleName2}
                                    </Form.Label>
                                </Col>
                            </Form.Group>
                            
                            <Form.Group as={Row} controlId="formPlaintextEmail" className="mt-3" style={{marginLeft:"195px"}}>
                                <Col>
                                    <Form.Label row style={{fontWeight:"bold" ,fontSize: 23}}>
                                        SECTION
                                    </Form.Label>
                                </Col> 
                                <Col>
                                    <Form.Label row style={{fontWeight:"bold" ,fontSize: 23}}>
                                        INFRACTION
                                    </Form.Label>
                                </Col>
                                <Col>
                                    <Form.Label row style={{fontWeight:"bold" ,fontSize: 23}}>
                                        CATEGORY
                                    </Form.Label>             
                                </Col> 
                            </Form.Group>

                            <Form.Group as={Row} controlId="formPlaintextEmail" className="mt-3" /* style={{marginLeft:"200px"}} */>
                                <Col style={{ marginLeft:"-1px", textAlign:"center",}}>
                                    <Form.Label row style={{fontWeight:"bold" ,fontSize: 23}}>
                                        {this.state.sectionNo}
                                    </Form.Label>
                                </Col> 
                                <Col style={{textAlign:"center",marginRight:"20px", marginLeft:"30px" }}>
                                    <Form.Label row style={{fontWeight:"bold" ,fontSize: 23}}>
                                        {this.state.infraction}
                                    </Form.Label>
                                </Col>
                                <Col style={{marginLeft:"150px", marginRight:"-4px"}}>
                                    <Form.Label row style={{fontWeight:"bold" ,fontSize: 23}}>
                                        {this.state.category}
                                    </Form.Label>                   
                                </Col> 
                            </Form.Group>

                            <Form.Group as={Row} controlId="formPlaintextEmail" className="mt-3" /* style={{marginLeft:"200px"}} */>
                                <Col style={{ marginLeft:"-1px", textAlign:"center",}}>
                                    <Form.Label row style={{fontWeight:"bold" ,fontSize: 23}}>
                                        {this.state.sectionNo2}
                                    </Form.Label>
                                </Col> 
                                <Col style={{textAlign:"center",marginRight:"20px" ,marginLeft:"30px" }}>
                                    <Form.Label row style={{fontWeight:"bold" ,fontSize: 23}}>
                                        {this.state.infraction2}
                                    </Form.Label>
                                </Col>
                                <Col style={{marginLeft:"150px", marginRight:"-4px"}}>
                                    <Form.Label row style={{fontWeight:"bold" ,fontSize: 23}}>
                                        {this.state.category2}
                                    </Form.Label>                             
                                </Col> 
                            </Form.Group>

                            <Form.Group as={Row} controlId="formPlaintextEmail" className="mt-3" >
                                <Col style={{ marginLeft:"-1px", textAlign:"center",}}>
                                    <Form.Label row style={{fontWeight:"bold" ,fontSize: 23}}>
                                        {this.state.sectionNo3}
                                    </Form.Label>
                                </Col> 
                                <Col style={{textAlign:"center",marginRight:"20px",marginLeft:"30px" }}>
                                    <Form.Label row style={{fontWeight:"bold" ,fontSize: 23}}>
                                        {this.state.infraction3}
                                    </Form.Label>
                                </Col>
                                <Col style={{marginLeft:"150px", marginRight:"-4px"}}>
                                    <Form.Label row style={{fontWeight:"bold" ,fontSize: 23}}>
                                        {this.state.category3}
                                    </Form.Label>                            
                                </Col> 
                            </Form.Group>

                            <Form.Group as={Row} controlId="formPlaintextEmail" className="mt-3">
                                <Col>
                                    <Form.Label row style={{width:"95%",fontSize: 23}}>
                                        {this.state.addedparagraph2}
                                    </Form.Label>  
                                </Col>                                    
                            </Form.Group>

                            <Form.Group as={Row} controlId="formPlaintextEmail">
                                <Form.Label column style={{fontWeight : "bold",fontSize: 23,}}>
                                    Prepared By :
                                </Form.Label>
                            </Form.Group>
                            <Form.Group as={Row} controlId="formPlaintextEmail">
                                <Form.Label column style={{fontWeight : "bold",fontSize: 23, marginTop:"-20px"}}>
                                    {this.state.preparedBy}
                                </Form.Label>
                            </Form.Group>


                            <Form.Group as={Row} controlId="formPlaintextEmail">
                                <Form.Label column sm="1" style={{fontWeight : "bold",fontSize: 23}}>
                                    Noted By :
                                </Form.Label>
                            </Form.Group>
                            <Form.Group as={Row} controlId="formPlaintextEmail">
                                
                            </Form.Group>
                            <Form.Group as={Row} controlId="formPlaintextEmail">
                                <Form.Label column sm="2" style={{fontWeight : "bold",fontSize: 23}}>
                                    {this.state.notedBy}
                                </Form.Label>
                            </Form.Group>
                            <Form.Group as={Row} controlId="formPlaintextEmail">
                                <Form.Label column sm="2" style={{fontWeight : "bold",fontSize: 19, marginTop:"-19px", marginLeft:"1"}}>
                                    {this.state.prepareByPosition}
                                </Form.Label>
                            </Form.Group>

                                <Form.Group as={Row} controlId="formPlaintextEmail">
                                <Form.Label column sm="2" style={{fontWeight : "bold",fontSize: 23}}>
                                    Received by :
                                </Form.Label>
                            </Form.Group>
                            <Form.Group as={Row} controlId="formPlaintextEmail">
                                <Form.Label column sm="1" style={{fontWeight : "bold",fontSize: 23,}}>
                                    <Col sm="10">
                                        <Form.Control 
                                            type="text"
                                            name="employeeName"
                                            value={this.state.employeeName}
                                            //onChange={this.onChangePosition} 
                                            autoComplete="off"
                                            //placeholder="Enter Name"
                                            style={{fontWeight:"bold",width:"500px",borderTop:"#ffffff", borderLeft:"#ffffff", borderRightColor:"#ffffff",borderRadius: "0",fontSize: 23,marginLeft:"-20px",borderBottomColor:"#000000"}}
                                        />
                                    </Col>
                                </Form.Label>
                            </Form.Group>
                            <Form.Group as={Row} controlId="formPlaintextEmail">
                                <Form.Label column sm="2" style={{fontWeight : "bold",fontSize: 19,marginTop:"-20px"}}>
                                    Sign over printed name
                                </Form.Label>
                            </Form.Group>

                            { <Col sm="10" style={{marginLeft:"550px"}}>
                                <Image src={paraFooter} style={{width:"20%", height:"100px", textAlign:"center",justifyContent:"center"}}/>
                            </Col>}
                        </Form>
                    
                
            </Container>
            <NoserLoading show={this.state.isloading} />

            <TrainingRegisterModal 
                show={this.state.modalTrainingRegisterShow}
                onHide={this.handleModalClose}
                onRefModal={ref => (this.child = ref)}
            />
           
            </>
            
        )
    }

}


class ExportNTE extends React.Component {
    render() {
      return (
        <div>
        
            <ComponentToPrint ref={(el) => (this.componentRef = el)} />
            <Col sm="3" className="mt-3" as={Row}>
                <ReactToPrint
                    trigger={() => <button>Print this out!</button>}
                    content={() => this.componentRef}
                />
                 <ButtonToolbar sm={12}>
                    <NavLink to="/home">
                        <Button variant="danger" href="/home" style={{minWidth:'60px',marginLeft:"10px"}}>
                            Close
                        </Button>
                    </NavLink>
                </ButtonToolbar>
            </Col>
        </div>
      );
    }
  }

export default ExportNTE
