import React from 'react';
import {Route, Redirect} from 'react-router-dom';
import NoserAuthenticate from './authenticate'
export const ProtectedRoute =({component:Component, ...rest})=>{
    return (
        <Route {...rest} render ={
            (props) =>{
                
                if(NoserAuthenticate.IsAuthenticated())
                {
		    if(props.location.pathname==="/manageemployeeapprovals" || props.location.pathname==="/employeeapproval"){}
                    else{
                        let userinfo = JSON.parse(sessionStorage.getItem("userData"))
                        sessionStorage.setItem("employeeapprovalFilter_"  + userinfo.userId + userinfo.employeeId, null)
                    }
                    return <Component {...props} />
                }
                else
                {
                    return <Redirect to={{
                        pathname:"/",
                        state:{
                            from:props.location
                        }
                    }}/>
                }
            }} /> 
    )
}
