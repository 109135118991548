import {
    React,Component,BootstrapTable,
    paginationFactory,Button, ButtonToolbar, Card, 
    Form, Col, Row, axios, Container, Banner, Typeahead, TimePicker,
    cellEditFactory, Type, moment, NavLink, Alert, AppConfiguration,
    NoserLoading, Noser, sizePerPageRenderer, DatePicker
} 
from '../../noser-hris-component';
import { DropdownButton, Dropdown} from 'react-bootstrap';
import { Input } from 'reactstrap';

const showSecond = false;
const str = showSecond ? 'HH:mm:ss' : 'HH:mm';

class RecordNodaPenalty extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fade            :   true, 
            color           :   "",
            isshow          :   false,
            message         :   "",
            userinfo        :   [],
            isloading       :   false,
            alerttype       :   "",
            disableTakeExam :   true,
            disableSubmit   :   true,

            modalInsertPenalty: false,
            modalCOCReference: false,

            isLoading                   :"",

            selectedEmployeeName        :[],
            selectedEmployeeId          :[],
            selectedEmployeeNo          :[],
            selectedEmployeeBranch      :[],
            selectedPosition            :[],
            selectedEmployeeBranch      :[],

            getClientList               :[],
            getEmployeeList             :[],
            getEmployeeNoList           :[],
            position                    :[],
            location                    :[],
            explanationLetter           :[],

            saveEmployeeNo              :"",
            currentDate                 :"",
            nodaRefNo                   :"",
            nodaDate                    :"",
            AttachedNodaCopy            :"",
            furtherAction               :"",
            remarks                     :"",
            dateReceived                :"",
            penalty                     :"",
            offenceDescription          :"",
            suspension                  :"",
            monitaryValue               :"",
            applicableDateTo            :"",
            applicableDateFrom          :"",
            furtherActionsList          :[],
            selectedfurtherAction       :'',
            selectedfurtherActionId     :'',
            checkBoxYes                 :'',
            checkBoxNo                  :false,
            disable                     :true,
            disableRemarks              :true,
            disableAction               :true,
            penaltyTypesList            :[],
            selectedpenaltyType         :'',
            selectedpenaltyTypeId       :'',
            suspensionInput             :false,
            penaltyVar                  :"",
            terminationInput            :false,
            terminationDate             :"",
        }
    }
    componentDidMount(){
        const sleep = (milliseconds) => {return new Promise(resolve => setTimeout(resolve, milliseconds))}
        this.state.userinfo = JSON.parse(sessionStorage.getItem("userData"))
        console.log(this.state.userinfo)

        this.setState({isLoading:true,loadingText:"Loading client list..."})

        this.state.userinfo = JSON.parse(sessionStorage.getItem("userData"))
        this.getClientList();;
        this.getfurtherAction();;
        this.getPenaltyType();;
        this.handleChangeSuspesionInput();
       
        
    }
    getClientList(){
        this.setState({isloading:true})
        const getParams = {
            "IpAddress" :   "0.0.0.0",
            "ClientId"  :   this.state.userinfo.clientId,
            "UserId"    :   this.state.userinfo.userId,
        }
        axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Client/GetClientList", getParams)
        .then(res => {
            this.setState({
                isloading       :   false,
                getClientList   :   res.data.clients ? res.data.clients : []
            });
        })
        .catch(error=>{
           this.setState({
               isloading       :   false,
               alerttype       :   "Error!",
               isshow          :   true,
               color           :   "danger",
               message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
               fade            :   true
            })
        })
    }
    onChangeClientList = (e) => {
        if(e.length == 0) {
            this.state.selectedClientName=""
            this.state.selectedClientId=""
            return
        } 
        this.state.selectedClientId = e[0].id
        this.state.selectedClientName = e[0].name
        console.log("Client selectedClientId " + this.state.selectedClientId );
        console.log("Client selectedClientName " + this.state.selectedClientName );
        this.setState({isloading:true,isshow:false,color:"",message:"",fade:true})
        
        this.getEmployees();
    }
    getEmployees(){
        this.setState({isloading:true})
        const getParams = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.selectedClientId,
            "UserId":this.state.userinfo.userId,
            "EmployeeName":"",
            "EmployeeNo":"",
            "ClientName":""
        }
        axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Employee/GetEmployees", getParams)
        .then(res => {
            //console.log("Employee List start ");
            //console.log(res.data);
            //console.log("Employee List end");
            const data = res.data
            if(data.status=="1")
                this.setState({getEmployeeList : data.employees, getEmployeeNoList : data.employees,isloading:false,}) 
            else
                this.setState({getEmployeeList : [], getEmployeeNoList : [],isloading:false}) 
        })
        .catch(error=>{
           this.setState(  {
               isloading       :   false,
               alerttype       :   "Error!",
               isshow          :   true,
               color           :   "danger",
               message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
               fade            :   true
           })
       })
        
    }

    onChangeEmployeesList = (e) => {
       

        if(e.length==0)
        {
            this.setState({getEmployeeListData: null, selectedPayrollpayPeriod: '', selectedWorkScheduleIdParam: '', selectedPayrollPeriodsId : '',selectedEmployeeId : '',selectedEmployeeName : '',selectedPayrollPeriodsName : '', selectedWorkScheduleId: ''})
          
            return
        }
        this.setState({isloading:true,isshow:false,color:"",message:"",fade:true})

        this.state.selectedEmployeeName = e[0].employeeName
        this.state.selectedEmployeeId = e[0].id
        this.state.selectedEmployeeNo = e[0].employeeNo
        this.state.selectedEmployeeBranch = e[0].locationName
        this.state.selectedPosition = e[0].position
       
        console.log("Work selectedEmployeeName   " + this.state.selectedEmployeeNo );
        console.log("Employee List start ");
        console.log(this.state.selectedEmployeeNo);
        console.log("Employee List end");

      /*   if(this.state.selectedPayrollpayPeriod == ""){
            this.state.selectedPayrollPeriodsId = e[0].payPeriodId
            this.state.selectedPayrollpayPeriod = e[0].payPeriod
        } */

        //this.employeeTransactionsList();


        this.setState({
            position        :this.state.selectedPosition,
            location        :this.state.selectedEmployeeBranch,
            saveEmployeeNo  : e[0].employeeNo

        })
        this.setState({isloading:false})
    }

    onChangeEmployeesNo(e){
        this.setState({selectedEmployeeNo : e.target.value})
        console.log(e.target.value)
    }
    onChangePosition(e){
        this.setState({position : e.target.value})
        console.log(e.target.value)
    }
    onChangeLocation(e){
        this.setState({location : e.target.value})
        console.log(e.target.value)
    }


    handleInsertPenalty = () => {
        //alert("Test")
        this.setState({
            modalInsertPenalty:   true
        })
    }

    handleCocReference = ()=>{
        this.setState({
            modalCOCReference:   true
        })
    }

    handleModalClosePenalty = (e) =>{
        this.setState({
            modalInsertPenalty:   false,
        })
    }

    handleModalCloseCOC = (e) =>{
        this.setState({
            modalCOCReference: false,
        })
    }

    handleExplanationLetter=(e)=>{
        this.setState({
            explanationLetter:e.target.value
        })
        console.log("explanationLetter")
        console.log(e.target.value)
        console.log("explanationLetter")
    }

    handleSave=(e)=>{
        //console.log(this.state.furtherAction)
        let createddate = moment(this.state.createddate).format('MM/DD/YYYY');

        let data = {            
            "client"                        :   this.state.selectedClientName,
            "employeeName"                  :   this.state.selectedEmployeeName,
            "position"                      :   this.state.selectedPosition,
            "employeeNo"                    :   this.state.selectedEmployeeNo,
            "location"                      :   this.state.selectedEmployeeBranch,
            "currentDate"                   :   this.state.currentDate,
            "nodaRefNo"                     :   this.state.nodaRefNo,
            "nodaDate"                      :   this.state.nodaDate,
            "AttachedNodaCopy"              :   this.state.AttachedNodaCopy,
            "furtherAction"                 :   this.state.selectedfurtherAction,
            "remarks"                       :   this.state.remarks,
            "dateReceived"                  :   this.state.dateReceived,
            "penalty"                       :   this.state.selectedpenaltyType,
            "offenceDescription"            :   this.state.offenceDescription,
            "suspensionDays"                :   this.state.suspension,
            "monitaryValue"                 :   this.state.monitaryValue,
            "applicableDateTo"              :   this.state.applicableDateTo,
            "applicableDateFrom"            :   this.state.applicableDateFrom,
            "terminationDate"               :   this.state.terminationDate,
            "isDraft"                       :   "0",
            "isDeleted"                     :   "0",
            "createdby"                     :   "user007",
            "createddate"                   :   createddate,
            "modifiedby"                    :   'user007',
            "modifieddate"                  :   '0',
            "isModified"                    :   '0',    
        }
        console.log(data)
        
        const addParams = {
          "_collection" : "TestRecordNodaPenalty",
          "doc_data"    : data
        }
          
        axios
        .post("http://134.209.99.190:8088/action/add", addParams)
        .then(res =>{
            const data = res.data
            console.log("success")
            console.log(data)
            this.setState({
                currentDate         :"",
                nodaRefNo           :"",
                nodaDate            :"",
                AttachedNodaCopy    :"",
                furtherAction       :"",
                remarks             :"",
                dateReceived        :"",
                penalty             :"",
                offenceDescription  :"",
                suspension          :"",
                monitaryValue       :"",
                applicableDateTo    :"",
                applicableDateFrom  :"",
                isloading       :   false,
                alerttype       :   "Success!",
                isshow          :   true,
                color           :   "success",
                message         :   "Data had successfully Save" ,
                fade            :   true
            
            })
        })
        .catch(error =>{
            console.log("error: " + error)
            this.setState({
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade            :   true
            })

        })
    }
    handleChangeDate = date => {
        ////console.log(date)
        this.setState({
            currentDate: date,
            isshow:false,
        })
        console.log(this.state.currentDate)
    }   
    handleChangenodaRefNo=(e)=>{
        this.setState({
            nodaRefNo:e.target.value
        })
        console.log(e.target.value)
    }
    handleChangenodaDate=(e)=>{
        this.setState({
            nodaDate:e.target.value
        })
        console.log(e.target.value)
    }
    handleChangeAttachedNodaCopy=(e)=>{
        this.setState({
            AttachedNodaCopy:e.target.value
        })
        console.log(e.target.value)
    }   
    getfurtherAction =(e)=>{
       
        let filter_data ={}
        const getParams ={
            "_collection" : "TestNodaFurtherAction",
            "filter_data" : filter_data
        }
            console.log("axios start")
            axios
            .post("http://134.209.99.190:8088/action/get" , getParams)
            .then(res =>{
                let data = res.data
                console.log("res.data start")
                console.log(res.data)
                console.log("res.data end")
            
                const itemUsersDataLists = this.buildList(data["TestNodaFurtherAction"])
                
                this.state.itemUserDataList  =  itemUsersDataLists
                
                let List =[]
                for (let i = 0; i < this.state.itemUserDataList.length; i++) {
                    let obj = {
                        'id'                    :   this.state.itemUserDataList[i]['id'],
                        'furtherAction'         :   this.state.itemUserDataList[i]['furtherAction'],
                       /*  'isDraft'               :   this.state.itemUserDataList[i]['isDraft'],
                        'isDeleted'             :   this.state.itemUserDataList[i]['isDeleted'],
                        'createdby'             :   this.state.itemUserDataList[i]['createdby'],
                        'createddate'           :   this.state.itemUserDataList[i]['createddate'],
                        'modifiedby'            :   this.state.itemUserDataList[i]['modifiedby'],
                        'modifieddate'          :   this.state.itemUserDataList[i]['modifieddate'],
                        'isModified'            :   this.state.itemUserDataList[i]['isModified'], */
                        
                    }
                    List.push(obj)
                    console.log(List)
                    
                    this.setState({
                        furtherActionsList:List

                    })
                   
                }
                
            })
            .catch(error=>{
                console.log("error: " + error)
            })
    } 
    onChangefurtherAction = (e) => {
        if(e.length == 0)
        {
            this.setState({
                selectedfurtherAction     :   '',
                selectedfurtherActionId   :   ''
            })
            return
        }
        this.state.selectedfurtherAction = e[0].furtherAction
        this.state.selectedfurtherActionId = e[0].id
       
        this.setState({
            isshow   :   false,
           selectedfurtherAction : e[0].furtherAction

        })
        console.log(this.state.selectedfurtherAction)
    }
        
    buildList(data) {
        //console.log("data start")
        let itemList =[]
    
        let idList                      =[]
        let furtherActionList           =[]
        let isDraftList                 =[]
        let isDeletedList               =[]
        let createdbyList               =[]
        let createddateList             =[]
        let modifiedbyList              =[]
        let modifieddateList            =[]
        let isModifiedList              =[]
        
    
        for (let i = 0; i < data.length; i++) {
            
    
            let s1 = data[i].split("':")
            
            let idClean                     = s1[1].replace("ObjectId(","").replace(")","").replace("{","")
            let IdClean2                    = idClean.split("',")[0]
            let furtherActionTmp            = s1[2].split("',")[0]
            let isDraftListTmp              = s1[3].split("',")[0]
            let isDeletedListTmp            = s1[4].split("',")[0]
            let createdbyListTmp            = s1[5].split("',")[0]
            let createddateListTmp          = s1[6].split("',")[0]
            let modifiedbyListTmp           = s1[7].split("',")[0]
            let modifieddateListTmp         = s1[8].split("',")[0]
            let isModifiedListTmp           = s1[9].split("',")[0]

        
            idList.push(IdClean2.replace("'",""))
            furtherActionList.push(furtherActionTmp.replace("'",""))
           
            isDraftList.push(isDraftListTmp.replace("'",""))
            isDeletedList.push(isDeletedListTmp.replace("'",""))
            createdbyList.push(createdbyListTmp.replace("'",""))
            createddateList.push(createddateListTmp.replace("'",""))
            modifiedbyList.push(modifiedbyListTmp.replace("'",""))
            modifieddateList.push(modifieddateListTmp.replace("'",""))
            isModifiedList.push(isModifiedListTmp.replace("'","").replace("'}",""))
        }
        
        for (let i = 0; i < idList.length; i++) {
    
            let obj ={
                'id'                    :   idList[i],
                'furtherAction'         :   furtherActionList[i],
                'isDraft'               :   isDraftList[i],
                'isDeleted'             :   isDeletedList[i],
                'createdby'             :   createdbyList[i],
                'createddate'           :   createddateList[i],
                'modifiedby'            :   modifiedbyList[i],
                'modifieddate'          :   modifieddateList[i],
                'isModified'            :   isModifiedList[i],
            
            }
            itemList.push(obj)
        }
        
       /*  console.log("itemList start")
        console.log(itemList)
        console.log("itemList End") */
        return itemList
    }
    handleChangeremarks=(e)=>{
        this.setState({
            remarks:e.target.value
        })
        console.log(e.target.value)
    }
    handleChangedateReceived=(e)=>{
        this.setState({
            dateReceived:e.target.value
        })
        console.log(e.target.value)
    }  
    handleChangeoffenceDescription=(e)=>{
        this.setState({
            offenceDescription:e.target.value
        })
        console.log(e.target.value)
    }
    handleChangesuspension=(e)=>{
        this.setState({
            suspension:e.target.value
        })
        console.log(e.target.value)
    }
    handleChangemonitaryValue=(e)=>{
        this.setState({
            monitaryValue:e.target.value
        })
        console.log(e.target.value)
    }
    applicableDateTo=(e)=>{
        this.setState({
            applicableDateTo:e.target.value
        })
        console.log(e.target.value)
    }
    handleChangeapplicableDateFrom=(e)=>{
        this.setState({
            applicableDateFrom:e.target.value
        })
        console.log(e.target.value)
    }
    handleChangeCheckBoxNo=(e)=>{
        this.setState({
            checkBoxNo:!this.state.checkBoxNo
        })
        console.log(this.state.checkBoxNo)
       
       this.handleDisable()
    }
    handleChangeterminationDate=(e)=>{
        this.setState({
            terminationDate:e.target.value
        })
        console.log(e.target.value)
    }
    handleDisable(){
        if(this.state.checkBoxNo === true){
            this.setState({
                disable:true,
                dateReceived:"",
            })
        }else{
            this.setState({
                disable:false
            })
        }

    }
    handleChangeCheckBoxYes=(e)=>{
        this.setState({
            checkBoxYes:!this.state.checkBoxYes
        })
        console.log(this.state.checkBoxYes)
        if(this.state.checkBoxYes === true){
            this.setState({
                disableAction:true,
                disableRemarks:true,
                remarks:'',
            })
        }else{
            this.setState({
                disableAction:false,
                disableRemarks:false,
            })
        }
        
    }
    getPenaltyType =()=>{
       
        let filter_data ={}

        const getParams ={
            "_collection" : "TestPenaltyType",
            "filter_data" : filter_data
        }
            console.log("axios start")
            axios
            .post("http://134.209.99.190:8088/action/get" , getParams)
            .then(res =>{
                let data = res.data
                console.log("res.data penalty Type start")
                console.log(res.data)
                console.log("res.data penalty Type end")
            
                const itemUsersDataLists = this.buildListpenaltyType(data["TestPenaltyType"])
                
                this.state.itemUserDataList  =  itemUsersDataLists
                
                let List =[]
                for (let i = 0; i < this.state.itemUserDataList.length; i++) {
                    let obj = {
                        'id'                    :   this.state.itemUserDataList[i]['id'],
                        'penaltyType'           :   this.state.itemUserDataList[i]['penaltyType'],
                        /* 'isDraft'               :   this.state.itemUserDataList[i]['isDraft'],
                        'isDeleted'             :   this.state.itemUserDataList[i]['isDeleted'],
                        'createdby'             :   this.state.itemUserDataList[i]['createdby'],
                        'createddate'           :   this.state.itemUserDataList[i]['createddate'],
                        'modifiedby'            :   this.state.itemUserDataList[i]['modifiedby'],
                        'modifieddate'          :   this.state.itemUserDataList[i]['modifieddate'],
                        'isModified'            :   this.state.itemUserDataList[i]['isModified'], */
                        
                    }
                    List.push(obj)
                   /*  console.log("res.data penalty Type start")
                    console.log(List)
                    console.log("res.data penalty Type start") */
                    
                    this.setState({
                        penaltyTypesList:List

                    })
                    console.log("this.state.penaltyTypesList start")
                    console.log(this.state.penaltyTypesList)
                    console.log("this.state.penaltyTypesList end")
                }

            })
            .catch(error=>{
                console.log("error: " + error)
            })
        } 
        
    buildListpenaltyType(data) {
        //console.log("data start")
        let itemList =[]
    
        let idList                      =[]
        let penaltyTypeList             =[]
        let isDraftList                 =[]
        let isDeletedList               =[]
        let createdbyList               =[]
        let createddateList             =[]
        let modifiedbyList              =[]
        let modifieddateList            =[]
        let isModifiedList              =[]
        
    
        for (let i = 0; i < data.length; i++) {
            
    
            let s1 = data[i].split("':")
            
            let idClean                     = s1[1].replace("ObjectId(","").replace(")","").replace("{","")
            let IdClean2                    = idClean.split("',")[0]
            let penaltyTypeTmp              = s1[2].split("',")[0]
            let isDraftListTmp              = s1[3].split("',")[0]
            let isDeletedListTmp            = s1[4].split("',")[0]
            let createdbyListTmp            = s1[5].split("',")[0]
            let createddateListTmp          = s1[6].split("',")[0]
            let modifiedbyListTmp           = s1[7].split("',")[0]
            let modifieddateListTmp         = s1[8].split("',")[0]
            let isModifiedListTmp           = s1[9].split("',")[0]

        
            idList.push(IdClean2.replace("'",""))
            penaltyTypeList.push(penaltyTypeTmp.replace("'",""))
           
            isDraftList.push(isDraftListTmp.replace("'",""))
            isDeletedList.push(isDeletedListTmp.replace("'",""))
            createdbyList.push(createdbyListTmp.replace("'",""))
            createddateList.push(createddateListTmp.replace("'",""))
            modifiedbyList.push(modifiedbyListTmp.replace("'",""))
            modifieddateList.push(modifieddateListTmp.replace("'",""))
            isModifiedList.push(isModifiedListTmp.replace("'","").replace("'}",""))
        }
        
        for (let i = 0; i < idList.length; i++) {
    
            let obj ={
                'id'                    :   idList[i],
                'penaltyType'           :   penaltyTypeList[i],
                'isDraft'               :   isDraftList[i],
                'isDeleted'             :   isDeletedList[i],
                'createdby'             :   createdbyList[i],
                'createddate'           :   createddateList[i],
                'modifiedby'            :   modifiedbyList[i],
                'modifieddate'          :   modifieddateList[i],
                'isModified'            :   isModifiedList[i],
            
            }
            itemList.push(obj)
        }
        
       /*  console.log("itemList start")
        console.log(itemList)
        console.log("itemList End") */
        return itemList
    }
    handleChangepenalty=(e)=>{
        if(e.length == 0)
        {
            this.setState({
                selectedpenaltyType     :   '',
                selectedpenaltyTypeId   :   ''
            })
            return
        }
        this.state.selectedpenaltyType = e[0].penaltyType
        this.state.selectedpenaltyTypeId = e[0].id
       
        this.setState({
            isshow              :   false,
            selectedpenaltyType :   e[0].penaltyType,
            penaltyVar          :   e[0].penaltyType
        })

        console.log(this.state.selectedpenaltyTypeId)
        console.log("this.state.selectedpenaltyTypeId")
        this.componentDidMount();
      
    } 
    handleChangeSuspesionInput(){
        if(this.state.selectedpenaltyType === " TERMINATION"){
            this.setState({
                terminationInput:true
            })
            console.log("TERMINATION")
        }
        else if(this.state.selectedpenaltyType === " SUSPENSION"){
            this.setState({
                suspensionInput:true
            })
            console.log("this.state.suspensionInput")
            console.log(this.state.suspensionInput)
        }else{
            console.log("false handleChangeSuspesionInput")
            //console.log(this.state.suspensionInput)
        }

        if(this.state.selectedpenaltyType === " TERMINATION"){
            this.setState({
                suspensionInput:false
            })
        }else if(this.state.selectedpenaltyType === " SUSPENSION"){
            this.setState({
                terminationInput:false
            })
          
        }else if(this.state.selectedpenaltyType === ""){
            this.setState({
                terminationInput:false
            })
            this.setState({
                suspensionInput:false
            })
           
        }else{
            this.setState({
                terminationInput:false
            })
            this.setState({
                suspensionInput:false
            })
           
        }
       
    }

    render() {
        
    return(
            <div>
                <Banner />
                    <Container className="mt-5" fluid>
                        <Card>
                            <Card.Header>Record NODA Penalty</Card.Header>
                            <Card.Body>
                                <Form>
                                    <Alert isOpen={this.state.isshow} color={this.state.color} fade={this.state.fade} className={this.state.isshow ? 'display-block' : 'display-none'}>
                                        <div className={this.state.color}></div> 
                                        {this.state.message}
                                    </Alert>
                                    <Card.Header>Employee Details</Card.Header>
                                    <Form.Group as={Row} className="mt-4" controlId="formPlaintextEmail">
                                        <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                            Client
                                        </Form.Label>
                                        <Col sm="11">
                                            <Typeahead
                                                labelKey='name'
                                                id="basic-example"
                                                onChange={this.onChangeClientList}
                                                options={this.state.getClientList}
                                                placeholder="Select Client"
                                            />
                                        </Col>
                                    </Form.Group>

                                    <Form.Group as={Row} controlId="formPlaintextEmail">
                                        <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                            Employee
                                        </Form.Label>
                                        <Col sm="8">
                                            <Typeahead
                                                labelKey='employeeName'
                                                id="basic-example"
                                                onChange={this.onChangeEmployeesList}
                                                options={this.state.getEmployeeList}
                                                placeholder="Select Employee"
                                                
                                            />
                                        </Col>
                                        <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                            Emp No.
                                        </Form.Label>
                                        <Col sm="2">
                                            <Form.Control 
                                                type="text"
                                                name="selectedEmployeeName"
                                                value={this.state.saveEmployeeNo}
                                                //onChange={this.onChangePosition} 
                                                autoComplete="off"
                                                placeholder="Autofill"
                                            />
                                        </Col>
                                    </Form.Group>

                                    <Form.Group as={Row} controlId="formPlaintextEmail">
                                        <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                            Position
                                        </Form.Label>
                                        <Col sm="6">
                                            <Form.Control 
                                                type="text"
                                                name="selectedEmployeeName"
                                                value={this.state.selectedPosition}
                                                onChange={this.onChangePosition} 
                                                autoComplete="off"
                                                placeholder="Autofill"
                                            />
                                        </Col>
                                        <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                        </Form.Label>
                                        
                                    </Form.Group>

                                    <Form.Group as={Row} controlId="formPlaintextEmail">
                                        <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                            Location
                                        </Form.Label>
                                        <Col sm="11">
                                            <Form.Control 
                                                type="text"
                                                name="selectedEmployeeName"
                                                value={this.state.selectedEmployeeBranch}
                                                onChange={this.onChangeLocation} 
                                                autoComplete="off"
                                                placeholder="Auto Fill"
                                            />
                                        </Col>
                                    </Form.Group>

                                    <Form.Group as={Row} controlId="formPlaintextEmail">
                                        <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                            Date
                                        </Form.Label>
                                        <Col sm="2">
                                        <DatePicker
                                                ref='currentDate'
                                                selected={this.state.currentDate}
                                                onChange={this.handleChangeDate}
                                                minDate={this.minDate}
                                                value={this.props.currentDate}
                                                dateFormat={"MM/dd/yyyy"}
                                                placeholderText="Current Date"
                                                className="form-control"
                                            />
                                        </Col>
                                        <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                            NODA Reference No.
                                        </Form.Label>
                                        <Col sm="3">
                                            <Form.Control 
                                                type="text"
                                                name="nodaRefNo"
                                                value={this.state.nodaRefNo}
                                                onChange={this.handleChangenodaRefNo} 
                                                autoComplete="off"
                                                placeholder="Enter NODA Reference No."
                                            />
                                        </Col>
                                        <Col sm="4">
                                        </Col>
                                    </Form.Group>

                                    <Form.Group as={Row} controlId="formPlaintextEmail">
                                        <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                            NODA DATE
                                        </Form.Label>
                                        <Col sm="2">
                                            <Form.Control 
                                                type="text"
                                                name="selectedEmployeeName"
                                                value={this.state.nodaDate}
                                                onChange={this.handleChangenodaDate} 
                                                autoComplete="off"
                                                //placeholder="Enter NTE Ref No."
                                            />
                                        </Col>
                                                                                
                                    </Form.Group>
                                    <Form.Group as={Row} className="mt-3" controlId="formPlaintextEmail">
                                        <Col sm="4">
                                            <label  style={{fontWeight : "bold"}}>Attached NODA Copy</label>
                                            <Input
                                                type="file" 
                                                style={{backgroundColor:"#008BFF"}} 
                                                className="file" 
                                                placeholder="attached file" 
                                                variant="primary" 
                                                onChange={this.handleChangeAttachedNodaCopy} 
                                                value={this.state.AttachedNodaCopy} 
                                            />  
                                        </Col>        
                                    </Form.Group>
                                    <Form.Label column sm="3" style={{fontWeight : "bold"}}>
                                        NODA Received and Acknowledge by Member
                                    </Form.Label>                                    

                                    <Form.Group as={Row} controlId="formPlaintextEmail" className="mt-2">
                                        <Col sm="1" style={{marginLeft:"50px", marginTop:"6px"}}>
                                            <Input
                                                type='checkbox'
                                                name='No'
                                                onChange={this.handleChangeCheckBoxYes}
                                            />
                                            <label style={{fontWeight : "bold" }}>No</label>
                                        </Col>                                       
                                        <Form.Label column sm="2" style={{fontWeight : "bold" ,marginLeft:"-10px"}}>
                                            Action
                                        </Form.Label>
                                        <Col sm="2">
                                            <Typeahead
                                                onChange={this.onChangefurtherAction}
                                                options={this.state.furtherActionsList} 
                                                placeholder="Select Further Action"
                                                labelKey='furtherAction'
                                                id="basic-example"
                                                disabled={this.state.disableAction}
                                              
                                            />
                                        </Col>
                                        <Col sm="3">
                                            <Form.Control 
                                                type="text"
                                                name="remarks"
                                                value={this.state.remarks}
                                                onChange={this.handleChangeremarks} 
                                                autoComplete="off"
                                                placeholder="Remarks"
                                                disabled={this.state.disableRemarks}
                                            />
                                        </Col>                                                                                  
                                    </Form.Group>
                                    <Form.Group as={Row} controlId="formPlaintextEmail">
                                        <Col sm="1" style={{marginLeft:"50px", marginTop:"6px"}}>
                                            <Input
                                                type='checkbox'
                                                name='Yes'
                                                onChange={this.handleChangeCheckBoxNo}
                                            />
                                            <label style={{fontWeight : "bold" }}>Yes</label>
                                        </Col>
                                        <Form.Label column sm="2" style={{fontWeight : "bold" ,marginLeft:"-10px"}}>
                                            Date Received 
                                        </Form.Label>
                                        <Col sm="2">
                                            <Form.Control 
                                                type="text"
                                                name="dateReceived"
                                                value={this.state.dateReceived}
                                                onChange={this.handleChangedateReceived} 
                                                autoComplete="off"
                                                placeholder="Enter Date Received"
                                                disabled={this.state.disable}
                                            />
                                        </Col>                                                                                                                        
                                    </Form.Group>

                                    <Card.Header>Applicable Penalty</Card.Header>

                                    <Form.Group as={Row} controlId="formPlaintextEmail" className="mt-3">
                                        <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                            Penalty
                                        </Form.Label>
                                        <Col sm="3">
                                            <Typeahead
                                                onChange={this.handleChangepenalty}
                                                options={this.state.penaltyTypesList} 
                                                placeholder="Select Penalty Type"
                                                labelKey='penaltyType'
                                                id="basic-example"
                                                //disabled={this.state.disableAction}
                                              
                                            />
                                        </Col>  
                                        <Col sm="2">  </Col>    
                                        <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                            Monetary Value
                                        </Form.Label>
                                        <Col sm="2">
                                            <Form.Control 
                                                type="text"
                                                name="monitaryValue"
                                                value={this.state.monitaryValue}
                                                onChange={this.handleChangemonitaryValue} 
                                                autoComplete="off"
                                                placeholder="Enter Amount"
                                            />
                                        </Col>                                                                                                                    
                                    </Form.Group> 
                                    <Form.Group as={Row} controlId="formPlaintextEmail">
                                        <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                           Offence Description
                                        </Form.Label>
                                        <Col sm="10">
                                            <Form.Control 
                                                type="text"
                                                name="offenceDescription"
                                                value={this.state.offenceDescription}
                                                onChange={this.handleChangeoffenceDescription} 
                                                autoComplete="off"
                                                //placeholder="Enter Date Received"
                                            />
                                        </Col>                                                                                                                        
                                    </Form.Group>
                                    {this.state.suspensionInput?  
                                        <Form.Group as={Row} controlId="formPlaintextEmail">
                                            <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                Suspension
                                            </Form.Label>
                                            <Col sm="2">
                                                <Form.Control 
                                                    type="text"
                                                    name="suspension"
                                                    value={this.state.suspension}
                                                    onChange={this.handleChangesuspension} 
                                                    autoComplete="off"
                                                    placeholder="Enter No. of days"
                                                />
                                            </Col>
                                            <Col sm="2"> </Col>

                                            <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                Applicable Date
                                            </Form.Label>
                                            <Col sm="2">
                                                <Form.Control 
                                                    type="text"
                                                    name="applicableDateFrom"
                                                    value={this.state.applicableDateFrom}
                                                    onChange={this.handleChangeapplicableDateFrom} 
                                                    autoComplete="off"
                                                    placeholder="Date From"
                                                />
                                            </Col>   
                                            <Col sm="2">
                                                <Form.Control 
                                                    type="text"
                                                    name="applicableDateTo"
                                                    value={this.state.applicableDateTo}
                                                    onChange={this.applicableDateTo} 
                                                    autoComplete="off"
                                                    placeholder=" Date To"
                                                />
                                            </Col>                                                                                                                         
                                        </Form.Group>:null
                                    }
                                    {this.state.terminationInput?
                                        <Form.Group as={Row} controlId="formPlaintextEmail">
                                            <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                Termination Date
                                            </Form.Label>
                                            <Col sm="2">
                                                <Form.Control 
                                                    type="text"
                                                    name="terminationDate"
                                                    value={this.state.terminationDate}
                                                    onChange={this.handleChangeterminationDate} 
                                                    autoComplete="off"
                                                    placeholder="Enter Date"
                                                />
                                            </Col>
                                        </Form.Group>:null
                                    }                                      

                                    <Form.Group as={Row} controlId="formPlaintextEmail" style={{marginRight:"5px"}}>
                                            <Button variant="success"  className="ml-auto "  style={{minWidth:'60px'}} onClick={this.handleSave} >Save</Button>&nbsp;&nbsp;
                                            <Button variant="danger" style={{minWidth:'60px'}} >Close</Button>
                                    </Form.Group>

                                </Form>
                            </Card.Body>
                        </Card>
                       
                    </Container>
                    <NoserLoading show={this.state.isloading} />
                   {/* <InsertPenaltyStatement 
                        show={this.state.modalInsertPenalty}
                        onHide={this.handleModalClosePenalty}
                    /> 
                    <InsertCOCReference
                        show={this.state.modalCOCReference}
                        onHide={this.handleModalCloseCOC}
                    /> */}
            </div> 
        )
    }

}

export  default RecordNodaPenalty 
