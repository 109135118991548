import {
    React,Component,BootstrapTable,
    paginationFactory,Button, ButtonToolbar, Card, 
    Form, Col, Row, axios, Container, Banner, Typeahead, TimePicker,
    cellEditFactory, Type, moment, NavLink, Alert, AppConfiguration,
    NoserLoading, Noser, sizePerPageRenderer, DatePicker, Modal
} 
from '../../../noser-hris-component';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css';

class AddUserControl extends Component {
    constructor(props) {
        super(props)
        this.state = {
            userinfo    :   JSON.parse(sessionStorage.getItem("userData")),
            isloading   :   false,
            isshow      :   false,
            alerttype   :   "",
            message     :   "",
            color       :   "",
            fade        :   true,
            disableAdd  :   true,

            accessId    :   '',
            employeeId  :   '',
            employeeName:   '',
            isClient    :   '',
            referenceId :   '',
            referenceName:  '',
            referenceGRID:  [],
            referenceSel:   [],
            defaultSearch:  ''
        }
    }
    componentDidMount(){
        this.state.userinfo = JSON.parse(sessionStorage.getItem("userData"))
        this.props.onRefModal(this)
    }
    componentWillUnmount() {
        this.props.onRefModal(undefined)
    }
    initialize=(e)=>{
        
        this.setState({
            userinfo    :   JSON.parse(sessionStorage.getItem("userData")),
            isloading   :   false,
            isshow      :   false,
            alerttype   :   "",
            message     :   "",
            color       :   "",
            fade        :   true,
            disableAdd  :   true,
            
            accessId    :   e.accessId,
            employeeId  :   e.employeeId,
            employeeName:   e.employeeName,
            isClient    :   e.isClient,
            referenceId :   '',
            referenceName:  '',
            referenceGRID:  [],
            referenceSel:   [],
            defaultSearch:  ''
        })
        if(e.isClient==="1"){
            this.GetClients()
        }
        else{
            this.GetPositions()
        }
    }
    GetPositions = async() => {
        this.setState({isloading:true,isDisable:true})
        const params = {
            "IpAddress"     :   "0.0.0.0",
            "ClientId"      :   this.state.userinfo.clientId,
            "UserId"        :   this.state.userinfo.userId,
            "SectionId"     :   "",
            "DepartmentId"  :   "",
            "Name"          :   ""
        };
        await axios.post(AppConfiguration.Setting().noserapiendpoint + "Maintenance/GetPositions",  params)
        .then(res => {
            const data = res.data;
            let referenceGRID=[]
            data.positions.forEach(itm=>{
                referenceGRID.push({
                    id:itm.id,
                    name:itm.name
                })
            })
            
            this.setState({
                isloading   :   false,
                alerttype   :   "",
                isshow      :   false,
                color       :   "",
                message     :   "",
                fade        :   false,
                referenceGRID:referenceGRID,
            });
        })
        .catch(error=>{
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade        :   true,
            })
        })
    }
    GetClients = async() =>{
        this.setState({isloading:true,isDisable:true})
        const params = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.userinfo.clientId,
            "UserId":this.state.userinfo.userId,
        }
        
        await axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Client/GetClientList", params)
        .then(res => {
            const data = res.data
            console.log(data)
            let referenceGRID=[]
            data.clients.forEach(itm=>{
                referenceGRID.push({
                    id:itm.id,
                    name:itm.name
                })
            })
            this.setState({
                isloading   :   false,
                alerttype   :   "",
                isshow      :   false,
                color       :   "",
                message     :   "",
                fade        :   false,
                referenceGRID:referenceGRID,
            });
        })
        .catch(error=>{
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade        :   true,
            })
        })
    }
    handleSubmit = (e) =>{
        this.setState({isloading:true})

        if(this.state.referenceSel.length===0){
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   this.state.isClient==="1" ? "Please add client." : "Please add position.",
                fade        :   true
            })
            return 
        }
        // const param = {
        //     "employeeId":this.state.employeeId,
        //     "accessId":this.state.accessId,
        //     "controlAccessDetails":this.state.referenceSel.filter(x=>x.isSelected==="1")
        // }
        
        const param = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.userinfo.clientId,
            "UserId":this.state.userinfo.userId,
            "EmployeeId":this.state.employeeId,
            "AccessId":this.state.accessId,
            "controlAccessDetails":this.state.referenceSel.filter(x=>x.isSelected==="1")
        }
        console.log("selected")
        console.log(this.state.referenceSel)
        axios
            .post(
                AppConfiguration.Setting().noserapiendpoint + "Administrator/AddUserControlAccessDetail",  param
            )
            .then(res => {
                const data = res.data;
                this.setState({
                    isloading   :   false,
                    alerttype   :   res.data.status=="1" ? "Success!" : "!Error",
                    isshow      :   true,
                    color       :   res.data.status=="1" ? "success" : "danger",
                    message     :   data.message,
                    fade        :   true
                });
            })
            .catch(error=>{
                this.setState({
                    isloading   :   false,
                    alerttype   :   "Error!",
                    isshow      :   true,
                    color       :   "danger",
                    message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                    fade        :   true
                })
            })
            
    }
    handleModalClose = () => {
        //this.props.onHide({"controlAccessDetails":[]});
        this.props.onHide();
    }
    
    handleChanged = (e) =>{
        
    }
    
    render() {
        const { SearchBar, ClearSearchButton } = Search;
        const col = [
            {
                dataField: 'name',
                text: 'NAME',
                editable: false,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'left',width:'100%' }},
                style:{textAlign:'left',width:'100%'},
                //filter: textFilter()
            },
        ]
        const selectRow = {
            mode: 'checkbox',
            hideSelectAll: false,
            clickToSelectAndEditCell: true,
            onSelect: (row, isSelect, rowIndex, e) => {
                if(isSelect)
                {
                    this.state.referenceSel.push({
                        "isClient":this.state.isClient,
                        "accessId":this.state.accessId,
                        "employeeId":this.state.employeeId,
                        "referenceId":row.id,
                        "referenceName":row.name,
                        "isSelected":"1"
                    })
                }
                else{
                    let referenceSel = this.state.referenceSel
                    this.state.referenceSel.map(function(itm,idx){
                        if(itm.id===row.id){
                            referenceSel.splice(idx,1)
                        }
                    })
                    this.setState({referenceSel:referenceSel})
                }
            },
            onSelectAll: (isSelected, rows) => {
                this.state.referenceSel = []
                if (isSelected) {
                    this.state.referenceGRID.forEach(itm =>{
                        this.state.referenceSel.push({
                            "isClient":this.state.isClient,
                            "accessId":this.state.accessId,
                            "employeeId":this.state.employeeId,
                            "referenceId":itm.id,
                            "referenceName":itm.name,
                            "isSelected":isSelected ? "1" : "0"
                        })
                    })
                    return this.state.referenceGRID.map(itm => itm.id);
                }
                else{
                    this.state.referenceSel = []
                }
            },
            selected: this.state.selectedRef,
        };
        
    return(
        
            <Modal
                {...this.props}
                return
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                backdrop="static"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        ADD CLIENT
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container>
                        <Alert isOpen={this.state.isshow} color={this.state.color} fade={this.state.fade} className={this.state.isshow ? 'display-block' : 'display-none'}>
                            <div className={this.state.color}></div> 
                            {this.state.message}
                        </Alert>
                        <Form.Row>
                            <Form.Group as={Col} sm={12} >
                                <Form.Control 
                                    name='name'
                                    type="text" 
                                    onChange={this.handleChange} 
                                    autoComplete="off" 
                                    value={this.state.employeeName}
                                    disabled
                                />
                            </Form.Group>
                        </Form.Row>
                        <Form.Row>
                            <Form.Group as={Col} sm={12} controlId="formGridEmail">
                                <div className="mt-1">
                                <ToolkitProvider
                                    keyField="id"
                                    data={ this.state.referenceGRID }
                                    columns={ col }
                                    search = {{defaultSearch: this.state.isClient==="1" ? 'DALIRETAIL INC.' : '10 WHEELER HELPER'}}
                                    >
                                    {
                                        props => (
                                        <div>
                                            <SearchBar { ...props.searchProps } />
                                            <ClearSearchButton { ...props.searchProps } />
                                            <BootstrapTable
                                            { ...props.baseProps }
                                            selectRow = { selectRow }
                                            cellEdit = { cellEditFactory({ 
                                                    mode: 'dbclick', 
                                                    blurToSave: true,
                                                    afterSaveCell: (oldValue, newValue, row, column) => { 
                                                        this.GridDataModified(oldValue, newValue, row.id, column.dataField)
                                                    }
                                                })
                                            }
                                            rowClasses="noser-table-row-class"
                                            striped
                                            hover
                                            condensed
                                            loading={true}
                                            wrapperClasses="table-responsive"
                                            pagination={ paginationFactory({sizePerPageRenderer})}
                                            noDataIndication={ () => <div style={{width:'100%'}}>No record found.</div> }
                                            />
                                            {/* <hr /> */}
                                            {/* <BootstrapTable
                                                keyField = "id"
                                                data = { this.state.userclients }
                                                columns = { col }
                                                selectRow = { selectRow }
                                                cellEdit = { cellEditFactory({ 
                                                        mode: 'dbclick', 
                                                        blurToSave: true,
                                                        afterSaveCell: (oldValue, newValue, row, column) => { 
                                                            this.GridDataModified(oldValue, newValue, row.id, column.dataField)
                                                        }
                                                    })
                                                }
                                                rowClasses="noser-table-row-class"
                                                striped
                                                hover
                                                condensed
                                                loading={true}
                                                wrapperClasses="table-responsive"
                                                pagination={ paginationFactory({sizePerPageRenderer})}
                                                noDataIndication={ () => <div style={{width:'100%'}}>No record found.</div> }
                                            /> */}
                                        </div>
                                        )
                                    }
                                    </ToolkitProvider>
                                    
                                </div>
                                
                            </Form.Group>
                        </Form.Row>
                    </Container>
                </Modal.Body>
                <Modal.Footer>
                    <ButtonToolbar >
                        <Button variant="success" className="ml-auto noser-button-mr1" onClick = { this.handleSubmit }>Save</Button>
                        <Button variant="danger" className="noser-button" onClick={this.handleModalClose}>Close</Button>
                    </ButtonToolbar>
                </Modal.Footer>
                <NoserLoading show={this.state.isloading} />
        </Modal>
        );
    }
}
export default AddUserControl