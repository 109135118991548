import {
    React,Component,BootstrapTable,
    paginationFactory,Button, ButtonToolbar, Card, 
    Form, Col, Row, axios, Container, Banner, Typeahead, TimePicker,
    cellEditFactory, Type, moment, NavLink, Alert, AppConfiguration,
    NoserLoading, Noser, sizePerPageRenderer
} 
from '../../noser-hris-component';
import { DropdownButton, Dropdown} from 'react-bootstrap';

class PostTrainingExaminationCreate extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fade            :   true, 
            color           :   "",
            isshow          :   false,
            message         :   "",
            userinfo        :   [],
            isloading       :   false,
            alerttype       :   "",

            disableAddButton:   true,
            disableRemoveButton:true,

            traininId       :   "",
            examName        :   "",
            instruction     :   "",
            noOfItems       :   "",
            timeLimit       :   "",
            passingScore    :   "",
            isRequired      :   true,
            isPostTest      :   false,
            trainingDDL     :   [],
            yesnoDDL        :   [{ "value" : 1, "label" : "CORRECT" },{ "value" : 0, "label" : "INCORRECT" }],

            clientGrid      :   [],
            positionGrid    :   [],
            questionGrid    :   [],
            choiceGrid      :   [
                {
                    "id":"1",
                    "name":"",
                    "answer":"1"
                },
                {
                    "id":"2",
                    "name":"",
                    "answer":"0",
                },
                {
                    "id":"3",
                    "name":"",
                    "answer":"0",
                },
                {
                    "id":"4",
                    "name":"",
                    "answer":"0",
                }
            ],
            questionName: "",
            answerName:"",
            clientGridIndicator: "No record found.",
            positionGridIndicator: "No record found.",
            isSetACheck : true,
            isSetBCheck : false,
            isSetCCheck : false,
            setExam     : "SET A",
        }
    }

    componentDidMount(){
        const sleep = (milliseconds) => {return new Promise(resolve => setTimeout(resolve, milliseconds))}
        this.state.userinfo = JSON.parse(sessionStorage.getItem("userData"))
        this.GetTrainings();
    }
    GetTrainings = async() =>{
        this.setState({isloading:true})
        const param = {
            "IpAddress"     :   "0.0.0.0",
            "UserId"        :   this.state.userinfo.userId,
            "ClientId"      :   "",
            "TrainingId"    :   ""
        };
        await axios.post(AppConfiguration.Setting().noserapiendpoint + "Maintenance/GetTrainings",  param)
        .then(res => {
            const data = res.data;
            this.setState({trainingDDL:data.trainings,isloading:false})
        })
        .catch(error=>{
            this.setState({
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade            :   true
            })
        })
    }
    handleChangedTraining = (e) =>{
        if(e.length === 0) {
            this.state.trainingId=""
            this.state.clientGrid=[]
            this.state.clientGridIndicator="No record found."
            this.state.positionGrid=[]
            this.state.positionGridIndicator="No record found."
            return
        }
        this.state.clientGrid=[]
        this.state.positionGrid=[]
        this.state.trainingId=e[0].id
        this.state.clientGrid=e[0].trainingClients
        this.state.clientGridIndicator=e[0].trainingClients.length==0 ? "ALL CLIENTS" : ""
        this.state.positionGrid=e[0].trainingPositions
        this.state.positionGridIndicator=e[0].trainingPositions.length==0 ? "ALL POSITIONS" : ""
        this.setState({isshow:false})
    }
    handleChangedRequired = (e) =>{
        this.setState({isRequired:e.target.checked})
        if(e.target.name==="mandatory")
        this.setState({isRequired:true})
        else
        this.setState({isRequired:false})
        
    }
    handleChangedPostTest = (e) =>{
        this.setState({isPostTest:e.target.checked})
        if(e.target.name==="posttest")
        this.setState({isPostTest:true})
        else
        this.setState({isPostTest:false})
        
    }
    handleChanged = (e) =>{
        this.setState({[e.target.name]:e.target.value,isshow:false})

        if(e.target.name==="noOfItems" && e.target.value!==""){
            this.setState({
                disableAddButton:parseInt(e.target.value)<=this.state.questionGrid.length ? true:false
            }) 
        }
        if(e.target.name==="noOfItems" && e.target.value===""){
            this.setState({
                disableAddButton:true
            }) 
        }

    }
    IsNumeric(evt){
        var theEvent = evt || window.event;
        var key = theEvent.keyCode || theEvent.which;
        key = String.fromCharCode( key );
        var regex = /^\d+(.\d+)?$/;
        if( !regex.test(key) ) {
        theEvent.returnValue = false;
        if(theEvent.preventDefault) theEvent.preventDefault();
        }
    }
    handleAddQuestion = () =>{

        let questionGrid = this.state.questionGrid
        if(this.state.noOfItems==="" || parseInt(this.state.noOfItems)===0){
            this.setState(  {
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "Please input no of item.",
                fade            :   true
            })
            return
        }
        
        if(this.state.questionName.trim()===""){
            this.setState(  {
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "Please input question.",
                fade            :   true
            })
            return
        }
        let choices = this.state.choiceGrid
        if(this.state.answerName.trim()==="" && choices.filter(x=>x.name.trim()==="").length>0){
            this.setState(  {
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "Please input either choices or specific answer.",
                fade            :   true
            })
            return
        }

        let newId = 0;
        if(questionGrid.length===0){
            let today = new Date()
            newId = moment(today).format('HHMMSS');
        }
        else{
            let today = new Date()
            const ids = questionGrid.map(x => x.id);
            const sorted = ids.sort((a, b) => a - b);
            const uid = moment(today).format('SS');
            newId = parseInt(sorted[sorted.length-1])+1+uid;
        }
        
        
        let question = {
            "id":newId.toString(),
            "name":this.state.questionName,
            "answer":this.state.answerName,
            "isDeleted":"0",
            "choices" : this.state.answerName.trim()!=="" ? [] : choices
        }
        questionGrid.push(question)
        let choiceGrid=[
            {
                "id":"1",
                "name":"",
                "answer":"1"
            },
            {
                "id":"2",
                "name":"",
                "answer":"0",
            },
            {
                "id":"3",
                "name":"",
                "answer":"0",
            },
            {
                "id":"4",
                "name":"",
                "answer":"0",
            }
        ]
        this.setState({
            isshow:false,
            questionGrid:questionGrid,
            disableAddButton:parseInt(this.state.noOfItems)<=questionGrid.length ? true:false,
            questionName:"",
            answerName:"",
            choiceGrid:choiceGrid
        }) 
    }
    handleRemoveQuestion = () =>{
        let questionGrid = this.state.questionGrid.filter(x=>x.isDeleted=="0")
        this.state.questionGrid = []
        this.setState({isshow:false,questionGrid:questionGrid,disableAddButton:false,disableRemoveButton: questionGrid.length===0 ? true : false})
    }
    handleChangedSetExam = (e) => {
        this.setState({setExam : e.target.value.toUpperCase()})
    }

    handleChangeCheckboxA = (e) =>{
        this.setState({
            isSetACheck : true,
            isSetBCheck : false,
            isSetCCheck : false,
            setExam     : e.target.name
        })
    }

    handleChangeCheckboxB = (e) =>{
        this.setState({
            isSetBCheck : true,
            isSetACheck : false,
            isSetCCheck : false,
            setExam     : e.target.name
        })
    }

    handleChangeCheckboxC = (e) =>{
        this.setState({
            isSetCCheck : true,
            isSetACheck : false,
            isSetBCheck : false,
            setExam     : e.target.name
        })
    }
    handleSaveClick = async() =>{
        this.setState({isshow:false,isloading:true})
        
        if(this.state.trainingId===""){
            this.setState(  {
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "Please select training name.",
                fade            :   true
            })
            return
        }

        if(this.state.examName===""){
            this.setState(  {
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "Please enter Exam name.",
                fade            :   true
            })
            return
        }

        if(this.state.setExam===""){
            this.setState(  {
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "Please enter Set Exam.",
                fade            :   true
            })
            return
        }

        if(this.state.instruction===""){
            this.setState(  {
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "Please enter instructions.",
                fade            :   true
            })
            return
        }

        if(this.state.noOfItems===""){
            this.setState(  {
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "Please enter no of item(s).",
                fade            :   true
            })
            return
        }

        if(this.state.timeLimit==="" || this.state.timeLimit==="0"){
            this.setState(  {
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "Please enter time limit.",
                fade            :   true
            })
            return
        }

        if(this.state.passingScore==="" || this.state.passingScore==="0"){
            this.setState(  {
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "Please enter passing score.",
                fade            :   true
            })
            return
        }

        let questionGrid = this.state.questionGrid
        if(questionGrid.length===0){
            this.setState(  {
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "Please enter question(s).",
                fade            :   true
            })
            return
        }
        
        if(questionGrid.length!=parseInt(this.state.noOfItems)){
            this.setState(  {
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "No of question(s) is not equal to the no of item(s).",
                fade            :   true
            })
            return
        }
        
        if(questionGrid.length>0){
            for(let i=0;i<questionGrid.length;i++){
                if(questionGrid[i]["name"]===""){
                    this.setState(  {
                        isloading       :   false,
                        alerttype       :   "Error!",
                        isshow          :   true,
                        color           :   "danger",
                        message         :   "Please enter question name in row " + (i+1).toString() + ".",
                        fade            :   true
                    })
                    return
                }
                if(questionGrid[i]["answer"]==="" && questionGrid[i].choices.filter(x=>x.name==="").length>0){
                    this.setState(  {
                        isloading       :   false,
                        alerttype       :   "Error!",
                        isshow          :   true,
                        color           :   "danger",
                        message         :   "Please complete all fields in " + questionGrid[i]["name"] + ".",
                        fade            :   true
                    })
                    return
                }
                if(questionGrid[i]["answer"]==="" && questionGrid[i].choices.filter(x=>x.answer==="1").length==0){
                    this.setState(  {
                        isloading       :   false,
                        alerttype       :   "Error!",
                        isshow          :   true,
                        color           :   "danger",
                        message         :   questionGrid[i]["name"] + " has no selected answer.",
                        fade            :   true
                    })
                    return
                }
            }
        }

        const param = {
            "IpAddress"     :   "0.0.0.0",
            "UserId"        :   this.state.userinfo.userId,
            "ClientId"      :   this.state.userinfo.clientId,
            "TrainingId"    :   this.state.trainingId,
            "Name"          :   this.state.examName,
            "Instructions"  :   this.state.instruction,
            "NoOfItems"     :   this.state.noOfItems,
            "TimeLimit"     :   this.state.timeLimit,
            "PassingScore"  :   this.state.passingScore,
            "IsRequired"    :   (this.state.isRequired) ? "1" : "0",
            "IsPostTest"    :   (this.state.isPostTest) ? "1" : "0",
            "questions"     :   questionGrid
        };
        await axios
            .post(
                AppConfiguration.Setting().noserapiendpoint + "Training/AddPostExamination",  param
            )
            .then(res => {
            const data = res.data
            if(data.status=="1"){
                this.setState({
                    disableAddButton:true,
                    disableRemoveButton:true,
                    isRequired:true,
                    trainingId:"",
                    examName:"",
                    noOfItems:"",
                    passingScore:"",
                    timeLimit:"",
                    instruction:"",
                    clientGrid:[],
                    positionGrid:[],
                    questionGrid:[]
                })
                this.refs.trainingId.clear()
            }
            this.setState({
                isloading   :   false,
                alerttype   :   data.status==="1" ? "Success!" : "Error!",
                isshow      :   true,
                color       :   data.status==="1" ? "success" : "danger",
                message     :   data.message,
                fade        :   true
            });
        })
        .catch(error=>{
            this.setState({
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade            :   true
            })
        })
    }
    render() {
        const clientCol = [
            {
                dataField: 'client',
                text: 'CLIENT',
                headerStyle : () => {
                    return { width  : "100%" };
                }
            }
        ]
        const positionCol = [
            {
                dataField: 'position',
                text: 'POSITION',
                headerStyle : () => {
                    return { width  : "100%" };
                }
            }
        ]
        const selectClientRow = {
            mode: 'checkbox',
            hideSelectAll: true
        };
        const selectPositionRow = {
            mode: 'checkbox',
            hideSelectAll: true
        };
        const selectRow = {
            mode: 'checkbox',
            hideSelectAll: false,
            onSelect: (row, isSelect, rowIndex, e) => {
                let isDisable = true
                this.state.questionGrid.map(function(item,i){
                    if(item.id===row.id)
                        item.isDeleted = isSelect ? "1" : "0"
                    if(item.isDeleted=="1")
                        isDisable=false
                })
                this.setState({
                    disableRemoveButton:isDisable,
                    disableAddButton:parseInt(this.state.noOfItems)<=this.state.questionGrid.length ? true:false
                })
            },
            onSelectAll: (isSelect, rows) => {
                let isDisable = true
                this.state.questionGrid.map(function(itm){
                    itm.isDeleted=isSelect ? "1" : "0"
                    if(itm.isDeleted=="1")
                        isDisable=false
                })
                this.setState({
                    disableRemoveButton:isDisable,
                    disableAddButton:parseInt(this.state.noOfItems)<=this.state.questionGrid.length ? true:false
                })
            }
        };
        const questionCol =[
            {
                dataField: 'name',
                text: 'Questions',
                editable: true,
                headerStyle : () => {
                    return { width  : "50%" };
                }
            },
            {
                dataField: 'answer',
                text: 'Answer',
                editable: true,
                headerStyle : () => {
                    return { width  : "50%" };
                }
            }
        ]
        const choiceCol =[
            {
                dataField: 'name',
                text: 'Choices',
                editable: true,
                headerStyle : () => {
                    return { width  : "50%" };
                }
            },
            {
                dataField: 'answer',
                text: 'Answer',
                editable: true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'left',width:'50%'}},
                style:{textAlign:'left'},
                editor: {
                    type: Type.SELECT,
                    options: this.state.yesnoDDL
                  },
                formatter: (cell, row) => {
                    if(row.answer!=='' && row.answer!==null){
                        return this.state.yesnoDDL.find(x => x.value == cell).label;
                    }
                }
            }
        ]
        const defchoiceCol =[
            {
                dataField: 'name',
                text: 'Choices',
                editable: true,
                headerStyle : () => {
                    return { width  : "50%" };
                }
            },
            {
                dataField: 'answer',
                text: 'Answer',
                editable: true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'left',width:'50%'}},
                style:{textAlign:'left'},
                editor: {
                    type: Type.SELECT,
                    options: this.state.yesnoDDL
                  },
                formatter: (cell, row) => {
                    if(row.answer!=='' && row.answer!==null){
                        return this.state.yesnoDDL.find(x => x.value == cell).label;
                    }
                }
            }
        ]
        const selectChoiceRow = {
            mode: 'checkbox',
            hideSelectAll: true
        };
        const expandItems = {
            onlyOneExpanding: true,
            renderer: row => 
            (
                <div>
                    <BootstrapTable
                        keyField = "id"
                        data = { row.choices }
                        columns = { choiceCol }
                        rowClasses="noser-table-row-class no-checkbox"
                        striped
                        hover
                        condensed
                        selectRow = { selectChoiceRow }
                        cellEdit = { cellEditFactory({ 
                            mode: 'dbclick', 
                            blurToSave: true,
                            afterSaveCell: (oldValue, newValue, row, column) => { 
                                //this.GridModified(oldValue, newValue, row.rowIndex, column.dataField)
                            }
                        })}
                        noDataIndication={ () =>  <div style={{width:'100%'}}>specific answer</div> }
                    />
                </div>
            ),
            showExpandColumn: true
          };
    return(
            <div>
                <Banner />
                    <Container className="mt-5" fluid>
                        <Card>
                            <Card.Header>MAINTENANCE >> TRAINING MAINTENANCE  >> TRAINING EXAMINATION (CREATE)</Card.Header>
                            <Card.Body>
                                <Form>
                                    <Alert isOpen={this.state.isshow} color={this.state.color} fade={this.state.fade} className={this.state.isshow ? 'display-block' : 'display-none'}>
                                        <div className={this.state.color}></div> 
                                        {this.state.message}
                                    </Alert>
                                    <Form.Group as={Row} controlId="formPlaintextEmail">
                                        <Form.Label column sm="1">
                                            TRAINING NAME
                                        </Form.Label>
                                        <Col sm="11">
                                            <Typeahead
                                                ref="trainingId"
                                                labelKey='name'
                                                id="basic-example"
                                                onChange={this.handleChangedTraining}
                                                options={this.state.trainingDDL}
                                            />
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} controlId="formPlaintextEmail">
                                        <Form.Label column sm="1">
                                            EXAM NAME
                                        </Form.Label>
                                        <Col sm="11">
                                            <Form.Control 
                                                ref="examName"
                                                name="examName"
                                                value={this.state.examName}
                                                onChange={this.handleChanged}
                                                autoComplete="off"
                                            />
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} controlId="formPlaintextEmail">
                                        <Col sm="1">
                                        </Col>
                                        <Col sm="0.5">
                                            <Form.Check 
                                                type="checkbox" 
                                                label="SET A"
                                                onChange={e => this.handleChangeCheckboxA(e)}
                                                checked={this.state.isSetACheck}
                                                name="SET A"
                                            />
                                        </Col>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                        <Col sm="0.5">
                                            <Form.Check 
                                                type="checkbox" 
                                                label="SET B"
                                                onChange={e => this.handleChangeCheckboxB(e)}
                                                checked={this.state.isSetBCheck}
                                                name="SET B"
                                            />
                                        </Col>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                        <Col sm="0.5">
                                            <Form.Check 
                                                type="checkbox" 
                                                label="SET C"
                                                onChange={e => this.handleChangeCheckboxC(e)}
                                                checked={this.state.isSetCCheck}
                                                name="SET C"
                                            />
                                        </Col>
                                    </Form.Group>
                                    <Form.Row className="mt-3">
                                        <Col sm="12" md={{ offset: 1 }}>
                                            <Form.Check 
                                                inline
                                                type="radio" 
                                                label="MANDATORY" 
                                                name="mandatory"
                                                value={this.state.isRequired}
                                                checked={this.state.isRequired}
                                                onChange={this.handleChangedRequired}
                                                value={this.state.isRequired}
                                            />
                                            <Form.Check 
                                                inline
                                                type="radio" 
                                                label="OPTIONAL" 
                                                name="optional"
                                                value={!this.state.isRequired}
                                                checked={!this.state.isRequired}
                                                onChange={this.handleChangedRequired}
                                                value={!this.state.isRequired}
                                                
                                            />
                                        </Col>
                                    </Form.Row>
                                    <Form.Row className="mt-3">
                                        <Col sm="12" md={{ offset: 1 }}>
                                            <Form.Check 
                                                inline
                                                type="radio" 
                                                label="PRE-TEST" 
                                                name="pretest"
                                                value={!this.state.isPostTest}
                                                checked={!this.state.isPostTest}
                                                onChange={this.handleChangedPostTest}
                                            />
                                            <Form.Check 
                                                inline
                                                type="radio" 
                                                label="POST-TEST" 
                                                name="posttest"
                                                value={this.state.isPostTest}
                                                checked={this.state.isPostTest}
                                                onChange={this.handleChangedPostTest}
                                            />
                                        </Col>
                                    </Form.Row>
                                    <fieldset className="border p-1 mt-2">
                                        <legend className="w-auto">Included Client(s) and Position(s)</legend>
                                    <Form.Row>
                                        <Form.Group as={Col} sm={6} controlId="formGridPassword">
                                            {/* <Card.Header className="mt-3">Included Client(s)</Card.Header> */}
                                            <div>
                                                <BootstrapTable
                                                    keyField = "id"
                                                    data = { this.state.clientGrid }
                                                    columns = { clientCol }
                                                    noDataIndication={ () => <div style={{width:'100%'}}>No record found.</div> }
                                                    rowClasses="noser-table-row-class no-checkbox"
                                                    striped
                                                    hover
                                                    condensed
                                                    selectRow = { selectClientRow }
                                                    noDataIndication={ () =>  <div style={{width:'100%'}}>{this.state.clientGridIndicator}</div> }
                                                />
                                            </div>
                                        </Form.Group>
                                        <Form.Group as={Col} sm={6} controlId="formGridPassword">
                                            {/* <Card.Header className="mt-3">Included Position(s)</Card.Header> */}
                                            <div>
                                                <BootstrapTable
                                                    keyField = "id"
                                                    data = { this.state.positionGrid }
                                                    columns = { positionCol }
                                                    noDataIndication={ () => <div style={{width:'100%'}}>No record found.</div> }
                                                    rowClasses="noser-table-row-class no-checkbox"
                                                    striped
                                                    hover
                                                    condensed
                                                    selectRow = { selectPositionRow }
                                                    noDataIndication={ () =>  <div style={{width:'100%'}}>{this.state.positionGridIndicator}</div> }
                                                />
                                            </div>
                                        </Form.Group>
                                    </Form.Row>
                                    </fieldset>
                                    
                                    <fieldset className="border p-1 mt-2">
                                        <legend className="w-auto">Exam Details</legend>
                                        <Form.Group as={Row} controlId="formPlaintextEmail">
                                            <Form.Label column sm="1">
                                                INSTRUCTION
                                            </Form.Label>
                                            <Col sm="11">
                                                <Form.Control
                                                    as="textarea"
                                                    rows={3} 
                                                    ref="instruction"
                                                    name="instruction"
                                                    value={this.state.instruction}
                                                    onChange={this.handleChanged} 
                                                    autoComplete="off" 
                                                    maxLength="1000"
                                                />
                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row} controlId="formPlaintextEmail">
                                            <Form.Label column sm="1">
                                                NO. OF ITEMS
                                            </Form.Label>
                                            <Col sm="2">
                                                <Form.Control 
                                                    ref="noOfItems"
                                                    type="text" 
                                                    name="noOfItems"
                                                    value={this.state.noOfItems}
                                                    onChange={this.handleChanged} 
                                                    autoComplete="off" 
                                                    onKeyPress={this.IsNumeric.bind(this)}
                                                    maxLength="3"
                                                />
                                            </Col>
                                            <Col sm="1">
                                            </Col>
                                            <Form.Label column sm="1">
                                                TIME LIMIT
                                            </Form.Label>
                                            <Col sm="2">
                                                <Form.Control 
                                                    ref="timeLimit"
                                                    type="text"
                                                    name="timeLimit"
                                                    value={this.state.timeLimit}
                                                    onChange={this.handleChanged} 
                                                    autoComplete="off" 
                                                    onKeyPress={this.IsNumeric.bind(this)}
                                                    maxLength="4"
                                                />
                                            </Col>
                                            <Col sm="1">
                                            </Col>
                                            <Form.Label column sm="1">
                                                PASSING SCORE
                                            </Form.Label>
                                            <Col sm="2">
                                                <Form.Control 
                                                    ref="passingScore"
                                                    type="text"
                                                    name="passingScore"
                                                    value={this.state.passingScore} 
                                                    onChange={this.handleChanged} 
                                                    autoComplete="off" 
                                                    onKeyPress={this.IsNumeric.bind(this)}
                                                    maxLength="3"
                                                />
                                            </Col>
                                        </Form.Group>
                                    </fieldset>
                                    <fieldset className="border p-1 mt-2">
                                        <legend className="w-auto">Question and Answer</legend>
                                        <Form.Group as={Row} controlId="formPlaintextEmail">
                                        <Form.Label column sm="1">
                                            QUESTION
                                        </Form.Label>
                                        <Col sm="2">
                                            <Form.Control 
                                                ref="questionName"
                                                type="text" 
                                                name="questionName"
                                                value={this.state.questionName}
                                                onChange={this.handleChanged} 
                                                autoComplete="off"
                                                maxLength="500"
                                                disabled={this.state.disableAddButton}
                                            />
                                        </Col>
                                        <Form.Label column sm="1">
                                            ANSWER
                                        </Form.Label>
                                        <Col sm="2">
                                            <Form.Control 
                                                ref="answerName"
                                                type="text"
                                                name="answerName"
                                                value={this.state.answerName}
                                                onChange={this.handleChanged} 
                                                autoComplete="off"
                                                maxLength="50"
                                                disabled={this.state.disableAddButton}
                                            />
                                        </Col>
                                    </Form.Group>
                                    <Form.Row>
                                        <Form.Group as={Col} sm={6}>
                                            <div>
                                                <BootstrapTable
                                                    keyField = "id"
                                                    data = { this.state.choiceGrid }
                                                    columns = { defchoiceCol }
                                                    noDataIndication={ () => <div style={{width:'100%'}}>No record found.</div> }
                                                    rowClasses="noser-table-row-class no-checkbox"
                                                    striped
                                                    cellEdit = { cellEditFactory({ 
                                                        mode: 'dbclick', 
                                                        blurToSave: true,
                                                        afterSaveCell: (oldValue, newValue, row, column) => { 
                                                            //this.GridModified(oldValue, newValue, row.rowIndex, column.dataField)
                                                        }
                                                    })}
                                                    selectRow = { selectClientRow }
                                                />
                                            </div>
                                        </Form.Group>
                                        <Form.Group as={Col} sm={6}>
                                            {/* <Card.Header className="mt-2">ADDED QUESTIONS</Card.Header> */}
                                            <div>
                                                <BootstrapTable
                                                    keyField = "id"
                                                    data = { this.state.questionGrid }
                                                    columns = { questionCol }
                                                    selectRow = { selectRow }
                                                    cellEdit = { cellEditFactory({ 
                                                            mode: 'dbclick', 
                                                            blurToSave: true,
                                                            afterSaveCell: (oldValue, newValue, row, column) => { 
                                                                //this.GridModified(oldValue, newValue, row.rowIndex, column.dataField)
                                                            }
                                                        })
                                                    }
                                                    striped
                                                    loading={true}
                                                    expandRow={ expandItems }
                                                    wrapperClasses="table-responsive"
                                                    rowClasses="noser-table-row-class"
                                                    noDataIndication={ () =>  <div style={{width:'100%'}}>No included question.</div> }
                                                />
                                            </div>
                                        </Form.Group>
                                    </Form.Row>
                                    <ButtonToolbar style={{marginTop:'-16px',marginBottom:'4px'}}>
                                        <Button disabled={this.state.disableAddButton} className="noser-button-mr1" variant="success" onClick = { this.handleAddQuestion }>Add</Button>
                                        <Button disabled={this.state.disableRemoveButton} className="ml-auto noser-button" variant="danger" onClick = { this.handleRemoveQuestion }>Remove</Button>
                                    </ButtonToolbar>
                                    </fieldset>
                                    <ButtonToolbar className="mt-2">
                                        <Button className="ml-auto noser-button-mr1" variant="success" onClick = { this.handleSaveClick }>Save</Button>
                                        <Button className="noser-button" href="/posttrainingexamination" variant="danger">Back</Button>
                                    </ButtonToolbar>
                                </Form>
                            </Card.Body>
                        </Card>
                       
                    </Container>
                    <NoserLoading show={this.state.isloading} />
            </div> 
        )
    }

}

export  default PostTrainingExaminationCreate 
