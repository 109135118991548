import {
    React,Component,BootstrapTable,
    paginationFactory,Button, ButtonToolbar, Card, 
    Form, Col, Row, axios, Container, Banner, Typeahead, TimePicker,
    cellEditFactory, Type, moment, NavLink, Alert, AppConfiguration,
    NoserLoading, Noser, sizePerPageRenderer, DatePicker, Redirect, Tabs, Tab
} 
from '../../noser-hris-component';
import { DropdownButton, Dropdown} from 'react-bootstrap';
import CreateClearance from './Modal/CreateClearance';
import EditClearance from './Modal/EditClearance';

class EmployeeClearance extends Component {
    constructor(props) {
        super(props);
        this.ref = React.createRef();
        this.state = {
            userinfo        :   [],
            isloading       :   false,
            isshow          :   false,
            alerttype       :   "",
            message         :   "",
            color           :   "",
            fade            :   true,   
            clientDDL       : [],  
            clientId : "",
            employeeDDL     : [],
            profileId : "",
            employeeId : "",
            disengagementModeDDL : [],
            disengagementModeId : "",
            tblGetDisengagementModeArrLst : [],
            date: "",
            dataGrid:[],
            disableDeleteBtn : true,
            arrList : [],
        }
        this.state.userinfo = JSON.parse(sessionStorage.getItem("userData"))

    }

    componentDidMount(){
        this.GetClient(); 
    }

    GetClient = async() =>{
        this.setState({isloading:true})
        const params = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.userinfo.clientId,
            "UserId":this.state.userinfo.userId,
            "EmployeeId":this.state.userinfo.roleId==="1" || 
                         this.state.userinfo.roleId==="8" ? "" : this.state.userinfo.employeeId
        }
        console.log("params")
        console.log(params)
        await axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Client/GetClientList", params)
        //.post(AppConfiguration.Setting().noserapiendpoint + "Client/GetClientPerAccess", params)
        .then(res => {
            const data = res.data;
            let arr = []
            if(this.state.userinfo.roleId!=="1"){
                let fieldaccess = JSON.parse(sessionStorage.getItem("userControlAccess"))
                if(fieldaccess.filter(x=>x.accessCode==="0001").length>0){
                    let access = fieldaccess.find(x=>x.accessCode==="0001" && x.controlAccessDetails.length>0)
                    //////console.log("access")
                    //////console.log(access)
                    if(access.controlAccessDetails.filter(x=>x.isClient==="1").length>0){
                        let brr = access.controlAccessDetails.filter(x=>x.isClient==="1")
                        arr = data.clients.filter(f => brr.some(item => item.referenceId === f.id))
                    }
                }
                this.setState({clientDDL : arr,isloading   :   false})
            }
            else{
                this.setState({clientDDL : data.clients,isloading   :   false})
            }
        })
        .catch(error=>{
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade        :   true
            })
        })
        this.GetDisengagementMode();
   }

   
    
   handleChangeClient = (e) => {
       if(e.length == 0) {
           this.state.clientId     =   ""
           this.state.clientName   =   ""
           return
       }
       this.state.clientId     =   e[0].id
       this.state.clientName   =   e[0].name
        this.setState({
            isshow:false,
        })
       this.GetEmployees();
   }

   GetEmployees = () => {
        this.setState({isloading:true})
        const getParams = {
            "IpAddress"     :   "0.0.0.0",
            "ClientId"      :   this.state.clientId,
            "UserId"        :   this.state.userinfo.userId,
            "EmployeeName"  :   "",
            "EmployeeNo"    :   "",
            "ClientName"    :   ""
        }
        axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Employee/GetEmployees",  getParams)
        .then(res => {
            const data = res.data
            console.log("GetEmployees")
            console.log(data)
            this.setState({
                employeeDDL        :   data.employees,
                isloading           :   false,
            }) 
        })
        .catch(error=>{
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade        :   true
            })
        }) 
        
    }


    handleChangeEmployee = (e) => {
        
        if(e.length == 0) {
            this.state.employeeId    =   ""
            this.state.profileId            =   ""
            // this.state.referenceNo          =   ""
            return
        }
        this.state.employeeId      =   e[0].id
        this.state.profileId            =   e[0].profileId
        // this.state.referenceNo          =   e[0].referenceNo
        this.setState({
            isshow:false,
        })
        //this.GetApplicationList();
    }

    GetDisengagementMode = async() => {
        this.setState({isloading:true})
        const param = {
            "IpAddress"         :   "0.0.0.0",
            "UserId"            :   this.state.userinfo.userId,
            "ClientId"          :   this.state.userinfo.clientId,
            "DisengagementId"   :   "",
        };
        await axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Maintenance/GetDisengagementMode",  param)
        .then(res => {
            const data = res.data.disengagementMode;
            console.log("DisengagementMode")
            console.log(data)
            this.setState({
                isloading           : false,
                disengagementModeDDL: data,
            });

            for (let i = 0; i < data.length; i++) {
                const obj = {
                    value : data[i]["id"],
                    label : data[i]["name"],
                };
                this.state.tblGetDisengagementModeArrLst.push(obj);
            }
            if(data.status==="0"){
                this.setState(  {
                    isloading       :   false,
                    alerttype       :   "Error!",
                    isshow          :   true,
                    color           :   "danger",
                    message         :   data.message,
                    fade            :   true
                })
            }
        })
        .catch(error=>{
            this.setState(  {
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade            :   true
            })
        })
        this.GetData();
    }
    handleChangedDisengagementMode = (e) => {
        this.setState({isloading:false,alerttype:'',isshow:false,color:'',message:'',fade:true})
        if(e.length == 0) {
            this.state.disengagementModeId=""
            return
        } 
        this.state.disengagementModeId = e[0].id
    }

    handleChangeDate = date => {
        this.setState({date: date,isshow:false})
    };
    handleSaveClick = () => {
        this.setState({isloading:true})

        const param = {
            "IpAddress"             :"0.0.0.0",
            "ClientId"              :this.state.clientId,
            "UserId"                :this.state.userinfo.userId,
            "ProfileId"             :this.state.profileId,
            "EmployeeId"            :this.state.employeeId,
            "DisengagementModeId"   :this.state.disengagementModeId,
            "Date"                  :this.state.date,
        }
        console.log("param")
        console.log(param)
        
        axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Clearance/AddClearance",  param)
        .then(res => {
            const data = res.data;
            this.setState({
                isloading   :   false,
                alerttype   :   res.data.status=="1" ? "Success!" : "!Error",
                isshow      :   true,
                color       :   res.data.status=="1" ? "success" : "danger",
                message     :   data.message,
                fade        :   true
            });
            //this.GetData();
        })
        .catch(error=>{
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade        :   true
            })
        })
    }   

    GetData = async() => {
        this.setState({isloading:true})
        const param = {
            "IpAddress"             :   "0.0.0.0",
            "UserId"                :   this.state.userinfo.userId,
            "ClientId"              :   "",
            "ProfileId"             :   "",
            "DisengagementModeId"   :   "",
            "Date"                  :   "",
        };
        await axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Clearance/GetClearance",  param)
        .then(res => {
            const data = res.data.clearance;
            console.log("GetClearance")
            console.log(data)
            this.setState({
                dataGrid    : data,
                isloading   : false,
            });
            if(data.status==="0"){
                this.setState(  {
                    isloading       :   false,
                    alerttype       :   "Error!",
                    isshow          :   true,
                    color           :   "danger",
                    message         :   data.message,
                    fade            :   true
                })
            }
        })
        .catch(error=>{
            this.setState(  {
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade            :   true
            })
        })
    }

    handleSearchClick = async() => {
        this.setState({isloading:true})
        const param = {
            "IpAddress"             :"0.0.0.0",
            "ClientId"              :this.state.clientId,
            "UserId"                :this.state.userinfo.userId,
            "ProfileId"             :this.state.profileId,
            "EmployeeId"            :this.state.employeeId,
            "DisengagementModeId"   :this.state.disengagementModeId,
            "Date"                  :this.state.date,
        };
        await axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Clearance/GetClearance",  param)
        .then(res => {
            const data = res.data.clearance;
            console.log("GetClearance")
            console.log(data)
            this.setState({
                dataGrid    : data,
                isloading   : false,
            });
            if(data.status==="0"){
                this.setState(  {
                    isloading       :   false,
                    alerttype       :   "Error!",
                    isshow          :   true,
                    color           :   "danger",
                    message         :   data.message,
                    fade            :   true
                })
            }
        })
        .catch(error=>{
            this.setState(  {
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade            :   true
            })
        })
    }

    GridDataModified(oldValue, newValue, id, column) {
        let disable = true
        this.state.dataGrid.map(function(item,i) {
            if (item.id===id){
                item.isModified = newValue!=oldValue ? "1" : "0"
                disable = newValue!=oldValue ? false : true
            }
        })
        this.setState({disableUpdateBtn:disable})
    } 
    handleDeleteClick = async() => {
        this.setState({arrList:[],isloading:true})

        for (let i = 0; i < this.state.dataGrid.length; i++) {
            if (this.state.dataGrid[i]["isDeleted"] == "1") {
                const obj = {
                    "Id"                    :   this.state.dataGrid[i]["id"],
                    "ClientId"              :   this.state.dataGrid[i]["clientId"],
                    "ProfileId"             :   this.state.dataGrid[i]["profileId"],
                    "EmployeeId"            :   this.state.dataGrid[i]["employeeId"],
                    "DisengagementModeId"   :   this.state.dataGrid[i]["disengagementModeId"],
                    "Date"                  :   this.state.dataGrid[i]["date"],
                    "ModifiedBy"            :   this.state.userinfo.userId,
                    "IsDeleted"             :   this.state.dataGrid[i]["isDeleted"].toString()
                };

                this.state.arrList.push(obj);
            }
        }
        const param = {
            "IpAddress"         :   "0.0.0.0",
            "UserId"            :   this.state.userinfo.userId,
            "ClientId"          :   this.state.userinfo.clientId,
            "clearance"   :   this.state.arrList,
        };
        console.log("param")
        console.log(param)
        await axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Clearance/EditClearance",  param)
        .then(res => {
            const data = res.data;
            if(data.status=="1"){
                this.setState({
                    isloading   :   false,
                    alerttype   :   "Success!",
                    isshow      :   true,
                    color       :   "success",
                    message     :   "Clearance successfully deleted.",
                    fade        :   true,
                    disableDeleteBtn : true,
                });
                this.GetData();
            }
            else{
                this.setState({
                    isloading   :   false,
                    alerttype   :   "Error!",
                    isshow      :   true,
                    color       :   "danger",
                    message     :   data.message,
                    fade        :   true
                })
            }
        })
        .catch(error=>{
            this.setState(  {
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade            :   true
            })
        })
    }
    handleModalShow = (e) =>{
        this.setState({openModal:true})
        let obj = {}
        this.child.initialize(obj)
    }
    handleModalClose = (e) =>{
        this.setState({openModal:false})
        this.GetData();
    }
    handleEditModalShow = (e) =>{
        this.setState({openEditModal:true})
        let obj = {}
        this.childEdit.initialize(e)
    }
    handleEditModalClose = (e) =>{
        this.setState({openEditModal:false})
        this.GetData();
    }

    render() {
        const columns = [
            {
                dataField: 'clientName',
                text: 'Client',
                editable    : false,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'left',whiteSpace:'nowrap',width:"25%"}},
                style:{textAlign:'left',whiteSpace:'nowrap',width:"25%"},
                sort:true,
            },
            {
                dataField: 'employeeName',
                text: 'Employee',
                editable    : false,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'left',whiteSpace:'nowrap',width:"25%"}},
                style:{textAlign:'left',whiteSpace:'nowrap',width:"25%"},
                sort:true,
            },
            {
                dataField: 'disengagementMode',
                text: 'Disengagement Mode',
                editable    : false,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'left',whiteSpace:'nowrap',width:"25%"}},
                style:{textAlign:'left',whiteSpace:'nowrap',width:"25%"},
            },
            {
                dataField: 'date',
                text: 'Date',
                editable    : false,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'left',whiteSpace:'nowrap',width:"15%"}},
                style:{textAlign:'left',whiteSpace:'nowrap',width:"15%"},
            },
            {
                dataField: 'action',
                text: 'Action',
                editable    : false,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'left',whiteSpace:'nowrap',width:"10%"}},
                style:{textAlign:'left',whiteSpace:'nowrap',width:"10%"},
                formatter   :   (cell, row, isSelect) => {
                    return( 
                        <Button style={{height:'16px',paddingTop:'0',marginTop:'-3px',fontSize:'11px'}} variant="link"
                        onClick={e => this.handleEditModalShow(row)}
                        >Edit</Button>
                    );
                },
            },
        ]
        const selectRow = {
            mode: 'checkbox',
            clickToSelectAndEditCell: true,
            onSelect: (row, isSelect, rowIndex, e) => {
                let isDisable = true
                this.state.dataGrid.map(function(item,i){
                    if(item.id===row.id)
                    {
                        item.isDeleted = isSelect ? "1" : "0"
                    }
                    if(item.isDeleted=="1")
                        isDisable=false
                })
                this.setState({disableDeleteBtn:isDisable})
            }
        };

        const rowEvents = {
            onClick: (e, row, rowIndex) => {
            }
        };
    return(
            <div>
                <Banner />
                    <Container className="mt-5" fluid>
                        <Card>
                            <Card.Header>Employee Clearance</Card.Header>
                            <Card.Body>
                                <Form>
                                    <Alert isOpen={this.state.isshow} color={this.state.color} fade={this.state.fade} className={this.state.isshow ? 'display-block' : 'display-none'}>
                                        <div className={this.state.color}></div> 
                                        {this.state.message}
                                    </Alert>
                                    <Form.Group as={Row} controlId="formPlaintextEmail">
                                        <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                            CLIENT
                                        </Form.Label>
                                        <Col>
                                            <Typeahead
                                                labelKey='name'
                                                id="basic-example"
                                                onChange={this.handleChangeClient}
                                                options={this.state.clientDDL}
                                            />
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} controlId="formPlaintextEmail">
                                        <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                        EMPLOYEE
                                        </Form.Label>
                                        <Col>
                                            <Typeahead 
                                                labelKey='employeeName'
                                                id="basic-example"
                                                onChange={this.handleChangeEmployee}
                                                options={this.state.employeeDDL} 
                                            />
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} controlId="formPlaintextEmail">
                                        <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                        DISENGAGEMENT MODE
                                        </Form.Label>
                                        <Col>
                                        <Typeahead
                                            labelKey='name'
                                            id="basic-example"
                                            onChange={this.handleChangedDisengagementMode}
                                            options={this.state.disengagementModeDDL}
                                        />
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} controlId="formPlaintextEmail">
                                        <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                        DATE
                                        </Form.Label>
                                        <Col>
                                            <DatePicker
                                                ref='date'
                                                selected={this.state.date}
                                                onChange={this.handleChangeDate}
                                                minDate={this.minDate}
                                                value={this.props.date}
                                                dateFormat={"MM/dd/yyyy"}
                                                className="form-control"
                                            /> 
                                        </Col>
                                    </Form.Group>
                                <ButtonToolbar sm={12}>
                                    <Button variant="success" className="ml-auto noser-button" onClick = { this.handleSearchClick }>
                                        SEARCH
                                    </Button>
                                </ButtonToolbar>
                                <div className="mt-3">
                                    <Card.Header>List Clearance</Card.Header>
                                    <BootstrapTable
                                        keyField = "id"
                                        data = { this.state.dataGrid }
                                        columns = { columns }
                                        pagination={ paginationFactory({sizePerPageRenderer}) }
                                        noDataIndication={ () => <div>No record found.</div> }
                                        rowClasses="noser-table-row-class"
                                        striped
                                        hover
                                        condensed
                                        cellEdit = { cellEditFactory({
                                            mode: 'dbclick',
                                            blurToSave: true,
                                            afterSaveCell: (oldValue, newValue, row, column) => {
                                                this.GridDataModified(oldValue, newValue, row.id, column.dataField)
                                                }
                                            })
                                        }
                                        rowEvents={ rowEvents }
                                        selectRow = { selectRow }
                                    />
                                </div>
                                <div className="mt-1">
                                    <ButtonToolbar>
                                        <Button className="ml-auto noser-button-mr1" variant="danger" onClick={this.handleDeleteClick} disabled={this.state.disableDeleteBtn}>Delete</Button>
                                        <Button className="noser-button" variant="primary" onClick={this.handleModalShow}>Create</Button>
                                    </ButtonToolbar>
                                </div>
                                </Form>
                            </Card.Body>
                        </Card>
                       
                    </Container>
                    <NoserLoading show={this.state.isloading} />
                <CreateClearance 
                    show={this.state.openModal}
                    onHide={this.handleModalClose}
                    onRefModal={ref => (this.child = ref)}
                />
                <EditClearance 
                    show={this.state.openEditModal}
                    onHide={this.handleEditModalClose}
                    onRefModal={ref => (this.childEdit = ref)}
                />
            </div> 
        )
    }

}

export  default EmployeeClearance 
