import {
    React,Component,BootstrapTable,
    paginationFactory,Button, ButtonToolbar, Card, 
    Form, Col, Row, axios, Container, Banner, Typeahead, TimePicker,
    cellEditFactory, Type, moment, NavLink, Alert, AppConfiguration,
    NoserLoading, Noser, sizePerPageRenderer
} 
from '../../noser-hris-component';
import CreateCostCenter from './Modal/CreateCostCenter';


class CostCenter extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userinfo    :   [],
            isloading   :   false,
            isshow      :   false,
            alerttype   :   "",
            message     :   "",
            color       :   "",
            fade        :   true,
            openModal   :   false,
            disableBtn  :   true,

            areaId      :   "",
            code        :   '',
            name        :   '',
            
            areaDDL     :   [],
            costCenterDDL:  [],
            costCenterList: [],
            costCenterGRID: [],
        }
    }
    

    componentDidMount(){
        this.state.userinfo = JSON.parse(sessionStorage.getItem("userData"))
        this.GetAreas();
    }
    handleModalShow = (e) =>{
        this.setState({openModal:true})
        let obj = {}
        this.child.initialize(obj)
    }
    handleModalClose = (e) =>{
        this.setState({openModal:false})
        this.GetAreas()
    }
    handleChangedArea = (e) => {
        this.setState({alerttype:'',isshow:false,color:'',message:'',fade:true})
        if(e.length == 0) {
            this.state.areaId=""
            this.state.costCenterDDL = this.state.costCenterList
            return
        } 
        this.setState({areaId:e[0].id,costCenterDDL:this.state.costCenterList.filter(x=>x.areaId===e[0].id)})
    }
    handleChangedCode = (e) => {
        this.setState({alerttype:'',isshow:false,color:'',message:'',fade:true})
        if(e.length == 0) {
            this.state.code=""
            return
        } 
        this.setState({code:e[0].code})
    }
    handleChangedName = (e) => {
        this.setState({alerttype:'',isshow:false,color:'',message:'',fade:true})
        if(e.length == 0) {
            this.state.name=""
            return
        } 
        this.setState({name:e[0].name})
    }
    GetAreas = async() => {
        this.setState({isloading:true,alerttype:'',isshow:false,color:'',message:'',fade:true})
        const params = {
            "IpAddress"     :   "0.0.0.0",
            "ClientId"      :   this.state.userinfo.clientId,
            "UserId"        :   this.state.userinfo.userId,
            "Code"          :   "",
            "Name"          :   ""
        };

        await axios.post(AppConfiguration.Setting().noserapiendpoint + "Maintenance/GetAreas",  params)
        .then(res => {
            const data = res.data;
            this.setState({areaDDL:data.areas});
         })
         .catch(error=>{
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade        :   true
            })
        })
        this.GetCostCenter()
    }
    GetCostCenter = async()=>{
        const params = {
            "IpAddress"     :   "0.0.0.0",
            "ClientId"      :   this.state.userinfo.clientId,
            "UserId"        :   this.state.userinfo.userId,
            "AreaId"        :   this.state.areaId,
            "Code"          :   "",
            "Name"          :   ""
        };

        axios
            .post(AppConfiguration.Setting().noserapiendpoint + "Maintenance/GetCostCenters",  params)
            .then(res => {
                const data = res.data;
                this.setState({costCenterList:data.costCenters,costCenterDDL:data.costCenters, costCenterGRID:data.costCenters ,isloading:false})
                
                if(data.status==="0"){
                    this.setState({
                        isloading   :   false,
                        alerttype   :   "Error!",
                        isshow      :   true,
                        color       :   "danger",
                        message     :   data.message,
                        fade        :   true
                    });
                }
            })
            .catch(error=>{
                this.setState({
                    isloading   :   false,
                    alerttype   :   "Error!",
                    isshow      :   true,
                    color       :   "danger",
                    message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                    fade        :   true
                })
            })
    }
    handleSearchClick = event => {
        this.setState({isloading:true})

        const params = {
            "IpAddress"     :   "0.0.0.0",
            "ClientId"      :   this.state.userinfo.clientId,
            "UserId"        :   this.state.userinfo.userId,
            "AreaId"        :   this.state.areaId,
            "Code"          :   this.state.code,
            "Name"          :   this.state.name
        };
        axios
            .post(AppConfiguration.Setting().noserapiendpoint + "Maintenance/GetCostCenters",  params)
            .then(res => {
                const data = res.data;
                this.setState({costCenterGRID:data.costCenters,isloading:false})
                if(data.status==="0"){
                    this.setState({
                        isloading   :   false,
                        alerttype   :   "Error!",
                        isshow      :   true,
                        color       :   "danger",
                        message     :   data.message,
                        fade        :   true
                    });
                }
            })
            .catch(error=>{
                this.setState({
                    isloading   :   false,
                    alerttype   :   "Error!",
                    isshow      :   true,
                    color       :   "danger",
                    message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                    fade        :   true
                })
            })

    }

    handleSaveClick = async() => {
        this.setState({newCostCenterList: [],isLoading:true})
        const params = {
            "IpAddress"     :   "0.0.0.0",
            "ClientId"      :   this.state.userinfo.clientId,
            "UserId"        :   this.state.userinfo.userId,
            "CostCenters"   :   this.state.costCenterGRID.filter(x=>x.isModified==="1")
        };
        axios
            .post(AppConfiguration.Setting().noserapiendpoint + "Maintenance/EditCostCenter", params)
            .then(res => {
                const data = res.data;
                this.setState({isLoading:false})
                if(data.status=="1"){
                    this.setState({
                        isloading   :   false,
                        alerttype   :   "Success!",
                        isshow      :   true,
                        color       :   "success",
                        message     :   data.message,
                        fade        :   true
                    });
                }
                else{
                    this.setState({
                        isloading   :   false,
                        alerttype   :   "Error!",
                        isshow      :   true,
                        color       :   "danger",
                        message     :   data.message,
                        fade        :true
                    })
                }
             })
             .catch(error=>{
                this.setState(  {
                    isloading   :   false,
                    alerttype   :   "Error!",
                    isshow      :   true,
                    color       :   "danger",
                    message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                    fade        :   true
                })
            })
    }
    GridDataModified(oldValue, newValue, id, column) {
        this.setState({disableBtn:true})
        this.state.costCenterGRID.forEach(item => {
            if (item.id === id){
                item.isModified = newValue!=oldValue ? "1" : "0"
                this.setState({disableBtn:false})
            }
        })
    }

    render() {
        const columnCostCenter = [
            {
                dataField   : 'areaName',
                text        : 'Area',
                //editable:true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'left',width:'10%' }},
                style:{textAlign:'left',width:'10%'}
            },
            {
                dataField   : 'code',
                text        : 'Code',
                editable:true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'left',width:'10%' }},
                style:{textAlign:'left',width:'10%'}
            },
            {
                dataField   : 'name',
                text        : 'Name',
                editable:true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'left',width:'80%' }},
                style:{textAlign:'left',width:'80%'}
            }
        ]

        const selectRow = {
            mode: 'checkbox',
            clickToSelectAndEditCell: true,
            onSelect: (row, isSelect, rowIndex, e) => {
                this.setState({disableBtn:true})
                this.state.costCenterGRID.forEach(item=>{
                    item.rateTypeId="0"
                    if(item.id===row.id)
                    {
                        item.isDeleted = isSelect ? "1" : "0"
                        item.isModified = isSelect ? "1" : "0"
                    }
                    if(item.isDeleted==="1"){
                        this.setState({disableBtn:false})
                    }
                })
            }
        };

        const rowEvents = {
            onClick: (e, row, rowIndex) => {
            }
        };


    return(
            <div>
                <Banner />
                    <Container className="themed-container" fluid={true}>
                        <Card className="mt-5">
                            <Card.Header>Cost Center</Card.Header>
                            <Card.Body>
                                <Form>
                                    <Alert isOpen={this.state.isshow} color={this.state.color} fade={this.state.fade} className={this.state.isshow ? 'display-block' : 'display-none'}>
                                        <div className={this.state.color}></div> 
                                        {this.state.message}
                                    </Alert>
                                        <Form.Group as={Row} controlId="formHorizontalEmail">
                                            <Col sm={12}>
                                                <Typeahead
                                                    labelKey='name'
                                                    id="basic-example"
                                                    onChange={this.handleChangedArea}
                                                    options={this.state.areaDDL}
                                                    placeholder="Select Area"
                                                />
                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row} controlId="formHorizontalEmail">
                                            <Col sm={12}>
                                                <Typeahead
                                                    labelKey='code'
                                                    id="basic-example"
                                                    onChange={this.handleChangedCode}
                                                    options={this.state.costCenterDDL}
                                                    placeholder="Select Cost Center Code"
                                                />
                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row} controlId="formHorizontalEmail">
                                            <Col sm={12}>
                                                <Typeahead
                                                    labelKey='name'
                                                    id="basic-example"
                                                    onChange={this.handleChangedName}
                                                    options={this.state.costCenterDDL}
                                                    placeholder="Select Cost Center Name"
                                                />
                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row} controlId="formHorizontalEmail">
                                            <Col sm={12}>
                                                <ButtonToolbar>
                                                    <Button variant="primary" className="ml-auto noser-button" onClick={ this.handleSearchClick }>Search</Button>
                                                </ButtonToolbar>
                                            </Col>
                                        </Form.Group>
                                        <div className="mt-1">
                                            <Card.Header>List Of COST CENTERS</Card.Header>
                                            <BootstrapTable
                                                rowClasses="noser-table-row-class"
                                                striped
                                                hover
                                                condensed
                                                pagination={ paginationFactory({sizePerPageRenderer}) }
                                                keyField = "id"
                                                data = { this.state.costCenterGRID }
                                                columns = { columnCostCenter}
                                                selectRow = { selectRow }
                                                cellEdit = { cellEditFactory({
                                                    mode: 'dbclick',
                                                    blurToSave: true,
                                                    afterSaveCell: (oldValue, newValue, row, column) => {
                                                        this.GridDataModified(oldValue, newValue, row.id, column.dataField)
                                                        }
                                                    })
                                                }
                                                rowEvents={ rowEvents }
                                                noDataIndication={ () => <div>No record found.</div> }
                                            />
                                            <ButtonToolbar sm={12}>
                                                <Button disabled={this.state.disableBtn} variant="success" className="ml-auto noser-button-mr1" onClick={this.handleSaveClick}>Save</Button>
                                                <Button variant="primary" className="noser-button" onClick={this.handleModalShow}>Create</Button>
                                            </ButtonToolbar>
                                        </div>
                                </Form>
                            </Card.Body>
                        </Card>
                    </Container>
                <NoserLoading show={this.state.isloading} />
                <CreateCostCenter 
                    show={this.state.openModal}
                    onHide={this.handleModalClose}
                    onRefModal={ref => (this.child = ref)}
                />
            </div> 
        )
    }

}

export  default CostCenter 