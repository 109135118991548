import {
    React,Component,BootstrapTable,
    paginationFactory,Button, ButtonToolbar, Card, 
    Form, Col, Row, axios, Container, Banner, Typeahead, TimePicker,
    cellEditFactory, Type, moment, NavLink, Alert, AppConfiguration,
    NoserLoading, Noser, sizePerPageRenderer, Modal, DatePicker, NumberFormat
} 
from '../../noser-hris-component';

class MergeEmployee extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userinfo        :   [],
            isloading       :   false,
            isshow          :   false,
            alerttype       :   "",
            message         :   "",
            color           :   "",
            fade            :   true,
            disable         :   false,    
            
            clientName      :   "",
            employeeName    :   "",
            employeeNo      :   "",
            dateofBirth     :   "",
            sssNumber       :   "",
            tinNumber       :   "",
            phicNumber      :   "",
            hdmfNumber      :   "",
            salaryRate      :   "",
            dateHired       :   "",
            dateStart       :   "",
            dateEnd         :   "",
            mobileNumber    :   "",

            employeeId      :   "",
            profileId       :   "",
            toEmployeeId    :   "",
            toProfileId     :   "",
            isHistory       :   true,
            isAddress       :   false,
            isBackground    :   false,
            isEducation     :   false,
        }
    }

    componentDidMount(){
        this.props.onRefModal(this)
        
    }
    componentWillUnmount() {
        this.props.onRefModal(undefined)
    }
    initialize=(e)=>{
        console.log(e)
        this.setState({
            userinfo        :   JSON.parse(sessionStorage.getItem("userData")),
            isloading       :   false,
            isshow          :   false,
            alerttype       :   "",
            message         :   "",
            color           :   "",
            fade            :   true, 
            disable         :   false,     
            
            clientName      :   e.clientName,
            employeeName    :   e.employeeName,
            employeeNo      :   e.employeeNo,
            dateofBirth     :   e.dateOfBirth,
            sssNumber       :   e.sssNumber,
            tinNumber       :   e.tinNumber,
            phicNumber      :   e.phicNumber,
            hdmfNumber      :   e.hdmfNumber,
            salaryRate      :   e.salaryRate,
            dateHired       :   e.dateHired,
            dateStart       :   e.contractDateStart,
            dateEnd         :   e.contractDateEnd,
            mobileNumber    :   e.mobileNumber,

            employeeId      :   e.id,
            profileId       :   e.profileId,
            toEmployeeId    :   "",
            toProfileId     :   "",
            isHistory       :   true,
            isAddress       :   false,
            isBackground    :   false,
            isEducation     :   false,
        })
        
    }
    
    handleSubmitClick = async() => {
        this.setState({isloading:true,disable:true})

        if(this.state.employeeId==="" || this.state.profileId===""){
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "No selected Employee ID / Profile ID.",
                fade        :   true
            })
            return
        }
        
        if(this.state.isHistory===true){
            if(this.state.toEmployeeId==="" || this.state.toProfileId===""){
                this.setState({
                    isloading   :   false,
                    alerttype   :   "Error!",
                    isshow      :   true,
                    color       :   "danger",
                    message     :   "Please enter TO ProfileId / TO Employee Id.",
                    fade        :   true
                })
                return
            }
            
        }
        else{
            if(this.state.isAddress===true || this.state.isBackground===true || this.state.isEducation===true ){
                if(this.state.toEmployeeId==="" || this.state.toProfileId===""){
                    this.setState({
                        isloading   :   false,
                        alerttype   :   "Error!",
                        isshow      :   true,
                        color       :   "danger",
                        message     :   "Please enter TO ProfileId / TO Employee Id.",
                        fade        :   true
                    })
                    return
                }
            }
        }
        const params = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.userinfo.clientId,
            "UserId":this.state.userinfo.userId,
            "IsHistory":this.state.isHistory ? "1" : "0",
            "ProfileId":this.state.profileId,
            "EmployeeId":this.state.employeeId,
            "ToEmployeeId":this.state.toEmployeeId,
            "ToProfileId":this.state.toProfileId,
            "IsAddress":this.state.isAddress  ? "1" : "0",
            "IsBackground":this.state.isBackground  ? "1" : "0",
            "IsEducation":this.state.isEducation  ? "1" : "0"
        }
        
        await axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Employee/EmployeeMerged", params)
        .then(res => {
            const data = res.data;
            this.setState({
                isloading   :   false,
                alerttype   :   res.data.status=="1" ? "Success!" : "!Error",
                isshow      :   true,
                color       :   res.data.status=="1" ? "success" : "danger",
                message     :   data.message,
                fade        :   true
            });
        })
        .catch(error=>{
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade        :   true,
                disable     :   false,
            })
        })
    } 
    handleChangedHistory = (e) =>{
        this.setState({isHistory:e.target.checked})
        if(e.target.name==="history")
        this.setState({isHistory:true})
        else
        this.setState({isHistory:false})
    }
    handleChangeCheckbox(e) {
        this.setState({[e.target.name]: e.target.checked})
    }
    handleChanged = (e) =>{
        this.setState({[e.target.name]:e.target.value})
    }
    IsNumeric(evt)
    {
        var theEvent = evt || window.event;
        var key = theEvent.keyCode || theEvent.which;
        key = String.fromCharCode( key );
        var regex = /^\d+(.\d+)?$/;
        if( !regex.test(key) ) {
        theEvent.returnValue = false;
        if(theEvent.preventDefault) theEvent.preventDefault();
        }
        
    }
    render() { 
        return(
                <>
                <Modal
                    {...this.props}
                    return
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    backdrop="static"
                >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        HISTORY / DELETE EMPLOYEE
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <Container>       
                    <Form>
                        <Alert isOpen={this.state.isshow} color={this.state.color} fade={this.state.fade} className={this.state.isshow ? 'display-block' : 'display-none'}>
                            <div className={this.state.color}></div> 
                            {this.state.message}
                        </Alert>
                        <Form.Group as={Row}>
                        <Col sm="12">
                            <Form.Check 
                                inline
                                type="radio" 
                                label="HISTORY" 
                                name="history"
                                value={this.state.isHistory}
                                checked={this.state.isHistory}
                                onChange={this.handleChangedHistory}
                            />
                            <Form.Check 
                                inline
                                type="radio" 
                                label="DELETE" 
                                name="delete"
                                value={!this.state.isHistory}
                                checked={!this.state.isHistory}
                                onChange={this.handleChangedHistory}
                                
                            />
                        </Col>
                        </Form.Group>
                        <Form.Group as={Row}>
                            <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                CLIENT
                            </Form.Label>
                            <Col sm="10">
                                <Form.Control 
                                    id="clientName"
                                    name="clientName"
                                    type="text" 
                                    value={this.state.clientName}
                                    disabled
                                />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row}>
                            <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                EMPLOYEE NAME
                            </Form.Label>
                            <Col sm="10">
                                <Form.Control 
                                    id="employeeName"
                                    name="employeeName"
                                    type="text" 
                                    value={this.state.employeeName}
                                    disabled
                                />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row}>
                            <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                EMPLOYEE NO
                            </Form.Label>
                            <Col sm="4">
                                <Form.Control 
                                    id="employeeNo"
                                    name="employeeNo"
                                    type="text" 
                                    value={this.state.employeeNo}
                                    disabled
                                />
                            </Col>
                            <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                DATE OF BIRTH
                            </Form.Label>
                            <Col sm="4">
                                <Form.Control 
                                    id="dateofBirth"
                                    name="dateofBirth"
                                    type="text" 
                                    value={this.state.dateofBirth}
                                    disabled
                                />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row}>
                            <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                SSS Number
                            </Form.Label>
                            <Col sm="4">
                                <Form.Control 
                                    id="sssNumber"
                                    name="sssNumber"
                                    type="text" 
                                    value={this.state.sssNumber}
                                    disabled
                                />
                            </Col>
                            <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                TIN NUMBER
                            </Form.Label>
                            <Col sm="4">
                                <Form.Control 
                                    id="tinNumber"
                                    name="tinNumber"
                                    type="text" 
                                    value={this.state.tinNumber}
                                    disabled
                                />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row}>
                            <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                PHIC NUMBER
                            </Form.Label>
                            <Col sm="4">
                                <Form.Control 
                                    id="phicNumber"
                                    name="phicNumber"
                                    type="text" 
                                    value={this.state.phicNumber}
                                    disabled
                                />
                            </Col>
                            <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                HDMF NUMBER 
                            </Form.Label>
                            <Col sm="4">
                                <Form.Control 
                                    id="hdmfNumber"
                                    name="hdmfNumber"
                                    type="text" 
                                    value={this.state.hdmfNumber}
                                    disabled
                                />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row}>
                            <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                DATE HIRED
                            </Form.Label>
                            <Col sm="4">
                                <Form.Control 
                                    id="dateHired"
                                    name="dateHired"
                                    type="text" 
                                    value={this.state.dateHired}
                                    disabled
                                />
                            </Col>
                            <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                MOBILE NUMBER
                            </Form.Label>
                            <Col sm="4">
                                <Form.Control 
                                    id="mobileNumber"
                                    name="mobileNumber"
                                    type="text" 
                                    value={this.state.mobileNumber}
                                    disabled
                                />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row}>
                            <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                DATE START
                            </Form.Label>
                            <Col sm="4">
                                <Form.Control 
                                    id="dateStart"
                                    name="dateStart"
                                    type="text" 
                                    value={this.state.dateStart}
                                    disabled
                                />
                            </Col>
                            <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                DATE END
                            </Form.Label>
                            <Col sm="4">
                                <Form.Control 
                                    id="dateEnd"
                                    name="dateEnd"
                                    type="text" 
                                    value={this.state.dateEnd}
                                    disabled
                                />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row}>
                            <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                TO PROFILE ID
                            </Form.Label>
                            <Col sm="4">
                                <Form.Control 
                                    id="toProfileId"
                                    name="toProfileId"
                                    type="text" 
                                    onChange={this.handleChanged}
                                    value={this.state.toProfileId}
                                    autoComplete="off"
                                    onKeyPress={this.IsNumeric.bind(this)}
                                    maxLength="13"
                                />
                            </Col>
                            <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                TO EMPLOYEE ID
                            </Form.Label>
                            <Col sm="4">
                                <Form.Control 
                                    id="toEmployeeId"
                                    name="toEmployeeId"
                                    type="text" 
                                    onChange={this.handleChanged}
                                    value={this.state.toEmployeeId}
                                    autoComplete="off"
                                    onKeyPress={this.IsNumeric.bind(this)}
                                    maxLength="13"
                                />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row}>
                            <Col sm="2"></Col>
                            <Col sm="2">
                                <Form.Check 
                                type="checkbox" 
                                label="ADDRESS"
                                onChange={e => this.handleChangeCheckbox(e)}
                                checked={this.state.isAddress}
                                name="isAddress"
                                />
                            </Col>
                            <Col sm="3">
                                <Form.Check 
                                type="checkbox" 
                                label="FAMILY BACKGROUND"
                                onChange={e => this.handleChangeCheckbox(e)}
                                checked={this.state.isBackground}
                                name="isBackground"
                                />
                            </Col>
                            <Col sm="2">
                                <Form.Check 
                                type="checkbox" 
                                label="EDUCATION"
                                onChange={e => this.handleChangeCheckbox(e)}
                                checked={this.state.isEducation}
                                name="isEducation"
                                />
                            </Col>
                        </Form.Group>
                    </Form>
                </Container>
                </Modal.Body>
                <Modal.Footer>
                    <ButtonToolbar >
                        <Button disabled={this.state.disable} variant="success" className="ml-auto  noser-button-mr1" onClick = { this.handleSubmitClick }>SUBMIT</Button>
                        <Button className="noser-button" variant="danger" onClick={this.props.onHide}>Close</Button>
                    </ButtonToolbar>
                </Modal.Footer>
                <NoserLoading show={this.state.isloading} />
            </Modal>
            </>
        );
    }
}
export  default MergeEmployee
