import {
    React,Component,BootstrapTable,
    paginationFactory,Button, ButtonToolbar, Card, 
    Form, Col, Row, axios, Container, Banner, Typeahead, TimePicker,
    cellEditFactory, Type, moment, NavLink, Alert, AppConfiguration,
    NoserLoading, Noser, sizePerPageRenderer, DatePicker, Redirect
} 
from '../../noser-hris-component';
import { DropdownButton, Dropdown} from 'react-bootstrap';

// import TrainingListModal from './TrainingListModal';
import ReSchedTraining  from './TrainingModal/ReSchedTraining'
import TrainingRequestCreate  from './TrainingModal/TrainingRequestCreate'

class TrainingList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userinfo        :   [],
            isloading       :   false,
            isshow          :   false,
            alerttype       :   "",
            message         :   "",
            color           :   "",
            fade            :   true,
            disableRemove   :   true, 

            clientDDL               : [],
            clientId                : "",
            employeeDDL             : [],
            referenceNo             : "",
            statusId                : "",
            trainingTypetinNumber   : "",
            locationName            : "",
            training                : "",
            trainingType            : "",
            locationStatus          : "",
            applicationFormNo       : "",
            suspended               : [],
            TrainingGrid            : [],
            trainingScheduleTableList : [],

            tblTrainingSuspendedArrLst      :   [],
            addTrainingSuspendedLists       :   [
                { 
                    "id" : "0", 
                    "name" : "NO" 
                },
                { 
                    "id" : "1", 
                    "name" : "YES" 
                },
            ],
            currentDate: "",
            
            openTrainingListModal    :   false,
            openReSchedTraining     :   false,
        }
        this.state.userinfo = JSON.parse(sessionStorage.getItem("userData"))
        this.state.data = JSON.parse(sessionStorage.getItem("traininglist_" + this.state.userinfo.userId + this.state.userinfo.employeeId))
        //console.log("this.state.data2222")
        //console.log(this.state.data)

        this.state.employeeName = this.state.data.employeeName
        this.state.employeeNo = this.state.data.profileId
        this.state.clientName = this.state.data.clientName
        this.state.positionName = this.state.data.positionName
        this.state.locationName = this.state.data.locationName
        this.state.applicationId = this.state.data.applicationId
        this.state.clientId = this.state.data.clientId
        
    }

    componentDidMount(){
        const sleep = (milliseconds) => {return new Promise(resolve => setTimeout(resolve, milliseconds))}
        this.state.userinfo = JSON.parse(sessionStorage.getItem("userData"))
        this.GetEmployeeTrainingList();
        this.GetTrainingSuspendedLists();
        this.setState({currentDate     :   moment(new Date).format('MM/DD/YYYY')})
    }


    GetEmployeeTrainingList = async()=> {
        console.log("GetEmployeeTrainingList")
        const params = {
            "IpAddress"     :   "0.0.0.0",
            "ClientId"      :   this.state.userinfo.clientId,
            "UserId"        :   this.state.userinfo.userId,
            "ProfileId"     :   this.state.employeeNo ,
            "TrainingId"   :   ""            
        };
        console.log("params 1")
        console.log(params)
        await axios
            .post(AppConfiguration.Setting().noserapiendpoint + "Training/GetEmployeeTrainings",  params) 
            .then(res => {
            const data = res.data;
            console.log(" GetEmployeeTrainingList  data 1")
            console.log(data.employeeTrainings.filter( x => x.isPresent === "1"))
            this.setState({TrainingGrid:data.employeeTrainings/* .filter( x => x.isPresent === "1") */,isloading:false});
            if(data.status=="0"){
                this.setState({
                    isloading   :   false,
                    alerttype   :   "Error!",
                    isshow      :   true,
                    color       :   "danger",
                    message     :   data.message,
                    fade        :   true
                });
            }
        })
        .catch(error=>{
            this.setState(  {
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade            :   true
            })
        })
    }
    
    handleClickDeploy = () => {
        this.setState({isloading:true})
        let selectedTraining= this.state.TrainingGrid
        //console.log(selectedTraining)

        let TrainingId_ = ""
        selectedTraining.map(function(item,i){
            TrainingId_ = item.id
        })
        // console.log("TrainingId_")
        // console.log(TrainingId_)
        const params = {
            "IpAddress" :   "0.0.0.0",
            "ClientId"  :   this.state.userinfo.clientId,
            "UserId"    :   this.state.userinfo.userId,
            "Id"        :   TrainingId_
            
        }

        // console.log("Save Params")
        // console.log(params)
    
        axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Training/AddTrainingDeployment",  params)
        .then(res => {
            const data = res.data;
            // console.log("data")
            // console.log(data)
            if(data.status=="1"){
                this.setState({
                    isloading   :   false,
                    alerttype   :   "Success!",
                    isshow      :   true,
                    color       :   "success",
                    message     :   data.message,
                    fade        :   true
                });
            
            }
            else {
                this.setState({
                    isloading   :   false,
                    alerttype   :   "Error!",
                    isshow      :   true,
                    color       :   "danger",
                    message     :   data.message,
                    fade        :   true
                });
            }
        })
        .catch(error=>{
            this.setState({
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade            :   true
            })
        })
    }
    
    GetTrainingSuspendedLists() {

        for (let i = 0; i < this.state.addTrainingSuspendedLists.length; i++) {
            const obj = {
                value : this.state.addTrainingSuspendedLists[i]["id"],
                label : this.state.addTrainingSuspendedLists[i]["name"],
            };
            this.state.tblTrainingSuspendedArrLst.push(obj);
        }
        this.testingData()

    }

    testingData(){
        console.log(" testingData 123123123")
        let w = this.state.tblTrainingSuspendedArrLst
        console.log(w)
    }

    handleRemoveClick = () => {
        this.setState({isloading:true})
        let TrainingId_ = ""
        let selectedTraining= this.state.TrainingGrid.filter( x => x.isModified === "1" )
        //console.log(selectedTraining)

        selectedTraining.map(function(item,i){
            TrainingId_ = item.id
        })
        //console.log("TrainingId_")
        //console.log(TrainingId_)
        const params = {
            "IpAddress"     :   "0.0.0.0",
            "ClientId"      :   this.state.userinfo.clientId, //--> userinfo.clientid
            "UserId"        :   this.state.userinfo.userId,
            "TrainingId"    :   TrainingId_, //--> GetEmployeeTrainings. Id
        };

        //console.log("submit params")
        //console.log(params)
        
        axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Training/SuspendEmployeeTraining",  params)
        .then(res => {
            const data = res.data;
            //console.log("remove Training")
            //console.log(data)
            if(data.status=="1"){
                this.setState({
                    isloading   :   false,
                    alerttype   :   "Success!",
                    isshow      :   true,
                    color       :   "success",
                    message     :   data.message,
                    fade        :   true
                });
                this.GetEmployeeTrainingList()
            }
            else {
                this.setState({
                    isloading   :   false,
                    alerttype   :   "Error!",
                    isshow      :   true,
                    color       :   "danger",
                    message     :   data.message,
                    fade        :   true
                });
            }
                
        })
        .catch(error=>{
            this.setState({
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade            :   true
            })
        }) 
    }
    handleViewClick = (e, row) => {
        this.handleModalShowDocument(e)
        // this.state.trainingSelectedId = row.id
        // this.child1.initialize(e)
    }

    handleModalShow = (obj) =>{
        this.setState({openModal:true})

        this.child.initialize(obj)
       //console.log(obj)
    } 

    handleModalShowDocument = (e, row) =>{
        // this.setState({openTrainingListModal:true})
        this.setState({openReSchedTraining:true})
        // this.state.trainingSelectedId = row.id
        this.child1.initialize(e)
    }

    
    handleModalCloseDocument = (e) =>{
        // console.log("dsds")
        // console.log(e)
        let trainingGrid = this.state.TrainingGrid
        trainingGrid.forEach(itm=>{
            if(itm.id===this.state.trainingSelectedId){
                itm.scheduleId = e[0].id
                
            }
        })
        //console.log(this.state.trainingGrid)
        this.state.trainingGrid = trainingGrid
        this.setState({openReSchedTraining:false})
        this.GetTrainingSuspendedLists();
        // this.GetEmployeeTrainingList();
        
    }

    handleShowTrainingCreate = () => {
        this.setState({isshowTrainingCreate:true})
        // this.state.trainingSelectedId = row.id
        let DataProfile = {
                "employeeName" : this.state.employeeName ,
                "employeeNo" : this.state.employeeNo,
                "clientName" : this.state.clientName,
                "positionName" : this.state.positionName,
                "locationName" : this.state.locationName,
                "applicationId" : this.state.applicationId,
                "clientId" : this.state.clientId,
        }
        this.childTraining.initializeCreate(DataProfile)
    }

    handleCloseTrainingCreate = () => {

        this.setState({isshowTrainingCreate:false, isloading : true})
        
        this.GetEmployeeTrainingList()
    }

    refreshPage() {
        // const params = {
        //     "IpAddress"     :   "0.0.0.0",
        //     "ClientId"      :   this.state.userinfo.clientId,
        //     "UserId"        :   this.state.userinfo.userId,
        //     "ProfileId"     :   this.state.data.profileId,
        //     "isSuspended"   :   "0"            
        // };
        // //console.log(params)
        // axios
        //     .post(AppConfiguration.Setting().noserapiendpoint + "Training/GetEmployeeTrainings",  params)
        //     .then(res => {
        //     const data = res.data;
        //     this.setState({TrainingGrid:data.employeeTrainings,isloading:false});
        //     console.log("new data222222")
        //     console.log(data.ProfileId)
        //     if(data.status=="0"){
        //         this.setState({
        //             isloading   :   false,
        //             alerttype   :   "Error!",
        //             isshow      :   true,
        //             color       :   "danger",
        //             message     :   data.message,
        //             fade        :   true
        //         });
        //     }
        // })
        // .catch(error=>{
        //     this.setState(  {
        //         isloading       :   false,
        //         alerttype       :   "Error!",
        //         isshow          :   true,
        //         color           :   "danger",
        //         message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
        //         fade            :   true
        //     })
        // })
    }
    

    render() {
        
        const applicationColumn = [
            {
                dataField: 'training',
                text: 'TRAINING',
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'left',width:'22%' }},
                style:{textAlign:'left',width:'22%'},
            },
            {
                dataField: 'required',
                text: 'TYPE',
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'8%' }},
                style:{textAlign:'center',width:'8%'},
            },
            {
                dataField: 'isSuspended',
                text: 'SUSPENDED',
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'8%' }},
                style:{textAlign:'center',width:'8%'},
                formatter: (cell, row) => {
                    if(row.isSuspended!='' && row.isSuspended!=null){
                        if(this.state.tblTrainingSuspendedArrLst.filter(x => x.value == cell).length==0)
                        return ""
                        else
                            return this.state.tblTrainingSuspendedArrLst.find(x => x.value == cell).label;
                    }
                },
                // editor: {
                //     type: Type.SELECT,
                //     options: this.state.tblTrainingSuspendedArrLst
                // },
            },
            {
                dataField: 'fromDate',
                text: 'DATE',
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'8%' }},
                style:{textAlign:'center',width:'8%'},
            },
            {
                dataField: 'validity',
                text: 'VALID UNTIL',
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'8%' }},
                style:{textAlign:'center',width:'8%'},
            },
            {
                dataField: 'batchNo',
                text: 'BATCH NUMBER',
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'11%' }},
                style:{textAlign:'center',width:'11%'},
            },
            {
                dataField: 'recommendation',
                text: 'RECOMMENDATION',
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'10%' }},
                style:{textAlign:'center',width:'10%'},
            },
            {
                dataField: 'remarks',
                text: 'REMARKS',
                headerStyle: () => {
                    return { width: "15%" };
                },
            },
            {
                dataField: 'action',
                text: 'ACTION',
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'10%' }},
                style:{textAlign:'center',width:'10%'},
                formatter   :   (cell, row, isSelect) => {
                    if(row.isSuspended === "1" && row.scheduleId==="0"){
                        return(
                            <Button 
                                onClick={e => this.handleViewClick(row)}
                                style={{
                                    height:'18px',
                                    paddingTop:'0',
                                    marginTop:'-4px',
                                    fontSize:'11px'}} 
                                variant="link"
                                >
                                SELECT SCHEDULE
                            </Button>
                        )
                
                    }
                    if(row.isSuspended === "1" && row.scheduleId===""){
                        return(
                            <Button 
                                onClick={e => this.handleViewClick(row)}
                                style={{
                                    height:'18px',
                                    paddingTop:'0',
                                    marginTop:'-4px',
                                    fontSize:'11px'}} 
                                variant="link"
                                >
                                SELECT SCHEDULE
                            </Button>
                        )
                
                    }
                    /* if(row.isSuspended === "0" && row.scheduleId==="0"){
                        return(
                            <Button 
                                onClick={e => this.handleViewClick(row)}
                                style={{
                                    height:'18px',
                                    paddingTop:'0',
                                    marginTop:'-4px',
                                    fontSize:'11px'}} 
                                variant="link"
                                >
                                SELECT SCHEDULE
                            </Button>
                        )
                
                    }*/
                    if(row.isSuspended === "0" /* && row.scheduleId==="" */){
                        return(
                            <Button 
                                onClick={e => this.handleViewClick(row)}
                                style={{
                                    height:'18px',
                                    paddingTop:'0',
                                    marginTop:'-4px',
                                    fontSize:'11px'}} 
                                variant="link"
                                >
                                RE-SCHEDULE
                            </Button>
                        )
                
                    } 
                    if(row.recommendation==="RE TRAINING"){
                        return(
                            <Button 
                                onClick={e => this.handleViewClick(row)}
                                style={{
                                    height:'18px',
                                    paddingTop:'0',
                                    marginTop:'-4px',
                                    fontSize:'11px'}} 
                                variant="link"
                                >
                                SELECT SCHEDULE
                            </Button>
                        )
                
                    }
                // },
                    // return (
                        
                    //     <Button onClick={e => this.handleViewClick(row)}
                    //         style={{height:'18px',paddingTop:'0',marginTop:'-4px',fontSize:'11px'}} 
                    //         variant="link"
                    // >RESCHEDULE</Button>
                    // );
                },
            }  
        ] 
        
    
        const trainingSelectRow = {
            mode: 'checkbox',
            clickToSelectAndEditCell: true,
            onSelect: (row, isSelect, rowIndex, e) => {
                let disable = true
                let trainingId = this.state.TrainingGrid
                let _TrainingId = []
                this.state.TrainingGrid.map(function(item,i){
                    if(item.id===row.id)
                        {
                        item.isSuspended = isSelect ? "1" : "0"
                        item.isModified = isSelect ? "1" : "0"
                        disable = isSelect ? false : true
                        //console.log("isSelect_1"+isSelect +" "+item.id) 
                        
                        // for (let i = 0; i < trainingId.length; i++) {
                        //     // const obj = {
                        //     //     value : trainingId[i]["trainingId"],
                        //     // };
                        //     // _TrainingId.push(obj);
                        //     const selectedTraining = trainingId[i]["trainingId"]
                        //     //console.log(selectedTraining)
                        // }
                        // //console.log(_TrainingId)
                        // const selectedTraining = item.id
                        // //console.log(selectedTraining)
                    }

                    if(item.isDeleted=="1")
                        disable=false
                })
                this.setState({disableRemove:disable})

            }
        };

        const trainingRowEvents = {
            onClick: (e, row, rowIndex) => {

            }
        };

    return(
            <div>
                <Banner />
                    <Container className="mt-5" fluid>
                        <Card>
                            <Card.Header>TRAINING &#62;&#62; TRAINING LIST</Card.Header>
                            <Card.Body>
                                <Form>
                                    <Alert isOpen={this.state.isshow} color={this.state.color} fade={this.state.fade} className={this.state.isshow ? 'display-block' : 'display-none'}>
                                        <div className={this.state.color}></div> 
                                        {this.state.message}
                                    </Alert>
                                    <Form.Group as={Row} controlId="formPlaintextEmail">
                                        <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                            EMPLOYEE :
                                        </Form.Label>
                                        <Col sm="5">
                                            <Form.Control 
                                                type="text" 
                                                value={this.state.employeeName}
                                                disabled
                                            />
                                        </Col>
                                        <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                            EMPLOYEE NO :
                                        </Form.Label>
                                        <Col sm="5">
                                            <Form.Control 
                                                type="text" 
                                                value={this.state.employeeNo}
                                                disabled
                                            />
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row}>
                                        <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                            CLIENT :
                                        </Form.Label>
                                        <Col sm="5">
                                            <Form.Control 
                                                type="text" 
                                                value={this.state.clientName} 
                                                disabled
                                            />
                                        </Col>
                                        <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                            LOCATION :
                                        </Form.Label>
                                        <Col sm="5">
                                            <Form.Control 
                                                type="text" 
                                                value={this.state.locationName}
                                                disabled
                                            />
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} controlId="formPlaintextEmail">
                                    <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                            POSITION :
                                        </Form.Label>
                                        <Col sm="5">
                                            <Form.Control 
                                                type="text" 
                                                value={this.state.positionName}
                                                disabled
                                            />
                                        </Col>
                                        <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                            DATE :
                                        </Form.Label>
                                        <Col sm="5">
                                            <Form.Control 
                                                type="text" 
                                                value={this.state.currentDate}
                                                disabled
                                            />
                                        </Col>
                                    </Form.Group>
                                    <ButtonToolbar className="mt-1">
                                        <Button variant="info" className="ml-auto" onClick={ this.handleShowTrainingCreate }>
                                            Create
                                        </Button>
                                    </ButtonToolbar>
                                    <div className="mt-5">  
                                        <div>
                                            <Card.Header></Card.Header>
                                            <BootstrapTable
                                                /* caption={Noser.TableHeader({title:"RECORD"})} */
                                                keyField = "id"
                                                data = { this.state.TrainingGrid }
                                                columns = { applicationColumn }
                                                pagination={ paginationFactory({sizePerPageRenderer}) }
                                                noDataIndication={ () => <div style={{width:'100%'}}>No record found.</div> }
                                                rowClasses="noser-table-row-class "
                                                striped
                                                hover
                                                condensed
                                                // cellEdit = { cellEditFactory({
                                                //     mode: 'dbclick',
                                                //     blurToSave: true,
                                                //     afterSaveCell: (oldValue, newValue, row, column) => {
                                                //         this.GridDataModified(oldValue, newValue, row.id, column.dataField)
                                                //         }
                                                //     })
                                                // }
                                                selectRow = { trainingSelectRow }
                                                rowEvents={ trainingRowEvents }
                        
                                            />
                                        </div>
                                    </div>
                                    <ButtonToolbar sm={12}>
                                        <NavLink className="ml-auto" /* to="/applicationformcreate" */ to={"/employeetrainingmonitoring"}>
                                            <Button className="noser-button-mr1 noser-button" onClick={this.handleClickDeploy} variant="success">Deploy</Button>
                                        </NavLink>&nbsp;&nbsp;
                                        <Button  onClick={this.handleRemoveClick} disabled={this.state.disableRemove} variant="danger" className="noser-button"  >Recall </Button>&nbsp;&nbsp;
                                        <Button  className="noser-button"  variant="danger" href="/employeetrainingmonitoring">Close </Button>
                                    </ButtonToolbar>
                                </Form>
                            </Card.Body>
                        </Card>
                    
                    </Container>
                    <NoserLoading show={this.state.isloading} />
                {/* <TrainingListModal 
                   show={this.state.openModal}
                   onHide={this.handleModalClose}
                   onRefModal={ref => (this.child = ref)}
                   />   */}
                <ReSchedTraining 
                    show={this.state.openReSchedTraining}
                    onHide={this.handleModalCloseDocument}
                    onRefModal={ref => (this.child1 = ref)}
                />
                <TrainingRequestCreate 
                    show={this.state.isshowTrainingCreate}
                    onHide={this.handleCloseTrainingCreate}
                    onRefModal={ref => (this.childTraining = ref)}
                />
            </div> 
        )
    }

}
export  default TrainingList