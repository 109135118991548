import {
    React,Component,BootstrapTable,
    paginationFactory,Button, ButtonToolbar, Card, 
    Form, Col, Row, axios, Typeahead, TimePicker,
    cellEditFactory ,moment, NavLink, Alert, AppConfiguration,
    NoserLoading, Noser, Banner, Container, DatePicker,  Tabs, Tab,
    sizePerPageRenderer,Type
} 
from '../../noser-hris-component';
import { GetRequest, PostRequest } from '../../noser-dataaccess'

class ViewDetails extends Component {
    constructor(props) {
        super(props)
        // this.minDate = new moment(props.minDate)
        this.state = {
            userinfo        :   [],
            isloading       :   false,
            isshow          :   false,
            alerttype       :   "",
            message         :   "",
            color           :   "",
            fade            :   true,

            clientDDL       : [],
            clientId        : "",
            clientName      : "",
            employeeDDL     : [],
            employeeId      : '',
            employeeName    : '',
            employeeNo      : '',
            profileId       : '',
            position        : '',
            location        : '',
            payCardNumber   : '',
            dateHired       : '',
            applicationDate : '',
            payCardDDL : [],
            payCardType       : '',
            payCardTypeId : '',
            newpayCardNumber : '',
            oldpayCardNumber : '',
            natureDDL : [],
            natureId:"",
            natureName:"",
            cardValue : '',
            reasonDDL : [],
            reasonId:"",
            reasonName : '',
            description : "",
            documentDG : [],
            statusDG : [],

        }
        this.state.userinfo         = JSON.parse(sessionStorage.getItem("userData"))
        this.state.details          = JSON.parse(sessionStorage.getItem("_paycardviewdetails"))
        this.state.applicationId    = this.state.details.id
        this.state.clientName       = this.state.details.clientName
        this.state.clientId         = this.state.details.clientId
        this.state.employeeId       = this.state.details.employeeId
        this.state.employeeNo       = this.state.details.employeeNo
        this.state.employeeName     = this.state.details.employeeName
        this.state.profileId        = this.state.details.profileId
        this.state.location         = this.state.details.branchName
        this.state.position         = this.state.details.positionName
        this.state.dateHired        = this.state.details.dateHired
        this.state.applicationDate  = new Date(this.state.details.applicationDate)
        this.state.payCardType      = this.state.details.payCardType
        this.state.payCardTypeId    = this.state.details.payCardTypeId
        this.state.oldpayCardNumber = this.state.details.oldOayCardNumber
        this.state.newpayCardNumber = this.state.details.payCardNumber
        this.state.natureName       = this.state.details.natureName
        this.state.natureId         = this.state.details.natureId
        this.state.cardValue        = this.state.details.cardValue
        this.state.reasonId         = this.state.details.reasonId
        this.state.reasonName       = this.state.details.reasonName
        this.state.description      = this.state.details.description
        this.state.statusId         = this.state.details.statusId
        this.state.isReApplication  = this.state.details.isReApplication
        this.state.reApplication    = this.state.details.reApplication
        this.state.documentDG       = this.state.details.applicationdocuments
        this.state.statusDG         = this.state.details.applicationstatus
    }

    componentDidMount(){
        this.GetClient();
        this.GetPayCard();
        this.GetNature();
        this.GetReason();
        for(let x = 0; x < this.state.statusDG.length; x++){
            if(this.state.statusId === this.state.statusDG[x]["statusId"]){
                console.log("requestDate")
                console.log(this.state.statusDG[x]["requestDate"])
                this.state.requestDate = this.state.statusDG[x]["requestDate"]
                this.state.statusRemarks = this.state.statusDG[x]["remarks"]
            }
        }
        

        if(this.state.userinfo.roleId === "8"){
            this.setState({
                saveStatusId        : "28",
                cancelStatusId      : "30",
                transmittedStatusId : "31",
            })
        }
        
        if(this.state.userinfo.roleId === "26"){
            this.setState({
                saveStatusId        : "1",
                cancelStatusId      : "3",
                transmittedStatusId : "4",
            })
        }
        
        if(this.state.userinfo.roleId === "4"){
            this.setState({
                saveStatusId        : "1",
                cancelStatusId      : "3",
                transmittedStatusId : "4",
            })
        }
        
        if(this.state.userinfo.roleId === "5"){
            this.setState({
                saveStatusId        : "1",
                cancelStatusId      : "3",
                transmittedStatusId : "4",
            })
        }

        if(this.state.userinfo.roleId === "32"){
            this.setState({
                disabledButton : true
            })
        }
    }

    GetClient = async() =>{
        this.setState({isloading:true})
        const params = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.userinfo.clientId,
            "UserId":this.state.userinfo.userId,
            "EmployeeId":this.state.userinfo.roleId==="1" || 
                         this.state.userinfo.roleId==="8" ? "" : this.state.userinfo.employeeId
        }
        console.log("params")
        console.log(params)
        await axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Client/GetClientList", params)
        //.post(AppConfiguration.Setting().noserapiendpoint + "Client/GetClientPerAccess", params)
        .then(res => {
            const data = res.data;
            let arr = []
            if(this.state.userinfo.roleId!=="1"){
                let fieldaccess = JSON.parse(sessionStorage.getItem("userControlAccess"))
                if(fieldaccess.filter(x=>x.accessCode==="0001").length>0){
                    let access = fieldaccess.find(x=>x.accessCode==="0001" && x.controlAccessDetails.length>0)
                    console.log("access")
                    console.log(access)
                    if(access.controlAccessDetails.filter(x=>x.isClient==="1").length>0){
                        let brr = access.controlAccessDetails.filter(x=>x.isClient==="1")
                        arr = data.clients.filter(f => brr.some(item => item.referenceId === f.id))
                    }
                }
                console.log("arr")
                console.log(arr)
                this.setState({clientDDL : arr,isloading:false})
            }
            else{
                this.setState({clientDDL : data.clients,isloading:false})
            }
        })
        .catch(error=>{
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade        :   true
            })
        })
   }

   onChangeClient = (e) => {
       if(e.length == 0) {
           this.setState({
            isshow      :false,
            clientId    :"",
            clientName  :""
        })
           return
       }
       this.state.clientId  =e[0].id
       this.state.clientName=e[0].name
       this.GetEmployees();
       //this.GetClientLocation();
       this.setState({isshow:false})
   }

   GetEmployees =() =>{
       this.setState({isloading:true})
       const getParams = {
           "IpAddress"     : "0.0.0.0",
           "ClientId"      : this.state.clientId,
           "UserId"        : this.state.userinfo.userId,
           "EmployeeName"  : "",
           "EmployeeNo"    : "",
           "ClientName"    : ""
       }
       axios
       .post( AppConfiguration.Setting().noserapiendpoint + "Employee/GetEmployees",  getParams)
       .then(res => {
           const data = res.data
           //console.log("GetEmployees")
           //console.log(data)
               this.setState({
                    employeeDDL :   data.employees,
                    isloading   :   false,
               }) 
       })
       .catch(error=>{
           this.setState({
               isloading   :   false,
               alerttype   :   "Error!",
               isshow      :   true,
               color       :   "danger",
               message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
               fade        :   true
           })
       })
       
   }

   onChangeEmployees = (e) => {
       console.log("e")
       console.log(e)
       if(e.length==0)
       {
           this.setState({
                employeeId      : '',
                employeeName    : '',
                employeeNo      : '',
                profileId       : '',
                position        : '',
                location        : '',
                payCardNumber   : '',
                dateHired       :    '',
           })
           return
       }
       this.state.employeeId    =   e[0].id
       this.state.employeeName  =   e[0].employeeName
       this.state.employeeNo    =   e[0].employeeNo
       this.state.profileId     =   e[0].profileId
       this.state.position      =   e[0].position
       this.state.status        =   e[0].employeeStatusType
       this.state.location      = e[0].locationName
       this.state.payCardNumber = e[0].payCardNumber
       if(e[0].dateHired === ""){
           this.state.dateHired = ""
       } else {
           this.state.dateHired = new Date(e[0].dateHired)
       }
       this.setState({
           isshow      :   false
       })

   }

   handleChangeApplicationDate = date => {
       this.setState({applicationDate: date})
       this.setState({
           isshow      :   false
       })
   };

   GetPayCard = () =>{
       this.setState({isloading:true})
       const getParams = {
           "IpAddress"     :   "0.0.0.0",
           "ClientId"      :   this.state.userinfo.clientId,
           "UserId"        :   this.state.userinfo.userId,
       }

       axios
       .post( AppConfiguration.Setting().noserapiendpoint + "Maintenance/GetPayCardTypes",  getParams)
       .then(res => {
           const data = res.data;
           ////console.log("Get Reason");
           ////console.log(data);
           this.setState({ payCardDDL : data.payCardTypes });
        })
   }

   onChangePayCardList = (e) => {
       if(e.length == 0) {
           this.setState({isshow:false,payCardTypeId:"",payCardType:""})
           return
       }
       this.state.payCardTypeId=e[0].id
       this.state.payCardType=e[0].name
       this.setState({
           isshow      :   false
       })
       
   }

   onChangeNewPayCardNumber = (e) => {
       this.setState({ newpayCardNumber: e.target.value} );
       this.setState({
           isshow      :   false
       })
   }

   onChangeOldPayCardNumber = (e) => {
       this.setState({ oldpayCardNumber: e.target.value} );
       this.setState({
           isshow      :   false
       })
   }

   GetNature = () =>{
     
       const getParams = {
           "IpAddress"     :   "0.0.0.0",
           "ClientId"      :   this.state.userinfo.clientId,
           "UserId"        :   this.state.userinfo.userId,
           "Code"          :   "0034"
       };

       axios
       .post( AppConfiguration.Setting().noserapiendpoint + "Maintenance/GetDataReferences",  getParams)
       .then(res => {
           const data = res.data;
           //console.log("Get Nature");
           //console.log(data);
           this.setState({ natureDDL : data.dataReferences });
        })
   }

   onChangeNature = (e) => {
       if(e.length == 0) {
           this.setState({
               isshow:false,
               natureId:"",
               natureName:"",
               oldpayCardNumber: "",
               documentDG : [],
               documentArray : []
           })
           return
       }
       this.state.natureId=e[0].id
       this.state.natureName=e[0].name
       if(e[0].id === "2" || e[0].id === "3" || e[0].id === "4" || e[0].id === "5" ){
           this.setState({
               oldpayCardNumber : this.state.payCardNumber
           })
       }
       this.setState({
           isshow      :   false
       })
       
       if(e[0].id === "1"){
           this.setState({
               documentDG : [
                   { "documentName" : "VALID ID", "id" : "2" },
                   { "documentName" : "ATD", "id" : "6"},
                   { "documentName" : "BDO APPLICATION FORM", "id" : "7"}
               ]
           })
           
       }
       
   }

   onChangeValue = (e) => {
       this.setState({ cardValue: e.target.value });
       this.setState({
           isshow      :   false
       })
   };

   GetReason = () =>{
       this.setState({isloading:true})
       const getParams = {
           "IpAddress"     :   "0.0.0.0",
           "ClientId"      :   this.state.userinfo.clientId,
           "UserId"        :   this.state.userinfo.userId,
           "Code"          :   "0033"
       };

       axios
       .post( AppConfiguration.Setting().noserapiendpoint + "Maintenance/GetDataReferences",  getParams)
       .then(res => {
           const data = res.data;
           //console.log("Get Reason");
           //console.log(data);
           this.setState({ reasonDDL : data.dataReferences });
        })
   }

   onChangeReasonList = (e) => {
       if(e.length == 0) {
           this.setState({
               isshow:false,
               reasonId:"",
               reasonName:"",
               documentDG : [],
               documentArray : []
           })
           return
       }
       this.state.reasonId=e[0].id
       this.state.reasonName=e[0].name
       this.setState({
           isshow      :   false
       })

       if(e[0].id === "7"){
           if(this.state.natureId === "3"){
               this.setState({
                   documentDG : [
                       { "documentName" : "VALID ID", "id" : "2" },
                       { "documentName" : "LOI FORM", "id" : "4"},
                       { "documentName" : "AFFIDAVIT LOSS", "id" : "5" },
                       { "documentName" : "ATD", "id" : "6"},
                       { "documentName" : "BDO APPLICATION FORM", "id" : "7"}
                   ]
               })
           }
       }

       if(e[0].id === "7"){
           if(this.state.natureId === "5"){
               this.setState({
                   documentDG : [
                       { "documentName" : "VALID ID", "id" : "2" },
                       { "documentName" : "LOI FORM", "id" : "4"},
                       { "documentName" : "AFFIDAVIT LOSS", "id" : "5" },
                   ]
               })
           }
       }
    }

    onChangeOtherReason  = (e) => {
        this.setState({ otherReason: e.target.value });
        this.setState({
            isshow      :   false
        })
    };
    
    IsDecimal(val)
    {
        var regex = /^\d*\.?(?:\d{1,2})?$/;
        return !regex.test(val)
    }
    IsNumeric(evt){
        var theEvent = evt || window.event;
        var key = theEvent.keyCode || theEvent.which;
        key = String.fromCharCode( key );
        var regex = /^\d+(.\d+)?$/;
        if( !regex.test(key) ) {
        theEvent.returnValue = false;
        if(theEvent.preventDefault) theEvent.preventDefault();
        }
    }

    handleSaveClick = () => {
        this.setState({isloading:true})
        // if(this.state.natureId === "1"){
        //     if(this.state.newpayCardNumber === ""){
        //         this.setState({
        //             isloading   :   false,
        //             alerttype   :   "Error!",
        //             isshow      :   true,
        //             color       :   "danger",
        //             message     :   "Please input new paycard!",
        //             fade        :   true
        //         })
        //         return
        //     }
        // }
        let arrLst = []
        let arrLstStat = []
        let headerId = this.state.applicationId
        // this.state.statusDG.map(function(itm,i) {
        //     if(itm.isSelected === "1"){
        //         arrLstStat.push({
        //             "HeaderId"            : headerId,
        //             "DocumentId"            : itm.id,
        //             "DocumentId"            : itm.id,
        //             "DocumentId"            : itm.id,
        //             "DocumentId"            : itm.id,
        //         })
        //     }
        // })
        this.state.documentDG.map(function(itm,i) {
            if(itm.isSelected === "1"){
                arrLst.push({
                    "DocumentId"            : itm.id,
                    "HeaderId"            : headerId,
                })
            }
        })
        console.log("arrLst")
        console.log(arrLst)
        if(arrLst.length === 0) {
            this.setState({
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :    "Please select attachment!",
                fade            :   true
            })
            return  
        }
        const params = {
            "IpAddress"     : "0,0,0,0",
            "ClientId"      : this.state.userinfo.clientId,
            "UserId"        : this.state.userinfo.userId,
            "applications"  : [{
                "Id"                : headerId,
                "ReferenceNo"       : this.state.employeeNo,
                "EmployeeId"        : this.state.employeeId,
                "ProfileId"         : this.state.profileId,
                "ApplicationDate"   : moment(this.state.applicationDate).format('MM/DD/YYYY'),
                "DateStart"         : moment(this.state.applicationDate).format('MM/DD/YYYY'),
                "PayCardTypeId"     : this.state.payCardTypeId,
                "PayCardNumber"     : this.state.newpayCardNumber,
                "OldOayCardNumber"  : this.state.oldpayCardNumber,
                "NatureId"          : this.state.natureId,
                "ReasonId"          : this.state.reasonId,
                "Description"       : this.state.description,
                "CardValue"         : this.state.cardValue,
                "StatusId"          : this.state.statusId,
                "Remarks"           : "",
                "isReApplication"   : this.state.isReApplication,
                "ReApplication"     : this.state.reApplication,
                "IsDeleted"         : "0",
                "ModifiedBy"        : this.state.userinfo.userId,
                "documents" : arrLst
            }]
        }
        console.log("params")
        console.log(params)
        axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Paycard/EditApplication",  params)
        .then(res => {
            const data = res.data
            this.setState({
                isloading   :   false,
                alerttype   :   "Success!",
                isshow      :   true,
                color       :   "success",
                message     :   data.message,
                fade        :   true,
            });
            //this.UpdateStatus();
        })
        .catch(error=>{
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade        :   true
            })
        }) 
    }

    UpdateStatus = () => {

        const params = {
            "IpAddress"     :   "0.0.0.0",
            "ClientId"      :   this.state.userinfo.clientId,
            "UserId"        :   this.state.userinfo.userId,
            "HeaderId"      :   this.state.applicationId,
            "StatusId"      :   this.state.statusId,
            "RequestDate"   :   moment(this.state.requestDate).format('MM/DD/YYYY'),
            "CreatedBy"     :   this.state.userinfo.userId,
            "Remarks"       :   this.state.statusRemarks,
         };

        console.log("Submit Params")
        console.log(params)

        axios
        .post( AppConfiguration.Setting().noserapiendpoint + "Paycard/AddApplicationStatus",  params)
        .then(res => {
            const data = res.data;
            if(data.status=="1"){
            this.setState({
                    isloading   :   false,
                    alerttype   :   "Success!",
                    isshow      :   true,
                    color       :   "success",
                    message     :   data.message,
                    fade        :   true
                });
            
            }
            else {
                this.setState({
                    isloading   :   false,
                    alerttype   :   "Error!",
                    isshow      :   true,
                    color       :   "danger",
                    message     :   data.message,
                    fade        :   true
                });
            }
        })
    }
    
    handleCancel = () => {
        this.setState({isloading:true})

        const areaParams = {
            "IpAddress"     :   "0.0.0.0",
            "ClientId"      :   this.state.userinfo.clientId,
            "UserId"        :   this.state.userinfo.userId,
            "HeaderId"      :   this.state.applicationId,
            "StatusId"      :   this.state.cancelStatusId,
            "RequestDate"   :   moment(this.state.date).format('MM/DD/YYYY'),
            "CreatedBy"     :   this.state.createdBy,
            "Remarks"       :   this.state.remarks,
         };

        console.log("Submit Params")
        console.log(areaParams)

        axios
        .post( AppConfiguration.Setting().noserapiendpoint + "Paycard/AddApplicationStatus",  areaParams)
        .then(res => {
            const data = res.data;
            if(data.status=="1"){
                this.setState({
                    isloading   :   false,
                    alerttype   :   "Success!",
                    isshow      :   true,
                    color       :   "success",
                    message     :   data.message,
                    fade        :   true,
                });
            } else {
                this.setState({
                    isloading   :   false,
                    alerttype   :   "Error!",
                    isshow      :   true,
                    color       :   "danger",
                    message     :   data.message,
                    fade        :   true
                });
            }
        })
        //this.UpdateManning();
    }
    
    handleTransmit = () => {
        this.setState({isloading:true})

        const areaParams = {
            "IpAddress"     :   "0.0.0.0",
            "ClientId"      :   this.state.userinfo.clientId,
            "UserId"        :   this.state.userinfo.userId,
            "HeaderId"      :   this.state.applicationId,
            "StatusId"      :   this.state.transmittedStatusId,
            "RequestDate"   :   moment(this.state.date).format('MM/DD/YYYY'),
            "CreatedBy"     :   this.state.createdBy,
            "Remarks"       :   this.state.remarks,
         };

        console.log("Submit Params")
        console.log(areaParams)

        axios
        .post( AppConfiguration.Setting().noserapiendpoint + "Paycard/AddApplicationStatus",  areaParams)
        .then(res => {
            const data = res.data;
            if(data.status=="1"){
                this.setState({
                    isloading   :   false,
                    alerttype   :   "Success!",
                    isshow      :   true,
                    color       :   "success",
                    message     :   data.message,
                    fade        :   true,
                });
            } else {
                this.setState({
                    isloading   :   false,
                    alerttype   :   "Error!",
                    isshow      :   true,
                    color       :   "danger",
                    message     :   data.message,
                    fade        :   true
                });
            }
        })
        //this.UpdateManning();
    }

    render() {
       
        const documentColumn = [
            {
                dataField: 'documentName',
                text: 'DOCUMENTS NAME',
                editable:false,
                headerStyle: {
                    width: '100%'
                },
                style:{width:'100%'}
               
            },
        ]

        const selectRow = {
            mode: 'checkbox',
            onSelect: (row, isSelect, rowIndex, e) => {
                this.state.documentDG.map(function(item,i){
                    if(item.id===row.id)
                    {
                        item.isSelected = isSelect ? "1" : "0"
                    }
                })
            },
            onSelectAll: (isSelected, row) => {
                if (isSelected){
                    this.state.documentDG.forEach(item =>{
                        item.isSelected = isSelected ? "1" : "0"
                    })
                    return this.state.documentDG.map(row => row.id);
                }
            }
        };
       
        const statusColumn = [
            {
                dataField: 'requestDate',
                text: 'REQUEST DATE',
                editable:false,
                headerStyle: {
                    width: '20%'
                },
                style:{width:'20%'}
               
            },
            {
                dataField: 'statusName',
                text: 'STATUS NAME',
                editable:false,
                headerStyle: {
                    width: '40%'
                },
                style:{width:'40%'}
               
            },
            {
                dataField: 'remarks',
                text: 'REMARKS',
                editable:false,
                headerStyle: {
                    width: '40%'
                },
                style:{width:'40%'}
               
            },
        ] 
        
        const selectRowStatus = {
            mode: 'checkbox',
            clickToSelectAndEditCell: true,
            onSelect: (row, isSelect, rowIndex, e) => {
                this.state.statusDG.map(function(item,i){
                   
                    if(item.id===row.id)
                        {
                        item.isDeleted = isSelect ? "1" : "0"
                        item.isModified = isSelect ? "1" : "0"
                    }
                })

            }
        };
       
        return(
            <div>
            <Banner />
            <Container className="themed-container" fluid={true}>
                <Card className="mt-5">
                    <Card.Header >PAYCARD APPLICATION (DETAILS)</Card.Header>
                    <Card.Body>
                        <Alert className="mt-5" isOpen={this.state.isshow} color={this.state.color} fade={this.state.fade} className={this.state.isshow ? 'display-block' : 'display-none'}>
                            <div className={this.state.color}></div> 
                            {this.state.message}
                        </Alert>
                        <Card className="mt-5">
                            <Card.Header>EMPLOYEE DETAILS</Card.Header>
                            <Card.Body>
                                <Form.Row className="mt-2">
                                    <Form.Group as={Col} controlId="formGridAddress1">
                                        <Form.Label style={{fontWeight : "bold"}}>CLIENT</Form.Label>
                                        <Typeahead
                                            labelKey='name'
                                            id="basic-example"
                                            onChange={this.onChangeClient}
                                            options={this.state.clientDDL}
                                            defaultSelected={[this.state.clientName]}
                                        />
                                    </Form.Group>
                                    <Form.Group as={Col} controlId="formGridAddress1">
                                        <Form.Label style={{fontWeight : "bold"}}>Employee</Form.Label>
                                        <Typeahead
                                            labelKey='employeeName'
                                            id="basic-example"
                                            onChange={this.onChangeEmployees}
                                            options={this.state.employeeDDL}
                                            defaultSelected={[this.state.employeeName]}
                                        />
                                    </Form.Group>
                                </Form.Row>
                                <Form.Row className="mt-2">
                                    <Form.Group as={Col} controlId="formGridAddress1">
                                        <Form.Label style={{fontWeight : "bold"}}>Emp No.</Form.Label>
                                        <Form.Control 
                                            type="text" 
                                            name="employeeNo"
                                            value={this.state.employeeNo}
                                            readOnly
                                        />
                                    </Form.Group>
                                    <Form.Group as={Col} controlId="formGridAddress1">
                                        <Form.Label style={{fontWeight : "bold"}}>LOCATION / BRANCH</Form.Label>
                                        <Form.Control 
                                            type="text"
                                            name="location"
                                            value={this.state.location}
                                            // onChange={this.onChangeLocation}
                                            disabled
                                        />
                                    </Form.Group>
                                </Form.Row>
                                <Form.Row className="mt-2">
                                    <Form.Group as={Col} controlId="formGridAddress1">
                                        <Form.Label style={{fontWeight : "bold"}}>Position.</Form.Label>
                                        <Form.Control 
                                            type="text" 
                                            name="position"
                                            value={this.state.position}
                                            readOnly
                                        />
                                    </Form.Group>
                                    <Form.Group as={Col} controlId="formGridAddress1">
                                        <Form.Label style={{fontWeight : "bold"}}>DATE START</Form.Label>
                                        <Form.Control 
                                            type="text"
                                            name="dateHired"
                                            value={this.state.dateHired}
                                            // onChange={this.onChangeLocation}
                                            disabled
                                        />
                                    </Form.Group>
                                </Form.Row>
                            </Card.Body>
                        </Card>
                        
                        <Card className="mt-5">
                            <Card.Header>PAYCARD DETAILS</Card.Header>
                            <Card.Body>
                                <Form.Row className="mt-2">
                                    <Form.Group as={Col} controlId="formGridAddress1">
                                        <Form.Label style={{fontWeight : "bold", marginLeft : 15}}>Application Date</Form.Label>
                                        <Form.Group as={Col} controlId="formGridCity">
                                        <DatePicker
                                            ref='applicationDate'
                                            selected={this.state.applicationDate}
                                            onChange={this.handleChangeApplicationDate}
                                            minDate={this.minDate}
                                            value={this.props.applicationDate}
                                            dateFormat={"MM/dd/yyyy"}
                                            className="form-control"
                                            wrapperClassName="datepicker"
                                            // disabled

                                        />
                                        </Form.Group>
                                    </Form.Group>
                                </Form.Row>
                                <Form.Row className="mt-2">
                                    <Form.Group as={Col} controlId="formGridAddress1">
                                        <Form.Label style={{fontWeight : "bold"}}>PayCard Type</Form.Label>
                                        <Typeahead
                                            labelKey='name'
                                            id="basic-example"
                                            onChange={this.onChangePayCardList}
                                            options={this.state.payCardDDL}
                                            placeholder="Select PayCard"
                                            defaultSelected={[this.state.payCardType]}
                                        />
                                    </Form.Group>
                                    <Form.Group as={Col} controlId="formGridAddress1">
                                        <Form.Label style={{fontWeight : "bold"}}>PayCard No. Old</Form.Label>
                                        <Form.Control 
                                            type="text"
                                            placeholder="Enter Old No." 
                                            ref="oldpayCardNumber"
                                            autoComplete="off"
                                            maxLength="16"
                                            name="oldpayCardNumber"
                                            value={this.state.oldpayCardNumber}
                                            onChange={this.onChangeOldPayCardNumber}
                                            onKeyPress={this.IsNumeric.bind(this)}
                                        />
                                    </Form.Group>
                                    <Form.Group as={Col} controlId="formGridAddress1">
                                        <Form.Label style={{fontWeight : "bold"}}>PayCard No. New</Form.Label>
                                        <Form.Control 
                                            type="text"
                                            placeholder="Enter New No." 
                                            ref="newpayCardNumber"
                                            autoComplete="off"
                                            maxLength="16"
                                            name="newpayCardNumber"
                                            value={this.state.newpayCardNumber}
                                            onChange={this.onChangeNewPayCardNumber}
                                            onKeyPress={this.IsNumeric.bind(this)}

                                        />
                                    </Form.Group>
                                </Form.Row>
                                <Form.Row className="mt-2">
                                    <Form.Group as={Col} controlId="formGridAddress1">
                                        <Form.Label style={{fontWeight : "bold"}}>Nature of Application</Form.Label>
                                        <Typeahead
                                            labelKey='name'
                                            id="basic-example"
                                            onChange={this.onChangeNature}
                                            options={this.state.natureDDL}
                                            placeholder="Select Nature"
                                            defaultSelected={[this.state.natureName]}
                                        />
                                    </Form.Group>
                                    <Form.Group as={Col} controlId="formGridAddress1">
                                        <Form.Label style={{fontWeight : "bold"}}>Value Transfer</Form.Label>
                                        <Form.Control 
                                            type="text"
                                            name="value"
                                            value={this.state.cardValue}
                                            onChange={this.onChangeValue} 
                                            autoComplete="off"
                                            placeholder="Enter Value" 
                                            onKeyPress={this.IsNumeric.bind(this)}
                                        />
                                    </Form.Group>
                                </Form.Row>
                                <Form.Row className="mt-2">
                                    <Form.Group as={Col} controlId="formGridAddress1">
                                        <Form.Label style={{fontWeight : "bold"}}>Reason of Application</Form.Label>
                                        <Typeahead
                                            labelKey='name'
                                            id="basic-example"
                                            onChange={this.onChangeReasonList}
                                            options={this.state.reasonDDL}
                                            placeholder="Select Reason"
                                            defaultSelected={[this.state.reasonName]}
                                        />
                                    </Form.Group>
                                    <Form.Group as={Col} controlId="formGridAddress1">
                                        <Form.Label style={{fontWeight : "bold"}}>Others Specify</Form.Label>
                                        <Form.Control 
                                            type="text"
                                            name="description"
                                            value={this.state.description}
                                            onChange={this.onChangeOtherReason} 
                                            autoComplete="off"
                                            placeholder="Enter Reason" 
                                        />
                                    </Form.Group>
                                </Form.Row>
                            </Card.Body>
                        </Card>
                        <Card className="mt-5">
                            <Card.Header>ATTACHMENTS</Card.Header>
                            <Card.Body>
                                <div className="mt-3">  
                                    <BootstrapTable
                                        keyField = "id"
                                        data = { this.state.documentDG }
                                        columns = { documentColumn }
                                        //pagination={ paginationFactory({sizePerPageRenderer}) }
                                        noDataIndication={ () => <div style={{width:'100%'}}>No record found.</div> }
                                        rowClasses="noser-table-row-class"
                                        striped
                                        hover
                                        condensed
                                        cellEdit = { cellEditFactory({
                                            mode: 'dbclick',
                                            blurToSave: true,
                                            afterSaveCell: (oldValue, newValue, row, column) => {
                                                //this.GridDataModified(oldValue, newValue, row.id, column.dataField)
                                                }
                                            })
                                        }
                                        selectRow = { selectRow }
                                        //rowEvents={ requestMonitoringRowEvents }
                
                                    />
                                </div>
                            </Card.Body>
                        </Card>
                        <Card className="mt-5">
                            <Card.Header>PAYCARD STATUS DETAILS</Card.Header>
                            <Card.Body>
                                <div className="mt-3">  
                                    <BootstrapTable
                                        keyField = "id"
                                        data = { this.state.statusDG }
                                        columns = { statusColumn }
                                        pagination={ paginationFactory({sizePerPageRenderer}) }
                                        noDataIndication={ () => <div style={{width:'100%'}}>No record found.</div> }
                                        rowClasses="noser-table-row-class no-checkbox"
                                        striped
                                        hover
                                        condensed
                                        cellEdit = { cellEditFactory({
                                            mode: 'dbclick',
                                            blurToSave: true,
                                            afterSaveCell: (oldValue, newValue, row, column) => {
                                                //this.GridDataModified(oldValue, newValue, row.id, column.dataField)
                                                }
                                            })
                                        }
                                        selectRow = { selectRowStatus }
                                        //rowEvents={ requestMonitoringRowEvents }
                
                                    />
                                </div>
                            </Card.Body>
                        </Card>
                        <ButtonToolbar className="mt-5">
                            <Button variant="success" className="ml-auto noser-button-mr1" onClick={ this.handleSaveClick }>Update</Button>&nbsp;&nbsp;
                            <Button variant="success" className="noser-button" onClick={ this.handleCancel} >Cancel</Button>&nbsp;&nbsp;
                            <Button variant="success" className="noser-button"  onClick={ this.handleTransmit}>Transmit</Button>&nbsp;&nbsp;
                            <Button href='/home' variant="danger" className="noser-button">Close</Button>
                        </ButtonToolbar>

                    </Card.Body>
                </Card>
                <div className="mt-5"></div>
            </Container>
            <NoserLoading show={this.state.isloading} />
            </div>
        )
    }
}
export  default ViewDetails
