import {
    React,Component,BootstrapTable, Type,
    paginationFactory,Button, ButtonToolbar, Card,
    Form, Col, Row, Container, axios, Typeahead, Banner, TimePicker,
    cellEditFactory ,moment, NavLink, Alert, AppConfiguration,
    NoserLoading, Noser, sizePerPageRenderer, DatePicker
} 
from '../../../noser-hris-component';
import type from 'react-bootstrap-table2-editor';

import ToolkitProvider, { CSVExport } from 'react-bootstrap-table2-toolkit';
import 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css';
import NumberFormat from 'react-number-format';

class MembersReport extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userinfo        :   [],
            isloading       :   false,
            isshow          :   false,
            alerttype       :   "",
            message         :   "",
            color           :   "",
            fade            :   true,

            clientDDL : [],
            employeeDDL : [],
            branchDDL : [],
            dateFrom : "",
            dateTo : "",
            employeeNumber: "",
            selectedClientId: "",
            selectedBranchId: "",
            selectedEmployeeId: "",
            membersReportGrid : [],
            statusDDL : [
                {"id":"0", "name":"ACTIVE"},
                {"id":"1", "name":"INACTIVE"}
            ],
            statusId : "",

        }
    }

    componentDidMount(){
        const sleep = (milliseconds) => {return new Promise(resolve => setTimeout(resolve, milliseconds))}
        this.state.userinfo = JSON.parse(sessionStorage.getItem("userData"))
        this.GetClients();
    }

    GetClients = async() => {
        this.setState({isloading:true})
        const param = {
            "IpAddress"     :   "0.0.0.0",
            "ClientId"      :   this.state.userinfo.clientId,
            "UserId"        :   this.state.userinfo.userId,
        };
        await axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Client/GetClientList",  param)
        .then(res => {
            const data = res.data;
            console.log("Client List ");
            console.log(data);
            this.setState({clientDDL:data.clients,isloading:false});
        })
        .catch(error=>{
            this.setState(  {
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade            :   true
            })
        })
    }

    onChangeClient = (e) => {
        if(e.length == 0) {
            this.state.selectedClientName=""
            this.state.selectedClientId=""
            return
        } 
        this.state.selectedClientId = e[0].id
        this.state.selectedClientName = e[0].name

        this.getEmployees();
        this.getClientLocation();
    }

    getEmployees = async() => {
        this.setState({isloading:true})
        const getParams = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.selectedClientId,
            "UserId":this.state.userinfo.userId,
        }
        await axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Employee/GetEmployeeProfileFilter", getParams)
        .then(res => {
            const data = res.data
            console.log("GetEmployeeProfileFilter");
            console.log(data);
            this.setState({employeeDDL : data.profiles, isloading:false,}) 
        })
        .catch(error=>{
           this.setState(  {
               isloading       :   false,
               alerttype       :   "Error!",
               isshow          :   true,
               color           :   "danger",
               message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
               fade            :   true
           })
       })
        
    }

    onChangeBranch = (e) => {
        if(e.length == 0) {
            this.state.selectedBranchId=""
            return
        } 
        this.state.selectedBranchId = e[0].id
    }

    getClientLocation(){
        this.setState({isloading:true})

        const getParams = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.selectedClientId,
            "UserId":this.state.userinfo.userId,
            "ClientName": this.state.selectedClientName,
            "City": "",
            "Province": "",
            "Region": ""
    
        }
        axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Client/GetClientLocations", getParams)
        .then(res => {
            const data = res.data;
            ////////////console.log("Client Location");
            ////////////console.log(data);
            this.setState({
                branchDDL	:   res.data.locations ? res.data.locations : [],
                isloading	            :   false
            })
            /* if(data.locations.length=="0"){
                this.setState({
                    isloading   :   false,
                    alerttype   :   "Error!",
                    isshow      :   true,
                    color       :   "danger",
                    message     :   data.message,
                    fade        :   true
                });
            } */
        })
    }

    onChangeEmployees = (e) => {
        if(e.length == 0) {
            this.state.selectedEmployeeId=""
            return
        } 
        this.state.selectedEmployeeId = e[0].profileId
    }

    onChangeEmployeeNumber = (e) => {
        this.setState({
            employeeNumber: e.target.value,isshow:false
        })
    }

    onChangePosition = (e) => {
        if(e.length == 0) {
            this.state.selectedPositionId=""
            return
        } 
        this.state.selectedPositionId = e[0].id
    }
    
    handleChangeDateFrom = date => {
        //////console.log(date)
        this.setState({
            dateFrom: date,
            isshow:false,
        })
    }

    handleChangeDateTo = date => {
        //////console.log(date)
        this.setState({
            dateTo: date,
            isshow:false,
        })
    }

    FormatDate(date) {
        let m = moment(date, 'MM-DD-YYYY');
        return (m.isValid() ? (date.getMonth()+1 + "/" + date.getDate() + "/" + date.getFullYear()) : "")
    }
    onChangeStatus = (e) => {
        this.setState({isshow:false})
        if(e.length == 0) {
            this.state.statusId=""
            return
        } 
        this.state.statusId = e[0].id
        
    }

    handleSearchClick = async() => {
        this.setState({isloading:true})
        let dateFrom = (this.state.dateFrom) ? moment(this.state.dateFrom).format('MM/DD/YYYY') : ""
        let dateTo = (this.state.dateTo) ? moment(this.state.dateTo).format('MM/DD/YYYY') : ""
        const params = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.selectedClientId,
            "UserId":this.state.userinfo.userId,
            "BranchId":this.state.selectedBranchId, 
            "ProfileId":this.state.selectedEmployeeId, 
            "EmployeeNo":this.state.employeeNumber,  
            "PositionId":"", 
            "StartDate":dateFrom, 
            "EndDate":dateTo,
            "StatusId" : this.state.statusId,
        };
        console.log("params");
        console.log(params);
        await axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Report/GetMemberReports",  params)
        .then(res => {
            const data = res.data;
            console.log("Get Member Reports");
            console.log(data);
            this.setState({ membersReportGrid  : data.employees, isloading:false });
            if(data.status=="0"){
               this.setState({
                   isloading   :   false,
                   alerttype   :   "Error!",
                   isshow      :   true,
                   color       :   "danger",
                   message     :   data.message,
                   fade        :   true
               });
            }
        })
        .catch(error=>{
           this.setState(  {
               isloading       :   false,
               alerttype       :   "Error!",
               isshow          :   true,
               color           :   "danger",
               message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
               fade            :   true
           })
       })

    }
    
    render() {
        const { ExportCSVButton } = CSVExport;

        const membersReportColumn = [
            // {
            //     text	    : 'HC',
            //     editable    :   false,
            //     dataField   :   "hc",
            // },
            {
                text	    : 'ACCOUNT',
                editable    :   false,
                dataField   :   "accountName",
            },
            {
                text	    : 'NAME_OF_CLIENT',
                editable    :   false,
                dataField   :   "clientname",
            },
            {
                text	    : 'STATUS',
                editable    :   false,
                dataField   :   "status",
            },
            {
                text	    : 'EMPLOYEE_NUMBER',
                editable    :   false,
                dataField   :   "employeeNo",
            },
            {
                text	    : 'FULL_NAME',
                editable    :   false,
                dataField   :   "employeeName",
            },
            {
                text	    : 'SURNAME',
                editable    :   false,
                dataField   :   "lastName",
            },
            {
                text	    : 'SUFFIX',
                editable    :   false,
                dataField   :   "suffixName",
            },
            {
                text	    : 'FIRST_NAME',
                editable    :   false,
                dataField   :   "firstName",
            },
            {
                text	    : 'MIDDLE_NAME',
                editable    :   false,
                dataField   :   "middleName",
            },
            {
                text	    : 'POSITION',
                editable    :   false,
                dataField   :   "position",
            },
            {
                text	    : 'AREA_OF_ASSIGNMENT',
                editable    :   false,
                dataField   :   "branchName",
            },
            // {
            //     text	    : 'AREA',
            //     editable    :   false,
            //     dataField   :   "branchArea",
            // },
            {
                text	    : 'REGION',
                editable    :   false,
                dataField   :   "branchArea",
            },
            {
                text	    : 'MEMBERSHIP_DATE',
                editable    :   false,
                dataField   :   "membershipDate",
            },
            {
                text	    : 'DATE_HIRED',
                editable    :   false,
                dataField   :   "contractDateStart",
            },
            {
                text	    : 'DATE_END',
                editable    :   false,
                dataField   :   "contractDateEnd",
            },
            {
                text	    : 'TENURE',
                editable    :   false,
                dataField   :   "tenure",
            },
            {
                text	    : 'EMPLOYMENT_STATUS',
                editable    :   false,
                dataField   :   "employmentStatus",
            },
            {
                text	    : 'DEPLOYMENT_STATUS',
                editable    :   false,
                dataField   :   "employmentStatus",
            },
            {
                text	    : 'DATE_OF_DEACTIVATION.',
                editable    :   false,
                dataField   :   "dateOfDeactivation",
            },
            {
                text	    : 'HMO_TAGGING',
                editable    :   false,
                dataField   :   "hmoTagging",
            },
            {
                text	    : 'DATE_ENROLLED',
                editable    :   false,
                dataField   :   "hmoDateEnrolled",
            },
            {
                text	    : 'DATE_OF_DEACTIVATION',
                editable    :   false,
                dataField   :   "hmoDateOfDeactivation",
            },
            {
                text	    : 'RATE',
                editable    :   false,
                dataField   :   "salarayRate",
            },
            {
                text	    : 'ECOLA',
                editable    :   false,
                dataField   :   "ecolaRate",
            },
            {
                text	    : 'COLA',
                editable    :   false,
                dataField   :   "colaRate",
            },
            {
                text	    : 'SEA',
                editable    :   false,
                dataField   :   "seaRate",
            },
            {
                text	    : 'PAY_CARD_NO.',
                editable    :   false,
                dataField   :   "paycardNumber",
            },

            {
                text	    : 'PAY_CARD_TYPE',
                editable    :   false,
                dataField   :   "paycardType",
            },
            {
                text	    : 'TRANSACTION_FEE',
                editable    :   false,
                dataField   :   "transactionFee",
            },
            {
                text	    : 'PAY_TYPE',
                editable    :   false,
                dataField   :   "payType",
            },
            {
                text	    : 'PAY_MODE',
                editable    :   false,
                dataField   :   "payMode",
            },
            {
                text	    : 'WORK_SCHEDULE',
                editable    :   false,
                dataField   :   "workSchedule",
            },
            {
                text	    : 'PERIOD_TYPE',
                editable    :   false,
                dataField   :   "periodType",
            },
            {
                text	    : 'APPROVER_1',
                editable    :   false,
                dataField   :   "approver1",
            },
            {
                text	    : 'APPROVER_2',
                editable    :   false,
                dataField   :   "approver2",
            },
            {
                text	    : 'CBU',
                editable    :   false,
                dataField   :   "cbu",
            },
            {
                text	    : 'ADDRESS',
                editable    :   false,
                dataField   :   "address",
            },
            {
                text	    : 'BIRTH_DATE',
                editable    :   false,
                dataField   :   "dateOfBirth",
            },
            {
                text	    : 'AGE',
                editable    :   false,
                dataField   :   "age",
            },
            {
                text	    : 'BIRTH_PLACE',
                editable    :   false,
                dataField   :   "placeOfBirth",
            },
            {
                text	    : 'BLOOD_TYPE',
                editable    :   false,
                dataField   :   "bloodType",
            },
            {
                text	    : 'MOBILE_NUMBER',
                editable    :   false,
                dataField   :   "mobileNumber",
            },
            {
                text	    : 'EMAIL_ADDRESS',
                editable    :   false,
                dataField   :   "emailAddress",
            },
            {
                text	    : 'GENDER',
                editable    :   false,
                dataField   :   "gender",
            },
            {
                text	    : 'CIVIL_STATUS',
                editable    :   false,
                dataField   :   "civilStatus",
            },
            {
                text	    : 'RELIGION',
                editable    :   false,
                dataField   :   "religion",
            },
            {
                text	    : 'HEIGHT',
                editable    :   false,
                dataField   :   "height",
            },
            {
                text	    : 'WEIGHT',
                editable    :   false,
                dataField   :   "weight",
            },
            {
                text	    : 'SSS',
                editable    :   false,
                dataField   :   "sssNumber",
            },
            {
                text	    : 'REMARKS',
                editable    :   false,
                dataField   :   "sssRemarks",
            },
            {
                text	    : 'PHIL',
                editable    :   false,
                dataField   :   "phicNumber",
            },
            {
                text	    : 'REMARKS',
                editable    :   false,
                dataField   :   "phicRemarks",
            },
            {
                text	    : 'PAG_IBIG',
                editable    :   false,
                dataField   :   "hdmfNumber",
            },
            {
                text	    : 'REMARKS',
                editable    :   false,
                dataField   :   "hdmfRemarks",
            },
            {
                text	    : 'TIN',
                editable    :   false,
                dataField   :   "tinNumber",
            },
            {
                text	    : 'REMARKS',
                editable    :   false,
                dataField   :   "tinRemarks",
            },
            {
                text	    : 'EMERGENCY_CONTACT_PERSON',
                editable    :   false,
                dataField   :   "emergencyContactPerson",
            },
            {
                text	    : 'RELATIONSHIP',
                editable    :   false,
                dataField   :   "emergencyContactRelation",
            },
            {
                text	    : 'EMERGENCY_CONTACT_NUMBER',
                editable    :   false,
                dataField   :   "emergencyContactNumber",
            },
            {
                text	    : 'FATHER_NAME',
                editable    :   false,
                dataField   :   "fatherName",
            },
            {
                text	    : 'AGE',
                editable    :   false,
                dataField   :   "fatherAge",
            },
            {
                text	    : 'MOTHER_NAME',
                editable    :   false,
                dataField   :   "motherName",
            },
            {
                text	    : 'AGE',
                editable    :   false,
                dataField   :   "motherAge",
            },
            {
                text	    : 'SPOUSE',
                editable    :   false,
                dataField   :   "spouseName",
            },
            {
                text	    : 'NO._CHILD',
                editable    :   false,
                dataField   :   "noofChildren",
            },
            {
                text	    : 'HIGHEST_EDUCATIONAL_ATTAINMENT',
                editable    :   false,
                dataField   :   "schoolLevel",
            },
            {
                text	    : 'SCHOOL',
                editable    :   false,
                dataField   :   "schoolName",
            },
            {
                text	    : 'COURSE',
                editable    :   false,
                dataField   :   "course",
            },
            {
                text	    : 'SCHOOL_YEAR_START',
                editable    :   false,
                dataField   :   "yearStart",
            },
            {
                text	    : 'SCHOOL_YEAR_END',
                editable    :   false,
                dataField   :   "yearEnd",
            },
        ] 

            const membersReportSelectRow = {
                mode: 'checkbox',
                clickToSelectAndEditCell: true,
                onSelect: (row, isSelect, rowIndex, e) => {
                    this.state.membersReportGrid.map(function(item,i){
                        if(item.id===row.id)
                        {
                            item.isDeleted = isSelect ? "1" : "0"
                            item.isModified = isSelect ? "1" : "0"
                        }
                    })
                }
            };
    
            const membersReportRowEvents = {
                onClick: (e, row, rowIndex) => {
                }
            };

        return(
            <div>

                <Banner />
                <Container className="themed-container" fluid={true}>
                    <Card className="mt-5">
                        <Card.Header>REPORT >> ADMIN - MEMBER'S REPORT</Card.Header>
                        <Card.Body>
                            <Form >
                                <Alert isOpen={this.state.isshow} color={this.state.color} fade={this.state.fade} className={this.state.isshow ? 'display-block' : 'display-none'}>
                                    <div className={this.state.color}></div> 
                                    {this.state.message}
                                </Alert>
                                <Form.Group as={Row} controlId="formPlaintextEmail">
                                    <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                        CLIENT
                                    </Form.Label>
                                    <Col sm="11">
                                        <Typeahead
                                            labelKey='name'
                                            id="basic-example"
                                            onChange={this.onChangeClient}
                                            options={this.state.clientDDL}
                                        />
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} controlId="formPlaintextEmail">
                                    <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                        BRANCH
                                    </Form.Label>
                                    <Col sm="11">
                                        <Typeahead
                                            labelKey='name'
                                            id="basic-example"
                                            onChange={this.onChangeBranch}
                                            options={this.state.branchDDL}
                                        />
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} controlId="formPlaintextEmail">
                                    <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                        EMPLOYEE
                                    </Form.Label>
                                    <Col sm="11">
                                        <Typeahead
                                            labelKey='employeeName'
                                            id="basic-example"
                                            onChange={this.onChangeEmployees}
                                            options={this.state.employeeDDL}
                                    />
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} controlId="formPlaintextEmail">
                                    <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                        EMPLOYEE NUMBER
                                    </Form.Label>
                                    <Col sm="11">
                                        <NumberFormat
                                            name="employeeNumber"
                                            value={this.state.employeeNumber}
                                            onChange={this.onChangeEmployeeNumber} 
                                            autoComplete="off"
                                            format="#####-#######"
                                            className="form-control"
                                        />
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} controlId="formPlaintextEmail">
                                    <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                        STATUS
                                    </Form.Label>
                                    <Col sm="11">
                                        <Typeahead
                                            labelKey='name'
                                            id="basic-example"
                                            onChange={this.onChangeStatus}
                                            options={this.state.statusDDL}

                                        />
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} controlId="formPlaintextEmail">
                                    <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                        DATE START
                                    </Form.Label>
                                    <Col sm="2">
                                        <DatePicker
                                            ref='dateFrom'
                                            selected={this.state.dateFrom}
                                            onChange={this.handleChangeDateFrom}
                                            minDate={this.minDate}
                                            value={this.props.dateFrom}
                                            dateFormat={"MM/dd/yyyy"}
                                            className="form-control"
                                        />  
                                    </Col>
                                    <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                        DATE END
                                    </Form.Label>
                                    <Col sm="2">
                                        <DatePicker
                                            ref='dateTo'
                                            selected={this.state.dateTo}
                                            onChange={this.handleChangeDateTo}
                                            minDate={this.minDate}
                                            value={this.props.dateTo}
                                            dateFormat={"MM/dd/yyyy"}
                                            className="form-control"
                                        />
                                    </Col>
                                </Form.Group>

                                <ButtonToolbar sm={12}>
                                    <Button variant="success" className="ml-auto" onClick = { this.handleSearchClick }>
                                        SEARCH
                                    </Button>
                                </ButtonToolbar>
                                <div className="mt-5">   
                                    <ToolkitProvider
                                        keyField="id"   
                                        data={ this.state.membersReportGrid }
                                        columns={ membersReportColumn }
                                        exportCSV={ {
                                            fileName: "Admin Members Reports.csv",
                                        } }
                                        >
                                        {
                                            props => (
                                            <div>
                                            <Card.Header>Admin Members Report Record</Card.Header>
                                            <BootstrapTable
                                                { ...props.baseProps } 
                                                /* caption={Noser.TableHeader({title:"RECORD"})} */
                                                keyField = "id"
                                                data = { this.state.membersReportGrid }
                                                columns = { membersReportColumn }
                                                pagination={ paginationFactory({sizePerPageRenderer}) }
                                                noDataIndication={ () => <div style={{width:'100%'}}>No record found.</div> }
                                                rowClasses="noser-table-row-class no-checkbox"
                                                striped
                                                hover
                                                condensed
                                                cellEdit = { cellEditFactory({
                                                    mode: 'dbclick',
                                                    blurToSave: true,
                                                    afterSaveCell: (oldValue, newValue, row, column) => {
                                                        //this.GridDataModified(oldValue, newValue, row.id, column.dataField)
                                                        }
                                                    })
                                                }
                                                selectRow = { membersReportSelectRow }
                                                rowEvents={ membersReportRowEvents }
                        
                                            />
                                            <ButtonToolbar>
                                                <ExportCSVButton className="btn btn-info ml-auto" { ...props.csvProps }>Export</ExportCSVButton>
                                            </ButtonToolbar>
                                            </div>
                                            )
                                        }
                                    </ToolkitProvider>
                                </div>
                            </Form>
                        </Card.Body>
                    </Card>
                </Container>
                <NoserLoading show={this.state.isloading} />
            </div> 
            
        )
    }
}

export default MembersReport;