import {
    React,Component,BootstrapTable,
    paginationFactory,Button, ButtonToolbar, Card, 
    Form, Col, Row, axios, Container, Banner, Typeahead, TimePicker,
    cellEditFactory, Type, moment, NavLink, Alert, AppConfiguration,
    NoserLoading, Noser, sizePerPageRenderer, DatePicker, Redirect,Tabs, Tab, Accordion, NumberFormat,
} 
from '../../noser-hris-component';
import { DropdownButton, Dropdown} from 'react-bootstrap';
import { it } from 'date-fns/locale';

class PendingJobOfferEdit extends Component {
    constructor(props) {
        super(props);
        this.state = {
            startDate: new Date(),
            userinfo: [],
            isloading: true,
            alerttype: "",
            isshow: false,
            message: "",
            color: "",
            fade: true,
            navigate : false,
            disablecontrol      :   true,
            isDisableApprove    :   true,
            isDisableReject     :   true,
            disablecard         :   false,
            
            profileId           :   "",
            applicationId       :   "",
            jobofferId          :   "",

            dateHired           :   "",
            title               :   "",
            firstName           :   "",
            middleName          :   "",
            lastName            :   "",
            suffixName          :   "",
            nickName            :   "",
            addressId           :   "",
            addresstypeId       :   "",
            addresstype         :   "",
            regionId           :   "",
            region              :   "",
            provinceId          :   "",
            province            :   "",
            cityId              :   "",
            city                :   "",
            houseNumber         :   "",
            street              :   "",
            barangay            :   "",
            postalCode          :   "",
            homephoneNumber     :   "",
            officephoneNumber   :   "",
            mobileNumber        :   "",
            emailAddress        :   "",
            facebook            :   "",
            twitter             :   "",
            nationalityId       :   "",
            nationality         :   "",
            religionId          :   "",
            religion            :   "",
            genderId            :   "",
            gender              :   "",
            civilstatusId       :   "",
            civilstatus         :   "",
            dateofBirth         :   "",
            placeofBirth        :   "",
            age                 :   "",
            arrangementId       :   "",
            arrangement         :   "",
            bloodtypeId         :   "",
            bloodtype           :   "",
            height              :   "",
            weight              :   "",
            languageSpoken      :   "",
            hobbies             :   "",
            emergencycontactName:   "",
            emergencycontactNumber:"",
            emergencycontactRelation:"",
            tinNumber           :   "",
            sssNumber           :   "",
            phicNumber          :   "",
            hdmfNumber          :   "",


            


            clientId            :   "",
            client              :   "",
            locationId          :   "",
            location            :   "",
            
            isdefaultLocation   :   false,
            positionId          :   "",
            position            :   "",
            statustypeId        :   "",
            statustype          :   "",
            contractdateStart   :   "",
            contractdateEnd     :   "",
            cardtypeId          :   "",
            cardtype            :   "",
            cardNumber          :   "",
            paytypeId           :   "",
            paytype             :   "",
            paymodeId           :   "",
            paymode             :   "",
            salaryRate          :   "",
            ecolaRate           :   "",
            seaRate             :   "",
            colaRate            :   "",
            taxexemptiontypeId  :   "",
            taxexemptiontype    :   "",
            taxtypeId           :   "",
            taxtype             :   "",
            taxCode             :   "",
            isOBP               :   false,
            deductionSSSId      :   "",
            deductionSSS        :   "",
            deductionHDMFId     :   "",
            deductionHDMF       :   "",
            deductionPHICId     :   "",
            deductionPHIC       :   "",
            basis13thMonthId    :   "",
            basis13thMonth      :   "",
            workingdaysperMonth :   "",
            workingdaysperYear  :   "",
            
            tmnprofileId        :   "",
            memberName          :   "",
            preparedbyId        :   "",
            approvedbyId        :   "",
            approvedBy          :   "",
            notedbyId           :   "",
            notedBy             :   "",
            conforme            :   "",
            
            jobOfferDDL         :   [],
            nationalityDDL      :   [],
            religionDDL         :   [],
            genderDDL           :   [
                {"id":"1","name":"MALE"},
                {"id":"2","name":"FEMALE"}
            ],
            civilstatusDDL      :   [
                {"id":"1","name":"SINGLE"},
                {"id":"2","name":"MARRIED"},
                {"id":"3","name":"DIVORCED"},
                {"id":"4","name":"SEPARATED"}
            ],
            arrangementDDL      :   [],
            bloodtypeDDL        :   [],
            
            clientDDL           :   [],
            locationDDL         :   [],
            positionDDL         :   [],
            statustypeDDL       :   [],
            cardtypeDDL         :   [],
            paytypeDDL          :   [],
            paymodeDDL          :   [],
            taxtypeDDL          :   [
                {"name":"TAX TABLE","id":"0"},
                {"name":"FINAL TAX","id":"1"},
            ],
            taxexemptionDDL     :   [
                {"name":"ZERO EXEMPTION","id":"0"},
                {"name":"SINGLE","id":"1"},
                {"name":"MARRIED","id":"2"},
                {"name":"MARRIED WITH ONE DEPENDENT","id":"3"},
                {"name":"SINGLE WITH ONE DEPENDENT","id":"4"},
                {"name":"MARRIED WITH TWO DEPENDENT","id":"5"},
                {"name":"SINGLE WITH TWO DEPENDENT","id":"6"},
                {"name":"MARRIED WITH THREE DEPENDENT","id":"7"},
            ],
            deductionsDDL       :   [
                {"name":"GROSS PAY","id":"1"},
                {"name":"BASIC PAY","id":"2"},
            ],
            basis13thmonthDDL   :   [
                {"name":"INTERNAL","id":"1"},
                {"name":"DEPLOYED","id":"2"},
            ],
            yesNoDDL            :   [
                { "value" : "1", "label" : "YES" },
                { "value" : "0", "label" : "NO" },
            ],
            titleDDL: [
                {name: 'Select Name Title', value: "",},
                {name: 'MR.',               value: 'Mr.',},
                {name: 'MS.',               value: 'Ms.',},
                {name: 'MRS.',              value: 'Mrs.',},
            ],
            suffixDDL: [
                {name: 'Select Suffix Name', value: "",},
                {name: 'SR',               value: 'SR',},
                {name: 'JR',               value: 'JR',},
                {name: 'I',              value: 'I',},
                {name: 'II',              value: 'II',},
                {name: 'III',              value: 'III',},
                {name: 'IV',              value: 'IV',},
                {name: 'V',              value: 'V',},
                {name: 'VI',              value: 'VI',},
                {name: 'VII',              value: 'VII',},
                {name: 'VIII',              value: 'VIII',},
                {name: 'IX',              value: 'IX',},
                {name: 'X',              value: 'X',},
            ],
            typeDDL: [
                {name: 'Select Address Type',   value: "",},
                {name: 'CURRENT ADDRESS',       value: '0',},
                {name: 'REGISTERED ADDRESS',    value: '1',},
                {name: 'PROVINCIAL ADDRESS',    value: "2",},
                {name: 'CORRESPONDENCE ADDRESS',value: '3',},
                {name: 'FOREIGN ADDRESS',       value: '4',},
            ],
            regionDDL:[],
            provinceList:[],
            provinceDDL:[],
            cityList:[],
            cityDDL:[],
            requiredDocsDG : [],
            tblDocumentsArrLst : [],
            newRequiredDocsDG : [],
            data:[],
            requiredDDL        :   [
                {"value":"0","label":"OPTIONAL"},
                {"value":"1","label":"MANDATORY"}
            ],
        }
    }

    componentDidMount() {
        this.state.userinfo = JSON.parse(sessionStorage.getItem("userData"))
        this.state.data = JSON.parse(sessionStorage.getItem("vwPendingJO" + this.state.userinfo.userId + this.state.userinfo.employeeId))
        console.log(this.state.data)
        let approverAdmin = this.state.userinfo.roleId === "1" || 
                            this.state.userinfo.roleId === "8" ||
                            this.state.userinfo.roleId === "11" ||
                            this.state.userinfo.roleId === "19" ||
                            this.state.userinfo.roleId === "28" ? true : false

        this.state.disablecontrol = approverAdmin
        if(approverAdmin) {
            if(this.state.data.statusId === "4"){
                this.setState({
                    isDisableApprove    : false,
                    isDisableReject     : false,
                    notedBy             : this.state.userinfo.fullName,
                    notedbyId           : this.state.userinfo.userId,
                    approvedBy          : this.state.data.approvedBy,
                    approvedbyId        : this.state.data.approvedById,
                })
            }
            else{
                this.setState({
                    isDisableApprove    : true,
                    isDisableReject     : true,
                    notedBy             : this.state.data.notedBy,
                    notedbyId           : this.state.data.notedById,
                    approvedBy          : this.state.data.approvedBy,
                    approvedbyId        : this.state.data.approvedById,
                })
            }
        }
        else {
            if(this.state.data.statusId ==="2"){
                this.setState({
                    notedBy             : "",
                    notedbyId           : "",
                    approvedBy          : this.state.userinfo.fullName,
                    approvedbyId        : this.state.userinfo.userId,
                    isDisableApprove    : false,
                    isDisableReject     : false
                })
            }
            else{
                this.setState({
                    notedBy             : this.state.data.notedBy,
                    notedbyId           : this.state.data.notedById,
                    approvedBy          : this.state.data.approvedBy,
                    approvedbyId        : this.state.data.approvedById,
                    isDisableApprove    : true,
                    isDisableReject     : true
                })
            }
        }
        
        this.state.profileId = this.state.data.profileId
        this.state.applicationId= this.state.data.applicationFormId
        this.state.jobofferId= this.state.data.id

        this.state.dateHired = this.state.data.dateHired!=="" ? new Date(this.state.data.dateHired) : ""
        this.state.title = this.state.data.title
        this.state.firstName = this.state.data.firstName
        this.state.lastName = this.state.data.lastName
        this.state.middleName = this.state.data.middleName
        this.state.suffixName = this.state.data.suffixName
        this.state.nickName = this.state.data.nickName
        this.state.addressId = this.state.data.addressId
        this.state.addresstypeId = this.state.data.addresstypeId
        this.state.addresstype = this.state.data.addresstype
        this.state.regionId   = this.state.data.regionId
        this.state.region      = this.state.data.region
        this.state.provinceId  = this.state.data.provinceId
        this.state.province    = this.state.data.province
        this.state.cityId      = this.state.data.cityId
        this.state.city        = this.state.data.city
        this.state.houseNumber = this.state.data.houseNumber
        this.state.street      = this.state.data.street
        this.state.barangay    = this.state.data.barangay
        this.state.postalCode  = this.state.data.postalCode
        this.state.homephoneNumber = this.state.data.homePhoneNumber
        this.state.officephoneNumber = this.state.data.officePhoneNumber
        this.state.mobileNumber = this.state.data.mobileNumber
        this.state.emailAddress = this.state.data.emailAddress
        this.state.facebook = this.state.data.facebook
        this.state.twitter = this.state.data.twitter
        this.state.nationalityId = this.state.data.nationalityId
        this.state.nationality = this.state.data.nationality
        this.state.religionId = this.state.data.religionId
        this.state.religion = this.state.data.religion
        this.state.genderId = this.state.data.genderId
        this.state.gender = this.state.data.gender
        this.state.civilstatusId = this.state.data.civilStatusId
        this.state.civilstatus = this.state.data.civilStatus
        this.state.dateofBirth = this.state.data.dateOfBirth!=="" ? new Date(this.state.data.dateOfBirth) : ""
        this.state.age = this.state.data.age
        this.state.placeofBirth = this.state.data.placeOfBirth
        this.state.arrangementId = this.state.data.livingArrangementId
        this.state.arrangement = this.state.data.livingArrangement
        this.state.bloodtypeId = this.state.data.bloodTypeId
        this.state.bloodtype = this.state.data.bloodType
        this.state.height = this.state.data.height
        this.state.weight = this.state.data.weight
        this.state.languageSpoken = this.state.data.languageSpoken
        this.state.hobbies = this.state.data.hobbies
        this.state.emergencycontactName = this.state.data.emergencyContactName
        this.state.emergencycontactNumber = this.state.data.emergencyContactNumber
        this.state.emergencycontactRelation = this.state.data.emergencyContactRelation
        this.state.tinNumber = this.state.data.tinNumber
        this.state.sssNumber = this.state.data.sssNumber
        this.state.phicNumber = this.state.data.phicNumber
        this.state.hdmfNumber = this.state.data.hdmfNumber


        this.state.clientId = this.state.data.clientId
        this.state.client = this.state.data.client
        this.state.locationId = this.state.data.locationId
        this.state.location=this.state.data.location
        this.state.isdefaultLocation = this.state.data.locationId === "1" ? true:false
        this.state.positionId =this.state.data.positionId
        this.state.position = this.state.data.position
        this.state.statustypeId = this.state.data.employeeStatusTypeId
        this.state.statustype = this.state.data.employeeStatusType
        this.state.contractdateStart = this.state.data.contractDateStart!=="" ? new Date(this.state.data.contractDateStart) : ""
        this.state.contractdateEnd = this.state.data.contractDateEnd!=="" ? new Date(this.state.data.contractDateEnd): ""
        this.state.cardtypeId = this.state.data.payCardTypeId
        this.state.cardtype = this.state.data.payCardType
        this.state.cardNumber = this.state.data.payCardNumber
        this.state.paymodeId = this.state.data.payModeId
        this.state.paymode = this.state.data.payMode
        this.state.paytypeId = this.state.data.payTypeId
        this.state.paytype = this.state.data.payType
        this.state.salaryRate = this.state.data.salaryOffered
        this.state.ecolaRate = this.state.data.ecolaValue
        this.state.seaRate = this.state.data.seaValue
        this.state.colaRate = this.state.data.colaValue
        this.state.taxexemptiontypeId = this.state.data.taxExemptionTypeId
        this.state.taxexemptiontype = this.state.data.taxExemptionType
        this.state.taxtypeId = this.state.data.taxTypeId
        this.state.taxtype = this.state.data.taxType
        this.state.taxcodeId = this.state.data.taxcodeId
        this.state.taxcode = this.state.data.taxcode
        this.state.isOBP = this.state.data.isOBP === "1" ? true:false
        this.state.deductionSSSId = this.state.data.deductionBasisSSSId
        this.state.deductionSSS = this.state.data.deductionBasisSSS
        this.state.deductionPHICId = this.state.data.deductionBasisPHICId
        this.state.deductionPHIC = this.state.data.deductionBasisPHIC
        this.state.deductionHDMFId = this.state.data.deductionBasisHDMFId
        this.state.deductionHDMF = this.state.data.deductionBasisHDMF
        this.state.basis13thMonthId = this.state.data.basisFor13thMonthId
        this.state.basis13thMonth = this.state.data.basisFor13thMonth
        this.state.workingdaysperMonth = this.state.data.workingDaysPerMonth
        this.state.workingdaysperYear = this.state.data.workingDaysPerYear
        

        this.state.tmnprofileId = this.state.data.tmnProfileId
        this.state.memberName = this.state.data.memberName
        this.state.conforme = this.state.data.memberName
        this.state.preparedbyId = this.state.data.preparedById
        this.state.preparedby = this.state.data.preparedBy
        this.state.requiredDocsDG = this.state.data.documents
        
        this.GetDocuments();
    }
    GetDocuments = async()=> {
        const params = {
            "IpAddress"     :   "0.0.0.0",
            "ClientId"      :   this.state.userinfo.clientId,
            "UserId"        :   this.state.userinfo.userId,
            "ProfileId"     :   this.state.profileId
        };
        await axios.post(AppConfiguration.Setting().noserapiendpoint + "Administrator/GetApplicationDocuments",  params)
        .then(res => {
            const data = res.data;
            console.log(data)
            this.setState({requiredDocsDG:data.documents});
            //this.forceUpdate()
        })
        this.GetAddress()
    }
    GetAddress = async()=> {
        const params = {
            "IpAddress"     :   "0.0.0.0",
            "ClientId"      :   this.state.userinfo.clientId,
            "UserId"        :   this.state.userinfo.userId,
            "ApplicationId" :   this.state.applicationId
        };
        await axios.post(AppConfiguration.Setting().noserapiendpoint + "Administrator/GetApplicationAddress",  params)
        .then(res => {
            const data = res.data;
            console.log(data.addresses)
            data.addresses.forEach(itm => {
                this.setState({
                    addressId:itm.addressId,
                    addresstypeId:itm.addresstypeId,
                    addresstype:itm.addresstype,
                    regionId:itm.regionId,
                    region:itm.region,
                    provinceId:itm.provinceId,
                    province:itm.province,
                    cityId:itm.cityId,
                    city:itm.city,
                    houseNumber:itm.houseNumber,
                    street:itm.street,
                    barangay:itm.barangay,
                    postalCode:itm.postalCode,
                });
            })
        })
        //this.forceUpdate()
        this.GetClient()
    }
    GetClient = async() =>{
        const params = {
            "IpAddress":"0.0.0.0",
           "ClientId":this.state.userinfo.clientId,
            "UserId":this.state.userinfo.userId
        }
        await axios
        .post(
            AppConfiguration.Setting().noserapiendpoint + "Client/GetClientList", params)
            .then(res => {
                const data = res.data;
                this.setState({clientDDL : data.clients})
            })
        .catch(error=>{
            this.setState(  {
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade            :   true
            })
        })
        this.GetLocations()
    }
    GetLocations = async()=>{
        const params = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.clientId,
            "UserId":this.state.userinfo.userId,
            "ClientName": this.state.client,
            "City": "",
            "Province": "",
            "Region": ""
    
        }
        await axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Client/GetClientLocations", params)
        .then(res => {
            this.setState({locationDDL : res.data.locations ? res.data.locations : []})
        })
        .catch(error=>{
           this.setState(  {
               isloading       :   false,
               alerttype       :   "Error!",
               isshow          :   true,
               color           :   "danger",
               message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
               fade            :   true
           })
       })
       this.GetNationalities()
    }
    GetNationalities = async()=> {
        const params = {
            "IpAddress"     :   "0.0.0.0",
            "ClientId"      :   this.state.userinfo.clientId,
            "UserId"        :   this.state.userinfo.userId,
            "SectionId"     :   "",
            "DepartmentId"  :   "",
            "Name"          :   ""
        };
        await axios.post(AppConfiguration.Setting().noserapiendpoint + "Maintenance/GetNationalities",  params)
        .then(res => {
            const data = res.data;
            this.setState({nationalityDDL:data.nationalities});
        })
        this.GetReligions()
    }
    GetReligions = async()=> {
        const params = {
            "IpAddress"     :   "0.0.0.0",
            "ClientId"      :   this.state.userinfo.clientId,
            "UserId"        :   this.state.userinfo.userId
        };
        await axios.post(AppConfiguration.Setting().noserapiendpoint + "Maintenance/GetReligions",  params)
        .then(res => {
            const data = res.data;
            this.setState({religionDDL:data.religions});
        })
        this.GetLivingArrangements()
    }
    GetLivingArrangements = async()=> {
        const params = {
            "IpAddress"     :   "0.0.0.0",
            "ClientId"      :   this.state.userinfo.clientId,
            "UserId"        :   this.state.userinfo.userId
        };
        await axios.post(AppConfiguration.Setting().noserapiendpoint + "Maintenance/GetLivingArrangements",  params)
        .then(res => {
            const data = res.data;
            this.setState({arrangementDDL:data.livingArrangements});
        })
        this.GetBloodTypes()
    }
    GetBloodTypes = async()=> {
        const params = {
            "IpAddress"     :   "0.0.0.0",
            "ClientId"      :   this.state.userinfo.clientId,
            "UserId"        :   this.state.userinfo.userId
        };
        await axios.post(AppConfiguration.Setting().noserapiendpoint + "Maintenance/GetBloodTypes",  params)
        .then(res => {
            const data = res.data;
            this.setState({bloodtypeDDL:data.bloodTypes});
        })
        this.GetPositions()
    }
    GetPositions = async()=> {
        const params = {
            "IpAddress"     :   "0.0.0.0",
            "ClientId"      :   this.state.userinfo.clientId,
            "UserId"        :   this.state.userinfo.userId,
            "SectionId"     :   "",
            "DepartmentId"  :   "",
            "Name"          :   ""
        };
        await axios.post(AppConfiguration.Setting().noserapiendpoint + "Maintenance/GetPositions",  params)
        .then(res => {
            const data = res.data;
            this.setState({positionDDL:data.positions});
        })
        this.GetPayrollTypes()
    }
    GetPayrollTypes = async()=> {
        const params = {
            "IpAddress"     :   "0.0.0.0",
            "ClientId"      :   this.state.userinfo.clientId,
            "UserId"        :   this.state.userinfo.userId
        };
        await axios.post(AppConfiguration.Setting().noserapiendpoint + "Timekeeping/GetPayrollTypes",  params)
        .then(res => {
            const data = res.data;
            this.setState({paytypeDDL:data.payrollTypes});
        })
        this.GetPayrollModes()
    }
    GetPayrollModes = async()=> {
        const params = {
            "IpAddress"     :   "0.0.0.0",
            "ClientId"      :   this.state.userinfo.clientId,
            "UserId"        :   this.state.userinfo.userId
        };
        await axios.post(AppConfiguration.Setting().noserapiendpoint + "Timekeeping/GetPayrollModes",  params)
        .then(res => {
            const data = res.data;
            this.setState({paymodeDDL:data.payrollModes});
        })
        this.GetPeriodTypes()
    }
    GetPeriodTypes = async()=> {
        const params = {
            "IpAddress"     :   "0.0.0.0",
            "ClientId"      :   this.state.userinfo.clientId,
            "UserId"        :   this.state.userinfo.userId,
            "PayModeId"     :   ""
        };
        await axios.post(AppConfiguration.Setting().noserapiendpoint + "Timekeeping/GetPeriodTypes",  params)
        .then(res => {
            const data = res.data;
            this.setState({periodtypeList:data.periodTypes});
        })
        this.GetEmployeeStatusTypes()
    }
    GetEmployeeStatusTypes = async()=> {
        const params = {
            "IpAddress"     :   "0.0.0.0",
            "ClientId"      :   this.state.userinfo.clientId,
            "UserId"        :   this.state.userinfo.userId
        };
        await axios.post(AppConfiguration.Setting().noserapiendpoint + "Maintenance/GetEmployeeStatusTypes",  params)
        .then(res => {
            const data = res.data;
            this.setState({statustypeDDL:data.statusTypes});
        })
        this.GetPayCardTypes()
    }
    GetPayCardTypes = async()=> {
        const params = {
            "IpAddress"     :   "0.0.0.0",
            "ClientId"      :   this.state.userinfo.clientId,
            "UserId"        :   this.state.userinfo.userId
        };
        await axios.post(AppConfiguration.Setting().noserapiendpoint + "Maintenance/GetPayCardTypes",  params)
        .then(res => {
            const data = res.data;
            this.setState({cardtypeDDL:data.payCardTypes});
        })
        this.GetRegion()
    }
    GetRegion = async()=> {
        const params = {
            "IpAddress" : "0.0.0.0",
            "ClientId"  : this.state.userinfo.clientId,
            "UserId"    : this.state.userinfo.userId,
            "Name"      : ""
        };
        await
        axios.post(AppConfiguration.Setting().noserapiendpoint + "Maintenance/GetRegions",  params)
        .then(res => {
            const data = res.data
            this.setState({regionDDL:data.regions});
        })
        .catch(error=>{
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade        :   true,
            })
        })
        this.GetProvince()
    }
    GetProvince = async()=> {
        this.setState({isloading:true});
        const provinceParams = {
            "IpAddress" : "0.0.0.0",
            "ClientId"  : this.state.userinfo.clientId,
            "UserId"    : this.state.userinfo.userId,
            //"Region"    : this.state.region
        };
        await
        axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Maintenance/GetProvinces",  provinceParams)
        .then(res => {
            const data = res.data;
            this.setState({provinceDDL:data.provinces.filter(x=>x.regionId===this.state.regionId),
                            provinceList:data.provinces});
        })
        .catch(error=>{
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade        :   true,
            })
        })
        this.GetCity()
    }
    GetCity = async()=> {
        const params = {
            "IpAddress" : "0.0.0.0",
            "ClientId"  : this.state.userinfo.clientId,
            "UserId"    : this.state.userinfo.userId,
            //"RegionId"  : this.state.regionId,
            //"ProvinceId": this.state.provinceId,
            "Name"      : ""
        };
        await
        axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Maintenance/GetCities",  params)
        .then(res => {
            const data = res.data;
            this.setState({
                cityList  : data.cities,
                cityDDL   : data.cities.filter(x=>x.provinceId===this.state.provinceId),
                isloading : false
            });
        })
        .catch(error=>{
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade        :   true,
            })
        })

    }
    handleChangedRegion = e => {
        this.state.alerttype="";this.state.isshow=false;this.state.color="";this.state.message="";this.state.fade=true;
        if(e.length===0){
            this.state.regionId= ''
            this.state.region=''
            this.state.provinceDDL = this.state.provinceList
            this.forceUpdate()
            return
        }
        this.state.provinceDDL = this.state.provinceList.filter(x=>x.regionId===e[0].id)
        this.state.regionId=e[0].id
        this.state.region=e[0].name
        this.forceUpdate()
    }
    handleChangedProvince = e => {
        this.state.alerttype="";this.state.isshow=false;this.state.color="";this.state.message="";this.state.fade=true;
        if(e.length===0){
            this.state.provinceId= ''
            this.state.province=''
            this.state.cityDDL = this.state.cityList
            this.forceUpdate()
            return
        }
        this.state.cityDDL = this.state.cityList.filter(x=>x.provinceId===e[0].id)
        this.state.provinceId=e[0].id
        this.state.province=e[0].name
        this.forceUpdate()
    }
    handleChangedCity = e => {
        this.state.alerttype="";this.state.isshow=false;this.state.color="";this.state.message="";this.state.fade=true;
        if(e.length===0){
            this.state.cityId= ''
            this.state.city=''
            return
        }
        this.state.cityId=e[0].id
        this.state.city=e[0].name
    }
    handleChangedNationality = e => {
        this.state.alerttype="";this.state.isshow=false;this.state.color="";this.state.message="";this.state.fade=true;
        if(e.length===0){
            this.state.nationalityId= ''
            this.state.nationality=''
            return
        }
        this.state.nationalityId=e[0].id
        this.state.nationality=e[0].name
    }
    handleChangedReligion = e => {
        this.state.alerttype="";this.state.isshow=false;this.state.color="";this.state.message="";this.state.fade=true;
        if(e.length===0){
            this.state.religionId= ''
            this.state.religion=''
            return
        }
        this.state.religionId=e[0].id
        this.state.religion=e[0].name
    }
    handleChangedGender = e => {
        this.state.alerttype="";this.state.isshow=false;this.state.color="";this.state.message="";this.state.fade=true;
        if(e.length===0){
            this.state.genderId= ''
            this.state.gender=''
            return
        }
        this.state.genderId=e[0].id
        this.state.gender=e[0].name
    }
    handleChangedCivilStatus = e => {
        this.state.alerttype="";this.state.isshow=false;this.state.color="";this.state.message="";this.state.fade=true;
        if(e.length===0){
            this.state.civilstatusId= ''
            this.state.civilstatus=''
            return
        }
        this.state.civilstatusId=e[0].id
        this.state.civilstatus=e[0].name
    }
    handleChangedArrangement = e => {
        this.state.alerttype="";this.state.isshow=false;this.state.color="";this.state.message="";this.state.fade=true;
        if(e.length===0){
            this.state.arrangementId= ''
            this.state.arrangement=''
            return
        }
        this.state.arrangementId=e[0].id
        this.state.arrangement=e[0].name
    }
    handleChangedBloodType = e => {
        this.state.alerttype="";this.state.isshow=false;this.state.color="";this.state.message="";this.state.fade=true;
        if(e.length===0){
            this.state.bloodtypeId= ''
            this.state.bloodtype=''
            return
        }
        this.state.bloodtypeId=e[0].id
        this.state.bloodtype=e[0].name
    }
    handleChangedClient = e => {
        this.state.alerttype="";this.state.isshow=false;this.state.color="";this.state.message="";this.state.fade=true;
        if(e.length===0){
            this.state.clientId= ''
            this.state.client=''
            return
        }
        this.state.clientId=e[0].id
        this.state.client=e[0].name
        this.GetLocations()
    }
    handleChangedLocation = e => {
        this.state.alerttype="";this.state.isshow=false;this.state.color="";this.state.message="";this.state.fade=true;
        if(e.length===0){
            this.state.locationId= ''
            this.state.location=''
            return
        }
        this.state.locationId=e[0].id
        this.state.location=e[0].name
    }
    handleChangedPosition = e => {
        this.state.alerttype="";this.state.isshow=false;this.state.color="";this.state.message="";this.state.fade=true;
        if(e.length===0){
            this.state.positionId= ''
            this.state.position=''
            return
        }
        this.state.positionId=e[0].id
        this.state.position=e[0].name
    }
    handleChangedStatusType = e => {
        this.state.alerttype="";this.state.isshow=false;this.state.color="";this.state.message="";this.state.fade=true;
        if(e.length===0){
            this.state.statustypeId= ''
            this.state.statustype=''
            return
        }
        this.state.statustypeId=e[0].id
        this.state.statustype=e[0].name
    }
    handleChangedCardType = (e) =>{
        this.state.alerttype="";this.state.isshow=false;this.state.color="";this.state.message="";this.state.fade=true;this.state.isloading=false
        if(e.length===0) {
            this.state.cardtypeId=""
            this.state.cardtype=""
            return
        }
        if(e[0].id==="4"){
            this.setState({ disablecard: true,cardNumber:""})
        }
        else{
            this.setState({ disablecard: false})
        }
        this.state.cardtypeId=e[0].id
        this.state.cardtype=e[0].name
    }
    handleChangedPayType = (e) =>{
        this.state.alerttype="";this.state.isshow=false;this.state.color="";this.state.message="";this.state.fade=true;this.state.isloading=false
        if(e.length===0) {
            this.state.paytypeId=""
            this.state.paytype=""
            return
        }
        this.state.paytypeId=e[0].id
        this.state.paytype=e[0].name
    }
    handleChangedPayMode = (e) =>{
        this.state.alerttype="";this.state.isshow=false;this.state.color="";this.state.message="";this.state.fade=true;this.state.isloading=false
        if(e.length===0) {
            this.state.paymodeId=""
            this.state.paymode=""
            this.state.periodtypeDDL=this.state.periodtypeList
            return
        }
        this.state.paymodeId=e[0].id
        this.state.paymode=e[0].name
    }
    
    handleChangedTaxExempt = (e) =>{
        this.state.alerttype="";this.state.isshow=false;this.state.color="";this.state.message="";this.state.fade=true;this.state.isloading=false
        if(e.length===0) {
            this.state.taxexemptiontypeId=""
            this.state.taxexemptiontype=""
            return
        }
        this.state.taxexemptiontypeId=e[0].id
        this.state.taxexemptiontype=e[0].name
    }
    handleChangedTaxType = (e) =>{
        this.state.alerttype="";this.state.isshow=false;this.state.color="";this.state.message="";this.state.fade=true;this.state.isloading=false
        if(e.length===0) {
            this.state.taxtypeId=""
            this.state.taxtype=""
            return
        }
        this.state.taxtypeId=e[0].id
        this.state.taxtype=e[0].name
    }
    handleChangedSSS = (e) =>{
        this.state.alerttype="";this.state.isshow=false;this.state.color="";this.state.message="";this.state.fade=true;this.state.isloading=false
        if(e.length===0) {
            this.state.deductionSSSId=""
            this.state.deductionSSS=""
            return
        }
        this.state.deductionSSSId=e[0].id
        this.state.deductionSSS=e[0].name
    }
    handleChangedPHIC = (e) =>{
        this.state.alerttype="";this.state.isshow=false;this.state.color="";this.state.message="";this.state.fade=true;this.state.isloading=false
        if(e.length===0) {
            this.state.deductionPHICId=""
            this.state.deductionPHIC=""
            return
        }
        this.state.deductionPHICId=e[0].id
        this.state.deductionPHIC=e[0].name
    }
    handleChangedHDMF = (e) =>{
        this.state.alerttype="";this.state.isshow=false;this.state.color="";this.state.message="";this.state.fade=true;this.state.isloading=false
        if(e.length===0) {
            this.state.deductionHDMFId=""
            this.state.deductionHDMF=""
            return
        }
        this.state.deductionHDMFId=e[0].id
        this.state.deductionHDMF=e[0].name
    }
    handleChanged13TH = (e) =>{
        this.state.alerttype="";this.state.isshow=false;this.state.color="";this.state.message="";this.state.fade=true;this.state.isloading=false
        if(e.length===0) {
            this.state.basis13thMonthId=""
            this.state.basis13thMonth=""
            return
        }
        this.state.basis13thMonthId=e[0].id
        this.state.basis13thMonth=e[0].name
    }
    handleChanged = (e) =>{
        if(e.target.name==="salaryRate" ||
            e.target.name==="ecolaRate" ||
            e.target.name==="colaRate" ||
            e.target.name==="seaRate"){
            if(e.target.value.split(".").length>2) return
            let dec = e.target.value.split(".")[1]
            if(typeof dec !='undefined')
                if(dec.length>2) return

        }
        this.setState({[e.target.name]:e.target.value})
    }
    handleChangedDOB = date =>{
        this.setState({dateofBirth:date})
    }
    handleChangedDH = date =>{
        this.setState({dateHired:date})
    }
    handleChangedEOS = date =>{
        this.setState({contractdateStart:date})
    }
    handleChangedEOC = date =>{
        this.setState({contractdateEnd:date})
    }
    handleChangedCB = (e) =>{
        this.setState({[e.target.name]: e.target.checked})
    }
    handleChangedTitle = (e) => {
        this.setState({ title: e.target.value,isshow:false, });
    };
    handleChangedSuffixName = (e) => {
        this.setState({ suffixName: e.target.value,isshow:false, });
    };
    handleChangedAddressType = (e) => {
        this.setState({ addresstypeId: e.target.value,isshow:false, });
    };
    FormatDate(date) {
        let m = moment(date, 'MM-DD-YYYY');
        return (m.isValid() ? (('0' + (date.getMonth()+1)).slice(-2) + "/" + ('0' + (date.getDate())).slice(-2) + "/" + date.getFullYear()) : "")
    }
    ValidNumeric(val)
    {
        var regex = /^\d+(.\d+)?$/;
        return !regex.test(val)
    }
    IsNumeric(evt)
    {
        var theEvent = evt || window.event;
        var key = theEvent.keyCode || theEvent.which;
        key = String.fromCharCode( key );
        var regex = /^\d+(.\d+)?$/;
        if( !regex.test(key) ) {
        theEvent.returnValue = false;
        if(theEvent.preventDefault) theEvent.preventDefault();
        }
        
    }
    IsDecimal(evt)
    {
        var theEvent = evt || window.event;
        var key = theEvent.keyCode || theEvent.which;
        key = String.fromCharCode( key );
        var regex = /^\d*\.?(?:\d{1,2})?$/;
        if( !regex.test(key) ) {
        theEvent.returnValue = false;
        if(theEvent.preventDefault) theEvent.preventDefault();
        }
    }
    handleSubmitClick = async(statusId) => {
        this.setState({isloading:true,isDisableApprove:true,isDisableReject:true})
        const formData = new FormData();
        formData.append('IpAddress', "0.0.0.0");
        formData.append('ClientId', this.state.userinfo.clientId);
        formData.append('UserId', this.state.userinfo.userId);
        formData.append('RoleId', this.state.userinfo.roleId);
        formData.append('StatusId', statusId);
        formData.append("jobOffers", JSON.stringify(
            [{
                "ProfileId"             :   this.state.profileId,
                "ApplicationFormId"     :   this.state.applicationId,
                "JobOfferId"            :   this.state.jobofferId,

                "DateHired"             :   moment(this.state.dateHired).format("MM/DD/YYYY" ),
                "Title"                 :   this.state.title.toUpperCase(),
                "FirstName"             :   this.state.firstName.toUpperCase(),
                "LastName"              :   this.state.lastName.toUpperCase(),
                "MiddleName"            :   this.state.middleName.toUpperCase(),
                "SuffixName"            :   this.state.suffixName.toUpperCase(),
                "NickName"              :   this.state.nickName.toUpperCase(),
                "AddressId"             :   this.state.addressId,
                "AddresstypeId"         :   this.state.addresstypeId,
                "Addresstype"           :   this.state.addresstype,
                "RegionId"              :   this.state.regionId,
                "Region"                :   this.state.region,
                "ProvinceId"            :   this.state.provinceId,
                "Province"              :   this.state.province,
                "CityId"                :   this.state.cityId,
                "City"                  :   this.state.city,
                "HouseNumber"           :   this.state.houseNumber.toUpperCase(),
                "Street"                :   this.state.street.toUpperCase(),
                "Barangay"              :   this.state.barangay.toUpperCase(),
                "PostalCode"            :   this.state.postalCode,
                "HomePhoneNumber"       :   this.state.homephoneNumber,
                "OfficePhoneNumber"     :   this.state.officephoneNumber,
                "MobileNumber"          :   this.state.mobileNumber.toString().replace("-",""),
                "EmailAddress"          :   this.state.emailAddress,
                "Facebook"              :   this.state.facebook.toUpperCase(),
                "Twitter"               :   this.state.twitter.toUpperCase(),
                "NationalityId"         :   this.state.nationalityId,
                "ReligionId"            :   this.state.religionId,
                "Gender"                :   this.state.gender,
                "CivilStatus"           :   this.state.civilstatus,
                "DateOfBirth"           :   moment(this.state.dateofBirth,).format("MM/DD/YYYY" ),
                "PlaceOfBirth"          :   this.state.placeofBirth,
                "LivingArrangementId"   :   this.state.arrangementId,
                "BloodTypeId"           :   this.state.bloodtypeId,
                "Height"                :   this.state.height,
                "Weight"                :   this.state.weight,
                "LanguageSpoken"        :   this.state.languageSpoken.toUpperCase(),
                "Hobbies"               :   this.state.hobbies.toUpperCase(),
                "EmergencyContactName"  :   this.state.emergencycontactName.toUpperCase(),
                "EmergencyContactNumber":   this.state.emergencycontactNumber.toUpperCase(),
                "EmergencyContactRelation": this.state.emergencycontactRelation.toUpperCase(),
                "SSSNumber"             :   this.state.sssNumber,
                "HDMFNumber"            :   this.state.hdmfNumber,
                "PHICNumber"            :   this.state.phicNumber,
                "TINNumber"             :   this.state.tinNumber,


                "ClientId"              :   this.state.clientId,
                "LocationId"            :   this.state.locationId,
                "IsDefaultLocation"     :   this.state.isdefaultLocation ? "1" : "0",
                "PositionId"            :   this.state.positionId,
                "EmployeeStatusTypeId"  :   this.state.statustypeId,
                "ContractDateStart"     :   moment(this.state.contractdateStart).format("MM/DD/YYYY" ),
                "ContractDateEnd"       :   moment(this.state.contractdateEnd).format("MM/DD/YYYY" ),
                "PayCardTypeId"         :   this.state.cardtypeId,
                "PayCardNumber"         :   this.state.cardNumber,
                "PayTypeId"             :   this.state.paytypeId,
                "PayModeId"             :   this.state.paymodeId,
                "SalaryOffered"         :   this.state.salaryRate,
                "SeaValue"              :   this.state.seaRate,
                "EcolaValue"            :   this.state.ecolaRate,
                "ColaValue"             :   this.state.colaRate,
                "TaxExemptionTypeId"    :   this.state.taxexemptiontypeId,
                "TaxTypeId"             :   this.state.taxtypeId,
                "TaxCodeId"             :   "0",
                "IsOBP"                 :   this.state.isOBP ? "1" : "0",
                "DeductionBasisSSSId"     :   this.state.deductionSSSId,
                "DeductionBasisHDMFId"    :   this.state.deductionHDMFId,
                "DeductionBasisPHICId"    :   this.state.deductionPHICId,
                "BasisFor13thMonthId"     :   this.state.basis13thMonthId,
                "WorkingDaysPerMonth"   :   this.state.workingdaysperMonth,
                "WorkingDaysPerYear"    :   this.state.workingdaysperYear,
                
                "TMNProfileId"          :   this.state.tmnprofileId,
                "MemberName"            :   this.state.lastName.toUpperCase() + ', ' + 
                                            this.state.firstName.toUpperCase() + ' ' + 
                                            this.state.suffixName.toUpperCase()  + ' ' +
                                            this.state.middleName.toUpperCase()  + ' ',
                "Client"                :   this.state.client,
                "Position"              :   this.state.position,
                "PreparedBy"            :   this.state.preparedbyId,
                "NotedBy"               :   this.state.notedbyId,
                "ApprovedBy"            :   this.state.approvedbyId,
                "Conforme"              :   this.state.conforme,
                "documents"             :   this.state.requiredDocsDG,
            }]
        ))
        
        this.state.requiredDocsDG.map(function(itm,idx){
            if(itm.fileName!==""){
                formData.append("FileUpload", itm.fileUpload);  
            }
        })
        await
        axios
        .post( AppConfiguration.Setting().noserapiendpoint + "Administrator/ApproveJobOffer",  formData, {headers: {'Content-Type': 'multipart/form-data'}})
        .then(res => {
            const data = res.data;
            if(data.status=="1"){
                this.setState({
                    isloading           :   false,
                    alerttype           :   "Success!",
                    isshow              :   true,
                    color               :   "success",
                    message             :   data.message,
                    fade                :   true,
                    isDisableApprove    :   true,
                    isDisableReject     :   true
                });
            }
            else {
                this.setState({
                    isloading   :   false,
                    alerttype   :   "Error!",
                    isshow      :   true,
                    color       :   "danger",
                    message     :   data.message,
                    fade        :   true,
                    isDisableApprove:   false,
                    isDisableReject:false
                });
            }
        })
        .catch(error=>{
            this.setState(  {
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade            :   true,
                isDisableApprove:   false,
                isDisableReject :   false
            })
        })
    
    }
    SetFile = rowid => e => {
        let requiredDocsDG = this.state.requiredDocsDG
        let uploadfile = ""
        let filename = ""
        let docid = this.state.docid
        if(e.target.value.length>0){
            uploadfile = e.target.files[0]
            uploadfile.id=this.state.docid
            filename = this.state.selectedProfileId + "_" + this.state.doccode + ".pdf"
        }
        else{
            uploadfile = "";
        }
        if(uploadfile!=="" && requiredDocsDG.filter(x=>x.originalfile===uploadfile.name).length>0){
            let id = requiredDocsDG.filter(x=>x.originalfile===uploadfile.name).id
            requiredDocsDG.map(function(itm){
                itm.fileName = itm.id===id ? "" : itm.fileName
            })
            this.state.requiredDocsDG = requiredDocsDG
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "File name already exist.",
                fade        :   true
            })
            alert("File name already exist.")
            return
        }
        requiredDocsDG.map(function(itm,idx){
            if(itm.id===docid){
                itm.fileUpload = uploadfile
                itm.fileName = filename
                itm.originalfile = uploadfile.name
            }
        })
        this.setState({requiredDocsDG:requiredDocsDG,docid:"",doccode:""})
    }
    handleAttachedClick = row => e =>{
        this.setState({isloading:false,alerttype:"",isshow:false,color:"",message:"",fade:false});
        
        if(row.isSuspended==="1") {alert("Attachment is not applicable for suspended document."); return}
        if(row.dateReceived==="") {alert("Please enter date received."); return}
        if(row.dateReceived===null) {alert("Please enter date received."); return}
        this.state.docid = row.id
        this.state.doccode = row.documentCode
        this.inputElement.click(row.id)
    }
    handleRemoveAttachedClick = rowid => e =>{
        let requiredDocsDG = this.state.requiredDocsDG
        requiredDocsDG.map(function(itm,idx){
            if(itm.id===rowid){
                itm.fileUpload = ""
                itm.fileName = ""
            }
        })
        this.setState({requiredDocsDG:requiredDocsDG})
    }    
    GridDataModified(oldValue, newValue, row, column) {
        let requiredDocsDG = this.state.requiredDocsDG
        requiredDocsDG.map(function(item,i) {
            if (item.id===row.id && newValue!==oldValue)
            {
                if(column==="isReceived" && newValue==="1"){
                    item.isSuspended="0"
                    item.dateReceived = null
                }
                if(column==="isReceived" && newValue==="0"){
                    item.isSuspended="1"
                    item.dateReceived=null
                }

                if(column==="isSuspended" && newValue==="1"){
                    item.isReceived="0"
                    item.dateReceived=null
                }
                if(column==="isSuspended" && newValue==="0"){
                    item.isReceived="1"
                    item.dateReceived = null
                }
                if(typeof item.dateReceived==='undefined')
                    item.dateReceived=null
            }
        })
        this.state.requiredDocsDG = requiredDocsDG
    }
    FormatDate(date) {
        let m = moment(date, 'MM/DD/YYYY');
        return (m.isValid() ? (date.getMonth()+1 + "/" + date.getDate() + "/" + date.getFullYear()) : "")
    }
    render() {
        const { navigate } = this.state;
        if (navigate) {
            return <Redirect to="/pendingjobOffer" push={true} />;
        } 
        
        const requiredDocsColumns = [
            {
                dataField: 'document',
                text: 'Documents',
                editable : false,
                headerStyle: () => {
                    return { width: "40%" };
                },
            },
            {
                dataField: 'isRequired',
                text: 'Type',
                editable : this.state.userinfo.roleId==="1" ? false : true,
                headerStyle: () => {
                    return { width: "10%" };
                },
                formatter: (cell, row) => {
                    if(row.isReceived!='' && row.isReceived!=null){
                        if(this.state.requiredDDL.filter(x => x.value == cell).length==0)
                        return ""
                        else
                            return this.state.requiredDDL.find(x => x.value == cell).label;
                    }
                },
                editor: {
                    type: Type.SELECT,
                    options: this.state.requiredDDL
                }
            },
            {
                dataField: 'isReceived',
                text: 'Received',
                editable : this.state.userinfo.roleId==="1" ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'10%'}},
                style:{textAlign:'center',width:'10%'},
                formatter: (cell, row) => {
                    if(row.isReceived!='' && row.isReceived!=null){
                        if(this.state.yesNoDDL.filter(x => x.value == cell).length==0)
                        return ""
                        else
                            return this.state.yesNoDDL.find(x => x.value == cell).label;
                    }
                },
                editor: {
                    type: Type.SELECT,
                    options: this.state.yesNoDDL
                }
            },
            {
                dataField: 'dateReceived',
                text: 'Date Received',
                editable : this.state.userinfo.roleId==="1" ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'10%'}},
                style:{textAlign:'center',width:'10%'},
                formatter: (cell) => { 
                    let dateObj = cell;
                    if (typeof cell !== 'object') dateObj = new Date(cell);
                    if (cell != null){
                        let m = moment(dateObj);
                        return (m.isValid() ? (dateObj.getMonth()+1 + "/" + dateObj.getDate() + "/" + dateObj.getFullYear()) : "")
                    }
                },
                editor: {
                    type: Type.DATE,
                    defaultValue: Date.now(),
                    minDateValue: Date.now(),
                },
            },
            {
                dataField: 'isSuspended',
                text: 'Suspended',
                editable : this.state.userinfo.roleId==="1" ? false : true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'10%'}},
                style:{textAlign:'center',width:'10%'},
                formatter: (cell, row) => {
                    if(row.isSuspended!='' && row.isSuspended!=null){
                        if(this.state.yesNoDDL.filter(x => x.value == cell).length==0)
                        return ""
                        else
                            return this.state.yesNoDDL.find(x => x.value == cell).label;
                    }
                },
                editor: {
                    type: Type.SELECT,
                    options: this.state.yesNoDDL
                }
            },
            {
                dataField: 'fileName',
                text: 'FILE',
                editable : false,
                editable:this.state.userinfo.roleId==="1" ? true : false,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'10%'}
                },
                style:{textAlign:'center',width:'10%'},
            },
            {
            dataField: 'action',
            text: 'ACTION',
            editable:false,
            headerStyle: (colum, colIndex) => {
                return { textAlign: 'center',width:'10%',whiteSpace:'nowrap'}
            },
            style:{textAlign:'center',width:'10%',whiteSpace:'nowrap'},
            formatter   :   (cell, row, isSelect) => {
                if(this.state.userinfo.roleId==="1")
                return (<div>
                    <Button disabled onClick={this.handleAttachedClick(row)} style={{height:'18px',paddingTop:'0',marginTop:'-4px',fontSize:'11px'}} 
                        variant="link">
                        ATTACH FILE
                    </Button>
                    <Form.Control ref={input => this.inputElement = input} style={{display: 'none'}} type="file" accept='.pdf' variant='link' onChange={this.SetFile(row.id)} />
                    </div>
                );
                }
            }    
        ] 
    
        const requiredDocsSelectRow = {
            mode: 'checkbox',
            clickToSelectAndEditCell: true,
            onSelect: (row, isSelect, rowIndex, e) => {
                this.state.requiredDocsDG.map(function(item,i){
                    if(item.id===row.id)
                        {
                        item.isDeleted = isSelect ? "1" : "0"
                        item.isModified = isSelect ? "1" : "0"
                    }
                })

            }
        };
        const requiredDocsRowEvents = {
        onClick: (e, row, rowIndex) => {

        }
        };
        return(
            <div>
                <Banner />
                    <Container className="mt-5" fluid>
                        <Card>
                            <Card.Header>ADMINISTRATOR >> ADMIN >> JOB OFFER - (APPROVE / REJECT)</Card.Header>
                            <Card.Body  className="mb-3">
                                <Form >
                                    <Alert isOpen={this.state.isshow} color={this.state.color} fade={this.state.fade} className={this.state.isshow ? 'display-block' : 'display-none'}>
                                        <div className={this.state.color}></div> 
                                        {this.state.message}
                                    </Alert>
                                    <Tabs className="mt-2" defaultActiveKey="default" transition={false} id="noanim-tab-example">
                                        <Tab eventKey="default" title="APPLICANT INFORMATION">
                                        <Card className="card-tab">
                                            <div className="card-header-tab"></div>
                                            <Card.Body>
                                            <Form.Group as={Row} controlId="formPlaintextEmail">
                                                <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                MEMBERSHIP DATE
                                                </Form.Label>
                                                <Col sm="3">
                                                    <DatePicker
                                                        ref='dateHired'
                                                        selected={this.state.dateHired}
                                                        onChange={this.handleChangedDH}
                                                        minDate={this.minDate}
                                                        value={this.state.dateHired}
                                                        dateFormat={"MM/dd/yyyy"}
                                                        className="form-control"
                                                        disabled={this.state.disablecontrol}
                                                    />
                                                </Col>
                                                <Col sm="2"> 
                                                </Col>
                                            </Form.Group>
                                            <Form.Group as={Row} controlId="formPlaintextEmail">
                                                <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                TITLE
                                                </Form.Label>
                                                <Col sm="3">
                                                    <Form.Control
                                                        as="select"
                                                        onChange={this.handleChangedTitle}
                                                        value={this.state.title}
                                                        disabled={this.state.disablecontrol}
                                                    >
                                                    {this.state.titleDDL.map(item => (
                                                        <option key={item.value} value={item.value}>
                                                        {item.name}
                                                        </option>
                                                    ))}
                                                    </Form.Control>
                                                </Col>
                                                <Col sm="2"> 
                                                </Col>
                                            </Form.Group>
                                            <Form.Group as={Row} controlId="formPlaintextEmail">
                                                <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                FIRST NAME
                                                </Form.Label>
                                                <Col sm="3">
                                                    <Form.Control 
                                                        type="text"
                                                        id="firstName"
                                                        name="firstName"
                                                        value={this.state.firstName}
                                                        onChange={this.handleChanged}
                                                        disabled={this.state.disablecontrol}
                                                    /> 
                                                </Col>
                                                <Col sm="2">
                                                </Col>
                                                <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                MIDDLE NAME
                                                </Form.Label>
                                                <Col sm="3">
                                                    <Form.Control 
                                                        type="text"
                                                        id="middleName"
                                                        name="middleName"
                                                        value={this.state.middleName}
                                                        onChange={this.handleChanged}
                                                        disabled={this.state.disablecontrol}
                                                    />
                                                </Col>
                                            </Form.Group>
                                            <Form.Group as={Row} controlId="formPlaintextEmail">
                                            <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                LAST NAME
                                                </Form.Label>
                                                <Col sm="3">
                                                    <Form.Control 
                                                        type="text"
                                                        id="lastName"
                                                        name="lastName"
                                                        value={this.state.lastName}
                                                        onChange={this.handleChanged}
                                                        disabled={this.state.disablecontrol}
                                                    />
                                                </Col>
                                                <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                                    SUFFIX NAME
                                                </Form.Label>
                                                <Col sm="1">
                                                    <Form.Control
                                                        as="select"
                                                        onChange={this.handleChangedSuffixName}
                                                        value={this.state.suffixName}
                                                    >
                                                    {this.state.suffixDDL.map(item => (
                                                        <option key={item.value} value={item.value}>
                                                        {item.name}
                                                        </option>
                                                    ))}
                                                    </Form.Control>
                                                </Col>
                                                <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                NICK NAME
                                                </Form.Label>
                                                <Col sm="3">
                                                    <Form.Control 
                                                        type="text"
                                                        id="nickName"
                                                        name="nickName"
                                                        value={this.state.nickName}
                                                        onChange={this.handleChanged}
                                                        disabled={this.state.disablecontrol}
                                                    />
                                                </Col>
                                            </Form.Group>
                                            <Form.Group as={Row} controlId="formPlaintextEmail">
                                                <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                ADDRESS TYPE
                                                </Form.Label>
                                                <Col sm="3">
                                                    <Form.Control
                                                        as="select"
                                                        onChange={this.handleChangedAddressType}
                                                        value={this.state.addresstypeId}
                                                        disabled={this.state.disablecontrol}
                                                    >
                                                    {this.state.typeDDL.map(item => (
                                                        <option key={item.value} value={item.value}>
                                                        {item.name}
                                                        </option>
                                                    ))}
                                                    </Form.Control>
                                                </Col>
                                                <Col sm="2">
                                                </Col>
                                                <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                REGION
                                                </Form.Label>
                                                <Col sm="3">
                                                    <Typeahead
                                                        labelKey='name'
                                                        id="regionId"
                                                        name="regionId"
                                                        onChange={this.handleChangedRegion}
                                                        options={this.state.regionDDL}
                                                        placeholder="Select Region"
                                                        selected={[this.state.region]}
                                                        disabled={this.state.disablecontrol}
                                                    />
                                                </Col>
                                            </Form.Group>
                                            <Form.Group as={Row} controlId="formPlaintextEmail">
                                                <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                PROVINCE
                                                </Form.Label>
                                                <Col sm="3">
                                                    <Typeahead
                                                        ref='provinceref'
                                                        labelKey='name'
                                                        id="basic-example"
                                                        onChange={this.handleChangedProvince}
                                                        options={this.state.provinceDDL}
                                                        placeholder="Select Province"
                                                        selected={[this.state.province]}
                                                        disabled={this.state.disablecontrol}
                                                    />
                                                </Col>
                                                <Col sm="2">
                                                </Col>
                                                <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                CITY/MUNICIPALITY
                                                </Form.Label>
                                                <Col sm="3">
                                                    <Typeahead    
                                                        ref='cityref'                              
                                                        labelKey='name'
                                                        id="basic-examplex"
                                                        onChange={this.handleChangedCity}
                                                        options={this.state.cityDDL}
                                                        placeholder="Select City"
                                                        selected={[this.state.city]}
                                                        disabled={this.state.disablecontrol}
                                                    />
                                                </Col>
                                            </Form.Group>
                                            <Form.Group as={Row} controlId="formPlaintextEmail">
                                                <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                HOUSE NUMBER
                                                </Form.Label>
                                                <Col sm="3">
                                                    <Form.Control 
                                                        type="text"
                                                        id="houseNumber"
                                                        name="houseNumber"
                                                        value={this.state.houseNumber}
                                                        onChange={this.handleChanged}
                                                        disabled={this.state.disablecontrol}
                                                    />
                                                </Col>
                                                <Col sm="2">
                                                </Col>
                                                <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                STREET
                                                </Form.Label>
                                                <Col sm="3">
                                                    <Form.Control 
                                                        type="text"
                                                        id="street"
                                                        name="street"
                                                        value={this.state.street}
                                                        onChange={this.handleChanged}
                                                        disabled={this.state.disablecontrol}
                                                    />
                                                </Col>
                                            </Form.Group>
                                            <Form.Group as={Row} controlId="formPlaintextEmail">
                                                <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                BARANGAY
                                                </Form.Label>
                                                <Col sm="3">
                                                    <Form.Control 
                                                        type="text"
                                                        id="barangay"
                                                        name="barangay"
                                                        value={this.state.barangay}
                                                        onChange={this.handleChanged}
                                                        disabled={this.state.disablecontrol}
                                                    />
                                                </Col>
                                                <Col sm="2">
                                                </Col>
                                                <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                POSTAL CODE
                                                </Form.Label>
                                                <Col sm="3">
                                                    <Form.Control 
                                                        type="text"
                                                        id="postalCode"
                                                        name="postalCode"
                                                        value={this.state.postalCode}
                                                        onChange={this.handleChanged}
                                                        disabled={this.state.disablecontrol}
                                                    />
                                                </Col>
                                            </Form.Group>
                                            <Form.Group as={Row} controlId="formPlaintextEmail">
                                                <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                HOME PHONE NUMBER
                                                </Form.Label>
                                                <Col sm="3">
                                                    <Form.Control 
                                                        type="text"
                                                        id="homephoneNumber"
                                                        name="homephoneNumber"
                                                        value={this.state.homephoneNumber}
                                                        onChange={this.handleChanged}
                                                        disabled={this.state.disablecontrol}
                                                    />
                                                </Col>
                                                <Col sm="2">
                                                </Col>
                                                <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                OFFICE PHONE NUMBER
                                                </Form.Label>
                                                <Col sm="3">
                                                    <Form.Control 
                                                        type="text"
                                                        id="officephoneNumber"
                                                        name="officephoneNumber"
                                                        value={this.state.officephoneNumber}
                                                        onChange={this.handleChanged}
                                                        disabled={this.state.disablecontrol}
                                                    />
                                                </Col>
                                            </Form.Group>
                                            <Form.Group as={Row} controlId="formPlaintextEmail">
                                                <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                MOBILE NUMBER
                                                </Form.Label>
                                                <Col sm="3">
                                                    <NumberFormat
                                                        id="mobileNumber"
                                                        name="mobileNumber"
                                                        value={this.state.mobileNumber}
                                                        format="####-###-####"
                                                        autoComplete="off"
                                                        className="form-control"
                                                        onChange={this.handleChanged}
                                                        disabled={this.state.disablecontrol}
                                                    />
                                                </Col>
                                                <Col sm="2">
                                                </Col>
                                                <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                EMAIL ADDRESS
                                                </Form.Label>
                                                <Col sm="3">
                                                    <Form.Control 
                                                        type="text"
                                                        id="emailAddress"
                                                        name="emailAddress"
                                                        value={this.state.emailAddress}
                                                        autoComplete="off"
                                                        onChange={this.handleChanged}
                                                        disabled={this.state.disablecontrol}
                                                    />
                                                </Col>
                                            </Form.Group>
                                            <Form.Group as={Row} controlId="formPlaintextEmail">
                                                <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                FACEBOOK
                                                </Form.Label>
                                                <Col sm="3">
                                                    <Form.Control 
                                                        type="text"
                                                        id="facebook"
                                                        name="facebook"
                                                        value={this.state.facebook}
                                                        autoComplete="off"
                                                        onChange={this.handleChanged}
                                                        disabled={this.state.disablecontrol}
                                                    />
                                                </Col>
                                                <Col sm="2">
                                                </Col>
                                                <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                TWITTER
                                                </Form.Label>
                                                <Col sm="3">
                                                    <Form.Control 
                                                        type="text"
                                                        id="twitter"
                                                        name="twitter"
                                                        value={this.state.twitter}
                                                        autoComplete="off"
                                                        onChange={this.handleChanged}
                                                        disabled={this.state.disablecontrol}
                                                    />
                                                </Col>
                                            </Form.Group>
                                            <Form.Group as={Row} controlId="formPlaintextEmail">
                                                <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                NATIONALITY
                                                </Form.Label>
                                                <Col sm="3">
                                                    <Typeahead
                                                        labelKey='name'
                                                        id='nationalityId'
                                                        name='nationalityId'
                                                        options={this.state.nationalityDDL}
                                                        onChange={this.handleChangedNationality}
                                                        selected={[this.state.nationality]}
                                                        disabled={this.state.disablecontrol}
                                                    />
                                                </Col>
                                                <Col sm="2">
                                                </Col>
                                                <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                RELIGION
                                                </Form.Label>
                                                <Col sm="3">
                                                    <Typeahead
                                                        labelKey='name'
                                                        id='religionId'
                                                        name='religionId'
                                                        options={this.state.religionDDL}
                                                        onChange={this.handleChangedReligion}
                                                        selected={[this.state.religion]}
                                                        disabled={this.state.disablecontrol}
                                                    />
                                                </Col>
                                            </Form.Group>
                                            <Form.Group as={Row} controlId="formPlaintextEmail">
                                                <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                GENDER
                                                </Form.Label>
                                                <Col sm="3">
                                                    <Typeahead
                                                        labelKey='name'
                                                        name="genderId"
                                                        id="genderId"
                                                        options={this.state.genderDDL}
                                                        onChange={this.handleChangedGender}
                                                        selected={[this.state.gender]}
                                                        disabled={this.state.disablecontrol}
                                                    />
                                                </Col>
                                                <Col sm="2">
                                                </Col>
                                                <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                CIVIL STATUS
                                                </Form.Label>
                                                <Col sm="3">
                                                    <Typeahead
                                                        labelKey='name'
                                                        id='civilstatusId'
                                                        name='civilstatusId'
                                                        options={this.state.civilstatusDDL}
                                                        onChange={this.handleChangedCivilStatus}
                                                        selected={[this.state.civilstatus]}
                                                        disabled={this.state.disablecontrol}
                                                    />
                                                </Col>
                                            </Form.Group>
                                            <Form.Group as={Row} controlId="formPlaintextEmail">
                                                <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                DATE OF BIRTH
                                                </Form.Label>
                                                <Col sm="3">
                                                    <DatePicker
                                                        ref='dateofBirth'
                                                        selected={this.state.dateofBirth}
                                                        onChange={this.handleChangedDOB}
                                                        minDate={this.minDate}
                                                        value={this.state.dateofBirth}
                                                        dateFormat={"MM/dd/yyyy"}
                                                        className="form-control"
                                                        disabled={this.state.disablecontrol}
                                                    />
                                                    <Form.Label column sm="0" style={{width:'100px',fontWeight : "bold",textAlign:'right'}}>
                                                    AGE :
                                                    </Form.Label>
                                                    <Form.Label column sm="0" style={{marginLeft:'4px',fontWeight : "bold",color:'blue',textAlign:'right'}}>
                                                        {this.state.age}
                                                    </Form.Label>
                                                </Col>
                                                <Col sm="2">
                                                </Col>
                                                <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                PLACE OF BIRTH
                                                </Form.Label>
                                                <Col sm="3">
                                                    <Form.Control 
                                                        type="text"
                                                        id="placeofBirth"
                                                        name="placeofBirth"
                                                        value={this.state.placeofBirth}
                                                        onChange={this.handleChanged}
                                                        disabled={this.state.disablecontrol}
                                                    />
                                                </Col>
                                            </Form.Group>
                                            <Form.Group as={Row} controlId="formPlaintextEmail">
                                                <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                LIVING ARRANGEMENT
                                                </Form.Label>
                                                <Col sm="3">
                                                    <Typeahead
                                                        labelKey='name'
                                                        id='arrangementId'
                                                        name='arrangementId'
                                                        options={this.state.arrangementDDL}
                                                        onChange={this.handleChangedArrangement}
                                                        selected={[this.state.arrangement]}
                                                        disabled={this.state.disablecontrol}
                                                    />
                                                </Col>
                                                <Col sm="2">
                                                </Col>
                                                <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                BLOOD TYPE
                                                </Form.Label>
                                                <Col sm="3">
                                                    <Typeahead
                                                        labelKey='name'
                                                        id='bloodtypeId'
                                                        name='bloodtypeId'
                                                        options={this.state.bloodtypeDDL}
                                                        onChange={this.handleChangedBloodType}
                                                        selected={[this.state.bloodtype]}
                                                        disabled={this.state.disablecontrol}
                                                    />
                                                </Col>
                                            </Form.Group>
                                            <Form.Group as={Row} controlId="formPlaintextEmail">
                                                <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                HEIGHT
                                                </Form.Label>
                                                <Col sm="3">
                                                    <Form.Control 
                                                        type="text"
                                                        id="height"
                                                        name="height"
                                                        value={this.state.height}
                                                        onChange={this.handleChanged}
                                                        disabled={this.state.disablecontrol}
                                                    />
                                                </Col>
                                                <Col sm="2">
                                                </Col>
                                                <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                WEIGHT
                                                </Form.Label>
                                                <Col sm="3">
                                                    <Form.Control 
                                                        type="text"
                                                        id="weight"
                                                        name="weight"
                                                        value={this.state.weight}
                                                        onChange={this.handleChanged}
                                                        disabled={this.state.disablecontrol}
                                                    />
                                                </Col>
                                            </Form.Group>
                                            <Form.Group as={Row} controlId="formPlaintextEmail">
                                                <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                LANGUAGE SPOKEN
                                                </Form.Label>
                                                <Col sm="3">
                                                    <Form.Control 
                                                        type="text"
                                                        id="languageSpoken"
                                                        name="languageSpoken"
                                                        value={this.state.languageSpoken}
                                                        onChange={this.handleChanged}
                                                        disabled={this.state.disablecontrol}
                                                    />
                                                </Col>
                                                <Col sm="2">
                                                </Col>
                                                <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                HOBBIES
                                                </Form.Label>
                                                <Col sm="3">
                                                    <Form.Control 
                                                        type="text"
                                                        id="hobbies"
                                                        name="hobbies"
                                                        value={this.state.hobbies}
                                                        onChange={this.handleChanged}
                                                        disabled={this.state.disablecontrol}
                                                    />
                                                </Col>
                                            </Form.Group>
                                            <Form.Group as={Row} controlId="formPlaintextEmail">
                                                <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                EMERGENCY CONTACT NAME
                                                </Form.Label>
                                                <Col sm="3">
                                                    <Form.Control 
                                                        type="text"
                                                        id="emergencycontactName"
                                                        name="emergencycontactName"
                                                        value={this.state.emergencycontactName}
                                                        onChange={this.handleChanged}
                                                        disabled={this.state.disablecontrol}
                                                    />
                                                </Col>
                                                <Col sm="2">
                                                </Col>
                                                <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                EMERGENCY CONTACT NUMBER
                                                </Form.Label>
                                                <Col sm="3">
                                                    <Form.Control 
                                                        type="text"
                                                        id="emergencycontactNumber"
                                                        name="emergencycontactNumber"
                                                        value={this.state.emergencycontactNumber}
                                                        onChange={this.handleChanged}
                                                        disabled={this.state.disablecontrol}
                                                    />
                                                </Col>
                                            </Form.Group>
                                            <Form.Group as={Row} controlId="formPlaintextEmail">
                                                <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                EMERGENCY CONTACT RELATION
                                                </Form.Label>
                                                <Col sm="3">
                                                    <Form.Control 
                                                        type="text"
                                                        id="emergencycontactRelation"
                                                        name="emergencycontactRelation"
                                                        value={this.state.emergencycontactRelation}
                                                        onChange={this.handleChanged}
                                                        disabled={this.state.disablecontrol}
                                                    />
                                                </Col>
                                                <Col sm="2">
                                                </Col>
                                            </Form.Group>
                                            <Form.Group as={Row} controlId="formPlaintextEmail">
                                                <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                SSS NUMBER
                                                </Form.Label>
                                                <Col sm="3">
                                                    <NumberFormat
                                                        id="sssNumber"
                                                        name="sssNumber"
                                                        value={this.state.sssNumber}
                                                        autoComplete="off"
                                                        format="##-#######-#"
                                                        className="form-control"
                                                        onChange={this.handleChanged}
                                                        disabled={this.state.disablecontrol}
                                                    />
                                                </Col>
                                                <Col sm="2">
                                                </Col>
                                                <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                TIN NUMBER 
                                                </Form.Label>
                                                <Col sm="3">
                                                    <NumberFormat
                                                        id="tinNumber"
                                                        name="tinNumber"
                                                        value={this.state.tinNumber}
                                                        format="###-###-###-###"
                                                        className="form-control"
                                                        onChange={this.handleChanged}
                                                        disabled={this.state.disablecontrol}
                                                    />
                                                </Col>
                                            </Form.Group>
                                            <Form.Group as={Row} controlId="formPlaintextEmail">
                                                <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                PHIC NUMBER
                                                </Form.Label>
                                                <Col sm="3">
                                                    <NumberFormat
                                                        id="phicNumber"
                                                        name="phicNumber"
                                                        value={this.state.phicNumber}
                                                        onChange={this.handleChanged}
                                                        format="##-#########-#"
                                                        className="form-control"
                                                        disabled={this.state.disablecontrol}
                                                    />
                                                </Col>
                                                <Col sm="2">
                                                </Col>
                                                <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                HDMF NUMBER 
                                                </Form.Label>
                                                <Col sm="3">
                                                    <NumberFormat
                                                        id="hdmfNumber"
                                                        name="hdmfNumber"
                                                        value={this.state.hdmfNumber}
                                                        onChange={this.handleChanged}
                                                        format="####-####-####"
                                                        className="form-control"
                                                        disabled={this.state.disablecontrol}
                                                    />
                                                </Col>
                                            </Form.Group>
                                            </Card.Body>
                                        </Card>
                                        </Tab>
                                        <Tab eventKey="201" title="JOB OFFER DETAILS">
                                            <Card className="card-tab">
                                                <div className="card-header-tab"></div>
                                                <Card.Body>
                                                <Form.Group as={Row} controlId="formPlaintextEmail">
                                                    <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                        CLIENT
                                                    </Form.Label>
                                                    <Col sm="10">
                                                        <Typeahead 
                                                            labelKey='name'
                                                            id='clientId'
                                                            name='clientId'
                                                            options={this.state.clientDDL}
                                                            onChange={this.handleChangedClient}
                                                            selected={[this.state.client]}
                                                            disabled={this.state.disablecontrol}
                                                        />
                                                    </Col>
                                                </Form.Group>
                                                <Form.Group as={Row} controlId="formPlaintextEmail">
                                                    <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                    BRANCH / LOCATION
                                                    </Form.Label>
                                                    <Col sm="3">
                                                        <Typeahead 
                                                            labelKey='name'
                                                            id='locationId'
                                                            name='locationId'
                                                            options={this.state.locationDDL}
                                                            onChange={this.handleChangedLocation}
                                                            selected={[this.state.location]}
                                                            disabled={this.state.disablecontrol}
                                                        />
                                                    </Col>
                                                    <Col sm="2">
                                                    </Col>
                                                    <Col sm="4">
                                                        <Form.Check type="checkbox" 
                                                            label="IS DEFAULT BRANCH" 
                                                            id="isdefaultLocation"
                                                            name="isdefaultLocation"
                                                            onChange={this.handleChangedCB}
                                                            style={{fontWeight : "bold",marginLeft:"2px"}}
                                                            checked={this.state.isdefaultLocation == "1" ? true:false}
                                                            disabled={this.state.disablecontrol}
                                                        />
                                                    </Col>
                                                </Form.Group>
                                                <Form.Group as={Row} controlId="formPlaintextEmail">
                                                    <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                    POSITION
                                                    </Form.Label>
                                                    <Col sm="3">
                                                        <Typeahead
                                                            labelKey='name'
                                                            id='positionId'
                                                            name='positionId'
                                                            options={this.state.positionDDL}
                                                            onChange={this.handleChangedPosition}
                                                            selected={[this.state.position]}
                                                            disabled={this.state.disablecontrol}
                                                        />
                                                    </Col>
                                                    <Col sm="2">
                                                    </Col>
                                                    <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                    EMPLOYMENT STATUS
                                                    </Form.Label>
                                                    <Col sm="3">
                                                        <Typeahead
                                                            labelKey='name'
                                                            id='statustypeId'
                                                            name='statustypeId'
                                                            options={this.state.statustypeDDL}
                                                            onChange={this.handleChangedStatusType}
                                                            selected={[this.state.statustype]}
                                                            disabled={this.state.disablecontrol}
                                                        />
                                                    </Col>
                                                </Form.Group>
                                                <Form.Group as={Row} controlId="formPlaintextEmail">
                                                    <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                    CONTRACT DATE START
                                                    </Form.Label>
                                                    <Col sm="3">
                                                        <DatePicker
                                                            id='contractdateStart'
                                                            name='contractdateStart'
                                                            value={this.state.contractdateStart}
                                                            selected={this.state.contractdateStart}
                                                            dateFormat={"MM/dd/yyyy"}
                                                            className="form-control"
                                                            onChange={this.handleChangedEOS}
                                                            disabled={this.state.disablecontrol}
                                                        />
                                                    </Col>
                                                    <Col sm="2">
                                                    </Col>
                                                    <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                    CONTRACT DATE END
                                                    </Form.Label>
                                                    <Col sm="3">
                                                        <DatePicker
                                                            id='contractdateEnd'
                                                            name='contractdateEnd'
                                                            value={this.state.contractdateEnd}
                                                            selected={this.state.contractdateEnd}
                                                            dateFormat={"MM/dd/yyyy"}
                                                            className="form-control"
                                                            autoComplete="off"
                                                            onChange={this.handleChangedEOC}
                                                            disabled={this.state.disablecontrol}
                                                        />
                                                    </Col>
                                                </Form.Group>
                                                <Form.Group as={Row} controlId="formPlaintextEmail">
                                                    <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                    PAY CARD TYPE
                                                    </Form.Label>
                                                    <Col sm="3">
                                                        <Typeahead
                                                            labelKey='name'
                                                            id='cardtypeId'
                                                            name='cardtypeId'
                                                            options={this.state.cardtypeDDL}
                                                            onChange={this.handleChangedCardType}
                                                            selected={[this.state.cardtype]}
                                                            disabled={this.state.disablecontrol}
                                                        />
                                                    </Col>
                                                    <Col sm="2">
                                                    </Col>
                                                    <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                    PAY CARD NUMBER
                                                    </Form.Label>
                                                    <Col sm="3">
                                                        <Form.Control 
                                                            type="text" 
                                                            id="cardNumber"
                                                            name="cardNumber"
                                                            value={this.state.paycardNumber}
                                                            disabled
                                                        />
                                                    </Col>
                                                </Form.Group>
                                                <Form.Group as={Row} controlId="formPlaintextEmail">
                                                    <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                    PAY TYPE
                                                    </Form.Label>
                                                    <Col sm="3">
                                                        <Typeahead
                                                            labelKey='name'
                                                            id='paytypeId'
                                                            name='paytypeId'
                                                            options={this.state.paytypeDDL}
                                                            onChange={this.handleChangedPayType}
                                                            selected={[this.state.paytype]}
                                                            disabled={this.state.disablecontrol}
                                                        />
                                                    </Col>
                                                    <Col sm="2">
                                                    </Col>
                                                    <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                    PAY MODE
                                                    </Form.Label>
                                                    <Col sm="3">
                                                        <Typeahead
                                                            labelKey='name'
                                                            id='paymodeId'
                                                            name='paymodeId'
                                                            options={this.state.paymodeDDL}
                                                            onChange={this.handleChangedPayMode}
                                                            selected={[this.state.paymode]}
                                                            disabled={this.state.disablecontrol}
                                                        />
                                                    </Col>
                                                </Form.Group>
                                                <Form.Group as={Row} controlId="formPlaintextEmail">
                                                    <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                    SALARY RATE
                                                    </Form.Label>
                                                    <Col sm="3">
                                                        <Form.Control 
                                                            type="text"
                                                            id="salaryRate"
                                                            name="salaryRate"
                                                            value={this.state.salaryRate}
                                                            onChange={this.handleChanged}
                                                            onKeyPress={this.IsDecimal.bind(this)}
                                                            maxLength="13"
                                                            autoComplete="off"
                                                            disabled={this.state.disablecontrol}
                                                        /> 
                                                    </Col>
                                                    <Col sm="2">
                                                    </Col>
                                                    <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                    SEA RATE
                                                    </Form.Label>
                                                    <Col sm="3">
                                                        <Form.Control 
                                                            type="text"
                                                            id="seaRate"
                                                            name="seaRate"
                                                            value={this.state.seaRate}
                                                            onChange={this.handleChanged}
                                                            onKeyPress={this.IsDecimal.bind(this)}
                                                            maxLength="13"
                                                            autoComplete="off"
                                                            disabled={this.state.disablecontrol}
                                                        />
                                                    </Col>
                                                </Form.Group>
                                                <Form.Group as={Row} controlId="formPlaintextEmail">
                                                    <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                    ECOLA RATE
                                                    </Form.Label>
                                                    <Col sm="3">
                                                        <Form.Control 
                                                            type="text"
                                                            id="ecolaRate"
                                                            name="ecolaRate"
                                                            value={this.state.ecolaRate}
                                                            onChange={this.handleChanged}
                                                            onKeyPress={this.IsDecimal.bind(this)}
                                                            maxLength="13"
                                                            autoComplete="off"
                                                            disabled={this.state.disablecontrol}
                                                        /> 
                                                    </Col>
                                                    <Col sm="2">
                                                    </Col>
                                                    <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                    COLA RATE
                                                    </Form.Label>
                                                    <Col sm="3">
                                                        <Form.Control 
                                                            type="text"
                                                            id="colaRate"
                                                            name="colaRate"
                                                            value={this.state.colaRate}
                                                            onChange={this.handleChanged}
                                                            onKeyPress={this.IsDecimal.bind(this)}
                                                            maxLength="13"
                                                            autoComplete="off"
                                                            disabled={this.state.disablecontrol}
                                                        />
                                                    </Col>
                                                </Form.Group>
                                                <Form.Group as={Row} controlId="formPlaintextEmail">
                                                <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                TAX EXEMPTION
                                                </Form.Label>
                                                <Col sm="3">
                                                    <Typeahead
                                                        labelKey='name'
                                                        id="taxexemptiontypeId"
                                                        name="taxexemptiontypeId"
                                                        onChange={this.handleChangedTaxExempt}
                                                        options={this.state.taxexemptionDDL}
                                                        selected={[this.state.taxexemptiontype]}
                                                        disabled={this.state.disablecontrol}
                                                    /> 
                                                </Col>
                                                <Col sm="2"> 
                                                </Col>
                                                <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                TAX TYPE
                                                </Form.Label>
                                                <Col sm="3">
                                                    <Typeahead
                                                        labelKey='name'
                                                        id="taxtypeId"
                                                        name="taxtype"
                                                        onChange={this.handleChangedTaxType}
                                                        options={this.state.taxtypeDDL}
                                                        selected={[this.state.taxtype]}
                                                        disabled={this.state.disablecontrol}
                                                    /> 
                                                </Col>
                                            </Form.Group>
                                            <Form.Group as={Row} controlId="formPlaintextEmail">
                                                <Col sm="2"> 
                                                </Col>
                                                <Col sm="3">
                                                    <Form.Check type="checkbox" 
                                                        style={{fontSize:"10px",fontWeight : "bold"}}
                                                        label="OPTIMIZED BASIC PAY" 
                                                        name="isOBP"
                                                        onChange={this.handleChangedCB}
                                                        checked={this.state.isOBP}
                                                        disabled={this.state.disablecontrol}
                                                    />
                                                </Col>
                                                <Col sm="2"> 
                                                </Col>
                                                <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                ALPHANUMERIC TAX CODE
                                                </Form.Label>
                                                <Col sm="3">
                                                    <Form.Control 
                                                        type="text" 
                                                        //placeholder="ALPHANUMERIC TAX CODE" 
                                                        value={this.state.taxCode}
                                                        onChange={this.handleChanged}
                                                        autoComplete="off" 
                                                        name="taxCode"
                                                        disabled={this.state.disablecontrol}
                                                    />
                                                </Col>
                                            </Form.Group>
                                            <Form.Group as={Row} controlId="formPlaintextEmail">
                                                <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                DEDUCTION BASIS SSS
                                                </Form.Label>
                                                <Col sm="3">
                                                    <Typeahead
                                                        labelKey='name'
                                                        id="deductionSSSId"
                                                        name="deductionSSSId"
                                                        onChange={this.handleChangedSSS}
                                                        options={this.state.deductionsDDL}
                                                        selected={[this.state.deductionSSS]}
                                                        disabled={this.state.disablecontrol}
                                                    /> 
                                                </Col>
                                                <Col sm="2"> 
                                                </Col>
                                                <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                DEDUCTION BASIS HDMF
                                                </Form.Label>
                                                <Col sm="3">
                                                    <Typeahead
                                                        labelKey='name'
                                                        id="deductionHDMFId"
                                                        name="deductionHDMFId"
                                                        onChange={this.handleChangedHDMF}
                                                        options={this.state.deductionsDDL}
                                                        selected={[ this.state.deductionHDMF]}
                                                        disabled={this.state.disablecontrol}
                                                    />
                                                </Col>
                                            </Form.Group>
                                            <Form.Group as={Row} controlId="formPlaintextEmail">
                                                <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                DEDUCTION BASIS PHIC
                                                </Form.Label>
                                                <Col sm="3">
                                                    <Typeahead
                                                        labelKey='name'
                                                        id="deductionPHICId"
                                                        name="deductionPHICId"
                                                        onChange={this.handleChangedPHIC}
                                                        options={this.state.deductionsDDL}
                                                        selected={[ this.state.deductionPHIC]}
                                                        disabled={this.state.disablecontrol}
                                                    />
                                                </Col>
                                                <Col sm="2"> 
                                                </Col>
                                                <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                BASIS FOR 13TH MONTH
                                                </Form.Label>
                                                <Col sm="3">
                                                    <Typeahead
                                                        labelKey='name'
                                                        id="basis13thMonthId"
                                                        name="basis13thMonthId"
                                                        onChange={this.handleChanged13TH}
                                                        options={this.state.basis13thmonthDDL}
                                                        selected={[this.state.basis13thMonth]}
                                                        disabled={this.state.disablecontrol}
                                                    />
                                                </Col>
                                            </Form.Group>
                                            <Form.Group as={Row} controlId="formPlaintextEmail">
                                                <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                WORKING DAYS PER MONTH
                                                </Form.Label>
                                                <Col sm="3">
                                                    <Form.Control 
                                                        type="text" 
                                                        value={this.state.workingdaysperMonth}
                                                        onChange={this.handleChanged}
                                                        autoComplete="off" 
                                                        name="workingdaysperMonth"
                                                        disabled={this.state.disablecontrol}
                                                    />
                                                </Col>
                                                <Col sm="2"> 
                                                </Col>
                                                <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                WORKING DAYS PER YEAR
                                                </Form.Label>
                                                <Col sm="3">
                                                    <Form.Control 
                                                        type="text" 
                                                        value={this.state.workingdaysperYear}
                                                        onChange={this.handleChanged}
                                                        autoComplete="off" 
                                                        name="workingdaysperYear"
                                                        disabled={this.state.disablecontrol}
                                                    />
                                                </Col>
                                            </Form.Group>
                                                </Card.Body>
                                            </Card>
                                        </Tab>
                                    </Tabs>
                                    <Card.Header className="mt-5">EMPLOYEE LIST OF REQUIREMENTS</Card.Header>
                                    <div >
                                        <BootstrapTable
                                            keyField = "id"
                                            data = { this.state.requiredDocsDG }
                                            columns = { requiredDocsColumns }
                                            //pagination={ paginationFactory({sizePerPageRenderer}) }
                                            noDataIndication={ () => <div style={{width:'100%'}}>No record found.</div> }
                                            rowClasses="noser-table-row-class no-checkbox"
                                            striped
                                            hover
                                            condensed
                                            cellEdit = { cellEditFactory({
                                                mode: 'dbclick',
                                                blurToSave: true,
                                                afterSaveCell: (oldValue, newValue, row, column) => {
                                                    this.GridDataModified(oldValue, newValue, row, column.dataField)
                                                    }
                                                })
                                            }
                                            selectRow = { requiredDocsSelectRow }
                                            rowEvents={ requiredDocsRowEvents }
                                        />
                                    </div>
                                    <Form.Group as={Row} controlId="formPlaintextEmail">
                                        <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                        PREPARED BY
                                        </Form.Label>
                                        <Col sm="3">
                                            <Form.Control 
                                                type="text" 
                                                autoComplete="off" 
                                                /* name="selectedClient" */
                                                value={this.state.data.preparedBy}
                                                disabled
                                            /> 
                                        </Col>
                                        <Col sm="2"> 
                                        </Col>
                                        <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                        NOTED BY
                                        </Form.Label>
                                        <Col sm="3">
                                            <Form.Control 
                                                type="text" 
                                                autoComplete="off" 
                                                /* name="selectedClient" */
                                                value={this.state.notedBy}
                                                disabled
                                            />
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} controlId="formPlaintextEmail">
                                        <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                        APPROVED BY
                                        </Form.Label>
                                        <Col sm="3">
                                            <Form.Control 
                                                type="text" 
                                                autoComplete="off" 
                                                /* name="selectedClient" */
                                                value={this.state.approvedBy}
                                                disabled
                                            />
                                        </Col>
                                        <Col sm="2"> 
                                        </Col>
                                        <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                        CONFORME
                                        </Form.Label>
                                        <Col sm="3">
                                            <Form.Control 
                                                type="text" 
                                                //placeholder=" CONFORME" 
                                                autoComplete="off" 
                                                name="conforme"
                                                value={this.state.conforme}
                                                disabled
                                            /> 
                                        </Col>
                                    </Form.Group>
                                    <ButtonToolbar sm={12} className="mt-3">
                                        <Button disabled={this.state.isDisableApprove}  className="ml-auto noser-button-mr1" variant="success" onClick={() => this.handleSubmitClick('2')}>
                                            Approve
                                        </Button>
                                        <Button className="noser-button-mr1" disabled={this.state.isDisableReject} variant="danger" onClick={() => this.handleSubmitClick('3')}>
                                            Reject
                                        </Button>
                                        <NavLink  to="/pendingjoboffer">
                                            <Button className="noser-button"  variant="warning"  href="/pendingjoboffer">
                                                Back
                                            </Button>
                                        </NavLink>
                                    </ButtonToolbar>
                                </Form>
                            </Card.Body>
                        </Card>
                    </Container>
                    <NoserLoading show={this.state.isloading} />
            </div> 
        )
    }

}

export  default PendingJobOfferEdit
