import {
    React,Component,BootstrapTable,
    paginationFactory,Button, ButtonToolbar, Card, 
    Form, Col, Row, axios, Typeahead, TimePicker,
    cellEditFactory ,moment, NavLink, Alert, AppConfiguration,
    NoserLoading, Noser, Banner, Container, DatePicker,  Tabs, Tab
} 
from '../../noser-hris-component';
import NoserGrid from './Modal/PayrollSummaryTable';

class GeneratePayrollPieceRate extends Component {

    constructor(props) {
        super(props)
        this.state = {
            userinfo        :   [],
            isloading       :   false,
            isshow          :   false,
            alerttype       :   "",
            message         :   "",
            color           :   "",
            fade            :   true,

            selectedClientId: "",
            selectedClient:"",
            clientList: [],
            selectedPayrollName:"",
            selectedPayrollId:"",
            payrollList:[],
            employeePayrollListGrid:[],
            selectedGetPayTypesId:'',
            PeriodTypesId : [],
            getEmployeeList : [],
            payPeriodList: [],
            locationList : [],

            isSpecial:'0',

            collectionDate:"",
            payrollDate:"",
            payrollTypeId:"0"
        }

    }

    searchGeneratePayroll = async () => {
        this.setState({isloading:true,
                        alerttype:'',
                        isshow:false,
                        color:'',
                        message:'',
                        fade:true})
                const clientParams = {
                    "IpAddress":"0.0.0.0",
                    "ClientId":this.state.selectedClientId,
                    "UserId":this.state.userinfo.userId,
                    "PayPeriodId": this.state.selectedPayPeriodId,
                    "LocationId": "",
                    "PayrollTypeId":this.state.payrollTypeId
            }
            await axios
                .post(
                    AppConfiguration.Setting().noserapiendpoint + "Payroll/GetEmployeePayrolls", clientParams
                )
                .then(res => {
                    const data = res.data;
                    //console.log("GetEmployeePayrolls");
                    console.log(data);
                    this.setState({
                        employeePayrollListGrid: data.payrolls, isloading:false
                    })
                    if(data.payrolls.length=="0"){
                        this.setState({
                            isloading   :   false,
                            alerttype   :   "Error!",
                            isshow      :   true,
                            color       :   "danger",
                            message     :   data.message,
                            fade        :   true
                        });
                    }
                })
                .catch(error=>{
                    this.setState({
                        isloading   :   false,
                        alerttype   :   "Error!",
                        isshow      :   true,
                        color       :   "danger",
                        message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                        fade        :   true
                    })
                })
    }
    

    /* GetEmployeePayrolls() {
        this.setState({isloading:true,loadingText:"Loading client list..."})
        const clientParams = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.userinfo.clientId,
            "UserId":this.state.userinfo.userId,
	        "PayPeriodId":""
       }
       axios
           .post(
               AppConfiguration.Setting().noserapiendpoint + "Payroll/GetEmployeePayrolls", clientParams
           )
           .then(res => {
               const data = res.data;
               //console.log("GetEmployeePayrolls");
               //console.log(data);
           })
   } */


    componentDidMount(){
        const sleep = (milliseconds) => {return new Promise(resolve => setTimeout(resolve, milliseconds))}
        this.state.userinfo = JSON.parse(sessionStorage.getItem("userData"))
        this.getClient();
        /* this.GetEmployeePayrolls(); */

    }

    getClient() {
        this.setState({isloading:true,})
        const clientParams = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.userinfo.clientId,
            "UserId":this.state.userinfo.userId,
            "EmployeeId":this.state.userinfo.employeeId
       }
       axios
           .post(
               //AppConfiguration.Setting().noserapiendpoint + "Client/GetClientPerAccess", clientParams
               AppConfiguration.Setting().noserapiendpoint + "Client/GetClientList", clientParams
           )
           .then(res => {
               const data = res.data;
               //console.log("GetClientList");
               //console.log(data);
               this.setState({clientList: data.clients.filter(x=>x.payrollTypeId==="3"), isloading:false})
           })
   }
   
    onChangeClientList = (e) => {
        this.setState({alerttype:'',
                        isshow:false,
                        color:'',
                        message:'',
                        fade:true})
        if(e.length == 0) {
            this.state.selectedClientName = ""
            this.state.selectedClientId = ""
            this.state.isSpecial = "0"
            this.state.payrollTypeId = "0"
            return
        }
        this.state.selectedClientId = e[0].id
        this.state.selectedClientName = e[0].name
        this.state.isSpecial = e[0].isSpecial
        this.state.payrollTypeId = e[0].payrollTypeId
        this.GetPayPeriodList(e[0].isSpecial);
    }

    getClientLocation(){
        this.setState({isloading:true})

        const getParams = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.selectedClientId,
            "UserId":this.state.userinfo.userId,
            "ClientName": this.state.selectedClientName,
            "City": "",
            "Province": "",
            "Region": ""
    
        }
        axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Client/GetClientLocations", getParams)
        .then(res => {
            const data = res.data;
            //console.log("Client Location");
            //console.log(data);
            this.setState({locationList : res.data.locations ? res.data.locations : [], isloading:false})
            if(data.locations.length=="0"){
                this.setState({
                    isloading   :   false,
                    alerttype   :   "Error!",
                    isshow      :   true,
                    color       :   "danger",
                    message     :   data.message,
                    fade        :   true
                });
            }
        })
    }

    onChangeLocation = (e) => {
        this.setState({alerttype:'',
                        isshow:false,
                        color:'',
                        message:'',
                        fade:true})
        if(e.length==0)
        {
            this.setState({
                selectedLocationId   :   '',
                selectedLocationName   :   ''
            })
            return
        }
        this.state.selectedLocationName = e[0].name
        this.state.selectedLocationId= e[0].id
    }

    onChangePayPeriod = (e) => {
        this.setState({alerttype:'',
                        isshow:false,
                        color:'',
                        message:'',
                        fade:true})
        if(e.length == 0) {
            this.state.selectedPayPeriodId=""
            this.state.selectedPayPeriodName=""
            return
        } 
        this.state.selectedPayPeriodId = e[0].periodId
        this.state.selectedPayPeriodName = e[0].payPeriod
        this.state.payrollDate = e[0].endDate
        //console.log(this.state.selectedPayPeriodName)

    }
    GetPayPeriodList(isSpecial) {
        const params = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.selectedClientId,
            "UserId":this.state.userinfo.userId,
            "EmployeeId": "",
            "IsProcessed":"0"
         };
         
         axios
             .post(
                 AppConfiguration.Setting().noserapiendpoint + "Timekeeping/GetPayrollPeriods",  params
             )
             .then(res => {
                const data = res.data;
                this.setState({payPeriodList: data.payrollPeriods,isloading:false })
             })
    }

    handleFinalizedClick = async (e) => {

	if (!window.confirm('Do you want to finalize the selected payroll period?'))
            return
        if (!window.confirm('Are you really sure?'))
            return

        this.setState({isloading:true,})
        const generateParams = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.selectedClientId,
            "UserId":this.state.userinfo.userId,
            "PayPeriodId": this.state.selectedPayPeriodId,
            "LocationId": ""
        };

        //console.log(generateParams)

       await axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Payroll/FinalizePayrollPiecerate",  generateParams)
        .then(res => {
            const data = res.data;
                //console.log(data)
                var alerttype = (data.status=="1") ? "success" : "danger"
                this.setState(
                {
                    isloading:false,
                    alerttype:(data.status=="1") ? "Success! " : "Error! ",
                    isshow:true,
                    color:alerttype,
                    message:data.message ,
                    fade:true
                });

                if(data.status==="1"){
                //     let _filterfields = {"clientId" : this.state.clientId, "collectionDate" : deductionGRID[0]["collectionDate"], "payrollDate" : deductionGRID[0]["payrollDate"]}
                //     //let _updateFields = {"isSentToHRIS" : "1", "isSentToHRISDate" : moment(new Date()).format('MM/DD/YYYY')}                //after pull-out by hris    change date to actual date
                //     let _updateFields = {"isPayrollProcessed" : "1", "isPayrollProcessedDate" : moment(new Date()).format('MM/DD/YYYY')}  //after payroll is finalized change date to actual date

                //     const updateParams = {
                //         "_collection"   : "DeductionSummaryHeader",
                //         "filterfields"    : _filterfields,
                //         "updateFields"    : _updateFields,
                //     }
            
                // axios
                //     .post(AppConfiguration.Setting().noserareapi + "action/update_fields",  updateParams )
                //     .then(res => {
                //         console.log("Updated!")
                //     })
                //     .catch(error =>{
                //         console.log("error on add subledger :" + error)
                //     })
                }
            })
            .catch(error=>{
            this.setState(
                {
                isloading:false,
                alerttype:"Error! ",
                isshow:true,
                color:"danger",
                message: "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade:true
            })
        })
        //console.log("Generate Payroll " + this.state.selectedClientName+ " " +this.state.selectedPayPeriodName +".csv")
        /* this.GetEmployeePayrolls() */
    }

   handleGenerateClick = async (e) => {
        
    this.setState({isloading:true,
                    alerttype:'',
                    isshow:false,
                    color:'',
                    message:'',
                    fade:true})
        const generateParams = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.selectedClientId,
            "UserId":this.state.userinfo.userId,
            "PayPeriodId": this.state.selectedPayPeriodId,
            "LocationId": ""
        };
       await axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Payroll/ProcessPayrollPieceRate",  generateParams)
        .then(res => {
            //console.log(res.data)
            const data = res.data;
                //console.log(data)
                if(data.status=="1")
                {
                    this.searchGeneratePayroll();
                }
                else
                {
                //var alerttype = (data.status=="1") ? "success" : "danger"
                this.setState(
                {
                    isloading:false,
                    alerttype:"Error! ",
                    isshow:true,
                    color:"danger",
                    message:data.message ,
                    fade:true
                });
                }
            })
            .catch(error=>{
            this.setState(
                {
                isloading:false,
                alerttype:"Error! ",
                isshow:true,
                color:"danger",
                message: "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade:true
            })
        })

    }
    
    
       
    render() {
        
        return(
            <div>
            <Banner />
            <Container className="themed-container" fluid={true}>
                <Card className="mt-5">
                    <Card.Header>Generate Payroll (Piece Rate)</Card.Header>
                    <Card.Body>
                        <Form>
                            <Alert isOpen={this.state.isshow} color={this.state.color} fade={this.state.fade} className={this.state.isshow ? 'display-block' : 'display-none'}>
                                <div className={this.state.color}></div> 
                                {this.state.message}
                            </Alert>
                            <Form.Group as={Row} controlId="formHorizontalEmail">
                                <Col sm={12}>
                                    <Typeahead
                                        labelKey='name'
                                        id="basic-example"
                                        onChange={this.onChangeClientList}
                                        options={this.state.clientList}
                                        placeholder="Select Client"
                                    />
                                </Col>
                            </Form.Group>
                            {/* <Form.Group as={Row} controlId="formHorizontalEmail">
                                <Col sm={12}>
                                    <Typeahead
                                        labelKey='name'
                                        id="basic-example"
                                        onChange={this.onChangeLocation}
                                        options={this.state.locationList}
                                        placeholder="Select Branch"
                                    />
                                </Col> 
                            </Form.Group>*/}
                            <Form.Group as={Row} controlId="formHorizontalEmail">
                                <Col sm={12}>
                                    <Typeahead
                                        labelKey='payPeriod'
                                        id="basic-example"
                                        onChange={this.onChangePayPeriod}
                                        options={this.state.payPeriodList}
                                        placeholder="Select Payroll Cut-Off"
                                    />
                                </Col>
                            </Form.Group>
                            <ButtonToolbar  >
                                <Button  className="ml-auto noser-button-mr1" variant="success"   onClick = { this.handleGenerateClick }>Generate</Button>
                                <Button className="noser-button" variant="info" onClick = { this.searchGeneratePayroll }>
                                    Search
                                </Button>
                            </ButtonToolbar>
                            <Form.Group  className="mt-4" controlId="formGridPassword">
                                <Card.Header>Payroll Summary (Piece Rate)</Card.Header>
                                <NoserGrid data={this.state.employeePayrollListGrid} 
                                    exportCSV={true}
                                />
                            </Form.Group>
                            <ButtonToolbar sm={12}>
                                <Button variant="success" className="ml-auto noser-button-mr1" onClick = { this.handleFinalizedClick }>
                                    Finalized
                                </Button>
                                <Button className="noser-button" variant="danger" href="/banner" >
                                    Close
                                </Button>
                            </ButtonToolbar>
                        </Form>
                    </Card.Body>
                </Card>
            </Container>
                <NoserLoading show={this.state.isloading} />
        </div>
    );
}

}
export  default GeneratePayrollPieceRate
