import { CardHeader } from 'reactstrap';
import {
    React,Component,BootstrapTable,
    paginationFactory,Button, ButtonToolbar, Card,
    Form, Col, Row, axios, Container, Banner, Typeahead, TimePicker,
    cellEditFactory, Type, moment, NavLink, Alert, AppConfiguration,
    NoserLoading, Noser, sizePerPageRenderer, DatePicker, Modal
}
from '../../../noser-hris-component';
//import SelectScheduleModal from '.TrainingModal/SecondModal/SelectScheduleModal';
const showSecond = false;
const str = showSecond ? 'HH:mm:ss' : 'HH:mm';

class TrainingRequestCreate extends Component {
    constructor(props) {
        super(props)
        this.ref = React.createRef();
        this.state = {
            userinfo    :   JSON.parse(sessionStorage.getItem("userData")),
            isloading   :   false,
            isshow      :   false,
            alerttype   :   "",
            message     :   "",
            color       :   "",
            fade        :   true,
            disableschedule :   true,
            id                          :   "",
            dateFrom                    :   "",
            dateTo                      :   "",
            venue                       :   "",
            facilitated                 :   "",
            selectedTrainingId          :   "",
            trainingAutocomplete        :   [],
            description                 :   "",
            facilitator                 :   "",
            batchNo                     :   "",
            participantLimit            :   "",
            checkIsRequired             :   "",
            partNo                      :   "",
            platform                    :   "",
            TrainingGrid                : [],
            trainingScheduleTableList   :  [],
            employeeName                :   "",
            employeeNo                  :   "",
            clientName                  :   "",
            locationName                :   "",
            positionName                :   "",
            EmployeeName                :   "",
            ReferenceNo                 :   "",
            TrainingSched               :   "",

            trainingId      :   "",
            scheduleId      :   "",
            requestTypeId   :   "",
            addTraining     :   "",
            addTrainingName :   "",

            platformDDL  :    [
                {"name": 'FACE TO FACE ',  "value": "1",},
                {"name":"ONLINE VIA ZOOM	",       "value":"2"},
            ],

            requestDDL : [
                {"name" : "TRAINING", "id" : "1"},
                {"name" : "RE TRAINING", "id" : "2"},
                {"name" : "ORIENTATION", "id" : "3"},
                {"name" : "RE ORIENTATION", "id" : "4"},
                {"name" : "PRE EXAM", "id" : "5"},
                {"name" : "POST EXAM", "id" : "6"},
                {"name" : "EXAM RETAKE", "id" : "7"},
                {"name" : "CHECK BACK", "id" : "8"},
                {"name" : "COMPLETED", "id" : "9"},
            ],
            DisableSubmit   :   true,
        }
    }
    componentDidMount(){
        this.state.userinfo = JSON.parse(sessionStorage.getItem("userData"))
        // // console.log(this.state.userinfo)
        this.state.data = JSON.parse(sessionStorage.getItem("traininglist_" + this.state.userinfo.userId + this.state.userinfo.employeeId))
        this.setState({currentDate     :   moment(new Date).format('MM/DD/YYYY')})
        this.props.onRefModal(this)
        this.GetAllTraining()
        this.GetEmployeeTrainingList()

    }
    componentWillUnmount() {
        this.props.onRefModal(undefined)
    }
    initializeCreate=(e)=>{
        // console.log("modal")
        // console.log(e)
        this.setState({
            userinfo    :   JSON.parse(sessionStorage.getItem("userData")),
            isloading   :   false,
            isshow      :   false,
            alerttype   :   "",
            message     :   "",
            color       :   "",
            fade        :   true,
            name        :   '',
            id          :  e.id,
            DisableSubmit : true,


            profileId       :   e.profileId,
            // employeeName    :   e.employeeName,
            // employeeNo      :   e.employeeNo,
            // clientName      :   e.client,
            // locationName    :   e.locationName,
            // positionName    :   e.position,
            trainingId      :   e.trainingId,
            scheduleId      :   e.scheduleId,
            etrainingId     :   e.id,
            TrainingSchedulesList   :   [],


        })

        // this.state.profileId       =   e.profileId
        this.state.EmployeeName     =   e.employeeName
        this.state.employeeNo       =   e.employeeNo
        this.state.clientName       =   e.clientName
        this.state.locationName     =   e.locationName
        this.state.positionName     =   e.positionName
        this.state.applicationId    =   e.applicationId
        this.state.clientId         =   e.clientId
        // this.getTraining(e.id)
    }
    GetAllTraining = async() => {
        
        this.setState({isloading:true})

        const param = {
            "IpAddress"     :   "0.0.0.0",
            "UserId"        :   this.state.userinfo.userId,
            "ClientId"      :   this.state.clientId,
            "TrainingId"    :   ""
        };
        await axios.post(AppConfiguration.Setting().noserapiendpoint + "Maintenance/GetAllTrainings",  param)
        .then(res => {
            const data = res.data;
            // // console.log("GetTrainings")
            // // console.log(data)
            this.setState({ trainingAutocomplete:data.trainings})
                
        })
        .catch(error=>{
            this.setState({
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade            :   true
            })
        })
        this.setState({isloading:false})
    }
    GetEmployeeTrainingList = async()=> {
        const params = {
            "IpAddress"     :   "0.0.0.0",
            "ClientId"      :   this.state.userinfo.clientId,
            "UserId"        :   this.state.userinfo.userId,
            "ProfileId"     :   this.state.data.profileId,
            "isSuspended"   :   "0"
        };
        // // console.log(params)
        await axios
            .post(AppConfiguration.Setting().noserapiendpoint + "Training/GetEmployeeTrainings",  params)
            .then(res => {
            const data = res.data;
            // console.log("data.employeeTrainings")
            // console.log(data.employeeTrainings[0].employeeNo)
            this.setState({TrainingGrid:data.employeeTrainings/* .filter( x => x.isSuspended === "0" ) */,isloading:false, ReferenceNo : data.employeeTrainings[0].employeeNo});
            // console.log(data)
            if(data.status=="0"){
                this.setState({
                    isloading   :   false,
                    alerttype   :   "Error!",
                    isshow      :   true,
                    color       :   "danger",
                    message     :   data.message,
                    fade        :   true
                });
            }
        })
        .catch(error=>{
            this.setState(  {
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade            :   true
            })
        })
    }
    // getTraining(id, scheduleId, trainingId) {
    //     this.setState({isloading:true})
    //     let dateFrom    =   moment(this.state.dateFrom).format('MM/DD/YYYY');
    //     let dateTo      =   moment(this.state.dateTo).format('MM/DD/YYYY');
    //     // this.setState({isloading:true})

    //     const params = {
    //         "IpAddress"     :   "0.0.0.0",
    //         "UserId"        :   this.state.userinfo.userId,
    //         "ClientId"      :   this.state.userinfo.clientId,
    //         "PositionId"    :   "",
    //         "TrainingId"    :   trainingId,
    //         // "ScheduleId"    :   scheduleId,
    //     };

    //     // console.log("params")
    //     // console.log(params)

    //     axios
    //     // .post(AppConfiguration.Setting().noserapiendpoint + "Training/ReScheduleEmployeeTraining",  params)
    //     .post(AppConfiguration.Setting().noserapiendpoint + "Training/GetTrainingSchedules",  params)
    //     .then(res => {
    //         const data = res.data;
    //         // // console.log("Training/ReScheduleEmployeeTraining")
    //         // // console.log(data)
    //         this.setState({ trainingScheduleTableList     :   data.trainingSchedules.filter( x => x.trainingId === this.state.trainingId ), isloading : false,  })
    //         if(data.status=="0"){
    //             this.setState({
    //                 isloading   :   false,
    //                 alerttype   :   "Error!",
    //                 isshow      :   true,
    //                 color       :   "danger",
    //                 message     :   data.message,
    //                 fade        :   true
    //             });

    //         }

    //     })
    //     .catch(error=>{
    //         this.setState({
    //             isloading       :   false,
    //             alerttype       :   "Error!",
    //             isshow          :   true,
    //             color           :   "danger",
    //             message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
    //             fade            :   true
    //         })
    //     })
    // }
    handleModalClose = (e) => {
        this.props.onHide(e);
        // console.log("closed modal reschedule")

    }
    handleAddTraining = () =>{
        
        const saveParams = {
            "IpAddress"         :   "0.0.0.0",
            "ClientId"          :   this.state.userinfo.clientId,
            "UserId"            :   this.state.userinfo.userId,
            "employeeTrainings":[
                {
                    "ApplicationId":this.state.applicationId, 
                    "ReferenceNo":this.state.ReferenceNo, 
                    "RequestTypeId":"1", 
                    "ProfileId":this.state.employeeNo,
                    "TrainingId":this.state.addTraining,
                    "TrainingName":this.state.addTrainingName,
                    "ScheduleId":"0", //this.state.TrainingSched,
                    "DateSubmitted":this.state.currentDate,
                    "IsRequired":"1",
                    "IsSuspended":"0",
                }
            ]
        }

        // console.log("Save Params")
        // console.log(saveParams)
        axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Training/AddEmployeeTraining",  saveParams)
        .then(res => {
            const data = res.data;
            // console.log("data")
            // console.log(data)
            this.GetEmployeeTrainingList()
            if(data.status=="1"){
                this.setState({
                    isloading   :   false,
                    alerttype   :   "Success!",
                    isshow      :   true,
                    color       :   "success",
                    message     :   data.message,
                    fade        :   true
                });
                // this.GetEmployeeTrainingList()
            }
            else{
                this.setState({
                    isloading   :   false,
                    alerttype   :   "Error!",
                    isshow      :   true,
                    color       :   "danger",
                    message     :   data.message,
                    fade        :   true
                })
            }
         })
         .catch(error=>{
            this.setState(  {
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade        :   true
            })
        })
    }
    GetTrainingSchedule = async(trainingid) => {
        const params = {
            "IpAddress"     :   "0.0.0.0",
            "UserId"        :   this.state.userinfo.userId,
            "ClientId"      :   this.state.userinfo.clientId,
            "PositionId"    :   "",
            "TrainingId"    :   trainingid,
            // "ScheduleId"    :   scheduleId,
        };
        await axios
            .post(AppConfiguration.Setting().noserapiendpoint + "Training/GetTrainingSchedules",  params)
            .then(res => {
            const data = res.data;
            // console.log("data training schedule")
            // console.log(data)
            this.setState({TrainingSchedulesList:data.trainingSchedules  /* .filter( x => x.isSuspended === "0" ) */,isloading:false});
            // // console.log(data)
            if(data.status=="0"){
                this.setState({
                    isloading   :   false,
                    alerttype   :   "Error!",
                    isshow      :   true,
                    color       :   "danger",
                    message     :   data.message,
                    fade        :   true
                });
            }
        })
        .catch(error=>{
            this.setState(  {
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade            :   true
            })
        })
    }
    handleSubmit = () =>{

        this.setState({isloading:true})

        const params = {
            "IpAddress"     :   "0.0.0.0",
            "UserId"        :   this.state.userinfo.userId,
            "ClientId"      :   this.state.userinfo.clientId,
            "PositionId"    :   "",
            "TrainingId"    :   this.state.trainingId,
            "ScheduleId"    :   this.state.scheduleId === "" ? "0" : this.state.scheduleId,
        };

        // console.log("params Submit")
        // console.log(params)
        axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Training/ReScheduleEmployeeTraining",  params)
        .then(res => {
            const data = res.data;
            // console.log("Training/ReScheduleEmployeeTraining")
            // console.log(data)
            // this.setState({ trainingScheduleTableList     :   data.trainingSchedules, isloading : false })
            this.setState({
                TrainingGrid:data.trainingSchedules,
                isloading:false
            });

            if(data.status=="0"){
                this.setState({
                    isloading   :   false,
                    alerttype   :   "Error!",
                    isshow      :   true,
                    color       :   "danger",
                    message     :   data.message,
                    fade        :   true
                });

            }

        })
        .catch(error=>{
            this.setState({
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade            :   true
            })
        })
        this.props.onHide(this.state.trainingScheduleTableList.filter(x=>x.isSelect==="1"))
    }
    onChangeRequest = (e) => {
        if(e.length == 0) {
            this.state.requestTypeId	=   ""
            return
        }
        this.state.requestTypeId	    =   e[0].id
        // // console.log(this.state.addTraining === "")
    }
    onChangeAddTraining = (e) => {
        let disableschedule = true
        if(e.length == 0) {
            this.state.addTraining	=   ""
            this.state.addTrainingName	=   ""
            this.state.disableschedule = true
            return
        }
        this.state.addTraining	    =   e[0].id
        this.state.addTrainingName =   e[0].name
        disableschedule = false
        this.setState({ disableschedule : disableschedule})
        let trainingid = e[0].id
        this.GetTrainingSchedule(trainingid)
        if ( trainingid === "" || trainingid.length === 0){
            this.setState({ DisableSubmit : true })
        }else{
            this.setState({ DisableSubmit : false })
        }
        // // console.log("this.state.addTraining")
        // // console.log(this.state.addTraining)
    }
    onChangeSchedule = (e) => {
        if(e.length == 0) {
            this.state.TrainingSched	=   ""
            // this.state.addTrainingName	=   ""
            // this.state.disableschedule = true
            return
        }
        this.state.TrainingSched =   e[0].id
        // // console.log(e)
    }
    render() {
        const trainingColumn = [
            {
                dataField: 'training',
                text: 'Training',
                editable : false,
                headerStyle : () => {
                    return { width  : "50%" };
                }
            },
            {
                dataField   : 'required',
                text        : 'Type',
                editable : false,
                headerStyle : () => {
                    return { width  : "50%" };
                }
            },
            // {
                // dataField   : 'toDate',
                // text        : 'Training Schedule',
                // editable : false,
                // headerStyle : () => {
                    // return { width  : "25%" };
                // }
            // },
            // {
                // dataField   : '',
                // text        : 'Select Schedule',
                // editable : false,
                // headerStyle : () => {
                    // return { width  : "25%" };
                // }
            // },
            // {
            //     dataField   : 'toTime',
            //     text        : 'Time To',
            //     editable : false,
            //     headerStyle : () => {
            //         return { width  : "10%" };
            //     }
            // },
            // {
            //     dataField   : 'venue',
            //     text        : 'Venue',
            //     editable : false,
            //     headerStyle : () => {
            //         return { width  : "10%" };
            //     }
            // },
            // {
            //     dataField   : 'facilitator',
            //     text        : 'Facilitator',
            //     editable : false,
            //     headerStyle : () => {
            //         return { width  : "10%" };
            //     },

            // },
            // {
            //     dataField   : 'batchNo',
            //     text        : 'Batch No.',
            //     editable : false,
            //     headerStyle : () => {
            //         return { width  : "10%" };
            //     }
            // },
            // {
            //     dataField   : 'participantLimit',
            //     text        : 'Limit No. of Participants',
            //     editable : false,
            //     headerStyle : () => {
            //         return { width  : "10%" };
            //     }
            // },

        ]

        const selectRow = {
            mode: 'checkbox',
            clickToSelectAndEditCell: true,
            onSelect: (row, isSelect, rowIndex, e) => {
                this.state.trainingScheduleTableList.map(function(item,i){
                    if(item.id===row.id)
                        {
                       // item.isDeleted = isSelect ? "1" : "0"
                        item.isSelect = isSelect ? "1" : "0"
                        // // console.log(isSelect + " " + item.id)
                        }
                    // // console.log(item.id)
                })

            }
        };

        // const rowEvents = {
        //     onClick: (e, row, rowIndex) => {

        //     }
        // };
    return(

            <Modal
                {...this.props}
                return
                size="xl"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                backdrop="static"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        TRAINING REQUEST - CREATE
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container>
                        <Alert isOpen={this.state.isshow} color={this.state.color} fade={this.state.fade} className={this.state.isshow ? 'display-block' : 'display-none'}>
                            <div className={this.state.color}></div>
                            {this.state.message}
                        </Alert>
                        <Form.Group as={Row} controlId="formPlaintextEmail">
                            <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                    CLIENT :
                                </Form.Label>
                                <Col sm="5">
                                    <Form.Control
                                        type="text"
                                        value={this.state.clientName}
                                        disabled
                                    />
                                </Col>
                            <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                LOCATION :
                            </Form.Label>
                            <Col sm="5">
                                <Form.Control
                                    type="text"
                                    value={this.state.locationName}
                                    disabled
                                />
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row} controlId="formPlaintextEmail">
                            <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                    EMPLOYEE :
                                </Form.Label>
                                <Col sm="5">
                                    <Form.Control
                                        type="text"
                                        value={this.state.EmployeeName}
                                        disabled
                                    />
                                </Col>
                            <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                EMPLOYEE NO. :
                            </Form.Label>
                            <Col sm="5">
                                <Form.Control
                                    type="text"
                                    value={this.state.employeeNo}
                                    disabled
                                />
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row} controlId="formPlaintextEmail">
                            <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                    POSITION :
                                </Form.Label>
                                <Col sm="5">
                                    <Form.Control
                                        type="text"
                                        value={this.state.positionName}
                                        disabled
                                    />
                                </Col>
                            <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                DATE :
                            </Form.Label>
                            <Col sm="5">
                                <Form.Control
                                    type="text"
                                    value={this.state.currentDate}
                                    disabled
                                />
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row} controlId="formPlaintextEmail">
                            <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                REQUEST TYPE :
                            </Form.Label>
                            <Col sm="10">
                                <Typeahead
                                    labelKey='name'
                                    id="basic-example"
                                    onChange={this.onChangeRequest}
                                    options={this.state.requestDDL}
                                />
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row} controlId="formPlaintextEmail">
                            <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                ADDI'L TRAINING :
                            </Form.Label>
                            <Col sm="10">
                                <Typeahead
                                    labelKey='name'
                                    id="basic-example"
                                    onChange={this.onChangeAddTraining}
                                    options={this.state.trainingAutocomplete}
                                />
                            </Col>
                            {/* <Col sm="1">
                                <Button variant="success" className="float-right" onClick={this.handleAddTraining}>
                                    ADD 
                                </Button> 
                            </Col> */}
                        </Form.Group>

                        {/* <Form.Group as={Row} controlId="formPlaintextEmail">
                            <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                SELECT SCHEDULE :
                            </Form.Label>
                            <Col sm="10">
                                <Typeahead
                                    labelKey='fromDate'
                                    id="basic-example"
                                    onChange={this.onChangeSchedule}
                                    options={this.state.TrainingSchedulesList}
                                    disabled = { this.state.disableschedule}
                                /> 
                            </Col>
                        </Form.Group> */}

                        <Form.Row>
                            <Form.Group as={Col}  >
                                <div className="mt-3">
                                    <CardHeader>TRAINING LIST</CardHeader>
                                    <BootstrapTable
                                        /* caption={Noser.TableHeader({title:"RECORD"})} */
                                        keyField = "id"
                                        data = { this.state.TrainingGrid }
                                        columns = { trainingColumn }
                                        pagination={ paginationFactory({sizePerPageRenderer}) }
                                        noDataIndication={ () => <div style={{width:'100%'}}><b>No record found.</b></div> }
                                        rowClasses="noser-table-row-class no-checkbox"
                                        striped
                                        hover
                                        condensed
                                        cellEdit = { cellEditFactory({
                                            mode: 'dbclick',
                                            blurToSave: true,
                                            afterSaveCell: (oldValue, newValue, row, column) => {
                                                this.GridDataModified(oldValue, newValue, row.id, column.dataField)
                                                }
                                            })
                                        }
                                        // rowEvents={ rowEvents }
                                        selectRow = { selectRow }

                                    />
                                </div>


                                <ButtonToolbar sm={12} className="mt-3">
                                    <Button variant="success" className="ml-auto" disabled = { this.state.DisableSubmit} onClick={ this.handleAddTraining }>
                                        Submit
                                    </Button>&nbsp;&nbsp;
                                    <Button variant="danger" onClick={() => this.handleModalClose('0')}>
                                        Close
                                    </Button>
                                </ButtonToolbar>
                            </Form.Group>

                        </Form.Row>
                    </Container>
                </Modal.Body>
                <Modal.Footer>

                </Modal.Footer>
                <NoserLoading show={this.state.isloading} />
        </Modal>
        );
    }
}
export default TrainingRequestCreate