import {
    React,Component,BootstrapTable,
    paginationFactory,Button, ButtonToolbar, Card, 
    Form, Col, Row, axios, Typeahead, TimePicker,
    cellEditFactory ,moment, NavLink, Alert, AppConfiguration,
    NoserLoading, Noser, Banner, Container, DatePicker,  Tabs, Tab
} 
from '../../noser-hris-component';
import ToolkitProvider, { CSVExport } from 'react-bootstrap-table2-toolkit';
import 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css';
import NoserGrid from './Modal/PayrollSummaryTable'

class ReportPayrollSummary extends Component {
    constructor(props) {
        super(props)
        this.state = {
            userinfo        :   [],
            isloading       :   false,
            isshow          :   false,
            alerttype       :   "",
            message         :   "",
            color           :   "",
            fade            :   true,
            disablereprocess:   false,
            selectedClientId            :   "",
            selectedClient              :   "",
            clientList                  :   [],
            employeeList                :   [],
            multiSelected               :   [],
            payPeriodList               :   [],
            selectedEmployeeId          :   '',
            selectedEmployeeName        :   '',
            cutOffDate                  :   '',
            month                       :   '',
            periodLists                 :   [],
            payrollCardSummaryListTable :   [],
            payrollSummaryListTable     :   [],
            
            payrollOffCycleList         :   [],
            payrollSummaryOffCycleList  :   [],

            payrollAdjustmentList         :   [],
            payrollSummaryAdjustmentList  :   [],

            payrollOtherList         :   [],
            payrollSummaryOtherList  :   [],

            isSpecial:"0",
            payrollTypeId:"0"
        }

    }
    componentDidMount(){
        const sleep = (milliseconds) => {return new Promise(resolve => setTimeout(resolve, milliseconds))}
        this.state.userinfo = JSON.parse(sessionStorage.getItem("userData"))
        this.getClient();

    }
    getClient() {
        this.setState({isloading:true})
        const clientParams = {
            "IpAddress"     :   "0.0.0.0",
            "ClientId"      :   this.state.userinfo.clientId,
            "UserId"        :   this.state.userinfo.userId,
       }
       axios
           .post(
               AppConfiguration.Setting().noserapiendpoint + "Client/GetClientList", clientParams
           )
           .then(res => {
                const data = res.data;
                let arr = []
                if(this.state.userinfo.roleId!=="1"){
                    let fieldaccess = JSON.parse(sessionStorage.getItem("userControlAccess"))
                    if(fieldaccess.filter(x=>x.accessCode==="0001").length>0){
                        let access = fieldaccess.find(x=>x.accessCode==="0001" && x.controlAccessDetails.length>0)
                        console.log("access")
                        console.log(access)
                        if(access.controlAccessDetails.filter(x=>x.isClient==="1").length>0){
                            let brr = access.controlAccessDetails.filter(x=>x.isClient==="1")
                            console.log("brr")
                            console.log(brr)
                            arr = data.clients.filter(f => brr.some(item => item.referenceId === f.id))
                        }
                    }
                    this.setState({clientList : arr, isloading:false})
                }
                else{
                    this.setState({clientList : data.clients, isloading:false})
                }
                //this.setState({clientList:data.clients,isloading:false})
           })
   }

   onChangeClientList = (e) => {
        if(e.length == 0) {
            this.setState({isshow:false,selectedClientId:"",selectedClientName:"",disablereprocess:true,payrollTypeId:"0"})
            return
        }
        this.state.selectedClientId=e[0].id
        this.state.selectedClientName=e[0].name
        this.state.isSpecial = e[0].isSpecial
        this.state.payrollTypeId = e[0].payrollTypeId
        this.GetPayPeriodList(e[0].isSpecial);
        this.getEmployees();
        
    }
    getEmployees(){
        this.setState({isloading:true})
        const getParams = {
            "IpAddress"     :   "0.0.0.0",
            "ClientId"      :   this.state.selectedClientId,
            "UserId"        :   this.state.userinfo.userId,
            "EmployeeName"  :   "",
            "EmployeeNo"    :   "",
            "ClientName"    :   ""
        }
        axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Employee/GetConfigurationEmployees", getParams)
        .then(res => {
            const data = res.data
            if(data.status=="1")
                this.setState({
                    employeeList        :   data.employees,
                    getEmployeeNoList   :   data.employees,
                    isloading           :   false,
                }) 
            else
                this.setState({
                    employeeList        :   [],
                    getEmployeeNoList   :   [],
                    isloading           :   false
                }) 
        })
        .catch(error=>{
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade        :   true
            })
        })
        
    }

    onChangeEmployeesList = (e) => {
        if(e.length==0)
        {
            this.setState({
                getEmployeeListData             :   null,
                disableNoOfIns                  :   true,
                selectedEmployeeLeadTime        :   '',
                selectedEmployeePayMode         :   '',
                selectedEmployeePayModeId       :   '',
                selectedWorkScheduleIdParam     :   '',
                selectedPayrollPeriodsId        :   '',
                selectedEmployeeId              :   '',
                selectedEmployeeName            :   '',
                selectedPayrollPeriodsName      :   '',
                selectedWorkScheduleId          :   ''
            })
            this.GetPayPeriodList();
            return
        }

        this.setState({
            isLoading   :   true
        })

        this.state.selectedEmployeeId           =   e[0].id
        this.state.selectedEmployeePayModeId    =   e[0].payModeId
        this.state.selectedEmployeePayMode      =   e[0].payMode
        this.state.selectedPayrollPeriodsId     =   e[0].payPeriodId
        this.state.selectedEmployeeName         =   e[0].employeeName
        this.state.selectedEmployeeNo           =   e[0].employeeNo
        this.state.selectedEmployeeBranch       =   e[0].locationName
        this.state.selectedEmployeeLeadTime     =   e[0].leadTime
        this.setState({
            isloading   :   false,
            isshow      :   false
        })

        this.GetPayPeriodList();
    
    }

    onChangeEmployeesNo(e){
        this.setState({selectedEmployeeNo:e.target.value})
    }
    onChangeEmployeeBranch(e){
        this.setState({selectedEmployeeBranch:e.target.value})
    }
    GetPayPeriodList = async() => {
        this.setState({isloading:true})
        const params = {
            "IpAddress"     :   "0.0.0.0",
            "ClientId"      :   this.state.selectedClientId,
            "UserId"        :   this.state.userinfo.userId,
            "EmployeeId"    :   this.state.selectedEmployeeId ? this.state.selectedEmployeeId : "",
            "IsProcessed"   :   "1"
         };
         let api = ""
        if(this.state.isSpecial==="0"){
            api = "Timekeeping/GetPayrollPeriods"
        }
        else{
            api = "Timekeeping/GetSpecialPayrollPeriods"
        }
        await axios
             .post(
                AppConfiguration.Setting().noserapiendpoint + api,  params
             )
             .then(res => {
                const data = res.data;
                this.setState({
                    payPeriodList   :   data.payrollPeriods,
                    isloading       :   false 
                })
             })
    }
    onChangePayPeriod = (e) => {
        let periodList = [];
        for(let i = 0; i < e.length; i++){
            const obj = {
                "PayPeriodId" : e[i]["periodId"]
            }
            periodList.push(obj)
            this.setState({
                periodLists : periodList,
                isshow      :   false
            })
        }
    }
    
    //handleSearchClick = () => {
    //    this.GetPayrollSummary()
    //}
    handleSearchClick = async()=>{
        this.setState({isloading:true})
        const params = {
            "IpAddress"     :   "0.0.0.0",
            "ClientId"      :   this.state.selectedClientId,
            "UserId"        :   this.state.userinfo.userId,
            "EmployeeId"    :   this.state.selectedEmployeeId,
            "Periods"       :   this.state.periodLists,
            "LocationId"    :   this.state.selectedLocationId,
            "IsOffCycle": "0",
            "PayrollTypeId" :   this.state.payrollTypeId
        }
        await axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Payroll/GetPayrollSummaryReport", params)
        .then(res => {
            const data = res.data
            this.setState({
                payrollSummaryListTable     :   data.payrolls,
                payrollCardSummaryListTable :   data.paycardSummary,
                //isloading                   :   false,
            })
        
        })
        .catch(error=>{
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade        :   true
            })
        })
	this.GetOffCyclePayrollSummary()
    }
    GetOffCyclePayrollSummary = async() =>{
        //this.setState({isloading:true})
        const getParams = {
            "IpAddress"     :   "0.0.0.0",
            "ClientId"      :   this.state.selectedClientId,
            "UserId"        :   this.state.userinfo.userId,
            "EmployeeId"    :   this.state.selectedEmployeeId,
            "Periods"       :   this.state.periodLists,
            "LocationId"    :   this.state.selectedLocationId,
            "IsOffCycle": "1"
        }
        await axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Payroll/GetPayrollSummaryReport", getParams)
        .then(res => {
            const data = res.data
            this.setState({
                payrollOffCycleList:data.payrolls,
                payrollSummaryOffCycleList:data.paycardSummary,
                //isloading:false,
            })
            this.GetAdjustmentPayrollSummary()
        })
        .catch(error=>{
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade        :   true
            })
        })
    }
    GetAdjustmentPayrollSummary = async() =>{
        //this.setState({isloading:true})
        const getParams = {
            "IpAddress"     :   "0.0.0.0",
            "ClientId"      :   this.state.selectedClientId,
            "UserId"        :   this.state.userinfo.userId,
            "EmployeeId"    :   this.state.selectedEmployeeId,
            "Periods"       :   this.state.periodLists,
            "LocationId"    :   this.state.selectedLocationId,
            "IsOffCycle": "2"
        }
        await axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Payroll/GetPayrollSummaryReport", getParams)
        .then(res => {
            const data = res.data
            this.setState({
                payrollAdjustmentList:data.payrolls,
                payrollSummaryAdjustmentList:data.paycardSummary,
            })
            this.GetPayrollOtherSummary()
        })
        .catch(error=>{
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade        :   true
            })
        })
    }
    GetPayrollOtherSummary = async() =>{
        //this.setState({isloading:true})
        const getParams = {
            "IpAddress"     :   "0.0.0.0",
            "ClientId"      :   this.state.selectedClientId,
            "UserId"        :   this.state.userinfo.userId,
            "EmployeeId"    :   this.state.selectedEmployeeId,
            "Periods"       :   this.state.periodLists,
            "LocationId"    :   this.state.selectedLocationId,
            "IsOffCycle"    :   "0",
            "PayrollTypeId" :   "6"
        }
        await axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Payroll/GetPayrollSummaryReport", getParams)
        .then(res => {
            const data = res.data
            console.log("adj")
            console.log(data)
            this.setState({
                payrollOtherList:data.payrolls,
                payrollSummaryOtherList:data.paycardSummary,
                isloading:false,
            })
        })
        .catch(error=>{
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade        :   true
            })
        })
    }
    handleReProcessClick = async(e) => {

        if(this.state.selectedClientId==""){
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "Please select client.",
                fade        :   true
            })
            return 
        }

        if(this.state.periodLists.length===0){
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "Please select atleast one payroll period.",
                fade        :   true
            })
            return 
        }
        if (!window.confirm('Do you want to re-process ledger transaction?'))
        return
        this.setState({isloading:true,disablereprocess:true})
        const params = {
            "IpAddress"     :   "0.0.0.0",
            "ClientId"      :   this.state.selectedClientId,
            "UserId"        :   this.state.userinfo.userId,
            "EmployeeId"    :   this.state.selectedEmployeeId,
            "PayPeriodId"   :   this.state.periodLists[0]["PayPeriodId"],
            "LocationId"    :   this.state.selectedLocationId,
            "IsOffCycle": "0"
        }
        
        await axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Payroll/ReProcessTransactions", params)
        .then(res => {
            const data = res.data
            this.setState({
                isloading   :   false,
                alerttype   :   data.status=="1" ? "Success!" : "Error!",
                isshow      :   true,
                color       :   data.status=="1" ? "success" : "danger",
                message     :   data.message,
                fade        :   true,
                disablereprocess:false
            })
        })
        .catch(error=>{
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade        :   true
            })
        })
    }
    
    render() {
        //const { ExportCSVButton } = CSVExport;
        const columnPayrollCardSummary = [
            {
                dataField   :   'name',
                text        :   'Pay Card Type',
                editable    :    false,
                headerStyle : () => {
                    return { width  : "50%" };
                }
            },
            {
                dataField   :   'count',
                text        :   'Count',
                editable    :    false,
                headerStyle : () => {
                    return { width  : "50%" };
                }
            },
        ] 
        const colOffCycleSummary = [
            {
                dataField   :   'name',
                text        :   'Pay Card Type',
                editable    :    false,
                headerStyle : () => {
                    return { width  : "50%" };
                }
            },
            {
                dataField   :   'count',
                text        :   'Count',
                editable    :    false,
                headerStyle : () => {
                    return { width  : "50%" };
                }
            },
        ] 
        return(
            <div>
                <Banner />
                <Container className="themed-container" fluid={true}>
                    <Card className="mt-5">
                        <Card.Header>Generate Report Payroll Summary</Card.Header>
                        <Card.Body>
                            <Form>
                                <Alert isOpen={this.state.isshow} color={this.state.color} fade={this.state.fade} className={this.state.isshow ? 'display-block' : 'display-none'}>
                                    <div className={this.state.color}></div> 
                                    {this.state.message}
                                </Alert>
                                <Form.Group as={Row} controlId="formHorizontalEmail">
                                    <Col sm={12}>
                                        <Typeahead
                                            labelKey='name'
                                            id="basic-example"
                                            onChange={this.onChangeClientList}
                                            options={this.state.clientList}
                                            placeholder="Select Client"
                                        />
                                    </Col>
                                </Form.Group>
                                <Form.Row>
                                    <Form.Group as={Col} sm={4} controlId="formGridEmail">
                                        <Typeahead
                                            labelKey='employeeName'
                                            id="basic-example"
                                            onChange={this.onChangeEmployeesList}
                                            options={this.state.employeeList}
                                            placeholder="Select Employee"
                                        />
                                    </Form.Group>
                                    <Form.Group as={Col} sm={4} controlId="formGridPassword">
                                        <Form.Control 
                                            type="text" 
                                            placeholder="Employee No" 
                                            autoComplete="off" 
                                            name="employeeNo"
                                            value={this.state.selectedEmployeeNo}
                                            onChange={this.onChangeEmployeesNo.bind(this)}
                                            readOnly/>
                                    </Form.Group>
                                    <Form.Group as={Col} sm={4} controlId="formGridPassword">
                                        <Form.Control 
                                            type="text" 
                                            placeholder="Employee Branch" 
                                            autoComplete="off" 
                                            name="employeeBranch"
                                            value={this.state.selectedEmployeeBranch}
                                            onChange={this.onChangeEmployeeBranch.bind(this)}
                                            readOnly/>
                                    </Form.Group>
                                </Form.Row>
                                <Form.Group as={Row} controlId="formHorizontalEmail">
                                    <Col sm={12}>
                                        <Typeahead
                                            labelKey='payPeriod'
                                            id="basic-example"
                                            onChange={this.onChangePayPeriod}
                                            options={this.state.payPeriodList}
                                            placeholder="Select Payroll Cut-Off"
                                            multiple
                                            clearButton
                                        />
                                    </Col>
                                </Form.Group>
                                <ButtonToolbar sm={12}>
                                    <Button variant="primary" disabled={this.state.disablereprocess} className="ml-auto noser-button-mr1" onClick = { this.handleReProcessClick }>
                                        REPROCESS LEDGER
                                    </Button>
                                    <Button variant="success" onClick = { this.handleSearchClick }>
                                        SEARCH
                                    </Button>
                                </ButtonToolbar>
                                
                                <Tabs defaultActiveKey="default" transition={false} id="noanim-tab-example">
                                    <Tab eventKey="default" title="PAYROLL SUMMARY">
                                        <Form.Group controlId="formGridPassword">
                                        <NoserGrid
                                            data = { this.state.payrollSummaryListTable }
                                            exportCSV={true}
                                            pageSize={150}
                                        />
                                        </Form.Group>
                                        <div className="mt-5">
                                            <BootstrapTable
                                                rowClasses="noser-table-row-class"
                                                striped
                                                hover
                                                condensed
                                                keyField = "id"
                                                data = { this.state.payrollCardSummaryListTable }
                                                columns = { columnPayrollCardSummary}
                                                cellEdit = { cellEditFactory({ mode: 'dbclick', blurToSave: true }) }
                                            />
                                        </div> 
                                    </Tab>
                                    <Tab eventKey="offcycle" title="PAYROLL SUMMARY (OFF CYCLE)">
                                        <Form.Group controlId="formGridPassword">
                                        <NoserGrid
                                            data = { this.state.payrollOffCycleList }
                                            exportCSV={true}
                                            pageSize={150}
                                        />
                                        </Form.Group>
                                        <div className="mt-5">
                                            <BootstrapTable
                                                rowClasses="noser-table-row-class"
                                                striped
                                                hover
                                                condensed
                                                keyField = "id"
                                                data = { this.state.payrollSummaryOffCycleList }
                                                columns = { colOffCycleSummary}
                                                cellEdit = { cellEditFactory({ mode: 'dbclick', blurToSave: true }) }
                                            />
                                        </div> 
                                    </Tab>
                                    <Tab eventKey="adjustment" title="PAYROLL SUMMARY (ADJUSTMENT)">
                                        <Form.Group controlId="formGridPassword">
                                        <NoserGrid
                                            data = { this.state.payrollAdjustmentList }
                                            exportCSV={true}
                                            pageSize={150}
                                        />
                                        </Form.Group>
                                        <div className="mt-5">
                                            <BootstrapTable
                                                rowClasses="noser-table-row-class"
                                                striped
                                                hover
                                                condensed
                                                keyField = "id"
                                                data = { this.state.payrollSummaryAdjustmentList }
                                                columns = { colOffCycleSummary}
                                                cellEdit = { cellEditFactory({ mode: 'dbclick', blurToSave: true }) }
                                            />
                                        </div> 
                                    </Tab>

                                    <Tab eventKey="other" title="PAYROLL SUMMARY (OTHER)">
                                        <Form.Group controlId="formGridPassword">
                                        <NoserGrid
                                            data = { this.state.payrollOtherList }
                                            exportCSV={true}
                                            pageSize={150}
                                        />
                                        </Form.Group>
                                        <div className="mt-5">
                                            <BootstrapTable
                                                rowClasses="noser-table-row-class"
                                                striped
                                                hover
                                                condensed
                                                keyField = "id"
                                                data = { this.state.payrollSummaryOtherList }
                                                columns = { colOffCycleSummary}
                                                cellEdit = { cellEditFactory({ mode: 'dbclick', blurToSave: true }) }
                                            />
                                        </div> 
                                    </Tab>
                                </Tabs>
                            </Form>
                        </Card.Body>
                    </Card>
                </Container>
                <NoserLoading show={this.state.isloading} />
            </div>
        )
    }


}

export default ReportPayrollSummary;
