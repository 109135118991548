import {
    React,Component,BootstrapTable,
    paginationFactory,Button, ButtonToolbar, Card, 
    Form, Col, Row, axios, Container, Banner, Typeahead, TimePicker,
    cellEditFactory, Type, moment, NavLink, Alert, AppConfiguration,
    NoserLoading, Noser, sizePerPageRenderer
} 
from '../../noser-hris-component';
import { DropdownButton, Dropdown} from 'react-bootstrap';

class DocumentCreate extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fade            :   true, 
            color           :   "",
            isshow          :   false,
            message         :   "",
            userinfo        :   [],
            isloading       :   false,
            alerttype       :   "",
            isDisableClient :   true,
            isDisablePosition:  true,
            disableremovePosition:true,
            disableremoveClient:true,
            isRequired      :   true,

            documentName    :   '',
            clientId        :   '',
            clientName      :   '',
            positionId      :   '',
            positionName    :   '',
            documentCode    :   '',

            clientDDL       :   [],
            clientGrid      :   [],
            positionDDL     :   [],
            positionGrid    :   []
        }
    }

    componentDidMount(){
        const sleep = (milliseconds) => {return new Promise(resolve => setTimeout(resolve, milliseconds))}
        this.state.userinfo = JSON.parse(sessionStorage.getItem("userData"))
        this.GetClients()
        this.GetPositions()
    }
    GetClients = async() => {
        this.setState({isloading:true})
        const params = {
            "IpAddress"     :   "0.0.0.0",
            "ClientId"      :   this.state.userinfo.clientId,
            "UserId"        :   this.state.userinfo.userId,
        };

        await axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Client/GetClientList",  params)
        .then(res => {
            const data = res.data;
            this.setState({
                clientDDL  :   data.clients,
                isloading   :   false
            });
        })
        .catch(error=>{
            this.setState(  {
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade            :   true
            })
        })
    }
    GetPositions = async() => {
        const params = {
            "IpAddress":"0.0.0.0",
            "ClientId"      :   this.state.userinfo.clientId,
            "UserId"        :   this.state.userinfo.userId,
            "SectionId":"",
            "DepartmentId":"",
            "Name":""
        };
        await axios.post(AppConfiguration.Setting().noserapiendpoint + "Maintenance/GetPositions",  params)
        .then(res => {
            const data = res.data;
            this.setState({ positionDDL  : data.positions });
        })
    }
    handleChangeDocumentsName = (e) => {
        this.setState({documentName:e.target.value.toUpperCase(),isshow:false})
    }

    // handleChangeValidity = (e) => {
    //     this.setState({validity:e.target.value.toUpperCase(),isshow:false})
    // }

    handleChangeRequired = (e) =>{
        this.setState({isRequired:e.target.checked})
        if(e.target.name==="mandatory")
        this.setState({isRequired:true})
        else
        this.setState({isRequired:false})
        
    }
    handleCheckClient = (e) => {
        if(!e.target.checked)
            this.setState({isDisableClient:!e.target.checked,clientGrid:[]})
        else
            this.setState({isDisableClient:!e.target.checked})
    }
    handleCheckPosition = (e) => {
        this.setState({isDisablePosition:!e.target.checked})
    }
    handleChangeClient = (e) =>{
        this.setState({
            alerttype   :   "",
            isshow      :   false,
            color       :   "",
            message     :   "",
            fade        :   false
        });
        if(e.length==0)
        {
            this.state.clientId=''
            this.state.clientName=''
            return
        }
        this.state.clientId= e[0].id
        this.state.clientName=e[0].name
    }
    handleChangePosition = (e) =>{
        this.setState({
            alerttype   :   "",
            isshow      :   false,
            color       :   "",
            message     :   "",
            fade        :   false
        });
        if(e.length==0)
        {
            this.state.positionId=''
            this.state.positionName=''
            return
        }
        this.state.positionId= e[0].id
        this.state.positionName=e[0].name
    }
    handleAddClient = (e) =>{
        let clientGrid = this.state.clientGrid

        if(this.state.clientId===""){
            this.setState({
                alerttype   :   "",
                isshow      :   true,
                color       :   "danger",
                message     :   "Please select client.",
                fade        :   false
            });
            return
        }
        var exist = clientGrid.filter(x=>x.clientId==this.state.clientId)
        if(exist.length>0){
            this.setState({
                alerttype   :   "",
                isshow      :   true,
                color       :   "danger",
                message     :   "Selected client already exist.",
                fade        :   false
            });
            return
        }

        let today = new Date()
        let newId = moment(today).format('HHMMSS');
        const obj = {
            "id":newId.toString(),
            "clientId" : this.state.clientId,
            "clientName" : this.state.clientName,
            "isDeleted" : "0",
        }
        clientGrid.push(obj)        
        this.refs.clientRef.clear()
        this.setState({clientGrid:clientGrid,clientId:'',clientName:''})
    }
    handleRemoveClient = (e) =>{
        let clientGrid = this.state.clientGrid.filter(x=>x.isDeleted=="0")
        this.state.clientGrid = []
        this.setState({clientGrid:clientGrid,disableremoveClient: clientGrid.length===0 ? true : false})
    }
    handleAddPosition = (e) =>{
        let positionGrid = this.state.positionGrid

        if(this.state.positionId===""){
            this.setState({
                alerttype   :   "",
                isshow      :   true,
                color       :   "danger",
                message     :   "Please select position.",
                fade        :   false
            });
            return
        }
        var exist = positionGrid.filter(x=>x.positionId==this.state.positionId)
        if(exist.length>0){
            this.setState({
                alerttype   :   "",
                isshow      :   true,
                color       :   "danger",
                message     :   "Selected position already exist.",
                fade        :   false
            });
            return
        }

        let today = new Date()
        let newId = moment(today).format('HHMMSS');
        
        const obj = {
            "id":newId.toString(),
            "positionId" : this.state.positionId,
            "positionName" : this.state.positionName,
            "isDeleted" : "0",
        }
        positionGrid.push(obj)        
        this.refs.positionRef.clear()
        this.setState({positionGrid:positionGrid,positionId:'',positionName:''})
    }
    handleRemovePosition = (e) =>{
        let positionGrid = this.state.positionGrid.filter(x=>x.isDeleted=="0")
        this.setState({positionGrid:positionGrid,disableremovePosition: positionGrid.length===0 ? true : false})
    }
    handleSaveClick = async(e) =>{
        if(this.state.documentName===""){
            this.setState({
                alerttype   :   "",
                isshow      :   true,
                color       :   "danger",
                message     :   "Please enter documents name.",
                fade        :   false
            });
            return
        }
        if(this.state.isDisableClient===false && this.state.clientGrid.length===0){
            this.setState({
                alerttype   :   "",
                isshow      :   true,
                color       :   "danger",
                message     :   "Please select client/s to include.",
                fade        :   false
            });
            return
        }
         if(this.state.isDisablePosition===false && this.state.positionGrid.length===0){
             this.setState({
                 alerttype   :   "",
                 isshow      :   true,
                 color       :   "danger",
                 message     :   "Please select position/s to include.",
                 fade        :   false
             });
             return
        }

        this.setState({isloading:true})
        let newClientGrid = []
        let newPositionGrid = []
        this.state.clientGrid.map(function(itm,i) {
            newClientGrid.push({
                "ClientId":itm.clientId,
            })
        })
        this.state.positionGrid.map(function(itm,i) {
            newPositionGrid.push({
                "PositionId":itm.positionId,
            })
        })

        const params = {
            "IpAddress":"0.0.0.0",
            "UserId":this.state.userinfo.userId,
            "ClientId":this.state.userinfo.clientId,
            "PositionId":"",
            "Code":this.state.documentCode,
            "Name":this.state.documentName,
            "IsRequired":(this.state.isRequired) ? "1" : "0",
            "documentClients":newClientGrid,
            "documentPositions":newPositionGrid
        };
        console.log("params")
        console.log(params)
        await axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Maintenance/AddDocument",  params)
        .then(res => {
            const data = res.data;

            if(data.status=="1"){
            this.setState({
                    isloading   :   false,
                    alerttype   :   "Success!",
                    isshow      :   true,
                    color       :   "success",
                    message     :   data.message,
                    fade        :   true,
                    
                    documentName            :   "",
                    isRequired              :   true,
                    disableremoveClient     :   true,
                    disableremovePosition   :   true,
                    clientGrid              :   [],
                    positionGrid            :   [],
                    documentCode            :   "",
                });
            
            }
            else {
                this.setState({
                    isloading   :   false,
                    alerttype   :   "Error!",
                    isshow      :   true,
                    color       :   "danger",
                    message     :   data.message,
                    fade        :   true
                });
            }
        })
        .catch(error=>{
            this.setState({
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade            :   true
            })
        })
    }

    handleChangeDocumentsCode = (e) => {
        this.setState({
            documentCode : e.target.value
        })
    }
    render() {

        const colClients = [
            {
                dataField: 'clientName',
                text: 'CLIENT',
                headerStyle : () => {
                    return { width  : "100%" };
                }
            },
        ]
        const clientSelectRow = {
            mode: 'checkbox',
            hideSelectAll: false,
            clickToSelectAndEditCell: true,
            onSelect: (row, isSelect, rowIndex, e) => {
                let isDisable = true
                this.state.clientGrid.map(function(item,i){
                    if(item.id===row.id)
                        item.isDeleted = isSelect ? "1" : "0"
                    if(item.isDeleted=="1")
                        isDisable=false
                })
                this.setState({disableremoveClient:isDisable})
            },
            onSelectAll: (isSelect, rows) => {
                let isDisable = true
                this.state.clientGrid.map(function(itm){
                    itm.isDeleted=isSelect ? "1" : "0"
                    if(itm.isDeleted=="1")
                        isDisable=false
                })
                this.setState({disableremoveClient:isDisable})
            }
        };
        const colPosition = [
            {
                dataField: 'positionName',
                text: 'POSITION',
                headerStyle : () => {
                    return { width  : "100%" };
                }
            },
        ]
        const positionSelectRow = {
            mode: 'checkbox',
            clickToSelectAndEditCell: true,
            hideSelectAll: false,
            onSelect: (row, isSelect, rowIndex, e) => {
                let isDisable = true
                this.state.positionGrid.map(function(item,i){
                    if(item.id===row.id)
                        item.isDeleted = isSelect ? "1" : "0"
                    if(item.isDeleted=="1")
                        isDisable=false
                })
                this.setState({disableremovePosition:isDisable})
            },
            onSelectAll: (isSelect, rows) => {
                let isDisable = true
                this.state.positionGrid.map(function(itm){
                    itm.isDeleted=isSelect ? "1" : "0"
                    if(itm.isDeleted=="1")
                        isDisable=false
                })
                this.setState({disableremovePosition:isDisable})
            }
        };    
    return(
            <div>
                <Banner />
                    <Container className="mt-5" fluid>
                        <Card>
                            <Card.Header>MAINTENANCE >> Recruitment Maintenance >> Required Documents - (CREATE)</Card.Header>
                            <Card.Body>
                                <Form>
                                    <Alert isOpen={this.state.isshow} color={this.state.color} fade={this.state.fade} className={this.state.isshow ? 'display-block' : 'display-none'}>
                                        <div className={this.state.color}></div> 
                                        {this.state.message}
                                    </Alert>
                                    <Form.Group as={Row} controlId="formPlaintextEmail">
                                        <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                        DOCUMENT NAME
                                        </Form.Label>
                                        <Col sm="11">
                                            <Form.Control 
                                                ref="documentName"
                                                name="documentName"
                                                value={this.state.documentName}
                                                onChange={this.handleChangeDocumentsName}
                                                autoComplete="off"
                                            />
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} controlId="formPlaintextEmail">
                                        <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                        DOCUMENT CODE
                                        </Form.Label>
                                        <Col sm="11">
                                            <Form.Control 
                                                ref="documentCode"
                                                name="documentCode"
                                                value={this.state.documentCode}
                                                onChange={this.handleChangeDocumentsCode}
                                                autoComplete="off"
                                                maxLength="5"
                                            />
                                        </Col>
                                    </Form.Group>
                                    <Form.Row className="mt-3">
                                        <Col sm="8" md={{ offset: 1 }}>
                                            <Form.Check 
                                                inline
                                                type="radio" 
                                                label="MANDATORY" 
                                                name="mandatory"
                                                value={this.state.isRequired}
                                                checked={this.state.isRequired}
                                                onChange={this.handleChangeRequired}
                                                value={this.state.isRequired}
                                            />
                                            <Form.Check 
                                                inline
                                                type="radio" 
                                                label="OPTIONAL" 
                                                name="optional"
                                                value={!this.state.isRequired}
                                                checked={!this.state.isRequired}
                                                onChange={this.handleChangeRequired}
                                                value={!this.state.isRequired}
                                                
                                            />
                                           
                                        </Col>
                                        {/* <Col md="auto">
                                        </Col> */}
                                        {/* <Col sm="2"  >
                                            <Form.Label>
                                                Enter Validity(MONTHS)
                                            </Form.Label>
                                            </Col>
                                            <Col sm="4">
                                            <Form.Control 
                                                ref="validity"
                                                name="validity"
                                                value={this.state.validity}
                                                onChange={this.handleChangeValidity}
                                                autoComplete="off"
                                            />
                                        </Col> */}
                                    </Form.Row>
                                    <Form.Row className="mt-1">
                                        <Col sm="6">
                                            <fieldset className="border p-1">
                                                <legend className="w-auto">INCLUDE CLIENT(S)</legend>
                                                <Form.Row>
                                                    <Col sm="2.5">
                                                        <Form.Check 
                                                            type="checkbox" 
                                                            label="SPECIFIC CLIENT" 
                                                            onChange={this.handleCheckClient}
                                                            style={{fontWeight : "bold",marginLeft:"2px",marginRight:"14px"}}
                                                        />
                                                    </Col>
                                                    <Col sm="5">
                                                        <Typeahead
                                                            ref="clientRef"
                                                            labelKey='name'
                                                            id="basic-example"
                                                            onChange={this.handleChangeClient}
                                                            options={this.state.clientDDL}
                                                            disabled={this.state.isDisableClient}
                                                        />
                                                    </Col>
                                                    <Col sm="2">
                                                        <Button disabled={this.state.isDisableClient} className="ml-auto noser-button-mr1" variant="success" onClick = { this.handleAddClient }>ADD</Button>
                                                        <Button disabled={this.state.disableremoveClient} className="noser-button" variant="danger" onClick = { this.handleRemoveClient }>REMOVE</Button>
                                                    </Col>
                                                </Form.Row>
                                                <Form.Row>
                                                    <Col sm="12">
                                                    <div className="mt-2">
                                                        <BootstrapTable
                                                            keyField = "id"
                                                            data = { this.state.clientGrid }
                                                            columns = { colClients }
                                                            rowClasses="noser-table-row-class"
                                                            striped
                                                            hover
                                                            condensed
                                                            cellEdit = { cellEditFactory({
                                                                mode: 'dbclick',
                                                                blurToSave: true,
                                                                afterSaveCell: (oldValue, newValue, row, column) => {
                                                                    this.ClientGridDataModified(oldValue, newValue, row.id, column.dataField)
                                                                    }
                                                                })
                                                            }
                                                            selectRow = { clientSelectRow }
                                                            noDataIndication={ () => <div style={{width:'100%'}}>No record found.</div> }
                                                        />
                                                    </div>
                                                    </Col>
                                                </Form.Row>
                                            </fieldset>
                                        </Col>
                                        <Col sm="6">
                                            <fieldset className="border p-1">
                                                <legend className="w-auto">INCLUDE POSITION(S)</legend>
                                                <Form.Row>
                                                    <Col sm="2.5">
                                                        <Form.Check 
                                                            type="checkbox" 
                                                            label="SPECIFIC POSITION" 
                                                            name="specificPosition"
                                                            onChange={this.handleCheckPosition}
                                                            style={{fontWeight : "bold",marginLeft:"2px"}}
                                                        />
                                                    </Col>
                                                    <Col sm="5">
                                                        <Typeahead
                                                            ref="positionRef"
                                                            labelKey='name'
                                                            id="basic-example"
                                                            onChange={this.handleChangePosition}
                                                            options={this.state.positionDDL}
                                                            disabled={this.state.isDisablePosition}
                                                        />
                                                    </Col>
                                                    <Col sm="2">
                                                        <Button disabled={this.state.isDisablePosition} className="ml-auto" style={{width:'60px',marginRight:"2px"}} variant="success" onClick = { this.handleAddPosition }>ADD</Button>
                                                        <Button disabled={this.state.disableremovePosition} style={{width:'60px'}} variant="danger" onClick = { this.handleRemovePosition }>REMOVE</Button>
                                                    </Col>
                                                </Form.Row>
                                                <Form.Row>
                                                    <Col sm="12">
                                                    <div className="mt-2">
                                                        <BootstrapTable
                                                            keyField = "id"
                                                            data = { this.state.positionGrid }
                                                            columns = { colPosition }
                                                            rowClasses="noser-table-row-class"
                                                            striped
                                                            hover
                                                            condensed
                                                            cellEdit = { cellEditFactory({
                                                                mode: 'dbclick',
                                                                blurToSave: true,
                                                                afterSaveCell: (oldValue, newValue, row, column) => {
                                                                    this.PositionGridDataModified(oldValue, newValue, row.id, column.dataField)
                                                                    }
                                                                })
                                                            }
                                                            selectRow = { positionSelectRow }
                                                            noDataIndication={ () => <div style={{width:'100%'}}>No record found.</div> }
                                                        />
                                                    </div>
                                                    </Col>
                                                </Form.Row>
                                            </fieldset>
                                        </Col>
                                    </Form.Row>
                                    <div className="mt-2">
                                    <ButtonToolbar>
                                        <Button className="noser-button-mr1 ml-auto" variant="success" onClick = { this.handleSaveClick }>Save</Button>
                                        <Button className="noser-button"  href="/documents" variant="danger">Back</Button>
                                    </ButtonToolbar>
                                    </div>
                                </Form>
                            </Card.Body>
                        </Card>
                       
                    </Container>
                    <NoserLoading show={this.state.isloading} />
            </div> 
        )
    }

}

export  default DocumentCreate 
