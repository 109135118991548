import {
    React,Component,BootstrapTable,
    paginationFactory,Button, ButtonToolbar, Card, 
    Form, Col, Row, axios, Container, Banner, Typeahead, TimePicker,
    cellEditFactory, Type, moment, NavLink, Alert, AppConfiguration,
    NoserLoading, Noser, sizePerPageRenderer, Modal
} 
from '../../noser-hris-component';

class TransmittedDocumentApproval extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userinfo        :   [],
            isloading       :   false,
            isshow          :   false,
            alerttype       :   "",
            message         :   "",
            color           :   "",
            fade            :   true,      
            disable         :   true,

            docid           :   "",
            doccode         :   "",
            profileId       :   "",
            employeeName    :   "",
            employeeNo      :   "",
            clientName      :   "",
            locationName    :   "",
            currentDate     :   "",
            positionName    :   "",
            documents       :   [],
            uploadfiles     :   []
        }
    }

    componentDidMount(){
        const sleep = (milliseconds) => {return new Promise(resolve => setTimeout(resolve, milliseconds))}
        this.state.userinfo = JSON.parse(sessionStorage.getItem("userData"))
        this.props.onRefModal(this)
    }
    componentWillUnmount() {
        this.props.onRefModal(undefined)
    }
    initialize=(e)=>{
        this.setState({
            userinfo        :   JSON.parse(sessionStorage.getItem("userData")),
            isloading       :   false,
            isshow          :   false,
            alerttype       :   "",
            message         :   "",
            color           :   "",
            fade            :   true,
            disable         :   true,

            profileId       :   e.profileId,
            employeeName    :   e.employeeName,
            employeeNo      :   e.employeeNo,
            clientName      :   e.client,
            locationName    :   e.location,
            positionName    :   e.position,
            currentDate     :   moment(new Date).format('MM/DD/YYYY'),
        })
        this.GetDocuments(e.profileId)
    }
    GetDocuments = async(profileId) => {
        this.setState({isloading:true})
        const params = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.userinfo.clientId,
            "UserId":this.state.userinfo.userId,
            "ProfileId":profileId,
            "StatusId":"4"
        }
        await axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Training/GetEmployeeDocuments", params)
        .then(res => {
            const data = res.data;
            console.log(data)
            this.setState({documents : data.documents, isloading:false})  
        })
        .catch(error=>{
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade        :   true
            })
        })
    } 
    handleSubmitClick = async() => {
        this.setState({isloading:true})

        if(this.state.documents.filter(x=>x.isModified==="1" && x.dateReceived==="").length>0){
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "Please enter date received.",
                fade        :   true
            })
            return
        }
        if(this.state.documents.filter(x=>x.isModified==="1" && x.fileName==="").length>0){
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "Please select document file.",
                fade        :   true
            })
            return
        }

        const formData = new FormData();
        formData.append('IpAddress', "0.0.0.0");
        formData.append('ClientId', this.state.userinfo.clientId);
        formData.append('UserId', this.state.userinfo.userId);
        formData.append('EmployeeNo', this.state.employeeNo);
        formData.append('ProfileId', this.state.profileId);
        formData.append('StatusId', "1");
        formData.append("documents", JSON.stringify(this.state.documents.filter(x=>x.isModified==="1"))) 

        this.state.documents.filter(x=>x.isModified==="1").map(function(itm,idx){
            //formData.append("documents", JSON.stringify(itm)) 
            if(itm.fileName!==""){
                formData.append("FileUpload", itm.fileUpload);      
            }
        })

        await axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Training/ApprovedEmployeeDocument", formData, {headers: {'Content-Type': 'multipart/form-data'}})
        .then(res => {
            const data = res.data;
            if(data.status==="0"){
                this.setState({
                    isloading   :   false,
                    alerttype   :   "Error!",
                    isshow      :   true,
                    color       :   "danger",
                    message     :   data.message,
                    fade        :   true
                });
            } 
            else{
                this.setState({
                    isloading   :   false,
                    alerttype   :   "Success!",
                    isshow      :   true,
                    color       :   "success",
                    message     :   data.message,
                    fade        :   true
                });
            } 
        })
        .catch(error=>{
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade        :   true
            })
        })
    } 
    SetFile = rowid => e => {
        let documents = this.state.documents
        let uploadfile = ""
        let filename = ""
        let docid = this.state.docid
        if(e.target.value.length>0){
            uploadfile = e.target.files[0]
            uploadfile.id=this.state.docid
            filename = this.state.employeeNo.replace("-","") + "_" + this.state.doccode + ".pdf"
            //uploadfile.name = filename = this.state.employeeNo.replace("-","") + "_" + "0001.pdf" 
        }
        else{
            uploadfile = "";
        }
        if(uploadfile!=="" && documents.filter(x=>x.originalfile===uploadfile.name).length>0){
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "File name already exist.",
                fade        :   true
            })
            return
        }
        documents.map(function(itm,idx){
            if(itm.id===docid){
                itm.fileUpload = uploadfile
                itm.fileName = filename
                itm.originalfile = uploadfile.name
            }
        })
        this.setState({documents:documents,docid:"",doccode:""})
        console.log(this.state.documents)
    }
    handleAttachedClick = row => e =>{
        this.state.docid = row.id
        this.state.doccode = row.documentCode
        this.inputElement.click(row.id)
    }
    handleRemoveAttachedClick = rowid => e =>{
        let documents = this.state.documents
        documents.map(function(itm,idx){
            if(itm.id===rowid){
                itm.fileUpload = ""
                itm.fileName = ""
            }
        })
        this.setState({documents:documents})
    }
    render() { 

        const columns1 = [
        {
            dataField: 'document',
            text: 'DOCUMENT',
            editable:false,
            headerStyle: (colum, colIndex) => {
                return { textAlign: 'left',width:'36%'}
            },
            style:{textAlign:'left',width:'36%'}
        },
        {
            dataField: 'required',
            text: 'TYPE',
            editable:false,
            headerStyle: (colum, colIndex) => {
                return { textAlign: 'center',width:'15%'}
            },
            style:{textAlign:'center',width:'15%'}
        },
        {
            dataField: 'dateReceived',
            text: 'DATE RECEIVED',
            editable:true,
            headerStyle: (colum, colIndex) => {
                return { textAlign: 'center',width:'20%'}
            },
            style:{textAlign:'center',width:'20%'},
            formatter: (cell) => {
                if(cell!=""){
                    let dateObj = cell;
                    if (typeof cell !== 'object') {
                        dateObj = new Date(cell);
                    }
                    let m = moment(dateObj, 'MM/DD/YYYY');
                    return (m.isValid() ? (dateObj.getMonth()+1 + "/" + dateObj.getDate() + "/" + dateObj.getFullYear()) : "")
                    //return `${('0' + (dateObj.getUTCMonth() + 1)).slice(-2)}/${('0' + dateObj.getUTCDate()).slice(-2)}/${dateObj.getUTCFullYear()}`;
                }
            },
            editor: {
                type: Type.DATE
            }
        },
        {
            dataField: 'fileName',
            text: 'FILE',
            editable:true,
            headerStyle: (colum, colIndex) => {
                return { textAlign: 'center',width:'15%'}
            },
            style:{textAlign:'center',width:'15%'},
        },
        {
            dataField: 'action',
            text: 'ACTION',
            editable:false,
            headerStyle: (colum, colIndex) => {
                return { textAlign: 'center',width:'21%',whiteSpace:'nowrap'}
            },
            style:{textAlign:'center',width:'21%',whiteSpace:'nowrap'},
            formatter   :   (cell, row, isSelect) => {
                if(row.fileName=="" || typeof row.fileName == 'undefined'){
                    return (<div>
                        <Button onClick={this.handleAttachedClick(row)} style={{height:'18px',paddingTop:'0',marginTop:'-4px',fontSize:'11px'}} 
                            variant="link">
                            ATTACH FILE
                        </Button>
                        <Form.Control ref={input => this.inputElement = input} style={{display: 'none'}} type="file" accept='.pdf' variant='link' onChange={this.SetFile(row.id)} />
                        </div>
                    );
                }
                else{
                    return (<div>
                        <Button onClick={this.handleRemoveAttachedClick(row.id)} style={{height:'18px',paddingTop:'0',marginTop:'-4px',fontSize:'11px'}} 
                            variant="link">
                            REMOVE FILE
                        </Button>
                        </div>
                    );
                }
            },
            
        }
        ] 
        const selectRow = {
            mode: 'checkbox',
            hideSelectAll: true,
            clickToSelectAndEditCell: false,
            onSelect: (row, isSelect, rowIndex, e) => {
                let disable = true
                this.state.documents.map(function(itm,idx){
                    if(itm.id===row.id)
                        itm.isModified = isSelect ? "1" : "0"
                    if (itm.isModified=="1")
                    disable =  false
                })
                this.setState({disable:disable})
            }
        };
        return(
                <>
                <Modal
                    {...this.props}
                    return
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    backdrop="static"
                >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        APPROVE TRANSMITTED DOCUMENT(S)
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <Container>       
                    <Form>
                        <Alert isOpen={this.state.isshow} color={this.state.color} fade={this.state.fade} className={this.state.isshow ? 'display-block' : 'display-none'}>
                            <div className={this.state.color}></div> 
                            {this.state.message}
                        </Alert>
                        <Form.Group as={Row} controlId="formPlaintextEmail">
                            <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                EMPLOYEE :
                            </Form.Label>
                            <Col sm="6">
                                <Form.Control 
                                    type="text" 
                                    value={this.state.employeeName}
                                    disabled
                                />
                            </Col>
                            <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                EMPLOYEE NO :
                            </Form.Label>
                            <Col sm="3">
                                <Form.Control 
                                    type="text" 
                                    value={this.state.employeeNo}
                                    disabled
                                />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row}>
                            <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                CLIENT :
                            </Form.Label>
                            <Col sm="6">
                                <Form.Control 
                                    type="text" 
                                    value={this.state.clientName} 
                                    disabled
                                />
                            </Col>
                            <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                LOCATION :
                            </Form.Label>
                            <Col sm="3">
                                <Form.Control 
                                    type="text" 
                                    value={this.state.locationName}
                                    disabled
                                />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} controlId="formPlaintextEmail">
                        <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                POSITION :
                            </Form.Label>
                            <Col sm="6">
                                <Form.Control 
                                    type="text" 
                                    value={this.state.positionName}
                                    disabled
                                />
                            </Col>
                            <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                DATE :
                            </Form.Label>
                            <Col sm="3">
                                <Form.Control 
                                    type="text" 
                                    value={this.state.currentDate}
                                    disabled
                                />
                            </Col>
                        </Form.Group>
                        <Card.Header className="mt-5">List</Card.Header>
                        <div >
                            <BootstrapTable
                                keyField = "id"
                                data = { this.state.documents }
                                columns = { columns1 }
                                pagination={ paginationFactory({sizePerPageRenderer}) }
                                noDataIndication={ () => <div style={{width:'100%'}}>No record found.</div> }
                                rowClasses="noser-table-row-class"
                                striped
                                hover
                                condensed
                                selectRow={selectRow}
                                cellEdit = { cellEditFactory({
                                    mode: 'dbclick',
                                    blurToSave: true,
                                    afterSaveCell: (oldValue, newValue, row, column) => {
                                        //this.GridDataModified(oldValue, newValue, row.id, column.dataField)
                                        }
                                    })
                                }
                            />
                        </div>
                    </Form>
                </Container>
                </Modal.Body>
                <Modal.Footer>
                    <ButtonToolbar >
                        <Button disabled={this.state.disable} variant="success" className="ml-auto  noser-button-mr1" onClick = { this.handleSubmitClick }>APPROVE</Button>
                        <Button className="noser-button" variant="danger" onClick={this.props.onHide}>Close</Button>
                    </ButtonToolbar>
                </Modal.Footer>
                <NoserLoading show={this.state.isloading} />
            </Modal>
            </>
        );
    }
}
export  default TransmittedDocumentApproval
