import {
    React,Component,BootstrapTable,
    paginationFactory,Button, ButtonToolbar, Card, 
    Form, Col, Row, axios, Container, Banner, Typeahead, TimePicker,
    cellEditFactory, Type, moment, NavLink, Alert, AppConfiguration,
    NoserLoading, Noser, sizePerPageRenderer, DatePicker, Modal
} 
from '../../../noser-hris-component';
import {CipherString} from '../../../noser-sec'
class CreateUser extends Component {
    constructor(props) {
        super(props)
        this.state = {
            userinfo    :   JSON.parse(sessionStorage.getItem("userData")),
            isloading   :   false,
            isshow      :   false,
            alerttype   :   "",
            message     :   "",
            color       :   "",
            fade        :   true,
            roleList    :   [],
            clientId    :   '',
            roleId      :   '',
            firstName   :   '',
            middleName  :   '',
            lastName    :   '',
            userName    :   '',
            password    :   ''
        }
    }
    componentDidMount(){
        this.state.userinfo = JSON.parse(sessionStorage.getItem("userData"))
        this.props.onRefModal(this)
    }
    componentWillUnmount() {
        this.props.onRefModal(undefined)
    }
    initialize=(e)=>{
        this.setState({
            userinfo    :   JSON.parse(sessionStorage.getItem("userData")),
            isloading   :   false,
            isshow      :   false,
            alerttype   :   "",
            message     :   "",
            color       :   "",
            fade        :   true,
            roleList    :   [],
            clientId    :   '',
            roleId      :   '',
            firstName   :   '',
            middleName  :   '',
            lastName    :   '',
            userName    :   '',
            password    :   ''
        })
        this.GetRoles()
    }
    GetRoles = () =>{
        this.setState({isloading:true,isDisable:true})
        const params = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.userinfo.clientId,
            "UserId":this.state.userinfo.userId
        }
        axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Administrator/GetRoles", params)
        .then(res => {
            const data = res.data
            this.setState({
                isloading   :   false,
                alerttype   :   data.status=="1" ? "Success!" : "Error!",
                isshow      :   data.status=="1" ? false : true,
                color       :   data.status=="1" ? "success" : "danger",
                message     :   data.message,
                fade        :   true,
                roleList    :   data.roles
            });
        })
        .catch(error=>{
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade        :   true,
            })
        })
    }
    handleSubmit = (e) =>{
        this.setState({isloading:true})

        if(this.state.firstName==""){
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "Please enter first name.",
                fade        :   true
            })
            return 
        }

        if(this.state.lastName==""){
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "Please enter last name.",
                fade        :   true
            })
            return 
        }

        if(this.state.roleId==""){
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "Please select role.",
                fade        :   true
            })
            return 
        }
        if(this.state.userName==""){
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "Please enter user name.",
                fade        :   true
            })
            return 
        }

        if(this.state.password==""){
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "Please enter user password.",
                fade        :   true
            })
            return 
        }

        const param = {
            "IpAddress":"0.0.0.0",
            "ClientId":"111",
            "UserId":this.state.userinfo.userId,
            "FirstName":this.state.firstName,
            "MiddleName":this.state.middleName,
            "LastName":this.state.lastName,
            "RoleId":this.state.roleId,
            "UserName":this.state.userName,
            "Password":CipherString(this.state.password),
        }
        
        axios
            .post(
                AppConfiguration.Setting().noserapiendpoint + "Administrator/AddUser",  param
            )
            .then(res => {
                const data = res.data;
                this.setState({
                    isloading   :   false,
                    alerttype   :   res.data.status=="1" ? "Success!" : "!Error",
                    isshow      :   true,
                    color       :   res.data.status=="1" ? "success" : "danger",
                    message     :   data.message,
                    fade        :   true
                });
            })
            .catch(error=>{
                this.setState({
                    isloading   :   false,
                    alerttype   :   "Error!",
                    isshow      :   true,
                    color       :   "danger",
                    message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                    fade        :   true
                })
            })
    }
    handleModalClose = () => {
        this.props.onHide();            
    }
    handleChangeUpperCase = (e) =>{
        this.setState({[e.target.name]: e.target.value.toUpperCase()})
    }
    handleChange = (e) =>{
        this.setState({[e.target.name]: e.target.value})
    }
    handleChangeRole = (e) =>{
        this.setState({
            alerttype   :   "",
            isshow      :   false,
            color       :   "",
            message     :   "",
            fade        :   false
        });
        if(e.length==0)
        {
            this.state.roleId= ''
            return
        }
        this.state.roleId= e[0].id
    }
    render() {
    return(
        
            <Modal
                {...this.props}
                return
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                backdrop="static"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        CREATE USER
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container>
                        <Alert isOpen={this.state.isshow} color={this.state.color} fade={this.state.fade} className={this.state.isshow ? 'display-block' : 'display-none'}>
                            <div className={this.state.color}></div> 
                            {this.state.message}
                        </Alert>
                        <Form.Row>
                            <Form.Group as={Col} sm={12} >
                                <Form.Control 
                                    name='firstName'
                                    type="text" 
                                    placeholder="Enter First Name" 
                                    onChange={this.handleChangeUpperCase} 
                                    autoComplete="off" 
                                    value={this.state.firstName}
                                    maxLength={50}
                                />
                            </Form.Group>
                        </Form.Row>
                        <Form.Row>
                            <Form.Group as={Col} sm={12} >
                                <Form.Control 
                                    name='middleName'
                                    type="text" 
                                    placeholder="Enter Middle Name" 
                                    onChange={this.handleChangeUpperCase} 
                                    autoComplete="off"
                                    value={this.state.middleName}
                                    maxLength={50}
                                />
                            </Form.Group>
                        </Form.Row>
                        <Form.Row>
                            <Form.Group as={Col} sm={12} >
                                <Form.Control 
                                    name='lastName'
                                    type="text" 
                                    placeholder="Enter Last Name" 
                                    onChange={this.handleChangeUpperCase} 
                                    autoComplete="off"
                                    value={this.state.lastName}
                                    maxLength={50}
                                />
                            </Form.Group>
                        </Form.Row>
                        <Form.Row>
                            <Form.Group as={Col} sm={12} controlId="formHorizontalEmail">
                                <Typeahead
                                        labelKey='name'
                                        id="basic-example"
                                        onChange={this.handleChangeRole}
                                        options={this.state.roleList}
                                        placeholder="Select Role"
                                    />
                            </Form.Group>
                        </Form.Row>
                        <Form.Row>
                            <Form.Group as={Col} sm={12} >
                                <Form.Control 
                                    name='userName'
                                    type="text" 
                                    placeholder="Enter User Name" 
                                    onChange={this.handleChange} 
                                    autoComplete="off"
                                    value={this.state.userName}
                                    maxLength={50}
                                />
                            </Form.Group>
                        </Form.Row>
                        <Form.Row>
                            <Form.Group as={Col} sm={12} >
                                <Form.Control 
                                    name='password'
                                    type="password" 
                                    placeholder="Enter Password" 
                                    onChange={this.handleChange} 
                                    autoComplete="off"
                                    value={this.state.password}
                                    maxLength={50}
                                />
                            </Form.Group>
                        </Form.Row>
                    </Container>
                </Modal.Body>
                <Modal.Footer>
                    <ButtonToolbar >
                        <Button variant="success" style={{minWidth:'60px',marginRight:'1pt'}} className="ml-auto" onClick = { this.handleSubmit }>Save</Button>
                        <Button variant="danger" style={{minWidth:'60px',marginRight:'15px'}} onClick={this.props.onHide}>Close</Button>
                    </ButtonToolbar>
                </Modal.Footer>
                <NoserLoading show={this.state.isloading} />
        </Modal>
        );
    }
}
export default CreateUser