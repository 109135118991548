import {
    React,Component,BootstrapTable,
    paginationFactory,Button, ButtonToolbar, Card, 
    Form, Col, Row, axios, Container, Banner, Typeahead, TimePicker,
    cellEditFactory, Type, moment, NavLink, Alert, AppConfiguration,
    NoserLoading, Noser, sizePerPageRenderer, DatePicker,
} 
from '../../noser-hris-component';
import { DropdownButton, Dropdown, FormControl} from 'react-bootstrap';

import  InsertCOCReference from '../Maintenance/Modal/InsertCOCReference';
import { Input } from 'reactstrap';
 
class CriminalCases extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fade            :   true, 
            color           :   "",
            isshow          :   false,
            message         :   "",
            userinfo        :   [],
            isloading       :   false,
            alerttype       :   "",

            batchAutocomplete                   :   [],
            clientAutocomplete                  :   [],
            positionAutocomplete                :   [],
            trainingAutocomplete                :   [],
          
            batchNoAutocomplete                 :   [],
            dateFrom                            :   "",
            dateTo                              :   "",
            modalCoCReferenceShow               :   false,

            category                :"",

            isLoading               :"",

           
            selectedEmployeeId      :[],
            selectedEmployeeNo      :[],
            selectedEmployeeBranch  :[],
            selectedPosition        :[],

            getClientList           :[],
            getEmployeeList         :[],
            getEmployeeNoList       :[],
            position                :[],
            location                :[],

            //addParagraph:[],
            values                  :[],
            
            dateApplied             :"",
            selectedClientName      :"",
            selectedEmployeeName    :[],
            selectedEmployeeNo      :[],            

            dateCaseFiled           :"",
            caseFiled               :"",
            venue                   :"",
            SchedOfHearing          :"",          
            prosecutor              :"",
            legal                   :"",
            latestUpdate            :"",
            updateDate              :"",
            remarks                 :"",
            status                  :"",
            AttachedDoc             :"",
            particularList: [
                {
                    "id" : "Pending",
                    "name" : "Pending"
                },
                {
                    "id" : "Closed",
                    "name" : "Closed"
                }
            ],
 	        selectedparticular      :   '',
            selectedparticularId    :   '',
            counsel:"",
            

        }
        /* this.handleSubmit = this.handleSubmit.bind(this); */
    }

    componentDidMount(){
        const sleep = (milliseconds) => {return new Promise(resolve => setTimeout(resolve, milliseconds))}
        this.state.userinfo = JSON.parse(sessionStorage.getItem("userData"))

        this.setState({isLoading:true,loadingText:"Loading client list..."})

        this.state.userinfo = JSON.parse(sessionStorage.getItem("userData"))
        this.getClientList();;
    }

    /////******DYNAMIC COMPONENT TASYO******/////////
    /* createUI(){
        return this.state.values.map((el, i) => 
            <div key={i} className="mt-3">
               <FormControl as="textarea" value={el||''} aria-label="With textarea" placeHolder="(Enter Paragraph)" onChange={this.handleChange.bind(this, i)} />
               
            </div>          
        )
    }
   
    handleChange(i, event) {
        let values = [...this.state.values];
        values[i] = event.target.value;
        this.setState({ values });
        console.log(values)
    }
     
    addClick(){
       this.setState(prevState => ({ values: [...prevState.values, '']}))
    }
     
    removeClick(i){
        let values = [...this.state.values];
        values.splice(i,1);
        this.setState({ values });
    }
   
    handleSubmit(event) {
       alert('A name was submitted: ' + this.state.values.join(', '));
       event.preventDefault();
    } */
   


    getClientList(){
        this.setState({isloading:true})
        const getParams = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.userinfo.clientId,
            "UserId":this.state.userinfo.userId,
        }
        axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Client/GetClientList", getParams)
        .then(res => {
            this.setState({
                isloading:false,
                getClientList : res.data.clients ? res.data.clients : []
            });
        })
        .catch(error=>{
           this.setState({
               isloading       :   false,
               alerttype       :   "Error!",
               isshow          :   true,
               color           :   "danger",
               message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
               fade            :   true
            })
       })
    }

    onChangeClientList = (e) => {
        if(e.length == 0) {
            this.state.selectedClientName=""
            this.state.selectedClientId=""
            return
        } 
        this.state.selectedClientId = e[0].id
        this.state.selectedClientName = e[0].name
        console.log("Client selectedClientId " + this.state.selectedClientId );
        console.log("Client selectedClientName " + this.state.selectedClientName );
        //this.setState({isloading:true,isshow:false,color:"",message:"",fade:true})
        
        this.getEmployees();
    }

    getEmployees(){
        this.setState({isloading:true})

        const getParams = {

            "IpAddress":"0.0.0.0",
            "ClientId":this.state.selectedClientId,
            "UserId":this.state.userinfo.userId,
            "EmployeeName":"",
            "EmployeeNo":"",
            "ClientName":""

        }

        axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Employee/GetEmployeeProfileFilter", getParams)
        .then(res => {
            console.log("Employee List start ");
            console.log(res.data);
            console.log("Employee List end");
            const data = res.data
            if(data.status=="1"){
                console.log("tur==")
                this.setState({getEmployeeList : data.profiles, getEmployeeNoList : data.profiles,isloading:false,})
            }else{
                console.log("else==")
                this.setState({getEmployeeList : [], getEmployeeNoList : [],isloading:false}) 
            }
                
        })
        .catch(error=>{
           this.setState(  {
               isloading       :   false,
               alerttype       :   "Error!",
               isshow          :   true,
               color           :   "danger",
               message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
               fade            :   true
           })
       })
        
    }

    onChangeEmployeesList = (e) => {       

        if(e.length==0)
        {
            console.log("123123")
            this.setState({getEmployeeList: null, selectedPayrollpayPeriod: '', selectedWorkScheduleIdParam: '', selectedPayrollPeriodsId : '',selectedEmployeeId : '',selectedEmployeeName : '',selectedPayrollPeriodsName : '', selectedWorkScheduleId: ''})
          
            return
        }

        this.setState({isloading:true,isshow:false,color:"",message:"",fade:true})
        console.log("45678")
        this.state.selectedEmployeeName = e[0].employeeName
        this.state.selectedEmployeeId = e[0].id
        this.state.selectedEmployeeNo = e[0].employeeNo
        this.state.selectedEmployeeBranch = e[0].locationName
        this.state.selectedPosition = e[0].position
       
        console.log("Work selectedEmployeeName   " + this.state.selectedEmployeeNo );
        console.log("Employee List start ");
        console.log(this.state.selectedEmployeeBranch);
        console.log("Employee List end");

      /*   if(this.state.selectedPayrollpayPeriod == ""){
            this.state.selectedPayrollPeriodsId = e[0].payPeriodId
            this.state.selectedPayrollpayPeriod = e[0].payPeriod
        } */

        //this.employeeTransactionsList();
       
        this.setState({isloading:false})
    }
    
    onChangeEmployeesNo(e){
        this.setState({selectedEmployeeNo : e.target.value})
        //console.log(e.target.value)
    }
   
    handleChangeDate = date => {
        ////console.log(date)
        this.setState({
            dateApplied: date,
            isshow:false,
        })
    }

    /* handleAddParagraph(){
        return this.state.addParagraph.map((el,i)=>
            <div className="mt-2" key={i}>

                <Form.Group as={Row} controlId="formPlaintextEmail" className="mt-4">
                    <Col>
                        <FormControl as="textarea" aria-label="With textarea" placeHolder="(Enter Paragraph)" />
                    </Col>
                </Form.Group>                                   
       
            </div>
        )
    } */

    handleSaveClick=()=>{
        let createddate = moment(this.state.createddate).format('MM/DD/YYYY');
        let data = {
            "client"                :   this.state.selectedClientName,
            "employeeName"          :   this.state.selectedEmployeeName,
            "position"              :   this.state.selectedPosition,
            "employeeNo"            :   this.state.selectedEmployeeNo,
            "location"              :   this.state.selectedEmployeeBranch,
            "dateCaseFiled"         :   this.state.dateCaseFiled,
            "caseFiled"             :   this.state.caseFiled,
            "venue"                 :   this.state.venue,
            "SchedOfHearing"        :   this.state.SchedOfHearing,
            "prosecutor"            :   this.state.prosecutor,
            "legal"                 :   this.state.legal,
            "latestUpdate"          :   this.state.latestUpdate,
            "updateDate"            :   this.state.updateDate,
            "remarks"               :   this.state.remarks,
            "status"                :   this.state.selectedparticular, 
            "file"                  :   this.state.AttachedDoc,
            "counsel"               :   this.state.counsel,
            "isDraft"               :   "0",
            "isDeleted"             :   "0",
            "createdby"             :   "user007",
            "createddate"           :   createddate,
            "modifiedby"            :   'user007',
            "modifieddate"          :   '0',
            "isModified"            :   '0',    
        }
        console.log(data)
        
        const addParams = {
            "_collection" : "TestCriminalCases",
            "doc_data"    : data
        }
          
        axios
        .post("http://134.209.99.190:8088/action/add", addParams)
        .then(res =>{
            const data = res.data
          
            this.setState({
                selectedClientName      :"",
                selectedEmployeeName    :"",
                selectedPosition        :"",
                selectedEmployeeNo      :"",
                selectedEmployeeBranch  :"",
                dateCaseFiled           :"",
                caseFiled               :"",
                venue                   :"",
                SchedOfHearing          :"",
                prosecutor              :"",
                legal                   :"",
                latestUpdate            :"",
                updateDate              :"",
                remarks                 :"",
                status                  :"", 
                AttachedDoc             :"", 
                counsel                 :"",       
                isloading       :   false,
                alerttype       :   "Success!",
                isshow          :   true,
                color           :   "success",
                message         :   "Data had successfully Save" ,
                fade            :   true
               
            })
          
        })
        .catch(error =>{
            console.log("error: " + error)
            this.setState({
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade            :   true
            })
        })
    }

    handleChangedateCaseFiled=(e)=>{
        this.setState({
            dateCaseFiled   : e.target.value
        })
        //console.log(this.state.paragraph)
    }
    handleAddedcaseFiled=(e)=>{
        this.setState({
            caseFiled:e.target.value
        })
    }
    handleChangevenue=(e)=>{
        this.setState({
            venue   : e.target.value
        })
        //console.log(this.state.addedparagraph1)
    }
    handleAddedSchedOfHearing=(e)=>{
        this.setState({
            SchedOfHearing:e.target.value
        })
    }
    handleChangeprosecutor=(e)=>{
        this.setState({
            prosecutor   : e.target.value
        })
        //console.log(this.state.addedparagraph2)
    }
    handlelegal=(e)=>{
        this.setState({
            legal : e.target.value
        })
    }
    handleChangelatestUpdate=(e)=>{
        this.setState({
            latestUpdate   : e.target.value
        })
        //console.log(this.state.addedparagraph3)
    }
 
    handleChangeupdateDate=(e)=>{
        this.setState({
            updateDate   : e.target.value
        })
        //console.log(this.state.quotedStatement)
    }
    handleOnChangeremarks=(e)=>{
        this.setState({
            remarks   : e.target.value
        })
        //console.log(this.state.preparedBy)
    }
    handleOnChangestatus=(e)=>{
        this.setState({
            status   : e.target.value
        })
        //console.log(this.state.notedBy)
    }
    handleOnChangeAttachedDoc=(e)=>{
        let fileTmp = e.target.value
        this.setState({
            AttachedDoc   : fileTmp
        })
        console.log(this.state.AttachedDoc)
    }
    handleOnChangeCounsel=(e)=>{
        this.setState({
            counsel   : e.target.value
        })
        console.log(this.state.counsel)
    }
    
    onChangeparticular = (e) => {
        if(e.length==0)
        {
            this.setState({
                selectedparticular     :   '',
                selectedparticularId   :   ''
            })
            return
        }
        this.state.selectedparticular = e[0].name
        this.state.selectedparticularId = e[0].id
       
        this.setState({
            isshow   :   false,
            selectedparticular : e[0].id
        })
        console.log(this.state.selectedparticular)
      
    }


    render() {        
    return(
            <div>
                <Banner />
                    <Container className="mt-5" fluid>
                        <Card>
                            <Card.Header>Criminal Cases</Card.Header>
                            <Card.Body>
                                <Card.Header className="mt-2">Employee INFORMATION</Card.Header>
                                <Form>
                                    <Alert isOpen={this.state.isshow} color={this.state.color} fade={this.state.fade} className={this.state.isshow ? 'display-block' : 'display-none'}>
                                        <div className={this.state.color}></div> 
                                        {this.state.message}
                                    </Alert>
                                    <Form.Group as={Row} controlId="formPlaintextEmail" className="mt-3">
                                        <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                            Client
                                        </Form.Label>
                                        <Col sm="11">
                                            <Typeahead
                                                labelKey='name'
                                                id="basic-example"
                                                onChange={this.onChangeClientList}
                                                options={this.state.getClientList}
                                                placeholder="Select Client"
                                            />
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} controlId="formPlaintextEmail">
                                        <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                            Employee
                                        </Form.Label>
                                        <Col sm="7">
                                            <Typeahead
                                                labelKey='employeeName'
                                                id="basic-example"
                                                onChange={this.onChangeEmployeesList}
                                                options={this.state.getEmployeeList}
                                                placeholder="Select Employee"
                                                autocomplete="false"
                                            /> 
                                        </Col>

                                        <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                            Employee No.
                                        </Form.Label>
                                        <Col sm="3">
                                            <Typeahead
                                                labelKey='name'
                                                id="basic-example"
                                                onChange={this.onChangeTraining}
                                                options={this.state.trainingAutocomplete}
                                            />
                                        </Col>
                                    </Form.Group>

                                    <Form.Group as={Row} controlId="formPlaintextEmail">
                                        <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                            Position
                                        </Form.Label>
                                        <Col sm="4">
                                            <Form.Control 
                                                type="text"
                                                name="selectedEmployeeName" 
                                                value={this.state.selectedPosition}
                                                onChange={this.onChangePosition} 
                                                autoComplete="off"
                                                placeholder="Autofill"
                                            />
                                        </Col>
                                        <Col sm="2"></Col>
                                        <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                        Location
                                        </Form.Label>
                                        <Col sm="4">
                                            <Form.Control 
                                                type="text"
                                                name="selectedEmployeeName"
                                                value={this.state.selectedEmployeeBranch}
                                                onChange={this.onChangeLocation} 
                                                autoComplete="off"
                                                placeholder="Auto Fill"
                                            />
                                        </Col>
                                    </Form.Group>

                                   
                                    
                                    <Card.Header className="mt-3">Case Details</Card.Header>
                                    <div className="mt-3">
                                    <Form.Group as={Row} controlId="formPlaintextEmail">
                                            <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                DATE CASE FILED
                                            </Form.Label>
                                            <Col sm="10" /* style={{marginLeft : "-1px"}} */>
                                                <Input
                                                    labelKey='year'
                                                    id="basic-example"
                                                    value={this.state.dateCaseFiled}
                                                    className="form-control"
                                                    onChange={this.handleChangedateCaseFiled}
                                                    //placeholder="Enter Case Numeber"
                                                    autocomplete="off"
                                                    
                                                />
                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row} controlId="formPlaintextEmail">
                                            <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                CASE FILED
                                            </Form.Label>
                                            <Col sm="10" /* style={{marginLeft : "-1px"}} */>
                                                <Input
                                                    labelKey='year'
                                                    id="basic-example"
                                                    value={this.state.caseFiled}
                                                    className="form-control"
                                                    onChange={this.handleAddedcaseFiled}
                                                    //placeholder="Enter Date Filed"
                                                    autocomplete="off"
                                                    
                                                />
                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row} controlId="formPlaintextEmail">
                                            <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                VENUE
                                            </Form.Label>
                                            <Col sm="10" /* style={{marginLeft : "-1px"}} */>
                                                <Input
                                                    labelKey='year'
                                                    id="basic-example"
                                                    value={this.state.venue}
                                                    className="form-control"
                                                    onChange={this.handleChangevenue}
                                                    //placeholder="Enter Date Received"
                                                    autocomplete="off"
                                                    
                                                />
                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row} controlId="formPlaintextEmail">
                                            <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                SCHEDULE OF HEARING
                                            </Form.Label>
                                            <Col sm="10" /* style={{marginLeft : "-1px"}} */>
                                                <Input
                                                    labelKey='Complaint'
                                                    id="basic-example"
                                                    value={this.state.SchedOfHearing}
                                                    className="form-control"
                                                    onChange={this.handleAddedSchedOfHearing}
                                                    //placeholder="Enter Complaint"
                                                    autocomplete="off"
                                                />
                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row} controlId="formPlaintextEmail">
                                            <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                PROSECUTOR
                                            </Form.Label>
                                            <Col sm="10" /* style={{marginLeft : "-1px"}} */>
                                                <Input
                                                    labelKey='year'
                                                    id="basic-example"
                                                    value={this.state.prosecutor}
                                                    className="form-control"
                                                    onChange={this.handleChangeprosecutor}
                                                    //placeholder="Enter Schedule Of Hearing"
                                                    autocomplete="off"
                                                />
                                            </Col>
                                           
                                        </Form.Group> 
                                        <Form.Group as={Row} controlId="formPlaintextEmail">
                                            <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                LEGAL
                                            </Form.Label>
                                            <Col sm="10" /* style={{marginLeft : "-1px"}} */>
                                                <Input
                                                    labelKey='year'
                                                    id="basic-example"
                                                    value={this.state.legal}
                                                    className="form-control"
                                                    onChange={this.handlelegal}
                                                    //placeholder="Enter Schedule Of Hearing"
                                                    autocomplete="off"
                                                />
                                            </Col>
                                           
                                        </Form.Group>
                                       
                                    </div>
                                    <Card.Header className="mt-3">UPDATE AND STATUS</Card.Header>

                                    <div className="mt-3">                                       
                                        <Form.Group as={Row} controlId="formPlaintextEmail">
                                            <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                                Latest Update
                                            </Form.Label>
                                            <Col sm="11" /* style={{marginLeft : "-1px"}} */>
                                                <Input
                                                    labelKey='year'
                                                    id="basic-example"
                                                    value={this.state.latestUpdate}
                                                    className="form-control"
                                                    onChange={this.handleChangelatestUpdate}
                                                    //placeholder="Enter Year"
                                                    autocomplete="off"
                                                />
                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row} controlId="formPlaintextEmail">
                                            <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                                Counsel
                                            </Form.Label>
                                            <Col sm="11" /* style={{marginLeft : "-1px"}} */>
                                                <Input
                                                    labelKey='year'
                                                    id="basic-example"
                                                    value={this.state.counsel}
                                                    className="form-control"
                                                    onChange={this.handleOnChangeCounsel}
                                                    //placeholder="Enter Year"
                                                    autocomplete="off"
                                                />
                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row} controlId="formPlaintextEmail">
                                            <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                                DATE
                                            </Form.Label>
                                            <Col sm="2" /* style={{marginLeft : "-1px"}} */>
                                                <Input
                                                    labelKey='year'
                                                    id="basic-example"
                                                    value={this.state.updateDate}
                                                    className="form-control"
                                                    onChange={this.handleChangeupdateDate}
                                                    //placeholder="Enter Year"
                                                    autocomplete="off"
                                                />
                                            </Col>
                                            <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                                REMARKS
                                            </Form.Label>
                                            <Col sm="4" /* style={{marginLeft : "-1px"}} */>
                                                <Input
                                                    labelKey='year'
                                                    id="basic-example"
                                                    value={this.state.remarks}
                                                    className="form-control"
                                                    onChange={this.handleOnChangeremarks}
                                                    //placeholder="Enter Year"
                                                    autocomplete="off"
                                                />
                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row} controlId="formPlaintextEmail">
                                        {/* <ButtonToolbar sm={2}>
                                            <Col className="mr-auto">
                                                <ButtonToolbar className="mt-1">                                                   
                                                    <Button className="mr-auto" variant="success" onClick={this.handleToExportNTE} href="/ExportNTE" style={{minHeight:"18px",minWidth:'60px', marginLeft:"129px", marginTop:"-1px"}}>ADD</Button>                                                   
                                                </ButtonToolbar>                                          
                                            </Col>                                                                      
                                        </ButtonToolbar> */}
                                        </Form.Group>

                                        <Form.Group as={Row} controlId="formPlaintextEmail">
                                            <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                                Status
                                            </Form.Label>
                                            <Col sm="11">
                                                <Typeahead
                                                    labelKey='name'
                                                    id="basic-example"
                                                    onChange={this.onChangeparticular}
                                                    options={this.state.particularList}
                                                    //placeholder="select penalty type"
                                                />
                                            </Col>
                                        </Form.Group> 
                                        
                                    </div>
                                    <Card.Header className="mt-3">DOCUMENTS ATTACHED</Card.Header>

                                    <div className="mt-3">
                                        <Form.Group as={Row} controlId="formPlaintextEmail">
                                            <Col sm="4" >                                                 
                                                <Input type="file" className="file" placeholder="attached file" variant="primary"  onChange={this.handleOnChangeAttachedDoc}/>                                          
                                            </Col>                                            
                                            
                                        </Form.Group>
                                        <Form.Group as={Row} controlId="formPlaintextEmail" className="mt-4">

                                           {/*  <ButtonToolbar sm={2}>
                                                <Col className="mr-auto">
                                                    <ButtonToolbar className="mt-5">                                                   
                                                        <Button className="mr-auto" variant="success" onClick={this.handleToExportNTE} href="/ExportNTE" style={{minHeight:"18px",minWidth:'60px',}}>Add</Button>                                                   
                                                    </ButtonToolbar>                                          
                                                </Col>                                                                      
                                            </ButtonToolbar> */}
                                        
                                        </Form.Group>
                                        <Form.Group as={Col} controlId="formPlaintextEmail" className="mt-4">
                                            
                                            <ButtonToolbar sm={12}>
                                                <Button variant="success" className="ml-auto" style={{minWidth:'60px'}} onClick={this.handleSaveClick}>
                                                    Save
                                                </Button>&nbsp;&nbsp;
                                                <NavLink to="/home">
                                                    <Button variant="danger" href="/home" style={{minWidth:'60px'}}>
                                                        Close
                                                    </Button>
                                                </NavLink>
                                            </ButtonToolbar>
                                            
                                        </Form.Group>
                                    </div>


                                </Form>
                            </Card.Body>
                        </Card>
                       
                    </Container>
                    <NoserLoading show={this.state.isloading} />

                    <InsertCOCReference 
                        show={this.state.modalCoCReferenceShow}
                        onHide={this.handleModalClose}
                    />
            </div> 
        )
    }

}

export  default CriminalCases
