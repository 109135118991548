import {
    React,Component,BootstrapTable,
    paginationFactory,Button, ButtonToolbar, Card, 
    Form, Col, Row, axios, Container, Banner, Typeahead, TimePicker,
    cellEditFactory, Type, moment, NavLink, Alert, AppConfiguration,
    NoserLoading, Noser, sizePerPageRenderer, Redirect, DatePicker
} 
from '../../noser-hris-component';
import TrainingClient from '../Training/TrainingModal/TrainingClient';
import TrainingPosition from '../Training/TrainingModal/TrainingPosition';
import {CSVLink, CSVDownload} from 'react-csv';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit'
import * as XLSX from 'xlsx';

class ClearanceAreUpload extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fade            :   true, 
            color           :   "",
            isshow          :   false,
            message         :   "",
            userinfo        :   [],
            isloading       :   false,
            alerttype       :   "",
            disablebtn      :   true,
            openClientModal :   false,
            openPositionModal:  false,
            clientId        :   "",
            trainingId      :   "",
            trainingDDL     :   [],
            trainingGrid    :   [],
            clientDDL       :   [],
            employeeDDL     :   [],
            statusDDL       :   [],
            statusId      :   "",
            requiredDDL     :   [{"value":"1","label":"MANDATORY"},{"value":"0","label":"OPTIONAL"}],
            gridDataExcel   :   [],
            resignationDate: "",
            clearanceDate: "",
            submissionDate: "",
            workingDate: "",
            toLastWorkingDate : "",
            fromLastWorkingDate : "",
            toDateReceived : "",
            fromDateReceived :"", 
            toClearanceDate : "",
            fromClearanceDate : "",
            toDateCreated : "",
            fromDateCreated : "",
            
            reasonDDL: [],
            batchNumber : "",
            reason : "",
            profileId : "",
            AresUploading : [],
            attachedDDL : [],
            fileInput: [],
            isSelectdFile : "0",
            dataListArray : [],
            
        }
        this.state.userinfo = JSON.parse(sessionStorage.getItem("userData"))
        this.onImageReplyChange = this.onImageReplyChange.bind(this);
    };

    componentDidMount(){
        const sleep = (milliseconds) => {return new Promise(resolve => setTimeout(resolve, milliseconds))}
        this.GetDataGrid()
    };
    GridDataModified(oldValue, newValue, id, column) {
        let disable = true
        this.state.trainingGrid.map(function(item,i) {
            if (item.id===id){
                item.isModified = newValue!=oldValue ? "1" : "0"
                disable = newValue!=oldValue ? false : true
            }
        })
        this.setState({disablebtn:disable})
    };

    

    GetDataGrid = async() => {
        this.setState({isloading:true})
        let param = {
            "IpAddress"         :   "0.0.0.0",
            "ClientId"          :   "",
            "UserId"            :   "35954",
            "ProfileId"            :   ""
        };
        await axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Clearance/GetClearance",  param)
        .then(res => {
            let data = res.data.clearance;
            let filterData = data.filter( x => x.isClearanceStatus === "6")
            console.log('filterData')
            console.log(filterData)
            if(data.length > 0) {
                this.setState({
                    dataListArray : filterData.sort((a, b) => new Date(a.dateCreated) > new Date(b.dateCreated) ? -1 : 1),
                    isloading:false
                })
            }else{
                this.setState(  {
                    isloading       :   false,
                    alerttype       :   "Error!",
                    isshow          :   true,
                    color           :   "danger",
                    message         :   data.message,
                    fade            :   true
                })
            }
        })
        .catch(error=>{
            this.setState(  {
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade            :   true
            })
        })
        
    };



    handleSaveClick = () =>{
        let modifiedArr = this.state.dataListArray
        let griData = this.state.trainingGrid.filter( x => x.isDeleted === "1")
        if (griData.length > 0){
            // // console.log(this.state.trainingGrid)
            this.setState({isloading:true})
            let date = (this.state.datenow) ? moment(this.state.datenow).format('MM/DD/YYYY') : ""
            let arrLst = []
            let dedArr = []

            modifiedArr.forEach(itm => {
                griData.forEach(x => {
                    if(itm.employeeNo === x.employeeNo){
                        arrLst.push({
                            "Id" : itm.id,
                            "ClientId":itm.clientId,
                            "ProfileId":itm.profileId,
                            "EmployeeId":itm.employeeId,
                            "PayPeriodId":itm.payPeriodId,
                            "DateCreated":(itm.dateCreated) ? moment(itm.dateCreated).format('MM/DD/YYYY') : "",
                            "ClearanceDateMember":(itm.clearanceDateMember) ? moment(itm.clearanceDateMember).format('MM/DD/YYYY') : "",
                            "ResignationDateMember":(itm.resignationDateMember) ? moment(itm.resignationDateMember).format('MM/DD/YYYY') : "",
                            "SubmissionDateMember":(itm.submissionDateMember) ? moment(itm.submissionDateMember).format('MM/DD/YYYY') : "",
                            "LastWorkingDateMember":(itm.lastWorkingDateMember) ? moment(itm.lastWorkingDateMember).format('MM/DD/YYYY') : "",
                            "DateSubmitted":(itm.dateSubmitted) ? moment(itm.dateSubmitted).format('MM/DD/YYYY') : "",
                            "IsSSSNumberMember":itm.isSSSNumberMember,
                            "IsTINNumberMember":itm.isTINNumberMember,
                            "IsPHICNumberMember":itm.isPHICNumberMember,
                            "IsHDMFNumberMember":itm.isHDMFNumberMember,
                            "IsCashCardMember":itm.isCashCardMember,
                            "IsValidIdMember":itm.isValidIdMember,
                            "IsResignationLetterMember":itm.isResignationLetterMember,
                            "IsTurnoverChecklistMember":itm.isTurnoverChecklistMember,
                            "IsCOERequestFormMember":itm.isCOERequestFormMember,
                            "IsTypeOfCashCardMember":itm.isTypeOfCashCardMember, // cash card mode of payment
                            "PaycardTypeIdMember":itm.paycardTypeIdMember,
                            "TypeOfCashCardMember":itm.typeOfCashCardMember,
                            "CashCardNumberMember":itm.cashCardNumberMember,
                            "IsCheckMember":itm.isCheckMember,
                            "IsMlhuillierMember":itm.isMlhuillierMember,
                            "IsPalawanMember":itm.isPalawanMember,
                            "RemarksMember":itm.remarksMember,
                            "NotesMember":itm.notesMember,
                            "ReasonForLeaving":itm.reasonForLeaving,
                            "DateForwardedToAmt": itm.dateForwardedToAmt,
                            "DateReceivedFromAmt": itm.dateReceivedFromAmt,
                            "IsEligible":itm.isEligible,
                            "IsNotEligible":itm.isNotEligible,
                            "SssNumber": itm.sssNumber,
                            "TinNumber": itm.tinNumber,
                            "PhicNumber": itm.phicNumber,
                            "HdmfNumber": itm.hdmfNumber,
                            "IsClearedClient":itm.isClearedClient,
                            "IsEligibleClient":itm.isEligibleClient,
                            "IsNonEligibleClient":itm.isNonEligibleClient,
                            "RemarksClient":itm.remarksClient,
            
                            "IsClearedRmt":itm.isNonEligibleClient,
                            "IsReturnedRmt":itm.isNonEligibleClient,
                            "ValidateByRmt":itm.isNonEligibleClient,
                            "IsForwardToARERmt":itm.isNonEligibleClient ,
                            "IsRetrunedServicesRmt":itm.isNonEligibleClient,
                            "IsCheckedByRmt":itm.isNonEligibleClient,
                            "RemarksRmt":itm.remarksRmt,
            
                            "RemarksLrd":itm.remarksLrd,
                            "ServicesRemarks":itm.servicesRemarks ,
            
                            "IsClearanceStatus": itm.isClearanceStatus,
                            "clearanceDetails" :itm.clearanceDetails,
                                
                            /* ADDITIONAL */
                            "DateUpdatedByClient": date,
                            "UpdatedByClient":this.state.userinfo.userId,
            
                            "DateUpdatedByLRD":date,
                            "UpdatedByLRD":this.state.userinfo.userId,
            
                            "DateUpdatedByRMT":date,
                            "UpdatedByRMT":this.state.userinfo.userId,
            
                            "DateUpdatedByServices":date,
                            "UpdatedByServices":this.state.userinfo.userId,
            
                            "DateUpdatedByARE":date,
                            "UpdatedByARE":this.state.userinfo.userId,
            
                            "DateUpdatedByFinance":date,
                            "UpdatedByFinance":this.state.userinfo.userId,
                            "DateSubmittedByStaff":date,
                            "SubmittedByStaff": this.state.userinfo.userId,
                            "DateSubmittedByLead":date,
                            "SubmittedByLead":this.state.userinfo.userId,
            
                            "DateReturnedByClient":itm.dateReturnedByClient,
                            "ReturnedByClient":this.state.userinfo.userId,
                            "DateReturnedByLRD":itm.dateReturnedByLRD,
                            "ReturnedByLRD":this.state.userinfo.userId,
                            "DateReturnedByRMT":itm.dateReturnedByRMT,
                            "ReturnedByRMT":this.state.userinfo.userId,
                            "DateReturnedByServices":itm.dateReturnedByServices,
                            "ReturnedByServices":this.state.userinfo.userId,
                            "DateReturnedByARE":itm.dateReturnedByARE,
                            "ReturnedByARE":this.state.userinfo.userId,
                            "DateReturnedByFinance":itm.dateReturnedByFinance,
                            "ReturnedByFinance":this.state.userinfo.userId,
                            
                            "DateSettle"        : itm.dateSettle,
                            "IsSettleWithDole"  :  itm.isSettleWithDole,
            
                            "DateReceivedByAdmin" : itm.dateReceivedByAdmin,
                            "IsCash" : itm.isCash,
                            "BatchNumber" : itm.batchNumber,
                            "servicesAttachments" : itm.servicesAttachments,
                            "clearanceDeductions":itm.clearanceDeductions,
            
                            
                            // 21/07/2023
                            "ParamountId" : itm.paramountId === "" ? "0" : itm.paramountId,
                            "LastSalary" : itm.lastSalary,
                            "Incentives" : itm.incentives,
                            "Sil" : itm.sil,
                            "ThirteenthMonth" : itm.thirteenthMonth,
                            "Inclusions" : itm.inclusions,
                            "GrossPay" : itm.grossPay,
                            "NetPay" : itm.netPay,
                            "totalBalances" : itm.totalBalances,
                            "PeriodCovered" : itm.periodCovered,
                            "IsModifiedByARE" : itm.isModifiedByARE,
                            "SeparationPay" : itm.separationPay
                            /* END */
                        
                        })
                    }
                })
                // this.setState({ profileId : itm.profileId })
            })
            let param = {
                "IpAddress"             :"0.0.0.0",
                "UserId"                :this.state.userinfo.userId,
                "ClientId"              :this.state.userinfo.clientId,
                "clearance"             :arrLst
            }
            console.log("Submit param")
            console.log(param)
    
            axios
            .post(AppConfiguration.Setting().noserapiendpoint + "Clearance/EditClearance",  param)
            .then(res => {
                let data = res.data;
                this.GetDataGrid()
                this.setState({
                    isloading   :   false,
                    alerttype   :   res.data.status=="1" ? "Success!" : "!Error",
                    isshow      :   true,
                    color       :   res.data.status=="1" ? "success" : "danger",
                    message     :   data.message,
                    fade        :   true
                });
                // this.RefreshPage();
            })
            .catch(error=>{
                this.setState({
                    isloading   :   false,
                    alerttype   :   "Error!",
                    isshow      :   true,
                    color       :   "danger",
                    message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                    fade        :   true
                })
            })
        }else {
            this.setState(  {
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "Please select atleast 1 to process",
                fade            :   true
            })
        }
    }

    GridExcellReports = () =>{
        let newGridData = []
        let dataSelected = this.state.trainingGrid.filter( x=> x.isDeleted === "1")
        let _isSelected = []
        if(dataSelected.length === 0){
            _isSelected = this.state.trainingGrid
        }else{
            _isSelected = dataSelected
        }
        for (let i = 0; i < _isSelected.length; i++){
            let obj = {
                "EMPLOYEE NO."      :   _isSelected[i]["employeeNo"],
                "EMPLOYEE NAME"     :   _isSelected[i]["employeeName"],
                "CLIENT"            :   _isSelected[i]["clientName"],
                "SIL"               :   _isSelected[i]["sil"],
                "13th MONTH"        :   _isSelected[i]["thirteenthMonth"],
                "SEPARATION PAY"    :   _isSelected[i]["separationPay"],
                "PERIOD COVERED"    :   _isSelected[i]["periodCovered"],
                "REMARKS"           :   _isSelected[i]["notesMember"],
            }
            newGridData.push(obj)
        }
        this.setState({gridDataExcel : newGridData})
    }


    
    // UPLOADING FILE
    
    onImageReplyChange(e) {
        // this.setState({ files : this.fileInput.current.files[0]})
        let _excelTemplate = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        let selectedFile = e.target.files[0]
        // console.log("e")
        // console.log(_excelTemplate)
        // console.log("selectedFile.type")
        console.log('selectedFile.type')
        console.log(selectedFile.type)
        if (selectedFile.type !== _excelTemplate){
            this.setState({
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "Invalid files!..",
                fade            :   true
            })
            // return
        }else{
                //
            let reader = new FileReader();
            reader.readAsArrayBuffer(selectedFile)
            reader.onload = (e) =>{
                console.log('e.target.result')
                console.log(e.target.result)
                this.setState(  {
                    isshow          :   false,
                    AresUploading : e.target.result,
                    isSelectdFile : "1"
                })
            };
        }
    };
    handleUploadClick = () =>{
        // alert('test')
        let _excelData = this.state.AresUploading
        // console.log("_excelData")
        // console.log(_excelData)
        if(this.state.isSelectdFile === '1'){
            let workbook = XLSX.read(_excelData,{type: 'buffer'});
            let worksheetName = workbook.SheetNames[0];
            let workSheet = workbook.Sheets[worksheetName];
            let data = XLSX.utils.sheet_to_json(workSheet)
            console.log('data')
            console.log(data)
            let _data = []
            for ( let i = 0; i < data.length; i++ ) {
                let obj = {
                    'employeeNo'        : data[i]['MEMBER NO.'],
                    'employeeName'      : data[i]['MEMBER NAME'],
                    'clientName'        : data[i]['CLIENT'],
                    'sil'               : data[i]['SIL'],
                    'thirteenthMonth'   : data[i]['13TH MONTH'],
                    'separationPay'     : data[i]['SEPARATION PAY'],
                    'periodCovered'     : data[i]['PERIOD COVERED'],
                    'notesMember'       : data[i]['REMARKS'],
                }
                _data.push(obj)
            }
            this.setState({trainingGrid: _data })


        }else{
            this.setState(  {
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "No file selected..",
                fade            :   true
            })
        }
    }

    render() {
        const { navigate } = this.state;
        // if(navigate){
        //     return <Redirect to="/editclearance" push={true}/>
        // }
        const { SearchBar, ClearSearchButton } = Search;
        const trainingColumn = [
            {
                dataField: 'employeeNo',
                text: 'MEMBER NO.',
                editable: false,
                headerStyle : () => {
                    return { width  : "14.29%" };
                },
                searchable: false
            },
            {
                dataField: 'employeeName',
                text: 'MEMBER NAME',
                editable: false,
                headerStyle : () => {
                    return { width  : "14.29%" };
                },
                searchable: false
            },
            {
                dataField: 'clientName',
                text: 'CLIENT',
                editable: false,
                headerStyle : () => {
                    return { width  : "14.29%" };
                },
                searchable: false
            },
            {
                dataField: 'sil',
                text: 'SIL',
                editable: true,
                headerStyle : () => {
                    return { width  : "14.29%", textAlign : "right"  };
                },
                style :{textAlign : "right"},
                searchable: false
            },
            {
                dataField: 'thirteenthMonth',
                text: '13TH MONTH',
                editable: true,
                headerStyle : () => {
                    return { width  : "14.29%", textAlign : "right"  };
                },
                style :{textAlign : "right"},
                searchable: false
            },
            {
                dataField: 'separationPay',
                text: 'SEPARATION PAY',
                editable: true,
                headerStyle : () => {
                    return { width  : "14.29%", textAlign : "right"  };
                },
                style :{textAlign : "right"},
                searchable: false
            },
            {
                dataField: 'periodCovered',
                text: 'PERIOD COVERED',
                editable: true,
                headerStyle : () => {
                    return { width  : "14.29%" };
                },
                searchable: false
            },
            {
                dataField: 'notesMember',
                text: 'REMARKS',
                editable: true,
                headerStyle : () => {
                    return { width  : "14.29%"};
                },
                searchable: false
            },
        ]

        const selectRow = {
            mode: 'checkbox',
            clickToSelectAndEditCell: true,
            onSelect: (row, isSelect, rowIndex, e) => {
                let disable = true
                this.state.trainingGrid.map(function(item,i){
                    if(item.id===row.id)
                    {
                        item.isDeleted = isSelect ? "1" : "0"
                        // item.isModified = isSelect ? "1" : "0"
                        // disable = isSelect ? false : true
                        // console.log(item)
                    }
                })
                this.setState({disablebtn:disable})
            },
            onSelectAll: (isSelect, row) => {
                let isDisable = true
                this.state.trainingGrid.map(function(itm){
                        itm.isDeleted = isSelect ? "1" : "0"
                })
            }
        };

        const selectClientRow = {
            mode: 'checkbox',
            hideSelectAll: true
        };
        const selectPositionRow = {
            mode: 'checkbox',
            hideSelectAll: true
        };
        const selectCertificateRow = {
            mode: 'checkbox',
            hideSelectAll: true
        };

    return(
            <div>
                <Banner />
                <Container className="mt-5" fluid>
                    <Card>
                        <Card.Header>CLEARANCE &#62;&#62; AR-E UPLOAD </Card.Header>
                        <Card.Body>
                            <Form>
                                <Alert isOpen={this.state.isshow} color={this.state.color} fade={this.state.fade} className={this.state.isshow ? 'display-block' : 'display-none'}>
                                    <div className={this.state.color}></div> 
                                    {this.state.message}
                                </Alert>
                                <div className="mt-4">
                                    <div>     
                                        <Row className='mt-2 mb-4'>
                                            <Col sm={1}>
                                                <Form.Label  className="mt-2" style={{fontWeight : "bold"}}>UPLOAD FILE</Form.Label>
                                            </Col>
                                            <Col sm={2}>
                                                {/*<Form.Control   type="file" multiple /> */}
                                                <input ref={/* ref=>  */this.fileInput/*  = ref */} name="file" className="mr-auto" type="file" onChange={this.onImageReplyChange}/>
                                            </Col>
                                            <Col sm={2}>
                                                <Button variant="info" className="ml-auto noser-button-mr1 noser-button" onClick={this.handleUploadClick} /* disabled = { this.state.userinfo.lastName === "TEAMLEAD02" || this.state.userinfo.lastName === "LDTEAMLEADO1" ? false : true } */>
                                                    UPLOAD
                                                </Button>
                                            </Col>
                                            <Col SM={5}></Col>
                                        </Row>                   
                                        <BootstrapTable
                                            /* caption={Noser.TableHeader({title:"RECORD"})} */
                                            keyField = "id"
                                            data = { this.state.trainingGrid }
                                            columns = { trainingColumn }
                                            pagination={ paginationFactory({sizePerPageRenderer}) }
                                            noDataIndication={ () => <div style={{width:'100%'}}>No record found.</div> }
                                            rowClasses="noser-table-row-class"
                                            striped
                                            hover
                                            condensed
                                            cellEdit = { cellEditFactory({
                                                mode: 'dbclick',
                                                blurToSave: true,
                                                afterSaveCell: (oldValue, newValue, row, column) => {
                                                    this.GridDataModified(oldValue, newValue, row.id, column.dataField)
                                                    }
                                                })
                                            }
                                            selectRow = { selectRow }
                                            //expandRow={ expandItems }
                                        />
                                    </div>
                                </div>
                                <ButtonToolbar sm={12}>
                                        <Button variant="info" className="ml-auto noser-button-mr1" >
                                            <CSVLink style={{ color : "white" }} onClick ={this.GridExcellReports} filename= "AR-E UPLOADING.xls" data={this.state.gridDataExcel}>Export</CSVLink>
                                        </Button>
                                    <Button variant="success" className="noser-button-mr1" disabled={true} onClick={this.handleSaveClick}> SUBMIT </Button>
                                    <Button className="noser-button" variant="danger" href="/batchclearanceare"> BACK </Button>
                                </ButtonToolbar>
                            </Form>
                        </Card.Body>
                    </Card>
                </Container>
                <NoserLoading show={this.state.isloading} />
            </div> 
        )
    }

}

export  default ClearanceAreUpload 
