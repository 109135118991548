import {
    React,Component,BootstrapTable,
    paginationFactory,Button, ButtonToolbar, Card, 
    Form, Col, Row, axios, Container, Banner, Typeahead, TimePicker,
    cellEditFactory, Type, moment, NavLink, Alert, AppConfiguration,
    NoserLoading, Noser, sizePerPageRenderer, DatePicker, Modal
} 
from '../../../noser-hris-component';

class CreateClearance extends Component {
    constructor(props) {
        super(props)
        this.ref = React.createRef();
        this.state = {
            userinfo    :   JSON.parse(sessionStorage.getItem("userData")),
            isloading   :   false,
            isshow      :   false,
            alerttype   :   "",
            message     :   "",
            color       :   "",
            fade        :   true,
        }
    }
    componentDidMount(){
        this.state.userinfo = JSON.parse(sessionStorage.getItem("userData"))
        this.props.onRefModal(this)
    }
    componentWillUnmount() {
        this.props.onRefModal(undefined)
    }
    initialize=(e)=>{
        this.setState({
            userinfo    :   JSON.parse(sessionStorage.getItem("userData")),
            isloading   :   false,
            isshow      :   false,
            alerttype   :   "",
            message     :   "",
            color       :   "",
            fade        :   true,
            clientDDL       : [],  
            clientId : "",
            employeeDDL     : [],
            profileId : "",
            employeeId : "",
            disengagementModeDDL : [],
            disengagementModeId : "",
            date: "",
        })
        //this.GetData();
        this.GetClient(); 
    }

    GetClient = async() =>{
        this.setState({isloading:true})
        const params = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.userinfo.clientId,
            "UserId":this.state.userinfo.userId,
            "EmployeeId":this.state.userinfo.roleId==="1" || 
                         this.state.userinfo.roleId==="8" ? "" : this.state.userinfo.employeeId
        }
        console.log("params")
        console.log(params)
        await axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Client/GetClientList", params)
        //.post(AppConfiguration.Setting().noserapiendpoint + "Client/GetClientPerAccess", params)
        .then(res => {
            const data = res.data;
            let arr = []
            if(this.state.userinfo.roleId!=="1"){
                let fieldaccess = JSON.parse(sessionStorage.getItem("userControlAccess"))
                if(fieldaccess.filter(x=>x.accessCode==="0001").length>0){
                    let access = fieldaccess.find(x=>x.accessCode==="0001" && x.controlAccessDetails.length>0)
                    //////console.log("access")
                    //////console.log(access)
                    if(access.controlAccessDetails.filter(x=>x.isClient==="1").length>0){
                        let brr = access.controlAccessDetails.filter(x=>x.isClient==="1")
                        arr = data.clients.filter(f => brr.some(item => item.referenceId === f.id))
                    }
                }
                this.setState({clientDDL : arr,isloading   :   false})
                console.log("arr")
                console.log(arr)
            }
            else{
                this.setState({clientDDL : data.clients,isloading   :   false})
                console.log("data")
                console.log(data)
            }
        })
        .catch(error=>{
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade        :   true
            })
        })
        this.GetDisengagementMode();
   }

   
    
   handleChangeClient = (e) => {
       if(e.length == 0) {
           this.state.clientId     =   ""
           this.state.clientName   =   ""
           return
       }
       this.state.clientId     =   e[0].id
       this.state.clientName   =   e[0].name
        this.setState({
            isshow:false,
        })
       this.GetEmployees();
   }

   GetEmployees = () => {
        this.setState({isloading:true})
        const getParams = {
            "IpAddress"     :   "0.0.0.0",
            "ClientId"      :   this.state.clientId,
            "UserId"        :   this.state.userinfo.userId,
            "EmployeeName"  :   "",
            "EmployeeNo"    :   "",
            "ClientName"    :   ""
        }
        axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Employee/GetEmployees",  getParams)
        .then(res => {
            const data = res.data
            console.log("GetEmployees")
            console.log(data)
            this.setState({
                employeeDDL        :   data.employees,
                isloading           :   false,
            }) 
        })
        .catch(error=>{
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade        :   true
            })
        }) 
        
    }


    handleChangeEmployee = (e) => {
        
        if(e.length == 0) {
            this.state.employeeId    =   ""
            this.state.profileId            =   ""
            // this.state.referenceNo          =   ""
            return
        }
        this.state.employeeId      =   e[0].id
        this.state.profileId            =   e[0].profileId
        // this.state.referenceNo          =   e[0].referenceNo
        this.setState({
            isshow:false,
        })
        //this.GetApplicationList();
    }

    GetDisengagementMode = async() => {
        this.setState({isloading:true})
        const param = {
            "IpAddress"         :   "0.0.0.0",
            "UserId"            :   this.state.userinfo.userId,
            "ClientId"          :   this.state.userinfo.clientId,
            "DisengagementId"   :   "",
        };
        await axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Maintenance/GetDisengagementMode",  param)
        .then(res => {
            const data = res.data.disengagementMode;
            console.log("DisengagementMode")
            console.log(data)
            this.setState({
                dataGrid            : data,
                isloading           : false,
                disengagementModeDDL: data,
            });
            if(data.status==="0"){
                this.setState(  {
                    isloading       :   false,
                    alerttype       :   "Error!",
                    isshow          :   true,
                    color           :   "danger",
                    message         :   data.message,
                    fade            :   true
                })
            }
        })
        .catch(error=>{
            this.setState(  {
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade            :   true
            })
        })
    }
    handleChangedDisengagementMode = (e) => {
        this.setState({isloading:false,alerttype:'',isshow:false,color:'',message:'',fade:true})
        if(e.length == 0) {
            this.state.disengagementModeId=""
            return
        } 
        this.state.disengagementModeId = e[0].id
    }

    handleChangeDate = date => {
        this.setState({date: date,isshow:false})
    };
    handleSubmit = () => {
        this.setState({isloading:true})

        const param = {
            "IpAddress"             :"0.0.0.0",
            "ClientId"              :this.state.clientId,
            "UserId"                :this.state.userinfo.userId,
            "ProfileId"             :this.state.profileId,
            "EmployeeId"            :this.state.employeeId,
            "DisengagementModeId"   :this.state.disengagementModeId,
            "Date"                  :moment(this.state.date).format('MM/DD/YYYY'),
        }
        console.log("param")
        console.log(param)
        
        axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Clearance/AddClearance",  param)
        .then(res => {
            const data = res.data;
            this.setState({
                isloading   :   false,
                alerttype   :   res.data.status=="1" ? "Success!" : "!Error",
                isshow      :   true,
                color       :   res.data.status=="1" ? "success" : "danger",
                message     :   data.message,
                fade        :   true
            });
            //this.GetData();
        })
        .catch(error=>{
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade        :   true
            })
        })
    }    
    handleModalClose = () => {
        this.props.onHide();            
    }
    render() {
    return(
        
            <Modal
                {...this.props}
                return
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                backdrop="static"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Create Employee Clearance
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container>
                        <Alert isOpen={this.state.isshow} color={this.state.color} fade={this.state.fade} className={this.state.isshow ? 'display-block' : 'display-none'}>
                            <div className={this.state.color}></div> 
                            {this.state.message}
                        </Alert>
                            <Form.Group as={Row} controlId="formPlaintextEmail">
                                <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                    CLIENT
                                </Form.Label>
                                <Col>
                                    <Typeahead
                                        labelKey='name'
                                        id="basic-example"
                                        onChange={this.handleChangeClient}
                                        options={this.state.clientDDL}
                                    />
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} controlId="formPlaintextEmail">
                                <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                EMPLOYEE
                                </Form.Label>
                                <Col>
                                    <Typeahead 
                                        labelKey='employeeName'
                                        id="basic-example"
                                        onChange={this.handleChangeEmployee}
                                        options={this.state.employeeDDL} 
                                    />
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} controlId="formPlaintextEmail">
                                <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                DISENGAGEMENT MODE
                                </Form.Label>
                                <Col>
                                <Typeahead
                                    labelKey='name'
                                    id="basic-example"
                                    onChange={this.handleChangedDisengagementMode}
                                    options={this.state.disengagementModeDDL}
                                />
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} controlId="formPlaintextEmail">
                                <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                DATE
                                </Form.Label>
                                <Col>
                                    <DatePicker
                                        ref='date'
                                        selected={this.state.date}
                                        onChange={this.handleChangeDate}
                                        minDate={this.minDate}
                                        value={this.props.date}
                                        dateFormat={"MM/dd/yyyy"}
                                        className="form-control"
                                    /> 
                                </Col>
                            </Form.Group>
                    </Container>
                </Modal.Body>
                <Modal.Footer>
                    <ButtonToolbar >
                        <Button variant="success" className="ml-auto noser-button-mr1" onClick = { this.handleSubmit }>Save</Button>
                        <Button variant="danger" className="noser-button-mr15" onClick={this.props.onHide}>Close</Button>
                    </ButtonToolbar>
                </Modal.Footer>
                <NoserLoading show={this.state.isloading} />
        </Modal>
        );
    }
}
export default CreateClearance