import {
    React,Component,BootstrapTable,
    paginationFactory,Button, ButtonToolbar, Card, 
    Form, Col, Row, axios, Container, Banner, Typeahead, TimePicker,
    cellEditFactory, Type, moment, NavLink, Alert, AppConfiguration,
    NoserLoading, Noser, sizePerPageRenderer, useState, Tabs, Tab, DatePicker
} 
from '../../noser-hris-component';

class EmployeeConfig extends Component {
    constructor(props) {
        super(props)

        let selectedOpt=''
        this.state = {
            userinfo        :   [],
            isloading       :   false,
            isshow          :   false,
            alerttype       :   "",
            message         :   "",
            color           :   "",
            fade            :   true,

            addModalShow: false,
            addModalShowRestDay: false,
            employeeLeaveTable : [],
            employeeRestDayTable : [],
            employeeWorkScheduleTable : [],
            getClientAutocomplete : [],
            GetClientLocationsAutocomplete: [],
            clientLocationList: [],
            getEmployeeList : [],
            getClientList : [],
            selectedClientName: "",
            selectedEmployeeName: "",
            selectedEmployeeId: "",
            periodId: "",
            getPayrollPeriodId: [],
            selectedClientId: [],
            employeeList: "",
            rpcList: "",
            filterLocation:"",
            EmployeeNoList: "",
            getEmployeeNoList: [],
            EemployeeListselected: [],
            selectedClient: [],
            selectedClientLocation: [],
            payTypeDate: "",
            PayModesDate: "",
            BranchDate: "",
            PeriodTypesDate: "",
            SalaryRateDate: "",
            StatusDate: "",
            TeamLeadDate: "",
            CoordinatorDate: "",
            WorkScheduleDate: "",
            RestDate: new Date(),
            FromDate: new Date(),
            getPayTypesList: [],
            getPayModesList: [],
            getPayStatusList: [],
            allDays: [],
            employeeConfigAuto : [
                { name: "Coffee" }
            ],
            checked : false,
            date: "1990-06-05",
            format: "YYYY-MM-DD",
            inputFormat: "DD/MM/YYYY",
            mode: "date",
            getEmployeesTeamLeadList: [
                { employeeName: "N/A" },
            ],
            getEmployeesCoordinatorList: [],
            salaryRate : '',
            getWorkScheduleList: [],
            selectedWorkScheduleIdParam: "",
            payPeriodList: [],
            payPeriodSelectedId: '',
            payPeriodSelected: '',
            getCurrentMonthly: '',
            getEmployeeListData: [],
            getPayTypes: '',
            getPayModes: '',
            getLocationNames: '',
            getEmployeeStatusTypes: '',
            getSalaryOffered: '',
            PeriodTypesId: [],
            currentrestdateAdded: [],
            FromDate: new Date(),
            ToDate: new Date(),
            LeavedayDataList: [],
            leaveList: [],
            getEmployeeLeaveList: [],
            isChecked: "1",
            duration: "",
            reason: "",
            currentLeavedateAdded: [],

            currentTeamLead:"",
            currentCoordinator:"",
            getPayCardTypeList: [
                { name: "BDO" },
                { name: "Metrobank" },
                { name: "MLhuillier" },
            ],
            getPayCardTypesList:[],
            PayCardType: "",
            PayCardNumber: "",
            txtpayCardNumber: "",

            sssAmount: '',
            sssAmountDate   : '',
            phicAmount: '',
            phicAmountDate: '',
            hdmfAmount: '',
            hdmfAmountDate: '',

            sssBrackets : [],

            ecolaValue : '',
            ecolaValueDate : '',
            colaValue : '',
            colaValueDate : '',
            seaRate : '',
            seaValueDate : '',

            positionAutocomplete    :   [],
            PositionDate            :   '',
            reasonToLeave : [
                { "name" : "SICK LEAVE" },
                { "name" : "VACATION LEAVE" },
                { "name" : "PATERNITY LEAVE" },
                { "name" : "MATERNITY LEAVE" },
            ],
            required:true,
            deactivate:false,
            isDisable : false,
            isWith201 : true,
            mobileNumber:'',
            txtmobileNumber:'',
            firstName:'',
            middleName:'',
            lastName:'',
            txtFirstName:'',
            txtMiddleName:'',
            txtLastName:'',
            disablePayCardNumber:false,
            locationDDL:[],
            workscheduleDDL:[],
            selectedSchedules:[],
            disablebutton:true,

            overtimeScheduleGRID:[],
            scheduleDate : new Date(),
            leaveTypesDDL:[],
            leaveTypeId:"",
            remarks:"",

            hasEditSalaryAccess: true,
            hasViewSalaryAccess: false,
            hasEditPayCardTypeAccess: true,
            hasViewPayCardTypeAccess: false,
            hasEditPayCardNumberAccess: true,
            hasViewPayCardNumberAccess: false
        }
}

/**************************** START LEAVE **************************************/

handleChangeFromDate = date => {
    this.setState({FromDate: date,isshow:false});
};
onChangeDuration = (e) => {
    this.setState({duration: e.target.value,isshow:false});
}
onChangeReason = (e) => {
    this.setState({ reason: e.target.value} );
}
handleChangeCheckboxLeave(e) {
    let isChecked = e.target.checked ? "0" : "1";
    this.setState({isChecked: isChecked})
}
handleChangeToDate = date => {
    this.setState({ToDate: date});
};
onChangeReasonToLeave = (e) => {
    if(e.length == 0) {
        this.state.selectedReasonToLeave = ""
        this.state.leaveTypeId = ""
        return
    }
    this.state.leaveTypeId = e[0].id  
    this.state.selectedReasonToLeave = e[0].name
    this.state.leaveTypeId = e[0].id
    this.setState({
        isshow          :   false,
    })
}
onSubmitAddLeave = (e) => {
    const {LeavedayDataList} = this.state
    let fromThisDate = moment(this.state.FromDate).format('MM/DD/YYYY');
    let lstLeaveday = this.state.LeavedayDataList
    let wholeday = Math.floor(this.state.duration)
    let halfday = this.state.duration % 1
    let totalduration = 0
    let duration =0

    if(this.state.duration=="")
    {
        this.setState({
            isloading       :   false,
            alerttype       :   "Warning!",
            isshow          :   true,
            color           :   "warning",
            message         :   "Please enter leave duration.",
            fade            :   true
        })
        return
    }
    if(this.state.fromThisDate=="")
    {
        this.setState({
            isloading       :   false,
            alerttype       :   "Warning!",
            isshow          :   true,
            color           :   "warning",
            message         :   "Please enter leave date.",
            fade            :   true
        })
        return
    }
    if(!this.state.selectedReasonToLeave)
    {
        this.setState({
            isloading       :   false,
            alerttype       :   "Warning!",
            isshow          :   true,
            color           :   "warning",
            message         :   "Pleas select reason for leave.",
            fade            :   true
        })
        return
    }
    
    if(halfday>0)
        totalduration = wholeday+1
    else
        totalduration = wholeday

    
    for(let i=1;i<=totalduration;i++)
    {
        if(i==totalduration && halfday>0)
        duration = halfday
        else
        duration=1

        let newId = 0;
        if(LeavedayDataList.length===0){
            let today = new Date()
            newId = moment(today).format('HHMMSS');
        }
        else{
            let today = new Date()
            const ids = LeavedayDataList.map(x => x.id);
            const sorted = ids.sort((a, b) => a - b);
            const uid = moment(today).format('SS');
            newId = parseInt(sorted[sorted.length-1])+1+uid;
        }

        const obj = {
            "id" :newId.toString(),
            "EmployeeId":this.state.selectedEmployeeId,
            "leaveDate" : fromThisDate,
            "mandays" : duration.toString(),
            "reason" : this.state.selectedReasonToLeave,
            "leaveTypeId" : this.state.leaveTypeId,
            "isPaid" : this.state.isChecked,
            "isPaidLabel" : this.state.isChecked=="1" ? "PAID" : "UNPAID",
            "isDeleted" : "0"
        }
        if(lstLeaveday.length>0)
        {
            for(let i=0;i<lstLeaveday.length;i++)
            {
                if(lstLeaveday[i].leaveDate==fromThisDate){
                    this.setState({
                        isloading       :   false,
                        alerttype       :   "Warning!",
                        isshow          :   true,
                        color           :   "warning",
                        message         :   "Selected date already exist.",
                        fade            :   true
                    })
                    return 
                }
            }
        }
        LeavedayDataList.push(obj)
        this.setState({LeavedayDataList: LeavedayDataList.sort((a, b) => parseInt(b["id"]) - parseInt(a["id"]))})
        fromThisDate = moment(fromThisDate).add(1, 'day').format('MM/DD/YYYY');
    }
}

onSubmitLeaveRemove =() => {
    let lstleaveday = this.state.LeavedayDataList
    for( var i = 0; i < lstleaveday.length; i++){ 
        if ( lstleaveday[i].IsSelected === 1) {
            lstleaveday.splice(i, 1); 
          i--;
        }
     }
    
    this.setState({LeavedayDataList:lstleaveday})
}
onSubmitRemove =()=>{
    let lstRestday = this.state.employeeRestDayTable
    for( var i = 0; i < lstRestday.length; i++){ 
        if ( lstRestday[i].IsSelected === 1) {
            lstRestday.splice(i, 1); 
          i--;
        }
     }
    
    this.setState({employeeRestDayTable:lstRestday})
}

/**************************** END LEAVE **************************************/

GetPeriodTypesId(id) {
    let GetpayTypesId = ''
    for (let i = 0; i <= this.state.PeriodTypesId.length; i++) {
        if (this.state.PeriodTypesId[i]["id"] === id) {
            GetpayTypesId = this.state.PeriodTypesId[i]['id']; 
            break;
        }
    }
    return GetpayTypesId
}

handleChangePeriodTypesId = (e) => {
    if(e.length === "") {
        this.setState({
            PeriodTypesId : ""
        })
    } else {
        if (e.length > 0) {
            this.state.selectedGetPayTypesId = this.GetPeriodTypesId(e[0].id)
        }
    }
}
GetPeriodTypes(){
    const getParams = {
        "IpAddress":"0.0.0.0",
        "ClientId":this.state.selectedClientId,
        "UserId":this.state.userinfo.userId,
        "PayModeId":this.state.selectedEmployeePayModesId,
    }
    axios
    .post(AppConfiguration.Setting().noserapiendpoint + "Timekeeping/GetPeriodTypes", getParams)
    .then(res => {
        const data = res.data
        if(data.status=="1")
            this.setState({PeriodTypesId : data.periodTypes,isloading:false}) 
        else
            this.setState({PeriodTypesId : [],isloading:false}) 
    })
    .catch(error=>{
        this.setState(
        { 
            isloading:false,
            alertType:"Error! ", 
            isShow:true,
            color:"danger",
            message: "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
            fade:true
        })  
    })
}

componentDidMount(){
    this.setState({isloading:true})
    this.state.userinfo = JSON.parse(sessionStorage.getItem("userData"))
    this.getClientList();
    this.GetPayCardTypes();
    this.GetEmployeeStatusList();
    this.GetEmployeePayTypesList();
    this.GetEmployeePayModesList();
    this.getSSSBackets();
    this.GetPosition();
    //this.setState({selectedOptions:'NORTHSTAR MEAT MERCHANTS - WALTERMART'}) //not working in typeahead

    if(this.state.userinfo.roleId==="1"){
        this.state.hasEditSalaryAccess = false
        this.state.hasViewSalaryAccess = true
        this.state.hasEditPayCardTypeAccess = false
        this.state.hasViewPayCardTypeAccess = true
        this.state.hasEditPayCardNumberAccess = false
        this.state.hasViewPayCardNumberAccess = true
    }
} 

componentWillMount(){
    //console.log('will mount')
    //this.setState({selectedOptions:'TEST DEFAULT DISPLAY'}) //working in typeahead
}
GetPayCardTypes(){
    const getParams = {
        "IpAddress":"0.0.0.0",
        "ClientId":this.state.userinfo.clientId,
        "UserId":this.state.userinfo.userId,
        
    }
    axios
    .post(AppConfiguration.Setting().noserapiendpoint + "Maintenance/GetPayCardTypes", getParams)
    .then(res => {
        const data = res.data
        this.setState(
            {
                isloading:false,
                getPayCardTypesList : data.payCardTypes ? data.payCardTypes : []
            });
    })
    .catch(error=>{
        this.setState(
        { 
            isloading:false,
            alertType:"Error! ", 
            isShow:true,
            color:"danger",
            message: "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
            fade:true
        })  
    })
}
onChangePayCardType = (e) => {
    if(e.length == 0) {
        this.state.selectedPayCardTypeId=""
        return
    } 
    //this.state.selectedPayCardTypeId = e[0].id
    if(e[0].id=="4"){
        this.setState({disablePayCardNumber:true,selectedPayCardTypeId:e[0].id})
    } 
    else{
        this.setState({disablePayCardNumber:false,selectedPayCardTypeId:e[0].id})
    }
}
onChangeEmployees = (e) => {
    if(e.length ==0) {
        this.setState({employeeList : ""})
        return
    }

}
handleChangeCheckbox = () => {
    this.setState({checked: !this.state.checked})
}
getParamountEmployees(){
    const getParams = {
        "IpAddress":"0.0.0.0",
        "ClientId":"111",
        "UserId":this.state.userinfo.userId,
        "EmployeeName":"",
        "EmployeeNo":"",
        "ClientName":""
    }
    axios
    .post(AppConfiguration.Setting().noserapiendpoint + "Employee/GetEmployees", getParams)
    .then(res => {
        const data = res.data
        if(data.status=="1")
        {
            let employees = this.state.getEmployeeList
            let teamLeadEmployees = employees.concat(res.data.employees)
            this.setState({getEmployeesCoordinatorList : data.employees,getEmployeesTeamLeadList: teamLeadEmployees,isloading:false}) 
        }
        else
            this.setState({getEmployeesCoordinatorList : [],getEmployeesTeamLeadList:[],isloading:false}) 
        
    })
    .catch(error=>{
        this.setState(
        { 
            isloading:false,
            alertType:"Error! ", 
            isShow:true,
            color:"danger",
            message: "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
            fade:true
        })  
    })
}

onChangeClientList = (e) => {
    if(e.length == 0) {
        this.state.selectedClientName=""
        this.state.selectedClientId=""
        return
    } 
    this.setState({isloading:true})
    this.state.selectedClientId = e[0].id
    this.state.selectedClientName = e[0].name
    this.getEmployees();
    this.getWorkSchedule();
    this.getClientLocation();
    
    /*
    let objArr = [{id:1,name:'PASELA, ROSSWELL CHARA'}]
    let nm = objArr.find(x=>x.id=1).name
    this.setState({selectedOptions:[nm]})
    */
}
onChangePayPeriod = (e) => {
    if(e.length == 0) {
        this.state.selectedPayrollPeriodsId = ""
        return
    }
    this.state.selectedPayrollPeriodsId = e[0].periodId
    this.employeeWorkScheduleTable()
}
getEmployeesData(){
    const getParams = {
        "IpAddress":"0.0.0.0",
        "ClientId":this.state.selectedClientId,
        "UserId":this.state.userinfo.userId,
        "EmployeeName":this.state.selectedEmployeeName,
        "EmployeeNo":this.state.selectedEmployeeNo,
        "ClientName":this.state.selectedClientName
    }
    axios
    .post(AppConfiguration.Setting().noserapiendpoint + "Employee/GetEmployees", getParams)
    .then(res => {
        const data = res.data
        if(data.status=="1")
        {
            this.setState({getEmployeeListData : data.employees, isloading:false})
        }
        else
        {
            this.setState({getEmployeeListData : [],isloading:false}) 
        }

        
    })
    .catch(error=>{
        this.setState(
        { 
            isloading:false,
            alertType:"Error! ", 
            isShow:true,
            color:"danger",
            message: "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
            fade:true
        })  
    }) 
    
}
clearCurrentEmployeeData() {
    this.setState({
        getPayTypes : "",
        getPayModes: "",
        getLocationNames: "",
        getEmployeeStatusTypes: "",
        getSalaryOffered: "",
        getWorkSchedule: "",

        getSSSAmount : "",
        getPHICAmount: "",
        getHDMFAmount: "",
        gePayCardType: "",
        getPeriodType: "",
        getPayCardNumber: "",
        getPosition :   "",
        currentTeamLead : "",
        currentCoordinator: "",
    })
}
onChangeEmployeesNo(e){
    this.setState({selectedEmployeeNo : e.target.value})
}

onChangeEmployeesList = (e) => {
    if(e.length==0)
    {
        this.setState({getEmployeeListData: null, selectedWorkScheduleIdParam: '', selectedPayrollPeriodsId : '',selectedEmployeeId : '',selectedEmployeeName : '',selectedPayrollPeriodsName : '', selectedWorkScheduleId: ''})
        this.clearCurrentEmployeeData()
        return
    }

    let fieldaccess = JSON.parse(sessionStorage.getItem("userControlAccess"))
    console.log(fieldaccess)
    
    if(fieldaccess.filter(x=>x.accessCode==="0001").length>0){

        if(fieldaccess.filter(x=>x.accessCode==="0001" && x.fieldName==="SalaryRate" ).length>0){
            let access = fieldaccess.find(x=>x.accessCode==="0001" && x.fieldName==="SalaryRate")
            if(access.controlAccessDetails.filter(x=>x.isClient==="1" && x.referenceId===e[0].clientId).length>0){
                this.state.hasEditSalaryAccess = access.hasEdit==="1" ? false : true
                this.state.hasViewSalaryAccess = access.hasView==="1" ? true : false
            }
            if(access.controlAccessDetails.filter(x=>x.isClient==="0" && x.referenceId===e[0].positionId).length>0){
                this.state.hasEditSalaryAccess = access.hasEdit==="1" ? false : true
                this.state.hasViewSalaryAccess = access.hasView==="1" ? true : false
            }
        }

        if(fieldaccess.filter(x=>x.accessCode==="0001" && x.fieldName==="PayCardType" ).length>0){
            let access = fieldaccess.find(x=>x.accessCode==="0001" && x.fieldName==="PayCardType")
            if(access.controlAccessDetails.filter(x=>x.isClient==="1" && x.referenceId===e[0].clientId).length>0){
                this.state.hasEditPayCardTypeAccess = access.hasEdit==="1" ? false : true
                this.state.hasViewPayCardTypeAccess = access.hasView==="1" ? true : false
            }
            if(access.controlAccessDetails.filter(x=>x.isClient==="0" && x.referenceId===e[0].positionId).length>0){
                this.state.hasEditSalaryAccess = access.hasEdit==="1" ? false : true
                this.state.hasViewSalaryAccess = access.hasView==="1" ? true : false
            }
        }

        if(fieldaccess.filter(x=>x.accessCode==="0001" && x.fieldName==="PayCardNumber" ).length>0){
            let access = fieldaccess.find(x=>x.accessCode==="0001" && x.fieldName==="PayCardNumber")
            if(access.controlAccessDetails.filter(x=>x.isClient==="1" && x.referenceId===e[0].positionId).length>0){
                this.state.hasEditPayCardNumberAccess = access.hasEdit==="1" ? false : true
                this.state.hasViewPayCardNumberAccess = access.hasView==="1" ? true : false
            }
            if(access.controlAccessDetails.filter(x=>x.isClient==="0" && x.referenceId===e[0].positionId).length>0){
                this.state.hasEditSalaryAccess = access.hasEdit==="1" ? false : true
                this.state.hasViewSalaryAccess = access.hasView==="1" ? true : false
            }
        }
    }

    this.setState({isloading:true})
    this.state.selectedEmployeeId = e[0].id
    this.state.selectedPayrollPeriodsId = e[0].payPeriodId
    this.state.selectedEmployeeName = e[0].employeeName
    this.state.selectedEmployeeNo = e[0].employeeNo
    this.state.selectedWorkScheduleIdParam = e[0].id

    this.state.getPayTypes = e[0].payType
    this.state.getPayModes = e[0].payMode
    this.state.getLocationNames = e[0].locationName
    this.state.getEmployeeStatusTypes = e[0].employeeStatusType
    this.state.getSalaryOffered  = e[0].salaryOffered
    this.state.getWorkSchedule = e[0].workSchedule

    this.state.getSSSAmount = e[0].sssAmount
    this.state.getPHICAmount = e[0].phicAmount
    this.state.getHDMFAmount = e[0].hdmfAmount
    this.state.getColaAmount = e[0].colaValue
    this.state.getEColaAmount = e[0].ecolaValue
    this.state.getSeaAmount = e[0].seaValue
    this.state.gePayCardType = e[0].payCardType
    this.state.getPeriodType = e[0].periodType
    this.state.getPayCardNumber = e[0].payCardTypeId=="4" ? "" : e[0].payCardNumber
    this.state.disablePayCardNumber = e[0].payCardTypeId=="4" ? true : false
    this.state.getPosition = e[0].position
    this.state.getPosition = e[0].position
    this.state.getPosition = e[0].position
    this.state.mobileNumber =e[0].mobileNumber
    this.state.firstName =e[0].firstName
    this.state.middleName =e[0].middleName
    this.state.lastName =e[0].lastName
    this.state.remarks=e[0].remarks
    this.state.dateOfBirth = moment(e[0].dateOfBirth).format('MM/DD/YYYY')
    this.state.contractDateStart = moment(e[0].contractDateStart).format('MM/DD/YYYY')
    this.state.contractDateEnd = moment(e[0].contractDateEnd).format('MM/DD/YYYY')
    this.state.deactivate = e[0].isDeleted=="1" ? true : false
    this.state.isDisable = e[0].isDeleted=="1" ? true : false
    this.state.IsWith201 = e[0].statusId=="1" ? false : true
    this.GetEmployeePayrollPeriods();
    
    this.GetPayperiodPeriodsId(e[0].payPeriodId);
    this.GetPayPeriodList()
    this.getEmployeesData();
    this.getEmployeesTeamLead();
    this.getEmployeesCoordinator();
    this.GetEmployeeConfiguration();
    this.employeeWorkScheduleTable();
    this.GetLeaveTypes();
}
GetEmployeeConfiguration(){
    const configParams = {
        "IpAddress":"0.0.0.0",
        "ClientId":this.state.userinfo.clientId,
        "UserId":this.state.userinfo.userId,
        "EmployeeId": this.state.selectedEmployeeId
     };
     axios
         .post(
             AppConfiguration.Setting().noserapiendpoint + "Employee/GetConfigurations",  configParams
         )
         .then(res => {
            const data = res.data
            console.log(data)
            if(data.status=="1"){
                this.setState({currentTeamLead: data.teamLead, 
                    currentCoordinator: data.coordinator,
                    employeeRestDayTable:data.restdays,
                    LeavedayDataList:data.leaves,
                    overtimeScheduleGRID:data.overtimeSchedules
                })

            }else
            {
                this.setState({currentTeamLead: [], 
                    currentCoordinator: [],
                    employeeRestDayTable: [],
                    LeavedayDataList: [],
                    overtimeScheduleGRID:[]
                })
            }
         })
}
GetPayPeriodList() {
    const periodListParams = {
        "IpAddress":"0.0.0.0",
        "ClientId":this.state.userinfo.clientId,
        "UserId":this.state.userinfo.userId,
        "EmployeeId": this.state.selectedEmployeeId
     };
     axios
         .post(
             AppConfiguration.Setting().noserapiendpoint + "Timekeeping/GetPayrollPeriods",  periodListParams
         )
         .then(res => {
            const data = res.data;
            this.setState({payPeriodList: data.payrollPeriods})
         })
}
getWorkSchedule(){
    const getParams = {
        "IpAddress":"0.0.0.0",
        "ClientId":this.state.selectedClientId,
        "UserId":this.state.userinfo.userId,
        "Location": this.state.selectedLocationName,
    }

    axios
        .post(AppConfiguration.Setting().noserapiendpoint + "TimeKeeping/GetWorkingSchedules", getParams)
        .then(res => {
            let ws = []
            res.data.workSchedules.map(function(itm){
                ws.push({"value" : itm.id,"label" : itm.description})
            })
            this.setState({getWorkScheduleList : res.data.workSchedules,workscheduleDDL:ws})
            console.log("Work Schedule DDL")
            console.log(ws)
        })
    }
GetWorkScheduleId(id) {
    let GetWorkScheduleId = ''
    for (let i = 0; i <= this.state.getWorkScheduleList.length; i++) {
        if (this.state.getWorkScheduleList[i]["id"] === id) {
            GetWorkScheduleId = this.state.getWorkScheduleList[i]['id'];
            break;
        }
    }
    return GetWorkScheduleId
}
    
onChangeWorkScheduleIdList = (e) => {
    if(e.length === "") {
        this.setState({
            getWorkScheduleList : ""
        })
    } else {
        if (e.length > 0) {
            this.state.selectedWorkScheduleId = this.GetWorkScheduleId(e[0].id)
        }
    }
}

getClientList(){
    const getParams = {
        "IpAddress":"0.0.0.0",
        "ClientId":this.state.userinfo.clientId,
        "UserId":this.state.userinfo.userId,
    }
    axios
    .post(AppConfiguration.Setting().noserapiendpoint + "Client/GetClientList", getParams)
    .then(res => {
        let arr = []
        if(this.state.userinfo.roleId!=="1"){
            let fieldaccess = JSON.parse(sessionStorage.getItem("userControlAccess"))
            if(fieldaccess.filter(x=>x.accessCode==="0001").length>0){
                let access = fieldaccess.find(x=>x.accessCode==="0001" && x.controlAccessDetails.length>0)
                if(access.controlAccessDetails.filter(x=>x.isClient==="1").length>0){
                    let brr = access.controlAccessDetails.filter(x=>x.isClient==="1")
                    arr = res.data.clients.filter(f => brr.some(item => item.referenceId === f.id))
                }
            }
            this.setState({isloading:false,getClientList : arr})
        }
        else{
             this.setState({clientDDL : res.data.clients})
        }
        //this.setState({isloading:false,getClientList : res.data.clients ? res.data.clients : []});
    })
    .catch(error=>{
       this.setState(  {
           isloading       :   false,
           alerttype       :   "Error!",
           isshow          :   true,
           color           :   "danger",
           message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
           fade            :   true
       })
   })
}
getClientLocation(){
    const getParams = {
        "IpAddress":"0.0.0.0",
        "ClientId":this.state.selectedClientId,
        "UserId":this.state.userinfo.userId,
        "ClientName": this.state.selectedClientName,
        "City": "",
        "Province": "",
        "Region": ""

    }
    axios
    .post(AppConfiguration.Setting().noserapiendpoint + "Client/GetClientLocations", getParams)
    .then(res => {
        let branches = []
        res.data.locations.map(function(itm){
            branches.push({"value" : itm.id,"label" : itm.name})
        })
        this.setState({clientLocationList : res.data.locations ? res.data.locations : [], locationDDL : branches})
    })
    .catch(error=>{
       this.setState(  {
           isloading       :   false,
           alerttype       :   "Error!",
           isshow          :   true,
           color           :   "danger",
           message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
           fade            :   true
       })
   })
}
GetLocation(name) {
    let GetLocationName = ''
    for (let i = 0; i <= this.state.clientLocationList.length; i++) {
        if (this.state.clientLocationList[i]["name"] === name) {
            GetLocationName = this.state.clientLocationList[i]['id']; 
            break;
        }
    }
    return GetLocationName
}
onChangeLocation = (e) => {
    if(e.length == 0) {
            this.state.selectedLocationName = ""
            this.state.selectedLocationId = ""
            return
    }  
    this.state.selectedLocationName = e[0].name
    this.state.selectedLocationId = e[0].id
}
getEmployeesTeamLead(){
    const getParams = {
        "IpAddress":"0.0.0.0",
        "ClientId":this.state.selectedClientId,
        "UserId":this.state.userinfo.userId,
        "EmployeeName":"",
        "EmployeeNo":"",
        "ClientName":""
    }
    axios
    .post(AppConfiguration.Setting().noserapiendpoint + "Employee/GetTeamLeadEmployees", getParams)
    .then(res => {
        const data = res.data
        if(data.status=="1")
            this.setState({getEmployeesTeamLeadList : data.employees,isloading:false}) 
        else
            this.setState({getEmployeesTeamLeadList : [],isloading:false}) 
    })
    .catch(error=>{
       this.setState(  {
           isloading       :   false,
           alerttype       :   "Error!",
           isshow          :   true,
           color           :   "danger",
           message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
           fade            :   true
       })
   })
}
GetEmployeeTeamLead(id) {
    let GetEmployeeTeamLeadId = ''
    for (let i = 0; i <= this.state.getEmployeesTeamLeadList.length; i++) {
        if (this.state.getEmployeesTeamLeadList[i]["id"] === id) {
            GetEmployeeTeamLeadId = this.state.getEmployeesTeamLeadList[i]['id'];
            break;
        }
    }
    return GetEmployeeTeamLeadId
}

onChangeEmployeesTeamLeadList = (e) => {
    if(e.length === "") {
        this.setState({
            getEmployeesTeamLeadList : ""
        })
    } else {
        if (e.length > 0) {
            this.state.selectedEmployeeTeamLeadId = this.GetEmployeeTeamLead(e[0].id)
        }
    }
}
getEmployeesCoordinator(){
    const getParams = {
        "IpAddress":"0.0.0.0",
        "ClientId":this.state.selectedClientId,
        "UserId":this.state.userinfo.userId,
        "EmployeeName":"",
        "EmployeeNo":"",
        "ClientName":""
    }
    axios
    .post(AppConfiguration.Setting().noserapiendpoint + "Employee/GetCoorEmployees", getParams)
    .then(res => {
        const data = res.data
        if(data.status=="1")
            this.setState({getEmployeesCoordinatorList : data.employees,isloading:false}) 
        else
            this.setState({getEmployeesCoordinatorList : [],isloading:false}) 
    })
    .catch(error=>{
       this.setState(  {
           isloading       :   false,
           alerttype       :   "Error!",
           isshow          :   true,
           color           :   "danger",
           message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
           fade            :   true
       })
   })
}
GetEmployeeCoordinator(id) {
    let GetEmployeeCoordinatorId = ''
    for (let i = 0; i <= this.state.getEmployeesCoordinatorList.length; i++) {
        if (this.state.getEmployeesCoordinatorList[i]["id"] === id) {
            GetEmployeeCoordinatorId = this.state.getEmployeesCoordinatorList[i]['id']; 
            break;
        }
    }
    return GetEmployeeCoordinatorId
}
onChangeEmployeesCoordinatorList = (e) => {
    if(e.length === "") {
        this.setState({
            getEmployeesCoordinatorList : ""
        })
    } else {
        if (e.length > 0) {
            this.state.selectedEmployeeCoordinatorId = this.GetEmployeeCoordinator(e[0].id)
        }
    }
}
employeeWorkScheduleTable() {
    this.setState({isloading:true})
    const getParams = {
        "IpAddress"     :   "0.0.0.0",
        "ClientId"      :   this.state.selectedClientId,
        "UserId"        :   this.state.userinfo.userId,
        "EmployeeId"    :   this.state.selectedEmployeeId,
        "PayPeriodId"   :   this.state.selectedPayrollPeriodsId
    }
    axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Timekeeping/GetEmployeeWebPayPeriodWorkSchedules", getParams)
        .then(res => {
            const data      =   res.data
            console.log(data)
            var obj         =   {}
            var obj1        =   {}
            var obj2        =   {}
            var schedule    =   []
            var isValid     =   true;
            if(data.status  =   "1"){
                
                if(data.payPeriodWorkSchedule   ==  null)
                {
                    isValid = false
                    data.payPeriodWorkSchedule  =   []
                }
                let x = 0
                data.payPeriodWorkSchedule.map(function(item, idx){
                    x = x + 1
                    if(item.periodWorkSchedule  ==  null)
                    {
                        isValid = false
                    }
                    else
                    {
                        obj = {
                            id                  :   x,
                            periodId            :   item.id,
                            date                :   item.date,
                            locationId          :   item.locationId,
                            workScheduleId      :   item.periodWorkSchedule.id,
                            timeIn              :   item.periodWorkSchedule.startTime,
                            timeOut             :   item.periodWorkSchedule.endTime,
                            firstBreakOut       :   '',
                            firstBreakIn        :   '',
                            secondBreakOut      :   '',
                            secondBreakIn       :   '',
                            thirdBreakOut       :   '',
                            thirdBreakIn        :   '',
                            fourthBreakOut      :   '',
                            fourthBreakIn       :   '',
                            overtime            :   '00:00',
                            isSelected          :   false
                        }
                        item.periodWorkSchedule.workScheduleDetails.map(function(item, idx){
                            if(idx==1){
                                obj.firstBreakOut = item.startTimeLabel
                                obj.firstBreakIn = item.endTimeLabel
                            }
                            if(idx==2){
                                obj.secondBreakOut = item.startTimeLabel
                                obj.secondBreakIn = item.endTimeLabel
                            }
                            if(idx==3){
                                obj.thirdBreakOut = item.startTimeLabel
                                obj.thirdBreakIn = item.endTimeLabel
                            }
                        })
                        schedule.push(obj)
                    }
                })
                if(!isValid){
                    this.setState({
                        isloading       :   false,
                        alerttype       :   "Information!",
                        isshow          :   true,
                        color           :   "info",
                        message         :   "Employee has no working schedule.",
                        fade            :   true,
                        employeeWorkScheduleTable:[]
                    })
                }
                else
                this.setState({
                    employeeWorkScheduleTable   :   schedule,
                    isShow                      :   false,
                    isloading                   :   false
                })
            }
        })
}
GetClientId(name) {
    let GetClientId = ''
    for (let i = 0; i <= this.state.getClientList.length; i++) {
        if (this.state.getClientList[i]["name"] === name) {
            GetClientId = this.state.getClientList[i]['id'];
            break;
        }
    }
    return GetClientId
}
getEmployees(){
    const getParams = {
        "IpAddress":"0.0.0.0",
        "ClientId":this.state.selectedClientId,
        "UserId":this.state.userinfo.userId,
        "EmployeeName":"",
        "EmployeeNo":"",
        "ClientName":""
    }
    axios
    .post(AppConfiguration.Setting().noserapiendpoint + "Employee/GetConfigurationEmployees", getParams)
    .then(res => {
        const data = res.data
        let arr = []
        if(this.state.userinfo.roleId!=="1"){
            let fieldaccess = JSON.parse(sessionStorage.getItem("userControlAccess"))
            if(fieldaccess.filter(x=>x.accessCode==="0001").length>0){
                let access = fieldaccess.find(x=>x.accessCode==="0001" && x.controlAccessDetails.length>0)
                if(access.controlAccessDetails.filter(x=>x.isClient==="1").length>0){
                    let brr = access.controlAccessDetails.filter(x=>x.isClient==="1")
                    arr = data.employees.filter(f => brr.some(item => item.referenceId === f.clientId))
                }
            }
            this.setState({isloading:false,getEmployeeList : arr,getEmployeeNoList : arr, getCurrentMonthly: data.employeespayPeriod})
        }
        else{
            this.setState({getEmployeeList : data.employees, getEmployeeNoList : data.employees,isloading:false, getCurrentMonthly: data.employeespayPeriod})
        }
        // if(data.status=="1")
        //     this.setState({getEmployeeList : data.employees, getEmployeeNoList : data.employees,isloading:false, getCurrentMonthly: data.employeespayPeriod})
        // else
        //     this.setState({getEmployeeList : [], getEmployeeNoList : [],isloading:false})
    })
    .catch(error=>{
       this.setState(  {
           isloading       :   false,
           alerttype       :   "Error!",
           isshow          :   true,
           color           :   "danger",
           message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
           fade            :   true
       })
   })

}
GetEmployeeId(id) {
    let GetEmployeeId = ''
    for (let i = 0; i <= this.state.getEmployeeList.length; i++) {
        if (this.state.getEmployeeList[i]["id"] === id) {
            GetEmployeeId = this.state.getEmployeeList[i]['id'];
            break;
        }
    }
    return GetEmployeeId
}
GetEmployeePayrollPeriods(){
    const getParams = {
        "IpAddress":"0.0.0.0",
        "ClientId":this.state.selectedClientId,
        "UserId":this.state.userinfo.userId,
        "EmployeeId":this.state.selectedEmployeeId,
        "IsProcessed":""
    }
    axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Timekeeping/GetPayrollPeriods", getParams)
        .then(res => {
            this.setState({getPayrollPeriodId : res.data.payrollPeriods})
        })
}
GetPayperiodPeriodsId(periodId){
    let PayPeriodName =''
    this.state.getPayrollPeriodId.map(function(item,idx){
        if(periodId==item.periodId)
        PayPeriodName = item.payPeriod
    })
    this.setState({selectedPayrollPeriodsName:PayPeriodName})
}
GetEmployeeStatusList(){
    const getParams = {
        "IpAddress":"0.0.0.0",
        "ClientId":this.state.userinfo.clientId,
        "UserId":this.state.userinfo.userId,
    }
    axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Maintenance/GetEmployeeStatusTypes", getParams)
        .then(res => {
            this.setState({getPayStatusList : res.data.statusTypes})
        })
}

GetEmployeeStatus(id) {
    let GetEmployeeStatusId = ''
    for (let i = 0; i <= this.state.getPayStatusList.length; i++) {
        if (this.state.getPayStatusList[i]["id"] === id) {
            GetEmployeeStatusId = this.state.getPayStatusList[i]['id']; 
            break;
        }
    }
    return GetEmployeeStatusId
}
onChangeEmployeesStatusList = (e) => {
    if(e.length === "") {
        this.setState({getPayStatusList:""})
    } 
    else {
        if (e.length > 0) {
            this.state.selectedEmployeeStatusId = this.GetEmployeeStatus(e[0].id) 
        }
    }
}
GetEmployeePayTypesList(){
    const getParams = {
        "IpAddress":"0.0.0.0",
        "ClientId":this.state.userinfo.clientId,
        "UserId":this.state.userinfo.userId,
    }

    axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Timekeeping/GetPayrollTypes", getParams)
        .then(res => {
            this.setState({getPayTypesList : res.data.payrollTypes})
        })

}
onChangeEmployeesPayTypesList = (e) => {
    if(e.length==0) return;
    this.setState({ selectedEmployeePayTypesId: e[0].id });
}

GetEmployeePayModesList(){
    const getParams = {
        "IpAddress":"0.0.0.0",
        "ClientId":this.state.userinfo.clientId,
        "UserId":this.state.userinfo.userId,
    }
    axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Timekeeping/GetPayrollModes", getParams)
        .then(res => {
            this.setState({getPayModesList : res.data.payrollModes})
        })
}
onChangeEmployeesPayModesList = (e) => {
    if(e.length==0) {
        this.setState({ selectedEmployeePayModesId: '' });
        return;
    }
    this.setState({selectedEmployeePayModesId: e[0].id,isloading:true})
    const sleep = (milliseconds) => {return new Promise(resolve => setTimeout(resolve, milliseconds))}
    sleep(1000).then(() => {
        this.GetPeriodTypes()
      })
}
onChangeSalaryRate = (e) => {
    this.setState({ salaryRate: e.target.value} );
}
handleChangeRestDate = date => {
    this.setState({RestDate:date,isshow:false,});
};
formatDate(date) {
    let m = moment(date, 'MM-DD-YYYY');
    return (m.isValid() ? (date.getMonth()+1 + "/" + date.getDate() + "/" + date.getFullYear()) : "")
  }

  onSubmitSaveEmployee = () => {
    this.setState({isloading:true})
    const getParams = {
        "IpAddress":"0.0.0.0",
        "ClientId":this.state.selectedClientId,
        "UserId":this.state.userinfo.userId,
        "EmployeeId":this.state.selectedEmployeeId,
        "ApproverId1":this.state.selectedEmployeeTeamLeadId ? this.state.selectedEmployeeTeamLeadId : "",
        "ApproverId2":this.state.selectedEmployeeCoordinatorId ? this.state.selectedEmployeeCoordinatorId : "",
        "PayTypeId":this.state.selectedEmployeePayTypesId ? this.state.selectedEmployeePayTypesId : "",
        "PayModeId":this.state.selectedEmployeePayModesId ? this.state.selectedEmployeePayModesId : "",
        "LocationId":this.state.selectedLocationId ? this.state.selectedLocationId : "",
        "WorkScheduleId":this.state.selectedWorkScheduleId ? this.state.selectedWorkScheduleId : "",
        "StatusTypeId":this.state.selectedEmployeeStatusId ? this.state.selectedEmployeeStatusId : "",
        "PeriodTypeId": this.state.selectedGetPayTypesId ? this.state.selectedGetPayTypesId : "",
        "Salary":this.state.salaryRate ? this.state.salaryRate : "",
        "EmployeeNo":this.state.selectedEmployeeNo ? this.state.selectedEmployeeNo : "",
        "PayTypeEffectivity": (this.state.payTypeDate ? this.formatDate(this.state.payTypeDate) : ""),
        "PayModeEffectivity": (this.state.PayModesDate ? this.formatDate(this.state.PayModesDate) : ""),
        "LocationEffectivity": (this.state.BranchDate ? this.formatDate(this.state.BranchDate) : ""),
        "WorkScheduleEffectivity": (this.state.WorkScheduleDate ? this.formatDate(this.state.WorkScheduleDate) : ""),
        "PeriodTypeEffectivity": (this.state.PeriodTypesDate ? this.formatDate(this.state.PeriodTypesDate) : ""),
        "StatusTypeEffectivity": (this.state.StatusDate ? this.formatDate(this.state.StatusDate) : ""),
        "SalaryEffectivity": (this.state.SalaryRateDate ? this.formatDate(this.state.SalaryRateDate) : ""),
        "Approver1": (this.state.TeamLeadDate ? this.formatDate(this.state.TeamLeadDate) : ""),
        "Approver2": (this.state.CoordinatorDate ? this.formatDate(this.state.CoordinatorDate) : ""),
        "Restdays" : this.state.employeeRestDayTable,
        "Leaves" : this.state.LeavedayDataList, 
        "PayCardTypeId" : this.state.selectedPayCardTypeId,
        "PayCardTypeEffectivity" : (this.state.PayCardType ? this.formatDate(this.state.PayCardType) : ""),
        "PayCardNumber" : this.state.txtpayCardNumber,
        "PayCardNumberEffectivity" : (this.state.PayCardNumber ? this.formatDate(this.state.PayCardNumber) : ""),
        
        "SSSAmount" : this.state.selectedsceeShare,
        "SSSEffectivity" : (this.state.sssAmountDate ? this.formatDate(this.state.sssAmountDate) : ""),

        "PHICAmount" : this.state.phicAmount,
        "PHICEffectivity" : (this.state.phicAmountDate ? this.formatDate(this.state.phicAmountDate) : ""),

        "HDMFAmount" : this.state.hdmfAmount,
        "HDMFEffectivity" : (this.state.hdmfAmountDate ? this.formatDate(this.state.hdmfAmountDate) : ""),
        "EColaRate" : this.state.ecolaValue,
        "EColaRateEffectivity": (this.state.ecolaValueDate ? this.formatDate(this.state.ecolaValueDate) : ""),
        "ColaRate" : this.state.colaValue,
        "ColaRateEffectivity": (this.state.colaValueDate ? this.formatDate(this.state.colaValueDate) : ""),
        "SeaRate" : this.state.seaRate,
        "SeaRateEffectivity" : (this.state.seaValueDate ? this.formatDate(this.state.seaValueDate) : ""),

        "PositionId"            :   this.state.selectedPositionId,
        "PositionEffectivity"   :   (this.state.PositionDate ? this.formatDate(this.state.PositionDate) : ""),
        "IsDeleted"             :   this.state.deactivate ? "1" : "0",

        "DateOfBirth"   :   (this.state.DateOfBirth ? this.formatDate(this.state.DateOfBirth) : ""),
        "ContractDateStart"   :   (this.state.ContractDateStart ? this.formatDate(this.state.ContractDateStart) : ""),
        "ContractDateEnd"   :   (this.state.ContractDateEnd ? this.formatDate(this.state.ContractDateEnd) : ""),
        "MobileNumber" : this.state.txtmobileNumber,
        "FirstName" : this.state.txtFirstName,
        "MiddleName" : this.state.txtMiddleName,
        "LastName" : this.state.txtLastName,
        "OvertimeSchedules" : this.state.overtimeScheduleGRID
    }
    axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Employee/SaveEmployeeConfiguration", getParams)
        .then(res => {
            const data = res.data;
            if(data.status=="1"){
                this.setState({
                    isloading       :   false,
                    alerttype       :   "Success!",
                    isshow          :   true,
                    color           :   "success",
                    message         :   data.message,
                    fade            :   true
                });
            }
            else{
                this.setState({
                    isloading       :   false,
                    alerttype       :   "Error!",
                    isshow          :   true,
                    color           :   "danger",
                    message         :   data.message,
                    fade            :   true
                })
            }
         })
         .catch(error=>{
            this.setState(  {
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade            :   true
            })
        })
  }

handleAddOTSchedule = () =>{
    let fromThisDate = moment(this.state.scheduleDate).format('MM/DD/YYYY');
    let lstschedules = this.state.overtimeScheduleGRID
    let schedArr = []
    const {overtimeScheduleGRID} = this.state
    //let scheduleDate = this.formatDate(this.state.scheduleDate)


    let newId = 0;
    if(lstschedules.length===0){
        let today = new Date()
        newId = moment(today).format('HHMMSS');
    }
    else{
        let today = new Date()
        const ids = lstschedules.map(x => x.id);
        const sorted = ids.sort((a, b) => a - b);
        const uid = moment(today).format('SS');
        newId = parseInt(sorted[sorted.length-1])+1+uid;
    }


    const schedule = {
        "id" :newId.toString(),
        "employeeId":this.state.selectedEmployeeId,
        "scheduledate" : fromThisDate,
        "isDeleted":"0"
    }
    if(lstschedules.length>0)
    {
        for(let i=0;i<lstschedules.length;i++)
        {
            if(lstschedules[i].scheduledate==fromThisDate){
                this.setState({
                    isloading       :   false,
                    alerttype       :   "Error!",
                    isshow          :   true,
                    color           :   "danger",
                    message         :   "Selected date already exist.",
                    fade            :   true
                })
                return 
            }
        }
    }
    overtimeScheduleGRID.push(schedule)
    this.setState({overtimeScheduleGRID: overtimeScheduleGRID.sort((a, b) => parseInt(b["id"]) - parseInt(a["id"]))})
}

onSubmitAdd = () => {

    let fromThisDate = moment(this.state.RestDate).format('MM/DD/YYYY');
    let lstRestday = this.state.employeeRestDayTable
    let restArr = []
    const {employeeRestDayTable} = this.state
    let restDay = this.formatDate(this.state.RestDate)


    let newId = 0;
    if(lstRestday.length===0){
        let today = new Date()
        newId = moment(today).format('HHMMSS');
    }
    else{
        let today = new Date()
        const ids = lstRestday.map(x => x.id);
        const sorted = ids.sort((a, b) => a - b);
        const uid = moment(today).format('SS');
        newId = parseInt(sorted[sorted.length-1])+1+uid;
    }


    const rest = {
        "id" :newId.toString(),
        "EmployeeId":this.state.selectedEmployeeId,
        "restdate" : fromThisDate,
        "isDeleted":"0"
    }
    if(lstRestday.length>0)
    {
        for(let i=0;i<lstRestday.length;i++)
        {
            if(lstRestday[i].restdate==fromThisDate){
                this.setState({
                    isloading       :   false,
                    alerttype       :   "Error!",
                    isshow          :   true,
                    color           :   "danger",
                    message         :   "Selected date already exist.",
                    fade            :   true
                })
                return 
            }
        }
    }
    employeeRestDayTable.push(rest)
    //employeeRestDayTable.insert(0,rest)
    this.setState({employeeRestDayTable: employeeRestDayTable.sort((a, b) => parseInt(b["id"]) - parseInt(a["id"]))})
   

}

    handleChangeDateOfBirth = date => {
        this.setState({DateOfBirth: date});
    };
    handleChangeContractDateStart = date => {
        this.setState({ContractDateStart: date});
    };
    handleChangeContractDateEnd = date => {
        this.setState({ContractDateEnd: date});
    };
    handleChangePayTypeDate = date => {
        this.setState({payTypeDate: date});
    };
    handleChangePayModesDate = date => {
        this.setState({PayModesDate: date});
    };
    handleChangePayBranchDate = date => {
        this.setState({BranchDate: date});
    };
    handleChangePeriodTypesDate = date => {
        this.setState({PeriodTypesDate: date});
    };
    handleChangeSalaryRateDate = date => {
        this.setState({SalaryRateDate: date});
    };
    handleChangeStatusDate = date => {
        this.setState({StatusDate: date});
    };
    handleChangeTeamLeadDate = date => {
        this.setState({TeamLeadDate: date});
    };
    handleChangeCoordinatorDate = date => {
        this.setState({CoordinatorDate: date});
    };
    handleChangeWorkScheduleDate = date => {
        this.setState({WorkScheduleDate: date});
    };
    handleChangePayCardType = date => {
        this.setState({PayCardType: date});
    };
    handleChangePayCardNumber = date => {
        this.setState({PayCardNumber: date});
    };
    handleModalClose = (e) => {
        this.setState({employeeLeaveTable: e});
    }
    onChangePayCardNumber = (e) => {
        this.setState({ txtpayCardNumber: e.target.value} );
    }
    handleChangeSSSAmountDate = date => {
        this.setState({sssAmountDate: date});
    };
    onChangePHICAmount = (e) => {
        this.setState({ phicAmount: e.target.value} );
    }
    handleChangePHICAmountDate = date => {
        this.setState({phicAmountDate: date});
    };
    onChangeHDMFAmount = (e) => {
        this.setState({ hdmfAmount: e.target.value} );
    }
    handleChangeHDMFAmountDate = date => {
        this.setState({hdmfAmountDate: date});
    };
    onChangeEColaValue = (e) => {
        this.setState({ ecolaValue: e.target.value} );
    }
    handleChangeEColaValueDate = date => {
        this.setState({ecolaValueDate: date});
    };
    onChangeColaValue = (e) => {
        this.setState({ colaValue: e.target.value} );
    }
    handleChangeColaValueDate = date => {
        this.setState({colaValueDate: date});
    };
    onChangeSeaValue = (e) => {
        this.setState({ seaRate: e.target.value} );
    }
    handleChangeSeaValueDate = date => {
        this.setState({seaValueDate: date});
    };
    getSSSBackets(){
        const params = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.userinfo.clientId,
            "UserId":this.state.userinfo.userId,
        }
        axios.post(AppConfiguration.Setting().noserapiendpoint + "Maintenance/GetSSSBrackets", params)
        .then(res => {
            const data = res.data
            this.setState({
                sssBrackets : data.brackets
            })
        })
    }
    onChangeSSSBrackets = (e) => {
        if(e.length == 0) {
            this.state.selectedsceeShare  = ''
            return
        } 
        this.state.selectedsceeShare = e[0].sceeShare
    }
    GetPosition() {
        const departmentCodeParams = {
            "IpAddress"     :   "0.0.0.0",
            "ClientId"      :   this.state.userinfo.clientId,
            "UserId"        :   this.state.userinfo.userId,
            "SectionId"     :   "",
            "DepartmentId"  :   "",
            "Name"          :   ""
        };

        axios.post(AppConfiguration.Setting().noserapiendpoint + "Maintenance/GetPositions",  departmentCodeParams)
        .then(res => {
            const data = res.data;
            this.setState({ positionAutocomplete  : data.positions });})
    }
    onChangePosition = (e) => {
        if (e.length > 0) {
            this.state.selectedPositionId = e[0].id
        } else {
            this.state.selectedPositionId = ""
        }
    }
    handleChangePositionDate = date => {
        this.setState({
            PositionDate: date
            
        });
    };
    handleChangeStatus = (e) =>{
        this.setState({deactivate: e.target.checked})
        //console.log(this.state.deactivate)
        //console.log(e.target.checked)
    }
    onChangeMobileNumber = (e) => {
        this.setState({ txtmobileNumber: e.target.value} );
    }
    onChangeName = (e) =>{
        this.setState({ [e.target.name]: e.target.value} );
    }
    FormatCommas(x) {
        x = x.toString();
        var pattern = /(-?\d+)(\d{3})/;
        while (pattern.test(x))
            x = x.replace(pattern, "$1,$2");
        return x;
    }
    IsValueNumber(val)
    {
        var regex = /^\d+(.\d+)?$/;
        return !regex.test(val)
    }
    IsNumeric(evt){
        var theEvent = evt || window.event;
        var key = theEvent.keyCode || theEvent.which;
        key = String.fromCharCode( key );
        var regex = /^\d+(.\d+)?$/;
        if( !regex.test(key) ) {
        theEvent.returnValue = false;
        if(theEvent.preventDefault) theEvent.preventDefault();
        }
    }
    handleSubmitSchedule = async (e) =>{
        
        let invalidloc = this.state.employeeWorkScheduleTable.filter(x=>x.locationId=="")
        if(invalidloc.length>0){
            alert("Please select location for schedule dated : " + invalidloc[0]["date"])
            return
        }
        let invalidws = this.state.employeeWorkScheduleTable.filter(x=>x.workScheduleId=="")
        if(invalidws.length>0){
            alert("Please select work schedule for schedule dated : " + invalidws[0]["date"])
            return
        }
        this.setState({isloading:true,isDisable:true})
        let schedules = []
        let employeeId = this.state.selectedEmployeeId
        let payperiodId = this.state.selectedPayrollPeriodsId
        let userId = this.state.userinfo.userId
        this.state.employeeWorkScheduleTable.filter(x=>x.isSelected==true).map(function(itm){

            schedules.push({
                "Id" : itm.periodId,
                "EmployeeId" : employeeId,
                "PayPerioId" : payperiodId,
                "WorkScheduleId" : itm.workScheduleId,
                "LocationId" : itm.locationId,
                "Date" : itm.date,
                "IsDeleted" : "0",
                "UserId" : userId
            })
        })

        const params = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.userinfo.clientId,
            "UserId":this.state.userinfo.userId,
            "periodWorkSchedules":schedules
        }
        
        await axios
        .post(AppConfiguration.Setting().noserapiendpoint + "TimeKeeping/SaveEmployeeWorkSchedule", params)
        .then(res => {
            const data = res.data
            this.setState({
                isloading   :   false,
                alerttype   :   data.status=="1" ? "Success!" : "Error!",
                isshow      :   true,
                color       :   data.status=="1" ? "success" : "danger",
                message     :   data.message,
                fade        :   true
            });
            if(data.status=="1"){
                this.employeeWorkScheduleTable()
            }
        })
        .catch(error=>{
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade        :   true,
            })
        })
    }
    handleRemoveSchedule = async (e) =>{
        let x = 0;
        let schedules = []
        let newSchedules = []

        this.setState({isloading:true,isDisable:true})
        let employeeId = this.state.selectedEmployeeId
        let payperiodId = this.state.selectedPayrollPeriodsId
        let userId = this.state.userinfo.userId
        this.state.employeeWorkScheduleTable.filter(x=>x.isSelected==true).map(function(itm){
            schedules.push({
                "Id" : itm.periodId,
                "EmployeeId" : employeeId,
                "PayPerioId" : payperiodId,
                "WorkScheduleId" : itm.workScheduleId,
                "LocationId" : itm.locationId,
                "Date" : itm.date,
                "IsDeleted" : "1",
                "UserId" : userId
            })
        })
        const params = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.userinfo.clientId,
            "UserId":this.state.userinfo.userId,
            "periodWorkSchedules":schedules
        }
        
        await axios
        .post(AppConfiguration.Setting().noserapiendpoint + "TimeKeeping/SaveEmployeeWorkSchedule", params)
        .then(res => {
            const data = res.data
            this.setState({
                isloading   :   false,
                alerttype   :   data.status=="1" ? "Success!" : "Error!",
                isshow      :   true,
                color       :   data.status=="1" ? "success" : "danger",
                message     :   data.message,
                fade        :   true
            });
            if(data.status=="1"){
                this.setState({employeeWorkScheduleTable:[],selectedSchedules:[],disablebutton:true})
                this.employeeWorkScheduleTable()
            }
        })
        .catch(error=>{
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade        :   true,
            })
        })
    }
    handleAddSchedule = (e) =>{
        // //console.log(this.state.employeeWorkScheduleTable)
        // let lastId = this.state.employeeWorkScheduleTable[this.state.employeeWorkScheduleTable.length-1]["id"]
        // //console.log(lastId)
        //let selectedSchedules = this.state.employeeWorkScheduleTable.filter(x=>x.isSelected==true)
        
        if(this.state.employeeWorkScheduleTable.filter(x=>x.isSelected==true).length==0){
            alert("Please select atleast one schedule date.")
            return
        }

        let scheduledate = ""
        let tmp = this.state.employeeWorkScheduleTable
        this.state.employeeWorkScheduleTable.map(function(itm){
            if(itm.isSelected) if(tmp.filter(x=>x.date==itm.date).length>5) scheduledate = itm.date
        })
        if(scheduledate!=""){
            alert("Unable to add row for schedule date : " + scheduledate )
            return
        }

        //const ids = this.state.employeeWorkScheduleTable.map(user => user.id);
        //const sorted = ids.sort((a, b) => a - b);
        //let maxId = parseInt(sorted[sorted.length-1]) + 1;

        let newSchedules = []
        let x = 0;
        this.state.employeeWorkScheduleTable.map(function(item,idx){
            x = x+1
            if(item.isSelected==true){
                newSchedules.push({
                    id                  :   x.toString(),
                    periodId            :   item.periodId,
                    date                :   item.date,
                    locationId          :   item.locationId,
                    workScheduleId      :   item.workScheduleId,
                    timeIn              :   item.timeIn,
                    timeOut             :   item.timeOut,
                    firstBreakOut       :   item.firstBreakOut,
                    firstBreakIn        :   item.firstBreakIn,
                    secondBreakOut      :   item.secondBreakOut,
                    secondBreakIn       :   item.secondBreakIn,
                    thirdBreakOut       :   item.thirdBreakOut,
                    thirdBreakIn        :   item.thirdBreakIn,
                    fourthBreakOut      :   item.fourthBreakOut,
                    fourthBreakIn       :   item.fourthBreakIn,
                    overtime            :   item.overtime,
                    isSelected          :   false
                })
                x = x+1
                newSchedules.push({
                    id                  :   x.toString(),
                    periodId            :   "0",
                    date                :   item.date,
                    locationId          :   '',
                    workScheduleId      :   '',
                    timeIn              :   '',
                    timeOut             :   '',
                    firstBreakOut       :   '',
                    firstBreakIn        :   '',
                    secondBreakOut      :   '',
                    secondBreakIn       :   '',
                    thirdBreakOut       :   '',
                    thirdBreakIn        :   '',
                    fourthBreakOut      :   '',
                    fourthBreakIn       :   '',
                    overtime            :   '',
                    isSelected          :   true,
                })
            }
            else{
                newSchedules.push({
                    id                  :   x.toString(),
                    periodId            :   item.periodId,
                    date                :   item.date,
                    locationId          :   item.locationId,
                    workScheduleId      :   item.workScheduleId,
                    timeIn              :   item.timeIn,
                    timeOut             :   item.timeOut,
                    firstBreakOut       :   item.firstBreakOut,
                    firstBreakIn        :   item.firstBreakIn,
                    secondBreakOut      :   item.secondBreakOut,
                    secondBreakIn       :   item.secondBreakIn,
                    thirdBreakOut       :   item.thirdBreakOut,
                    thirdBreakIn        :   item.thirdBreakIn,
                    fourthBreakOut      :   item.fourthBreakOut,
                    fourthBreakIn       :   item.fourthBreakIn,
                    overtime            :   item.overtime,
                    isSelected          :   false
                })
            }
        })
        this.state.employeeWorkScheduleTable = []
        this.setState({employeeWorkScheduleTable:newSchedules,selectedSchedules:[],disablebutton:true})
    }
    handleOnSelectAllWorkSchedules = (isSelect, rows) => {
        let disablebutton = true
        let selectedSchedules = []
        this.state.employeeWorkScheduleTable.map(function(itm){
            itm.isSelected = isSelect
            selectedSchedules.push(itm.id)
            if(itm.isSelected) disablebutton=false
        })
        if(!isSelect) selectedSchedules = []
        this.state.selectedSchedules = []
        this.setState({disablebutton:disablebutton,selectedSchedules: selectedSchedules})
        if (isSelect) {
            return this.state.employeeWorkScheduleTable.map(r => r.id);
        }
        else{ 
            return []
        }
        
    }
    handleChangeScheduleDate = date => {
        this.setState({scheduleDate:date,isshow:false,});
    };
    GetLeaveTypes = async() =>{
        const params = 
        {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.selectedClientId,
            "UserId":this.state.userinfo.userId,
        }
        await axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Maintenance/GetLeaveTypes", params)
        .then(res => {
            const data = res.data
            this.setState({
                isloading:false,
                leaveTypesDDL : data.leaveTypes,
            })
        })
    }
    render() {

        const sizePerPageRenderer = ({
            options,
            currSizePerPage,
            onSizePerPageChange
            }) => (
            <div className="btn-group" role="group">
                {
                options.map((option) => {
                    const isSelect = currSizePerPage === `${option.page}`;
                    return (
                    <button
                        key={ option.text }
                        type="button"
                        onClick={ () => onSizePerPageChange(option.page) }
                        className={ `btn ${isSelect ? 'btn-primary' : 'btn-success'}` }
                    >
                        { option.text }
                    </button>
                    );
                })
                }
            </div>
            );
        const options = {
            sizePerPageRenderer
        };


        const columnLeaveTable = [
            {
                dataField: 'leaveDate',
                text: 'Date',
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'12%' }},
                style:{textAlign:'center'}
            },
            {
                dataField: 'mandays',
                text: 'Duration',
                align:"center",
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'10%' }},
                style:{textAlign:'center'}
            },
            {
                dataField: 'isPaidLabel',
                text: 'Paid',
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'10%' }},
                style:{textAlign:'center'}
            },
            {
                dataField: 'reason',
                text: 'Reason',
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'left' ,width:'68%'}},
                style:{textAlign:'left'}
            }
        ]

        const employeeLeaveRow = {
            /* mode: 'checkbox',
            clickToSelect: true,
            clickToSelectAndEditCell: true */

            mode: 'checkbox',
            clickToSelectAndEditCell: false,
            onSelect: (row, isSelect, rowIndex, e) => {
                this.state.LeavedayDataList.map(function(item,idx){
                    if(idx==rowIndex)
                    {
                        item.isDeleted = isSelect ? "1" : "0"
                    }
                }) 
                ////console.log(this.state.LeavedayDataList)
            }
        };

        const columnRestDay = [
            {
                dataField: 'restdate',
                text: 'Date',
                editable: false,
                headerStyle: () => {
                    return { width: "100%" };
                  }
            },
        ]
        const columnOTSchedule = [
            {
                dataField: 'scheduledate',
                text: 'Date',
                editable: false,
                headerStyle: () => {
                    return { width: "100%" };
                  }
            },
        ]

        const restDayRow = {
            mode: 'checkbox',
            clickToSelectAndEditCell: true,
            onSelect: (row, isSelect, rowIndex, e) => {
                let restLst = this.state.employeeRestDayTable
                this.state.employeeRestDayTable.map(function(item,idx){
                    
                    if(idx==rowIndex)
                    {
                        item.isDeleted = isSelect ? "1" : "0"
                    }
                })
                ////console.log(this.state.employeeRestDayTable)
              }
        };
        const overtimeScheduleRow = {
            mode: 'checkbox',
            clickToSelectAndEditCell: true,
            onSelect: (row, isSelect, rowIndex, e) => {
                this.state.overtimeScheduleGRID.map(function(item,idx){
                    if(idx==rowIndex)
                    {
                        item.isDeleted = isSelect ? "1" : "0"
                    }
                })
              }
        };
        const columnWorkSchedule = [
            { dataField: 'date', text: 'DATE',editable: false,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'6%' }},
                style:{textAlign:'center'} },
            { dataField: 'locationId', text: 'LOCATION',editable: false,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'left',width:'16%'}},
                style:{textAlign:'left',whiteSpace:'nowrap'},
                formatter: (cell, row) => {
                    if(row.locationId!='' && row.locationId!=null){
                        if(this.state.locationDDL.filter(x => x.value == cell).length>0){
                            return this.state.locationDDL.find(x => x.value == cell).label;
                        }
                    }
                },
                editor: {
                    type: Type.SELECT,
                    options: this.state.locationDDL
                },
                editable: true
            },
            { dataField: 'workScheduleId', text: 'WORK SCHEDULE',
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'14%' }
                },
                style:{textAlign:'center'},
                formatter: (cell, row) => {
                    ////console.log(this.state.workscheduleDDL.includes(x => x.value == cell))
                    if(row.workScheduleId!='' && row.workScheduleId!=null){  
                        if(this.state.workscheduleDDL.filter(x => x.value == cell).length>0){
                            return this.state.workscheduleDDL.find(x => x.value == cell).label;
                        }
                    }
                },
                editor: {
                    type: Type.SELECT,
                    options: this.state.workscheduleDDL
                },
                editable: true
            },
            { dataField: 'timeIn', text: 'TIME-IN',editable: false,
                headerStyle: (colum, colIndex) => {
                    return { textAlign:'center',width:'6%' }},
                style:{textAlign:'center'} },
            { dataField: 'firstBreakOut', text: '1ST BREAK-OUT',editable: false,
            headerStyle: (colum, colIndex) => {
                return { textAlign:'center',width:'9%' }},
            style:{textAlign:'center'} },
            { dataField: 'firstBreakIn', text: '1ST BREAK-IN',editable: false,
            headerStyle: (colum, colIndex) => {
                return { textAlign:'center',width:'8%' }},
            style:{textAlign:'center'} },
            { dataField: 'secondBreakOut', text: '2ND BREAK-OUT',editable: false,
            headerStyle: (colum, colIndex) => {
                return { textAlign:'center',width:'9%' }},
            style:{textAlign:'center'} },
            { dataField: 'secondBreakIn', text: '2ND BREAK-IN',editable: false,
            headerStyle: (colum, colIndex) => {
                return { textAlign:'center',width:'8%' }},
            style:{textAlign:'center'} },
            { dataField: 'thirdBreakOut', text: '3RD BREAK-OUT',editable: false,
            headerStyle: (colum, colIndex) => {
                return { textAlign:'center',width:'9%' }},
            style:{textAlign:'center'} },
            { dataField: 'thirdBreakIn', text: '3RD BREAK-IN',editable: false,
            headerStyle: (colum, colIndex) => {
                return { textAlign:'center',width:'8%' }},
            style:{textAlign:'center'} },
            { dataField: 'timeOut', text: 'TIME-OUT',editable: false,
            headerStyle: (colum, colIndex) => {
                return { textAlign:'center',width:'7%' }},
            style:{textAlign:'center'} },
        ]

    const workScheduleRow = {
        mode: 'checkbox',
        hideSelectAll: false,
        clickToSelectAndEditCell: true,
        onSelect: (row, isSelect, rowIndex, e) => {
            row.isSelected = isSelect
            if(isSelect)
                this.state.selectedSchedules.push(row.id)
            else{
                let item_idx = this.state.selectedSchedules.indexOf(row.id)
                if (item_idx !== -1)
                this.state.selectedSchedules.splice(item_idx, 1)
            }
            let disablebutton = true
            this.state.employeeWorkScheduleTable.map(function(itm){
                if(itm.isSelected)
                    disablebutton=false
            })
            this.setState({disablebutton:disablebutton})
        },
        onSelectAll: this.handleOnSelectAllWorkSchedules,
        selected: this.state.selectedSchedules,
    };
    const minDate = new Date();

          return(
                <div>
                <Banner />
                    <Container fluid>
                    <Card className="mt-5">
                    <Card.Header>Timekeeping >> Employee Configuration</Card.Header>
                    <Card.Body>
                        <Form>
                            <Alert isOpen={this.state.isshow} color={this.state.color} fade={this.state.fade} className={this.state.isshow ? 'display-block' : 'display-none'}>
                                <div className={this.state.color}></div> 
                                {this.state.message}
                            </Alert>
                            <Form.Row>
                                <Form.Group as={Col} sm={12} controlId="formGridEmail">
                                    <Typeahead
                                        labelKey='name'
                                        id="basic-example"
                                        onChange={this.onChangeClientList}
                                        options={this.state.getClientList}
                                        placeholder="Select Client"
                                        
                                    />
                                </Form.Group>

                                <Form.Group as={Col} controlId="formGridPassword">
                                </Form.Group>
                            </Form.Row>

                            <Form.Row>
                                <Form.Group as={Col} sm={6} controlId="formGridEmail">
                                    <Typeahead
                                        labelKey='employeeName'
                                        id="basic-example"
                                        onChange={this.onChangeEmployeesList}
                                        options={this.state.getEmployeeList}
                                        placeholder="Select Employee"
                                        autocomplete="false"
                                        //defaultSelected={this.state.selectedOptions || ''}
                                    />
                                </Form.Group>
                                
                                <Form.Group as={Col} sm={6} controlId="formGridPassword">
                                    <Form.Control 
                                        type="text" 
                                        placeholder="Employee No" 
                                        autoComplete="off" 
                                        name="employeeNo"
                                        value={this.state.selectedEmployeeNo}
                                        onChange={this.onChangeEmployeesNo.bind(this)}
                                    />
                                </Form.Group>
                            </Form.Row>
                            <Tabs className="mt-2" defaultActiveKey="default" transition={false} id="noanim-tab-example">
                                <Tab eventKey="default" title="Default Configurations">
                                    <Form.Row>
                                        <Form.Group as={Col} sm={12}>
                                            <Card className="card-tab">
                                                <div className="card-header-tab"></div>
                                                <Card.Body>
                                                <Form.Group as={Col} sm={12}>
                                                    <Form.Row className="mt-4">
                                                        &nbsp;&nbsp;&nbsp;<Form.Check 
                                                            type="checkbox" 
                                                            label="Deactivate"
                                                            onChange={e => this.handleChangeStatus(e)}
                                                            checked={this.state.deactivate}
                                                            name="deactivate"
                                                            disabled
                                                        />
                                                        &nbsp;&nbsp;&nbsp;<Form.Check 
                                                            type="checkbox" 
                                                            label="With 201"
                                                            checked={this.state.IsWith201}
                                                            name="IsWith201"
                                                            disabled
                                                        />
                                                    </Form.Row>
                                                </Form.Group>
                                                <Form.Group as={Col} sm={12}>
                                                    <Form.Row className="mt-4">
                                                        <Form.Group as={Col} sm={3}>
                                                        <label class="font-italic" for="exampleFormControlInput1"><span style={{color: '#2188FC'}}>Current:</span> <span style={{color: 'red'}}>{this.state.firstName}</span></label>
                                                            <Form.Control
                                                                type="text"
                                                                autoComplete="off"
                                                                name="txtFirstName"
                                                                value={this.state.txtFirstName}
                                                                onChange={this.onChangeName}
                                                                maxLength="50"
                                                                placeholder="Enter First Name"
                                                                disabled
                                                            />
                                                        </Form.Group>
                                                        <Form.Group as={Col} sm={3} className="ml-4">
                                                            <label class="font-italic" for="exampleFormControlInput1"><span style={{color: '#2188FC'}}>Current:</span> <span style={{color: 'red'}}>{this.state.middleName}</span></label>
                                                            <Form.Control
                                                                type="text"
                                                                autoComplete="off"
                                                                name="txtMiddleName"
                                                                value={this.state.txtMiddleName}
                                                                onChange={this.onChangeName}
                                                                maxLength="50"
                                                                placeholder="Enter Middle Name"
                                                                disabled
                                                            />
                                                        </Form.Group>
                                                        <Form.Group as={Col} sm={3} className="ml-4">
                                                            <label class="font-italic" for="exampleFormControlInput1"><span style={{color: '#2188FC'}}>Current:</span> <span style={{color: 'red'}}>{this.state.lastName}</span></label>
                                                            <Form.Control
                                                                type="text"
                                                                autoComplete="off"
                                                                name="txtLastName"
                                                                value={this.state.txtLastName}
                                                                onChange={this.onChangeName}
                                                                maxLength="50"
                                                                placeholder="Enter Last Name"
                                                                disabled
                                                            />
                                                        </Form.Group>
                                                    </Form.Row>
                                                    
                                                    <Form.Row className="mt-4">
                                                        <Form.Group as={Col} sm={3}>
                                                            <Form.Row>
                                                                <Col>
                                                                <label class="font-italic" for="exampleFormControlInput1"><span style={{color: '#2188FC'}}>Current:</span> <span style={{color: 'red'}}>{this.state.dateOfBirth}</span></label>
                                                                </Col>
                                                            </Form.Row>
                                                            <Form.Row>
                                                                <Form.Group as={Col} sm={0} controlId="formGridPassword">
                                                                    <Form.Label>Date of Birth :</Form.Label>
                                                                </Form.Group>
                                                                <Form.Group as={Col} sm={4}>
                                                                    <DatePicker
                                                                        ref='DateOfBirth'
                                                                        selected={this.state.DateOfBirth}
                                                                        onChange={this.handleChangeDateOfBirth}
                                                                        minDate={this.minDate}
                                                                        value={this.props.DateOfBirth}
                                                                        dateFormat={"MM/dd/yyyy"}
                                                                        className="form-control"
                                                                        disabled
                                                                    />
                                                                </Form.Group>
                                                            </Form.Row>
                                                        </Form.Group>
                                                        <Form.Group as={Col} sm={3}>
                                                            <Form.Row>
                                                                <Col>
                                                                <label class="font-italic" for="exampleFormControlInput1"><span style={{color: '#2188FC'}}>Current:</span> <span style={{color: 'red'}}>{this.state.contractDateStart}</span></label>
                                                                </Col>
                                                            </Form.Row>
                                                            <Form.Row>
                                                                <Form.Group as={Col} sm={0} controlId="formGridPassword">
                                                                    <Form.Label>Contract Date Start :</Form.Label>
                                                                </Form.Group>
                                                                <Form.Group as={Col} sm={4}>
                                                                    <DatePicker
                                                                        ref='ContractDateStart'
                                                                        selected={this.state.ContractDateStart}
                                                                        onChange={this.handleChangeContractDateStart}
                                                                        minDate={this.minDate}
                                                                        value={this.props.ContractDateStart}
                                                                        dateFormat={"MM/dd/yyyy"}
                                                                        className="form-control"
                                                                        disabled
                                                                    />
                                                                </Form.Group>
                                                            </Form.Row>
                                                        </Form.Group>
                                                        <Form.Group as={Col} sm={3}>
                                                            <Form.Row>
                                                                <Col>
                                                                <label class="font-italic" for="exampleFormControlInput1"><span style={{color: '#2188FC'}}>Current:</span> <span style={{color: 'red'}}>{this.state.contractDateEnd}</span></label>
                                                                </Col>
                                                            </Form.Row>
                                                            <Form.Row>
                                                                <Form.Group as={Col} sm={0} controlId="formGridPassword">
                                                                    <Form.Label>Contract Date End :</Form.Label>
                                                                </Form.Group>
                                                                <Form.Group as={Col} sm={4}>
                                                                    <DatePicker
                                                                        ref='ContractDateEnd'
                                                                        selected={this.state.ContractDateEnd}
                                                                        onChange={this.handleChangeContractDateEnd}
                                                                        minDate={this.minDate}
                                                                        value={this.props.ContractDateStart}
                                                                        dateFormat={"MM/dd/yyyy"}
                                                                        className="form-control"
                                                                        disabled
                                                                    />
                                                                </Form.Group>
                                                            </Form.Row>
                                                        </Form.Group>
                                                        <Form.Group as={Col} sm={3}>
                                                            <Form.Row>
                                                                <Col>
                                                                <label class="font-italic" for="exampleFormControlInput1"><span style={{color: '#2188FC'}}>Current:</span> <span style={{color: 'red'}}>{this.state.mobileNumber}</span></label>
                                                                </Col>
                                                            </Form.Row>
                                                            <Form.Row>
                                                                <Form.Group as={Col} sm={0} controlId="formGridPassword">
                                                                    <Form.Label>Mobile Number :</Form.Label>
                                                                </Form.Group>
                                                                <Form.Group as={Col} sm={4}>
                                                                    <Form.Control
                                                                        type="text"
                                                                        ref="MobileNumber"
                                                                        autoComplete="off"
                                                                        name="MobileNumber"
                                                                        value={this.state.txtmobileNumber}
                                                                        onChange={this.onChangeMobileNumber}
                                                                        maxLength="11"
                                                                        onKeyPress={this.IsNumeric.bind(this)}
                                                                        disabled
                                                                    />
                                                                </Form.Group>
                                                            </Form.Row>
                                                        </Form.Group>
                                                        <Form.Group as={Col} sm={6}>
                                                            <Form.Row>
                                                                <Col>
                                                                <label class="font-italic" for="exampleFormControlInput1"><span style={{color: '#2188FC'}}>Current:</span> <span style={{color: 'red'}}>{this.state.getLocationNames}</span></label>
                                                                </Col>
                                                            </Form.Row>
                                                            <Form.Row>
                                                                <Form.Group as={Col} sm={6} controlId="formGridEmail">
                                                                    <Typeahead
                                                                        labelKey='name'
                                                                        id="basic-example"
                                                                        /* onChange={selectedClientLocation => this.setState({ selectedClientLocation })} */
                                                                        onChange={this.onChangeLocation}
                                                                        options={this.state.clientLocationList}
                                                                        placeholder="Select Branch"
                                                                        disabled
                                                                    />
                                                                </Form.Group>
                                                                <Form.Group as={Col} sm={0} controlId="formGridPassword">
                                                                    <Form.Label>Effectivity Date :</Form.Label>
                                                                </Form.Group>
                                                                <Form.Group as={Col} sm={2}>
                                                                    <DatePicker
                                                                        ref='BranchDate'
                                                                        selected={this.state.BranchDate}
                                                                        onChange={this.handleChangePayBranchDate}
                                                                        minDate={this.minDate}
                                                                        value={this.props.BranchDate}
                                                                        dateFormat={"MM/dd/yyyy"}
                                                                        className="form-control"
                                                                        disabled
                                                                    />
                                                                </Form.Group>
                                                            </Form.Row>
                                                        </Form.Group>
                                                        <Form.Group as={Col} sm={6}>
                                                            <Form.Row>
                                                                <Col>
                                                                    <label class="font-italic" for="exampleFormControlInput1"><span style={{color: '#2188FC'}}>Current:</span> <span style={{color: 'red'}}>{this.state.getPayTypes}</span></label>
                                                                </Col>
                                                            </Form.Row>
                                                            <Form.Row>
                                                                <Form.Group as={Col} sm={6} controlId="formGridEmail">
                                                                    <Typeahead
                                                                        labelKey='name'
                                                                        id="basic-example"
                                                                        onChange={this.onChangeEmployeesPayTypesList}
                                                                        options={this.state.getPayTypesList}
                                                                        placeholder="Select Pay Type"
                                                                        disabled
                                                                    />
                                                                </Form.Group>
                                                                <Form.Group as={Col} sm={0}>
                                                                    <Form.Label>Effectivity Date :</Form.Label>
                                                                </Form.Group>
                                                                <Form.Group as={Col} sm={2} controlId="formGridPassword">
                                                                    <DatePicker
                                                                        ref='payTypeDate'
                                                                        selected={this.state.payTypeDate}
                                                                        onChange={this.handleChangePayTypeDate}
                                                                        minDate={this.minDate}
                                                                        value={this.props.payTypeDate}
                                                                        dateFormat={"MM/dd/yyyy"}
                                                                        className="form-control"
                                                                        disabled
                                                                    />
                                                                </Form.Group>
                                                            </Form.Row>
                                                        </Form.Group>
                                                        <Form.Group as={Col} sm={6}>
                                                            <Form.Row>
                                                                <Col>
                                                                    <label class="font-italic" for="exampleFormControlInput1"><span style={{color: '#2188FC'}}>Current:</span> <span style={{color: 'red'}}>{this.state.getPayModes}</span></label>
                                                                </Col>
                                                            </Form.Row>
                                                            <Form.Row>
                                                                <Form.Group as={Col} sm={6} controlId="formGridEmail">
                                                                    <Typeahead
                                                                        labelKey='name'
                                                                        id="basic-example"
                                                                        onChange={this.onChangeEmployeesPayModesList}
                                                                        options={this.state.getPayModesList}
                                                                        placeholder="Select Pay Mode"
                                                                        disabled
                                                                    />
                                                                </Form.Group>
                                                                <Form.Group as={Col} sm={0}  controlId="formGridPassword">
                                                                    <Form.Label>Effectivity Date :</Form.Label>
                                                                </Form.Group>
                                                                <Form.Group as={Col} sm={2}>
                                                                    <DatePicker
                                                                        ref='PayModesDate'
                                                                        selected={this.state.PayModesDate}
                                                                        onChange={this.handleChangePayModesDate}
                                                                        minDate={this.minDate}
                                                                        value={this.props.PayModesDate}
                                                                        dateFormat={"MM/dd/yyyy"}
                                                                        className="form-control"
                                                                        disabled
                                                                    />
                                                                </Form.Group>
                                                            </Form.Row>
                                                        </Form.Group>
                                                        <Form.Group as={Col} sm={6}>
                                                            <Form.Row>
                                                                <Col>
                                                                    <label class="font-italic" for="exampleFormControlInput1"><span style={{color: '#2188FC'}}>Current:</span> <span style={{color: 'red'}}>{this.state.getPeriodType}</span></label>
                                                                </Col>
                                                            </Form.Row>
                                                            <Form.Row>
                                                                <Form.Group as={Col} sm={6} controlId="formGridEmail">
                                                                    <Typeahead
                                                                        labelKey='name'
                                                                        id="basic-example"
                                                                        onChange={this.handleChangePeriodTypesId}
                                                                        options={this.state.PeriodTypesId}
                                                                        placeholder="Select Period Type"
                                                                        disabled
                                                                    />
                                                                </Form.Group>
                                                                <Form.Group as={Col} sm={0}  controlId="formGridPassword">
                                                                    <Form.Label>Effectivity Date :</Form.Label>
                                                                </Form.Group>
                                                                <Form.Group as={Col} sm={2}>
                                                                    <DatePicker
                                                                        ref='PeriodTypesDate'
                                                                        selected={this.state.PeriodTypesDate}
                                                                        onChange={this.handleChangePeriodTypesDate}
                                                                        minDate={this.minDate}
                                                                        value={this.state.PeriodTypesDate}
                                                                        dateFormat={"MM/dd/yyyy"}
                                                                        className="form-control"
                                                                        disabled
                                                                    />
                                                                </Form.Group>
                                                            </Form.Row>
                                                        </Form.Group>
                                                        <Form.Group as={Col} sm={6}>
                                                            <Form.Row>
                                                                <Col className={this.state.hasViewSalaryAccess ? 'display-block' : 'display-none'}>
                                                                <label class="font-italic" for="exampleFormControlInput1"><span style={{color: '#2188FC'}}>Current:</span> <span style={{color: 'red'}}>{this.state.getSalaryOffered}</span></label>
                                                                </Col>
                                                            </Form.Row>
                                                            <Form.Row>
                                                                <Form.Group as={Col} sm={6} controlId="formGridEmail" className={this.state.hasViewSalaryAccess ? 'display-block' : 'display-none'}>
                                                                    <Form.Control 
                                                                        type="text" 
                                                                        placeholder="Enter Salary Rate" 
                                                                        ref="salaryRate"
                                                                        autoComplete="off" 
                                                                        name="salaryRate"
                                                                        value={this.state.salaryRate}
                                                                        onChange={this.onChangeSalaryRate}
                                                                        disabled
                                                                        />
                                                                </Form.Group>
                                                                <Form.Group as={Col} sm={0} controlId="formGridPassword" className={this.state.hasViewSalaryAccess ? 'display-block' : 'display-none'}>
                                                                    <Form.Label>
                                                                        Effectivity Date :
                                                                    </Form.Label>
                                                                </Form.Group>
                                                                <Form.Group as={Col} sm={2} className={this.state.hasViewSalaryAccess ? 'display-block' : 'display-none'}>
                                                                    <DatePicker
                                                                        ref='SalaryRateDate'
                                                                        selected={this.state.SalaryRateDate}
                                                                        onChange={this.handleChangeSalaryRateDate}
                                                                        minDate={this.minDate}
                                                                        value={this.props.SalaryRateDate}
                                                                        dateFormat={"MM/dd/yyyy"}
                                                                        className="form-control"
                                                                        disabled
                                                                    />
                                                                </Form.Group>
                                                            </Form.Row>
                                                        </Form.Group>
                                                        <Form.Group as={Col} sm={6}>
                                                            <Form.Row>
                                                                <Col>
                                                                    <label class="font-italic" for="exampleFormControlInput1"><span style={{color: '#2188FC'}}>Current:</span> <span style={{color: 'red'}}>{this.state.getEmployeeStatusTypes}</span></label>
                                                                </Col>
                                                            </Form.Row>
                                                            <Form.Row>
                                                                <Form.Group as={Col} sm={6} controlId="formGridEmail">
                                                                    <Typeahead
                                                                        labelKey='name'
                                                                        id="basic-example"
                                                                        onChange={this.onChangeEmployeesStatusList}
                                                                        options={this.state.getPayStatusList}
                                                                        placeholder="Select Status"
                                                                        disabled
                                                                    />
                                                                </Form.Group>
                                                                <Form.Group as={Col} sm={0} controlId="formGridPassword">
                                                                    <Form.Label>
                                                                            Effectivity Date :
                                                                    </Form.Label>
                                                                </Form.Group>
                                                                <Form.Group as={Col} sm={2}>
                                                                    <DatePicker
                                                                        ref='StatusDate'
                                                                        selected={this.state.StatusDate}
                                                                        onChange={this.handleChangeStatusDate}
                                                                        minDate={this.minDate}
                                                                        value={this.props.StatusDate}
                                                                        dateFormat={"MM/dd/yyyy"}
                                                                        className="form-control"
                                                                        disabled
                                                                    />
                                                                </Form.Group>
                                                            </Form.Row>
                                                        </Form.Group>
                                                        <Form.Group as={Col} sm={6}>
                                                            <Form.Row>
                                                                <Col><label class="font-italic" for="exampleFormControlInput1"><span style={{color: '#2188FC'}}>Current:</span> <span style={{color: 'red'}}>{this.state.currentTeamLead}</span></label></Col>
                                                            </Form.Row>
                                                            <Form.Row>
                                                                <Form.Group as={Col} sm={6} controlId="formGridEmail">
                                                                    <Typeahead
                                                                        labelKey='employeeName'
                                                                        id="basic-example"
                                                                        onChange={this.onChangeEmployeesTeamLeadList}
                                                                        options={this.state.getEmployeesTeamLeadList}
                                                                        placeholder="Select Team Lead"
                                                                        disabled
                                                                    />
                                                                </Form.Group>
                                                                <Form.Group as={Col} sm={0} controlId="formGridPassword">
                                                                    <Form.Label>
                                                                            Effectivity Date :
                                                                    </Form.Label>
                                                                </Form.Group>
                                                                <Form.Group as={Col} sm={2}>
                                                                    <DatePicker
                                                                        ref='TeamLeadDate'
                                                                        selected={this.state.TeamLeadDate}
                                                                        onChange={this.handleChangeTeamLeadDate}
                                                                        minDate={this.minDate}
                                                                        value={this.state.TeamLeadDate}
                                                                        dateFormat={"MM/dd/yyyy"}
                                                                        className="form-control"
                                                                        disabled
                                                                    />
                                                                </Form.Group>
                                                            </Form.Row>
                                                        </Form.Group>
                                                        <Form.Group as={Col} sm={6}>
                                                            <Form.Row>
                                                                <Col>
                                                                    <label class="font-italic" for="exampleFormControlInput1"><span style={{color: '#2188FC'}}>Current:</span> <span style={{color: 'red'}}>{this.state.currentCoordinator}</span></label>
                                                                </Col>
                                                            </Form.Row>
                                                            <Form.Row>
                                                                <Form.Group as={Col} sm={6} controlId="formGridEmail">
                                                                    <Typeahead
                                                                        labelKey='employeeName'
                                                                        id="basic-example"
                                                                        onChange={this.onChangeEmployeesCoordinatorList}
                                                                        options={this.state.getEmployeesCoordinatorList}
                                                                        placeholder="Select Coordinator"
                                                                        disabled
                                                                    />
                                                                </Form.Group>
                                                                <Form.Group as={Col} sm={0} controlId="formGridPassword">
                                                                    <Form.Label>
                                                                            Effectivity Date :
                                                                    </Form.Label>
                                                                </Form.Group>
                                                                <Form.Group as={Col} sm={2}>
                                                                    <DatePicker
                                                                        ref='CoordinatorDate'
                                                                        selected={this.state.CoordinatorDate}
                                                                        onChange={this.handleChangeCoordinatorDate}
                                                                        minDate={this.minDate}
                                                                        value={this.props.CoordinatorDate}
                                                                        dateFormat={"MM/dd/yyyy"}
                                                                        className="form-control"
                                                                        disabled
                                                                    />
                                                                </Form.Group>
                                                            </Form.Row>
                                                        </Form.Group>
                                                        <Form.Group as={Col} sm={6}>
                                                            <Form.Row>
                                                                <Col>
                                                                    <label class="font-italic" for="exampleFormControlInput1"><span style={{color: '#2188FC'}}>Current:</span> <span style={{color: 'red'}}>{this.state.getWorkSchedule}</span></label>
                                                                </Col>
                                                            </Form.Row>
                                                            <Form.Row>
                                                                <Form.Group as={Col} sm={6} controlId="formGridEmail">
                                                                    <Typeahead
                                                                        labelKey='description'
                                                                        id="basic-example"
                                                                        onChange={this.onChangeWorkScheduleIdList}
                                                                        options={this.state.getWorkScheduleList}
                                                                        placeholder="Select Work Schedule"
                                                                        disabled
                                                                    />
                                                                </Form.Group>
                                                                <Form.Group as={Col} sm={0} controlId="formGridPassword">
                                                                    <Form.Label>
                                                                        Effectivity Date :
                                                                    </Form.Label>
                                                                </Form.Group>
                                                                <Form.Group as={Col} sm={2}>
                                                                    <DatePicker
                                                                        ref='WorkScheduleDate'
                                                                        selected={this.state.WorkScheduleDate}
                                                                        onChange={this.handleChangeWorkScheduleDate}
                                                                        minDate={this.minDate}
                                                                        value={this.props.WorkScheduleDate}
                                                                        dateFormat={"MM/dd/yyyy"}
                                                                        className="form-control"
                                                                        disabled
                                                                    />
                                                                </Form.Group>
                                                            </Form.Row>
                                                        </Form.Group>
                                                        <Form.Group as={Col} sm={6}>
                                                            <Form.Row>
                                                                <Col className={this.state.hasViewPayCardTypeAccess ? 'display-block' : 'display-none'}>
                                                                    <label class="font-italic" for="exampleFormControlInput1"><span style={{color: '#2188FC'}}>Current:</span> <span style={{color: 'red'}}>{this.state.gePayCardType}</span></label>
                                                                </Col>
                                                            </Form.Row>
                                                            <Form.Row>
                                                                <Form.Group as={Col} sm={6} controlId="formGridEmail" className={this.state.hasViewPayCardTypeAccess ? 'display-block' : 'display-none'}>
                                                                    <Typeahead
                                                                        labelKey='name'
                                                                        id="basic-example"
                                                                        onChange={this.onChangePayCardType}
                                                                        options={this.state.getPayCardTypesList}
                                                                        placeholder="Select Pay Card Type"
                                                                        disabled
                                                                    />
                                                                </Form.Group>
                                                                <Form.Group as={Col} sm={0} controlId="formGridPassword" className={this.state.hasViewPayCardTypeAccess ? 'display-block' : 'display-none'}>
                                                                    <Form.Label controlId="formGridPassword">
                                                                        Effectivity Date :
                                                                    </Form.Label>
                                                                </Form.Group>
                                                                <Form.Group as={Col} sm={2} controlId="formGridPassword" className={this.state.hasViewPayCardTypeAccess ? 'display-block' : 'display-none'}>
                                                                    <DatePicker
                                                                        ref='PayCardType'
                                                                        selected={this.state.PayCardType}
                                                                        onChange={this.handleChangePayCardType}
                                                                        minDate={this.minDate}
                                                                        value={this.props.PayCardType}
                                                                        dateFormat={"MM/dd/yyyy"}
                                                                        className="form-control"
                                                                        disabled
                                                                    />
                                                                </Form.Group>   
                                                            </Form.Row>
                                                        </Form.Group>
                                                        <Form.Group as={Col} sm={6}>
                                                            <Form.Row>
                                                                <Col className={this.state.hasViewPayCardNumberAccess ? 'display-block' : 'display-none'}>
                                                                    <label class="font-italic" for="exampleFormControlInput1"><span style={{color: '#2188FC'}}>Current:</span> <span style={{color: 'red'}}>{this.state.getPayCardNumber}</span></label>
                                                                </Col>
                                                            </Form.Row>
                                                            <Form.Row>
                                                                <Form.Group as={Col} sm={6} controlId="formGridEmail" className={this.state.hasViewPayCardNumberAccess ? 'display-block' : 'display-none'}>
                                                                    <Form.Control
                                                                        type="text"
                                                                        placeholder="Enter Pay Card Number" 
                                                                        ref="txtpayCardNumber"
                                                                        autoComplete="off"
                                                                        name="txtpayCardNumber"
                                                                        value={this.state.txtpayCardNumber}
                                                                        onChange={this.onChangePayCardNumber}
                                                                        onKeyPress={this.IsNumeric.bind(this)}
                                                                        //disabled={this.state.disablePayCardNumber}
                                                                        disabled
                                                                    />
                                                                </Form.Group>
                                                                <Form.Group as={Col}sm={0} controlId="formGridPassword" className={this.state.hasViewPayCardNumberAccess ? 'display-block' : 'display-none'}>
                                                                    <Form.Label>
                                                                        Effectivity Date :
                                                                    </Form.Label>
                                                                </Form.Group>
                                                                <Form.Group as={Col} sm={2} className={this.state.hasViewPayCardNumberAccess ? 'display-block' : 'display-none'}>
                                                                    <DatePicker
                                                                        ref='PayCardNumber'
                                                                        selected={this.state.PayCardNumber}
                                                                        onChange={this.handleChangePayCardNumber}
                                                                        minDate={this.minDate}
                                                                        value={this.props.PayCardNumber}
                                                                        dateFormat={"MM/dd/yyyy"}
                                                                        className="form-control"
                                                                        disabled
                                                                    />
                                                                </Form.Group>
                                                            </Form.Row>
                                                        </Form.Group>
                                                        <Form.Group as={Col} sm={12}>
                                                            <Form.Row>
                                                                <Form.Group as={Col} sm={1} controlId="formGridPassword">
                                                                    <Form.Label>Remarks :</Form.Label>
                                                                </Form.Group>
                                                                <Form.Group as={Col} sm={11}>
                                                                    <Form.Control
                                                                        type="text"
                                                                        ref="Remarks"
                                                                        autoComplete="off"
                                                                        name="Remarks"
                                                                        value={this.state.remarks}
                                                                        disabled
                                                                    />
                                                                </Form.Group>
                                                            </Form.Row>
                                                        </Form.Group>
                                                    </Form.Row>
                                                    </Form.Group>
                                                </Card.Body>
                                            </Card> 
                                        </Form.Group>
                                    </Form.Row>      
                                </Tab>
                                <Tab eventKey="payroll" title="Payroll Configurations">
                                    <Form.Row>
                                    <Form.Group as={Col} sm={12} controlId="formGridEmail">
                                        <Card className="card-tab">
                                            <div className="card-header-tab"></div>
                                            <Card.Body>
                                            <Form.Group as={Col} sm={12}>
                                                <Form.Row>
                                                    <Form.Group as={Col} sm={6}>
                                                        <Form.Row>
                                                            <Col>
                                                                <label class="font-italic" for="exampleFormControlInput1"><span style={{color: '#2188FC'}}>Current:</span> <span style={{color: 'red'}}>{this.state.getSSSAmount}</span></label>
                                                            </Col>
                                                        </Form.Row>
                                                        <Form.Row>
                                                            <Form.Group as={Col} sm={6} controlId="formGridEmail">
                                                                <Typeahead
                                                                    labelKey='sceeShare'
                                                                    id="basic-example"
                                                                    onChange={this.onChangeSSSBrackets}
                                                                    options={this.state.sssBrackets}
                                                                    placeholder="Select SSS Bracket"
                                                                />
                                                            </Form.Group>
                                                            <Form.Group as={Col} sm={0} controlId="formGridPassword">
                                                                <Form.Label>
                                                                    Effectivity Date :
                                                                </Form.Label>
                                                            </Form.Group>
                                                            <Form.Group as={Col} sm={2}>
                                                                <DatePicker
                                                                    ref='sssAmountDate'
                                                                    selected={this.state.sssAmountDate}
                                                                    onChange={this.handleChangeSSSAmountDate}
                                                                    minDate={this.minDate}
                                                                    value={this.props.sssAmountDate}
                                                                    dateFormat={"MM/dd/yyyy"}
                                                                    className="form-control"
                                                                />
                                                            </Form.Group>
                                                        </Form.Row>
                                                    </Form.Group>
                                                </Form.Row>
                                                <Form.Row>
                                                    <Form.Group as={Col} sm={6}>
                                                        <Form.Row>
                                                            <Col>
                                                                <label class="font-italic" for="exampleFormControlInput1"><span style={{color: '#2188FC'}}>Current:</span> <span style={{color: 'red'}}>{this.state.getPHICAmount}</span></label>
                                                            </Col>
                                                        </Form.Row>
                                                        <Form.Row>
                                                            <Form.Group as={Col} sm={6} controlId="formGridEmail">
                                                                <Form.Control
                                                                    type="text"
                                                                    placeholder="Enter PHIC Amount" 
                                                                    ref="phicAmount"
                                                                    autoComplete="off"
                                                                    name="phicAmount"
                                                                    value={this.state.phicAmount}
                                                                    onChange={this.onChangePHICAmount}/>
                                                            </Form.Group>
                                                            <Form.Group as={Col} sm={0} controlId="formGridPassword">
                                                                <Form.Label>
                                                                    Effectivity Date :
                                                                </Form.Label>
                                                            </Form.Group>
                                                            <Form.Group as={Col} sm={2}>
                                                                <DatePicker
                                                                    ref='phicAmountDate'
                                                                    selected={this.state.phicAmountDate}
                                                                    onChange={this.handleChangePHICAmountDate}
                                                                    minDate={this.minDate}
                                                                    value={this.props.phicAmountDate}
                                                                    dateFormat={"MM/dd/yyyy"}
                                                                    className="form-control"
                                                                />
                                                            </Form.Group>
                                                        </Form.Row>
                                                    </Form.Group>
                                                </Form.Row>
                                                <Form.Row>
                                                    <Form.Group as={Col} sm={6}>
                                                        <Form.Row>
                                                            <Col>
                                                                <label class="font-italic" for="exampleFormControlInput1"><span style={{color: '#2188FC'}}>Current:</span> <span style={{color: 'red'}}>{this.state.getHDMFAmount}</span></label>
                                                            </Col>
                                                        </Form.Row>
                                                        <Form.Row>
                                                            <Form.Group as={Col} sm={6} controlId="formGridEmail">
                                                                <Form.Control
                                                                    type="text"
                                                                    placeholder="Enter HDMF Amount" 
                                                                    ref="hdmfAmount"
                                                                    autoComplete="off"
                                                                    name="hdmfAmount"
                                                                    value={this.state.hdmfAmount}
                                                                    onChange={this.onChangeHDMFAmount}/>
                                                            </Form.Group>
                                                            <Form.Group as={Col} sm={0} controlId="formGridPassword">
                                                                <Form.Label>
                                                                    Effectivity Date :
                                                                </Form.Label>
                                                            </Form.Group>
                                                            <Form.Group as={Col} sm={2}>
                                                                <DatePicker
                                                                    ref='hdmfAmountDate'
                                                                    selected={this.state.hdmfAmountDate}
                                                                    onChange={this.handleChangeHDMFAmountDate}
                                                                    minDate={this.minDate}
                                                                    value={this.props.hdmfAmountDate}
                                                                    dateFormat={"MM/dd/yyyy"}
                                                                    className="form-control"
                                                                />
                                                            </Form.Group>
                                                        </Form.Row>
                                                    </Form.Group>
                                                </Form.Row>
                                                <Form.Row>
                                                    <Form.Group as={Col} sm={6}>
                                                        <Form.Row>
                                                            <Col>
                                                                <label class="font-italic" for="exampleFormControlInput1"><span style={{color: '#2188FC'}}>Current:</span> <span style={{color: 'red'}}>{this.state.getEColaAmount}</span></label>
                                                            </Col>
                                                        </Form.Row>
                                                        <Form.Row>
                                                            <Form.Group as={Col} sm={6} controlId="formGridEmail">
                                                                <Form.Control
                                                                    type="text"
                                                                    placeholder="Enter ECola Rate" 
                                                                    ref="ecolaValue"
                                                                    autoComplete="off"
                                                                    name="ecolaValue"
                                                                    value={this.state.ecolaValue}
                                                                    onChange={this.onChangeEColaValue}/>
                                                            </Form.Group>
                                                            <Form.Group as={Col} sm={0} controlId="formGridPassword">
                                                                <Form.Label>
                                                                    Effectivity Date :
                                                                </Form.Label>
                                                            </Form.Group>
                                                            <Form.Group as={Col} sm={2}>
                                                                <DatePicker
                                                                    ref='ecolaValueDate'
                                                                    selected={this.state.ecolaValueDate}
                                                                    onChange={this.handleChangeEColaValueDate}
                                                                    minDate={this.minDate}
                                                                    value={this.props.ecolaValueDate}
                                                                    dateFormat={"MM/dd/yyyy"}
                                                                    className="form-control"
                                                                />
                                                            </Form.Group>
                                                        </Form.Row>
                                                    </Form.Group>
                                                </Form.Row>
                                                <Form.Row>
                                                    <Form.Group as={Col} sm={6}>
                                                        <Form.Row>
                                                            <Col>
                                                                <label class="font-italic" for="exampleFormControlInput1"><span style={{color: '#2188FC'}}>Current:</span> <span style={{color: 'red'}}>{this.state.getColaAmount}</span></label>
                                                            </Col>
                                                        </Form.Row>
                                                        <Form.Row>
                                                            <Form.Group as={Col} sm={6} controlId="formGridEmail">
                                                                <Form.Control
                                                                    type="text"
                                                                    placeholder="Enter Cola Rate" 
                                                                    ref="colaValue"
                                                                    autoComplete="off"
                                                                    name="colaValue"
                                                                    value={this.state.colaValue}
                                                                    onChange={this.onChangeColaValue}/>
                                                            </Form.Group>
                                                            <Form.Group as={Col} sm={0} controlId="formGridPassword">
                                                                <Form.Label>
                                                                    Effectivity Date :
                                                                </Form.Label>
                                                            </Form.Group>
                                                            <Form.Group as={Col} sm={2}>
                                                                <DatePicker
                                                                    ref='colaValueDate'
                                                                    selected={this.state.colaValueDate}
                                                                    onChange={this.handleChangeColaValueDate}
                                                                    minDate={this.minDate}
                                                                    value={this.props.colaValueDate}
                                                                    dateFormat={"MM/dd/yyyy"}
                                                                    className="form-control"
                                                                />
                                                            </Form.Group>
                                                        </Form.Row>
                                                    </Form.Group>
                                                </Form.Row>
                                                <Form.Row>
                                                    <Form.Group as={Col} sm={6}>
                                                        <Form.Row>
                                                            <Col>
                                                                <label class="font-italic" for="exampleFormControlInput1"><span style={{color: '#2188FC'}}>Current:</span> <span style={{color: 'red'}}>{this.state.getSeaAmount}</span></label>
                                                            </Col>
                                                        </Form.Row>
                                                        <Form.Row>
                                                            <Form.Group as={Col} sm={6} controlId="formGridEmail">
                                                                <Form.Control
                                                                    type="text"
                                                                    placeholder="Enter Sea Rate" 
                                                                    ref="seaRate"
                                                                    autoComplete="off"
                                                                    name="seaRate"
                                                                    value={this.state.seaRate}
                                                                    onChange={this.onChangeSeaValue}/>
                                                            </Form.Group>
                                                            <Form.Group as={Col} sm={0} controlId="formGridPassword">
                                                                <Form.Label>
                                                                    Effectivity Date :
                                                                </Form.Label>
                                                            </Form.Group>
                                                            <Form.Group as={Col} sm={2}>
                                                                <DatePicker
                                                                    ref='seaValueDate'
                                                                    selected={this.state.seaValueDate}
                                                                    onChange={this.handleChangeSeaValueDate}
                                                                    minDate={this.minDate}
                                                                    value={this.props.seaValueDate}
                                                                    dateFormat={"MM/dd/yyyy"}
                                                                    className="form-control"
                                                                />
                                                            </Form.Group>
                                                        </Form.Row>
                                                    </Form.Group>
                                                </Form.Row>
                                                </Form.Group>
                                            </Card.Body>
                                        </Card>
                                    </Form.Group>
                                    </Form.Row>
                                </Tab>
                                <Tab eventKey="restday" title="Employee Restday">
                                    <Form.Row>
                                        <Form.Group as={Col} sm={12} controlId="formGridEmail">
                                            <Card className="card-tab">
                                                <div className="card-header-tab"></div>
                                                <Card.Body>
                                                    <Form.Group as={Col} sm={12}>
                                                        <Form.Row className="mt-2">
                                                            <Form.Group as={Col} sm={0} controlId="formGridEmail">
                                                                <DatePicker
                                                                    ref='RestDate'
                                                                    selected={this.state.RestDate}
                                                                    onChange={this.handleChangeRestDate}
                                                                    value={this.state.RestDate}
                                                                    dateFormat={"MM/dd/yyyy"}
                                                                    className="form-control"
                                                                />
                                                            </Form.Group>
                                                            <Form.Group as={Col} controlId="formGridPassword">
                                                                <Button style={{minWidth:'60px'}} className="ml-auto" variant="success" onClick={this.onSubmitAdd}>Add</Button>
                                                            </Form.Group>
                                                        </Form.Row>
                                                        <Form.Row>
                                                        <Form.Group as={Col} controlId="formGridEmail">
                                                            <Card.Header>Restday List</Card.Header>
                                                            <BootstrapTable
                                                                    keyField = "restdate"
                                                                    data = { this.state.employeeRestDayTable}
                                                                    columns = { columnRestDay}
                                                                    selectRow = { restDayRow }
                                                                    cellEdit = { cellEditFactory({ mode: 'dbclick', blurToSave: true }) }
                                                                    rowClasses="noser-table-row-class"
                                                                    striped
                                                                    hover
                                                                    condensed
                                                                    pagination={ paginationFactory({sizePerPageRenderer})}
                                                                />
                                                        </Form.Group>
                                                        </Form.Row>
                                                    </Form.Group>
                                                </Card.Body>
                                            </Card>
                                        </Form.Group>
                                    </Form.Row>
                                </Tab>
                                <Tab eventKey="otschedule" title="Employee Overtime Scheduled">
                                    <Form.Row>
                                        <Form.Group as={Col} sm={12} controlId="formGridEmail">
                                            <Card className="card-tab">
                                                <div className="card-header-tab"></div>
                                                <Card.Body>
                                                    <Form.Group as={Col} sm={12}>
                                                        <Form.Row className="mt-2">
                                                            <Form.Group as={Col} sm={0} controlId="formGridEmail">
                                                                <DatePicker
                                                                    ref='scheduleDate'
                                                                    selected={this.state.scheduleDate}
                                                                    onChange={this.handleChangeScheduleDate}
                                                                    value={this.state.scheduleDate}
                                                                    dateFormat={"MM/dd/yyyy"}
                                                                    className="form-control"
                                                                />
                                                            </Form.Group>
                                                            <Form.Group as={Col} controlId="formGridPassword">
                                                                <Button style={{minWidth:'60px'}} className="ml-auto" variant="success" onClick={this.handleAddOTSchedule}>Add</Button>
                                                            </Form.Group>
                                                        </Form.Row>
                                                        <Form.Row>
                                                        <Form.Group as={Col} controlId="formGridEmail">
                                                            <Card.Header>Overtime Schedule List</Card.Header>
                                                            <BootstrapTable
                                                                    keyField = "id"
                                                                    data = { this.state.overtimeScheduleGRID}
                                                                    columns = { columnOTSchedule}
                                                                    selectRow = { overtimeScheduleRow }
                                                                    cellEdit = { cellEditFactory({ mode: 'dbclick', blurToSave: true }) }
                                                                    rowClasses="noser-table-row-class"
                                                                    striped
                                                                    hover
                                                                    condensed
                                                                    pagination={ paginationFactory({sizePerPageRenderer})}
                                                                />
                                                        </Form.Group>
                                                        </Form.Row>
                                                    </Form.Group>
                                                </Card.Body>
                                            </Card>
                                        </Form.Group>
                                    </Form.Row>
                                </Tab>
                                <Tab eventKey="leave" title="Employee Leaves">
                                <Form.Row>
                                        <Form.Group as={Col} sm={12} controlId="formGridEmail">
                                            <Card className="card-tab">
                                                <div className="card-header-tab"></div>
                                                <Card.Body>
                                                    <Form.Group as={Col} sm={12}>
                                                        <Form.Row className="mt-2">
                                                        <Form.Group as={Col} sm={0} controlId="formGridEmail">
                                                            <DatePicker
                                                                ref='FromDate'
                                                                selected={this.state.FromDate}
                                                                onChange={this.handleChangeFromDate}
                                                                value={this.state.FromDate}
                                                                dateFormat={"MM/dd/yyyy"}
                                                                style={{height:'40px !important'}}
                                                                size="40"
                                                                className="form-control"
                                                            />
                                                        </Form.Group>
                                                        <Form.Group as={Col} sm={2} controlId="formGridEmail">
                                                            <Form.Control 
                                                                type="number" 
                                                                min="0.5"
                                                                max="90"
                                                                step={0.5} precision={2}
                                                                value={this.state.duration}
                                                                onChange={this.onChangeDuration}
                                                                autoComplete="off"
                                                                //onKeyPress={e => /^[0-9]*\.?[0-9]*$/.test(e.key) && e.preventDefault()}
                                                                onKeyPress={this.IsNumeric.bind(this)}
                                                                placeholder="Duration"/>
                                                        </Form.Group>

                                                        <Form.Group as={Col} sm={3} controlId="formGridEmail">
                                                            <Typeahead
                                                                labelKey='name'
                                                                id="basic-example"
                                                                onChange={this.onChangeReasonToLeave}
                                                                options={this.state.leaveTypesDDL}
                                                                placeholder="Select Reason for Leave/ Absent"
                                                                inputProps={{ required: true }}
                                                            />
                                                            {/* <Form.Control 
                                                                type="text" 
                                                                placeholder="Reason for Leave/ Absent" 
                                                                value={this.state.reason}
                                                                onChange={this.onChangeReason}
                                                                autoComplete="off"/> */}
                                                        </Form.Group>
                                                        <Form.Group as={Col} sm={0} controlId="formGridEmail">
                                                            <Form.Check label="Unpaid" onChange={e => this.handleChangeCheckboxLeave(e)}/>
                                                        </Form.Group>
                                                        <Form.Group as={Col} controlId="formGridPassword">
                                                            <ButtonToolbar>
                                                                <Button style={{minWidth:'60px'}}  variant="success" onClick={this.onSubmitAddLeave}>Add</Button>
                                                            </ButtonToolbar>
                                                        </Form.Group>
                                                        </Form.Row>
                                                        <div>
                                                        <Form.Row>
                                                        <Form.Group as={Col} controlId="formGridPassword">
                                                            <Card.Header>Leaves List</Card.Header>
                                                            <BootstrapTable
                                                                    keyField = "leaveDate"
                                                                    data = { this.state.LeavedayDataList }
                                                                    columns = { columnLeaveTable }
                                                                    selectRow = { employeeLeaveRow }
                                                                    cellEdit = { cellEditFactory({ mode: 'dbclick', blurToSave: true }) }
                                                                    rowClasses="noser-table-row-class"
                                                                    striped
                                                                    hover
                                                                    condensed
                                                                    pagination={ paginationFactory({sizePerPageRenderer})}
                                                                />
                                                        </Form.Group>
                                                        </Form.Row>
                                                        </div>
                                                    </Form.Group>
                                                </Card.Body>
                                            </Card>
                                        </Form.Group>
                                    </Form.Row>
                                </Tab>
                                <Tab eventKey="schedule" title="Employee Schedules">
                                    <Form.Row>
                                    <Form.Group as={Col} sm={12} controlId="formGridEmail">
                                        <Card className="card-tab">
                                            <div className="card-header-tab"></div>
                                            <Card.Body>
                                                <Form.Group as={Col} sm={12}>
                                                    <Form.Row className="mt-2">
                                                        <Form.Group as={Col} sm={3} >
                                                            <Typeahead
                                                                labelKey='payPeriod'
                                                                id="basic-example"
                                                                onChange={this.onChangePayPeriod}
                                                                options={this.state.payPeriodList}
                                                                placeholder="Select Payroll Period"
                                                            />
                                                        </Form.Group>
                                                    </Form.Row>
                                                    <Form.Row>
                                                    <Form.Group  controlId="formGridPassword" as={Col}>
                                                        <div>
                                                            <ButtonToolbar >
                                                                <Button disabled={this.state.disablebutton} variant="success" style={{minWidth:'60px',marginRight:'1pt'}} onClick={this.handleAddSchedule}>ADD ROW</Button>
                                                                <Button disabled={this.state.disablebutton} variant="danger" style={{minWidth:'60px',marginRight:'1pt'}} onClick={this.handleRemoveSchedule}>REMOVE ROW</Button>
                                                                <Button disabled={this.state.disablebutton} variant="primary" style={{minWidth:'60px'}} onClick={this.handleSubmitSchedule}>SAVE SCHEDULES</Button>
                                                            </ButtonToolbar>
                                                        </div>
                                                        <Card.Header className="mt-1">WORK SCHEDULES</Card.Header>
                                                        <BootstrapTable
                                                            wrapperClasses="table-responsive"
                                                            rowClasses="noser-table-row-class"
                                                            keyField = "id"
                                                            data = { this.state.employeeWorkScheduleTable }
                                                            columns = { columnWorkSchedule }
                                                            selectRow = { workScheduleRow }
                                                            cellEdit = { cellEditFactory({ mode: 'dbclick', blurToSave: true }) }
                                                            rowClasses="noser-table-row-class"
                                                            striped
                                                            hover
                                                            condensed
                                                            expandRow
                                                            pagination={ paginationFactory({sizePerPage:50,hideSizePerPage:true,hidePageListOnlyOnePage:true}) }
                                                            />
                                                        </Form.Group>
                                                    </Form.Row>
                                                </Form.Group>
                                            </Card.Body>
                                        </Card>
                                    </Form.Group>
                                    </Form.Row>
                                </Tab>
                            </Tabs>
                            
                        </Form>
                        <ButtonToolbar className="mt-2" style={{marginLeft:"-8px"}}>
                            <Button style={{minWidth:'60px'}} variant="success" onClick={this.onSubmitSaveEmployee}>Save</Button>&nbsp;&nbsp;&nbsp;
                            <Button style={{minWidth:'60px'}} variant="danger" href="/banner">Close</Button>
                        </ButtonToolbar>
                    </Card.Body>
                </Card>
                </Container>
                <NoserLoading show={this.state.isloading} />
                </div>
        )
    }

}

export  default EmployeeConfig
