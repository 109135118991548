import axios from 'axios'

const GetRequest = async(props) => {
    let response = {
        "status" : "",
        "message" : "",
        [props.params._collection]:[]
    }
    let collection = new Array()
    console.log("befire data access")
    console.log(props.params)
    await axios
      .post(props.url , props.params)
      .then(res => {
            const data = res.data
            console.log("data access")
            console.log(res.data)
            // data[props.params._collection].map(function(itm,i){
            //     let strJson = "{" + "'id':" + itm.substring(17, 43) + "," + itm.substring(46, itm.length)
            //     let json = JSON.parse(strJson.replace(/'/g,'"'));
            //     collection.push(json)
            // })
            data.ResultList.map(function(itm,i){
                let strJson = "{" + "'id':" + itm.substring(17, 43) + "," + itm.substring(46, itm.length)
                let json = JSON.parse(strJson.replace(/'/g,'"'));
                collection.push(json)
            })
            
            response = {
                status : "1",
                "message" : "success",
                [props.params._collection]:collection
            }
      })
      .catch(error=>{
        response = {
            status : "0",
            "message" : "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
            [props.params._collection]:[]
        }
      })
    return response
} 
const PostRequest = async(props) => {
    let response = {
        "status" : "",
        "message" : ""
    }
    let collection = new Array()
    await axios
      .post(props.url , props.params)
      .then(res => {
            //const data = res.data
            response = {
                status : "1",
                "message" : "success"
            }
      })
      .catch(error=>{
        response = {
            status : "0",
            "message" : "An error occured while processing your request, Please contact your System Administrator for : " + error.message
        }
      })
    return response
} 

export { GetRequest, PostRequest }