import {
    React,Component,BootstrapTable,
    paginationFactory,Button, ButtonToolbar, Card, 
    Form, Col, Row, axios, Container, Banner, Typeahead, TimePicker,
    cellEditFactory, Type, moment, NavLink, Alert, AppConfiguration,
    NoserLoading, Noser, sizePerPageRenderer, DatePicker, Modal
} 
from '../../noser-hris-component';

class ContractSigningCreate extends Component {
    constructor(props) {
        super(props)
        this.ref = React.createRef();
        this.state = {
            userinfo    :   JSON.parse(sessionStorage.getItem("userData")),
            isloading   :   false,
            isshow      :   false,
            alerttype   :   "",
            message     :   "",
            color       :   "",
            fade        :   true,
            name        :   '',
            disableBtn  :   true,

            contracttypeId:"",
            contracttype:"",
            clientId:"",
            clientName:"",
            employeeId:"",
            employeeName:"",
            lastName:"",
            branchId:"",
            branchName:"",
            positionId:"",
            positionName:"",
            employmentstatusId:"",
            employmentStatus:"",
            contractStart:"",
            contractEnd:"",
            salaryRate:"",
            issuedbyId:"",
            issuerDDL:[],
            paytype:"",
            groupRegion:""
        }
    }
    componentDidMount(){
        this.state.userinfo = JSON.parse(sessionStorage.getItem("userData"))
        this.props.onRefModal(this)
    }
    initialize=(e)=>{
        this.setState({
            userinfo    :   JSON.parse(sessionStorage.getItem("userData")),
            isloading   :   false,
            isshow      :   false,
            alerttype   :   "",
            message     :   "",
            color       :   "",
            fade        :   true,
            name        :   '',
            disableBtn  :   true,

            contracttypeId:e.probitionaryTypeId,
            contracttype:e.probitionaryType,
            clientId:e.clientId,
            clientName:e.clientName,
            employeeId:e.id,
            employeeName:e.employeeName,
            lastName:e.lastName,
            branchId:e.locationid,
            branchName:e.locationName,
            positionId:e.positionId,
            positionName:e.positionName,
            employmentstatusId:e.employmentStatusId,
            employmentStatus:e.employmentStatus,
            contractStart:e.contractDateStart,
            contractEnd:e.contractDateEnd,
            salaryRate:e.salaryRate,
            issuedbyId:"",
            issuerDDL:[],
            paytype:e.paytype,
            groupRegion:e.groupRegion
        })
        let issueDate = new Date(e.contractDateStart)
        issueDate = new Date(issueDate.setMonth(issueDate.getMonth()+6))
        issueDate = new Date(issueDate.setDate(issueDate.getDate()+1))
        e.issueDate = issueDate
        //e.issueDate = new Date()
        sessionStorage.setItem("contract_" + this.state.userinfo.userId + this.state.userinfo.employeeId, JSON.stringify(e))
        this.GetIssuer()
        console.log(e)
    }
    GetIssuer = async()=> {
        this.setState({isloading:true});
        const params = {
            "IpAddress"     :   "0.0.0.0",
            "ClientId"      :   "111",
            "UserId"        :   this.state.userinfo.userId
        };
        await axios.post(AppConfiguration.Setting().noserapiendpoint + "Employee/GetEmployeeApprovers",  params)
        .then(res => {
            const data = res.data;
            console.log(data)
            this.setState({issuerDDL:data.approvers,isloading:false});
        })
        .catch(error=>{
            this.setState(  {
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade            :   true
            })
        })
    }
    handleGenerateContract = async() => {
        
        if(this.state.issuedbyId==="")
        {
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "Please select issued by name.",
                fade        :   true
            })
            return
        }

        this.setState({isloading:true,isDisable:true})
        const params = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.userinfo.clientId,
            "UserId":this.state.userinfo.userId,
            "EmployeeId" : this.state.employeeId,
            "ContractTypeId" : this.state.contracttypeId,
            "ContractDateStart" : this.state.contractStart,
            "ContractDateEnd" : this.state.contractEnd,
            "EmploymentStatusId" : this.state.employmentstatusId,
            "EmploymentStatus" : this.state.employmentStatus,
            "SalaryRate" : this.state.salaryRate,
            "IssuedById" : this.state.issuedbyId,
            "Remarks" : "UNRETURN",
            "IsGenerated" : "1",
        }
        await axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Administrator/AddContract", params)
        .then(res => {
            const data = res.data
            if(data.status=="1"){
                this.setState({isloading:false});
                this.props.onHide()
                if(this.state.employmentstatusId==="13")
                    window.open('/TrainingContract','_blank').focus()
                else if(this.state.employmentstatusId==="8")
                    window.open('/ProbiContract','_blank').focus()
                else
                    window.open('/Contract','_blank').focus()
            }
            else{
                this.setState({
                    isloading   :   false,
                    alerttype   :   "Error!",
                    isshow      :   true,
                    color       :   "danger",
                    message     :   data.message,
                    fade        :   true
                });
            }
        })
        .catch(error=>{
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade        :   true,
            })
        })
    }
    handleChangedIssuer = (e) =>{
        this.state.alerttype="";this.state.isshow=false;this.state.color="";this.state.message="";this.state.fade=true;this.state.isloading=false
        if(e.length===0) {
            this.state.issuedbyId=""
            return
        }
        this.state.issuedbyId=e[0].id
    }
    render() {
        return(
            
                <Modal
                    {...this.props}
                    return
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    backdrop="static"
                >
                    <Modal.Header closeButton className="card-header">
                        <Modal.Title id="contained-modal-title-vcenter">
                            GENERATE CONTRACT
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Container>
                            <Form>
                                <Alert isOpen={this.state.isshow} color={this.state.color} fade={this.state.fade} className={this.state.isshow ? 'display-block' : 'display-none'}>
                                    <div className={this.state.color}></div> 
                                    {this.state.message}
                                </Alert>
                                <Form.Group as={Row} controlId="formPlaintextEmail">
                                    <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                    CONTRACT TYPE
                                    </Form.Label>
                                    <Col sm="10">
                                        <Form.Control 
                                            name='contracttype'
                                            type="text" 
                                            autoComplete="off" 
                                            value={this.state.contracttype}
                                            disabled
                                        />
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row}>
                                    <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                    CLIENT NAME
                                    </Form.Label>
                                    <Col sm="10">
                                        <Form.Control 
                                            name='clientName'
                                            type="text" 
                                            autoComplete="off" 
                                            value={this.state.clientName}
                                            disabled
                                        />
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row}>
                                    <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                    BRANCH NAME
                                    </Form.Label>
                                    <Col sm="10">
                                        <Form.Control 
                                            name='branchName'
                                            type="text" 
                                            autoComplete="off" 
                                            value={this.state.branchName}
                                            disabled
                                        />
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row}>
                                    <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                    EMPLOYEE NAME
                                    </Form.Label>
                                    <Col sm="10">
                                        <Form.Control 
                                            name='employeeName'
                                            type="text" 
                                            autoComplete="off" 
                                            value={this.state.employeeName}
                                            disabled
                                        />
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row}>
                                <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                    ISSUED BY
                                </Form.Label>
                                <Col sm="10">
                                    <Typeahead
                                        id="issuedbyId"
                                        name="issuedbyId"
                                        labelKey='employeeName'
                                        onChange={this.handleChangedIssuer}
                                        options={this.state.issuerDDL}
                                        placeholder="Select issuer name"
                                    />
                                </Col>
                            </Form.Group>
                            </Form>
                        </Container>
                    </Modal.Body>
                    <Modal.Footer>
                        <ButtonToolbar >
                            <Button variant="success" className="ml-auto noser-button-mr1" onClick = { this.handleGenerateContract }>GENERATE</Button>
                            <Button variant="danger" className="noser-button-mr15" onClick={this.props.onHide}>CANCEL</Button>
                        </ButtonToolbar>
                    </Modal.Footer>
                    <NoserLoading show={this.state.isloading} />
            </Modal>
            );
        }

}

export  default ContractSigningCreate