import {
    React,Component,BootstrapTable, Type,
    paginationFactory,Button, ButtonToolbar, Card,
    Form, Col, Row, Container, axios, Typeahead, Banner, TimePicker,
    cellEditFactory ,moment, NavLink, Alert, AppConfiguration,
    NoserLoading, Noser, sizePerPageRenderer, DatePicker
} 
from '../../noser-hris-component';
import type from 'react-bootstrap-table2-editor';

class EmployeeRecord extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userinfo        :   [],
            isloading       :   false,
            isshow          :   false,
            alerttype       :   "",
            message         :   "",
            color           :   "",
            fade            :   true,
            selected: [],

            employeeRecordList:[],
            employeeRecordListGrid:[],
            selectedEmployeeNameId:"",
            selectedEmployeeName:"",
            selectedEmployeeId:"",
            selectedSortBy:"",
            selectedSortById:"",
            statusList:[],
            getClientList :[],
            selectedClientName:"",
            selectedClientId:"",
            selectedLocationId:"",
            selectedLocation:""

            
           
        }
         
    }

    componentDidMount(){
        const sleep = (milliseconds) => {return new Promise(resolve => setTimeout(resolve, milliseconds))}
        this.state.userinfo = JSON.parse(sessionStorage.getItem("userData"))
        this.GetSubmitted();
        this.getStatus();
        this.getClient();
        
        sleep(1000).then(() => {
        this.setState({isLoading:false})})
    }

    getClient(){
        this.setState({isloading:true,})

        const getParams = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.userinfo.clientId,
            "UserId":this.state.userinfo.userId,
        }

        axios
            .post(AppConfiguration.Setting().noserapiendpoint + "Client/GetClientList", getParams)
            .then(res => {
                console.log("Client List ");
                console.log(res.data);
                this.setState({
                    isloading:false,
                    getClientList : res.data.clients
                })
            })
    }

    handleChangeClient= (e) => {
        if(e.length == 0) {
            this.state.selectedClientName=""
            this.state.selectedClientId=""
            return
        } 
        this.state.selectedClientId = e[0].id
        this.state.selectedClientName = e[0].name
        this.setState({isloading:true})
        
        // this.GetEmployees();
        // this.getLocation();
    
    }

    handleChangeEmployee= (e) => {
        console.log(e)
            if (e.length == 0) {
                this.setState({selectedEmployeeName: null, selectedEmployeeNameId:"", selectedProfileId:''})
            return
        }
         this.state.selectedEmployeeName = e[0].employeeName
        this.state.selectedEmployeeNameId = e[0].id
        this.state.selectedEmployeeId =e[0].employeeId

        // this.GetSubmittedEmployeeProfiles();
        this.setState({isloading:false,})
    }

    handleCoverChangeSortBy = (e) => {
        if (e.length > 0) {
            this.state.selectedSortBy = e[0].name
            this.state.selectedSortById = e[0].id

        } else {
            this.state.selectedSortBy = ""
            this.state.selectedSortById = ""
        }
        
        console.log(this.state.selectedSortBy);
        
    } 

    handleCoverChangeBranch= (e) => {
        console.log(e)
        if(e.length == 0) {
            this.state.selectedLocation    =   ""
            this.state.selectedLocationId     =   ""
            return
        }
        this.state.selectedLocation     =   e[0].locationName
        this.state.selectedLocationId     =   e[0].id

    }

    getStatus(){
        const statusParams = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.userinfo.clientId,
            "UserId":this.state.userinfo.userId,
            "Code":"0021"
        };
        

        axios
            .post(AppConfiguration.Setting().noserapiendpoint + "Maintenance/GetDataReferences", statusParams)
            .then(res => {
                const data = res.data;
                this.setState({
                    statusList : res.data.dataReferences})
                    
            })
                
    }

    GetSubmitted() {
        this.setState({
            employeeRecordList:[]
            
        })
        const submittedParams = {
            "IpAddress":"0.0.0.0",
            "UserId":this.state.userinfo.userId,
            "ClientId":this.state.userinfo.clientId,
            "RoleId":this.state.userinfo.roleId,
            "EmployeeId":"",
            "StatusId":""
        };
        console.log("Test app");
        console.log(submittedParams)
        axios
            .post(AppConfiguration.Setting().noserapiendpoint + "Administrator/GetSubmittedEmployeeRecords",  submittedParams)
        .then(res => {
            const data = res.data;
            console.log("Test app123");
            console.log(data);
            this.setState({ employeeRecordList: data.employeeRecords});
           
        })

    }    

    handleSearchClick = event => {
        this.setState({employeeRecordListGrid:[], isloading:true})

        const searchParams = {
            "IpAddress":"0.0.0.0",
            "UserId":this.state.userinfo.userId,
            "ClientId":this.state.userinfo.clientId,
            "RoleId":this.state.userinfo.roleId,
            "EmployeeId":this.state.selectedEmployeeId,
            "StatusId":this.state.selectedSortById
        };
        console.log(searchParams)
        axios
            .post(AppConfiguration.Setting().noserapiendpoint + "Administrator/GetSubmittedEmployeeRecords",  searchParams)
            .then(res => {
            const data = res.data;
            console.log(res.data)
            this.setState({employeeRecordListGrid: data.employeeRecords});
            // this.processGrid();
            if(data.status=="1"){
                this.setState({
                    isloading       :   false,
                    alerttype       :   "Success!",
                    isshow          :   true,
                    color           :   "success",
                    message         :   data.message,
                    fade            :   true
                });
            }
            else{
                this.setState({
                    isloading       :   false,
                    alerttype       :   "Error!",
                    isshow          :   true,
                    color           :   "danger",
                    message         :   data.message,
                    fade            :   true
                })
            }
            })
            .catch(error=>{
            this.setState(  {
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade            :   true
            })
        })

    }


    render() {
        const columns1 = [
            {
                dataField: 'employeeName',
                text: 'EMPLOYEE'
            },
            {
                dataField: 'clientName',
                text: 'CLIENT'
            },
            {
                dataField: 'locationName',
                text: 'BRANCH',
            },
            {
                dataField: 'createdDate',
                text: 'SUBMITTED DATE',
            },
            {
                dataField: 'status',
                text: 'APPROVAL STATUS',
            
            }] 
       
       
        const selectRow = {
            mode: 'checkbox',
            //clickToSelect: true,
            clickToSelectAndEditCell: true
        };
        const rowEvents = {
            onClick: (e, row, rowIndex) => {
              //alert(e.row);
              
            }
        };

        return(
            <div>
                <Banner />
                    <Container className="mt-5">
                        <Card>
                            <Card.Header>Records</Card.Header>
                            <Card.Body>
                                <Form>
                                    <Form.Row>
                                        <Col sm={6} className="mt-3">
                                           <Typeahead 
                                               labelKey='employeeName'
                                               id="basic-example"
                                               onChange={this.handleChangeEmployee}
                                               options={this.state.employeeRecordList}
                                               placeholder="EMPLOYEE"
                                            /> 
                                        </Col>
                                        {/* <Col sm={6} className="mt-3">
                                            <Typeahead 
                                                labelKey='name'
                                                id="basic-example"
                                                onChange={this.handleChangeClient}
                                                options={this.state.getClientList}
                                                placeholder="CLIENT"
                                            />
                                        </Col>
                                        <Col sm={6} className="mt-3">
                                            <Typeahead 
                                                labelKey='locationName'
                                                id="basic-example"
                                                onChange={this.handleCoverChangeBranch}
                                                options={this.state.employeeRecordList}
                                                placeholder="BRANCH"
                                                
                                            />
                                        </Col> */}
                                        <Col sm={6} className="mt-3">
                                            <Typeahead
                                                labelKey='name'
                                                id="basic-example"
                                                onChange={this.handleCoverChangeSortBy}
                                                options={this.state.statusList}
                                                placeholder="APPROVAL STATUS"
                                            /> 
                                        </Col>
                                        {/* <Col sm={6} className="mt-3">
                                            <DatePicker
                                                ref='submitedDate'
                                                selected={this.state.submitedDate}
                                                onChange={this.handleChangeDateHired}
                                                minDate={this.minDate}
                                                value={this.props.dateHired}
                                                dateFormat={"MM/dd/yyyy"}
                                                placeholderText="SUBMITED DATE"
                                                className="form-control"
                                                disabled = { this.state.submitedDate}
                                                
                                            />
                                        </Col> */}
                                    </Form.Row>
                                    
                                    <ButtonToolbar sm={12} className="mt-3">
                                        <Button variant="success"  onClick={this.handleSearchClick}>
                                            Search
                                        </Button>&nbsp;&nbsp;
                                        <NavLink to="/EmployeeRecordCreate">
                                            <Button  variant="primary" variant="success">
                                                Create
                                            </Button>
                                        </NavLink>&nbsp;&nbsp;
                                    </ButtonToolbar>
                                    <div className="mt-5">
                                        <BootstrapTable
                                        keyField = "id"
                                        data = { this.state.employeeRecordListGrid }
                                        columns = { columns1 }
                                        //selectRow = { selectRow }
                                        cellEdit = { cellEditFactory({ mode: 'dbclick', blurToSave: true }) }
                                        rowEvents={ rowEvents }
                                        pagination={ paginationFactory({sizePerPageRenderer}) }
                                        /> 
                                    </div>
                                        
                                </Form>
                            </Card.Body>
                        </Card>
                    </Container>
            </div> 
            
        )
    }
}

export default EmployeeRecord;