import {
    React,Component,BootstrapTable,
    paginationFactory,Button, ButtonToolbar, Card, 
    Form, Col, Row, axios, Container, Banner, Typeahead, TimePicker,
    cellEditFactory, Type, moment, NavLink, Alert, AppConfiguration,
    NoserLoading, Noser, sizePerPageRenderer, DatePicker
} 
from '../../noser-hris-component';
import ToolkitProvider, { CSVExport } from 'react-bootstrap-table2-toolkit';
import CreatePayrollPerTrip from './Modal/CreatePayrollPerTrip';

class PayrollPerTrip extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fade            :   true,
            color           :   "",
            isshow          :   false,
            message         :   "",
            userinfo        :   [],
            isloading       :   false,
            alerttype       :   "",

            rateNameDDL     :   [],
            rateNameId      :   "",
            typeDDL         :   [
                {
                    "value" : "1", "label" : "RSK"
                },
                {
                    "value" : "2", "label" : "CAPASCO"
                },
            ],
            categoryDDL : [
                {
                    "value" : "1", "name" : "DRIVER"
                },
                {
                    "value" : "2", "name" : "6 WHEELER DRIVER"
                },
                {
                    "value" : "3", "name" : "10 WHEELER DRIVER"
                },
                {
                    "value" : "4", "name" : "TRAILER DRIVER"
                },
                {
                    "value" : "5", "name" : "HELPER"
                }
            ],
            typeId          :   "",
            categoryId      :   "",
            categoryDGDDL     :   [],
            locationDDL     : [],
            locationId      :   "",
            locationDGDDL : [],
            productDDL     : [],
            productId      :   "",
            productDGDDL : [],
            dataGrid        : [],
            arrList         : [],
            disableSaveBtn  : true,
            ratetypeDDL     :[
                {"value":"0","label":"REGULAR"},
                {"value":"1","label":"HOLIDAY"}
            ]

        }
        this.state.userinfo = JSON.parse(sessionStorage.getItem("userData"))
        this.GetLocation();
        this.GetProduct();
        this.GetCategory();
        this.DataOnLoad();
        this.DataRateName();
    }
    
    componentDidMount(){
    }

    GetCategory = () => {
        for (let i = 0; i < this.state.categoryDDL.length; i++) {
            const obj = {
                value : this.state.categoryDDL[i]["value"],
                label : this.state.categoryDDL[i]["name"],
            };
            this.state.categoryDGDDL.push(obj);
        }
        
    }
    handleModalShow = (e) =>{
        this.setState({openModal:true})
        let obj = {}
        this.child.initialize(obj)
    }
    handleModalClose = (e) =>{
        this.setState({openModal:false})
        this.DataOnLoad();
        this.DataRateName();
        this.GetLocation();
        this.GetProduct();
    }
    DataOnLoad = async() => {
        this.setState({isloading:true})
        const param = {
            "IpAddress"     : "0.0.0.0",
            "UserId"        : this.state.userinfo.userId,
            "ClientId"      : this.state.userinfo.clientId,
            "Id"            : "",
            "TypeId"        : this.state.typeId,
            "CategoryId"    : this.state.categoryId,
            "LocationId"    : this.state.locationId,
            "ProductId"     : this.state.productId,
            "BasicRate"     : "" ,
            "AllowanceRate" : "" 
        };
        await axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Maintenance/GetPayrollPerTrip",  param)
        .then(res => {
            const data = res.data;
            console.log("data list")
            console.log(data)
            this.setState({dataGrid:data.payrollPerTrip,isloading:false});
            if(data.status==="0"){
                this.setState(  {
                    isloading       :   false,
                    alerttype       :   "Error!",
                    isshow          :   true,
                    color           :   "danger",
                    message         :   data.message,
                    fade            :   true
                })
            }
        })
        .catch(error=>{
            this.setState(  {
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade            :   true
            })
        })
    }
    DataRateName = async() => {
        this.setState({isloading:true})
        const param = {
            "IpAddress"     : "0.0.0.0",
            "UserId"        : this.state.userinfo.userId,
            "ClientId"      : this.state.userinfo.clientId,
            "Id"            : "",
            "CategoryId"    : "" ,
            "LocationId"    : "" ,
            "ProductId"     : "" ,
            "BasicRate"     : "" ,
            "AllowanceRate" : "" 
        };
        await axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Maintenance/GetPayrollPerTrip",  param)
        .then(res => {
            const data = res.data;
            //console.log(data)
            this.setState({rateNameDDL:data.payrollPerTrip,isloading:false});
            if(data.status==="0"){
                this.setState(  {
                    isloading       :   false,
                    alerttype       :   "Error!",
                    isshow          :   true,
                    color           :   "danger",
                    message         :   data.message,
                    fade            :   true
                })
            }
        })
        .catch(error=>{
            this.setState(  {
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade            :   true
            })
        })
    }
    handleChangedRateName = (e) => {
        this.setState({isshow:false})
        if(e.length == 0) {
            this.state.rateNameId=""
            return
        } 
        this.state.rateNameId = e[0].id
        
    }
    handleChangedCategory = (e) => {
        this.setState({isshow:false})
        if(e.length == 0) {
            this.state.categoryId=""
            return
        } 
        this.state.categoryId = e[0].value
        
    }
    handleChangedType = (e) => {
        this.setState({isshow:false})
        if(e.length == 0) {
            this.state.typeId=""
            return
        } 
        this.state.typeId = e[0].value
        
    }
    GetLocation = async() => {
        this.setState({isloading:true})
        const param = {
            "IpAddress" :   "0.0.0.0",
            "UserId"    :   this.state.userinfo.userId,
            "ClientId"  :   this.state.userinfo.clientId,
            "Id"        :   ""
        };
        await axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Maintenance/GetPayrollLocation",  param)
        .then(res => {
            const data = res.data.payrollLocation;
            console.log("GetPayrollLocation")
            console.log(data)
            this.setState({locationDDL:data,isloading:false});
            for (let i = 0; i < data.length; i++) {
                const loc = {
                    value : data[i]["id"],
                    label : data[i]["name"],
                };
                this.state.locationDGDDL.push(loc);
            }
            if(data.status==="0"){
                this.setState({
                    isloading       :   false,
                    alerttype       :   "Error!",
                    isshow          :   true,
                    color           :   "danger",
                    message         :   data.message,
                    fade            :   true
                })
            }
        })
        .catch(error=>{
            this.setState(  {
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade            :   true
            })
        })
    }
    handleChangedLocation = (e) => {
        this.setState({isshow:false})
        if(e.length == 0) {
            this.state.locationId=""
            return
        } 
        this.state.locationId = e[0].id
        
    }
    GetProduct = async() => {
        this.setState({isloading:true})
        const param = {
            "IpAddress" :   "0.0.0.0",
            "UserId"    :   this.state.userinfo.userId,
            "ClientId"  :   this.state.userinfo.clientId,
            "Id"        :   ""
        };
        await axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Maintenance/GetPayrollProduct",  param)
        .then(res => {
            const data = res.data.payrollProduct;
            console.log("GetPayrollProduct")
            console.log(data)
            this.setState({productDDL:data,isloading:false});
            for (let i = 0; i < data.length; i++) {
                const prod = {
                    value : data[i]["id"],
                    label : data[i]["name"],
                };
                this.state.productDGDDL.push(prod);
            }
            if(data.status==="0"){
                this.setState(  {
                    isloading       :   false,
                    alerttype       :   "Error!",
                    isshow          :   true,
                    color           :   "danger",
                    message         :   data.message,
                    fade            :   true
                })
            }
        })
        .catch(error=>{
            this.setState(  {
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade            :   true
            })
        })
    }
    handleChangedProduct = (e) => {
        this.setState({isshow:false})
        if(e.length == 0) {
            this.state.productId=""
            return
        } 
        this.state.productId = e[0].id
        
    }
    handleSearchClick = async() =>{
        this.setState({isloading:true})
        const param = {
            "IpAddress"     : "0.0.0.0",
            "UserId"        : this.state.userinfo.userId,
            "ClientId"      : this.state.userinfo.clientId,
            "Id"            : this.state.rateNameId,
            "TypeId"        : this.state.typeId,
            "CategoryId"    : this.state.categoryId,
            "LocationId"    : this.state.locationId,
            "ProductId"     : this.state.productId,
            "BasicRate"     : "" ,
            "AllowanceRate" : "" 
        };
        await axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Maintenance/GetPayrollPerTrip",  param)
        .then(res => {
            const data = res.data;
            //console.log(data)
            this.setState({dataGrid:data.payrollPerTrip,isloading:false});
            if(data.status==="0"){
                this.setState(  {
                    isloading       :   false,
                    alerttype       :   "Error!",
                    isshow          :   true,
                    color           :   "danger",
                    message         :   data.message,
                    fade            :   true
                })
            }
        })
        .catch(error=>{
            this.setState(  {
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade            :   true
            })
        })
    }

    handleSaveClick = event => {
        this.setState({
            arrList     : [],
            isloading   : true,
            disableSaveBtn : true
        })

        // for (let i = 0; i < this.state.dataGrid.length; i++) {
        //     if (this.state.dataGrid[i]["isModified"] == 1) {
        //         const obj = {
        //             "Id"        :   this.state.dataGrid[i]["id"],
        //             "RateName"      :   this.state.dataGrid[i]["rateName"].toUpperCase(),
        //             "CategoryId"    :   this.state.dataGrid[i]["categoryId"],
        //             "Category"      :   this.state.dataGrid[i]["category"],
        //             "LocationId"    :   this.state.dataGrid[i]["locationId"],
        //             "ProductId"     :   this.state.dataGrid[i]["productId"],
        //             "BasicRate"     :   this.state.dataGrid[i]["basicRate"],
        //             "AllowanceRate" :   this.state.dataGrid[i]["allowanceRate"],
        //             "ModifiedBy":   this.state.userinfo.userId,
        //             "IsDeleted" :   this.state.dataGrid[i]["isDeleted"].toString()
        //         };

        //         this.state.arrList.push(obj);
        //     }
        // }

        const params = {
            "IpAddress"     :   "0.0.0.0",
            "ClientId"      :   this.state.userinfo.clientId,
            "UserId"        :   this.state.userinfo.userId,
            "payrollPerTrip":   this.state.dataGrid.filter(x=>x.isModified==="1")
        };
        console.log("Submit Edit Params")
        console.log(params)
        axios.post(AppConfiguration.Setting().noserapiendpoint + "Maintenance/EditPayrollPerTrip", params)
        .then(res => {
            const data = res.data;
            this.setState({
                isloading   :   false,
                alerttype   :   "Success!",
                isshow      :   true,
                color       :   "success",
                message     :   data.message,
                fade        :   true
            });
            this.DataOnLoad();
            this.DataRateName();
            this.GetLocation();
            this.GetProduct();
            })
            .catch(error=>{
            this.setState(  {
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade        :   true
            })
        })
    }

    GridDataModified(oldValue, newValue, id, column) {
        let disable = true
        this.state.dataGrid.map(function(item,i) {
            if (item.id===id){
                item.isModified = newValue!=oldValue ? "1" : "0"
                disable = newValue!=oldValue ? false : true
            }
        })
        this.setState({disableSaveBtn:disable})
    }
    render(){
        const columns = [
            {
                dataField: 'typeId',
                text: 'TYPE',
                editable    : true,
                headerStyle: (colum, colIndex) => {
                    return { width:"10%"}},
                style:{width:"10%"},
                formatter: (cell, row) => {
                    if(row.typeId!=='' && row.typeId!==null || typeof row.typeId!=='undefined'){
                        if(this.state.typeDDL.filter(x => x.value == cell).length>0)
                            return this.state.typeDDL.find(x => x.value == cell).label;
                    }
                },
                editor: {
                    type: Type.SELECT,
                    options: this.state.categoryDGDDL
                },
                validator: (newValue, row, column) => {
                  if (newValue === "") {
                    return {
                      valid: false,
                      message: 'Category should not empty'
                    };
                  }
                  return true;
                }
            },
            {
                dataField: 'isHolidayRate',
                text: 'RATE TYPE',
                editable    : true,
                headerStyle: (colum, colIndex) => {
                    return { width:"10%"}},
                style:{width:"10%"},
                sort:true,
                formatter: (cell, row) => {
                    if(row.isHolidayRate!=='' && row.isHolidayRate!==null || typeof row.isHolidayRate!=='undefined'){
                        if(this.state.ratetypeDDL.filter(x => x.value == cell).length>0)
                            return this.state.ratetypeDDL.find(x => x.value == cell).label;
                    }
                },
                editor: {
                    type: Type.SELECT,
                    options: this.state.ratetypeDDL
                },
                validator: (newValue, row, column) => {
                  if (newValue === "") {
                    return {
                      valid: false,
                      message: 'Rate Type should not empty'
                    };
                  }
                  return true;
                }
            },
            {
                dataField: 'rateName',
                text: 'Rate Name',
                editable    : true,
                headerStyle: (colum, colIndex) => {
                    return { width:"15%"}},
                style:{width:"15%"},
                sort:true,
                validator: (newValue, row, column) => {
                  if (newValue === "") {
                    return {
                      valid: false,
                      message: 'Rate Name should not empty'
                    };
                  }
                  return true;
                }
            },
            {
                dataField: 'categoryId',
                text: 'Category',
                editable    : true,
                headerStyle: (colum, colIndex) => {
                    return { width:"15%"}},
                style:{width:"15%"},
                formatter: (cell, row) => {
                    if(row.categoryId!=='' && row.categoryId!==null || typeof row.categoryId!=='undefined'){
                        if(this.state.categoryDGDDL.filter(x => x.value == cell).length>0)
                            return this.state.categoryDGDDL.find(x => x.value == cell).label;
                    }
                },
                editor: {
                    type: Type.SELECT,
                    options: this.state.categoryDGDDL
                },
                validator: (newValue, row, column) => {
                  if (newValue === "") {
                    return {
                      valid: false,
                      message: 'Category should not empty'
                    };
                  }
                  return true;
                }
            },
            {
                dataField: 'locationId',
                text: 'Location',
                editable    : true,
                headerStyle: (colum, colIndex) => {
                    return { width:"15%"}},
                style:{width:"15%"},
                formatter: (cell, row) => {
                    /* if(row.locationId!=='' && row.locationId!==null || typeof row.locationId!=='undefined'){
                        if(this.state.locationDGDDL.filter(x => x.value == cell).length>0)
                            return this.state.locationDGDDL.find(x => x.value == cell).label;
                    } */
                    if(row.locationId!='' && row.locationId!=null){
                        if(this.state.locationDGDDL.filter(x => x.value == cell).length==0)
                        return ""
                        else
                            return this.state.locationDGDDL.find(x => x.value == cell).label;
                    }
                },
                editor: {
                    type: Type.SELECT,
                    options: this.state.locationDGDDL.sort((a, b) => (a.label > b.label) ? 1 : -1)
                },
                validator: (newValue, row, column) => {
                  if (newValue === "") {
                    return {
                      valid: false,
                      message: 'Location should not empty'
                    };
                  }
                  return true;
                }
            },
            {
                dataField: 'productId',
                text: 'Product',
                editable    : true,
                headerStyle: (colum, colIndex) => {
                    return { width:"15%"}},
                style:{width:"15%"},
                formatter: (cell, row) => {
                    /* if(row.productId!=='' && row.productId!==null || typeof row.productId!=='undefined'){
                        if(this.state.productDGDDL.filter(x => x.value == cell).length>0)
                            return this.state.productDGDDL.find(x => x.value == cell).label;
                    } */
                    if(row.productId!='' && row.productId!=null){
                        if(this.state.productDGDDL.filter(x => x.value == cell).length==0)
                        return ""
                        else
                            return this.state.productDGDDL.find(x => x.value == cell).label;
                    }
                },
                editor: {
                    type: Type.SELECT,
                    options: this.state.productDGDDL.sort((a, b) => (a.label > b.label) ? 1 : -1)
                },
                validator: (newValue, row, column) => {
                  if (newValue === "") {
                    return {
                      valid: false,
                      message: 'Product should not empty'
                    };
                  }
                  return true;
                }
            },
            {
                dataField: 'basicRate',
                text: 'Basic Rate',
                editable    : true,
                headerStyle: (colum, colIndex) => {
                    return {textAlign:'center', width:"10%"}},
                style:{textAlign:'center', width:"10%"},
                validator: (newValue, row, column) => {
                    if (isNaN(newValue)) {
                        return {
                          valid: false,
                          message: 'Basic Rate should be numeric'
                        };
                    }
                    if (newValue === "") {
                        return {
                        valid: false,
                        message: 'Basic Rate should not empty'
                        };
                    }
                    return true;
                },
                align: (cell, row, rowIndex, colIndex) => {
                  return 'right';
                }
            },
            {
                dataField: 'allowanceRate',
                text: 'Allowance Rate',
                editable    : true,
                headerStyle: (colum, colIndex) => {
                    return {textAlign:'center', width:"10%"}},
                style:{textAlign:'center', width:"10%"},
                validator: (newValue, row, column) => {
                    if (isNaN(newValue)) {
                        return {
                          valid: false,
                          message: 'Allowance Rate should be numeric'
                        };
                    }
                    if (newValue === "") {
                        return {
                        valid: false,
                        message: 'Allowance Rate should not empty'
                        };
                    }
                    return true;
                },
                align: (cell, row, rowIndex, colIndex) => {
                  return 'right';
                }
            },
        ]
        const selectRow = {
            mode: 'checkbox',
            clickToSelectAndEditCell: true,
            onSelect: (row, isSelect, rowIndex, e) => {
                let isDisable = true
                this.state.dataGrid.map(function(item,i){
                    if(item.id===row.id)
                    {
                        item.isDeleted = isSelect ? "1" : "0"
                        item.isModified = isSelect ? "1" : "0"
                    }
                    if(item.isDeleted=="1")
                        isDisable=false
                })
                this.setState({disableSaveBtn:isDisable})
            }
        };

        const rowEvents = {
            onClick: (e, row, rowIndex) => {
            }
        };
        return(
            <div>
                <Banner />
                <Container className="mt-5" fluid>
                    <Card>
                        <Card.Header>MAINTENANCE >> PAYROLL >> TRIP RATE</Card.Header>
                        <Card.Body>
                            <Form>
                                <Alert isOpen={this.state.isshow} color={this.state.color} fade={this.state.fade} className={this.state.isshow ? 'display-block' : 'display-none'}>
                                    <div className={this.state.color}></div> 
                                    {this.state.message}
                                </Alert>
                                <Form.Group as={Row} controlId="formPlaintextEmail">
                                    <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                        TYPE
                                    </Form.Label>
                                    <Col>
                                        <Typeahead
                                            labelKey='label'
                                            id="basic-example"
                                            onChange={this.handleChangedType}
                                            options={this.state.typeDDL}
                                        />
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} controlId="formPlaintextEmail">
                                    <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                        RATE NAME
                                    </Form.Label>
                                    <Col>
                                        <Typeahead
                                            labelKey='rateName'
                                            id="basic-example"
                                            onChange={this.handleChangedRateName}
                                            options={this.state.rateNameDDL}
                                        />
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} controlId="formPlaintextEmail">
                                    <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                        CATTEGORY
                                    </Form.Label>
                                    <Col>
                                        <Typeahead
                                            labelKey='name'
                                            id="basic-example"
                                            onChange={this.handleChangedCategory}
                                            options={this.state.categoryDDL}
                                        />
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} controlId="formPlaintextEmail">
                                    <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                        Location
                                    </Form.Label>
                                    <Col>
                                        <Typeahead
                                            labelKey='name'
                                            id="basic-example"
                                            onChange={this.handleChangedLocation}
                                            options={this.state.locationDDL}
                                        />
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} controlId="formPlaintextEmail">
                                    <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                        PRODUCT
                                    </Form.Label>
                                    <Col>
                                        <Typeahead
                                            labelKey='name'
                                            id="basic-example"
                                            onChange={this.handleChangedProduct}
                                            options={this.state.productDDL}
                                        />
                                    </Col>
                                </Form.Group>
                                <ButtonToolbar sm={12}>
                                    <Button variant="success" className="ml-auto noser-button" onClick = { this.handleSearchClick }>
                                        SEARCH
                                    </Button>
                                </ButtonToolbar>
                                <div className="mt-3">
                                    <Card.Header>TRIP RATE LISTS</Card.Header>
                                    <BootstrapTable
                                        keyField = "id"
                                        data = { this.state.dataGrid }
                                        columns = { columns }
                                        pagination={ paginationFactory({sizePerPageRenderer}) }
                                        noDataIndication={ () => <div>No record found.</div> }
                                        rowClasses="noser-table-row-class"
                                        striped
                                        hover
                                        condensed
                                        cellEdit = { cellEditFactory({
                                            mode: 'dbclick',
                                            blurToSave: true,
                                            afterSaveCell: (oldValue, newValue, row, column) => {
                                                this.GridDataModified(oldValue, newValue, row.id, column.dataField)
                                                }
                                            })
                                        }
                                        rowEvents={ rowEvents }
                                        selectRow = { selectRow }
                                    />
                                </div>
                                <div className="mt-1">
                                    <ButtonToolbar>
                                        <Button className="ml-auto noser-button-mr1" variant="success" onClick={this.handleSaveClick} disabled={this.state.disableSaveBtn}>Save</Button>
                                        <Button className="noser-button" variant="primary" onClick={this.handleModalShow}>Create</Button>
                                    </ButtonToolbar>
                                </div>
                            </Form>
                        </Card.Body>
                    </Card>
                </Container>  
                <NoserLoading show={this.state.isloading} />
                <CreatePayrollPerTrip 
                    show={this.state.openModal}
                    onHide={this.handleModalClose}
                    onRefModal={ref => (this.child = ref)}
                />
            </div>
        )
    }

}

export  default PayrollPerTrip