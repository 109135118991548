import {
    React,Component,
    paginationFactory,Button, ButtonToolbar, Card, BootstrapTable,
    Form, Col, Row, axios, Container, Banner, Typeahead, TimePicker,
    cellEditFactory, Type, moment, NavLink, Alert, AppConfiguration,
    NoserLoading, Noser, sizePerPageRenderer, useState
} 
from '../../noser-hris-component';

import StandardBillingModalCreate  from './BillingModal/StandardBillingModalCreate';
import BillingRateOneCreate  from './BillingModal/BillingRateOneCreate';
import CustomRate from './BillingModal/CustomRate';
class BillingConfiguration extends Component {
    constructor(props) {
        super(props)
        this.state = {
            userinfo        :   [],
            isloading       :   false,
            isshow          :   false,
            alerttype       :   "",
            message         :   "",
            color           :   "",
            fade            :   true,
            
            clientDDL       :   [],
            locationDDL     :   [],
            templateDDL     :   [
                { "id" : "1","name" : "STANDARD" },
                { "id" : "2","name" : "RATE 1" },
                { "id" : "3","name" : "RATE 2" },
                { "id" : "4","name" : "CLIENT RATE" },
                { "id" : "5","name" : "CUSTOM RATE" }
            ],
            adminfeeTypeDDL :   [
                { "id":"1","name":"BASIC" },
                {"id":"2","name":"GROSS" },
                {"id":"3","name":"ACROSS" },
            ],
            billingrateGRID :   [],

            clientId        :   "",
            clientName      :   "",
            locationId      :   "",
            locationName    :   "",
            templateId      :   "",
            templateName    :   "",
            adminfeetypeId  :   "",
            adminfeetypeName:   "",

            disableBranch   :   false,
            disableBilling  :   true,
            disableAdminFeeType: false,

            stdModal        :   false,
            rateOneModal    :   false,
            rateTwoModal    :   false,
            clientRate      :   false,
            customRate      :   false,

            yesNoDDL        :   [
                {value:"1", label:"YES"},
                {value:"0", label:"NO"}
            ]
        }
    }

    componentDidMount() {
        this.state.userinfo = JSON.parse(sessionStorage.getItem("userData"))
        this.GetClients();        
    }
    GetClients = async() =>{
        this.setState({isloading:true})
        const params = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.userinfo.clientId,
            "UserId":this.state.userinfo.userId,
        }
        await axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Client/GetClientList", params)
        .then(res => {
            this.setState({clientDDL:res.data.clients ? res.data.clients : [],isloading:false})
        })
        .catch(error=>{
           this.setState(  {
               isloading       :   false,
               alerttype       :   "Error!",
               isshow          :   true,
               color           :   "danger",
               message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
               fade            :   true
           })
       })
    }
    GetLocations = async() =>{
        this.setState({isloading:true})
        const params = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.clientId,
            "UserId":this.state.userinfo.userId,
            "ClientName": this.state.clientName,
            "City": "",
            "Province": "",
            "Region": ""
    
        }
        await axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Client/GetClientLocations", params)
        .then(res => {
            const data = res.data;
            this.setState({locationDDL : res.data.locations ? res.data.locations : [], isloading:false})
            if(data.locations.length=="0"){
                this.setState({
                    isloading   :   false,
                    alerttype   :   "Error!",
                    isshow      :   true,
                    color       :   "danger",
                    message     :   data.message,
                    fade        :   true
                });
            }
        })
        .catch(error=>{
           this.setState(  {
               isloading       :   false,
               alerttype       :   "Error!",
               isshow          :   true,
               color           :   "danger",
               message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
               fade            :   true
           })
       })
    }
    handleChangedClient = (e) => {
        if(e.length == 0) {
            this.state.clientId=""
            this.state.clientName=""
            return
        } 
        this.state.clientId = e[0].id
        this.state.clientName = e[0].name
        this.GetLocations();
        this.setState({isshow:false,disableBilling:false})
    }
    handleChangedLocation = (e) => {
        if(e.length==0)
        {
            this.state.locationId=""
            this.state.locationName=""
            return
        }
        this.state.locationId= e[0].id
        this.state.locationName = e[0].name
        this.setState({isshow:false})
    }
    handleChangedTemplate = (e) => {
        if(e.length==0)
        {
            this.state.templateId=""
            this.state.templateName=""
            return
        }
        this.state.templateId = e[0].id
        this.state.templateName = e[0].name

        if(this.state.templateId == 1){
            this.setState({
                disableAdminFeeType:false
            })
        }
        if(this.state.templateId == 4){
            this.setState({
                disableAdminFeeType:false,
            })
        }
        if(this.state.templateId == 2){
            this.setState({
                adminfeetypeId:'',
                adminfeetypeName:'',
                disableAdminFeeType:true,
            })
            this.defTemplate.getInstance().clear()
        }
        if(this.state.templateId == 3){
            this.setState({
                adminfeetypeId:'',
                adminfeetypeName:'',
                disableAdminFeeType:true
            })
            this.defTemplate.getInstance().clear()
        }
        if(this.state.templateId == 5){
            this.setState({
                adminfeetypeId:'',
                adminfeetypeName:'',
                disableAdminFeeType:true
            })
            this.defTemplate.getInstance().clear()
        }
    }
    handleChangedAdminFeeType = (e) => {
        if(e.length==0)
        {
            this.state.adminfeetypeId=""
            this.state.adminfeetypeName=""
            return
        }
        this.state.adminfeetypeId = e[0].id
        this.state.adminfeetypeName = e[0].name
    }
    handleEditRate = (e) =>{
        console.log(e)
        
        let obj = {
            rateId          :   e.id,
            clientName      :   e.client,
            branchName      :   e.location,
            rateType        :   e.rateType,
            adminfeeType    :   e.adminFeeType,

            clientId        :   e.clientId,
            locationId      :   e.locationId,
            rateTypeId      :   e.rateTypeId,
            adminFeeTypeId  :   e.adminFeeTypeId,
            isPercentage    :   e.isPercentage==="1" ? true : false,
            isConvertHrs    :   e.isConvertHrsToDays==="1" ? true : false,            
            feeRate         :   e.feeRate,
            additionalFieldsGRID:e.additionalFields,
            otherFieldsGRID     :e.otherFields,
            exclusionFieldsGRID :e.excludedFields,
            
            otherFeeRate    :   e.otherFeeRate,
            isBasicBillingRate:e.isBasicBillingRate ==="1" ? true : false,
            isPremiumBillingRate:e.isPremiumBillingRate ==="1" ? true : false,
            allowance:e.allowance,
            cola:e.cola,
            otHrs:e.otHrs,
            ndHrs:e.ndHrs,
            otherAmount:e.otherAmount
        }
        if(e.rateTypeId === "1"){
            this.setState({stdModal:true})
            this.childStandardCreate.initialize(obj)
            
        }

        if(e.rateTypeId === "2") {
            this.setState({rateOneModal:true})
            this.childBillingRateOneCreate.initialize(obj)
            
        }

        if(e.rateTypeId === "3") {
            this.setState({rateOneModal:true})
            this.childBillingRateOneCreate.initialize(obj)
            
        }

        if(e.rateTypeId == "4") {
            this.setState({stdModal:true})
            this.childStandardCreate.initialize(obj)   
        }
        if(e.rateTypeId == "5") {
            this.setState({customRateModal:true})
            this.childCustomRateCreate.initialize(obj)
        }
    }
    handleModalOpen = (e) => {

        if(!this.state.clientName) {
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "Please select client",
                fade        :   true
            });
            return
        }
        
        if(!this.state.templateName) {
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "Please select billing template",
                fade        :   true
            });
            return
        }

        if(this.state.templateId === "1" && !this.state.adminfeetypeName){
                this.setState({
                    isloading   :   false,
                    alerttype   :   "Error!",
                    isshow      :   true,
                    color       :   "danger",
                    message     :   "Please select default template",
                    fade        :   true
                });
                return
        }

        if(this.state.templateId === "4" && !this.state.adminfeetypeName) {
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "Please select default template",
                fade        :   true
            });
            return    
        }

        let obj = {
            rateId          :   "0",
            clientName      :   this.state.clientName,
            branchName      :   this.state.locationName,
            rateType        :   this.state.templateName,
            adminfeeType    :   this.state.adminfeetypeName,

            clientId        :   this.state.clientId,
            locationId      :   this.state.locationId,
            rateTypeId      :   this.state.templateId,
            adminFeeTypeId  :   this.state.adminfeetypeId,
            isPercentage    :   true,
            isConvertHrs    :   false,            
            feeRate         :   "",
            additionalFieldsGRID:[],
            otherFieldsGRID     :[],
            exclusionFieldsGRID :[],
            
        }

        if(this.state.templateId === "1" && this.state.adminfeetypeName){
            this.setState({stdModal:true})
            this.childStandardCreate.initialize(obj)
        }

        if(this.state.templateId === "2") {
            this.setState({rateOneModal:true})
            this.childBillingRateOneCreate.initialize(obj)
        }

        if(this.state.templateId === "3") {
            this.setState({rateOneModal:true})
            this.childBillingRateOneCreate.initialize(obj)
        }

        if(this.state.templateId == "4" && this.state.adminfeetypeName) {
            this.setState({stdModal:true})
            this.childStandardCreate.initialize(obj)
        }
        
        if(this.state.templateId == "5") {
            this.setState({customRateModal:true})
            this.childCustomRateCreate.initialize(obj)
        }
    }
    handleModalClose = (e) =>{
        this.setState({
            stdModal  :   false,
            rateOneModal : false,
            rateTwoModal : false,
            clientRateModal : false,
            customRateModal : false
        })
        this.handleSearchBillingRate(e)
    }
    handleSearchBillingRate = async() => {
        this.setState({isloading:true})
        const params = {
            "IpAddress"         :   "0.0.0.0",
            "ClientId"          :   this.state.clientId,
            "UserId"            :   this.state.userinfo.userId,
            "Id"                :   "",
            "RateTypeId"        :   this.state.templateId,
            "AdminFeeTypeId"    :   this.state.adminfeetypeId,
            "AreaId"            :   "",
            "CostCenterId"      :   "",
            "LocationId"        :   this.state.locationId,
        };

        await axios.post(AppConfiguration.Setting().noserapiendpoint + "BillingConfiguration/GetBillingRates",  params)
        .then(res => {
            const data = res.data;
            this.setState({billingrateGRID:data.clientBillingRates,isloading:false});
            console.log(data)
            if(data.status==="0"){
                this.setState({
                    isloading   :   false,
                    alerttype   :   "Error!",
                    isshow      :   true,
                    color       :   "danger",
                    message     :   data.message,
                    fade        :   true
                });
            }
        })
        .catch(error=>{
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade        :   true
            })
        })
    }
    
    GridDataModified(oldValue, newValue, id, column) {
        console.log(id)
        this.state.billingrateGRID.map(function(item,i) {
            if (item.id === id)
                item.isModified = newValue!=oldValue ? "1" : "0"
        })
    }
    handleDeleteClick = async() => {
        this.setState({isloading:true})
        const params = {
            "IpAddress"         :   "0.0.0.0",
            "ClientId"          :   this.state.userinfo.clientId,
            "UserId"            :   this.state.userinfo.userId,
            "billingRates"      :   this.state.billingrateGRID.filter(x=>x.isModified==="1")
        };

        await axios
            .post(AppConfiguration.Setting().noserapiendpoint + "BillingConfiguration/EditBillingRate", params)
            .then(res => {
                const data = res.data;
                this.handleSearchBillingRate()
                if(data.status==="1"){
                    this.setState({
                        isloading       :   false,
                        alerttype       :   "Success!",
                        isshow          :   true,
                        color           :   "success",
                        message         :   data.message,
                        fade            :   true
                    });
                    
                }
                else{
                    this.setState({
                        isloading       :   false,
                        alerttype       :   "Error!",
                        isshow          :   true,
                        color           :   "danger",
                        message         :   data.message,
                        fade            :   true
                    })
                }
             })
             .catch(error=>{
                 this.setState({
                     isloading       :   false,
                     alerttype       :   "Error!",
                     isshow          :   true,
                     color           :   "danger",
                     message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                     fade            :   true
                 })
             })
    }
    // handleSaveBilling = async() =>{
    //     this.setState({isloading:true})
    //     let params = {
    //         "IpAddress"             :   "0.0.0.0",
    //         "ClientId"              :   this.state.clientId,
    //         "UserId"                :   this.state.userinfo.userId,
    //         "billingRates"          :   this.state.billingrateGRID.isModified==="1"
    //     };
    //     await axios.post(AppConfiguration.Setting().noserapiendpoint + "BillingConfiguration/EditBillingRate",  params)
    //     .then(res => {
    //         const data = res.data;
    //         this.setState({isloading:false});
    //         if(data.status==="0"){
    //             this.setState({
    //                 isloading   :   false,
    //                 alerttype   :   "Error!",
    //                 isshow      :   true,
    //                 color       :   "danger",
    //                 message     :   data.message,
    //                 fade        :   true
    //             });
    //         }
    //     })
    //     .catch(error=>{
    //         this.setState({
    //             isloading   :   false,
    //             alerttype   :   "Error!",
    //             isshow      :   true,
    //             color       :   "danger",
    //             message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
    //             fade        :   true
    //         })
    //     })
    // }
    render() {
        const col = [
            {
                dataField   : 'client',
                text        : 'CLIENT NAME',
                headerStyle : () => {
                    return { width  : "20%" };
                },
            },
            {
                dataField   : 'location',
                text        : 'BRANCH',
                headerStyle : () => {
                    return { width  : "15%" };
                },
            },
            {
                dataField   : 'rateType',
                text        : 'TEMPLATE',
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'10%' }},
                style:{textAlign:'center'},
            },
            {
                dataField   : 'adminFeeType',
                text        : 'FEE TYPE',
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'10%' }},
                style:{textAlign:'center'},
            },
            {
                dataField   : 'isPercentage',
                text        : 'PERCENTAGE',
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'10%' }},
                style:{textAlign:'center'},
                formatter: (cell, row) => {
                    if(row.isPercentage!='' && row.isPercentage!=null){
                        if(this.state.yesNoDDL.filter(x => x.value == cell).length==0)
                        return ""
                        else
                            return this.state.yesNoDDL.find(x => x.value == cell).label;
                    }
                },
                editor: {
                    type: Type.SELECT,
                    options: this.state.yesNoDDL
                }
            },
            {
                dataField   : 'isConvertHrsToDays',
                text        : 'ACTUAL HRS CONVERT TO DAYS ',
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'10%' }},
                style:{textAlign:'center'},
                formatter: (cell, row) => {
                    if(row.isConvertHrsToDays!='' && row.isConvertHrsToDays!=null){
                        if(this.state.yesNoDDL.filter(x => x.value == cell).length==0)
                        return ""
                        else
                            return this.state.yesNoDDL.find(x => x.value == cell).label;
                    }
                },
                editor: {
                    type: Type.SELECT,
                    options: this.state.yesNoDDL
                }
            },
            {
                dataField   : 'is13thMonthRateAllowance',
                text        : '13TH MONTH WITH RATE ALLOWANCE',
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'10%' }},
                style:{textAlign:'center'},
                formatter: (cell, row) => {
                    if(row.is13thMonthRateAllowance!='' && row.is13thMonthRateAllowance!=null){
                        if(this.state.yesNoDDL.filter(x => x.value == cell).length==0)
                        return ""
                        else
                            return this.state.yesNoDDL.find(x => x.value == cell).label;
                    }
                },
                editor: {
                    type: Type.SELECT,
                    options: this.state.yesNoDDL
                }
            },
            {
                dataField   : 'feeRate',
                text        : 'FEE RATE',
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'10%' }},
                style:{textAlign:'center'},
            },
            {
                dataField   : 'otherFeeRate',
                text        : 'OTHER FEE RATE',
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'10%' }},
                style:{textAlign:'center'},
            },
            {
                dataField   : '',
                text        : 'ACTION',
                headerStyle : () => {
                    return { width  : "10%" };
                },
                formatter   :   (cell, row, isSelect) => {
                    return (
                        <Button style={{paddingTop:'0',paddingBottom:'0',marginLeft:'-10px',fontSize:'10px'}}
                            variant="link" onClick={e => this.handleEditRate(row)}
                    >EDIT</Button>
                    );
                } 
            },

        ]
        const additionalFldsCol =[
            {
                dataField   : 'payrollField',
                text        : 'ADDITIONAL FIELDS',
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'left',width:'100%' }},
                style:{textAlign:'left'}
            },
        ]

        const otherFldsCol =[
            {
                dataField   : 'payrollField',
                text        : 'OTHER FIELDS',
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'left',width:'100%' }},
                style:{textAlign:'left'}
            },
        ]

        const excludedFldsCol =[
            {
                dataField   : 'payrollField',
                text        : 'EXCLUDED FIELDS',
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'left',width:'100%' }},
                style:{textAlign:'left'}
            },
        ]

        const selectRow = {
            mode: 'checkbox',
            clickToSelectAndEditCell: true,
            onSelect: (row, isSelect, rowIndex, e) => {
                let isDisable=true
                this.state.billingrateGRID.map(function(item,i){
                    if(item.id===row.id)
                    {
                        item.isDeleted = isSelect ? "1" : "0"
                        item.isModified = isSelect ? "1" : "0"
                    }
                    if (item.isDeleted=="1")
                    isDisable =  false
                })
                this.setState({isDisable:isDisable})
            }
        }
        const selectDetRow = {
            mode: 'checkbox',
            hideSelectAll: true,
            clickToSelectAndEditCell: true,
            onSelect: (row, isSelect, rowIndex, e) => {
                
            }
        }
        const expandRow = {
            onlyOneExpanding: true,
            renderer: row => 
            (
                <div>
                <Form.Group as={Row} sm={12} controlId="formHorizontalEmail">
                    <Col sm={12}>
                    <BootstrapTable
                            keyField = "id"
                            data = { row.additionalFields }
                            columns = { additionalFldsCol }
                            rowClasses="noser-table-row-class no-checkbox"
                            striped
                            hover
                            condensed
                            expandRow
                            wrapperClasses="table-responsive"
                            noDataIndication={ () => <div>No additional field included.</div> }
                            selectRow = { selectDetRow }
                        />
                        <BootstrapTable
                            keyField = "id"
                            data = { row.otherFields }
                            columns = { otherFldsCol }
                            rowClasses="noser-table-row-class no-checkbox"
                            striped
                            hover
                            condensed
                            expandRow
                            wrapperClasses="table-responsive"
                            noDataIndication={ () => <div>No other field included.</div> }
                            selectRow = { selectDetRow }
                        /> 

                    <BootstrapTable
                            keyField = "id"
                            data = { row.excludedFields }
                            columns = { excludedFldsCol }
                            rowClasses="noser-table-row-class no-checkbox"
                            striped
                            hover
                            condensed
                            expandRow
                            wrapperClasses="table-responsive"
                            noDataIndication={ () => <div>No excluded field.</div> }
                            selectRow = { selectDetRow }
                        /> 
                    </Col> 
                    <Col sm={6}>
                    
                    </Col>
                </Form.Group>
                </div>
            ),
            showExpandColumn: true,
            
          };
        const rowEvents = {
            onClick: (e, row, rowIndex) => {
            }
        };
        return(
            <div>
                <Banner />
                <Container className="mt-5" fluid>
                    <Card>
                        <Card.Header>Billing Configuration</Card.Header>
                        <Card.Body>
                            <Alert isOpen={this.state.isshow} color={this.state.color} fade={this.state.fade} className={this.state.isshow ? 'display-block' : 'display-none'}>
                                <div className={this.state.color}></div> 
                                {this.state.message}
                            </Alert>
                            <Form.Group as={Row} controlId="formHorizontalEmail">
                                <Col sm={12}>
                                    <Typeahead
                                        labelKey='name'
                                        id="basic-example"
                                        onChange={this.handleChangedClient}
                                        options={this.state.clientDDL}
                                        placeholder="Select Client"
                                    />
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} controlId="formHorizontalEmail">
                                <Col sm={12}>
                                    <Typeahead
                                        labelKey='name'
                                        id="basic-example"
                                        onChange={this.handleChangedLocation}
                                        options={this.state.locationDDL}
                                        placeholder="Select Branch"
                                        disabled={this.state.disableBranch}
                                    />
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} controlId="formHorizontalEmail">
                                <Col sm={12}>
                                    <Typeahead
                                        labelKey='name'
                                        id="basic-example"
                                        onChange={this.handleChangedTemplate}
                                        options={this.state.templateDDL}
                                        placeholder="Select Billing Template"
                                    />
                                </Col>
                            </Form.Group>

                            <Form.Group as={Row} controlId="formHorizontalEmail">
                                <Col sm={12}>
                                    <Typeahead
                                        labelKey='name'
                                        id="basic-example"
                                        onChange={this.handleChangedAdminFeeType}
                                        options={this.state.adminfeeTypeDDL}
                                        placeholder="Select Default Template"
                                        disabled={this.state.disableAdminFeeType}
                                        ref={ref => this.defTemplate = ref}
                                    />
                                </Col>
                            </Form.Group>

                            <ButtonToolbar>
                                <Button variant="success" className="ml-auto" onClick={this.handleSearchBillingRate}>
                                    Search
                                </Button>
                            </ButtonToolbar>
                            <Card.Header className="mt-3">Record</Card.Header>
                            <div className="mt-1">
                                <BootstrapTable
                                    keyField = "id"
                                    data = { this.state.billingrateGRID }
                                    columns = { col }
                                    pagination={ paginationFactory({sizePerPageRenderer}) }
                                    rowClasses="noser-table-row-class"
                                    striped
                                    hover
                                    condensed
                                    expandRow={ expandRow }
                                    cellEdit = { cellEditFactory({
                                        mode: 'dbclick',
                                        blurToSave: true,
                                        afterSaveCell: (oldValue, newValue, row, column) => {
                                            this.GridDataModified(oldValue, newValue, row.id, column.dataField)
                                            }
                                        })
                                    }
                                    rowEvents={ rowEvents }
                                    selectRow = { selectRow }
                                    noDataIndication={ () => <div>No record found.</div> }
                                />
                            </div>

                            <ButtonToolbar className="mt-5">
                                <Button variant="success" className="ml-auto noser-button-mr1" onClick={e => this.handleModalOpen()}>Create</Button>
                                {/* <Button variant="success" className="noser-button-mr1" onClick={e => this.handleSaveBilling()}>Save</Button> */}
                                <Button variant="success" className="noser-button" onClick={e => this.handleDeleteClick()} disabled={this.state.isDisable}>SAVE</Button>
                            </ButtonToolbar>
                        </Card.Body>
                    </Card>
                </Container>
                <NoserLoading show={this.state.isloading} />

                <StandardBillingModalCreate 
                    show={this.state.stdModal}
                    onHide={this.handleModalClose}
                    onStandardCreateRef={ref => (this.childStandardCreate = ref)}
                />
                <BillingRateOneCreate 
                    show={this.state.rateOneModal}
                    onHide={this.handleModalClose}
                    onBillingRateOneCreateRef={ref => (this.childBillingRateOneCreate = ref)}
                />
                <CustomRate 
                    show={this.state.customRateModal}
                    onHide={this.handleModalClose}
                    onCustomRateCreateRef={ref => (this.childCustomRateCreate = ref)}
                />
            </div>
        )

    }

}

export  default BillingConfiguration