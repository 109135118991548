import {
    React,Component,BootstrapTable, Type,
    paginationFactory,Button, ButtonToolbar, Card,
    Form, Col, Row, Container, axios, Typeahead, Banner, TimePicker,
    cellEditFactory ,moment, NavLink, Alert, AppConfiguration,
    NoserLoading, Noser, sizePerPageRenderer, DatePicker
} 
from '../../noser-hris-component';
import type from 'react-bootstrap-table2-editor';

class PaySlip extends Component {
    constructor(props) {
        super(props);
        this.ref = React.createRef();
        this.state = {
            userinfo        :   [],
            isloading       :   false,
            isshow          :   false,
            alerttype       :   "",
            message         :   "",
            color           :   "",
            fade            :   true,   
            
            clientDDL   : [],
            clientId    : "",
            client      : "",
            employeeDDL : [],
            employeeId  :   "",
            employeeName:   "",
            profileId   :   "",
            employeeNo  :   "",
            position    :   "",
            location    :   "",
            payperiodDDL : [],
            endDate : "",
            isProcessed : "",
            payPeriod : "",
            periodId : "",
            startDate : "",
            payrollPeriodsData : [],
            dataGrid : [],
            isSpecial : "",
            modalShow : false,
            employeeList:[],
            payrollTypeId:"0",
            periodLists:[],
            payrollPayslip:[],
        }
       

    }

    componentDidMount(){
        const sleep = (milliseconds) => {return new Promise(resolve => setTimeout(resolve, milliseconds))}
        this.state.userinfo = JSON.parse(sessionStorage.getItem("userData"))
        this.GetClient(); 
    }

    GetClient = async() => {
        this.setState({isloading:true})
        const params = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.userinfo.clientId,
            "UserId":this.state.userinfo.userId,
            "EmployeeId":this.state.userinfo.roleId==="1" || 
                         this.state.userinfo.roleId==="8" ? "" : this.state.userinfo.employeeId
        }
        ////////////console.log("params")
        ////////////console.log(params)
        await axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Client/GetClientList", params)
        //.post("http://209.97.173.181:8081/Client/GetClientList",  params)
        .then(res => {
            const data = res.data.clients.filter(q => q.id !== "1467" && q.id !== "1463" && q.id !== "1465" && q.id !== "1190");
            let arr = []
            if(this.state.userinfo.roleId!=="1"){
                let fieldaccess = JSON.parse(sessionStorage.getItem("userControlAccess"))
                if(fieldaccess.filter(x=>x.accessCode==="0001").length>0){
                    let access = fieldaccess.find(x=>x.accessCode==="0001" && x.controlAccessDetails.length>0)
                    ////////////console.log("access")
                    ////////////console.log(access)
                    if(access.controlAccessDetails.filter(x=>x.isClient==="1").length>0){
                        let brr = access.controlAccessDetails.filter(x=>x.isClient==="1")
                        arr = data.filter(f => brr.some(item => item.referenceId === f.id))
                    }
                }
                ////////////console.log("arr")
                ////////////console.log(arr)
                this.setState({clientDDL : arr,isloading:false})
            }
            else{
                this.setState({clientDDL : data,isloading:false})
            }
        })
        .catch(error=>{
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade        :   true
            })
        })
        this.GetEmployees();
   }

   handleChangeClient = (e) => {
        ////console.log(e)
       if(e.length == 0) {
           this.state.clientId  =   ""
           this.state.client    =   ""
           return
       }
       this.state.clientId  =   e[0].id
       this.state.client    =   e[0].name
       this.state.isSpecial = e[0].isSpecial
       this.state.payrollTypeId = e[0].payrollTypeId
       this.GetPayrollPeriods(e[0].isSpecial);
       this.GetEmployees();
       //this.GetPayrollLedgersEmployee();
       this.setState({
           isshow:false,
       })
   }


   GetEmployees = () => {
        this.setState({isloading:true})
        const params = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.clientId,
            "UserId":this.state.userinfo.userId,
            "EmployeeName":"",
            "EmployeeNo":"",
            "ClientName":""
        }
        axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Employee/GetConfigurationEmployees",  params)
        //.post("http://209.97.173.181:8081/Employee/GetConfigurationEmployees",  params)
        .then(res => {
            const data = res.data.employees
            ////console.log("data")
            ////console.log(data)
            /* END LOOP FOR EMPLOYEE */
            let arr = []
            if(this.state.userinfo.roleId!=="1"){
                let fieldaccess = JSON.parse(sessionStorage.getItem("userControlAccess"))
                if(fieldaccess.filter(x=>x.accessCode==="0001").length>0){
                    let access = fieldaccess.find(x=>x.accessCode==="0001" && x.controlAccessDetails.length>0)
                    if(access.controlAccessDetails.filter(x=>x.isClient==="1").length>0){
                        let brr = access.controlAccessDetails.filter(x=>x.isClient==="1")
                        arr = data.filter(f => brr.some(item => item.referenceId === f.clientId))
                    }
                }
                this.setState({employeeDDL : arr,isloading   :   false})
            }
            else{
                this.setState({employeeDDL : data,isloading   :   false})
            }
            
            // this.setState({
            //     employeeDDL :   data,
            //     //employeeList :   data,
            //     isloading   :   false,
            // }) 
            
        })
    }

    handleChangeEmployee = (e) => {
        ////////////console.log("e")
        ////////////console.log(e)
        if(e.length == 0) {
            this.state.employeeId   =   ""
            this.state.employeeName =   ""
            this.state.profileId    =   ""
            this.state.employeeNo   =   ""
            this.state.position     =   ""
            this.state.location     =   ""
            return
        }
        this.state.employeeId   =   e[0].id
        this.state.employeeName =   e[0].employeeName
        this.state.profileId    =   e[0].employeeId
        this.state.employeeNo   =   e[0].employeeNo
        this.state.position     =   e[0].position
        this.state.location     =   e[0].locationName
        //this.GetPayrollLedgers();
        this.setState({
            isshow:false,
        })
    }

    GetPayrollPeriods = (isSpecial) => {
        this.setState({isloading:true})
        const params = {
            "IpAddress"     :"0.0.0.0",
            "ClientId"      :this.state.clientId,
            "UserId"        :this.state.userinfo.userId,
            "EmployeeId"    :this.state.employeeId,
            "IsProcessed"   :"1"
        };
        let api = ""
        if(isSpecial==="0"){
           api = "Timekeeping/GetPayrollPeriods"
        }
        else{
           api = "Timekeeping/GetSpecialPayrollPeriods"
        }
        axios
        .post(AppConfiguration.Setting().noserapiendpoint + api,  params)
        //.post("http://209.97.173.181:8081/Timekeeping/GetPayrollPeriods",  params)
        .then(res => {
            const data = res.data.payrollPeriods;
            //////////console.log("GetPayrollPeriods")
            //////////console.log(data)
            this.setState({
                payperiodDDL : data,
                isloading   :  false,
            })
        })
    }  

    handleChangePayPeriod = (e) => {
        
        let periodList = [];
        for(let i = 0; i < e.length; i++){
            const obj = {
                "PayPeriodId" : e[i]["periodId"]
            }
            periodList.push(obj)
            this.setState({
                periodLists : periodList,
                isshow      :   false
            })
        }
        if(e.length == 0) {
            this.state.endDate   =   ""
            this.state.isProcessed   =   ""
            this.state.payPeriod   =   ""
            this.state.periodId   =   ""
            this.state.startDate   =   ""
            return
        }
        this.state.endDate   =   e[0].endDate
        this.state.isProcessed   =   e[0].isProcessed
        this.state.payPeriod   =   e[0].payPeriod
        this.state.periodId   =   e[0].periodId
        this.state.startDate   =   e[0].startDate
        //this.GetPayrolls();
        //this.GetPayrollLedgers();
       //this.GetPayrollLedgersEmployee(e[0].periodId);
        this.setState({
            isshow:false,
        })
    }
    
    GetPayrollLedgersEmployee = async(data) => {
        this.setState({isloading:true})
        //for(let x = 0; x < data.length; x++){
            const params = {
                "IpAddress"     : "0.0.0.0",
                "ClientId"      : this.state.clientId,
                "UserId"        : this.state.userinfo.userId,
                "Periods"   : data,
                "EmployeeId"    : "",
            };
            ////console.log("params")
            ////console.log(params)
            await axios
            .post(AppConfiguration.Setting().noserapiendpoint + "Payroll/GetPayrollPayslips",  params)
            //.post("http://209.97.173.181:8081/Payroll/GetPayrollLedgers",  params)
            .then(res => {
                const data = res.data.payrolls;
                ////console.log("GetPayrollLedgers")
                ////console.log(data)
                this.setState({
                    payrollPayslip : data,
                    isloading   :  false,
                })
            })
        //}
    }

    handleSearchClick = async() => {
        this.setState({isloading:true})
        // if(!this.state.clientId){
        //     this.setState({
        //         isloading   :   false,
        //         alerttype   :   "Error!",
        //         isshow      :   true,
        //         color       :   "danger",
        //         message     :   "Please select client!",
        //         fade        :   true
        //     });
        //     return
        // }
        // if(this.state.periodId === ""){
        //     this.setState({
        //         isloading   :   false,
        //         alerttype   :   "Error!",
        //         isshow      :   true,
        //         color       :   "danger",
        //         message     :   "Please select cutoff!",
        //         fade        :   true
        //     });
        //     return
        // }
        // const getParams = {
        //     "IpAddress"     :   "0.0.0.0",
        //     "ClientId"      :   this.state.clientId,
        //     "UserId"        :   this.state.userinfo.userId,
        //     "EmployeeId"    :   this.state.employeeId,
        //     "Periods"       :   this.state.periodLists,
        //     "LocationId"    :   this.state.selectedLocationId,
        //     "IsOffCycle": "0",
        //     "PayrollTypeId" :   this.state.payrollTypeId
        // }
        // //console.log("params")
        // //console.log(getParams)
        // await axios
        // .post(AppConfiguration.Setting().noserapiendpoint + "Payroll/GetPayrollSummaryReport", getParams)
        // .then(res => {
        //     const data = res.data.payrolls
        //     //console.log("GetPayrollLedgers")
        //     //console.log(data)
        //     /* END LOOP FOR EMPLOYEE */
        //     let arr = []
        //     /* END LOOP FOR EMPLOYEE */
        //     data.forEach(itm => {
        //         if(itm.memberName !== "GRAND TOTAL"){
        //             const obj = {
        //                 "client"        : this.state.client,
        //                 "clientId"      : this.state.clientId,
        //                 "employee"      : itm.memberName,
        //                 "employeeId"    : itm.employeeId,
        //                 "action"        : "VIEW PAYSLIP",
        //                 "endDate"       : this.state.endDate,
        //                 "isProcessed"   : this.state.isProcessed,
        //                 "payPeriod"     : this.state.payPeriod,
        //                 "periodId"      : this.state.periodId,
        //                 "startDate"     : this.state.startDate,
        //                 "isSpecial"     : this.state.isSpecial,
        //             }
        //             arr.push(obj)
        //         }
        //     });
        //     this.setState({
        //         dataGrid :   arr,
        //         //employeeList :   data,
        //         isloading   :   false,
        //     })
        
        // })
        // .catch(error=>{
        //     this.setState({
        //         isloading   :   false,
        //         alerttype   :   "Error!",
        //         isshow      :   true,
        //         color       :   "danger",
        //         message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
        //         fade        :   true
        //     })
        // })

        //END

        if(!this.state.clientId){
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "Please select client!",
                fade        :   true
            });
            return
        }
        if(this.state.periodId === ""){
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "Please select cutoff!",
                fade        :   true
            });
            return
        }
        const getParams = {
            "IpAddress"     :   "0.0.0.0",
            "ClientId"      :   this.state.clientId,
            "UserId"        :   this.state.userinfo.userId,
            "EmployeeId"    :   this.state.employeeId,
            "Periods"       :   this.state.periodLists,
        }
        //console.log("getParams")
        //console.log(getParams)
        await axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Payroll/GetPayrollPayslips", getParams)
        .then(res => {
            //console.log("res.data.payrolls")
            //console.log(res.data.payrolls)
            const data = res.data.payrolls.filter(o => o.payrollTypeId === "0" || o.payrollTypeId === this.state.payrollTypeId)
            //console.log("GetPayrollPayslips")
            //console.log(data)
            let arr = []
            let payrollType = ""
            var nf = new Intl.NumberFormat();
            const FormatCommas = (x) =>{
                x = x.toString();
                var pattern = /(-?\d+)(\d{3})/;
                while (pattern.test(x))
                    x = x.replace(pattern, "$1,$2");
                return x;
            }
            data.forEach(itm => {
                let netPay = ( FormatCommas(itm.netPay) - FormatCommas(itm.taxAmount) )
                ////console.log("netPay")
                ////console.log(FormatCommas(itm.netPay))
                ////console.log("taxAmount")
                ////console.log(FormatCommas(itm.taxAmount))
                ////console.log("total net pay")
                ////console.log(netPay)
                if(itm.payrollTypeId === "6"){
                    payrollType = "OTHER"
                }
                else if(itm.payrollTypeId === "1"){
                    payrollType = "ADJUSTMENT"
                }else {
                    payrollType = "WHOLE"
                }
                const obj = {
                    "client"        : this.state.client,
                    "clientId"      : this.state.clientId,
                    "employee"      : itm.memberName,
                    "employeeId"    : itm.employeeId,
                    "payrollType"   : payrollType,
                    "payrollTypeId" : itm.payrollTypeId,
                    "isSpecial"     : this.state.isSpecial,
                    "action"        : "VIEW PAYSLIP",
                    "endDate"       : this.state.endDate,
                    "isProcessed"   : this.state.isProcessed,
                    "payPeriod"     : itm.payPeriod,
                    "periodId"      : itm.payPeriodId,
                    "startDate"     : this.state.startDate,
                    "netPay"        : netPay
                }
                arr.push(obj)
            });
            this.setState({
                dataGrid :   arr,
                //employeeList :   data,
                isloading   :   false,
            })
        
        })
        .catch(error=>{
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade        :   true
            })
        })
        
    }  

    GetPayrollLedgers = () => {
        this.setState({isloading:true})
        const params = {
            "IpAddress"     :"0.0.0.0",
            "ClientId"      :this.state.clientId,
            "UserId"        :this.state.userinfo.userId,
            "EmployeeId"   :this.state.employeeId,
            "LedgerTypeId"   :"2",
            "ReferenceId"   :"3"
        };
        ////////////console.log("GetPayrollLedgers params")
        ////////////console.log(params)
        axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Payroll/GetPayrolls",  params)
        //.post("http://209.97.173.181:8081/Payroll/GetPayrollLedgers",  params)
        .then(res => {
            const data = res.data;
            ////////////console.log("GetPayrollLedgers")
            ////////////console.log(data)
            this.setState({
                //payperiodDDL : data,
                isloading   :  false,
            })
        })
    }  

    handleModalShow = (row, url) => {
        
        sessionStorage.setItem("payslipdetails", JSON.stringify(row))
        //window.open("http://localhost:8080/downloadpayslip")
        //window.open("http://143.198.221.10/downloadpayslip")
        window.open(window.location.origin + "/downloadpayslip")
    } 

    // openInNewTab = (row, url) => {
    //     sessionStorage.setItem("payslipdetails_", JSON.stringify(row))
    //     window.open(url, '_blank', 'noreferrer');
    //   };

    // handleModalClose = (e) =>{
    //     this.setState({modalShow:false})
    // }

    render() {
        // const {navigate} = this.state;
        // if (navigate) {
        //     return <Redirect to="/downloadpayslip" push={true} />;
        // } 
      
        const column = [
            // {
            //     dataField: 'payrollType',
            //     text: 'Payroll Type',
            //     editable: false,
            //     headerStyle: () => {
            //         return {width:'15%'}
            //     },
            // },
            {
                dataField: 'client',
                text: 'CLIENT',
                editable: false,
                headerStyle: () => {
                    return {width:'35%'}
                },
            },
            {
                dataField: 'employee',
                text: 'EMPLOYEE NAME',
                editable: false,
                headerStyle: () => {
                    return {width:'35%'}
                },
            },
            {
                dataField: 'payPeriod',
                text: 'CUTOFF',
                editable: false,
                headerStyle: () => {
                    return {width:'15%'}
                },
            },
            {
                dataField: 'action',
                text: 'ACTION',
                editable:false,
                headerStyle: () => {
                    return { width: "15%" };
                },
                style:{width:'10%'},
                formatter   :   (cell, row, isSelect) => {
                    return (
                        
                        <Button style={{height:'18px',paddingTop:'0',marginTop:'-4px',fontSize:'11px'}} 
                            variant="link" onClick={e => this.handleModalShow(row)} /*onClick={e => openInNewTab(row, 'http://localhost:3000/downloadpayslip')}*/
                        >{cell}</Button>
                        
                        );
                },
                
            }
        ] 
    
        const selectRow = {
            mode: 'checkbox',
            clickToSelectAndEditCell: true,
            onSelect: (row, isSelect, rowIndex, e) => {
                this.state.dataGrid.map(function(item,i){
                   
                    if(item.id===row.id)
                        {
                        item.isDeleted = isSelect ? "1" : "0"
                        item.isModified = isSelect ? "1" : "0"
                    }
                })

            }
        };
            const rowEvents = {
            onClick: (e, row, rowIndex) => {

            }
        };

        const expandRow = {
            renderer: row => (
                <BootstrapTable
                    keyField = "periodId"
                    data = { row.payrollDetails }
                    columns = { payrollColumn }
                    pagination={ paginationFactory({sizePerPageRenderer}) }
                    noDataIndication={ () => <div style={{width:'100%'}}>No record found.</div> }
                    rowClasses="noser-table-row-class no-checkbox"
                    striped
                    hover
                    condensed
                    wrapperClasses="table-responsive"
                    //selectRow = { selectRows }
                    //rowEvents={ requestMonitoringRowEvents }

                />
            ),
            showExpandColumn: true
        };
        const payrollColumn = [
            {
                dataField: 'payPeriod',
                text: 'PAY PERIOD',
                editable:false,
                headerStyle: () => {
                    return { width: "30%" };
                },
                style:{width:'30%'},
                
            },
            {
                dataField: 'startDate',
                text: 'START DATE',
                editable:false,
                headerStyle: () => {
                    return { width: "20%" };
                },
                style:{width:'20%'},
                
            },
            {
                dataField: 'endDate',
                text: 'END DATE',
                editable:false,
                headerStyle: () => {
                    return { width: "20%" };
                },
                style:{width:'20%'},
                
            },
            {
                dataField: 'action',
                text: 'ACTION',
                editable:false,
                headerStyle: () => {
                    return { width: "30%" };
                },
                style:{width:'30%'},
                formatter   :   (cell, row, isSelect) => {
                    return (
                        
                        <Button style={{height:'18px',paddingTop:'0',marginTop:'-4px',fontSize:'11px'}} 
                            variant="link" onClick={e => this.handleModalShow(row)} /*onClick={e => openInNewTab(row, 'http://localhost:3000/downloadpayslip')}*/
                        >{cell}</Button>
                        
                        );
                },
                
            }
        ] 
        return(
            <div>
                <Banner />
                    <Container className="mt-5" fluid>
                        <Card>
                            <Card.Header>PAYROLL >> PaySlip</Card.Header>
                            <Card.Body>
                                <Form>
                                    <Alert isOpen={this.state.isshow} color={this.state.color} fade={this.state.fade} className={this.state.isshow ? 'display-block' : 'display-none'}>
                                        <div className={this.state.color}></div> 
                                        {this.state.message}
                                    </Alert>
                                    <Form.Group as={Row} controlId="formPlaintextEmail">
                                        <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                            CLIENT
                                        </Form.Label>
                                        <Col>
                                            <Typeahead 
                                                labelKey='name'
                                                id="basic-example"
                                                onChange={this.handleChangeClient}
                                                options={this.state.clientDDL}
                                                placeholder="Select Client" 
                                            />
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} controlId="formPlaintextEmail">
                                        <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                        CUT-OFF
                                        </Form.Label>
                                        <Col>
                                            <Typeahead 
                                                labelKey='payPeriod'
                                                id="basic-example"
                                                onChange={this.handleChangePayPeriod}
                                                options={this.state.payperiodDDL}
                                                placeholder="Select pay Period" 
                                                multiple
                                                clearButton 
                                            />
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} controlId="formPlaintextEmail">
                                        <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                        EMPLOYEE
                                        </Form.Label>
                                        <Col>
                                            <Typeahead 
                                                labelKey='employeeName'
                                                id="basic-example"
                                                onChange={this.handleChangeEmployee}
                                                options={this.state.employeeDDL}
                                                placeholder="Select Employee" 
                                            />
                                        </Col>
                                    </Form.Group>
                                    <ButtonToolbar className="mt-1">
                                        <Button variant="info" className="ml-auto" onClick={ this.handleSearchClick }>
                                            Search
                                        </Button>
                                    </ButtonToolbar>
                                    <div className="mt-5">  
                                        <div>
                                            <Card.Header>EMPLOYEE PAYSLIP</Card.Header>
                                            <BootstrapTable
                                                keyField = "id"
                                                data = { this.state.dataGrid }
                                                columns = { column }
                                                pagination={ paginationFactory({sizePerPageRenderer}) }
                                                noDataIndication={ () => <div style={{width:'100%'}}>No record found.</div> }
                                                rowClasses="noser-table-row-class no-checkbox"
                                                striped
                                                hover
                                                condensed
                                                cellEdit = { cellEditFactory({
                                                    mode: 'dbclick',
                                                    blurToSave: true,
                                                    afterSaveCell: (oldValue, newValue, row, column) => {
                                                        //this.GridDataModified(oldValue, newValue, row.id, column.dataField)
                                                        }
                                                    })
                                                }
                                                wrapperClasses="table-responsive"
                                                //expandRow={ expandRow }
                                                selectRow = { selectRow }
                                                //rowEvents={ requestMonitoringRowEvents }
                        
                                            />
                                        </div>
                                    </div>
                                    
                                </Form>
                            </Card.Body>
                        </Card>
                       
                    </Container>
                    <NoserLoading show={this.state.isloading} />
                    {/* <PayslipModal 
                        show={this.state.modalShow}
                        onHide={this.handleModalClose}
                        onRefModal={ref => (this.child = ref)}
                    /> */}
                  
            </div> 
        )
    }

}
export default PaySlip;