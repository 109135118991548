import {
    React,Component,BootstrapTable,
    paginationFactory,Button, ButtonToolbar, Card, 
    Form, Col, Row, axios, Container, Banner, Typeahead, TimePicker,
    cellEditFactory, Type, moment, NavLink, Alert, AppConfiguration,
    NoserLoading, Noser, sizePerPageRenderer, DatePicker, Tabs, Tab,
    Redirect
} 
from '../../../noser-hris-component'

import UploadModal from './UploadModal'

import ToolkitProvider, { CSVExport } from 'react-bootstrap-table2-toolkit';
import 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css';
class SpecialClientConfig extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userinfo        :   [],
            isloading       :   false,
            isshow          :   false,
            alerttype       :   "",
            message         :   "",
            color           :   "",
            fade            :   true,
            minDate         :   '',
            disablecutoff   :   true,
            disablemapping  :   true,
            disablebillable :   true,
            openModal       :   false,
            closeModal      :   false,
            
            clientId        :   "",
            clientName      :   "",

            periodId        :   "",
            periodName      :   "",
            startDate       :   "",
            endDate         :   "",
            typeId          :   "0",
            periodYear      :   "",

            employeeId      :   "",
            mapId           :   "",

            clientDDL       :   [],
            employeeDDL     :   [],
            periodDDL       :   [],
            employeeMapDDL  :   [],
            billableDDL     :   [],
            typeDDL         :   [
                {"value":"0","label":"1ST"},
                {"value":"1","label":"2ND"}
            ]
        }
    } 
    componentDidMount(){
        this.state.userinfo = JSON.parse(sessionStorage.getItem("userData"))
        this.GetClients()
    }
    handleModalShow = (e) =>{
        this.setState({openModal:true})
    }
    handleModalClose = (e) =>{
        this.setState({openModal:false})
        //this.handleSearch(e)
    }
    handleChangeClient = (e) =>{
        this.setState({alerttype:"",isshow:false,color:"",message:"",fade:true});
        if(e.length===0)
        {
            this.state.clientId= ''
            this.state.clientName=''
            return
        }
        this.state.clientId= e[0].id
        this.state.clientName=e[0].name
        this.GetPayrollPeriods()
        this.GetEmployeeList()
        this.GetEmployeeMappings()
    }
    handleChangeEmployee = (e) =>{
        this.setState({alerttype:"",isshow:false,color:"",message:"",fade:true});
        if(e.length===0)
        {
            this.state.employeeId= ''
            return
        }
        this.state.employeeId= e[0].id
    }
    GetClients = async() =>{
        this.setState({isloading:true})
        const params = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.userinfo.clientId,
            "UserId":this.state.userinfo.userId,
        }
        await axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Client/GetClientList", params)
        .then(res => {
            const data = res.data;
            this.setState({clientDDL : data.clients ? data.clients : [], isloading:false})
            if(data.clients.length===0){
                this.setState({
                    alerttype   :   "Error!",
                    isshow      :   true,
                    color       :   "danger",
                    message     :   data.message,
                    fade        :   true
                });
            }    
        })
        .catch(error=>{
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade        :   true
            })
        })
    }
    GetEmployeeList = async() =>{
        this.setState({isloading:true})
        
        const params = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.clientId,
            "UserId":this.state.userinfo.userId,
        }
        await axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Employee/GetEmployeeProfileFilter", params)
        .then(res => {
            const data = res.data;
            this.setState({employeeDDL : data.profiles, isloading:false})
        })
        .catch(error=>{
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade        :   true
            })
        })
    }
    GetPayrollPeriods = async()=>{
        this.setState({isloading:true})
        const params = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.clientId,
            "UserId":this.state.userinfo.userId,
        }
        await axios
        .post(AppConfiguration.Setting().noserapiendpoint + "SpecialClientConfiguration/GetClientPayrollPeriods", params)
        .then(res => {
            const data = res.data;
            console.log(data)
            this.setState({periodDDL : data.payrollPeriods,isloading:false})
        })
        .catch(error=>{
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade        :   true
            })
        })
    }
    GetEmployeeMappings = async()=>{
        this.setState({isloading:true})
        const params = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.clientId,
            "UserId":this.state.userinfo.userId,
        }
        await axios
        .post(AppConfiguration.Setting().noserapiendpoint + "SpecialClientConfiguration/GetEmployeeMappings", params)
        .then(res => {
            const data = res.data;
            this.setState({employeeMapDDL : data.employeeMappings,isloading:false})
        })
        .catch(error=>{
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade        :   true
            })
        })
    }
    GetBillableHours =(e) => {
        this.setState({isloading:true})

        const params = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.clientId,
            "UserId":this.state.userinfo.userId,
            "ClientId": this.state.clientId,
            "LocationId": this.state.locationId,
            "PeriodId": this.state.periodId,
            "IsSpecial": "1"
    
        }
        console.log(params)
        axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Timekeeping/GetUploadBillableHours", params)
        .then(res => {
            const data = res.data;
            console.log(data)
            this.setState({billableDDL : data.billableHours ? data.billableHours : [], isloading:false})
            // if(data.billableDDL.length==="0"){
            //     this.setState({
            //         isloading   :   false,
            //         alerttype   :   "Error!",
            //         isshow      :   true,
            //         color       :   "danger",
            //         message     :   data.message,
            //         fade        :   true
            //     });
            // }
        })
        .catch(error=>{
           this.setState(  {
               isloading       :   false,
               alerttype       :   "Error!",
               isshow          :   true,
               color           :   "danger",
               message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
               fade            :   true
           })
       })
    }
    handlePayrollPeriod = (e) => {
        this.setState({alerttype:"",isshow:false,color:"",message:"",fade:true});
        if(e.length===0)
        {
            this.state.periodId= ''
            this.state.periodName=''
            return
        }
        this.state.periodId= e[0].id
        this.state.periodName=e[0].payPeriod
        this.GetBillableHours()
    }
    handleChanged = (e) =>{
        this.setState({[e.target.name]:e.target.value})
    }
    handleChangedDate = name => date =>{
        this.setState({[name]:date})

        if(name==='startDate'){
            var myDate = new Date(date);
            this.setState({minDate:myDate})
        }
    }
    handleAddEmployee = async() =>{
        this.setState({isloading:true})
        if(this.state.employeeId === ''){
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "Please select employee.",
                fade        :   true
            });
            return
        }

        if(this.state.mapId === ''){
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "Please enter reference id.",
                fade        :   true
            });
            return
        }
        
        const ids = this.state.employeeMapDDL.map(x => x.id);
        const sorted = ids.sort((a, b) => a - b);
        let maxId = parseInt(sorted[sorted.length-1]) + 1;
        
        const params = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.clientId,
            "UserId":this.state.userinfo.userId,
            "employeeMappings" :[
                {
                    "id":maxId.toString(),
                    "employeeId" : this.state.employeeId,
                    "mapId" : this.state.mapId,
                    "isDeleted" : "0",
                    "isModified": "1"
                }
            ]
        }
        await axios
        .post(AppConfiguration.Setting().noserapiendpoint + "SpecialClientConfiguration/SaveEmployeeMapping", params)
        .then(res => {
            const data = res.data;
            if(data.status==="1"){
                this.setState({
                    alerttype           :   "Success!",
                    isshow              :   true,
                    color               :   "success",
                    message             :   data.message,
                    fade                :   true,
                    employeeId          :   '',
                    mapId               :   '',
                });
                this.refs.empRef.clear() 
            }
            else {
                this.setState({
                    isloading   :   false,
                    alerttype   :   "Error!",
                    isshow      :   true,
                    color       :   "danger",
                    message     :   data.message,
                    fade        :   true
                });
            }
            this.GetEmployeeMappings()
        })
        .catch(error=>{
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade        :   true
            })
        })
    }
    handleSubmitEmployee = async() =>{
        this.setState({isloading:true})
        const params = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.clientId,
            "UserId":this.state.userinfo.userId,
            "employeeMappings" :this.state.employeeMapDDL.filter(x=>x.isDeleted==='1')
        }
        await axios
        .post(AppConfiguration.Setting().noserapiendpoint + "SpecialClientConfiguration/SaveEmployeeMapping", params)
        .then(res => {
            const data = res.data;
            if(data.status==="1"){
                this.setState({
                    alerttype           :   "Success!",
                    isshow              :   true,
                    color               :   "success",
                    message             :   data.message,
                    fade                :   true,
                    disablecutoff       :   true
                });
            }
            else {
                this.setState({
                    isloading   :   false,
                    alerttype   :   "Error!",
                    isshow      :   true,
                    color       :   "danger",
                    message     :   data.message,
                    fade        :   true
                });
            }
            this.GetEmployeeMappings()
        })
        .catch(error=>{
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade        :   true
            })
        })
    }
    handleSubmitCutoff = async() =>{
        this.setState({isloading:true})
        const params = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.clientId,
            "UserId":this.state.userinfo.userId,
            "payrollperiods" :this.state.periodDDL.filter(x=>x.isDeleted==='1')
        }
        await axios
        .post(AppConfiguration.Setting().noserapiendpoint + "SpecialClientConfiguration/SaveClientPayrollPeriod", params)
        .then(res => {
            const data = res.data;
            if(data.status==="1"){
                this.setState({
                    alerttype           :   "Success!",
                    isshow              :   true,
                    color               :   "success",
                    message             :   data.message,
                    fade                :   true,
                    disablecutoff       :   true
                });
            }
            else {
                this.setState({
                    isloading   :   false,
                    alerttype   :   "Error!",
                    isshow      :   true,
                    color       :   "danger",
                    message     :   data.message,
                    fade        :   true
                });
            }
            this.GetPayrollPeriods()
        })
        .catch(error=>{
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade        :   true
            })
        })
    }
    handleAddPeriod = async() => {
        this.setState({isloading:true})
        if(this.state.startDate === ''){
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "Please enter start date.",
                fade        :   true
            });
            return
        }

        if(this.state.endDate === ''){
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "Please enter end date.",
                fade        :   true
            });
            return
        }
        
        let startDate = moment(this.state.startDate).format('MM/DD/YYYY')
        let endDate = moment(this.state.endDate).format('MM/DD/YYYY')
        let periodYear = moment(this.state.startDate).format('YYYY')
        let periodName = this.FormatPeriodName(startDate,endDate)

        const ids = this.state.periodDDL.map(x => x.id);
        const sorted = ids.sort((a, b) => a - b);
        let maxId = parseInt(sorted[sorted.length-1]) + 1;
        
        const params = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.clientId,
            "UserId":this.state.userinfo.userId,
            "payrollperiods" :[
                {
                    "id":maxId.toString(),
                    "payPeriod" : periodName,
                    "startDate" : startDate,
                    "endDate": endDate,
                    "typeId" : this.state.typeId,
                    "periodYear" : periodYear,
                    "isDeleted" : "0",
                    "isModified": "1"
                }
            ]
        }
        await axios
        .post(AppConfiguration.Setting().noserapiendpoint + "SpecialClientConfiguration/SaveClientPayrollPeriod", params)
        .then(res => {
            const data = res.data;
            if(data.status==="1"){
                this.setState({
                    alerttype           :   "Success!",
                    isshow              :   true,
                    color               :   "success",
                    message             :   data.message,
                    fade                :   true,
                    startDate           :   '',
                    endDate           :   '',
                });
            }
            else {
                this.setState({
                    isloading   :   false,
                    alerttype   :   "Error!",
                    isshow      :   true,
                    color       :   "danger",
                    message     :   data.message,
                    fade        :   true
                });
            }
            this.GetPayrollPeriods()
        })
        .catch(error=>{
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade        :   true
            })
        })
    }
    handleChangeCheckbox(e) {
        if(e.target.name==="FirstCutOff"){
            this.setState({typeId: e.target.checked ? "0" : "1"})
        }
        if(e.target.name==="SecondCutOff"){
            this.setState({typeId: e.target.checked ? "1" : "0"})
        }
    }
    FormatPeriodName(startDate,endDate){
        let startYear = moment(startDate).format('YYYY')
        let endYear = moment(endDate).format('YYYY')
        let startMonth = moment(startDate).format('MMMM')
        let endMonth = moment(endDate).format('MMMM')
        let periodName = ''
        if(startYear === endYear){
            if(startMonth===endMonth){
                periodName = moment(startDate).format('MMM DD') + '-' + moment(endDate).format('DD, YYYY')
            }
            else{
                periodName = moment(startDate).format('MMM DD') + '-' + moment(endDate).format('MMM DD, YYYY')
            }
        }
        else{
            periodName = moment(startDate).format('MMM DD YYYY') + '-' + moment(endDate).format('MMM DD, YYYY')
        }
        return periodName
    }
    handleSubmitBillable = async(e) => {
        this.setState({isloading:true})

        const params = {
            "IpAddress":"0.0.0.0",
            "UserId":this.state.userinfo.userId,
            "ClientId": this.state.userinfo.clientId,
            "billableHours": this.state.billableDDL.filter(x => x.isModified==="1")
        }
        
        await axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Timekeeping/EditUploadBillableHours", params)
        .then(res => {
            const data = res.data;
            this.setState({
                isloading   :   false,
                alerttype   :   (data.status=="0") ? "Error!" : "Success!",
                isshow      :   true,
                color       :   (data.status=="0") ? "danger" : "success",
                message     :   data.message,
                fade        :   true
            });
            if(data.status=="1"){
                this.GetBillableHours()
            }
        })
        
        .catch(error=>{
           this.setState(  {
               isloading       :   false,
               alerttype       :   "Error!",
               isshow          :   true,
               color           :   "danger",
               message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
               fade            :   true
           })
       })
    }
    render(){
        const colBillable = [
            {
                dataField   :   'clientName',
                text        :   'CLIENT',
                editable    :    false,
                headerStyle : () => {
                    return { textAlign:'left',width  : "25%",fontSize:'10px' };
                  },
                style:{textAlign:'left',fontSize:'10px'}
            },
            {
                dataField   :   'locationName',
                text        :   'BRANCH',
                editable    :    false,
                headerStyle : () => {
                    return { width  : "20%",fontSize:'10px' };
                  },
                  style:{fontSize:'10px'}
            },
            {
                dataField   :   'periodName',
                text        :   'PERIOD',
                editable    :    false,
                headerStyle : () => {
                    return { textAlign:'center',width  : "10%",fontSize:'10px' };
                  },
                  style:{textAlign:'center',fontSize:'11px'}
            },
            {
                dataField   :   'totalTxn',
                text        :   'TOTAL COUNT',
                editable    :    false,
                headerStyle : () => {
                    return { textAlign:'center',width  : "10%",fontSize:'10px' };
                  },
                  style:{textAlign:'center',fontSize:'10px'}
            },
            {
                dataField   :   'txnDate',
                text        :   'DATE UPLOAD',
                editable    :    false,
                headerStyle : () => {
                    return { textAlign:'center',width  : "10%",fontSize:'10px' };
                  },
                  style:{textAlign:'center',fontSize:'10px'}
            },
            {
                dataField   :   'fileName',
                text        :   'FILE NAME',
                editable    :    false,
                headerStyle : () => {
                    return { width  : "25%",fontSize:'10px' };
                  },
                  style:{fontSize:'10px'}
            }
        ] 
        const colBillableDetails = [
            {
                dataField   :   'employeeName',
                text        :   'EMPLOYEE NAME',
                editable    :    false,
                headerStyle : () => {
                    return { textAlign:'left',width  : "10%",fontSize:'10px' };
                  },
                style:{textAlign:'left',fontSize:'10px',whiteSpace:'nowrap'}
            },
            {
                dataField   :   'totalWorkingDays',
                text        :   'TOTAL WORKING DAYS',
                editable    :    false,
                headerStyle : () => {
                    return { textAlign:'center',width  : "10%",fontSize:'10px' };
                  },
                  style:{textAlign:'center',fontSize:'10px'}
            },
            {
                dataField   :   'totalWorkingHrs',
                text        :   'TOTAL WORKING HRS',
                editable    :    false,
                headerStyle : () => {
                    return { textAlign:'center',width  : "10%",fontSize:'10px' };
                  },
                  style:{textAlign:'center',fontSize:'10px'}
            },
            {
                dataField   :   'absentDays',
                text        :   'ABSENT DAYS',
                editable    :    false,
                headerStyle : () => {
                    return { textAlign:'center',width  : "10%",fontSize:'10px' };
                  },
                  style:{textAlign:'center',fontSize:'10px'}
            },
            {
                dataField   :   'absentHrs',
                text        :   'ABSENT HRS',
                editable    :    false,
                headerStyle : () => {
                    return { textAlign:'center',width  : "10%",fontSize:'10px' };
                  },
                  style:{textAlign:'center',fontSize:'10px'}
            },
            {
                dataField   :   'lateMins',
                text        :   'LATE MINS',
                editable    :    false,
                headerStyle : () => {
                    return { textAlign:'center',width  : "10%",fontSize:'10px' };
                  },
                  style:{textAlign:'center',fontSize:'10px'}
            },
            {
                dataField   :   'lateHrs',
                text        :   'LATE HRS',
                editable    :    false,
                headerStyle : () => {
                    return { textAlign:'center',width  : "10%",fontSize:'10px' };
                  },
                  style:{textAlign:'center',fontSize:'10px'}
            },
            {
                dataField   :   'overbreakMins',
                text        :   'OB MINS',
                editable    :    false,
                headerStyle : () => {
                    return { textAlign:'center',width  : "10%",fontSize:'10px' };
                  },
                  style:{textAlign:'center',fontSize:'10px'}
            },
            {
                dataField   :   'overbreakHrs',
                text        :   'OB HRS',
                editable    :    false,
                headerStyle : () => {
                    return { textAlign:'center',width  : "10%",fontSize:'10px' };
                  },
                  style:{textAlign:'center',fontSize:'10px'}
            },
            {
                dataField   :   'undertimeMins',
                text        :   'UT MINS',
                editable    :    false,
                headerStyle : () => {
                    return { textAlign:'center',width  : "10%",fontSize:'10px' };
                  },
                  style:{textAlign:'center',fontSize:'10px'}
            },
            {
                dataField   :   'undertimeHrs',
                text        :   'UT HRS',
                editable    :    false,
                headerStyle : () => {
                    return { textAlign:'center',width  : "10%",fontSize:'10px' };
                  },
                  style:{textAlign:'center',fontSize:'10px'}
            },
            {
                dataField   :   'vacationLeave',
                text        :   'VL',
                editable    :    false,
                headerStyle : () => {
                    return { textAlign:'center',width  : "10%",fontSize:'10px' };
                  },
                  style:{textAlign:'center',fontSize:'10px'}
            },
            {
                dataField   :   'sickLeave',
                text        :   'SL',
                editable    :    false,
                headerStyle : () => {
                    return { textAlign:'center',width  : "10%",fontSize:'10px' };
                  },
                  style:{textAlign:'center',fontSize:'10px'}
            },
            {
                dataField   :   'maternityLeave',
                text        :   'ML',
                editable    :    false,
                headerStyle : () => {
                    return { textAlign:'center',width  : "10%",fontSize:'10px' };
                  },
                  style:{textAlign:'center',fontSize:'10px'}
            },
            {
                dataField   :   'paternityLeave',
                text        :   'PL',
                editable    :    false,
                headerStyle : () => {
                    return { textAlign:'center',width  : "10%",fontSize:'10px' };
                  },
                  style:{textAlign:'center',fontSize:'10px'}
            },
            {
                dataField   :   'ndHrs',
                text        :   'ND HRS',
                editable    :    false,
                headerStyle : () => {
                    return { textAlign:'center',width  : "10%",fontSize:'10px' };
                  },
                  style:{textAlign:'center',fontSize:'10px'}
            },
            {
                dataField   :   'ndotHrs',
                text        :   'NDOT HRS',
                editable    :    false,
                headerStyle : () => {
                    return { textAlign:'center',width  : "10%",fontSize:'10px' };
                  },
                  style:{textAlign:'center',fontSize:'10px'}
            },
            {
                dataField   :   'otHrs',
                text        :   'OT HRS',
                editable    :    false,
                headerStyle : () => {
                    return { textAlign:'center',width  : "10%",fontSize:'10px' };
                  },
                  style:{textAlign:'center',fontSize:'10px'}
            },
            {
                dataField   :   'dodHrs',
                text        :   'DOD HRS',
                editable    :    false,
                headerStyle : () => {
                    return { textAlign:'center',width  : "10%",fontSize:'10px' };
                  },
                  style:{textAlign:'center',fontSize:'10px'}
            },
            {
                dataField   :   'dodotHrs',
                text        :   'DOD OT HRS',
                editable    :    false,
                headerStyle : () => {
                    return { textAlign:'center',width  : "10%",fontSize:'10px' };
                  },
                  style:{textAlign:'center',fontSize:'10px'}
            },
            {
                dataField   :   'dodndHrs',
                text        :   'DOD ND HRS',
                editable    :    false,
                headerStyle : () => {
                    return { textAlign:'center',width  : "10%",fontSize:'10px' };
                  },
                  style:{textAlign:'center',fontSize:'10px'}
            },
            {
                dataField   :   'dodndotHrs',
                text        :   'DOD NDOT HRS',
                editable    :    false,
                headerStyle : () => {
                    return { textAlign:'center',width  : "10%",fontSize:'10px' };
                  },
                  style:{textAlign:'center',fontSize:'10px'}
            },
            {
                dataField   :   'sholHrs',
                text        :   'SHOL HRS',
                editable    :    false,
                headerStyle : () => {
                    return { textAlign:'center',width  : "10%",fontSize:'10px' };
                  },
                  style:{textAlign:'center',fontSize:'10px'}
            },
            {
                dataField   :   'sholotHrs',
                text        :   'SHOL OT HRS',
                editable    :    false,
                headerStyle : () => {
                    return { textAlign:'center',width  : "10%",fontSize:'10px' };
                  },
                  style:{textAlign:'center',fontSize:'10px'}
            },
            {
                dataField   :   'sholndHrs',
                text        :   'SHOL ND HRS',
                editable    :    false,
                headerStyle : () => {
                    return { textAlign:'center',width  : "10%",fontSize:'10px' };
                  },
                  style:{textAlign:'center',fontSize:'10px'}
            },
            {
                dataField   :   'sholndotHrs',
                text        :   'SHOL NDOT HRS',
                editable    :    false,
                headerStyle : () => {
                    return { textAlign:'center',width  : "10%",fontSize:'10px' };
                  },
                  style:{textAlign:'center',fontSize:'10px'}
            },
            {
                dataField   :   'shdodHrs',
                text        :   'SHOL DOD HRS',
                editable    :    false,
                headerStyle : () => {
                    return { textAlign:'center',width  : "10%",fontSize:'10px' };
                  },
                  style:{textAlign:'center',fontSize:'10px'}
            },
            {
                dataField   :   'shdodotHrs',
                text        :   'SHOL DOD OT HRS',
                editable    :    false,
                headerStyle : () => {
                    return { textAlign:'center',width  : "10%",fontSize:'10px' };
                  },
                  style:{textAlign:'center',fontSize:'10px'}
            },
            {
                dataField   :   'shdodndHrs',
                text        :   'SHOL DOD ND HRS',
                editable    :    false,
                headerStyle : () => {
                    return { textAlign:'center',width  : "10%",fontSize:'10px' };
                  },
                  style:{textAlign:'center',fontSize:'10px'}
            },
            {
                dataField   :   'shdodndotHrs',
                text        :   'SHOL DOD NDOT HRS',
                editable    :    false,
                headerStyle : () => {
                    return { textAlign:'center',width  : "10%",fontSize:'10px' };
                  },
                  style:{textAlign:'center',fontSize:'10px'}
            },
            {
                dataField   :   'lholHrs',
                text        :   'LHOL HRS',
                editable    :    false,
                headerStyle : () => {
                    return { textAlign:'center',width  : "10%",fontSize:'10px' };
                  },
                  style:{textAlign:'center',fontSize:'10px'}
            },
            {
                dataField   :   'lholotHrs',
                text        :   'LHOL OT HRS',
                editable    :    false,
                headerStyle : () => {
                    return { textAlign:'center',width  : "10%",fontSize:'10px' };
                  },
                  style:{textAlign:'center',fontSize:'10px'}
            },
            {
                dataField   :   'lholndHrs',
                text        :   'LHOL ND HRS',
                editable    :    false,
                headerStyle : () => {
                    return { textAlign:'center',width  : "10%",fontSize:'10px' };
                  },
                  style:{textAlign:'center',fontSize:'10px'}
            },
            {
                dataField   :   'lholndotHrs',
                text        :   'LHOL NDOT HRS',
                editable    :    false,
                headerStyle : () => {
                    return { textAlign:'center',width  : "10%",fontSize:'10px' };
                  },
                  style:{textAlign:'center',fontSize:'10px'}
            },

            {
                dataField   :   'lholdodHrs',
                text        :   'LHOL DOD NDOT HRS',
                editable    :    false,
                headerStyle : () => {
                    return { textAlign:'center',width  : "10%",fontSize:'10px' };
                  },
                  style:{textAlign:'center',fontSize:'10px'}
            },
            {
                dataField   :   'lholdodotHrs',
                text        :   'LHOL DOD NDOT HRS',
                editable    :    false,
                headerStyle : () => {
                    return { textAlign:'center',width  : "10%",fontSize:'10px' };
                  },
                  style:{textAlign:'center',fontSize:'10px'}
            },
            {
                dataField   :   'lholdodndHrs',
                text        :   'LHOL DOD NDOT HRS',
                editable    :    false,
                headerStyle : () => {
                    return { textAlign:'center',width  : "10%",fontSize:'10px' };
                  },
                  style:{textAlign:'center',fontSize:'10px'}
            },
            {
                dataField   :   'lholdodndotHrs',
                text        :   'LHOL DOD NDOT HRS',
                editable    :    false,
                headerStyle : () => {
                    return { textAlign:'center',width  : "10%",fontSize:'10px' };
                  },
                  style:{textAlign:'center',fontSize:'10px'}
            },

            {
                dataField   :   'doublesholHrs',
                text        :   'DOUBLE HOL HRS',
                editable    :    false,
                headerStyle : () => {
                    return { textAlign:'center',width  : "10%",fontSize:'10px' };
                  },
                  style:{textAlign:'center',fontSize:'10px'}
            },
            {
                dataField   :   'doublesholotHrs',
                text        :   'DOUBLE HOL OT HRS',
                editable    :    false,
                headerStyle : () => {
                    return { textAlign:'center',width  : "10%",fontSize:'10px' };
                  },
                  style:{textAlign:'center',fontSize:'10px'}
            },
            {
                dataField   :   'doublesholndHrs',
                text        :   'DOUBLE HOL ND HRS',
                editable    :    false,
                headerStyle : () => {
                    return { textAlign:'center',width  : "10%",fontSize:'10px' };
                  },
                  style:{textAlign:'center',fontSize:'10px'}
            },
            {
                dataField   :   'doublesholndotHrs',
                text        :   'DOUBLE HOL NDOT HRS',
                editable    :    false,
                headerStyle : () => {
                    return { textAlign:'center',width  : "10%",fontSize:'10px' };
                  },
                  style:{textAlign:'center',fontSize:'10px'}
            },

            {
                dataField   :   'doublesholdodHrs',
                text        :   'DOUBLE HOL DOD HRS',
                editable    :    false,
                headerStyle : () => {
                    return { textAlign:'center',width  : "10%",fontSize:'10px' };
                  },
                  style:{textAlign:'center',fontSize:'10px'}
            },
            {
                dataField   :   'doublesholotHrs',
                text        :   'DOUBLE HOL DOD OT HRS',
                editable    :    false,
                headerStyle : () => {
                    return { textAlign:'center',width  : "10%",fontSize:'10px' };
                  },
                  style:{textAlign:'center',fontSize:'10px'}
            },
            {
                dataField   :   'doublesholndHrs',
                text        :   'DOUBLE HOL DOD ND HRS',
                editable    :    false,
                headerStyle : () => {
                    return { textAlign:'center',width  : "10%",fontSize:'10px' };
                  },
                  style:{textAlign:'center',fontSize:'10px'}
            },
            {
                dataField   :   'doublesholndotHrs',
                text        :   'DOUBLE HOL DOD NDOT HRS',
                editable    :    false,
                headerStyle : () => {
                    return { textAlign:'center',width  : "10%",fontSize:'10px' };
                  },
                  style:{textAlign:'center',fontSize:'10px'}
            },

            {
                dataField   :   'doublelholHrs',
                text        :   'DOUBLE LHOL HRS',
                editable    :    false,
                headerStyle : () => {
                    return { textAlign:'center',width  : "10%",fontSize:'10px' };
                  },
                  style:{textAlign:'center',fontSize:'10px'}
            },
            {
                dataField   :   'doublelholotHrs',
                text        :   'DOUBLE LHOL OT HRS',
                editable    :    false,
                headerStyle : () => {
                    return { textAlign:'center',width  : "10%",fontSize:'10px' };
                  },
                  style:{textAlign:'center',fontSize:'10px'}
            },
            {
                dataField   :   'doublelholndHrs',
                text        :   'DOUBLE LHOL ND HRS',
                editable    :    false,
                headerStyle : () => {
                    return { textAlign:'center',width  : "10%",fontSize:'10px' };
                  },
                  style:{textAlign:'center',fontSize:'10px'}
            },
            {
                dataField   :   'doublelholndotHrs',
                text        :   'DOUBLE LHOL NDOT HRS',
                editable    :    false,
                headerStyle : () => {
                    return { textAlign:'center',width  : "10%",fontSize:'10px' };
                  },
                  style:{textAlign:'center',fontSize:'10px'}
            },

            {
                dataField   :   'doublelholdodHrs',
                text        :   'DOUBLE LHOL DOD HRS',
                editable    :    false,
                headerStyle : () => {
                    return { textAlign:'center',width  : "10%",fontSize:'10px' };
                  },
                  style:{textAlign:'center',fontSize:'10px'}
            },
            {
                dataField   :   'doublelholdodotHrs',
                text        :   'DOUBLE LHOL DOD OT HRS',
                editable    :    false,
                headerStyle : () => {
                    return { textAlign:'center',width  : "10%",fontSize:'10px' };
                  },
                  style:{textAlign:'center',fontSize:'10px'}
            },
            {
                dataField   :   'doublelholdodndHrs',
                text        :   'DOUBLE LHOL DOD ND HRS',
                editable    :    false,
                headerStyle : () => {
                    return { textAlign:'center',width  : "10%",fontSize:'10px' };
                  },
                  style:{textAlign:'center',fontSize:'10px'}
            },
            {
                dataField   :   'doublelholdodndotHrs',
                text        :   'DOUBLE LHOL DOD NDOT HRS',
                editable    :    false,
                headerStyle : () => {
                    return { textAlign:'center',width  : "10%",fontSize:'10px' };
                  },
                  style:{textAlign:'center',fontSize:'10px'}
            },

            {
                dataField   :   'remarks',
                text        :   'Remarks',
                editable    :    false,
                headerStyle : () => {
                    return { textAlign:'left',width  : "20%",fontSize:'10px' };
                  },
                  style:{textAlign:'left',fontSize:'10px',whiteSpace:'nowrap'}
            },
            
        ]
        const colEmployees = [
            {
                dataField: 'employeeName',
                text: 'EMPLOYEE NAME',
                editable:false,
                headerStyle: () => {
                    return { width: "20%", textAlign:'left' };
                  },
                style:()=>{
                    return {textAlign:'left'}
                }
            },
            {
                dataField: 'mapId',
                text: 'REFERENCE ID',
                editable:false,
                headerStyle: () => {
                    return { width: "10%", textAlign:'center' };
                  },
                style:()=>{
                    return {textAlign:'center'}
                }
            },
            {
                dataField: 'status',
                text: 'EMPLOYEE STATUS',
                editable:false,
                headerStyle: () => {
                    return { width: "70%", textAlign:'left' };
                  },
                style:()=>{
                    return {textAlign:'left'}
                }
            },

        ]
        const colPeriods = [
            {
                dataField: 'payPeriod',
                text: 'Period',
                editable:false,
                headerStyle: () => {
                    return { width: "18%", textAlign:'left' };
                  },
                style:()=>{
                    return {textAlign:'left'}
                }
            },
            {
                dataField: 'startDate',
                text: 'START DATE',
                editable:false,
                headerStyle: () => {
                    return { width: "12%",textAlign:'center' };
                  },
                  style:()=>{
                    return {textAlign:'center'}
                },
                formatter: (cell) => { 
                    let dateObj = cell;
                    if (typeof cell !== 'object') dateObj = new Date(cell);
                    if (cell != null){
                        let m = moment(dateObj);
                        return (m.isValid() ? moment(dateObj).format('MM/DD/YYYY') : "")
                    }
                },
                editor: {
                    type: Type.DATE,
                    //defaultValue: Date.now(),
                    //minDateValue: Date.now(),
                }
            },
            {
                dataField: 'endDate',
                text: 'END DATE',
                editable:false,
                headerStyle: () => {
                    return { width: "12%",textAlign:'center' };
                  },
                  style:()=>{
                    return {textAlign:'center'}
                },
                formatter: (cell) => { 
                    let dateObj = cell;
                    if (typeof cell !== 'object') dateObj = new Date(cell);
                    if (cell != null){
                        let m = moment(dateObj);
                        return (m.isValid() ? moment(dateObj).format('MM/DD/YYYY') : "")
                    }
                },
                editor: {
                    type: Type.DATE,
                    //defaultValue: Date.now(),
                    //minDateValue: Date.now(),
                }
            },
            {
                dataField: 'typeId',
                text: 'TYPE',
                editable:true,
                headerStyle: () => {
                    return { width: "58%", textAlign:'left' };
                  },
                style:()=>{
                    return {textAlign:'left'}
                },
                formatter: (cell, row) => {
                    if(row.typeId!=='' && row.typeId!=null){
                        if(this.state.typeDDL.filter(x=>x.value===cell).length>0){
                            return this.state.typeDDL.find(x => x.value === cell).label;
                        }
                    }
                },
                editor: {
                    type: Type.SELECT,
                    options: this.state.typeDDL
                }
            },
        ]
        const periodRow = {
            mode: 'checkbox',
            clickToSelectAndEditCell: true,
            hideSelectAll: true,
            onSelect: (row, isSelect, rowIndex, e) => {
                let isDisable = true
                this.state.periodDDL.map(function(item,i){
                    if(item.id===row.id)
                    {
                        item.isDeleted = isSelect ? "1" : "0"
                    }
                    if(item.isDeleted==="1")
                        isDisable=false
                })
                this.setState({disablecutoff:isDisable})
             }
        };
        const employeeRow = {
            mode: 'checkbox',
            clickToSelectAndEditCell: true,
            hideSelectAll: true,
            onSelect: (row, isSelect, rowIndex, e) => {
                let isDisable = true
                this.state.employeeMapDDL.map(function(item,i){
                    if(item.id===row.id)
                    {
                        item.isDeleted = isSelect ? "1" : "0"
                    }
                    if(item.isDeleted==="1")
                        isDisable=false
                })
                this.setState({disablemapping:isDisable})
             }
        };
        const billableRow = {
            mode: 'checkbox',
            clickToSelectAndEditCell: true,
            hideSelectAll: true,
            onSelect: (row, isSelect, rowIndex, e) => {
                let isDisable = true
                this.state.billableDDL.map(function(item,i){
                    if(item.id===row.id)
                    {
                        item.isDeleted = isSelect ? "1" : "0"
                        item.isModified = isSelect ? "1" : "0"
                    }
                    if(item.isDeleted==="1")
                        isDisable=false
                })
                this.setState({disablebillable:isDisable})
             }
        };
        const expandRow = {
            onlyOneExpanding: true,
            renderer: row => 
            (
                <BootstrapTable
                    keyField = "id"
                    data = { row.billableDetails }
                    columns = { colBillableDetails }
                    rowClasses="noser-table-row-class"
                    striped
                    hover
                    condensed
                    expandRow
                    wrapperClasses="table-responsive"
                    rowClasses="noser-table-row-class"
                    noDataIndication={ () => <div>No details.</div> }
                /> 
                
            ),
            showExpandColumn: true,
          };
        return(
            <div>
                <Banner />
                <Container className="themed-container" fluid={true}>
                    <Card className="mt-5">
                        <Card.Header>Timekeeping >> Special Client Configurations</Card.Header>
                        <Card.Body>
                            <Form >
                                <Alert isOpen={this.state.isshow} color={this.state.color} fade={this.state.fade} className={this.state.isshow ? 'display-block' : 'display-none'}>
                                    <div className={this.state.color}></div> 
                                    {this.state.message}
                                </Alert>
                                <Form.Group as={Row} controlId="formHorizontalEmail">
                                    <Col sm={12}>
                                        <Typeahead
                                            labelKey='name'
                                            id="basic-example"
                                            onChange={this.handleChangeClient}
                                            options={this.state.clientDDL}
                                            placeholder="Select Client"

                                        />
                                    </Col>
                                </Form.Group>
                                
                                <Tabs defaultActiveKey="default" transition={false} id="noanim-tab-example">
                                    <Tab eventKey="default" title="Payroll Period">
                                    <Card className="card-tab">
                                        <div className="card-header-tab"></div>
                                        <Card.Body>
                                            <Form.Row>
                                            <Form.Group as={Col} sm={0}>
                                                <Form.Label>
                                                    From :
                                                </Form.Label>
                                            </Form.Group>
                                            <Form.Group as={Col} sm={1}>
                                                <DatePicker
                                                    id='startDate'
                                                    name='startDate'
                                                    onChange={this.handleChangedDate('startDate')}
                                                    //minDate={this.minDate}
                                                    value={this.state.startDate}
                                                    selected={this.state.startDate}
                                                    dateFormat={"MM/dd/yyyy"}
                                                    className="form-control"
                                                    autoComplete="off"
                                                    disabled={this.state.clientId==='' ? true : false}
                                                />
                                            </Form.Group>
                                            <Form.Group as={Col} sm={0}>
                                                <Form.Label>
                                                    To :
                                                </Form.Label>
                                            </Form.Group>
                                            <Form.Group as={Col} sm={1}>
                                                <DatePicker
                                                    id='endDate'
                                                    name='endDate'
                                                    onChange={this.handleChangedDate('endDate')}
                                                    minDate={this.state.minDate}
                                                    value={this.state.endDate}
                                                    selected={this.state.endDate}
                                                    dateFormat={"MM/dd/yyyy"}
                                                    className="form-control"
                                                    autoComplete="off"
                                                    disabled={this.state.clientId==='' ? true : false}
                                                />
                                            </Form.Group>
                                            <Form.Group as={Col} sm={8}>
                                                <ButtonToolbar>
                                                    <Button disabled={this.state.clientId==='' ? true : false} style={{minWidth:'60px'}} variant="success" onClick={this.handleAddPeriod}>Add</Button>
                                                </ButtonToolbar>
                                            </Form.Group>
                                            </Form.Row>
                                            <Form.Row>
                                            <Form.Group as={Col} sm={1}>
                                                <Form.Check 
                                                    inline
                                                    type="radio" 
                                                    label="1st"
                                                    onChange={e => this.handleChangeCheckbox(e)}
                                                    checked={this.state.typeId==="0" ? true : false}
                                                    disabled={this.state.clientId==='' ? true : false}
                                                    name="FirstCutOff"
                                                />
                                            </Form.Group>
                                            <Form.Group as={Col} sm={1}>
                                                <Form.Check 
                                                    inline
                                                    type="radio" 
                                                    label="2nd"
                                                    onChange={e => this.handleChangeCheckbox(e)}
                                                    checked={this.state.typeId==="1" ? true : false}
                                                    disabled={this.state.clientId==='' ? true : false}
                                                    name="SecondCutOff"
                                                />
                                            </Form.Group>
                                        </Form.Row>
                                            <Form.Row>
                                                <Form.Group as={Col} sm={12}>
                                                    <Card.Header>Payroll Periods</Card.Header>
                                                    <BootstrapTable
                                                        keyField = "id"
                                                        data = { this.state.periodDDL }
                                                        columns = { colPeriods }
                                                        selectRow = { periodRow }
                                                        cellEdit = { cellEditFactory({ mode: 'dbclick', blurToSave: true }) }
                                                        pagination={ paginationFactory({sizePerPage:15,hideSizePerPage:true,hidePageListOnlyOnePage:true})}
                                                        rowClasses="noser-table-row-class"
                                                        striped
                                                        hover
                                                        condensed
                                                        noDataIndication={ () => <div style={{width:'100%'}}>No record found.</div> }
                                                    />
                                                </Form.Group>
                                            </Form.Row>
                                            <Form.Row>
                                                <ButtonToolbar sm={12}>
                                                    <Button className='ml-auto' disabled={this.state.disablecutoff} style={{minWidth:'60px'}} variant="success" onClick={this.handleSubmitCutoff}>SAVE</Button>
                                                </ButtonToolbar>
                                            </Form.Row>
                                        </Card.Body>
                                    </Card>
                                    </Tab>
                                    <Tab eventKey="mapping" title="Employee Mapping">
                                    <Card className="card-tab">
                                        <div className="card-header-tab"></div>
                                        <Card.Body>
                                            <Form.Row>
                                            <Form.Group as={Col} sm={0}>
                                                <Form.Label>
                                                    EMPLOYEE NAME :
                                                </Form.Label>
                                            </Form.Group>
                                            <Form.Group as={Col} sm={2}>
                                                <Typeahead
                                                    ref="empRef"
                                                    labelKey='employeeName'
                                                    id="basic-example"
                                                    onChange={this.handleChangeEmployee}
                                                    options={this.state.employeeDDL}
                                                    placeholder="Select Employee"
                                                    disabled={this.state.clientId==='' ? true : false}
                                                />
                                            </Form.Group>
                                            <Form.Group as={Col} sm={0}>
                                                <Form.Label>
                                                    Reference Id :
                                                </Form.Label>
                                            </Form.Group>
                                            <Form.Group as={Col} sm={1}>
                                                <Form.Control 
                                                    type="text"
                                                    id="mapId"
                                                    name="mapId"
                                                    value={this.state.mapId}
                                                    onChange={this.handleChanged}
                                                    autoComplete="off"
                                                    disabled={this.state.clientId==='' ? true : false}
                                                />
                                            </Form.Group>
                                            <Form.Group as={Col} sm={6}>
                                                <ButtonToolbar>
                                                    <Button disabled={this.state.clientId==='' ? true : false} style={{minWidth:'60px'}} variant="success" onClick={this.handleAddEmployee}>Add</Button>
                                                </ButtonToolbar>
                                            </Form.Group>
                                        </Form.Row>
                                            <Form.Row>
                                            <Form.Group as={Col} sm={12}>
                                                <Card.Header>Employee Mapping</Card.Header>
                                                <BootstrapTable
                                                    keyField = "id"
                                                    data = { this.state.employeeMapDDL }
                                                    columns = { colEmployees }
                                                    selectRow = { employeeRow }
                                                    cellEdit = { cellEditFactory({ mode: 'dbclick', blurToSave: true }) }
                                                    pagination={ paginationFactory({sizePerPage:15,hideSizePerPage:true,hidePageListOnlyOnePage:true})}
                                                    rowClasses="noser-table-row-class"
                                                    striped
                                                    hover
                                                    condensed
                                                    noDataIndication={ () => <div style={{width:'100%'}}>No record found.</div> }
                                                />
                                            </Form.Group>
                                        </Form.Row>
                                            <Form.Row>
                                            <ButtonToolbar sm={12}>
                                                <Button className='ml-auto' disabled={this.state.disablemapping} style={{minWidth:'60px'}} variant="success" onClick={this.handleSubmitEmployee}>SAVE</Button>
                                            </ButtonToolbar>
                                            </Form.Row>
                                        </Card.Body>
                                    </Card>
                                    </Tab>
                                    <Tab eventKey="billable" title="Billable DTR">
                                    <Card className="card-tab">
                                        <div className="card-header-tab"></div>
                                        <Card.Body>
                                        <Form.Row>
                                            <Form.Group as={Col} sm={0}>
                                                <Form.Label>
                                                    PAYROLL PERIOD :
                                                </Form.Label>
                                            </Form.Group>
                                            <Form.Group as={Col} sm={3}>
                                                <Typeahead
                                                    ref="periodRef"
                                                    labelKey='payPeriod'
                                                    id="basic-example"
                                                    onChange={this.handlePayrollPeriod}
                                                    options={this.state.periodDDL}
                                                    placeholder="Select Payroll Period"
                                                    disabled={this.state.clientId==='' ? true : false}
                                                />
                                            </Form.Group>
                                            </Form.Row>
                                            <Form.Row>
                                            <Form.Group as={Col} sm={12}>
                                                <Card.Header>Billable Upload</Card.Header>
                                                <BootstrapTable
                                                    keyField = "id"
                                                    data = { this.state.billableDDL }
                                                    columns = { colBillable }
                                                    selectRow = { billableRow }
                                                    cellEdit = { cellEditFactory({ mode: 'dbclick', blurToSave: true }) }
                                                    pagination={ paginationFactory({sizePerPage:15,hideSizePerPage:true,hidePageListOnlyOnePage:true})}
                                                    rowClasses="noser-table-row-class"
                                                    striped
                                                    hover
                                                    condensed
                                                    noDataIndication={ () => <div style={{width:'100%'}}>No record found.</div> }
                                                    expandRow={ expandRow }
                                                />
                                            </Form.Group>
                                            </Form.Row>
                                            <div>
                                                <ButtonToolbar>
                                                <Button className="noser-button-mr1" variant="success" onClick={this.handleModalShow}>UPLOAD</Button>
                                                <Button disabled={this.state.disablebillable} className="noser-button" variant="success" onClick={this.handleSubmitBillable}>SAVE</Button>
                                                </ButtonToolbar>
                                            </div>
                                        </Card.Body>
                                    </Card>
                                    </Tab>
                                </Tabs>
                            </Form>
                        </Card.Body>
                    </Card>
                </Container>
                <NoserLoading show={this.state.isloading} />
                <UploadModal 
                    show={this.state.openModal}
                    onHide={this.handleModalClose}
                />
            </div>
        )
    }
}

export default SpecialClientConfig;