import {
    React,Component,BootstrapTable,
    paginationFactory,Button, ButtonToolbar, Card, 
    Form, Col, Row, axios, Container, Banner, Typeahead, TimePicker,
    cellEditFactory, Type, moment, NavLink, Alert, AppConfiguration,
    NoserLoading, Noser, sizePerPageRenderer, DatePicker,
} 
from '../../noser-hris-component';
import { DropdownButton, Dropdown, FormControl} from 'react-bootstrap';

import  InsertCOCReference from '../Maintenance/Modal/InsertCOCReference';
import { Input } from 'reactstrap';
 
class NoticeToExplain extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fade            :   true, 
            color           :   "",
            isshow          :   false,
            message         :   "",
            userinfo        :   [],
            isloading       :   false,
            alerttype       :   "",

            batchAutocomplete                   :   [],
            clientAutocomplete                  :   [],
            positionAutocomplete                :   [],
            trainingAutocomplete                :   [],
            trainingRegisterTableList           :   [ { "typeOfCases" : "DOLE" } ],
            batchNoAutocomplete                 :   [],
            dateFrom                            :   "",
            dateTo                              :   "",
            modalCoCReferenceShow               :   false,

            category                :"",

            isLoading               :"",

           
            selectedEmployeeId      :[],
            selectedEmployeeNo      :[],
            selectedEmployeeBranch  :[],
            selectedPosition        :[],

            getClientList           :[],
            getEmployeeList         :[],
            getEmployeeNoList       :[],
            position                :[],
            location                :[],

            //addParagraph:[],
            values                  :[],
            
            dateApplied             :"",
            selectedClientName      :"",
            selectedEmployeeName    :[],
            selectedEmployeeNo      :[],
            irReferenceNo           :"",
            paragraph               :"",
            addedparagraph1         :"",
            addedparagraph2         :"",
            addedparagraph3         :"",
            quotedStatement         :[],

            addedparagraph1Check    : false,
            cocReferences           : false,
            addedparagraph2Check    : false,
            addedparagraph3Check    : false,
            quotedStatementCheck    : false,

            preparedBy              :"",
            prepareByPosition       :"",
            notedBy                 :"",
            receivedBy              :"",

            articleNo               :"",
            articleName             :"",
            sectionNo               :"",
            infraction              :"",
            category                :"",

            articleNo2               :"",
            articleName2             :"",
            sectionNo2              :"",
            infraction2             :"",
            category2                :"",

            sectionNo3              :"",
            infraction3             :"",
            category3               :"",
          
            irDateIssued            :"",
            dateRecieveByER         :"",
            referenceNoNTE          :"",
            cocReferences           :"",

        }
        /* this.handleSubmit = this.handleSubmit.bind(this); */
    }

    componentDidMount(){
        const sleep = (milliseconds) => {return new Promise(resolve => setTimeout(resolve, milliseconds))}
        this.state.userinfo = JSON.parse(sessionStorage.getItem("userData"))

        this.setState({isLoading:true,loadingText:"Loading client list..."})

        this.state.userinfo = JSON.parse(sessionStorage.getItem("userData"))
        this.getClientList();;
    }

    /////******DYNAMIC COMPONENT TASYO******/////////
    /* createUI(){
        return this.state.values.map((el, i) => 
            <div key={i} className="mt-3">
               <FormControl as="textarea" value={el||''} aria-label="With textarea" placeHolder="(Enter Paragraph)" onChange={this.handleChange.bind(this, i)} />
               
            </div>          
        )
    }
   
    handleChange(i, event) {
        let values = [...this.state.values];
        values[i] = event.target.value;
        this.setState({ values });
        console.log(values)
    }
     
    addClick(){
       this.setState(prevState => ({ values: [...prevState.values, '']}))
    }
     
    removeClick(i){
        let values = [...this.state.values];
        values.splice(i,1);
        this.setState({ values });
    }
   
    handleSubmit(event) {
       alert('A name was submitted: ' + this.state.values.join(', '));
       event.preventDefault();
    } */
   


    getClientList(){
        this.setState({isloading:true})
        const getParams = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.userinfo.clientId,
            "UserId":this.state.userinfo.userId,
        }
        axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Client/GetClientList", getParams)
        .then(res => {
            this.setState({
                isloading:false,
                getClientList : res.data.clients ? res.data.clients : []
            });
        })
        .catch(error=>{
           this.setState({
               isloading       :   false,
               alerttype       :   "Error!",
               isshow          :   true,
               color           :   "danger",
               message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
               fade            :   true
            })
       })
    }
    onChangeClientList = (e) => {
        if(e.length == 0) {
            this.state.selectedClientName=""
            this.state.selectedClientId=""
            return
        } 
        this.state.selectedClientId = e[0].id
        this.state.selectedClientName = e[0].name
        console.log("Client selectedClientId " + this.state.selectedClientId );
        console.log("Client selectedClientName " + this.state.selectedClientName );
        this.setState({isloading:true,isshow:false,color:"",message:"",fade:true})
        
        this.getEmployees();
    }
    getEmployees(){
        this.setState({isloading:true})
        const getParams = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.selectedClientId,
            "UserId":this.state.userinfo.userId,
            "EmployeeName":"",
            "EmployeeNo":"",
            "ClientName":""
        }
        axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Employee/GetEmployees", getParams)
        .then(res => {
            console.log("Employee List start ");
            console.log(res.data);
            console.log("Employee List end");
            const data = res.data

            if(data.status=="1")
                this.setState({getEmployeeList : data.employees, getEmployeeNoList : data.employees,isloading:false,}) 
            else
                this.setState({getEmployeeList : [], getEmployeeNoList : [],isloading:false}) 
                
        })
        .catch(error=>{

           this.setState(  {
               isloading       :   false,
               alerttype       :   "Error!",
               isshow          :   true,
               color           :   "danger",
               message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
               fade            :   true
           })

        })
        
    }

    onChangeEmployeesList = (e) => {       

        if(e.length==0)
        {
            this.setState({getEmployeeListData: null, selectedPayrollpayPeriod: '', selectedWorkScheduleIdParam: '', selectedPayrollPeriodsId : '',selectedEmployeeId : '',selectedEmployeeName : '',selectedPayrollPeriodsName : '', selectedWorkScheduleId: ''})
          
            return
        }

        this.setState({isloading:true,isshow:false,color:"",message:"",fade:true})

        this.state.selectedEmployeeName = e[0].employeeName
        this.state.selectedEmployeeId = e[0].id
        this.state.selectedEmployeeNo = e[0].employeeNo
        this.state.selectedEmployeeBranch = e[0].locationName
        this.state.selectedPosition = e[0].position
       
        console.log("Work selectedEmployeeName   " + this.state.selectedEmployeeNo );
        console.log("Employee List start ");
        console.log(this.state.selectedEmployeeBranch);
        console.log("Employee List end");


        this.setState({
            position:this.state.selectedPosition,
            location:this.state.selectedEmployeeBranch

        })

        this.setState({isloading:false})

    }

    onChangeEmployeesNo(e){

        this.setState({selectedEmployeeNo : e.target.value})
        //console.log(e.target.value)
    }

    onChangePosition(e){

        this.setState({position : e.target.value})
        //console.log(e.target.value)
    }

    onChangeLocation(e){

        this.setState({location : e.target.value})
        //console.log(e.target.value)
    }

    showTrainingRegisterModal = (e) => {

        this.setState({modalCoCReferenceShow: true})

    }

    handleModalClose = (e) =>{
        //reload or refresh data here
        //alert("Data pass from child : " + e)
        this.setState({modalCoCReferenceShow: false})
        this.getViewDraftMemo();;

    }


    GridDataModified(oldValue, newValue, id, column) {
        ////console.log(id)
        this.state.trainingScheduleTableList.map(function(item,i) {
            if (item.id===id)
                item.isModified = newValue!=oldValue ? "1" : "0"
        })

    }

    handleChangeDate = date => {
        ////console.log(date)

        this.setState({
            dateApplied: date,
            isshow:false,
        })

    }

    handleSaveClick=()=>{
        let createddate = moment(this.state.createddate).format('MM/DD/YYYY');
        let data = {
            "client"                :   this.state.selectedClientName,
            "employeeName"          :   this.state.selectedEmployeeName,
            "position"              :   this.state.position,
            "employeeNo"            :   this.state.selectedEmployeeNo,
            "location"              :   this.state.selectedEmployeeBranch,
            "date"                  :   this.state.dateApplied,
            "referenceNoNTE"        :   this.state.referenceNoNTE,
            "irReferenceNo"         :   this.state.irReferenceNo,
            "irDateIssued"          :   this.state.irDateIssued,
            "dateRecieveByER"       :   this.state.dateRecieveByER,
            "paragraph"             :   this.state.paragraph,
            "addedparagraph1"       :   this.state.addedparagraph1,
            "addedparagraph2"       :   this.state.addedparagraph2,
            "addedparagraph3"       :   this.state.addedparagraph3,
            "quotedStatement"       :   this.state.quotedStatement,
            "articleNo"             :   this.state.articleNo,
            "articleName"           :   this.state.articleName,
            "articleNo2"            :   this.state.articleNo2,
            "articleName2"          :   this.state.articleName2,
            "sectionNo"             :   this.state.sectionNo,
            "infraction"            :   this.state.infraction,
            "category"              :   this.state.category,
            "sectionNo"             :   this.state.sectionNo2,
            "infraction"            :   this.state.infraction2,
            "category"              :   this.state.category2,
            "sectionNo"             :   this.state.sectionNo3,
            "infraction"            :   this.state.infraction3,
            "category"              :   this.state.category3,
            "preparedBy"            :   this.state.preparedBy,
            "prepareByPosition"     :   this.state.prepareByPosition,
            "notedBy"               :   this.state.notedBy,
            "receivedBy"            :   this.state.receivedBy,
            "isDraft"               :   "0",
            "isDeleted"             :   "0",
            "createdby"             :   "user007",
            "createddate"           :   createddate,
            "modifiedby"            :   'user007',
            "modifieddate"          :   '0',
            "isModified"            :   '0',

        }

        console.log(data)
        
        const addParams = {
          "_collection" : "TestNTE",
          "doc_data"    : data
        }
          
        axios
        .post("http://134.209.99.190:8088/action/add", addParams)
        .then(res =>{
            const data = res.data
            console.log("success")
            console.log(res.data)
            this.setState({
                isloading       :   false,
                alerttype       :   "Success!",
                isshow          :   true,
                color           :   "success",
                message         :   "Data had successfully Save" ,
                fade            :   true
            })          
        })
        .catch(error =>{
            console.log("error: " + error)
            this.setState({
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade            :   true
            })
        })
    }

    handleChangeParagraph=(e)=>{
        this.setState({
            paragraph   : e.target.value
        })

        //console.log(this.state.paragraph)
    }

    handleAddedParagraph1=(e)=>{
        this.setState({
            addedparagraph1Check:true
        })
    }

    handleChangeAddedParagraph1=(e)=>{
        this.setState({
            addedparagraph1   : e.target.value
        })

        //console.log(this.state.addedparagraph1)
    }

    handleAddedParagraph2=(e)=>{
        this.setState({
            addedparagraph2Check:true
        })
    }

    handleChangeAddedParagraph2=(e)=>{
        this.setState({
            addedparagraph2   : e.target.value
        })
        //console.log(this.state.addedparagraph2)
    }

    handleAddedParagraph3=(e)=>{
        this.setState({
            addedparagraph3Check:true
        })
    }

    handleChangeAddedParagraph3=(e)=>{
        this.setState({
            addedparagraph3   : e.target.value
        })
        //console.log(this.state.addedparagraph3)
    }

    handleQuotedStatementCheck=(e)=>{
        this.setState({
            quotedStatementCheck:true
        })
    }

    handleChangeQuotedStatement=(e)=>{
        this.setState({
            quotedStatement   : e.target.value
        })
        //console.log(this.state.quotedStatement)
    }

    handleOnChangePreparedBy=(e)=>{
        this.setState({
            preparedBy   : e.target.value
        })
        //console.log(this.state.preparedBy)
    }

    handleOnChangeNotedBy=(e)=>{
        this.setState({
            notedBy   : e.target.value
        })
        //console.log(this.state.notedBy)
    }

    handleOnChangePrepareByPosition=(e)=>{
        this.setState({
            prepareByPosition   : e.target.value
        })
        //console.log(this.state.prepareByPosition)
    }

    handleOnChangeReceivedBy=(e)=>{
        this.setState({
            receivedBy   : e.target.value
        })
        //console.log(this.state.receivedBy)
    }

    handleOnChangeIrDateIssued=(e)=>{
        this.setState({
            irDateIssued   : e.target.value
        })
        console.log(this.state.irDateIssued)
    }

    handleOnChangeDateRecieveByER=(e)=>{
        this.setState({
            dateRecieveByER   : e.target.value
        })
        console.log(this.state.dateRecieveByER)
    }

    handleOnChangeReferenceNoNTE=(e)=>{
        this.setState({
            referenceNoNTE   : e.target.value
        })
        console.log(this.state.referenceNoNTE)
    }

    getViewDraftMemo=()=>{
        let viewdataCOCRef= JSON.parse(sessionStorage.getItem("viewdataCOCRefTmp"))

        if(viewdataCOCRef == "1" || viewdataCOCRef === "1" ){
            let dataCOCRefTmp = JSON.parse(sessionStorage.getItem("dataCOCRef"))

            this.setState({
                articleNo       :   dataCOCRefTmp.articleNo,
                articleName     :   dataCOCRefTmp.articleName,
                sectionNo       :   dataCOCRefTmp.sectionNo,
                infraction      :   dataCOCRefTmp.infraction,
                category        :   dataCOCRefTmp.category,
                articleNo2      :   dataCOCRefTmp.articleNo2,
                articleName2    :   dataCOCRefTmp.articleName2,
                sectionNo2      :   dataCOCRefTmp.sectionNo2,
                infraction2     :   dataCOCRefTmp.infraction2,
                category2       :   dataCOCRefTmp.category2,
                sectionNo3      :   dataCOCRefTmp.sectionNo3,
                infraction3     :   dataCOCRefTmp.infraction3,
                category3       :   dataCOCRefTmp.category3,

                cocReferences: true
            })

        }else{

            this.setState({
                cocReferences: false
            })

        }
    }

    handleToExportNTE=()=>{
       
        let createddate = moment(this.state.createddate).format('MM/DD/YYYY');
        let data = {
            "client"                :   this.state.selectedClientName,
            "employeeName"          :   this.state.selectedEmployeeName,
            "position"              :   this.state.position,
            "employeeNo"            :   this.state.selectedEmployeeNo,
            "location"              :   this.state.selectedEmployeeBranch,
            "date"                  :   this.state.dateApplied,
            "referenceNoNTE"        :   this.state.referenceNoNTE,
            "irReferenceNo"         :   this.state.irReferenceNo,
            "irDateIssued"          :   this.state.irDateIssued,
            "dateRecieveByER"       :   this.state.dateRecieveByER,
            "paragraph"             :   this.state.paragraph,
            "addedparagraph1"       :   this.state.addedparagraph1,
            "addedparagraph2"       :   this.state.addedparagraph2,
            "addedparagraph3"       :   this.state.addedparagraph3,
            "quotedStatement"       :   this.state.quotedStatement,
            "articleNo"             :   this.state.articleNo,
            "articleName"           :   this.state.articleName,
            "sectionNo"             :   this.state.sectionNo,
            "infraction"            :   this.state.infraction,
            "category"              :   this.state.category,

            "articleNo2"             :   this.state.articleNo2,
            "articleName2"           :   this.state.articleName2,
            "sectionNo2"             :   this.state.sectionNo2,
            "infraction2"            :   this.state.infraction2,
            "category2"              :   this.state.category2,

            "sectionNo3"             :   this.state.sectionNo3,
            "infraction3"            :   this.state.infraction3,
            "category3"              :   this.state.category3,

            "preparedBy"            :   this.state.preparedBy,
            "prepareByPosition"     :   this.state.prepareByPosition,
            "notedBy"               :   this.state.notedBy,
            "receivedBy"            :   this.state.receivedBy,



            "isDraft"               :   "0",
            "isDeleted"             :   "0",
            "createdby"             :   "user007",
            "createddate"           :   createddate,
            "modifiedby"            :   'user007',
            "modifieddate"          :   '0',
            "isModified"            :   '0',    
        }

        console.log(data)

        sessionStorage.setItem("data", JSON.stringify(data))
        let viewDraftMemoTmp = "1"
        sessionStorage.setItem("viewDraftMemoTmp", JSON.stringify(viewDraftMemoTmp))

    }

    render() {        
    return(
            <div>
                <Banner />
                    <Container className="mt-5" fluid>
                        <Card>
                            <Card.Header>Notice To Explain (NTE)</Card.Header>
                            <Card.Body>
                                <Card.Header className="mt-2">Employee Details</Card.Header>
                                <Form>
                                    <Alert isOpen={this.state.isshow} color={this.state.color} fade={this.state.fade} className={this.state.isshow ? 'display-block' : 'display-none'}>
                                        <div className={this.state.color}></div> 
                                        {this.state.message}
                                    </Alert>
                                    <Form.Group as={Row} controlId="formPlaintextEmail" className="mt-3">
                                        <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                            Client
                                        </Form.Label>
                                        <Col sm="11">
                                            <Typeahead
                                                labelKey='name'
                                                id="basic-example"
                                                onChange={this.onChangeClientList}
                                                options={this.state.getClientList}
                                                placeholder="Select Client"
                                            />
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} controlId="formPlaintextEmail">
                                        <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                            Employee
                                        </Form.Label>
                                        <Col sm="7">
                                            <Typeahead
                                                labelKey='employeeName'
                                                id="basic-example"
                                                onChange={this.onChangeEmployeesList}
                                                options={this.state.getEmployeeList}
                                                placeholder="Select Employee"
                                            />
                                        </Col>

                                        <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                            Employee No.
                                        </Form.Label>
                                        <Col sm="3">
                                            <Typeahead
                                                labelKey='name'
                                                id="basic-example"
                                                onChange={this.onChangeTraining}
                                                options={this.state.trainingAutocomplete}
                                            />
                                        </Col>
                                    </Form.Group>

                                    <Form.Group as={Row} controlId="formPlaintextEmail">
                                        <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                            Position
                                        </Form.Label>
                                        <Col sm="4">
                                            <Form.Control 
                                                type="text"
                                                name="selectedEmployeeName" 
                                                value={this.state.selectedPosition}
                                                onChange={this.onChangePosition} 
                                                autoComplete="off"
                                                placeholder="Autofill"
                                            />
                                        </Col>
                                        <Col sm="2"></Col>
                                        <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                        Location
                                        </Form.Label>
                                        <Col sm="4">
                                            <Form.Control 
                                                type="text"
                                                name="selectedEmployeeName"
                                                value={this.state.selectedEmployeeBranch}
                                                onChange={this.onChangeLocation} 
                                                autoComplete="off"
                                                placeholder="Auto Fill"
                                            />
                                        </Col>
                                    </Form.Group>

                                    <Form.Group as={Row}>
                                        <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                            Date
                                        </Form.Label>
                                        <Col  style={{marginLeft : "-1px"}}>
                                             <DatePicker
                                                ref='dateApplied'
                                                selected={this.state.dateApplied}
                                                onChange={this.handleChangeDate}
                                                minDate={this.minDate}
                                                value={this.props.dateApplied}
                                                dateFormat={"MM/dd/yyyy"}
                                                /* placeholderText="DATE APPLIED" */
                                                className="form-control"
                                            />
                                        </Col>


                                        <Form.Label row sm="2" style={{fontWeight : "bold", marginLeft : "-1265px"}}>
                                            NTE Reference No.
                                        </Form.Label>
                                        
                                        <Col sm="2" style={{marginLeft : "73px"}}>

                                            <Input
                                                labelKey='name'
                                                id="basic-example"
                                                //value={this.state.dateReported}
                                                className="form-control"
                                                onChange={this.handleOnChangeReferenceNoNTE}
                                                autoComplete="off"
                                                
                                            />

                                        </Col>

                                    </Form.Group>

                                    <Form.Group as={Row} controlId="formPlaintextEmail">
                                        <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                            IR Reference No.
                                        </Form.Label>
                                        <Col sm="2" style={{marginLeft : "-100px"}}>
                                            <Input
                                                labelKey='name'
                                                id="basic-example"
                                                className="form-control"
                                                onChange={this.onChangeTraining}
                                                autoComplete="off"
                                                
                                            />
                                        </Col>

                                    </Form.Group>

                                   {/*  <Form.Group as={Row} controlId="formPlaintextEmail">
                                        <Button variant="success"  style={{minWidth:'80px',  marginLeft : "50px" }} onClick={this.handleUpdateClick}>
                                           Attached IR
                                        </Button>
                                    </Form.Group> */}
                                    
                                    <Form.Group as={Row} controlId="formPlaintextEmail"> </Form.Group>
                                    
                                    <Card.Header className="mt-3">Memorandum Details</Card.Header>
                                    
                                    <div className="mt-1">

                                        <Form.Group as={Row} controlId="formPlaintextEmail" className="mt-4">
                                            <Col>
                                                <FormControl as="textarea" aria-label="With textarea" placeHolder="(Enter Paragraph)" onChange={this.handleChangeParagraph} rows="3"/>
                                                
                                            </Col>                    
                                        </Form.Group>

                                        { this.state.quotedStatementCheck ?
                                            <Form.Group as={Row} controlId="formPlaintextEmail" className="mt-4">
                                                <Col>
                                                    <FormControl as="textarea" aria-label="With textarea" placeHolder="(Enter Quoted Statement)" onChange={this.handleChangeQuotedStatement} style={{fontWeight : "bold"}}/>                                                
                                                </Col>                    
                                            </Form.Group>:null
                                        }
                                        { this.state.addedparagraph1Check ?
                                            <Form.Group as={Row} controlId="formPlaintextEmail" className="mt-4">
                                                <Col>
                                                    <FormControl as="textarea" aria-label="With textarea" placeHolder="(Enter Paragraph)" onChange={this.handleChangeAddedParagraph1}/>                                                
                                                </Col>                    
                                            </Form.Group>:null
                                        }
                                        { this.state.cocReferences ?
                                            <Form.Group as={Row} controlId="formPlaintextEmail" className="mt-4">
                                                <Form.Label column sm="1" style={{fontWeight : "bold" , fontSize:15}}>
                                                    COC DETAILS
                                                </Form.Label> 
                                            </Form.Group>:null
                                        } 
                                        { this.state.cocReferences ?
                                            <Form.Group as={Row} controlId="formPlaintextEmail" className="mt-4">
                                                <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                                   Article No
                                                </Form.Label>
                                                <Col sm="3">
                                                    <Input
                                                        type="text"
                                                        name="name" 
                                                        className="form-control"
                                                        value={this.state.articleNo}
                                                        autoComplete="off"
                                                    />                                               
                                                </Col>
                                               
                                                <Col sm="3">
                                                    <Input
                                                        type="text"
                                                        name="name" 
                                                        className="form-control"
                                                        value={this.state.articleName}
                                                        autoComplete="off"
                                                    />                                               
                                                </Col>  

                                            </Form.Group>:null
                                        } 
                                        { this.state.cocReferences ?
                                            <Form.Group as={Row} controlId="formPlaintextEmail" className="mt-4">
                                                <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                                   Article No
                                                </Form.Label>
                                                <Col sm="3">
                                                    <Input
                                                        type="text"
                                                        name="name" 
                                                        className="form-control"
                                                        value={this.state.articleNo2}
                                                        autoComplete="off"
                                                    />                                               
                                                </Col>
                                               
                                                <Col sm="3">
                                                    <Input
                                                        type="text"
                                                        name="name" 
                                                        className="form-control"
                                                        value={this.state.articleName2}
                                                        autoComplete="off"
                                                    />                                               
                                                </Col>  

                                            </Form.Group>:null
                                        } 
                                        { this.state.cocReferences ?
                                            <Form.Group as={Row} controlId="formPlaintextEmail" className="mt-4">
                                                <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                                   Section
                                                </Form.Label>
                                                <Col sm="3">
                                                    <Input
                                                        type="text"
                                                        name="name" 
                                                        className="form-control"
                                                        value={this.state.sectionNo}
                                                        autoComplete="off"
                                                    />                                               
                                                </Col>
                                               
                                                <Col sm="3">
                                                    <Input
                                                        type="text"
                                                        name="name" 
                                                        className="form-control"
                                                        value={this.state.infraction}
                                                        autoComplete="off"
                                                    />                                               
                                                </Col> 
                                                <Col sm="3">
                                                    <Input
                                                        type="text"
                                                        name="name" 
                                                        className="form-control"
                                                        value={this.state.category}
                                                        autoComplete="off"
                                                    />                                               
                                                </Col>   

                                            </Form.Group>:null
                                        }
                                        { this.state.cocReferences ?
                                            <Form.Group as={Row} controlId="formPlaintextEmail" className="mt-4">
                                                <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                                   Section
                                                </Form.Label>
                                                <Col sm="3">
                                                    <Input
                                                        type="text"
                                                        name="name" 
                                                        className="form-control"
                                                        value={this.state.sectionNo2}
                                                        autoComplete="off"
                                                    />                                               
                                                </Col>
                                               
                                                <Col sm="3">
                                                    <Input
                                                        type="text"
                                                        name="name" 
                                                        className="form-control"
                                                        value={this.state.infraction2}
                                                        autoComplete="off"
                                                    />                                               
                                                </Col> 
                                                <Col sm="3">
                                                    <Input
                                                        type="text"
                                                        name="name" 
                                                        className="form-control"
                                                        value={this.state.category2}
                                                        autoComplete="off"
                                                    />                                               
                                                </Col>   

                                            </Form.Group>:null
                                        }
                                        { this.state.cocReferences ?
                                            <Form.Group as={Row} controlId="formPlaintextEmail" className="mt-4">
                                                <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                                   Section
                                                </Form.Label>
                                                <Col sm="3">
                                                    <Input
                                                        type="text"
                                                        name="name" 
                                                        className="form-control"
                                                        value={this.state.sectionNo3}
                                                        autoComplete="off"
                                                    />                                               
                                                </Col>
                                               
                                                <Col sm="3">
                                                    <Input
                                                        type="text"
                                                        name="name" 
                                                        className="form-control"
                                                        value={this.state.infraction3}
                                                        autoComplete="off"
                                                    />                                               
                                                </Col> 
                                                <Col sm="3">
                                                    <Input
                                                        type="text"
                                                        name="name" 
                                                        className="form-control"
                                                        value={this.state.category3}
                                                        autoComplete="off"
                                                    />                                               
                                                </Col>   

                                            </Form.Group>:null
                                        }
                                        
                                        { this.state.addedparagraph2Check ?
                                            <Form.Group as={Row} controlId="formPlaintextEmail" className="mt-4">
                                                <Col>
                                                    <FormControl as="textarea" aria-label="With textarea" placeHolder="(Enter Paragraph)" onChange={this.handleChangeAddedParagraph2}/>                                                
                                                </Col>                    
                                            </Form.Group>:null
                                        }  


                                        {/* <Form.Group as={Row} controlId="formPlaintextEmail" className="mt-4">                                       
                                            <Col>
                                            
                                                {this.createUI()}  
                                            </Col>                                        
                                        </Form.Group>   */}                               
                                   
                                    </div>
                                    <ButtonToolbar sm={12} className="mt-4">
                                        <Col>                                            
                                            <Button variant="success" style={{minWidth:'280px'}} onClick={this.handleAddedParagraph1}>
                                                Add 2nd Paragraph
                                            </Button>                                            
                                        </Col>&nbsp;&nbsp;
                                        <Col>                                            
                                            <Button variant="success" style={{minWidth:'280px'}} onClick={this.handleAddedParagraph2}>
                                                Add 3nd Paragraph
                                            </Button>                                            
                                        </Col>&nbsp;&nbsp;
                                        <Col>                                           
                                            <Button variant="success" style={{minWidth:'280px'}} onClick={this.handleQuotedStatementCheck}>
                                                Insert QuotedStatement
                                            </Button>                                           
                                        </Col>
                                        <Col>                                        
                                            <Button variant="success" style={{minWidth:'280px'}} onClick={this.showTrainingRegisterModal}>
                                                Insert COC Reference
                                            </Button>                                           
                                        </Col>
                                       {/*  <Col>
                                            <NavLink to="/home">
                                                <Button variant="success" href="/home" style={{minWidth:'280px'}}>
                                                    View Draft Memo
                                                </Button>
                                            </NavLink>
                                        </Col> */}
                                    </ButtonToolbar>

                                    <Form.Group as={Row} controlId="formPlaintextEmail" className="mt-4">
                                        <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                            Prepared By:
                                        </Form.Label>
                                        <Col sm="3">
                                            <Input
                                                type="text"
                                                name="name" 
                                                className="form-control"
                                                value={this.state.preparedBy}
                                                onChange={this.handleOnChangePreparedBy} 
                                                autoComplete="off"
                                            />
                                        </Col>

                                        <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                            Noted By:
                                        </Form.Label>
                                        <Col sm="3">
                                            <Input 
                                                type="text"
                                                name="name"
                                                value={this.state.notedBy}
                                                onChange={this.handleOnChangeNotedBy} 
                                                autoComplete="off"
                                                //placeholder="Enter Company"
                                            />
                                        </Col>

                                        <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                            Position
                                        </Form.Label>
                                        <Col sm="3">
                                            <Form.Control 
                                                type="text"
                                                name="name"
                                                value={this.state.prepareByPosition}
                                                onChange={this.handleOnChangePrepareByPosition} 
                                                autoComplete="off"
                                                //placeholder="Enter Company"
                                            />
                                        </Col>
                                    </Form.Group>

                                   {/*  <Form.Group as={Row} controlId="formPlaintextEmail" className="mt-4">
                                        <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                            Received By:
                                        </Form.Label>
                                        <Col sm="5">
                                            <Form.Control 
                                                type="text"
                                                name="name"
                                                value={this.state.receivedBy}
                                                onChange={this.handleOnChangeReceivedBy} 
                                                autoComplete="off"
                                                //placeholder="Enter Company"
                                            />
                                        </Col>
                                    </Form.Group> */}

                                    <Form.Group as={Row} controlId="formPlaintextEmail" className="mt-4">
                                        <Col  sm={12}>
                                            <ButtonToolbar className="mt-5">
                                                <Button /* className="ml-auto" */ variant="success" onClick = { this.handleSaveClick } style={{minWidth:'60px'}}>Save</Button>&nbsp;&nbsp;&nbsp;
                                                <Button  href="/trainingschedule" variant="danger" style={{minWidth:'60px'}}>Back</Button>
                                                <NavLink to="/ExportNTE"  className="ml-auto">
                                                    <Button variant="success" onClick={this.handleToExportNTE} href="/ExportNTE" style={{minHeight:"18px",minWidth:'60px', marginLeft:"1200px", marginTop:"-1px"}}>Generate NTE Memo</Button>&nbsp;&nbsp;
                                                </NavLink>
                                            </ButtonToolbar>                                          
                                        </Col>                
                                    </Form.Group>
                                </Form>
                            </Card.Body>
                        </Card>
                       
                    </Container>
                    <NoserLoading show={this.state.isloading} />

                    <InsertCOCReference 
                        show={this.state.modalCoCReferenceShow}
                        onHide={this.handleModalClose}
                    />
            </div> 
        )
    }

}

export  default NoticeToExplain
