import {
    React,Component,BootstrapTable,
    paginationFactory,Button, ButtonToolbar, Card, 
    Form, Col, Row, axios, Typeahead, TimePicker,
    cellEditFactory ,moment, NavLink, Alert, AppConfiguration,
    NoserLoading, Noser, Banner, Container, DatePicker,  Tabs, Tab,
    sizePerPageRenderer,Type
} 
from '../../noser-hris-component';

import ToolkitProvider, { CSVExport } from 'react-bootstrap-table2-toolkit';
import 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css';
import { GetRequest, PostRequest } from '../../noser-dataaccess'

class DeductionSummary extends Component {
    constructor(props) {
        super(props)
        this.state = {
            userinfo        :   [],
            //loadingcaption : "",
            isloading       :   false,
            isshow          :   false,
            alerttype       :   "",
            message         :   "",
            color           :   "",
            fade            :   true,

            clientId        :   "",
            clientName      :   "",
            employeeId      :   "",
            employeeName    :   "",
            payperiodId     :   "",
            payperiod       :   "",
            payrollDate     :   "",
            
            clientDDL       :   [],
            periodDDL       :   [],
            employeeDDL     :   [],
            deductionGRID   :   [],
            yesnoDDL        :   [
                {
                    "value":"0",
                    "label":"YES"
                },
                {
                    "value":"1",
                    "label":"NO"
                }
            ],
            postedDDL      :   [
                {
                    "value":"1",
                    "label":"YES"
                },
                {
                    "value":"0",
                    "label":"NO"
                }
            ],
            proceedAmount : "0",
            notproceedAmount: "0",
            postedAmount : "0",
            notpostedAmount: "0",
            hasPosted:"0",
            isAre:"0",
        }
    }

    componentDidMount(){
        this.state.userinfo = JSON.parse(sessionStorage.getItem("userData"))
        this.GetClients()        
    }
    GetClients = async()=> {
        this.setState({isloading:true,})
        const clientParams = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.userinfo.clientId,
            "UserId":this.state.userinfo.userId,
       }
       await axios
           .post(
               AppConfiguration.Setting().noserapiendpoint + "Client/GetClientList", clientParams
           )
           .then(res => {
               const data = res.data;
               this.setState({clientDDL: data.clients, isloading:false})
           })
           .catch(error=>{
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade        :   true
            })
        })
    }
    GetEmployeeList = async() =>{
        this.setState({isloading:true})
        
        const params = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.clientId,
            "UserId":this.state.userinfo.userId,
        }
        await axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Employee/GetEmployeeProfileFilter", params)
        .then(res => {
            const data = res.data;
            this.setState({employeeDDL : data.profiles.filter(x=>x.status==="ACTIVE"),isloading:false})
        })
        .catch(error=>{
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade        :   true
            })
        })
    }
    GetPayPeriodList = async(isSpecial) => {
        this.setState({isloading:true})
        let payrollperiods = []
        const params = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.clientId,
            "UserId":this.state.userinfo.userId,
            "IsProcessed": "1"
         };
         let api = ""
         if(isSpecial==="0"){
            api = "Timekeeping/GetPayrollPeriods"
         }
         else{
            //api = "Timekeeping/GetSpecialPayrollPeriods"
            api = "SpecialClientConfiguration/GetClientPayrollPeriods"
         }
         ////console.log(params)
        await axios
            .post(
                AppConfiguration.Setting().noserapiendpoint + api,  params
            )
            .then(res => {
                const data = res.data;
                this.setState({isloading:false})
                if(isSpecial==="0"){
                    data.payrollPeriods.map(function(itm){
                        payrollperiods.push(
                            {
                                "typeId":itm.typeId,
                                "periodId":itm.periodId,
                                "payPeriod":itm.payPeriod,
                                "startDate":itm.startDate,
                                "endDate":itm.endDate
                            }
                        )
                    })
                }
                else{
                    data.payrollPeriods.forEach(itm=>{
                        payrollperiods.push({
                            "periodId":itm.id,
                            "payPeriod":itm.payPeriod,
                            "startDate":itm.startDate,
                            "endDate":itm.endDate,
                            "isProcessed":"0",
                            "typeId":"0",
                        })
                    })
                }
            })
            .catch(error=>{
                this.setState({
                    isloading   :   false,
                    alerttype   :   "Error!",
                    isshow      :   true,
                    color       :   "danger",
                    message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                    fade        :   true
                })
            })

        /*PROCESSED PAYROLL*/
        const params1 = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.clientId,
            "UserId":this.state.userinfo.userId,
            "EmployeeId": this.state.employeeId
         };
         
         if(isSpecial==="0"){
            api = "Timekeeping/GetPayrollPeriods"
         }
         else{
            api = "Timekeeping/GetSpecialPayrollPeriods"
         }
         ////console.log(params)
        await axios
            .post(
                AppConfiguration.Setting().noserapiendpoint + api,  params1
            )
            .then(res => {
                const data1 = res.data;
                //this.setState({periodDDL: data.payrollPeriods })
                data1.payrollPeriods.map(function(itm){
                    payrollperiods.push(
                        {
                            "typeId":itm.typeId,
                            "periodId":itm.periodId,
                            "payPeriod":itm.payPeriod,
                            "startDate":itm.startDate,
                            "endDate":itm.endDate
                        }
                    )
                })
            })
            .catch(error=>{
                this.setState({
                    isloading   :   false,
                    alerttype   :   "Error!",
                    isshow      :   true,
                    color       :   "danger",
                    message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                    fade        :   true
                })
            })
        ////console.log(payrollperiods)  
        const key = 'payPeriod';

        const arrayUniqueByKey = [...new Map(payrollperiods.map(item =>
        [item[key], item])).values()];
        this.setState({periodDDL: arrayUniqueByKey })  
    }
    handleChangedClient = (e) =>{
        this.state.alerttype="";this.state.isshow=false;this.state.color="";this.state.message="";this.state.fade=true;this.state.isloading=false
        if(e.length===0) {
            this.state.clientId = ""
            this.state.clientName = ""
            this.state.employeeDDL=[]
            return
        }
        this.state.clientId = e[0].id
        this.state.clientName = e[0].name
        this.GetPayPeriodList(e[0].isSpecial)
    }
    handleChangedEmployee = (e) =>{
        this.state.alerttype="";this.state.isshow=false;this.state.color="";this.state.message="";this.state.fade=true;this.state.isloading=false
        if(e.length===0) {
            this.state.employeeId = ""
            this.state.employeeName=[]
            return
        }
        this.state.employeeId = e[0].id
    }
    handleChangedPeriod = (e) =>{
        this.state.alerttype="";this.state.isshow=false;this.state.color="";this.state.message="";this.state.fade=true;this.state.isloading=false
        if(e.length===0) {
            this.state.payperiodId = ""
            this.state.payperiod= ""
            this.state.payrollDate= ""
            return
        }
        this.state.payperiodId = e[0].periodId
        this.state.payrollDate = e[0].endDate
        this.state.payperiod = e[0].payPeriod
        
    }
    handleSearchClick = async()=>{
        this.setState({isloading:true,})
        if(this.state.clientId===""){
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "Please select client.",
                fade        :   true
            })
            return 
        }
        if(this.state.payperiodId===""){
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "Please select cut-off.",
                fade        :   true
            })
            return 
        }
        const params = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.clientId,
            "UserId":this.state.userinfo.userId,
            "PayPeriodId":this.state.payperiodId,
            //"IsPosted":"1"
        }
        //console.log(params)
        await axios
        .post(AppConfiguration.Setting().noserapiendpoint + "PayrollConfiguration/GetDeductionSummary", params)
        .then(res => {
            const data = res.data;
            if(data.status==="0"){
                this.setState({
                    isloading   :   false,
                    alerttype   :   "Error!",
                    isshow      :   true,
                    color       :   "danger",
                    message     :   data.message,
                    fade        :   true
                })
                return
            }
            let hasposted = data.deductionSummaries.filter(x=> x.isPosted==="1").length
            let proceed = data.deductionSummaries.filter(x=>x.isDeleted==="0").reduce((a,b) => a = parseFloat(a) + parseFloat(b.payrollAmount), 0).toFixed(2)
            let notproceed = data.deductionSummaries.filter(x=>x.isDeleted==="1").reduce((a,b) => a = parseFloat(a) + parseFloat(b.payrollAmount), 0).toFixed(2)
            let posted = data.deductionSummaries.filter(x=>x.isPosted==="1").reduce((a,b) => a = parseFloat(a) + parseFloat(b.payrollAmount), 0).toFixed(2)
            let notposted = data.deductionSummaries.filter(x=>x.isPosted==="0").reduce((a,b) => a = parseFloat(a) + parseFloat(b.payrollAmount), 0).toFixed(2)

            this.setState({deductionGRID : data.deductionSummaries,
                            proceedAmount: this.FormatCommas(proceed),
                            notproceedAmount:this.FormatCommas(notproceed),
                            postedAmount:this.FormatCommas(posted),
                            notpostedAmount: hasposted>0 ? this.FormatCommas(notposted) : "0.00",
                            hasPosted:hasposted,
                            isAre:"0",
                            isloading:false})
        })
        .catch(error=>{
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade        :   true
            })
        })
    }
    isNumber =(value) => {
        return typeof value === 'number';
      }
    handleGetLatest = async() => {
        this.setState({isloading:true,})
        if(this.state.clientId===""){
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "Please select client.",
                fade        :   true
            })
            return 
        }
        if(this.state.payperiodId===""){
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "Please select cut-off.",
                fade        :   true
            })
            return 
        }

        let filter_data = {"isDeleted": "0", "isPosted": "0","hrisId":"1","clientId": this.state.clientId, "payrollDate" : this.state.payrollDate}
        console.log(filter_data)
        let resp = await GetRequest(
                {
                    url : AppConfiguration.Setting().noserareapi + "action/get",
                    params : {
                            "dbname":AppConfiguration.Setting().noseraredb,
                            "_collection" : "DeductionSummaryDetails",
                            "filter_data" : filter_data
                            }    
                })
        console.log("deduction summary")
        console.log(resp) 
        if(resp.status==="1"){
            console.log(resp.DeductionSummaryDetails)
            
            let deductionGRID = resp.DeductionSummaryDetails.filter(x=>x.client===this.state.clientName && x.payrollDate===this.state.payrollDate)
            // try
            // {
                
                deductionGRID.map(function(itm,idx){
                    // if(!isNaN(parseFloat(itm.payrollAmount))){
                    //     this.setState({
                    //         isloading   :   false,
                    //         alerttype   :   "Error!",
                    //         isshow      :   true,
                    //         color       :   "danger",
                    //         message     :    "Invalid payroll amount. Employee Name : " + itm.employeeName + " Deduction Name : " + itm.deductionAmount,
                    //         fade        :   true
                    //     })
                    //     return
                    // }
                    // if(!isNaN(parseFloat(itm.amortization))){
                    //     this.setState({
                    //         isloading   :   false,
                    //         alerttype   :   "Error!",
                    //         isshow      :   true,
                    //         color       :   "danger",
                    //         message     :    "Invalid amortization amount. Employee Name : " + itm.employeeName + " Deduction Name : " + itm.deductionAmount,
                    //         fade        :   true
                    //     })
                    //     return
                    // }
                    itm.payrollAmount = itm.deductionType==="LOANS" ? itm.amortization : itm.payrollAmount
                    itm.payrollAmount = !isNaN(+itm.payrollAmount) ? itm.payrollAmount.toString() : itm.payrollAmount
                    itm.deductionAmount = itm.payrollAmount
                    itm.numberOfDeduction = !isNaN(+itm.numberOfDeduction) ? itm.numberOfDeduction.toString() : itm.numberOfDeduction
                    itm.isModified = itm.isModified==="" ? "0" : itm.isModified
                    itm.payrollRemarks = ""
                    itm.isEdited = "1"
                })
            // }
            // catch(error){
            //     this.setState({
            //         isloading   :   false,
            //         alerttype   :   "Error!",
            //         isshow      :   true,
            //         color       :   "danger",
            //         message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
            //         fade        :   true
            //     })
            //     return
            // }
            
            console.log("deductionGRID")
            console.log(deductionGRID)
            let hasposted = deductionGRID.filter(x=> x.isPosted==="1").length
            let proceed = deductionGRID.filter(x=>x.isDeleted==="0").reduce((a,b) => a = parseFloat(a) + parseFloat(b.payrollAmount), 0).toFixed(2)
            let notproceed = deductionGRID.filter(x=>x.isDeleted==="1").reduce((a,b) => a = parseFloat(a) + parseFloat(b.payrollAmount), 0).toFixed(2)
            let posted = deductionGRID.filter(x=>x.isPosted==="1").reduce((a,b) => a = parseFloat(a) + parseFloat(b.payrollAmount), 0).toFixed(2)
            let notposted = deductionGRID.filter(x=>x.isPosted==="0").reduce((a,b) => a = parseFloat(a) + parseFloat(b.payrollAmount), 0).toFixed(2)

            this.setState({deductionGRID : deductionGRID,
                            proceedAmount: this.FormatCommas(proceed),
                            notproceedAmount:this.FormatCommas(notproceed),
                            postedAmount:this.FormatCommas(posted),
                            notpostedAmount:hasposted > 0 ? this.FormatCommas(notposted) : "0.00",
                            hasPosted:hasposted,
                            isAre:"1",
                            isloading:false})

            if(deductionGRID.length==0){
                this.setState({
                    isloading   :   false,
                    alerttype   :   "Information!",
                    isshow      :   true,
                    color       :   "info",
                    message     :   "No record found in AR E for the payroll period of " + this.state.payperiod,
                    fade        :   true
                })
            }
            else{
                let _filterfields = {"clientId" : this.state.clientId, "collectionDate" : deductionGRID[0]["collectionDate"], "payrollDate" : deductionGRID[0]["payrollDate"]}
                let _updateFields = {"isSentToHRIS" : "1", "isSentToHRISDate" : moment(new Date()).format('MM/DD/YYYY')}                //after pull-out by hris    change date to actual date
                //let _updateFields = {"isPayrollProcessed" : "1", "isPayrollProcessedDate" : "2/15/2022"}  //after payroll is finalized change date to actual date

                const updateParams = {
                    "dbname"        :AppConfiguration.Setting().noseraredb,
                    "_collection"   : "DeductionSummaryHeader",
                    "filterfields"  : _filterfields,
                    "updateFields"  : _updateFields,
                }
            
            axios
                .post(AppConfiguration.Setting().noserareapi + "action/update_fields",  updateParams )
                .then(res => {
                    console.log("Updated!")
                })
                .catch(error =>{
                    console.log("error on add subledger :" + error)
                })
            }
        }
        else{
            this.setState({
                isloading   :   false,
                alerttype   :   "Information!",
                isshow      :   true,
                color       :   "info",
                message     :   "No record found in AR E for the payroll period of " + this.state.payperiod,
                fade        :   true
            })
        }
        this.setState({isloading:false})
    }
    handleSaveClick = async()=>{
        this.setState({isloading:true,})
        if(this.state.clientId===""){
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "Please select client.",
                fade        :   true
            })
            return 
        }
        if(this.state.payperiodId===""){
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "Please select cut-off.",
                fade        :   true
            })
            return 
        }
        if(this.state.deductionGRID.length===0){
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "No deduction summary to save.",
                fade        :   true
            })
            return 
        }

	if(this.state.deductionGRID.filter(x=>x.isDeleted==="1" && x.payrollRemarks==="").length>0){
            let noremarks = this.state.deductionGRID.filter(x=>x.isDeleted==="1" && x.payrollRemarks==="")
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "Please enter remarks for employee " + noremarks[0].employeeName + " deduction name " + noremarks[0].deductionName,
                fade        :   true
            })
            return
        }

        const params = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.clientId,
            "UserId":this.state.userinfo.userId,
            "PayPeriodId":this.state.payperiodId,
            "deductionSummaries":this.state.deductionGRID.filter(x=>x.isPosted==="0")
        }
        //console.log(params)
        await axios
        .post(AppConfiguration.Setting().noserapiendpoint + "PayrollConfiguration/SaveDeductionSummary", params)
        .then(res => {
            const data = res.data;
            this.handleSearchClick()
            this.setState({
                isloading   :   false,
                alerttype   :   data.status==="1" ? "Success!" : "Error!",
                isshow      :   true,
                color       :   data.status==="1" ? "success" : "danger",
                message     :   data.message,
                fade        :   true
            })
        })
        .catch(error=>{
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade        :   true
            })
        })
    }
    GridDataModified(oldValue, newValue, rowid, column) {
        let isDisable = true
        this.state.deductionGRID.map(function(item,i){
	    item.isEdited = "1"
            if(item.id===rowid && item.isPosted==="1"){
                alert("Transaction is already posted")
                if(column==="payrollAmount"){
                    item.payrollAmount = oldValue
                }
                if(column==="payrollRemarks"){
                    item.payrollRemarks = oldValue
                }
                return
            }
            else{
                if(item.id===rowid){
                    item.isModified = "1"
                    //item.isEdited = "1"
                }
                if(item.isModified=="1"){
                    isDisable = false
                }
            }
            
        })
        this.setState({disableSaveBtn:isDisable})
    }
    IsDecimal(val)
    {
        var regex = /^\d*\.?(?:\d{1,2})?$/;
        return !regex.test(val)
    }
    FormatCommas(x) {
        x = x.toString();
        var pattern = /(-?\d+)(\d{3})/;
        while (pattern.test(x))
            x = x.replace(pattern, "$1,$2");
        return x;
    }
    FormatAmount(column) {
        console.log("dsdsdsd")
        console.log(column.payrollAmount)
        return (
          <strong>{ column.payrollAmount }</strong>
        );
      }
    render() {
        const { ExportCSVButton } = CSVExport;
        const deductionCol =[
            {
                dataField: 'employeeNo',
                text: 'EMPLOYEE NO',
                editable: false,
                sort: true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'8%' }},
                style:{textAlign:'center',width:'8%'},
                footer:''
            },
            {
                dataField: this.state.isAre==="1" ? 'employeeName' : 'memberName',
                text: 'EMPLOYEE NAME',
                editable: false,
                sort: true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'left',width:'15%' }},
                style:{textAlign:'left',width:'15%'},
                footer:''
            },
            {
                dataField: 'deductionName',
                text: 'DEDUCTION NAME',
                editable: false,
                sort: true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'left',width:'10%' }},
                style:{textAlign:'left',width:'10%'},
                footer:''
            },
            {
                dataField: 'payrollDate',
                text: 'PAYROLL DATE',
                editable: false,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'8%' }},
                style:{textAlign:'center',width:'8%'},
                footer:''
            },
            {
                dataField: 'collectionDate',
                text: 'COLLECTION DATE',
                editable: false,
                sort: true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'11%' }},
                style:{textAlign:'center',width:'11%'},
                footer:''
            },
            {
                dataField: 'payrollAmount',
                text: 'AMOUNT',
                editable: true,
                sort: true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'8%' }},
                style:{textAlign:'right',width:'8%'},
                validator: (newValue, row, column) => {
                    
                    if (this.IsDecimal(newValue)) {
                      return {
                        valid: false
                      };
                    }
                    if(newValue.split(".").length>2)
                    return {
                        valid: false
                      };
                    return true;
                },
                //footerFormatter: this.FormatAmount,
                footerAlign: (column, colIndex) => 'right',
                footer: columnData => columnData.reduce((acc, item) => parseFloat(parseFloat(acc) + parseFloat(item)).toFixed(2), 0)
            },
            {
                dataField: 'isDeleted',
                text: 'PROCESS',
                editable: true,
                sort: true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'8%' }},
                style:{textAlign:'center',width:'8%'},
                editor: {
                    type: Type.SELECT,
                    options: this.state.yesnoDDL
                  },
                formatter: (cell, row) => {
                    if(row.isDeleted!='' && row.isDeleted!=null){
                        return this.state.yesnoDDL.find(x => x.value == cell).label;
                    }
                },
                editable: {
                    noEditFormatter (value, row, index) {
                      return !row.isFinalized==="1"
                    }
                  },
                validator: (newValue, row, column) => {
                    if (row.isFinalized==="1") {
                      return {
                        valid: false,
                        message: 'Transaction is already finalized.'
                      };
                    }
                    return true;
                  },
                // editor: {
                //     type: Type.CHECKBOX,
                //     value: 'Y:N'
                // },
                // formatter: (cell, row, rowIndex) => {
                //     //     console.log(cell, row)
                //     return (
                //         <input type="checkbox" checked={cell==="1" ? false : true} />
                //     );
                // },
                footer:''
            },
            {
                dataField: 'isPosted',
                text: 'POSTED',
                editable: false,
                sort: true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'8%' }},
                style:{textAlign:'center',width:'8%'},
                editor: {
                    type: Type.SELECT,
                    options: this.state.postedDDL
                  },
                  formatter: (cell, row) => {
                      if(row.isPosted!='' && row.isPosted!=null){
                          return this.state.postedDDL.find(x => x.value == cell).label;
                      }
                  },
                  footer:''
            },
            {
                dataField: 'docNo',
                text: 'DOC NO',
                editable: false,
                sort: true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'8%' }},
                style:{textAlign:'center',width:'8%'},
                footer:''
            },
            {
                dataField: 'isVaraince',
                text: 'VARIANCE',
                editable: true,
                sort: true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',width:'10%' }},
                style:{textAlign:'center',width:'10%'},
                formatter: (cell, row) => {
                    if(row.isVaraince==='1'){
                        return 'YES';
                    }
                    else{
                        return 'NO';
                    }
                },
                footer:''
            },
            {
                dataField: 'payrollRemarks',
                text: 'REMARKS',
                editable: true,
                sort: true,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'left',width:'16%' }},
                style:{textAlign:'left',width:'16%'},
                footer:''
            },
            
        ]
        const selectRow = {
            mode: 'checkbox',
            hideSelectAll: true,
            clickToSelectAndEditCell: true,
            //clickToSelect: true,
            style:{backgroundColor:'yellow', color:'#000',cursor:'pointer'},
            onSelect: (row, isSelect, rowIndex, e) => {
                this.state.deductionGRID.map(function(itm,idx){
		    itm.isEdited = "1"
                    if(itm.id===row.id && itm.isPosted==="1"){
                        alert("Transaction is already posted")
                        itm.isDeleted = "0"
                        return
                    }
                    if(itm.id===row.id){
                        itm.isDeleted = isSelect ? "1" : "0"
                        //itm.isEdited = isSelect ? "1" : "0"
                    }
                })
            }
        };
        const rowClasses = (row, rowIndex) => {
        let classes = null;
        
        if (row.isPosted==="1") {
            classes = 'noser-table-row-class no-checkbox';
        }
        else{
            classes = 'noser-table-row-class';
        }
        return classes;
        };
        const rowStyle = (row, rowIndex) => {
            //POSTED
            if(row.isPosted==="1")
                return {color:'#0000FF'};
            //PROCEED
            else if(row.isPosted==="0" && row.isDeleted==="0" && this.state.hasPosted===0)
                return {color:'#006600'};
            //NOT POSTED
            else if(row.isPosted==="0" && row.isDeleted==="0" && this.state.hasPosted>0)
                return {color:'#FF00FF'};
            
            //NOT PROCEED
            else if(row.isDeleted==="1")
                return {color:'#FF0000'};
            
          };
        return(
            <div>
            <Banner />
            <Container className="themed-container" fluid={true}>
                <Card className="mt-5">
                    <Card.Header>PAYROLL >> DEDUCTION SUMMARY </Card.Header>
                    <Card.Body>
                        <Form>
                            <Alert isOpen={this.state.isshow} color={this.state.color} fade={this.state.fade} className={this.state.isshow ? 'display-block' : 'display-none'}>
                                <div className={this.state.color}></div> 
                                {this.state.message}
                            </Alert>
                            <Form.Group as={Row} controlId="formHorizontalEmail">
                                <Col sm={12}>
                                    <Typeahead
                                        labelKey='name'
                                        id="basic-example"
                                        onChange={this.handleChangedClient}
                                        options={this.state.clientDDL}
                                        placeholder="Select Client"
                                    />
                                </Col>
                            </Form.Group>
                            {/* <Form.Group as={Row} controlId="formHorizontalEmail">
                                <Col sm={12}>
                                    <Typeahead
                                        labelKey='employeeName'
                                        id="basic-example"
                                        onChange={this.handleChangedEmployee}
                                        options={this.state.employeeDDL}
                                        placeholder="Select Employee"
                                    />
                                </Col>
                            </Form.Group> */}
                            <Form.Group as={Row} controlId="formHorizontalEmail">
                                <Col sm={12}>
                                    <Typeahead
                                        labelKey='payPeriod'
                                        id="basic-example"
                                        onChange={this.handleChangedPeriod}
                                        options={this.state.periodDDL}
                                        placeholder="Select Cut-Off"
                                    />
                                </Col>
                            </Form.Group>
                            <ButtonToolbar className="mt-1">
                                <Button variant="primary" className="ml-auto noser-button" onClick={ this.handleSearchClick }>Search</Button>
                            </ButtonToolbar>
                            <Form.Group as={Row} controlId="formHorizontalEmail">
                                <Col sm={2}>
                                    <Form.Label style={{fontWeight : "bold"}}>
                                    TOTAL TRX : {this.FormatCommas(this.state.deductionGRID.reduce((a,b) => a = parseFloat(a) + parseFloat(b.payrollAmount), 0).toFixed(2))}
                                    </Form.Label>
                                </Col>
                                <Col sm={2}>
                                    <Form.Label style={{fontWeight : "bold"}}>
                                    TOTAL COUNT : {this.FormatCommas(this.state.deductionGRID.length)}
                                    </Form.Label>
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} controlId="formHorizontalEmail">
                                <Col sm={2}>
                                    <Form.Label style={{fontWeight : "bold",color:"#006600"}}>
                                    PROCEED : {this.state.proceedAmount}
                                    </Form.Label>
                                </Col>
                                <Col sm={2}>
                                    <Form.Label style={{fontWeight : "bold",color:"#FF0000"}}>
                                    NOT PROCEED : {this.state.notproceedAmount}
                                    </Form.Label>
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} controlId="formHorizontalEmail">
                                <Col sm={2}>
                                    <Form.Label style={{fontWeight : "bold",color:"#0000FF"}}>
                                    POSTED : {this.state.postedAmount}
                                    </Form.Label>
                                </Col>
                                <Col sm={2}>
                                    <Form.Label style={{fontWeight : "bold",color:"#FF00FF"}}>
                                    NOT POSTED : {this.state.notpostedAmount}
                                    </Form.Label>
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} controlId="formHorizontalEmail"  className="mt-2">
                                <Col sm={12}>
                                <Card.Header>DEDUCTION SUMMARY LIST <div style={{fontSize:"11px",fontWeight:"bold",float:"right",width:"200px",textAlign:"right"}}>TOTAL COUNT - {this.state.deductionGRID.length}</div>
                                </Card.Header>
                                <div>
                                    <ToolkitProvider
                                        keyField="id"   
                                        data = { this.state.deductionGRID }
                                        columns = { deductionCol }
                                        exportCSV={ {
                                            noAutoBOM: false,blobType: "data:text/csv;charset=utf-8,%EF%BB%BF",fileName: "DeductionSummary.csv",
                                        } }
                                        >
                                        {
                                            props => (
                                            <div>
                                                
                                                <BootstrapTable
                                                    keyField = "id"
                                                    data = { this.state.deductionGRID.sort((a, b) => a.employeeName.toLowerCase() > b.employeeName.toLowerCase() ? 1 : -1)}
                                                    columns = { deductionCol }
                                                    selectRow = { selectRow }
                                                    cellEdit = { cellEditFactory({ 
                                                            mode: 'dbclick', 
                                                            blurToSave: true,
                                                            afterSaveCell: (oldValue, newValue, row, column) => { 
                                                                this.GridDataModified(oldValue, newValue, row.id, column.dataField)
                                                            }
                                                        })
                                                    }
                                                    rowClasses="noser-table-row-class"
                                                    striped
                                                    hover
                                                    condensed
                                                    loading={true}
                                                    wrapperClasses="table-responsive"
                                                    noDataIndication={ () => <div style={{width:'100%'}}>No record found.</div> }
                                                    rowClasses={ rowClasses }
                                                    rowStyle={ rowStyle } 
                                                />
                                                <ButtonToolbar className="mt-1">
                                                    <Button variant="primary" className="noser-button" onClick={this.handleGetLatest}>Update From AR E</Button>
                                                    <ExportCSVButton style={{marginLeft:"2px"}} className="noser-button" disabled={this.state.deductionGRID.length>0 ? false : true} className="btn btn-success" { ...props.csvProps }>Export CSV</ExportCSVButton>
                                                    <Button variant="success" className="noser-button ml-auto" onClick={this.handleSaveClick}>SAVE</Button>
                                                </ButtonToolbar>
                                            </div>
                                            )
                                        }
                                    </ToolkitProvider>
                                </div>
                                </Col>
                            </Form.Group>
                        </Form>
                    </Card.Body>
                </Card>
            </Container>
            <NoserLoading show={this.state.isloading} />
            </div>
        )
    }
}
export  default DeductionSummary
