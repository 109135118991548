import {
    React,Component,BootstrapTable,
    paginationFactory,Button, ButtonToolbar, Card, 
    Form, Col, Row, axios, Container, Banner, Typeahead, TimePicker,
    cellEditFactory, Type, moment, NavLink, Alert, AppConfiguration,
    NoserLoading, Noser, sizePerPageRenderer, Modal, DatePicker, NumberFormat
} 
from '../../noser-hris-component';

class BatchScheduler
extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userinfo        :   [],
            isloading       :   false,
            isshow          :   false,
            alerttype       :   "",
            message         :   "",
            color           :   "",
            fade            :   true,      
            disable         :   true,
            
            employeeGRID    :   [],
            scheduleDate    :   "",
        }
    }

    componentDidMount(){
        //const sleep = (milliseconds) => {return new Promise(resolve => setTimeout(resolve, milliseconds))}
        this.state.userinfo = JSON.parse(sessionStorage.getItem("userData"))
        //this.props.onRefModal(this)
        this.GetBatchScheduledEmployees()
    }
    componentWillUnmount() {
        //this.props.onRefModal(undefined)
    }
    GetBatchScheduledEmployees = async() =>{
        this.setState({isloading:true})
        const params = {
            "IpAddress":"0.0.0.0",
            "UserId":this.state.userinfo.userId,
            "ClientId":this.state.userinfo.clientId,
            "ScheduleDate":this.state.scheduleDate
        }
        await axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Manning/GetBatchScheduledEmployees", params)
        .then(res => {
            const data = res.data;
            this.setState({employeeGRID : data.batchEmployees, isloading:false})
            
            if(data.status==="0"){
                this.setState({
                    isloading   :   false,
                    alerttype   :   "Error!",
                    isshow      :   true,
                    color       :   "danger",
                    message     :   data.message,
                    fade        :   true
                });
            }  
        })
        .catch(error=>{
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade        :   true
            })
        })
        
    }
    handleClickedProcess = async() =>{
        this.setState({isloading:true})
        const params = {
            "IpAddress":"0.0.0.0",
            "UserId":this.state.userinfo.userId,
            "ClientId":this.state.userinfo.clientId,
            "ScheduleDate":this.state.scheduleDate
        }
        await axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Manning/ProcessBatchScheduledEmployees", params)
        .then(res => {
            const data = res.data;
            this.setState({
                isloading   :   false,
                alerttype   :   data.status==="0" ? "Error!" : "Success!",
                isshow      :   true,
                color       :   data.status==="0" ? "danger" : "success",
                message     :   data.message,
                fade        :   true
            }); 
            if(data.status==="1"){
                this.GetBatchScheduledEmployees()
            }
        })
        .catch(error=>{
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade        :   true
            })
        })
        
    }
    handleChangedScheduleDate = date => {
        this.setState({scheduleDate: date});
    };
    render() { 
        const employeeCol = [
            {
                dataField: 'employeeNo',
                text: 'EMPLOYEE NO',
                editable: false,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'left',width:'15%'}},
                style:{textAlign:'left',width:'15%'}
            },
            {
                dataField: 'employeeName',
                text: 'EMPLOYEE NAME',
                editable: false,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'left',width:'25%'}},
                style:{textAlign:'left',width:'25%'}
            },
            {
                dataField: 'clientName',
                text: 'CLIENT',
                editable: false,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'left',width:'60%'}},
                style:{textAlign:'left',width:'60%'}
            }
        ]
        const selectRow = {
            mode: 'checkbox',
            hideSelectAll: true,
            clickToSelectAndEditCell: true,
            onSelect: (row, isSelect, rowIndex, e) => {
                
            }
        };
        return(
            <div>
            <Banner />
            <Container className="themed-container" fluid={true}>
                <Card className="mt-5">
                    <Card.Header>Manning >> Batch Scheduler</Card.Header>
                    <Card.Body>
                        <Form >
                            <Alert isOpen={this.state.isshow} color={this.state.color} fade={this.state.fade} className={this.state.isshow ? 'display-block' : 'display-none'}>
                                <div className={this.state.color}></div> 
                                {this.state.message}
                            </Alert>
                            <Form.Group as={Row} controlId="formHorizontalEmail">
                                <Form.Label column sm="1">
                                    Scheduled Date 
                                </Form.Label>
                                <Col sm="5">
                                    <DatePicker
                                        ref='scheduleDate'
                                        selected={this.state.scheduleDate}
                                        onChange={this.handleChangedScheduleDate}
                                        //minDate={this.minDate}
                                        value={this.props.scheduleDate}
                                        dateFormat={"MM/dd/yyyy"}
                                        className="form-control"
                                    />
                                </Col>
                            </Form.Group>
                                <div>
                                    <Card.Header>EMPLOYEE LIST</Card.Header>
                                    <BootstrapTable
                                        keyField = "id"
                                        data = { this.state.employeeGRID }
                                        columns = { employeeCol }
                                        selectRow = { selectRow }
                                        cellEdit = { cellEditFactory({ 
                                                mode: 'dbclick', 
                                                blurToSave: true,
                                                afterSaveCell: (oldValue, newValue, row, column) => { 
                                                    //this.GridDataModified(oldValue, newValue, row.id, column.dataField)
                                                }
                                            })
                                        }
                                        rowClasses="noser-table-row-class no-checkbox"
                                        striped
                                        hover
                                        condensed
                                        loading={true}
                                        wrapperClasses="table-responsive"
                                        rowClasses="noser-table-row-class no-checkbox"
                                        pagination={ paginationFactory({sizePerPageRenderer})}
                                        noDataIndication={ () => <div style={{width:'100%'}}>No record found.</div> }
                                    />
                            </div>
                        </Form>
                        <ButtonToolbar className="mt-1">
                            <Button disabled={this.state.employeeGRID.length>0 ? false:true} variant="success" className="noser-button ml-auto" onClick={this.handleClickedProcess}>RE-PROCESS</Button>
                        </ButtonToolbar>
                    </Card.Body>
                </Card>
            </Container>
            <NoserLoading show={this.state.isloading} />
        </div>
        );
    }
}
export  default BatchScheduler
