import {
    React,Component,BootstrapTable,
    paginationFactory,Button, ButtonToolbar, Card, 
    Form, Col, Row, axios, Container, Banner, Typeahead, TimePicker,
    cellEditFactory, Type, moment, NavLink, Alert, AppConfiguration,
    NoserLoading, Noser, sizePerPageRenderer, DatePicker, Redirect
} 
from '../../noser-hris-component';
import { DropdownButton, Dropdown} from 'react-bootstrap';

class TrainingMonitoring extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userinfo        :   [],
            isloading       :   false,
            isshow          :   false,
            alerttype       :   "",
            message         :   "",
            color           :   "",
            fade            :   true,      
            
            referenceNo             : "",
            statusId                : "",
            trainingTypetinNumber   : "",
            location                : [],
            training                : "",
            trainingType            : "",
            applicationFormNo       : "",
            employeeGrid            : [],

            clientName              : "",
            locationName            : "",
            employeeName            : "",
            employeeId              : "",
            clientId                : "",
            locationId              : "",
            locationStatus : [
                {
                    "id"    :   "13",
                    "name"  :   "ON TRAINING" 
                },
                {
                    "id"    :   "15",
                    "name"  :   "DEPLOYED"
                },
            ]
        }
    }

    componentDidMount(){
        const sleep = (milliseconds) => {return new Promise(resolve => setTimeout(resolve, milliseconds))}
        this.state.userinfo = JSON.parse(sessionStorage.getItem("userData"))
        this.GetEmployeeTrainingMonitorings(); 
    }
    GetEmployeeTrainingMonitorings = async()=> {
        this.setState({isloading:true})
        const trainingmonitoring = {
            "IpAddress"     :   "0.0.0.0",
            "ClientId"      :   "",//--> selected clientid optional
            "UserId"        :   this.state.userinfo.userId,
            "LocationId"    :   ""      , //--> BranchId optional
            "ProfileId"     :   ""      , //--> ProfileId optional
            "StatusId"      :   ""      , //--> StatusId optional
        };
        await
        axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Training/GetEmployeeTrainingMonitorings",  trainingmonitoring)
        .then(res => {
            const data = res.data;
            this.setState({employeeGrid:data.employeeTrainings,isloading:false});
            console.log(data)
        })
        .catch(error=>{
            this.setState(  {
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade            :   true
            })
        })
    }
    
    handleChangeClient = (e) => {

        if(e.length == 0) {
            this.state.clientId         =   ""
            this.state.clientName       =   ""
            return
        }
        this.state.clientId             =   e[0].clientId
        this.state.clientName           =   e[0].clientName
        this.setState({
            isshow:false,
        })
        this.GetEmployeeTrainingMonitorings();
    }

    handleChangeEmployee = (e) => {
        
        if(e.length == 0) {
            this.state.employeeId       =   ""
            this.state.employeeName     =   ""
            return
        }
        this.state.employeeId           =   e[0].profileId
        this.state.employeeName         =   e[0].employeeName
        this.setState({
            isshow:false,
        })
        this.GetEmployeeTrainingMonitorings();
    }

    handleChangeLocation = (e) => {
        
        if(e.length == 0) {
            this.state.locationId       =   ""
            this.state.locationName     =   ""
            return
        }
        this.state.locationId           =   e[0].locationId
        this.state.locationName         =   e[0].locationName
        this.setState({
            isshow:false,
        })
        this.GetEmployeeTrainingMonitorings();
    }

    handleChangeLocationStatus = (e) => {
        if(e.length == 0) {
            this.state.statusId     =   ""
            this.state.statusName   =   ""
            return
        }
        this.state.statusId     =   e[0].id
        this.state.statusName   =   e[0].name
        this.setState({
            isshow:false,
        })
    }

    handleSearchClick = () => {
        this.setState({isloading:true})
        const params = {
            "IpAddress"     :   "0.0.0.0",
            "ClientId"      :   this.state.clientId,
            "UserId"        :   this.state.userinfo.userId,
            "LocationId"    :   this.state.locationId, //--> BranchId optional
            "ProfileId"     :   this.state.employeeId,
            "StatusId"      :   this.state.statusId,
        };
        console.log(params)
        axios
            .post(AppConfiguration.Setting().noserapiendpoint + "Training/GetEmployeeTrainingMonitorings",  params)
            .then(res => {
            const data = res.data;
            console.log(data)
            this.setState({
                employeeGrid    :   data.employeeTrainings,
                isloading       :   false
            });

            if(data.status=="0"){
                this.setState({
                    isloading   :   false,
                    alerttype   :   "Error!",
                    isshow      :   true,
                    color       :   "danger",
                    message     :   data.message,
                    fade        :   true
                });
            }
        })
        .catch(error=>{
            this.setState(  {
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade            :   true
            })
        })
    }

    handleClickedRedirect = (row) =>{
            sessionStorage.setItem("traininglist_" + this.state.userinfo.userId + this.state.userinfo.employeeId, JSON.stringify(row))
            this.setState({navigate:true})
    }

    render() {
        const {navigate} = this.state;
        if (navigate) {
            return <Redirect to="/employeetraininglist" push={true} />;
        }
        
        const employeeColumn = [
            {
                dataField: 'employeeName',
                text: 'FULL NAME',
                headerStyle: () => {
                    return { width: "20%" };
                },

                formatter   :   (cell, row, isSelect) => {
                        return (
                            <Button style={{paddingTop:'0',paddingBottom:'0',marginLeft:'-10px',fontSize:'10px'}}
                                variant="link" onClick={e => this.handleClickedRedirect(row)}
                        >{cell}</Button>
                        );
                } 
            },
            {
                dataField: 'clientName',
                text: 'CLIENT/S',
                headerStyle: () => {
                    return { width: "20%" };
                },
            },
            {
                dataField: 'locationName',
                text: 'BRANCH',
                headerStyle: () => {
                    return { width: "30%" };
                },
            },
            {
                dataField: 'deployedDate',
                text: 'DEPLOYMENT DATE',
                headerStyle: () => {
                    return { width: "10%" };
                },
            },
            {
                dataField: 'statusName',
                text: 'CURRENT LOCATION',
                headerStyle: () => {
                    return { width: "10%" };
                },
            },
            {
                dataField: 'requiredTraining',
                text: 'REQUIRED TRAINING',
                headerStyle: () => {
                    return { width: "20%" };
                },
            },
            {
                dataField: 'trainingAttended',
                text: 'ATTENDED',
                headerStyle: () => {
                    return { width: "20%" };
                },
            }   
        ] 
        
    
        const selectRow = {
            mode: 'checkbox',
            clickToSelectAndEditCell: true,
            onSelect: (row, isSelect, rowIndex, e) => {
                this.state.applicationFormListGrid.map(function(item,i){
                    
                    if(item.id===row.id)
                        {
                        item.isDeleted = isSelect ? "1" : "0"
                        item.isModified = isSelect ? "1" : "0"
                    }
                })

            }
        };
            const rowEvents = {
            onClick: (e, row, rowIndex) => {

            }
        };

    return(
            <div>
                <Banner />
                    <Container className="mt-5" fluid>
                        <Card>
                            <Card.Header>TRAINING &#62;&#62;EMPLOYEE TRAINING MONITORING</Card.Header>
                            <Card.Body>
                                <Form>
                                    <Alert isOpen={this.state.isshow} color={this.state.color} fade={this.state.fade} className={this.state.isshow ? 'display-block' : 'display-none'}>
                                        <div className={this.state.color}></div> 
                                        {this.state.message}
                                    </Alert>
                                    <Form.Row>
                                        <Form.Group as={Col} controlId="formGridAddress1">
                                            <Form.Label style={{fontWeight : "bold"}}>CLIENT</Form.Label>
                                            <Typeahead 
                                                labelKey='clientName'
                                                id="basic-example"
                                                onChange={this.handleChangeClient}
                                                options={this.state.employeeGrid}
                                                placeholder="Select Client" 
                                            />
                                        </Form.Group>
                                        <Form.Group as={Col} sm="1" controlId="formGridCity">
                                        </Form.Group>
                                        <Form.Group as={Col} controlId="formGridAddress1">
                                            <Form.Label style={{fontWeight : "bold"}}>EMPLOYEE</Form.Label>
                                            <Typeahead 
                                                labelKey='employeeName'
                                                id="basic-example"
                                                onChange={this.handleChangeEmployee}
                                                options={this.state.employeeGrid}
                                                placeholder="Select Employee" 
                                            />
                                        </Form.Group>
                                    </Form.Row>
                                    <Form.Row className="mt-2">
                                        <Form.Group as={Col} controlId="formGridCity">
                                            <Form.Label style={{fontWeight : "bold"}}>BRANCH / LOCATION</Form.Label>
                                            <Typeahead 
                                                labelKey='locationName'
                                                id="basic-example"
                                                onChange={this.handleChangeLocation}
                                                options={this.state.employeeGrid}
                                                placeholder="Branch/Location" 
                                            />
                                        </Form.Group>
                                        <Form.Group as={Col} sm="1" controlId="formGridCity">
                                        </Form.Group>
                                        <Form.Group as={Col} controlId="formGridCity">
                                            <Form.Label style={{fontWeight : "bold"}}>LOCATION STATUS</Form.Label>
                                            <Typeahead 
                                                labelKey='name'
                                                id="basic-example"
                                                onChange={this.handleChangeLocationStatus}
                                                options={this.state.locationStatus}
                                                placeholder="Select Location Status" 
                                            />
                                        </Form.Group>
                                    </Form.Row>{/* 
                                    <Form.Row className="mt-2">
                                        <Form.Group as={Col} controlId="formGridCity">
                                            <Form.Label style={{fontWeight : "bold"}}>TYPE OF TRAINING</Form.Label>
                                            <Form.Control 
                                                type="text"
                                                name="trainingType"
                                                value={this.state.trainingType}
                                                placeholder="Select Type" 
                                            />
                                        </Form.Group>
                                        <Form.Group as={Col} sm="1" controlId="formGridCity">
                                        </Form.Group>
                                        <Form.Group as={Col} controlId="formGridCity">
                                            <Form.Label style={{fontWeight : "bold"}}>TRAINING</Form.Label>
                                            <Form.Control 
                                                type="text"
                                                name="training"
                                                value={this.state.training}
                                                placeholder="Select Training" 
                                            />
                                        </Form.Group>
                                    </Form.Row>
                                    <Form.Row className="mt-2">
                                        <Form.Group as={Col} controlId="formGridCity">
                                            <Form.Label style={{fontWeight : "bold"}}>TIN NUMBER</Form.Label>
                                            <Form.Control 
                                                type="text"
                                                name="tinNumber"
                                                value={this.state.tinNumber}
                                                disabled
                                            />
                                        </Form.Group>
                                        <Form.Group as={Col} sm="1" controlId="formGridCity">
                                        </Form.Group>
                                        <Form.Group as={Col} controlId="formGridCity">
                                            <Form.Label style={{fontWeight : "bold"}}>REFERENCE NO</Form.Label>
                                            <Form.Control 
                                                type="text"
                                                name="referenceNo"
                                                value={this.state.referenceNo}
                                                disabled
                                            />
                                        </Form.Group>
                                    </Form.Row> */}
                                    <ButtonToolbar className="mt-1">
                                        <Button variant="info" className="ml-auto" onClick={ this.handleSearchClick }>
                                            Search
                                        </Button>
                                    </ButtonToolbar>
                                    <div className="mt-5">  
                                        <div>
                                            <Card.Header>EMPLOYEE LIST</Card.Header>
                                            <BootstrapTable
                                                /* caption={Noser.TableHeader({title:"RECORD"})} */
                                                keyField = "id"
                                                data = { this.state.employeeGrid }
                                                columns = { employeeColumn }
                                                pagination={ paginationFactory({sizePerPageRenderer}) }
                                                noDataIndication={ () => <div style={{width:'100%'}}>No record found.</div> }
                                                rowClasses="noser-table-row-class no-checkbox"
                                                striped
                                                hover
                                                condensed
                                                cellEdit = { cellEditFactory({
                                                    mode: 'dbclick',
                                                    blurToSave: true,
                                                    afterSaveCell: (oldValue, newValue, row, column) => {
                                                        //this.GridDataModified(oldValue, newValue, row.id, column.dataField)
                                                        }
                                                    })
                                                }
                                                //selectRow = { requestMonitoringSelectRow }
                                                //rowEvents={ requestMonitoringRowEvents }
                        
                                            />
                                        </div>
                                    </div>
                                    <ButtonToolbar sm={12}>
                                        {/* <NavLink className="ml-auto" to="/applicationformcreate" to={"/createrecruittraining"}>
                                            <Button className="noser-button-mr1 noser-button"  variant="success">Create</Button>
                                        </NavLink> */}
                                    
                                        <Button  className="ml-auto noser-button-mr1 noser-button"  variant="danger" href="/home">Close </Button>
                                    </ButtonToolbar>
                                </Form>
                            </Card.Body>
                        </Card>
                    
                    </Container>
                    <NoserLoading show={this.state.isloading} />
            </div> 
        )
    }

}
export  default TrainingMonitoring