import {
    React,Component,BootstrapTable,
    paginationFactory,Button, ButtonToolbar, Card, 
    Form, Col, Row, axios, Container, Banner, Typeahead, TimePicker,
    cellEditFactory, Type, moment, NavLink, Alert, AppConfiguration,
    NoserLoading, Noser, sizePerPageRenderer, DatePicker, Modal
} 
from '../../../noser-hris-component';

class BillablePerTripModal extends Component {
    constructor(props) {
        super(props)
        this.module = React.createRef();
        this.state = {
            userinfo    :   JSON.parse(sessionStorage.getItem("userData")),
            isloading   :   false,
            isshow      :   false,
            alerttype   :   "",
            message     :   "",
            color       :   "",
            fade        :   true,
            
            clientId    :   '',
            clientName  :   '',
            periodId    :   '',
            periodName  :   '',
            periodId    :   '',
            periodName  :   '',
            typeId      :   '',
            uploadfile  :   '',
            startDate   :   '',
            endDate     :   '',
            clientDDL       :   [],
            periodDDL       :   [],
        }
    }
    componentDidMount(){
        this.state.userinfo = JSON.parse(sessionStorage.getItem("userData"))
        this.props.onRefModal(this)
    }
    componentWillUnmount() {
        this.props.onRefModal(undefined)
    }
    initialize=(e)=>{
        this.setState({
            userinfo    :   JSON.parse(sessionStorage.getItem("userData")),
            isloading   :   false,
            isshow      :   false,
            alerttype   :   "",
            message     :   "",
            color       :   "",
            fade        :   true,
            
            clientId    :   e.clientId,
            clientName  :   e.clientName,
            periodId    :   e.periodId,
            periodName  :   e.periodName,
            startDate   :   e.startDate,
            endDate     :   e.endDate,
            typeId      :   e.typeId,
            uploadfile  :   '',
            
            clientDDL       :   [],
            periodDDL       :   [],
        })
        this.GetClients()
    }
    GetClients = async() =>{
        this.setState({isloading:true})
        const params = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.userinfo.clientId,
            "UserId":this.state.userinfo.userId
        }
        await axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Client/GetClientList", params)
        .then(res => {
            const data = res.data;
            let arr = []
            
            if(this.state.userinfo.roleId!=="1"){
                let fieldaccess = JSON.parse(sessionStorage.getItem("userControlAccess"))
                if(fieldaccess.filter(x=>x.accessCode==="0001").length>0){
                    let access = fieldaccess.find(x=>x.accessCode==="0001" && x.controlAccessDetails.length>0)
                    if(access.controlAccessDetails.filter(x=>x.isClient==="1").length>0){
                        let brr = access.controlAccessDetails.filter(x=>x.isClient==="1")
                        arr = data.clients.filter(f => brr.some(item => item.referenceId === f.id))
                    }
                }
                this.setState({clientDDL:arr.filter(x=>x.payrollTypeId==="7"),isloading:false})
            }
            else{
                this.setState({clientDDL:data.clients.filter(x=>x.payrollTypeId==="7"),isloading:false})
            }
            this.GetPayrollPeriods()
        })
        .catch(error=>{
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade        :   true
            })
        })
    }
    GetPayrollPeriods = async() => {
        this.setState({isloading:true})
        const params = {
            "IpAddress"     :   "0.0.0.0",
            "ClientId"      :   this.state.clientId,
            "UserId"        :   this.state.userinfo.userId,
            "EmployeeId"    :   "",
            "IsProcessed"   :   ""
        };
         
        await axios
             .post(
                 AppConfiguration.Setting().noserapiendpoint + "Timekeeping/GetPayrollPeriods",  params
             )
             .then(res => {
                const data = res.data;
                this.setState({periodDDL:data.payrollPeriods,isloading:false})
            })
    }
    
    handleChangedClient = (e) =>{
        this.state.alerttype="";this.state.isshow=false;this.state.color="";this.state.message="";this.state.fade=true;
        if(e.length===0){
            this.state.clientId= ''
            this.state.client=''
            this.state.employeeDDL=[]
            return
        }
        this.state.clientId=e[0].id
        this.GetPayrollPeriods()
    }
    handleChangedPeriod = (e) =>{
        this.state.alerttype="";this.state.isshow=false;this.state.color="";this.state.message="";this.state.fade=true;
        if(e.length===0){
            this.state.periodId=''
            this.state.startDate=''
            this.state.endDate=''
            return
        }
        this.state.periodId=e[0].periodId
        this.state.periodName=e[0].payPeriod
        this.state.startDate=e[0].startDate
        this.state.endDate=e[0].endDate
        console.log(e)
    }
    
    handleSubmit = (e) =>{
        this.setState({alerttype:"",isshow:false,color:"",message:"",fade:true,isloading:true})
        if(this.state.typeId==="1"){
            this.DownloadTemplate()
        }
        else{
            this.UploadOtherPayroll()
        }
    }
    DownloadTemplate = async() =>{
        if(this.state.clientId==""){
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "Please select client.",
                fade        :   true,
            })
            return 
        }
        
        const params = {
            "IpAddress"     :   "0.0.0.0",
            "ClientId"      :   this.state.clientId,
            "UserId"        :   this.state.userinfo.userId,
            "PayPeriodId"   :   this.state.periodId,
            "PayPeriodName" :   this.state.periodName,
            "StartDate"     :   this.state.startDate,
            "EndDate"       :   this.state.endDate,
        }
        console.log("params")
        console.log(params)
        await axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Timekeeping/DownloadPerTripTemplate", params,{'responseType': 'blob'})
        .then(res => {
            let today = new Date()
            const uid = moment(today).format('MMDDYYYYHHMMSS') + Math.floor(100000 + Math.random() * 900000);
            let fileName = "PT_" + params.ClientId + params.PayPeriodId + uid + ".xlsx"
            const url = window.URL.createObjectURL(new Blob([res.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();
            this.setState({isloading:false})
        })
        .catch(error=>{
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade        :   true
            })
        })
    }
    UploadOtherPayroll = async()=>{
        this.setState({alerttype:"",isshow:false,color:"",message:"",fade:true,isloading:true,disabledUpload:true})
        const formData = new FormData();
        formData.append('IpAddress', "0.0.0.0");
        formData.append('UserId', this.state.userinfo.userId);
        formData.append('ClientId', this.state.clientId);
        formData.append('PayPeriodId', this.state.periodId);
        formData.append('StartDate', this.state.startDate);
        formData.append('EndDate', this.state.endDate);
        formData.append('File', this.state.uploadfile);

        axios
            .post(
                AppConfiguration.Setting().noserapiendpoint + "Timekeeping/UploadPerTripTemplate",  formData, {headers: {'Content-Type': 'multipart/form-data'}}
            )
            .then(res => {
                const data = res.data;
                this.setState({
                    isloading   :   false,
                    alerttype   :   data.status=="1" ? "Success!" : "Error!",
                    isshow      :   true,
                    color       :   data.status=="1" ? "success" : "danger",
                    message     :   data.message,
                    fade        :   true
                });
            })
            .catch(error=>{
                this.setState({
                    isloading   :   false,
                    alerttype   :   "Error!",
                    isshow      :   true,
                    color       :   "danger",
                    message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                    fade        :   true,
                    disabledUpload:false
                })
            })
    }
    handleModalClose = () => {
        this.props.onHide()            
    }
    SetFile = (e) => {
        if(e.target.value.length>0)
            this.setState({ uploadfile: e.target.files[0],disabledUpload:false});
        else
            this.setState({ uploadfile: "",disabledUpload:true });
    }
    render() {
    return(
        
            <Modal
                {...this.props}
                return
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                backdrop="static"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        {this.state.typeId ==="1" ? "DOWNLOAD PER TRIP TEMPLATE" : "UPLOAD PER TRIP TEMPLATE" }
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container>
                        <Alert isOpen={this.state.isshow} color={this.state.color} fade={this.state.fade} className={this.state.isshow ? 'display-block' : 'display-none'}>
                            <div className={this.state.color}></div> 
                            {this.state.message}
                        </Alert>
                        <Form.Group as={Row} controlId="formHorizontalEmail">
                            <Col sm={12}>
                                <Typeahead
                                    labelKey='name'
                                    id="basic-example"
                                    onChange={this.handleChangedClient}
                                    options={this.state.clientDDL}
                                    selected={[this.state.clientName]}
                                    placeholder="Select CLient"
                                />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} controlId="formHorizontalEmail">
                            <Col sm={12}>
                                <Typeahead
                                    labelKey='payPeriod'
                                    id="basic-example"
                                    onChange={this.handleChangedPeriod}
                                    options={this.state.periodDDL}
                                    selected={[this.state.periodName]}
                                    placeholder="Select Period"
                                />
                            </Col>
                        </Form.Group>
                        <div className={this.state.typeId==="1" ? 'display-none' : 'display-block'}>
                        <Form.Group as={Row} controlId="formHorizontalEmail">
                            <Col sm={12}>
                                <Form.Control type="file" accept='.xlsx' onChange={this.SetFile} />
                            </Col>
                        </Form.Group>
                        </div>
                    </Container>
                </Modal.Body>
                <Modal.Footer>
                    <ButtonToolbar >
                        <Button variant="success" className="ml-auto noser-button-mr1" onClick = { this.handleSubmit }>Submit</Button>
                        <Button variant="danger"  className="ml-auto noser-button-mr15" onClick={this.handleModalClose}>Close</Button>
                    </ButtonToolbar>
                </Modal.Footer>
                <NoserLoading show={this.state.isloading} />
        </Modal>
        );
    }
}
export default BillablePerTripModal