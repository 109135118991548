import {
    React,Component,BootstrapTable,
    paginationFactory,Button, ButtonToolbar, Card, 
    Form, Col, Row, axios, Container, Banner, Typeahead, TimePicker,
    cellEditFactory, Type, moment, NavLink, Alert, AppConfiguration,
    NoserLoading, Noser, sizePerPageRenderer, DatePicker, Tabs, Tab,
    Redirect
} 
from '../../noser-hris-component';
import BatchDownload from './BatchDownload';
import ToolkitProvider, { CSVExport } from 'react-bootstrap-table2-toolkit';
import 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css';
class ManageEmployees extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userinfo        :   [],
            isloading       :   false,
            isshow          :   false,
            alerttype       :   "",
            message         :   "",
            color           :   "",
            fade            :   true,
            openModal       :   false,
            uploadfile      :   "",
            disabledUpload  :   true,
            disableExport   :   true,
            
            clientId        :   "",
            clientName      :   "",
            locationId      :   "",
            employeeId      :   "",
            statusId        :   "",
            clientDDL       :   [],
            locationDDL     :   [],
            employeeDDL     :   [],
            statusDDL       :   [
                {"id":"","name":"ALL"},
                //{"id":"1","name":"PENDING FOR APPROVAL (AM)"},
                {"id":"2","name":"FOR APPROVAL"},
                //{"id":"3","name":"REJECTED BY AM"},
                {"id":"4","name":"APPROVED"},
                {"id":"5","name":"REJECTED"}
            ],
            employeeGRID    :   []
        }
    } 
    componentDidMount(){
        this.state.userinfo = JSON.parse(sessionStorage.getItem("userData"))
        this.GetClients()
        this.state.statusId = this.state.userinfo.roleId === "1" ||  
                                this.state.userinfo.roleId === "8" ? "4" : "2"
	sessionStorage.setItem("employeeapprovalFilter_"  + this.state.userinfo.userId + this.state.userinfo.employeeId, null)        
    }
    handleModalShow = (e) =>{
        this.setState({openModal:true})
        let obj = {}
        this.child.initialize(obj)
    }
    handleModalClose = (e) =>{
        this.setState({openModal:false})
        if(this.state.clientId!==""){
            //this.GetEmployees()
        }
        //this.handleSearch(e)
    }
    GetClients = async() =>{
        this.setState({isloading:true})
        const params = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.userinfo.clientId,
            "UserId":this.state.userinfo.userId,
        }
        await axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Client/GetClientList", params)
        .then(res => {
            const data = res.data;
            console.log(data)
            this.setState({clientDDL : data.clients ? data.clients : []})
            if(data.clients.length===0){
                this.setState({
                    isloading   :   false,
                    alerttype   :   "Error!",
                    isshow      :   true,
                    color       :   "danger",
                    message     :   data.message,
                    fade        :   true
                });
            }    
        })
        .catch(error=>{
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade        :   true
            })
        })
        this.GetSubmittedEmployees()
    }
    
    GetEmployees = async() =>{
        this.setState({isloading:true})
        const params = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.clientId,
            "UserId":this.state.userinfo.userId,
            "LocationId":this.state.locationId,
            "IsDeleted":this.state.statusId
        }
        await axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Employee/GetEmployeeList", params)
        .then(res => {
            const data = res.data;
            this.setState({employeeDDL : data.employees ? data.employees : [], isloading:false})
            if(data.employees.length===0){
                this.setState({
                    isloading   :   false,
                    alerttype   :   "Error!",
                    isshow      :   true,
                    color       :   "danger",
                    message     :   data.message,
                    fade        :   true
                });
            }  
        })
        .catch(error=>{
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade        :   true
            })
        })
        
    }
    handleChangeClient = (e) =>{
        this.setState({alerttype:"",isshow:false,color:"",message:"",fade:true});
        if(e.length===0)
        {
            this.state.clientId= ''
            this.state.clientName=''
            return
        }
        this.state.clientId= e[0].id
        this.state.clientName=e[0].name
        this.GetEmployees()
    }
    handleChangeStatus = (e) =>{
        this.setState({alerttype:"",isshow:false,color:"",message:"",fade:true});
        if(e.length===0)
        {
            this.state.statusId= ''
            return
        }
        this.state.statusId= e[0].id
        //this.GetEmployees()
    }
    handleChangeEmployee = (e) =>{
        this.setState({alerttype:"",isshow:false,color:"",message:"",fade:true});
        if(e.length===0)
        {
            this.state.employeeId= ''
            return
        }
        this.state.employeeId= e[0].id
    }
    handleDownloadReferenceDataClick = async() => {
        this.setState({alerttype:"",isshow:false,color:"",message:"",fade:true,isloading:true})

        const params = {
            "IpAddress"     :   "0.0.0.0",
            "ClientId"      :   this.state.userinfo.clientId,
            "UserId"        :   this.state.userinfo.userId
        }
        await axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Manning/ExportReferenceData", params,{'responseType': 'blob'})
        .then(res => {
            const url = window.URL.createObjectURL(new Blob([res.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', "ReferenceData.xlsx");
            document.body.appendChild(link);
            link.click();
            this.setState({isloading:false})
        })
        .catch(error=>{
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade        :   true
            })
        })
    }
    handleBatchUpload = async() => {
        this.setState({alerttype:"",isshow:false,color:"",message:"",fade:true,isloading:true,disabledUpload:true})
        const formData = new FormData();
        formData.append('IpAddress', "0.0.0.0");
        formData.append('ClientId', this.state.userinfo.clientId);
        formData.append('UserId', this.state.userinfo.userId);
        formData.append('RoleId', this.state.userinfo.roleId);
        formData.append('EmployeeId', this.state.userinfo.employeeId);
        formData.append('File', this.state.uploadfile);

        axios
            .post(
                AppConfiguration.Setting().noserapiendpoint + "Manning/ImportBatchUpdate",  formData, {headers: {'Content-Type': 'multipart/form-data'}}
            )
            .then(res => {
                const data = res.data;
                if(data.status=="1")
                {
                    this.GetSubmittedEmployees()
                    this.setState({
                        isloading   :   false,
                        alerttype   :   "Success!",
                        isshow      :   true,
                        color       :   "success",
                        message     :   data.message,
                        fade        :   true
                    });
                }
                else{
                    this.setState({
                        isloading   :   false,
                        alerttype   :   "Error!",
                        isshow      :   true,
                        color       :   "danger",
                        message     :   data.message,
                        fade        :   true,
                        disabledUpload:false
                    });
                }
            })
            .catch(error=>{
                this.setState({
                    isloading   :   false,
                    alerttype   :   "Error!",
                    isshow      :   true,
                    color       :   "danger",
                    message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                    fade        :   true,
                    disabledUpload:false
                })
            })
    }
    SetFile = (e) => {
        if(e.target.value.length>0)
            this.setState({ uploadfile: e.target.files[0],disabledUpload:false});
        else
            this.setState({ uploadfile: "",disabledUpload:true });
    }
    GetSubmittedEmployees = async() =>{
        this.setState({isloading:true})
        const params = {
            "IpAddress":"0.0.0.0",
            "UserId":this.state.userinfo.userId,
            "RoleId":this.state.userinfo.roleId,
            "SubmittedBy":this.state.userinfo.employeeId,
            "ClientId":this.state.clientId,
            "LocationId":this.state.locationId,
            "EmployeeId":this.state.employeeId,
            "StatusId":this.state.statusId
        }
        await axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Manning/GetManningSubmittedEmployees", params)
        .then(res => {
            const data = res.data;
            this.setState({employeeGRID : data.manningEmployees ? data.manningEmployees : [], isloading:false,disableExport:false})
            
            if(data.status==="0"){
                this.setState({
                    isloading   :   false,
                    alerttype   :   "Error!",
                    isshow      :   true,
                    color       :   "danger",
                    message     :   data.message,
                    fade        :   true
                });
            }  
        })
        .catch(error=>{
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade        :   true
            })
        })
        
    }
    handleSearchClick = () =>{
        this.GetSubmittedEmployees()
    }
    handleApprovalClick = (row) =>{
        row.origin = "1"
        sessionStorage.setItem("employeeapproval_" + this.state.userinfo.userId + this.state.userinfo.employeeId, JSON.stringify(row))
        this.setState({navigate: true})
    }
    render(){
        let employeeCol = []
        let isAdmin = this.state.userinfo.roleId==="1" || 
                        this.state.userinfo.roleId==="8" ||
                        this.state.userinfo.roleId==="11" ||
                        this.state.userinfo.roleId==="19" ||
                        this.state.userinfo.roleId==="28" ||
                        this.state.userinfo.roleId==="31"
        const { navigate } = this.state;
        if(navigate){
            return <Redirect to="/employeeinformation" push={true}/>
        }
        if(isAdmin){
            employeeCol = [
                
                {
                    dataField: 'submittedDate',
                    text: 'DATE SUBMITTED',
                    editable: false,
                    headerStyle: (colum, colIndex) => {
                        return { textAlign: 'center',width:'10%'}},
                    style:{textAlign:'center',width:'10%'}
                },
                {
                    dataField: 'employeeName',
                    text: 'EMPLOYEE NAME',
                    editable: false,
                    headerStyle: (colum, colIndex) => {
                        return { textAlign: 'left',width:'25%'}},
                    style:{textAlign:'left',width:'25%'}
                },
                {
                    dataField: 'clientName',
                    text: 'CLIENT',
                    editable: false,
                    headerStyle: (colum, colIndex) => {
                        return { textAlign: 'left',width:'66%'}},
                    style:{textAlign:'left',width:'66%'}
                },
                {
                    dataField: 'action',
                    text: 'ACTION',
                    editable:false,
                    headerStyle: (colum, colIndex) => {
                        return { textAlign: 'center',width:'7%'}
                    },
                    style:{textAlign:'center',width:'7%'},
                    formatter   :   (cell, row, isSelect) => {
                        return (
                            <Button 
                                onClick={e => this.handleApprovalClick(row)}
                                style={{height:'18px',paddingTop:'0',marginTop:'-4px',fontSize:'11px'}} 
                                variant="link"
                        >VIEW</Button>
                        );
                    },
                    
                }
            ]
        }
        else{
            employeeCol = [
                {
                    dataField: 'status',
                    text: 'STATUS',
                    editable: false,
                    headerStyle: (colum, colIndex) => {
                        return { textAlign: 'center',width:'8%'}},
                    style:{textAlign:'center',width:'8%'},
                },
                {
                    dataField: 'department',
                    text: 'DEPARTMENT',
                    editable: false,
                    headerStyle: (colum, colIndex) => {
                        return { textAlign: 'left',width:'8%'}},
                    style:{textAlign:'left',width:'8%'}
                },
                {
                    dataField: 'submittedByName',
                    text: 'SUBMITTED BY',
                    editable: false,
                    headerStyle: (colum, colIndex) => {
                        return { textAlign: 'center',width:'8%'}},
                    style:{textAlign:'center',width:'8%'}
                },
                {
                    dataField: 'submittedDate',
                    text: 'DATE SUBMITTED',
                    editable: false,
                    headerStyle: (colum, colIndex) => {
                        return { textAlign: 'center',width:'8%'}},
                    style:{textAlign:'center',width:'8%'}
                },
                {
                    dataField: 'approvedByName',
                    text: 'APPROVED / REJECTED BY',
                    editable: false,
                    headerStyle: (colum, colIndex) => {
                        return { textAlign: 'left',width:'19%'}},
                    style:{textAlign:'left',width:'19%'}
                },
                {
                    dataField: 'approvedDate',
                    text: 'APPR / REJ DATE',
                    editable: false,
                    headerStyle: (colum, colIndex) => {
                        return { textAlign: 'center',width:'8%'}},
                    style:{textAlign:'center'}
                },
                {
                    dataField: 'employeeName',
                    text: 'EMPLOYEE NAME',
                    editable: false,
                    headerStyle: (colum, colIndex) => {
                        return { textAlign: 'left',width:'20%'}},
                    style:{textAlign:'left',width:'20%'}
                },
                {
                    dataField: 'clientName',
                    text: 'CLIENT',
                    editable: false,
                    headerStyle: (colum, colIndex) => {
                        return { textAlign: 'left',width:'22%'}},
                    style:{textAlign:'left',width:'22%'}
                },
                {
                    dataField: 'action',
                    text: 'ACTION',
                    editable:false,
                    headerStyle: (colum, colIndex) => {
                        return { textAlign: 'center',width:'7%'}
                    },
                    style:{textAlign:'center',width:'7%'},
                    formatter   :   (cell, row, isSelect) => {
                        return (
                            <Button 
                                onClick={e => this.handleApprovalClick(row)}
                                style={{height:'18px',paddingTop:'0',marginTop:'-4px',fontSize:'11px'}} 
                                variant="link"
                        >VIEW</Button>
                        );
                    },
                    
                }
            ]
        }
        
        const addressCol = [
            {
                dataField: 'type',
                text: 'TYPE',
                editable: false,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'left',whiteSpace:'nowrap'}},
                style:{textAlign:'left',whiteSpace:'nowrap'}
            },
            {
                dataField: 'region',
                text: 'REGION',
                editable: false,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'left',whiteSpace:'nowrap'}},
                style:{textAlign:'left',whiteSpace:'nowrap'}
            },
            {
                dataField: 'province',
                text: 'PROVINCE',
                editable: false,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'left',whiteSpace:'nowrap'}},
                style:{textAlign:'left',whiteSpace:'nowrap'}
            },
            {
                dataField: 'city',
                text: 'CITY',
                editable: false,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'left',whiteSpace:'nowrap'}},
                style:{textAlign:'left',whiteSpace:'nowrap'}
            },
            {
                dataField: 'houseNumber',
                text: 'House Number',
                editable: false,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'left',whiteSpace:'nowrap'}},
                style:{textAlign:'left',whiteSpace:'nowrap'}
            },
            {
                dataField: 'street',
                text: 'STREET NAME',
                editable: false,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'left',whiteSpace:'nowrap'}},
                style:{textAlign:'left',whiteSpace:'nowrap'}
            },
            {
                dataField: 'barangay',
                text: 'BARANGAY',
                editable: false,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'left',whiteSpace:'nowrap'}},
                style:{textAlign:'left',whiteSpace:'nowrap'}
            },
            {
                dataField: 'postal',
                text: 'POSTAL CODE',
                editable: false,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'left',whiteSpace:'nowrap'}},
                style:{textAlign:'left',whiteSpace:'nowrap'}
            },
        ]
        const fbCol = [
            {
                dataField: 'role',
                text: 'ROLE',
                editable: false,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'left',whiteSpace:'nowrap'}},
                style:{textAlign:'left',whiteSpace:'nowrap'}
            },
            {
                dataField: 'name',
                text: 'Name',
                editable: false,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'left',whiteSpace:'nowrap'}},
                style:{textAlign:'left',whiteSpace:'nowrap'}
            },
            {
                dataField: 'age',
                text: 'AGE',
                editable: false,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'left',whiteSpace:'nowrap'}},
                style:{textAlign:'left',whiteSpace:'nowrap'}
            },
            {
                dataField: 'occupation',
                text: 'OCCUPATION',
                editable: false,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'left',whiteSpace:'nowrap'}},
                style:{textAlign:'left',whiteSpace:'nowrap'}
            },
            {
                dataField: 'company',
                text: 'COMPANY',
                editable: false,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'left',whiteSpace:'nowrap'}},
                style:{textAlign:'left',whiteSpace:'nowrap'}
            }
        ]
        const eduCol = [
            {
                dataField: 'level',
                text: 'LEVEL',
                editable: false,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'left',whiteSpace:'nowrap'}},
                style:{textAlign:'left',whiteSpace:'nowrap'}
            },
            {
                dataField: 'schoolName',
                text: 'SCHOOL NAME',
                editable: false,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'left',whiteSpace:'nowrap'}},
                style:{textAlign:'left',whiteSpace:'nowrap'}
            },
            {
                dataField: 'course',
                text: 'COURSE',
                editable: false,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'left',whiteSpace:'nowrap'}},
                style:{textAlign:'left',whiteSpace:'nowrap'}
            },
            {
                dataField: 'startYear',
                text: 'START YEAR',
                editable: false,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'center',whiteSpace:'nowrap'}},
                style:{textAlign:'center',whiteSpace:'nowrap'}
            },
            {
                dataField: 'endYear',
                text: 'END YEAR',
                editable: false,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'left',whiteSpace:'nowrap'}},
                style:{textAlign:'left',whiteSpace:'nowrap'}
            },
            {
                dataField: 'honor',
                text: 'HONOR',
                editable: false,
                headerStyle: (colum, colIndex) => {
                    return { textAlign: 'left',whiteSpace:'nowrap'}},
                style:{textAlign:'left',whiteSpace:'nowrap'}
            }
        ]
        const selectRow = {
            mode: 'checkbox',
            hideSelectAll: true,
            clickToSelectAndEditCell: true,
            onSelect: (row, isSelect, rowIndex, e) => {
                
            }
        };
        const expandAddress = {
            onlyOneExpanding: true,
            renderer: row => 
            (
                <div className="expandable-table" style={{marginLeft:'50px !important'}}>
                    <BootstrapTable
                        keyField = "id"
                        data = { row.addressesses }
                        columns = { addressCol }
                        rowClasses="noser-table-row-class no-checkbox"
                        className="expandable-table-sub"
                        striped
                        hover
                        condensed
                    />
                    <BootstrapTable
                        keyField = "id"
                        data = { row.familyBackgrounds }
                        columns = { fbCol }
                        rowClasses="noser-table-row-class no-checkbox"
                        className="expandable-table-sub"
                        striped
                        hover
                        condensed
                    />
                    <BootstrapTable
                        keyField = "id"
                        data = { row.educations }
                        columns = { eduCol }
                        rowClasses="noser-table-row-class no-checkbox"
                        className="expandable-table-sub"
                        striped
                        hover
                        condensed
                    />
                </div>
            ),
            showExpandColumn: true
        };
        const { ExportCSVButton } = CSVExport;
        return(
            <div>
                <Banner />
                <Container className="themed-container" fluid={true}>
                    <Card className="mt-5">
                        <Card.Header>Manning >> Manage Employees</Card.Header>
                        <Card.Body>
                            <Form >
                                <Alert isOpen={this.state.isshow} color={this.state.color} fade={this.state.fade} className={this.state.isshow ? 'display-block' : 'display-none'}>
                                    <div className={this.state.color}></div> 
                                    {this.state.message}
                                </Alert>
                                <Form.Group as={Row} controlId="formHorizontalEmail">
                                    <Col sm={12}>
                                        <Typeahead
                                            labelKey='name'
                                            id="basic-example"
                                            onChange={this.handleChangeClient}
                                            options={this.state.clientDDL}
                                            placeholder="Select Client"

                                        />
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} controlId="formHorizontalEmail">
                                    <Col sm={12}>
                                        <Typeahead
                                            labelKey='employeeName'
                                            id="basic-example"
                                            onChange={this.handleChangeEmployee}
                                            options={this.state.employeeDDL}
                                            placeholder="Select Employee"
                                        />
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} controlId="formHorizontalEmail">
                                    <Col sm={12}>
                                        <Typeahead
                                            labelKey='name'
                                            id="basic-example"
                                            onChange={this.handleChangeStatus}
                                            options={this.state.statusDDL}
                                            placeholder="Select Status"

                                        />
                                    </Col>
                                </Form.Group>
                                <ButtonToolbar className="mt-1">
                                    <Button variant="primary" className="ml-auto noser-button-mr1" onClick={ this.handleSearchClick }>Search</Button>
                                    <Button variant="success" className="noser-button" href="/manageemployee">Create</Button>
                                </ButtonToolbar>
                                <hr/>
                                <ButtonToolbar className="mt-1">
                                    <Form.Group as={Row}>
                                        <Col sm="4">
                                            <Form.Control type="file" accept='.xlsx' onChange={this.SetFile} style={{border:"1pt solid #ccc"}}/>
                                        </Col>
                                        <Col sm="8">
                                            <Button disabled={this.state.disabledUpload} variant="info" className="noser-button-mr1" onClick={ this.handleBatchUpload }>BATCH UPLOAD</Button>
                                            <Button variant="info" className="ml-auto noser-button-mr1" onClick={ this.handleModalShow }>BATCH DOWNLOAD</Button>
                                            <Button variant="info" className="noser-button" onClick={ this.handleDownloadReferenceDataClick }>DOWNLOAD REFERENCE DATA</Button>
                                        </Col>
                                    </Form.Group>
                                </ButtonToolbar>
                                {/* <Form.Group as={Col} sm={12} controlId="formHorizontalEmail">
                                
                                </Form.Group> */}
                                <div className="mt-2">
                                    <ToolkitProvider
                                        keyField="id"   
                                        data={ this.state.employeeGRID }
                                        columns = { employeeCol }
                                        exportCSV={ {
                                            fileName: "Manage Employees.csv",
                                            noAutoBOM: false,
                                            separator: ',',
                                            blobType: "text/plain;charset=utf-8,%EF%BB%BF"
                                        } }
                                        >
                                        {
                                            props => (
                                            <div>
                                                
                                                <hr />
                                                <ExportCSVButton disabled={this.state.disableExport} className="btn btn-success" { ...props.csvProps }>Export CSV!!</ExportCSVButton>
                                                <Card.Header>EMPLOYEE LIST</Card.Header>
                                                <BootstrapTable
                                                    keyField = "id"
                                                    data = { this.state.employeeGRID }
                                                    columns = { employeeCol }
                                                    selectRow = { selectRow }
                                                    cellEdit = { cellEditFactory({ 
                                                            mode: 'dbclick', 
                                                            blurToSave: true,
                                                            afterSaveCell: (oldValue, newValue, row, column) => { 
                                                                this.GridDataModified(oldValue, newValue, row.id, column.dataField)
                                                            }
                                                        })
                                                    }
                                                    rowClasses="noser-table-row-class no-checkbox"
                                                    striped
                                                    hover
                                                    condensed
                                                    loading={true}
                                                    wrapperClasses="table-responsive"
                                                    rowClasses="noser-table-row-class no-checkbox"
                                                    pagination={ paginationFactory({sizePerPageRenderer})}
                                                    noDataIndication={ () => <div style={{width:'100%'}}>No record found.</div> }
                                                    //expandRow={ expandAddress }
                                                />
                                            </div>
                                            )
                                        }
                                    </ToolkitProvider>
                                </div>
                            </Form>
                        </Card.Body>
                    </Card>
                </Container>
                <NoserLoading show={this.state.isloading} />
                <BatchDownload 
                    show={this.state.openModal}
                    onHide={this.handleModalClose}
                    onRefModal={ref => (this.child = ref)}
                />
            </div>
        )
    }
}

export default ManageEmployees;
