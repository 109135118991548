import {
    React,Component,BootstrapTable,
    paginationFactory,Button, ButtonToolbar, Card, 
    Form, Col, Row, axios, Container, Banner, Typeahead, TimePicker,
    cellEditFactory, Type, moment, NavLink, Alert, AppConfiguration,
    NoserLoading, Noser, sizePerPageRenderer, Modal, DatePicker, NumberFormat, Image
} 
from '../../../noser-hris-component';

import ReactToPrint from "react-to-print";
import paramountHeader from '../../../icon/contract-header.PNG'
import paramountFooter from '../../../icon/contract-footer.PNG'

class ComponentToPrint extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userinfo        :   [],
            isloading       :   false,
            isshow          :   false,
            alerttype       :   "",
            message         :   "",
            color           :   "",
            fade            :   true,      
            disable         :   true,

            employeeName    :   "",
            lastName        :   "",
            address         :   "",
            issueDate       :   "JANUARY 12, 2022",
            contractStart   :   "JANUARY 12, 2022",
            contractEnd     :   "JULY 12, 2022",
            monthlyRate     :   "11,000",
            positionName    :   "RECRUITMENT ASSOCIATE",
            branchName      :   "F004 - FORME BTQ FESTIVALL MALL ALABANG",
            paytype         :   "",
            employmentStatus:   "",
        }
    }
    componentDidMount(){
        this.state.userinfo = JSON.parse(sessionStorage.getItem("userData"))
        document.title = "Contract";

        let data = JSON.parse(sessionStorage.getItem("contract_" + this.state.userinfo.userId + this.state.userinfo.employeeId))
        console.log(data)
        this.setState({
            employeeName : data.employeeName,
            lastName:data.lastName,
            positionName : data.positionName,
            address : data.address,
            contractStart:moment(new Date(data.contractDateStart)).format('MMMM DD, YYYY'),
            contractEnd:moment(new Date(data.contractDateEnd)).format('MMMM DD, YYYY'),
            monthlyRate:data.salaryRate,
            issueDate : moment(new Date(data.issueDate)).format('MMMM DD, YYYY'),
            branchName: data.locationName,
            paytype:data.payType,
            employmentStatus:data.employmentStatus,
        })
    }
    render() {
        return( <>
            <Container className="mt-0 noser-a4-body-page" fluid>
                <div className='noser-a4-page'  style={{wordBreak:'break-word',whiteSpace:'-moz-pre-wrap'}}>
                <Form>
                <Col style={{marginLeft:'-30px', marginTop:"50px"}} >
                    <Image src={paramountHeader} style={{width:"900px", height:"200px"}} />
                </Col>
                <Form.Group as={Col} sm="12">                                    
                    <Form.Label row sm="1" style={{fontFamily:'Times New Roman',fontWeight : "bold" ,fontSize: 18,marginTop:"5px", marginLeft:"31%"}}>
                        MEMBERSHIP AND EMPLOYMENT AGREEMENT
                    </Form.Label>                            
                </Form.Group>
                <Form.Group as={Row} controlId="formPlaintextEmail" className="mt-3">
                    <Col>
                        <Form.Label className="no-text-transform" row style={{fontFamily:'Times New Roman',fontWeight : "bold", fontSize: 18}}>
                        MR./MS. &nbsp;{this.state.employeeName}
                        </Form.Label>  
                    </Col>                                    
                </Form.Group>
                <Form.Group as={Row} controlId="formPlaintextEmail">
                    <Col>
                        <div>
                            <div style={{width:"100px",float:"left",marginRight:"2px"}}>
                            <span style={{fontFamily:'Times New Roman',textDecoration:'underline',fontWeight : "bold", fontSize: 18, marginTop:"-10px",whiteSpace: "pre-wrap" }}>
                            ADDRESS : 
                            </span>
                            </div>
                            <div style={{width:"700px",float:"left",height:"70px",whiteSpace: "pre-wrap"}}>
                                <span style={{fontFamily:'Times New Roman',textDecoration:'underline',fontWeight : "bold", fontSize: 18, marginTop:"-10px",whiteSpace: "pre-wrap" }}>
                                {this.state.address}
                            </span> 
                            </div>
                        </div>
                        {/* <span style={{background:"green",height:"40px",fontFamily:'Times New Roman',textDecoration:'underline',fontWeight : "bold", fontSize: 18, marginTop:"-10px",whiteSpace: "pre-wrap" }}>
                            {this.state.address}
                        </span> 
                        </Form.Label> 
                        {<Form.Label row style={{fontFamily:'Times New Roman',fontWeight : "bold", fontSize: 18, marginTop:"-10px" }}>
                        <span style={{fontFamily:'Times New Roman',textDecoration:'underline',fontWeight : "bold", fontSize: 18, marginTop:"-10px",whiteSpace: "pre-wrap" }}>ADDRESS: &nbsp;</span>
                        <div>
                        
                        </div>
                         <span style={{background:"green",height:"40px",fontFamily:'Times New Roman',textDecoration:'underline',fontWeight : "bold", fontSize: 18, marginTop:"-10px",whiteSpace: "pre-wrap" }}>
                            {this.state.address}
                        </span>
                        </Form.Label>   */}
                    </Col>                                    
                </Form.Group>

                <Form.Group as={Row} controlId="formPlaintextEmail" className="mt-5">
                    <Col>
                        <Form.Label className="no-text-transform" row style={{fontFamily:'Times New Roman',fontWeight : "bold", fontSize: 18}}>
                            Dear Mr / Ms.&nbsp;{this.state.lastName}
                        </Form.Label>  
                    </Col>                                    
                </Form.Group>

                <Form.Group as={Row} controlId="formPlaintextEmail" className="mt-3">
                    <Col>
                        <Form.Label className="no-text-transform" row style={{ fontFamily:'Times New Roman',fontSize: 18, whiteSpace: "pre-wrap",fontWeight : "bold", }}>
                            Congratulations!
                        </Form.Label>  
                    </Col>                                    
                </Form.Group>

                <Form.Group as={Row} controlId="formPlaintextEmail" className="mt-3">
                    <Col>
                        <p style={{ fontFamily:'Times New Roman',textAlign:'justify',fontSize: 18,whiteSpace: "pre-line" }}>
                        Your application for membership with Paramount Human Resource Multi-Purpose Cooperative (PHRMPC) has been approved and accepted by the Board of Directors, subject to the terms and conditions of the cooperative By-Laws, and to the payment of P 1,500.00 membership fee.
                        </p>
                    </Col>                                    
                </Form.Group>

                <Form.Group as={Row} controlId="formPlaintextEmail" className="mt-3">
                    <Col>
                        <p style={{ fontFamily:'Times New Roman',textAlign:'justify',fontSize: 18,whiteSpace: "pre-wrap" }}>
                        One of the cooperative’s lines of business is to provide services under service contracts. 
                        In relation to this, PHRMPC derives its income from capital build up of members and from service fees paid as a result of said service contracts for services rendered by the cooperative. 
                        Apart from being a member of the cooperative, you are also now an employee of the cooperative and you may be assigned, if you so choose, to serve under one of the cooperative’s various service agreements. 
                        Note, however, that you will not be entitled to a share service surplus or service fee (your compensation for services to be rendered) unless and until you are assigned to serve under a service contract 
                        and only for the duration of each assignment, unless you perform other services for the cooperative in another official capacity as approved by the cooperative. If you are interested in any of the different endeavors that 
                        PHRMPC has with its various service contracts, you as member-owner-employee are entitled to priority of assignment. You may apply for assignment to any of the clients in writing which shall be subject to approval by the 
                        Board of Directors depending upon your qualifications and the required services of a particular service contract. Upon approval you will received an ASSIGNMENT NOTE, which shall indicate the name and address where you render services, 
                        the nature of the service to be rendered, the service fees, the duration of your assignment, which shall be co-terminus with the particular service contract, and other details as may be necessary.
                        </p>
                    </Col>                                    
                </Form.Group>

                <Form.Group as={Row} controlId="formPlaintextEmail" className="mt-3">
                    <Col>
                        <p style={{ fontFamily:'Times New Roman',textAlign:'justify',fontSize: 18,whiteSpace: "pre-wrap" }}>
                            You are entitled to withdraw your shares/dividends from the earnings of the cooperative in proportion to your contributions and service fees on an annual basis, subject to the provisions of the PHRMPC By-Laws and the Cooperative Code of the Philippines. However, upon your written request, you may avail of cash advances from your available cooperative shares not more than twice per month.
                        </p>
                    </Col>                                    
                </Form.Group>

                <Form.Group as={Row} controlId="formPlaintextEmail" >
                    <Col>
                        <Form.Label className="no-text-transform" row style={{ fontFamily:'Times New Roman',fontSize: 18, whiteSpace: "pre-wrap",marginTop:"30px" }}>
                        Very truly yours,
                        </Form.Label>  
                    </Col>                                    
                </Form.Group>
                <h1 style={{backgroundColor:'#000000', width:"28%", height:"5px",marginTop:"40px" }} > </h1>
                <Form.Group as={Row} controlId="formPlaintextEmail" className="mt-3">
                    <Col>
                        <Form.Label className="no-text-transform" row style={{ fontFamily:'Times New Roman',fontSize: 18, marginLeft:"60px",whiteSpace: "pre-wrap" }}>
                        HR Admin OIC
                        </Form.Label>  
                    </Col>                                    
                </Form.Group>
                <Form.Group as={Row} controlId="formPlaintextEmail" className="ml-auto">
                    <Col>
                        <Form.Label className="no-text-transform" row style={{ fontFamily:'Times New Roman',fontSize: 18, marginLeft:"85%",whiteSpace: "pre-wrap" }}>
                        Received Copy:
                        </Form.Label>  
                    </Col>                                    
                </Form.Group>
                <h1  style={{backgroundColor:'#000000', width:"30%", height:"5px",marginLeft:"70%",marginTop:"40px" }} > </h1>
                <Form.Group as={Row} controlId="formPlaintextEmail" >
                    <Col>
                        <Form.Label className="no-text-transform" row style={{ fontFamily:'Times New Roman',fontSize: 18, marginLeft:"72%",whiteSpace: "pre-wrap" }}>
                        Signature Over Printed Name
                        </Form.Label>  
                    </Col>                                    
                </Form.Group>
                <Col>
                    <Image src={paramountFooter} 
                        style={{width:"400px", 
                                height:"100px", 
                                marginLeft:'200px',
                                marginTop:"10px"}}/>
                </Col>
                <Col>
                    <Image src={paramountHeader} 
                        style={{width:"900px",
                                height:"200px", 
                                textAlign:"center",
                                justifyContent:"center",
                                marginLeft:'-75px', 
                                marginTop:"50px"}} />
                </Col>

                <Form.Group as={Col} sm="12">                                    
                    <Form.Label row sm="1" style={{fontFamily:'Times New Roman',textDecoration:'underline',fontWeight : "bold" ,fontSize: 18,marginTop:"5px", marginLeft:"50%"}}>
                        CONTRACT
                    </Form.Label>                            
                </Form.Group>

                <Form.Group as={Row} controlId="formPlaintextEmail" className="mt-3">
                    <Col>
                        <Form.Label className="no-text-transform" row style={{fontFamily:'Times New Roman',fontWeight : "bold",fontSize: 18}}>
                        MR./MS. &nbsp;{this.state.employeeName}
                        </Form.Label>  
                    </Col>                                    
                </Form.Group>
                <Form.Group as={Row} controlId="formPlaintextEmail" className="mt-3">
                    <Col>
                        <Form.Label className="no-text-transform" row style={{fontFamily:'Times New Roman',fontWeight : "bold", fontSize: 18}}>
                        ADDRESS: &nbsp;
                        <span style={{fontFamily:'Times New Roman',textDecoration:'underline',fontWeight : "bold", fontSize: 18, marginTop:"-10px" }}>
                            {this.state.address}
                        </span>
                        </Form.Label>  
                    </Col>                                    
                </Form.Group>
                <Form.Group as={Row} controlId="formPlaintextEmail" className="mt-3">
                    <Col>
                        <Form.Label className="no-text-transform" row style={{fontFamily:'Times New Roman',fontWeight : "bold", fontSize: 18, marginTop:"30px" }}>
                        DATE: &nbsp;{this.state.contractStart}
                        </Form.Label>  
                    </Col>                                    
                </Form.Group>

                <Form.Group as={Row} controlId="formPlaintextEmail" className="mt-3">
                    <Col>
                        <p style={{ fontFamily:'Times New Roman',textAlign:'justify',fontSize: 18,whiteSpace: "pre-wrap" }}>
                        Your written request for assignment, together with your Bio-Data Sheet, were evaluated and you have met the qualification requirements which have been determined by the cooperative as sufficient for services to be rendered by the cooperative under a service contract. A specific description of the work/services that you will perform is attached hereto and made and integral part hereof as Annex “A.”
                        </p>
                    </Col>                                    
                </Form.Group>
                <Form.Group as={Row} controlId="formPlaintextEmail" className="mt-3">
                    <Col>
                        <p style={{ fontFamily:'Times New Roman',textAlign:'justify',fontSize: 18,whiteSpace: "pre-wrap" }}>
                        <span className="no-text-transform" style={{fontFamily:'Times New Roman',fontWeight:'bold',textAlign:'justify',fontSize: 18,whiteSpace: "pre-wrap"}}>
                        Place of Assignment.
                        </span>&nbsp;
                        You shall render services&nbsp; 
                        <span className="no-text-transform" style={{fontFamily:'Times New Roman',textDecoration:'underline',fontWeight:'bold',textAlign:'justify',fontSize: 18,whiteSpace: "pre-wrap"}}>
                        {this.state.branchName} 
                        </span>&nbsp;
                        which shall serve as your place of work/assignment.
                        </p>
                    </Col>                                    
                </Form.Group>
                <Form.Group as={Row} controlId="formPlaintextEmail" className="mt-3">
                    <Col>
                        <p style={{ fontFamily:'Times New Roman',textAlign:'justify',fontSize: 18,whiteSpace: "pre-wrap" }}>
                        <span className="no-text-transform" style={{fontFamily:'Times New Roman',fontWeight:'bold',textAlign:'justify',fontSize: 18,whiteSpace: "pre-wrap"}}>
                        Duration of Assignment: 
                        </span>&nbsp;
                        You shall render services from &nbsp;
                        <span className="no-text-transform" style={{fontFamily:'Times New Roman',textDecoration:'underline',fontWeight:'bold',textAlign:'justify',fontSize: 18,whiteSpace: "pre-wrap"}}>
                        {this.state.contractStart} TO {this.state.contractEnd}
                        </span>&nbsp;
                        <span className="no-text-transform" style={{fontFamily:'Times New Roman',fontWeight:'bold',textAlign:'justify',fontSize: 18,whiteSpace: "pre-wrap"}}>
                        ({this.state.employmentStatus}) 
                        </span>&nbsp;
                        with the term or period of the service contract to which you are assigned, unless the said service contract is earlier terminated. Upon the expiration of the term or period afore-stated, you shall report to the cooperative for possible re-assignment.
                        </p>
                    </Col>                                    
                </Form.Group>
                <Form.Group as={Row} controlId="formPlaintextEmail" className="mt-3">
                    <Col>
                        <p style={{ fontFamily:'Times New Roman',textAlign:'justify',fontSize: 18,whiteSpace: "pre-wrap" }}>
                        <span className="no-text-transform" style={{fontFamily:'Times New Roman',fontWeight:'bold',textAlign:'justify',fontSize: 18,whiteSpace: "pre-wrap"}}>
                        Service Fee: 
                        </span>&nbsp;
                        Your service fee for this particular assignment payable by the cooperative shall be &nbsp;
                        <span className="no-text-transform" style={{fontFamily:'Times New Roman',textDecoration:'underline',fontWeight:'bold',textAlign:'justify',fontSize: 18,whiteSpace: "pre-wrap"}}>
                        Php {this.state.monthlyRate} per {this.state.paytype}, 
                        </span>&nbsp;
                        payable on a bi-monthly basis, which in no case shall be less than the applicable minimum wage for the region where you will be assigned. Should there be any further increases in the applicable minimum wages, where the service fee falls below the minimum wage, the service fee specified herein shall be deemed to be automatically adjusted upwards to comply with the minimum wage. 
                        </p>
                    </Col>                                    
                </Form.Group>
                <Form.Group as={Row} controlId="formPlaintextEmail" className="mt-3">
                    <Col>
                        <p style={{ fontFamily:'Times New Roman',textAlign:'justify',fontSize: 18,whiteSpace: "pre-wrap" }}>
                        <span className="no-text-transform" style={{fontFamily:'Times New Roman',fontWeight:'bold',textAlign:'justify',fontSize: 18,whiteSpace: "pre-wrap"}}>
                        Work Schedule: 
                        </span>&nbsp;
                        You are to render your services as &nbsp;
                        <span className="no-text-transform" style={{fontFamily:'Times New Roman',textDecoration:'underline',fontWeight:'bold',textAlign:'justify',fontSize: 18,whiteSpace: "pre-wrap"}}>
                        {this.state.positionName}
                        </span>&nbsp;
                        subject to the work schedule as the cooperative may assign to you, but in no case shall it be more than eight (8) hours per day, or forty-eight (48) hours for six (6) days per week, with one (1) rest day as the cooperative may assign to you.  You may be required by the cooperative to render overtime services, and for this purpose, a written authorization will be issued to you. The additional premium for the said overtime work shall be paid accordingly. Your specific working schedule is attached hereto and made and integral part hereof as Annex “B”
                        </p>
                    </Col>                                    
                </Form.Group>
                <Form.Group as={Row} controlId="formPlaintextEmail" className="mt-3">
                    <Col>
                        <p style={{ fontFamily:'Times New Roman',textAlign:'justify',fontSize: 18,whiteSpace: "pre-wrap" }}>
                        <span className="no-text-transform" style={{fontFamily:'Times New Roman',fontWeight:'bold',textAlign:'justify',fontSize: 18,whiteSpace: "pre-wrap"}}>
                        Transfer and Reassignments:  
                        </span>&nbsp;
                        It is understood that you are available for re-assignment or transfer in any work station or service contract as may be determined as reasonable and necessary by the cooperative. 
                        </p>
                    </Col>                                    
                </Form.Group>
                
                <Form.Group as={Row} controlId="formPlaintextEmail" className="mt-3">
                    <Col>
                        <p style={{ fontFamily:'Times New Roman',textAlign:'justify',fontSize: 18,whiteSpace: "pre-wrap" }}>
                        Other terms and conditions of this particular Assignment:
                        </p> 
                    </Col>                                    
                </Form.Group>

                <Form.Group as={Row} controlId="formPlaintextEmail" className="mt-3">
                    <Col>
                        <ol>
                            <p>
                            <li className="no-text-transform-li" style={{fontFamily:'Times New Roman',listStyle:'none', textAlign:'justify', marginLeft:"60px",fontSize: 18,whiteSpace: "pre-wrap"}}>
                            a) For purposes of maintaining safety, order, and security in the work premises, you shall abide by the applicable rules and regulations on workplace behavior depending on the premises where you will be working;
                            </li>
                            </p>
                        </ol>
                    </Col>                                    
                </Form.Group>
                <Col>
                    <Image src={paramountFooter} 
                        style={{width:"400px", 
                                height:"100px", 
                                marginLeft:'200px',
                                marginTop:"110px"}}/>
                </Col>
                <Col>
                    <Image src={paramountHeader} 
                        style={{width:"900px",
                                height:"200px", 
                                textAlign:"center",
                                justifyContent:"center",
                                marginLeft:'-75px', 
                                marginTop:"50px"}} />
                </Col>
                <Form.Group as={Row} controlId="formPlaintextEmail" className="mt-3">
                    <Col>
                        <ol>
                            <p>
                            <li className="no-text-transform-li" style={{fontFamily:'Times New Roman',listStyle:'none', textAlign:'justify', marginLeft:"60px",fontSize: 18,whiteSpace: "pre-wrap"}}>
                            b) You shall be subject to the “conflict of interest rule,” hence, you shall not accept any benefit or payment in return for favors, discounts, and other considerations granted by suppliers, customers, and other third parties.  You are prohibited from accepting other employments or consultancy work from other employers who are competitors of the recipient of the cooperative’s services, or those whose interest are otherwise in conflict 
                            with those of the said recipient, during the period of your assignment and for a period of one (1) year thereafter.
                            </li>
                            </p>

                            <p>
                            <li className="no-text-transform-li" style={{fontFamily:'Times New Roman',listStyle:'none', textAlign:'justify', marginLeft:"60px",fontSize: 18,whiteSpace: "pre-wrap"}}>
                            c) You shall at all times comply with the rules of CONFIDENTIALITY.  Thus, you cannot divulge any information you have become privy to as a result your assignment.
                            </li>
                            </p>
                            <p>
                            <li className="no-text-transform-li" style={{fontFamily:'Times New Roman',listStyle:'none', textAlign:'justify', marginLeft:"60px",fontSize: 18,whiteSpace: "pre-wrap"}}>
                            d) Your membership with the cooperative means that you are a part-owner thereof but you are also an employee of the cooperative as per the ruling in Republic of the Philippines vs. Asiapro Cooperative, G.R. No. 172101, 23 November 2007. Therefore, you are not qualified to form or join the labor union or workers’ organization pertaining to any other company’s employees for the purpose of collective bargaining with such company. Any violation of this rule may result in the termination of your membership with the cooperative and consequently, your assignment may also be recalled.
                            </li>
                            </p>

                            <p>
                            <li className="no-text-transform-li" style={{fontFamily:'Times New Roman',listStyle:'none', textAlign:'justify', marginLeft:"60px",fontSize: 18,whiteSpace: "pre-wrap"}}>
                            e) Your employment and therefore your assignment may be terminated by the cooperative for any just or authorized cause, with observance of due process in either case. In case you decide to resign, you will shall conform to the 30 day prior notice for the effectivity of your resignation.
                            </li>
                            </p>
                        </ol>
                    </Col>                                    
                </Form.Group>
                <Form.Group as={Row} controlId="formPlaintextEmail" className="mt-3">
                    <Col>
                        <p style={{ fontFamily:'Times New Roman',textAlign:'justify',fontSize: 18,whiteSpace: "pre-wrap" }}>
                        Any violation of the foregoing terms and conditions aforementioned shall constitute as a ground for the termination of your employment.
                        </p>
                    </Col>                                    
                </Form.Group>
                <Form.Group as={Row} controlId="formPlaintextEmail" className="mt-3">
                    <Col>
                        <p style={{ fontFamily:'Times New Roman',textAlign:'justify',fontSize: 18,whiteSpace: "pre-wrap" }}>
                        If you are amenable to the above terms and conditions of your Service contract, please indicate your conformity therewith by affixing your signatures at the space provide below.
                        </p>
                    </Col>                                    
                </Form.Group>
                <Col sm="12">
                    <Image src={paramountFooter} 
                        style={{width:"400px", 
                                height:"100px", 
                                marginLeft:'210px',
                                marginTop:"460px"}}/>
                </Col>
                </Form>
                </div>
            </Container>
            <NoserLoading show={this.state.isloading} />
            </>
        )
    }
}
class Contract extends React.Component {
    render() {
      return (
        <div style={{backgroundColor:"#FFF",height:"100%"}}>
            <div style={{width:"100%"}}>
                <Col sm="3" className="noser-button" as={Row} style={{width:"150px",
                                                height:"25px",
                                                marginLeft:"auto",
                                                marginRight:"auto"}}>
                    <ReactToPrint
                        trigger={() => <button style={{width:"150px",
                                                height:"25px",
                                                marginLeft:"auto",
                                                marginRight:"auto",
                                                color:"#ffffff",
                                                fontWeight:"bold",
                                                backgroundColor:"rgb(8, 139, 30)",
                                                border:".5pt solid rgba(17, 99, 31, 0.644)"}}>Print Preview</button>}
                        content={() => this.componentRef}
                    />
                </Col>
            </div>
            <ComponentToPrint ref={(el) => (this.componentRef = el)} />    
        </div>
      );
    }
  }
export  default Contract