import {
    React,Component,BootstrapTable,
    paginationFactory,Button, ButtonToolbar, Card, 
    Form, Col, Row, axios, Container, Banner, Typeahead, TimePicker,
    cellEditFactory, Type, moment, NavLink, Alert, AppConfiguration,
    NoserLoading, Noser, sizePerPageRenderer, DatePicker, Tabs, Tab, Redirect, 
} 
from '../../noser-hris-component';
import { DropdownButton, Dropdown, Image} from 'react-bootstrap';

import CreateCaseModal from './CreateCaseModal';
//import AttachmentModal from './AttachmentModal';
//import Lightbox from 'react-image-lightbox';
import ImgsViewer from 'react-images-viewer'
import { async } from 'q';

import { GetRequest, PostRequest } from '../../noser-dataaccess';

 
class CaseScreen extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fade            :   true, 
            color           :   "",
            isshow          :   false,
            message         :   "",
            userinfo        :   [],
            isloading       :   false,
            alerttype       :   "",
            viewerIsOpen    :   false,
            viewerIsOpenCase: 	false,

            caseData        : [],
            replyId         : [],
            caseId          : "",
            title           : "",
            user            : "",
            reply           : "",
            type            : "",
            description     : "",
            deptAccess      : "",
            createddate     : "",
            createdby       : "",
            valueKey        : "",
            userId          : "",
            navigate        : "",
            CreateCaseModal :   false,
            userFirstName   : "",
            roleId          : "",
            fullName        : "",
            Attachment      : false,
            forCommentImage : [],
            commentId       : "",
            image           : [],
            getImage        : [],
            idImage         : [],
            getImageReply   : [],
            storeDummyImage : [],
            viewCommentImage: [],
            getreplyidtmp   : "",

            unKnownData         :   [],
            viewConcernDataInfo :   [],
            caseReplyList       :   [],
            getCaseReplyListArr : [],

            forCommentImage2 : [],
			itemCheck : false,
        }
        this.onImageReplyChange = this.onImageReplyChange.bind(this);
        //this.onKeyUp = this.onKeyUp.bind(this);
    };

    componentDidMount(){
        this.state.viewConcernDataInfo = JSON.parse(sessionStorage.getItem("viewConcernData"))
        console.log(this.state.viewConcernDataInfo)
        this.setState({
            priorityLevel: this.state.viewConcernDataInfo.priorityLevel,
            deptAccess: this.state.viewConcernDataInfo.deptAccess,
            status: this.state.viewConcernDataInfo.status,
            type: this.state.viewConcernDataInfo.type,
            title: this.state.viewConcernDataInfo.title,
            description: this.state.viewConcernDataInfo.description,
            createddate: this.state.viewConcernDataInfo.createddate,
            createdby: this.state.viewConcernDataInfo.createdby,
            caseId: this.state.viewConcernDataInfo.id,
        })

        this.getCase();
        // this.GetLastImageId();
        //this.GetCaseReply();

        const sleep = (milliseconds) => {return new Promise(resolve => setTimeout(resolve, milliseconds))}
        this.state.userinfo = JSON.parse(sessionStorage.getItem("userData"))

        //////// console.log(this.props.location.params.data)
        this.setState({
            userId: this.state.userinfo.userId,
            userFirstName: this.state.userinfo.firstName,
            roleId: this.state.userinfo.roleId,
            fullName: this.state.userinfo.fullName,
        })
        // this.getImageReply();
        // this.showAttachmentModal()
        
        
    };

    

    callUpdateButton = () => {
        const obj = {
            updateKey       : "1"
        }

        this.child.initialize(obj)
        let userId = JSON.parse(sessionStorage.getItem("userData"))
        let filter_data = {}
            filter_data = {"createdby" : userId}
           sessionStorage.setItem("CreateUpdateKey", JSON.stringify(1))
            this.setState({CreateCaseModal: true})

            
    };

    showAttachmentModal = () => {
        // alert("test")
        let newId = this.state.viewConcernDataInfo.id
        // console.log("start main case image")
        // console.log("1")
        //// console.log(newId)
            let filter_data ={ "concernId": newId }  
    
            const getParams ={
            "_collection" : "ImageCase",
            "filter_data" : filter_data
            }
            // console.log("2")
            axios
            .post("http://43.255.218.198:8086/action/get" , getParams)
            .then(res =>{
                let data = res.data
				if (data["ImageCase"].length === 0){
					alert("no attachment")
					return this.setState({ isloading : false })
				}else{
                
					const itemUsersDataLists = this.buildListImage(data["ImageCase"])
					
					this.state.itemUserDataList  =  itemUsersDataLists
					console.log(this.state.itemUserDataList)
					console.log("this.state.itemUserDataList")
					let List =[]
					//////// console.log("3")
					for (let i = 0; i < this.state.itemUserDataList.length; i++) {
						let obj = {
							
							'image'         :   this.state.itemUserDataList[i].replace(" '","").replace("'",""),
						 
						}
		
						List.push(obj)
		
						this.setState({
							image           : obj.image,
						})
						let newImagetmp = atob(this.state.image)

						let secondImage = {src: 'data:image/jpeg;base64,' + newImagetmp}

						let finalImage = this.state.getImage

						finalImage.push(secondImage)

						this.setState({getImage: finalImage})
						//this.setState({getImage: newImagetmp})

						this.setState({viewerIsOpenCase: true})
						// console.log(this.state.getImage)
						// console.log("asdfg")
					}
				}

            })
            .catch(error=>{
                this.setState({
                    isloading   :   false,
                    alerttype   :   "Error!",
                    //isshow      :   true,
                    color       :   "danger",
                    message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                    fade        :   true
                })
            })
            //this.getcommentId(newId)
            // console.log("end main case image")
        } 
        
    
        buildListImage = (data) => {
    
            let itemList =[]

            //// console.log(data)
            for (let i = 0; i < data.length; i++) {
    
                
                let s1 = data[i].split("'image': [{")
                // // console.log(s1)
                let s2 = s1[1].split("}], ")
                // // console.log(s2)
                let s3 = s2[0].split("}, {'uri': ")
                // // console.log(s3)
                let uri1 = s3[0].replace("'uri': '", "")
                // // console.log(uri1)
                itemList.push(uri1.replace("'","").replace("'",""))  
    
                for (let x =1; x < s3.length; x++) {
                        
                    itemList.push(s3[x].replace("'","").replace("'",""))
    
                }
            }
            // // console.log(itemList)
            return itemList
        };

    closeViewer = () => {
        this.setState({viewerIsOpenCase: false, currImg: 0})
    };
    
    closeModal = (e) => {

        this.setState({CreateCaseModal:false,isloading:false})
        window.location.reload();
       
    };

    closeModalAttachment = (e) => {

        this.setState({Attachment:false})

    };

    GridDataModified(oldValue, newValue, id, column) {
        //////// console.log(id)
        this.state.caseData.map(function(item,i) {
            if (item.id===id)
                item.isModified = newValue!=oldValue ? "1" : "0"
        })
    };

    onChangereply = (e) => {

        this.setState({reply: e.target.value})

    };

    formatDate(date) {
        var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();
    
        if (month.length < 2) 
            month = '0' + month;
        if (day.length < 2) 
            day = '0' + day;
        return [month, day, year].join('/');
    };


    /**************** case Reply  ************************/

    
    

    submitCaseReply = async (e) => {
        ////// console.log(this.state.caseId)
        //this.getReplyId();
        let today = new Date()
        //let newId = moment(today).format('HHMMSS');
        
        
       this.setState({isloading: true})

        const data ={
            "caseId"        :   this.state.caseId,
            "reply"         :   this.state.reply,
            "userfname"     :   this.state.userFirstName,
            "isDeleted"     :   "0",
            "isDraft"       :   "0",
            "createdby" 	:   this.state.userId,
            "createddate"   :   this.formatDate(new Date()),
            "modifiedby"    :   this.state.userId,
            "modifieddate" 	:   this.formatDate(new Date()),
            "isModified"    :   "0",

        
        }
       
        //// console.log("submit casereply params")
        //// console.log(data)
        this.setState({ imageNewId: data.caseId})
        const addParams = {
            "_collection" : "CaseReply",
            "doc_data"    : data
        }

        axios
        .post("http://43.255.218.198:8086/action/add", addParams)
        .then(res =>{
            if(this.state.itemCheck == true){
				// console.log("if")
				this.getReplyId()
				// this.sendReplyImage()
				this.fileInput.value = ""

			}else{
				// // // console.log("this.state.itemCheck == false")
				// console.log("else")
                this.setState({
                    fade:   true,
                    isshow: true,
                    alerttype:   "Success!",
                    color: "success",
                    isloading: false,
                    message: "Successfully Saved",
                    //check: true,
                    //isDisbaledUpload : true,
					reply: "",
					forCommentImage : ""
                })

				this.fileInput.value = ""
				this.GetCaseReply()
			}
            
        })
        .catch(error=>{
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade        :   true
            })
        })

        
        
        //Alert.alert('File Saved')
    };


    /****************** Get Case ***************************/
    getCase (){
        this.GetCaseReply();
    }; 

    GetCaseReply  () {
		// console.log("get case reply")
        let idTmp = JSON.parse(sessionStorage.getItem("viewConcernData"))
        let getIdtmp = idTmp.id
        ////// console.log(idTmp.id)
        ////// console.log('idTmp.id')
        // console.log(" start reply case ")
        // // console.log("1")
        let filter_data = {"caseId" : getIdtmp}
    
        const getParams = {
          "_collection" : "CaseReply",
          "filter_data" : filter_data
        }
        
        // // console.log("2")
        axios
        .post("http://43.255.218.198:8086/action/get" , getParams)
        .then(res => {
            const data = res.data
			// console.log(data)
			// console.log(data)
            const caseReplyListTmp = this.buildListReply(data["CaseReply"])
            let getCaseReplyListArr =[]
            for (let i = 0; i < caseReplyListTmp.length; i++) {
                let obj = {
                    'id'            :   caseReplyListTmp[i]['id'].replace("' ","").replace("'",""),              
                    'caseId'        :   caseReplyListTmp[i]['caseId'].replace("' ","").replace("'",""),
                    'reply'         :   caseReplyListTmp[i]['reply'].replace("' ","").replace("'",""),
                    'userfname'     :   caseReplyListTmp[i]['userfname'].replace("' ","").replace("'",""),
                    'isDeleted'     :   caseReplyListTmp[i]['isDeleted'].replace("' ","").replace("'",""),
                    'isDraft'       :   caseReplyListTmp[i]['isDraft'].replace("' ","").replace("'",""),
                    'createdby'     :   caseReplyListTmp[i]['createdby'].replace("' ","").replace("'",""),
                    'createddate'   :   caseReplyListTmp[i]['createddate'].replace("' ","").replace("'",""),
                    'modifiedby'    :   caseReplyListTmp[i]['isModified'].replace("' ","").replace("'",""),
                    'modifieddate'  :   caseReplyListTmp[i]['modifieddate'].replace("' ","").replace("'",""),
                    'isModified'    :   caseReplyListTmp[i]['isModified'].replace("' ","").replace("'",""),
                    
                }
                getCaseReplyListArr.push(obj)
                // // console.log(getCaseReplyListArr)
                ////// console.log(obj.id)
                let replyidTmp = obj.id
                this.setState({ replyId : replyidTmp})
                ////// console.log(this.state.replyId)
                ////// console.log("final id")
                
            }
            
            this.setState({
                caseReplyList : getCaseReplyListArr,
                isshow:false,
                reply : '',
				itemCheck :  false,
    
            })
            
        })
        .catch(error=>{
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade        :   true
            })
        })

        // console.log("end reply case")

    }

    buildListReply(data) {
        ////// console.log('1')
        //// console.log(data)
        //// console.log("data")
        let itemList =[]
    
        let idList              =[]
        //let commentIdList       =[]
        let caseIdList          =[]
        let replyList           =[]
        let userfnameList       =[]
        let isDeletedList       =[]
        let isDraftList         =[]
        let createdbyList       =[]
        let createddateList     =[]
        let modifiedbyList      =[]
        let modifieddateList    =[]
        let isModifiedList      =[]
         
        ////// console.log(data)
        
        for (let i = 0; i < data.length; i++) {
            let s1 = data[i].split("':")

            //// console.log(s1)
            //// console.log("s1")
           
            let idClean = s1[1].replace("ObjectId(","").replace(")","").replace("{","")
            let IdClean2            = idClean.split("',")[0]
            let caseIdListTmp          = s1[2].split("',")[0]
            let replyListTmp           = s1[3].split("',")[0]
            let userfnameListTmp       = s1[4].split("',")[0]
            let isDeletedListTmp      = s1[5].split(",")[0]
            let isDraftListTmp         = s1[6].split("',")[0]
            let createdbyListTmp         = s1[7].split("',")[0]
            let createddateListTmp         = s1[8].split("',")[0]
            let modifiedbyListTmp        = s1[9].split("',")[0]
            let modifieddateListTmp         = s1[10].split("',")[0]
            let isModifiedListTmp        = s1[11].split("',")[0]
            ////// console.log(isDraftList)

        
            idList.push(IdClean2.replace("'",""))
            caseIdList.push(caseIdListTmp.replace("'",""))
            replyList.push(replyListTmp.replace("'","").replace('"',""))
            userfnameList.push(userfnameListTmp.replace("'",""))
            isDeletedList.push(isDeletedListTmp.replace("'",""))
            isDraftList.push(isDraftListTmp.replace("'",""))
            createdbyList.push(createdbyListTmp.replace("'",""))
            createddateList.push(createddateListTmp.replace("'",""))
            modifiedbyList.push(modifiedbyListTmp.replace("'",""))
            modifieddateList.push(modifieddateListTmp.replace("'",""))
            isModifiedList.push(isModifiedListTmp.replace("'","").replace("'}",""))
        }
    
        for (let i = 0; i < idList.length; i++) {
    
            let obj = {
                "id"            :   idList[i].replace(" ",""),
                "caseId"        :   caseIdList[i].replace(" ",""),
                "reply"         :   replyList[i].replace(" ",""),
                "userfname"     :   userfnameList[i].replace(" ",""),
                "isDeleted"     :   isDeletedList[i].replace(" ",""),
                "isDraft"       :   isDraftList[i].replace(" ",""),
                "createdby"     :   createdbyList[i].replace(" ",""),
                "createddate"   :   createddateList[i].replace(" ",""),
                "modifiedby"    :   modifiedbyList[i].replace(" ",""),
                "modifieddate"  :   modifieddateList[i].replace(" ",""),
                "isModified"    :   isModifiedList[i].replace(" ",""),
            
            }
            itemList.push(obj)
        }
        // // console.log(itemList)
        // // console.log("itemList")
        return itemList
     
    };

    /************** End Get Case **************************/

    getReplyId (){

        let caseId = JSON.parse(sessionStorage.getItem("viewConcernData"))
        let commentIdTmp = caseId.id
        ////// console.log(commentIdTmp)
        ////// console.log("get case reply")

        let filter_data = {"caseId" : commentIdTmp}
        
        const getParams = {
          "_collection" : "CaseReply",
          "filter_data" : filter_data
        }

        axios
        .post("http://43.255.218.198:8086/action/get" , getParams)
        .then(res => {
            const data = res.data

            const itemUsersDataListsID = this.buildListReplyId(data["CaseReply"])
            this.state.itemUserDataListID   = itemUsersDataListsID

            for (let i = 0; i < this.state.itemUserDataListID.length; i++) {
                
                this.setState({
                    replyId: this.state.itemUserDataListID[i]['id'].replace(" '","").replace("'","").replace(" ","")
                })
               
            }
            //// console.log("qwerty")
            //// console.log(this.state.replyId)
            if(this.state.replyId != ""){
                // this.GetLastImageId() 
				this.sendReplyImage()
            }
        })
        .catch(error=>{
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade        :   true
            })
        }) 
             
    }

    buildListReplyId(data) {
        //// console.log("reply id")
        ////// console.log('1')
        let itemListTmp =[]
    
        let idList              =[]
        //let commentIdList       =[]
        let caseIdList          =[]
        let replyList           =[]
        let userfnameList       =[]
        let isDeletedList       =[]
        let isDraftList         =[]
        let createdbyList       =[]
        let createddateList     =[]
        let modifiedbyList      =[]
        let modifieddateList    =[]
        let isModifiedList      =[]
         
        ////// console.log(data)
        
        for (let i = 0; i < data.length; i++) {
            let s1 = data[i].split("':")
           
            let idClean                 = s1[1].replace("ObjectId(","").replace(")","").replace("{","")
            let IdClean2                = idClean.split("',")[0]
            let caseIdListTmp           = s1[2].split("',")[0]
            let replyListTmp            = s1[3].split("',")[0]
            let userfnameListTmp        = s1[4].split("',")[0]
            let isDeletedListTmp        = s1[5].split(",")[0]
            let isDraftListTmp          = s1[6].split("',")[0]
            let createdbyListTmp        = s1[7].split("',")[0]
            let createddateListTmp      = s1[8].split("',")[0]
            let modifiedbyListTmp       = s1[9].split("',")[0]
            let modifieddateListTmp     = s1[10].split("',")[0]
            let isModifiedListTmp       = s1[11].split("',")[0]
            ////// console.log(isDraftList)

        
            idList.push(IdClean2.replace("'",""))
            caseIdList.push(caseIdListTmp.replace("'",""))
            replyList.push(replyListTmp.replace("'",""))
            userfnameList.push(userfnameListTmp.replace("'",""))
            isDeletedList.push(isDeletedListTmp.replace("'",""))
            isDraftList.push(isDraftListTmp.replace("'",""))
            createdbyList.push(createdbyListTmp.replace("'",""))
            createddateList.push(createddateListTmp.replace("'",""))
            modifiedbyList.push(modifiedbyListTmp.replace("'",""))
            modifieddateList.push(modifieddateListTmp.replace("'",""))
            isModifiedList.push(isModifiedListTmp.replace("'","").replace("'}",""))
        }
    
        for (let i = 0; i < idList.length; i++) {
    
            let obj = {
                "id"            :   idList[i],
                "caseId"        :   caseIdList[i],
                "reply"         :   replyList[i],
                "userfname"     :   userfnameList[i],
                "isDeleted"     :   isDeletedList[i],
                "isDraft"       :   isDraftList[i],
                "createdby"     :   createdbyList[i],
                "createddate"   :   createddateList[i],
                "modifiedby"    :   modifiedbyList[i],
                "modifieddate"  :   modifieddateList[i],
                "isModified"    :   isModifiedList[i],
            
            }
            itemListTmp.push(obj)
        }
        //// console.log(itemListTmp)        
        //// console.log("asd")
        return itemListTmp
     
    };

    /************** get case reply ************/


    
    /*************** end reply item ************************/

    /*************** reply image  **************************/
   
    onImageReplyChange(e) {
        let reader = new FileReader();
    
        reader.onload = () =>   {            
            this.testImageReplyStr(reader.result)
        };
        if(e.target.files[0]){
            reader.readAsDataURL(e.target.files[0]);
          }
    };

    testImageReplyStr(result) {

        let _tmpClear = []
        this.setState({forCommentImage : _tmpClear})

        let newResult = result.replace("data:image/png;base64,","").replace("data:image/jpeg;base64,", "")

        let decoded = btoa(newResult)

        let getImageReply = this.state.forCommentImage

        let imagetmp = {uri:decoded}

        let imageReplyPush = imagetmp

        getImageReply.push(imageReplyPush)
        // getImageReply.push(decoded)

        this.setState({forCommentImage : getImageReply, itemCheck : true})
        // console.log(this.state.forCommentImage)

    };


    // GetLastImageId= async()=> {

        // let _count = 0
		// // console.log("get last image id")
        
        // let filter_data = {}
    
        // const getParams = {
          // "_collection" : "ImageReplyCase",
          // "filter_data" : filter_data
        // }
        
        // axios
        // .post("http://43.255.218.198:8086/action/get" , getParams)
        // .then(res => {
            // const data = res.data
            // _count = data["ImageReplyCase"].length + 1

            // this.setState({
                // idImage: _count
            // })
			// this.sendReplyImage()
        // })
        // .catch(error=>{
            // this.setState({
                // isloading   :   false,
                // alerttype   :   "Error!",
                // isshow      :   true,
                // color       :   "danger",
                // message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                // fade        :   true
            // })
        // })

    // };

    sendReplyImage = async () => {

        this.setState({forCommentImage2 : this.state.forCommentImage})
		const _imageID = moment(new Date()).format('YYYYMMDDHHMMSS');

        let data2 ={
        
            "imageId"       : _imageID,
            "image"         : this.state.forCommentImage,
            "commentId"     : this.state.replyId,
            "user"          : this.state.fullName,
            "isDeleted"     : "0",
            "isDraft"       : "0",
            "createdby" 	: this.state.userId,
            "createddate"   : this.formatDate(new Date()),
            "modifiedby"    : this.state.userId,
            "modifieddate" 	: this.formatDate(new Date()),
            "isModified"    : "0",
        }
        
        const addParams ={
            "_collection" : "ImageReplyCase",
            "doc_data"    : data2
        }

        console.log(data2)
        console.log("image")
        axios
        .post("http://43.255.218.198:8086/action/add", addParams)
        .then(res =>{
            this.GetCaseReply();
					
            this.setState({
                fade:   true,
                isshow: true,
                alerttype:   "Success!",
                color: "success",
                isloading: false,
                message: "Successfully Saved with Attachment",
                check: true,
                isDisbaledUpload : true,
				forCommentImage : "" }
			)
        })
        .catch(error=>{
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade        :   true
            })
        })

    };

    /********** End send image reply *************/


    //  Image Reply

    handleClickShowImage = async(item) => {
        // // console.log("start button reply image")
		// console.log(item)
		// // console.log(this.state.caseReplyList)
		// // console.log("casereply") "6312eec970b28a1cc9cf0ecc"
		this.setState({isloading : true})
        let filter_data ={"commentId" : item.id }

		const getParams ={
			"_collection" : "ImageReplyCase",
			"filter_data" : filter_data
		}
        // // console.log("2")
        
        axios
            .post("http://43.255.218.198:8086/action/get", getParams)
            .then(res =>{
               

				let data = res.data
				console.log(data)
				console.log(data["ImageReplyCase"].length === 0)
				if (data["ImageReplyCase"].length === 0){
					alert("no attachment")
					return this.setState({ isloading : false })
				}else{
					const itemUsersDataLists = this.buildListImageComment(data["ImageReplyCase"])  
					// console.log(itemUsersDataLists)
					// // console.log("itemUsersDataLists")
					// this.state.itemUserDataList  =  itemUsersDataLists
					let List =[]
					let newCommentList= []
					
					for (let i = 0; i < itemUsersDataLists.length; i++) {

						let obj = {
							
							'image'         :   itemUsersDataLists[i]["image"],
							'imageId'       :   itemUsersDataLists[i]["imageId"],
							
						}        
						List.push(obj) 

						let topushitem = []
						let decodedImage = atob(obj.image)
						// // console.log(decodedImage)
						// // console.log("decodedImage")

						let secondImageReply = {src: 'data:image/jpeg;base64,' + decodedImage}


						topushitem.push(secondImageReply)
						// // console.log(topushitem)
						// // console.log("topushitem")

						this.setState({getImageReply: topushitem, isloading: false, viewerIsOpen : true})
						console.log(this.state.getImageReply)
						console.log("this.state.getImageReply")
						// let commentMsgTmp = this.state.caseData
					
						// if(this.state.getImageReply != ""){
							// let commentImageTmp = this.state.getImageReply
					
							// for (let d = 0; d < this.state.caseReplyList.length; d++){
								
								// let obj = {
									// "image"         :   commentImageTmp,
									// 'id'            :   this.state.caseReplyList[d]['id'],
									// 'caseId'        :   this.state.caseReplyList[d]['caseId'],
									// 'reply'         :   this.state.caseReplyList[d]['reply'],
									// 'userfname'     :   this.state.caseReplyList[d]['userfname'],
									// 'isDeleted'     :   this.state.caseReplyList[d]['isDeleted'],
									// 'isDraft'       :   this.state.caseReplyList[d]['isDraft'],
									// 'createdby'     :   this.state.caseReplyList[d]['createdby'],
									// 'createddate'   :   this.state.caseReplyList[d]['createddate'],
									// 'modifiedby'    :   this.state.caseReplyList[d]['isModified'],
									// 'modifieddate'  :   this.state.caseReplyList[d]['modifieddate'],
									// 'isModified'    :   this.state.caseReplyList[d]['isModified'],
									
								// }
								// newCommentList.push(obj)
							// }
							// this.setState({caseData : newCommentList, isloading: false, viewerIsOpen : true})
						// }
					}
				}
            })
            .catch(error=>{
                this.setState({
                    isloading   :   false,
                    alerttype   :   "Error!",
                    isshow      :   false,
                    color       :   "danger",
                    message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                    fade        :   true
                })
            })		
    }

    buildListImageComment =(data)=>{
        // // console.log(data)
		// // console.log("data buildList")
        let itemListImage =[]

        let idCommentList=[]
        let imageList=[]
		// console.log("1")
        for (let i = 0; i < data.length; i++) {
            // console.log("2")
            let list001 = data[i].split(",")
            // console.log("3")
            let idCheck = data[i].split("'image': [{")
           // console.log("4")
            let idClean = idCheck[0].split(",")
            // console.log("5")
            let Idimagetmp1 = idClean[2]
			// console.log("6")
            let s1 = data[i].split("'image': [{")
			// console.log(s1)
			// console.log("7")
            let s2 = s1[1].split("}], ")
			// console.log(s2)
			// console.log("8")
            let s3 = s2[0].split("}, {'uri': ")
			// console.log("9")
            let uri1 = s3[0].replace("'uri': '", "")
			// console.log("10")
            let uri2 = uri1.replace("'", "")
            let obj={
                'image': uri2,
                'imageId': Idimagetmp1
            }
            imageList.push(obj) 
			// console.log("end")
        }
        // console.log(imageList)
        // console.log("itemlist image")
        // // console.log("buldlist")
        return imageList 
    };

    /*************** End reply image ***********************/
    forUpdateKey =() => {
        sessionStorage.setItem("CreateUpdateKey", JSON.stringify(0))
    };

    closeViewerImageReply = () => {

        //// console.log("hi")
        let _tmpClear = []

        this.setState({
            viewerIsOpen: false,
            currImg: 0,
            forCommentImage :   _tmpClear,
            })

    };

    render() {
        //const { reply } = this.state;

    return(
            <div>
                <Banner />
                <Container className="mt-5" fluid>
                    <Card>
                                <Alert isOpen={this.state.isshow} color={this.state.color} fade={this.state.fade} className={this.state.isshow ? 'display-block' : 'display-none'}>
                                    <div className={this.state.color}></div> 
                                    {this.state.message}
                                </Alert>
                        <Card.Header>
                            <Form.Group as={Row} controlId="formPlaintextEmail">
                                <Form.Label column sm="10" style={{fontWeight : "bold"}}>
                                    VIEW CONCERN
                                </Form.Label>
                                <Col sm="2">
                                <ButtonToolbar >
                                    <Button variant="primary" className="ml-auto" onClick={this.callUpdateButton} style={{fontWeight : "bold", minWidth:'60px'}}>
                                        Update Case
                                    </Button>
                                </ButtonToolbar>
                                </Col>
                            </Form.Group>
                        </Card.Header>
                        <Card.Body>
                            <Form>
                                
                                <Form.Group as={Row} controlId="formPlaintextEmail">
                                    <Form.Label column sm="4" style={{fontWeight : "bold"}}>
                                        TYPE
                                    </Form.Label>
                                    <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                        :
                                    </Form.Label>
                                    <Form.Label column sm="7" style={{fontWeight : "bold"}}>
                                        {this.state.type}
                                    </Form.Label>
                                </Form.Group>

                                <Form.Group as={Row} controlId="formPlaintextEmail">
                                    <Form.Label column sm="4" style={{fontWeight : "bold"}}>
                                        TITLE
                                    </Form.Label>
                                    <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                        :
                                    </Form.Label>
                                    <Form.Label column sm="7" style={{fontWeight : "bold"}}>
                                        {this.state.title}
                                    </Form.Label>
                                </Form.Group>

                                <Form.Group as={Row} controlId="formPlaintextEmail">
                                    <Form.Label column sm="4" style={{fontWeight : "bold"}}>
                                        DESCRIPTION
                                    </Form.Label>
                                    <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                        :
                                    </Form.Label>
                                    <Form.Label column sm="7" style={{fontWeight : "bold"}}>
                                        {this.state.description}
                                    </Form.Label>
                                </Form.Group>

                                <Form.Group as={Row} controlId="formPlaintextEmail">
                                    <Form.Label column sm="4" style={{fontWeight : "bold"}}>
                                        DATE
                                    </Form.Label>
                                    <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                        :
                                    </Form.Label>
                                    <Form.Label column sm="7" style={{fontWeight : "bold"}}>
                                        {this.state.createddate}
                                    </Form.Label>
                                </Form.Group>

                                <Form.Group as={Row} controlId="formPlaintextEmail">
                                    <Form.Label column sm="4" style={{fontWeight : "bold"}}>
                                        ATTACHMENT
                                    </Form.Label>
                                    <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                        :
                                    </Form.Label>
                                    <Col sm="7">
                                        <ButtonToolbar >
                                            <Button style={{minWidth:'60px', fontWeight : "bold", color: "#000000", background:'#FFFFFF'}} variant='transparent' onClick={ this.showAttachmentModal }>
                                              <u><i>Show Attachment</i></u> 
                                            </Button>
                                        </ButtonToolbar>
                                    </Col>
                                </Form.Group>
                               
                                <ImgsViewer
                                    imgs = {this.state.getImage}
                                    isOpen={this.state.viewerIsOpenCase}
                                    currImg={0} 
                                    //onClickPrev={this.gotoPrevious}
                                    //onClickNext={this.gotoNext}
                                    onClose={this.closeViewer.bind(this)}
                                />
                                
                                <div style={{height: '70px'}}></div>
                                
                                    <fieldset className="border p-3 ">
                                    <legend className="w-auto" style={{fontWeight: "bold"}}> <i>COMMENTS: </i></legend>
                                        <div style={{height: '300px', overflowY: 'scroll' }}>
                                        <div style={{height: "10px"}}></div>
                                            {this.state.caseReplyList.map((item) => ( 
                                            <Form.Group as={Row} controlId="formPlaintextEmail">
                                                <Form.Label column sm="2" style={{fontWeight : "bold"}}>
                                                    {item.userfname} <br />
                                                    {item.createddate}
                                                </Form.Label>
                                                <Form.Label column sm="7" style={{fontWeight : "bold"}}>
                                                    {item.reply}
                                                </Form.Label>
												
                                                <Col sm="3">
                                                    <Button style={{minWidth:'60px', fontWeight : "bold", color: "#000000", background:'#FFFFFF'}} variant='transparent' onClick={ () => this.handleClickShowImage(item) }>
														<u><i>Show Attachment</i></u> 
                                                    </Button>
                                                </Col>
                                                <ImgsViewer
                                                    imgs = {this.state.getImageReply}
                                                    isOpen={this.state.viewerIsOpen}
                                                    //currImg={0} 
													//style = {{ height : "30%", width : "20%" }}
                                                    //onClickPrev={this.gotoPrevious}
                                                    //onClickNext={this.gotoNext}
                                                    onClose={this.closeViewerImageReply.bind(this)}
                                                />
                                            </Form.Group>
                                            ))}
                                        </div>
                                    </fieldset>
                                <Form.Group as={Row} className="mt-3" controlId="formPlaintextEmail">
                                    <Col sm="3">
                                        <ButtonToolbar >
                                        <Col sm='12'>
                                            {/*<input ref={ref=> this.fileInput = ref} name="file" className="mr-auto" type="file" onChange={this.onImageReplyChange.bind(this)}/>*/}
                                            <input ref={ref=> this.fileInput = ref} name="file" className="mr-auto" type="file" onChange={this.onImageReplyChange}/>
                                        </Col>
                                        </ButtonToolbar>
                                    </Col>
                                    <Col sm="7">
                                        <Form.Control 
                                            type="reply"
                                            name="reply"
                                            value={this.state.reply}
                                            onKeyDown={this.handleKeyDown}
                                            //onKeyPress={(e) => e.key === 'Enter' && this.submitCaseReply()}
                                            onChange={this.onChangereply} 
                                            autoComplete="off"
                                        />
                                    </Col>
                                    <Col sm="2">
                                        <ButtonToolbar>
                                            <Button style={{minWidth:'60px', fontWeight: 'bold'}} onClick={this.submitCaseReply} variant='primary'>
                                             Send
                                            </Button>&nbsp;&nbsp;
                                            <Button style={{minWidth:'60px', fontWeight: 'bold'}}onClick={this.updateKey} variant='danger' href="/viewConcern">
                                             Back
                                            </Button>
                                        </ButtonToolbar>
                                    </Col>{/* 
                                    <Col sm="3">
                                    </Col> */}
                                </Form.Group> 

                            </Form>
                        </Card.Body>
                    </Card>
                </Container>
                <NoserLoading show={this.state.isloading} />
               
                <CreateCaseModal
                    show={this.state.CreateCaseModal}
                    onHide={this.closeModal}
                    onRefCaseModal={ref => (this.child = ref)}
                   
                   />

                
            </div> 
        )
    }

}

export  default CaseScreen
	
