import {
    React,Component,BootstrapTable,
    paginationFactory,Button, ButtonToolbar, Card, 
    Form, Col, Row, axios, Container, Banner, Typeahead, TimePicker,
    cellEditFactory, Type, moment, NavLink, Alert, AppConfiguration,
    NoserLoading, Noser, sizePerPageRenderer, DatePicker, Redirect
} 
from '../../noser-hris-component';
import { DropdownButton, Dropdown} from 'react-bootstrap';

import  TrainingRegisterModal from './TrainingModal/TrainingRegisterModal'
 
class TrainingRegister extends Component {
    constructor(props) {    
        super(props);
        this.state = {
            fade            :   true, 
            color           :   "",
            isshow          :   false,
            message         :   "",
            userinfo        :   [],
            isloading       :   false,
            alerttype       :   "",
            navigate   :   false,

            trainingNameDDL        :   [],
            trainingTypeDDL        :   [],
            trainingRegisterGrid   :   [],
            batchNoDDL         :   [],
            dateFrom                    :   new Date(),
            dateTo                      :   new Date(),
            modalTrainingRegisterShow   :   false,
            selectedBatchNo : "",
            trainingId: "",
            selectedScheduleId : "",

        }
    }

    componentDidMount(){
        const sleep = (milliseconds) => {return new Promise(resolve => setTimeout(resolve, milliseconds))}
        this.state.userinfo = JSON.parse(sessionStorage.getItem("userData"))
        this.GetTrainingName();
        this.GetSearchTrainingRegistrations();
    }

    GetTrainingName = async() =>{
        this.setState({isloading:true})
        const param = {
            "IpAddress"     :   "0.0.0.0",
            "ClientId"      :   this.state.userinfo.clientId,
            "UserId"        :   this.state.userinfo.userId,
        };
        await axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Maintenance/GetTrainings",  param)
        .then(res => {
            const data = res.data;
            //console.log(data)
            this.setState({trainingNameDDL:data.trainings,isloading:false});
        })
        .catch(error=>{
            this.setState(  {
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade            :   true
            })
        })
    }

    onChangeTrainingName = (e) => {
        if(e.length == 0) {
            this.state.trainingId     =   ""
            return
        }
        this.state.trainingId    =   e[0].id
        this.setState({
            isshow:false,
        })
        this.GetTrainingRegistrations();
    }

    GetTrainingRegistrations = async() =>{
        this.setState({isloading:true})
        const searchParams = {
            "IpAddress"     :   "0.0.0.0",
            "UserId"        :   this.state.userinfo.userId,
            "ClientId"      :   this.state.userinfo.clientId,
            "TypeId"        :   "",
            "TrainingId"    :   this.state.trainingId,
            "BatchNo"       :   "",
        };

        //console.log("Search Params")
        //console.log(searchParams)
        await
        axios
        //.post(AppConfiguration.Setting().noserapiendpoint + "Training/GetTrainingRegistrationDetails",  searchParams)
        .post(AppConfiguration.Setting().noserapiendpoint + "Training/GetTrainingRegistrations",  searchParams)
        .then(res => {
            const data = res.data;
            console.log("GetTrainingRegistrationDetails")
            console.log(data)
            this.setState({ batchNoDDL     :   data.trainingRegistrations, isloading : false })
                
        })
        .catch(error=>{
            this.setState({
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade            :   true
            })
        })
    }

    onChangeBatchNo = (e) => {
        if(e.length == 0) {
            this.state.batchNo     =   ""
            return
        }
        this.state.batchNo    =   e[0].batchNo
        this.setState({
            isshow:false,
        })
    }

    handleChangeDateFrom = date => {
        //////console.log(date)
        this.setState({
            dateFrom: date,
            isshow:false,
        })
    }

    handleChangeDateTo = date => {
        //////console.log(date)
        this.setState({
            dateTo: date,
            isshow:false,
        })
    }

    handleSearchClick = () => {
        this.GetSearchTrainingRegistrations();
    }

    GetSearchTrainingRegistrations = async() =>{
        this.setState({isloading:true})
        const searchParams = {
            "IpAddress"     :   "0.0.0.0",
            "UserId"        :   this.state.userinfo.userId,
            "ClientId"      :   this.state.userinfo.clientId,
            "TypeId"        :   "",
            "TrainingId"    :   this.state.trainingId,
            "BatchNo"       :   this.state.batchNo,
        };

        //console.log("Search Params")
        //console.log(searchParams)
        await
        axios
        //.post(AppConfiguration.Setting().noserapiendpoint + "Training/GetTrainingRegistrationDetails",  searchParams)
        .post(AppConfiguration.Setting().noserapiendpoint + "Training/GetTrainingRegistrations",  searchParams)
        .then(res => {
            const data = res.data;
            console.log("GetTrainingRegistrationDetails")
            console.log(data)
            this.setState({ trainingRegisterGrid     :   data.trainingRegistrations, isloading : false })
                
        })
        .catch(error=>{
            this.setState({
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade            :   true
            })
        })
    }

    showTrainingRegisterModal = (row) => {
        this.setState({modalTrainingRegisterShow: true})
        this.child.initialize(row)
    }
    handleModalClose = (e) =>{
        //reload or refresh data here
        //alert("Data pass from child : " + e)
        this.setState({modalTrainingRegisterShow: false})
    }


    GridDataModified(oldValue, newValue, id, column) {
        //////console.log(id)
        this.state.trainingScheduleTableList.map(function(item,i) {
            if (item.id===id)
                item.isModified = newValue!=oldValue ? "1" : "0" 
        })
    }

    onClickDataRow = (row) => {
        this.setState({
            navigate   :   true
        })
        
        sessionStorage.setItem("trainingRegisterDataRow", JSON.stringify(row))
    }

    render() {
        const { navigate } = this.state;
        if (navigate) {
            return <Redirect to="/trainingregisterdetails" push={true} />;
        } 
        
        const trainingRegisterColumn = [
            {
                dataField   : 'trainingDate',
                text        : 'Date',
                headerStyle : () => {
                    return { width  : "10%" };
                }
            },
            {
                dataField   : 'trainingType',
                text        : 'Training Type',
                headerStyle : () => {
                    return { width  : "15%" };
                }
            },
            {
                dataField: 'training',
                text: 'Training',
                headerStyle : () => {
                    return { width  : "15%" };
                }
            },
            {
                dataField   : 'registered',
                text        : 'Registered',
                headerStyle : () => {
                    return { width  : "15%" };
                }
            },
            {
                dataField   : 'attended',
                text        : 'Attended',
                headerStyle : () => {
                    return { width  : "15%" };
                }
            },
            {
                dataField   : 'batchNo',
                text        : 'Batch No.',
                headerStyle : () => {
                    return { width  : "15%" };
                }
            },
            {
                dataField   : 'action',
                text        : 'Action',
                headerStyle : () => {
                    return { width  : "15%" };
                },
                formatter   :   (cell, row, isSelect) => {
                    this.state.employeeName = row.employeeName
                    if (row)
                    return (
                        <Button style={{height:'16px',paddingTop:'0',marginTop:'-3px',fontSize:'11px'}}
                            variant="link" onClick={e => this.onClickDataRow(row)}
                        >View Details</Button>
                        /* <NavLink to={{pathname:"/trainingregisterdetails",params:{data:row} }} >
                            <Button href="/trainingregisterdetails" style={{height:'16px',paddingTop:'0',marginTop:'-3px',fontSize:'11px'}} variant="link">View Details</Button>
                        </NavLink> */
                        
                    );
                },
            },
        ]

        const selectRow = {
            mode: 'checkbox',
            clickToSelectAndEditCell: true,
            onSelect: (row, isSelect, rowIndex, e) => {
                this.state.trainingRegisterGrid.map(function(item,i){
                    if(item.id===row.id)
                    {
                        item.isDeleted = isSelect ? "1" : "0"
                        item.isModified = isSelect ? "1" : "0"
                    }
                })
            }
        };

        const rowEvents = {
            onClick: (e, row, rowIndex) => {
            }
        };
        
    return(
            <div>
                <Banner />
                    <Container className="mt-5" fluid>
                        <Card>
                            <Card.Header>TRAINING >> L and D >> TRAINING REGISTER</Card.Header>
                            <Card.Body>
                                <Form>
                                    <Alert isOpen={this.state.isshow} color={this.state.color} fade={this.state.fade} className={this.state.isshow ? 'display-block' : 'display-none'}>
                                        <div className={this.state.color}></div> 
                                        {this.state.message}
                                    </Alert>
                                    {/* <Form.Group as={Row} controlId="formPlaintextEmail">
                                        <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                            CLIENT
                                        </Form.Label>
                                        <Col sm="11">
                                            <Typeahead
                                                labelKey='name'
                                                id="basic-example"
                                                onChange={this.onChangeClient}
                                                options={this.state.clientAutocomplete}
                                            />
                                        </Col>
                                    </Form.Group> */}
                                    {/* <Form.Group as={Row} controlId="formPlaintextEmail">
                                        <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                            POSITION
                                        </Form.Label>
                                        <Col sm="11">
                                            <Typeahead
                                                labelKey='name'
                                                id="basic-example"
                                                onChange={this.onChangePosition}
                                                options={this.state.positionAutocomplete}
                                            />
                                        </Col>
                                    </Form.Group>  */}
                                    <Form.Group as={Row} controlId="formPlaintextEmail">
                                        <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                            TRAINING
                                        </Form.Label>
                                        <Col sm="11">
                                            <Typeahead
                                                labelKey='name'
                                                id="basic-example"
                                                onChange={this.onChangeTrainingName}
                                                options={this.state.trainingNameDDL}
                                            />
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} controlId="formPlaintextEmail">
                                        <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                            TYPE
                                        </Form.Label>
                                        <Col sm="11">
                                            <Typeahead
                                                labelKey='name'
                                                id="basic-example"
                                                onChange={this.onChangeTrainingType}
                                                options={this.state.trainingTypeDDL}
                                            />
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} controlId="formPlaintextEmail">
                                        <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                            BATCH NO.
                                        </Form.Label>
                                        <Col sm="11">
                                            <Typeahead
                                                labelKey='batchNo'
                                                id="basic-example"
                                                onChange={this.onChangeBatchNo}
                                                options={this.state.batchNoDDL}
                                            />
                                        </Col>
                                    </Form.Group>
                                    {/* <Form.Group as={Row} controlId="formPlaintextEmail">
                                        <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                            TRAINING DATE
                                        </Form.Label>
                                        <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                            DATE FROM
                                        </Form.Label>
                                        <Col sm="1">
                                            <DatePicker
                                                ref='dateFrom'
                                                selected={this.state.dateFrom}
                                                onChange={this.handleChangeDateFrom}
                                                minDate={this.minDate}
                                                value={this.props.dateFrom}
                                                dateFormat={"MM/dd/yyyy"}
                                                className="form-control"
                                            />
                                        </Col>
                                        <Col sm="1">
                                        </Col>
                                        <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                            DATE TO
                                        </Form.Label>
                                        <Col sm="1">
                                            <DatePicker
                                                ref='dateTo'
                                                selected={this.state.dateTo}
                                                onChange={this.handleChangeDateTo}
                                                minDate={this.minDate}
                                                value={this.props.dateTo}
                                                dateFormat={"MM/dd/yyyy"}
                                                className="form-control"
                                            />
                                        </Col>
                                    </Form.Group> */}

                                    <ButtonToolbar className="mt-1">
                                        <Button variant="success" className="ml-auto" onClick={ this.handleSearchClick }>
                                            Search
                                        </Button>&nbsp;&nbsp;
                                        <Button  variant="primary">
                                            Clear
                                        </Button>
                                    </ButtonToolbar>

                                    <Card.Header className="mt-3">List Training Conducted</Card.Header>
                                    <div className="mt-1">
                                        <BootstrapTable
                                            /* caption={Noser.TableHeader({title:"RECORD"})} */
                                            keyField = "id"
                                            data = { this.state.trainingRegisterGrid }
                                            columns = { trainingRegisterColumn }
                                            pagination={ paginationFactory({sizePerPageRenderer}) }
                                            noDataIndication={ () => <div style={{width:'100%'}}>No record found.</div> }
                                            rowClasses="noser-table-row-class"
                                            striped
                                            hover
                                            condensed
                                            cellEdit = { cellEditFactory({
                                                mode: 'dbclick',
                                                blurToSave: true,
                                                afterSaveCell: (oldValue, newValue, row, column) => {
                                                    this.GridDataModified(oldValue, newValue, row.id, column.dataField)
                                                    }
                                                })
                                            }
                                            rowEvents={ rowEvents }
                                            selectRow = { selectRow }

                                        />
                                    </div>
                                    <ButtonToolbar sm={12}>
                                        <NavLink className="ml-auto" to="/home">
                                            <Button variant="danger" href="/home" style={{minWidth:'60px'}}>
                                                Close
                                            </Button>
                                        </NavLink>
                                    </ButtonToolbar>
                                </Form>
                            </Card.Body>
                        </Card>
                       
                    </Container>
                    <NoserLoading show={this.state.isloading} />

                    <TrainingRegisterModal 
                        show={this.state.modalTrainingRegisterShow}
                        onHide={this.handleModalClose}
                        onRefModal={ref => (this.child = ref)}
                    />
            </div> 
        )
    }

}

export  default TrainingRegister
