import {
    React,Component,BootstrapTable,
    paginationFactory,Button, ButtonToolbar, Card, 
    Form, Col, Row, axios, Container, Banner, Typeahead, TimePicker,
    cellEditFactory, Type, moment, NavLink, Alert, AppConfiguration,
    NoserLoading, Noser, sizePerPageRenderer, Modal, DatePicker, NumberFormat
} 
from '../../noser-hris-component';

class DraftEmployee extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userinfo        :   [],
            isloading       :   false,
            isshow          :   false,
            alerttype       :   "",
            message         :   "",
            color           :   "",
            fade            :   true,      
            disable         :   true,
            disablecard     :   true,
            
            clientDDL       :   [],
            locationDDL     :   [],
            positionDDL     :   [],
            employmentDDL   :   [],
            paymodeDDL      :   [],
            paytypeDDL      :   [],
            cardtypeDDL     :   [],

            clientId        :   "",
            clientName      :   "",
            locationId      :   "",
            positionId      :   "",
            employmentId    :   "",
            firstName       :   "",
            middleName      :   "",
            lastName        :   "",
            suffixName      :   "",
            dateofBirth     :   "",
            dateHired       :   "",
            duration        :   "",
            contactNo       :   "",
            salaryRate      :   "",
            paymodeId       :   "",
            paytypeId       :   "",
            cardTypeId      :   "",
            cardNumber      :   "",
            tinNumber       :   "",
            sssNumber       :   "",
            phicNumber      :   "",
            hdmfNumber      :   "",
            dateResigned    :   "",
        }
    }

    componentDidMount(){
        //const sleep = (milliseconds) => {return new Promise(resolve => setTimeout(resolve, milliseconds))}
        //this.state.userinfo = JSON.parse(sessionStorage.getItem("userData"))
        this.props.onRefModal(this)
        
    }
    componentWillUnmount() {
        this.props.onRefModal(undefined)
    }
    initialize=(e)=>{
        this.setState({
            userinfo        :   JSON.parse(sessionStorage.getItem("userData")),
            isloading       :   false,
            isshow          :   false,
            alerttype       :   "",
            message         :   "",
            color           :   "",
            fade            :   true,      
            disable         :   true,
            disablecard     :   true,
            
            clientDDL       :   [],
            locationDDL     :   [],
            positionDDL     :   [],
            employmentDDL   :   [],
            paymodeDDL      :   [],
            paytypeDDL      :   [],
            cardtypeDDL     :   [],

            clientId        :   "",
            clientName      :   "",
            locationId      :   "",
            positionId      :   "",
            employmentId    :   "",
            firstName       :   "",
            middleName      :   "",
            lastName        :   "",
            suffixName      :   "",
            dateofBirth     :   "",
            dateHired       :   "",
            duration        :   "",
            contactNo       :   "",
            salaryRate      :   "",
            paymodeId       :   "",
            paytypeId       :   "",
            cardTypeId      :   "",
            cardNumber      :   "",
            tinNumber       :   "",
            sssNumber       :   "",
            phicNumber      :   "",
            hdmfNumber      :   "",
            dateResigned    :   "",
            eColaRate       :   "",
            eColaRate       :   "",
            seaRate         :   "",
        })
        this.GetClients()
    }
    GetClients = async() =>{
        this.setState({isloading:true})
        const params = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.userinfo.clientId,
            "UserId":this.state.userinfo.userId,
        }
        await axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Client/GetClientList", params)
        .then(res => {
            const data = res.data;
            this.setState({clientDDL : data.clients,isloading:false})
        })
        .catch(error=>{
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade        :   true
            })
        })
    }
    GetLocations = async() =>{
        this.setState({isloading:false})
        const params = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.clientId,
            "UserId":this.state.userinfo.userId,
            "ClientName": this.state.clientName,
        }
        axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Client/GetClientLocations", params)
        .then(res => {
            const data = res.data;
            this.setState({locationDDL : data.locations ? data.locations : []})
        })
        .catch(error=>{
           this.setState(  {
               isloading       :   false,
               alerttype       :   "Error!",
               isshow          :   true,
               color           :   "danger",
               message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
               fade            :   true
           })
       })
       this.GetPositions()
    }
    GetPositions = async()=> {
        const params = {
            "IpAddress"     :   "0.0.0.0",
            "ClientId"      :   this.state.userinfo.clientId,
            "UserId"        :   this.state.userinfo.userId,
            "SectionId"     :   "",
            "DepartmentId"  :   "",
            "Name"          :   ""
        };
        await axios.post(AppConfiguration.Setting().noserapiendpoint + "Maintenance/GetPositions",  params)
        .then(res => {
            const data = res.data;
            let positionLst = []
                data.positions.map(function(itm){
                    positionLst.push({
                        "value" : itm.id,
                        "label" : itm.name
                    })
            })
            this.setState({positionDDL:data.positions});
        })
        .catch(error=>{
            this.setState(  {
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade            :   true
            })
        })
        this.GetPayrollTypes()
    }
    GetPayrollTypes = async()=> {
        const params = {
            "IpAddress"     :   "0.0.0.0",
            "ClientId"      :   this.state.userinfo.clientId,
            "UserId"        :   this.state.userinfo.userId
        };
        await axios.post(AppConfiguration.Setting().noserapiendpoint + "Timekeeping/GetPayrollTypes",  params)
        .then(res => {
            const data = res.data;
            this.setState({paytypeDDL:data.payrollTypes});
        })
        .catch(error=>{
            this.setState(  {
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade            :   true
            })
        })
        this.GetPayrollModes()
    }
    GetPayrollModes = async()=> {
        const params = {
            "IpAddress"     :   "0.0.0.0",
            "ClientId"      :   this.state.userinfo.clientId,
            "UserId"        :   this.state.userinfo.userId
        };
        await axios.post(AppConfiguration.Setting().noserapiendpoint + "Timekeeping/GetPayrollModes",  params)
        .then(res => {
            const data = res.data;
            this.setState({paymodeDDL:data.payrollModes});
        })
        .catch(error=>{
            this.setState(  {
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade            :   true
            })
        })
        this.GetEmploymentTypes()
    }
    GetEmploymentTypes = async()=> {
        const params = {
            "IpAddress"     :   "0.0.0.0",
            "ClientId"      :   this.state.userinfo.clientId,
            "UserId"        :   this.state.userinfo.userId
        };
        await axios.post(AppConfiguration.Setting().noserapiendpoint + "Maintenance/GetEmployeeStatusTypes",  params)
        .then(res => {
            const data = res.data;
            this.setState({employmentDDL:data.statusTypes});
        })
        .catch(error=>{
            this.setState(  {
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade            :   true
            })
        })
        this.GetPayCardTypes()
    }
    GetPayCardTypes = async()=> {
        const params = {
            "IpAddress"     :   "0.0.0.0",
            "ClientId"      :   this.state.userinfo.clientId,
            "UserId"        :   this.state.userinfo.userId
        };
        await axios.post(AppConfiguration.Setting().noserapiendpoint + "Maintenance/GetPayCardTypes",  params)
        .then(res => {
            const data = res.data;
            this.setState({cardtypeDDL:data.payCardTypes,isloading:false});
        })
        .catch(error=>{
            this.setState(  {
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade            :   true
            })
        })
    }
    handleSubmitClick = async() => {
        this.setState({isloading:true,disable:true})

        if(this.state.clientId===""){
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "Please select client name.",
                fade        :   true
            })
            return
        }

        if(this.state.locationId===""){
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "Please select branch / location.",
                fade        :   true
            })
            return
        }

        if(this.state.positionId===""){
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "Please select position.",
                fade        :   true
            })
            return
        }
        if(this.state.firstName===""){
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "Please enter first name.",
                fade        :   true
            })
            return
        }
        if(this.state.lastName===""){
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "Please enter last name.",
                fade        :   true
            })
            return
        }
        if(this.state.contactNo===""){
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "Please enter contact number.",
                fade        :   true
            })
            return
        }
        if(this.state.salaryRate===""){
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "Please enter salary rate.",
                fade        :   true
            })
            return
        }
        if(this.state.paymodeId===""){
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "Please select payroll mode.",
                fade        :   true
            })
            return
        }
        if(this.state.cardtypeId===""){
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "Please select payroll card type.",
                fade        :   true
            })
            return
        }
        if(this.state.employmentId===""){
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "Please select employment type.",
                fade        :   true
            })
            return
        }
        if(this.state.dateofBirth===""){
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "Please enter date of birth.",
                fade        :   true
            })
            return
        }
        if(this.state.dateHired===""){
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "Please enter date hired.",
                fade        :   true
            })
            return
        }
        if(this.state.duration===""){
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "Please enter date duration.",
                fade        :   true
            })
            return
        }
        
        if(this.state.cardtypeId==="2" ||
                this.state.cardtypeId==="3" ||
                this.state.cardtypeId==="6" ||
                this.state.cardtypeId==="14")
        {
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "Please enter card number.",
                fade        :   true
            })
            return
        }

        const params = {
            "IpAddress":"0.0.0.0",
            "ClientId":this.state.clientId,
            "UserId":this.state.userinfo.userId,
            "RoleId":this.state.userinfo.roleId,
            "LocationId":this.state.locationId,
            "PositionId":this.state.positionId,
            "EmploymentId":this.state.employmentId,
            "LastName":this.state.lastName,
            "FirstName":this.state.firstName,
            "MiddleName":this.state.middleName,
            "SuffixName":this.state.suffixName,
            "DateOfBirth":this.FormatDate(this.state.dateofBirth),
            "DateHired":this.FormatDate(this.state.dateHired),
            "Duration":this.FormatDate(this.state.duration),
            "MobileNumber":this.state.contactNo,
            "SalaryOffered":this.state.salaryRate,
            "PayTypeId":this.state.paytypeId,
            "PayModeId":this.state.paymodeId,
            "PayCardTypeId":this.state.cardtypeId,
            "PayCardNumber":this.state.cardNumber,
            "TINNumber":this.state.tinNumber,
            "SSSNumber":this.state.sssNumber,
            "PHICNumber":this.state.phicNumber,
            "HDMFNumber":this.state.hdmfNumber,
            "DateResigned":this.FormatDate(this.state.dateResigned),
            "SubmittedBy":this.state.userinfo.employeeId,
            "EColaRate":this.state.eColaRate,
            "ColaRate":this.state.colaRate,
            "SeaRate":this.state.seaRate,
        }
        console.log(params)
        await axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Manning/AddDraftEmployee", params)
        .then(res => {
            const data = res.data;
            if(data.status==="0"){
                this.setState({
                    isloading   :   false,
                    alerttype   :   "Error!",
                    isshow      :   true,
                    color       :   "danger",
                    message     :   data.message,
                    fade        :   true,
                    disable     :   false,
                    disablecard :   false
                });
            } 
            else{
                this.setState({
                    isloading   :   false,
                    alerttype   :   "Success!",
                    isshow      :   true,
                    color       :   "success",
                    message     :   data.message,
                    fade        :   true,
                    disable     :   false,
                    disablecard :   false,
                    employmentId:   "",
                    firstName   :   "",
                    middleName  :   "",
                    lastName    :   "",
                    suffixName  :   "",
                    dateofBirth :   "",
                    dateHired   :   "",
                    duration    :   "",
                    contactNo   :   "",
                    salaryRate  :   "",
                    paymodeId   :   "",
                    paytypeId   :   "",
                    cardTypeId  :   "",
                    cardNumber  :   "",
                    tinNumber   :   "",
                    sssNumber   :   "",
                    phicNumber  :   "",
                    hdmfNumber  :   "",
                });
                this.refs.paymodeId.clear()
                this.refs.paytypeId.clear()
                this.refs.cardtypeId.clear()
                this.refs.employmentId.clear()
            } 
        })
        .catch(error=>{
            this.setState({
                isloading   :   false,
                alerttype   :   "Error!",
                isshow      :   true,
                color       :   "danger",
                message     :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade        :   true,
                disable     :   false,
                disablecard :   false,
            })
        })
    } 
    handleChangedClient = async(e) => {
        this.setState({alerttype:"",isshow:false,color:"",message:"",fade:true,isloading:false});
        if(e.length===0){
            this.state.clientId=''
            this.state.clientName=''
            this.state.disable=true
            this.state.disablecard=true
        }
        else{
            this.state.clientId=e[0].id
            this.state.clientName=e[0].name
            this.state.disable=false
            this.state.disablecard=false
        }
        this.GetLocations()
    }
    handleChangedDDL = name => e =>{
        this.setState({alerttype:"",isshow:false,color:"",message:"",fade:true,isloading:false});
        if(e.length===0) {this.setState({[name]:""}); return}
        this.setState({[name]:e[0].id})

        if(name==="cardtypeId"){
            if(e[0].id==="1" || 
                e[0].id==="4" ||
                e[0].id==="5" ||
                e[0].id==="15")
            this.setState({disablecard:true,cardNumber:""})
            else if(e[0].id==="2" ||
                    e[0].id==="3" ||
                    e[0].id==="6" ||
                    e[0].id==="14")
            this.setState({disablecard:false})
            else if(e[0].id==="8")
            this.setState({disablecard:true})
        }
        
    }
    handleChangedDate = name => date =>{
        this.setState({[name]:date})
    }
    handleChanged = (e) =>{
        if(e.target.name==="salaryRate"){
            if(e.target.value.split(".").length>2) return
            let dec = e.target.value.split(".")[1]
            if(typeof dec !='undefined')
                if(dec.length>2) return

        }
        this.setState({[e.target.name]:e.target.value})
    }
    FormatDate(date) {
        let m = moment(date, 'MM-DD-YYYY');
        return (m.isValid() ? (date.getMonth()+1 + "/" + date.getDate() + "/" + date.getFullYear()) : "")
    }
    IsNumeric(evt)
    {
        var theEvent = evt || window.event;
        var key = theEvent.keyCode || theEvent.which;
        key = String.fromCharCode( key );
        var regex = /^\d+(.\d+)?$/;
        if( !regex.test(key) ) {
        theEvent.returnValue = false;
        if(theEvent.preventDefault) theEvent.preventDefault();
        }
        
    }
    IsDecimal(evt)
    {
        var theEvent = evt || window.event;
        var key = theEvent.keyCode || theEvent.which;
        key = String.fromCharCode( key );
        var regex = /^\d*\.?(?:\d{1,2})?$/;
        if( !regex.test(key) ) {
        theEvent.returnValue = false;
        if(theEvent.preventDefault) theEvent.preventDefault();
        }
    }
    IsValidMobileNumber(val)
    {
        var regex = /0{1}9{1}[0-9]{1}[0-9]{7}/;
        return !regex.test(val)
    }
    render() { 
        return(
                <>
                <Modal
                    {...this.props}
                    return
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    backdrop="static"
                >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        DRAFT EMPLOYEE
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <Container>       
                    <Form>
                        <Alert isOpen={this.state.isshow} color={this.state.color} fade={this.state.fade} className={this.state.isshow ? 'display-block' : 'display-none'}>
                            <div className={this.state.color}></div> 
                            {this.state.message}
                        </Alert>
                        <Form.Group as={Row}>
                            <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                CLIENT
                            </Form.Label>
                            <Col sm="11">
                                <Typeahead
                                    id="clientId"
                                    name="clientId"
                                    labelKey='name'
                                    onChange={this.handleChangedClient}
                                    options={this.state.clientDDL}
                                    placeholder="Select client name"
                                />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row}>
                            <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                LOCATION
                            </Form.Label>
                            <Col sm="11">
                                <Typeahead
                                    id="locationId"
                                    name="locationId"
                                    labelKey='name'
                                    onChange={this.handleChangedDDL("locationId")}
                                    options={this.state.locationDDL}
                                    placeholder="Select branch / location name"
                                    disabled={this.state.disable}
                                />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row}>
                            <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                POSITION
                            </Form.Label>
                            <Col sm="11">
                                <Typeahead
                                    id="positionId"
                                    name="positionId"
                                    labelKey='name'
                                    onChange={this.handleChangedDDL("positionId")}
                                    options={this.state.positionDDL}
                                    placeholder="Select position name"
                                    disabled={this.state.disable}
                                />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row}>
                            <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                FIRST NAME
                            </Form.Label>
                            <Col sm="3">
                                <Form.Control 
                                    id="firstName"
                                    name="firstName"
                                    type="text" 
                                    value={this.state.firstName}
                                    placeholder="Enter first name"
                                    disabled={this.state.disable}
                                    autoComplete="off"
                                    onChange={this.handleChanged}
                                />
                            </Col>
                            <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                M NAME
                            </Form.Label>
                            <Col sm="3">
                                <Form.Control 
                                    id="middleName"
                                    name="middleName"
                                    type="text" 
                                    value={this.state.middleName}
                                    placeholder="Enter middle name"
                                    disabled={this.state.disable}
                                    autoComplete="off"
                                    onChange={this.handleChanged}
                                />
                            </Col>
                            <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                LAST NAME
                            </Form.Label>
                            <Col sm="3">
                                <Form.Control 
                                    id="lastName"
                                    name="lastName"
                                    type="text" 
                                    value={this.state.lastName}
                                    placeholder="Enter last name"
                                    disabled={this.state.disable}
                                    autoComplete="off"
                                    onChange={this.handleChanged}
                                />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row}>
                            <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                CONTACT #
                            </Form.Label>
                            <Col sm="3">
                                <Form.Control 
                                    id="contactNo"
                                    name="contactNo"
                                    type="text" 
                                    value={this.state.contactNo}
                                    placeholder="Enter contact number"
                                    disabled={this.state.disable}
                                    autoComplete="off"
                                    maxLength="11"
                                    onChange={this.handleChanged}
                                    onKeyPress={this.IsNumeric.bind(this)}
                                />
                            </Col>
                            <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                SALARY 
                            </Form.Label>
                            <Col sm="3">
                                <Form.Control 
                                    id="salaryRate"
                                    name="salaryRate"
                                    type="text" 
                                    value={this.state.salaryRate}
                                    placeholder="Enter salary rate"
                                    disabled={this.state.disable}
                                    autoComplete="off"
                                    onChange={this.handleChanged}
                                    onKeyPress={this.IsDecimal.bind(this)}
                                    maxLength="13"
                                />
                            </Col>
                            <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                SUFFIX NAME
                            </Form.Label>
                            <Col sm="3">
                                <Form.Control 
                                    id="suffixName"
                                    name="suffixName"
                                    type="text" 
                                    value={this.state.suffixName}
                                    placeholder="Enter Suffix name"
                                    disabled={this.state.disable}
                                    autoComplete="off"
                                    onChange={this.handleChanged}
                                />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row}>
                            <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                PAY MODE
                            </Form.Label>
                            <Col sm="3">
                                <Typeahead
                                    ref="paymodeId"
                                    id="paymodeId"
                                    name="paymodeId"
                                    labelKey='name'
                                    onChange={this.handleChangedDDL("paymodeId")}
                                    options={this.state.paymodeDDL}
                                    placeholder="Select payroll mode"
                                    disabled={this.state.disable}
                                />
                            </Col>
                            <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                PAY TYPE
                            </Form.Label>
                            <Col sm="3">
                                <Typeahead
                                    ref="paytypeId"
                                    id="paytypeId"
                                    name="paytypeId"
                                    labelKey='name'
                                    onChange={this.handleChangedDDL("paytypeId")}
                                    options={this.state.paytypeDDL}
                                    placeholder="Select payroll type"
                                    disabled={this.state.disable}
                                />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row}>
                            <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                CARD TYPE
                            </Form.Label>
                            <Col sm="3">
                                <Typeahead
                                    ref="cardtypeId"
                                    id="cardtypeId"
                                    name="cardtypeId"
                                    labelKey='name'
                                    onChange={this.handleChangedDDL("cardtypeId")}
                                    options={this.state.cardtypeDDL}
                                    placeholder="Enter pay card type"
                                    disabled={this.state.disable}
                                />
                            </Col>
                            <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                CARD #
                            </Form.Label>
                            <Col sm="3">
                                <Form.Control 
                                    id="cardNumber"
                                    name="cardNumber"
                                    type="text" 
                                    onChange={this.handleChanged}
                                    value={this.state.cardNumber}
                                    placeholder="Enter pay card number"
                                    disabled={this.state.disablecard}
                                    autoComplete="off"
                                />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row}>
                            <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                EMPLOYMENT
                            </Form.Label>
                            <Col sm="3">
                                <Typeahead
                                    ref="employmentId"
                                    id="employmentId"
                                    name="employmentId"
                                    labelKey='name'
                                    onChange={this.handleChangedDDL("employmentId")}
                                    options={this.state.employmentDDL}
                                    placeholder="Select employment status"
                                    disabled={this.state.disable}
                                    autoComplete="off"
                                />
                            </Col>
                            <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                BIRTH DATE
                            </Form.Label>
                            <Col sm="3">
                                <DatePicker
                                    id="dateofBirth"
                                    name="dateofBirth"
                                    value={this.state.dateofBirth}
                                    selected={this.state.dateofBirth}
                                    onChange={this.handleChangedDate("dateofBirth")}
                                    minDate={this.minDate}
                                    dateFormat={"MM/dd/yyyy"}
                                    className="form-control"
                                    disabled={this.state.disable}
                                    autoComplete="off"
                                />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row}>
                            <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                TIN Number
                            </Form.Label>
                            <Col sm="3">
                                <NumberFormat
                                    name="tinNumber"
                                    value={this.state.tinNumber}
                                    onChange={this.handleChanged} 
                                    autoComplete="off"
                                    format="###-###-###-###"
                                    className="form-control"
                                    placeholder="Enter tin number"
                                    disabled={this.state.disable}
                                />
                            </Col>
                            <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                SSS Number 
                            </Form.Label>
                            <Col sm="3">
                                <NumberFormat
                                    name="sssNumber"
                                    value={this.state.sssNumber}
                                    onChange={this.handleChanged} 
                                    autoComplete="off"
                                    format="##-#######-#"
                                    className="form-control"
                                    placeholder="Enter ssss number"
                                    disabled={this.state.disable}
                                />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row}>
                            <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                PHIC Number
                            </Form.Label>
                            <Col sm="3">
                                <NumberFormat
                                    name="phicNumber"
                                    value={this.state.phicNumber}
                                    onChange={this.handleChanged} 
                                    autoComplete="off"
                                    format="##-#########-#"
                                    className="form-control"
                                    placeholder="Enter phic number"
                                    disabled={this.state.disable}
                                />
                            </Col>
                            <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                HDMF Number 
                            </Form.Label>
                            <Col sm="3">
                                <NumberFormat
                                    name="hdmfNumber"
                                    value={this.state.hdmfNumber}
                                    onChange={this.handleChanged} 
                                    autoComplete="off"
                                    format="####-####-####"
                                    className="form-control"
                                    placeholder="Enter hdmf number"
                                    disabled={this.state.disable}
                                />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row}>
                            <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                DATE HIRED
                            </Form.Label>
                            <Col sm="3">
                                <DatePicker
                                    id="dateHired"
                                    name="dateHired"
                                    selected={this.state.dateHired}
                                    onChange={this.handleChangedDate("dateHired")}
                                    minDate={this.minDate}
                                    value={this.state.dateHired}
                                    dateFormat={"MM/dd/yyyy"}
                                    className="form-control"
                                    disabled={this.state.disable}
                                    autoComplete="off"
                                />
                            </Col>
                            <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                EOC
                            </Form.Label>
                            <Col sm="3">
                                <DatePicker
                                    id="duration"
                                    name="duration"
                                    selected={this.state.duration}
                                    onChange={this.handleChangedDate("duration")}
                                    minDate={this.minDate}
                                    value={this.state.duration}
                                    dateFormat={"MM/dd/yyyy"}
                                    className="form-control"
                                    disabled={this.state.disable}
                                    autoComplete="off"
                                />
                            </Col>
                        </Form.Group>
                        {/* <Form.Group as={Row}>
                            <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                DATE RESIGNED
                            </Form.Label>
                            <Col sm="3">
                                <DatePicker
                                    id="dateResigned"
                                    name="dateResigned"
                                    selected={this.state.dateResigned}
                                    onChange={this.handleChangedDate("dateResigned")}
                                    minDate={this.minDate}
                                    value={this.state.dateResigned}
                                    dateFormat={"MM/dd/yyyy"}
                                    className="form-control"
                                    disabled={this.state.disable}
                                    autoComplete="off"
                                />
                            </Col>
                        </Form.Group> */}
                        <Form.Group as={Row}>
                            <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                ECOLA
                            </Form.Label>
                            <Col sm="3">
                                <Form.Control 
                                    id="eColaRate"
                                    name="eColaRate"
                                    type="text" 
                                    value={this.state.eColaRate}
                                    placeholder="Enter ecola rate"
                                    disabled={this.state.disable}
                                    autoComplete="off"
                                    onChange={this.handleChanged}
                                    onKeyPress={this.IsDecimal.bind(this)}
                                    maxLength="13"
                                />
                            </Col>
                            <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                COLA
                            </Form.Label>
                            <Col sm="3">
                                <Form.Control 
                                    id="colaRate"
                                    name="colaRate"
                                    type="text" 
                                    value={this.state.colaRate}
                                    placeholder="Enter cola rate"
                                    disabled={this.state.disable}
                                    autoComplete="off"
                                    onChange={this.handleChanged}
                                    onKeyPress={this.IsDecimal.bind(this)}
                                    maxLength="13"
                                />
                            </Col>
                            <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                SEA
                            </Form.Label>
                            <Col sm="3">
                                <Form.Control 
                                    id="seaRate"
                                    name="seaRate"
                                    type="text" 
                                    value={this.state.seaRate}
                                    placeholder="Enter sea rate"
                                    disabled={this.state.disable}
                                    autoComplete="off"
                                    onChange={this.handleChanged}
                                    onKeyPress={this.IsDecimal.bind(this)}
                                    maxLength="13"
                                />
                            </Col>
                        </Form.Group>
                    </Form>
                </Container>
                </Modal.Body>
                <Modal.Footer>
                    <ButtonToolbar >
                        <Button disabled={this.state.disable} variant="success" className="ml-auto  noser-button-mr1" onClick = { this.handleSubmitClick }>SUBMIT</Button>
                        <Button className="noser-button" variant="danger" onClick={this.props.onHide}>Close</Button>
                    </ButtonToolbar>
                </Modal.Footer>
                <NoserLoading show={this.state.isloading} />
            </Modal>
            </>
        );
    }
}
export  default DraftEmployee
