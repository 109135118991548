import {
    React,Component,BootstrapTable,
    paginationFactory,Button, ButtonToolbar, Card, 
    Form, Col, Row, axios, Container, Banner, Typeahead, TimePicker,
    cellEditFactory, Type, moment, NavLink, Alert, AppConfiguration,
    NoserLoading, Noser, sizePerPageRenderer, DatePicker
} 
from '../../noser-hris-component';
import { DropdownButton, Dropdown} from 'react-bootstrap';
import { subDays } from "date-fns";

const showSecond = false;
const str = showSecond ? 'HH:mm:ss' : 'HH:mm';

class TrainingScheduleRegistration extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fade            :   true, 
            color           :   "",
            isshow          :   false,
            message         :   "",
            userinfo        :   [],
            isloading       :   false,
            alerttype       :   "",

            // Start Orientation
            orientationDate         : "",
            orientationVenue        : "",
            orientationFacilitator  : "",
            orientationTime         : "",
            // END Orientation

            //START PRE-TEST
            preTestDate : "",
            TimePreTest        : "",
            preTestFacilitator  : "",
            preTestVenue         : "",
            //END PRE-TEST

            //START RE-TEST
            reTestDate : "",
            TimeReTest        : "",
            reTestFacilitator  : "",
            reTestVenue         : "",
            //END RE-TEST

            // START TRAINING SCHEDULE

            trainingDDL         : [],
            trainingIdSchedule : "",
            trainingSessionDDL  : [
                {"id" : "1", "name" : "Single Session"},
                {"id" : "2", "name" : "Multiple Session"},
            ],
            batchNo : "",
            participantLimit : "",
            trainingFacilitator : "",
            trainingVenue : "",
            dateFrom : "",
            dateTo : "",
            TimeFrom : "",
            TimeTo : "",
            valueFrom : "",
            valueTo : "",
            valueToExist :"",
            valueFromExist : "",
            trainingNameDefaultValue : [],
            selectedTrainingName : "",
            trainingScheduleData : [],
            description : "",
            trainingSession : "",

            scheduleNameDDL : [],
            isshowdescription : true,
            isshowscheduleName :  false,
            newTrainingSchedule : true,
            scheduleId : "",
            disableTrainingName : true,

            isshowNew : true,
            isshowExisting : false,
            valueFromExist : "",

            requestMonitoringData : JSON.parse(sessionStorage.getItem("requestMonitoringDataRow")),
        }
    }

    componentDidMount(){
        const sleep = (milliseconds) => {return new Promise(resolve => setTimeout(resolve, milliseconds))}
        this.state.userinfo = JSON.parse(sessionStorage.getItem("userData"))
        //this.GetEmployeeSchedules();
        this.GetTrainings(); 
        this.setState({
            referenceId : this.state.requestMonitoringData.id,
            clientName : this.state.requestMonitoringData.client,
            clientId : this.state.requestMonitoringData.clientId,
            profileId : this.state.requestMonitoringData.profileId,
            trainingId : this.state.requestMonitoringData.trainingId,
            requestTypeId : this.state.requestMonitoringData.requestTypeId,
            employeeName : this.state.requestMonitoringData.employeeName,
            employeeNo : this.state.requestMonitoringData.employeeNo,
            position : this.state.requestMonitoringData.position,
            location : this.state.requestMonitoringData.location,
            requestType : this.state.requestMonitoringData.requestType,
            trainingName : this.state.requestMonitoringData.training,
            deploymentDate : this.state.requestMonitoringData.deploymentDate,
        }) 
        
        if(this.state.newTrainingSchedule === true) {
            this.setState({
                isshowdescription : true,
            })
        }
        
        if(this.state.requestMonitoringData.requestTypeId === "1") {
            this.setState({
                disableOrientation : false,
                disablePreTest : false,
                disableReTest : false,
                disableTraining : false,
                isDisabledField : false,
                isDisabledDesc : false,
            })
        }
        
        if(this.state.requestMonitoringData.requestTypeId === "2") {
            this.setState({
                disableOrientation : false,
                disablePreTest : false,
                disableReTest : false,
                disableTraining : false,
                isDisabledField : false,
                isDisabledDesc : false,
            })
        }
        
        if(this.state.requestMonitoringData.requestTypeId === "4") {
            this.setState({
                disableOrientation : false,
                disablePreTest : true,
                disableReTest : true,
                disableTraining : false,
                isDisabledField : true,
                isDisabledDesc : true,
            })
        }
        
        if(this.state.requestMonitoringData.requestTypeId === "7" || this.state.requestMonitoringData.requestTypeId === "8") {
            this.setState({
                disableOrientation : true,
                disablePreTest : true,
                disableReTest : false,
                disableTraining : true,
                isDisabledField : true,
                isDisabledDesc : true,
            })
        }

        console.log("requestMonitoringDataRow")
        console.log(this.state.requestMonitoringData)
    }

    GetEmployeeSchedules = async() => {
        this.setState({isloading:true})
        const param = {
            "IpAddress"     :   "0.0.0.0",
            "ClientId"      :   this.state.userinfo.clientId,
            "UserId"        :   this.state.userinfo.userId,
            "ProfileId"     :   this.state.requestMonitoringData.profileId,
            "TrainingId"    :   this.state.requestMonitoringData.trainingId,
            "ScheduleId"    :   this.state.requestMonitoringData.scheduleId,
        };
        //////console.log("param")
        //////console.log(param)
        await axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Training/GetEmployeeSchedules",  param)
        .then(res => {
            const data = res.data;
           //console.log("GetEmployeeSchedules")
            //console.log(data)
            this.setState({
                scheduleNameDDL : data.schedules,
                isloading:false
            })
            
        })
        .catch(error=>{
            this.setState(  {
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade            :   true
            })
        })
    }

    onChangeScheduleName = (e) => {
        if(e.length == 0) {
            this.state.scheduleId=""
            this.state.batchNo=""
            this.state.trainingSession=""
            this.state.trainingFacilitator=""
            this.state.trainingVenue=""
            this.state.participantLimit=""
            return
        } 
        this.state.scheduleId = e[0].id
        /* this.state.batchNo = e[0].batchNo
        this.state.trainingSession = e[0].sessionName
        this.state.trainingFacilitator = e[0].facilitator
        this.state.trainingVenue = e[0].venue
        this.state.participantLimit = e[0].participantLimit */

        let today = new Date();
        let valueFrom = new moment();
        let valueTo = new Date();
        let dd = String(today.getDate()).padStart(2, '0');
        let mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
        let yyyy = today.getFullYear();

        valueFrom = moment(mm + '/' + dd + '/' + yyyy + ' ' + e[0].fromTime);
        valueTo = moment(mm + '/' + dd + '/' + yyyy + ' ' + e[0].toTime);

        this.setState({
            batchNo : e[0].batchNo,
            trainingSession : e[0].sessionName,
            description : e[0].description,
            trainingFacilitator : e[0].facilitator,
            trainingVenue : e[0].venue,
            participantLimit : e[0].participantLimit,
            dateFrom : new Date(e[0].fromDate),
            dateTo : new Date(e[0].toDate),
            valueFromExist : valueFrom,
            valueToExist : valueTo,
            timeFrom : valueFrom.format(str),
            timeTo : valueTo.format(str)
        })


        //console.log(valueFrom.format(str))
    }

    

    /* START ORIENTATION */

    handleChangeOrientationSchedule = (e) => {
        this.state.orientationSchedule = e.target.checked
        this.setState({
            //orientationSchedule	: e.target.checked,
            isshow:false,
        })

        if(this.state.orientationSchedule === true) {
            this.setState({
                disableOrientation : false
            })
        }
    }

    handleChangeOrientationDate = date => {
        this.setState({
            orientationDate	:   date,
            isshow:false,
        });
    };

    onChangeOrientationVenue = (e) => {
        this.setState({
            orientationVenue	: e.target.value,
            isshow:false,
        })
    }

    onChangeOrientationFacilitator = (e) => {
        this.setState({
            orientationFacilitator	: e.target.value,
            isshow:false,
        })
    }

    onChangeOrientationTime = (TimeOrientation) => {
        this.state.TimeOrientation = TimeOrientation && TimeOrientation.format(str)
    } 

    /* END ORIENTATION */

    /* START PRE-TEST */

    handleChangePreTestSchedule = (e) => {
        this.state.preTestSchedule = e.target.checked
        this.setState({
            isshow:false,
        })

        if(this.state.preTestSchedule === true) {
            this.setState({
                disablePreTest : false
            })
        }
    }

    handleChangePreTestDate = date => {
        this.setState({
            preTestDate	:   date,
            isshow:false,
        });
    };

    onChangePreTestVenue = (e) => {
        this.setState({
            preTestVenue	: e.target.value,
            isshow:false,
        })
    }

    onChangePreTestTime = (TimePreTest) => {
        this.state.TimePreTest = TimePreTest && TimePreTest.format(str)
        this.setState({
            isshow:false,
        })
    } 

    onChangePreTestFacilitator = (e) => {
        this.setState({
            preTestFacilitator	: e.target.value,
            isshow:false,
        })
    }

    /* END PRE-TEST */

    /* START PRE-TEST */

    handleChangeReTestSchedule = (e) => {
        this.state.reTestSchedule = e.target.checked
        this.setState({
            isshow:false,
        })

        if(this.state.reTestSchedule === true) {
            this.setState({
                disableReTest : false
            })
        }
    }

    handleChangeReTestDate = date => {
        this.setState({
            reTestDate	:   date,
            isshow:false,
        });
    };

    onChangeReTestVenue = (e) => {
        this.setState({
            reTestVenue	: e.target.value,
            isshow:false,
        })
    }

    onChangeReTestTime = (TimeReTest) => {
        this.state.TimeReTest = TimeReTest && TimeReTest.format(str)
        this.setState({
            isshow:false,
        })
    } 

    onChangeReTestFacilitator = (e) => {
        this.setState({
            reTestFacilitator	: e.target.value,
            isshow:false,
        })
    }

    /* END RE-TEST */

    /* START TRAINING SCHEDULE */

    handleChangeTrainingSchedule = (e) => {
        this.state.trainingSchedule	= e.target.checked
        this.setState({
            isshow:false,
        })


        if(this.state.trainingSchedule === true) {
            this.GetEmployeeSchedules();
            this.setState({
                newTrainingSchedule : false,
                isshowdescription : false,
                isshowscheduleName : true,
                isDisabledField : true,
                isshowExisting : true,
                isshowNew   : false,
            })
        }
    }

    handleChangeNewTrainingSchedule = (e) => {
        this.state.newTrainingSchedule	= e.target.checked
        this.setState({
            isshow:false,
        })

        if(this.state.newTrainingSchedule === true) {
            this.setState({
                trainingSchedule : false,
                isshowdescription : true,
                isshowscheduleName : false,
                scheduleId : "",
                isshowTimeFromNew : true,
                isshowNew : true,
                isshowExisting : false,
                description : "",
                batchNo : "",
                trainingSession : "",
                trainingFacilitator : "",
                trainingVenue : "",
                participantLimit : "",
                dateFrom : "",
                dateTo : "",
                valueFrom : "",
                valueTo : "",

                isDisabledField : false,
            })
        }
        this.refs.scheduleNameRef.clear()

    }

    GetTrainings = async()=> {
        this.setState({isloading:true})

        const param = {
            "IpAddress":"0.0.0.0",
            "UserId":this.state.userinfo.userId,
            "ClientId":this.state.userinfo.clientId,
            "TrainingId":"",
        };
        await axios
            .post(
                 AppConfiguration.Setting().noserapiendpoint + "Maintenance/GetTrainings",  param
            )
             .then(res => {
                 const data = res.data;
                 this.setState({ trainingDDL:data.trainings, isloading : false })
                 if(data.status=="0"){
                    this.setState({
                        isloading   :   false,
                        alerttype   :   "Error!",
                        isshow      :   true,
                        color       :   "danger",
                        message     :   data.message,
                        fade        :   true
                    });
                
                }
                    
            })
            .catch(error=>{
                this.setState({
                    isloading       :   false,
                    alerttype       :   "Error!",
                    isshow          :   true,
                    color           :   "danger",
                    message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                    fade            :   true
                })
            })
        
    }

    onChangeDescription = (e) => {
        this.setState({
            description	: e.target.value,
            isshow:false,
        })
    }

    onChangeTrainingSession = (e) => {
        
        this.setState({
            trainingSession	: e.target.value,
            isshow:false,
        })
        this.setState({
            isshow:false,
        })
    }

    onChangeBatchNo = (e) => {
        this.setState({
            batchNo	: e.target.value,
            isshow:false,
        })
    }

    onChangeParticipantLimit = (e) => {
        this.setState({
            participantLimit	: e.target.value,
            isshow:false,
        })
    }

    onChangeParticipantLimitExist = (e) => {
        this.setState({
            participantLimit	: e.target.value,
            isshow:false,
        })
    }
    
    onChangeTrainingFacilitator = (e) => {
        this.setState({
            trainingFacilitator	: e.target.value,
            isshow:false,
        })
    }

    onChangeTrainingFacilitatorExist = (e) => {
        this.setState({
            trainingFacilitator	: e.target.value,
            isshow:false,
        })
    }


    onChangeTrainingVenue = (e) => {
        this.setState({
            trainingVenue	: e.target.value,
            isshow:false,
        })
    }

    handleChangeDateFrom = date => {
        this.setState({
            dateFrom	:   date,
            isshow:false,
        });
    };

    handleChangeDateTo = date => {
        this.setState({
            dateTo	:   date,
            isshow:false,
        });
    };

    onChangeTimeFrom = (TimeFrom) => {
        this.state.timeFrom = TimeFrom && TimeFrom.format(str)
        this.setState({
            isshow:false,
        })
    }


    onChangeTimeTo = (TimeTo) => {
        this.state.timeTo = TimeTo && TimeTo.format(str)
        this.setState({
            isshow:false,
        })
    }
    
    /* END TRAINING SCHEDULE */



    handleSaveClick = () => {

        let dateFrom = ""
        let dateTo = ""
        let orientationDate = ""
        let reTestDate = ""
        let preTestDate = ""

        if(this.state.dateFrom === "") {
            dateFrom = ""
        } else {
            dateFrom = moment(this.state.dateFrom).format('MM/DD/YYYY')
        }

        if(this.state.dateTo === "") {
            dateTo = ""
        } else {
            dateTo = moment(this.state.dateTo).format('MM/DD/YYYY')
        }

        if(this.state.orientationDate === "") {
            orientationDate = ""
        } else {
            orientationDate = moment(this.state.orientationDate).format('MM/DD/YYYY')
        }

        if(this.state.reTestDate === "") {
            reTestDate = ""
        } else {
            reTestDate = moment(this.state.reTestDate).format('MM/DD/YYYY')
        }

        if(this.state.preTestDate === "") {
            preTestDate = ""
        } else {
            preTestDate = moment(this.state.preTestDate).format('MM/DD/YYYY')
        }

        //this.state.timeFrom = this.state.valueFromExist
        

        //this.setState({isloading   :   true})

        const saveParams = {
            "IpAddress"         :   "0.0.0.0",
            "UserId"            :   this.state.userinfo.userId,
            "ClientId"          :   this.state.clientId,
            "ReferenceId"       :   this.state.referenceId,
            "ProfileId"         :   this.state.profileId,
            "TrainingId"        :   this.state.trainingId,
            "RequestTypeId"     :   this.state.requestTypeId,
            "DeploymentDate"    :   this.state.deploymentDate,
            "IsPreTest"         :   (this.state.preTestSchedule)? "1" : "0",
            "IsNewSchedule"     :   (this.state.newTrainingSchedule)? "1" : "0",
            "trainingSchedule":{
                "Id"                :   this.state.scheduleId, // from description if autocomplete if textbox empty
                "Description"       :   this.state.description, // from description textbox
                "SessionName"       :   this.state.trainingSession,
                "BatchNo"           :   this.state.batchNo,
                "Facilitator"       :   this.state.trainingFacilitator,
                "Venue"             :   this.state.trainingVenue,
                "ParticipantLimit"  :   this.state.participantLimit,
                "FromDate"          :   (this.state.dateFrom)? moment(this.state.dateFrom).format('MM/DD/YYYY') : "",
                "FromTime"          :   this.state.timeFrom,
                "ToDate"            :   (this.state.dateTo)? moment(this.state.dateFrom).format('MM/DD/YYYY') : "",
                "ToTime"            :   this.state.timeTo,
            },
            "orientationSchedule"   :{
                "ScheduleDate"      :   orientationDate,
                "ScheduleTime"      :   this.state.TimeOrientation,
                "Facilitator"       :   this.state.orientationFacilitator,
                "Venue"             :   this.state.orientationVenue,
            },
            "retestSchedule"    :{
                "ScheduleDate"  :   reTestDate,
                "ScheduleTime"  :   this.state.TimeReTest,
                "Facilitator"   :   this.state.reTestFacilitator,
                "Venue"         :   this.state.reTestVenue,
            },
            "pretestSchedule"   :{
                "ScheduleDate"  :   preTestDate,
                "ScheduleTime"  :   this.state.TimePreTest,
                "Facilitator"   :   this.state.preTestFacilitator,
                "Venue"         :   this.state.preTestVenue,
            }
        };

        console.log("Save Params")
        console.log(saveParams)

        axios
        .post(AppConfiguration.Setting().noserapiendpoint + "Training/AddEmployeeSchedule",  saveParams)
        .then(res => {
            const data = res.data;
            ////console.log("Save Results")
            ////console.log(data)
            if(data.status=="1"){
            this.setState({
                    isloading   :   false,
                    alerttype   :   "Success!",
                    isshow      :   true,
                    color       :   "success",
                    message     :   data.message,
                    fade        :   true
                });
            
            }
            else {
                this.setState({
                    isloading   :   false,
                    alerttype   :   "Error!",
                    isshow      :   true,
                    color       :   "danger",
                    message     :   data.message,
                    fade        :   true
                });
            }
        })
        .catch(error=>{
            this.setState({
                isloading       :   false,
                alerttype       :   "Error!",
                isshow          :   true,
                color           :   "danger",
                message         :   "An error occured while processing your request, Please contact your System Administrator for : " + error.message,
                fade            :   true
            })
        })
    }


    render() {
        return(
            <div>
                <Banner />
                    <Container className="mt-5" fluid>
                        <Card>
                            <Card.Header>TRAINING >> TRAINING SCHEDULE REGISTRATION (CREATE)</Card.Header>
                            <Card.Body>
                                <Form>
                                    <Alert isOpen={this.state.isshow} color={this.state.color} fade={this.state.fade} className={this.state.isshow ? 'display-block' : 'display-none'}>
                                        <div className={this.state.color}></div> 
                                        {this.state.message}
                                    </Alert>
                                    <Form.Group as={Row} controlId="formPlaintextEmail">
                                        <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                            CLIENT:
                                        </Form.Label>
                                        <Form.Label column sm="3" style={{fontFamily : "italic", color: "red"}}>
                                        {this.state.clientName}
                                        </Form.Label>
                                        <Col sm="1">
                                        </Col>
                                        <Form.Label column sm="1" style={{fontWeight : "bold",}}>
                                            EMPLOYEE NAME: 
                                        </Form.Label>
                                        <Form.Label column sm="2" style={{fontFamily : "italic", color: "red"}}>
                                        {this.state.employeeName}
                                        </Form.Label>
                                        <Col sm="1">
                                        </Col>
                                        <Form.Label column sm="1" style={{fontWeight : "bold",}}>
                                            EMPLOYEE NO.: 
                                        </Form.Label>
                                        <Form.Label column sm="2" style={{fontFamily : "italic", color: "red"}}>
                                        {this.state.employeeNo}
                                        </Form.Label>
                                    </Form.Group>
                                    <Form.Group as={Row} controlId="formPlaintextEmail">
                                        <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                            POSITION:
                                        </Form.Label>
                                        <Form.Label column sm="3" style={{fontFamily : "italic", color: "red"}}>
                                        {this.state.position}
                                        </Form.Label>
                                        <Col sm="1">
                                        </Col>
                                        <Form.Label column sm="1" style={{fontWeight : "bold",}}>
                                            BRANCH/LOCATION: 
                                        </Form.Label>
                                        <Form.Label column sm="2" style={{fontFamily : "italic", color: "red"}}>
                                        {this.state.location}
                                        </Form.Label>
                                    </Form.Group>
                                    <Form.Group as={Row} controlId="formPlaintextEmail">
                                        <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                            REQUEST TYPE:
                                        </Form.Label>
                                        <Form.Label column sm="3" style={{fontFamily : "italic", color: "red"}}>
                                        {this.state.requestType}
                                        </Form.Label>
                                        <Col sm="1">
                                        </Col>
                                        <Form.Label column sm="1" style={{fontWeight : "bold",}}>
                                            TRAINING: 
                                        </Form.Label>
                                        <Form.Label column sm="2" style={{fontFamily : "italic", color: "red"}}>
                                        {this.state.trainingName}
                                        </Form.Label>
                                        <Col sm="1">
                                        </Col>
                                        <Form.Label column sm="1" style={{fontWeight : "bold",}}>
                                            DEPLOYMENT DATE.: 
                                        </Form.Label>
                                        <Form.Label column sm="2" style={{fontFamily : "italic", color: "red"}}>
                                        {this.state.deploymentDate}
                                        </Form.Label>
                                    </Form.Group>
                                    <hr className="mt-5"/>
                                    <Form.Group className="mt-5" as={Row} controlId="formPlaintextEmail">
                                        <Col sm="1">
                                            <Form.Check type="checkbox" 
                                                label="TRAINING SCHEDULE" 
                                                name="trainingSchedule"
                                                onChange={this.handleChangeTrainingSchedule}
                                                style={{fontWeight : "bold",marginLeft:"2px"}}
                                                checked={this.state.trainingSchedule}
                                                disabled={this.state.disableTraining}
                                            />
                                        </Col>
                                        <Col sm="1">
                                            <Form.Check type="checkbox" 
                                                label="CREATE NEW TRAINING SCHEDULE" 
                                                name="trainingScheduleCreate"
                                                onChange={this.handleChangeNewTrainingSchedule}
                                                style={{fontWeight : "bold",marginLeft:"2px"}}
                                                checked={this.state.newTrainingSchedule}
                                                disabled={this.state.disableTraining}
                                            />
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} controlId="formPlaintextEmail">
                                        <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                            TRAINING NAME
                                        </Form.Label>
                                        <Col sm="11">
                                            <Form.Control 
                                                ref="trainingName"
                                                name="trainingName"
                                                value={this.state.trainingName}
                                                //onChange={this.onChangeDescription}
                                                autoComplete="off"
                                                disabled={this.state.disableTrainingName}
                                            />
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} controlId="formPlaintextEmail">
                                        <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                            DESCRIPTION
                                        </Form.Label>
                                        <Col sm="6" className={this.state.isshowdescription ? 'display-block' : 'display-none'}>
                                            <Form.Control 
                                                ref="description"
                                                name="description"
                                                value={this.state.description}
                                                onChange={this.onChangeDescription}
                                                autoComplete="off"
                                                className={this.state.isshowdescription ? 'display-block' : 'display-none'}
                                                disabled={this.state.isDisabledDesc}
                                            />
                                        </Col>
                                        <Col sm="6" className={this.state.isshowscheduleName ? 'display-block' : 'display-none'}>
                                            <Typeahead
                                                ref="scheduleNameRef"
                                                labelKey='scheduleName'
                                                id="basic-example"
                                                onChange={this.onChangeScheduleName}
                                                options={this.state.scheduleNameDDL}
                                                className={this.state.isshowscheduleName ? 'display-block' : 'display-none'}
                                                disabled={this.state.isDisabledDesc}
                                            />
                                        </Col>
                                        <Col sm="1">
                                        </Col>
                                        <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                            TRAINING SESSION
                                        </Form.Label>
                                        <Col sm="3">
                                            <Form.Control 
                                                ref="trainingSession"
                                                name="trainingSession"
                                                value={this.state.trainingSession}
                                                onChange={this.onChangeTrainingSession}
                                                autoComplete="off"
                                                disabled={this.state.isDisabledField}
                                            />
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} controlId="formPlaintextEmail">
                                        <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                            BATCH NO.
                                        </Form.Label>
                                        <Col sm="6">
                                            <Form.Control 
                                                ref="batchNo"
                                                name="batchNo"
                                                value={this.state.batchNo}
                                                onChange={this.onChangeBatchNo}
                                                autoComplete="off"
                                                disabled={this.state.isDisabledField}
                                            />
                                        </Col>
                                        <Col sm="1">
                                        </Col>
                                        <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                            LIMIT NO. OF PARTICIPANTS
                                        </Form.Label>
                                        <Col sm="3">
                                            <Form.Control 
                                                ref="participantLimit"
                                                name="participantLimit"
                                                value={this.state.participantLimit}
                                                onChange={this.onChangeParticipantLimit}
                                                autoComplete="off"
                                            />
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} controlId="formPlaintextEmail">
                                        <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                            FACILITATOR
                                        </Form.Label>
                                        <Col sm="6">
                                            <Form.Control 
                                                ref="trainingFacilitator"
                                                name="trainingFacilitator"
                                                value={this.state.trainingFacilitator}
                                                onChange={this.onChangeTrainingFacilitator}
                                                autoComplete="off"
                                            />
                                        </Col>
                                        <Col sm="1">
                                        </Col>
                                        <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                            VENUE
                                        </Form.Label>
                                        <Col sm="3">
                                            <Form.Control 
                                                ref="trainingVenue"
                                                name="trainingVenue"
                                                value={this.state.trainingVenue}
                                                onChange={this.onChangeTrainingVenue}
                                                autoComplete="off"
                                                disabled={this.state.isDisabledField}
                                            />
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} controlId="formPlaintextEmail">
                                        <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                            DATE FROM
                                        </Form.Label>
                                        <Col sm="1">
                                            <DatePicker
                                                ref='dateFrom'
                                                selected={this.state.dateFrom}
                                                onChange={this.handleChangeDateFrom}
                                                //minDate={new Date()}
                                                value={this.state.dateFrom}
                                                dateFormat={"MM/dd/yyyy"}
                                                className="form-control"
                                                disabled={this.state.disableTraining}
                                            />
                                        </Col>
                                        <Col sm="1">
                                        </Col>
                                        <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                            DATE TO
                                        </Form.Label>
                                        <Col sm="1">
                                            <DatePicker
                                                ref='dateTo'
                                                selected={this.state.dateTo}
                                                onChange={this.handleChangeDateTo}
                                                minDate={this.state.dateFrom}
                                                value={this.state.dateTo}
                                                dateFormat={"MM/dd/yyyy"}
                                                className="form-control"
                                                disabled={this.state.disableTraining}
                                            />
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} controlId="formPlaintextEmail">
                                        <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                            TIME FROM
                                        </Form.Label>
                                        <Col sm="1" className={this.state.isshowNew ? 'display-block' : 'display-none'}>
                                            <TimePicker 
                                                showSecond={showSecond}
                                                defaultValue={this.state.valueFrom}
                                                className="xxx"
                                                onChange={this.onChangeTimeFrom}
                                                disabled={this.state.disableTraining}
                                            />
                                        </Col>
                                        <Col sm="1" className={this.state.isshowExisting ? 'display-block' : 'display-none'}>
                                            <TimePicker 
                                                showSecond={showSecond}
                                                value={this.state.valueFromExist}
                                                defaultValue={this.state.valueFromExist}
                                                className="xxx"
                                                onChange={this.onChangeTimeFromExist}
                                                disabled={this.state.isDisabledField}
                                            />
                                        </Col>
                                        <Col sm="1">
                                        </Col>
                                        <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                            TIME TO
                                        </Form.Label>
                                        <Col sm="1" className={this.state.isshowNew ? 'display-block' : 'display-none'}>
                                            <TimePicker 
                                                showSecond={showSecond}
                                                defaultValue={this.state.valueTo}
                                                className="xxx"
                                                onChange={this.onChangeTimeTo}
                                                disabled={this.state.disableTraining}
                                            />
                                        </Col>
                                        <Col sm="1" className={this.state.isshowExisting ? 'display-block' : 'display-none'}>
                                            <TimePicker 
                                                showSecond={showSecond}
                                                value={this.state.valueToExist}
                                                defaultValue={this.state.valueToExist}
                                                className="xxx"
                                                onChange={this.onChangeTimeTo}
                                                disabled={this.state.isDisabledField}
                                            />
                                        </Col>
                                    </Form.Group>
                                    <hr className="mt-5"/>
                                    <Form.Group className="mt-5" as={Row} controlId="formPlaintextEmail">
                                        <Col sm="1">
                                            <Form.Check type="checkbox" 
                                                label="PRE-TEST SCHEDULE" 
                                                name="preTestSchedule"
                                                onChange={this.handleChangePreTestSchedule}
                                                style={{fontWeight : "bold",marginLeft:"2px"}}
                                                //checked={this.state.preTestSchedule}
                                            />
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} controlId="formPlaintextEmail">
                                        <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                            PRE-TEST DATE
                                        </Form.Label>
                                        <Col sm="1">
                                            <DatePicker
                                                ref='preTestDate'
                                                selected={this.state.preTestDate}
                                                onChange={this.handleChangePreTestDate}
                                                minDate={this.state.dateFrom}
                                                value={this.props.preTestDate}
                                                dateFormat={"MM/dd/yyyy"}
                                                className="form-control"
                                                disabled={this.state.disablePreTest}
                                            />
                                        </Col>
                                        <Col sm="1">
                                        </Col>
                                        <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                            VENUE
                                        </Form.Label>
                                        <Col sm="8">
                                            <Form.Control 
                                                type="text"
                                                name="preTestVenue"
                                                value={this.state.preTestVenue}
                                                onChange={this.onChangePreTestVenue} 
                                                autoComplete="off"
                                                disabled={this.state.disablePreTest}
                                            />
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} controlId="formPlaintextEmail">
                                        <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                        PRE-TEST TIME
                                        </Form.Label>
                                        <Col sm="1">
                                            <TimePicker 
                                                showSecond={showSecond}
                                                defaultValue={this.state.preTestDateTime}
                                                className="xxx"
                                                onChange={this.onChangePreTestTime}
                                                disabled={this.state.disablePreTest}
                                            />
                                        </Col>
                                        <Col sm="1">
                                        </Col>
                                        <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                            FACILITATOR
                                        </Form.Label>
                                        <Col sm="8">
                                            <Form.Control 
                                                type="text"
                                                name="preTestFacilitator"
                                                value={this.state.preTestFacilitator}
                                                onChange={this.onChangePreTestFacilitator} 
                                                autoComplete="off"
                                                disabled={this.state.disablePreTest}
                                            />
                                        </Col>
                                    </Form.Group>
                                    <Form.Group className="mt-5" as={Row} controlId="formPlaintextEmail">
                                        <Col sm="1">
                                            <Form.Check type="checkbox" 
                                                label="RE-TEST SCHEDULE" 
                                                name="reTestSchedule"
                                                onChange={this.handleChangeReTestSchedule}
                                                style={{fontWeight : "bold",marginLeft:"2px"}}
                                                //checked={this.state.reTestSchedule}
                                            />
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} controlId="formPlaintextEmail">
                                        <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                        RE-TEST DATE
                                        </Form.Label>
                                        <Col sm="1">
                                            <DatePicker
                                                ref='reTestDate'
                                                selected={this.state.reTestDate}
                                                onChange={this.handleChangeReTestDate}
                                                minDate={this.state.dateFrom}
                                                value={this.props.reTestDate}
                                                dateFormat={"MM/dd/yyyy"}
                                                className="form-control"
                                                disabled={this.state.disableReTest}
                                            />
                                        </Col>
                                        <Col sm="1">
                                        </Col>
                                        <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                            VENUE
                                        </Form.Label>
                                        <Col sm="8">
                                            <Form.Control 
                                                type="text"
                                                name="reTestVenue"
                                                value={this.state.reTestVenue}
                                                onChange={this.onChangeReTestVenue} 
                                                autoComplete="off"
                                                disabled={this.state.disableReTest}
                                            />
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} controlId="formPlaintextEmail">
                                        <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                        RE-TEST TIME
                                        </Form.Label>
                                        <Col sm="1">
                                            <TimePicker 
                                                showSecond={showSecond}
                                                defaultValue={this.state.reTestDateTime}
                                                className="xxx"
                                                onChange={this.onChangeReTestTime}
                                                disabled={this.state.disableReTest}
                                            />
                                        </Col>
                                        <Col sm="1">
                                        </Col>
                                        <Form.Label column sm="1" style={{fontWeight : "bold"}}>
                                            FACILITATOR
                                        </Form.Label>
                                        <Col sm="8">
                                            <Form.Control 
                                                type="text"
                                                name="reTestFacilitator"
                                                value={this.state.reTestFacilitator}
                                                onChange={this.onChangeReTestFacilitator} 
                                                autoComplete="off"
                                                disabled={this.state.disableReTest}
                                            />
                                        </Col>
                                    </Form.Group>
                                    
                                    
                                    <ButtonToolbar className="mt-5">
                                        <Button className="ml-auto" variant="success" onClick = { this.handleSaveClick }>Save</Button>&nbsp;&nbsp;&nbsp;
                                        <Button  href="/requestmonitoring" variant="danger">Back</Button>
                                    </ButtonToolbar>
                                </Form>
                            </Card.Body>
                        </Card>
                       
                    </Container>
                    <NoserLoading show={this.state.isloading} />
            </div> 
        )
    }

}

export  default TrainingScheduleRegistration 
